import ProcessType, { ProcessTypeText } from 'consts/ProcessType';
import React from 'react';
import { useForm } from 'react-hook-form';
import JDAFilterCheckbox from 'components/smartFilter/JDAFilterCheckbox';
import JDAFilterRadio from 'components/smartFilter/JDAFilterRadio';
import TagCategory, { TagCategoryText } from 'consts/TagCategory';

// ** form..

const FilterFormPage = () => {
  const { control, watch } = useForm({
    defaultValues: {
      'processTypeCheck.PRESENTATION': true,
      'processTypeCheck.ETC': true,
    }
  });

  return (
    <div>
      <div>라디오</div>
      {
        Object.values(TagCategory).map((key) => (
          <div key={`radio-${key}`}>
            <JDAFilterRadio
              control={control}
              name='processType'
              value={key}
              text={TagCategoryText[key]}
            />
          </div>
        ))
      }
      <div>
        processType: {watch('processType')}
      </div>
      <div>필터 체크박스</div>
      {
        Object.values(ProcessType).map((key) => (
          <div key={`radio-${key}`}>
            <JDAFilterCheckbox
              control={control}
              name={`processTypeCheck.${key}`}
              value={key}
              text={ProcessTypeText[key]}
            />
          </div>
        ))
      }
      <div>
        {JSON.stringify(watch('processTypeCheck'))}
      </div>
    </div>
  );
};

export default FilterFormPage;
