import Colors from '__designkit__/common/colors';
import Fonts, { fontStyle } from '__designkit__/common/fonts';
import { PhsAccType } from 'consts/MatchingMessageType';

import { AccGradeResultType } from 'consts/_v2/acc/AccApplyType';
import { PstGradeResultType, PstGradeResultTypeText } from 'consts/_v2/phs/pst/PstApplyType';
import React, { FC } from 'react';
import styled from 'styled-components';
import ImgStar from 'assets/_v2/phs/pst/img_pst_badge_star.svg';

const Frame = styled.div`
 .progress {
      display: flex;
      padding:6px 8px;
      height: 25px;
      align-items: center;
      background: ${Colors.CG_30};
      border: 1px solid ${Colors.CG_50};
      border-radius: 4px;
      color: ${Colors.CG_70};
      font: ${Fonts.B4_Medium};
      line-height: 14px;
  }
`;

const GradeBadge = styled.div<{ grade: PstGradeResultType }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 8px;
  height: 25px;
  font: ${Fonts.B4_Medium_P};
  color: ${Colors.JOBDA_BLACK};
  border-radius: 4px;
  border: ${(props) => {
    switch (props.grade) {
      case PstGradeResultType.TRY_AGAIN: return '1px solid rgba(0, 0, 0, 0.20);';
      default: return '1px solid rgba(0, 0, 0, 0.05);';
    }
  }};
  background-color: ${(props) => {
    switch (props.grade) {
      case PstGradeResultType.TRY_AGAIN: return '#FFF';
      case PstGradeResultType.BRONZE: return '#DC9868';
      case PstGradeResultType.SILVER: return '#D4D4D4';
      case PstGradeResultType.GOLD: return '#FFD600';
      case PstGradeResultType.PLATINUM: return '#58ECAE';
      default: return '#9FA4AB';
    }
  }};
  
  .star {
    width: 10px;
    height: 10px;
    margin-right: 4px;
  }
`;

interface IAccPstGradeProps {
  accGrade?: AccGradeResultType;
  pstGrade?: PstGradeResultType;
  type: PhsAccType;
  progressYn?: boolean;

}
const AccPstGrade: FC<IAccPstGradeProps> = ({ pstGrade, progressYn }) => (
  <Frame>
    {progressYn
      ? <div className='progress'>분석중</div>
      : (pstGrade
        && (
        <GradeBadge grade={pstGrade}>
          {pstGrade !== PstGradeResultType.TRY_AGAIN && <img src={ImgStar} className='star' alt='star' />}
          {PstGradeResultTypeText[pstGrade]}
        </GradeBadge>
        )
      )}
  </Frame>
);
export default AccPstGrade;
