import React, { FC, forwardRef, useCallback } from 'react';
import styled from 'styled-components';
import IComponentProps from '../interfaces/props/IComponentProps';

const StyledTabItem = styled.div`
  display: inline-block;
  ${(props) => `background-image: linear-gradient(${(props as any)['data-color']}, ${(props as any)['data-color']});`}
  background-position: left ${(props) => (props as any)['data-position-y']}px;
  background-repeat: no-repeat;
  padding-bottom: ${(props) => (props as any)['data-padding-bottom']}px;
  ${(props) => ((props as any)['data-enabled'] ? `
    background-size:100%;
    animation-name:splash;
    `
    : `
    background-size:0%;
    `)};
  animation-duration:${(props) => (props as any)['data-duration']}s;
  animation-iteration-count:1;
  will-change:auto;
  @keyframes splash {
    0%{
      background-size:0%;
    }
    100%{
      background-size:100%;
      transition: background-size ease-out;
    }
  }
`;

interface IUnderlineSplash extends IComponentProps {
  height: number;
  enable?: boolean;
  ratio?: number;
  duration?:number;
  color?: string;
}

const UnderlineSplash: FC<IUnderlineSplash> = forwardRef<HTMLDivElement, IUnderlineSplash>((props, ref) => {
  const { height, enable = false, ratio = 0.7, color, duration = 0.25 } = props;

  const getPaddingBottom = useCallback(() => (
    (height * ratio) - (height / 2) + 1
  ), [height, ratio]);

  const getPositionY = useCallback(() => (
    (height / 2) + 1
  ), [height]);

  return (
    <StyledTabItem
      ref={ref}
      data-enabled={enable}
      data-position-y={getPositionY()}
      data-padding-bottom={getPaddingBottom()}
      data-color={color}
      data-duration={duration}
      {...props}
    >
      {props.children}
    </StyledTabItem>
  );
});

export default UnderlineSplash;
