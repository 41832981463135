import FormProfileSubValueTitle from 'components/_v2/_common/form/FormProfileSubValueTitle';
import FormValueFrame, { FormValueMiddleFrame, ScoreTypeFormValueFrame } from 'components/_v2/_common/form/FormProfileValueFrame';
import FormProfileValueTitle from 'components/_v2/_common/form/FormProfileValueTitle';
import JDBaseInput from 'components/_v2/_common/input/JDBaseInput';
import JDCheckInput, { JD_CHECK_INPUT_TYPE } from 'components/_v2/_common/input/JDCheckInput';
import JDRadioInput, { JD_RADIO_INPUT_TYPE } from 'components/_v2/_common/input/JDRadioInput';
import JDSearchInput from 'components/_v2/_common/input/JDSearchInput';
import { NUMBER_GPA_STR, NUMBER_PERCENTAGE_STR } from 'consts/ValidationRule';
import { IMajorAliasListGetDto } from 'interfaces/rqrs/IMajorAliasListGetRs';
import { MajorType, ScoreType } from 'interfaces/_v2/profile/IProfileEducation';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import ProfileEducationModel from 'models/_v2/profile/ProfileEducationModel';
import React, { FC, useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { debounce } from 'utils/CommonUtils';
import { ErrorMessage } from '@hookform/error-message';

const FormSquareCheckBoxFrame = styled.div`

`;

export interface IProfileEduFieldArrayProps {
  prefixName: string;
  profileEducation?: ProfileEducationModel;
  hidden?: boolean;
}

const ProfileEducationMajor: FC<IProfileEduFieldArrayProps> = (props: IProfileEduFieldArrayProps) => {
  const { prefixName, profileEducation = new ProfileEducationModel() } = props;
  const useFormed = useFormContext();
  const { control, register, watch, setValue, errors } = useFormed;
  const name = `${prefixName}.profileMajors`;
  const { fields: profileMajorsFields, append: profileMajorsAppend, remove: profileMajorsRemove } = useFieldArray({
    control,
    name,
  });
  const [searchMajor, setSearchMajor] = useState<IMajorAliasListGetDto[]>([]);
  useEffect(() => {
    if (profileMajorsFields.length === 0 || profileMajorsFields === null) profileMajorsAppend({
      majorCode: undefined,
      majorName: undefined,
      majorType: MajorType.MAJOR,
      perfectScore: undefined,
      score: undefined,
      scoreType: ScoreType.SCORE,
    }, false);
  }, []);

  return (
    <div aria-hidden={props.hidden}>
      <FormProfileValueTitle required>학과・전공</FormProfileValueTitle>
      <FormProfileSubValueTitle>주전공</FormProfileSubValueTitle>
      {
        profileMajorsFields && profileMajorsFields.map((profileMajorsField, profileMajorsIndex) => {
          if (profileMajorsField.majorType === MajorType.MAJOR)
            return (
              <div key={profileMajorsField.id}>
                <input aria-hidden ref={register()} name={`${name}[${profileMajorsIndex}].majorType`} defaultValue={profileMajorsField.majorType} />
                <FormValueMiddleFrame>
                  <JDSearchInput
                    title='주전공・학과를 검색해 주세요.'
                    defaultTextName={profileMajorsField.majorName}
                    defaultCodeName={profileMajorsField.majorCode}
                    textName={`${name}[${profileMajorsIndex}].majorName`}
                    codeName={`${name}[${profileMajorsIndex}].majorCode`}
                    placeholder='주전공・학과를 검색해 주세요.'
                    onSearch={(searchText: string) => {
                      if (searchText === '') setSearchMajor([]);
                      else {
                        debounce(async () => {
                          await profileEducation.searchMajorAliases(searchText);
                          const sortedMajorAliases = profileEducation.majorAliases.sort((a, b) => a.name.localeCompare(b.name));
                          setSearchMajor(sortedMajorAliases);
                        }, 800);
                      }
                    }}
                    searchList={searchMajor}
                  />
                </FormValueMiddleFrame>
                <ScoreTypeFormValueFrame>
                  <div className='btn-frame'>
                    <JDRadioInput
                      type={JD_RADIO_INPUT_TYPE.SQUARE_CHIP}
                      name={`${name}[${profileMajorsIndex}].scoreType`}
                      value={ScoreType.SCORE}
                      label='학점'
                      defaultValue={profileMajorsField.scoreType}
                      onChange={() => setValue(`${name}[${profileMajorsIndex}].score`, undefined)}
                    />
                    <JDRadioInput
                      type={JD_RADIO_INPUT_TYPE.SQUARE_CHIP}
                      name={`${name}[${profileMajorsIndex}].scoreType`}
                      value={ScoreType.PERCENTAGE}
                      defaultValue={profileMajorsField.scoreType}
                      label='백분위'
                      onChange={() => setValue(`${name}[${profileMajorsIndex}].score`, undefined)}
                    />
                  </div>
                  <div className='input-frame '>
                    <JDBaseInput
                      name={`${name}[${profileMajorsIndex}].score`}
                      pattern={watch(`${name}[${profileMajorsIndex}].scoreType`) === ScoreType.PERCENTAGE ? NUMBER_PERCENTAGE_STR : NUMBER_GPA_STR}
                      type={watch(`${name}[${profileMajorsIndex}].scoreType`) === ScoreType.PERCENTAGE ? 'numberPercentage' : 'numberGPA'}
                      placeholder={watch(`${name}[${profileMajorsIndex}].scoreType`) === ScoreType.PERCENTAGE ? '백분위' : '평점'}
                      defaultValue={profileMajorsField.score}
                    />
                    <span className={watch(`${name}[${profileMajorsIndex}].scoreType`) === ScoreType.PERCENTAGE ? 'none' : ''}>/</span>
                    <JDBaseInput
                      className={watch(`${name}[${profileMajorsIndex}].scoreType`) === ScoreType.PERCENTAGE ? 'none' : ''}
                      name={`${name}[${profileMajorsIndex}].perfectScore`}
                      placeholder='만점'
                      pattern={watch(`${name}[${profileMajorsIndex}].scoreType`) === ScoreType.PERCENTAGE ? NUMBER_PERCENTAGE_STR : NUMBER_GPA_STR}
                      type={watch(`${name}[${profileMajorsIndex}].scoreType`) === ScoreType.PERCENTAGE ? 'numberPercentage' : 'numberGPA'}
                      defaultValue={profileMajorsField.perfectScore}
                    />
                  </div>
                </ScoreTypeFormValueFrame>
              </div>
            );
          return <></>;
        })
      }
      <FormValueMiddleFrame>
        <FormSquareCheckBoxFrame>
          <JDCheckInput
            type={JD_CHECK_INPUT_TYPE.CHECK_BOX}
            name={`${prefixName}.majorTypeMinor`}
            className='major-type-checkbox'
            label='부전공'
            defaultChecked={profileMajorsFields.findIndex((profileMajorsField) => profileMajorsField.majorType === MajorType.MINOR) !== -1}
            onChange={(checked: boolean) => {
              if (checked === true) {
                profileMajorsAppend({
                  majorCode: undefined,
                  majorName: undefined,
                  majorType: MajorType.MINOR,
                  perfectScore: undefined,
                  score: undefined,
                  scoreType: ScoreType.SCORE,
                });
              } else {
                const index = profileMajorsFields.findIndex((profileMajorsField) => profileMajorsField.majorType === MajorType.MINOR);
                if (index !== -1) profileMajorsRemove(index);
              }
            }}
          />
        </FormSquareCheckBoxFrame>
      </FormValueMiddleFrame>
      {
        profileMajorsFields.map((profileMajorsField, profileMajorsIndex) => {
          if (profileMajorsField.majorType === MajorType.MINOR)
            return (
              <div key={profileMajorsField.id}>
                <input aria-hidden ref={register()} name={`${name}[${profileMajorsIndex}].majorType`} defaultValue={profileMajorsField.majorType} />
                <FormValueMiddleFrame>
                  <JDSearchInput
                    title='부전공・학과를 검색해 주세요.'
                    defaultTextName={profileMajorsField.majorName}
                    defaultCodeName={profileMajorsField.majorCode}
                    textName={`${name}[${profileMajorsIndex}].majorName`}
                    codeName={`${name}[${profileMajorsIndex}].majorCode`}
                    placeholder='부전공・학과를 검색해 주세요.'
                    onSearch={(searchText: string) => {
                      if (searchText === '') setSearchMajor([]);
                      else {
                        debounce(async () => {
                          await profileEducation.searchMajorAliases(searchText);
                          const sortedMajorAliases = profileEducation.majorAliases.sort((a, b) => a.name.localeCompare(b.name));
                          setSearchMajor(sortedMajorAliases);
                        }, 800);
                      }
                    }}
                    searchList={searchMajor}
                  />
                </FormValueMiddleFrame>
                <ScoreTypeFormValueFrame>
                  <div className='btn-frame'>
                    <JDRadioInput
                      type={JD_RADIO_INPUT_TYPE.SQUARE_CHIP}
                      name={`${name}[${profileMajorsIndex}].scoreType`}
                      value={ScoreType.SCORE}
                      defaultValue={profileMajorsField.scoreType}
                      label='학점'
                      onChange={() => setValue(`${name}[${profileMajorsIndex}].score`, undefined)}
                    />
                    <JDRadioInput
                      type={JD_RADIO_INPUT_TYPE.SQUARE_CHIP}
                      name={`${name}[${profileMajorsIndex}].scoreType`}
                      value={ScoreType.PERCENTAGE}
                      defaultValue={profileMajorsField.scoreType}
                      label='백분위'
                      onChange={() => setValue(`${name}[${profileMajorsIndex}].score`, undefined)}
                    />
                  </div>
                  <div className='input-frame '>
                    <JDBaseInput
                      name={`${name}[${profileMajorsIndex}].score`}
                      pattern={watch(`${name}[${profileMajorsIndex}].scoreType`) === ScoreType.PERCENTAGE ? NUMBER_PERCENTAGE_STR : NUMBER_GPA_STR}
                      type={watch(`${name}[${profileMajorsIndex}].scoreType`) === ScoreType.PERCENTAGE ? 'numberPercentage' : 'numberGPA'}
                      placeholder={watch(`${name}[${profileMajorsIndex}].scoreType`) === ScoreType.PERCENTAGE ? '백분위' : '평점'}
                      defaultValue={profileMajorsField.score}
                    />
                    <span className={watch(`${name}[${profileMajorsIndex}].scoreType`) === ScoreType.PERCENTAGE ? 'none' : ''}>/</span>
                    <JDBaseInput
                      className={watch(`${name}[${profileMajorsIndex}].scoreType`) === ScoreType.PERCENTAGE ? 'none' : ''}
                      name={`${name}[${profileMajorsIndex}].perfectScore`}
                      placeholder='만점'
                      pattern={watch(`${name}[${profileMajorsIndex}].scoreType`) === ScoreType.PERCENTAGE ? NUMBER_PERCENTAGE_STR : NUMBER_GPA_STR}
                      type={watch(`${name}[${profileMajorsIndex}].scoreType`) === ScoreType.PERCENTAGE ? 'numberPercentage' : 'numberGPA'}
                      defaultValue={profileMajorsField.perfectScore}
                    />
                  </div>
                </ScoreTypeFormValueFrame>
              </div>
            );
          return <></>;
        })
      }
      <FormValueFrame>
        <FormSquareCheckBoxFrame>
          <JDCheckInput
            type={JD_CHECK_INPUT_TYPE.CHECK_BOX}
            name={`${prefixName}.majorTypeDouble`}
            className='major-type-checkbox'
            label='복수전공'
            defaultChecked={profileMajorsFields.findIndex((profileMajorsField) => profileMajorsField.majorType === MajorType.DOUBLE) !== -1}
            onChange={(checked: boolean) => {
              if (checked === true) {
                profileMajorsAppend({
                  majorCode: undefined,
                  majorName: undefined,
                  majorType: MajorType.DOUBLE,
                  perfectScore: undefined,
                  score: undefined,
                  scoreType: ScoreType.SCORE,
                });
              } else {
                const index = profileMajorsFields.findIndex((profileMajorsField) => profileMajorsField.majorType === MajorType.DOUBLE);
                if (index !== -1) profileMajorsRemove(index);
              }
            }}
          />
        </FormSquareCheckBoxFrame>
      </FormValueFrame>
      {
        profileMajorsFields.map((profileMajorsField, profileMajorsIndex) => {
          if (profileMajorsField.majorType === MajorType.DOUBLE)
            return (
              <div key={profileMajorsField.id}>
                <input aria-hidden ref={register()} name={`${name}[${profileMajorsIndex}].majorType`} defaultValue={profileMajorsField.majorType} />
                <FormValueMiddleFrame>
                  <JDSearchInput
                    title='복수전공・학과를 검색해 주세요.'
                    defaultTextName={profileMajorsField.majorName}
                    defaultCodeName={profileMajorsField.majorCode}
                    textName={`${name}[${profileMajorsIndex}].majorName`}
                    codeName={`${name}[${profileMajorsIndex}].majorCode`}
                    placeholder='복수전공・학과를 검색해 주세요.'
                    onSearch={(searchText: string) => {
                      if (searchText === '') setSearchMajor([]);
                      else {
                        debounce(async () => {
                          await profileEducation.searchMajorAliases(searchText);
                          const sortedMajorAliases = profileEducation.majorAliases.sort((a, b) => a.name.localeCompare(b.name));
                          setSearchMajor(sortedMajorAliases);
                        }, 800);
                      }
                    }}
                    searchList={searchMajor}
                  />
                </FormValueMiddleFrame>
                <ScoreTypeFormValueFrame>
                  <div className='btn-frame'>
                    <JDRadioInput
                      type={JD_RADIO_INPUT_TYPE.SQUARE_CHIP}
                      name={`${name}[${profileMajorsIndex}].scoreType`}
                      value={ScoreType.SCORE}
                      defaultValue={profileMajorsField.scoreType}
                      label='학점'
                      onChange={() => setValue(`${name}[${profileMajorsIndex}].score`, undefined)}
                    />
                    <JDRadioInput
                      type={JD_RADIO_INPUT_TYPE.SQUARE_CHIP}
                      name={`${name}[${profileMajorsIndex}].scoreType`}
                      value={ScoreType.PERCENTAGE}
                      defaultValue={profileMajorsField.scoreType}
                      label='백분위'
                      onChange={() => setValue(`${name}[${profileMajorsIndex}].score`, undefined)}
                    />
                  </div>
                  <div className='input-frame'>
                    <JDBaseInput
                      name={`${name}[${profileMajorsIndex}].score`}
                      pattern={watch(`${name}[${profileMajorsIndex}].scoreType`) === ScoreType.PERCENTAGE ? NUMBER_PERCENTAGE_STR : NUMBER_GPA_STR}
                      type={watch(`${name}[${profileMajorsIndex}].scoreType`) === ScoreType.PERCENTAGE ? 'numberPercentage' : 'numberGPA'}
                      placeholder={watch(`${name}[${profileMajorsIndex}].scoreType`) === ScoreType.PERCENTAGE ? '백분위' : '평점'}
                      defaultValue={profileMajorsField.score}
                    />
                    <span className={watch(`${name}[${profileMajorsIndex}].scoreType`) === ScoreType.PERCENTAGE ? 'none' : ''}>/</span>
                    <JDBaseInput
                      className={watch(`${name}[${profileMajorsIndex}].scoreType`) === ScoreType.PERCENTAGE ? 'none' : ''}
                      name={`${name}[${profileMajorsIndex}].perfectScore`}
                      placeholder='만점'
                      pattern={watch(`${name}[${profileMajorsIndex}].scoreType`) === ScoreType.PERCENTAGE ? NUMBER_PERCENTAGE_STR : NUMBER_GPA_STR}
                      type={watch(`${name}[${profileMajorsIndex}].scoreType`) === ScoreType.PERCENTAGE ? 'numberPercentage' : 'numberGPA'}
                      defaultValue={profileMajorsField.perfectScore}
                    />
                  </div>
                </ScoreTypeFormValueFrame>
              </div>
            );
          return <></>;
        })
      }
    </div>
  );
};

export default inject(injectStore.profileEducationModel)(observer(ProfileEducationMajor));
