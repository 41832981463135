import React, { FC, useRef } from 'react';
import classnames from 'classnames/bind';
import { RecruitType, RecruitUnionType } from '..';
import { IntervieweeVO } from '../../types';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

interface IListFilterProps {
  setActiveTab: (tab: RecruitType) => void;
  activeTab: RecruitType;
  userList: Record<RecruitUnionType, IntervieweeVO[]>;
}

const ListFilter: FC<IListFilterProps> = ({ setActiveTab, activeTab, userList }) => {
  const filterAreaRef = useRef<HTMLDivElement>(null);

  // 스크롤을 맨 위로 이동하는 함수
  const scrollToTop = () => {
    if (filterAreaRef.current) {
      filterAreaRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    window.scrollTo({ top: 480, behavior: 'smooth' });
  };

  return (
    <div className={cx('filterArea')} ref={filterAreaRef}>
      <a
        className={cx('option', { on: activeTab === RecruitType.Contents })}
        role='button'
        onClick={() => {
          setActiveTab(RecruitType.Contents);
          scrollToTop();
        }}
      >
        콘텐츠<span className={cx('count')}>{userList.Contents.length}</span>
      </a>
      <a
        className={cx('option', { on: activeTab === RecruitType.Performance })}
        role='button'
        onClick={() => {
          setActiveTab(RecruitType.Performance);
          scrollToTop();
        }}
      >
        퍼포먼스 · CRM · 전략<span className={cx('count')}>{userList.Performance.length}</span>
      </a>
      <a
        className={cx('option', { on: activeTab === RecruitType.Brand })}
        role='button'
        onClick={() => {
          setActiveTab(RecruitType.Brand);
          scrollToTop();
        }}
      >
        브랜드<span className={cx('count')}>{userList.Brand.length}</span>
      </a>
      <a
        className={cx('option', { on: activeTab === RecruitType.Pr })}
        role='button'
        onClick={() => {
          setActiveTab(RecruitType.Pr);
          scrollToTop();
        }}
      >
        홍보 · PR<span className={cx('count')}>{userList.Pr.length}</span>
      </a>
      <a
        className={cx('option', { on: activeTab === RecruitType.Md })}
        role='button'
        onClick={() => {
          setActiveTab(RecruitType.Md);
          scrollToTop();
        }}
      >
        커머스 · MD<span className={cx('count')}>{userList.Md.length}</span>
      </a>
    </div>
  );
};

export default ListFilter;
