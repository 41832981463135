/* eslint-disable react-hooks/exhaustive-deps */
import { ReactComponent as IconReset } from 'assets/_v2/_common/input/icon_reset.svg';
import { ReactComponent as IconArrowDown } from 'assets/_v2/_common/input/icon_small_arrow_down.svg';
import { StyledSelectModalFrame, StyledSmallSelector } from 'components/_v2/position/filter/PositionJobTitleFilter';
import JDSquareCheckBox from 'components/_v2/_common/input/JDSquareCheckBox';
import JDUnderBaseModal from 'components/_v2/_common/modals/JDUnderBaseModal';
import { StyledIconSVG } from 'consts/assets/icons/iconPages';
import RoutePaths from 'consts/RoutePaths';
import colors from '__designkit__/common/colors';
import ILoginPageProps from 'interfaces/props/ILoginPageProps';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import PositionListModel from 'models/_v2/PositionListModel';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { PositionFiltersProps } from '../PostionFilterTab';

const Frame = styled.div`

`;

const PositionMatchFilter: FC<PositionFiltersProps & ILoginPageProps> = ({ reloadPosition, filteredCount, login = new Login(), positionListModel = new PositionListModel() }) => {
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const useformed = useForm({ mode: 'all' });
  const { watch, reset } = useformed;
  const [currentSelectList, setCurrentSelectList] = useState<boolean>(false);
  const [saveSelectList, setSaveSelectList] = useState<boolean>(false);
  const history = useHistory();

  const onChangeCheckbox = async () => {
    const value = watch('matchingYn');
    positionListModel.searchFilters.matchingYn = value;
    reloadPosition();
    setCurrentSelectList(value);
  };

  const onClickReset = () => {
    reset({ matchingYn: false });
    setCurrentSelectList(false);
    positionListModel.searchFilters.matchingYn = false;
    reloadPosition();
  };

  const onClickFilter = () => {
    positionListModel.searchFilters.matchingYn = currentSelectList;
    reloadPosition();
    setSaveSelectList(currentSelectList);
    setIsSelectOpen(false);
  };

  useEffect(() => {
    if (positionListModel.searchFilters.matchingYn === false) setSaveSelectList(false);
    else setSaveSelectList(true);
  }, [positionListModel.searchFilters.matchingYn]);

  useEffect(() => {
    if (isSelectOpen) {
      const value = positionListModel.searchFilters.matchingYn!;
      reset({ matchingYn: value });
      setCurrentSelectList(value);
    }
  }, [isSelectOpen]);

  return (
    <Frame>
      <StyledSmallSelector
        count={saveSelectList ? 1 : 0}
        onClick={() => {
          if (login.userInfo === null) {
            history.push(RoutePaths.login);
            return;
          }
          setIsSelectOpen(true);
        }}
      >
        <span>추천 상태{saveSelectList ? ` +${1}` : ''}</span>
        <StyledIconSVG fill={colors.CG_70}><IconArrowDown /></StyledIconSVG>
      </StyledSmallSelector>
      <JDUnderBaseModal isOpen={isSelectOpen} onClickClose={() => setIsSelectOpen(false)}>
        <FormProvider {...useformed}>
          <StyledSelectModalFrame>
            <div className='title'>필터</div>
            <div className='content-frame'>
              <div className='group-frame'>
                <h1>추천 상태</h1>
                <div className='group-items'>
                  <JDSquareCheckBox
                    key='check-option-matchingYn'
                    name='matchingYn'
                    value
                    label={<span>추천 받음</span>}
                    onChange={onChangeCheckbox}
                  />
                </div>
              </div>
            </div>
            <div className='btn-frame'>
              <button className='btn-reset' onClick={onClickReset}><IconReset />필터 초기화</button>
              <button
                className='btn-search'
                onClick={onClickFilter}
              >{`검색(${filteredCount()})건`}
              </button>
            </div>
          </StyledSelectModalFrame>
        </FormProvider>
      </JDUnderBaseModal>
    </Frame>
  );
};

export default inject(injectStore.login, injectStore.positionListModel)(observer(PositionMatchFilter));
