import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import IconQuotation from 'assets/_v2/company/icon_quoation.png';
import ImgSample from 'assets/_v2/company/img_sample.png';
import { CompanyInformationTitle } from 'components/_v2/company/CompanyDetailInformation';
import { CompanyEmployeeTipTypeList, CompanyYearTypeText } from 'consts/_v2/company/CompanyDetailType';
import { inject, observer } from 'mobx-react';
import CompanyModel from 'models/_v2/CompanyModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
    padding:0 16px;
`;

const InnerFrame = styled.div`
  padding:64px 0 0 0;
`;
const TipContainer = styled.div`
    display: flex;
    flex-direction: column;
    .title-text{
        font:${Fonts.B1_Bold_P};
        color:${colors.JOBDA_BLACK}
    }
    .sub-text-frame{
      display: flex;
      align-items: center;
      .sub-text-job{
        font:${Fonts.B3_Medium};
        color:${colors.CG_70};
        white-space: pre;
      }
      .sub-text-year{
        font:${Fonts.B3_Medium};
        color:${colors.CG_70};
      }
    }
    
    .id-text{
        font:${Fonts.B4_Medium};
        color:${colors.CG_70};
    }
    .divider-24{
        width: 100%;
        height: 1px;
        background-color: ${colors.CG_40};
        margin:24px 0;
    }
`;

const ImageFrame = styled.div`
  width: 100%;
  height: 160px;
  padding:42px 32px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  flex-direction: column;
  position: relative;
  
  .img-blur{
    z-index: 2;
    background-image: url(${ImgSample});
    width: 100%;
    height: 160px;
    position: absolute;
    border-radius: 8px;
    background-size: cover; 
    background-repeat: no-repeat; 
    background-position: center; 
}
  .img-banner{
      z-index: 2;
      width: 100%;
      height: 160px;
      position: absolute;
      border-radius: 8px;
      background-size: cover; 
      background-repeat: no-repeat; 
      background-position: center; 
  }
  .dimmed{
        position: absolute;
        width: 100%;
        height: 100%;
        bottom:0;
        background-color: ${colors.JOBDA_BLACK};
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        z-index: 1;
        border-radius: 8px;
    }
  .icon-quotation{
    margin-bottom: 12px;
    width: 16px;
    height: 16px;
    color:${colors.WHITE_100};
    z-index: 3;
  }
  .cheer-text{
    font:${Fonts.B1_Bold};
    color:${colors.WHITE_100};
    z-index: 3;
  }
`;
const ScrollFrame = styled.div`
    height: 350px;
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
    position: relative;
    padding-right: 12px;
    ::-webkit-scrollbar{
      width:4px;
    }
    ::-webkit-scrollbar-thumb{
      border-radius: 0px;
      background-color: ${colors.CG_50};
    }
    ::-webkit-scrollbar-track{
      background-color: ${colors.WHITE_100};
    }
`;

interface ICompanyDetailHoneyTip{
    companyModel?:CompanyModel
}
const CompanyDetailHoneyTip:FC<ICompanyDetailHoneyTip> = ({ companyModel = new CompanyModel() }) => {
  const [backgroundImage, setBackgroundImage] = useState('');

  useEffect(() => {
    if (companyModel.companySn) {
      const modNum = companyModel.companySn % 12;
      setBackgroundImage(CompanyEmployeeTipTypeList[modNum].image);
    }
  }, [companyModel.companySn]);
  return (
    <Frame>
      {companyModel.companyEmployStories && companyModel.companyInformation
      && (
      <InnerFrame>
        <CompanyInformationTitle>현직자 이야기</CompanyInformationTitle>
        <SpacingBlock vertical size={24} />

        <ImageFrame>
          <div className='dimmed' />
          <img src={backgroundImage} className='img-banner' alt='img' />

          <img src={IconQuotation} className='icon-quotation' alt='icon' />
          <div className='cheer-text'>함께할 동료가 말해주는 우리 회사 이야기</div>
        </ImageFrame>
        <SpacingBlock vertical size={24} />
        <ScrollFrame>
          {companyModel.companyEmployStories && companyModel.companyEmployStories.stories.map((card, idx) => (
            <TipContainer key={`stories-${card.experience + idx}`}>
              <div className='title-text'>{card.experience}</div>
              <SpacingBlock vertical size={12} />
              <div className='sub-text-frame'>
                <div className='sub-text-job'>{card.jobGroupName}</div>
                <div className='sub-text-job'>{` - `}</div>
                <div className='sub-text-year'>{CompanyYearTypeText[card.yearType]}</div>
              </div>
              {idx !== (companyModel.companyEmployStories?.stories?.length || 0) - 1 && <div className='divider-24' />}
            </TipContainer>
          ))}
        </ScrollFrame>
      </InnerFrame>
      )}
    </Frame>
  );
};

export default inject(injectStore.companyModel)(observer(CompanyDetailHoneyTip));
