import URIs from 'consts/URIs';
import { AccCenterPositionType, IAccCenterApplyCount, IAccCenterApplyRecent, IAccCenterCompanyApplyRecent, IAccCenterJobPosting, IAccCenterPositionRs, IAccCenterPost, IAccCenterPostRs, IAccResult } from 'interfaces/_v2/acc/IAccGate';
import { action, observable } from 'mobx';
import request from 'utils/request';

export default class AccaGateModel {
    @observable applyCount:IAccCenterApplyCount | null = null;
    @observable applies:IAccCenterApplyRecent[] | null = null;
    @observable atoz:IAccCenterPostRs | null = null;
    @observable reviewPost:IAccCenterPost[] | null = null;
    @observable jobPosting:IAccCenterJobPosting[] | null = null;
    @observable recommend:IAccCenterPositionRs | null = null;
    @observable accResult:IAccResult | null = null;
    @observable maxApplyChance:number = 5;
    @observable companyApplies:IAccCenterCompanyApplyRecent[] | null = null;
    @action
    init = async () => {
      await this.loadAccCenterApplyCount();
      await this.loadAccCenterAtoZ();
      await this.loadAccCenterScreeningPassReviews();
    }

    @action
    loadAccCenterApplyCount = async () => {
      try {
        this.applyCount = (await request<IAccCenterApplyCount>({
          method: 'get',
          url: URIs.get_acc_center_apply_count,
        }));
      } catch (e) {
        console.error(e);
        throw e;
      }
    }

    @action
    loadAccCenterApplyRecent = async () => {
      try {
        this.applies = (await request<{applies:IAccCenterApplyRecent[]}>({
          method: 'get',
          url: URIs.get_acc_center_apply_recent_jobda,
        })).applies;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }

    @action
    loadAccCenterCompanyApplyRecent = async () => {
      try {
        this.companyApplies = (await request<{applies:IAccCenterCompanyApplyRecent[]}>({
          method: 'get',
          url: URIs.get_acc_center_apply_recent_company,
        })).applies;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }

    @action
    deleteAccCenterApplyRecent = async (sn:number) => {
      try {
        const res = (await request({
          method: 'delete',
          url: URIs.delete_acc_center_apply_recent(sn),
        }));
        return res;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }

    @action
    loadAccCenterAtoZ = async () => {
      try {
        this.atoz = (await request<IAccCenterPostRs>({
          method: 'get',
          url: URIs.get_acc_center_atoz,
          params: {
            page: 0,
            size: 100,
          },
        }));
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadAccCenterScreeningPassReviews = async () => {
      try {
        this.reviewPost = (await request<{posts:IAccCenterPost[]}>({
          method: 'get',
          url: URIs.get_acc_center_screening_pass_reviews,
          params: {
            page: 0,
            size: 15,
          },
        })).posts;
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadAccCenterPositionRecommend = async (type: AccCenterPositionType) => {
      try {
        this.recommend = (await request<IAccCenterPositionRs>({
          method: 'get',
          url: URIs.get_acc_center_positions_recommend,
          params: { type },
        }));
      } catch (e) {
        console.error(e);
        throw e;
      }
    }

    @action
    loadAccCenterJobPosting = async () => {
      try {
        this.jobPosting = (await request<{jobPostings:IAccCenterJobPosting[]}>({
          method: 'get',
          url: URIs.get_job_postings_acc,
          params: { pageSize: 24 },
        })).jobPostings;
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadMaxApplyChange = async () => {
      try {
        this.maxApplyChance = (await request<{maxApplyChance:number}>({
          method: 'get',
          url: URIs.get_acc_max_apply_chance,
        })).maxApplyChance;
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadAccResult = async () => {
      try {
        this.accResult = (await request<IAccResult>({
          method: 'get',
          url: URIs.get_acc_results,
        }));
      } catch (e) {
        console.error(e);
      }
    }
}
