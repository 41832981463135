import ChallengeDetailContent from 'components/_v2/challengeDetail/ChallengeDetailContent';
import ChallengeHeaderInfo from 'components/_v2/challengeDetail/ChallengeHeaderInfo';
import RoutePaths from 'consts/RoutePaths';
import IChallengeDetailProps from 'interfaces/_v2/challenge/IChallengeDetailProps';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import ChallengeDetailModel from 'models/_v2/ChallengeDetailModel';
import React, { FC, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';

const Frame = styled.div`
    padding: 16px 0 24px 0;
`;

const ChallengeDetail:FC<IChallengeDetailProps> = ({ challengeDetailModel = new ChallengeDetailModel() }) => {
  const { challengeSn } = useParams<{ challengeSn: string }>();
  const history = useHistory();
  useEffect(() => {
    const init = async () => {
      if (!Number.isNaN(Number(challengeSn))) {
        challengeDetailModel.loadChallengeDetail(Number(challengeSn));
        challengeDetailModel.loadChallengeDetailPositions(Number(challengeSn));
        challengeDetailModel.loadChallengeDetailCompanies(Number(challengeSn));
      } else {
        history.push(RoutePaths.error);
      }
    };
    init();
  }, [challengeSn]);

  return (
    <>
      <Frame>
        <ChallengeHeaderInfo />
      </Frame>

      <ChallengeDetailContent />
    </>
  );
};

export default inject(injectStore.challengeDetailModel)(observer(ChallengeDetail));
