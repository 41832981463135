import iconArrowGreen from 'assets/_v2/matchHome/icon_arrow_right_green.svg';
import iconArrowGrey from 'assets/_v2/matchHome/icon_arrow_right_grey.svg';
import iconTime from 'assets/_v2/_common/icon_time.svg';
import iconTimeGrey from 'assets/_v2/_common/icon_time_grey.svg';
import IconJobManage from 'assets/_v2/matchHome/job/icon_job_manage.svg';
import IconJobConstruction from 'assets/_v2/matchHome/job/icon_job_construction_eng.svg';
import IconJobCustomerSupport from 'assets/_v2/matchHome/job/icon_job_customer_support.svg';
import IconJobDesign from 'assets/_v2/matchHome/job/icon_job_design.svg';
import IconJobItService from 'assets/_v2/matchHome/job/icon_job_it_service.svg';
import IconJobMarketing from 'assets/_v2/matchHome/job/icon_job_marketing.svg';
import IconJobMedical from 'assets/_v2/matchHome/job/icon_job_medical.svg';
import IconJobRnd from 'assets/_v2/matchHome/job/icon_job_rnd.svg';
import IconJobSales from 'assets/_v2/matchHome/job/icon_job_sales.svg';
import IconJobProduct from 'assets/_v2/matchHome/job/icon_job_product_distribution.svg';
import IconJobInsurance from 'assets/_v2/matchHome/job/icon_job_insurance.svg';
import IconJobDeveloper from 'assets/_v2/matchHome/job/icon_job_developer.svg';
import IconJobManageW from 'assets/_v2/matchHome/job/icon_job_manage_white.svg';
import IconJobConstructionW from 'assets/_v2/matchHome/job/icon_job_construction_eng_white.svg';
import IconJobCustomerSupportW from 'assets/_v2/matchHome/job/icon_job_customer_support_white.svg';
import IconJobDesignW from 'assets/_v2/matchHome/job/icon_job_design_white.svg';
import IconJobItServiceW from 'assets/_v2/matchHome/job/icon_job_it_service_white.svg';
import IconJobMarketingW from 'assets/_v2/matchHome/job/icon_job_marketing_white.svg';
import IconJobMedicalW from 'assets/_v2/matchHome/job/icon_job_medical_white.svg';
import IconJobRndW from 'assets/_v2/matchHome/job/icon_job_rnd_white.svg';
import IconJobSalesW from 'assets/_v2/matchHome/job/icon_job_sales_white.svg';
import IconJobProductW from 'assets/_v2/matchHome/job/icon_job_product_distribution_white.svg';
import IconJobInsuranceW from 'assets/_v2/matchHome/job/icon_job_insurance_white.svg';
import IconJobDeveloperW from 'assets/_v2/matchHome/job/icon_job_developer_white.svg';

import OnOffIcon, { OnOffImgHTMLAttributes } from 'consts/assets/icons/iconModule';
import React, { FC } from 'react';

export const IconArrowRight:FC<OnOffImgHTMLAttributes> = (props) => (
  <OnOffIcon {...props} alt='icon-arrow-right' active={props.active} activeSrc={iconArrowGreen} unActiveSrc={iconArrowGrey} />
);

export const IconTimeOnOff:FC<OnOffImgHTMLAttributes> = (props) => (
  <OnOffIcon {...props} alt='icon-arrow-right' active={props.active} activeSrc={iconTime} unActiveSrc={iconTimeGrey} />
);

export const IconJobManageOnOff:FC<OnOffImgHTMLAttributes> = (props) => (
  <OnOffIcon {...props} alt='icon-job-manage' active={props.active} activeSrc={IconJobManageW} unActiveSrc={IconJobManage} />
);

export const IconJobMarketingOnOff:FC<OnOffImgHTMLAttributes> = (props) => (
  <OnOffIcon {...props} alt='icon-job-manage' active={props.active} activeSrc={IconJobMarketingW} unActiveSrc={IconJobMarketing} />
);

export const IconJobSalesOnOff:FC<OnOffImgHTMLAttributes> = (props) => (
  <OnOffIcon {...props} alt='icon-job-manage' active={props.active} activeSrc={IconJobSalesW} unActiveSrc={IconJobSales} />
);

export const IconJobProductOnOff:FC<OnOffImgHTMLAttributes> = (props) => (
  <OnOffIcon {...props} alt='icon-job-manage' active={props.active} activeSrc={IconJobProductW} unActiveSrc={IconJobProduct} />
);

export const IconJobConstructionOnOff:FC<OnOffImgHTMLAttributes> = (props) => (
  <OnOffIcon {...props} alt='icon-job-manage' active={props.active} activeSrc={IconJobConstructionW} unActiveSrc={IconJobConstruction} />
);

export const IconJobRndOnOff:FC<OnOffImgHTMLAttributes> = (props) => (
  <OnOffIcon {...props} alt='icon-job-manage' active={props.active} activeSrc={IconJobRndW} unActiveSrc={IconJobRnd} />
);

export const IconJobItServiceOnOff:FC<OnOffImgHTMLAttributes> = (props) => (
  <OnOffIcon {...props} alt='icon-job-manage' active={props.active} activeSrc={IconJobItServiceW} unActiveSrc={IconJobItService} />
);

export const IconJobDesignOnOff:FC<OnOffImgHTMLAttributes> = (props) => (
  <OnOffIcon {...props} alt='icon-job-manage' active={props.active} activeSrc={IconJobDesignW} unActiveSrc={IconJobDesign} />
);

export const IconJobInsuranceOnOff:FC<OnOffImgHTMLAttributes> = (props) => (
  <OnOffIcon {...props} alt='icon-job-manage' active={props.active} activeSrc={IconJobInsuranceW} unActiveSrc={IconJobInsurance} />
);

export const IconJobCustomerSupportOnOff:FC<OnOffImgHTMLAttributes> = (props) => (
  <OnOffIcon {...props} alt='icon-job-manage' active={props.active} activeSrc={IconJobCustomerSupportW} unActiveSrc={IconJobCustomerSupport} />
);

export const IconJobMedicalOnOff:FC<OnOffImgHTMLAttributes> = (props) => (
  <OnOffIcon {...props} alt='icon-job-manage' active={props.active} activeSrc={IconJobMedicalW} unActiveSrc={IconJobMedical} />
);

export const IconJobDeveloperOnOff:FC<OnOffImgHTMLAttributes> = (props) => (
  <OnOffIcon {...props} alt='icon-job-manage' active={props.active} activeSrc={IconJobDeveloperW} unActiveSrc={IconJobDeveloper} />
);
