import ProfileAPI from 'api/profileAPI';
import URIs from 'consts/URIs';
import { initialValueOfAdditional, initialValueOfEssential } from 'consts/_v2/profile/profile';
import { IProfileAttachFileRq, IProfileAttachFileRs } from 'interfaces/_v2/profile/IProfileAttachFileRqRs';
import { IProfileDetails } from 'interfaces/_v2/profile/IProfileDetail';
import { IProfileExperienceEditRq, IProfileExperienceRq, IProfileExperienceRs } from 'interfaces/_v2/profile/IProfileExperienceRqRs';
import { IProfilePreferentialRqRs } from 'interfaces/_v2/profile/IProfilePreferentialRqRs';
import { IProfilePrizeRqRs } from 'interfaces/_v2/profile/IProfilePrizeRqRs';
import { IProfileSnbCheckAdditional, IProfileSnbCheckEssential } from 'interfaces/_v2/profile/IProfileSnbCheck';
import { action, observable } from 'mobx';
import store from 'models/store';
import { request } from 'utils/request';

// 관리 Profile 전체 Model
export default class ProfileModel {
  @observable preferential: IProfilePreferentialRqRs | null = null;
  @observable attachedFile: IProfileAttachFileRs | null = null;
  @observable prize: IProfilePrizeRqRs | null = null;
  @observable isDirty: boolean = false;
  @observable experience : IProfileExperienceRs | null = null;
  @observable experienceEdit : IProfileExperienceEditRq | null = null;
  @observable isWrongExperience : number[] | null = null;
  @observable isPrizeDirty: boolean = false;
  @observable snbCheckEssential : IProfileSnbCheckEssential = initialValueOfEssential;
  @observable snbCheckAdditional : IProfileSnbCheckAdditional = initialValueOfAdditional;
  @observable scrollPosition : number = 0
  @observable sourcePage: string | null = null;
  @observable previewProfile: IProfileDetails | null = null;
  @observable resumedProfileDetail: IProfileDetails | null = null;
  constructor() {
    this.initSnbEssential();
    this.initSnbAdditional();
  }

  @action
  setScrollPosition = (scrollPosition: number) => {
    this.scrollPosition = scrollPosition;
  }

  @action
  setIsDirty = (isDirty: boolean) => {
    this.isDirty = isDirty;
  }

  @action
  setIsWrongExperience = (isWrongExperience:number[]) => {
    this.isWrongExperience = isWrongExperience;
  }

  @action
  militaryReset = () => {
    if (this.preferential?.military)
      this.preferential.military = null;
  }

  @action
  disabilityReset = () => {
    if (this.preferential?.disability)
      this.preferential.disability = null;
  }

  @action
  veteranReset = () => {
    if (this.preferential?.veteran) {
      this.preferential.veteran.status = null;
      this.preferential.veteran.reason = '';
    }
  }

  @action
  initExperience = () => {
    this.experienceEdit = {
      activities: [
        {
          type: '',
          place: '',
          startDate: '',
          endDate: '',
          description: '',
        },
      ],
      foreignExperiences: [
        {
          countryCode: 0,
          countryName: '',
          startDate: '',
          endDate: '',
          description: '',
        },
      ],
      experiences: [
        { description: '' },
      ],
    };
  }

  @action
  initSnbEssential = () => {
    this.snbCheckEssential = initialValueOfEssential;
  }

  @action
  initSnbAdditional = () => {
    this.snbCheckAdditional = initialValueOfAdditional;
  }

  @action
  checkEssential = async () => {
    try {
      await store.profileBasicInfoModel.loadBaseInfo();
      await store.profileEducationModel.loadEducations();
    } catch (e) {
      console.error(e);
    }
  }

  @action
  checkAdditional = async () => {
    try {
      store.profileCareersModel.setCareers((await ProfileAPI.getCareers()).profileCareers);
      await store.profileProjectModel.loadProjects();
      await store.profileSkillModel.loadSkill();
      await this.loadExperience();
      await this.loadPrize();
      await this.loadPreferential();
      await this.loadAttachedFile();
    } catch (e) {
      console.error(e);
    }
  }

  @action
  loadPreferential = async () => {
    try {
      const res = await request<IProfilePreferentialRqRs>({
        method: 'get',
        url: URIs.get_profiles_preferential,
      });
      this.setPreferential(res);
      if (res.military || res.disability || res.veteran) this.snbCheckAdditional.militaryInfo.completeYn = true;
      else this.snbCheckAdditional.militaryInfo.completeYn = false;
    } catch (e) {
      console.error(e);
    }
  }

  @action
  setPreferential = (preferential: IProfilePreferentialRqRs) => {
    this.preferential = preferential;
  }

  @action
  savePreferential = async (data: Partial<IProfilePreferentialRqRs>) => {
    try {
      await request({
        method: 'put',
        url: URIs.put_profiles_preferential,
        data,
      });
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  @action
  savePrize = async (prize:IProfilePrizeRqRs) => {
    try {
      await request({
        method: 'put',
        url: URIs.put_profiles_prize,
        data: prize,
      });
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  @action
  loadPrize = async () => {
    try {
      const res = await request<IProfilePrizeRqRs>({
        method: 'get',
        url: URIs.get_profiles_prize,
      });
      this.setPrize(res);
      if (res.prizes.length > 0) this.snbCheckAdditional.prizeInfo.completeYn = true;
      else this.snbCheckAdditional.prizeInfo.completeYn = false;
    } catch (e) {
      console.error(e);
    }
  }

  @action
  setPrize = (prize:IProfilePrizeRqRs) => {
    this.prize = prize;
  }

  @action
  loadExperience = async () => {
    try {
      const res = await request<IProfileExperienceRs>({
        method: 'get',
        url: URIs.get_profiles_experience,
      });
      this.setExperience(res);
      if (res.activitiesAndForeignExperiences.length > 0 || res.etcExperiences.length > 0) this.snbCheckAdditional.experienceInfo.completeYn = true;
      else this.snbCheckAdditional.experienceInfo.completeYn = false;
    } catch (e) {
      console.error(e);
    }
  }

  @action
  setExperience = (experience: IProfileExperienceRs) => {
    this.experience = experience;
  }

  @action
  saveExperience = async (data:Partial<IProfileExperienceRq>) => {
    try {
      await request({
        method: 'put',
        url: URIs.put_profiles_experience,
        data,
      });
    } catch (e) {
      console.error(e);
    }
  }

  @action
  loadAttachedFile = async () => {
    try {
      const res = await request<IProfileAttachFileRs>({
        method: 'get',
        url: URIs.get_profiles_file,
      });
      this.setAttachedFile(res);
      if (res.careerAttachFiles.length > 0 || res.portfolioAttachFiles.length > 0 || res.referenceUrls.length > 0) this.snbCheckAdditional.fileInfo.completeYn = true;
      else this.snbCheckAdditional.fileInfo.completeYn = false;
    } catch (e) {
      console.error(e);
    }
  }

  @action
  setAttachedFile = (attachedFile: IProfileAttachFileRs) => {
    this.attachedFile = attachedFile;
  }

  @action
  saveAttachedFile = async (data: IProfileAttachFileRq) => {
    try {
      await request({
        method: 'put',
        url: URIs.put_profiles_file,
        data,
      });
      return true;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  @action
  loadProfileDetail = async () => {
    try {
      this.previewProfile = await request({
        method: 'get',
        url: URIs.get_profiles_details,
      });
    } catch (e) {
      console.error(e);
    }
  }

  @action
  getProfileResumeSn = async (positionSn: number) => {
    try {
      const res = await request <{profileResumeSn: number}>({
        method: 'get',
        url: URIs.get_profile_resume,
        params: { positionSn },
      });
      return res;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  @action
  getProfileResumeDetail = async (profileResumeSn: number) => {
    try {
      this.resumedProfileDetail = await request <IProfileDetails>({
        method: 'get',
        url: URIs.get_profile_resume_details(profileResumeSn),
      });
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  @action
  getMatchOfferProfileResumeDetail = async (positionSn: number) => {
    try {
      this.resumedProfileDetail = await request <IProfileDetails>({
        method: 'get',
        url: URIs.get_match_position_profile(positionSn),
      });
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  @action
  saveProfileConfirmAll = async () => {
    try {
      await request({
        method: 'post',
        url: URIs.post_profiles_audit_confirm_all,
      });
      return true;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}
