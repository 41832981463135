import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import FormRoundChip from 'components/_v2/_common/form/FormRoundChip';
import ProfileItemTitle from 'components/_v2/profile/ProfileItemTitle';
import Fonts from '__designkit__/common/fonts';
import Colors from '__designkit__/common/colors';
import { LocationCode } from 'consts/CompanyLocationType';
import IComponentProps from 'interfaces/props/IComponentProps';
import useProfileConditions from 'query/profiles/useProfileConditionsQuery';
import { ProfileAuditItemType } from 'consts/_v2/profile/ProfileType';
import { useInsightResumeAutoSave } from 'query/resume/useResumeQuery';
import ConditionEditModal from './ConditionEditModal';

const Frame = styled.div`
  .title {
    font: ${Fonts.B2_Bold};
    color: ${Colors.JOBDA_BLACK};
    letter-spacing: -0.01em;
    margin-bottom: 16px;
  }

  .job-group-frame{
    margin-top: 4px;
    margin-bottom: 22px;
  }

  .list-frame {
      display: flex;
      flex-wrap: wrap;
    }

  .salary-frame {
    display: flex;
    align-items: center;

    .salary-box {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      padding: 10px 16px;
      background: ${Colors.CG_30};
      border: 1px solid ${Colors.CG_40};
      border-radius: 8px;
      margin-right: 10px;
      
    }

    span {
      font: ${Fonts.B1_Medium};
      color: ${Colors.JOBDA_BLACK};
    }
  }
`;
const SalaryText = styled.div<{edit?:boolean}>`
        font: ${Fonts.B1_Bold};
      color: ${Colors.JOBDA_BLACK};
      background-color: ${(props) => (props.edit ? `${Colors.Y_100}` : `${Colors.CG_30}`)};
      padding:0 2px;
`;
const FormFrame = styled.div`
  margin-bottom: 26px;
  .working-preference{
    font:${Fonts.B2_Medium_P};
    color:${Colors.CG_80}; 
    white-space: pre-wrap;
  }
`;

const Divider = styled.div`
    width: 100%;
    height: 1px;
    background: ${Colors.CG_40};
    margin-bottom: 32px;
`;

const Title = styled.div`
  font: ${Fonts.H5_Bold};
  line-height: 22px;
  color: ${Colors.JOBDA_BLACK};
  margin-bottom: 16px;
`;

interface IProfileConditionViewProps extends IComponentProps {
  suggestionModalCode: number;
}

const ProfileConditionView:FC<IProfileConditionViewProps> = ({ suggestionModalCode = 0 }) => {
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const { data: profileConditionsData } = useProfileConditions(true);
  const { data: insightResumeAutoSaveData, refetch: refetchAutoSave } = useInsightResumeAutoSave(true);

  useEffect(() => {
    if (suggestionModalCode === 2) setIsEditOpen(true);
  }, [suggestionModalCode]);

  const checkAudit = (caseType:ProfileAuditItemType) => {
    switch (caseType) {
      case ProfileAuditItemType.PROFILE_JOB_TITLE:
        return profileConditionsData?.auditItems?.includes(ProfileAuditItemType.PROFILE_JOB_TITLE);
      case ProfileAuditItemType.PROFILE_LOCATION:
        return profileConditionsData?.auditItems?.includes(ProfileAuditItemType.PROFILE_LOCATION);
      case ProfileAuditItemType.MATCHING_CONDITION_SALARY:
        return profileConditionsData?.auditItems?.includes(ProfileAuditItemType.MATCHING_CONDITION_SALARY);
      default:
        return false;
    }
  };
  return (
    <Frame>
      <ProfileItemTitle required icon='heart' text='선호정보' subText='선호하는 직군 · 직무, 근무 지역, 연봉, 복지를 알려주세요.' onClickEditBtn={() => setIsEditOpen(true)} />
      {profileConditionsData && profileConditionsData.jobGroups && profileConditionsData.jobGroups.length > 0 && (
        <div>
          <Title>희망 직군 · 직무</Title>
          {profileConditionsData.jobGroups.map(({ jobGroupCode, jobGroupName, jobTitleNames }) => (
            <div key={jobGroupCode} className='job-group-frame'>
              <p className='title'>{jobGroupName}</p>
              <div className='list-frame'>
                { jobTitleNames.map((item) => (
                  <FormRoundChip edit={checkAudit(ProfileAuditItemType.PROFILE_JOB_TITLE)} key={`${jobGroupCode}-${item}`}><span>{item}</span></FormRoundChip>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
      { profileConditionsData?.locations && profileConditionsData.locations.length > 0 && (
        <>
          <Divider />
          <FormFrame>
            <Title>희망 근무지역</Title>
            <div className='list-frame'>
              { profileConditionsData.locations.length === 17
                ? <FormRoundChip edit={checkAudit(ProfileAuditItemType.PROFILE_LOCATION)} key={`condition-all`}><span>전체 지역</span></FormRoundChip>
                : profileConditionsData.locations.map((location) => (
                  <FormRoundChip edit={checkAudit(ProfileAuditItemType.PROFILE_LOCATION)} key={`condition-${location}`}><span>{LocationCode(Number(location))}</span></FormRoundChip>
                ))}
            </div>
          </FormFrame>
        </>
      )}
      { profileConditionsData?.salary && (
      <>
        <Divider />
        <FormFrame>
          <Title>희망 연봉수준</Title>
          <div className='salary-frame'>
            <div className='salary-box'>
              <SalaryText edit={checkAudit(ProfileAuditItemType.MATCHING_CONDITION_SALARY)}>
                {profileConditionsData?.salary.toLocaleString()}
              </SalaryText>
            </div>
            <span>만원 이상</span>
          </div>
        </FormFrame>
      </>
      )}
      { profileConditionsData?.welfare && profileConditionsData.welfare.length > 0 && (
      <>
        <Divider />
        <FormFrame>
          <Title>희망 복지혜택</Title>
          <div className='list-frame'>
            { profileConditionsData?.welfare.map((welfare) => (
              <FormRoundChip key={`condition-${welfare.code}`}>{welfare.name}</FormRoundChip>
            ))}
          </div>
        </FormFrame>
      </>
      )}
      { profileConditionsData?.workingPreference && profileConditionsData.workingPreference.length > 0 && (
      <>
        <Divider />
        <FormFrame>
          <Title>기타 희망사항</Title>
          <div className='working-preference'>{profileConditionsData?.workingPreference}</div>
        </FormFrame>
      </>
      )}
      <ConditionEditModal
        isOpen={isEditOpen}
        onClose={() => {
          refetchAutoSave();
          setIsEditOpen(false);
        }}
      />
    </Frame>
  );
};

export default ProfileConditionView;
