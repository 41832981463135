import ImgReport1 from 'assets/_v2/company/cardIcon/icon_person_ability_white.svg';
import ImgReport2 from 'assets/_v2/company/cardIcon/icon_bubble_emoji_smile_white.svg';
import ImgReport3 from 'assets/_v2/company/cardIcon/icon_crown_white.svg';
import ImgReport4 from 'assets/_v2/company/cardIcon/icon_description_write_white.svg';
import ImgReport5 from 'assets/_v2/company/cardIcon/icon_laurel_wreath_star_white.svg';
import ImgReport6 from 'assets/_v2/company/cardIcon/icon_chart_search_white.svg';
import ImgReport7 from 'assets/_v2/company/cardIcon/icon_matching_2_white.svg';
import ImgReport8 from 'assets/_v2/company/cardIcon/icon_star_person_white.svg';
import ImgReport9 from 'assets/_v2/company/cardIcon/icon_page_checklist_white.svg';
import ImgReport10 from 'assets/_v2/company/cardIcon/icon_chart_pyramid_white.svg';
import ImgReport11 from 'assets/_v2/company/cardIcon/icon_test_white.svg';
import ImgReport12 from 'assets/_v2/company/cardIcon/icon_diamond_white.svg';
import ImgReport13 from 'assets/_v2/company/cardIcon/icon_target_blue.svg';
import ImgReport14 from 'assets/_v2/company/cardIcon/icon_chat_white.svg';
import ImgReport15 from 'assets/_v2/company/cardIcon/icon_compass_white.svg';
import ImgReport16 from 'assets/_v2/company/cardIcon/icon_equity_white.svg';
import ImgReport17 from 'assets/_v2/company/cardIcon/icon_diversity_2_white.svg';
import ImgReport18 from 'assets/_v2/company/cardIcon/icon_clap_white.svg';
import ImgReport19 from 'assets/_v2/company/cardIcon/icon_diversity_white.svg';
import ImgReport20 from 'assets/_v2/company/cardIcon/icon_building_2_white.svg';

const textToImageMap: Record<string, string> = {
  '높은 자신감': ImgReport1,
  '높은 긍정성': ImgReport2,
  '강한 인정욕구': ImgReport3,
  '강한 학습의지': ImgReport4,
  '강한 성공의지': ImgReport5,
  '빠른 학습력': ImgReport6,
  '높은 계획력': ImgReport7,
  '뛰어난 기억력': ImgReport8,
  // eslint-disable-next-line quote-props
  '멀티태스킹': ImgReport9,
  '높은 집중력': ImgReport10,
  '꾸준한 실천': ImgReport11,
  '세심한 성격': ImgReport12,
  '높은 의도이해': ImgReport13,
  '뛰어난 설득력': ImgReport14,
  '목표 추구성': ImgReport15,
  '객관적인 시각': ImgReport16,
  '반성하는 자세': ImgReport17,
  '겸손한 태도': ImgReport18,
  '규범의 준수': ImgReport19,
  '빠른 적응력': ImgReport20,
};

export default function mapTextToImage(text:string) {
  return textToImageMap[text] || ImgReport1; // 예시로 ImgReport1을 기본 값으로 설정
}
