import JDBaseModal from 'components/_v2/_common/modals/JDBaseModal';
import { JDButtonTwoChoiceFrame, JDModalBasicFrame, JDModalButton, JDModalButtonGhost, JDModalDescription, JDModalTitle } from 'components/_v2/_common/modals/JDModalComponents';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import React, { FC } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
  z-index: 10006;
  ${JDModalTitle} {
    color: ${colors.JOBDA_BLACK};
    margin-bottom: 8px;
  }

  ${JDButtonTwoChoiceFrame} {
    margin-top: 24px;
  }

  ${JDModalButtonGhost} {
    font: ${Fonts.B1_Bold};
    margin-right: 16px;
  }

  ${JDModalButton} {
    font: ${Fonts.B1_Bold};
  }
`;

interface ISimpleConfirmModalProps {
  isOpen: boolean;
  title: string;
  subTitle: string;
  confirmBtnText: string;
  cancelBtnText: string;
  onClickConfirm: () => void;
  onClickCancel: () => void;
}

const SimpleConfirmModal:FC<ISimpleConfirmModalProps> = ({ isOpen, title, subTitle, confirmBtnText, cancelBtnText, onClickConfirm, onClickCancel }) => (
  <JDBaseModal dimmed isOpen={isOpen} onClickClose={onClickCancel} isDimClickClose>
    <Frame>
      <JDModalBasicFrame>
        <JDModalTitle>{ title }</JDModalTitle>
        <JDModalDescription>{ subTitle }</JDModalDescription>
        <JDButtonTwoChoiceFrame>
          <JDModalButtonGhost onClick={onClickCancel}>{ cancelBtnText }</JDModalButtonGhost>
          <JDModalButton onClick={onClickConfirm}>{ confirmBtnText }</JDModalButton>
        </JDButtonTwoChoiceFrame>
      </JDModalBasicFrame>
    </Frame>
  </JDBaseModal>
);

export default SimpleConfirmModal;
