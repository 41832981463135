import { useQuery } from 'react-query';
import { RemoteAdsRepo } from 'repository/ads/AdsRepo';

export const keys = ['ads', 'main-banners', 'rolling'];

const adsRepo = new RemoteAdsRepo();

const fetchAdsMainBanner = async () => {
  const data = await adsRepo.fetchMainBannerTextRolling();
  return data;
};

export default function useAdsMainBanner(isEnabled: boolean) {
  return useQuery([...keys], fetchAdsMainBanner, { enabled: isEnabled });
}
