import { ErrorMessage } from '@hookform/error-message';
import colors from '__designkit__/common/colors';
import { numberPointValidator, numberValidator } from 'consts/ValidationRule';
import Fonts from '__designkit__/common/fonts';
import { JDBaseInputMixin } from 'consts/_v2/_common/style/mixins';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { forwardRef, ReactElement, useEffect, useRef, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import styled from 'styled-components/macro';

const Frame = styled.div`
  ${JDBaseInputMixin()};

  >div{
    padding-right:80px;
  }

  .timer{
      display:block;
      position:absolute;
      color:${colors.ERROR};
      font: ${Fonts.B2_Medium_P}
      top:30%;
      right:95px;
    }
  
  .action-button{
    width: 68px;
    height:32px;
    position:absolute;
    margin-left:auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: ${colors.JOBDA_BLACK};
    color:${colors.WHITE_100};
    font: ${Fonts.B2_Bold};
    top:20%;
    right:16px;

    :disabled{
      background: ${colors.CG_50};
    }
  }
`;

interface IJDTimerInput extends IComponentProps {
  name: string;
  defaultMessage?: string
  type?: string;
  disabled?: boolean;
  btnDisabled:boolean;
  defaultValue?: string;
  className?: string;
  caption?:ReactElement;
  count?: number;
  isRunning: boolean;
  onTimerEnd?: () => void;
  onClickInnerBtn: () => void;
}

const JDTimerInput = forwardRef((props: IJDTimerInput, fieldRef) => {
  const { name, defaultMessage, type, onClear, disabled, btnDisabled, defaultValue, className, caption, onClickInnerBtn, count, isRunning, onTimerEnd, ...rest } = props;
  const useFormed = useFormContext();
  const { control, errors, watch, setValue, trigger } = useFormed;
  const { field: { ref: BaseRef }, meta } = useController({ name, control, defaultValue });
  const [inputValue, setInputValue] = useState<string>('');
  const countRef = useRef(count || 180);
  const [counterStr, setCounterStr] = useState<string>('');
  const timerRef = useRef<any>();

  useEffect(() => {
    setCounterStr(new Date((countRef.current) * 1000).toISOString().substr(14, 5));
    const timer = setInterval(() => {
      if (isRunning && countRef.current > 0) {
        countRef.current -= 1;
        setCounterStr(new Date((countRef.current) * 1000).toISOString().substr(14, 5));
      } else {
        clearInterval(timer);
        if (onTimerEnd && countRef.current === 0) {
          onTimerEnd();
          countRef.current = count || 180; // 다시 인증했을 때 초기화 해주기.
        }
      }
    }, 1000);
    timerRef.current = timer;
    return () => {
      clearInterval(timer);
    };
  }, [isRunning]);

  useEffect(() => {
    if (!isRunning)clearInterval(timerRef.current);
  }, [isRunning]);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (type === 'tel') {
      if (numberValidator(e.currentTarget.value)) {
        setInputValue(e.currentTarget.value);
      } else {
        e.currentTarget.value = inputValue;
      }
    }

    if (type === 'numberPoint') {
      if (numberPointValidator(e.currentTarget.value)) {
        setInputValue(e.currentTarget.value);
      } else {
        e.currentTarget.value = inputValue;
      }
    }

    setValue(name, e.currentTarget.value);
    trigger(name);
    if (props.onChange) {
      props.onChange();
    }
  };

  return (
    <Frame className={`jd-input ${className || ''} ${!meta.invalid}`}>
      <div>
        <input
          name={name}
          ref={function (innerRef) {
            if (fieldRef !== null) (fieldRef as any).current = innerRef;
            if (BaseRef !== undefined) BaseRef.current = innerRef;
          }}
          className={`${watch(name) ? 'value' : ''}`}
          onChange={onChangeHandler}
          disabled={!!disabled}
          defaultValue={defaultValue}
          autoComplete='off'
          {...rest}
        />
        <span className='timer'>{counterStr}</span>
        <button
          type='button'
          className='action-button'
          disabled={btnDisabled}
          onClick={() => {
            onClickInnerBtn && onClickInnerBtn();
          }}
        >
          확인
        </button>
      </div>
      {(defaultMessage && !meta.invalid) && <h4 className='message'>{defaultMessage}</h4>}
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <h4 className='message false'>{message}</h4>}
      />
      {caption}
    </Frame>
  );
});

export default JDTimerInput;
