import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import JDCheckInput, { JD_CHECK_INPUT_TYPE } from 'components/_v2/_common/input/JDCheckInput';
import { CompanyLocation } from 'consts/CompanyLocationType';
import { ConditionRegisterType } from 'consts/_v2/profile/ProfileType';
import useQueryParams from 'hooks/useQueryParams';
import Context from 'models/Context';
import { IPutConditionLocationsRq } from 'interfaces/_v2/profile/IConditionRqRs';
import IComponentProps from 'interfaces/props/IComponentProps';
import useProfileConditions, { useFetchSaveProfileConditionsLocations } from 'query/profiles/useProfileConditionsQuery';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import IconLocation from 'assets/_v2/preferred/icon_pin_red.svg';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';

const TabFrame = styled.div<{isResumeModal?:boolean}>`
  width: 100%;
  background-color: ${Colors.WHITE_100};
  height: calc(100% - 78px);
  animation: slide .5s ease-out forwards;

  @keyframes slide {
    0% {
    transform: translateY(10%);
    opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
  }

  .step-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 24px 0;
    text-align: center;
    font: ${Fonts.H4_Bold};
    color: ${Colors.JOBDA_BLACK};

    .title-icon {
      margin-bottom: 12px;
      width: 40px;
      height: 40px;
    }
  }
`;

const Frame = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  row-gap: 12px;
  column-gap: 8px;
  
  .jd-input{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
  }

  .check-box {
    label {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      .label{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 48px;
        border-radius: 4px;
        border: 1px solid ${Colors.CG_50};
        font: ${Fonts.B1_Medium};
      } 

      & > input[type='checkbox']:checked + .label {
          font: ${Fonts.B1_Bold};
          background-color: ${Colors.JOBDA_WHITE};
        }
    }
  }
`;

interface ILocationTabProps extends IComponentProps {
  context?: Context;
  hidden?: boolean;
  locationData?: number[];
  setConditionRegisterType: (type: ConditionRegisterType) => void;
  isResumeModal?: boolean;
}

export interface ChildLocationHandles {
  handleSaveLocation: () => void;
}

const LocationTab = forwardRef<ChildLocationHandles, ILocationTabProps>(({ isResumeModal, context = new Context(), hidden, locationData, setConditionRegisterType }, ref) => {
  const { sourcePage } = useQueryParams();
  const { refetch } = useProfileConditions(true);
  const saveSuccess = async () => {
    await refetch();
    setConditionRegisterType(ConditionRegisterType.LIST);
  };
  const { mutate: saveProfileLocationConditions } = useFetchSaveProfileConditionsLocations(saveSuccess);
  const defaultValues:IPutConditionLocationsRq = { locations: [], sourcePage: '' };
  const formMethods = useForm({
    mode: 'onSubmit',
    defaultValues,
  });

  const { register, setValue, watch, getValues, formState, reset } = formMethods;
  const handleLocationAllClick = (checked: boolean) => {
    if (!checked) {
      Object.values(CompanyLocation).forEach((location, index) => {
        setValue(`location[${index}]`, false);
      });
    } else {
      Object.values(CompanyLocation).forEach((location, index) => {
        setValue(`location[${index}]`, location.code);
      });
    }
  };

  useEffect(() => {
    if (!locationData) return;

    const formValues: Record<string, number | boolean> = {};
    Object.values(CompanyLocation).forEach((location, index) => {
      const isLocationSelected = locationData.includes(Number(location.code));

      formValues[`location[${index}]`] = isLocationSelected ? Number(location.code) : false;
    });
    reset({ ...formValues });
  }, [locationData, reset]);

  useEffect(() => {
    if (hidden) return; // hidden이 true이면 아무 작업도 하지 않음

    const temp = watch('location', []) as string[];
    temp
      .filter((locationCode: string | number) => locationCode)
      .map((locationCode: string | number) => Number(locationCode));

    setValue('locations', temp);

    const locations = String(getValues('locations'))
      .split(',')
      .map((location) => Number(location.trim()))
      .filter((code) => !Number.isNaN(code) && Number.isFinite(code));

    const locationsArray = watch('location', []) as boolean[];

    const allSelected = !locationsArray.some((location) => location === false);
    if (getValues('location-TOTAL') !== allSelected) {
      setValue('location-TOTAL', allSelected);
    }

    const locationRq = {
      locations: locations.length > 0 ? locations : [],
      sourcePage: sourcePage || null,
    };

    if (locationRq.locations.length > 0) {
      context.isPreferredClicked = true;
    } else {
      context.isPreferredClicked = false;
    }
  }, [watch, setValue, getValues]);

  useEffect(() => {
    // 페이지가 로드될 때 상단으로 스크롤
    window.scrollTo(0, 0);

    const formValues: Record<string, number | boolean> = {};
    Object.values(CompanyLocation).forEach((location, index) => {
      formValues[`location[${index}]`] = false; // 모든 값을 초기값으로 설정
    });
    reset({ ...formValues });
  }, [reset]);

  useEffect(() => {
    // 페이지가 로드될 때 상단으로 스크롤
    window.scrollTo(0, 0);

    const formValues: Record<string, number | boolean> = {};
    Object.values(CompanyLocation).forEach((location, index) => {
      formValues[`location[${index}]`] = false; // 모든 값을 초기값으로 설정
    });
    reset({ ...formValues });
  }, [reset]);

  useImperativeHandle(ref, () => ({
    async handleSaveLocation() {
      const temp = watch('location', []) as string[];
      temp
        .filter((locationCode: string | number) => locationCode)
        .map((locationCode: string | number) => Number(locationCode));

      setValue('locations', temp);

      const locations = String(getValues('locations'))
        .split(',')
        .map((location) => Number(location.trim()))
        .filter((code) => !Number.isNaN(code) && Number.isFinite(code));

      const locationRq = {
        locations: locations.length > 0 ? locations : [],
        sourcePage: sourcePage || null,
      };

      if (locationRq.locations.length > 0) {
        saveProfileLocationConditions(locationRq);
      }
    },
  }));

  if (hidden) return null;

  return (
    <TabFrame isResumeModal={isResumeModal}>
      <FormProvider {...formMethods}>
        <input name='locations' ref={register} aria-hidden />
        <div className='step-title'>
          <img className='title-icon' alt='위치' src={IconLocation} />
          희망하는 근무지역을 알려주세요.
        </div>
        <Frame>
          <JDCheckInput
            key='check-option-TOTAL'
            type={JD_CHECK_INPUT_TYPE.SQUARE_CHIP}
            name='location-TOTAL'
            value
            className='check-box'
            label={<div className='check-box-label'>전체 지역</div>}
            onChange={(checked: boolean) => handleLocationAllClick(checked)}
          />
          {Object.values(CompanyLocation).filter((location) => location.code !== '10000')
            .map((location, index) => (
              <JDCheckInput
                key={`check-option-${location.code}`}
                type={JD_CHECK_INPUT_TYPE.SQUARE_CHIP}
                name={`location[${index}]`}
                value={location.code}
                className='check-box'
                label={<div className='check-box-label'>{location.name}</div>}
              />
            ))}
        </Frame>
      </FormProvider>
    </TabFrame>
  );
});

export default inject(injectStore.context)(observer(LocationTab));
