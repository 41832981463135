import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';
import JDBaseModal, { IJDBaseModal } from 'components/_v2/_common/modals/JDBaseModal';
import { JDModalBasicFrame, JDModalDescription, JDModalFullButton, JDModalTitle } from 'components/_v2/_common/modals/JDModalComponents';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';

const Frame = styled.div`
  ${JDModalDescription} {
    margin-top: 8px;
    margin-bottom: 12px;
  }

  .descriptionsTag{
    margin-top: 8px;
    margin-bottom: 12px;
    font: ${Fonts.B1_Medium};
    color: ${Colors.CG_70};
    text-align: center;
    white-space: pre-line;

    a{
      text-decoration: underline;
    }

    button{
      text-decoration: underline;
    }
  }
`;
const ButtonFrame = styled.div`
    display: flex;
    justify-content: center;
    gap:8px;
    margin-top: 20px;
    .btn-left{
        width: 100%;
        padding: 14px 22px;
        background-color: ${Colors.WHITE_100};
        color:${Colors.JOBDA_BLACK};
        font:${Fonts.B2_Bold};
        border: 1px solid ${Colors.JOBDA_BLACK};
        border-radius: 4px;
    }
    .btn-right{
        width: 100%;
        padding: 14px 22px;
        background-color: ${Colors.JOBDA_BLACK};
        color:${Colors.WHITE_100};
        font:${Fonts.B2_Bold};
        border: 1px solid ${Colors.JOBDA_BLACK};
        border-radius: 4px;
    }
`;

const JDDeleteModal: FC<IJDBaseModal & {isButton?:boolean, dimmed?:boolean, title?: string, descriptions?: string, descriptionsTag?: ReactElement, btnTextLeft?: string, btnTextRight?:string, handleClickLeft?: () => void, handleClickRight?: () => void, }> = (({ isButton = true, dimmed = false, isOpen, handleClickLeft, handleClickRight, btnTextLeft = '확인', btnTextRight, onClickClose, title, descriptions, descriptionsTag, isBackContentClickable, isDimClickClose }) => (
  <JDBaseModal dimmed={dimmed} isOpen={isOpen} onClickClose={onClickClose} isBackContentClickable={isBackContentClickable} isDimClickClose={isDimClickClose}>
    <Frame>
      <JDModalBasicFrame>
        { title && <JDModalTitle>{title}</JDModalTitle> }
        { descriptions && <JDModalDescription>{descriptions}</JDModalDescription> }
        { descriptionsTag && descriptionsTag }
        {
          isButton
          && (
            <ButtonFrame>
              <button
                className='btn-left'
                onClick={() => {
                  if (handleClickLeft) handleClickLeft();
                  if (onClickClose) onClickClose();
                }}
              >{btnTextLeft}
              </button>
              <button
                className='btn-right'
                onClick={() => {
                  if (handleClickRight) handleClickRight();
                  if (onClickClose) onClickClose();
                }}
              >{btnTextRight}
              </button>
            </ButtonFrame>
          )
        }

      </JDModalBasicFrame>
    </Frame>
  </JDBaseModal>
));
export default JDDeleteModal;
