import URIs from 'consts/URIs';
import { action, observable } from 'mobx';
import request from 'utils/request';
import { IPaccApplyStatusRs, IPhsContentsRs, IPhsSkillDto, IPhsSkillRs, IPhsSkillSaveRq, PhsPositionsRs } from '../../interfaces/rqrs/IPhsRqRs';

const SEARCH_INIT_PAGE = 0;
const SEARCH_INIT_SIZE = 8;
export default class PaccGateModel {
    @observable paccMaxChance:number = 5;
    @observable pstMaxChance:number = 5;
    @observable paccChanceCount:number = 0;
    @observable pstChanceCount:number = 0;
    @observable phsPositions:PhsPositionsRs | null = null;
    @observable selectedJobTitle:number = -1;
    @observable selectedKeyword:number = -1;
    @observable selectedKeywordName:string = '';
    @observable contentsCard:IPhsContentsRs | null = null;
    @observable centerSkill:IPhsSkillRs | null = null;
    @observable centerSkillChip:IPhsSkillDto[] = [];
    @observable phsSkillPositions:PhsPositionsRs | null = null;
    @observable isDirty:boolean = false;

    @action
    loadPhsCenterSkillPosition = async (skillCode:number) => {
      try {
        this.phsSkillPositions = await request<PhsPositionsRs>({
          method: 'get',
          url: URIs.get_phs_center_skill_position,
          params: {
            page: SEARCH_INIT_PAGE,
            size: SEARCH_INIT_SIZE,
            skillCode,
          },
        });
      } catch (e) {
        console.error(e);
      }
    }

    @action
    savePhsCenterSill = async (data:IPhsSkillSaveRq) => {
      try {
        await request({
          method: 'put',
          url: URIs.put_phs_center_skill,
          data,
        });
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadPhsCenterSkill = async () => {
      try {
        this.centerSkill = await request<IPhsSkillRs>({
          method: 'get',
          url: URIs.get_phs_center_skill,
        });
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadPhsCenterContents = async () => {
      try {
        this.contentsCard = await request<IPhsContentsRs>({
          method: 'get',
          url: URIs.get_phs_center_contents,
          page: SEARCH_INIT_PAGE,
          size: SEARCH_INIT_SIZE,
        });
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadPhsCenterPosition = async (jobTitleCode?:number) => {
      try {
        this.phsPositions = await request<PhsPositionsRs>({
          method: 'get',
          url: URIs.get_phs_center_position,
          params: {
            page: SEARCH_INIT_PAGE,
            size: SEARCH_INIT_SIZE,
            jobTitleCode,
          },
        });
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadPstMaxChance = async () => {
      try {
        this.pstMaxChance = (await request<{maxApplyChance:number}>({
          method: 'get',
          url: URIs.get_phs_pst_apply_max_apply_chance,
        })).maxApplyChance;
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadPaccMaxChance = async () => {
      try {
        this.paccMaxChance = (await request<{maxApplyChance:number}>({
          method: 'get',
          url: URIs.get_phs_pacc_apply_max_apply_chance,
        })).maxApplyChance;
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadPstApplyStatus = async () => {
      try {
        const result = (await request<IPaccApplyStatusRs>({
          method: 'get',
          url: URIs.get_phs_pst_apply_status,
        }));
        this.pstChanceCount = result.chanceCount;
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadPaccApplyStatus = async () => {
      try {
        const result = (await request<IPaccApplyStatusRs>({
          method: 'get',
          url: URIs.get_phs_pacc_apply_status,
        }));
        this.paccChanceCount = result.chanceCount;
      } catch (e) {
        console.error(e);
      }
    }
}
