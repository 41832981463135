import React from 'react';
import JDALink from 'components/JDALink';
import RoutePaths from 'consts/RoutePaths';

import classnames from 'classnames/bind';
import styles from './floatingBar.module.scss';

const cx = classnames.bind(styles);

const FloatingBar = () => (
  // 하단 플로팅 바
  <div className={cx('floatingBar')}>
    <div className={cx('floatingInner')}>
      <JDALink
        to={RoutePaths.acca_test}
        className={cx('floatingBtn', 'acca_intro_testGo')}
      >
        역량검사 바로가기
      </JDALink>
    </div>
  </div>
);

export default FloatingBar;
