import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { StatusType, StatusTypeText } from 'consts/_v2/calendar/CalendarFilterType';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import ICalendarProps from 'interfaces/_v2/calendar/ICalendarProps';
import CalendarModel from 'models/_v2/CalendarModel';

const Frame = styled.div`
    display: flex;
    top: 206px;
    right: 16px;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    width: 120px;
    padding: 22px 20px;
    background-color: ${colors.WHITE_100};
    border: 1px solid ${colors.CG_50};
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    z-index: 10001;
  
    &[aria-hidden=true] {
        visibility: hidden;
    }

    .text {
        font: ${Fonts.B2_Medium};
        color: ${colors.CG_70};  
    }
    .selected-text{
      font: ${Fonts.B2_Bold};
      color:${colors.JOBDA_BLACK};
    }
    ul {
        li:not(:last-child) {
            margin-bottom: 20px;
        }
    }
    
`;

interface IJobPostingStatusTypeMoreListProps extends ICalendarProps {
    isOpen: boolean;
    onClickClose: () => void;
}

const JobPostingStatusTypeMoreList: FC<IJobPostingStatusTypeMoreListProps> = ({ isOpen, onClickClose, calendarModel = new CalendarModel() }) => {
  const wrapperRef = useRef<any>(null);
  const [visibleOpen, setVisibleOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      setVisibleOpen(true);
    }
  }, [isOpen]);

  const useOutsideClicker = (ref:any) => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setVisibleOpen(false);
        onClickClose();
      }
    }
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  useOutsideClicker(wrapperRef);

  const handleStatusType = (type: any) => {
    calendarModel.statusFilter = type;
    onClickClose();
  };

  return (
    <>
      {visibleOpen && (
      <Frame ref={wrapperRef} aria-hidden={!isOpen}>
        <ul>
          {
            (Object.keys(StatusType) as Array<keyof typeof StatusType>).map((type) => (
              type === calendarModel.statusFilter
                ? <li role='presentation' onClick={() => handleStatusType(type)} className='selected-text'>{StatusTypeText[type]}</li>
                : <li role='presentation' onClick={() => handleStatusType(type)} className='text'>{StatusTypeText[type]}</li>
            ))
          }
        </ul>
      </Frame>
      )}
    </>
  );
};

export default inject(injectStore.calendarModel)(observer(JobPostingStatusTypeMoreList));
