import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';
import LottieFile from 'utils/LottieFile';
import { v4 as uuidV4 } from 'uuid';

const Frame = styled.div<{darkMode?:boolean}>`
  width: 308px;
  height:200px;
  border-radius: 8px;
  background-color:${(props) => (props.darkMode ? `${Colors.CG_90}` : `${Colors.CG_30}}`)};
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  

  .sub-header-frame{
    display: flex;
    justify-content: space-between;
    .sub-header-text{
      font:${Fonts.B3_Medium};
      color:${(props) => (props.darkMode ? `${Colors.CG_60}` : `${Colors.CG_80}`)}
    }
  }

  h3{
    margin-top:4px;
   font: ${Fonts.B3_Medium};
   color:${(props) => (props.darkMode ? `${Colors.JOBDA_WHITE}` : `${Colors.CG_80}`)}
  };  
  .only-pc-info{
    color:${(props) => (props.darkMode ? `${Colors.JOBDA_WHITE}` : `${Colors.CG_60}`)}
  }

  h2{
    margin-top:6px;
    font: ${Fonts.B2_Medium};
    em{
      font: ${Fonts.B2_Bold};
    }
    color:${(props) => (props.darkMode ? `${Colors.JOBDA_WHITE}` : `${Colors.CG_90}`)}
  }

  h1{
    margin-top:4px;
    font: ${Fonts.H3_Bold};
    color:${(props) => (props.darkMode ? `${Colors.JOBDA_WHITE}` : `${Colors.JOBDA_BLACK}`)}
  };
  
  
  .info-frame{
    display: flex;
    position:relative;
    justify-content: space-between;
    >h3:not(:first){
      margin-top:4px;
    }

  }

  .spinner{
    width:96px;
    height:96px;
    bottom:-4px;
    right:0;
    position:absolute;
  }
`;

interface IAccaGateServiceCard{
  subTitle:string;
  onlyPc?:boolean;
  title:string;
  restCount?:number;
  infoString1:string;
  infoString2:string;
  infoString3:string;
  onClick?:()=>void;
  icon?:ReactElement;
  lottie?: { className: string; animationData: any };
  darkMode?:boolean;
}

const AccaGateServiceCard:FC<IAccaGateServiceCard> = (props) => (
  <Frame onClick={props.onClick} darkMode={props.darkMode}>
    <div className='header-frame'>
      <div className='sub-header-frame'>
        <div className='sub-header-text'>{props.subTitle}</div>
        {props.onlyPc && <h3 className='only-pc-info'>*PC에서만 가능</h3>}
      </div>
      <h1>{props.title}</h1>
      {props.restCount !== undefined && (
      <h2>
        이번달 잔여 횟수 : <em>{props.restCount}회</em>
      </h2>
      )}
    </div>
    <div className='info-frame'>
      <div>
        <h3>{props.infoString1}</h3>
        <h3>{props.infoString2}</h3>
        <h3>{props.infoString3}</h3>
      </div>
      {props.icon && props.icon}
      {props.lottie && <LottieFile className={props.lottie.className} animationData={props.lottie.animationData} />}
    </div>
  </Frame>
);
export default AccaGateServiceCard;
