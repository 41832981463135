import IconBasket from 'assets/icon/icon_basket_16_Regular.svg';
import IconFile from 'assets/icon/icon_file_24_Regular.svg';
import colors from '__designkit__/common/colors';
import { fonts } from '__designkit__/common/fonts';
import URIs from 'consts/URIs';
import IComponentProps from 'interfaces/props/IComponentProps';
import { IAttachFile } from 'interfaces/_v2/profile/IProfileEducation';
import React, { FC, useRef } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import styled from 'styled-components/macro';
import FileUtil from 'utils/FileUtil';
import request from 'utils/request';

// 50MB
const LIMIT_FILE_SIZE: number = 52428800;

const Frame = styled.div`
  /* margin-top:8px;
  margin-bottom:8px; */
  width:100%;
  .action-btn{
    display: flex;
    height: 56px;
    width: 100%;
    background:${colors.CG_30};
    border-radius: 4px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    >span{
      margin-left:16px;
      ${fonts.NOTO_15_400};
      color:${colors.CG_60};
    }
    >div{
      border-radius: 0px 4px 4px 0px;
      background:${colors.G_100};
      width:56px;
      height:100%;
      display:flex;
      justify-content: center;
      align-items:center;
    }
  }

  input[type="file"] {
      position: absolute;
      width: 0;
      height: 0;
      padding: 0;
      overflow: hidden;
      border: 0;
  }

  h3{
    margin-top:4px;
    margin-left:16px;
    ${fonts.NOTO_11_400};
    color:${colors.CG_70};
  }
  
`;

const FileItem = styled.div < { size?: number } >`
    cursor: pointer;
    background: transparent;
    margin-top:4px;
    margin-left:16px;
    display:flex;
    align-items: center;

    .download-btn {
      margin-right: 4px;
      background: transparent;
      border: none;
      text-align: left;
      ${fonts.NOTO_11_400};
      color:${colors.B_100};
      text-decoration-line: underline;
      /* width: ${(props: any) => props.size > 0 && `${props.size * 5 + 10}px`}; */
      max-width: 214px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      word-wrap: normal;
  }
   .clear-btn{
      background: transparent;
      width: 14px;
      height: 14px;
    }
`;

interface IJDAInnerButtonInput extends IComponentProps {
  name: string;
  placeholder?: string;
  type?: string[];
}

const JDAFileUpload: FC<IJDAInnerButtonInput> = (props: IJDAInnerButtonInput) => {
  const { name, placeholder, type, ...rest } = props;
  const useFormed = useFormContext();
  const { control, register, watch } = useFormed;
  const fileRef = useRef<HTMLInputElement>();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const uploadFile = async (file: File) => {
    try {
      const data = new FormData();
      data.append('authYn', 'false');
      data.append('file', file);
      const res = await request<IAttachFile>({
        method: 'post',
        url: URIs.post_file_upload,
        data,
      });
      return res;
    } catch (e) {
      return null;
    }
  };

  const downloadFile = async (fileName: string, fileUid: string) => {
    try {
      const res = await request<ArrayBuffer>({
        method: 'get',
        url: URIs.get_file_download(fileUid),
        responseType: 'arraybuffer',
      });
      FileUtil.fileDownload(fileName, res);
      return res;
    } catch (e) {
      return null;
    }
  };

  const checkFileValidation = (file: File) => {
    if (file.size > LIMIT_FILE_SIZE) {
      alert(`파일 사이즈가 초과했습니다.`);
      return false;
    }
    if (type) {
      const checkType = type.some((fileType: string) => {
        if (file.type === fileType) {
          return true;
        }
      });
      if (!checkType) {
        // type에 맞게 커스텀 가능.
        alert(`파일 확장자는 PDF, PPT만 가능합니다.`);
        return false;
      }
    }
    return true;
  };

  return (
    <Frame>
      <div className='action-btn' role='button' onClick={() => fileRef.current?.click()}>
        <span>{placeholder || '첨부파일을 추가해 주세요.'}</span>
        <div>
          <img src={IconFile} alt='파일' />
        </div>
      </div>
      <input
        id={`file-input-${name}`}
        name={name}
        ref={(innerRef) => {
          if (fileRef !== undefined) (fileRef as any).current = innerRef;
        }}
        //* capture 추가시 카메라창 화면으로 바로 전환됨
        // capture='camera'
        className={`${watch(name) ? 'value' : ''}`}
        type='file'
        onChange={async (e) => {
          if (e.target.files?.item(0)!) {
            if (checkFileValidation(e.target.files?.item(0)!)) {
              const res = await uploadFile(e.target.files?.item(0)!);
              append({ fileName: res?.fileName, fileUid: res?.fileUid });
            }
          }
        }}
        {...rest}
      />
      {fields.length === 0 && <h3>PDF, PPT 파일(50MB 이하)</h3>}
      {fields.map((field, index) => (
        <FileItem
          size={String(watch(`${name}[${index}].fileName`)).length * 1.5 || 0}
          key={field.id}
        >
          <button
            className='download-btn'
            onClick={() => downloadFile(field.fileName, field.fileUid)}
          >
            {field.fileName}
          </button>
          <button
            className='clear-btn'
            onClick={() => {
              // 서버 삭제 기능 미지원
              remove(index);
            }}
          >
            <img
              src={IconBasket}
              alt='삭제'
            />
          </button>
          <input
            aria-hidden
            name={`${name}[${index}].fileName`}
            ref={register()}
            defaultValue={field.fileName}
          />
          <input
            aria-hidden
            name={`${name}[${index}].fileUid`}
            ref={register()}
            defaultValue={field.fileUid}
          />
        </FileItem>
      ))}
    </Frame>
  );
};

export default JDAFileUpload;
