import { useEffect } from 'react';

// 웹 브라우저 뒤로가기 기능 조작 hook (APP)
const useGoBack = (handleClose: () => void) => {

  useEffect(() => {
    const preventGoBack = () => {
      window.history.pushState(null, '', document.location.href);
      handleClose();
    };
    window.history.pushState(null, '', document.location.href);
    window.addEventListener('popstate', preventGoBack);

    return () => {
      window.removeEventListener('popstate', preventGoBack);
    };
  }, []);

  return {};
};

export default useGoBack;
