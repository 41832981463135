import { useEffect, useState } from 'react';

const useActiveTabFlag = () => {
  const [initFlag, setInitFlag] = useState(true);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') setInitFlag(true);
      if (document.visibilityState === 'hidden') setInitFlag(false);
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, []);

  return initFlag;
};

export default useActiveTabFlag;
