import React from 'react';
import classnames from 'classnames/bind';
import styles from './accaStory.module.scss';

const cx = classnames.bind(styles);

interface AccaStoryProps {
  fadeIn: boolean;
}

const AccaStory = ({ fadeIn }: AccaStoryProps) => {
  return (
    // 역량검사 스토리
    <div className={cx('storyInner')}>
      <div className={cx('coverBg')} />
      <div className={cx('storyText')}>
        <span className={cx('storyTitle', 'mainTitle', { on: fadeIn })}>
          <span className={cx('mainSmall')}>JOBDA는 모든 구직자가</span>
          <br />
          역량만으로 공정하게
          <br />
          취업하는 세상을
          <br />
          꿈꾸고 있어요.
        </span>
        <span className={cx('storyMent', { on: fadeIn })}>
          당신을 완벽히 보여줄 수 없었던
          <br />
          취업 과정의 틀을 깨고
        </span>
        <span className={cx('storyMent', 'fadeLast', { on: fadeIn })}>
          당신의 숨은 가치인
          <br />
          ‘역량’을, ‘사람’을 봅니다.
        </span>
      </div>
    </div>
  );
};

export default AccaStory;
