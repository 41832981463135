import JDBaseModal, { IJDBaseModal } from 'components/_v2/_common/modals/JDBaseModal';
import { JDButtonTwoChoiceFrame, JDModalBasicFrame, JDModalButton, JDModalButtonGhost, JDModalDescription, JDModalTitle } from 'components/_v2/_common/modals/JDModalComponents';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import React, { FC } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
  z-index: 10006;
  ${JDModalTitle} {
    color: ${colors.JOBDA_BLACK};
    margin-bottom: 8px;
  }

  ${JDButtonTwoChoiceFrame} {
    margin-top: 24px;
  }

  ${JDModalButtonGhost} {
    font: ${Fonts.B1_Bold};
    margin-right: 16px;
  }

  ${JDModalButton} {
    font: ${Fonts.B1_Bold};
  }
`;

const PaccGateDirtyModal:FC<IJDBaseModal> = ({ isOpen, onClickClose, onSuccess }) => (
  <JDBaseModal dimmed isOpen={isOpen} onClickClose={onClickClose} isDimClickClose>
    <Frame>
      <JDModalBasicFrame>
        <JDModalTitle>페이지를 닫으시겠어요?</JDModalTitle>
        <JDModalDescription>저장되지 않은 정보는 사라집니다.</JDModalDescription>
        <JDButtonTwoChoiceFrame>
          <JDModalButtonGhost onClick={onClickClose}>취소</JDModalButtonGhost>
          <JDModalButton onClick={onSuccess}>닫기</JDModalButton>
        </JDButtonTwoChoiceFrame>
      </JDModalBasicFrame>
    </Frame>
  </JDBaseModal>
);

export default PaccGateDirtyModal;
