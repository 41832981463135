import DisableScroll from 'components/common/DisableScroll';
import Portal from 'components/common/Portal';
import GlobalSearchInput from 'components/_v2/search/GlobalSearchInput';
import { IconArrowBack, IconClose, IconLogo } from 'consts/assets/icons/iconPages';
import RoutePaths from 'consts/RoutePaths';
import colors from '__designkit__/common/colors';
import { dimmer, lineClampBox } from 'consts/style/mixins';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import GlobalSearchModel from 'models/_v2/GlobalSearchModel';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import Fonts from '__designkit__/common/fonts';
import { Link } from 'react-router-dom';
import Icon from '__designkit__/icon/Icon';

const GlobalKeywordFrame = styled.div`
  .keywordList {
    display: flex;
    gap: 12px;
    overflow-x: scroll;

    ::-webkit-scrollbar {
          display: none;
      }
    ::-webkit-scrollbar-thumb {
      background: transparent;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }
  
    &:first-child {
      padding-left: 16px;
    }

    &:last-child {
      padding-right: 16px;
    }
    
    .keywordLink {
      flex: none;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 0 16px;
      width: max-content;
      height: 48px;
      background-color: ${colors.CG_30};
      border-radius: 4px;
      font: ${Fonts.B1_Medium};
      color: ${colors.CG_80};
    }
  }
`;
interface IGlobalKeyword{
  onClickClose: () => void;
}
const GlobalKeyword: FC<IGlobalKeyword> = ({ onClickClose }) => {
  return (
    <GlobalKeywordFrame>
      <div className='keywordList'>
        <Link onClick={onClickClose} className='keywordLink' to={RoutePaths.match_apply}>
          <Icon size={24} name='page-blue' />
          매칭 현황
        </Link>
        <Link onClick={onClickClose} className='keywordLink' to={RoutePaths.acca_test}>
          <Icon size={24} name='acca' />
          역량검사
        </Link>
        <Link onClick={onClickClose} className='keywordLink' to={RoutePaths.calendar}>
          <Icon size={24} name='calender-fill' />
          채용 캘린더
        </Link>
        <Link onClick={onClickClose} className='keywordLink' to={RoutePaths.mypage_partnership}>
          <Icon size={24} name='handshake' />
          제휴 서비스 신청
        </Link>
        <Link onClick={onClickClose} className='keywordLink' to={RoutePaths.info}>
          <Icon size={24} name='book-green' />
          취업 콘텐츠
        </Link>
        <Link onClick={onClickClose} className='keywordLink' to={RoutePaths.event}>
          <Icon size={24} name='gift-red' />
          이벤트
        </Link>
      </div>
    </GlobalKeywordFrame>
  );
};

export default inject(injectStore.globalSearchModel)(observer(GlobalKeyword));
