import React from 'react';
import RoutePaths from 'consts/RoutePaths';
import { Link, useLocation } from 'react-router-dom';
import MatchApplyHome from 'components/_v2/matchApply/MatchApplyHome';
import MatchApplyStatus from 'components/_v2/matchApply/matchApplyStatus/MatchApplyStatus';
import styled from 'styled-components';
import Fonts from '__designkit__/common/fonts';
import colors from '__designkit__/common/colors';
import shadows from '__designkit__/common/shadows';

const LinkNaviTab = styled.nav`
    display: flex;
    align-items: center;
    gap: 24px;
    padding: 16px 16px 0 16px;
    ${shadows.headers};
  
    .a-link {
        position:relative;
        width:fit-content;
        font: ${Fonts.B2_Medium};
        color: ${colors.CG_60};
        padding-bottom: 16px;
        
        &[aria-current=true] {
            font: ${Fonts.B2_Bold};
            color:${colors.JOBDA_BLACK};
        }

        &[aria-current=true]:before {
          display:block;
          position:absolute;
          content:'';
          bottom: 0;
          left:0;
          width:100%;
          height:2px;
          background:${colors.JOBDA_BLACK};
        }
    }


`;

const MatchApplyTab = () => {
  const { pathname } = useLocation();

  return (
    <>
      <LinkNaviTab>
        <Link className='a-link' aria-current={pathname === RoutePaths.match_apply} to={RoutePaths.match_apply}>잡다매칭</Link>
        <Link className='a-link' aria-current={pathname === RoutePaths.match_apply_status} to={RoutePaths.match_apply_status}>매칭현황</Link>
      </LinkNaviTab>
      {
        pathname === RoutePaths.match_apply && <MatchApplyHome />
      }
      {
        pathname === RoutePaths.match_apply_status && <MatchApplyStatus />
      }
    </>
  );
};

export default MatchApplyTab;
