/* eslint-disable react/no-unescaped-entities */
import IconJoyTypeA from 'assets/img/ai/gate/icon_joy_type_a.png';
import IconJoyTypeB from 'assets/img/ai/gate/icon_joy_type_b.png';
import IconJoyTypeC from 'assets/img/ai/gate/icon_joy_type_c.png';
import ImgHeaderBg from 'assets/img/ai/gate/img_header_bg.png';
import AiGateCard from 'components/aiGate/AiGateCard';
import LazyImage from 'components/common/LazyImage';
import UnderlineSplash from 'components/UnderlineSplash';
import RoutePaths from 'consts/RoutePaths';
import colors from '__designkit__/common/colors';
import { fonts, fontStyle, lineHeights } from '__designkit__/common/fonts';
import { absoluteCenterCenter } from 'consts/style/mixins';
import useAnchorScroll from 'hooks/useAnchorScroll';
import React from 'react';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components/macro';

const Frame = styled.div`
  height:100%;
  padding-left:16px;
  padding-right:16px;
`;

const StyledHeaderFrame = styled.div`
  padding-top:48px;
  padding-bottom:28px;
  width: 100%;
  position: relative;

  >div{
    ${absoluteCenterCenter()}
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    ${fonts.NOTO_24_700};
    color:${colors.JOBDA_BLACK};
    ${lineHeights.LINEHEIGHT_1_50};
    white-space: nowrap;
    em{
      ${fonts.WEMAKEP_24_700};
      color: ${colors.c5EC7C3};
    }

    h3{
      margin-top:16px;
      text-align: center;
      ${fontStyle.CAPTION1_MEDIUM_P};
      color:${colors.CG_70};
    }
  }
  
`;

const StyledCardFrame = styled.div`
  >*{
    margin-bottom:24px;
  }
  padding-bottom: 48px;
`;

const AiGate: React.FC = () => {
  const history = useHistory();
  const { hash } = useLocation();
  useAnchorScroll(hash);

  return (
    <Frame>
      <StyledHeaderFrame>
        <div>
          (구)역량검사 준비는<br />
          <UnderlineSplash className='splash' height={30} color={colors.cFDF69E} enable>여기에서 끝!</UnderlineSplash>
          <h3>*역량센터 서비스를 이용하실 분들은 <br />
            상단 메뉴의 '역량센터'를 이용해 주세요!
          </h3>
        </div>
        <LazyImage className='img-bg' src={ImgHeaderBg} alt='역량검사 게이트' />
      </StyledHeaderFrame>
      <StyledCardFrame>
        <AiGateCard
          type='typeB'
          img={IconJoyTypeB}
          subTitle='역량검사의 모든것'
          title={<h1><em>역량검사</em>&nbsp;비법</h1>}
          btnMsg='자세히 알아보기'
          onClickBtn={() => window.open('https://deluxe-poppyseed-309.notion.site/005a55c758a84a11b54f50e5b079051b', '_blank')}
        >
          <div><span>✓</span><div><em>공식개발사가 말하는 역량검사의 정확한 응시방법</em>을 확인 할 수 있어요.</div></div>
          <div><span>✓</span><div>역량검사의 &nbsp;<em>평가 알고리즘</em>을 볼 수 있어요.</div></div>
          <div><span>✓</span><div>역량검사에 대한 &nbsp;<em>궁금점과 오해와 진실</em>을 해결 할 수 있어요.</div></div>
        </AiGateCard>
        <AiGateCard
          type='typeA'
          img={IconJoyTypeA}
          subTitle='역량검사란 바로 이런것'
          title={<h1>역량을 잡다란?</h1>}
          btnMsg='자세히 알아보기'
          onClickBtn={() => history.push(RoutePaths.acc_introduce)}
        >
          <div><span>✓</span><div>잡다에서 말하는&nbsp;<em>역량이 무엇인지</em> 확인할 수 있어요.</div></div>
          <div><span>✓</span><div>잡다에서 제공하는&nbsp;<em>역량 기반 리포트</em>가 어떤 내용으로 구성되어 있는지 확인할 수 있어요.</div></div>
          <div><span>✓</span><div>인적성검사와&nbsp;<em>역량검사의 차별점</em>이 무엇인지 확인할 수 있어요.</div></div>
        </AiGateCard>
        <AiGateCard
          type='typeC'
          img={IconJoyTypeC}
          subTitle='실전과 똑같은 환경에서'
          title={<h1><em>역량검사</em>&nbsp;수련</h1>}
          btnMsg='PC에서 확인해 주세요'
          onClickBtn={() => { }}
        >
          <div><span>✓</span><div><em>역량검사를 단계별로 연습</em>할 수 있어요.</div></div>
          <div><span>✓</span><div><em>전략게임 숙련도</em>를 차근차근 올리고 &nbsp;<em>게임별 진단결과</em>도 확인할 수 있어요.</div></div>
          <div><span>✓</span><div>익숙하지 않았던&nbsp;<em>영상면접</em>을 대비할 수 있어요.</div></div>
        </AiGateCard>
      </StyledCardFrame>
    </Frame>
  );
};

export default AiGate;
