import Colors from '__designkit__/common/colors';
import { ConditionRegisterType } from 'consts/_v2/profile/ProfileType';
import useQueryParams from 'hooks/useQueryParams';
import { IPutConditionJobGroupsRq, IRqJobGroup, IRsJobGroups, ISelectedJob } from 'interfaces/_v2/profile/IConditionRqRs';
import IComponentProps from 'interfaces/props/IComponentProps';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import { injectStore } from 'models/store';
import useProfileConditions, { useFetchSaveProfileConditionsJobGroups } from 'query/profiles/useProfileConditionsQuery';
import React, { FC, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import Fonts from '__designkit__/common/fonts';
import IconJobs from 'assets/_v2/preferred/icon_briefcase.svg';
import JobGroupSelectView, { TwoDepthItem } from './preferredJobSelectView';

const TabFrame = styled.div<{isResumeModal?:boolean}>`
`;

const Frame = styled.div`
  .step-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 24px 0;
    text-align: center;
    font: ${Fonts.H4_Bold};
    color: ${Colors.JOBDA_BLACK};
    
    .title-icon {
      margin-bottom: 12px;
      width: 40px;
      height: 40px;
    }
  }
`;

interface IJobTabProps extends IComponentProps {
  context?: Context;
  hidden?: boolean;
  jobGroupData?:IRsJobGroups[];
  setConditionRegisterType: (type: ConditionRegisterType) => void;
  isResumeModal?: boolean;
  setStartChecking?: (startChecking: boolean) => void;
}

export interface ChildJobTabHandles {
  handleSaveJobButton: () => void;
}

const JobTab = forwardRef<ChildJobTabHandles, IJobTabProps>(({ setStartChecking, jobGroupData, isResumeModal, context = new Context(), hidden, setConditionRegisterType }, ref) => {
  const defaultValues:IPutConditionJobGroupsRq = { jobGroups: [], sourcePage: '' };
  const [init, setInit] = useState(false);
  const [jobGroups, setJobGroups] = useState<TwoDepthItem[]>([]);
  const { sourcePage } = useQueryParams();
  const { refetch, data: profileConditionsData } = useProfileConditions(true);
  const saveSuccess = async () => {
    await refetch();
    if (setStartChecking)setStartChecking(true);
    setInit(false);
    setConditionRegisterType(ConditionRegisterType.LIST);
  };

  const { mutate: saveProfileJobConditions } = useFetchSaveProfileConditionsJobGroups(saveSuccess);

  const formMethods = useForm({
    mode: 'onSubmit',
    defaultValues,
  });

  const { watch, reset, getValues } = formMethods;

  useEffect(() => {
    if (jobGroupData && watch('jobTitles', []) && !init) {
      const newValues: any = {};
      jobGroupData.forEach((jobGroup) => {
        jobGroup.jobTitleCodes.forEach((jobTitleCode) => {
          newValues[`jobTitles[${jobTitleCode}]`] = { jobGroupCode: jobGroup.jobGroupCode, jobTitleCode };
        });
      });
      reset({ ...getValues(), ...newValues });
      setInit(true);
    }
  }, [reset, watch, init, jobGroupData, watch('jobTitles'), getValues, profileConditionsData]);

  useEffect(() => {
    if (!context.jobGroupsWithTitles.length) return;
    setJobGroups(context.jobGroupsWithTitles.map((group) => {
      const temp2 = group.jobTitles.map((title) => ({
        code: title.code,
        name: title.name,
        value: { jobGroupCode: group.code, jobTitleCode: title.code },
      }));
      return {
        code: group.code,
        name: group.name,
        children: temp2,
      };
    }));
  }, [context.jobGroupsWithTitles]);

  useEffect(() => {
    const temp: IRqJobGroup[] = [];
    const jobTitlesWatched = watch('jobTitles', []) as ISelectedJob[];
    jobTitlesWatched
      .filter((jobItem: boolean | ISelectedJob) => jobItem)
      .forEach((jobItem : ISelectedJob) => {
        const index = temp.findIndex((jobGroup) => jobGroup.jobGroupCode === jobItem.jobGroupCode);
        if (index !== -1) {
          temp[index].jobTitleCodes.push(jobItem.jobTitleCode);
        } else {
          temp.push({
            jobGroupCode: jobItem.jobGroupCode,
            jobTitleCodes: [jobItem.jobTitleCode],
          });
        }
      });
    if (temp.length > 0) {
      context.isPreferredClicked = true;
    } else {
      context.isPreferredClicked = false;
    }
  }, [watch('jobTitles')]);

  useImperativeHandle(ref, () => ({
    async handleSaveJobButton() {
      const temp: IRqJobGroup[] = [];
      const jobTitlesWatched = watch('jobTitles', []) as ISelectedJob[];
      jobTitlesWatched
        .filter((jobItem: boolean | ISelectedJob) => jobItem)
        .forEach((jobItem : ISelectedJob) => {
          const index = temp.findIndex((jobGroup) => jobGroup.jobGroupCode === jobItem.jobGroupCode);
          if (index !== -1) {
            temp[index].jobTitleCodes.push(jobItem.jobTitleCode);
          } else {
            temp.push({
              jobGroupCode: jobItem.jobGroupCode,
              jobTitleCodes: [jobItem.jobTitleCode],
            });
          }
        });
      if (temp.length > 0) {
        const putConditionJobGroupsRq: IPutConditionJobGroupsRq = {
          jobGroups: temp,
          sourcePage: sourcePage || 'PROFILE',
        };
        saveProfileJobConditions(putConditionJobGroupsRq, { onSuccess: saveSuccess });
      }
    },
  }));

  if (hidden) return null;
  return (
    <TabFrame isResumeModal={isResumeModal}>
      <FormProvider {...formMethods}>
        <Frame>
          <div className='step-title'>
            <img className='title-icon' alt='직군' src={IconJobs} />
            원하는 직군을 알려주세요.
          </div>
          <JobGroupSelectView name='jobTitles' jobGroups={jobGroups} />
        </Frame>
      </FormProvider>
    </TabFrame>
  );
});

export default inject(injectStore.context)(observer(JobTab));
