import { Http } from '@capacitor-community/http';
import { Preferences } from '@capacitor/preferences';
import config from 'config';
import URIs from 'consts/URIs';
import { IAccessTokenRs, ILoginRs } from 'interfaces/rqrs/ILoginRqRs';
import ILoginUserInfoRs from 'interfaces/rqrs/ILoginUserInfoRs';
import { OpenIdProvider } from 'interfaces/rqrs/ISocialAuthLoginRs';
import jsCookie from 'js-cookie';
import MobileStore from 'store/mobileStore';
import CommonUtils from 'utils/CommonUtils';
import PushUtils, { FCM_TOKEN } from 'utils/PushUtils';
import request from 'utils/request';

export enum AUTHORIZE_TOKEN_KEY {
  ACCESS_TOKEN = 'accessToken',
  ACCESS_TOKEN_EXPIRES_TIME = 'accessToken_time',
  REFRESH_TOKEN = 'refreshToken'
}

const AUTO_LOGIN_KEY = 'JOBDA_AUTO_LOGIN';

// 백엔드 accessToken 만료 2시간
const AccessTokenExpireTime = 120 * 60 * 1000;
// 백엔드 refreshToken 만료 30일
const RefreshTokenExpireTime = 30 * 24 * 60 * 60 * 1000;

class AuthorizeUtil {
  static expiredCallback: Function = () => { };
  static bearerAccessToken: string = '';

  static isUsingService: boolean = true;
  static checkUsingServiceId = -1;
  static checkAccessTokenExpireId = -1;
  static init = async () => {
    await AuthorizeUtil.getRefreshToken();
    const accessToken = await AuthorizeUtil.getAccessToken();
    if (accessToken) {
      AuthorizeUtil.bearerAccessToken = `Bearer ${accessToken}`;
    }
  };

  static updateBackendWithFCMToken = async () => {
    if (PushUtils.FCM_TOKEN) {
      await request({
        method: 'post',
        url: URIs.post_users_device_fcm_token,
        data: { token: PushUtils.FCM_TOKEN },
      });
      await request({
        method: 'put',
        url: URIs.put_users_device_fcm_app_runtime,
        data: { token: PushUtils.FCM_TOKEN },
      });
    }
  };

  static updateFCMRunTimeToken = async () => {
    if (PushUtils.FCM_TOKEN) {
      await request({
        method: 'put',
        url: URIs.put_users_device_fcm_app_runtime,
        data: { token: PushUtils.FCM_TOKEN },
      });
    }
  };

  static removeFCMTokenFromBackend = async () => {
    if (FCM_TOKEN) {
      await request({
        method: 'delete',
        url: URIs.delete_users_device_fcm_token,
        data: { token: FCM_TOKEN },
      });
    }
  };

  // 백엔드와 통신이 2시간동안 없으면 서비스를 사용중이지 않게 처리하는 함수
  static checkUsingService = () => {
    AuthorizeUtil.isUsingService = true;
    window.clearTimeout(AuthorizeUtil.checkUsingServiceId);
    const res = window.setTimeout(() => {
      AuthorizeUtil.isUsingService = false;
      window.clearTimeout(AuthorizeUtil.checkUsingServiceId);
    }, (AccessTokenExpireTime));
    AuthorizeUtil.checkUsingServiceId = res;
  }

  // accessToken 만료값이 30분이라서 30분마다 업데이트를 해주는 함수
  static intervalAccessTokenUpdate = () => {
    if (!MobileStore.isMobile) {
      AuthorizeUtil.checkAccessTokenExpireId = window.setInterval(() => {
        if (AuthorizeUtil.isUsingService) {
          AuthorizeUtil.updateAccessToken();
        } else {
          AuthorizeUtil.removeAccessToken();
          window.clearInterval(AuthorizeUtil.checkAccessTokenExpireId);
          window.document.location.reload();
        }
      }, AccessTokenExpireTime);
    } else {
      AuthorizeUtil.checkAccessTokenExpireId = window.setInterval(() => {
        AuthorizeUtil.updateAccessToken();
      }, AccessTokenExpireTime);
    }
  }

  // accessToken 재발급은 쿠키에 저장되어있는 refreshToken값으로 가능
  static updateAccessToken = async (isRefresh?: boolean) => {
    try {
      const url = URIs.get_reissue_token;
      if (!MobileStore.isMobile) {
        const updateRs = await request<ILoginRs & IAccessTokenRs>({
          method: 'get',
          url,
        });
        AuthorizeUtil.setAccessToken(updateRs.accessToken);
        if (isRefresh && updateRs.refreshToken) AuthorizeUtil.setRefreshToken(updateRs.refreshToken);
      } else {
        // ios 앱은 보안상 이유로 웹뷰 쿠키가 적용되지 않아 Http 라이브러리(캐패시터 앱 전용)로 쿠키 컨트롤을 해주어야함
        const updateRs: ILoginRs & IAccessTokenRs = (await Http.get({ url: config.host + url })).data;
        AuthorizeUtil.setAccessToken(updateRs.accessToken);
        if (isRefresh && updateRs.refreshToken) AuthorizeUtil.setRefreshToken(updateRs.refreshToken);
      }
    } catch (e) {
      AuthorizeUtil.removeAccessToken();
      AuthorizeUtil.removeRefreshToken();
      throw e;
    }
  };

  // expiredCallback 추가 함수
  static addEventExpired = (callback: Function) => {
    AuthorizeUtil.expiredCallback = callback;
  };

  // access 토큰 만료 여부 확인 및 리셋
  static checkAccessTokenExpired = async () => {
    try {
      const loginInfo = await request<ILoginUserInfoRs>({
        method: 'get',
        url: URIs.get_login_user_info,
      });
      if (loginInfo) return true;

      AuthorizeUtil.removeAccessToken();
      return false;
    } catch (e) {
      console.error(e);
    }
  }

  static getIsAutoLogin = async () => {
    if (!MobileStore.isMobile) {
      return jsCookie.get(AUTO_LOGIN_KEY) === 'true';
    }
    return (await Preferences.get({ key: AUTO_LOGIN_KEY })).value === 'true';
  }

  static setIsAutoLogin = async (isAutoLogin: boolean) => {
    if (!MobileStore.isMobile) {
      jsCookie.set(AUTO_LOGIN_KEY, `${isAutoLogin}`);
    } else {
      await Preferences.set({ key: AUTO_LOGIN_KEY, value: `${isAutoLogin}` });
    }
  }

  static removeIsAutoLogin = async () => {
    if (!MobileStore.isMobile) {
      jsCookie.remove(AUTO_LOGIN_KEY);
    } else {
      await Preferences.remove({ key: AUTO_LOGIN_KEY });
    }
  }

  static getAutoLoginInfo = async () => ({ type: (await Preferences.get({ key: 'type' })).value })

  static setAutoLoginInfo = async (type: OpenIdProvider) => {
    await Preferences.set({ key: 'type', value: `${type}` });
  }

  static removeAutoLoginInfo = async () => {
    await Preferences.remove({ key: 'type' });
  }

  static setAccessToken = async (accessToken:string) => {
    if (!MobileStore.isMobile) {
      const currentCookieDomain = CommonUtils.getCookieDomain();
      jsCookie.set(
        AUTHORIZE_TOKEN_KEY.ACCESS_TOKEN,
        accessToken,
        { expires: new Date(Date.now() + AccessTokenExpireTime), domain: currentCookieDomain },
      );
    } else {
      await Preferences.set({ key: AUTHORIZE_TOKEN_KEY.ACCESS_TOKEN, value: accessToken });
      await Preferences.set({ key: AUTHORIZE_TOKEN_KEY.ACCESS_TOKEN_EXPIRES_TIME, value: new Date(Date.now() + AccessTokenExpireTime).toUTCString() });
    }
    AuthorizeUtil.bearerAccessToken = `Bearer ${accessToken}`;

    if (MobileStore.isMobile) {
      try {
        await AuthorizeUtil.updateBackendWithFCMToken();
      } catch (e) {
        console.error('Failed to update backend with FCM token:', e);
      }
    }
  };

  static getAccessToken = async () => {
    if (!MobileStore.isMobile) {
      const accessToken = jsCookie.get(AUTHORIZE_TOKEN_KEY.ACCESS_TOKEN);
      if (!accessToken) {
        AuthorizeUtil.expiredCallback();
        return null;
      }
      return accessToken;
    }
    const accessToken = (await Preferences.get({ key: AUTHORIZE_TOKEN_KEY.ACCESS_TOKEN })).value;
    const accessTokenExpiresTime = (await Preferences.get({ key: AUTHORIZE_TOKEN_KEY.ACCESS_TOKEN_EXPIRES_TIME })).value;
    if (accessToken && accessTokenExpiresTime && new Date() < new Date(accessTokenExpiresTime)) {
      return accessToken;
    }
    if (!accessToken) {
      AuthorizeUtil.expiredCallback();
      return null;
    }
  };

  static removeAccessToken = async () => {
    if (!MobileStore.isMobile) {
      const currentCookieDomain = CommonUtils.getCookieDomain();
      jsCookie.remove(AUTHORIZE_TOKEN_KEY.ACCESS_TOKEN);
      jsCookie.remove(AUTHORIZE_TOKEN_KEY.ACCESS_TOKEN, { domain: currentCookieDomain });
    } else {
      await Preferences.remove({ key: AUTHORIZE_TOKEN_KEY.ACCESS_TOKEN });
      await Preferences.remove({ key: AUTHORIZE_TOKEN_KEY.ACCESS_TOKEN_EXPIRES_TIME });
    }
    AuthorizeUtil.bearerAccessToken = '';
  };

  static setRefreshToken = async (refreshToken:string) => {
    if (!MobileStore.isMobile) {
      jsCookie.set(
        AUTHORIZE_TOKEN_KEY.REFRESH_TOKEN,
        refreshToken, { expires: new Date(Date.now() + RefreshTokenExpireTime) },
      );
    } else {
      await Preferences.set({ key: AUTHORIZE_TOKEN_KEY.REFRESH_TOKEN, value: refreshToken });
      await Http.setCookie({ url: config.host, key: 'refreshToken', value: refreshToken });
    }
  }

  static getRefreshToken = async () => {
    if (!MobileStore.isMobile) {
      const refreshToken = jsCookie.get(AUTHORIZE_TOKEN_KEY.REFRESH_TOKEN);
      return refreshToken;
    }
    const refreshToken = (await Preferences.get({ key: AUTHORIZE_TOKEN_KEY.REFRESH_TOKEN })).value;
    if (refreshToken) await Http.setCookie({ url: config.host, key: 'refreshToken', value: refreshToken });
    return refreshToken;
  }

  static removeRefreshToken = async () => {
    if (!MobileStore.isMobile) {
      jsCookie.remove(AUTHORIZE_TOKEN_KEY.REFRESH_TOKEN);
    } else {
      try {
        await AuthorizeUtil.removeFCMTokenFromBackend();
      } catch (e) {
        console.error('Failed to delete backend with FCM token:', e);
      }
      await Preferences.remove({ key: AUTHORIZE_TOKEN_KEY.REFRESH_TOKEN });
      await Http.clearCookies({ url: config.host });
    }
  }
}

export default AuthorizeUtil;
