import LazyImage from 'components/common/LazyImage';
import colors from '__designkit__/common/colors';
import { fonts, lineHeights } from '__designkit__/common/fonts';
import React from 'react';
import styled from 'styled-components/macro';
import ImgAi1 from 'assets/img/ai/introduce/img_introduce_ai_1.png';
import ImgAi2 from 'assets/img/ai/introduce/img_introduce_ai_2.png';

const Frame = styled.div`
  height:100%;
`;

const StyledAiInfoFrame = styled.div`
  padding-left:24px;
  padding-right:24px;
  display: flex;
  flex-direction: column;
  
  h1{
   padding-top:96px;
   ${fonts.NOTO_36_700};
   color:${colors.CG_90};
   ${lineHeights.LINEHEIGHT_1_40};
  }

  h4{
    ${fonts.NOTO_13_700};
    &.sky{
      color:${colors.c5EC7C3};
    }
    &.green{
      color:${colors.G_100};
    }
  }

  h2{
   margin-top:16px;
   margin-bottom:24px;
   ${fonts.NOTO_20_700};
   color:${colors.CG_80}; 
   ${lineHeights.LINEHEIGHT_1_50};
  }

  h3{
   margin-bottom:16px;
   ${fonts.NOTO_13_400};
   color:${colors.CG_70};
   ${lineHeights.LINEHEIGHT_1_50};
   em{
     ${fonts.NOTO_13_700};
   }
  }

`;

const AiIntroduceAiInfo: React.FC = () => (
  <Frame>
    <StyledAiInfoFrame>
      <h1>역량으로<br />
        알 수 있습니다
      </h1>
      <h4 className='green' style={{ marginTop: '48px' }}>역량은 언제, 어떻게 만들어지나요?</h4>
      <h2>모든 경험과 체험의<br />
        과정, 결과로<br />
        역량은 만들어집니다.
      </h2>
      <h3>사람의 역량은 출생한 후에 뇌가 성장하는 과정에서 만들어집니다. 태어났을 때 미성숙의 뇌는 성장 발달 과정을 거쳐서 20세 전후가 되면 거의 완성되는 것으로 알려져 있습니다.
      </h3>
      <h3>잡다의 역량검사에서 핵심역량이라고 정의하는 긍정성, 적극성, 전략성, 성실성, 관계성, 정체성, 객관성은 부모, 형제, 또래, 친구, 선생님을 포함하여 <em>가정과 학교 그리고 사회생활에서 만나는 사람들과 환경과의 상호작용으로 이루어지는 모든 체험과 경험의 과정과 결과를 통해 만들어집니다.</em></h3>
      <h3>역량은 뇌가 환경에 대응하는 과정에서 형성되는 적응 능력이며, 우리의 삶의 수준을 결정짓는 중요한 요소입니다. 7가지 핵심역량을 이해하고 <em>성인이 된 이후에는 긍정기술, 전략기술, 성찰기술과 같은 역량기술을 학습하고 습관화한다면 우리는 역량을 강화하고 함양할 수 있습니다.</em></h3>
    </StyledAiInfoFrame>
    <LazyImage style={{ width: '100%', marginTop: '32px' }} src={ImgAi1} />
    <StyledAiInfoFrame>
      <h4 className='green' style={{ marginTop: '48px' }}>역량검사 리포트는 무엇인가요?</h4>
      <h2>세계 최초로 제공되는<br />
        청년을 위한<br />
        역량 기반 리포트입니다.
      </h2>
      <h3>잡다의 역량검사 리포트는 세계 최초로 신경과학 알고리즘과 인공지능 기술을 융합한 역량검사솔루션인 ‘역량검사’에서 구직자에게 제공하는 결과리포트입니다.</h3>
      <h4 className='sky' style={{ marginTop: '32px', marginBottom: '8px' }}>역량검사란?</h4>
      <h3><em>역량검사는 3가지 TASK(성향파악, 전략게임, 영상면접)을 통해 구직자의 성과역량, 가치역량 그리고 관찰특성을 확인합니다.</em> 성과역량은 성과를 만드는 전전두엽의 7가지 핵심역량을, 가치역량은 정서적 안정감이나 사회적 책임감을 바탕으로 한 조직내 성장가능성을, 그리고 관찰특성은 상황질문에 대한 답변을 통해  소통역량을 측정합니다.</h3>
      <h4 className='sky' style={{ marginTop: '24px', marginBottom: '8px' }}>역량검사 리포트에는 어떤 내용이 담겨 있나요?</h4>
      <h3>먼저, <em>응시결과 분석리포트</em>는 역량검사에 대한 올바른 이해와 적응을 돕고 응시결과 요약, 성향파악/영상면접/전략게임 가이드와 맞춤형 응시 꿀팁 등을 제공합니다.</h3>
      <h3>또한, 자신의 역량을 객관적으로 이해하도록 돕기 위한 역량 결과 분석을 제공합니다. 구직자의 역량결과(성과역량, 가치역량, 소통역량)를 분석하여 역량검사에서 정의하는 12가지 역량유형과 유형에 맞는 다양한 정보가 포함되어 있습니다.</h3>
      <h3>또한 핵심역량에 대한 역량프로파일을 통해 구직자의 뛰어난 TOP3역량과 강점 및 약점의 정보를 안내합니다.</h3>
      <h3>이를 통해 구직자는 본인의 역량을 보다 합리적이고 선명하게 확인함으로써 <em>역량검사를 통해 자신의 진짜 역량을 객관적으로 바라볼 수 있는 기회</em>를 제공합니다.</h3>
      <h3>추가적으로, 구직자에게 맞춤형 기업 문화 소개, 직군 및 직무 추천을 통해 내 역량을 제대로 펼칠 수 있는 방향도 제시해 줍니다.</h3>
    </StyledAiInfoFrame>
    <LazyImage style={{ width: '100%', marginTop: '32px' }} src={ImgAi2} />
  </Frame>
);

export default AiIntroduceAiInfo;
