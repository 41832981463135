import { ReactSelectStyles } from 'consts/_v2/_common/style/ReactSelectStyles';
import { TeamType } from 'interfaces/_v2/profile/IProfileProjectRqRs';
import React, { FC } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import Select from 'react-select';

interface IProfileProjectContributionSelect {
    prefixName : string;
}

export const contributionOptions = [
  { value: 100, label: '100%' },
  { value: 90, label: '90%' },
  { value: 80, label: '80%' },
  { value: 70, label: '70%' },
  { value: 60, label: '60%' },
  { value: 50, label: '50%' },
  { value: 40, label: '40%' },
  { value: 30, label: '30%' },
  { value: 20, label: '20%' },
  { value: 10, label: '10%' },
];

const ProfileProjectContributionSelect :FC<IProfileProjectContributionSelect> = ({ prefixName }) => {
  const { watch, control } = useFormContext();
  const { field } = useController({ name: `${prefixName}.contribution`, control });
  return (
    <Select
      {...field}
      className='select'
      placeholder='기여도'
      styles={ReactSelectStyles}
      isSearchable={false}
      options={contributionOptions}
      isDisabled={watch(`${prefixName}.teamType`) !== TeamType.TEAM}
    />
  );
};

export default ProfileProjectContributionSelect;
