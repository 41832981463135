import colors from '__designkit__/common/colors';
import Fonts, { fontStyle } from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import ToggleSwitch from 'components/ToggleSwitch';
import { DateUtil } from 'components/_v2/_common/input/datePicker/DateUtil';
import JDBaseModal, { IJDBaseModal } from 'components/_v2/_common/modals/JDBaseModal';
import {
  JDModalBasicFrame,
  JDModalButton,
  JDModalDescription,
  JDModalTitle,
} from 'components/_v2/_common/modals/JDModalComponents';
import { TERM_TYPE } from 'consts/TermType';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import JoinModel from 'models/_v2/JoinModel';
import { injectStore } from 'models/store';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

const Frame = styled.div`
  ${JDModalTitle} {
    margin: 16px 0 8px 0;
    white-space: pre-line;
  }
  ${JDModalDescription} {
    margin-bottom: 16px;
  }
  ${JDModalButton} {
    margin-top: 20px;
  }
  .grey-toggle-frame {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: ${colors.CG_30};
    width: 100%;
    height:fit-content;
    padding: 24px 48px;
    border-radius: 4px;
    .align-alarm{
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      .alarm-text{
        text-align: center;
        font: ${Fonts.B2_Medium_P};
        color: ${colors.JOBDA_BLACK};
    }
    }
    .detail-consent{
      font:${Fonts.B2_Medium};
      text-decoration: underline;
      color:${colors.CG_70};
    }
   
  }
  .grey-message-frame{
    display: flex;
    padding: 24px;
    flex-direction: column;
    justify-content: flex-start;
    background-color: ${colors.CG_30};
    width: 100%;
    height: fit-content;
    border-radius: 4px;
    .align-message{
      display: flex;
      justify-content: flex-start;
      .message-title{
        font:${Fonts.B1_Bold_P};
        color:${colors.CG_90};
        width: 74px;
        flex:none;

      }
      .message-detail{
        font:${Fonts.B1_Medium_P};
        color:${colors.CG_80};
      }
    }
  }
  .guide-text{
    font:${Fonts.B2_Medium_P};
    color:${colors.CG_70};
    text-align: center;
    white-space: pre-line;
  }
`;

interface IMarketingConsentModal extends IJDBaseModal {
    login?:Login
    joinModel?:JoinModel
}

const MarketingConsentModal:FC<IMarketingConsentModal> = ({ isOpen, onClickClose, onSuccess, login = new Login(), joinModel = new JoinModel() }) => {
  const useFormed = useForm();
  const { watch, handleSubmit, setValue, control, reset } = useFormed;
  const [marketingConsentAll, setMarketingConsentAll] = useState<boolean>(false);
  useEffect(() => {
    const init = async () => {
      await login.loadMarketingConsent();
    };
    init();
  }, []);

  useEffect(() => {
    if (login.marketingConsentRs) {
      reset({ marketingEmailConsentYn: login.marketingConsentRs?.emailYn, marketingSmsConsentYn: login.marketingConsentRs?.smsYn });
    }
  }, [login.marketingConsentRs]);

  useEffect(() => {
    if (login.marketingConsentRs?.emailYn && login.marketingConsentRs?.smsYn) {
      setMarketingConsentAll(true);
    } else {
      setMarketingConsentAll(false);
    }
  }, [login.marketingConsentRs]);

  const onSubmit = async () => {
    if (onClickClose) onClickClose();
  };
  const updateMarketingConsent = async () => {
    try {
      await login.updateNotificationSettings('SMS', true);
      await login.updateNotificationSettings('EMAIL', true);
      await login.loadMarketingConsent();
    } catch (e) {
      alert('다시 시도해 주세요.');
      console.error(e);
    }
  };
  const onError = () => { };

  return (
    <JDBaseModal isDimCloseBtn dimmed isOpen={isOpen} onClickClose={onClickClose}>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <FormProvider {...useFormed}>
          <Frame>
            <JDModalBasicFrame>
              <JDModalTitle>{marketingConsentAll ? `마케팅 수신 동의 완료!` : `마케팅 수신 동의가 \n 필요해요!`}</JDModalTitle>
              <JDModalDescription>
                {marketingConsentAll
                  ? `'이벤트 응모'버튼을 \n 다시 눌러주세요!`
                  : `리워드 지급을 위해 \n SMS, 이메일 알림 설정이 필요해요.`}
              </JDModalDescription>
              <SpacingBlock vertical size={8} />
              {marketingConsentAll
                ? (
                  <div className='grey-message-frame'>
                    <div className='align-message'>
                      <div className='message-title'>전송자</div>
                      <div className='message-detail'>잡다</div>
                    </div>
                    <SpacingBlock size={8} vertical />
                    <div className='align-message'>
                      <div className='message-title'>일시</div>
                      <div className='message-detail'>{DateUtil.format(DateUtil.getNow(), 'yyyy년 MM월 dd일 HH시 mm분')}</div>
                    </div>
                    <SpacingBlock size={8} vertical />
                    <div className='align-message'>
                      <div className='message-title'>처리결과</div>
                      <div className='message-detail'>SMS,이메일 알림 수신동의 처리 완료</div>
                    </div>
                  </div>
                )
                : (
                  <div className='grey-toggle-frame'>
                    <div className='align-alarm'>
                      <div className='alarm-text'>SMS 알림 설정</div>
                      <Controller
                        name='marketingSmsConsentYn'
                        control={control}
                        render={(props) => (
                          <ToggleSwitch
                            className='recruit-toggle'
                            checked={props.value}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              if (e.target.checked)
                                setValue('marketingSmsConsentYn', true);
                              else
                                setValue('marketingSmsConsentYn', false);
                            }}
                          />
                        )}
                        defaultValue={false}
                      />
                    </div>
                    <SpacingBlock vertical size={16} />
                    <div className='align-alarm'>
                      <div className='alarm-text'>이메일 알림 설정</div>
                      <Controller
                        name='marketingEmailConsentYn'
                        control={control}
                        render={(props) => (
                          <ToggleSwitch
                            className='recruit-toggle'
                            checked={props.value}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              if (e.target.checked)
                                setValue('marketingEmailConsentYn', true);
                              else
                                setValue('marketingEmailConsentYn', false);
                            }}
                          />
                        )}
                        defaultValue={false}
                      />
                    </div>
                    <SpacingBlock vertical size={16} />
                    <div role='button' onClick={() => { joinModel.activeTermType = TERM_TYPE.MARKETING_POLICY; }} className='detail-consent'>자세히 보기</div>
                  </div>
                )}

              <SpacingBlock vertical size={24} />

              <div className='guide-text'>
                {`추후 마이페이지 > 알림관리에서\n변경할 수 있습니다.`}

              </div>
              {marketingConsentAll
                ? (
                  <JDModalButton onClick={updateMarketingConsent}>
                    확인
                  </JDModalButton>
                )
                : (
                  <JDModalButton onClick={updateMarketingConsent} type='button' disabled={!watch('marketingEmailConsentYn') || !watch('marketingSmsConsentYn')}>
                    완료
                  </JDModalButton>
                )}

            </JDModalBasicFrame>

          </Frame>
        </FormProvider>
      </form>
    </JDBaseModal>

  );
};
export default inject(injectStore.login, injectStore.joinModel)(observer(MarketingConsentModal));
