interface DetailInfoListData {
  title: string;
  data?: string;
  dataDesc?: string;
  subData?: {
    data: string;
    dataDesc: string;
  }[];
}

export const detailInfoListData: DetailInfoListData[] = [
  {
    title: '대회 기간',
    data: '09.01(금) ~ 09.27(수) 23:59',
  },
  {
    title: '사용 가능 언어',
    data: 'Python / Java / C++ / JavaScript',
  },
  {
    title: '응시 자격',
    data: '검사 수행을 위해 웹캠과 마이크가 준비된 개발자 그 외 제한 없음',
  },
  {
    title: '응시 방법',
    data: '행사 기간 중 JOBDA 사이트를 통해 개발 구현 능력 검사 / 개발자 역량검사(역량검사) 응시',
    dataDesc: '9월 행사 기간은 09.01(금) ~ 9.27(수)입니다.',
  },
  {
    title: '참가자 혜택',
    subData: [
      {
        data: '피드백 리포트',
        dataDesc: '나의 개발 능력 진단과 역량 분석이 담긴 피드백 리포트를 받아보실 수 있습니다.',
      },
      {
        data: '기업 스카웃 제안',
        dataDesc: '피드백 리포트를 통해 참여 기업의 스카웃 제안을 우선적으로 받아보실 수 있습니다.',
      },
      {
        data: '채용 정보 큐레이션',
        dataDesc: 'AI와 전문 매칭 큐레이터가 합격률 높은 포지션을 디챔스 이후에도 계속해서 추천드립니다.',
      },
      {
        data: '결선 참가 기회',
        dataDesc: '매월 상위 30명에게 최종 순위결정전 참가 기회를 드리며, 순위에 따른 포상을 받으실 수 있습니다.',
      },
    ],
  },
  {
    title: '참가 관련 문의',
    data: 'b2cmarketing@midasin.com',
  },
];

export default detailInfoListData;
