import Icon from '__designkit__/icon/Icon';
import Fonts from '__designkit__/common/fonts';
import Colors from '__designkit__/common/colors';
import ICalendarProps from 'interfaces/_v2/calendar/ICalendarProps';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import CalendarModel from 'models/_v2/CalendarModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { DateDataFormatMMDDCCUnit, DateDataFormatYYYYMM, DateFormatMMDDCCUnit, weeksKor } from 'utils/DateUtils';

const CalendarContainer = styled.div`
  position: sticky;
  z-index: 100;
  transition: top 0.25s, background 0.25s;
  top: ${(props) => (!(props as any)['data-hide'] ? '57' : '0')}px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  height: 400px;
  overflow-x: auto;
  white-space: pre;
  padding: 0 16px;
  background: ${Colors.WHITE_100};
  border-radius: 0px 0px 16px 16px;

  ::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`;

// 스타일드 컴포넌트를 사용하여 스타일 정의
const Container = styled.div`
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0 20px 0;
  text-align: center;
`;

const Month = styled.h1`
  margin: 0px 5px;
  font: ${Fonts.B2_Bold};
  color: ${Colors.CG_90};
`;

const WeekDays = styled.div`
  display: flex;
  margin-bottom: 2px;
`;

const WeekDay = styled.div<{ isSameDayOfWeek: boolean }>`
  flex: 1;
  padding: 5px;
  text-align: center;
  font: ${Fonts.B3_Medium};
  color: ${Colors.CG_60};

  ${(props) => props.isSameDayOfWeek
    && css`
      font: ${Fonts.B3_Bold};
      color: ${Colors.CG_80};
    `}
`;

const Dates = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1px;
`;

const DateBox = styled.div<{ isSunday?: boolean; selected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  border-radius: 50%;
  height: 38px;
  margin-top: 12px;
  ${(props) => props.selected
    && css`
      margin-top: 9px;
    `}
  .day-number {
    display: flex;
    justify-content: center;
    align-items: center;
    font: ${Fonts.B3_Medium};
    color: ${Colors.CG_80};
    width: 16px;
    height: 16px;
    ${({ isSunday }) => isSunday && `color: ${Colors.ERROR};`}
    margin-bottom: 7px;
    ${(props) => props.selected
      && css`
        color: ${Colors.WHITE_100};
        background-color: ${Colors.G_150};
        border-radius: 50%;
        margin-bottom: 6px;
        padding: 10px;

        &.isSunday {
          color: ${Colors.WHITE_100};
        }
      `}
  }

  .jobs-count {
    font: ${Fonts.B4_Medium};
    color: ${Colors.CG_60};
  }
`;

const ExpandedCalendar: FC<ICalendarProps> = ({ context = new Context(), calendarModel = new CalendarModel() }) => {
  const calendarRef = useRef<HTMLDivElement>(null);
  const touchStartX = useRef(0);
  const touchStartY = useRef(0);
  const touchEndX = useRef(0);
  const touchEndY = useRef(0);
  const [dates, setDates] = useState<Date[]>([]);
  const month = calendarModel.currentDate.getMonth();
  const year = calendarModel.currentDate.getFullYear();

  useEffect(() => {
    const init = async () => {
      await calendarModel.loadCalendarMonthCountList();
    };

    init();
  }, []);

  useEffect(() => {
    const getDatesArray = (): Date[] => {
      const firstDay = new Date(year, month, 1);
      const lastDay = new Date(year, month + 1, 0);
      const prevMonthLastDate = new Date(year, month, 0).getDate();
      const datesArray: Date[] = [];

      // 이전 달의 마지막 날짜
      for (let i = firstDay.getDay(); i > 0; i--) {
        const date = new Date(year, month - 1, prevMonthLastDate - i + 1);
        datesArray.push(date);
      }

      // 현재 달의 날짜
      for (let i = 1; i <= lastDay.getDate(); i++) {
        const date = new Date(year, month, i);
        datesArray.push(date);
      }

      // 다음 달의 첫 날짜
      for (let i = 1; datesArray.length < 42; i++) {
        const date = new Date(year, month + 1, i);
        datesArray.push(date);
      }

      return datesArray;
    };

    const newDatesArray = getDatesArray();
    setDates(newDatesArray);
  }, [year, month]);

  // 터치 시작 시 X 좌표 저장
  const handleTouchStart = (event: any) => {
    touchStartX.current = event.touches[0].clientX;
    touchStartY.current = event.touches[0].clientY;
  };

  // 터치 종료 시 X 좌표 저장하고 스와이프 처리
  const handleTouchEnd = (event: any) => {
    touchEndX.current = event.changedTouches[0].clientX;
    touchEndY.current = event.changedTouches[0].clientY;
    handleSwipe();
  };

  // 스와이프 처리
  const handleSwipe = () => {
    const differenceX = touchEndX.current - touchStartX.current;
    const differenceY = touchEndY.current - touchStartY.current;
    if (differenceX > 150) {
      // 왼쪽 스와이프: 다음 날로 이동
      handleSwipeLeft();
    } else if (differenceX < -150) {
      // 오른쪽 스와이프: 이전 날로 이동
      handleSwipeRight();
    }

    if (differenceY > 50) {
      calendarModel.isCalendarFolded = false;
    } else if (differenceY < -50) {
      calendarModel.isCalendarFolded = true;
    }
  };

  // 이전 날로 이동
  const handleSwipeLeft = () => {
    goToPreviousMonth();
  };

  // 다음 날로 이동
  const handleSwipeRight = () => {
    goToNextMonth();
  };

  // 이전 달로 이동
  const goToPreviousMonth = async () => {
    const prevDate = calendarModel.currentDate;
    const tempPrevMonth = prevDate.getMonth() - 1;
    prevDate.setMonth(tempPrevMonth);
    calendarModel.currentDate = new Date(prevDate);
    await calendarModel.loadCalendarMonthCountList(DateDataFormatYYYYMM(calendarModel.currentDate));
  };

  // 다음 달로 이동
  const goToNextMonth = async () => {
    const prevDate = calendarModel.currentDate;
    const tempPrevMonth = prevDate.getMonth() + 1;
    prevDate.setMonth(tempPrevMonth);
    calendarModel.currentDate = new Date(prevDate);
    await calendarModel.loadCalendarMonthCountList(DateDataFormatYYYYMM(calendarModel.currentDate));
  };

  // 날짜 클릭 시 선택된 날짜 변경
  const handleDateClick = (date: any) => {
    calendarModel.selectedDate = date;
    calendarModel.showDate = date;
    calendarModel.isCalendarFolded = true;
  };

  const getPostingCount = (date: Date) => {
    const formattedDate = DateDataFormatMMDDCCUnit(date);
    const item = calendarModel.calendarMonthCountList?.find((v) => DateFormatMMDDCCUnit(v.date) === formattedDate);
    const count = item?.jobPostings.length;

    return count ? `+${count}` : '';
  };

  return (
    <CalendarContainer ref={calendarRef} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} data-hide={context.hideHeader}>
      <Container>
        <Header>
          <button onClick={goToPreviousMonth}>
            <Icon name='arrow-left' size={24} color={Colors.CG_70} />
          </button>
          <Month>
            {year}년 {month + 1}월
          </Month>
          <button onClick={goToNextMonth}>
            <Icon name='arrow-right' size={24} color={Colors.CG_70} />
          </button>
        </Header>
        <WeekDays>
          {weeksKor.map((weekDay, index) => (
            <WeekDay isSameDayOfWeek={calendarModel.showDate && calendarModel.showDate.getDay() === index}>{weekDay}</WeekDay>
          ))}
        </WeekDays>
        <Dates>
          {dates.map((date) => {
            if (date.getMonth() === new Date(year, month - 1).getMonth() || date.getMonth() === new Date(year, month + 1).getMonth()) return <DateBox key={date.getTime()} isSunday={date.getDay() === 0} selected={calendarModel.showDate && date.toDateString() === calendarModel.showDate.toDateString()} />;
            return (
              <DateBox key={date.getTime()} isSunday={date.getDay() === 0} selected={calendarModel.showDate && date.toDateString() === calendarModel.showDate.toDateString()} onClick={() => handleDateClick(date)}>
                <div className='day-number'>{date.toLocaleDateString('en-US', { day: 'numeric' })}</div>
                <div className='jobs-count'>{getPostingCount(date)}</div>
              </DateBox>
            );
          })}
        </Dates>
      </Container>
    </CalendarContainer>
  );
};

export default inject(injectStore.context, injectStore.calendarModel)(observer(ExpandedCalendar));
