import Colors from '__designkit__/common/colors';
import PreferredStep from 'components/_v2/preferred/PreferredStep';
import { IconLogo } from 'consts/assets/icons/iconPages';
import styled from 'styled-components';

const PreferredWrap = styled.div`
  .preferred-step {
  }
`;

const Header = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 56px;
  background: ${Colors.WHITE_100};
  z-index: 1;
`;

const Preferred = () => {
  return (
    <PreferredWrap>
      {/* 로고 헤더 */}
      <Header>
        <IconLogo className='logo' />
      </Header>

      {/* 선호정보 입력 스텝 */}
      <div className='preferred-step'>
        <PreferredStep />
      </div>
    </PreferredWrap>
  );
};

export default Preferred;
