import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import ProfileItemTitle from 'components/_v2/profile/ProfileItemTitle';
import ProfileListCard from 'components/_v2/profile/ProfileListCard';
import { Divider1G40 } from 'components/divider/Divider';
import { IProfileExperienceRs } from 'interfaces/_v2/profile/IProfileExperienceRqRs';
import React, { FC } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
`;

const ETCExperienceTitle = styled.div`
  font: ${Fonts.B2_Bold};
  color:${colors.CG_90};
`;

const ETCExperienceField = styled.div`
  width:100%;
  padding:18px 16px;
  margin-top: 16px;
  background:${colors.CG_30};

  span {
    width: 100%;
    display: inline-block;
    white-space: pre-line;
    font: ${Fonts.B2_Medium};
    color:${colors.c44474B_80};
  }
`;

const ProfilePreviewExperience: FC<{ data: IProfileExperienceRs}> = ({ data }) => {
  const { activitiesAndForeignExperiences, etcExperiences } = data;
  return (
    <Frame>
      <ProfileItemTitle icon='read-book' text='경험' />
      { activitiesAndForeignExperiences !== null && activitiesAndForeignExperiences.map((card, index) => (
        <ProfileListCard key={`card-list${card.title}`} tag={card.tag} institution={card.title} date={`${card.startDate} ~ ${card.endDate}`} description={card.description} index={index} length={activitiesAndForeignExperiences ? activitiesAndForeignExperiences.length : 0} />
      ))}
      { etcExperiences !== null && etcExperiences.length > 0 && (
      <ETCExperienceTitle>기타 경험</ETCExperienceTitle>
      )}
      { etcExperiences && etcExperiences.map((etc) => (
        <>
          { etc.description && (
          <ETCExperienceField key={`card-etc${etc.description}`}>
            <span>
              {etc.description}
            </span>
          </ETCExperienceField>
          )}
        </>
      ))}
      <Divider1G40 />
    </Frame>
  );
};

export default ProfilePreviewExperience;
