/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-distracting-elements */
import JobOpeningLogoCard from '__designkit__/card/JobOpeningLogoCard';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import IconComapny1 from 'assets/_v2/acc/icon_acca_company1.png';
import IconComapny10 from 'assets/_v2/acc/icon_acca_company10.png';
import IconComapny11 from 'assets/_v2/acc/icon_acca_company11.png';
import IconComapny2 from 'assets/_v2/acc/icon_acca_company2.png';
import IconComapny3 from 'assets/_v2/acc/icon_acca_company3.png';
import IconComapny4 from 'assets/_v2/acc/icon_acca_company4.png';
import IconComapny5 from 'assets/_v2/acc/icon_acca_company5.png';
import IconComapny6 from 'assets/_v2/acc/icon_acca_company6.png';
import IconComapny7 from 'assets/_v2/acc/icon_acca_company7.png';
import IconComapny8 from 'assets/_v2/acc/icon_acca_company8.png';
import IconComapny9 from 'assets/_v2/acc/icon_acca_company9.png';
import JDSwiper, { JDSwiperSlide, PageNationType } from 'components/_v2/_common/JDSwiper';
import Marquee from 'components/_v2/_common/Marquee';
import MainNoPosition from 'components/_v2/main/MainNoPosition';
import { Divider12G } from 'components/divider/Divider';
import RoutePaths from 'consts/RoutePaths';
import { IAccCenterJobPosting } from 'interfaces/_v2/acc/IAccGate';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import AccaGateModel from 'models/_v2/acca/AccaGateModel';
import { injectStore } from 'models/store';
import { IAccaGateProps } from 'pages/_v2/acca/AccaGate';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { DateFormatYYYYMMDDCCHHMMUnit, getDDayString, str2DateTime } from 'utils/DateUtils';

const Frame = styled.div`
  background: ${Colors.WHITE_100};

  >h1{
    padding: 40px 16px 0 16px;
    font: ${Fonts.H4_Bold};
    color:${Colors.JOBDA_BLACK};
    margin-bottom:24px;
  }

  .icon-banner{
    padding-bottom: 16px;
    width:100%;
    height: auto;;
  }
`;

const AccaGateRecruitFrame = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 16px;
    .pagination-bullets {
      margin: 22px 0 32px 0;
    }
    .acca-custom-slide{
    width:calc(100vw - 32px);
    padding:0 16px;

    display: flex;
    flex-direction: column;
  }
`;

const BannerFrame = styled.div`
  padding: 40px 16px 0 16px;

  .main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 128px;
    background: ${Colors.G_100};
    border-radius: 8px;
    padding: 0 20px;
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: fit-content;
    height: fit-content;

    .title {
      font: ${Fonts.B2_Bold};
      color: ${Colors.CG_90};
    }

    .description {
      display: flex;
      align-items: center;
      font: ${Fonts.B3_Medium};
      color: ${Colors.CG_80};
    }
  }

  .logo {
    display: flex;
    height: 100%;
    padding: 0 16px;

    >:first-child {
      margin-right: 8px;
    }

    .blank {
      width: 10px;
      height: 10px;
      background: transparent;
    }
  }
`;

export const Banner = () => {
  const history = useHistory();

  return (
    <BannerFrame onClick={() => history.push(RoutePaths.match_introduce)}>
      <div className='main'>
        <div className='info'>
          <div className='title'>이제, 스펙과 자소서가 아닌<br />‘역량’만으로<br />입사 제안 받으세요.</div>
          <div className='description'>
            JOBDA matching 자세히 보기
            <Icon name='arrow-right' size={24} color={Colors.CG_80} />
          </div>
        </div>
        <div className='logo'>
          <Marquee direction='top' speed={15} overlay={Colors.G_100}>
            <img alt='' src={IconComapny1} width={40} height={40} />
            <img alt='' src={IconComapny2} width={40} height={40} />
            <img alt='' src={IconComapny3} width={40} height={40} />
            <img alt='' src={IconComapny4} width={40} height={40} />
            <img alt='' src={IconComapny5} width={40} height={40} />
          </Marquee>
          <Marquee direction='top' speed={15} overlay={Colors.G_100}>
            <img alt='' src={IconComapny6} width={40} height={40} />
            <img alt='' src={IconComapny7} width={40} height={40} />
            <img alt='' src={IconComapny8} width={40} height={40} />
            <img alt='' src={IconComapny9} width={40} height={40} />
            <img alt='' src={IconComapny10} width={40} height={40} />
            <img alt='' src={IconComapny11} width={40} height={40} />
          </Marquee>
        </div>
      </div>
    </BannerFrame>
  );
};

const AccaGateRecruit: FC<IAccaGateProps> = ({ login = new Login(), accaGateModel = new AccaGateModel() }) => {
  const [accPositions, setAccPositions] = useState<any[]>([]);

  useEffect(() => {
    if (accaGateModel.jobPosting && accaGateModel.jobPosting.length > 0) {
      const realDummy: any[] = [];
      let tmpDummy: any[] = [];
      accaGateModel.jobPosting.map((item, idx) => {
        if (idx % 3 === 0 && idx !== 0) {
          realDummy.push(tmpDummy);

          tmpDummy = [];
        }
        tmpDummy.push(item);
      });
      if (tmpDummy.length > 0) {
        realDummy.push(tmpDummy);
      }
      setAccPositions(realDummy);
    }
  }, [accaGateModel.jobPosting]);

  useEffect(() => {
    const init = async () => {
      await accaGateModel.loadAccCenterJobPosting();
    };
    init();
  }, []);

  return (
    <>
      <Frame>
        { !login.userInfo && Banner() }
        <h1>역량검사로 채용 중인 공고</h1>
      </Frame>

      {accPositions && accPositions.length > 0
        ? (
          <AccaGateRecruitFrame>
            <JDSwiper
              slidesPerView={1}
              centeredSlides
              paginationtype={PageNationType.bullets}
            >
              { accPositions && accPositions.length > 0 && accPositions.map((item) => (
                <JDSwiperSlide className='acca-custom-slide' key={`acca-recruit-${item[0].sn}}`}>
                  { item.map((innerItem: IAccCenterJobPosting) => (
                    <JobOpeningLogoCard
                      item={{
                        title: innerItem.title,
                        subTitle: innerItem.companyName,
                        date: innerItem.closingDateTime && DateFormatYYYYMMDDCCHHMMUnit(innerItem.closingDateTime),
                        image: innerItem.logoImageUrl,
                        dDate: innerItem.closingDateTime && getDDayString(str2DateTime(innerItem.closingDateTime)),
                        bannerLink: RoutePaths.jobs_posting(innerItem.sn),
                      }}
                    />
                  ))}
                </JDSwiperSlide>
              ))}
            </JDSwiper>
          </AccaGateRecruitFrame>
        )
        : (
          <MainNoPosition />
        )}
      <div />
      <Divider12G />
    </>
  );
};
export default inject(injectStore.login, injectStore.accaGateModel)(observer(AccaGateRecruit));
