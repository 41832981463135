/* eslint-disable react-hooks/exhaustive-deps */
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import ScrollUtil from 'utils/ScrollUtil';

const Frame = styled.div`

`;

const InfiniteButton = styled.button`
  margin: 32px auto;
  width: 328px;
  height:37px;
  padding: 0px 12px;
  border: 1px solid ${colors.JOBDA_BLACK};
  border-radius: 4px;
  color:${colors.JOBDA_BLACK};
  display:flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  font: ${Fonts.B3_Bold};
`;

interface ICustomInfiniteScroll extends IComponentProps {
  threshold?: number;
  initonInfinite?: boolean;
  onInfinite: () => void;
  onBeforeLoad?: () => void;
  onAfterLoad?: () => void;
  usingDownBtn?: boolean;
  downBtnName?:string;
}

const CustomInfiniteScroll: FC<ICustomInfiniteScroll> = ((props) => {
  const { threshold = 0, onInfinite, initonInfinite = true, downBtnName, children, onBeforeLoad = () => { }, onAfterLoad = () => { }, disabled, usingDownBtn = false } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [onInitActive] = useState<boolean>(false);

  const infiniteHandler = useCallback(async () => {
    if (isLoading === false) {
      // console.log('scrollHeight', (await ScrollUtil.getScrollHeight()));
      // console.log('(await ScrollUtil.getScrollTop()) + threshold', (await ScrollUtil.getScrollTop()));
      // console.log('(await ScrollUtil.getClientHeight() * 2)', (await ScrollUtil.getClientHeight()));
      const isBottom = (await ScrollUtil.getScrollHeight()) - ((await ScrollUtil.getScrollTop()) + threshold) <= (await ScrollUtil.getClientHeight() * 2);
      // console.log((await ScrollUtil.getScrollHeight()) - ((await ScrollUtil.getScrollTop()) + threshold), (await ScrollUtil.getClientHeight() * 2));
      if (isBottom) {
        setIsLoading(true);
      }
    }
  }, [disabled, onInitActive, isLoading]);

  useEffect(() => {
    const load = async () => {
      if (isLoading) {
        await onBeforeLoad();
        await onInfinite();
        setIsLoading(false);
      } else {
        await onAfterLoad();
      }
    };
    load();
  }, [isLoading]);

  useEffect(() => {
    if (initonInfinite) setIsLoading(true);
    if (!usingDownBtn) ScrollUtil.addEventListener(infiniteHandler);
    return () => {
      if (!usingDownBtn) ScrollUtil.removeEventListener(infiniteHandler);
    };
  }, []);

  return (
    <Frame>
      {
        children
      }
      {
        usingDownBtn && <InfiniteButton onClick={() => infiniteHandler()}>{downBtnName || '더보기'}</InfiniteButton>
      }
    </Frame>
  );
});

export default CustomInfiniteScroll;
