import { AxiosError } from 'axios';
import { IAccApplyDeleteRq } from 'interfaces/_v2/acc/IAccGate';
import { useMutation, useQuery } from 'react-query';
import { RemoteAccRepo } from 'repository/acc/AccRepo';

export const keys = ['v2', 'acc'];
export const accApplyKeys = ['acca', 'apply'];

const accRepo = new RemoteAccRepo();
const fetchAccDelete = async (rq:IAccApplyDeleteRq) => {
  const data = await accRepo.fetchAccDelete(rq);
  return data;
};

export function useAccDelete(onSuccess: () => void, onError?: (e: AxiosError) => void) {
  return useMutation((rq:IAccApplyDeleteRq) => fetchAccDelete(rq), {
    onSuccess,
    onError,
  });
}

const fetchAccApplyTrialUrl = async () => {
  try {
    const data = await accRepo.fetchAccApplyTrialUrl();
    // eslint-disable-next-line prefer-template
    const trialUrl = data.url + '/tutorial';
    return { ...data, url: trialUrl };
  } catch (error) {
    // 호출 실패 시 반환할 기본값 설정
    return { url: 'https://jobda.acca.ai/tutorial' };
  }
};

export const useAccApplyTrialUrl = () => {
  return useQuery([...accApplyKeys, 'trial', 'url'], fetchAccApplyTrialUrl, { staleTime: Infinity });
};
