import React from 'react';

import classnames from 'classnames/bind';
import styles from './explanation.module.scss';

const cx = classnames.bind(styles);

const Explanation = () => (
  <div className={cx('explanationArea')}>
    <div className={cx('detailInfoInner')}>
      <em className={cx('detailInfoTitle')}>
        실무와 유사한
        <br />
        개발 구현 능력 검사와
        <br />
        개발자 역량 진단을
        <br />
        경험하세요
      </em>
      <p className={cx('detailInfoDesc')}>
        JOBDA 개발 구현 능력 검사는 국내 최고의
        <br />
        알고리즘 전문가 ‘BAEKJOON’과
        <br />
        컴퓨터공학 석·박사 출신 개발 실무진이
        <br />
        직접 문제를 제작합니다.
      </p>
      <p className={cx('detailInfoDesc')}>
        개발자 역량검사는 우수한 개발자에게 필요한
        <br />
        핵심역량을 측정하고, 미래 성과 창출 가능성을
        <br />
        체계적인 측정을 통해 예측합니다.
      </p>
    </div>
  </div>
);

export default Explanation;
