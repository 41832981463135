import IconTermsBasic from 'assets/icon/icon_terms_basic.svg';
import IconTermsChecked from 'assets/icon/icon_terms_checked.svg';
import LazyImage from 'components/common/LazyImage';
import { IconArrowUpDown } from 'consts/assets/icons/iconPages';
import colors from '__designkit__/common/colors';
import Fonts, { fontStyle } from '__designkit__/common/fonts';
import ITermsDto from 'interfaces/terms/ITermsListRs';
import React, { FC } from 'react';
import styled from 'styled-components';

interface TermProps extends ITermsDto {
    idx: number;
    isChecked: boolean;
    isOpen: boolean;
    onCheck: (args: { idx: number }) => void;
    onOpen: (args: { idx: number }) => void;
}

const Frame = styled.div`
  width: 100%;
`;

const TextArea = styled.div<{ visible: boolean }>`
    display: ${(props) => (props.visible ? 'block' : 'none')};
    padding: 15px 24px 24px 24px;
    background: ${colors.CG_30};
    border-top: 1px solid ${colors.CG_40} ;
    margin-top: 16px;

    .text {
        
      div {
            font: ${Fonts.B2_Medium_P} !important;
            color: ${colors.CG_70};
        }

        span {
          font: ${Fonts.B2_Bold_P} !important;
          color: ${colors.CG_80};
          margin-bottom: 8px;
        }

        b {
            ${fontStyle.CAPTION1_MEDIUM} !important;
            margin-bottom: 4px !important;
            color: ${colors.CG_80};
        }

        p {
          font: ${Fonts.B2_Medium_P} !important;
          color: ${colors.CG_70};
        }
    }
`;

const Term:FC<TermProps> = ({ idx, contents, isChecked, isOpen, onCheck, onOpen, title }) => (
  <Frame>
    <label className='label'>
      <input
        type='checkbox'
        className='input'
        aria-hidden
        checked={isChecked}
        readOnly
      />
      <LazyImage onClick={() => onCheck({ idx })} src={isChecked ? IconTermsChecked : IconTermsBasic} className='mark' />
      <span className='title'>{title}</span>
      <div className='arrow-btn' role='button' onClick={() => onOpen({ idx })}>
        <IconArrowUpDown active={isOpen} />
      </div>
    </label>
    <TextArea visible={isOpen}>
      <div
        className='text'
        dangerouslySetInnerHTML={{ __html: contents }}
      />
    </TextArea>
  </Frame>
);

export default Term;
