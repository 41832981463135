import { yupResolver } from '@hookform/resolvers/yup';
import JDASearchInput from 'components/inputs/JDASearchInput';
import { SkillListItem } from 'components/_v2/profile/skill/ProfileSkill';
import { IProfileSkillProps } from 'components/_v2/profile/skill/ProfileSkillView';
import JDClosableChip from 'components/_v2/_common/chip/JDClosableChip';
import FormProfileValueTitle from 'components/_v2/_common/form/FormProfileValueTitle';
import JDSelector, { JDSelectorType } from 'components/_v2/_common/input/JDSelector';
import useToast from 'hooks/useToast';
import { IconPlus24 } from 'consts/assets/icons/iconPages';
import { JDAInputFrame } from 'consts/style/mixins';
import Fonts from '__designkit__/common/fonts';
import { JDButton } from 'consts/_v2/_common/style/mixins';
import { IExaminationGradeDto } from 'interfaces/rqrs/IExaminationGradeListRs';
import { IExaminationDto } from 'interfaces/rqrs/IExaminationListRs';
import { IProfileExaminationDto } from 'interfaces/_v2/profile/IProfileKnowledgeAndSkillsRs';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import ProfileSkillModel from 'models/_v2/profile/ProfileSkillModel';
import React, { ChangeEventHandler, FC, useRef, useState } from 'react';
import { FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { obj2DataAttribute } from 'utils/CommonUtils';
import * as yup from 'yup';

const Frame = styled.div`
    padding: 32px 16px;
    
    .frame-chips {
      display: flex;
      flex-wrap: wrap;
      margin-top: 16px;

      >div {
        margin-right: 8px;
        margin-bottom: 8px;
      }
    }

    .row-select-confirm {
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        align-items:center;
        margin-top: 12px;

        > *:first-child {
        flex:1;
        }
        .score-input{
        ${JDAInputFrame()};
        input{
            width:100%;
        }
        }
        
        .btn-confirm {
        ${JDButton(false)};
        font: ${Fonts.B2_Bold};
        line-height: 20px;
        margin-left:16px;
        width: 58px;
        padding:14px 16px;
        border-radius:4px;
        &:disabled {
            opacity: 0.5;
        }
        }
  }
  
`;

const examFormValidationSchema = yup.object().shape({
  code: yup.number().required(),
  name: yup.string().required(),
  grade: yup.string().when('gradeYn', {
    is: true,
    then: yup.string().required(),
  }),
  scoreYn: yup.boolean(),
  gradeYn: yup.boolean(),
  gradeCode: yup.number().when('gradeYn', {
    is: true,
    then: yup.number().required(),
  }),
});

const ProfileSkillExamination:FC<IProfileSkillProps> = ({ profileSkillModel = new ProfileSkillModel(), hidden }) => {
  const useFormed = useFormContext();
  const { watch, setValue, control, register } = useFormed;
  const examFormMethod = useForm<IProfileExaminationDto>({ mode: 'all', resolver: yupResolver(examFormValidationSchema) });
  const { formState: examFormFormState } = examFormMethod;
  const { isValid: isValidExamForm } = examFormFormState;
  const examinationFileds = useFieldArray<IProfileExaminationDto>({ control, name: 'examinations' });
  const reservedTimeout = useRef<number>(-1);
  const [examGrades, setExamGrades] = useState<IExaminationGradeDto[]>([]);
  const { setToastObject } = useToast();

  // 중복이 있다면 return : true
  const checkDuplication = (value: IProfileExaminationDto) => {
    const found = examinationFileds.fields.some((data) => Number(data.code) === value.code);
    return found;
  };

  const [score, setScore] = useState<number | undefined>(undefined);
  const onChangeInput: ChangeEventHandler<HTMLInputElement> = (e) => {
    // // 점수 0 ~ 9999점까지 입력 가능
    if (e.target.value === '') {
      setScore(undefined);
      return;
    }
    if (Number(e.target.value) > 9999) return;
    setScore(Number(e.target.value));
  };

  return (
    <>
      <Frame aria-hidden={hidden}>
        <FormProfileValueTitle>공인 어학시험</FormProfileValueTitle>
        <JDASearchInput
          className='input-search'
          name='examIdx'
          title='공인 어학시험'
          placeholder='시험명을 검색해 주세요.'
          onChange={async (searchText: string) => {
            if (reservedTimeout.current > -1)
              clearTimeout(reservedTimeout.current);
            reservedTimeout.current = setTimeout(() => {
              profileSkillModel.searchExaminations(searchText);
            }, 800) as unknown as number;
          }}
          listItemIcon={<IconPlus24 />}
          onSelectItem={async () => {
            const idx: number = watch('examIdx');
            const data: IExaminationDto = profileSkillModel.searchedExaminations[idx];
            const { code, name, gradeYn, scoreYn } = data;
            examFormMethod.setValue('code', code);
            examFormMethod.setValue('name', name);
            examFormMethod.setValue('gradeYn', gradeYn);
            examFormMethod.setValue('scoreYn', scoreYn);
            if (gradeYn) {
              try {
                setExamGrades((await profileSkillModel.getExaminationGrades(code)).examinationGrades);
              } catch (e) {
                console.error(e);
                alert('점수 조회에 실패했습니다. 다시 시도 해 주세요.');
                setExamGrades([]);
              }
            }
          }}
        >
          <SkillListItem>
            {
                profileSkillModel.searchedExaminations.map((exam, idx) => {
                  if (examinationFileds.fields.some((field) => field.code === exam.code)) return <></>;
                  return (
                    <li key={exam.code} value={idx} {...obj2DataAttribute(exam)}>{exam.name}</li>
                  );
                })
              }
          </SkillListItem>
        </JDASearchInput>
        <input type='text' aria-hidden name='code' ref={examFormMethod.register()} />
        <input type='text' aria-hidden name='name' ref={examFormMethod.register()} />
        <input type='text' aria-hidden name='gradeYn' ref={examFormMethod.register()} />
        <input type='text' aria-hidden name='scoreYn' ref={examFormMethod.register()} />

        <FormProvider {...examFormMethod}>
          <section className='row-select-confirm'>
            {
                `${examFormMethod.watch('gradeYn')}` === 'true' ? (
                  <>
                    <input type='text' aria-hidden name='grade' ref={examFormMethod.register()} />
                    <JDSelector
                      type={JDSelectorType.NORMAL}
                      selectTitle='수준을 선택해 주세요.'
                      name='gradeCode'
                      saveData={(text) => examFormMethod.setValue('grade', text)}
                    >
                      <select>
                        <option value='' hidden>수준을 선택해 주세요.</option>
                        {
                        examGrades.map(({ grade, code }) => (
                          <option
                            key={grade}
                            value={code}
                          >
                            {grade}
                          </option>
                        ))
                      }
                      </select>
                    </JDSelector>
                  </>
                ) : (
                  <div className='score-input'>
                    <input type='number' value={score} ref={examFormMethod.register()} onChange={onChangeInput} name='score' disabled={!examFormMethod.watch('code')} placeholder='점수를 입력해 주세요.' />
                  </div>
                )
              }
            <button
              disabled={!isValidExamForm || (`${examFormMethod.watch('scoreYn')}` === 'true' && score === undefined)}
              className='btn-confirm'
              onClick={examFormMethod.handleSubmit((value: IProfileExaminationDto) => {
                if (!checkDuplication(value)) {
                  examinationFileds.append(value);
                  examFormMethod.reset({});
                  setValue('examIdx', '');
                  setScore(undefined);
                } else {
                  setToastObject({ isOpen: true, type: 'ERROR', message: '이미 등록된 자격증이나 어학시험이 있습니다.', position: 'middle' });
                }
              })}
            >
              추가
            </button>
          </section>
        </FormProvider>
        <section className='frame-chips'>
          {
              examinationFileds.fields.map((field, idx) => (
                <React.Fragment key={`${field.code}-idx`}>
                  <input aria-hidden ref={register()} name={`examinations[${idx}].code`} defaultValue={field.code} />
                  <input aria-hidden ref={register()} name={`examinations[${idx}].name`} defaultValue={field.name} />
                  <input aria-hidden ref={register()} name={`examinations[${idx}].gradeYn`} defaultValue={`${field.gradeYn}`} />
                  <input aria-hidden ref={register()} name={`examinations[${idx}].scoreYn`} defaultValue={`${field.scoreYn}`} />
                  <input aria-hidden ref={register()} name={`examinations[${idx}].grade`} defaultValue={field.grade} />
                  <input aria-hidden ref={register()} name={`examinations[${idx}].score`} defaultValue={field.score} />
                  <input aria-hidden ref={register()} name={`examinations[${idx}].gradeCode`} defaultValue={field.gradeCode} />
                  <JDClosableChip onClose={() => examinationFileds.remove(idx)}>{`${field.name}-${`${field.gradeYn}` === 'true' ? `${field.grade}` : field.score}`}</JDClosableChip>
                </React.Fragment>
              ))
            }
        </section>
      </Frame>
    </>
  );
};

export default inject(injectStore.profileSkillModel)(observer(ProfileSkillExamination));
