/* eslint-disable import/prefer-default-export */
export function maskCompanyName(name: string): string {
  const hasCompanyPrefix = name.includes('(주)');
  const companyName = hasCompanyPrefix ? name.replace('(주)', '') : name;

  const length = companyName.length + (hasCompanyPrefix ? 1 : 0);

  if (length <= 2) {
    // 회사 이름이 2글자 이하인 경우, 첫 번째 글자만 표기하고 나머지 글자를 '*'로 대체합니다.
    return companyName[0] + '*'.repeat(length - 1);
  }
  // 회사 이름이 3~4글자인 경우, 앞의 2글자를 제외하고 모든 글자를 '*'로 대체합니다.
  return (
    (hasCompanyPrefix ? '(주)' : '')
      + companyName.slice(0, 2 - (hasCompanyPrefix ? 1 : 0))
      + '*'.repeat(Math.min(length - 2, 3))
  );
}
