import FormFiledFrame from 'components/_v2/_common/form/FormFiledFrame';
import FormProfileValueTitle from 'components/_v2/_common/form/FormProfileValueTitle';
import JDBaseInput from 'components/_v2/_common/input/JDBaseInput';
import JDSelector, { JDSelectorType } from 'components/_v2/_common/input/JDSelector';
import { VeteranTagStr, VeteranType } from 'consts/_v2/profile/VeteranType';
import { IProfileProps } from 'interfaces/_v2/profile/IProfileProps';
import { inject, observer } from 'mobx-react';
import ProfileModel from 'models/_v2/profile/ProfileModel';
import { injectStore } from 'models/store';
import React, { FC } from 'react';
import { ArrayField, useFormContext } from 'react-hook-form';

interface IProfilePreferentialVeteranProps extends IProfileProps {
    veteranFields: Partial<ArrayField<Record<string, any>, 'id'>>[];
}

const ProfilePreferentialVeteran:FC<IProfilePreferentialVeteranProps> = ({ veteranFields, profileModel = new ProfileModel() }) => {
  const useFormed = useFormContext();
  const { watch, setValue } = useFormed;

  return (
    <>
      { veteranFields.map((filed) => (
        <div key={filed.id}>
          <FormFiledFrame>
            <FormProfileValueTitle required>보훈 사항</FormProfileValueTitle>
            <JDSelector
              name='veteran.status'
              selectTitle='보훈 여부'
              type={JDSelectorType.NORMAL}
              className='selector'
              value={profileModel.preferential?.veteran?.status || ''}
              onChange={() => {
                if (watch('veteran.status') === VeteranType.NONE) {
                  setValue('veteran.reason', '');
                }
              }}
            >
              <select>
                <option value='' hidden>보훈 여부</option>
                {
                  (Object.keys(VeteranType) as Array<keyof typeof VeteranType>).map((key) => (
                    <option key={key} value={key} selected={`${watch('veteran.status')}` === key}>
                      {VeteranTagStr[key]}
                    </option>
                  ))
                }
              </select>
            </JDSelector>
            <JDBaseInput
              placeholder='대상 사유를 입력해 주세요.'
              name='veteran.reason'
              maxLength={35}
              className='baseInput'
              disabled={watch('veteran.status') === VeteranType.NONE || !watch('veteran.status')}
              defaultValue={profileModel.preferential?.veteran?.reason || ''}
            />
          </FormFiledFrame>
        </div>
      ))}
    </>
  );
};

export default inject(injectStore.profileModel)(observer(ProfilePreferentialVeteran));
