export enum MatchApplyStatusType {
    RECOMMEND = 'RECOMMEND', // 추천
    PROFILE_OPEN = 'PROFILE_OPEN', // 열람
    OFFER = 'OFFER', // 제안
    OFFER_CLOSED = 'OFFER_CLOSED', // 제안 마감
  }

export enum MatchResponseType {
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
    UNANSWERED = 'UNANSWERED', // 미응답
  }

export enum MatchTabType {
    ALL = 'ALL',
    APPLICATION = 'APPLICATION',
    IN_PROGRESS = 'IN_PROGRESS',
    END = 'END',
    WRITING = 'WRITING',
  }
