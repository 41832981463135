/* eslint-disable jsx-a11y/media-has-caption */
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import JDFullModal from 'components/_v2/_common/modals/JDFullModal';
import PassInterviewAnswer from 'components/_v2/passInterview/PassInterviewAnswer';
import { PassInterviewBanner } from 'components/_v2/passInterview/PassInterviewHistory';
import { RecruitmentTypesText } from 'consts/_v2/calendar/CalendarFilterType';
import { QuestionHelpContentTypeCode, QuestionTypeCode } from 'interfaces/_v2/passInterview/IPassInterviewRs';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import { injectStore } from 'models/store';
import { usePassInterviewQuestionResults } from 'query/passInterview/usePassInterviewQuery';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import ImgJoyEmpty from 'assets/_v2/positionList/img_character_joy_empty.png';

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
  height: 56px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 0 8px;
  background: ${Colors.WHITE_100};

`;

interface IPassInterviewDetailModal{
    isOpen:boolean
    onClose:()=>void
    selectedSn:number;
    context?:Context
}
const Frame = styled.div`
    padding:12px 16px 68px 16px;
    .img{
        width: 100%;
        height: auto;
        border-radius: 8px;
        background-color: ${Colors.CG_30};

    }
`;

const EmptyFrame = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.CG_30};
  padding: 48px 0;
  align-items: center;
  justify-content: center;
  gap:16px;
  border-radius: 8px;
  .img{
    width: 80px;
    height: 80px;
  }
  .empty-text{
    font:${Fonts.H4_Bold};
    color:${Colors.JOBDA_BLACK};
    text-align: center;
  }
`;
const QuestionFrame = styled.div`
    display: flex;
    width: 100%;
    padding:24px 16px;
    background-color: ${Colors.CG_30};
    border-radius: 8px;
    gap: 8px;
    flex-direction: column;
    .question-frame{
        display: flex;
        gap: 8px;
        .question-mark{
            font:${Fonts.B1_Medium_P};
            color:${Colors.G_150};
        }
        .question-title{
            font:${Fonts.B1_Bold_P};
            color:${Colors.JOBDA_BLACK};
        }
        
    }
    .question-type{
        display: flex;
        gap: 8px;
        align-items: center;
        .divider{
            width: 1px;
            height: 8px;
            background-color: ${Colors.CG_40};
        }
        .text{
            font:${Fonts.B3_Medium};
            color:${Colors.CG_60};
        }
    }
`;

const PassInterviewDetailModal:FC<IPassInterviewDetailModal> = ({ isOpen, onClose, selectedSn, context = new Context() }) => {
  const [videoUrl, setVideoUrl] = useState<string>('');
  const [badgeList, setBadgeList] = useState<string[]>([]);
  const { data: passInterviewPracticeResults } = usePassInterviewQuestionResults(selectedSn);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const questionIntention = passInterviewPracticeResults?.helpContents?.[QuestionHelpContentTypeCode.QUESTION_PURPOSE] ?? [];
  const answerGuide = passInterviewPracticeResults?.helpContents?.[QuestionHelpContentTypeCode.ANSWER_GUIDE] ?? [];
  const warningPoint = passInterviewPracticeResults?.helpContents?.[QuestionHelpContentTypeCode.CAUTION] ?? [];
  const goodAnswers = passInterviewPracticeResults?.helpContents?.[QuestionHelpContentTypeCode.GOOD_ANSWER] ?? [];
  const badAnswers = passInterviewPracticeResults?.helpContents?.[QuestionHelpContentTypeCode.BAD_ANSWER] ?? [];
  const similarAnswers = passInterviewPracticeResults?.helpContents?.[QuestionHelpContentTypeCode.SIMILAR_QUESTION_ANSWER] ?? [];

  const questionIntentionRenderable = questionIntention.length > 0;
  const answerGuideRenderable = answerGuide.length > 0;
  const goodAnswersRenderable = goodAnswers.length > 0;
  const badAnswersRenderable = badAnswers.length > 0;
  const similarPointRenderable = similarAnswers.length > 0;
  const warningPointRenderable = warningPoint.length > 0;

  const isEmpty = !questionIntentionRenderable
    && !answerGuideRenderable
    && !goodAnswersRenderable
    && !badAnswersRenderable
    && !similarPointRenderable
    && !warningPointRenderable;

  useEffect(() => {
    if (passInterviewPracticeResults) {
      setVideoUrl(passInterviewPracticeResults.answerVideoUrl);
      const tmpLst:string[] = [];
      if (passInterviewPracticeResults.badgeTitle) {
        tmpLst.push(passInterviewPracticeResults.badgeTitle);
      }
      if (passInterviewPracticeResults.description) {
        tmpLst.push(passInterviewPracticeResults.description);
      }
      if (passInterviewPracticeResults.jobGroupCode) {
        const jobGroups = context.jobGroupsWithTitles.find((jobGroup) => jobGroup.code === passInterviewPracticeResults.jobGroupCode)?.name;
        if (jobGroups) {
          tmpLst.push(jobGroups);
        }
      }
      if (passInterviewPracticeResults.jobTypeCode) {
        tmpLst.push(RecruitmentTypesText[passInterviewPracticeResults.jobTypeCode]);
      }
      if (passInterviewPracticeResults.type) {
        tmpLst.push(passInterviewPracticeResults.type);
      }
      setBadgeList(tmpLst);
    }
    return () => {
      setVideoUrl('');
    };
  }, [selectedSn, passInterviewPracticeResults]);
  if (!isOpen) return <></>;
  const checkVideoFormat = (url:string) => {
    if (url.includes('.webm')) {
      return true;
    } if (url.includes('.mp4')) {
      return false;
    }
    return null;
  };
  return (
    <JDFullModal>
      <Header>
        <Icon name='close' size={32} color={Colors.JOBDA_BLACK} onClick={onClose} />
      </Header>
      <Frame>
        <PassInterviewBanner>
          <div className='text-frame'>
            <div className='title'>면접 연습기록 리스트</div>
            <div className='detail'>{isSafari ? '연습 영상은 PC에서만 확인 가능합니다.' : '면접 연습은 PC에서만 가능합니다.'}
            </div>
          </div>
          <Icon name='idea' size={64} />
        </PassInterviewBanner>
        <SpacingBlock size={24} vertical />
        {
          videoUrl
            ? (
              checkVideoFormat(videoUrl) ? (
                <video className='img' playsInline controls loop>
                  <source
                    src={`${videoUrl}#t=0.001`}
                    type='video/webm'
                  />
                </video>
              ) : (
                <video className='img' playsInline controls loop>
                  <source
                    src={`${videoUrl}#t=0.001`}
                    type='video/mp4'
                  />
                </video>
              )

            )
            : <img className='img' alt='empty' />
        }
        <SpacingBlock size={12} vertical />
        <QuestionFrame>
          <div className='question-type'>
            {
              badgeList.map((badge, index) => (
                <React.Fragment key={index}>
                  <div className='text'>{badge}</div>
                  {index !== badgeList.length - 1 && <div className='divider' />}
                </React.Fragment>
              ))
            }
          </div>
          <div className='question-frame'>
            <div className='question-mark'>Q.</div>
            <div className='question-title'>{passInterviewPracticeResults?.questionText}</div>
          </div>

        </QuestionFrame>
        <SpacingBlock size={12} vertical />
        {
      answerGuideRenderable && (
        <PassInterviewAnswer
          type={QuestionHelpContentTypeCode.ANSWER_GUIDE}
          value={answerGuide[0].value}
          strength={passInterviewPracticeResults?.type}
        />
      )
     }
        {
      questionIntentionRenderable && (
        <PassInterviewAnswer
          type={QuestionHelpContentTypeCode.QUESTION_PURPOSE}
          value={questionIntention[0].value}
        />
      )
     }
        {
      goodAnswersRenderable && (
        <PassInterviewAnswer
          type={QuestionHelpContentTypeCode.GOOD_ANSWER}
          title={goodAnswers[0].title}
          value={goodAnswers[0].value}
        />
      )
     }
        {
      badAnswersRenderable && (
        <PassInterviewAnswer
          type={QuestionHelpContentTypeCode.BAD_ANSWER}
          title={badAnswers[0].title}
          value={badAnswers[0].value}
        />
      )
     }
        {
      similarPointRenderable && (
        <PassInterviewAnswer
          type={QuestionHelpContentTypeCode.SIMILAR_QUESTION_ANSWER}
          title={similarAnswers[0].title}
          value={similarAnswers[0].value}
        />
      )
     }
        {
      warningPointRenderable && (
        <PassInterviewAnswer
          type={QuestionHelpContentTypeCode.CAUTION}
          value={warningPoint[0].value}
        />
      )
     }
        {
      isEmpty && (
        <EmptyFrame>
          <img src={ImgJoyEmpty} className='img' alt='' />
          <div className='empty-text'>내가 만든 질문은 분석과<br />답변 가이드 제공이 어려워요</div>
        </EmptyFrame>
      )
     }
      </Frame>
    </JDFullModal>
  );
};
export default inject(injectStore.context)(observer(PassInterviewDetailModal));
