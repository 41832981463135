import { Preferences } from '@capacitor/preferences';
import jsCookie from 'js-cookie';
import MobileStore from 'store/mobileStore';

export default class CommonUtils {
  static clearLocalStorage = () => localStorage.clear();

  static getLocalStorage = (cname: string): any | null => localStorage.getItem(cname);

  static setLocalStorage = (cname: string, cvalue: any) => {
    localStorage.setItem(cname, cvalue);
  };

  static parseQuery = (queryString: string) => {
    const query: any = {};
    const pairs: string[] = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }

    return query;
  }

  static getCookieDomain = () => {
    const currentURL = window.location.origin;
    const domainParts = currentURL.split('.');

    if (domainParts.length >= 3) {
      return `.${domainParts.slice(-2).join('.')}`;
    }
    return '';
  };

  static cropByColumnSize = (arr: any[], columnSize: number = 4) => {
    const columns = Math.floor(arr.length / columnSize);
    if (arr.length % columnSize > 0 && columns > 0)
      arr.length = columns * columnSize;
  }

  static isDevEnv = () => ['development', 'staging', 'staging2'].includes(process.env.REACT_APP_ENV as string);

  static chunkify = <T extends any>(arr: T[], chunk: number = 3): T[][] => {
    const result: T[][] = [];
    let itrIdx = 0;
    while (itrIdx < arr.length) {
      result.push(arr.slice(itrIdx, itrIdx + chunk));
      itrIdx += chunk;
    }
    return result;
  }

  static debounceId: number = -1;
}

export const serializeQueryString = (jsonObj: any) => {
  // console.log(jsonObj);
  let str: string = '?';
  const sliceObj = [];
  for (const key in jsonObj) {
    if (jsonObj.hasOwnProperty(key) && jsonObj[key] !== undefined) {
      sliceObj.push(key);
    }
  }

  let size: number = sliceObj.length;
  if (size === 0) return '';

  for (const key in sliceObj) {
    str += `${sliceObj[key]}=${jsonObj[sliceObj[key]]}${--size ? '&' : ''}`;
  }

  return str;
};

export const htmlDecode = (_html: string) => {
  const div = document.createElement('div');
  div.innerHTML = _html;
  return div.innerText;
};

export const clearUndefined = (obj: Object) => Object.entries(obj).reduce((acc, entry) => (
  entry[1] !== undefined && entry[1] !== null && entry[1] !== ''
    ? ({ ...acc, [entry[0]]: entry[1] })
    : acc
), {});

export const isNotUndefinedAndNotNull = (value?: any): boolean => value !== null && value !== undefined; //* * 0 거르기 위해.. */

export const getAgeUsingBirth = (birth: string) => {
  const dateString = birth.replace(/-/g, '');
  const birthDate = new Date(Number(dateString.substring(0, 4)), Number(dateString.substring(4, 6)), Number(dateString.substring(6, 8)));
  const ageDifMs = Date.now() - birthDate.getTime();
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const getAgeUsingBirthAndGender = (birth: string, gender: string) => {
  let dateString;
  if (gender === '1' || gender === '2') {
    dateString = `19${birth}`;
  } else {
    dateString = `20${birth}`;
  }
  const birthDate = new Date(Number(dateString.substring(0, 4)), Number(dateString.substring(4, 6)), Number(dateString.substring(6, 8)));
  const ageDifMs = Date.now() - birthDate.getTime();
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const obj2DataAttribute = (obj:Object) => Object.entries(obj).reduce((acc, [key, value]) => ({ ...acc, [`data-${key}`]: value }), {});

export const debounce = (fn: any, delay: number) => {
  let timerId: number = -1;
  clearTimeout(CommonUtils.debounceId);
  timerId = window.setTimeout(fn, delay);
  CommonUtils.debounceId = timerId;
};
export const debounceKey = (() => {
  const debounceMap: Map<string, number> = new Map();

  return (fn: any, delay: number) => (key: string, ...args: any[]) => {
    const timerId = debounceMap.get(key);
    if (timerId) {
      clearTimeout(timerId);
    }

    const newTimerId = window.setTimeout(() => {
      fn(...args);
      debounceMap.delete(key);
    }, delay);

    debounceMap.set(key, newTimerId);
  };
})();

export const saveSession = async (key: string, value: any) => {
  if (MobileStore.isMobile) {
    await Preferences.set({ key, value });
  } else {
    jsCookie.set(key, value);
  }
};

export const getSession = async (key: string) => {
  let res;
  if (MobileStore.isMobile) {
    res = (await Preferences.get({ key })).value || '';
  } else {
    res = jsCookie.get(key) || '';
  }
  return res;
};

export const removeSession = async (key: string) => {
  if (MobileStore.isMobile) {
    await Preferences.remove({ key });
  } else {
    jsCookie.remove(key);
  }
};

export function notEmptyFilter<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export const shuffleArray = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const getCommaNumber = (value: number) => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
