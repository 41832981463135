import IComponentProps from 'interfaces/props/IComponentProps';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Channel } from 'talkplus-sdk';
import ChannelItem from 'components/_v2/chatting/ChannelItem';
import { MyChannel } from 'interfaces/_v2/chatting/IChattingRqRs';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  height: fit-content;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

interface IChannelListProps extends IComponentProps {
  items: (Channel | MyChannel)[];
  lastMessage?: boolean;
}

const ChannelList: FC<IChannelListProps> = ({ items, lastMessage = false }) => (
  <Frame>
    { items && items.length > 0 && items.map((item) => <ChannelItem key={item.id} item={item} lastMessage={lastMessage} />) }
  </Frame>
);

export default ChannelList;
