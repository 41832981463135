import JDBaseModal, { IJDBaseModal } from 'components/_v2/_common/modals/JDBaseModal';
import { JDButtonTwoChoiceFrame, JDModalBasicFrame, JDModalButton, JDModalButtonGhost, JDModalDescription, JDModalTitle } from 'components/_v2/_common/modals/JDModalComponents';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import React, { FC } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
  z-index: 10006;
  ${JDModalTitle} {
    color: ${colors.JOBDA_BLACK};
    margin-bottom: 8px;
  }

  ${JDButtonTwoChoiceFrame} {
    margin-top: 24px;
  }

  ${JDModalButtonGhost} {
    font: ${Fonts.B1_Bold};
    line-height: 22px;
    margin-right: 16px;
  }

  ${JDModalButton} {
    font: ${Fonts.B1_Bold};
    line-height: 22px;
  }
`;
const JDModalTitleWrap = styled(JDModalTitle)`
  white-space: pre-wrap;
`
const ProfileMoveDirtyModal:FC<IJDBaseModal> = ({ isOpen, onClickClose, onSuccess }) => (
  <JDBaseModal dimmed isOpen={isOpen} onClickClose={onClickClose} isDimClickClose>
    <Frame>
      <JDModalBasicFrame>
        <JDModalTitleWrap>{`저장되지 않은 내용이 있어요.\n 페이지에서 나가시겠어요?`}</JDModalTitleWrap>
        <JDModalDescription>저장되지 않은 정보는 사라집니다.</JDModalDescription>
        <JDButtonTwoChoiceFrame>
          <JDModalButtonGhost onClick={onClickClose}>취소</JDModalButtonGhost>
          <JDModalButton onClick={onSuccess}>나가기</JDModalButton>
        </JDButtonTwoChoiceFrame>
      </JDModalBasicFrame>
    </Frame>
  </JDBaseModal>
);

export default ProfileMoveDirtyModal;
