import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Shadows from '__designkit__/common/shadows';
import Icon from '__designkit__/icon/Icon';
import ChattingAPI from 'api/chattingAPI';
import SimpleConfirmModal from 'components/_v2/_common/modals/SimpleConfirmModal';
import { TOAST_TYPE } from 'components/_v2/_common/toast/JDToast';
import RoutePaths from 'consts/RoutePaths';
import useToast from 'hooks/useToast';
import { MyChannel } from 'interfaces/_v2/chatting/IChattingRqRs';
import IComponentProps from 'interfaces/props/IComponentProps';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import ChattingModel from 'models/_v2/ChattingModel';
import { injectStore } from 'models/store';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

const Frame = styled.div`
  position: relative;
  min-width: 32px;
  min-height: 32px;
  width: fit-content;
  height: fit-content;
`;

const IconFrame = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: fit-content;
  height: fit-content;
  border-radius: 4px;
  overflow: hidden;
  transition: .2s;
  z-index: 2;

  &.close {
    transform: scale(0);
  }

  &:active {
    background-color: ${Colors.CG_30};
  }
`;

const MenuFrame = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: fit-content;
  height: fit-content;
  border: 1px solid ${Colors.CG_40};
  border-radius: 8px;
  background-color: ${Colors.WHITE_100};
  filter: ${Shadows.Shadow_200};
  overflow: hidden;
  transition: .2s;
  transform-origin: calc(100% - 12px) 20px;
  z-index: 1;

  &.close {
    transform: scale(0);
  }
`;

const MenuList = styled.div`
  width: 100%;
  height: fit-content;
`;

const MenuItem = styled.div`
  width: 100%;
  height: fit-content;
  padding: 12px 20px 12px 16px;
  font: ${Fonts.B2_Medium};
  color: ${Colors.CG_70};
  white-space: nowrap;

  &.error {
    color: ${Colors.ERROR};
  }

  &:active {
    background-color: ${Colors.CG_30};
  }
`;

interface IChattingRoomMenuProps extends IComponentProps {
  context?: Context;
  chattingModel?: ChattingModel;
}

const ChattingRoomMenu: FC<IChattingRoomMenuProps> = ({ context = new Context(), chattingModel = new ChattingModel() }) => {
  const history = useHistory();
  const { setToastObject } = useToast();
  const [isClose, setIsClose] = useState(false);
  const [isOpenLeaveConfirmModal, setIsOpenLeaveConfirmModal] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const onClickAlramOnOff = async () => {
    try {
      if (chattingModel.currentChannel!.notificationEnabled) {
        await ChattingAPI.updateChannelNotificationWhether(chattingModel.currentChannel!.id, false);
        setToastObject({ isOpen: true, type: TOAST_TYPE.INFO, message: '알림이 꺼졌어요.', subMessage: '새 메시지, 공고를 쉽게 확인하고 싶다면 알림을 켜주세요.', duration: 3000 });
      } else {
        await ChattingAPI.updateChannelNotificationWhether(chattingModel.currentChannel!.id, true);
        setToastObject({ isOpen: true, type: TOAST_TYPE.INFO, message: '알림이 켜졌어요.', subMessage: '이제 모바일 앱을 통해 새 메시지, 공고를 실시간으로 받아보세요.', duration: 3000 });
      }
      const temp: MyChannel = chattingModel.currentChannel as MyChannel;
      temp.notificationEnabled = !temp.notificationEnabled;
      chattingModel.setCurrentChannel(temp);
      setIsClose(false);
    } catch (e) {
      const err = e as { response: { data: { message: string } } };
      setToastObject({ isOpen: true, type: 'ERROR', message: err.response.data.message, duration: 2000 });
    }
  };

  const onClickLeave = () => {
    setIsOpenLeaveConfirmModal(true);
    setIsClose(false);
  };

  const onTouchEvent = useCallback((e) => {
    if (menuRef.current && !menuRef.current.contains(e.target) && isClose) setIsClose(false);
  }, [isClose]);

  useEffect(() => {
    if (isClose) {
      document.addEventListener('touchstart', onTouchEvent);
    } else {
      document.removeEventListener('touchstart', onTouchEvent);
    }
  }, [isClose, onTouchEvent]);

  return (
    <Frame>
      <MenuFrame className={isClose ? '' : 'close'} ref={menuRef}>
        <MenuList>
          <MenuItem onClick={onClickAlramOnOff}>{ chattingModel.currentChannel?.notificationEnabled ? '알림 끄기' : '알림 켜기' }</MenuItem>
          <MenuItem className='error' onClick={onClickLeave}>채팅방 나가기</MenuItem>
        </MenuList>
      </MenuFrame>
      <IconFrame
        className={isClose ? 'close' : ''}
        onClick={(e) => {
          e.stopPropagation();
          setIsClose(!isClose);
        }}
      >
        <Icon name='more-horizon' size={32} color={Colors.JOBDA_BLACK} />
      </IconFrame>
      <SimpleConfirmModal
        isOpen={isOpenLeaveConfirmModal}
        title='채팅방을 나가시겠어요?'
        subTitle='해당 채팅방이 내 채팅에서 사라집니다.'
        cancelBtnText='취소'
        confirmBtnText='나가기'
        onClickCancel={() => setIsOpenLeaveConfirmModal(false)}
        onClickConfirm={() => {
          chattingModel.onClickChattingLeaveBtn();
          setIsOpenLeaveConfirmModal(false);
          if (context.previousPathList.length > 1) history.goBack();
          else history.replace(RoutePaths.root);
        }}
      />
    </Frame>
  );
};

export default inject(injectStore.chattingModel, injectStore.context)(observer(ChattingRoomMenu));
