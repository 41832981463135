import { ReactComponent as IconPlus } from 'assets/icon/icon_plus_24.svg';
import JDRegisterItemButton from 'components/_v2/_common/button/JDRegisterItemButton';
import JDClosableChip from 'components/_v2/_common/chip/JDClosableChip';
import JDBaseInput from 'components/_v2/_common/input/JDBaseInput';
import JDCheckInput, { JD_CHECK_INPUT_TYPE } from 'components/_v2/_common/input/JDCheckInput';
import JDDatePickerInput from 'components/_v2/_common/input/JDDatePickerInput';
import JDDescriptionInput from 'components/_v2/_common/input/JDDescriptionInput';
import JDFileUpload from 'components/_v2/_common/input/JDFileUpload';
import JDInnerButtonInput from 'components/_v2/_common/input/JDInnerButtonInput';
import JDRadioInput, { JD_RADIO_INPUT_TYPE } from 'components/_v2/_common/input/JDRadioInput';
import JDSelector, { JDSelectorType } from 'components/_v2/_common/input/JDSelector';
import JDTimerInput from 'components/_v2/_common/input/JDTimerInput';
import JDSlider from 'components/_v2/_common/input/rang/JDSlider';
import SalaryMultiRange, { ICondition } from 'components/_v2/_common/input/rang/SalaryMultiRange';
import colors from '__designkit__/common/colors';
import { PositionOrderType, PositionOrderTypeStr } from 'consts/_v2/position/PositionType';
import Fonts from '__designkit__/common/fonts';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import styled from 'styled-components';

// ** form..
const Frame = styled.div`
  width: 100%;
  padding-top: 20px;
  padding-bottom: 50px;
  form{
    margin:0px 16px;
  }

  button{
    background:${colors.c232323};
    width: 80px;
    height: 20px;
    color:white;
    &.error{
      background: ${colors.cE93C3C};
    }
  }

  .title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    h1{
      font: ${Fonts.B2_Bold};
      margin: 16px 0px;
    }
  }

  .list{
    >*{
      margin-bottom:12px;
    }
  }
`;

const min = 0;
const max = 10000;
const dots = [
  { value: min, label: '0원' },
  { value: 2000, label: '' },
  { value: 4000, label: '' },
  { value: 6000, label: '' },
  { value: 8000, label: '' },
  { value: max, label: '2억원' },
];

const CustomInputPage: FC = () => {
  const useFormed = useForm({ mode: 'onSubmit' });
  const { watch, control, handleSubmit, setError, errors } = useFormed;
  const closeableChipArray = useFieldArray({ control, name: 'closeableArray' });
  const [registerItemMode, setRegisterItemMode] = useState<boolean>(false);
  const [salary, setSalary] = useState<number>(0);
  const [condition, setCondition] = useState<ICondition>({
    minValue: min,
    maxValue: max,
    requirementType: 'PREFERENTIAL',
  });

  useEffect(() => {
    closeableChipArray.append({ value: 1 });
  }, []);

  const onSubmit = useCallback(async (data?: any) => {
    alert(JSON.stringify(data));
  }, []);

  const onError = useCallback((data) => {
    alert(JSON.stringify(data));
  }, []);

  return (
    <Frame>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <button type='submit'>제출</button>

        <FormProvider {...useFormed}>
          <div className='title'>
            <h1>JDBaseInput</h1>
            <button
              type='button'
              className='error'
              onClick={() => setError('baseInput', {
                type: 'required',
                message: '에러 메세지',
              })}
            >에러
            </button>
          </div>
          <JDBaseInput
            name='baseInput'
            placeholder='기본 베이스 인풋'
            maxLength={25}
            defaultMessage='디폴트 메세지'
          />
          <div className='title' style={{ marginTop: '50px' }}>
            <h1>셀렉트 시리즈</h1>
          </div>
          <div className='title'>
            <h1>JDSelector (JDSelectorType.NORMAL)</h1>
            <button
              type='button'
              className='error'
              onClick={() => setError('JDSelectorType_NORMAL', {
                type: 'required',
                message: '에러 메세지',
              })}
            >에러
            </button>
          </div>
          <JDSelector
            selectTitle='정렬'
            name='JDSelectorType_NORMAL'
            type={JDSelectorType.NORMAL}
            onChange={async () => {
              const value = watch('JDSelectorType_NORMAL');
              console.log(value);
            }}
          >
            <select>
              <option value='' hidden disabled>정렬 방식 선택해 주세요.</option>
              {
                (Object.keys(PositionOrderType) as Array<keyof typeof PositionOrderType>).map((key, idx) => (
                  <option key={`${key}-idx`} value={key}>{PositionOrderTypeStr[key]}</option>
                ))
              }
            </select>
          </JDSelector>
          <div className='title'>
            <h1>JDSelector (JDSelectorType.ORDER)</h1>
          </div>
          <JDSelector
            selectTitle='정렬'
            name='JDSelectorType_ORDER'
            type={JDSelectorType.ORDER}
            onChange={async () => {
              const value = watch('JDSelectorType_ORDER');
              console.log(value);
            }}
          >
            <select>
              {
                (Object.keys(PositionOrderType) as Array<keyof typeof PositionOrderType>).map((key, idx) => (
                  <option key={`${key}-idx`} value={key}>{PositionOrderTypeStr[key]}</option>
                ))
              }
            </select>
          </JDSelector>
          <div className='title'>
            <h1>JDSelector (JDSelectorType.MEDIUM)</h1>
          </div>
          <JDSelector
            selectTitle='정렬'
            name='JDSelectorType_MEDIUM'
            type={JDSelectorType.MEDIUM}
            onChange={async () => {
              const value = watch('JDSelectorType_MEDIUM');
              console.log(value);
            }}
          >
            <select>
              {
                (Object.keys(PositionOrderType) as Array<keyof typeof PositionOrderType>).map((key, idx) => (
                  <option key={`${key}-idx`} value={key}>{PositionOrderTypeStr[key]}</option>
                ))
              }
            </select>
          </JDSelector>
          <div className='title'>
            <h1>JDSelector (JDSelectorType.SMALL)</h1>
          </div>
          <JDSelector
            selectTitle='정렬'
            name='JDSelectorType_SMALL'
            type={JDSelectorType.SMALL}
            onChange={async () => {
              const value = watch('JDSelectorType_SMALL');
              console.log(value);
            }}
          >
            <select>
              {
                (Object.keys(PositionOrderType) as Array<keyof typeof PositionOrderType>).map((key, idx) => (
                  <option key={`${key}-idx`} value={key}>{PositionOrderTypeStr[key]}</option>
                ))
              }
            </select>
          </JDSelector>
          <div className='title' style={{ marginTop: '50px' }}>
            <h1>체크 시리즈</h1>
          </div>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2,1fr)', alignItems: 'center' }}>

            <div className='title'>
              <h1>네모 체크 박스</h1>
            </div>
            {/* label <span>으로 감싸기 em 도 넣을 수 있음 */}
            <JDCheckInput
              type={JD_CHECK_INPUT_TYPE.CHECK_BOX}
              name='square_checkbox'
              value
              label={<span>네모 {watch('square_checkbox') ? 'true' : 'false'}</span>}
            />
            <div className='title'>
              <h1>커스텀 체크 칩</h1>
            </div>
            <JDCheckInput
              type={JD_CHECK_INPUT_TYPE.CUSTOM_CHIP}
              name='context_box'
              value
              label={<div>자유디자인 {watch('context_box') ? 'true' : 'false'}</div>}
            />
            <div className='title'>
              <h1>둥근 체크 칩</h1>
            </div>
            <JDCheckInput
              type={JD_CHECK_INPUT_TYPE.ROUND_CHIP}
              name='check_chip'
              value
              label={(<div>체크칩 {watch('check_chip') ? 'true' : 'false'}</div>)}
            />
            <div className='title'>
              <h1>네모 체크 칩</h1>
            </div>
            <JDCheckInput
              type={JD_CHECK_INPUT_TYPE.SQUARE_CHIP}
              name='square_check_chip'
              value
              label={(<div>네모 체크칩 {watch('square_check_chip') ? 'true' : 'false'}</div>)}
            />
          </div>
          <div className='title'>
            <h1>삭제 칩</h1>
            <button
              type='button'
              className='error'
              onClick={() => closeableChipArray.append({ value: closeableChipArray.fields.length + 1 })}
            >추가
            </button>
          </div>
          {closeableChipArray.fields.map(({ value }, idx) => <JDClosableChip onClose={() => closeableChipArray.remove(idx)}>{value}</JDClosableChip>)}
          <div className='title' style={{ marginTop: '30px' }}>
            <h1>파일 제출 컴포넌트(로그인 필수)</h1>
          </div>
          <JDFileUpload
            name='attachFiles'
            placeholder='첨부파일을 추가해 주세요.'
            accept='application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation'
            type={['application/pdf', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.slideshow', 'application/vnd.openxmlformats-officedocument.presentationml.presentation']}
          />
          <div className='title' style={{ marginTop: '50px' }}>
            <h1>라디오 시리즈</h1>
          </div>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3,1fr)', alignItems: 'center' }}>
            <div className='title'>
              <h1>네모 라디오 칩</h1>
            </div>
            <JDRadioInput
              type={JD_RADIO_INPUT_TYPE.SQUARE_CHIP}
              name='square_radio_chip'
              value='radio 1'
              label={(<div>네모라디오1 {watch('square_radio_chip') === 'radio 1' ? 'true' : 'false'}</div>)}
            />
            <JDRadioInput
              type={JD_RADIO_INPUT_TYPE.SQUARE_CHIP}
              name='square_radio_chip'
              value='radio 2'
              label={(<div>네모라디오2 {watch('square_radio_chip') === 'radio 2' ? 'true' : 'false'}</div>)}
            />
          </div>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3,1fr)', alignItems: 'center' }}>
            <div className='title'>
              <h1>둥근 라디오 박스</h1>
            </div>
            <JDRadioInput
              type={JD_RADIO_INPUT_TYPE.ROUND_BOX}
              name='round_radio_box'
              value='radio 1'
              label={(<div>둥근 라디오1 {watch('round_radio_box') === 'radio 1' ? 'true' : 'false'}</div>)}
            />
            <JDRadioInput
              type={JD_RADIO_INPUT_TYPE.ROUND_BOX}
              name='round_radio_box'
              value='radio 2'
              label={(<div>둥근 라디오2 {watch('round_radio_box') === 'radio 2' ? 'true' : 'false'}</div>)}
            />
            <JDRadioInput
              type={JD_RADIO_INPUT_TYPE.ROUND_CIRCLE_BOX}
              name='round_radio_box'
              value='radio 3'
              label={(<div>둥근 라디오3 {watch('round_radio_box') === 'radio 3' ? 'true' : 'false'}</div>)}
            />
          </div>
          <div className='title' style={{ marginTop: '50px' }}>
            <h1>데이트 피커</h1>
            <button
              type='button'
              className='error'
              onClick={() => setError('date_picker', {
                type: 'required',
                message: '에러 메세지',
              })}
            >에러
            </button>
          </div>
          <JDDatePickerInput
            name='date_picker'
            placeholder='연도. 월.'
            month
          />
          <div className='title' style={{ marginTop: '50px' }}>
            <h1>텍스트 Area</h1>
            <button
              type='button'
              className='error'
              onClick={() => setError('textArea', {
                type: 'required',
                message: '에러 메세지',
              })}
            >에러
            </button>
          </div>
          <JDDescriptionInput
            name='textArea'
            placeholder={`본인의 경험에 대해 자유롭게 설명해 주세요.\n\n- '희망의 연탄 나르기'봉사활동 매주 참여 (2011년 ~)\n- '전국 대학생 디자인 공모전' 참여 및 대상 수상 (2019.03)\n\n[프로젝트]\n- 2022.01 ~ 2022.04\n- 담당 역할 : PO 및 프론트 개발\n- 신규 커머스 플랫폼 개발 프로젝트 참여\n- 플레이스토어에 판매 (누적 10,000건 다운로드, 만족도 4.2/5점)`}
            maxLength={300}
            height={330}
          />
          <div className='title' style={{ marginTop: '50px' }}>
            <h1>텍스트 Area</h1>
            <button
              type='button'
              className='error'
              onClick={() => setError('textArea', {
                type: 'required',
                message: '에러 메세지',
              })}
            >에러
            </button>
          </div>
          <JDDescriptionInput
            name='textArea'
            placeholder={`본인의 경험에 대해 자유롭게 설명해 주세요.\n\n- '희망의 연탄 나르기'봉사활동 매주 참여 (2011년 ~)\n- '전국 대학생 디자인 공모전' 참여 및 대상 수상 (2019.03)\n\n[프로젝트]\n- 2022.01 ~ 2022.04\n- 담당 역할 : PO 및 프론트 개발\n- 신규 커머스 플랫폼 개발 프로젝트 참여\n- 플레이스토어에 판매 (누적 10,000건 다운로드, 만족도 4.2/5점)`}
            maxLength={300}
            height={330}
          />
          <div className='title' style={{ marginTop: '50px' }}>
            <h1>등록 리스트 아이템</h1>
            <button
              type='button'
              className='error'
              onClick={() => {
                setRegisterItemMode(!registerItemMode);
              }}
            >삭제
            </button>
          </div>
          <div className='list'>
            <JDRegisterItemButton
              icon={<IconPlus width={24} height={24} />}
              title='새로운 학력 정보 등록하기'
              strong
              onClick={() => alert('선택')}
            />
            <JDRegisterItemButton
              title='한양대학교'
              mode={registerItemMode ? 'DELETE' : 'SELECT'}
              onClick={() => alert('선택')}
              onDelete={() => alert('삭제')}
            />
          </div>
          <div className='title' style={{ marginTop: '50px' }}>
            <h1>슬라이더</h1>
          </div>
          <div style={{ marginBottom: '50px' }}>
            <h1>출처가 달라서 싱글range과 multi range 사용방법이 다릅니다.</h1>
            <h1>디자인이 또 바뀌여서 변경 요청중입니다.</h1>
          </div>
          <JDSlider
            min={1000}
            max={10000}
            step={1000}
            tooltipVisible
            toolTip={(value: number) => value}
            marks={{
              1000: '1,000 만원',
              10000: '1억원 이상',
            }}
            value={salary}
            onChange={setSalary}
          />
          <div style={{ marginTop: '50px', marginBottom: '20px' }}>
            <SalaryMultiRange min={min} max={max} dots={dots} step={1000} condition={condition} setCondition={setCondition} />
          </div>
          <div className='title' style={{ marginTop: '50px' }}>
            <h1>버튼 input</h1>
            <button
              type='button'
              className='error'
              onClick={() => {
                setError('inner_input', {
                  type: 'required',
                  message: '에러 메세지',
                });
              }}
            >에러
            </button>
          </div>
          <JDInnerButtonInput
            name='inner_input'
            btnLabel='변경'
            btnDisabled={!!errors.inner_input}
            onClickInnerBtn={() => alert('클릭')}
          />
          <div className='title' style={{ marginTop: '50px' }}>
            <h1>타이머 확인 버튼</h1>
            <button
              type='button'
              className='error'
              onClick={() => {
                setError('timer_input', {
                  type: 'required',
                  message: '에러 메세지',
                });
              }}
            >에러
            </button>
          </div>
          <JDTimerInput
            name='timer_input'
            isRunning
            onClickInnerBtn={() => alert('클릭')}
          />
        </FormProvider>
      </form>
    </Frame>
  );
};

export default CustomInputPage;
