import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, ButtonMode } from '__designkit__/button/Button';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SingleMenuItem from '__designkit__/components/SingleMenuItem';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import JDSaveButton from 'components/_v2/_common/button/JDSaveButton';
import FormValueFrame, { DatePickerFormValueFrame } from 'components/_v2/_common/form/FormProfileValueFrame';
import FormProfileValueTitle from 'components/_v2/_common/form/FormProfileValueTitle';
import JDBaseInput from 'components/_v2/_common/input/JDBaseInput';
import JDDatePickerInput from 'components/_v2/_common/input/JDDatePickerInput';
import JDDescriptionInput from 'components/_v2/_common/input/JDDescriptionInput';
import JDRadioInput, { JD_RADIO_INPUT_TYPE } from 'components/_v2/_common/input/JDRadioInput';
import JDFullModal from 'components/_v2/_common/modals/JDFullModal';
import ProfileMoveDirtyModal from 'components/_v2/profile/ProfileMoveDirtyModal';
import useQueryParams from 'hooks/useQueryParams';
import useToast from 'hooks/useToast';
import { IProfileProjectListConvert, IProfileProjectSaveRq, TeamType, TeamTypeText } from 'interfaces/_v2/profile/IProfileProjectRqRs';
import { inject, observer } from 'mobx-react';
import ProfileProjectModel from 'models/_v2/profile/ProfileProjectModel';
import { injectStore } from 'models/store';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { debounce } from 'utils/CommonUtils';
import * as yup from 'yup';
import ProfileEditTitleComponent from '../ProfileEditTitleComponent';
import ProfileLegend from '../ProfileLegend';
import ProfileProjectContributionSelect from './ProfileProjectContributionSelect';
import ProfileProjectKeyword from './ProfileProjectKeyword';
import { IProfileProjectView } from './ProfileProjectView';

const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  padding: 0 8px;
`;

const FormFrame = styled.form`
  width: 100%;
  height: calc(100% - 56px);
`;

const ProjectFrame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .content{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 24px 16px 16px;
    overflow-y: auto;

    .title{
      display: flex;
      justify-content: flex-end;

      .delete-field {
        display: flex;
        align-items: center;
        height: 24px;
        padding: 2px;
      
        & > span {
          display: flex;
          align-items: center;
          font: ${Fonts.B3_Medium};
          color:${Colors.JOBDA_BLACK};
        }

        :disabled {
          opacity: 0.5;
        }
      }
    }

    .register-item-frame{
      >*{
        margin-bottom: 16px;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &.none{
    display: none;
  }
`;

const ProjectRegisterFrame = styled.div`
  .content{
    padding: 16px 16px 72px 16px;

    .title-frame {
        display: flex;
        justify-content: space-between;

        .a-proposal {
            display:block;
            width:fit-content;
            font: ${Fonts.B2_Medium};
            text-decoration-line:underline;
            color:${Colors.B_100};
        }

        .title-left {
            display: flex;
            .anchor-frame {
                display: flex;
                align-items: center;
                margin-left: 4px;
            }
        }
    }
    .team-error{
      width: 100%;
      display: flex;
      flex-direction: column;
      .team-private{
      display: flex;
      justify-content: space-between;
      width: 100%;
      .team-contribution{
        display: flex;
        .team{
          font: ${Fonts.B1_Medium};
        color:${Colors.CG_90};
      }
      .contribution{
          margin: 0 12px 0 8px;
          width: 100px;
          .select {
            position: relative;
            border: 1px solid $C_COOL_GRAY_30;
            border-radius: 4px;
            }
        }
    }
      .private{
        display:flex;
      }
    }
    .message-false{
      font: ${Fonts.B3_Medium};
      color:${Colors.ERROR}
    }
    }
   
    .save-button{
      margin-top: 72px;
    }
  }
`;

const BtnFrame = styled.div`
  width: 100%;
  height: fit-content;
  padding: 16px;
`;

interface IProfileProjectModal extends IProfileProjectView {
    isOpen?:boolean;
    onClose?:() => void;
}
const ProjectSchema = yup.object({
  projects: yup.array(
    yup.object({
      title: yup.string().required('프로젝트 명을 입력해 주세요.'),
      endDate: yup.string()
        .when('startDate', (startDate:any, schema:any) => schema.test({
          test: (endDate: Date) => endDate >= startDate,
          message: '종료 일자가 시작 일자보다 빠릅니다.',
        }))
        .required('종료 일자를 입력해 주세요.'),
      teamType: yup.string().nullable().required('프로젝트 타입을 선택해 주세요.'),
      contribution: yup.object().nullable().when('teamType', {
        is: (val: TeamType) => val === 'TEAM',
        then: yup.object().nullable().required('기여도를 입력해 주세요.'),
        otherwise: yup.object().nullable().notRequired(),
      }),
      startDate: yup
        .string()
        .nullable()
        .required('시작 일자를 입력해 주세요.'),
    }),
  ).nullable(),
});
const ProfileProject:FC<IProfileProjectModal> = ({ isOpen, onClose, profileProjectModel = new ProfileProjectModel() }) => {
  const { setToastObject } = useToast();
  const [registerItemMode, setRegisterItemMode] = useState<boolean>(false);
  const [openRegisterFrame, setOpenRegisterFrame] = useState<{ open: boolean, selectIndex: number, type: 'NEW' | 'EDIT' }>({ open: false, selectIndex: -1, type: 'NEW' });
  const registerFrameRef = useRef<HTMLDivElement | null>(null);
  const [isDirtyModalOpen, setIsDirtyModalOpen] = useState<boolean>(false);
  const { sourcePage } = useQueryParams();

  const ProjectDefaultValue = {
    sn: undefined,
    title: undefined,
    startDate: undefined,
    endDate: undefined,
    teamType: undefined,
    contribution: undefined,
    performance: undefined,
    institution: undefined,
    skills: [
      {
        skill: {
          code: undefined,
          keyword: undefined,
        },
        skillLevel: undefined,
      },
    ],
  };
  const useFormed = useForm<Partial<IProfileProjectListConvert>>({
    mode: 'onSubmit',
    resolver: yupResolver(ProjectSchema),
  });
  const { control, reset, getValues, formState, handleSubmit, errors } = useFormed;
  const { fields: profileProjectFields, append: profileProjectAppend, remove: profileProjectRemove } = useFieldArray({
    control,
    name: 'projects',
  });

  const projectsInit = async () => {
    reset({ projects: profileProjectModel.importedProjects || undefined });
  };

  const onClickCloseBtn = async () => {
    profileProjectModel.recommendSkills = [];
    if (registerItemMode) {
      setRegisterItemMode(false);
    }
    if (profileProjectModel.isDirty) {
      setIsDirtyModalOpen(true);
      return;
    }
    if (openRegisterFrame.open) {
      if (openRegisterFrame.type === 'NEW')
        profileProjectRemove(profileProjectFields.length - 1);
      setOpenRegisterFrame({ open: false, selectIndex: -1, type: 'NEW' });
    } else {
      await profileProjectModel.loadProjects();
      if (onClose) onClose();
    }
  };

  const onSubmit = async () => {
    try {
      const { projects } = getValues() as IProfileProjectListConvert;

      if (projects !== undefined && projects.length > 0) {
        const fieldsLength: number = projects.length;
        const tmpArray = [];
        for (let index = 0; index <= fieldsLength; index++) {
          const item = projects && projects[index];
          if (item) {
            const newItem:IProfileProjectSaveRq = {
              title: item.title,
              startDate: item.startDate,
              endDate: item.endDate,
              teamType: item.teamType,
              contribution: 0,
              performance: item.performance,
              institution: item.institution,
              skills: item.skills,
            };
            if (item.contribution) {
              newItem.contribution = Number(item.contribution.value);
            }
            tmpArray.push(newItem);
          }
        }

        await profileProjectModel.saveProjects({ projects: tmpArray.length > 0 ? [...tmpArray] : [], sourcePage });
        setToastObject({ isOpen: true, message: '성공적으로 저장되었습니다.', type: 'SUCCESS' });
        setOpenRegisterFrame({ open: false, selectIndex: -1, type: 'NEW' });
        setRegisterItemMode(false);
        await profileProjectModel.loadProjects();
        projectsInit();
      } else {
        await profileProjectModel.saveProjects({ projects: [], sourcePage });
        setToastObject({ isOpen: true, message: '성공적으로 저장되었습니다.', type: 'SUCCESS' });
        setOpenRegisterFrame({ open: false, selectIndex: -1, type: 'NEW' });
        setRegisterItemMode(false);
        await profileProjectModel.loadProjects();
        projectsInit();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onError = useCallback((data) => {
    console.error(data);
    setToastObject({ isOpen: true, message: '저장에 필요한 정보를 작성해 주세요.', type: 'ERROR' });
  }, []);

  useEffect(() => {
    projectsInit();
  }, [isOpen]);
  useEffect(() => {
    profileProjectModel.isDirty = formState.isDirty;
  }, [formState.isDirty]);

  useEffect(() => {
    if (registerItemMode) {
      profileProjectModel.isDirty = true;
    }
  }, [registerItemMode]);

  return !isOpen ? <></> : (
    <JDFullModal>
      <Header>
        <Icon name='large-arrow-left' size={32} color={Colors.JOBDA_BLACK} onClick={onClickCloseBtn} />
      </Header>
      <FormFrame onSubmit={handleSubmit(onSubmit, onError)}>
        <FormProvider {...useFormed}>
          <ProjectFrame hidden={openRegisterFrame.open}>
            <ProfileEditTitleComponent
              title='등록된 프로젝트 경험을 확인해 주세요.'
              subtitle='최근 활동 순으로 자동 정렬돼요.'
            />
            <div className='content'>
              { profileProjectFields.length > 0
                ? (
                  <>
                    <div className='title'>
                      <button
                        type='button'
                        className='delete-field'
                        onClick={() => {
                          if (registerItemMode)projectsInit();
                          setRegisterItemMode(!registerItemMode);
                        }}
                      >
                        <span>
                          { registerItemMode ? <></> : <Icon name='guide' size={24} color={Colors.JOBDA_BLACK} /> }
                          { registerItemMode ? '취소' : '편집' }
                        </span>
                      </button>
                    </div>
                    <SpacingBlock size={16} vertical />
                    <div className='register-item-frame'>
                      { profileProjectFields.map((profileProjectField, profileProjectIndex) => (
                        <SingleMenuItem
                          key={profileProjectField.id}
                          title={profileProjectField.title}
                          subTitle={`${profileProjectField.startDate} ~ ${profileProjectField.endDate}`.replace(/-/g, '.')}
                          onClick={registerItemMode
                            ? () => { profileProjectRemove(profileProjectIndex); }
                            : () => { setOpenRegisterFrame({ open: true, selectIndex: profileProjectIndex, type: 'EDIT' }); }}
                          rightIcon={registerItemMode ? <Icon name='remove-filled' size={32} color={Colors.JOBDA_BLACK} /> : undefined}
                        />
                      ))}
                    </div>
                  </>
                )
                : registerItemMode ? (
                  <div className='title'>
                    <button
                      type='button'
                      className='delete-field'
                      onClick={() => {
                        if (registerItemMode)projectsInit();
                        setRegisterItemMode(!registerItemMode);
                      }}
                    >
                      <span>
                        { registerItemMode ? <></> : <Icon name='guide' size={24} color={Colors.JOBDA_BLACK} /> }
                        { registerItemMode ? '취소' : '편집' }
                      </span>
                    </button>
                  </div>
                ) : (
                  <SingleMenuItem
                    title='직접 추가하기'
                    onClick={() => {
                      profileProjectAppend({ ...ProjectDefaultValue }, false);
                      setOpenRegisterFrame({ open: true, selectIndex: profileProjectFields.length, type: 'NEW' });
                      debounce(() => {
                        if (registerFrameRef.current) registerFrameRef.current.focus();
                      }, 500);
                    }}
                    leftIcon={<Icon name='add' size={32} color={Colors.JOBDA_BLACK} />}
                  />
                )}
            </div>
            { registerItemMode
              ? <JDSaveButton type='submit' fixed={false} />
              : profileProjectFields.length > 0 && (
                <BtnFrame>
                  <Button
                    label='프로젝트 추가하기'
                    size='large'
                    buttonMode={ButtonMode.PRIMARY}
                    onClick={() => {
                      profileProjectAppend({ ...ProjectDefaultValue }, false);
                      setOpenRegisterFrame({ open: true, selectIndex: profileProjectFields.length, type: 'NEW' });
                      debounce(() => {
                        if (registerFrameRef.current) registerFrameRef.current.focus();
                      }, 500);
                    }}
                  />
                </BtnFrame>
              )}
          </ProjectFrame>
          <ProjectRegisterFrame hidden={!openRegisterFrame.open} ref={registerFrameRef}>
            <ProfileEditTitleComponent
              title='프로젝트 경험을 입력해 주세요.'
              subtitle='자신이 했던 역할과 문제 해결 방식을 상세히 작성해 주세요.'
              divider={false}
              tip
            />
            {profileProjectFields.map((profileProjectsField, profileProjectsIndex) => (
              <div className='content' key={profileProjectsField.id} hidden={openRegisterFrame.selectIndex !== profileProjectsIndex}>
                <ProfileLegend />
                <FormProfileValueTitle required>프로젝트명</FormProfileValueTitle>
                <FormValueFrame>
                  <JDBaseInput
                    name={`projects[${profileProjectsIndex}].title`}
                    placeholder='프로젝트명을 입력해 주세요.'
                    maxLength={30}
                  />
                </FormValueFrame>
                <FormProfileValueTitle required>프로젝트 기간</FormProfileValueTitle>
                <DatePickerFormValueFrame>
                  <JDDatePickerInput
                    name={`projects[${profileProjectsIndex}].startDate`}
                    placeholder='연도.월.'
                    defaultValue={profileProjectsField.startDate}
                    month
                  />
                  <span className='date-picker-divider'>~</span>
                  <JDDatePickerInput
                    name={`projects[${profileProjectsIndex}].endDate`}
                    placeholder='연도.월.'
                    defaultValue={profileProjectsField.endDate}
                    month
                  />
                </DatePickerFormValueFrame>
                <FormProfileValueTitle required>팀/개인</FormProfileValueTitle>
                <FormValueFrame>
                  <div className='team-error'>
                    <div className='team-private'>
                      {Object.values(TeamType).map((key) => (
                        <>
                          <div className='team-contribution'>
                            <JDRadioInput
                              type={JD_RADIO_INPUT_TYPE.ROUND_BOX}
                              key={`radio-option-${key}`}
                              name={`projects[${profileProjectsIndex}].teamType`}
                              value={key}
                              defaultValue={profileProjectsField.teamType}
                              label={(<div className='team'>{TeamTypeText[key]}</div>)}
                            />

                            {key === TeamType.TEAM && (
                            <div className='contribution'>
                              <ProfileProjectContributionSelect prefixName={`projects[${profileProjectsIndex}]`} />
                            </div>
                            )}
                          </div>
                        </>
                      ))}
                    </div>
                    <ErrorMessage
                      errors={errors}
                      name={`projects[${profileProjectsIndex}].teamType`}
                      render={({ message }) => <h4 className='message-false'>{message}</h4>}
                    />
                    <ErrorMessage
                      errors={errors}
                      name={`projects[${profileProjectsIndex}].contribution`}
                      render={({ message }) => <h4 className='message-false'>{message}</h4>}
                    />
                  </div>

                </FormValueFrame>
                <FormProfileValueTitle>주요 업무 및 성과</FormProfileValueTitle>
                <FormValueFrame>
                  <JDDescriptionInput
                    key=''
                    name={`projects[${profileProjectsIndex}].performance`}
                    placeholder='프로직트 진행 시 담당했던 업무와 성과를 입력해 주세요.'
                    maxLength={10000}
                    height={300}
                    autoFocus={false}
                  />
                </FormValueFrame>
                <FormProfileValueTitle>학교/회사/기관</FormProfileValueTitle>
                <FormValueFrame>
                  <JDBaseInput
                    name={`projects[${profileProjectsIndex}].institution`}
                    placeholder='학교/회사/기관을 입력해 주세요.'
                    maxLength={30}
                  />
                </FormValueFrame>
                <ProfileProjectKeyword name={`projects[${profileProjectsIndex}].skills`} />

              </div>
            ))}

            <JDSaveButton
              type='submit'
              fixed={false}
              register
              className='save-button'
            />
          </ProjectRegisterFrame>
        </FormProvider>
      </FormFrame>
      <ProfileMoveDirtyModal
        isOpen={isDirtyModalOpen}
        onSuccess={async () => {
          if (openRegisterFrame.open) {
            if (openRegisterFrame.type === 'NEW') profileProjectRemove(profileProjectFields.length - 1);
            setOpenRegisterFrame({ open: false, selectIndex: -1, type: 'NEW' });
            setIsDirtyModalOpen(false);
          } else {
            await profileProjectModel.loadProjects();
            if (profileProjectModel.projects) {
              reset({ projects: profileProjectModel.importedProjects || undefined });
            }
            setRegisterItemMode(false);
            if (onClose) {
              profileProjectModel.isDirty = false;
              setIsDirtyModalOpen(false);
              onClose();
            }
          }
        }}
        onClickClose={() => setIsDirtyModalOpen(false)}
      />
    </JDFullModal>
  );
};

export default inject(injectStore.profileProjectModel)(observer(ProfileProject));
