import { AxiosError } from 'axios';
import { InsightResumeLogRq } from 'interfaces/_v2/insight/IInsightResumeRqRs';
import { useMutation } from 'react-query';
import { RemoteLogRepo } from 'repository/LogRepo';

export const keys = ['log'];

const logRepo = new RemoteLogRepo();

const fetchLog = async (rq: InsightResumeLogRq) => {
  const data = await logRepo.saveLog(rq);
  return data;
};

export function useSaveLog(onSuccess: () => void, onError?: (e: AxiosError) => void) {
  return useMutation((rq: InsightResumeLogRq) => fetchLog(rq), {
    onSuccess,
    onError,
  });
}
