export enum RecruitmentTypes {
    NEW = 'NEW',
    CAREER = 'CAREER',
    ANY = 'ANY',
    INTERNSHIP = 'INTERNSHIP',
    NEWORCAREER = 'NEWORCAREER',
}

export enum RecruitmentTypesText {
    NEW = '신입',
    CAREER = '경력',
    ANY = '경력무관',
    INTERNSHIP = '인턴',
    NEWORCAREER = '신입/경력',
}

export enum BusinessSizeTypes {
    LARGE = 'LARGE',
    MEDIUM = 'MEDIUM',
    GOVERNMENT = 'GOVERNMENT',
    ETC = 'ETC',
}

export enum BusinessSizeTypesText {
    LARGE = '대기업',
    MEDIUM = '중견기업',
    GOVERNMENT = '공공기관',
    ETC = '기타',
}

export enum StatusType {
    TOTAL = 'TOTAL',
    START = 'START',
    END = 'END',
    ROLLING = 'ROLLING',
}

export enum StatusTypeText {
    TOTAL = '전체',
    START = '시작',
    END = '마감',
    ROLLING = '상시',
}
