/* eslint-disable class-methods-use-this */
import { AxiosResponse } from 'axios';
import URIs from 'consts/URIs';
import { request } from 'utils/request';
import { IGetConditionRs, IGetWelfareCategoryRs, IMatchingConditionLastModifiedRs, IPutConditionJobGroupsRq, IPutConditionLocationsRq, IPutConditionRq, IPutConditionSalaryRq, IPutConditionWelfareRq, IPutConditionWorkingPreferenceRq } from 'interfaces/_v2/profile/IConditionRqRs';

export default interface ProfilesRepo {

  // 선호 정보 > 조회
  fetchProfileConditions(): Promise<IGetConditionRs>;

  // 선호 정보 > 저장
  fetchSaveProfileConditions(rq: IPutConditionRq): Promise<boolean>;

  // 선호 정보 > 직군 저장
  fetchSaveProfileConditionsJobGroups(rq:IPutConditionJobGroupsRq): Promise<boolean>;

  // 선호 정보 > 지역 저장
  fetchSaveProfileConditionsLocations(rq:IPutConditionLocationsRq): Promise<boolean>;

  // 선호 정보 > 연봉 저장
  fetchSaveProfileConditionsSalary(rq:IPutConditionSalaryRq): Promise<boolean>;

  // 선호 정보 > 복지 저장
  fetchSaveProfileConditionsWelfareKeywords(rq:IPutConditionWelfareRq): Promise<boolean>;

  // 선호 정보 > 희망 근무 조건 저장
  fetchSaveProfileConditionsWorkingPreference(rq:IPutConditionWorkingPreferenceRq): Promise<boolean>;

  fetchLoadTotalWelfare(): Promise<IGetWelfareCategoryRs>;

  // 선호 정보 마지막 수정일
  fetchMatchConditionsLastModified() : Promise<IMatchingConditionLastModifiedRs>

  // 선호 정보 마지막 수정일 - 이대로 제안받기
  fetchSaveMatchConditionsLastModified(): Promise<boolean>;
}

export class RemoteProfilesRepo implements ProfilesRepo {
  fetchProfileConditions(): Promise<IGetConditionRs> {
    return request<IGetConditionRs>({
      method: 'get',
      url: URIs.get_match_conditions,
    });
  }

  fetchSaveProfileConditions(data: IPutConditionRq): Promise<boolean> {
    return request({
      method: 'put',
      url: URIs.put_match_conditions,
      data,
    });
  }

  fetchSaveProfileConditionsJobGroups(data: IPutConditionJobGroupsRq): Promise<boolean> {
    return request({
      method: 'put',
      url: URIs.put_match_conditions_job_group,
      data,
    });
  }

  fetchSaveProfileConditionsLocations(data: IPutConditionLocationsRq): Promise<boolean> {
    return request({
      method: 'put',
      url: URIs.put_match_conditions_locations,
      data,
    });
  }

  fetchSaveProfileConditionsSalary(data: IPutConditionSalaryRq): Promise<boolean> {
    return request({
      method: 'put',
      url: URIs.put_match_conditions_salary,
      data,
    });
  }

  fetchSaveProfileConditionsWelfareKeywords(data: IPutConditionWelfareRq): Promise<boolean> {
    return request({
      method: 'put',
      url: URIs.put_match_conditions_welfare,
      data,
    });
  }

  fetchSaveProfileConditionsWorkingPreference(data: IPutConditionWorkingPreferenceRq): Promise<boolean> {
    return request({
      method: 'put',
      url: URIs.put_match_conditions_working_preference,
      data,
    });
  }

  fetchLoadTotalWelfare(): Promise<IGetWelfareCategoryRs> {
    return request<IGetWelfareCategoryRs>({
      method: 'get',
      url: URIs.get_welfare_categories_welfares,
    });
  }

  fetchMatchConditionsLastModified(): Promise<IMatchingConditionLastModifiedRs> {
    return request<IMatchingConditionLastModifiedRs>({
      method: 'get',
      url: URIs.get_match_conditions_last_modified,
    });
  }

  fetchSaveMatchConditionsLastModified(): Promise<boolean> {
    return request({
      method: 'post',
      url: URIs.post_match_conditions_last_modified,
    });
  }
}
