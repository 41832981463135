import Icon from '__designkit__/icon/Icon';
import ImgResult from '__pc__/page/eventChuseok/@assets/img_result_sample.png';
import ImgResume from '__pc__/page/eventChuseok/@assets/img_resume_sample.png';
import JDFullModal from 'components/_v2/_common/modals/JDFullModal';
import JDFullModalHeader from 'components/_v2/_common/modals/JDFullModalComponents';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { FC } from 'react';

interface ISampleFullModalProps extends IComponentProps {
    isOpen: boolean;
    onClose: () => void;
    resume?:boolean;

  }

const SampleFullModal:FC<ISampleFullModalProps> = ({ isOpen, onClose, resume }) => (
  !isOpen ? <></> : (
    <JDFullModal isOpen={isOpen} onClose={onClose}>
      <JDFullModalHeader>
        <Icon name='close' size={32} onClick={onClose} />
      </JDFullModalHeader>
      {resume
        ? <img src={ImgResume} alt='' />
        : <img src={ImgResult} alt='' />}

    </JDFullModal>
  ));
export default SampleFullModal;
