import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import IconKakao from 'assets/_v2/company/icon_kakao_white.png';
import JDALink from 'components/JDALink';
import { inject, observer } from 'mobx-react';
import MatchReportModel from 'models/_v2/MatchReportModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { windowOpenUtils } from 'utils/_v2/windowOpenUtils';

const Frame = styled.div`
  width: 100%;
  padding: 40px 24px;
`;

const Title = styled.div`
  font:${Fonts.H5_Bold};
  color:${Colors.JOBDA_BLACK};
`;
const LocationFrame = styled.div`
  display: flex;
  align-items: center;
  margin-top: 32px;
  .location-text{
    font:${Fonts.B3_Medium_P};
    color:${Colors.CG_70};
  }
`;
const KakaoMapButton = styled.div`
  display: flex;
  padding:11px 22px 11px 16px;
  gap:6px;
  margin-top: 8px;
  background-color: ${Colors.JOBDA_BLACK};
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  .icon{
    width: 24px;
    height: 24px;
  }
  .kakao-text{
    font:${Fonts.B2_Bold};
    color:${Colors.WHITE_100};
  }
`;
interface ICompanyReportMapProps{
  location?:string
  matchReportModel?:MatchReportModel
}

const CompanyReportMap: FC<ICompanyReportMapProps> = ({ location, matchReportModel = new MatchReportModel() }) => {
  const kakaoFindMapUrl = () => {
    if (matchReportModel.matchReportPositionsDetail) {
      const url = `https://map.kakao.com/link/to/${matchReportModel.matchReportPositionsDetail.addressInfo.address},${matchReportModel.locationY},${matchReportModel.locationX}`;
      windowOpenUtils(url, '_blank');
    }
  };

  return (
    <Frame>
      <Title>
        우리 집부터 기업까지<br />소요 시간을 미리 확인해 보세요.
      </Title>
      <LocationFrame>
        <Icon name='pin' size={20} color={Colors.CG_70} />
        <div className='location-text'>{location}</div>
      </LocationFrame>
      <KakaoMapButton onClick={() => kakaoFindMapUrl()}>
        <img className='icon' src={IconKakao} alt='icon' />
        <div className='kakao-text'>카카오맵으로 소요 시간 확인하기</div>
      </KakaoMapButton>
      <SpacingBlock vertical size={24} />
      <div
        id='kakaoMap'
        style={{
          width: '100%',
          height: '240px',
        }}
      />
    </Frame>
  );
};
export default inject(injectStore.matchReportModel)(observer(CompanyReportMap));
