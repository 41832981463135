import classnames from 'classnames/bind';
import styles from './lineChart.module.scss';
import { EDGE_SPACE, LineChartSizeType } from './data';
import React from 'react';

const cx = classnames.bind(styles);

interface RowsProps {
  rows: string[];
  type: LineChartSizeType;
  maxRow: number;
  onIndex: number | null;
  tableWidthWhitUnit: string;
  translateX: string;
  visible?:boolean;
}

const Rows = ({ rows, type, maxRow, onIndex, tableWidthWhitUnit, translateX, visible }: RowsProps) => {
  const getRowsEl = () => {
    const rowEl = rows.map((row, index) => {
      return (
        <div
          key={index}
          className={cx('row', { on: index === onIndex }, type , { active: visible })}
          style={{ width: index === 0 ? `${EDGE_SPACE[type] * 100}%` : '100%' }}
        >
          <span className={cx('rowText' , { active: visible })}>{row}</span>
        </div>
      );
    });

    const emptyRowEl = new Array(maxRow - rows.length)
      .fill(null)
      .map((_, index) => <div key={index} className={cx('row' , { active: visible })} />);

    return (
      <>
        {rowEl}
        {emptyRowEl}
        <div className={cx('row' , { active: visible })} style={{ width: `${EDGE_SPACE[type] * 100}%` }} />
      </>
    );
  };

  return (
    <div className={cx('rowArea' , { active: visible })} style={{ width: tableWidthWhitUnit, transform: translateX }}>
      <div className={cx('rowWrap' , { active: visible })}>{getRowsEl()}</div>
    </div>
  );
};

export default Rows;
