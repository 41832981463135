import { Divider1G40 } from 'components/divider/Divider';
import { IconBag } from 'consts/assets/icons/iconPages';
import Fonts from '__designkit__/common/fonts';
import React from 'react';
import styled from 'styled-components';

const Frame = styled.div`
    padding: 0 16px;
    .divider {
        margin: 32px 0;
    }
`;

const Title = styled.div`
    display: flex;
    margin-bottom: 16px;
    margin-top: 32px;
    .icon{
      margin-right: 10px;
      width: 24px;
      height:24px;
    }
    .text{
      font: ${Fonts.H5_Bold};
      line-height: 24px;
    }
`;

const PositionJdKakaoComponent = () => (
  <Frame>
    <Title>
      <IconBag className='icon' />
      <div className='text'>여기서 근무해요.</div>
    </Title>
    <div
      id='kakaoMap'
      style={{
        width: '100%',
        height: '180px',
      }}
    />
    <Divider1G40 className='divider' />

  </Frame>
);

export default PositionJdKakaoComponent;
