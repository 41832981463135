import { Button, ButtonMode } from '__designkit__/button/Button';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import JDRadioInput, { JD_RADIO_INPUT_TYPE } from 'components/_v2/_common/input/JDRadioInput';
import JDBaseModal, { IJDBaseModal } from 'components/_v2/_common/modals/JDBaseModal';
import { JDModalBasicFrame, JDModalDescription, JDModalFullButton, JDModalTitle } from 'components/_v2/_common/modals/JDModalComponents';
import { Divider1G40 } from 'components/divider/Divider';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import MatchApplyModel from 'models/_v2/MatchApplyModel';
import { injectStore } from 'models/store';
import React, { FC, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

const Frame = styled.div`

    ${JDModalDescription} {
        margin-top: 8px;
        margin-bottom: 12px;
    }
`;

const OptionFormFrame = styled.div`
    margin: 24px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .jd-input {
        margin-bottom: 12px;

        .label {
            font: ${Fonts.B2_Bold};
            color: ${colors.JOBDA_BLACK};
        }
    }
`;

interface IMatchJobSearchStatusModalProps extends IJDBaseModal {
  login?: Login;
  matchApplyModel?: MatchApplyModel;
}

const MatchJobSearchStatusModal: FC<IMatchJobSearchStatusModalProps> = (({ isOpen, onClickClose, login = new Login(), matchApplyModel = new MatchApplyModel() }) => {
  const useformed = useForm({
    mode: 'all',
    defaultValues: { job_status: login.matchConfigStatus?.jobSearchStatus || 'ACTIVE_SEARCH' },
  });
  const { watch, reset } = useformed;

  const onSubmit = useCallback(async () => {
    await matchApplyModel.saveJobSearchStatus(watch('job_status') || login.matchConfigStatus?.jobSearchStatus);
    await login.getMatchConfigStatus();
    onClickClose();
  }, [watch]);

  useEffect(() => {
    if (login.matchConfigStatus?.jobSearchStatus) {
      reset({ 'job_status': login.matchConfigStatus?.jobSearchStatus });
    }
  }, [login.matchConfigStatus?.jobSearchStatus]);


  return (
    <JDBaseModal isOpen={isOpen} onClickClose={onClickClose} isDimClickClose>
      <FormProvider {...useformed}>
        <Frame>
          <JDModalBasicFrame>
            <JDModalTitle>구직 상태</JDModalTitle>
            <JDModalDescription>받고 싶은 제안의 종류에 따라 <br />구직 상태를 설정해 주세요.</JDModalDescription>
            <Divider1G40 />
            <OptionFormFrame>
              <JDRadioInput
                key='radio-ACTIVE_SEARCH'
                type={JD_RADIO_INPUT_TYPE.ROUND_CIRCLE_BOX}
                name='job_status'
                value='ACTIVE_SEARCH'
                label={(<div>적극적으로 취업/이직을 준비 중이에요.</div>)}
                defaultValue={login.matchConfigStatus?.jobSearchStatus || ''}
              />
              <JDRadioInput
                key='radio-OPEN_TO_OFFERS'
                type={JD_RADIO_INPUT_TYPE.ROUND_CIRCLE_BOX}
                name='job_status'
                value='OPEN_TO_OFFERS'
                label={(<div>좋은 제안이 있으면 받고 싶어요.</div>)}
                defaultValue={login.matchConfigStatus?.jobSearchStatus || ''}
              />
            </OptionFormFrame>
            <Button size='large' onClick={onSubmit} buttonMode={ButtonMode.PRIMARY} disabled={!watch('job_status')} label='확인' />
          </JDModalBasicFrame>
        </Frame>
      </FormProvider>
    </JDBaseModal>
  );
});

export default inject(injectStore.login, injectStore.matchApplyModel)(observer(MatchJobSearchStatusModal));
