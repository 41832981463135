import { ErrorMessage } from '@hookform/error-message';
import { IconClose20 } from 'consts/assets/icons/iconPages';
import IconPencil from 'assets/_v2/_common/icon_pencil.svg';
import { nameValidator, numberGPAValidator, numberPercentageValidator, numberPointValidator, numberValidator } from 'consts/ValidationRule';
import { JDBaseInputMixin } from 'consts/_v2/_common/style/mixins';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { forwardRef, ReactElement, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import styled from 'styled-components/macro';

const Frame = styled.div`
  ${JDBaseInputMixin()};

  .frame-input-box {
    position: relative;

  }

  .custom-edit {
    position: absolute;
    right: 8px;
  }
`;

interface IJDABaseInput extends IComponentProps {
  name: string;
  defaultMessage?: string
  customValidator: any;
  type?: string;
  onClear?: () => void;
  disabled?: boolean;
  defaultValue?: string;
  className?: string;
  caption?: ReactElement;
  onSaveEdit?: () => void;
  customEdit?: boolean;
  btnClear?: boolean;
  maxLength?: number;
}

const JDBaseInput = forwardRef((props: IJDABaseInput, fieldRef) => {
  const { name, defaultMessage, customValidator, btnClear, type, onClear, maxLength, disabled, onSaveEdit, defaultValue, className, caption, customEdit, ...rest } = props;
  const useFormed = useFormContext();
  const { control, errors, watch, setValue, setError, clearErrors, trigger, register } = useFormed;
  const { field: { ref: BaseRef }, meta } = useController({ name, control, defaultValue });
  const [inputValue, setInputValue] = useState<string>('');

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (type) {
      case 'tel':
        if (numberValidator(e.currentTarget.value)) {
          setInputValue(e.currentTarget.value);
        } else {
          e.currentTarget.value = inputValue;
        }
        break;
      case 'numberPoint':
        if (numberPointValidator(e.currentTarget.value)) {
          setInputValue(e.currentTarget.value);
        } else {
          e.currentTarget.value = inputValue;
        }
        break;
      case 'numberGPA':
        if (numberGPAValidator(e.currentTarget.value)) {
          setInputValue(e.currentTarget.value);
        } else {
          e.currentTarget.value = inputValue;
        }
        break;
      case 'numberPercentage':
        if (numberPercentageValidator(e.currentTarget.value)) {
          setInputValue(e.currentTarget.value);
        } else {
          e.currentTarget.value = inputValue;
        }
        break;
      case 'name':
        if (nameValidator(e.currentTarget.value) !== true) {
          setError('name', { message: '올바른 형식이 아닙니다.' });
        } else {
          trigger(name);
        }
        break;
      default:
    }
    setValue(name, e.currentTarget.value);
    // trigger(name);
    if (props.onChange) {
      props.onChange();
    }
  };

  const handleCustomEdit = () => {
    BaseRef.current.focus();
  };

  return (
    <Frame className={`jd-input ${className || ''} ${!meta.invalid}`}>
      <div className='frame-input-box'>
        <input
          name={name}
          ref={function (innerRef) {
            if (fieldRef !== null) (fieldRef as any).current = innerRef;
            if (BaseRef !== undefined) BaseRef.current = innerRef;
            register(innerRef);
          }}
          onChange={onChangeHandler}
          disabled={!!disabled}
          value={watch(name)}
          defaultValue={defaultValue}
          autoComplete='off'
          {...rest}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && customEdit) {
              BaseRef.current.blur();
              onSaveEdit && onSaveEdit();
            }
          }}
          onBlur={() => {
            if (customEdit) {
              onSaveEdit && onSaveEdit();
            }
          }}
          maxLength={maxLength}
        />
        { btnClear
          && (
          <button
            type='button'
            className='btn-clear'
            onClick={() => {
              if (!watch(name)) return;
              if (BaseRef !== undefined) BaseRef.current.value = '';
              if (disabled) return;
              setValue(name, '');
              setInputValue('');
              clearErrors(name);
              if (onClear) onClear();
              trigger();
            }}
          >
            <IconClose20 />
          </button>
          )}
        {customEdit
            && <img role='presentation' title='custom-edit' className='custom-edit' onClick={handleCustomEdit} src={IconPencil} alt='custom-edit-icon' />}
      </div>
      {(defaultMessage && !meta.invalid) && <h4 className='message'>{defaultMessage}</h4>}
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <h4 className='message false'>{message}</h4>}
      />
      {caption}
    </Frame>
  );
});

export default JDBaseInput;
