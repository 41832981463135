// created by khj1115 on 2021-07-28
/* eslint-disable react-hooks/exhaustive-deps */
import ContentBigCard from '__designkit__/card/ContentBigCard';
import colors from '__designkit__/common/colors';
import Fonts, { fontStyle } from '__designkit__/common/fonts';
import RoutePaths from 'consts/RoutePaths';
import { IconRightSimpleRound } from 'consts/assets/icons/iconPages';
import { inject, observer } from 'mobx-react';
import Info from 'models/Info';
import { injectStore } from 'models/store';
import { IInfoDetailPageProps } from 'pages/InfoDetailPage';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const BodyFrame = styled.div<{passboard?:boolean}>`
  display: flex;
  flex-direction: column;
  padding: 48px 24px;
  background-color:${(props) => (props.passboard ? `${colors.WHITE_100}` : `${colors.JOBDA_WHITE}`)} ;
`;

const SuggentionContentTitle = styled.p`
  ${fontStyle.HEADLINE5_SEMIBOLD};
  line-height: 31px;
  align-items: flex-start;
`;

const InfoCardFrame = styled.div`
  margin-top: 32px;
  align-items: center;

  .info-card-wrap {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0%;
    }
  }
`;

const MoreButton = styled.button`
  background-color: ${colors.CG_40};
  align-items: center;

  border-radius: 24px;
  width: 181px;
  height: 48px;
  margin-top: 32px;
  margin-bottom: 48px;
  font: ${Fonts.B1_Medium};
  line-height: 20px;
  color: ${colors.c474747};
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  .icon-right {
    width:16px;
    height:16px;
    margin-left:16px;
  }
`;

const InfoSuggentionContent: React.FC<IInfoDetailPageProps> = ({ info = new Info(), passboard }) => (
  <BodyFrame passboard={passboard}>
    <SuggentionContentTitle>
      당신이 좋아할 콘텐츠만 잡아봤어요.
    </SuggentionContentTitle>
    <InfoCardFrame>
      {info.suggestionContents.map((content) => (
        <ContentBigCard key={content.id} item={content} />
      ))}
    </InfoCardFrame>
    <Link to={RoutePaths.info}>
      <MoreButton>
        전체 콘텐츠 보기
        <IconRightSimpleRound className='icon-right' />
      </MoreButton>
    </Link>
  </BodyFrame>
);

export default inject(injectStore.info)(observer(InfoSuggentionContent));
