import { yupResolver } from '@hookform/resolvers/yup';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import JDBaseInput from 'components/_v2/_common/input/JDBaseInput';
import JDDatePickerInput from 'components/_v2/_common/input/JDDatePickerInput';
import JDDescriptionInput from 'components/_v2/_common/input/JDDescriptionInput';
import JDImageUpload from 'components/_v2/_common/input/JDImageUpload';
import JDSelector, { JDSelectorType } from 'components/_v2/_common/input/JDSelector';
import PassboardBanner from 'components/_v2/passboard/PassboardBanner';
import { desturctureOptions } from 'components/_v2/profile/careers/ProfileCareers';
import { Divider1G } from 'components/divider/Divider';
import JDPopover from 'components/_v2/_common/popover/JDPopover';
import useToast from 'hooks/useToast';
import { PassReviewAnswerDto, PassReviewAnswerRsDto, PassReviewPostSaveRq, PassReviewQuestionListRq, PassReviewQuestionType, PassReviewQuestionTypeEnum, PassboardReviewType } from 'interfaces/_v2/passReview/IPassReviewRqRs';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import { injectStore } from 'models/store';
import { useCreatePassReviewPosts, usePassReviewPostDetail, usePassReviewPostQuestions, useUpdatePassReviewPosts } from 'query/passReview/usePassReveiwQuery';
import React, { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';
import { IconInfoCircleDarkGrey } from 'consts/assets/icons/iconPages';
import Icon from '__designkit__/icon/Icon';
import JDSaveButton from 'components/_v2/_common/button/JDSaveButton';
import JDScrollToTopButton from 'components/_v2/_common/button/JDScrollToTopButton';
import { useHistory, useParams } from 'react-router';
import PassboardQuestion from 'components/_v2/passboard/PassboardQuestion';
import RoutePaths from 'consts/RoutePaths';

const Frame = styled.div`
    padding:0 16px;
`;
const TitleFrame = styled.div`
  margin-bottom: 24px;
  font: ${Fonts.H5_Bold};
  color:${Colors.JOBDA_BLACK};
`;

const QuestionTitle = styled.div`
    font:${Fonts.B1_Bold};
    color:${Colors.JOBDA_BLACK};
    margin-bottom: 12px;
    .question-subtitle{
      font:${Fonts.B3_Medium};
      color:${Colors.CG_60};
      margin-left: 8px;
    }
`;
const FormValueFrame = styled.div`
    margin-bottom: 24px;
`;
const PopOverFrame = styled.abbr`
  display:block;
  width: fit-content;
  color:${Colors.WHITE_100};
  h1 {
    font: ${Fonts.B3_Bold};
    line-height: 20px;
    margin-bottom: 8px;
  }

  h2 {
    font: ${Fonts.B3_Medium};
    line-height: 16px;
    color:${Colors.CG_30};
  }

  ul li {
    margin-bottom: 2px;
  }
`;
const InlineFrame = styled.div`
    display: flex;
`;

const ButtonFrame = styled.div`
    display: flex;
    gap:8px;
    padding-bottom: 80px;
`;

const RegisterButton = styled.button`
    width: 100%;
    height: 48px;
    background:${Colors.JOBDA_BLACK};
    border-radius: 4px;
    display:flex;
    justify-content:center;
    align-items:center;
    font:${Fonts.B2_Bold};
    color:${Colors.WHITE_100};
`;
const ShowButton = styled.button`
    width: 100%;
    height: 48px;
    background:${Colors.WHITE_100};
    border-radius: 4px;
    display:flex;
    justify-content:center;
    align-items:center;
    font:${Fonts.B2_Bold};
    color:${Colors.JOBDA_BLACK};
    border: 1px solid ${Colors.JOBDA_BLACK};
`;
const validationSchema = yup.object().shape({
  title: yup.string().required('제목을 입력해 주세요.'),
  companyName: yup.string().required('기업명을 입력해주세요.').max(100, '기업명은 100자를 초과할 수 없습니다.'),
  jobGroupCode: yup.number().required('직군을 선택해 주세요.'),
  jobTitleCode: yup.number().required('직무를 선택해 주세요.'),
  passDate: yup.string().required('합격 일자를 입력해 주세요..').typeError('합격 일자는 유효한 날짜여야 합니다.'),
  certificationImageUid: yup.array().of(
    yup.object().shape({ fileUid: yup.string().required('합격 인증 사진은 필수입니다.') }),
  ).required('합격 인증 사진을 업로드해주세요.'),
  answers: yup.array().of(
    yup.object().shape({
      answer: yup.string()
        .required('내용을 입력해 주세요.')
        .test('minLength-test', '최소 글자수 이상 입력해 주세요.', function (value) {
          const { minLength } = this.parent;
          // minLength가 설정되지 않았거나, 설정된 최소 길이보다 값의 길이가 길거나 같은 경우에 true를 반환
          return minLength == null || (value ? value.length >= minLength : false);
        }),
      minLength: yup.number().nullable(true), // 검증에 사용되는 숨겨진 필드, null 허용
    }),
  ),
});
interface IPassboardNew{
    context? : Context;
}
const PassboardNew: FC<IPassboardNew> = ({ context = new Context() }) => {
  const { setToastObject } = useToast();
  const history = useHistory();
  const { sn } = useParams<{ sn: string }>();
  const { data: passboardDetail, isLoading } = usePassReviewPostDetail(Number(sn));
  const { data: questionData } = usePassReviewPostQuestions({ type: passboardDetail?.type as PassReviewQuestionType } || 'DEFAULT');
  const saveSuccess = async () => {
    history.push(RoutePaths.passboard);
  };
  const { mutate: savePassReview } = useCreatePassReviewPosts(saveSuccess);
  const { mutate: updatePassReview } = useUpdatePassReviewPosts(saveSuccess);
  const formMethods = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  });
  const { watch, setValue, getValues, handleSubmit, reset, errors } = formMethods;
  useEffect(() => {
    if (passboardDetail) {
      reset({
        ...passboardDetail,
        certificationImageUid: [{ fileUid: passboardDetail.certificationImage.fileUid, fileName: passboardDetail.certificationImage.fileName }],
      });
    }
  }, [passboardDetail]);
  const jobGroupCode = watch('jobGroupCode');
  const jobTitleCode = watch('jobTitleCode');
  const onSubmit = handleSubmit((formData) => {
    // rq 형태에 맞춰 구조화하기
    if (questionData) {
      let rq: PassReviewPostSaveRq;
      if (sn) {
        rq = {
          title: formData.title,
          companyName: formData.companyName,
          jobGroupCode: formData.jobGroupCode,
          jobTitleCode: formData.jobTitleCode,
          passDate: formData.passDate,
          certificationImageUid: formData.certificationImageUid[0].fileUid,
          answers: formData.answers.map((answer: PassReviewAnswerDto, idx: number) => ({
            questionSn: answer.questionSn,
            answer: answer.answer,
          })),
        };
      } else {
        rq = {
          title: formData.title,
          companyName: formData.companyName,
          jobGroupCode: formData.jobGroupCode,
          jobTitleCode: formData.jobTitleCode,
          passDate: formData.passDate,
          certificationImageUid: formData.certificationImageUid[0].fileUid,
          answers: questionData.questions.map((question, idx) => ({
            questionSn: question.questionSn,
            answer: formData.answers[idx].answer, // 폼에서 입력된 대답과 매핑
          })),
        };
      }
      if (sn) {
        updatePassReview({ sn: Number(sn), rq });
      } else {
        savePassReview(rq);
      }
    }
  });
  return (
    <>
      <PassboardBanner />
      <SpacingBlock vertical size={40} />
      <Frame>
        <TitleFrame>
          합격 후기 게시판
        </TitleFrame>
        <form onSubmit={onSubmit}>
          <FormProvider {...formMethods}>
            <QuestionTitle>제목</QuestionTitle>
            <FormValueFrame>
              <JDBaseInput
                name='title'
                placeholder='제목을 입력해 주세요.'
                defaultValue={watch('title')}
                maxLength={100}
              />
            </FormValueFrame>
            <QuestionTitle>합격 기업 · 일자</QuestionTitle>
            <FormValueFrame>
              <JDBaseInput
                name='companyName'
                placeholder='기업을 입력해 주세요.'
                defaultValue={watch('companyName')}
                maxLength={100}
              />
              <SpacingBlock vertical size={8} />
              <JDDatePickerInput
                name='passDate'
                placeholder='연도. 월'
                defaultValue={watch('passDate')}
                month
                fullSize
              />
            </FormValueFrame>
            <QuestionTitle>합격 직군 · 직무</QuestionTitle>
            <FormValueFrame>
              <JDSelector
                type={JDSelectorType.NORMAL}
                name='jobGroupCode'
                selectTitle='직군을 선택해 주세요.'
                value={jobGroupCode ? String(jobGroupCode) : undefined}
                onChange={async (value) => {
                  setValue(`jobTitleCode`, value === '0' ? '0' : '');
                }}
              >
                <select>
                  <option value='' hidden>직군을 선택해 주세요.</option>
                  { desturctureOptions(context.jobGroupsWithTitles, jobGroupCode ? (code: number) => code === Number(jobGroupCode) : undefined) }
                </select>
              </JDSelector>
              <SpacingBlock vertical size={8} />

              <JDSelector
                type={JDSelectorType.NORMAL}
                name='jobTitleCode'
                selectTitle='직무를 선택해 주세요.'
                value={jobTitleCode ? String(jobTitleCode) : undefined}
                disabled={!jobGroupCode}
              >
                <select>
                  <option value='' hidden>직무를 선택해 주세요.</option>
                  { jobGroupCode && desturctureOptions(context.jobTitles[String(jobGroupCode)]?.titles, (code) => code === Number(jobTitleCode)) }
                </select>
              </JDSelector>

            </FormValueFrame>
            <InlineFrame>
              <QuestionTitle>합격 인증 사진 업로드</QuestionTitle>
              <SpacingBlock horizontal size={4} />
              <JDPopover popoverWidth={218} useOutsideClick position='non_pony_under_left' popoverMargin={0} anchorIcon={<Icon name='information' size={24} color={Colors.CG_60} />}>
                <PopOverFrame>
                  <div className='keyword-info'>
                    <h1>합격 인증 사진</h1>
                    <h2>관리자 확인용이며, 다른 사람에게는 노출되지 않습니다.</h2>
                  </div>

                </PopOverFrame>
              </JDPopover>
            </InlineFrame>
            <FormValueFrame>
              <JDImageUpload
                name='certificationImageUid'
                type={['image/jpeg', 'image/png']}
                accept='.jpg, .jpeg, .png'
              />
            </FormValueFrame>
            <SpacingBlock vertical size={24} />
            <Divider1G />
            <SpacingBlock vertical size={24} />

            {
           !sn && questionData?.questions.map((question, idx) => (
             <FormValueFrame>
               <PassboardQuestion question={question} fieldName='answers' idx={idx} />
             </FormValueFrame>
           ))
        }
            {
         sn && passboardDetail?.answers.map((answer, idx) => (
           <FormValueFrame>
             <PassboardQuestion question={answer.question} fieldName='answers' idx={idx} />
           </FormValueFrame>
         ))
        }
            <ButtonFrame>
              <RegisterButton type='submit'>등록하기</RegisterButton>
            </ButtonFrame>
          </FormProvider>
        </form>
        <JDScrollToTopButton />
      </Frame>
    </>
  );
};

export default inject(injectStore.context)(observer(PassboardNew));
