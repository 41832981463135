import React from 'react';

import classnames from 'classnames/bind';
import JDALink from 'components/JDALink';
import RoutePaths from 'consts/RoutePaths';
import styles from './phsTestButton.module.scss';

const cx = classnames.bind(styles);

const PhsTestButton = () => (
  <JDALink
    to={RoutePaths.phs_test}
    className={cx('btnPhsTest', 'dch_landing_entered_cta')}
  >
    디챔스 참가하고 스카웃 제안 받기
  </JDALink>
);

export default PhsTestButton;
