import React, { useEffect } from 'react';

// import { bodyScrollHidden } from '__pc__/utils/common';
import classnames from 'classnames/bind';
import styles from './modal.module.scss';
import Portal from 'components/common/Portal';
import DisableScroll from 'components/common/DisableScroll';

const cx = classnames.bind(styles);

export interface ModalProps {
  visible?: boolean;
  dimmed?: boolean;
  children?: React.ReactNode | string;
  onClickBg?: React.MouseEventHandler;
  isUseScrollHidden?: boolean; //scroll hidden 기능 사용할지 여부
}

const Modal = ({ visible, dimmed = true, children, onClickBg, isUseScrollHidden = true }: ModalProps) => {
  // useEffect(() => {
  //   const isBodyScrollHidden = !!visible;

  //   if (isUseScrollHidden) {
  //     bodyScrollHidden(isBodyScrollHidden);
  //   }

  //   return () => {
  //     if (isUseScrollHidden) {
  //       bodyScrollHidden(false);
  //     }
  //   };
  // }, [visible]);

  const handleClickBg: React.MouseEventHandler = (e) => onClickBg?.(e);

  return (
    <>
      {visible && (
        <Portal selector="#modal">
          <DisableScroll />
          <div className={cx('modal', dimmed && 'dimmed')} onClick={handleClickBg}>
            <div className={cx('modalLayer')}>{children}</div>
          </div>
        </Portal>
      )}
    </>
  );
};

export default Modal;
