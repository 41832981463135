import NoticeModal, { INoticeModalProps } from 'components/modals/NoticeModal';
import React, { FC } from 'react';

interface IPushNoticeModalProps extends Pick<INoticeModalProps, 'isOpen' | 'onOk' | 'onCancel'> {
    onOff: 'ON' | 'OFF'
}

const PushNoticeModal: FC<IPushNoticeModalProps> = ({ onOff, ...props }) => (
  <NoticeModal
    title={(
        onOff === 'ON' ? (
          <>
            <span className='accent'>기기 알림을 켜주세요.</span>
          </>
        )
          : (
            <>
              <span className='accent'>푸시 알림 수신을 OFF</span>
              하시겠습니까?
            </>
          )
    )}
    content={(
      onOff === 'ON' ? (
        <>
          채용에 관련된 중요 소식을<br />
          편리하게 받아볼 수 있어요.
        </>
      )
        : (
          <>
            잠깐! 알림 수신을 OFF하면<br />
            채용정보와 기업소식이 제공되지 않습니다.
          </>
        )
    )}
    {...props}
  />
);

export default PushNoticeModal;
