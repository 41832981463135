import colors from '__designkit__/common/colors';
import Fonts, { fontStyle } from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import imgNoJoy from 'assets/_v2/alarm/icon_joy_no_alarm.svg';
import JDRadioInput, { JD_RADIO_INPUT_TYPE } from 'components/_v2/_common/input/JDRadioInput';
import JDUnderBaseModal from 'components/_v2/_common/modals/JDUnderBaseModal';
import AccPstGrade from 'components/_v2/acc/AccPstGrade';
import AccResultModal from 'components/_v2/acc/AccResultModal';
import PstResultModal from 'components/_v2/phs/pst/PstResultModal';
import { StyledSelectModalFrame } from 'components/_v2/position/filter/PositionJobTitleFilter';
import { MatchAccAnalysisType, MatchPHSAnalysisType } from 'consts/MatchAnalysisType';
import { PhsAccType, PhsAccTypeStr } from 'consts/MatchingMessageType';
import IModalFrameProps from 'interfaces/IModalFrameProps';
import { IAccProgressCompleteDto } from 'interfaces/_v2/acc/IAccProgressCompleteRs';
import { inject, observer } from 'mobx-react';
import MatchApplyModel from 'models/_v2/MatchApplyModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { DateFormatYYYYMMDDHHMMWithDot } from 'utils/DateUtils';
import MatchApplyNoResultModal from 'components/_v2/matchApply/MatchApplyNoResultModal';

const NoImgFrame = styled.div`
  padding-top:102px;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img{
    width:100px;
    margin-bottom:32px;
  }

  h1{
    text-align: center;
    font: ${Fonts.H5_Bold};
    line-height: 30px;
    color:${colors.JOBDA_BLACK};
  }

  h2 {
    text-align: center;
    font: ${Fonts.B2_Medium};
    line-height: 24px;
    color: ${colors.CG_70};
  }
`;

const ListFrame = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  


  .li-frame {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;

    >div {
      display: flex;
      align-items: center;
    }

    .left-frame {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .match-tag {
      display: flex;
      align-items: center;
      font: ${Fonts.B3_Bold};
      color: ${colors.JOBDA_BLACK};
    }

    .date {
      font: ${Fonts.B3_Medium};
      line-height: 16px;
      color: ${colors.JOBDA_BLACK};
      padding-left: 4px;
    }

    .slider {
      width: 1px;
      height: 12px;
      background-color: ${colors.CG_40};
      margin: 0 10px;
    }

    .result-btn {
      font: ${Fonts.B3_Medium};
      line-height: 16px;
      color: ${colors.CG_80};
      text-align: center;
      text-decoration: underline;

      &:disabled {
        opacity: 0.5;
      }
    }
  }
`;

const NoImgComponent:FC<{ type: string }> = ({ type }) => (
  <NoImgFrame>
    <img src={imgNoJoy} alt='no-joy' />
    <h1>{type} 결과가 없습니다.</h1>
    <h2>*{type} 응시는 PC에서만 가능합니다.</h2>
  </NoImgFrame>
);

interface IMatchApplySubmitModalProps extends IModalFrameProps {
    submitType: PhsAccType;
    matchApplyModel?: MatchApplyModel;
}

const MatchApplySubmitModal:FC<IMatchApplySubmitModalProps> = ({ matchApplyModel = new MatchApplyModel(), submitType, isOpen, onClickClose }) => {
  const useformed = useForm({ mode: 'onSubmit' });
  const { watch } = useformed;
  const [currentApplySn, setCurrentApplySn] = useState<number | null>(null);
  const { accProgressList, paccProgressList, pstProgressList } = matchApplyModel;
  const [accExist, setAccExist] = useState<boolean>(false);
  const [paccExist, setPaccExist] = useState<boolean>(false);
  const [pstExist, setPstExist] = useState<boolean>(false);

  const [isOpenAccResultModal, setIsOpenAccResultModal] = useState<boolean>(false);
  const [isOpenPstResultModal, setIsOpenPstResultModal] = useState<boolean>(false);

  const [selectedResultSn, setSelectedResultSn] = useState(0);
  const [selectedResultItem, setSelectedResultItem] = useState<IAccProgressCompleteDto | undefined>(undefined);

  useEffect(() => {
    const init = async () => {
      switch (submitType) {
        case PhsAccType.ACC:
          await matchApplyModel.loadAccProgressList();
          if (matchApplyModel.accProgressList) setAccExist(matchApplyModel.accProgressList?.some((record) => [MatchAccAnalysisType.COMPLETE, MatchAccAnalysisType.PROGRESS].includes(record.analysisType)));
          break;
        case PhsAccType.PACC:
          await matchApplyModel.loadPaccProgressList();
          if (matchApplyModel.paccProgressList) setPaccExist(matchApplyModel.paccProgressList?.some((record) => [MatchPHSAnalysisType.COMPLETED, MatchPHSAnalysisType.IN_PROGRESS].includes(record.analysisType)));
          break;
        case PhsAccType.PST:
          await matchApplyModel.loadPstProgressList();
          if (matchApplyModel.pstProgressList) setPstExist(matchApplyModel.pstProgressList?.some((record) => [MatchPHSAnalysisType.COMPLETED, MatchPHSAnalysisType.IN_PROGRESS].includes(record.analysisType)));
          break;
        default:
          break;
      }
    };

    init();
    //   TODO: isOpen말고 다른걸로 변경
  }, [isOpen]);

  useEffect(() => {
    setCurrentApplySn(watch('applySn'));
  }, [watch('applySn'), watch]);

  const submitResult = async () => {
    const applySn = watch('applySn');
    //   TODO: 에러 처리
    if (applySn) {
      if (submitType === PhsAccType.ACC) {
        await matchApplyModel.putAccResultMatching(applySn);
      } else if (submitType === PhsAccType.PACC) {
        await matchApplyModel.putPaccResultMatching(applySn);
      } else if (submitType === PhsAccType.PST) {
        await matchApplyModel.putPstResultMatching(applySn);
      }
      await matchApplyModel.loadMatchingProfile(); // update
    }
    if (onClickClose) onClickClose();
  };

  // TODO: ACC, PACC, PST 분석 타입이나 인터페이스들이 미세하게 다 달라서 통합처리하기 어려움. 추후 코드 최적화 필요
  return (
    <>
      <JDUnderBaseModal isOpen={isOpen} onClickClose={() => onClickClose && onClickClose()}>
        { submitType === PhsAccType.ACC && !accExist
          ? <MatchApplyNoResultModal onClickClose={onClickClose} />
          : (
            <FormProvider {...useformed}>
              <StyledSelectModalFrame>
                <div className='title'>잡다매칭에 사용할 결과표를 선택해 주세요.</div>
                <div className='content-frame'>
                  {/* ACC */}
                  { submitType === PhsAccType.ACC
                    ? accProgressList && accProgressList.length > 0 && accExist
                      ? (
                        <ListFrame>
                          { accProgressList.map((item) => (
                            item.analysisType !== MatchAccAnalysisType.FAILED
                        && (
                        <li className='li-frame' key={item.sn}>
                          <div>
                            <JDRadioInput
                              key={`radio-${item.code}`}
                              type={JD_RADIO_INPUT_TYPE.ROUND_CIRCLE_BOX}
                              name='applySn'
                              value={item.sn}
                              defaultValue={accProgressList.find((result) => result.matchingUseYn)?.sn}
                              label={(<div />)}
                              disabled={item.analysisType !== MatchAccAnalysisType.COMPLETE}
                            />
                            <div className='left-frame'>
                              {item.matchingUseYn && (
                                <div className='match-tag'>
                                  <Icon name='verified-filled' size={24} color={colors.G_150} />
                                  잡다매칭
                                </div>
                              )}
                              <span className='date'>{DateFormatYYYYMMDDHHMMWithDot(item.applyEndDateTime)}</span>
                            </div>
                            <div className='slider' />
                            <AccPstGrade
                              accGrade={item.resultType}
                              type={submitType}
                              progressYn={item.analysisType === MatchAccAnalysisType.PROGRESS}
                            />
                          </div>

                          <div>
                            <button
                              className='result-btn'
                              disabled={item.analysisType !== MatchAccAnalysisType.COMPLETE}
                              onClick={() => {
                                setSelectedResultSn(item.sn);
                                setSelectedResultItem(item);
                                setIsOpenAccResultModal(true);
                              }}
                            >결과표 보기
                            </button>
                          </div>
                        </li>
                        )
                          ))}
                        </ListFrame>
                      )
                      : (
                        <NoImgComponent
                          type={PhsAccTypeStr[submitType]}
                        />
                      ) : <></>}
                  {/* PACC */}
                  { submitType === PhsAccType.PACC
                    ? paccProgressList && paccProgressList.length > 0 && paccExist
                      ? (
                        <ListFrame>
                          { paccProgressList.map((item) => (
                            item.analysisType !== MatchPHSAnalysisType.FAILED
                        && (
                        <li className='li-frame' key={item.sn}>
                          <div>
                            <JDRadioInput
                              key={`radio-${item.code}`}
                              type={JD_RADIO_INPUT_TYPE.ROUND_CIRCLE_BOX}
                              name='applySn'
                              value={item.sn}
                              defaultValue={paccProgressList.find((result) => result.matchingUseYn)?.sn}
                              label={(<div />)}
                              disabled={item.analysisType !== MatchPHSAnalysisType.COMPLETED}
                            />
                            <div className='left-frame'>
                              {item.matchingUseYn && (
                                <div className='match-tag'>
                                  <Icon name='verified-filled' size={24} color={colors.G_150} />
                                  잡다매칭
                                </div>
                              )}
                              <span className='date'>{DateFormatYYYYMMDDHHMMWithDot(item.applyEndDateTime)}</span>
                            </div>
                            <div className='slider' />
                            <AccPstGrade
                              accGrade={item.resultType}
                              type={submitType}
                              progressYn={item.analysisType === MatchPHSAnalysisType.IN_PROGRESS}
                            />
                          </div>
                          <div>
                            <button
                              className='result-btn'
                              disabled={item.analysisType !== MatchPHSAnalysisType.COMPLETED}
                              onClick={() => {
                                setSelectedResultSn(item.sn);
                                setIsOpenAccResultModal(true);
                              }}
                            >결과표 보기
                            </button>
                          </div>
                        </li>
                        )
                          ))}
                        </ListFrame>
                      )
                      : (
                        <NoImgComponent
                          type={PhsAccTypeStr[submitType]}
                        />
                      ) : <></>}
                  {/* PST */}
                  { submitType === PhsAccType.PST
                    ? pstProgressList && pstProgressList.length > 0 && pstExist
                      ? (
                        <ListFrame>
                          { pstProgressList.map((item) => (
                            item.analysisType !== MatchPHSAnalysisType.FAILED
                        && (
                        <li className='li-frame' key={item.sn}>
                          <div>
                            <JDRadioInput
                              key={`radio-${item.code}`}
                              type={JD_RADIO_INPUT_TYPE.ROUND_CIRCLE_BOX}
                              name='applySn'
                              value={item.sn}
                              defaultValue={pstProgressList.find((result) => result.matchingUseYn)?.sn}
                              label={(<div />)}
                              disabled={item.analysisType !== MatchPHSAnalysisType.COMPLETED}
                            />
                            <div className='left-frame'>
                              {item.matchingUseYn && (
                              <div className='match-tag'>
                                <Icon name='verified-filled' size={24} color={colors.G_150} />
                                잡다매칭
                              </div>
                              )}
                              <span className='date'>{DateFormatYYYYMMDDHHMMWithDot(item.applyEndDateTime)}</span>
                            </div>
                            <div className='slider' />
                            <AccPstGrade
                              pstGrade={item.grade}
                              type={submitType}
                              progressYn={item.analysisType === MatchPHSAnalysisType.IN_PROGRESS}
                            />
                          </div>
                          <div>
                            <button
                              className='result-btn'
                              disabled={item.analysisType !== MatchPHSAnalysisType.COMPLETED}
                              onClick={() => {
                                setSelectedResultSn(item.sn);
                                setIsOpenPstResultModal(true);
                              }}
                            >결과표 보기
                            </button>
                          </div>
                        </li>
                        )
                          ))}
                        </ListFrame>
                      )
                      : (
                        <NoImgComponent
                          type={PhsAccTypeStr[submitType]}
                        />
                      ) : <></>}
                </div>
                <div className='btn-frame'>
                  <button
                    className='btn-select'
                    disabled={!currentApplySn}
                    onClick={submitResult}
                  >선택 완료
                  </button>
                </div>
              </StyledSelectModalFrame>
            </FormProvider>
          )}
        <AccResultModal
          isOpen={isOpenAccResultModal}
          onClose={() => setIsOpenAccResultModal(false)}
          sn={selectedResultSn}
          item={selectedResultItem}
          type={submitType}
        />
        <PstResultModal
          isOpen={isOpenPstResultModal}
          onClose={() => setIsOpenPstResultModal(false)}
          sn={selectedResultSn}
          type={submitType}
        />
      </JDUnderBaseModal>

    </>

  );
};

export default inject(injectStore.matchApplyModel)(observer(MatchApplySubmitModal));
