import usePdf, { getBase64ImageFromUrl } from 'hooks/usePDF';
import React, { FC, useEffect, useState } from 'react';
import ImgStamp from 'assets/_v2/verification/icon_stamp.png';
import ImgLogo from 'assets/_v2/verification/icon_jobda_logo.png';
import styled from 'styled-components';
import Fonts from '__designkit__/common/fonts';
import colors from '__designkit__/common/colors';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import MatchHomeDashboardModel from 'models/_v2/MatchHomeDashboardModel';
import Login from 'models/Login';
import { ICertificationDto } from 'interfaces/_v2/matchHome/dashboard/IMatchHomeDashboard';
import { DateFormatYYMMDDUnit, DateFormatYYMMDDWithDot, DateFormatYYYYMMDDWithDot } from 'utils/DateUtils';
import { MatchHomeDashboardPositionType, MatchHomeDashboardPositionTypeText, MatchHomeDashboardPositionTypeTextForPdf } from 'consts/_v2/matchHome/dashboard/MatchHomeDashboardType';
import Loading from 'components/Loading';

const ApplyButton = styled.button<{ active?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 12px 18px;
  font: ${Fonts.B2_Bold};
  line-height: 22px;
  background: ${(props) => (props.active ? colors.CG_60 : colors.JOBDA_BLACK)}; 
  color: ${colors.WHITE_100};
  border-radius: 4px;

  >a {
    font: ${Fonts.B2_Bold};
    line-height: 22px;
  }

  &:disabled {
    background: ${colors.CG_30};
    color: ${colors.CG_50};
  }

  &[data-disabled=true] {
    background: ${colors.CG_30};
    color: ${colors.CG_50};
  }
`;
export interface IMyPageApplyPdfButton {
  matchHomeDashboardModel?: MatchHomeDashboardModel;
  login?:Login
  selectedPositionSn:number[]
}
const MyPageApplyPdfButton:FC<IMyPageApplyPdfButton> = ({ selectedPositionSn, matchHomeDashboardModel = new MatchHomeDashboardModel(), login = new Login() }) => {
  const { PdfWrapper, downloadPdf } = usePdf();
  const [stampBase64, setStampBase64] = useState<string | null>(null);
  const [logoBase64, setLogoBase64] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    const fetchImage = async () => {
      const base64 = await getBase64ImageFromUrl(ImgStamp);
      if (base64) {
        setStampBase64(base64);
      }
      const logo64 = await getBase64ImageFromUrl(ImgLogo);
      if (logo64) {
        setLogoBase64(logo64);
      }
    };
    fetchImage();
  }, []);
  const pxToPt = (px:number) => px * 0.75;
  const downloadData = async () => {
    await matchHomeDashboardModel.loadApplicationCertification(selectedPositionSn);
    await login.loadUserDefault();
    if (matchHomeDashboardModel.applicationCertification && login.userDefault) {
      handleGeneratePdf();
    }
  };
  const handleGeneratePdf = async () => {
    setIsLoading(true);
    const formatPhoneNumber = (phoneNumber: string) => {
      if (phoneNumber) {
        return phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
      }
    };
    if (matchHomeDashboardModel.applicationCertification && login.userDefault) {
      const docDefinition = {
        pageSize: 'A4', // A4 사이즈로 설정
        content: [
          {
            columns: [
              {
                image: logoBase64,
                width: pxToPt(72), // 96px -> 포인트 단위
                height: pxToPt(16), // 16px -> 포인트 단위
                alignment: 'left',
              },
              { text: `발급번호 : ${matchHomeDashboardModel.applicationCertification.certificationNumber}`, alignment: 'right', style: 'headerSn' },
            ],
          },
          { text: '취업활동 증명서', style: 'title', margin: [0, pxToPt(20), 0, pxToPt(40)] }, // 20px -> 포인트 단위
          {
            style: 'userInfoArea',
            table: {
              widths: ['auto', 'auto', '*'], // 포인트 단위 또는 비율로 지정 가능
              body: [
                [
                  { text: '성명', style: 'label' },
                  { canvas: [{ type: 'line', x1: 0, y1: pxToPt(4), x2: 0, y2: pxToPt(12), lineWidth: pxToPt(1), color: '#E7E8EA' }] },
                  { text: `${login.userDefault.name}`, style: 'value' },
                ],
                [
                  { text: '생년월일', style: 'label' },
                  { canvas: [{ type: 'line', x1: 0, y1: pxToPt(4), x2: 0, y2: pxToPt(12), lineWidth: pxToPt(1), color: '#E7E8EA' }] },
                  { text: `${login.userDefault.birthDate.replaceAll('-', '.')}`, style: 'value' },
                ],
                [
                  { text: '연락처', style: 'label' },
                  { canvas: [{ type: 'line', x1: 0, y1: pxToPt(4), x2: 0, y2: pxToPt(12), lineWidth: pxToPt(1), color: '#E7E8EA' }] },
                  { text: `${formatPhoneNumber(login.userDefault.mobile)}`, style: 'value' },
                ],
              ],
            },
            layout: 'noBorders',
            margin: [0, pxToPt(10), 0, pxToPt(30)], // 10px, 30px -> 포인트 단위
          },
          {
            style: 'applyInfoArea',
            table: {
              headerRows: 1,
              widths: ['14.49%', '23.19%', '47.83%', '14.49%'], // 비율로 지정
              dontBreakRows: true,
              keepWithHeaderRows: 1,
              body: [
                [
                  { text: '지원일', style: 'tableHeader' },
                  { text: '기업명', style: 'tableHeader' },
                  { text: '주소', style: 'tableHeader' },
                  { text: '활동결과', style: 'tableHeader' },
                ],
                ...matchHomeDashboardModel.applicationCertification.jobApplicationCertificationDtos.map((item: ICertificationDto) => [
                  { text: item.applicationCompleteTime ? DateFormatYYMMDDWithDot(item.applicationCompleteTime) : '-', style: 'tableContent' },
                  { text: item.companyName, style: 'tableContent' },
                  { text: item.positionLocation ? item.positionLocation : '-', style: 'tableContent' },
                  { text: MatchHomeDashboardPositionTypeTextForPdf[item.jobApplicationProcessType], style: 'tableContent' },
                ]),
              ],
            },
            layout: {
              fillColor(rowIndex: any, node: any, columnIndex: any) {
                return rowIndex === 0 ? '#eeeeee' : null;
              },
              vLineWidth(i: any) {
                return 0;
              },
              hLineColor(i: any, node: any) {
                if (i === 0 || i === node.table.body.length) {
                  return '#121619'; // 상단과 하단 보더 색
                }
                return '#e7e8ea'; // 나머지 보더 색
              },
              hLineWidth(i: any, node: any) {
                return pxToPt(1);
              },
              paddingLeft(i: any) {
                return i === 0 ? 0 : pxToPt(8); // 8px -> 포인트 단위
              },
              paddingRight(i: any, node: any) {
                return i === node.table.widths.length - 1 ? 0 : pxToPt(8); // 8px -> 포인트 단위
              },
              paddingTop(i: any) {
                return pxToPt(4); // 4px -> 포인트 단위
              },
              paddingBottom(i: any) {
                return pxToPt(4); // 4px -> 포인트 단위
              },
            },
            margin: [0, 0, 0, pxToPt(30)], // 30px -> 포인트 단위
          },
        ],
        styles: {
          header: {
            fontSize: pxToPt(24), // 24px -> 포인트 단위
            bold: true,
          },
          title: {
            fontSize: pxToPt(32), // 32px -> 포인트 단위
            bold: true,
            margin: [0, pxToPt(20), 0, pxToPt(20)], // 20px -> 포인트 단위
          },
          headerSn: {
            fontSize: pxToPt(10), // 10px -> 포인트 단위
            bold: false,
            color: '#9fa4ab',
          },
          userInfoArea: { margin: [0, pxToPt(16), 0, pxToPt(48)] }, // 16px, 48px -> 포인트 단위
          label: {
            width: pxToPt(50), // 50px -> 포인트 단위
            fontSize: pxToPt(12), // 12px -> 포인트 단위
            color: '#44474b',
          },
          divider: {
            width: pxToPt(1), // 1px -> 포인트 단위
            height: pxToPt(8), // 8px -> 포인트 단위
            backgroundColor: '#E7E8EA',
          },
          value: { color: '#44474b', fontSize: pxToPt(12) }, // 12px -> 포인트 단위
          applyInfoArea: { margin: [0, pxToPt(25), 0, pxToPt(25)] }, // 25px -> 포인트 단위
          tableHeader: {
            fontSize: pxToPt(9), // 12px -> 포인트 단위
            color: '#656a71',
            alignment: 'left',
            fillColor: '#F4F4F5',
            margin: [pxToPt(8), pxToPt(10), 0, pxToPt(10)], // 10px -> 포인트 단위
          },
          tableContent: { margin: [pxToPt(8), pxToPt(18), 0, pxToPt(18)], alignment: 'left', fontSize: pxToPt(10), color: '#121619' }, // 18px, 10px -> 포인트 단위
          footer: {
            fontSize: pxToPt(9), // 9px -> 포인트 단위
            bold: false,
            color: '#656A71',
            alignment: 'left',
          },
          footerRightTop: {
            fontSize: pxToPt(12), // 12px -> 포인트 단위
            bold: true,
            color: '#121619',
            alignment: 'right',
          },
          footerRight: {
            fontSize: pxToPt(9), // 9px -> 포인트 단위
            color: '#656A71',
            alignment: 'right',
          },
        },
        defaultStyle: { font: 'Pretendard' },
        footer: () => {
          if (matchHomeDashboardModel.applicationCertification) {
            const nonNullApplications = matchHomeDashboardModel.applicationCertification.jobApplicationCertificationDtos.filter(
              (item: ICertificationDto) => item.applicationCompleteTime !== null,
            );
            const sortedApplications = nonNullApplications.sort((a, b) => (a.applicationCompleteTime > b.applicationCompleteTime ? -1 : 1));
            const lastIndex = sortedApplications.length - 1;
            const today = new Date();
            return {
              columns: [
                {
                  stack: [
                    { text: sortedApplications.length > 0 ? `${DateFormatYYMMDDUnit(sortedApplications[lastIndex].applicationCompleteTime)} 부터 ${DateFormatYYMMDDUnit(sortedApplications[0].applicationCompleteTime)}까지` : '날짜 정보 없음', style: 'footer', margin: [0, pxToPt(25), 0, 0] },
                    { text: '잡다(https://www.jobda.im/)를 통해 위와 같이 취업 활동했음을 증명합니다.', style: 'footer' },
                  ],
                  alignment: 'right',
                },
                {
                  stack: [
                    { text: '자인원', style: 'footerRightTop', margin: [0, pxToPt(20), 0, 0] }, // 도장 이미지의 하단에 맞추기 위해 margin 조정
                    { text: `${DateFormatYYYYMMDDWithDot(today.toISOString())}`, style: 'footerRight', margin: [0, 0, 0, 0] }, // 도장 이미지의 하단에 맞추기 위해 margin 조정
                  ],
                  alignment: 'right',
                },
                {
                  image: stampBase64,
                  width: pxToPt(50), // 50px -> 포인트 단위
                  height: pxToPt(50), // 50px -> 포인트 단위
                  alignment: 'right',
                },
              ],
              margin: [pxToPt(40), 0], // 40px -> 포인트 단위
            };
          }
        },
        pageMargins: [pxToPt(40), pxToPt(50), pxToPt(40), pxToPt(100)], // 40px, 50px, 40px, 100px -> 포인트 단위
      };
      const fileName = `취업증명서_${login.userDefault.name}.pdf`;
      await downloadPdf(docDefinition, fileName);
      setIsLoading(false);
    }
  };
  if (isLoading) { <Loading />; }
  return (
    <>
      <ApplyButton
        onClick={async () => {
          await downloadData();
        }}
        disabled={false}
      >
        다운로드
      </ApplyButton>
    </>
  );
};

export default inject(injectStore.matchHomeDashboardModel, injectStore.login)(observer(MyPageApplyPdfButton));
