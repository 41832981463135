import JDALink from 'components/JDALink';
import Fonts from '__designkit__/common/fonts';
import { lineClampBox } from 'consts/_v2/_common/style/mixins';
import React, { FC } from 'react';
import styled from 'styled-components';
import Colors from '__designkit__/common/colors';

const ContentCardFrame = styled.div<{ darkMode?:boolean }>`
  position:relative;
  width: 240px;
  .img{
    width: 240px;
    height: 180px;
    border-radius: 8px;
  }
  .title{
    font: ${Fonts.B2_Bold};
    color:${(props) => (props.darkMode ? `${Colors.JOBDA_WHITE}` : `${Colors.JOBDA_BLACK}`)};
    margin-top: 12px;
    height: 36px;
    ${lineClampBox(2)}
  }
  .category{
    font: ${Fonts.B3_Medium};
    color:${(props) => (props.darkMode ? `${Colors.JOBDA_WHITE}` : `${Colors.JOBDA_BLACK}`)};
    text-decoration: underline;
    margin-top: 16px;
  }
  .hash-tag-frame{
    display: flex;
    justify-content: flex-start;
    margin-top: 12px;
    .hash-tag{
        font: ${Fonts.B3_Medium};
        color:${Colors.CG_60};
        margin-right: 8px;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
    }
  }
`;

interface IContentCard {
  className?: string;
  title: string;
  category: string;
  label: string;
  image:string;
  bannerLink:string;
}

const ContentCard: FC<{ className?: string, darkMode?:boolean, item: IContentCard }> = ({ className, darkMode = false, item }) => (
  <JDALink to={item.bannerLink}>
    <ContentCardFrame className={className} darkMode={darkMode}>
      <img src={item.image} className='img' alt='img' />
      <div className='category'>{item.category}</div>
      <div className='title'>{item.title}</div>
      <div className='hash-tag-frame'>
        <div className='hash-tag'>{item.label}</div>
      </div>
    </ContentCardFrame>
  </JDALink>
);

export default ContentCard;
