import CompanyBookmarkCard from '__designkit__/card/CompanyBookmarkCard';
import Colors from '__designkit__/common/colors';
import MatchHomeNotFound, { MatchHomeNotFoundViewType } from 'components/_v2/matchHome/MatchHomeNotFound';
import { useBookmarkCompanies } from 'query/bookmark/useBookmarkQuery';
import React from 'react';
import styled from 'styled-components';

const Frame = styled.div`
    .divider{
        width: 100%;
        height: 1px;
        background-color: ${Colors.CG_40};
    }
`;
const MyPageBookmarkCompanies = () => {
  const { data: bookmarkCompanies } = useBookmarkCompanies();
  return (
    <Frame>
      {bookmarkCompanies && bookmarkCompanies.map((item) => (
        <>
          <CompanyBookmarkCard companySn={item.companySn} logo={item.logoImageUrl} title={item.companyName} numberOfPositions={item.numberOfPositions} bookmark={item.likeYn} />
          <div className='divider' />
        </>
      ))}
      { bookmarkCompanies && bookmarkCompanies.length === 0 && (
      <MatchHomeNotFound content={MatchHomeNotFoundViewType.COMPANY} height={282} />
      )}
    </Frame>
  );
};

export default MyPageBookmarkCompanies;
