import IconClose from 'assets/icon/icon_close_24.svg';
import JDBaseModal from 'components/_v2/_common/modals/JDBaseModal';
import RoutePaths from 'consts/RoutePaths';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import PositionListModel from 'models/_v2/PositionListModel';
import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Frame = styled.div`
  width:296px;
  height: 252px;
  display: flex;
  flex-direction: column;
  background: ${colors.WHITE_100};
  border-radius: 12px;
  padding:24px;
  .icon-close{
    align-self: flex-end;
    margin-bottom: 8px;
  }
  h1{
    font: ${Fonts.H4_Bold};
    ${colors.JOBDA_BLACK};
    text-align: center;
    margin-bottom: 8px;
  }
  h2{
    font: ${Fonts.B2_Medium};
    ${colors.CG_70};
    text-align: center;
    margin-bottom:24px;
  }

  >a{
    display: contents;
  }

  .bookmark-view-btn{
    padding: 10px 16px;
    border-radius: 4px;
    font: ${Fonts.B2_Bold};
    ${colors.CG_70};
    text-align: center;
    color:${colors.WHITE_100};
    background: ${colors.JOBDA_BLACK};
  }
`;

const PostionFisrtBookmarkModal: FC<{ positionListModel?: PositionListModel }> = ({ positionListModel = new PositionListModel() }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onCloseBookmarkModal = () => {
    positionListModel.isFirstBookmark = false;
  };

  useEffect(() => {
    // 다른 페이지에서 bookmark 해제시 포지션에서 북마크 영향 제거를 위한 초기화 추가
    positionListModel.isFirstBookmark = false;
  }, []);

  useEffect(() => {
    if (positionListModel.isFirstBookmark) setIsOpen(true);
    else setIsOpen(false);
  }, [positionListModel.isFirstBookmark]);

  return (
    <JDBaseModal dimmed={false} isOpen={isOpen} onClickClose={onCloseBookmarkModal}>
      <Frame>
        <button className='icon-close' onClick={onCloseBookmarkModal}><img src={IconClose} alt='icon-close' /></button>
        <h1>처음으로 관심있는 포지션을<br />저장하셨네요!</h1>
        <h2>{`북마크한 포지션은 [지원현황] > 북마크에서`}<br />확인 할 수 있어요.</h2>
        <Link to={RoutePaths.mypage_bookmark} onClick={onCloseBookmarkModal}>
          <button className='bookmark-view-btn'>북마크한 포지션 보기</button>
        </Link>
      </Frame>
    </JDBaseModal>
  );
};

export default inject(injectStore.positionListModel)(observer(PostionFisrtBookmarkModal));
