import React, { FC } from 'react';
import Icon from '__designkit__/icon/Icon';
import classnames from 'classnames/bind';
import styles from './challenge.module.scss';
import ScrollUtil from 'utils/ScrollUtil';

const cx = classnames.bind(styles);

// 스크롤 이동을 위한 ref
interface IChallenge{
  missionOneRef: React.RefObject<HTMLDivElement>;
  missionTwoRef: React.RefObject<HTMLDivElement>;
}

// 결과적으로, maxScroll 변수에는 현재 페이지의 최대 스크롤 가능한 높이가 저장되며, 이 값을 이용하여 페이지 스크롤을 관리하거나 다른 스크롤 관련 작업에 사용할 수 있습니다
const Challenge:FC<IChallenge> = ({ missionOneRef, missionTwoRef }) => {
  const getPageMaxScroll = () => Math.max(
    document.body.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.clientHeight,
    document.documentElement.scrollHeight,
    document.documentElement.offsetHeight,
  ) - window.innerHeight;
  const maxScroll = getPageMaxScroll();

  // 챌린지 미션 1버튼 이동
  const missionOneClick = () => {
    const refOffSetTop = missionOneRef.current?.offsetTop;
    if (refOffSetTop) {
      if (refOffSetTop <= maxScroll || maxScroll === 0) {
        ScrollUtil.scrollTo({
          top: refOffSetTop - 100,
          left: 0,
          behavior: 'smooth',
        });
      } else {
        ScrollUtil.scrollTo({
          top: maxScroll,
          left: 0,
          behavior: 'smooth', // 이동이 스무스하게 이루어짐
        });
      }
    }
  };

  // 챌린지 미션 2버튼 이동
  const missionTwoClick = () => {
    const refOffSetTop = missionTwoRef.current?.offsetTop;
    if (refOffSetTop) {
      if (refOffSetTop <= maxScroll || maxScroll === 0) {
        ScrollUtil.scrollTo({
          top: refOffSetTop - 100,
          left: 0,
          behavior: 'smooth',
        });
      } else {
        ScrollUtil.scrollTo({
          top: maxScroll,
          left: 0,
          behavior: 'smooth', // 이동이 스무스하게 이루어짐
        });
      }
    }
  };

  return (
    <div className={cx('wrap')}>
      <div className={cx('inner')}>
        <div className={cx('sectionTitleArea')}>
          <strong className={cx('sectionTitle')}>취업 JOBDA 챌린지란?</strong>
          <p className={cx('sectionDesc')}>
            어려운 자소서, 복잡한 채용과정에
            <br />
            지친 취준생분들을 위해
            <br />
            빠른 취업에 필요한 자료집과
            <br />
            다양한 혜택까지 드리는 챌린지입니다.
          </p>
        </div>

        {/* 미션 리스트 영역 */}
        <ul className={cx('missionList')}>
          <li className={cx('listItem')}>
            <div className={cx('itemInner')}>
              <p className={cx('itemTitle')}>챌린지 참여 혜택 1</p>
              <p className={cx('itemDesc')}>
                프로필만 작성해도!
                <br />
                대기업 면접질문 연습 서비스
                <br />
                + 네이버페이 2,000P 제공!
              </p>
              <button onClick={missionOneClick} className={cx('btnEvent', 'outLine')}>
                자세히 보기
                <Icon name='arrow-upward' size={24} />
              </button>
            </div>
            <div className={cx('imgArea', 'type1')} />
          </li>

          <li className={cx('listItem')}>
            <div className={cx('itemInner')}>
              <p className={cx('itemTitle')}>챌린지 참여 혜택 2</p>
              <p className={cx('itemDesc')}>
                역량검사만 응시해도!
                <br />
                합격자 이력서 모음집
                <br />
                + 네이버페이 10,000P 제공!
              </p>
              <button onClick={missionTwoClick} className={cx('btnEvent', 'outLine')}>
                자세히 보기
                <Icon name='arrow-upward' size={24} />
              </button>
            </div>
            <div className={cx('imgArea', 'type2')} />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Challenge;
