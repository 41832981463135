import { EducationLevel } from 'consts/_v2/profile/EducationLevel';
import URIs from 'consts/URIs';
import { INameCodeRs } from 'interfaces/rqrs/ICommonRqRs';
import IMajorAliasListGetRs, { IMajorAliasListGetDto } from 'interfaces/rqrs/IMajorAliasListGetRs';
import { IProfileEducation, IProfileEducationRq, IProfileEducationRs } from 'interfaces/_v2/profile/IProfileEducation';
import { action, observable } from 'mobx';
import { request } from 'utils/request';
import store from 'models/store';

export default class ProfileEducationModel {
  @observable educations: IProfileEducation[] | null = null;
  @observable educationLevel: EducationLevel | null = null;
  @observable schools: INameCodeRs[] = [];
  @observable majorAliases: IMajorAliasListGetDto[] = [];
  @observable isDirty: boolean = false;

  @action
  loadEducations = async () => {
    try {
      const res = (await request<IProfileEducationRs>({
        method: 'get',
        url: URIs.get_profiles_education,
      }));
      this.setEducations(res);
      if (res.educations.length > 0) store.profileModel.snbCheckEssential.eduInfo.completeYn = true;
      else store.profileModel.snbCheckEssential.eduInfo.completeYn = false;
    } catch (e) {
      console.error(e);
    }
  }

  @action setEducations = (educations: IProfileEducationRs) => {
    this.educations = educations.educations;
    this.educationLevel = educations.educationLevel;
  }

  @action
  saveEducation = async (educationsRq: Partial<IProfileEducationRq>) => {
    try {
      await request({
        method: 'put',
        url: URIs.put_profiles_education,
        data: { ...educationsRq },
      });
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  @action
  searchMajorAliases = async (name: string) => {
    try {
      this.majorAliases = (await request<IMajorAliasListGetRs>({
        method: 'get',
        url: URIs.get_register_major_aliases,
        params: { name },
      })).majorAliases;
    } catch (e) {
      console.error(e);
      this.majorAliases = [];
    }
  }

  @action
  searchSchools = async (educationLevel: EducationLevel, name: string) => {
    try {
      this.schools = (await request<{ schools: INameCodeRs[] }>({
        method: 'get',
        url: URIs.get_register_schools,
        params: { educationLevel, name },
      })).schools;
    } catch (e) {
      console.error(e);
      this.schools = [];
    }
  }
}
