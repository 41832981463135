import { IconSmallArrowUpDown } from 'consts/assets/icons/iconPages';
import colors from '__designkit__/common/colors';
import { fonts, lineHeights } from '__designkit__/common/fonts';
import React, { useState } from 'react';
import styled from 'styled-components/macro';

const Frame = styled.div`
  height:100%;
  background:${colors.JOBDA_WHITE};
  padding-top:56px;
  padding-bottom:64px;
  padding-left:24px;
  padding-right:24px;

  h4{
    ${fonts.NOTO_13_700};
    color:${colors.G_100};
  }

  h1{
   margin-top:48px;
   margin-bottom:24px;
   ${fonts.NOTO_20_700};
   color:${colors.CG_80}; 
   ${lineHeights.LINEHEIGHT_1_50};
   em{
    ${fonts.WEMAKEP_20_700};
    display: inline-block;
    transform: translateY(-0.05em);
   }
  }


`;

const Bar = styled.div`
  width:100%;
  height:1px;
  background: ${colors.CG_40};
`;

const InfoTab = styled.div`
  padding-top:24px;
  padding-bottom:24px;
  >div{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  h2{
   ${fonts.NOTO_15_700};
   color:${colors.CG_80}; 
   ${lineHeights.LINEHEIGHT_1_50};
  }

  >:nth-child(2){
    margin-top:24px;
  }

  h3{
   margin-bottom:20px;
   ${fonts.NOTO_13_400};
   color:${colors.CG_70};
   ${lineHeights.LINEHEIGHT_1_50};
   em{
     ${fonts.NOTO_13_700};
    }
  }

`;

const AiIntroduceAiResultInfo: React.FC = () => {
  const [openTab1, setOpenTab1] = useState<boolean>(true);
  const [openTab2, setOpenTab2] = useState<boolean>(false);
  const [openTab3, setOpenTab3] = useState<boolean>(false);

  return (
    <Frame>
      <h4>여기서 잠깐</h4>
      <h1><em>역량검사</em> 리포트에 대한<br />
        궁금증을 해결해드립니다.
      </h1>
      <Bar />
      <InfoTab>
        <div role='button' onClick={() => setOpenTab1(!openTab1)}><h2>역량검사 리포트는 정확한가요?</h2><IconSmallArrowUpDown active={openTab1} /></div>
        {openTab1 && (
          <h3>역량검사 리포트는 구직자를 판단하거나 결정하는 정답이 아닙니다. 일반적인 인적성검사나 대면면접 보다 훨씬 <em>더 합리적</em>이고 <em>객관적인 관점으로 구직자 역량을 분석</em>하고 이를 통해 자신의 역량에 대한 합리적 이해를 통해 <em>성장의 방향을 설정하는데 도움을 주는 모범 답안</em>의 역할이라고 생각합니다.
          </h3>
        )}
      </InfoTab>
      <Bar />
      <InfoTab>
        <div role='button' onClick={() => setOpenTab2(!openTab2)}><h2>왜 12가지 유형인가요?</h2><IconSmallArrowUpDown active={openTab2} /></div>
        {openTab2 && (
          <>
            <h3>천인천색(千人千色), 만인만색(萬人萬色)이라는 말이 있습니다. 사람의 얼굴과 개성, 성격과 성향, 능력과 역량 등은 모두 다르며 똑같은 사람은 없다는 의미입니다. 한 사람 한 사람은 그 자체로 유일한 존재이며 각자의 아름다운 결을 가진 인격체로서 존중받아야 합니다. 만약 잡다가 사람을 평가하는 의도였다면 78억 개의 유형이 있어야 합니다.</h3>
            <h3>그러나 <em>잡다는 사람의 역량에 주목하고 역량중심 사회를 만들어 가기 위해</em> 역량검사를 통해 핵심역량을 분석하고 역량프로파일을 통해 나온 경향성에 따라 개념화를 진행하였습니다. 이후, <em>빅데이터 분석으로 역량별 차이점을 구분하고 분류하여 역량검사 결과를 12가지로 유형으로 범주화하였습니다.</em></h3>
          </>
        )}
      </InfoTab>
      <Bar />
      <InfoTab>
        <div role='button' onClick={() => setOpenTab3(!openTab3)}><h2>강점과 약점은 무슨 의미인가요?</h2><IconSmallArrowUpDown active={openTab3} /></div>
        {openTab3 && (
          <>
            <h3>잡다는 역랑중심 사회를 위해서 역량이라는 가장 중요한 공통분모를 중심으로 취업과 채용을 돕고자 개발하였습니다.</h3>
            <h3>우리는 자신의 강점은 강화하고 약점은 개선해야 한다는 말을 많이 듣습니다. 그러나 우리가 <em>행복한 삶을 살기 위해서는 약점보다는 강점을 바탕으로 우리에게 주어지는 기회와 역할에서 역량을 최대한 발휘하여 성공경험을 할 때 행복할 수 있습니다.</em></h3>
            <h3>기업은 좋은 인재를 채용하여 조직의 좋은 성과를 통해 기업이 성장하기를 원합니다. 그러므로 <em>기업은 지원자의 약점을 보고 인재를 판단하고 인재를 채용하지는 않습니다.</em> 기업은 지원자의 강점을 이용해 조직의 성과를 만들고 이익을 얻는 것이 중요하기 때문입니다. 그럼에도 지원자들은 자신의 강점보다 약점에 더 신경을 씁니다. 강점을 계속해서 ‘강화’하고 ‘함양’하는 것은 매우 중요하며 약점은 개선하거나 보완하는 것이 아니라 ‘조심’하는 것입니다.</h3>
            <h3>약점으로 인해 내가 하고자 하는 일이 그릇되지 않도록 계속해서 주시하고 조심하도록 하며 <em>강점을 기반으로 더 능동적이고 주체적으로 생각하고 행동하는 것이 행복한 삶의 원동력입니다.</em></h3>
          </>
        )}
      </InfoTab>
      <Bar />
    </Frame>
  );
};

export default AiIntroduceAiResultInfo;
