import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import Colors from '__designkit__/common/colors';
import IComponentProps from 'interfaces/props/IComponentProps';
import Fonts from '__designkit__/common/fonts';
import JDCheckInput, { JD_CHECK_INPUT_TYPE } from 'components/_v2/_common/input/JDCheckInput';
import { useFormContext } from 'react-hook-form';
import JDClosableChip from 'components/_v2/_common/chip/JDClosableChip';

const SELECTED_FRAME_HEIGHT = 60;

const Frame = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  height: 100%;
  border-top: 1px solid ${Colors.JOBDA_WHITE};
  background-color: ${Colors.WHITE_100};
  
  & *::-webkit-scrollbar {
    display: none;
  }
  `;

const GroupFrame = styled.div`
  width: fit-content;
  height: calc(100% - ${SELECTED_FRAME_HEIGHT}px - 78px);
  border-right: 1px solid ${Colors.CG_40};
  background-color: ${Colors.CG_30};
  overflow-y: scroll;
`;

const Group = styled.div<{ active: boolean }>`
  width: 100%;
  height: fit-content;
  padding: 12px 24px 12px 16px;
  background-color: ${({ active }) => (active ? Colors.WHITE_100 : 'transparent')};
  font: ${Fonts.B2_Medium};
  color: ${Colors.CG_70};
`;

const ItemFrame = styled.div`
  width: fit-content;
  height: calc(100% - ${SELECTED_FRAME_HEIGHT}px - 80px);
  padding: 20px;
  overflow-y: scroll;

  & > div:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const SelectedFrame = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  width: 100vw;
  height: ${SELECTED_FRAME_HEIGHT}px;
  border-top: 1px solid ${Colors.CG_40};
  overflow: hidden;
  bottom: calc(env(safe-area-inset-bottom) + 78px);
  background-color: ${Colors.WHITE_100};
  & > div {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 16px;
    overflow-x: scroll;

    & > div:not(:last-child) {
      margin-right: 8px;
    }
  }

  & > span {
    position: absolute;
    top: 0;
    display: block;
    width: 20px;
    height: 100%;
    float: left;
    
    &.left {
      left: 0;
      background: linear-gradient(to right, ${Colors.WHITE_100}, rgba(255, 255, 255, 0));
    }

    &.right {
      right: -1px;
      background: linear-gradient(to left, ${Colors.WHITE_100}, rgba(255, 255, 255, 0));
    }
  }
`;

const getJobNameByCode = (jobs: TwoDepthItem[], code: number): string => {
  for (let i = 0; i < jobs.length; i++) {
    for (let j = 0; j < jobs[i].children.length; j++) {
      if (jobs[i].children[j].code === code) return jobs[i].children[j].name;
    }
  }
  return '오류사항';
};

export interface TwoDepthItem {
  code: number;
  name: string;
  children: Item[];
}

interface Item {
  code: number;
  name: string;
  value: any;
}

interface IJobGroupSelectViewProps extends IComponentProps {
  name: string;
  jobGroups: TwoDepthItem[];
}

const JobGroupSelectView: FC<IJobGroupSelectViewProps> = ({ name, jobGroups }) => {
  const [currentGroup, setCurrentGroup] = useState<number>(-1);
  const formMethods = useFormContext();
  const { watch, setValue } = formMethods;

  useEffect(() => {
    if (!jobGroups.length) return;
    setCurrentGroup(jobGroups[0].code);
  }, [jobGroups]);

  return (
    <Frame>
      <GroupFrame>
        { jobGroups.map((jobGroup) => (
          <Group active={currentGroup === jobGroup.code} onClick={() => setCurrentGroup(jobGroup.code)}>
            { jobGroup.name }
          </Group>
        )) }
      </GroupFrame>
      { jobGroups.map((jobGroup) => (
        <ItemFrame aria-hidden={currentGroup !== jobGroup.code}>
          { jobGroup.children.map((job) => (
            <JDCheckInput
              key={`${name}-${job.code}`}
              type={JD_CHECK_INPUT_TYPE.CHECK_BOX}
              name={`${name}[${job.code}]`}
              value={job.value}
              label={<span>{job.name}</span>}
              defaultChecked={watch(`jobTitles.${job.code}`) === false}
            />
          ))}
        </ItemFrame>
      ))}

      <SelectedFrame>
        <span className='left' />
        <div>
          { watch(name) && watch(name).filter((job: boolean | { jobGroupCode: number, jobTitleCode: number }) => job).map((job: any) => (
            <JDClosableChip onClose={() => { setValue(`${name}[${job.jobTitleCode}]`, false); }}>{getJobNameByCode(jobGroups, job.jobTitleCode)}</JDClosableChip>
          ))}
        </div>
        <span className='right' />
      </SelectedFrame>

    </Frame>
  );
};

export default JobGroupSelectView;
