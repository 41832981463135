import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import Loading from 'components/Loading';
import JDASimplelModal from 'components/modals/JDASimpleModal';
import MatchAtsFailModal from 'components/modals/MatchAtsFailModal';
import MatchMessageFileSubmitModal from 'components/modals/MatchMessageFileSubmitModal';
import { MatchingCommandType, MatchingFileTypeList } from 'consts/MatchingMessageType';
import { lineClampOnlyOne } from 'consts/_v2/_common/style/mixins';
import useToast from 'hooks/useToast';
import { IMatchingTalkMessageOptionDto, IMessageAttachFileDto } from 'interfaces/rqrs/IMatchingMessageListRs';
import { IPositionAgreeCheckRs } from 'interfaces/terms/IAgreeInformationRs';
import { inject, observer } from 'mobx-react';
import MatchMessageStore from 'models/MatchMessageStore';
import TermsModel from 'models/TermsModel';
import { injectStore } from 'models/store';
import { FC, useEffect, useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import MobileStore from 'store/mobileStore';
import styled from 'styled-components';
import { formatDateWithDayOfWeek } from 'utils/DateUtils';
import FileUtil from 'utils/FileUtil';
import MatchFileTermModal from '../agree/MatchFileTermModal';

// 50MB
const LIMIT_FILE_SIZE: number = 52428800;

const Frame = styled.div`
  margin-top: 18px;

  .button-accept {
    background: ${colors.JOBDA_BLACK};
    color: ${colors.WHITE_100};
    font: ${Fonts.B2_Bold};
    width: 100%;
    border-radius: 4px;
    padding:14px 22px;
    &:disabled {
      opacity: 0.64;
      background-color: ${colors.CG_40};
      color:${colors.CG_60};

    }
  }

  input[type="file"] {
      position: absolute;
      width: 0;
      height: 0;
      padding: 0;
      overflow: hidden;
      border: 0;
  }
  .upload-frame{
    display: flex;
    margin-top: 8px;
    .upload-title{
      font:${Fonts.B2_Bold_P};
      color:${colors.CG_90};
    }
    .upload-date{
      font:${Fonts.B2_Medium_P};
      color:${colors.CG_70};
      margin-left: 12px;
    }
  }
  .upload-description{
    margin-top: 4px;
    font:${Fonts.B3_Medium_P};
    color:${colors.CG_60};
  }
`;

const UploadButton = styled.button<{decision?:boolean}>`
  width: 100%;
  display: flex;
  height: 46px;
  justify-content: center;
  align-items: center;
  padding: 14px 22px;
  gap:6px;
  background-color: ${colors.CG_40};
  border-radius: 4px;
  .btn-title{
    font:${Fonts.B2_Bold};
    color:${(props) => (props.decision ? `${colors.CG_50}` : `${colors.JOBDA_BLACK}`)};
  }

`;
const FileContent = styled.div`
`;

const FileList = styled.ul<{disabled?:boolean}>`

  .file-list{
    width: 100%;
    padding:14px 12px 14px 16px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    border: 1px solid ${colors.CG_40};
    background-color:${(props) => (props.disabled ? `${colors.CG_30}` : `${colors.WHITE_100}`)} ;
    border-radius: 4px;
    .file-text{
      font:${Fonts.B2_Medium_P};
      color:${(props) => (props.disabled ? `${colors.CG_50}` : `${colors.CG_70}`)} ;
      width: 220px;
      ${lineClampOnlyOne()}
    }
  }
`;

const FileHeader = styled.div`
  > p {
    font: ${Fonts.B3_Medium};
    color: ${colors.CG_60};
    margin-bottom: 12px;
  }

  .button-box {
    display: flex;
    flex-direction: row-reverse;
    img { 
      width: 12px;
    }
    span {
      font: ${Fonts.B3_Bold};
    }
  }
`;

interface IMatchDashBoardFileProps {
    termsModel?: TermsModel;
    matchMessageStore?: MatchMessageStore;
    optionSn: number;
    command: MatchingCommandType;
    companyAttachFiles: IMessageAttachFileDto[];
    messageSn: number;
    loadMessage: () => void;
    decisionDueDateTime: string;
    decision:IMatchingTalkMessageOptionDto
}

const MatchDashBoardFile:FC<IMatchDashBoardFileProps> = ({ decisionDueDateTime, decision, messageSn, loadMessage, termsModel = new TermsModel(), matchMessageStore = new MatchMessageStore(), optionSn, command, companyAttachFiles }) => {
  const name = 'dashBoardFile';
  const useFormed = useFormContext();
  const { control, register, watch, reset } = useFormed;
  const fileRef = useRef<HTMLInputElement>();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const [viewTermModal, setViewTermModal] = useState<boolean>(false);
  const [isFileModalVisible, setFileModalVisible] = useState(false);
  const [atsFailModalOpen, setAtsFailModalOpen] = useState<boolean>(false);
  const [fileNameList, setFileNameList] = useState<string[]>([]);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>('');
  const [modalContent, setModalContent] = useState<string>('');
  const [modalSubDesc, setModalSubDesc] = useState<string>('');
  const [files, setFiles] = useState<File[]>([]);
  const { setToastObject } = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const init = async () => {
      termsModel.fileTermInit();
      await termsModel.loadFileTermsAgree();
      if (termsModel.termsPositionAgreeCheck) {
        if (!getCheckTerms(termsModel.termsPositionAgreeCheck)) {
          await termsModel.loadTermsPrivacyPolicy();
          await termsModel.loadTermsPrivacyProvision();
          termsModel.privacyLists = [...termsModel.privacyPolicy, ...termsModel.privacyProvision];
        }
      }
      // companyAttachFiles.map((file) => (append({ fileName: file.fileName, fileUid: file.fileUid, fileSize: file.fileSize })));
    };
    init();
  }, []);

  const handleFileSubmit = async () => {
    try {
      await matchMessageStore.SubmitFile(files, matchMessageStore.positionSn, optionSn);
      setFileModalVisible(false);
      loadMessage();
      await matchMessageStore.loadMessage(messageSn);
      reset();
      if (matchMessageStore.message?.companyAttachFiles)
        matchMessageStore.message.companyAttachFiles.map((file) => (append({ fileName: file.fileName, fileUid: file.fileUid, fileSize: file.fileSize })));
    } catch (e: any) {
      if (e.response.data.errorCode === 'B901') {
        setModalTitle(`종료된 포지션입니다.`);
        setModalContent(`포지션이 종료되어<br/>파일 업로드가 불가능 합니다.`);
        setModalOpen(true);
      } else if (e.response.data.errorCode === 'B902') {
        setModalTitle(`파일 개수가 초과되었습니다.`);
        setModalContent(`파일은 총 10개를 넘을 수 없습니다.<br/>
        더 많은 파일을 업로드하시려면 여러 개의<br/> 파일을 압축하신 후 업로드해 주세요.`);
        setModalOpen(true);
      } else if (e.response.data.errorCode === 'B903') {
        setModalTitle(`첨부할 수 없는 파일입니다.`);
        setModalContent(`파일 용량은 총 50MB를 넘을 수 없습니다.<br/>
        용량을 줄이신 후 다시 업로드해 주세요.`);
        setModalOpen(true);
      } else if (e.response.data.errorCode === 'B907') {
        setModalTitle(`첨부할 수 없는 파일입니다.`);
        setModalContent(`업로드 가능한 파일 형식을 확인해 주세요.`);
        setModalSubDesc(`- 문서: doc,docx,hwp,pdf,ppt,pptx,rtf,txt,xls,xlsx<br/>
        - 영상/음성: avi,m4v,mov,mp3,mp4,webm,wmv<br/>
        - 사진/그림: ai,gif,jfif,jpeg,jpg,png,tif,tiff,ico<br/>
        - 압축파일: egg, zip`);
        setModalOpen(true);
      } else if (e.response.data.errorCode === 'B908') {
        setAtsFailModalOpen(true);
      } else {
        // ATS 통신 에러 일괄 처리
        setAtsFailModalOpen(true);
      }
      setFileModalVisible(false);
      console.error(e);
    }
  };

  const getCheckTerms = (term: IPositionAgreeCheckRs) => term.privacyPolicyYn && term.privacyProvisionYn;

  const handleFileAdd = async () => {
    if (termsModel.termsPositionAgreeCheck) {
      if (!getCheckTerms(termsModel.termsPositionAgreeCheck)) {
        setViewTermModal(true);
      } else {
        fileRef.current?.click();
      }
    }
  };

  const handleDownload = async (fileUid: string, fileName: string, index: number) => {
    try {
      let fileArrayBuffer: ArrayBuffer = new ArrayBuffer(0);
      if (command === MatchingCommandType.FILE_SUBMIT)
        fileArrayBuffer = await matchMessageStore.DownloadFile(matchMessageStore.positionSn, fileUid);
      else if (command === MatchingCommandType.FILE_REQUEST)
        fileArrayBuffer = await FileUtil.fileGetArrayBuffer(files[index]) as ArrayBuffer;
      if (fileArrayBuffer)
        if (MobileStore.isMobile) {
          setIsLoading(true);
        }
      FileUtil.fileDownload(fileName, fileArrayBuffer, false, setToastObject, setIsLoading);
    } catch (e:any) {
      if (e.response.data.errorCode === 'B908') {
        setAtsFailModalOpen(true);
      } else {
        // ATS 통신 에러 일괄 처리
        setAtsFailModalOpen(true);
      }
      console.error(e);
    }
  };

  const uploadFile = (file: File) => {
    if (file) return file;
    return null;
  };

  const handleFileSubmitClick = () => {
    const list = fields.map((field) => field.fileName);
    setFileNameList(list);
    setFileModalVisible(true);
  };

  return (
    <Frame>
      {isLoading && <Loading />}

      {
  decisionDueDateTime && (
    <>
      <div className='upload-frame'>
        <div className='upload-title'>응답 기한</div>
        <div className='upload-date'>{formatDateWithDayOfWeek(decisionDueDateTime) }</div>
      </div>
      <div className='upload-description'>정해진 유효기간까지 제출하지 않을 경우 제출이 불가능합니다.</div>
    </>
  )
}

      <div className='upload-frame'>
        <div className='upload-title'>추가서류 업로드</div>
      </div>
      <SpacingBlock vertical size={12} />
      <FileContent>
        { fields.length > 0
        && (
        <FileList disabled={command === MatchingCommandType.FILE_SUBMIT}>
          {fields.map((field, index) => (
            <>
              <div
                className='file-list'
                key={field.id}
                onClick={() => handleDownload(field.fileUid, field.fileName, index)}
                role='button'
              >
                <div className='file-text'>{field.fileName}</div>
                <button
                  disabled={command === MatchingCommandType.FILE_SUBMIT}
                  onClick={() => {
                    remove(index);
                  }}
                >
                  <Icon name='delete' size={24} color={command === MatchingCommandType.FILE_SUBMIT ? `${colors.CG_50}` : `${colors.JOBDA_BLACK}`} />
                </button>
                <input
                  aria-hidden
                  name={`${name}[${index}].fileName`}
                  ref={register()}
                  defaultValue={field.fileName}
                />
                <input
                  aria-hidden
                  name={`${name}[${index}].fileUid`}
                  ref={register()}
                  defaultValue={field.fileUid}
                />
              </div>
              {index !== fields.length - 1 && <SpacingBlock size={4} vertical />}
              {index === fields.length - 1 && <SpacingBlock size={12} vertical />}
            </>
          ))}
        </FileList>
        )}
        <input
          id={`file-input-${name}`}
          name={name}
          ref={(innerRef) => {
            if (fileRef !== undefined) (fileRef as any).current = innerRef;
          }}
          className={`${watch(name) ? 'value' : ''}`}
          type='file'
          onChange={async (e) => {
            if (e.target.files?.item(0)!) {
              if (fields.length >= 10) {
                setModalTitle(`파일 개수가 초과되었습니다.`);
                setModalContent(`파일은 총 10개를 넘을 수 없습니다.<br/>
                    더 많은 파일을 업로드하시려면 여러 개의<br/> 파일을 압축하신 후 업로드해 주세요.`);
                setModalOpen(true);
              } else if (!FileUtil.checkFileSizeZeroValidation(e.target.files?.item(0)!)) {
                setModalTitle(`파일을 다시 확인해 주세요.`);
                setModalContent(`손상된 파일이거나 비어있는 파일입니다.<br/>확인 후 다시 업로드해 주세요.`);
                setModalOpen(true);
              } else if (
                !FileUtil.checkFileSizeValidation(
                      e.target.files?.item(0)!,
                      LIMIT_FILE_SIZE,
                )
              ) {
                setModalTitle(`첨부할 수 없는 파일입니다.`);
                setModalContent(`파일 용량은 총 50MB를 넘을 수 없습니다.<br/>
                    용량을 줄이신 후 다시 업로드해 주세요.`);
                setModalOpen(true);
              } else if (!FileUtil.checkFileTypeValidation(e.target.files?.item(0)!, MatchingFileTypeList)) {
                setModalTitle(`첨부할 수 없는 파일입니다.`);
                setModalContent(`업로드 가능한 파일 형식을 확인해 주세요.`);
                setModalSubDesc(`- 문서: doc,docx,hwp,pdf,ppt,pptx,rtf,txt,xls,xlsx<br/>
                    - 영상/음성: avi,m4v,mov,mp3,mp4,webm,wmv<br/>
                    - 사진/그림: ai,gif,jfif,jpeg,jpg,png,tif,tiff,ico<br/>
                    - 압축파일: egg, zip`);
                setModalOpen(true);
              } else {
                const file = uploadFile(e.target.files?.item(0)!);
                if (file) {
                  const temp = [...files, file];
                  setFiles(temp);
                  append({
                    fileName: file.name,
                    fileUid: '',
                    fileSize: file.size,
                  });
                }
              }
            }
          }}
        />
      </FileContent>
      <UploadButton
        decision={!!decision}
        disabled={command === MatchingCommandType.FILE_SUBMIT || !matchMessageStore.messagesDto?.openYn}
        onClick={() => {
          handleFileAdd();
        }}
      >
        <Icon name='add' size={24} color={decision ? `${colors.CG_50}` : `${colors.JOBDA_BLACK}`} />
        <div className='btn-title'>파일 추가</div>
      </UploadButton>
      <SpacingBlock vertical size={4} />
      <div className='upload-description'>업로드 시 각 파일당 50MB, 총 10개까지 업로드 가능합니다.</div>
      <SpacingBlock vertical size={40} />
      { command === MatchingCommandType.FILE_REQUEST
        && (
        <button
          id='fileSubmit'
          className='button-accept'
          disabled={fields.length === 0 || !matchMessageStore.messagesDto?.openYn}
          onClick={() => handleFileSubmitClick()}
        >
          파일 제출
        </button>
        )}
      <MatchFileTermModal
        isOpen={viewTermModal}
        onClose={() => setViewTermModal(false)}
        onClickConfirm={() => {
          setViewTermModal(false);
        }}
      />
      <MatchMessageFileSubmitModal
        isOpen={isFileModalVisible}
        onClickSubmit={() => handleFileSubmit()}
        onClickClose={() => setFileModalVisible(false)}
        files={fileNameList}
      />
      <MatchAtsFailModal
        isOpen={atsFailModalOpen}
        onClickClose={() => setAtsFailModalOpen(false)}
      />
      <JDASimplelModal
        isOpen={isModalOpen}
        onClickClose={() => { setModalOpen(false); setModalSubDesc(''); }}
        title={modalTitle}
        content={modalContent}
        subDesc={modalSubDesc}
      />
    </Frame>
  );
};

export default inject(injectStore.termsModel, injectStore.matchMessageStore)(observer(MatchDashBoardFile));
