import colors from '__designkit__/common/colors';
import { fonts } from '__designkit__/common/fonts';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import styled from 'styled-components';

const JDARadioLabel = styled.label`
input[type=radio] {
  display:none;
  + .label {
    display: inline-block;
    padding:8px 12px 8px 12px;
    ${fonts.NOTO_13_400};
    color:${colors.CG_80};
    background:${colors.WHITE_100};
    border:1px solid ${colors.CG_40};
    width:fit-content;
    transition:background .5s, border .5s;
    border-radius:4px;
  }
  &:checked + .label {
    ${fonts.NOTO_13_700};
    color:${colors.WHITE_100};
    background:${colors.G_100};
    border:1px solid ${colors.G_200};
    width:fit-content;
  }
}
`;

interface IJDASquareRadio extends IComponentProps {
  control: Control;
  name: string;
  defaultValue?: any;
  value: any;
  text: string;
}

const JDASquareRadio: FC<IJDASquareRadio> = ({ control, name, defaultValue, value, text }) => (
  <Controller
    control={control}
    name={name}
    defaultValue={defaultValue}
    render={(props) => (
      <JDARadioLabel>
        <input type='radio' {...props} value={value} defaultChecked={defaultValue} checked={control.watchInternal(name) === value} />
        <span className='label'>{text}</span>
      </JDARadioLabel>
    )}
  />
);

export default JDASquareRadio;
