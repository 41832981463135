import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { DateUtil } from 'components/_v2/_common/input/datePicker/DateUtil';
import ProfileItemTitle from 'components/_v2/profile/ProfileItemTitle';
import ProfileListCard from 'components/_v2/profile/ProfileListCard';
import ProfileCareers from 'components/_v2/profile/careers/ProfileCareers';
import { ProfileAuditItemType } from 'consts/_v2/profile/ProfileType';
import { EMPLOYMENT_STATUS, EMPLOYMENT_STATUS_TEXT, EMPLOYMENT_TYPE_TEXT } from 'consts/_v2/profile/career';
import { IProfileCareerRs } from 'interfaces/_v2/profile/IProfileCareerRqRs';
import { inject, observer } from 'mobx-react';
import ProfileCareersModel from 'models/_v2/profile/ProfileCareersModel';
import { injectStore } from 'models/store';
import { useInsightResumeAutoSave } from 'query/resume/useResumeQuery';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidV4 } from 'uuid';

const Frame = styled.div``;

const DescriptionCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  width: 100%;

  > h2 {
    display: inline-block;
    width: 100%;
    font: ${Fonts.B2_Medium_P};
    color: ${Colors.CG_70};
    white-space: pre-line;
  }
  .role-text{
    font:${Fonts.B3_Bold};
    color:${Colors.CG_90};
  }
  .job-frame{
    display: flex;
    gap:8px;
    margin-bottom: 12px;

    .border{
      width: 1px;
      height: 8px;
      background-color:${Colors.CG_40};
    }
    .bold-text{
      font: ${Fonts.B2_Bold};
      color: ${Colors.CG_90};
    }
    .light-text{
      font:${Fonts.B2_Medium};
      color:${Colors.CG_70};
    }
  }
`;
const JobFrame = styled.div<{edit?:boolean}>`
    display: flex;
    gap:8px;
    margin-bottom: 12px;
    background-color: ${(props) => (props.edit ? `${Colors.Y_100}` : `${Colors.WHITE_100}`)};
    .border{
      width: 1px;
      height: 8px;
      background-color:${Colors.CG_40};
    }
    .bold-text{
      font: ${Fonts.B2_Bold};
      color: ${Colors.CG_90};
    }
    .light-text{
      font:${Fonts.B2_Medium};
      color:${Colors.CG_70};
    
  }
`;
export interface IProfileCareersView {
  profileCareersModel?: ProfileCareersModel;
}

export const getTotalCareerPeriodText = (careerList: IProfileCareerRs[]) => {
  let totalCareerPeriod = 0;

  careerList.forEach((career) => {
    const endDate = career.employmentStatus === EMPLOYMENT_STATUS.RESIGN && career.endDate ? new Date(career.endDate) : new Date();
    totalCareerPeriod += DateUtil.getMonthPeriod(new Date(career?.startDate || ''), endDate);
  });

  return DateUtil.getTotalPeriodTextYM(totalCareerPeriod);
};

const ProfileCareersView: FC<IProfileCareersView> = ({ profileCareersModel = new ProfileCareersModel() }) => {
  const { data: insightResumeAutoSaveData, refetch: refetchAutoSave } = useInsightResumeAutoSave(true);

  const [openCareer, setOpenCareer] = useState<boolean>(false);
  const [totalCareers, setTotalCareers] = useState<string>('');

  useEffect(() => {
    if (profileCareersModel.careers.length > 0) {
      const totalCareerPeriod = getTotalCareerPeriodText(profileCareersModel.careers);
      setTotalCareers(totalCareerPeriod);
    } else {
      setTotalCareers('');
    }
  }, [profileCareersModel.careers]);

  const checkAudit = (caseType:ProfileAuditItemType, index:number) => {
    switch (caseType) {
      case ProfileAuditItemType.PROFILE_CAREER_START_DATE:
        return profileCareersModel.careers[index].auditItems?.includes(ProfileAuditItemType.PROFILE_CAREER_START_DATE);
      case ProfileAuditItemType.PROFILE_CAREER_END_DATE:
        return profileCareersModel.careers[index].auditItems?.includes(ProfileAuditItemType.PROFILE_CAREER_END_DATE);
      case ProfileAuditItemType.PROFILE_CAREER_EMPLOYMENT_STATUS:
        return profileCareersModel.careers[index].auditItems?.includes(ProfileAuditItemType.PROFILE_CAREER_EMPLOYMENT_STATUS);
      case ProfileAuditItemType.PROFILE_CAREER_JOB_TITLE:
        return profileCareersModel.careers[index].auditItems?.includes(ProfileAuditItemType.PROFILE_CAREER_JOB_TITLE);
      case ProfileAuditItemType.PROFILE_CAREER_EMPLOYMENT_TYPE:
        return profileCareersModel.careers[index].auditItems?.includes(ProfileAuditItemType.PROFILE_CAREER_EMPLOYMENT_TYPE);
      default:
        return false;
    }
  };
  return (
    <Frame>
      <ProfileItemTitle icon='work' text='경력' subText='직장, 인턴십, 아르바이트 등의 업무 경험을 적어주세요.' onClickEditBtn={() => setOpenCareer(true)} tag={totalCareers !== '' ? totalCareers : undefined} />
      { profileCareersModel.careers.map((card: IProfileCareerRs, index: number) => (
        <ProfileListCard
          key={`card-list-${uuidV4()}`}
          institution={card.companyName}
          jobGroupName={card.jobGroupName}
          jobTitleName={card.jobTitleName}
          date={`${card.startDate} ~ ${card.employmentStatus === EMPLOYMENT_STATUS.WORK ? EMPLOYMENT_STATUS_TEXT.WORK : card.endDate}`}
          editDate={checkAudit(ProfileAuditItemType.PROFILE_CAREER_START_DATE, index) || checkAudit(ProfileAuditItemType.PROFILE_CAREER_END_DATE, index)}
          description={(
            <DescriptionCard>
              <JobFrame edit={checkAudit(ProfileAuditItemType.PROFILE_CAREER_JOB_TITLE, index)}>
                <div className='bold-text'>{card.jobGroupName}</div>
                <div className='border' />
                <div className='bold-text'>{card.jobTitleName}</div>
                <div className='border' />

                <div className='light-text'>{EMPLOYMENT_TYPE_TEXT[card.employmentType]}</div>
              </JobFrame>
              {
                card.role && <div className='role-text'>{card.role}</div>
              }

              <h2>{card.performance}</h2>
            </DescriptionCard>
          )}
          index={index}
          length={profileCareersModel.careers.length}
        />
      ))}
      { openCareer && (
      <ProfileCareers onClose={() => {
        refetchAutoSave();
        setOpenCareer(false);
      }}
      />
      )}
    </Frame>
  );
};

export default inject(injectStore.profileCareersModel)(observer(ProfileCareersView));
