import colors from '__designkit__/common/colors';

const shadows = {
  base: 'box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);',
  tnbLine: 'box-shadow: inset 0px -0.5px 0.5px rgba(207, 209, 213, 0.5);',
  headers: `box-shadow: 0px 1px 1px ${colors.CG_40};`,
  small: 'box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1), 0px 6px 9px rgba(0, 0, 0, 0.1);',
  medium: 'box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);',
  large: 'box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);',
  xlarge: 'box-shadow: 0px 20px 25px rgba(0, 0, 0, 0.1), 0px 10px 10px rgba(0, 0, 0, 0.04);',
};

export default shadows;
