import useToast from 'hooks/useToast';
import { JDModalButton } from 'components/_v2/_common/modals/JDModalComponents';
import { IFindPasswordRq } from 'interfaces/rqrs/IFindAccountRqRs';
import { inject, observer } from 'mobx-react';
import FindAccountModel from 'models/FindAccountModel';
import { injectStore } from 'models/store';
import { IFindAccountProps } from 'pages/FindAccount';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import JDInput from 'components/_v2/join/JDInput';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import { countryList } from 'consts/_v2/join/countryList';
import Fonts from '__designkit__/common/fonts';
import Colors from '__designkit__/common/colors';
import { convertMsToMMSS } from 'utils/_v2/timeUtils';
import JDInputSelector from 'components/_v2/join/JDInputSelector';

const Frame = styled.div`
  ${JDModalButton} {
    left: 50%;
    transform: translate(-50%, 0);
    position: fixed;
    width:90%;
    bottom:calc(24px + env(safe-area-inset-bottom));
  }
`;

const InnerButtonInput = styled.div`
  position: relative;
  width: 100%;
  height: 46px;

  > div.postFix {
    position: absolute;
    top: 50%;
    right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-50%);
  }
`;

const BirthdayBox = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: fit-content;

  > div.dash {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 46px;
    margin: 0 12px;
    
    > span {
      display: inline-block;
      width: 8px;
      border-top: 1px solid ${Colors.JOBDA_BLACK};
    }
  }
`;

const GenderBox = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  > div.dot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: ${Colors.JOBDA_BLACK}
  }
`;

const PhoneBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const VerificationBox = styled.div`
`;

const Button = styled.button`
  padding: 8px 16px;
  border-radius: 4px;
  background-color: ${Colors.JOBDA_BLACK};
  font: ${Fonts.B3_Bold};
  color: ${Colors.WHITE_100};

  &:active {
    background-color: ${Colors.CG_70};
  }

  &:disabled {
    background-color: ${Colors.CG_40};
    color: ${Colors.CG_60};
  }
`;

const Timer = styled.div`
  margin-right: 12px;
  font: ${Fonts.B2_Medium};
  color: ${Colors.ERROR};
`;

interface IFindPasswordProps extends IFindAccountProps {
    className?: string;
}

const FindPasswordForm: FC<IFindPasswordProps> = ({ findAccountModel = new FindAccountModel(), className }) => {
  const useFormed = useFormContext();
  const { errors, watch, getValues } = useFormed;
  const { setToastObject } = useToast();

  const countryCode = watch('countryCode');
  const mobile = watch('mobile');
  const token = watch('token');
  const [sendMobile, setSendMobile] = useState<string>('');
  const [isDiableSendBtn, setIsDiableSendBtn] = useState<boolean>(false);
  const [isVerificate, setIsVerificate] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(0);
  const timerRef = useRef<number>(0);

  const onClickSendBtn = async () => {
    if (!(errors.countryCode || errors.mobile)) {
      try {
        setSendMobile(mobile);
        const res = await findAccountModel.sendPasswordSMS({ id: watch('id'), countryCode, mobile });
        if (res) {
          window.clearTimeout(timerRef.current);
          setTimer(180000);
          setToastObject({ isOpen: true, type: 'SUCCESS', message: '인증번호가 발송되었습니다.' });
        } else setToastObject({ isOpen: true, type: 'ERROR', message: '인증번호가 발송에 실패했습니다.' });
      } catch (e) {
        const err = e as { response: any };
        setToastObject({ isOpen: true, type: 'ERROR', message: err.response.data.message });
      }
    } else setToastObject({ isOpen: true, type: 'INFO', message: '필수 값들을 모두 입력해 주세요.' });
  };

  const onClickCheckBtn = async () => {
    if (token.length !== 6) setToastObject({ isOpen: true, type: 'ERROR', message: '인증번호를 확인해 주세요.' });
    else if (token && !errors.token) {
      try {
        const res = await findAccountModel.checkSMS({ countryCode, mobile, token });
        if (res) setIsVerificate(true);
        else {
          setIsVerificate(false);
          setToastObject({ isOpen: true, type: 'ERROR', message: '인증에 실패했습니다.' });
        }
      } catch (e) {
        const err = e as { response: any };
        setToastObject({ isOpen: true, type: 'ERROR', message: err.response.data.message });
      }
    }
  };

  const onSubmit = async () => {
    try {
      const findPasswordRq = getValues();
      const res = await findAccountModel.checkPasswordVerification(findPasswordRq as IFindPasswordRq);
      if (res) findAccountModel.nextFindPasswordStep(2);
    } catch (e) {
      const err = e as { response: any };
      setToastObject({ isOpen: true, type: 'ERROR', message: err.response.data.message });
    }
  };

  useEffect(() => {
    if (mobile !== sendMobile) {
      setTimer(0);
      setIsDiableSendBtn(false);
      setSendMobile('');
    }
    if (timer && !isVerificate && sendMobile) timerRef.current = window.setTimeout(() => setTimer(timer - 1000), 1000);
  }, [timer, isVerificate, mobile, sendMobile]);

  useEffect(() => {
    if (!(countryCode && mobile && timer < 120000 && mobile.length > 9) || isVerificate) {
      setIsDiableSendBtn(true);
    } else {
      setIsDiableSendBtn(false);
    }
  }, [countryCode, mobile, isVerificate, timer]);

  return (
    <Frame className={className || ''}>
      <PhoneBox>
        <JDInputSelector name='countryCode' label='국가' options={countryList} width='calc((100% - 12px) * .3)' />
        <InnerButtonInput style={{ width: 'calc((100% - 12px) * .7)' }}>
          <JDInput type='tel' name='mobile' label='휴대폰 번호' maxLength='11' inputMode='tel' />
          <div className='postFix'>
            <Button type='button' disabled={isDiableSendBtn} onClick={onClickSendBtn}>
              { timer === 0 ? '인증' : '재인증' }
            </Button>
          </div>
        </InnerButtonInput>
      </PhoneBox>
      <SpacingBlock size={16} vertical />
      <VerificationBox>
        <InnerButtonInput>
          <JDInput type='string' name='token' label='인증번호' maxLength='6' successText={isVerificate ? '인증이 완료되었어요.' : ''} inputMode='numeric' />
          <div className='postFix'>
            { timer !== 0 && !isVerificate ? <Timer>{ convertMsToMMSS(timer) }</Timer> : null }
            <Button type='button' disabled={timer === 0 || isVerificate || watch('token').length < 6} onClick={onClickCheckBtn}>
              확인
            </Button>
          </div>
        </InnerButtonInput>
      </VerificationBox>
      <JDModalButton disabled={!isVerificate} onClick={onSubmit}>
        비밀번호 재설정
      </JDModalButton>
    </Frame>
  );
};

export default inject(injectStore.findAccountModel)(observer(FindPasswordForm));
