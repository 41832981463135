import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import ChattingAPI from 'api/chattingAPI';
import JDUnderBaseModal from 'components/_v2/_common/modals/JDUnderBaseModal';
import SimpleConfirmModal from 'components/_v2/_common/modals/SimpleConfirmModal';
import { TOAST_TYPE } from 'components/_v2/_common/toast/JDToast';
import useToast from 'hooks/useToast';
import IComponentProps from 'interfaces/props/IComponentProps';
import { inject, observer } from 'mobx-react';
import ChattingModel from 'models/_v2/ChattingModel';
import { injectStore } from 'models/store';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
  width: 100%;
  height: fit-content;
  padding: 8px 0 16px;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    user-select: none;
  }

  & > .title {
    padding: 16px;
    border-bottom: 1px solid ${Colors.CG_40};
    font: ${Fonts.B1_Bold};
  }

  & > .menu {
    padding: 16px;
    border-radius: 4px;
    font: ${Fonts.B2_Medium};
    color: ${Colors.JOBDA_BLACK};


    &:active {
      background-color: ${Colors.CG_30};
    }
  }

  & > .error {
    color: ${Colors.ERROR};
  }
`;

interface IChannelLongTouchMenuProps extends IComponentProps {
  chattingModel?: ChattingModel;
}

const ChannelLongTouchMenu: FC<IChannelLongTouchMenuProps> = ({ chattingModel = new ChattingModel() }) => {
  const { setToastObject } = useToast();
  const [isOpenLeaveConfirmModal, setIsOpenLeaveConfirmModal] = useState(false);

  const onClickAlramOnOff = async () => {
    try {
      if (chattingModel.currentChannel) {
        if (chattingModel.currentChannel.notificationEnabled) {
          await ChattingAPI.updateChannelNotificationWhether(chattingModel.currentChannel.id, false);
          setToastObject({ isOpen: true, type: TOAST_TYPE.INFO, message: '알림이 꺼졌어요.', subMessage: '새 메시지, 공고를 쉽게 확인하고 싶다면 알림을 켜주세요.', duration: 3000 });
        } else {
          await ChattingAPI.updateChannelNotificationWhether(chattingModel.currentChannel.id, true);
          setToastObject({ isOpen: true, type: TOAST_TYPE.INFO, message: '알림이 켜졌어요.', subMessage: '이제 모바일 앱을 통해 새 메시지, 공고를 실시간으로 받아보세요.', duration: 3000 });
        }
        chattingModel.setMyChannelNotificationList(await ChattingAPI.getAllChannelNotificationWhether());
        chattingModel.setIsOpenChannelLongTouchMenu(false);
      }
    } catch (e) {
      const err = e as { response: { data: { message: string } } };
      setToastObject({ isOpen: true, type: 'ERROR', message: err.response.data.message, duration: 2000 });
    }
  };

  return (
    <JDUnderBaseModal isOpen={chattingModel.isOpenChannelLongTouchMenu} onClickClose={() => chattingModel.setIsOpenChannelLongTouchMenu(false)}>
      <Frame>
        <div className='title'>{ chattingModel.currentChannel?.name }</div>
        <SpacingBlock size={12} vertical />
        <div role='button' className='menu' onClick={onClickAlramOnOff}>{ chattingModel.currentChannel?.notificationEnabled ? '알림 끄기' : '알림 켜기' }</div>
        <div role='button' className='menu error' onClick={() => setIsOpenLeaveConfirmModal(true)}>채팅방 나가기</div>
      </Frame>
      <SimpleConfirmModal
        isOpen={isOpenLeaveConfirmModal}
        title='채팅방을 나가시겠어요?'
        subTitle='해당 채팅방이 내 채팅에서 사라집니다.'
        cancelBtnText='취소'
        confirmBtnText='나가기'
        onClickCancel={() => setIsOpenLeaveConfirmModal(false)}
        onClickConfirm={() => {
          chattingModel.onClickChattingLeaveBtn();
          setIsOpenLeaveConfirmModal(false);
        }}
      />
    </JDUnderBaseModal>
  );
};

export default inject(injectStore.chattingModel)(observer(ChannelLongTouchMenu));
