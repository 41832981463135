import React, { FC } from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import Login from 'models/Login';
import TermsModel from 'models/TermsModel';
import { injectStore } from 'models/store';
import Icon from '__designkit__/icon/Icon';
import Fonts from '__designkit__/common/fonts';
import Colors from '__designkit__/common/colors';
import type { IconName } from 'interfaces/_v2/_common/iconType';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import IComponentProps from 'interfaces/props/IComponentProps';
import MatchServiceToggle from './MatchServiceToggle';

const Frame = styled.div`
  width: 100%;
  height: fit-content;
  padding: 32px 0;
`;

const TitleFrame = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font: ${Fonts.H4_Bold};

  & > div {
    display: flex;
    justify-content: start;
    align-items: center;
  }
`;

export const RequiredIndicator = styled.div`
  width: 4px;
  height: 4px;
  margin-left: 8px;
  border-radius: 4px;
  background: ${Colors.ERROR};
`;

const SubFrame = styled.div`
  margin-top: 6px;
  font: ${Fonts.B3_Medium};
  color: ${Colors.CG_70};
`;

interface IMatchTabProps extends IComponentProps {
  login?: Login;
  termsModel?: TermsModel;
  icon: IconName;
  text: string;
  subText?: string;
  sourcePage?: string;
}

const MatchTab: FC<IMatchTabProps> = ({ icon, text, subText, sourcePage }) => (
  <Frame>
    <TitleFrame>
      <div>
        <Icon name={icon} size={32} />
        <SpacingBlock size={4} />
        <span>{text}</span>
      </div>
      <MatchServiceToggle className='mo_profile_matchON_0831' sourcePage={sourcePage} />
    </TitleFrame>
    { subText && (
      <SubFrame>
        <span>{subText}</span>
      </SubFrame>
    )}
  </Frame>
);

export default inject(injectStore.login, injectStore.termsModel, injectStore.matchApplyModel)(observer(MatchTab));
