import React from 'react';
// import JDALink from 'components/JDALink';
// import RoutePaths from 'consts/RoutePaths';
import JDSwiper, { JDSwiperSlide, PageNationType } from 'components/_v2/_common/JDSwiper';
import classnames from 'classnames/bind';
import styles from './resultStyle.module.scss';

const cx = classnames.bind(styles);

const Result = () => (

  // 역량검사 심층 분석 결과표 이미지 슬라이드
  <div className={cx('contentInner', 'bottomBox', 'slideDark')}>
    {/* 이미지 슬라이드 제목 */}
    <div className={cx('slideSubText')}>
      <div className={cx('icon', 'target')} />
      <span className={cx('text')}>어디서 구할 수 없는 내 맞춤형 분석자료로</span>
      <span className={cx('desc')}>자소서/면접 완벽대비</span>
      <div className={cx('star', 'starWhite')} />
    </div>
    <div className={cx('basicTitle')}>
      <p className={cx('subTitle')}>실제 대기업 채용에 반영되는<br /> 데이터 기반으로<br />나의 잠재역량을 확인하는</p>
      <p className={cx('mainTitle')}>역량검사 심층 분석 결과표</p>
    </div>

    {/* 이미지 슬라이드 */}
    <div className={cx('slideInner')}>
      <JDSwiper className='service-swiper' paginationtype={PageNationType.bullets} autoplay>
        <JDSwiperSlide className='service-card'>
          <div className={cx('sliderItem', 'type1')} />
        </JDSwiperSlide>
        <JDSwiperSlide className='service-card'>
          <div className={cx('sliderItem', 'type2')} />
        </JDSwiperSlide>
        <JDSwiperSlide className='service-card'>
          <div className={cx('sliderItem', 'type3')} />
        </JDSwiperSlide>
      </JDSwiper>
    </div>
  </div>
);

export default Result;
