/* eslint-disable @typescript-eslint/no-shadow */
import ImgTrashBin from 'assets/_v2/profile/icon_trashbin.svg';
import JDPlusTextButton from 'components/_v2/_common/button/JDPlusTextButton';
import FormProfileValueTitle from 'components/_v2/_common/form/FormProfileValueTitle';
import JDDatePickerInput from 'components/_v2/_common/input/JDDatePickerInput';
import JDDescriptionInput from 'components/_v2/_common/input/JDDescriptionInput';
import JDSearchInput from 'components/_v2/_common/input/JDSearchInput';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { INameCodeRs } from 'interfaces/rqrs/ICommonRqRs';
import React, { FC, useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import request from 'utils/request';
import URIs from 'consts/URIs';
import { DatePickerFormValueFrame } from 'components/_v2/_common/form/FormProfileValueFrame';

const ActivitiesFrame = styled.div`
    .delete-frame{
        display: flex;
        justify-content: flex-end;
        margin-bottom: 22px;
        .delete-field{
            display:flex;
            .delete-text{
                margin-left: 4px;
                font: ${Fonts.B2_Medium_P};
                line-height: 18px;
                color:${colors.c44474B_80}
        }
        }
        
    }
    .activity-selector{
        margin:20px 0 24px 0;
        .selector{
            margin-top: 12px;
        }
        .center-country{
            margin-top: 12px;
    }

    }
    
    .info-date{
      margin-bottom: 24px;
    }
    .margin-date{
      margin-top: 12px;
    }
`;
const Divider = styled.div`
    width: 100%;
    height: 1px;
    background: ${colors.CG_40};
    margin: 28px 0;
`;
const ProfileExperienceForeignExperiencesCard:FC = () => {
  const useFormed = useFormContext();
  const { control, watch } = useFormed;
  const [countries, setCountries] = useState<INameCodeRs[]>([]);
  const [searchCountries, setSearchCountries] = useState<INameCodeRs[]>([]);

  const name = `foreignExperiences`;
  const { fields: ForeignExperiencesFields, append: foreignExperiencesAppend, remove: foreignExperiencesRemove } = useFieldArray({
    control,
    name,
  });

  const searchCountry = async () => {
    try {
      const { countries } = await request<{ countries: INameCodeRs[] }>({
        method: 'get',
        url: URIs.get_codes_countries,
      });
      setCountries(countries);
    } catch (e) {
      console.error(e);
      setCountries([]);
    }
  };

  useEffect(() => {
    searchCountry();
  }, []);
  useEffect(() => {
    if (ForeignExperiencesFields.length === 0) {
      foreignExperiencesAppend({
        countryName: '',
        countryCode: '',
        startDate: '',
        endDate: '',
        description: '',
      }, false);
    }
  }, [ForeignExperiencesFields.length]);

  return (
    <>
      {
        (ForeignExperiencesFields).map((field, foreignExperiencesIndex) => (
          <ActivitiesFrame>

            <div key={field.id}>
              <div className='delete-frame'>
                <div className='delete-field' onClick={() => foreignExperiencesRemove(foreignExperiencesIndex)} role='button'>
                  <img src={ImgTrashBin} alt='trash' />
                  <div className='delete-text'>삭제</div>
                </div>
              </div>

              <div className='activity-selector'>
                <FormProfileValueTitle>국가명</FormProfileValueTitle>
                <div className='center-country'>
                  <JDSearchInput
                    key={field.id}
                    title='국가명'
                    defaultTextName={countries.find(((value) => value.code === watch(`foreignExperiences[${foreignExperiencesIndex}].countryCode`)))?.name}
                    defaultCodeName={field.countryCode}
                    textName={`${name}[${foreignExperiencesIndex}].countryName`}
                    codeName={`${name}[${foreignExperiencesIndex}].countryCode`}
                    placeholder='국가를 검색해 주세요.'
                    forForeign
                    onSearch={(searchText: string) => {
                      if (searchText === '') setSearchCountries(countries);
                      else {
                        const searchItem = countries.filter((value, index) => value.name.includes(searchText));
                        setSearchCountries(searchItem);
                      }
                    }}
                    searchList={searchCountries}
                  />
                </div>
              </div>
              <FormProfileValueTitle>기간</FormProfileValueTitle>
              <DatePickerFormValueFrame>
                <JDDatePickerInput
                  key={`${field.id}-start`}
                  name={`foreignExperiences[${foreignExperiencesIndex}].startDate`}
                  placeholder='연도. 월.'
                  month
                  defaultValue={field.startDate}
                />
                <span className='date-picker-divider'>~</span>
                <JDDatePickerInput
                  key={`${field.id}-end`}
                  name={`foreignExperiences[${foreignExperiencesIndex}].endDate`}
                  placeholder='연도. 월.'
                  month
                  defaultValue={field.endDate}
                />
              </DatePickerFormValueFrame>

              <FormProfileValueTitle>내용</FormProfileValueTitle>
              <JDDescriptionInput
                key={field.id}
                name={`foreignExperiences[${foreignExperiencesIndex}].description`}
                placeholder='해외에서 어떤 경험을 했는지 간단히 입력해 주세요. (예시 : 교환학생, 어학연수, 워킹홀리데이, 해외근무)'
                maxLength={1000}
                height={120}
                autoFocus={false}
                defaultValue={field.description}
              />
              <Divider />

            </div>
          </ActivitiesFrame>

        ))
      }
      <JDPlusTextButton
        text='추가'
        onClick={() => foreignExperiencesAppend({
          countryName: '',
          countryCode: '',
          startDate: '',
          endDate: '',
          description: '',
        })}
      />
    </>
  );
};

export default ProfileExperienceForeignExperiencesCard;
