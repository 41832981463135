import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import BuilderAtsErrorModal from 'components/_v2/builder/BuilderAtsErrorModal';
import BuilderLoginInfoModal from 'components/_v2/builder/BuilderLoginInfoModal';
import LoginForm from 'components/_v2/login/LoginForm';
import SignUpFrame from 'components/_v2/login/SignUpFrame';
import SocialLoginForm from 'components/_v2/login/SocialLoginForm';
import RoutePaths from 'consts/RoutePaths';
import ILoginPageProps from 'interfaces/props/ILoginPageProps';
import { inject, observer } from 'mobx-react';
import JoinModel from 'models/_v2/JoinModel';
import ResumeModel from 'models/_v2/ResumeModel';
import { injectStore } from 'models/store';
import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import CommonUtils, { removeSession } from 'utils/CommonUtils';
import TodayRenderCheckUtil from 'utils/TodayRenderCheckUtil';

const Frame = styled.div`
  height: 100%;
  background: ${colors.WHITE_100};
  padding: 16px 20px;
  max-width: 460px;
  min-width: 360px;
  margin: auto;
`;

const TitleFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${colors.JOBDA_BLACK};

  .sub-title {
    display: flex;
    font: ${Fonts.B1_Medium};
    margin-top: 8px;
  }
  .main-title {
    margin-top: 52px;
    text-align: center;
    font: ${Fonts.H3_Bold};
  }
`;
interface IBuilderLogin extends ILoginPageProps {
  resumeModel?: ResumeModel;
}
const BuilderLogin: FC<IBuilderLogin> = (props) => {
  const { login, joinModel = new JoinModel(), resumeModel = new ResumeModel() } = props;
  const [isInfoModalOpen, setIsInfoModalOpen] = useState<boolean>(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
  const { pathname, search } = useLocation();
  const { publicKey, positionId } = CommonUtils.parseQuery(search);
  const paramList = CommonUtils.parseQuery(search);
  const history = useHistory();
  const matchLoginPathYn = pathname === RoutePaths.matchLogin;
  const keyName = 'builder';
  // ats 수동 인재 연동
  const checkMatchLogin = () => {
    if (matchLoginPathYn && publicKey && positionId && login) {
      login.saveMatchLoginInfo(publicKey, Number(positionId));
    }
  };
  useEffect(() => {
    const init = async () => {
      await resumeModel.getPositionSn(paramList.publicKey, paramList.positionId);
      TodayRenderCheckUtil.setPositionSn(keyName, String(resumeModel.positionSn));
    };
    init();
  }, []);

  // const openAtsResume = async () => {
  //   const accessToken = await AuthorizeUtil.getAccessToken();
  //   if (accessToken === null) throw new Error('accessToken is null');
  //   try {
  //     await resumeModel.getPublicKey(Number(resumeModel.positionSn));
  //     const url = `${resumeModel.url}/writeResume?publicKey=${resumeModel.publicKey}&positionId=${resumeModel.positionId}&jobdaToken=Bearer ${accessToken}`;
  //     windowOpenUtils(url, '_self');
  //   } catch (error) {
  //     setIsErrorModalOpen(true);
  //   }
  // };

  useEffect(() => {
    if (login?.userInfo && resumeModel.positionSn !== 0) {
      history.push(RoutePaths.position_jd(resumeModel.positionSn, 'builder'));
    }
  }, [login?.userInfo, resumeModel.positionSn]);

  useEffect(() => {
    joinModel.socialJoinInfo = null;
    removeSession('socialInfo');
  }, []);

  return (
    <>
      <Frame>
        <TitleFrame>
          <div className='main-title'>
            여러분들의 취업을 <br /> JOBDA가 응원합니다!
          </div>
          <div className='sub-title'>
            로그인 후 지원서를 작성해 주세요.{' '}
            <button onClick={() => setIsInfoModalOpen(true)}>
              <Icon name='information' size={16} />{' '}
            </button>
          </div>
        </TitleFrame>
        <LoginForm builder />
        <SocialLoginForm checkMatchLogin={checkMatchLogin} />
        <SignUpFrame checkMatchLogin={checkMatchLogin} />
      </Frame>
      <BuilderLoginInfoModal isOpen={isInfoModalOpen} onClickClose={() => setIsInfoModalOpen(false)} />
      <BuilderAtsErrorModal isOpen={isErrorModalOpen} onClickClose={() => setIsErrorModalOpen(false)} />
    </>
  );
};

export default inject(injectStore.resumeModel, injectStore.login, injectStore.joinModel)(observer(BuilderLogin));
