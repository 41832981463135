import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import JDPasswordInput from 'components/_v2/_common/input/JDPasswordInput';
import { JDModalButton } from 'components/_v2/_common/modals/JDModalComponents';
import VerificationTimeEndModal from 'components/_v2/verification/VerificationTimeEndModal';
import RoutePaths from 'consts/RoutePaths';
import { passwordValidator, rePasswordValidator } from 'consts/ValidationRule';
import useToast from 'hooks/useToast';
import { IFindPasswordIPINRq, IFindPasswordRq } from 'interfaces/rqrs/IFindAccountRqRs';
import { inject, observer } from 'mobx-react';
import FindAccountModel, { FIND_ACCOUNT_ERROR_STR } from 'models/FindAccountModel';
import { injectStore } from 'models/store';
import { IFindAccountProps } from 'pages/FindAccount';
import { FC, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router';
import MobileStore from 'store/mobileStore';
import styled from 'styled-components';
import AuthorizeUtil from 'utils/AuthorizeUtil';
import { getSession, removeSession } from 'utils/CommonUtils';
import TodayRenderCheckUtil from 'utils/TodayRenderCheckUtil';
import { windowOpenUtils } from 'utils/_v2/windowOpenUtils';

const Frame = styled.div`
  height:100%;

  .info{
    font: ${Fonts.H4_Bold};
    color:${colors.CG_90};
    margin-bottom:24px;
  }

  .input-wrapper {
      margin-bottom: 16px;       
  }


  ${JDModalButton} {
      margin-top: 24px;
    }

`;

interface IFindIdProps extends IFindAccountProps {
    className?: string;
}

const FindPasswordChange: FC<IFindIdProps> = ({ findAccountModel = new FindAccountModel(), className }) => {
  const useFormed = useFormContext();
  const { errors, watch, getValues, setError } = useFormed;
  const { setToastObject } = useToast();
  const [isVerificationTimeEndModalOpen, setIsVerificationTimeEndModalOpen] = useState(false);
  const history = useHistory();

  const onSubmit = async () => {
    const redirectUrl = await TodayRenderCheckUtil.getUtmParams('redirect_url');
    try {
      if (findAccountModel.verificationInfo) {
        // ipin
        let savedId = '';
        savedId = await getSession('find-password-inputId');

        const res = getValues();
        const findPasswordRq: IFindPasswordIPINRq = {
          token: findAccountModel.verificationInfo.identificationToken,
          id: savedId,
          password: res.password,
        };
        await findAccountModel.resetPasswordIPIN(findPasswordRq as IFindPasswordIPINRq);
        removeSession('find-password-inputId');
      } else {
        const findPasswordRq = getValues();
        await findAccountModel.resetPassword(findPasswordRq as IFindPasswordRq);
      }
      // 자동로그인 해제
      AuthorizeUtil.removeIsAutoLogin();
      AuthorizeUtil.removeAutoLoginInfo();
      setToastObject({ isOpen: true, type: 'SUCCESS', message: '비밀번호 재설정이 완료되었습니다.' });

      if (redirectUrl) {
        if (MobileStore.isMobile) {
          windowOpenUtils(redirectUrl, '_blank');
          history.push(RoutePaths.login);
        } else windowOpenUtils(redirectUrl, '_self');
      } else history.push(RoutePaths.login);
    } catch (e: any) {
      if (e.response.data.errorCode === 'B001') setError('password', { type: 'string', message: '기존과 동일한 비밀번호는 사용할 수 없습니다.' });
      else if (e.response.data.errorCode === 'B002') setError('password', { type: 'string', message: FIND_ACCOUNT_ERROR_STR.B002 });
      else if (e.response.data.errorCode === 'B901') setToastObject({ isOpen: true, type: 'ERROR', message: '존재하는 아이디가 아닙니다.', duration: 2000 });
      else if (e.response.data.errorCode === 'B902') setIsVerificationTimeEndModalOpen(true);
      else if (e.response.data.errorCode === 'B903') setToastObject({ isOpen: true, type: 'ERROR', message: '계정 아이디와 본인인증된 계정 아이디가 다릅니다.', duration: 2000 });
      else setToastObject({ isOpen: true, type: 'ERROR', message: '비밀번호 재설정이 실패했습니다.', duration: 2000 });
    }
  };

  return (
    <>
      <Frame className={className || ''}>
        <div className='info'>비밀번호를 재설정 해 주세요.</div>
        <JDPasswordInput
          className='input-wrapper'
          name='password'
          maxLength={16}
          placeholder='비밀번호를 입력해 주세요.'
          customValidator={{ validate: (value: string) => passwordValidator(value) }}
          defaultMessage='8~16자, 영문 대·소문자, 숫자, 특수문자 중 2개 이상 사용하세요.'
          signUpYn
        />
        <JDPasswordInput
          className='input-wrapper'
          name='rePassword'
          maxLength={16}
          placeholder='비밀번호를 다시 입력해 주세요.'
          customValidator={{ validate: (value: string) => rePasswordValidator(value, watch('password')) }}
          signUpYn
        />
        <JDModalButton disabled={!(watch('password') && watch('rePassword') && !errors.password && !errors.rePassword)} onClick={() => { onSubmit(); }}>설정 완료</JDModalButton>
      </Frame>
      <VerificationTimeEndModal
        isOpen={isVerificationTimeEndModalOpen}
        onClickClose={() => setIsVerificationTimeEndModalOpen(false)}
      />
    </>

  );
};

export default inject(injectStore.findAccountModel)(observer(FindPasswordChange));
