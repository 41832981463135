import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import { Strength } from 'interfaces/_v2/matchingReport/IMatchingReportRs';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

const SpecialtyBox = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px;
    border-radius: 8px;
    border: 1px solid ${Colors.CG_40};
    &:not(:last-child){
        margin-bottom: 16px;
    }
    .title-text{
        font:${Fonts.B1_Bold};
        color:${Colors.JOBDA_BLACK};
        margin-bottom: 16px;
    }
    .sub-text{
        font:${Fonts.B2_Medium_P};
        color:${Colors.JOBDA_BLACK};
        margin-bottom: 16px;
    }
    .button-frame{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .button-text{
        font:${Fonts.B3_Medium};
        color:${Colors.B_100};
    }
    }
    .small-divider{
        width: 100%;
        height: 1px;
        background-color: ${Colors.CG_40};
        margin: 24px 0;
    }
    .question-title{
        font:${Fonts.B2_Bold_P};
        color:${Colors.JOBDA_BLACK};
        margin-bottom: 12px;
    }
    .question-answer{
        font:${Fonts.B3_Medium_P};
        color:${Colors.CG_70};
    }
`;
interface ISpecialtyFoldedCard {
    item: Strength;
}
const SpecialtyFoldedCard:FC<ISpecialtyFoldedCard> = ({ item }) => {
  const [folded, setFolded] = useState(true);
  const onClickFolded = () => {
    setFolded(!folded);
  };
  return (
    <SpecialtyBox>
      <div className='title-text'>{item.keyword}</div>
      <div className='sub-text'>{item.comment}</div>
      <div className='button-frame' onClick={onClickFolded} role='button'>
        {folded
          ? (
            <>
              <div className='button-text'>예상 면접 질문 보기</div>
              <Icon name='arrow-bottom' size={24} color={Colors.B_100} />
            </>
          )
          : (
            <>
              <div className='button-text'>닫기</div>
              <Icon name='arrow-top' size={24} color={Colors.B_100} />
            </>
          )}
      </div>
      {
          !folded && (
          <>
            {
            item.questions.map((question) => (
              <>
                <div className='small-divider' />
                <div className='question-title'>{question.question}</div>
                <div className='question-answer'>{question.comment}</div>
              </>

            ))
          }

          </>
          )
        }

    </SpecialtyBox>
  );
};
export default SpecialtyFoldedCard;
