import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import JDFullModal from 'components/_v2/_common/modals/JDFullModal';
import RoutePaths from 'consts/RoutePaths';
import { PositionItemTypeText, ProfileItemType, ProfileItemTypeText } from 'consts/_v2/profile/ProfileType';
import IPositionJdProps from 'interfaces/_v2/positionJd/IPositionJdProps';
import { inject, observer } from 'mobx-react';
import PositionJdModel from 'models/_v2/PositionJdModel';
import { injectStore } from 'models/store';
import React, { FC } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

const Frame = styled.div`
    padding: 56px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
        font: ${Fonts.H4_Bold};
        color: ${colors.JOBDA_BLACK};
        margin-bottom: 16px;
        text-align: center ;
    }

    .sub-desc {
        display: flex;
        align-items: center;
        font: ${Fonts.B2_Medium};
        color: ${colors.CG_90};

        span {
            margin-left: 4px;
        }
    }
`;

const RequirementBoxFrame = styled.div`
    width: 100%;
    
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 12px;
    column-gap: 12px;
    margin-top: 24px;
    .card {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 108px;
      border-radius: 8px;
      border: 1px solid ${colors.CG_50};
      background: ${colors.WHITE_100};
    }

    .main {
      font: ${Fonts.B2_Bold};
      color: ${colors.JOBDA_BLACK};
      margin-top: 8px;
      margin-bottom: 2px;
    }

    .sub {
      font: ${Fonts.B4_Medium};
      color: ${colors.CG_60};
    }

    ul {
      display: flex;
      align-items: center;
    }

    .icon-error {
      position: absolute;
      top: 8px;
      right: 8px;
    }
`;

export interface IProfileRequirementModalProps extends IPositionJdProps {
    handleClose: () => void;
}

const ProfileRequirementModal: FC<IProfileRequirementModalProps> = ({ handleClose, positionJdModel = new PositionJdModel() }) => {
  const history = useHistory();

  const handleIconName = (profileItemType: ProfileItemType) => {
    switch (profileItemType) {
      case ProfileItemType.BASIC:
        return 'heart';
      case ProfileItemType.EDUCATION:
        return 'university';
      case ProfileItemType.CAREER:
        return 'work';
      case ProfileItemType.PROJECT:
        return 'project-light';
      case ProfileItemType.KNOWLEDGE_AND_SKILL:
        return 'design';
      case ProfileItemType.PREFERENTIAL:
        return 'military';
      case ProfileItemType.FILE:
        return 'folder';
      default:
        return 'read-book';
    }
  };
  return (
    <JDFullModal handleClose={handleClose}>
      <Frame>
        <div className='title'>
          이 포지션에 지원하기 위해<br />필요한 프로필 항목을 확인해 주세요.
        </div>
        <div className='sub-desc'>
          <Icon name='check' size={24} color={colors.JOBDA_BLACK} />
          <span>프로필이 상세할수록 서류 합격률이 높아져요.</span>
        </div>
        <div className='sub-desc'>
          <Icon name='check' size={24} color={colors.JOBDA_BLACK} />
          <span>포지션마다 요구하는 프로필 항목이 다를 수 있어요.</span>
        </div>
        <RequirementBoxFrame>
          {positionJdModel.profileRequiredItems && Object.keys(positionJdModel.profileRequiredItems).map((key) => (
            <div className='card' role='presentation' key={key} onClick={() => history.replace(RoutePaths.profile_anchor(key))}>
              <Icon name={handleIconName(key as ProfileItemType)} size={32} color={colors.JOBDA_BLACK} />
              <Icon name='error' className='icon-error' size={20} color={colors.ERROR} />
              <div className='main'>{ProfileItemTypeText[key]}</div>
              {
                key === ProfileItemType.EDUCATION || key === ProfileItemType.CAREER || key === ProfileItemType.PROJECT ? (
                  <></>)
                  : (
                    <ul className='sub'>
                      (
                      {positionJdModel.profileRequiredItems && positionJdModel.profileRequiredItems[key].map((item, idx, array) => (
                        <li key={item}>
                          {PositionItemTypeText[item]}{idx < array.length - 1 ? ', ' : ''}
                        </li>
                      ))}
                      )
                    </ul>
                  )
              }
            </div>
          ))}
        </RequirementBoxFrame>
      </Frame>
    </JDFullModal>
  );
};

export default inject(injectStore.positionJdModel)(observer(ProfileRequirementModal));
