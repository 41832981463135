import React from 'react';

import classnames from 'classnames/bind';
import styles from './flowListItem.module.scss';

const cx = classnames.bind(styles);

interface FlowListItemProps {
  thumbnail: string;
  comment: string;
  job: string;
  who: string;
  url: string;
  handleItemClick: (url: string) => void;
}

const FlowListItem = ({
  thumbnail,
  comment,
  job,
  who,
  url,
  handleItemClick,
}: FlowListItemProps) => {
  return (
    <div className={cx('flowListItem')}>
      <button
        className={cx('storyBox')}
        type="button"
        onClick={() => handleItemClick(url)}
      >
        <div
          className={cx('videoImg')}
          style={{ backgroundImage: `url(${thumbnail})` }}
        >
          <div className={cx('playBtn')} />
        </div>
        <div className={cx('videoText')}>
          <p
            className={cx('storyMent')}
            dangerouslySetInnerHTML={{ __html: comment }}
          />
          <p className={cx('interviewJob')}>{job}</p>
          <span className={cx('interviewer')}>{who}</span>
        </div>
      </button>
    </div>
  );
};

export default FlowListItem;
