import DisableScroll from 'components/common/DisableScroll';
import Portal from 'components/common/Portal';
import { IconCloseW } from 'consts/assets/icons/iconPages';
import { absoluteCenterCenter, dimmer } from 'consts/style/mixins';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';

interface IAccVideoModalProps extends IComponentProps {
  isOpen:boolean;
  onClose:() => void;
  onNomore?: () => void; // 다시 보기 버튼 콜백. 없을 경우 나타나지 않는다.
  url : string;
  useOutsideClick?: boolean;

}

const Dimmer = styled.div`
  ${dimmer()};
  z-index:-1;
`;

const Frame = styled.section`
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index:99000;
`;

const VideoFrame = styled.article`
  position:absolute;
  ${absoluteCenterCenter()};
  width:328px;
  height:184px;
  .frame-video, iframe {
    height:100%;
  }
  .btn-close{
    position:absolute;
    right:5px;
    top:-35px;
  }
`;

const AccVideoModal:FC<IAccVideoModalProps> = ({ isOpen, onClose, onNomore, url, useOutsideClick }) => {
  const wrapperRef = useRef<any>(null);

  const useOutsideClicker = (ref:any) => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClose && onClose();
      }
    }
    useEffect(() => {
      if (useOutsideClick) {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }
    }, [ref]);
  };

  useOutsideClicker(wrapperRef);
  if (!isOpen)
    return <></>;

  return (
    <Portal>
      <DisableScroll />
      <Frame ref={wrapperRef}>
        <Dimmer />
        <VideoFrame>
          <button className='btn-close' onClick={onClose}>
            <IconCloseW />
          </button>
          <div className='player'>
            <video className='player__video viewer' style={{ width: 320, height: 240 }} loop playsInline controls autoPlay>
              <source src={url} type='video/mp4' />
            </video>
          </div>
        </VideoFrame>
      </Frame>
    </Portal>
  );
};

export default AccVideoModal;
