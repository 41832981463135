import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import styled from 'styled-components';
import { fontStyle } from '__designkit__/common/fonts';
import colors from '__designkit__/common/colors';
import iconCheckRegular from 'assets/icon/icon_check_16_Regular_green.svg';
import { keyFrameFadeIn } from 'consts/style/mixins';

const JDARadioLabel = styled.label`
input[type=checkbox] {
  display:none;
  + .label {
    display: inline-block;
    padding:4px 11px 6px 11px;
    ${fontStyle.CAPTION1_MEDIUM};
    color:${colors.CG_80};
    background:${colors.WHITE_100};
    border:1px solid ${colors.CG_40};
    width:fit-content;
    transition:background .5s, border .5s;
    border-radius:4px;
  }
  &:checked + .label {
    ${fontStyle.CAPTION1_SEMIBOLD};
    color:${colors.G_200};
    background:${colors.G_30};
    border:1px solid ${colors.G_100};
    width:fit-content;
    &:before {
      content:'';
      display:inline-block;
      width:16px;
      height:16px;
      background:url(${iconCheckRegular});
      vertical-align:sub;
      margin-right:6px;
      animation: fadein .3s forwards;
      ${keyFrameFadeIn};
    }
  }
}
`;

interface IJDAFilterCheckboxProps {
  control: Control;
  name: string;
  defaultValue?:any;
  value:any;
  text:string;
}

const JDAFilterCheckbox:FC<IJDAFilterCheckboxProps> = ({ control, name, defaultValue, value, text }) => (
  <Controller
    control={control}
    name={name}
    defaultValue={defaultValue}
    render={(props) => (
      <JDARadioLabel>
        <input type='checkbox' {...props} value={value} checked={`${control.watchInternal(name)}` === 'true'} onChange={(e) => props.onChange(e.target.checked)} defaultChecked={defaultValue} />
        <span className='label'>{text}</span>
      </JDARadioLabel>
    )}
  />
);

export default JDAFilterCheckbox;
