import { MatchLoginConnectInfoModalProps } from 'components/_v2/matchLogin/MatchLoginConnectInfoModal';
import JDBaseModal from 'components/_v2/_common/modals/JDBaseModal';
import { JDModalBasicFrame, JDModalDescription, JDModalFullButton, JDModalTitle } from 'components/_v2/_common/modals/JDModalComponents';
import JDNoHeaderFullModal from 'components/_v2/_common/modals/JDNoHeaderFullModal';
import React, { FC } from 'react';
import styled from 'styled-components';

const Frame = styled.div`

    ${JDModalDescription} {
        margin-top: 8px;
    }
`;

const MatchLoginRedundantEmailModal: FC<MatchLoginConnectInfoModalProps> = ({ isOpen, onClickClose }) => (
  <JDNoHeaderFullModal isOpen={isOpen} onClose={onClickClose}>
    <JDBaseModal dimmed isOpen={isOpen} onClickClose={onClickClose} isDimClickClose>
      <Frame>
        <JDModalBasicFrame>
          <JDModalTitle>이미 연동된 메시지입니다.</JDModalTitle>
          <JDModalDescription>
            연동한 아이디를 확인해 주세요.
          </JDModalDescription>
          <JDModalFullButton onClick={onClickClose}>확인</JDModalFullButton>
        </JDModalBasicFrame>
      </Frame>
    </JDBaseModal>
  </JDNoHeaderFullModal>
);

export default MatchLoginRedundantEmailModal;
