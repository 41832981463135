import React from 'react';

import classnames from 'classnames/bind';
import styles from './introStyle.module.scss';

const cx = classnames.bind(styles);

const Intro = () => (
  <div className={cx('wrap')}>
    <div className={cx('introBg')} />
  </div>
);

export default Intro;
