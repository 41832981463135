import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import MatchApplyBlindFullModal from 'components/_v2/matchApply/MatchApplyBlindFullModal';
import MatchJobSearchStatusModal from 'components/_v2/matchApply/MatchJobSearchStatusModal';
import ConditionEditModal from 'components/_v2/profile/condition/ConditionEditModal';
import MatchServiceToggle from 'components/_v2/profile/match/MatchServiceToggle';
import { Divider1G40 } from 'components/divider/Divider';
import { JobSearchStatusTypeText } from 'consts/MatchType';
import { LoggingType } from 'consts/_v2/LoggingType';
import { lineClampBox } from 'consts/style/mixins';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import MatchApplyModel from 'models/_v2/MatchApplyModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

const Frame = styled.div`
    padding: 0 16px;
`;

const ToggleFrame = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0;

  .title {
    font: ${Fonts.B1_Medium};
    color: ${colors.CG_70};
  }

  .toggle-frame {
    display: flex;
    align-items: center;

    .toggle-desc {
        font: ${Fonts.B1_Bold};
        color: ${colors.JOBDA_BLACK};
        margin-right: 16px;
    }
  }
`;

const TitleFrame = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    font: ${Fonts.B1_Bold};
    color: ${colors.JOBDA_BLACK};
  }

  .edit-button {
    font: ${Fonts.B3_Medium};
    color: ${colors.JOBDA_BLACK};
    text-decoration-line: underline;
  }
`;

const Text = styled.div`
  display: flex;
  align-items: center;

  .text-limit {
    font: ${Fonts.B2_Medium};
    color: ${colors.CG_70};
    ${lineClampBox(1)}
  }
`;

interface IMatchApplySetting {
  login?:Login
  matchApplyModel?: MatchApplyModel
}
const MatchApplySetting: FC<IMatchApplySetting> = ({ login = new Login(), matchApplyModel = new MatchApplyModel() }) => {
  const { matchingConfigYn, jobSearchStatus } = login.matchConfigStatus || {};
  const [isOpenJobStateModal, setIsOpenJobStateModal] = useState<boolean>(false);
  const [isOpenBlindModal, setIsOpenBlindModal] = useState<boolean>(false);
  const useFormed = useForm({ mode: 'onSubmit' });
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);

  useEffect(() => {
    const init = async () => {
      await matchApplyModel.loadMyBlinds();
    };
    init();
  }, []);

  const conditionEdit = () => {
    setIsEditOpen(true);
  };

  return (
    <Frame>
      <FormProvider {...useFormed}>
        <ToggleFrame>
          <div className='title'>매칭 설정</div>
          <div className='toggle-frame'>
            <span className='toggle-desc'>제안 받기</span>
            <MatchServiceToggle sourcePage={LoggingType.MATCH} conditionEdit={conditionEdit} />
          </div>
        </ToggleFrame>
        {matchingConfigYn && (
          <>
            <Divider1G40 />
            <SpacingBlock size={24} vertical />
            <TitleFrame>
              <div className='title'>선호 정보</div>
              <button className='edit-button' onClick={() => setIsEditOpen(true)}>변경</button>
            </TitleFrame>
            <SpacingBlock size={24} vertical />
            <TitleFrame>
              <div className='title'>구직 상태</div>
              <button className='edit-button' onClick={() => setIsOpenJobStateModal(true)}>변경</button>
            </TitleFrame>
            <SpacingBlock size={16} vertical />
            <Text>
              <div className='text-limit'>
                {jobSearchStatus && JobSearchStatusTypeText[jobSearchStatus]}
              </div>
            </Text>
            <SpacingBlock size={24} vertical />
            <TitleFrame>
              <div className='title'>프로필 열람 제한 기업</div>
              <button className='edit-button' onClick={() => setIsOpenBlindModal(true)}>변경</button>
            </TitleFrame>
            <SpacingBlock size={16} vertical />
            <Text>
              <div className='text-limit'>
                {matchApplyModel.myBlinds?.blindCompanies?.map((blindCompany) => blindCompany.name).join(', ')}
              </div>
            </Text>
            <SpacingBlock size={16} vertical />

          </>
        )}
      </FormProvider>
      {isOpenBlindModal
        && (
        <MatchApplyBlindFullModal
          handleClose={async () => {
            setIsOpenBlindModal(false);
            await matchApplyModel.loadMyBlinds();
          }}
        />
        )}
      {login.matchConfigStatus?.jobSearchStatus && (
        <MatchJobSearchStatusModal
          isOpen={isOpenJobStateModal}
          onClickClose={() => setIsOpenJobStateModal(false)}
        />
      )}
      <ConditionEditModal isOpen={isEditOpen} onClose={() => setIsEditOpen(false)} />

    </Frame>
  );
};

export default inject(injectStore.login, injectStore.matchApplyModel)(observer(MatchApplySetting));
