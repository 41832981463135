import React, { useEffect } from 'react';
import styled from 'styled-components';
import Logo from '__designkit__/Logo';
import Fonts from '__designkit__/common/fonts';
import colors from '__designkit__/common/colors';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import { Button, ButtonMode } from '__designkit__/button/Button';
import { FormProvider, useForm } from 'react-hook-form';
import JDDatePickerInput from 'components/_v2/_common/input/JDDatePickerInput';
import { make15YearsOldDate } from 'utils/DateUtils';
import Login from 'models/Login';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import useToast from 'hooks/useToast';
import { useHistory } from 'react-router';
import RoutePaths from 'consts/RoutePaths';
import AuthorizeUtil from 'utils/AuthorizeUtil';
import Context from 'models/Context';
import { UserAuthorityType } from 'consts/_v2/UserAuthorityType';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top));
  padding: 16px;
  border-top: 8px solid ${colors.CG_90};
  background-color: ${colors.WHITE_100};
`;

const HeaderFrame = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
  padding: 24px 0;
  border-bottom: 1px solid ${colors.CG_50};
`;

const ContentFrame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
  padding: 0 4px;
  text-align: center;

  & h1 {
    font: ${Fonts.H4_Bold};
    color: ${colors.JOBDA_BLACK};
  }

  & p {
    font: ${Fonts.B2_Medium_P};
    color: ${colors.CG_70};
  }
`;

const DatePickerFrame = styled.div`
  width: 100%;
  padding: 0 4px;
`;

const BtnFrame = styled.div`
  width: 100%;
  height: fit-content;
  padding: 8px 4px;
`;

const UpdateBirthdatePage = ({ context = new Context(), login = new Login() }) => {
  const history = useHistory();
  const formMethods = useForm();
  const { setToastObject } = useToast();
  const onClickSaveBtn = async () => {
    try {
      await login.updateBirthDate(formMethods.watch('birthDate'));
      await AuthorizeUtil.updateAccessToken(true);

      setToastObject({ isOpen: true, message: '생년월일이 성공적으로 변경되었습니다.', type: 'SUCCESS' });

      if (login.userInfo?.authorities.includes(UserAuthorityType.LONG_TERM_INACTIVE_VERIFICATION_REQUIRED)) {
        if (window.location.pathname !== RoutePaths.update_account) {
          window.location.href = RoutePaths.update_account;
        }
        return;
      }
      if (context.previousPathList.length === 0 || context.previousPathList[context.previousPathList.length - 1] === '/update-birthdate') history.replace(RoutePaths.root);
      else history.replace(context.previousPathList[context.previousPathList.length - 1]);
    } catch (e) {
      const err = e as { response: { data: { message: string } } };
      setToastObject({ isOpen: true, message: err.response.data.message || '알 수 없는 오류로 실패했습니다. 잠시 후에 다시 시도해 주세요.', type: 'ERROR' });
    }
  };

  useEffect(() => {
    if (!login.userInfo) history.replace(RoutePaths.root);
    if (!login.userInfo?.authorities.includes(UserAuthorityType.USER_15_TEEN)) history.replace(RoutePaths.root);
  }, [history, login.userInfo]);

  return (
    <Frame>
      <HeaderFrame>
        <Logo color='black' size={80} />
      </HeaderFrame>
      <ContentFrame>
        <h1>만 15세 미만이신가요?</h1>
        <SpacingBlock size={16} vertical />
        <p>만 15세 미만은 잡다를 이용할 수 없으니,<br />정확한 생년월일을 입력해 주세요.</p>
        <SpacingBlock size={24} vertical />
        <FormProvider {...formMethods}>
          <DatePickerFrame>
            <JDDatePickerInput name='birthDate' placeholder='생년월일' maxDate={make15YearsOldDate()} />
          </DatePickerFrame>
        </FormProvider>
      </ContentFrame>
      <BtnFrame>
        <Button label='저장하기' buttonMode={ButtonMode.PRIMARY} size='large' disabled={!formMethods.watch('birthDate')} onClick={onClickSaveBtn} />
      </BtnFrame>
    </Frame>
  );
};

export default inject(injectStore.context, injectStore.login)(observer(UpdateBirthdatePage));
