import React, { FC } from 'react';
import JDASelector, { JDA_SELECTOR_TYPE } from '../../components/inputs/JDASelector';
import styled from 'styled-components';
import { FormProvider, useForm } from 'react-hook-form';

const StyledFrame = styled.div`
  padding: 0 16px;
  margin: 16px 0;
`;

const SelectOptionPage: FC = (() => {
  const useformed = useForm({ mode: 'onChange' });

  return (
    <div>
      <FormProvider {...useformed}>
        <StyledFrame>
          <JDASelector selectTitle='선택하세요' type={JDA_SELECTOR_TYPE.type2}>
            <select>
              <option value='' hidden>placeholder</option>
              <option value='a'>선택1</option>
              <option value='b'>선택2</option>
              <option value='c'>선택3</option>
              <option value='d'>선택4</option>
              <option value='e'>선택5</option>
              <option value='f'>선택6</option>
            </select>
          </JDASelector>
        </StyledFrame>

        <StyledFrame>
          <JDASelector selectTitle='선택하세요' type={JDA_SELECTOR_TYPE.type2}>
            <select>
              <option value='' selected disabled>placeholder</option>
              <option value='a'>선택a</option>
              <option value='b'>선택b</option>
              <option value='c'>선택c</option>
              <option value='d'>선택d</option>
              <option value='e'>선택e</option>
              <option value='f'>선택f</option>
            </select>
          </JDASelector>
        </StyledFrame>

        <StyledFrame>
          <JDASelector selectTitle='선택하세요' type={JDA_SELECTOR_TYPE.type1}>
            <select>
              <option value='' selected disabled>placeholder</option>
              <option value='a'>선택a</option>
              <option value='b'>선택b</option>
              <option value='c'>선택c</option>
              <option value='d'>선택d</option>
              <option value='e'>선택e</option>
              <option value='f'>선택f</option>
            </select>
          </JDASelector>
          <JDASelector selectTitle='선택하세요' type={JDA_SELECTOR_TYPE.type1}>
            <select>
              <option value='' selected disabled>placeholder</option>
              <option value='a'>선택aa</option>
              <option value='b'>선택bb</option>
              <option value='c'>선택cc</option>
              <option value='d'>선택dd</option>
              <option value='e'>선택ee</option>
              <option value='f'>선택ff</option>
            </select>
          </JDASelector>
        </StyledFrame>
      </FormProvider>
    </div>
  );
});

export default SelectOptionPage;
