/* eslint-disable array-callback-return */
/* eslint-disable react/no-this-in-sfc */
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { DatePickerFormValueFrame } from 'components/_v2/_common/form/FormProfileValueFrame';
import FormProfileValueTitle from 'components/_v2/_common/form/FormProfileValueTitle';
import JDDatePickerInput from 'components/_v2/_common/input/JDDatePickerInput';
import JDDescriptionInput from 'components/_v2/_common/input/JDDescriptionInput';
import JDSearchInput from 'components/inputs/JDSearchInput';
import URIs from 'consts/URIs';
import { BaseProfileActivityAndForeignDto } from 'interfaces/_v2/insight/IInsightResumeRqRs';
import { INameCodeRs } from 'interfaces/rqrs/ICommonRqRs';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import request from 'utils/request';

const ActivitiesFrame = styled.div`
    .content {
      padding: 0 1px;
    }
 .title-text{
      font:${Fonts.H4_Bold};
      color:${Colors.JOBDA_BLACK};
      margin-bottom: 24px;
    }
    .activity-selector{
        margin:20px 0 24px 0;
        .selector{
            margin-top: 12px;
        }
        .center-place{
            margin-top: 12px;
    }

    }
    
    .info-date{
      margin-bottom: 24px;
    }
    .margin-date{
      margin-top: 12px;
    }
`;

interface IResumeEditForeign{
    prefixName:string;
    foreignExperiencesIndex:number;
    isVisible:boolean
    foreignFields?:BaseProfileActivityAndForeignDto[]
}

const ResumeEditForeign:FC<IResumeEditForeign> = ({ prefixName, foreignExperiencesIndex, isVisible, foreignFields }) => {
  const { control, watch, setValue, trigger } = useFormContext();
  const [searchCountries, setSearchCountries] = useState<INameCodeRs[]>([]);
  const [getCountries, setGetCountries] = useState<INameCodeRs[]>([]);
  const topRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isVisible && topRef.current) {
      topRef.current.scrollIntoView();
    }
  }, [isVisible]);

  const searchCountry = async () => {
    try {
      const { countries } = await request<{ countries: INameCodeRs[] }>({
        method: 'get',
        url: URIs.get_codes_countries,
      });
      setGetCountries(countries);
    } catch (e) {
      console.error(e);
      setGetCountries([]);
    }
  };

  useEffect(() => {
    searchCountry();
  }, [watch(`${prefixName}[${foreignExperiencesIndex}].code`)]);
  return (
    <ActivitiesFrame hidden={!isVisible} ref={topRef}>
      <div className='title-text'>
        경험을 통해 얻은 결과와 배운 점을<br />구체적으로 작성해주세요.
      </div>
      {
        foreignFields?.map((field, index) => (
          <div hidden={index !== foreignExperiencesIndex} className='content'>

            <div className='activity-selector'>
              <FormProfileValueTitle>국가명</FormProfileValueTitle>
              <div className='center-country'>
                <JDSearchInput
                  title='국가명'
                  defaultTextName={getCountries.find(((value) => value.code === watch(`${prefixName}[${index}].code`)))?.name}
                  defaultCodeName={watch(`${prefixName}[${index}]`).code}
                  textName={`${prefixName}[${index}].title`}
                  codeName={`${prefixName}[${index}].code`}
                  placeholder='국가를 검색해 주세요.'
                  forForeign
                  onSearch={(searchText: string) => {
                    if (searchText === '') setSearchCountries(getCountries);
                    else {
                      const searchItem = getCountries.filter((value) => value.name.includes(searchText));
                      setSearchCountries(searchItem);
                    }
                  }}
                  searchList={searchCountries}
                />
              </div>
            </div>
            <FormProfileValueTitle>기간</FormProfileValueTitle>
            <DatePickerFormValueFrame>
              <JDDatePickerInput
                key={`${watch(`${prefixName}[${index}]`).id}-start`}
                name={`${prefixName}[${index}].startDate`}
                placeholder='연도. 월.'
                month
                defaultValue={watch(`${prefixName}[${index}]`).startDate}
              />
              <span className='date-picker-divider'>~</span>
              <JDDatePickerInput
                key={`${watch(`${prefixName}[${index}]`).id}-end`}
                name={`${prefixName}[${index}].endDate`}
                placeholder='연도. 월.'
                month
                defaultValue={watch(`${prefixName}[${index}]`).endDate}
              />
            </DatePickerFormValueFrame>

            <FormProfileValueTitle>내용</FormProfileValueTitle>
            <JDDescriptionInput
              key={watch(`${prefixName}[${index}]`).id}
              name={`${prefixName}[${index}].description`}
              placeholder='해외에서 어떤 경험을 했는지 간단히 입력해 주세요. (예시 : 교환학생, 어학연수, 워킹홀리데이, 해외근무)'
              maxLength={1000}
              height={120}
              autoFocus={false}
              limitation
              defaultValue={watch(`${prefixName}[${index}]`).description}
            />
          </div>
        ))
      }

    </ActivitiesFrame>
  );
};

export default ResumeEditForeign;
