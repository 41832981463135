import { InsightResumeLogRq, InsightResumeSaveRq } from 'interfaces/_v2/insight/IInsightResumeRqRs';
import { useMutation, useQuery } from 'react-query';
import { RemoteResumeRepo } from 'repository/resume/ResumeRepo';
import { AxiosError } from 'axios';

export const keys = ['insight', 'resume'];

const resumeRepo = new RemoteResumeRepo();

export const fetchInsightAccessToken = async () => {
  const data = await resumeRepo.fetchInsightAccessToken();
  return data;
};

export function useInsightAceessToken() {
  return useMutation(fetchInsightAccessToken);
}

const fetchInsightResumeList = async () => {
  const data = await resumeRepo.fetchInsightResumeList();
  return data;
};

export default function useInsightResumeList(isEnabled: boolean) {
  return useQuery([...keys, 'list'], fetchInsightResumeList, { enabled: isEnabled });
}

const fetchInsightResumeDetail = async (resumeSn: number) => {
  const data = await resumeRepo.fetchInsightResumeDetail(resumeSn);
  return data;
};

export function useInsightResumeDetail(resumeSn: number, isEnabled: boolean) {
  return useQuery([...keys, resumeSn], () => fetchInsightResumeDetail(resumeSn), { enabled: isEnabled });
}

const fetchInsightResumeSave = async (rq: InsightResumeSaveRq) => {
  const data = await resumeRepo.saveInsightResume(rq);
  return data;
};

export function useInsightResumeSave(onSuccess: () => void, onError?: (e: AxiosError) => void) {
  return useMutation((rq: InsightResumeSaveRq) => fetchInsightResumeSave(rq), {
    onSuccess,
    onError,
  });
}

const fetchInsightResumeUnread = async () => {
  const data = await resumeRepo.fetchInsightResumeUnread();
  return data;
};

export function useInsightResumeUnread(isEnabled: boolean) {
  return useQuery([...keys, 'unread'], fetchInsightResumeUnread, { enabled: isEnabled });
}

const fetchInsightResumeLog = async (rq: InsightResumeLogRq) => {
  const data = await resumeRepo.saveInsightResumeLog(rq);
  return data;
};

export function useInsightResumeLog(onSuccess: () => void, onError?: (e: AxiosError) => void) {
  return useMutation((rq: InsightResumeLogRq) => fetchInsightResumeLog(rq), {
    onSuccess,
    onError,
  });
}

const fetchInsightResumeAutoSave = async () => {
  const data = await resumeRepo.fetchInsightResumeAutoSave();
  return data;
};

export function useInsightResumeAutoSave(isEnabled: boolean) {
  return useQuery([...keys, 'autoSave'], fetchInsightResumeAutoSave, { enabled: isEnabled });
}
