import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import { CompanyInformationTitle } from 'components/_v2/company/CompanyDetailInformation';
import React, { FC } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
    padding: 0 16px;
`;

const LocationTitle = styled.div`
  display: flex;
  flex-direction: row;
  .text{
    font:${Fonts.B3_Medium};
    color:${colors.CG_70};
    display: flex;
    align-items: center;
  }
`;
interface ICompanyDetailsKakaoComponent {
  location:string;
}
const CompanyDetailsKakaoComponent: FC<ICompanyDetailsKakaoComponent> = ({ location }) => (
  <Frame>
    <SpacingBlock vertical size={64} />
    <CompanyInformationTitle>위치</CompanyInformationTitle>
    <SpacingBlock vertical size={12} />
    <LocationTitle>
      <Icon name='pin' size={24} color={colors.CG_70} />
      <div className='text'>{location}</div>
    </LocationTitle>
    <SpacingBlock vertical size={24} />
    <div
      id='kakaoMap'
      style={{
        width: '100%',
        height: '240px',
      }}
    />
    <SpacingBlock size={40} vertical />
  </Frame>
);

export default CompanyDetailsKakaoComponent;
