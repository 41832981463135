import { Button, ButtonMode } from '__designkit__/button/Button';
import colors from '__designkit__/common/colors';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import JDBaseModal from 'components/_v2/_common/modals/JDBaseModal';
import { Divider1G, Divider1G40 } from 'components/divider/Divider';
import RoutePaths from 'consts/RoutePaths';
import { LoggingType } from 'consts/_v2/LoggingType';
import { ProfileType } from 'consts/_v2/profile/ProfileType';
import IComponentProps from 'interfaces/props/IComponentProps';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

const Frame = styled.div`
  width: 312px;
  height: fit-content;
  border-radius: 12px;
  background-color: ${Colors.WHITE_100};
`;

const ContentsFrame = styled.div`
  width: 100%;
  height: fit-content;
  padding: 40px 20px 0;

  .title {
    font: ${Fonts.H4_Bold};
    text-align: center;
  }
`;

const MissionList = styled.ul`
  width: 100%;
  height: fit-content;
  
  .mission-type {
    font: ${Fonts.B3_Medium_P};
    color: ${colors.CG_70};
    margin-bottom: 12px;
  }

  & > li:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const MissionItem = styled.li<{ isComplete: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font: ${Fonts.B2_Medium};

  .mission-title-frame {
    display: flex;
    align-items: center;
  }

  .mission-tag-frame {
    display: flex;
    align-items: center;
    width: 64px;
  }
  .mission-title {
    font: ${Fonts.B1_Bold};
  }

  .mission-sub {
    font: ${Fonts.B4_Medium_P};
    color: ${colors.CG_70};
    margin-left: 4px;
  }

  .tag {
    margin-left: 4px;
  }
  ${(props) => !props.isComplete && `
    .tag {
      color: ${Colors.CG_60};
    }
  `}
`;

const ButtonFrame = styled.div`
  width: 100%;
  height: fit-content;
  padding: 20px;
`;

interface IMissionItemProps {
  isComplete: boolean;
  title: string;
  subTitle?: string;
  profile?: boolean;
  onClickClose?: () => void;
  sourcePage?: string;
}

const MissionItemComponent: FC<IMissionItemProps> = ({ isComplete, title, subTitle, profile, onClickClose, sourcePage }) => {
  const history = useHistory();
  return (
    <MissionItem isComplete={isComplete}>
      <div
        role='presentation'
        className='mission-title-frame'
        onClick={() => {
          if (onClickClose) {
            onClickClose();
          }
          if (profile) {
            if (sourcePage === LoggingType.MATCH) history.push(RoutePaths.profile_source(LoggingType.MATCH));
            else history.push(RoutePaths.profile);
          }
        }}
      >
        {title}
        { profile && <Icon name='arrow-right' size={24} color={Colors.JOBDA_BLACK} />}
        {subTitle && <div className='mission-sub'>{subTitle}</div>}
      </div>
      <div className='mission-tag-frame'>
        <Icon name='check-circle-filled' size={24} color={isComplete ? Colors.G_150 : Colors.CG_50} />
        <span className='tag'>{isComplete ? '완료' : '미완료'}</span>
      </div>

    </MissionItem>
  );
};

interface IMatchMissionGuideModalProps extends IComponentProps {
  isOpen: boolean;
  onClickClose: () => void;
  login?: Login;
  sourcePage?: string;
}

const MatchMissionGuideModal: FC<IMatchMissionGuideModalProps> = ({ isOpen, onClickClose, login = new Login(), sourcePage }) => {
  const [type, setType] = useState('');
  useEffect(() => {
    const init = async () => {
      if (isOpen) {
        await login.getMatchConfigStatus();
        await login.loadProfileType();

        // 둘다 없으면 일반 직군
        if (!login.profileType?.generalYn && !login.profileType?.developerYn) {
          setType(ProfileType.GENERAL);
        } else if (login.profileType?.generalYn && !login.profileType?.developerYn) {
          // 일반 직군만 선택
          setType(ProfileType.GENERAL);
        } else if (!login.profileType?.generalYn && login.profileType?.developerYn) {
          // 개발 직군만 선택
          setType(ProfileType.DEVELOPER);
        } else {
          // 일반 + 개발 직군 정보가 있지만 역량검사결과들이 없어서 조건 통과 못했을 경우
          setType('ALL');
        }
      }
    };

    init();
  }, [isOpen]);

  return (
    <JDBaseModal isOpen={isOpen} onClickClose={onClickClose} dimmed isDimClickClose>
      <Frame>
        <ContentsFrame>
          <div className='title'>잡다매칭에 참여하기 위해<br />할 일을 확인해보세요.</div>
          <SpacingBlock size={24} vertical />
          <MissionList>
            {(type === ProfileType.GENERAL || type === 'ALL') && (
              <>
                <div className='mission-type'>일반 직군</div>
                <MissionItemComponent
                  isComplete={!!login.matchConfigStatus?.matchingConfigCondition.profileRequiredCompleteYn}
                  title='프로필 필수 항목 입력'
                  profile
                  onClickClose={onClickClose}
                  sourcePage={sourcePage}
                />
                <MissionItemComponent
                  isComplete={!!login.matchConfigStatus?.matchingConfigCondition.accCompleteYn}
                  title='역량검사'
                  subTitle='(PC만 가능)'
                />
              </>
            )}
            {type === 'ALL' && <Divider1G40 />}
            <SpacingBlock size={24} vertical />
            {(type === ProfileType.DEVELOPER || type === 'ALL') && (
              <>
                <div className='mission-type'>개발 직군</div>
                <MissionItemComponent
                  isComplete={!!login.matchConfigStatus?.matchingConfigCondition.profileRequiredCompleteYn}
                  title='프로필 필수 항목 입력'
                  profile
                  onClickClose={onClickClose}
                  sourcePage={sourcePage}
                />
                <MissionItemComponent
                  isComplete={!!login.matchConfigStatus?.matchingConfigCondition.paccCompleteYn}
                  title='개발자 역량검사'
                  subTitle='(PC만 가능)'
                />
                <MissionItemComponent
                  isComplete={!!login.matchConfigStatus?.matchingConfigCondition.pstCompleteYn}
                  title='개발구현능력검사'
                  subTitle='(PC만 가능)'
                />
              </>
            )}
          </MissionList>
        </ContentsFrame>
        <ButtonFrame>
          <Button buttonMode={ButtonMode.PRIMARY} size='large' onClick={onClickClose} label='확인' />
        </ButtonFrame>
      </Frame>
    </JDBaseModal>
  );
};

export default inject(injectStore.login)(observer(MatchMissionGuideModal));
