import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import { DateUtil } from 'components/_v2/_common/input/datePicker/DateUtil';
import RoutePaths from 'consts/RoutePaths';
import { lineClampBox } from 'consts/style/mixins';
import shadows from 'consts/style/shadows';
import { PassReviewListDto, PassReviewPostListGetRs } from 'interfaces/_v2/passReview/IPassReviewRqRs';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { DateFormatYYMMDDWithDot } from 'utils/DateUtils';

const Frame = styled.div<{badgeType:string}>`
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
    
    .title-text{
        font:${Fonts.B1_Bold_P};
        color:${Colors.JOBDA_BLACK};
        ${lineClampBox(2)}
        .badge{
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            padding:3.5px 8px;
            border:${(props) => (props.badgeType === 'NOTICE' ? `1px solid ${Colors.CG_50}` : 'none')};
            background-color: ${(props) => (props.badgeType === 'NOTICE' ? Colors.WHITE_100 : Colors.CG_80)};
            font:${Fonts.B3_Medium};
            color:${(props) => (props.badgeType === 'NOTICE' ? Colors.CG_70 : Colors.WHITE_100)};
            margin-right:8px;
        }
    }
    .sub-frame{
        display: flex;
        align-items:center;
        .sub-text{
            font:${Fonts.B2_Medium_P};
        color:${Colors.CG_60};
        }
        .divider{
            width:1px;
            height:8px;
            background-color:${Colors.CG_40};
            margin:0 8px;
        }
        
    }
`;

interface IPassboardCard{
    info:PassReviewListDto
}
const PassboardCard: FC<IPassboardCard> = ({ info }) => {
  const history = useHistory();
  const handleClickBtn = (sn:number) => {
    if (sn) {
      history.push(RoutePaths.passboard_detail(sn));
    }
  };
  return (
    <Frame badgeType={info.type} onClick={() => handleClickBtn(info.sn)}>
      <div className='title-text'>
        {info.type === 'NOTICE' ? <span className='badge'>공지</span> : (info.type === 'BEST' ? <span className='badge'>BEST</span> : null)}
        {info.title}
      </div>
      <SpacingBlock vertical size={8} />
      <div className='sub-frame'>
        <div className='sub-text'>{info.displayId}</div>
        <div className='divider' />
        <div className='sub-text'>{DateFormatYYMMDDWithDot(info.createdDateTime) }</div>
        <div className='divider' />
        <div className='sub-text'>조회 {info.views}</div>
        <div className='divider' />
        <div className='sub-text'>댓글 {info.commentCount > 100 ? '99+' : info.commentCount}</div>
      </div>
    </Frame>
  );
};
export default PassboardCard;
