import React from 'react';
import classnames from 'classnames/bind';
import styles from './banner3Style.module.scss';

const cx = classnames.bind(styles);

const Banner3 = () => (
  <div className={cx('contentInner', 'banner3Inner')}>
    <div className={cx('star')} />
    <div className={cx('basicTitle')}>
      <p className={cx('subTitle')}>JOBDA가 여러분을 응원합니다.</p>
      <p className={cx('mainTitle')}>스펙없이 취업을, JOBDA</p>
    </div>
  </div>
);

export default Banner3;
