// created by khj1115 on 2021-07-29
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react';
import colors from '__designkit__/common/colors';
import { fontStyle } from '__designkit__/common/fonts';
import {
  IconLeftSimpleRoundSvg,
  IconRightSimpleRoundSvg,
} from '../../consts/assets/icons/iconPages';

const Frame = styled.div<{passboard?:boolean}>`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-bottom: ${(props) => (props.passboard ? '' : '24px')} ;
`;

const PageWrapFrame = styled.div``;

const Arrow = styled.div`
  display: inline-block;
  padding: 10px;
  vertical-align: top;
`;

const PageFrame = styled.ul<{ maxWidth: number }>`
  display: inline-block;
  max-width: ${({ maxWidth }) => `${maxWidth}px`};
  margin: 0 8px;
  overflow-x: hidden;
  white-space: nowrap;
`;

const Page = styled.li<{ selected: boolean }>`
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  text-align: center;
  background-color: ${({ selected }) => (selected ? colors.CG_40 : colors.WHITE_100)};
  margin-right: 4px;
  ${fontStyle.BODY1_REGULAR};
  line-height: 39px;
  color: ${({ selected }) => (selected ? colors.c474747 : colors.cA4A4A4)};
`;

export interface PagingProps {
  totalPageCount: number;
  selectedPage: number;
  visiblePageCount?: number;
  onChangePage?: (page: number) => void;
  passboard?:boolean;
}

const Paging: React.FC<PagingProps> = ({
  totalPageCount,
  selectedPage,
  visiblePageCount = 5,
  passboard = false,
  onChangePage,
}) => {
  const ref = useRef<HTMLUListElement>(null);

  const [selectPage, setSelectPage] = useState(selectedPage + 1);

  useEffect(() => {
    setSelectPage(selectedPage + 1);
  }, [selectedPage]);

  const pages = useMemo(() => Array.from({ length: totalPageCount }).map((_, index) => index + 1), [totalPageCount]);

  const maxWidth = useMemo(() => visiblePageCount * 40 + 4 * (visiblePageCount - 1), [visiblePageCount]);

  const leftArrowDisable = useMemo(() => selectPage === 1, [selectPage]);

  const rightArrowDisable = useMemo(() => selectPage >= totalPageCount, [selectPage, totalPageCount]);

  const handleLeftArrowClick = () => {
    if (selectPage - 1 <= 0) {
      return;
    }

    handleChangePage(selectPage - 1);
  };

  const handleRightArrowClick = () => {
    if (selectPage + 1 > totalPageCount) {
      return;
    }

    handleChangePage(selectPage + 1);
  };

  const handleChangePage = (page: number) => {
    const visible = Math.floor(visiblePageCount / 2);
    let middleValue = selectPage;

    if (selectPage < 1 + visible) {
      middleValue = 1 + visible;
    }
    if (selectPage > totalPageCount - visible) {
      middleValue = totalPageCount - visible;
    }

    const move = (page - middleValue) * 44;

    ref.current?.scrollBy({
      left: move,
      behavior: 'smooth',
    });

    setSelectPage(page);
    onChangePage?.(page - 1);
  };

  const handleSelectPage = (page: number) => () => {
    handleChangePage(page);
  };

  return (
    <Frame passboard={passboard}>
      <PageWrapFrame>
        <Arrow onClick={handleLeftArrowClick}>
          <IconLeftSimpleRoundSvg
            fill={leftArrowDisable ? colors.cD1D1D1 : colors.c6A6A6A}
          />
        </Arrow>
        <PageFrame maxWidth={maxWidth} ref={ref}>
          {pages.map((page) => (
            <Page
              selected={page === selectPage}
              key={page}
              onClick={handleSelectPage(page)}
            >
              {page}
            </Page>
          ))}
        </PageFrame>
        <Arrow onClick={handleRightArrowClick}>
          <IconRightSimpleRoundSvg
            fill={rightArrowDisable ? colors.cD1D1D1 : colors.c6A6A6A}
          />
        </Arrow>
      </PageWrapFrame>
    </Frame>
  );
};

export default observer(Paging);
