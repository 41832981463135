export enum ProcessType {
  WORKING = 'WORKING',
  EXECUTIVE = 'EXECUTIVE',
  PRESENTATION = 'PRESENTATION',
  DISCUSSION = 'DISCUSSION',
  FOREIGN_LANGUAGE = 'FOREIGN_LANGUAGE',
  CODING = 'CODING',
  ETC = 'ETC',
}

export enum ProcessTypeTagText {
  WORKING = '실무',
  EXECUTIVE = '임원',
  PRESENTATION = 'PT',
  DISCUSSION = '토론',
  FOREIGN_LANGUAGE = '외국어',
  CODING = '코테',
  ETC = '기타',
}

export enum ProcessTypeText { //면접까지 붙이는게 좋을 것 같아서
  WORKING = '실무면접',
  EXECUTIVE = '임원면접',
  PRESENTATION = 'PT면접',
  DISCUSSION = '토론면접',
  FOREIGN_LANGUAGE = '외국어면접',
  CODING = '코딩테스트',
  ETC = '기타',
}

export default ProcessType;
