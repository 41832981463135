import React, { useState } from 'react';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import Footer from 'components/_v2/_common/Footer';
import Intro from './intro';
import FloatingBar from './floatingBar';
import IntroBox from './introBox';
import FlowStory from './flowStory';
import PreviewStep from './previewStep';
import LogoList from './logoList';
import GateBox from './gateBox';
import Faq from './faq';
import AccaStory from './accaStory';

const AccaIntroducePage = () => {
  const [fadeInFirst, setFadeInFirst] = useState(false);
  const [fadeInSecond, setFadeInSecond] = useState(false);
  const [fadeInThird, setFadeInThird] = useState(false);
  const [fadeInFourth, setFadeInFourth] = useState(false);
  const [fadeInFifth, setFadeInFifth] = useState(false);
  const [fadeInSixth, setFadeInSixth] = useState(false);

  // Intersection Observer
  const handleIntersectFirst = (entries: IntersectionObserverEntry[]) => {
    if (entries[0].isIntersecting) {
      setFadeInFirst(true);
    }
  };

  const handleIntersectSecond = (entries: IntersectionObserverEntry[]) => {
    if (entries[0].isIntersecting) {
      setFadeInSecond(true);
    }
  };

  const handleIntersectThird = (entries: IntersectionObserverEntry[]) => {
    if (entries[0].isIntersecting) {
      setFadeInThird(true);
    }
  };

  const handleIntersectFourth = (entries: IntersectionObserverEntry[]) => {
    if (entries[0].isIntersecting) {
      setFadeInFourth(true);
    }
  };

  const handleIntersectFifth = (entries: IntersectionObserverEntry[]) => {
    if (entries[0].isIntersecting) {
      setFadeInFifth(true);
    }
  };

  const handleIntersectSixth = (entries: IntersectionObserverEntry[]) => {
    if (entries[0].isIntersecting) {
      setFadeInSixth(true);
    }
  };

  const setFirstSectionRef = useIntersectionObserver({
    onIntersect: handleIntersectFirst,
    threshold: 0.2,
  });
  const setSecondSectionRef = useIntersectionObserver({
    onIntersect: handleIntersectSecond,
    threshold: 0.2,
  });
  const setThirdSectionRef = useIntersectionObserver({
    onIntersect: handleIntersectThird,
    threshold: 0.2,
  });
  const setFourthSectionRef = useIntersectionObserver({
    onIntersect: handleIntersectFourth,
    threshold: 0.2,
  });
  const setFifthSectionRef = useIntersectionObserver({
    onIntersect: handleIntersectFifth,
    threshold: 0.2,
  });
  const setSixthSectionRef = useIntersectionObserver({
    onIntersect: handleIntersectSixth,
    threshold: 0.2,
  });

  return (
    <div>
      <div>
        <Intro />
        {/* 역량검사 응시 카드 */}
        <div ref={setFirstSectionRef}>
          <IntroBox fadeIn={fadeInFirst} />
        </div>
        {/* 역량검사 step video */}
        <div ref={setSecondSectionRef}>
          <PreviewStep fadeIn={fadeInSecond} />
        </div>
        {/* 성공사례 인터뷰 영상 */}
        <div ref={setThirdSectionRef}>
          <FlowStory fadeIn={fadeInThird} />
        </div>
        {/* 로고 리스트 */}
        <div ref={setFourthSectionRef}>
          <LogoList fadeIn={fadeInFourth} />
        </div>
        {/* 역량검사 게이트 이동 */}
        <div ref={setFifthSectionRef}>
          <GateBox fadeIn={fadeInFifth} />
        </div>
        {/* 역량검사 소개 정리 */}
        <div ref={setSixthSectionRef}>
          <AccaStory fadeIn={fadeInSixth} />
        </div>
        {/* FAQ */}
        <div>
          <Faq />
        </div>

        {/* 플로팅바 */}
        <FloatingBar />
      </div>
      <Footer isNavi={false} corpInfoFoled={false} />
    </div>
  );
};

export default AccaIntroducePage;
