import React, { FC } from 'react';
import IconPlus from 'assets/icon/icon_plus_24.svg';
import styled from 'styled-components';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';

const Frame = styled.button`
  display: flex;
  background: transparent;
  align-items:center;
  >span{
    color:${colors.CG_80};
    font: ${Fonts.B1_Medium};
    margin-left: 4px;
  }
  
  &:disabled {
    opacity: 0.5;
  }

  img {
    width: 20px;
    height: 20px;
  }
`;

const JDPlusTextButton:FC<{ text: string, disabled?: boolean, onClick: React.MouseEventHandler<HTMLButtonElement>}> = ({ text, disabled, onClick }) => (
  <Frame disabled={disabled} onClick={onClick} type='button'>
    <img src={IconPlus} alt='+' />
    <span>{text}</span>
  </Frame>
);

export default JDPlusTextButton;
