import RoutePaths from 'consts/RoutePaths';
import colors from '__designkit__/common/colors';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import Fonts from '__designkit__/common/fonts';
import PositionListModel from 'models/_v2/PositionListModel';
import Login from 'models/Login';
import useProfileConditions from 'query/profiles/useProfileConditionsQuery';
import { TwoDepthItem } from 'components/_v2/profile/condition/JobGroupSelectView';
import Context from 'models/Context';
import { LocationCode } from 'consts/CompanyLocationType';

const GlobalRecommendFrame = styled.div`
  .keywordList {
    display: flex;
    gap: 12px;
    overflow-x: scroll;

    ::-webkit-scrollbar {
          display: none;
      }
    ::-webkit-scrollbar-thumb {
      background: transparent;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }
  
    &:first-child {
      padding-left: 16px;
    }

    &:last-child {
      padding-right: 16px;
    }
    
    .keywordButton {
      flex: none;
      display: flex;
      align-items: center;
      padding: 0 20px;
      width: max-content;
      height: 48px;
      border: 1px solid ${colors.CG_50};
      border-radius: 4px;
      font: ${Fonts.B1_Medium};
      color: ${colors.CG_80};
    }
  }
`;
interface IGlobalRecommend {
  positionListModel?:PositionListModel
  login?:Login
  context?: Context;
  onClickClose: () => void;

}
const GlobalRecommend: FC<IGlobalRecommend> = ({ positionListModel = new PositionListModel(), context = new Context(), login = new Login(), onClickClose }) => {
  const [jobList, setJobList] = useState<any[]>([]);
  const [locationList, setLocationList] = useState<number[]>([]);
  const [salaryLevel, setSalaryLevel] = useState(0);
  const { data: profileConditionsData } = useProfileConditions(!!login.userInfo);
  const [jobGroups, setJobGroups] = useState<TwoDepthItem[]>([]);
  const history = useHistory();

  useEffect(() => {
    if (!context.jobGroupsWithTitles.length) return;
    setJobGroups(context.jobGroupsWithTitles.map((group) => {
      const temp2 = group.jobTitles.map((title) => ({
        code: title.code,
        name: title.name,
        value: { jobGroupCode: group.code, jobTitleCode: title.code },
      }));
      return {
        code: group.code,
        name: group.name,
        children: temp2,
      };
    }));
  }, [context.jobGroupsWithTitles]);
  const shuffleArray = (array:any[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]; // 요소 교환
    }
    return array;
  };

  useEffect(() => {
    const newJobList:any[] = [];
    if (profileConditionsData?.jobGroups) {
      const shuffledJobGroups = shuffleArray(profileConditionsData.jobGroups.flatMap((group) => group.jobTitleNames));
      const selectedJobTitles = shuffledJobGroups.slice(0, 3);

      selectedJobTitles.forEach((jobTitleName) => {
        const jobGroup = profileConditionsData.jobGroups.find((group) => group.jobTitleNames.includes(jobTitleName));
        if (jobGroup) {
          newJobList.push({
            code: jobGroup.jobGroupCode,
            name: jobGroup.jobGroupName,
            children: [{
              code: jobGroup.jobTitleCodes[jobGroup.jobTitleNames.indexOf(jobTitleName)],
              name: jobTitleName,
              value: {
                jobGroupCode: jobGroup.jobGroupCode,
                jobTitleCode: jobGroup.jobTitleCodes[jobGroup.jobTitleNames.indexOf(jobTitleName)],
              },
            }],
          });
        }
      });
      setJobList(newJobList);
    } else {
      const randomGroupIndex = Math.floor(Math.random() * jobGroups.length);
      const selectedGroup = jobGroups[randomGroupIndex];

      if (selectedGroup && selectedGroup.children && selectedGroup.children.length > 0) {
        const randomChildIndex = Math.floor(Math.random() * selectedGroup.children.length);
        const selectedJobTitle = selectedGroup.children[randomChildIndex];
        // 선택된 직무 타이틀을 TwoDepthItem[] 형태로 변환하여 설정
        setJobList([{
          code: selectedGroup.code,
          name: selectedGroup.name,
          children: [selectedJobTitle],
        }]);
      }
    }
    let selectedLocations:number[] = [];

    if (profileConditionsData?.locations) {
      const shuffledLocations = shuffleArray([...profileConditionsData.locations]);
      selectedLocations = shuffledLocations.slice(0, 2);
    } else {
      selectedLocations = [10001, 10009];
    }
    setLocationList(selectedLocations);
    if (profileConditionsData?.salary) {
      setSalaryLevel(profileConditionsData?.salary);
    } else {
      setSalaryLevel(4000);
    }
  }, [login, profileConditionsData, jobGroups]);
  const handleSearch = async (cases:string, item:any) => {
    positionListModel.resetFilter();
    if (cases === 'job') {
      positionListModel.searchFilters.jobTitles = String(item);
      positionListModel.isGlobalSearchedJob = true;
    } else if (cases === 'location') {
      positionListModel.searchFilters.locations = String(item);
      positionListModel.isGlobalSearchedLocation = true;
    } else if (cases === 'salary') {
      positionListModel.searchFilters.minSalary = item;
      positionListModel.searchFilters.maxSalary = 10000;
      positionListModel.isGlobalSearchedSalary = true;
    }
    await positionListModel.loadMatchPositions();
    onClickClose();
    history.push(RoutePaths.position);
  };

  return (
    <GlobalRecommendFrame>
      <div className='keywordList'>
        {
          jobList.map((item) => (
            <button className='keywordButton' onClick={() => handleSearch('job', item.children[0].code)}>
              {`#${item.children[0].name}`}
            </button>
          ))
        }
        {
          locationList.map((item) => (
            <button className='keywordButton' onClick={() => handleSearch('location', item)}>
              {`#${LocationCode(item)}`}
            </button>
          ))
        }
        <button className='keywordButton' onClick={() => handleSearch('salary', salaryLevel)}>
          {`#연봉 ${salaryLevel.toLocaleString()}만원 이상`}
        </button>
      </div>
    </GlobalRecommendFrame>
  );
};

export default inject(injectStore.positionListModel, injectStore.login, injectStore.context)(observer(GlobalRecommend));
