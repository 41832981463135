// created by khj1115 on 2021-07-28

import React from 'react';
import styled from 'styled-components';
import iconShareKakao from 'assets/icon/info/icon_info_share_icon_kakao.png';
import iconShareFacebook from 'assets/icon/info/icon_info_share_icon_facebook.png';
import LazyImage from 'components/common/LazyImage';
import colors from '__designkit__/common/colors';
import { IShareKakaoTalkParams, shareKakaoTalk } from 'utils/KakaoUtils';
import { copyText } from 'utils/ClipboardUtils';
import { IconPageShareURL } from 'consts/assets/icons/iconPages';
import useToast from 'hooks/useToast';

const ShareButtonGroupFrame = styled.div`
  border-top:1px solid ${colors.CG_40};
  padding:24px 0 40px 0;
  .ul-share {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .kakao-share,
    .facebook-share {
      margin-right: 16px;
    }
    .btn-share {
      border-radius: 24px;
      width: 48px;
      height: 48px;
      overflow:hidden;
    }
  }
`;

interface IFacebookShareParams {
  u: string;
  quote: string;
}

interface IWebShareParams {
  title?: string;
  url?: string;
  text?: string;
}

interface IShareButtonGroupProps {
  kakao?: IShareKakaoTalkParams;
  facebook?: IFacebookShareParams;
  webShare?: IWebShareParams;
}

const RoundIconShareLink = styled(IconPageShareURL)`
  border-radius: 24px;
  width: 48px;
  height: 48px;
`;

const ShareButtonGroup: React.FC<IShareButtonGroupProps> = ({
  kakao = {
    title: 'JOBDA',
    description: 'JOBDA',
    mobileWebUrl: document.location.href,
    webUrl: document.location.href,
  },
  facebook = {
    u: document.location.href,
    quote: 'JOBDA',
  },
  webShare = {
    title: 'JOBDA',
    url: document.location.href,
    text: 'JOBDA',
  },
}) => {
  const { setToastObject } = useToast();

  return (
    <ShareButtonGroupFrame>
      <ul className='ul-share'>
        {kakao && (
          <li
            role='presentation'
            className='btn-share kakao-share'
            onClick={() => shareKakaoTalk(kakao)}
          >
            <LazyImage src={iconShareKakao} />
          </li>
        )}
        {facebook && (
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${facebook.u}&quote=${facebook.quote}`}
            target='_blank'
            rel='noopener noreferrer'
            className='facebook-share'
          >
            <li className='btn-share'>
              <LazyImage src={iconShareFacebook} />
            </li>
          </a>
        )}
        <li
          role='presentation'
          className='btn-share'
          onClick={() => {
            copyText(window.location.href);
            setToastObject({ isOpen: true, type: 'SUCCESS', message: '링크가 복사되었습니다.', duration: 1000 });
          }}
        >
          <RoundIconShareLink />
        </li>
      </ul>
    </ShareButtonGroupFrame>
  );
};

export default ShareButtonGroup;
