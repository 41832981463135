import { useQuery } from 'react-query';
import { RemoteBookmarkRepo } from 'repository/bookmark/BookmarkRepo';

const keys = ['bookmark'];

const repo = new RemoteBookmarkRepo();

const fetchBookmarkCompanies = async () => {
  const { bookmarks } = await repo.fetchBookmarkCompanies();
  return bookmarks;
};

const fetchBookmarkJobPostings = async () => {
  const { bookmarks } = await repo.fetchBookmarkJobPostings();
  return bookmarks;
};

const fetchBookmarkPositions = async () => {
  const { bookmarks } = await repo.fetchBookmarkPositions();
  return bookmarks;
};

const fetchBookmarkStatus = async () => {
  const status = await repo.fetchBookmarkStatus();
  return status;
};

export const useBookmarkCompanies = () => {
  return useQuery([...keys, 'companies'], fetchBookmarkCompanies);
};

export const useBookmarkJobPostings = () => {
  return useQuery([...keys, 'job-postings'], fetchBookmarkJobPostings);
};

export const useBookmarkPositions = () => {
  return useQuery([...keys, 'positions'], fetchBookmarkPositions);
};

export const useBookmarkStatus = () => {
  return useQuery([...keys, 'status'], fetchBookmarkStatus);
};
