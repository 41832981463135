import { yupResolver } from '@hookform/resolvers/yup';
import ProfileAttachedFileUrls from 'components/_v2/profile/attachedFile/ProfileAttachedFileUrls';
import ProfileEditTitleComponent from 'components/_v2/profile/ProfileEditTitleComponent';
import JDSaveButton from 'components/_v2/_common/button/JDSaveButton';
import FormProfileValueTitle from 'components/_v2/_common/form/FormProfileValueTitle';
import JDFileUpload from 'components/_v2/_common/input/JDFileUpload';
import useToast from 'hooks/useToast';
import ErrorCode from 'consts/ErrorCodes';
import { useProfileFormDirtyObserver } from 'hooks/useFormDirtyObserver';
import { IProfileAttachFileRq, IProfileAttachFileRs, IProfilePortfolioAttachFileSaveDto } from 'interfaces/_v2/profile/IProfileAttachFileRqRs';
import { IProfileProps } from 'interfaces/_v2/profile/IProfileProps';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import ProfileModel from 'models/_v2/profile/ProfileModel';
import React, { FC, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';

const Frame = styled.div`
    padding: 32px 16px 188px 16px;
`;

const SubFrame = styled.div`
  margin-bottom: 24px;
`;

const attachedFileSchema = yup.object({});

interface IProfileAttachedFileProps extends IProfileProps {
  login?: Login;
  onClose?: () => void;
}

const ProfileAttachedFile:FC<IProfileAttachedFileProps> = ({ onClose, profileModel = new ProfileModel(), login = new Login() }) => {
  const useFormed = useForm<IProfileAttachFileRs>({
    mode: 'onSubmit',
    resolver: yupResolver(attachedFileSchema),
    defaultValues: {
      portfolioAttachFiles: [],
      referenceUrls: [],
      careerAttachFiles: [],
    },
  });
  const { handleSubmit, watch, control, formState, getValues, reset } = useFormed;
  useProfileFormDirtyObserver(formState);
  const { setToastObject } = useToast();

  const onSubmit = useCallback(async (data: any) => {
    try {
      const inputData = getValues();

      const transformed: IProfileAttachFileRq = {
        ...inputData,
        portfolioAttachFiles: inputData.portfolioAttachFiles?.map<IProfilePortfolioAttachFileSaveDto>(({ fileUid }) => ({ fileUid })),
        referenceUrls: inputData.referenceUrls?.map((item) => (item)),
        careerAttachFiles: inputData.careerAttachFiles?.map<IProfilePortfolioAttachFileSaveDto>(({ fileUid }) => ({ fileUid })),
        sourcePage: profileModel.sourcePage,
      };

      if (transformed.portfolioAttachFiles === undefined) transformed.portfolioAttachFiles = [];
      if (transformed.referenceUrls === undefined) transformed.referenceUrls = [];
      if (transformed.careerAttachFiles === undefined) transformed.careerAttachFiles = [];

      await profileModel.saveAttachedFile(transformed);
      profileModel.setIsDirty(false);
      setToastObject({ isOpen: true, type: 'SUCCESS', message: '성공적으로 저장되었습니다.', subMessage: undefined });
      if (onClose) onClose();
    } catch (e: any) {
      switch (e.response?.data?.errorCode) {
        case ErrorCode.B901:
          setToastObject({ isOpen: true, type: 'ERROR', message: '중복되는 경력기술서가 있습니다.' });
          break;
        case ErrorCode.B902:
          setToastObject({ isOpen: true, type: 'ERROR', message: '중복되는 포트폴리오가 있습니다.' });
          break;

        default:
          setToastObject({ isOpen: true, type: 'ERROR', message: '저장에 실패하였습니다.' });
      }
    }
  }, [profileModel]);

  const onError = () => {

  };

  useEffect(() => {
    const init = async () => {
      await profileModel.loadAttachedFile();
      if (profileModel.attachedFile) reset(profileModel.attachedFile);
    };
    init();
    return (() => {

    });
  }, []);

  return (
    <>
      <ProfileEditTitleComponent
        title='첨부 자료를 입력해 주세요.'
        subtitle={`구체적인 결과물은 ${login.userInfo?.name}님의 역량을 자세히 파악할 수 있도록 도와줘요.`}
      />
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <FormProvider {...useFormed}>
          <Frame>
            <FormProfileValueTitle>포트폴리오</FormProfileValueTitle>
            <SubFrame>
              <JDFileUpload
                name='portfolioAttachFiles'
                placeholder='첨부파일을 추가해 주세요.'
                limitCount={20}
                accept='application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation'
                type={['application/pdf', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.slideshow', 'application/vnd.openxmlformats-officedocument.presentationml.presentation']}
              />
            </SubFrame>
            <SubFrame>
              <FormProfileValueTitle>경력기술서</FormProfileValueTitle>
              <JDFileUpload
                name='careerAttachFiles'
                placeholder='첨부파일을 추가해 주세요.'
                limitCount={20}
                accept='application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation'
                type={['application/pdf', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.slideshow', 'application/vnd.openxmlformats-officedocument.presentationml.presentation']}
              />
            </SubFrame>
            <ProfileAttachedFileUrls />
          </Frame>
          <JDSaveButton type='submit' fixed={false} onSubmit={() => {}} />
        </FormProvider>
      </form>
    </>
  );
};

export default inject(injectStore.profileModel, injectStore.login)(observer(ProfileAttachedFile));
