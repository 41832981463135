import Icon from '__designkit__/icon/Icon';
import JDCheckInput, { JD_CHECK_INPUT_TYPE } from 'components/_v2/_common/input/JDCheckInput';
import JobPostingStatusTypeMoreList from 'components/_v2/calendar/JobPostingStatusTypeMoreList';
import RoutePaths from 'consts/RoutePaths';
import Fonts from '__designkit__/common/fonts';
import { StatusTypeText } from 'consts/_v2/calendar/CalendarFilterType';
import Colors from '__designkit__/common/colors';
import ICalendarProps from 'interfaces/_v2/calendar/ICalendarProps';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import CalendarModel from 'models/_v2/CalendarModel';
import { injectStore } from 'models/store';
import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { DateDataFormatMMDDCCUnit } from 'utils/DateUtils';

const Frame = styled.div`
  padding: 20px 16px 12px 16px;
  border-bottom: 1px solid ${Colors.CG_40};
`;

const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .list-day-frame {
    display: flex;
    align-items: center;
    font: ${Fonts.B2_Medium};
    color: ${Colors.CG_70};
  }

  .list-filter-frame {
    display: flex;
    align-items: center;
  }

  .filter-chip {
    margin-right: 8px;

    .jd-input {
      width: 100%;
      margin: 0;
    }

    .filter-label {
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        margin-left: 4px;
        font: ${Fonts.B3_Medium};
        color: ${Colors.CG_80};

        &[aria-selected='true'] {
          font: ${Fonts.B3_Bold};
          color: ${Colors.JOBDA_BLACK};
        }
      }
    }
  }

  .job-posting {
    .jd-input {
      width: 100%;
      margin: 0;
    }

    .job-posting-label {
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font: ${Fonts.B3_Medium};
        color: ${Colors.CG_80};
      }
    }
  }
`;

const CalendarListHeader: FC<ICalendarProps> = ({ calendarModel = new CalendarModel(), login = new Login() }) => {
  const useFormed = useForm({ mode: 'all' });
  // const ref = useRef<HTMLDivElement>(null);
  const { watch, setValue } = useFormed;
  const history = useHistory();

  const handleBookmark = () => {
    if (!login.userInfo) {
      history.push(RoutePaths.login);
    } else {
      calendarModel.bookmarkFilter = !calendarModel.bookmarkFilter;
    }
  };

  return (
    <Frame>
      <FormProvider {...useFormed}>
        <ListHeader>
          <div className='list-day-frame'>{DateDataFormatMMDDCCUnit(calendarModel.showDate)}</div>
          <div className='list-filter-frame'>
            <div className='filter-chip'>
              <JDCheckInput
                type={JD_CHECK_INPUT_TYPE.ROUND_CHIP}
                name='accFilterYn'
                value
                padding='8px 12px 8px 8px'
                defaultChecked={calendarModel.accFilter}
                label={(
                  <div role='presentation' className='filter-label' onClick={() => { calendarModel.accFilter = !calendarModel.accFilter; }}>
                    {calendarModel.accFilter ? <Icon name='business-filled' size={16} /> : <Icon name='business' size={16} />}
                    <span aria-selected={calendarModel.accFilter}>역량검사공고</span>
                  </div>
                )}
              />
            </div>
            <div className='filter-chip'>
              <JDCheckInput
                type={JD_CHECK_INPUT_TYPE.ROUND_CHIP}
                name='bookmarkYn'
                value
                padding='8px 12px 8px 8px'
                defaultChecked={calendarModel.bookmarkFilter}
                label={(
                  <div role='presentation' className='filter-label' onClick={handleBookmark}>
                    {calendarModel.bookmarkFilter ? <Icon name='bookmark-filled' size={16} /> : <Icon name='bookmark' size={16} />}
                    <span aria-selected={calendarModel.bookmarkFilter}>북마크</span>
                  </div>
                )}
              />
            </div>
            <div className='job-posting'>
              <JDCheckInput
                type={JD_CHECK_INPUT_TYPE.ROUND_CHIP}
                name='job-posting-status'
                value
                padding='8px 8px 8px 12px'
                label={(
                  <div role='presentation' className='job-posting-label'>
                    <span>{StatusTypeText[calendarModel.statusFilter]}</span>
                    {watch('job-posting-status') ? <Icon name='arrow-top' size={24} /> : <Icon name='arrow-bottom' size={24} color={Colors.CG_80} />}
                  </div>
                )}
              />
              <JobPostingStatusTypeMoreList isOpen={watch('job-posting-status')} onClickClose={() => setValue('job-posting-status', false)} />
            </div>
          </div>
        </ListHeader>
      </FormProvider>
    </Frame>
  );
};

export default inject(injectStore.calendarModel, injectStore.login)(observer(CalendarListHeader));
