export enum MatchingMessageSenderType {
  COMPANY = 'COMPANY',
  USER = 'USER',
}

export enum MatchingMessageCommandType {
  ACCEPT = 'ACCEPT',
  REFUSE = 'REFUSE',
  SUBMISSION = 'SUBMISSION',
  ADJUST = 'ADJUST'
}

export enum MatchingMessageType {
  OPTIONS = 'OPTIONS',
  SIMPLE = 'SIMPLE',
}

export enum MatchingCommandType {
  INTERVIEW_OFFER = 'INTERVIEW_OFFER',
  INTERVIEW_FAILED = 'INTERVIEW_FAILED',
  INTERVIEW_PASSED = 'INTERVIEW_PASSED',
  INTERVIEW_OFFER_SCHEDULE = 'INTERVIEW_OFFER_SCHEDULE',
  FILE_REQUEST = 'FILE_REQUEST', // 파일 제출 요청
  FILE_SUBMIT = 'FILE_SUBMIT', // 파일 제출 완료
  ACC_REQUEST = 'ACC_REQUEST', // ACC 역량검사 결과 제출
  ACC_LITE_REQUEST = 'ACC_LITE_REQUEST', // ACC 라이트 역량검사 결과 제출
  PHS_REQUEST = 'PHS_REQUEST', // PHS 역량검사 결과 제출
  WRITING_RESUME = 'WRITING_RESUME', // 작성 중인 지원서 확인
  SHOW_SUBMIT_RESUME = 'SHOW_SUBMIT_RESUME', // 지원서 제출 확인
  MATCH_OFFER = 'MATCH_OFFER', // 입사지원 매칭 제안
  SHOW_SUBMIT_PROFILE_RESUME = 'SHOW_SUBMIT_PROFILE_RESUME', // 프로필 지원서 확인
  SHOW_MATCHING_PROFILE = 'SHOW_MATCHING_PROFILE', // 제안받은 프로필 지원서 확인
  SHOW_MATCHING_REPORT = 'SHOW_MATCHING_REPORT', // 매칭 리포트 확인
  INTERVIEW_CANCEL = 'INTERVIEW_CANCEL', // 면접 취소
  SHOW_SUBMITTED_PROFILE = 'SHOW_SUBMITTED_PROFILE', // 제출한 프로필 확인
  INTERVIEW_ACCEPTED = 'INTERVIEW_ACCEPTED', // 면접 제안 수락
}

export enum MatchingInterviewLocationType {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
}

export const MatchingFileTypeList = [
  'image/ai',
  'image/gif',
  'image/jpeg', // .jpg, .jpeg, .jfif, .pjpeg, .pjp
  'image/vnd.microsoft.icon',
  'image/x-icon', // .ico , .cur
  'image/png',
  'image/tiff', // .tif , .tiff
  'video/avi',
  'video/quicktime', // .mov
  'video/mp4', // .mp4 , .m4v, .m4p
  'video/wmv',
  'video/webm', // .webm
  'video/mpeg',
  'audio/mp3', // .mp3
  'audio/webm', // .webm
  'audio/mpeg',
  'video/x-msvideo',
  '.doc',
  '.docx',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'application/pdf',
  'application/msword',
  'application/vnd.ms-powerpoint',
  'application/rtf',
  'application/vnd.ms-excel',
  'application/zip',
  'application/alzip', // alz, egg
  'text/plain',
];

export enum PhsAccType {
  ACC = 'ACC',
  PACC = 'PACC',
  PST = 'PST',
  DCHAMPS = 'DCHAMPS'
}

export enum PhsAccResultType {
  ACC_RESULT_PRIVACY_PROVISION = 'ACC_RESULT_PRIVACY_PROVISION',
  PACC_RESULT_PRIVACY_PROVISION = 'PACC_RESULT_PRIVACY_PROVISION',
  PST_RESULT_PRIVACY_PROVISION = 'PST_RESULT_PRIVACY_PROVISION'

}

export enum PhsAccTypeStr {
  ACC = '역량검사',
  PACC = '개발자 역량검사',
  PST = '개발 구현 능력 검사',
  DCHAMPS = '디챔스'
}
