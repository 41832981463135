import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import DisableScroll from 'components/common/DisableScroll';
import ICalendarProps from 'interfaces/_v2/calendar/ICalendarProps';
import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 20px;
    width: 120px;
    padding: 22px 20px;
    background-color: ${colors.WHITE_100};
    border: 1px solid ${colors.CG_50};
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    top:160px;
    z-index:10000;
    &[aria-hidden=true] {
        visibility: hidden;
    }

    .text {
        display: flex;
        font: ${Fonts.B2_Medium};
        color: ${colors.CG_70};  
    }

    ul {
        li:not(:last-child) {
            margin-bottom: 20px;
        }
    }
    
`;

interface IPassboardDetailMoreListProps extends ICalendarProps {
    isOpen: boolean;
    onClickClose: () => void;
    afterDelete?: () => void;
    afterEdit?: () => void;
}

const PassboardDetailMoreList: FC<IPassboardDetailMoreListProps> = ({ isOpen, onClickClose, afterDelete, afterEdit }) => {
  const wrapperRef = useRef<any>(null);
  const [visibleOpen, setVisibleOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      setVisibleOpen(true);
    }
  }, [isOpen]);

  const useOutsideClicker = (ref:any) => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setVisibleOpen(false);
        onClickClose();
      }
    }
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  useOutsideClicker(wrapperRef);

  const handleEditClick = (event: React.MouseEvent) => {
    event.stopPropagation(); // 이벤트 버블링 중단
    if (afterEdit) afterEdit();
    onClickClose();
  };
  const handleDeleteClick = () => {
    if (afterDelete) afterDelete();
    onClickClose();
  };
  return (
    <>
      {visibleOpen && (
      <Frame ref={wrapperRef} aria-hidden={!isOpen}>
        <DisableScroll />
        <ul>
          <li role='presentation' onClick={(event) => handleEditClick(event)} className='text'>수정</li>
          <li role='presentation' onClick={() => handleDeleteClick()} className='text'>삭제</li>
        </ul>
      </Frame>
      )}
    </>
  );
};

export default PassboardDetailMoreList;
