import React from 'react';

import classnames from 'classnames/bind';
import styles from './intro.module.scss';

const cx = classnames.bind(styles);

const Intro = () => (
  <div className={cx('wrap')}>
    <span className={cx('hidden')}>D:CHAMPS 나의 개발 순위 확인부터 스카웃 제안까지! sudo, 1등하고 맥북 받아줘</span>
  </div>
);

export default Intro;
