import { PushNotifications } from '@capacitor/push-notifications';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';
import NoticeUpdateResultModal, { onOffType } from 'components/NoticeUpdateResultModal';
import ToggleSwitch from 'components/ToggleSwitch';
import NoticeTemplateModal, { NoticeType } from 'components/_v2/_common/modals/NoticeTemplateModal';
import PushNoticeModal from 'components/_v2/_common/modals/PushNoticeModal';
import useToast from 'hooks/useToast';
import ILoginPageProps from 'interfaces/props/ILoginPageProps';
import { DateTime } from 'luxon';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import MobileStore from 'store/mobileStore';
import styled from 'styled-components';
import { DATE_FORMAT_YYYY_MM_DD_HH_MM_SS } from 'utils/DateUtils';

import { registerPlugin } from '@capacitor/core';

export interface AndroidPostNotificationsPermissionPlugin {
  areNotificationsEnabled(): Promise<{ enabled: boolean }>;
}

const AndroidPostNotificationsPermission = registerPlugin<AndroidPostNotificationsPermissionPlugin>('AndroidPostNotificationsPermission', {});

const Frame = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:stretch;
  .frame-row {
    padding:0 24px;
    margin-top:32px;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    .frame-title {
      flex:1;
      font: ${Fonts.B1_Medium};
      color:${colors.CG_90};
    }
  }
  .frame-detail{
  padding: 0 24px;
  margin-top: 16px;
  display:flex;
  font: ${Fonts.B3_Medium};
  color:${colors.CG_70}
}
  .frame-modal {
    display:block;
    width:304px;
  }
`;

const MyPageNotice:FC<ILoginPageProps> = ({ login = new Login() }) => {
  const [SMSOnModalVisible, setSMSOnModalVisible] = useState<boolean>(false);
  const [SMSOffModalVisible, setSMSOffModalVisible] = useState<boolean>(false);
  const [EMAILOnModalVisible, setEMAILOnModalVisible] = useState<boolean>(false);
  const [EMAILOffModalVisible, setEMAILOffModalVisible] = useState<boolean>(false);
  const [PUSHOffModalVisible, setPUSHOffModalVisible] = useState<boolean>(false);
  const [userNotificationPermission, setUserNotificationPermission] = useState<boolean>(false);
  const [updateType, setUpdateType] = useState<NoticeType>(NoticeType.SMS);
  const [updateOnOff, setUpdateOnOff] = useState<onOffType>('ON');
  const [updateResultModalVisible, setUpdateResultModalVisible] = useState<boolean>(false);
  const { setToastObject } = useToast();
  useEffect(() => {
    login.loadUserDefault();
  }, []);

  useEffect(() => {
    login.loadMarketingConsent();
  }, [login]);

  useEffect(() => {
    // 설정 변경하고 앱 화면으로 돌아왔을때 실시간 변화 확인
    document.addEventListener('visibilitychange', checkNotificationPermission);
    checkNotificationPermission();
    return () => {
      document.removeEventListener('visibilitychange', checkNotificationPermission);
    };
  }, []);

  const checkNotificationPermission = async () => {
    const permStatus = await PushNotifications.checkPermissions();
    let response = { enabled: false };
    try {
      response = await AndroidPostNotificationsPermission.areNotificationsEnabled();
    } catch (e) {
      console.error(e);
    }

    if (MobileStore.currentPlatform === 'android') {
      if (response.enabled) setUserNotificationPermission(true);
      else setUserNotificationPermission(false);
    } else if (permStatus.receive === 'granted') setUserNotificationPermission(true);
    else setUserNotificationPermission(false);
  };

  const openSettings = async () => {
    if (MobileStore.currentPlatform === 'ios') {
      await NativeSettings.openIOS({ option: IOSSettings.App });
    } else {
      await NativeSettings.openAndroid({ option: AndroidSettings.ApplicationDetails });
    }
  };

  return (
    <Frame>
      <div className='frame-row'>
        <div className='frame-title'>SMS 알림 설정</div>
        {/* //TODO: switch 동작 업데이트가 좀 이상한데..나중에 봐야할듯. */}
        <ToggleSwitch
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked)
              if (!login.userDefault?.mobile) setToastObject({ isOpen: true, type: 'ERROR', message: '핸드폰 인증 완료 후, 설정 가능합니다' });
              else setSMSOnModalVisible(true);
            else
              setSMSOffModalVisible(true);
          }}
          checked={login.marketingConsentRs?.smsYn}
        />
      </div>
      <div className='frame-row'>
        <div className='frame-title'>이메일 알림 설정</div>
        <ToggleSwitch
          onChange={(e:ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked)
              setEMAILOnModalVisible(true);
            else
              setEMAILOffModalVisible(true);
          }}
          checked={login.marketingConsentRs?.emailYn}
        />
      </div>
      { MobileStore.isMobile && (
      <div className='frame-row'>
        <div className='frame-title'>푸시 알림 설정</div>
        <ToggleSwitch
          onChange={() => {
            setPUSHOffModalVisible(true);
          }}
          checked={userNotificationPermission}
        />

      </div>
      )}
      <NoticeTemplateModal
        type={NoticeType.SMS}
        onOff='ON'
        isOpen={SMSOnModalVisible}
        onOk={async () => {
          try {
            if (!(await login.updateNotificationSettings('SMS', true)))
              throw new Error('failed');
            setUpdateType(NoticeType.SMS);
            setUpdateOnOff('ON');
            setUpdateResultModalVisible(true);
          } catch (e: any) {
            console.error(e);
            if (e.response.data.errorCode === 'B901') setToastObject({ isOpen: true, type: 'ERROR', message: '핸드폰 인증 완료 후, 설정 가능합니다' });
            setToastObject({ isOpen: true, type: 'ERROR', message: '변경에 실패했습니다. 다시 시도해 주세요.' });
          } finally {
            setSMSOnModalVisible(false);
          }
        }}
        onCancel={() => setSMSOnModalVisible(false)}
      />
      <NoticeTemplateModal
        type={NoticeType.SMS}
        onOff='OFF'
        isOpen={SMSOffModalVisible}
        onOk={async () => {
          try {
            if (!(await login.updateNotificationSettings('SMS', false)))
              throw new Error('failed');
            setUpdateType(NoticeType.SMS);
            setUpdateOnOff('OFF');
            setUpdateResultModalVisible(true);
          } catch (e) {
            console.error(e);
            alert('다시 시도해 주세요.');
          } finally {
            setSMSOffModalVisible(false);
          }
        }}
        onCancel={() => setSMSOffModalVisible(false)}
      />
      <NoticeTemplateModal
        type={NoticeType.EMAIL}
        onOff='ON'
        isOpen={EMAILOnModalVisible}
        onOk={async () => {
          try {
            if (!(await login.updateNotificationSettings('EMAIL', true)))
              throw new Error('failed');
            setUpdateType(NoticeType.EMAIL);
            setUpdateOnOff('ON');
            setUpdateResultModalVisible(true);
          } catch (e) {
            console.error(e);
            alert('다시 시도해 주세요.');
          } finally {
            setEMAILOnModalVisible(false);
          }
        }}
        onCancel={() => setEMAILOnModalVisible(false)}
      />
      <NoticeTemplateModal
        type={NoticeType.EMAIL}
        onOff='OFF'
        isOpen={EMAILOffModalVisible}
        onOk={async () => {
          try {
            if (!(await login.updateNotificationSettings('EMAIL', false)))
              throw new Error('failed');
            setUpdateType(NoticeType.EMAIL);
            setUpdateOnOff('OFF');
            setUpdateResultModalVisible(true);
          } catch (e) {
            console.error(e);
            alert('다시 시도해 주세요.');
          } finally {
            setEMAILOffModalVisible(false);
          }
        }}
        onCancel={() => setEMAILOffModalVisible(false)}
      />
      <PushNoticeModal
        isOpen={PUSHOffModalVisible}
        onOk={() => {
          openSettings();
          setPUSHOffModalVisible(false);
        }}
        onCancel={() => setPUSHOffModalVisible(false)}
        onOff={userNotificationPermission ? 'OFF' : 'ON'}
      />
      <NoticeUpdateResultModal
        isOpen={updateResultModalVisible}
        type={updateType}
        onOff={updateOnOff}
        dateTime={DateTime.local().toFormat(DATE_FORMAT_YYYY_MM_DD_HH_MM_SS)}
        onOk={() => setUpdateResultModalVisible(false)}
      />
    </Frame>
  );
};

export default inject(injectStore.login)(observer(MyPageNotice));
