// thanks to 지경민님

import colors from '__designkit__/common/colors';
import { fonts } from '__designkit__/common/fonts';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

const SliderFrame = styled.div<{ sliderPercent: number }>`
.slider {
  position: relative;
  height: 30px;
  padding: 10px 16px;

  &.disabled {
    opacity: 0.5;

    .handle {
      border-color:${colors.CG_80}; 
      background-color:${colors.WHITE_100};

      &:hover {
        box-shadow: none;
      }
    }
  }
}



.wrap {
  position: relative;
  height: 10px;
}

.railArea,
.trackArea,
.handleArea,
.markArea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 5px;
  cursor: pointer;
}

.railArea {
  border-radius: 6px;
  background-color:${colors.CG_50};

  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 5px;
    border-radius: 6px 0 0 6px;
    background-color: ${colors.G_100};
    content: '';
  }
}

// 1,000만원 - 1억원 이상 - 단위
.markArea {
  top: auto;
  right: 20px;
  bottom: -1px;
  left: 20px;
  width: auto;
  height: 16px;
  transform: translateY(100%);
  cursor: auto;

  .mark {
    position: absolute;
    top: 0;
    width: max-content;
    ${fonts.NOTO_11_500};
    line-height: 14px;
    letter-spacing: -0.07px;
    color:${colors.CG_60};
    transform: translateX(-50%);
  }
}



.dotArea,
.trackWrap,
.handleWrap,
.markWrap {
  position: relative;
  height: 100%;
}


.dot {
  &:before,
  &:after {
    position: absolute;
    top: 50%;
    left: 0;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color:${colors.WHITE_100};
    transform: translate(-50%, -50%);
    content: '';
    z-index: 100;
  }

  &.type1 {
    &:before {
      left: 0;
      background-color: ${colors.WHITE_100};
    }

    &:after {
      left: 11.1111%;

      ${(props) => props.sliderPercent > 11.1111 && css`
        background-color: ${colors.WHITE_100};
      `}
    }
  }

  &.type2 {
    &:before {
      left: 22.2222%;
      ${(props) => props.sliderPercent > 22.2222 && css`
        background-color: ${colors.WHITE_100};
      `}
    }

    &:after {
      left: 33.3333%;
      ${(props) => props.sliderPercent > 33.3333 && css`
        background-color: ${colors.WHITE_100};
      `}
    }
  }

  &.type3 {
    &:before {
      left: 44.4444%;
      ${(props) => props.sliderPercent > 44.4444 && css`
        background-color: ${colors.WHITE_100};
      `}
    }

    &:after {
      left: 55.5556%;
      ${(props) => props.sliderPercent > 55.5556 && css`
        background-color: ${colors.WHITE_100};
      `}
    }
  }

  &.type4 {
    &:before {
      left: 66.6667%;
      ${(props) => props.sliderPercent > 66.6667 && css`
        background-color: ${colors.WHITE_100};
      `}
    }

    &:after {
      left: 77.7778%;
      ${(props) => props.sliderPercent > 77.7778 && css`
        background-color: ${colors.WHITE_100};
      `}
    }
  }

  &.type5 {
    &:before {
      left: 88.8889%;
      ${(props) => props.sliderPercent > 88.8889 && css`
        background-color: ${colors.WHITE_100};
      `}
    }

    &:after {
      left: 100%;
    }
  }
}

.track {
  width: 0;
  height: 100%;
  background-color: ${colors.JOBDA_BLACK};
  opacity: 0.8;
  border-radius: 5.72186px;
  position: relative;
  left: -5px;
}

.handle {
  position: absolute;
  top: 0;
  width: 24px;
  height: 24px;
  border: 2px solid ${colors.CG_80};
  border-radius: 50%;
  background-color: ${colors.JOBDA_WHITE};
  transform: translate(-50%, -7px);
  z-index: 101;

}

.tooltip {
  position: absolute;
  top: -35px;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  min-height: 26px;
  padding: 4px 12px;
  border-radius: 8px;
  background-color: ${colors.c474747};
  ${fonts.NOTO_13_700};
  line-height: 18px;
  color: ${colors.WHITE_100};
  transform: translateX(-50%);

  &.hide {
    display: none;
  }

  &::after {
    position: absolute;
    bottom: 0px;
    left: calc(50% - 5px);
    width: 10px;
    height: 5px;
    background-color: ${colors.c474747};
    transform: rotate(-135deg);
    user-select: none;
    content: '';
  }
}

.input {
  display: none;
}

`;

interface IMarkProps {
  max: number;
  min: number;
  marks?: Record<number, ReactNode | string>;
}

const Mark = ({ max, min, marks }: IMarkProps) => {
  if (!marks) return null;

  const mark = Object.entries(marks).map(([key, value], index) => {
    const range = max - min;
    const left = (Number(key) - min) / range * 100;

    return (
      <div key={key} className='mark' style={{ left: `${left}%` }}>
        {value}
      </div>
    );
  });

  return <>{mark}</>;
};

interface ISliderProps {
  disabled?: boolean;
  marks?: Record<number, ReactNode>;
  max: number;
  min: number;
  toolTip?: (value: number) => ReactNode;
  tooltipVisible?: boolean;
  value: number;
  step: number;
  onChange: (value: number) => void;
}

const JDSlider = (
  {
    disabled = false,
    marks,
    max,
    min,
    toolTip,
    tooltipVisible = true,
    value,
    step,
    onChange,
  }: ISliderProps,
) => {
  const sliderValue = (value: number) => {
    if (value <= min) {
      return min;
    } if (value >= max) {
      return max;
    }
    return value;
  };

  const railEl = useRef<HTMLDivElement>(null);
  const handleEl = useRef<HTMLDivElement>(null);
  const [sliderPercent, setSliderPercent] = useState((sliderValue(value) - min) / (max - min) * 100);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [shiftHandleX, setShiftHandleX] = useState(0); // 클릭시 마우스 위치와 핸들의 위치 차이

  const handleSlide = (left: number) => {
    if (!railEl.current) return;
    if (left < 0) left = 0;

    const rightEdge = railEl.current.offsetWidth;
    if (left > rightEdge) left = rightEdge;

    // 소수점 자리수
    const fixedNum = (`${step}`).split('.')[1]?.length || 0;

    // 현재 슬라이더 퍼센트
    const percent = (left / rightEdge) * 100;

    // 소수점 step 처리를 위한 보정값
    const decimalOffset = Math.pow(10, fixedNum);

    // 한 스텝당 이동하는 슬라이더의 퍼센트
    const stepRange = (max - min) / step;

    // 퍼센트에서 relativeValue 구하기 위한 보정값
    const offset = stepRange / decimalOffset / 100;

    // 퍼센트에서 환산된 상대적 위치값
    const relativeValue = Number((percent * offset).toFixed(fixedNum));

    // 마우스 위치에 따라 계산된 슬라이더 위치
    const nextLeft = relativeValue / offset;

    // 실제 value 값
    const newResult = Number(((min + relativeValue * step * decimalOffset)).toFixed(fixedNum));

    if (newResult > max) return;
    onChange(newResult);
    setSliderPercent(nextLeft);
  };

  const onTouchUp = () => {
    setIsMouseDown(false);
    document.removeEventListener('touchmove', onTouchMove);
    document.removeEventListener('touchend', onTouchUp);
  };

  const onTouchMove = (e: TouchEvent) => {
    if (!railEl.current) return;
    const left = e.touches[0].clientX - shiftHandleX - railEl.current.getBoundingClientRect().left;
    handleSlide(left);
  };

  // 클릭 했을 때 핸들의 처음 위치를 정해주는 함수
  const setNewPosition = async (clientX: number) => {
    if (!railEl.current) return;
    const shiftRailX = clientX - railEl.current.getBoundingClientRect().left;
    await handleSlide(shiftRailX);

    // 클릭 했을 때 클라이언트의 클릭 위치에 대한 핸들의 상대적 위치가 정해짐
    // 핸들의 상대적 위치는 핸들이 옮겨지고 결정되어야함
    if (!handleEl.current) return;
    setShiftHandleX(clientX - handleEl.current.getBoundingClientRect().left);
  };

  const handleOnTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    if (disabled) return;
    e.preventDefault();
    setIsMouseDown(true);
    setNewPosition(e.touches[0].clientX);
    document.addEventListener('touchmove', onTouchMove);
    document.addEventListener('touchend', onTouchUp);
  };

  const toolTipEl = toolTip ? toolTip(value) : value;

  useEffect(() => {
    setSliderPercent((sliderValue(value) - min) / (max - min) * 100);
  }, [value]);

  return (
    <SliderFrame sliderPercent={sliderPercent}>
      <div className={`slider ${disabled ? 'disabled' : ''}`}>
        <div
          className='wrap'
          onTouchStart={(e) => handleOnTouchStart(e)}
        >
          <div ref={railEl} className='railArea'>
            <div className='dotArea'>
              <span className='dot type1' />
              <span className='dot type2' />
              <span className='dot type3' />
              <span className='dot type4' />
              <span className='dot type5' />
            </div>
          </div>
          <div className='trackArea'>
            <div className='trackWrap'>
              <div className='track' style={{ width: `${sliderPercent}%` }} />
            </div>
          </div>
          <div className='handleArea'>
            <div className='handleWrap'>
              <div
                ref={handleEl}
                className={`handle ${isMouseDown ? 'active' : ''}`}
                // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
                role='slider'
                style={{ left: `${sliderPercent}%` }}
              >
                {tooltipVisible && <div className='tooltip'>{toolTipEl}</div>}
              </div>
            </div>
          </div>
        </div>

        <div className='markArea'>
          <div className='markWrap'>
            <Mark
              min={min}
              max={max}
              marks={marks}
            />
          </div>
        </div>
      </div>
    </SliderFrame>
  );
};

export default JDSlider;
