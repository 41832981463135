import Footer from 'components/_v2/_common/Footer';
import JDScrollToTopButton from 'components/_v2/_common/button/JDScrollToTopButton';
import { PositionMetaTag } from 'components/_v2/_common/meta/MetaTag';
import PositionAd from 'components/_v2/position/PositionAd';
import PositionList from 'components/_v2/position/PositionList';
import PositionCurationList from 'components/_v2/position/curation/PositionCurationList';
import PositionCurationTab, { IPositionCurationTab } from 'components/_v2/position/curation/PositionCurationTab';
import RoutePaths from 'consts/RoutePaths';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import PositionCurationModel, { CurationDefaultType } from 'models/_v2/PositionCurationModel';
import { injectStore } from 'models/store';
import { FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import MobileStore from 'store/mobileStore';

const Position: FC<IPositionCurationTab> = ({ login = new Login(), positionCurationModel = new PositionCurationModel() }) => {
  const { pathname } = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (login.userInfo === null) {
      history.push(RoutePaths.position);
      return;
    }
    if (pathname === RoutePaths.position) positionCurationModel.curationSelect = CurationDefaultType.ALL;
    else if (pathname === RoutePaths.position_recommend) positionCurationModel.curationSelect = CurationDefaultType.RECOMMEND;
  }, [pathname]);

  return (
    <>
      <PositionMetaTag />
      <PositionAd />
      <PositionCurationTab />
      {
        positionCurationModel.curationSelect === CurationDefaultType.ALL ? <PositionList /> : <PositionCurationList />
      }
      {!MobileStore.isMobile && <Footer isNavi={false} corpInfoFoled={false} />}
      <JDScrollToTopButton />
    </>
  );
};

export default inject(injectStore.login, injectStore.positionCurationModel)(observer(Position));
