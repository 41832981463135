import { Button, ButtonMode } from '__designkit__/button/Button';
import Colors from '__designkit__/common/colors';
import ProfileEditTitleComponent from 'components/_v2/profile/ProfileEditTitleComponent';
import JobGroupSelectView, { TwoDepthItem } from 'components/_v2/profile/condition/JobGroupSelectView';
import { ConditionRegisterType } from 'consts/_v2/profile/ProfileType';
import useQueryParams from 'hooks/useQueryParams';
import useToast from 'hooks/useToast';
import { IPutConditionJobGroupsRq, IRqJobGroup, IRsJobGroups, ISelectedJob } from 'interfaces/_v2/profile/IConditionRqRs';
import IComponentProps from 'interfaces/props/IComponentProps';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import { injectStore } from 'models/store';
import useProfileConditions, { useFetchSaveProfileConditionsJobGroups } from 'query/profiles/useProfileConditionsQuery';
import React, { FC, useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

const TabFrame = styled.div<{isResumeModal?:boolean}>`
  width: 100%;
  height: 100%;
  background-color: ${Colors.WHITE_100};
 `;

const Frame = styled.div`
  width: 100%;
  height: calc(100% - 88px);
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;
const BtnFrame = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 0 16px;
  height: fit-content;
  position: fixed;
  bottom: env(safe-area-inset-bottom);
  height: 78px;
  background-color: ${Colors.WHITE_100};
  z-index: 100000;
`;
const ButtonFrame = styled.div`
  position: fixed;
  bottom: env(safe-area-inset-bottom);
  width: 100%;
  height: 78px;
  padding: 16px;
  border-top: 1px solid ${Colors.CG_40};
  background-color: ${Colors.WHITE_100};
`;
interface IJobTabProps extends IComponentProps {
  context?: Context;
  hidden?: boolean;
  jobGroupData?:IRsJobGroups[];
  setConditionRegisterType: (type: ConditionRegisterType) => void;
  isResumeModal?: boolean;
  setStartChecking?: (startChecking: boolean) => void;
}

const JobTab: FC<IJobTabProps> = ({ setStartChecking, jobGroupData, isResumeModal, context = new Context(), hidden, setConditionRegisterType }) => {
  const defaultValues:IPutConditionJobGroupsRq = { jobGroups: [], sourcePage: '' };
  const [init, setInit] = useState(false);
  const [jobGroups, setJobGroups] = useState<TwoDepthItem[]>([]);
  const { sourcePage } = useQueryParams();
  const { setToastObject } = useToast();
  const { refetch, data: profileConditionsData } = useProfileConditions(true);
  const saveSuccess = async () => {
    await refetch();
    if (setStartChecking)setStartChecking(true);
    setToastObject({ type: 'SUCCESS', isOpen: true, message: '성공적으로 저장되었습니다.' });
    setInit(false);
    setConditionRegisterType(ConditionRegisterType.LIST);
  };

  const { mutate: saveProfileJobConditions } = useFetchSaveProfileConditionsJobGroups(saveSuccess);

  const formMethods = useForm({
    mode: 'onSubmit',
    defaultValues,
  });
  const { register, watch, reset, getValues, formState } = formMethods;

  const getIsDirty = () => formState.isDirty;
  useEffect(() => {
    if (jobGroupData && watch('jobTitles', []) && !init) {
      const newValues: any = {};
      jobGroupData.forEach((jobGroup) => {
        jobGroup.jobTitleCodes.forEach((jobTitleCode) => {
          newValues[`jobTitles[${jobTitleCode}]`] = { jobGroupCode: jobGroup.jobGroupCode, jobTitleCode };
        });
      });
      reset({ ...getValues(), ...newValues });
      setInit(true);
    }
  }, [reset, watch, init, jobGroupData, watch('jobTitles'), getValues, profileConditionsData]);

  useEffect(() => {
    if (!context.jobGroupsWithTitles.length) return;
    setJobGroups(context.jobGroupsWithTitles.map((group) => {
      const temp2 = group.jobTitles.map((title) => ({
        code: title.code,
        name: title.name,
        value: { jobGroupCode: group.code, jobTitleCode: title.code },
      }));
      return {
        code: group.code,
        name: group.name,
        children: temp2,
      };
    }));
  }, [context.jobGroupsWithTitles]);

  const onClickSaveBtn = async () => {
    const temp: IRqJobGroup[] = [];
    const jobTitlesWatched = watch('jobTitles', []) as ISelectedJob[];

    jobTitlesWatched
      .filter((jobItem: boolean | ISelectedJob) => jobItem)
      .forEach((jobItem : ISelectedJob) => {
        const index = temp.findIndex((jobGroup) => jobGroup.jobGroupCode === jobItem.jobGroupCode);
        if (index !== -1) {
          temp[index].jobTitleCodes.push(jobItem.jobTitleCode);
        } else {
          temp.push({
            jobGroupCode: jobItem.jobGroupCode,
            jobTitleCodes: [jobItem.jobTitleCode],
          });
        }
      });
    if (temp.length > 0) {
      const putConditionJobGroupsRq: IPutConditionJobGroupsRq = {
        jobGroups: temp,
        sourcePage: sourcePage || 'PROFILE', // sourcePage가 undefined일 경우 null을 할당합니다.
      };
      saveProfileJobConditions(putConditionJobGroupsRq, { onSuccess: saveSuccess });
    } else {
      setToastObject({ type: 'ERROR', isOpen: true, message: '저장에 필요한 정보를 작성해 주세요.' });
    }
  };
  if (hidden) return null;
  return (
    <TabFrame isResumeModal={isResumeModal}>
      <FormProvider {...formMethods}>
        <input name='jobGroups' ref={register} aria-hidden />
        <ProfileEditTitleComponent
          title='희망하는 직군 · 직무를 모두 선택해 주세요.'
          subtitle='직군·직무 선택에 따라 추천되는 포지션이 달라져요.'
          divider={false}
        />
        <Frame>
          <JobGroupSelectView name='jobTitles' jobGroups={jobGroups} />
        </Frame>
      </FormProvider>
      {
        isResumeModal
          ? (
            <BtnFrame>
              <Button label='이전' buttonMode={ButtonMode.DEFAULT} size='large' outLined onClick={() => setConditionRegisterType(ConditionRegisterType.LIST)} />
              <Button label='저장하기' buttonMode={ButtonMode.PRIMARY} size='large' onClick={onClickSaveBtn} />
            </BtnFrame>
          )
          : (
            <ButtonFrame>
              <Button label='저장' buttonMode={ButtonMode.PRIMARY} size='large' onClick={onClickSaveBtn} />
            </ButtonFrame>
          )
      }

    </TabFrame>
  );
};

export default inject(injectStore.context)(observer(JobTab));
