/* eslint-disable react-hooks/exhaustive-deps */
import colors from '__designkit__/common/colors';
import Fonts, { fonts, lineHeights } from '__designkit__/common/fonts';
import { JDAInputFrameBasic, JDAInputFrameMessage } from 'consts/style/mixins';
import { numberValidator } from 'consts/ValidationRule';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useFormContext, UseFormMethods } from 'react-hook-form';
import styled from 'styled-components/macro';

const Frame = styled.div`
  margin-top:8px;
  margin-bottom:8px;
  .input-frame{
    ${JDAInputFrameBasic()};
    
    >div{
      display:flex;
      align-items: center;
      position:absolute;
      top:18%;
      right:16px;
      .timer{
        display:block;
        background:none;
        ${fonts.NOTO_11_400};
        color:${colors.ERROR};
        ${lineHeights.LINEHEIGHT_1_50};
        margin-right:12px;
      }
      .action-btn{
        display:block;
        min-width:68px;
        background:${colors.G_100};
        font: ${Fonts.B2_Bold_P};
        border-radius:4px;
        padding: 6px 10px 8px;
        color:${colors.WHITE_100};
        :disabled{
          opacity: 0.5;
        }
      }
    }
  }
  .message{
   ${JDAInputFrameMessage()};
  }
`;
interface IJDATimerInput extends IComponentProps {
  fieldRef?: React.RefObject<HTMLInputElement | null>;
  count?: number;
  onTimerEnd?: () => void;
  name: string;
  isRunning: boolean;
  defaultMessage?: string
  customValidator: any;
  type?: string;
  disabled?: boolean;
  defaultValue?: string;
  useformed?: UseFormMethods<Record<string, any>>;
  className?: string;
  onClickInnerBtn: any
}

const JDTimerConfirmInput = forwardRef((props: IJDATimerInput, ref) => {
  const { fieldRef, name, defaultMessage, count, isRunning = true, customValidator, type, disabled, defaultValue, onClickInnerBtn, onTimerEnd, className, ...rest } = props;
  const useFormed = useFormContext();
  const { register, errors, watch } = useFormed;
  const [inputValue, setInputValue] = useState<string>('');
  const countRef = useRef(count || 180);
  const [counterStr, setCounterStr] = useState<string>('');
  const timerRef = useRef<any>();

  useEffect(() => {
    setCounterStr(new Date((countRef.current) * 1000).toISOString().substr(14, 5));
    const timer = setInterval(() => {
      if (isRunning && countRef.current > 0) {
        countRef.current -= 1;
        setCounterStr(new Date((countRef.current) * 1000).toISOString().substr(14, 5));
      } else {
        clearInterval(timer);
        if (onTimerEnd && countRef.current === 0) onTimerEnd();
      }
    }, 1000);
    timerRef.current = timer;
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (!isRunning)clearInterval(timerRef.current);
  }, [isRunning]);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (type === 'tel') {
      if (numberValidator(e.currentTarget.value)) {
        setInputValue(e.currentTarget.value);
      } else {
        e.currentTarget.value = inputValue;
      }
    }
    if (props.onChange) {
      props.onChange();
    }
  };

  return (
    <Frame className={`jda-input ${className || ''}`}>
      <div className={`input-frame ${errors[name] === undefined}`}>
        <input
          type={type}
          name={name}
          ref={function (innerRef) {
            if (fieldRef !== undefined) (fieldRef as any).current = innerRef;
            (register(innerRef, customValidator));
          }}
          className={`${watch(name) ? 'value' : ''}`}
          onChange={onChangeHandler}
          disabled={!!disabled}
          defaultValue={defaultValue}
          {...rest}
        />
        <div>
          <span className='timer'>{counterStr}</span>
          <button
            type='button'
            disabled={errors[name] || watch(name) === '' || disabled}
            className='action-btn'
            onClick={() => onClickInnerBtn && onClickInnerBtn()}
          >
            확인
          </button>
        </div>
      </div>
      { (defaultMessage || errors[name]) && <h4 className={`message ${errors[name] === undefined}`}>{errors[name] ? (errors[name] as any).message : defaultMessage && defaultMessage}</h4>}
    </Frame>
  );
});

export default JDTimerConfirmInput;
