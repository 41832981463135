export const JobSearchStatus = {
  ACTIVE_SEARCH: 'ACTIVE_SEARCH', // 현재 취업/이직을 준비 중이에요
  OPEN_TO_OFFERS: 'OPEN_TO_OFFERS', // 좋은 제안이 있으면 받고 싶어요.
} as const;

export type JobSearchStatusType = keyof typeof JobSearchStatus;

export enum JobSearchStatusTypeText {
    ACTIVE_SEARCH = '적극적으로 취업/이직을 준비 중이에요.',
    OPEN_TO_OFFERS = '좋은 제안이 있으면 받고 싶어요.',
}
