import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { inject, observer } from 'mobx-react';
import store, { injectStore } from 'models/store';
import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { LocationCode } from 'consts/CompanyLocationType';
import useProfileConditions from 'query/profiles/useProfileConditionsQuery';
import { ProfileAuditItemType } from 'consts/_v2/profile/ProfileType';
import { useInsightResumeAutoSave } from 'query/resume/useResumeQuery';
import IconDone from 'assets/_v2/preferred/icon_done_green_fill.svg';
import ProfileCareersModel from 'models/_v2/profile/ProfileCareersModel';
import ProfileAPI from 'api/profileAPI';
import { getTotalCareerPeriodText } from '../profile/careers/ProfileCareersView';

const Frame = styled.div`
  animation: slide .5s ease-out forwards;

  @keyframes slide {
    0% {
      transform: translateY(10%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  .step-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 24px 0;
    text-align: center;
    font: ${Fonts.H4_Bold};
    color: ${Colors.JOBDA_BLACK};
    
    .title-icon {
      margin-bottom: 12px;
      width: 40px;
      height: 40px;
    }
  }

  .result-frame {
    padding: 32px 24px;
    border-radius: 12px;
    border: 1px solid ${Colors.CG_40};
    font: ${Fonts.B1_Bold_P};
    color: ${Colors.JOBDA_BLACK};

    .result-text {
      font: ${Fonts.B1_Bold_P};
      color: ${Colors.G_200};
    }
  }

  .working-preference {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid ${Colors.CG_40};
    font: ${Fonts.B2_Medium_P};
    color: ${Colors.CG_60};
  }

  .notice {
    margin-top: 12px;
    text-align: center;
    font: ${Fonts.B2_Medium};
    color: ${Colors.CG_60};
  }
`;

const FormFrame = styled.div`
`;

const SalaryText = styled.div<{edit?:boolean}>`
`;

export interface IProfileCareersView {
  profileCareersModel?: ProfileCareersModel;
}

const PreferredResult = ({ profileCareersModel = new ProfileCareersModel() }) => {
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const { data: profileConditionsData } = useProfileConditions(true);
  const { data: insightResumeAutoSaveData, refetch: refetchAutoSave } = useInsightResumeAutoSave(true);

  const checkAudit = (caseType:ProfileAuditItemType) => {
    switch (caseType) {
      case ProfileAuditItemType.PROFILE_JOB_TITLE:
        return profileConditionsData?.auditItems?.includes(ProfileAuditItemType.PROFILE_JOB_TITLE);
      case ProfileAuditItemType.PROFILE_LOCATION:
        return profileConditionsData?.auditItems?.includes(ProfileAuditItemType.PROFILE_LOCATION);
      case ProfileAuditItemType.MATCHING_CONDITION_SALARY:
        return profileConditionsData?.auditItems?.includes(ProfileAuditItemType.MATCHING_CONDITION_SALARY);
      default:
        return false;
    }
  };

  const [totalCareers, setTotalCareers] = useState<string>('');
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  // 경력불러오기 api 호출
  useEffect(() => {
    if (profileConditionsData) {
      setIsDataLoaded(true);
    }
  }, [profileConditionsData]);

  useEffect(() => {
    if (profileCareersModel.careers.length > 0) {
      const totalCareerPeriod = getTotalCareerPeriodText(profileCareersModel.careers);
      setTotalCareers(totalCareerPeriod);
    } else {
      setTotalCareers('');
    }
  }, [profileCareersModel.careers]);

  if (!isDataLoaded) {
    return null;
  }

  return (
    <Frame>
      {/* 타이틀 */}
      <div className='step-title'>
        <img className='title-icon' alt='직군' src={IconDone} />
        프로필 작성을 완료했습니다!
      </div>

      {/* 프로필 작성 결과 영역 */}
      <div className='result-frame'>
        {/* 경력 */}
        {/* 직군 직무 */}
        {profileConditionsData && profileConditionsData.jobGroups && profileConditionsData.jobGroups.length > 0 && (
        <>
          {profileConditionsData.jobGroups.map(({ jobGroupCode, jobGroupName, jobTitleNames }) => (
            <div key={jobGroupCode} className='job-group-frame'>
              {totalCareers !== '' ? (
                <>
                  <span className='result-text'>총 {totalCareers} 경력</span>
                  으로
                </>
              ) : (
                <>
                  <span className='result-text'>신입</span>
                  으로
                </>
              )}
              <span className='result-text'> {jobGroupName} 직군</span>에<br />
              <span className='result-text item-area'>{jobTitleNames.join(', ')} 직무로</span> 취업을 희망합니다.
            </div>
          ))}
        </>
        )}
        {/* 지역 */}
        { profileConditionsData?.locations && profileConditionsData.locations.length > 0 && (
          <FormFrame>
            <div className='list-frame'>
              {profileConditionsData.locations.length === 17
                ? <span className='result-text'>전체 지역</span>
                : (
                  <span className='result-text'>
                    {profileConditionsData.locations.map((location) => LocationCode(Number(location))).join(', ')}
                  </span>
                )}에서 근무가 가능하고,
            </div>
          </FormFrame>

        )}
        {/* 연봉 */}
        { profileConditionsData?.salary && (
        <FormFrame>
          <div className='salary-box'>
            <SalaryText edit={checkAudit(ProfileAuditItemType.MATCHING_CONDITION_SALARY)}>
              희망 연봉은 <span className='result-text'>{profileConditionsData?.salary.toLocaleString()}만원 이상</span>입니다.
            </SalaryText>
          </div>
        </FormFrame>
        )}
        {/* 희망 근무 조건 */}
        { profileConditionsData?.workingPreference && profileConditionsData.workingPreference.length > 0 && (
        <FormFrame>
          <div className='working-preference'>{profileConditionsData?.workingPreference}</div>
        </FormFrame>
        )}
      </div>
      <div className='notice'>입력하신 정보는 언제든지 내 프로필에서 수정할 수 있습니다.</div>
    </Frame>
  );
};

export default inject(injectStore.context, injectStore.login, injectStore.profileModel, injectStore.termsModel, injectStore.profileBasicInfoModel, injectStore.profileCareersModel)(observer(PreferredResult));
