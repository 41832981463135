import { Haptics, ImpactStyle } from '@capacitor/haptics';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import basicChannelLogo from 'assets/icon/icon_pages_empty_logo_error.svg';
import ChattingIndicator from 'components/_v2/chatting/ChattingIndicator';
import RoutePaths from 'consts/RoutePaths';
import { MyChannel } from 'interfaces/_v2/chatting/IChattingRqRs';
import IComponentProps from 'interfaces/props/IComponentProps';
import { inject, observer } from 'mobx-react';
import ChattingModel from 'models/_v2/ChattingModel';
import { injectStore } from 'models/store';
import React, { FC, useRef } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { Channel, Message, MessageTypeEnum } from 'talkplus-sdk';

const Frame = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  min-height: 56px;
  padding: 8px 12px;
  border-radius: 4px;
  overflow: hidden;
  user-select: none;

  &:active {
    background-color: ${Colors.CG_30};
  }
`;

const ImageFrame = styled.div`
  width: 40px;
  height: 40px;
  border: 1px solid ${Colors.CG_50};
  background-color: ${Colors.WHITE_100};
  overflow: hidden;
`;

const InfoFrame = styled.div`
  width: fit-content;
  height: fit-content;
  
  & > .title {
    display: flex;
    align-items: center;
    min-height: 24px;
    & > span:nth-child(1) {
      font: ${Fonts.B2_Bold};
      color: ${Colors.JOBDA_BLACK};
    }

    & > span:nth-child(2) {
      margin: 0 2px 0 8px;
      font: ${Fonts.B3_Medium};
      color: ${Colors.JOBDA_BLACK};
    }
  }

  & > .last-message {
    margin-top: 4px;
    font: ${Fonts.B3_Medium};
      color: ${Colors.CG_70};
  }
`;

interface IChannelItemProps extends IComponentProps {
  chattingModel?: ChattingModel;
  item: Channel | MyChannel;
  lastMessage?: boolean;
}

const ChannelItem: FC<IChannelItemProps> = ({ item, lastMessage = false, chattingModel = new ChattingModel() }) => {
  const history = useHistory();
  const touchTimer = useRef<number | null>(null);

  const checkIsAdmin = (messageItem: Message) => messageItem.type === MessageTypeEnum.Admin || messageItem.type === MessageTypeEnum.AdminHidden;

  const onClickChannel = async () => {
    history.push(`${RoutePaths.chatChannel}?channelId=${item.id}`);
  };

  const onTouchStartChannelItem = () => {
    touchTimer.current = window.setTimeout(async () => {
      touchTimer.current = null;
      if (lastMessage) {
        try {
          await Haptics.impact({ style: ImpactStyle.Medium });
        } catch (e) {
          // 햅틱이 동작하지 않는 디바이스를 위한 방어코드
        }
        chattingModel.setCurrentChannel(item as MyChannel);
        chattingModel.setIsOpenChannelLongTouchMenu(true);
      }
    }, 500);
  };

  const onTouchEndChannelItem = () => {
    if (touchTimer.current) {
      window.clearTimeout(touchTimer.current);
      touchTimer.current = null;
    }
  };

  return (
    <Frame onClick={onClickChannel} onTouchStart={onTouchStartChannelItem} onTouchEnd={onTouchEndChannelItem} onContextMenu={(e) => { e.preventDefault(); }}>
      <ImageFrame>
        <img src={item.imageUrl ? item.imageUrl : basicChannelLogo} alt={`${item.name} 로고 이미지`} width={40} onError={(e) => { e.currentTarget.src = basicChannelLogo; }} />
      </ImageFrame>
      <SpacingBlock size={16} />
      <InfoFrame>
        <div className='title'>
          <span>{ item.name }</span>
          <span>({ item.memberCount - 1 < 0 ? 0 : item.memberCount - 1 }명)</span>
          { 'notificationEnabled' in item && !item.notificationEnabled && <Icon name='bell-off' size={24} color={Colors.CG_70} /> }
        </div>
        { lastMessage && item.lastMessage
          ? new Date().getTime() - item.lastMessage.createdAt < 1000 * 60 * 60
            ? <ChattingIndicator />
            : (
              <div className='last-message'>
                { checkIsAdmin(item.lastMessage) ? '' : item.lastMessage?.text.length > 18 ? `${item.lastMessage?.text.slice(0, 18)}...` : item.lastMessage?.text }
              </div>
            )
          : null }
      </InfoFrame>
    </Frame>
  );
};

export default inject(injectStore.chattingModel)(observer(ChannelItem));
