import React from 'react';
import IJDADeclarationSloganData, { IJDADeclarationBenefitData, IJDADeclarationPromiseData } from 'interfaces/IJDADeclarationData';
import RoutePaths from 'consts/RoutePaths';
import imgDeclarationPromise1 from 'assets/img/declaration/img_declaration_promise1.png';
import imgDeclarationPromise2 from 'assets/img/declaration/img_declaration_promise2.png';
import imgDeclarationSlogan1 from 'assets/img/declaration/img_declaration_slogan1.png';
import imgDeclarationSlogan2 from 'assets/img/declaration/img_declaration_slogan2.png';
import imgDeclarationSlogan3 from 'assets/img/declaration/img_declaration_slogan3.png';
import imgDeclarationBenefit1 from 'assets/img/declaration/img_declaration_benefit1.svg';
import imgDeclarationBenefit2 from 'assets/img/declaration/img_declaration_benefit2.svg';
import imgDeclarationBenefit3 from 'assets/img/declaration/img_declaration_benefit3.svg';

export const promises: IJDADeclarationPromiseData[] = [
  {
    title: `청년 여러분들께\n약속합니다.`,
    paragraphs: [
      <p>
        {
          `기회가 불공평한가요?
          결과가 불공정한가요?
          함께 하고 싶은 기업은 어디에 있을까요?
  
          매칭 플랫폼 잡다는
          스펙이 아닌 역량으로
          나의 능력을 알고
          내가 성장할 수 있는 기업을 매칭합니다.`
        }
      </p>,
    ],
    quote: `역량기반 취업으로\n취업의 문제를 바로 잡습니다.`,
    headerImage: imgDeclarationPromise1,
  },
  {
    title: `기업 여러분들께\n약속합니다.`,
    paragraphs: [
      <p>
        {
          `뽑을 기회가 없으신가요?
          뽑을 사람이 없으신가요?
          함께 하고 싶은 인재는 어디에 있을까요?
  
          매칭 플랫폼 잡다는
          기업에 대한 올바른 정보제공과
          다양한 역량기반 인재풀을 통해
          함께 성장할 수 있는 인재를 매칭합니다.`
        }
      </p>,
    ],
    quote: `역량기반 채용으로\n올바른 채용문화를 만들겠습니다.`,
    headerImage: imgDeclarationPromise2,
  },
];

const slogans: IJDADeclarationSloganData[] = [
  {
    title: `잡다는\n능력과 기회를\n매칭합니다.`,
    subtitle: `Connect Capability to Opportunity`,
    paragraphs: [
      <p>
        {
          `잡다는 올바른 정보의 제공으로 취업과
          채용을 돕는 `
        }
        <b>매칭 플랫폼</b>
        입니다.
        <b>
          {
            `
            잡다는 사람과 기업의 만남입니다.
            잡다는 좋은 인재와 좋은 문화의
            연결입니다.
            잡다는 능력과 기회를 매칭합니다.`
          }
        </b>
        {
          `

          대한민국 취업과 채용시장 문제의 본질은
          구인과 구직간 ‘기대의 미스매치’와 ‘정보의
          미스매치’입니다. 잡다는 올바른 정보를
          통해 구직자에게는 역량을 기반으로 진짜
          기회를 기업에게는 역량을 기반으로 진짜
          인재를 올바르게 매칭하여 미스매치의 문제
          를 해결합니다. 이 모든 것의 중심에는 ‘역량’
          이 있습니다.

          `
        }
        <b>
          {
            `잡다는 역량기반의 취업, 역량기반의 채용,
            역량기반의 교육을 통해 인재를 살리고,
            기업을 키우고, 사회를 밝히는 공정하고
            공평한 세상을 추구합니다.`
          }
        </b>
      </p>,
    ],
    headerImage: imgDeclarationSlogan1,
  }, {
    title: `스펙을 접고,\n역량을 잡다.`,
    subtitle: `Get Competency, Not Spec`,
    paragraphs: [
      <p>
        잡다에서 제공하는 <strong>역량검사</strong>는<br />
        세계 최초의 신경과학 알고리즘과<br />
        인공지능(AI) 기술이 융합된 역량검사<br />
        솔루션입니다.<br />
        <br />
        20년 동안 자연과학 기반의 사람에 대한<br />
        연구를 통해 무의식 영역에서 사람의 역량을<br />
        볼 수 있는 ‘과학의 눈’을 탑재하였습니다.<br />
      </p>,
      <ol className='dark'>
        <li>
          <span>
            {
              `생물학과 신경과학 500여편의 심리
              및 행동특성 논문의 메타분석 결과`
            }
          </span>
        </li>
        <li>
          <span>
            {
              `10만여명의 빅데이터 기반의
              머신러닝 학습`
            }
          </span>
        </li>
        <li>
          <span>
            {
              `200여명의 면접전문가와 인사전문가
              의 평가 데이터 학습`
            }
          </span>
        </li>
      </ol>,
      <p>
        <strong>역량검사</strong>은 역량 기반의 최적 인재를 합리적으로<br />
        선발하기 위한 세계 최고 수준의 의사결정<br />
        도구이며 국내 600여개 기업에서 인재채용<br />
        에 활용하고 있습니다.<br />
        <br />
        또한 <strong>역량검사</strong>은 취업을 준비하는 구직자들에게<br />
        20세 전후에 형성되는 긍정성,<br />
        적극성, 전략성, 성실성, 공감, 성찰,<br />
        메타인지와 같은 핵심역량의 보유수준에<br />
        대한 정보를 제공합니다.
      </p>,
      <ol className='dark'>
        <li>
          {
            `내적역량을 통한 강점과 역량유형
            기반의 역량 프로파일 해석`
          }
        </li>
        <li>
          {
            `나에게 적합한 기업특성과 직군 및
            직무특성의 안내`
          }
        </li>
        <li>
          {
            `역량정보를 바탕으로 면접 및
            취업준비 가이드`
          }
        </li>
      </ol>,
      <p>
        {
          `잡다는 지식으로 포장된 가짜 스펙이 아닌
          역량기반의 진짜 스펙으로 나에게 적합한
          기업과 직군과 직무를 추천하고
          매칭합니다.`
        }
      </p>,
    ],
    action: {
      text: '역량검사 지금 체험하기',
      to: RoutePaths.acc_tutorial,
    },
    headerImage: imgDeclarationSlogan2,
  }, {
    title: `가짜를 접고,\n진짜를 잡다.`,
    subtitle: 'Get real, Not fake',
    paragraphs: [
      <p>
        <b>
          {
            `잡다에서 제공하는 기업정보는
            인증된 직장 선배들이 직접 말해주는
            기업문화를 바탕으로 한 진짜 정보입니다.`
          }
        </b>
        {
          `
          기업문화는 기업의 정신과 철학이 반영되어
          구성원들의 공통된 사고방식과
          행동양식으로 드러나는무형의 질서입니다.
          기업을 판단하는 정보들 중에서
          조직의 역량과 구성원의 성장 가능성을 확인
          할 수 있는 가장 중요한 척도는 겉으로
          드러나지 않는 기업문화입니다.

          현재 취업과 관련된 정보를 제공하는 많은
          서비스들이 있습니다.
          대다수의 서비스에서, 작성자의 신원을 검증할
          수 없는 한계, 사실 여부가 확인되지 않은
          무분별한 비방,가십 위주의 리뷰를 빈번하게
          볼 수 있습니다.
          모든 리뷰들이 거짓이라고 볼 수는 없지만,
          이러한 정보로 인해 취업준비생의 기업에 대
          한 반감과 회사생활에 대한 두려움은 나날이
          커져만 가고 있습니다.

          기업은 좋은 인재를 채용할 기회를 잃어버리
          고 구직자는 좋은 기업에 입사할 기회를 잃어
          버리는 현실이 안타깝습니다.

          잡다에서 제공하는 기업정보는`
        }
        <ol>
          <li>
            {
              `기업의 외연적 정보(재무/사업/기술
              등)와 내연적 정보(조직문화/직원리
              뷰 등)의 통합 정보`
            }
          </li>
          <li>
            {
              `긍정문화, 성과문화, 가치문화에 대해
              현직자가 직접 소개하는 실질적이고
              구체적인 정보를 시각화 하여 제공`
            }
          </li>
          <li>
            기업문화를 중심으로 나에게 적합한 기업을 큐레이팅하여 추천
          </li>
        </ol>
      </p>,
      <p>
        {
          `잡다의 모든 기업 정보는 긍정 중심이며,
          올바른 기업정보의 전달로
          대한민국의 건전한 채용문화를 지향합니다.`
        }
      </p>,
    ],
    action: {
      text: '진짜 기업정보 보러가기',
      to: RoutePaths.position,
    },
    headerImage: imgDeclarationSlogan3,
  },
];

const benefits: IJDADeclarationBenefitData[] = [
  {
    title: '청년에게 기회를.',
    subtitle: `잡다는 좋은 기업을 찾는 청년에게\n3가지 가치를 제공합니다`,
    paragraphs: [
      <ol>
        <li>
          <h2 className='frame-subtitle'>
            {
              `첫째, 구직 준비에 소요되는 시간과 비용과
              노력이 더 이상 필요 없게 됩니다.`
            }
          </h2>
          <p>
            <strong>역량검사</strong>로 시간과 장소의 제약없이 언제<br />
            어디서나 응시할 수 있습니다. 취업을 위한 스펙 쌓<br />
            기, 자기소개서 작성, 인적성 검사 준비 등의 노력과<br />
            비용을 해소합니다. 한번의 <strong>역량검사</strong>로 모<br />
            든 취업준비를 마칠 수 있습니다.
          </p>
        </li>
        <li>
          <h2 className='frame-subtitle'>
            둘째, <strong>역량검사</strong>을 통해 나의 역량을<br />
            객관적으로 확인할 수 있습니다.
          </h2>
          <p>
            {
              `잡다는 역량이라는 진짜 스펙을 통해 다양한 취업의
              기회를 제공합니다. 역량은 KSA(지식/기술/태도)로
              만들어지지 않습니다. 긍정성, 적극성, 전략성,
              성실성, 공감, 성찰, 메타인지와 같은 핵심역량의
              보유수준과 역량유형 및 분석으로 내가 무엇을
              잘하고 어떤 일을 할 때 성과를 내고 성장가능성이
              높은지를 객관적으로 확인할 수 있습니다.`
            }
          </p>
        </li>
        <li>
          <h2 className='frame-subtitle'>
            {
              `셋째, 매칭을 통해 나에게 적합한 기업을
              추천 받을 수 있습니다.`
            }
          </h2>
          <p>
            {
              `잡다는 기업에 대한 올바른 정보와 관점을
              제공합니다. 기업에 대한 정보부족과 대비부족으로
              인한 부정적 불확실성이 해소됩니다. 역량기반의
              매칭으로 나에게 적합한 기업과 직무 정보를 통해
              취업의 기회가 확대됩니다.`
            }
          </p>
        </li>
      </ol>,
    ],
    headerImage: imgDeclarationBenefit1,
  }, {
    title: '기업에게 인재를.',
    subtitle: `잡다는 좋은 인재를 찾는 대한민국\n기업에게 3가지 가치를 제공합니다.`,
    paragraphs: [
      <ol>
        <li>
          <h2 className='frame-subtitle'>
            첫째, 채용업무에 소요되는 시간과 비용과노력이 대폭 감소됩니다.
          </h2>
          <p>
            {
              `지금은 채용계획 수립부터 최종 채용 확정까지
              수많은 준비와 실행이 필요합니다. 잡다 플랫폼의
              인재풀과 추천 그리고 매칭을 이용하면 더 이상 채용
              포탈을 통한 공고와 홍보가 필요 없으며 한정된
              자원으로 채용의 많은 업무를 진행하는 수고와
              노력이 없어집니다.`
            }
          </p>
        </li>
        <li>
          <h2 className='frame-subtitle'>
            {
              `둘째, 기업이 원하는 적합한 인재를
              효과적으로 선발할 수 있습니다.`
            }
          </h2>
          <p>
            {
              `잡다는 역량이라는 진짜 스펙을 통해 기업이 원하는
              인재선발을 돕습니다. 지식과 스펙이 성과를
              보장하지 않고, 학력과 경력이 능력을 대변하지 않습
              니다. 면접으로는 사람의 편향과 편견의 한계를
              극복하기 어렵습니다. 과학의 눈을 통해,무의식
              영역의 역량을 파악함으로써 우리 기업에 맞는 성과
              를 내고 성장 가능성이 높은 인재를 선발할 수
              있습니다.`
            }
          </p>
        </li>
        <li>
          <h2 className='frame-subtitle'>
            {
              `셋째, 다양한 인재풀로
              수시 상시 채용이 가능해집니다.`
            }
          </h2>
          <p>
            {
              `이제는 기업의 채용문화가 정기채용과 공개채용에서
              수시/상시 채용으로 변화되고 있습니다.
              잡다에서 제공되는 다양한 인재풀 그리고 인재추천
              과 매칭서비스는 채용담당 인원과 채용업무의 증가
              없이 수시상시 채용이 가능하도록 지원합니다.
              직군별, 직무별로 성과를 내고 성장가능성이 높은
              인재를 기업이 원하는 때에 원하는 인재를 선발할 수
              있습니다.`
            }
          </p>
        </li>
      </ol>,
    ],
    headerImage: imgDeclarationBenefit2,
  }, {
    title: '대한민국에게 미래를.',
    subtitle: `잡다는 대한민국 사회에\n3가지 가치를 제공합니다.`,
    paragraphs: [
      <ol>
        <li>
          <h2 className='frame-subtitle'>
            {
              `첫째, 취업과 채용에 소요되는
              사회적 비용이 감소됩니다.`
            }
          </h2>
          <p>
            {
              `구직자가 1년동안 취업을 준비하는 비용은 평균
              378만원 (2021 인쿠르트 조사) 기업에서 직원 1명을
              채용하는데 드는 비용은 연간 평균 6240만원 (2018
              고용노동부 조사) 미스매치로 발생하는 청년 일자리
              창출의 사회적 비용은 매년 증가하고 있습니다.
              잡다는 역량기반 취업과 채용 그리고 수시상시
              매칭을 통해 취업과 채용에 소요되는 사회적 비용을
              감소시키며 청년을 위한 새로운 일자리 창출의
              기회를 확대합니다.`
            }
          </p>
        </li>
        <li>
          <h2 className='frame-subtitle'>
            {
              `둘째, 기업에 대한 인식이 긍정적이고
              친사회적으로 전환됩니다.`
            }
          </h2>
          <p>
            {
              `사회적 부를 창출하고 국가경제 발전과 사회공헌의
              주체이자 주역은 바로 기업입니다. 기업에 대한
              부정적 인식은 사회적 신뢰를 저하시키고 기업
              경영자와 구성원의 동기를 저하시킴으로써 경제
              성장과 발전에 악영향을 초래합니다. 잡다의
              기업정보는 취준생에게 기업에 대한 긍정적 인식을
              강화하고 기업 구성원들에게도 자부심을
              고취시킴으로써  기업 성장과 고용 활성화라는
              선순환을 유도합니다.`
            }
          </p>
        </li>
        <li>
          <h2 className='frame-subtitle'>
            {
              `셋째, 스펙중심 사회에서 역량중심  
              사회로 변화할 수 있습니다.`
            }
          </h2>
          <p>
            {
              `학벌과 학력 중심 채용으로 발생하는 기업
              채용에서의 부익부 빈익빈 현상을 해소합니다.
              대한민국 교육 문제의 핵심은 대학입시 중심의
              과다경쟁과 서열화입니다. 기업에서 스펙을
              보지 않고 역량기반으로 인재를 채용한다면 대학의
              역할과 교육이 바뀔 것이고, 입시 중심의
              초중고등학교 교육은 역량기반의 교육으로 혁신될
              것이며 우리 사회는 누구나 개인의 역량을 발견하고
              개발함으로써자기실현을 추구할 수 있는 역량중심
              사회로 변화될 것입니다.`
            }
          </p>
        </li>
      </ol>,
    ],
    headerImage: imgDeclarationBenefit3,
  },
];

export default ({ promises, slogans, benefits });
