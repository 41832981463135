import IconClose from 'assets/icon/icon_close_24.svg';
import RoutePaths from 'consts/RoutePaths';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import JDBaseModal, { IJDBaseModal } from '../_common/modals/JDBaseModal';
import { JDModalBasicFrame, JDModalFullButton, JDModalTitle } from '../_common/modals/JDModalComponents';

const Frame = styled.div`
  .icon-close{
    position: absolute;
    top: 20px;
    right: 20px;
  }

  ${JDModalTitle} {
    margin-top: 8px;
  }
`;

const PositionApplyMissionCardModal:FC<IJDBaseModal> = ({ isOpen, onClickClose }) => (
  <JDBaseModal dimmed isOpen={isOpen} onClickClose={onClickClose} isDimClickClose>
    <Frame>
      <JDModalBasicFrame>
        <button className='icon-close' onClick={onClickClose}><img src={IconClose} alt='icon-close' /></button>
        <JDModalTitle>입사지원을 위해 프로필의<br />기본, 선호, 학력정보를<br />입력해 주세요.</JDModalTitle>
        <Link to={RoutePaths.profile}>
          <JDModalFullButton>프로필 입력하러 가기</JDModalFullButton>
        </Link>
      </JDModalBasicFrame>
    </Frame>
  </JDBaseModal>
);

export default PositionApplyMissionCardModal;
