import graphLottie from 'assets/lottie/resume_load_lottie.json';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import Lottie from 'lottie-react';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useInsightResumeLog } from 'query/resume/useResumeQuery';
import { InsightResumeLogRq } from 'interfaces/_v2/insight/IInsightResumeRqRs';
import { useLocation } from 'react-router';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;

  & .title {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    height: fit-content;

    & .sub {
      font: ${Fonts.B2_Medium};
      color: ${Colors.G_200};
    }

    & .main {
      font: ${Fonts.H4_Bold};
      color: ${Colors.CG_90};
    }
  }

  & .lottie {
    width: 240px;
  }
  .question-text{
    font:${Fonts.B2_Bold};
    color:${Colors.CG_90};
    margin-bottom: 12px;
    align-self: flex-start;
  }
  .info-frame{
        display: flex;
        padding:16px;
        align-items: flex-start;
        background-color: ${Colors.CG_30};
        border-radius: 8px;
        flex-direction: column;
        gap: 4px;
        margin-bottom: 76px;
        width: 100%;
        .inner-frame{
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 8px;
            .inner-text{
                font:${Fonts.B2_Medium};
                color:${Colors.CG_70};
            }
        }
    }
`;

interface IResumeIntroduce {
  isMatchDashboard?:boolean;
}
const ResumeIntroduce:FC<IResumeIntroduce> = ({ isMatchDashboard }) => {
  const { pathname } = useLocation();

  const saveSuccess = () => {
  };
  const { mutate: saveResumeInduce } = useInsightResumeLog(saveSuccess);
  const onView = async () => {
    const induceRq:InsightResumeLogRq = {
      action: 'VIEW',
      actionSource: pathname,
      actionComponent: 'modal_gate_insight_resume',
      data: {},
    };
    saveResumeInduce(induceRq);
  };
  useEffect(() => {
    onView();
  }, []);
  return (
    <Frame className='modal_gate_insight_resume'>
      <div className='title'>
        <div className='sub'>귀찮았던 프로필 작성을 간단하게!</div>
        <div className='main'>
          제출한 지원서를 활용해서 <br />
          프로필을 채워보세요.
        </div>
      </div>
      <div className='lottie'>
        <Lottie animationData={graphLottie} />
      </div>
      <div className='question-text'>프로필을 채우면 어떤 점이 좋나요?</div>
      <div className='info-frame'>
        <div className='inner-frame'>
          <Icon name='check' size={24} color={Colors.JOBDA_BLACK} />
          <div className='inner-text'>정리해놓은 내 프로필로 간편히 지원해 보세요.</div>
        </div>
        <div className='inner-frame'>
          <Icon name='check' size={24} color={Colors.JOBDA_BLACK} />
          <div className='inner-text'>내 지원서 정보를 한 곳에서 편하게 관리해 보세요.</div>
        </div>
        <div className='inner-frame'>
          <Icon name='check' size={24} color={Colors.JOBDA_BLACK} />
          <div className='inner-text'>프로필 정보가 채워질수록 더 정확한 매칭을 받을 수 있어요.</div>
        </div>
      </div>
    </Frame>
  );
};

export default ResumeIntroduce;
