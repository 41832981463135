import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import React, { FC } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
    margin-bottom: 32px;

    h1 {
        font: ${Fonts.H3_Bold};
        color: ${colors.JOBDA_BLACK};
        margin-bottom: 16px;
    }

    div {
        font: ${Fonts.B2_Medium};
        line-height: 20px;
        color: ${colors.JOBDA_BLACK};
    }

`;

interface IMatchHomeTitleHeaderProps {
    title: string;
    desc: string;
}

const MatchHomeTitleHeader:FC<IMatchHomeTitleHeaderProps> = ({ title, desc }) => (
  <Frame>
    <h1>{title}</h1>
    <div dangerouslySetInnerHTML={{ __html: desc }} />
  </Frame>
);

export default MatchHomeTitleHeader;
