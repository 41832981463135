import React from 'react';
import classnames from 'classnames/bind';
import styles from './curatorList.module.scss';

const cx = classnames.bind(styles);

const CuratorList = () => (
  <div className={cx('curatorList')}>
    <ul>
      <li className={cx('flowImg')} aria-label='logo' />
    </ul>
  </div>
);

export default CuratorList;
