import JDCalendar from 'components/_v2/calendar/JDCalendar';
import { CalendarMetaTag } from 'components/_v2/_common/meta/MetaTag';
import React, { FC } from 'react';

const Calendar:FC = () => (
  <div>
    <CalendarMetaTag />
    <JDCalendar />
  </div>
);

export default Calendar;
