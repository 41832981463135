import DisableScroll from 'components/common/DisableScroll';
import Portal from 'components/common/Portal';
import JDASelector, { JDA_SELECTOR_TYPE } from 'components/inputs/JDASelector';
import { StyledTermsFrame } from 'components/modals/TermsModal';
import MainHeader from 'components/_v2/_common/mainHeader/MainHeader';
import RoutePaths from 'consts/RoutePaths';
import { TERM_TYPE } from 'consts/TermType';
import IJoinPageProps from 'interfaces/props/IJoinPageProps';
import { ITermsGetDto } from 'interfaces/rqrs/ITermsListGetRs';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import JoinModel from 'models/_v2/JoinModel';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';

interface termsFormValues { activeIndex: number }

const Privacy: FC<IJoinPageProps> = ({ joinModel = new JoinModel() }) => {
  const [activeTerms, setActiveTerms] = useState<ITermsGetDto | null>(null);
  const formMethod = useForm<termsFormValues>({ defaultValues: { activeIndex: 0 } });
  const history = useHistory();

  const { setValue, watch, reset } = formMethod;

  useEffect(() => {
    const init = async () => {
      await joinModel.loadTermsList(TERM_TYPE.PRIVACY_POLICY);
      setValue('activeIndex', 0);
      setActiveTerms(joinModel.activeTermContents[Number(watch('activeIndex'))]);
    };
    init();
    return () => {
      reset();
      joinModel.resetTerms();
    };
  }, []);

  return (
    <Portal>
      <StyledTermsFrame className='visible'>
        <DisableScroll />
        <div className='dimmer' />
        <MainHeader
          className='header'
          title='개인정보 처리방침'
          close
          alarm={false}
          search={false}
          gnb={false}
          onClose={() => {
            history.push(RoutePaths.root);
            setTimeout(() => {
              joinModel.activeTermType = null;
            }, 500);
          }}
          data-hide={false as any}
        />
        <FormProvider {...formMethod}>
          <div className='frame-content'>
            <div className='frame-title'>
              <div className='title'>{activeTerms?.title}</div>
              {
                joinModel.activeTermContents.length > 0 && (
                  <JDASelector
                    className='select-options'
                    selectTitle='개정이력'
                    type={JDA_SELECTOR_TYPE.type1}
                    name='activeIndex'
                  >
                    <select>
                      {
                        joinModel.activeTermContents.map(({ sn, version }, idx) => (
                          <option key={`option-term-${sn}`} value={idx} selected={idx === Number(watch('activeIndex'))}>개정이력 {version}</option>
                        ))
                      }
                    </select>
                  </JDASelector>
                )
              }
            </div>
            <div className='frame-terms' dangerouslySetInnerHTML={{ __html: activeTerms?.contents || '' }} />
          </div>
        </FormProvider>
      </StyledTermsFrame>
    </Portal>
  );
};

export default inject(injectStore.joinModel)(observer(Privacy));
