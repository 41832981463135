import TextAreaField from '__designkit__/components/TextAreaField';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { FC, useEffect, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

const Frame = styled.div`
  position: relative;
  width: 100%;
`;

interface IReasonFieldProps extends IComponentProps {
  name: string; // 필수값, react-hook-form에서 사용하는 name 값
  defaultValue?: string; // 선택값, 지정시 초기값으로 설정
  disabled?: boolean; // 선택값, 지정시 수정 불가
  maxLength?: number; // 선택값, 지정시 최대 글자수 제한
}

const ReasonField: FC<IReasonFieldProps> = ({ name, defaultValue, disabled, maxLength }) => {
  const [reasonValue, setReasonValue] = useState<string | undefined>();

  const { setValue, formState, clearErrors } = useFormContext();
  const { field } = useController({ name });

  useEffect(() => {
    if (defaultValue) setReasonValue(defaultValue);
  }, [defaultValue]);

  return (
    <Frame>
      <TextAreaField
        {...field}
        errorMessage={formState.errors[name]?.message}
        value={reasonValue}
        placeholder='구체적으로 작성해주시면 더 빠르게 조치할 수 있어요.'
        onChange={(e) => { setReasonValue(e.target.value); setValue(name, e.target.value); }}
        onFocus={() => { clearErrors(name); }}
        disabled={disabled}
        autoComplete='off'
        maxLength={maxLength}
      />
    </Frame>
  );
};

export default ReasonField;
