import { Button, ButtonType } from '__designkit__/button/Button';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import BottomFloatingFrame from 'components/BottomFloatingFrame';
import Loading from 'components/Loading';
import CalendarFloatingButton from 'components/_v2/calendar/CalendarFloatingButton';
import CalendarListCard from 'components/_v2/calendar/CalendarListCard';
import CalendarListHeader from 'components/_v2/calendar/CalendarListHeader';
import ExpandedCalendar from 'components/_v2/calendar/ExpandedCalendar';
import FoldedCalendar from 'components/_v2/calendar/FoldedCalendar';
import { Divider1G } from 'components/divider/Divider';
import { EmploymentHomeType } from 'consts/_v2/CenterType';
import { absoluteHorizonCenter } from 'consts/_v2/_common/style/mixins';
import { StatusType } from 'consts/_v2/calendar/CalendarFilterType';
import { ScreeningType } from 'consts/_v2/jobs/ScreeningType';
import ICalendarProps from 'interfaces/_v2/calendar/ICalendarProps';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import Login from 'models/Login';
import CalendarModel from 'models/_v2/CalendarModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { debounceKey } from 'utils/CommonUtils';
import { DateDataFormatMMDDCCUnit, DateDataFormatYYYYMMDD, DateFormatMMDDCCUnit, isBeforeDateOnly } from 'utils/DateUtils';
import ScrollUtil from 'utils/ScrollUtil';

const Frame = styled.div`
  .frame-input-box {
    width: 152px;
    height: 36px;
  }

  .jd-input {
    width: 152px;
    font: ${Fonts.B3_Medium};
    margin-right: 6px;

    > div {
      input {
        font: ${Fonts.B3_Medium};
        padding-left: 4px;
      }

      input:not([value='']) + .btn-clear {
        display: block;
        position: absolute;
        top: 25%;
        right: 0;
        margin-right: 8px;
        background: none;
      }
    }
  }

  .jobposting-date {
    font: ${Fonts.B2_Medium};
    color: ${Colors.CG_70};
    padding: 20px 0 0 16px;
  }

  .more-view {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid ${Colors.CG_30};
  }
`;

const StickyFrame = styled.div`
  position: sticky;
  top: ${(props) => (!(props as any)['data-hide'] ? '52' : '0')}px;
  transition: top 0.25s;
  z-index: 1;
  background: white;
`;

const CalendarHeader = styled.div`
  padding-bottom: 2px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.12);
  border-radius: 0px 0px 16px 16px;
`;

const Divider = styled(Divider1G)`
  padding: 0 16px;
`;

const StyledContent = styled.div`
  background: ${Colors.WHITE_100};
  position: absolute;
  width: 100%;
  border-radius: 12px 12px 0px 0px;
  z-index: 100;

  .drag-area {
    ${absoluteHorizonCenter()}
    top: -12px;
    width: 56px;
    height: 2px;
    border-radius: 2px;
    background-color: #c4c4c4;
  }
`;

const DateDivider = styled.div`
  height: 12px;
  background: ${Colors.CG_30};
`;

const NoPosting = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 16px;

  > div {
    width: 100%;
    padding: 32px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${Colors.CG_30};
    border-radius: 4px;
  }
  .text {
    font: ${Fonts.B3_Medium};
    color: ${Colors.CG_70};
  }

  border-bottom: 12px solid ${Colors.CG_30};
`;

const NoPostingMonth = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  background: ${Colors.CG_30};

  > div {
    width: 100%;
    padding: 24px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .text {
    font: ${Fonts.B3_Medium};
    color: ${Colors.CG_70};
    text-align: center;
  }
`;

const NoListPosting = styled.div`
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 84px;

  > div {
    width: 188px;
    display: flex;
    justify-content: center;

    > div {
      align-items: baseline;
    }

    .text {
      font: ${Fonts.B3_Medium};
      color: ${Colors.CG_70};
      text-align: center;
    }
  }
`;

const JDCalendar: FC<ICalendarProps> = ({ context = new Context(), calendarModel = new CalendarModel(), login = new Login() }) => {
  const useFormed = useForm({ mode: 'all' });
  const infinityLoadDividerRef = useRef<HTMLDivElement>(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const itemsRef = useRef<{ [date: string]: HTMLDivElement | null }>({});
  const [timeScrollEnd, setTimeScrollEnd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [earliestDate, setEarliestDate] = useState(new Date());
  const blendHandler = async () => {
    const scrollTop = await ScrollUtil.getScrollTop();
    setScrollPosition(scrollTop);
  };

  useEffect(() => {
    context.employmentHomeType = EmploymentHomeType.CALENDAR;
    ScrollUtil.addEventListener(blendHandler);
    const init = async () => {
      // calendarModel.init(); // 공고 들어갔다가 나오면 필터가 유지되어야 해서.. init 제거 (추후 사이드 고려)
      // 1. 로그인 여부 확인 필터조회 (중간에 로그인 됐을경우도 생각해야 돼서 밖으로 뻇습니다)
      if (login.userInfo) {
        await calendarModel.loadFilter();
        if (calendarModel.recentlyFilter) {
          calendarModel.calendarLoadFilter = {
            jobTitles: calendarModel.recentlyFilter.jobTitles ? calendarModel.recentlyFilter.jobTitles.map((job) => job.code).join(', ') : '', // 직무 코드
            recruitmentTypes: calendarModel.recentlyFilter.recruitmentTypes ? calendarModel.recentlyFilter.recruitmentTypes.join(', ') : '', // 채용 형태
            locations: calendarModel.recentlyFilter.locations ? calendarModel.recentlyFilter.locations.join(', ') : '', // 지역
            businessSizeTypes: calendarModel.recentlyFilter.businessSizeTypes ? calendarModel.recentlyFilter.businessSizeTypes.join(', ') : '', // 기업 구분
          };
        }
      }
      // 공고 이동시 초기화 방지
      if (!calendarModel.calendarList) {
        await calendarModel.loadCalendarList();
      }
      handleSelectedDateChange(); // 새로고침시 따로 초기화를 안시켜줘서 위치 잡아주기.
    };

    init();

    return () => {
      ScrollUtil.removeEventListener(blendHandler);
    };
  }, []);

  useLayoutEffect(() => {
    handleSelectedDateChange();
    saveEarlyDate();
  }, [calendarModel.selectedDate]);

  const saveEarlyDate = () => {
    if (!calendarModel.selectedDate) return;

    const currentDate = new Date(calendarModel.selectedDate);
    if (!earliestDate || currentDate < new Date(earliestDate)) {
      setEarliestDate(calendarModel.selectedDate);
    }
  };

  const handleSelectedDateChange = async () => {
    if (calendarModel.selectedDate.getFullYear() !== calendarModel.selectedLoadDate.getFullYear() || calendarModel.selectedDate.getMonth() !== calendarModel.selectedLoadDate.getMonth()) {
      await changeCalendarList();
    }

    if (calendarModel.calendarList && calendarModel.selectedDate && calendarModel.showDate) {
      const ref = itemsRef.current[DateDataFormatYYYYMMDD(calendarModel.selectedDate)];
      const refOffSetTop = ref?.offsetTop;
      // 화면에 그려진 다음에 이동
      if (refOffSetTop) {
        setTimeScrollEnd(false);
        setTimeout(() => {
          smoothScrollTo({ top: refOffSetTop ? refOffSetTop - 220 : 0 }).then(() => {
            setTimeScrollEnd(true);
          });
        }, 0);
      } else {
        smoothScrollTo({ top: 0 });
      }
    }
  };

  useLayoutEffect(() => {
    if (timeScrollEnd && itemsRef.current) {
      const itemKeys = Object.keys(itemsRef.current);
      itemKeys
        .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
        .forEach((date) => {
          const item = itemsRef.current[date];
          if (item && 'offsetTop' in item && timeScrollEnd) {
            if (scrollPosition + 240 >= item.offsetTop) {
              const debounceFn = debounceKey(async () => {
                calendarModel.showDate = new Date(date);
              }, 25);
              debounceFn('Calendar');
            }
          }
        });
    }
  }, [scrollPosition, timeScrollEnd]);

  const smoothScrollTo = (options: any): Promise<void> => new Promise((resolve) => {
    setIsLoading(true); // Loading 시작
    const { top } = options;
    ScrollUtil.scrollTo(0, top);
    setIsLoading(false); // Loading 종료
    resolve();
  });

  const changeCalendarList = async () => {
    // 초기화
    calendarModel.calendarList = [];
    calendarModel.lastLoadDate = '';
    const keys = Object.keys(itemsRef.current);
    keys.forEach((key) => {
      itemsRef.current[key] = null;
    });
    await calendarModel.loadCalendarList();
  };

  const loadInfiniteScroll = async () => {
    await calendarModel.lazyCalendarList();
  };

  return (
    <Frame>
      <FormProvider {...useFormed}>
        <StickyFrame data-hide={context.hideHeader}>
          { isLoading && <Loading /> }
          <CalendarHeader>
            { calendarModel.isCalendarFolded ? <FoldedCalendar /> : <ExpandedCalendar /> }
            <StyledContent>
              <div className='drag-area' />
            </StyledContent>
          </CalendarHeader>
          <CalendarListHeader />
        </StickyFrame>
        { calendarModel.calendarList && calendarModel.calendarList.map(({ date, jobPostings }, idx) => {
          let count = 0;
          if (isBeforeDateOnly(new Date(date), earliestDate)) return <></>;
          return (
            <div>
              <div
                key={DateFormatMMDDCCUnit(date)}
                ref={(el) => {
                  itemsRef.current[date] = el;
                }}
              >
                { DateDataFormatMMDDCCUnit(calendarModel.showDate) !== DateFormatMMDDCCUnit(date) && <div className='jobposting-date'>{ DateFormatMMDDCCUnit(date) }</div> }
                { jobPostings.map((data) => {
                  if (calendarModel.bookmarkFilter && !data.bookmarkYn) return <></>;
                  if (calendarModel.statusFilter !== StatusType.TOTAL && calendarModel.statusFilter !== data.statusType) return <></>;
                  if (calendarModel.accFilter && data.screeningType !== ScreeningType.AI_SCREENING) return <></>;
                  count += 1;
                  return (
                    <div key={data.jobPostingSn}>
                      <CalendarListCard date={date} calendarItem={data} />
                      <Divider />
                    </div>
                  );
                })}
                { count === 0 && (
                <NoPosting>
                  <div>
                    <Icon name='information' size={16} color={Colors.CG_70} />
                    <div className='text'>공고가 없습니다.</div>
                  </div>
                </NoPosting>
                )}
                {calendarModel.calendarList && calendarModel.calendarList.length - 1 !== idx && <DateDivider />}
              </div>
            </div>
          );
        })}
        { calendarModel.calendarList && calendarModel.calendarList.length === 0 && (
          <NoListPosting>
            <div>
              <Icon name='information' size={16} color={Colors.CG_70} />
              <span className='text'>공고가 없습니다. 검색 조건과 날짜를 다시 선택해 주세요.</span>
            </div>
          </NoListPosting>
        )}
        { calendarModel.nextLoadCount !== 3 ? (
          <div role='presentation' className='more-view' onClick={() => loadInfiniteScroll()}>
            <Button buttonType={ButtonType.TEXT} icon={<Icon name='arrow-bottom' size={24} />} label='더보기' size='small' iconRight />
          </div>
        ) : (
          <NoPostingMonth>
            <div>
              <Icon name='information' size={16} color={Colors.CG_70} />
              <span className='text'>최대 4개월까지 공고를 확인할 수 있어요.</span>
            </div>
          </NoPostingMonth>
        )}
        <div ref={infinityLoadDividerRef} />
      </FormProvider>
      <BottomFloatingFrame>
        <CalendarFloatingButton />
      </BottomFloatingFrame>
    </Frame>
  );
};

export default inject(injectStore.context, injectStore.calendarModel, injectStore.login)(observer(JDCalendar));
