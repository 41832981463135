import React, { useState } from 'react';
import Swiper from 'swiper';
import classnames from 'classnames/bind';
import JDSwiper, { JDSwiperSlide } from 'components/_v2/_common/JDSwiper';
import styles from './cheeringMessage.module.scss';
import { messageData } from './messageData';

const cx = classnames.bind(styles);

const CheeringMessage = () => {
  const messageListEl = messageData.map((item, index) => (
    <JDSwiperSlide className={cx('slideCard')}>
      <div key={index} className={cx('messageInner')}>
        <div className={cx('messageWrap', { secondary: index % 2 === 1 })}>
          <p className={cx('companyName')}>{item.companyName}</p>
          <p className={cx('comment')}>{item.comment}</p>
          <p className={cx('who')}>{item.who}</p>
        </div>
      </div>
    </JDSwiperSlide>
  ));
  const [swiperObj, setSwiperObj] = useState<Swiper | null>(null);

  return (
    <div className={cx('wrap')} style={{ width: '100%' }}>
      <div className={cx('inner')}>
        <strong className={cx('sectionTitle')}>기업 인사담당자 응원</strong>

        <JDSwiper
          className={cx('messageArea')}
          slidesPerView="auto"
          spaceBetween={16}
          slidesOffsetBefore={16}
          slidesOffsetAfter={16}
          onSwiper={(e) => setSwiperObj(e)}
        >
          {messageListEl}
        </JDSwiper>
      </div>
    </div>
  );
};

export default CheeringMessage;
