import { IonSlide, IonSlides } from '@ionic/react';
import React, { FC, HTMLAttributes, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import DisableScroll from 'components/common/DisableScroll';
import { IconCloseW, IconSlideArrow } from 'consts/assets/icons/iconPages';
import colors from '__designkit__/common/colors';
import { fonts, lineHeights } from '__designkit__/common/fonts';
import { absoluteCenterCenter, absoluteVerticalCenter, dimmer, IonSlideSpaceBetween, ScrollHiddenMixin } from 'consts/style/mixins';
import IModalFrameProps from 'interfaces/IModalFrameProps';
import Portal from '../common/Portal';

export const StyledIonSlide = styled(IonSlide)`
  width: 100%;
  align-self: center;
  .modal-slide-frame {
    border-radius: ${(props) => (props as any)['data-border-radius']}px;
    max-height: calc(100vh - 120px);
    overflow: auto;
    ${ScrollHiddenMixin()};
  }
`;

export const ModalSlide: FC<HTMLAttributes<HTMLIonSlideElement> & { borderRadius?: number }> = (({ children, borderRadius = 12 }) => (
  <StyledIonSlide data-border-radius={borderRadius}>
    <div className='modal-slide-frame'>{ children }</div>
  </StyledIonSlide>
));

const StyledContent = styled.div`
  ${absoluteCenterCenter()};
  width: 100%;
  height: 100%;
  max-width: 430px;
  padding: 40px;
  .ion-slides-frame {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    ion-slide {
      ${IonSlideSpaceBetween(16)};
    }
  }
  .close-btn {
    position: absolute;
    right: 24px;
    top: 24px;
    width: 24px;
    height: 24px;
    background: none;
  }
  .slide-btn {
    ${absoluteVerticalCenter()};
    z-index: 2;
    background: none;
    display: none;
    &.active {
      display: inline-block;
    }
    &.left {
      transform: translate(0%, -50%) rotate(180deg);
      left: 12px;
    }
    &.right {
      right: 12px;
    }
  }
`;

const Frame = styled.div`
  position: relative;
  ${dimmer()};
  z-index: 1000000;
  opacity: 0;
  pointer-events: none;
  
  animation-duration: 0.25s;
  animation-name: fadeOut;
  animation-fill-mode: forwards;
  
  ${StyledContent} {
    animation-duration: 0.25s;
    animation-name: scaleDown;
    animation-fill-mode: forwards;
  }
  
  &.active {
    pointer-events: inherit;
    animation-name: fadeIn;
    ${StyledContent} {
      animation-name: scaleUp;
    }
  }
  
  @keyframes scaleUp {
    from {
      transform: translate(-50%, -50%) scale(0);    
    }
    to {
      transform: translate(-50%, -50%) scale(1);
    }
  }
  
  @keyframes scaleDown {
    from {
      transform: translate(-50%, -50%) scale(1);
    }
    to {
      transform: translate(-50%, -50%) scale(0);    
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

const StyledIonSlides = styled(IonSlides)`
  position: relative;
  align-self: center;
  overflow: visible;
  width: 100%;

  .swiper-pagination {
    padding: 0 10px;
    left: 0;
    width: 100%;
    ${fonts.NOTO_13_400};
    ${lineHeights.LINEHEIGHT_1_40};
    color: ${colors.WHITE_100};
    .custom-fraction {
      display: flex;
      justify-content: space-between;
    }
  }
  &.show-title {
    .swiper-pagination {
      top: -5px;
      transform: translate(0, -100%);
      bottom: unset;
    }
  }
  &:not(.show-title) {
    .swiper-pagination {
      bottom: -5px;
      transform: translate(0, 100%);
    }
  }
`;

const MODAL_SLIDE_OPTIONS = {
  slidesPerView: 'auto',
  autoHeight: true,
  pagination: {
    el: '.swiper-pagination',
    type: 'fraction',
  },
};

const MODAL_SLIDE_OPTIONS_WITH_TITLE = (title: string) => ({
  slidesPerView: 'auto',
  autoHeight: true,
  pagination: {
    el: '.swiper-pagination',
    type: 'fraction',
    renderFraction: (currentClass: string, totalClass: string, e: any) => (`
        <div class='custom-fraction'>
            <div>${title}</div>
            <div><span class='${currentClass}'></span>/<span class='${totalClass}'></span></div>
        </div>
      `),
  },
});

interface IModalSlideViewerProps extends IModalFrameProps{
  title?: string;
  index?: number;
}

const ModalSlideViewer: FC<IModalSlideViewerProps> = ((props) => {
  const { children, isOpen, onDidDismiss, onDidPresent, onWillDismiss, onWillPresent, title, index = 0, onClickClose } = props;
  const [isOpenState, setIsOpenState] = useState<boolean>(isOpen);
  const ionSlidesRef = useRef<HTMLIonSlidesElement>(null);
  const [isBeginning, setIsBeginning] = useState<boolean>(false);
  const [isEnd, setIsEnd] = useState<boolean>(false);
  // renderFraction 관련해서 함수인 경우 동작이 잘 안됨 -> useState에 ionSlide를 추가
  const options = title ? MODAL_SLIDE_OPTIONS_WITH_TITLE(title) : MODAL_SLIDE_OPTIONS;

  const slideButtonActiveHandler = async () => {
    if (!ionSlidesRef.current) return;
    const isBeginning = await ionSlidesRef.current.isBeginning();
    const isEnd = await ionSlidesRef.current.isEnd();
    setIsBeginning(isBeginning);
    setIsEnd(isEnd);
  };

  const [slides, setSlides] = useState(
    <StyledIonSlides
      ref={ionSlidesRef}
      pager
      options={options}
      className={title ? 'show-title' : ''}
      onIonSlideWillChange={slideButtonActiveHandler}
    >
      { children }
    </StyledIonSlides>,
  );

  useEffect(() => {
    slideButtonActiveHandler();
  }, [ionSlidesRef.current]);

  useEffect(() => {
    setSlides(
      <StyledIonSlides
        ref={ionSlidesRef}
        pager
        options={options}
        className={title ? 'show-title' : ''}
        onIonSlideWillChange={slideButtonActiveHandler}
      >
        { children }
      </StyledIonSlides>,
    );
  }, [children, options, title]);

  useEffect(() => {
    if (isOpen) {
      ionSlidesRef.current?.slideTo(index, 0);
      if (isOpenState) {
        onDidPresent && onDidPresent();
      } else {
        onWillPresent && onWillPresent();
      }
    } else if (isOpenState) {
      onWillDismiss && onWillDismiss();
    } else {
      onDidDismiss && onDidDismiss();
    }
  }, [isOpen, isOpenState, onWillPresent, onDidPresent, onWillDismiss, onDidDismiss, index]);

  const animationEndHandler = () => {
    setIsOpenState(isOpen);
  };

  const onClickLeftHandler = () => {
    ionSlidesRef.current?.slidePrev();
  };

  const onClickRightHandler = () => {
    ionSlidesRef.current?.slideNext();
  };

  if (isOpen || isOpenState)
    return (
      <Portal>
        <DisableScroll />
        <Frame className={isOpen ? 'active' : ''} onAnimationEnd={animationEndHandler}>
          <StyledContent>
            <button className='close-btn' onClick={onClickClose}><IconCloseW /></button>
            <button className={isBeginning ? 'slide-btn left' : 'slide-btn left active'} onClick={onClickLeftHandler}>
              <IconSlideArrow />
            </button>
            <div className='ion-slides-frame'>
              {slides}
            </div>
            <button className={isEnd ? 'slide-btn right' : 'slide-btn right active'} onClick={onClickRightHandler}>
              <IconSlideArrow />
            </button>
          </StyledContent>
        </Frame>
      </Portal>
    );
  return <></>;
});

export default ModalSlideViewer;
