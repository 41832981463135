import JDAInformationBox from 'components/JDAInformationBox';
import colors from '__designkit__/common/colors';
import React, { FC, useState } from 'react';
import PassReviewListItem from 'components/PassReviewListItem';
import PassReviewSimple from 'fixtures/PassReviewSimpleRs';
import DifficultyRating from '../../components/rating/DIfficultyRating';
import ProgressHeartRating from '../../components/rating/ProgressHeartRating';

const InterviewComponentsPage: FC = (() => {
  const [rating, setRating] = useState<'NONE' | 'VERY_EASY' | 'EASY' | 'NORMAL' | 'HARD' | 'VERY_HARD'>('NONE');
  const [rate, setRate] = useState<number>(0);
  return (
    <div>
      <div style={{ padding: 30 }}>
        <DifficultyRating rating={rating} />
        <br /><br />
        <select value='NONE' onChange={(e) => setRating(e.currentTarget.value as ('NONE' | 'VERY_EASY' | 'EASY' | 'NORMAL' | 'HARD' | 'VERY_HARD'))}>
          <option value='NONE'>없음</option>
          <option value='VERY_EASY'>쉬움</option>
          <option value='EASY'>다소 쉬움</option>
          <option value='NORMAL'>보통</option>
          <option value='HARD'>다소 어려움</option>
          <option value='VERY_HARD'>어려움</option>
        </select>
      </div>

      <div style={{ padding: 30 }}>
        <ProgressHeartRating max={5} value={rate} />
        <br /><br />
        <input type='number' value={rate} onChange={(e) => setRate(Number(e.currentTarget.value))} />
      </div>
      <div style={{ padding: '0 20px' }}>
        <JDAInformationBox>
          {`합격자가 평가한 긍정 지수, 존중 지수, 공정 지수를 합한 지표로\n온도가 높을 수록 면접 환경에 높은 평가를 부여한 것이에요.`}
        </JDAInformationBox>
        <JDAInformationBox
          backgroundColor={colors.cFDF69E}
          borderColor={colors.Y_100}
        >
          합격자가 평가한 긍정 지수, 존중 지수, 공정 지수를 합한 지표로 온도가 높을 수록 면접 환경에 높은 평가를 부여한 것이에요.
        </JDAInformationBox>
      </div>
      <div>
        <PassReviewListItem
          simpleInfo={PassReviewSimple.simpleInfoList[0]}
        />
      </div>
    </div>
  );
});

export default InterviewComponentsPage;
