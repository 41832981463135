import { ScrollDetail } from '@ionic/core/components';
import { IonFooter } from '@ionic/react';
import Nudge from 'components/Nudge';
import Footer from 'components/_v2/_common/Footer';
import HeaderHidableIonContent from 'components/common/HeaderHidableIonContent';
import { NavRenderer, NavType } from 'components/common/PageFrame';
import Error500 from 'components/error/Error500';
import IContextPageProps from 'interfaces/props/IContextPageProps';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components/macro';
import { debounce } from 'utils/CommonUtils';
import ScrollUtil from 'utils/ScrollUtil';
import browserHistory from 'utils/browserHistory';

export interface IPageFrameProps extends IContextPageProps {
  background?: string;
  header?: React.ReactElement;
  scrollRestore?: boolean;
  footer?: boolean;
  scrollInitialize?: boolean;
  scrollRestoreDelay?: number;
  scrollBehavior?: 'smooth' | 'auto';
  navType?: NavType;
}

export const ContentFrame = styled.div``;

export const StyledIonFooter = styled(IonFooter)`
  ::before {
    content: none;
  }
`;

let lastScrollTop = 0;

const TIMEOUT_DELAY = 1000;
const IonicPageFrame: FC<IPageFrameProps> = ({
  background,
  scrollRestoreDelay = TIMEOUT_DELAY,
  scrollBehavior,
  children,
  header,
  scrollInitialize = true,
  scrollRestore = false,
  context = new Context(),
  footer,
  navType = NavType.JOBDA,
  ...props
}) => {
  const { pathname } = useLocation();
  const ionContentRef = useRef<HTMLIonContentElement>(null);

  useEffect(() => {
    try {
      if (ionContentRef.current === null) return;

      ScrollUtil.setIonContentElement(ionContentRef.current);

      if (browserHistory.action === 'POP' && scrollRestore) {
        const top = context.contextHistory[pathname]?.scroll || 0.0001;
        const hideHeaderRestoreValue = context.contextHistory[pathname]?.hideHeader || false;
        setTimeout(async () => {
          await ionContentRef.current?.scrollToPoint(
            0,
            context.contextHistory[pathname]?.scroll || 0,
          );
          context.hideHeader = hideHeaderRestoreValue;
        }, scrollRestoreDelay);
      }
    } catch (e) {
      console.log(e);
    }
  }, [pathname]);

  useEffect(() => {
    try {
      if (ionContentRef.current === null) return;
      if (scrollInitialize) ionContentRef.current?.scrollToPoint(0, 0);
    } catch (e) {
      console.log(e);
    }

    return () => {};
  }, [pathname]);

  return (
    <>
      <HeaderHidableIonContent
        ref={ionContentRef}
        onIonScroll={(e: CustomEvent<ScrollDetail>) => {
          debounce(() => {
            const currentScrollTop = e.detail.scrollTop;
            context.hideHeader = lastScrollTop - currentScrollTop < 0;
            lastScrollTop = e.detail.scrollTop;

            if (pathname === document.location.pathname) {
              context.contextHistory[pathname] = {
                scroll: e.detail.scrollTop,
                hideHeader: context.hideHeader,
              };
            }
          }, 100);
          if (ScrollUtil.mobileEventListener) ScrollUtil.mobileEventListener();
        }}
      >
        {header}
        <ContentFrame>
          {children}
          {footer && <Footer />}
        </ContentFrame>
        {context.error500 && <Error500 />}
        <Nudge />
      </HeaderHidableIonContent>
      <StyledIonFooter>
        {!context.hideBottomNav && <NavRenderer navType={navType} />}
      </StyledIonFooter>
    </>
  );
};

export default inject(injectStore.context)(observer(IonicPageFrame));
