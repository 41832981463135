import { JDModalButton, JDModalButtonGhost } from 'components/_v2/_common/modals/JDModalComponents';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import IModalFrameProps from 'interfaces/IModalFrameProps';
import React, { FC } from 'react';
import styled from 'styled-components';
import { JDAModalContentDescription, JDAModalContentText } from './JDAModalComponents';
import JDAModalFrame from './JDAModalFrame';

const StyledModalContent = styled.div`
  text-align: center;
  padding: 24px;
  
  ${JDAModalContentText} {
    margin-top: 16px;
    margin-bottom: 8px;
    font: ${Fonts.H5_Bold};
    color: ${colors.JOBDA_BLACK};
  }

  ${JDAModalContentDescription} {
      font: ${Fonts.B2_Medium_P};
      margin-bottom: 12px;
      color: ${colors.CG_70};
  }

  ${JDModalButton} {
    margin-bottom: 8px;
  }

  .schedule-frame {
    display: flex;
    flex-direction: column;
    padding: 20px 16px;
    margin-bottom: 8px;
    background: ${colors.CG_30};
    border-radius: 4px;

    div {
      display: flex;

    }
    .title {
      text-align: left;
      font: ${Fonts.B2_Bold};
      color: ${colors.CG_90};
      flex:none;
    }
    
    p:first-child {
      margin-bottom: 8px;
    }
    
    .schedule-location {
      max-width: 232px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .desc {
      margin-left: 12px;
      font: ${Fonts.B2_Medium};
      color: ${colors.CG_80};
      text-align: left;
    }
  }
`;

const JDAAlertContent = styled.p`
    font: ${Fonts.B3_Medium};
    color: ${colors.ERROR};
    padding: 0 23.5px;
    margin-bottom: 20px;
`;
interface IMatchMessageAcceptModalProps extends IModalFrameProps {
    onClickApply: (e: React.MouseEvent<HTMLElement>) => void;
    schedule?: boolean;
    scheduleLocation?: string;
    scheduleTime?: string;
}
const MatchMessageAcceptModal:FC<IMatchMessageAcceptModalProps> = (props) => {
  const { onClickApply, onClickClose, schedule, scheduleLocation, scheduleTime, ...rest } = props;

  return (
    <JDAModalFrame {...rest}>
      <div style={{ width: 312 }}>
        <StyledModalContent>
          <JDAModalContentText>
            제안을 수락하시겠습니까?
          </JDAModalContentText>
          <JDAModalContentDescription>
            면접 장소와 시간을 잘 확인해 주세요. <br />
            수락한 제안은 취소가 불가능해요.<br />
          </JDAModalContentDescription>
          { schedule
            && (
            <div className='schedule-frame'>
              {scheduleLocation && <div><p className='schedule-location title'>면접 장소</p> <p className='desc'>{scheduleLocation}</p></div>}
              {scheduleTime && <div><p className='title'>면접 시간</p><p className='desc'>{scheduleTime}</p></div>}
            </div>
            )}
          <JDModalButton id='acceptModalClick' onClick={(e) => onClickApply(e)}>수락하기</JDModalButton>
          <JDModalButtonGhost onClick={onClickClose}>아니오</JDModalButtonGhost>
        </StyledModalContent>
      </div>
    </JDAModalFrame>
  );
};

export default MatchMessageAcceptModal;
