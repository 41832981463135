import React from 'react';

import classnames from 'classnames/bind';
import CuratorList from './curatorList';
import styles from './matching.module.scss';

const cx = classnames.bind(styles);

interface FlowStoryProps {
  fadeIn: boolean;
}

const Matching = ({ fadeIn }: FlowStoryProps) => (
  <div className={cx('commonBox', { on: fadeIn })}>
    <p className={cx('subTitle')}>나와 맞지 않는 공고 추천?<br />무분별한 AI 매칭?</p>
    <div className={cx('mainTitle', 'lineBg')}>
      <p className={cx('light')}>다른 취업 플랫폼처럼</p>
      단순한 공고 추천은
      <br />
      이제 그만!
    </div>

    {/* 매칭 내용 두가지 */}
    <div className={cx('matchingInner')}>
      <div className={cx('matchingBox', 'firstBox')}>
        <div className={cx('contentsInner', 'firstContents')}>
          <span className={cx('subText')}>스펙, 경력없는 신입도</span>
          <div className={cx('mainTitle')}>
            <p className={cx('light')}>오직 역량만으로</p>
            쏟아지는 입사 제안
          </div>
        </div>
      </div>

      <div className={cx('matchingBox', 'secondBox')}>
        <div className={cx('contentsInner')}>
          <span className={cx('subText')}>JOBDA matching 참여</span>
          <div className={cx('mainTitle')}>
            <p className={cx('light')}>전문 큐레이터가<br />나를 추천하고</p>
            기업이 직접 채용
          </div>
        </div>
      </div>
    </div>

    {/* 큐레이터 */}
    <div className={cx('curatorInner')}>
      <div className={cx('mainTitle')}>
        <p className={cx('light')}>나에게 최적화된<br />회사를 매칭해주는</p>
        직무별 전문 큐레이터
      </div>
      <CuratorList />
    </div>
  </div>
);

export default Matching;
