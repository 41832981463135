import colors from '__designkit__/common/colors';
import { JDMainButton } from 'consts/_v2/_common/style/mixins';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import Fonts from '__designkit__/common/fonts';

const Frame = styled.div<{fixed:boolean}>`
    ${(props) => props.fixed && css`
      position: fixed; 
      z-index: 9999;
      border-top: 1px solid ${colors.CG_40};
    `}
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: env(safe-area-inset-bottom);
    padding: 16px;
    padding-bottom: calc(16px + env(safe-area-inset-bottom));
    width: 100%;
    height:calc(78px + env(safe-area-inset-bottom));
    background: ${colors.WHITE_100};

    .confirm {
      ${JDMainButton(false, '100%')}
      height: 46px;
      text-align: center;
      border-radius: 4px;
      font: ${Fonts.B2_Bold};
      :disabled{
        background: ${colors.CG_50};
      }
    }
`;

interface IJDSaveButton extends IComponentProps {
    disabled?: boolean;
    onSubmit?: () => void;
    fixed?:boolean;
    register?:boolean;
    form?: string;
}

const JDSaveButton:FC<IJDSaveButton> = ({ disabled, onSubmit, props, fixed = true, register, form }) => (
  <Frame fixed={fixed}>
    <button
      form={form || undefined}
      className='confirm'
      disabled={disabled}
      onClick={onSubmit}
      {...props}
    >
      {register ? '등록' : '저장' }

    </button>
  </Frame>
);

export default JDSaveButton;
