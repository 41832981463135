import URIs from 'consts/URIs';
import { IDuplicateOrNotRs, IGetCheckMobileDuplicateRq, IGetProfileBasicInfoRs, IPutProfileBasicInfoRq } from 'interfaces/_v2/profile/IBasicInfoRqRs';
import { action, observable } from 'mobx';
import store from 'models/store';
import { request } from 'utils/request';

// Profile 기본정보 전체 Model
export default class ProfileBasicInfoModel {
  @observable baseInfo: IGetProfileBasicInfoRs | null = null;
  @observable uploadImageUid: string | null= null;

  // 휴대폰 번호 중복 체크
  @action
  checkMobileDuplicate = async (params: IGetCheckMobileDuplicateRq): Promise<boolean> => {
    try {
      const res = await request<IDuplicateOrNotRs>({
        method: 'get',
        url: URIs.get_user_duplication_check_mobile,
        params,
      });
      return res.isDuplicate;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // 프로필 기본 정보 조회
  @action
  loadBaseInfo = async (): Promise<void> => {
    try {
      const res = await request<IGetProfileBasicInfoRs>({
        method: 'get',
        url: URIs.get_profiles_basic_info,
      });
      this.baseInfo = res;
      this.uploadImageUid = res.profileImageUid;
      if (res.name) store.profileModel.snbCheckEssential.basicInfo.completeYn = true;
      else store.profileModel.snbCheckEssential.basicInfo.completeYn = false;
    } catch (e) {
      console.error(e);
    }
  }

  // 프로필 기본 정보 수정
  @action
  saveBaseInfo = async (baseInfo: Partial<IPutProfileBasicInfoRq>): Promise<boolean> => {
    try {
      const res = await request<boolean>({
        method: 'put',
        url: URIs.put_profiles_basic_info,
        data: { ...baseInfo },
      });
      return res;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  @action
  savePhoto = async (profileImageUid: string | null) => {
    try {
      await request({
        method: 'put',
        url: URIs.put_profiles_file_image,
        data: { profileImageUid: profileImageUid || null },
      });
    } catch (e) {
      console.error(e);
    }
  }

  @action
  removePhoto = () => {
    if (this.baseInfo && this.baseInfo.profileImageUrl) {
      this.baseInfo.profileImageUrl = null;
      this.baseInfo.profileImageUid = null;
    }
  }
}
