import JDBaseModal, { IJDBaseModal } from 'components/_v2/_common/modals/JDBaseModal';
import { JDModalBasicFrame, JDModalDescription, JDModalFullButton, JDModalTitle } from 'components/_v2/_common/modals/JDModalComponents';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import MatchHomeDashboardModel from 'models/_v2/MatchHomeDashboardModel';
import React, { FC } from 'react';
import styled from 'styled-components';

const Frame = styled.div`

    ${JDModalDescription} {
        margin-top: 8px;
        margin-bottom: 12px;
    }
`;

export enum MatchDashboardInfoType {
  END_JF3 = 'END_JF3'
}

interface IMatchHomeDashboardInfoModal extends IJDBaseModal {
  matchHomeDashboardModel?: MatchHomeDashboardModel;
}

const MatchHomeDashboardInfoModal: FC<IMatchHomeDashboardInfoModal> = ({ isOpen, onClickClose, matchHomeDashboardModel = new MatchHomeDashboardModel() }) => {
  const rendingContent = (type: MatchDashboardInfoType | null) => {
    switch (type) {
      case MatchDashboardInfoType.END_JF3:
        return (
          <>
            <JDModalTitle>공고를 확인할 수 없어요.</JDModalTitle>
            <JDModalDescription>전형이 종료되거나 비공개 처리되었습니다.</JDModalDescription>
            <JDModalFullButton onClick={onClickClose}>확인</JDModalFullButton>
          </>
        );
      default:
        return (
          <></>
        );
    }
  };

  return (
    <JDBaseModal dimmed isOpen={isOpen} onClickClose={onClickClose} isDimClickClose={false}>
      <Frame>
        <JDModalBasicFrame>
          {
            rendingContent(matchHomeDashboardModel.infoModal.type)
          }
        </JDModalBasicFrame>
      </Frame>
    </JDBaseModal>
  );
};
export default inject(injectStore.matchHomeDashboardModel)(observer(MatchHomeDashboardInfoModal));
