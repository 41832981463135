import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import JDBaseModal, { IJDBaseModal } from 'components/_v2/_common/modals/JDBaseModal';
import { JDModalFullButton, JDModalTitle } from 'components/_v2/_common/modals/JDModalComponents';
import RoutePaths from 'consts/RoutePaths';
import { DateTime } from 'luxon';
import React, { FC } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { DateFormatYYYYMMDDCCHHMMUnit } from 'utils/DateUtils';

const Frame = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 312px;   
    height: fit-content;
    padding: 20px;
    border-radius: 12px;
    background-color: ${colors.WHITE_100};

    .icon {
        margin-top: 24px;
    }

    ${JDModalTitle} {
        margin-top: 8px;
    }

    .modal-content-frame {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 8px;
        padding: 24px;
        background-color: ${colors.CG_30};
        border-radius: 8px;

        .content-line {
            display: flex;
            text-align: left;
            align-items: center;
            margin-bottom: 8px;
            
            .title {
                font: ${Fonts.B3_Bold};
                color: ${colors.CG_90};
            }

            .content {
                margin-left: 2px;
                font: ${Fonts.B3_Medium};
                color: ${colors.CG_90};
            }
        }
        
        .tip {
            margin-top: 8px;
            font: ${Fonts.B4_Medium};
            color: ${colors.CG_60};

            >b {
                font: ${Fonts.B4_Medium};
                color: ${colors.CG_80};
            }
        }
    }
`;

interface IPositionApplySuccessModalProps extends IJDBaseModal {
    positionName: string;
    companyName: string;
    positionSn: number;
}
const PositionApplySuccessModal: FC<IPositionApplySuccessModalProps> = ({ isOpen, onClickClose, positionName, companyName, positionSn }) => {
  const history = useHistory();
  return (
    <JDBaseModal isOpen={isOpen} onClickClose={onClickClose} isDimClickClose isDimCloseBtn>
      <Frame>
        <Icon className='icon' name='check-circle-filled' size={48} color={colors.G_100} />
        <JDModalTitle>지원이 완료되었습니다.</JDModalTitle>
        <div className='modal-content-frame'>
          <div className='content-line'>
            <div className='title'>포지션명 : </div>
            <div className='content'>{positionName}</div>
          </div>
          <div className='content-line'>
            <div className='title'>기업 : </div>
            <div className='content'>{companyName}</div>
          </div>
          <div className='content-line'>
            <div className='title'>지원완료일시 : </div>
            <div className='content'>{DateFormatYYYYMMDDCCHHMMUnit(DateTime.local().toISO())}</div>
          </div>
          <div className='tip'>
            *제출한 지원서는 <b>지원 현황 &gt; 해당 포지션</b> 클릭시 확인 가능합니다.
          </div>
        </div>
        <JDModalFullButton onClick={() => history.push(RoutePaths.mypage_apply_Message(positionSn))}>지원 내역 확인하기</JDModalFullButton>
      </Frame>
    </JDBaseModal>
  );
};

export default PositionApplySuccessModal;
