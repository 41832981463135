//* * 스마트 필터링 필터 분류 */
export enum TagCategory {
  ON_WORK = 'ON_WORK',
  OFF_WORK = 'OFF_WORK',
  REST = 'REST',
  SELF_DEVELOPMENT = 'SELF_DEVELOPMENT',
  WORKING_CULTURE = 'WORKING_CULTURE',
  WELFARE_CULTURE = 'WELFARE_CULTURE',
  TEAM_CULTURE = 'TEAM_CULTURE',
  COMPANY_CULTURE = 'COMPANY_CULTURE',
}

export const TagCategoryText:Record<keyof typeof TagCategory | TagCategory, string> = {
  ON_WORK: '출근',
  OFF_WORK: '퇴근',
  REST: '휴식',
  SELF_DEVELOPMENT: '자기계발',
  WORKING_CULTURE: '일하는 문화',
  WELFARE_CULTURE: '복지 문화',
  TEAM_CULTURE: '팀 문화',
  COMPANY_CULTURE: '기업 문화',
};

export default TagCategory;
