import IconClose from 'assets/icon/icon_close_24.svg';
import AccPstGrade from 'components/_v2/acc/AccPstGrade';
import JDBaseModal, { IJDBaseModal } from 'components/_v2/_common/modals/JDBaseModal';
import { JDButtonTwoChoiceFrame, JDModalBasicFrame, JDModalButton, JDModalButtonGhost, JDModalTitle } from 'components/_v2/_common/modals/JDModalComponents';
import { IconCheck } from 'consts/assets/icons/iconPages';
import { PhsAccResultType, PhsAccType } from 'consts/MatchingMessageType';
import colors from '__designkit__/common/colors';
import { AccGradeResultType } from 'consts/_v2/acc/AccApplyType';
import { PstGradeResultType } from 'consts/_v2/phs/pst/PstApplyType';
import Fonts from '__designkit__/common/fonts';
import { inject, observer } from 'mobx-react';
import MatchMessageStore from 'models/MatchMessageStore';
import { injectStore } from 'models/store';
import TermsModel from 'models/TermsModel';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import JDCheckInput, { JD_CHECK_INPUT_TYPE } from '../_common/input/JDCheckInput';

const Frame = styled.div`

  ${JDModalBasicFrame} {
    padding-top: 52px;
  }

  ${JDButtonTwoChoiceFrame} {
    margin-top: 24px;
  }

  ${JDModalButtonGhost} {
    margin-right: 16px;
  }
  
  .icon-close {
    position: fixed;
    top: 20px;
    right: 20px;
  }

  .title {
    margin-top: 20px;
    margin-bottom: 8px;
    font: ${Fonts.B3_Medium};
    line-height: 16px;
    color: ${colors.JOBDA_BLACK};
  }

  .result-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 12px;
    border: 1px solid ${colors.CG_50};
    border-radius: 4px;

    .date {
      font: ${Fonts.B3_Medium};
      line-height: 16px;
      color: ${colors.JOBDA_BLACK};
    }
  }
  .sub-agree-frame{
    display:flex;
    flex-direction:column;
    width:100%;
    max-height:90px;
    padding:12px 16px;
    margin-top:8px;
    overscroll-behavior:contain;
    overflow:auto;
    background-color:${colors.CG_30};
    font: ${Fonts.B4_Medium_P};
    color:${colors.CG_70};


  }
  .description {
      margin-top: 20px;
      li {
          display: flex;
          align-items: flex-start;
      }

      .icon-check {
        margin-top: 2.5px;
      }

      li:not(:last-child) {
          margin-bottom: 6px;
      }

      h3 {
        font: ${Fonts.B3_Medium};
        line-height: 18px;
        color: ${colors.JOBDA_BLACK};
        margin-left: 10px;
    }
  }
  .check-frame{
    margin-top: 20px;
  }
`;

interface IMessageAccPhsSubmitModal extends IJDBaseModal {
  onBack: () => void;
  submitType: PhsAccType;
  time: string;
  accGrade?: AccGradeResultType;
  pstGrade?: PstGradeResultType;
  progressYn: boolean;
  termsModel?:TermsModel;
  matchMessageStore? : MatchMessageStore
  companySn?:number;

}

const MessageAccPhsSubmitModal:FC<IMessageAccPhsSubmitModal> = ({ isOpen, onClickClose, progressYn, onBack, onSuccess, submitType, time, accGrade, pstGrade, termsModel = new TermsModel(), companySn }) => {
  const [isSubList, setIsSubList] = useState<boolean>(false);
  const useFormed = useForm({ mode: 'onSubmit' });
  const { setValue, watch } = useFormed;

  const handleSubmit = async () => {
    if (termsModel.resultPrivacyProvision && companySn) {
      await termsModel.saveTermsResultPrivacyProvision(companySn, termsModel.resultPrivacyProvision?.sn);
      onSuccess && onSuccess();
    }
  };

  useEffect(() => {
    const init = async () => {
      let termsType:PhsAccResultType;
      if (submitType === 'ACC') {
        termsType = PhsAccResultType.ACC_RESULT_PRIVACY_PROVISION;
      } else if (submitType === 'PACC') {
        termsType = PhsAccResultType.PACC_RESULT_PRIVACY_PROVISION;
      } else {
        termsType = PhsAccResultType.PST_RESULT_PRIVACY_PROVISION;
      }
      if (companySn) {
        await termsModel.loadTermsResultPrivacyProvision(companySn, termsType);
      }
    };

    init();
  }, [submitType, companySn]);
  return (
    <FormProvider {...useFormed}>

      <JDBaseModal dimmed isOpen={isOpen} onClickClose={onClickClose}>
        <Frame>
          <JDModalBasicFrame>
            <button className='icon-close' onClick={onClickClose}><img src={IconClose} alt='icon-close' /></button>
            <JDModalTitle>제출 전 꼭 확인하세요.</JDModalTitle>
            <p className='title'>제출할 역량검사 결과</p>
            <div className='result-box'>
              <span className='date'>{time}</span>
              <AccPstGrade
                accGrade={accGrade}
                pstGrade={pstGrade}
                type={submitType}
                progressYn={progressYn}
              />
            </div>
            <ul className='description'>
              <li><IconCheck className='icon-check' /><h3>응시 결과 제출 후에는 수정이 어려울 수 있어요.</h3></li>
              <li><IconCheck className='icon-check' /><h3>분석 중에도 제출은 가능하지만 등급은 분석 완료 이후 확인할 수 있어요.</h3></li>
              <li><IconCheck className='icon-check' /><h3>제출된 결과는 지원현황에서 계속 확인할 수 있어요.</h3></li>
            </ul>
            {
        termsModel.resultPrivacyProvision
        && (
        <>
          <div className='check-frame'>
            <JDCheckInput
              name='privacyProvisionYn'
              label={termsModel.resultPrivacyProvision.title}
              type={JD_CHECK_INPUT_TYPE.CHECK_BOX_SMALL}
              value
              isMoreListBtn
              upDown
              onClickMoreListBtn={() => setIsSubList(!isSubList)}
              onChange={() => {
                if (watch('privacyProvisionYn')) setValue('privacyProvisionYn', '');
                else setValue('privacyProvisionYn', false);
              }}
            />
          </div>
          <div style={{ display: isSubList ? '' : 'none' }} className='sub-agree-frame' dangerouslySetInnerHTML={{ __html: termsModel.resultPrivacyProvision.contents }} />
        </>
        )
      }

            <JDButtonTwoChoiceFrame>
              <JDModalButtonGhost onClick={onBack}>다시 선택</JDModalButtonGhost>
              <JDModalButton disabled={watch('privacyProvisionYn') === false} onClick={handleSubmit}>최종 제출</JDModalButton>
            </JDButtonTwoChoiceFrame>
          </JDModalBasicFrame>
        </Frame>
      </JDBaseModal>
    </FormProvider>
  );
};

export default inject(injectStore.termsModel)(observer(MessageAccPhsSubmitModal));
