import { Button, ButtonMode } from '__designkit__/button/Button';
import colors from '__designkit__/common/colors';
import React, { FC } from 'react';
import styled from 'styled-components';

const SubmitButtonFrame = styled.div`
    width: 100%;
    position: fixed;
    bottom: calc(0px + env(safe-area-inset-bottom));
    display: flex;
    padding: 16px;
    align-items: center;
    justify-content: center;
    border-top: 1px solid ${colors.CG_40};
    margin-top: auto;
    background-color: ${colors.WHITE_100};
    
    .default-btn {
      margin-right: 8px;
    }

`;

interface IJDBottomFixedButtonProps {
    defaultLabel?: string;
    primaryLabel: string;
    defaultClick?: () => void;
    handleSuccess?: () => void;
    disabled?: boolean;
}
const JDBottomFixedButton:FC<IJDBottomFixedButtonProps> = ({ defaultClick, handleSuccess, disabled, defaultLabel, primaryLabel }) => (
  <SubmitButtonFrame>
    <Button className='default-btn' buttonMode={ButtonMode.DEFAULT} outLined size='large' onClick={defaultClick} label={defaultLabel} />
    <Button className='primary-btn' disabled={disabled} buttonMode={ButtonMode.PRIMARY} size='large' onClick={handleSuccess} label={primaryLabel} />
  </SubmitButtonFrame>
);

export default JDBottomFixedButton;
