import React from 'react';

import classnames from 'classnames/bind';
import styles from './banner.module.scss';

const cx = classnames.bind(styles);

const Banner = () => (
  <div className={cx('wrap')}>
    <div className={cx('title')}>
      참가자 18,927명 중
      <br />
      <span className={cx('emphasis')}>나의 등급</span>을 확인해 보세요!
    </div>
  </div>
);

export default Banner;
