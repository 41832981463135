import ProfileExperienceForeignExperiencesCard from 'components/_v2/profile/experience/ProfileExperienceForeignExperiencesCard';
import FormProfileSectionTitle from 'components/_v2/_common/form/FormProfileSectionTitle';
import { IProfileProps } from 'interfaces/_v2/profile/IProfileProps';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import ProfileModel from 'models/_v2/profile/ProfileModel';
import React, { FC } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
    position:relative;
    padding: 32px 16px 128px 16px;
    .activity-absolute{
      position: absolute;
      top:32px;
    }

`;
interface IProfileExperienceForeignExperiencesProps extends IProfileProps{
    hidden:boolean
}
const ProfileExperienceForeignExperiences:FC<IProfileExperienceForeignExperiencesProps> = ({ profileModel = new ProfileModel(), hidden }) => (
  <div aria-hidden={hidden}>

    <Frame>
      <FormProfileSectionTitle className='activity-absolute'>해외 경험</FormProfileSectionTitle>
      <ProfileExperienceForeignExperiencesCard />
    </Frame>

  </div>
);

export default inject(injectStore.profileModel)(observer(ProfileExperienceForeignExperiences));
