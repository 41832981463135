import ChattingAPI from 'api/chattingAPI';
import ChannelLongTouchMenu from 'components/_v2/chatting/ChannelLongTouchMenu';
import ChattingAppInduction from 'components/_v2/chatting/ChattingAppInduction';
import ChattingHeader from 'components/_v2/chatting/ChattingHeader';
import ChattingMain from 'components/_v2/chatting/ChattingMain';
import ChattingRoom from 'components/_v2/chatting/ChattingRoom';
import ChattingSearch from 'components/_v2/chatting/ChattingSearch';
import RoutePaths from 'consts/RoutePaths';
import { ChattingServerError } from 'interfaces/_v2/chatting/IChattingRqRs';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import ChattingModel from 'models/_v2/ChattingModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import styled from 'styled-components';

const Frame = styled.div`
  width: 100vw;
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
`;

const ChattingContents = styled.div`
  width: 100%;
  height: calc(100% - 56px);
`;

interface IChattingPageProps {
  login?: Login;
  chattingModel?: ChattingModel;
}

const ChattingPage: FC<IChattingPageProps> = ({ login = new Login(), chattingModel = new ChattingModel() }) => {
  const [isUserInit, setIsUserInit] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        // talk-plus 로그인 처리 (잡다 로그인 유저라면 토큰 로그인, 아니라면 익명 로그인)
        if (login.userInfo) {
          if (!chattingModel.user) {
            const token = await ChattingAPI.getToken();
            const { user } = await chattingModel.client.loginWithToken({ userId: login.userInfo.id, loginToken: token, data: { isAnonymous: false } });
            chattingModel.setUser(user);
          }
        } else {
          const { user } = await chattingModel.client.loginAnonymous({ userId: 'jobda', data: { isAnonymous: true } });
          chattingModel.setUser(user);
        }

        setIsUserInit(true);

        // 전체 채널 목록을 가져오고, 익명 로그인이라면 talk-plus 로그아웃 처리
        if (!chattingModel.allChannelList) {
          chattingModel.setAllChannelList(await ChattingAPI.getAllChannel(chattingModel.client));
        }
        if (chattingModel?.user?.data?.isAnonymous) {
          await chattingModel.client.logout();
          chattingModel.setUser(null);
        }
      } catch (e) {
        const err = e as ChattingServerError;
        console.error(err);
      }
    })();

    return () => {
      chattingModel.reset();
    };
  }, []);
  return (
    <Frame>
      <ChattingHeader />
      <ChattingContents>
        <Switch>
          <Route path={RoutePaths.chat} render={() => (<Redirect to={RoutePaths.chatList} />)} exact />
          <Route path={RoutePaths.chatList} render={() => (<ChattingMain />)} exact />
          <Route path={RoutePaths.chatSearch} render={() => (<ChattingSearch />)} exact />
          <Route path={RoutePaths.chatChannel} render={() => (<ChattingRoom isUserInit={isUserInit} />)} exact />
        </Switch>
      </ChattingContents>
      <ChannelLongTouchMenu />
      <ChattingAppInduction />
    </Frame>
  );
};

export default inject(injectStore.login, injectStore.chattingModel)(observer(ChattingPage));
