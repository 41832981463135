// Created by ksy0105 on 2022-04-12 ATS
import classNames from 'classnames/bind';
import React, { FC, FormEvent, useCallback, useMemo, useState } from 'react';
import styles from './MultiRange.module.scss';

const cx = classNames.bind(styles);

type Props = {
  min: number;
  max: number;
  leftValue: number;
  rightValue: number;
  minValue?: number;
  maxValue?: number;
  setLeftValue: (leftValue: number) => void;
  setRightValue: (leftValue: number) => void;
  step?: number;
  color?: 'black' | 'sky' | 'blue' | 'purple' | 'green';
  dots?: { value: number; label: string }[];
  tooltipDirection?: 'top' | 'bottom';
  disabledRightValue?: boolean;
};

const MultiRange: FC<Props> = ({
  min,
  max,
  leftValue,
  rightValue,
  minValue,
  maxValue,
  setLeftValue,
  setRightValue,
  step = 1,
  color = 'black',
  dots = [],
  tooltipDirection = 'top',
  disabledRightValue,
}) => {
  const [onMouseDownLeftThumb, setOnMouseDownLeftThumb] = useState(false);
  const [onMouseDownRightThumb, setOnMouseDownRightThumb] = useState(false);

  const leftPercent = useMemo(() => `${((leftValue - min) / (max - min)) * 100}%`, [leftValue, min, max]);
  const rightPercent = useMemo(() => `${100 - ((rightValue - min) / (max - min)) * 100}%`, [rightValue, min, max]);

  const onInputLeftThumb = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      const value = Number(e.currentTarget.value);
      if (minValue && value < minValue) {
        setLeftValue(minValue);
      } else {
        setLeftValue(Math.min(value, rightValue - step));
      }
    },
    [rightValue, setLeftValue, minValue, step],
  );
  const onInputRightThumb = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      const value = Number(e.currentTarget.value);
      if (maxValue && value > maxValue) {
        setRightValue(maxValue);
      } else {
        setRightValue(Math.max(value, leftValue + step));
      }
    },
    [leftValue, setRightValue, maxValue, step],
  );

  const DotListEl = dots.map((dot, idx) => {
    const { value, label } = dot;
    const labelEl = label ? <span className={cx('value')}>{dot.label}</span> : <></>;

    return (
      <div
        key={idx}
        className={cx('dot', value >= leftValue && value <= rightValue && 'active')}
        style={{ left: `${((value - min) / (max - min)) * 100}%` }}
      >
        {labelEl}
      </div>
    );
  });

  return (
    <section className={cx('container')}>
      <input
        type='range'
        min={min}
        max={max}
        step={step}
        value={leftValue}
        onInput={onInputLeftThumb}
        onMouseDown={() => setOnMouseDownLeftThumb(true)}
        onMouseUp={() => setOnMouseDownLeftThumb(false)}
      />
      <input
        type='range'
        min={min}
        max={max}
        step={step}
        value={rightValue}
        onInput={onInputRightThumb}
        onMouseDown={() => setOnMouseDownRightThumb(true)}
        onMouseUp={() => setOnMouseDownRightThumb(false)}
      />

      <div className={cx('slider', color)}>
        <div className={cx('track')} />
        <div className={cx('range')} style={{ left: leftPercent, right: rightPercent }} />
        {dots.length > 0 && <div className={cx('dots')}>{DotListEl}</div>}
        <div className={cx('thumb', 'left', onMouseDownLeftThumb && 'active')} style={{ left: leftPercent }}>
          {onMouseDownLeftThumb && (
            <div className={cx('tooltip', `tooltip-${tooltipDirection}`)}>
              {leftValue}
              <span className={cx('arrow')} />
            </div>
          )}
        </div>
        <div className={cx('thumb', 'right', onMouseDownRightThumb && 'active', disabledRightValue && 'disabledRightValue')} style={{ right: rightPercent }}>
          {onMouseDownRightThumb && (
            <div className={cx('tooltip', `tooltip-${tooltipDirection}`)}>
              {rightValue}
              <span className={cx('arrow')} />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default MultiRange;
