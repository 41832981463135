import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import styled from 'styled-components';
import IconInfo from 'assets/_v2/_common/icon_info.svg';

const FormProfileSectionTitle = styled.h2<{ info?: boolean}>`
  display: flex;
  align-items: center;
  font: ${Fonts.H5_Bold};
  line-height: 22px;
  color: ${colors.JOBDA_BLACK};
  letter-spacing: -0.01em;
  margin-bottom: 20px;
  ${(props) => props.info && `
     :after{
      content: ''; 
      width: 16px;
      height: 16px;
      background-image: url(${IconInfo});
      margin-left: 4px;
    }
  `};
`;

export default FormProfileSectionTitle;
