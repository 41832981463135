import { ReactComponent as IconMoreLine } from 'assets/_v2/_common/icon_more_vert_24_line.svg';
import { MatchDashboardInfoType } from 'components/_v2/matchHome/dashboard/MatchHomeDashboardInfoModal';
import MatchHomeDashboardMoreBox from 'components/_v2/matchHome/dashboard/MatchHomeDashboardMoreBox';
import colors from '__designkit__/common/colors';
import { MatchHomeDashboardPositionType } from 'consts/_v2/matchHome/dashboard/MatchHomeDashboardType';
import Fonts from '__designkit__/common/fonts';
import { lineClampBox, lineClampOnlyOne } from 'consts/_v2/_common/style/mixins';
import { ApplicationPositionType, IMatchDashboardPosition, JF3PageType, VisibilityType } from 'interfaces/_v2/matchHome/dashboard/IMatchHomeDashboard';
import { inject, observer } from 'mobx-react';
import MatchMessageStore from 'models/MatchMessageStore';
import { injectStore } from 'models/store';
import MatchHomeDashboardModel from 'models/_v2/MatchHomeDashboardModel';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { DateFormatMMDDDot, DateFormatYYYYMMDDWithDot, getDDayString, overDateNow, str2DateTime } from 'utils/DateUtils';
import { windowOpenUtils } from 'utils/_v2/windowOpenUtils';
import { Button } from '__designkit__/button/Button';
import Icon from '__designkit__/icon/Icon';
import { LazyImageLogo } from 'components/common/LazyImage';
import { MatchTabType } from 'consts/_v2/match/MatchType';

const StyledMatchHomeDashboardCard = styled.div<{ type: MatchHomeDashboardPositionType, positiontype: ApplicationPositionType, closeYn?: boolean }>`
position: relative;
cursor: pointer;
height: fit-content;
display: flex;
flex-direction: column;
padding: 24px 16px 24px 16px;
border-bottom: 1px solid ${colors.CG_40};
background: ${(props) => (props.type === MatchHomeDashboardPositionType.INTERVIEW_DENIED || props.type === MatchHomeDashboardPositionType.INTERVIEW_FAILED || (props.type === MatchHomeDashboardPositionType.WRITING_RESUME && props.closeYn) ? colors.JOBDA_WHITE : colors.WHITE_100)};

.position-detail{
  display: flex;
  gap:12px;
  .company-logo{
    width: 48px;
    height: 48px;
    border:1px solid ${colors.CG_30};
  }
  .position-frame{
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap:8px;
    .name-frame{
      display: flex;
      align-items: center;
      .position-name{
      max-width: 220px;
      ${lineClampOnlyOne()}
      font:${Fonts.B1_Bold};
      color:${colors.JOBDA_BLACK};
      margin-right: 4px;
    }
    }
    
    .company-name{
      max-width: 220px;
      min-width: 220px;
      ${lineClampOnlyOne()}
      font:${Fonts.B3_Medium};
      color:${colors.CG_70};
    }
  }
}
.position-progress-writing{
  display: flex;
  margin-top: 16px;
  align-items: center;
    font:${Fonts.B2_Bold_P};
  .gray{
    color:${colors.CG_60};
    
  }
  .green{
    color:${colors.G_200};
  }
  .white{
    color:${colors.CG_60};
  }

  .black{
    color:${colors.CG_90}
  }
  }
.position-type-frame{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  .position-progress{
    font:${Fonts.B2_Bold_P};
  .gray{
    color:${colors.CG_60};
    
  }
  .green{
    color:${colors.G_200};
  }
  .white{
    color:${colors.CG_60};
  }

  .black{
    color:${colors.CG_90}
  }
  }
  .position-date{
    display: flex;
    .matching-yn{
      font:${Fonts.B2_Medium_P};
      color:${colors.G_200};
      display: flex;
      align-items: center;
      &::after{
        display: flex;
        width: 1px;
        height: 8px;
        content: '';
        margin:0 8px;
        background-color:${colors.CG_40};
      }
    }
    .date{
      font:${Fonts.B2_Medium_P};
      color:${colors.CG_60};
    }
  }
}

.icon-more-line {
  position: absolute;
  top:24px;
  right:16px;
}
`;

interface IMatchHomeDashboardCard {
  item: IMatchDashboardPosition;
  matchMessageStore?: MatchMessageStore;
  matchHomeDashboardModel?: MatchHomeDashboardModel;
  type:MatchTabType;
  pdfModalOpen?: boolean;
  isSelected?: boolean;
  onCardClick?: () => void;
}

const MatchHomeDashboardCard: FC<IMatchHomeDashboardCard> = ({ pdfModalOpen = false, type, matchHomeDashboardModel = new MatchHomeDashboardModel(), item, matchMessageStore = new MatchMessageStore(), isSelected = false, onCardClick }) => {
  const [viewMoreBox, setViewMoreBox] = useState<boolean>(false);

  const getChip = () => {
    switch (item.jobApplicationProcessType) {
      case MatchHomeDashboardPositionType.WRITING_RESUME:
        if (!item.closingDateTime) return <div className='black'>상시 채용</div>;
        if (item.closingDateTime && overDateNow(item.closingDateTime)) return <div className='gray'>포지션 마감</div>;
        return <div className='black'>{getDDayString(str2DateTime(item.closingDateTime))}</div>;
      case MatchHomeDashboardPositionType.APPLY:
        return <div className='black'>입사 지원</div>;
      case MatchHomeDashboardPositionType.INTERVIEW_OFFERED:
        return <div className='black'>면접 요청</div>;
      case MatchHomeDashboardPositionType.INTERVIEW_ACCEPTED:
        return <div className='gray'>면접 수락</div>;
      case MatchHomeDashboardPositionType.INTERVIEW_DENIED:
        return <div className='gray'>면접 거절</div>;
      case MatchHomeDashboardPositionType.INTERVIEW_ADJUSTED:
        return <div className='black'>면접 조율 중</div>;
      case MatchHomeDashboardPositionType.ACC_REQUEST:
        return <div className='black'>역량검사 제출 필요</div>;
      case MatchHomeDashboardPositionType.ACC_SUBMIT:
        return <div className='black'>역량검사 제출 완료</div>;
      case MatchHomeDashboardPositionType.PHS_REQUEST:
        return <div className='black'>개발자 검사 제출 필요</div>;
      case MatchHomeDashboardPositionType.PHS_SUBMIT:
        return <div className='black'>개발자 검사 제출 완료</div>;
      case MatchHomeDashboardPositionType.INTERVIEW_PASSED:
        return <div className='green'>최종 합격</div>;
      case MatchHomeDashboardPositionType.INTERVIEW_FAILED:
        return <div className='gray'>불합격</div>;
      case MatchHomeDashboardPositionType.IN_PROGRESS:
        return <div className='black'>진행중</div>;
      case MatchHomeDashboardPositionType.END:
        return <div className='black'>종료</div>;
      default: return <div />;
    }
  };

  const getPdfChip = () => {
    switch (item.jobApplicationProcessType) {
      case MatchHomeDashboardPositionType.APPLY:
        return <div className='black'>지원완료</div>;
      case MatchHomeDashboardPositionType.INTERVIEW_OFFERED:
      case MatchHomeDashboardPositionType.INTERVIEW_ACCEPTED:
      case MatchHomeDashboardPositionType.INTERVIEW_DENIED:
      case MatchHomeDashboardPositionType.INTERVIEW_ADJUSTED:
      case MatchHomeDashboardPositionType.ACC_REQUEST:
      case MatchHomeDashboardPositionType.ACC_SUBMIT:
      case MatchHomeDashboardPositionType.PHS_REQUEST:
      case MatchHomeDashboardPositionType.PHS_SUBMIT:
        return <div className='black'>전형 진행중</div>;
      case MatchHomeDashboardPositionType.INTERVIEW_PASSED:
        return <div className='green'>최종 합격</div>;
      case MatchHomeDashboardPositionType.INTERVIEW_FAILED:
        return <div className='gray'>불합격</div>;
      case MatchHomeDashboardPositionType.IN_PROGRESS:
        return <div className='black'>진행중</div>;
      case MatchHomeDashboardPositionType.END:
        return <div className='black'>전형 종료</div>;
      default: return <div />;
    }
  };
  const moveMessageModal = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target !== e.currentTarget) return;
    if (pdfModalOpen) return;
    if (item.positionType === ApplicationPositionType.JOBDA) {
      matchMessageStore.open(item.positionSn);
    } else {
      // jf3카드 클릭 동작
      if (!item.openYn || item.visibilityType === VisibilityType.PRIVATE) {
        matchHomeDashboardModel.infoModal = { isOpen: true, type: MatchDashboardInfoType.END_JF3 };
        return;
      }

      if (item.jobApplicationProcessType === MatchHomeDashboardPositionType.WRITING_RESUME) {
        // 채용공고 URL
        await matchHomeDashboardModel.getJF3Domain(item.positionSn, JF3PageType.JOB_DESCRIPTION);
        windowOpenUtils(`${matchHomeDashboardModel.jp3URL}`, 'jf3');
      } else {
        // 마이페이지 새탭
        await matchHomeDashboardModel.getJF3Domain(item.positionSn, JF3PageType.MY_PAGE);
        windowOpenUtils(`${matchHomeDashboardModel.jp3URL}`, 'jf3');
      }
    }
  };
  const handleSelect = () => {
    if (onCardClick) onCardClick();
  };
  return (
    <>
      <StyledMatchHomeDashboardCard
        type={item.jobApplicationProcessType}
        positiontype={item.positionType}
        closeYn={!!item.closingDateTime && overDateNow(item.closingDateTime)}
        onClick={(e) => {
          handleSelect();
          moveMessageModal(e);
        }}
      >
        {/* {getChip()} */}
        <div className='position-detail'>
          {
            item.companyLogoUrl
              ? <LazyImageLogo src={item.companyLogoUrl} className='company-logo' />
              : <div className='company-logo' />
          }

          <div className='position-frame' role='button' onClick={(e) => moveMessageModal(e)}>
            <div className='name-frame'>
              <div className='position-name' role='button' onClick={(e) => moveMessageModal(e)}>{item.positionName}</div>
              {item.positionType === ApplicationPositionType.HOMEPAGE
              && <Icon name='open-in-new' size={24} color={colors.CG_60} />}
            </div>

            <div className='company-name' role='button' onClick={(e) => moveMessageModal(e)}>{item.companyName}</div>
          </div>
        </div>
        {
        type === MatchTabType.WRITING
          ? <div className='position-progress-writing'>{getChip()}</div>

          : (
            <div className='position-type-frame' role='button' onClick={(e) => moveMessageModal(e)}>
              <div className='position-date'>
                {
            item.matchingOfferYn && <div className='matching-yn'>매칭 제안</div>
          }
                {
            item.applicationCompleteTime && <div className='date'>{DateFormatYYYYMMDDWithDot(item.applicationCompleteTime)} 지원</div>
          }

              </div>
              <div className='position-progress'>{pdfModalOpen ? getPdfChip() : getChip()}</div>
            </div>
          )
        }

        {/* {item.positionType === ApplicationPositionType.HOMEPAGE
          && <Button className='position-type-text' label='홈페이지 지원' size='small' iconRight icon={<Icon name='arrow-right' size={24} color={colors.CG_70} />} />}
        {item.matchingOfferYn
          && <Button className='position-type-match' label='매칭 제안' size='small' icon={<Icon name='verified-filled' size={24} color={colors.G_150} />} />} */}
        {
            pdfModalOpen
              ? (
                isSelected
                  ? <Icon name='checkbox' size={24} color={colors.G_150} className='icon-more-line' />
                  : <Icon name='checkbox-empty' size={24} color={colors.G_150} className='icon-more-line' />
              )
              : (
                <IconMoreLine
                  onClick={(e) => {
                    e.stopPropagation();
                    setViewMoreBox(true);
                  }}
                  className='icon-more-line'
                  width={32}
                  color={colors.CG_60}
                  height={32}
                />
              )
          }

        <MatchHomeDashboardMoreBox type={type} item={item} positionSn={item.positionSn} isOpen={viewMoreBox} onClickClose={() => setViewMoreBox(false)} />
      </StyledMatchHomeDashboardCard>
    </>
  );
};

export default inject(injectStore.matchMessageStore, injectStore.matchHomeDashboardModel)(observer(MatchHomeDashboardCard));
