import colors from '__designkit__/common/colors';
import React from 'react';
import styled, { keyframes } from 'styled-components';

const loadingFade = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
`;

const Frame = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  gap: 4px;

  & > .dot {
    width: 5.5px;
    height: 5.5px;
    background: ${colors.CG_60};
    border-radius: 50%;
    opacity: 0;
    animation: ${loadingFade} 1.5s infinite;
  }

  & > .dot:nth-child(1) {
    animation-delay: 0s;
  }

  & > .dot:nth-child(2) {
    animation-delay: 0.2s;
  }

  & > .dot:nth-child(3) {
    animation-delay: 0.4s;
  }
`;

const ChattingIndicator = () => (
  <Frame>
    <div className='dot' />
    <div className='dot' />
    <div className='dot' />
  </Frame>
);

export default ChattingIndicator;
