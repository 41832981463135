import { yupResolver } from '@hookform/resolvers/yup';
import JDASearchInput from 'components/inputs/JDASearchInput';
import useToast from 'hooks/useToast';
import { SkillListItem } from 'components/_v2/profile/skill/ProfileSkill';
import { IProfileSkillProps } from 'components/_v2/profile/skill/ProfileSkillView';
import JDClosableChip from 'components/_v2/_common/chip/JDClosableChip';
import FormProfileValueTitle from 'components/_v2/_common/form/FormProfileValueTitle';
import JDSelector, { JDSelectorType } from 'components/_v2/_common/input/JDSelector';
import JDPopover from 'components/_v2/_common/popover/JDPopover';
import { IconInfoCircleDarkGrey, IconPlus24 } from 'consts/assets/icons/iconPages';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { SkillLevelDescription } from 'interfaces/rqrs/IProfileKnowledgeAndSkillsRs';
import { ISkillDto } from 'interfaces/rqrs/ISkillListRs';
import { IProfileSkillDto, SkillLevel, SkillLevelText } from 'interfaces/_v2/profile/IProfileKnowledgeAndSkillsRs';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import ProfileSkillModel from 'models/_v2/profile/ProfileSkillModel';
import React, { FC, useRef, useState } from 'react';
import { FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';

const Frame = styled.div`
    padding: 32px 16px 124px 16px;

    .title-frame {
        display: flex;
        justify-content: space-between;

        .a-proposal {
            display:block;
            width:fit-content;
            font: ${Fonts.B2_Medium};
            text-decoration-line:underline;
            color:${colors.B_100};
        }

        .title-left {
            display: flex;
            .anchor-frame {
                display: flex;
                align-items: center;
                margin-left: 4px;
            }
        }
    }

    .row-select-confirm {
        margin-top: 12px;
    }

    .frame-chips {
      display: flex;
      flex-wrap: wrap;
      margin-top: 16px;

      >div {
        margin-right: 8px;
        margin-bottom: 8px;
      }
    }

    .tip {
        margin-top: 40px;
        font: ${Fonts.B1_Medium};
        color: ${colors.CG_60};
    }
`;

const PopOverFrame = styled.abbr`
  display:block;
  width: fit-content;
  color:${colors.WHITE_100};

  .keyword-info{
    margin-bottom: 16px;
  }

  h1 {
    font: ${Fonts.B3_Bold};
    line-height: 20px;
    margin-bottom: 8px;
  }

  h2 {
    font: ${Fonts.B3_Medium};
    line-height: 16px;
    color:${colors.CG_50};
  }

  ul li {
    margin-bottom: 2px;
  }
`;

const RoundChipFrame = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 16px;
`;

const RoundChip = styled.div`
    font: ${Fonts.B3_Medium};
    line-height: 16px;
    border: 1px solid ${colors.CG_40};
    border-radius: 20px;
    padding: 8px 12px;
    margin-right: 8px;
    margin-bottom: 8px;
`;

const RecommendCategoryFrame = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  h1 {
    font: ${Fonts.B2_Bold};
    color: ${colors.JOBDA_BLACK};
    margin-bottom: 12px;
  }

  .skill-frame {
    display: flex;
    flex-wrap: wrap;
  }
`;

const skillValidationSchema = yup.object().shape({
  code: yup.number().required(),
  keyword: yup.string().required(),
  level: yup.string().required(),
});

type KeywordLevelHelp = {
    title: string;
    description: string;
}

const keywordLevelHelps: KeywordLevelHelp[] = Object.values(SkillLevel).map((skill) => ({
  title: SkillLevelText[skill],
  description: SkillLevelDescription[skill],
}));

const ProfileSkillKeyword:FC<IProfileSkillProps> = ({ profileSkillModel = new ProfileSkillModel(), hidden }) => {
  const useFormed = useFormContext();
  const { watch, setValue, control, register } = useFormed;
  const { setToastObject } = useToast();
  const [selectRecommend, setSelectRecommend] = useState<ISkillDto>();
  const [selectRecommendPrev, setSelectRecommendPrev] = useState<ISkillDto>();
  const [inputText, setInputText] = useState<string>('');
  const skillFields = useFieldArray<IProfileSkillDto>({ control, name: 'skills' });
  const reservedTimeout = useRef<number>(-1);
  const skillFormMethod = useForm<IProfileSkillDto>({ mode: 'all', resolver: yupResolver(skillValidationSchema) });
  const refForm = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    refForm.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const findRecommendList = async () => {
    if (selectRecommend) {
      await profileSkillModel.loadRecommendSkills(selectRecommend.code);
    }
  };

  // 중복이 있다면 return : true
  const checkDuplication = (value: IProfileSkillDto) => {
    if (!value.code) return true;
    const found = skillFields.fields.some((data) => Number(data.code) === Number(value.code));
    return found;
  };

  return (
    <>
      <Frame aria-hidden={hidden} ref={refForm}>
        <div className='title-frame'>
          <div className='title-left'>
            <FormProfileValueTitle>기술 키워드</FormProfileValueTitle>
            <JDPopover popoverWidth={300} useOutsideClick position='non_pony_under_left' popoverMargin={0} anchorIcon={<IconInfoCircleDarkGrey className='icon-anchor' />}>
              <PopOverFrame>
                <div className='keyword-info'>
                  <h1>기술키워드란?</h1>
                  <h2>개발 스택, 디자인 툴, 협업툴 등 직무와 관련된 기술을 의미해요.</h2>
                </div>
                <div>
                  <h1>기술키워드의 숙련도는?</h1>
                  <ul>
                    {
                        keywordLevelHelps.map(({ title, description }) => (
                          <li key={title}>
                            <h2>• {title}: {description}</h2>
                          </li>
                        ))
                      }
                  </ul>
                </div>
              </PopOverFrame>
            </JDPopover>
          </div>
          <a className='a-proposal' href='https://docs.google.com/forms/d/1TXJVG4fDhWXFr_1D70oFBf2XBuV3Q9ygDJBDfPo7h9s/edit' target='_blank' rel='noopener noreferrer'>기술 키워드 요청하기</a>
        </div>
        <JDASearchInput
          className='input-search'
          name='skillIdx'
          title='기술 키워드 '
          placeholder='기술 키워드를 검색해 주세요.'
          inputTextValue={inputText}
          onChange={async (searchText: string) => {
            if (reservedTimeout.current > -1)
              clearTimeout(reservedTimeout.current);
            reservedTimeout.current = setTimeout(() => {
              profileSkillModel.searchSkills(searchText);
            }, 800) as unknown as number;
          }}
          listItemIcon={<IconPlus24 />}
          onSelectItem={async () => {
            const idx: number = watch('skillIdx');
            const data: ISkillDto = profileSkillModel.searchedSkills[idx];
            const { code, keyword } = data;
            skillFormMethod.setValue('code', code);
            skillFormMethod.setValue('keyword', keyword);
            setInputText(keyword);
            setSelectRecommend({ code, keyword });
            profileSkillModel.searchedSkills = [];
          }}
        >
          <SkillListItem>
            {
                profileSkillModel.searchedSkills.map((skill, idx) => {
                  if (skillFields.fields.some((filed) => Number(filed.code) === Number(skill.code))) return <></>;
                  return (
                    <li
                      role='presentation'
                      key={skill.code}
                      value={idx}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      {skill.keyword}
                    </li>
                  );
                })
            }
          </SkillListItem>
        </JDASearchInput>
        <input type='text' aria-hidden name='code' ref={skillFormMethod.register()} />
        <input type='text' aria-hidden name='keyword' ref={skillFormMethod.register()} />

        <FormProvider {...skillFormMethod}>
          <section className='row-select-confirm'>
            <input type='text' aria-hidden name='level' ref={skillFormMethod.register()} />
            <JDSelector
              type={JDSelectorType.NORMAL}
              selectTitle='숙련도를 선택해 주세요.'
              name='level'
              saveData={(text) => skillFormMethod.setValue('level', text)}
              disabled={inputText === ''}
              onChange={async () => {
                const value = skillFormMethod.getValues();
                if (!checkDuplication(value)) {
                  skillFields.append(value);
                  setSelectRecommendPrev(selectRecommend);
                  await findRecommendList();
                  skillFormMethod.reset({});
                  setValue('skillIdx', null);
                  setValue('level', null);
                  setInputText('');
                } else {
                  setToastObject({ isOpen: true, type: 'ERROR', message: '이미 등록된 기술입니다.', position: 'middle' });
                }
              }}
            >
              <select>
                <option value='' hidden>숙련도를 선택해 주세요.</option>
                {
                    (Object.keys(SkillLevel) as Array<keyof typeof SkillLevel>).map((key) => (
                      <option
                        key={key}
                        value={key}
                      >
                        {SkillLevelText[key]}
                      </option>
                    ))
                }
              </select>
            </JDSelector>
          </section>
        </FormProvider>
        <section className='frame-chips'>
          {
            skillFields.fields.map((field, idx) => (
              <React.Fragment key={field.id}>
                <input aria-hidden ref={register()} name={`skills[${idx}].keyword`} defaultValue={field.keyword} />
                <input aria-hidden ref={register()} name={`skills[${idx}].code`} defaultValue={field.code} />
                <input aria-hidden ref={register()} name={`skills[${idx}].level`} defaultValue={field.level} />
                <JDClosableChip onClose={() => skillFields.remove(idx)}>{`${field.keyword || ''} - ${field.level ? SkillLevelText[field.level] : ''}`}</JDClosableChip>
              </React.Fragment>
            ))
            }
        </section>

        { profileSkillModel.recommendSkills && profileSkillModel.recommendSkills.length > 0 && (
        <>
          <p className='tip '>Tips. {selectRecommendPrev?.keyword}와 유사한 기술 키워드를 추가해 보세요.</p>
          <RoundChipFrame>
            {profileSkillModel.recommendSkills.map((skillCategory) => (
              <RecommendCategoryFrame>
                <h1>{skillCategory.category}</h1>
                <div className='skill-frame'>
                  {skillCategory.skills.map((skill, index) => {
                    if (index > 20) return <></>;
                    if (skillFields.fields.some((filed) => Number(filed.code) === Number(skill.code))) return <></>;
                    return (
                      <RoundChip
                        key={skill.code}
                        onClick={() => {
                          skillFormMethod.setValue('code', skill.code);
                          skillFormMethod.setValue('keyword', skill.keyword);
                          setSelectRecommend({ code: skill.code, keyword: skill.keyword });
                          setInputText(skill.keyword);
                          handleScroll();
                        }}
                      >{skill.keyword}
                      </RoundChip>
                    );
                  })}
                </div>
              </RecommendCategoryFrame>
            ))}
          </RoundChipFrame>
        </>
        )}
      </Frame>
    </>
  );
};

export default inject(injectStore.profileSkillModel)(observer(ProfileSkillKeyword));
