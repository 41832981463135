import { IconLogo } from 'consts/assets/icons/iconPages';
import colors from '__designkit__/common/colors';
import React, { useState } from 'react';
import styled from 'styled-components';
import IconInfo from 'assets/_v2/_common/icon_info.svg';
import Fonts from '__designkit__/common/fonts';
import IconArrowRight from 'assets/_v2/_common/icon_right_small_black.svg';
import CompanyDetailsEditRequestModal from 'components/_v2/company/CompanyDetailsEditRequestModal';

const Frame = styled.div`
    padding: 0 16px 32px 16px;
`;

const InnerFrame = styled.div`
    padding: 24px;
    background: ${colors.JOBDA_WHITE};

    .content-frame {
        margin-top: 16px;
        display: flex;
        align-items: flex-start;
        
        img {
            margin-top: 2px;
            margin-right: 4px;
        }

        .desc {
            font: ${Fonts.B3_Medium_P};
            color: ${colors.CG_60};
        }

        .button-frame {
            display: flex;
            align-items: center;
            margin-top: 20px;

            >span {
                font: ${Fonts.B3_Medium};
                color: ${colors.JOBDA_BLACK};
                text-decoration: underline;
                text-underline-position: under; 
            }
        }
    }
`;

const CompanyDetailsEditRequest = () => {
  const [isOpenEditRequestModal, setIsOpenEditRequestModal] = useState(false);
  return (
    <>
      <Frame>
        <InnerFrame>
          <IconLogo />
          <div className='content-frame'>
            <img src={IconInfo} alt='icon' />
            <div className='content'>
              <span className='desc'>
                JOBDA에서는 NICE평가정보, 국민연금 가입 내역, 금융감독원 공시 정보 및 기업 회원이 입력한 정보를 조합하여 제공합니다. <br />
                위 정보는 무단으로 수집 및 배포할 수 없습니다.
              </span>
              <div role='presentation' className='button-frame' onClick={() => setIsOpenEditRequestModal(true)}>
                <span>기업정보 수정 요청</span>
                <img src={IconArrowRight} alt='icon' />
              </div>
            </div>
          </div>
        </InnerFrame>
      </Frame>
      <CompanyDetailsEditRequestModal
        isOpen={isOpenEditRequestModal}
        onClickClose={() => setIsOpenEditRequestModal(false)}
      />
    </>
  );
};

export default CompanyDetailsEditRequest;
