const getEnvURI = () => {
  const env = process.env.REACT_APP_ENV;
  let host;
  switch (env) {
    case 'local':
    case 'development':
      host = 'https://m-jobda-im.kr-dv-jainwon.com';
      break;
    case 'staging':
      host = 'https://m-jobda-im.kr-st-jainwon.com';
      break;
    case 'staging2':
      host = 'https://st2-m-jobda-im.kr-st-jainwon.com';
      break;
    case 'production':
    default:
      host = 'https://m.jobda.im';
      break;
  }
  return host;
};

export default getEnvURI;
