import { yupResolver } from '@hookform/resolvers/yup';
import useToast from 'hooks/useToast';
import ProfilePrizeCard from 'components/_v2/profile/prize/ProfilePrizeCard';
import ProfileEditTitleComponent from 'components/_v2/profile/ProfileEditTitleComponent';
import JDSaveButton from 'components/_v2/_common/button/JDSaveButton';
import FormProfileSectionTitle from 'components/_v2/_common/form/FormProfileSectionTitle';
import { IPrizeDto, IProfilePrizeRqRs } from 'interfaces/_v2/profile/IProfilePrizeRqRs';
import { IProfileProps } from 'interfaces/_v2/profile/IProfileProps';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import ProfileModel from 'models/_v2/profile/ProfileModel';
import React, { FC, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';

const Frame = styled.div`
    position:relative;
    padding: 32px 16px 128px 16px;
    .prize-absolute{
      position: absolute;
      top:32px;
    }

`;

const defaultValues:Partial<IProfilePrizeRqRs> = { prizes: [] };

interface IProfilePrizeProps extends IProfileProps {
  onClose?: () => void;
}
export const prizeSchema = yup.object({
  prizes: yup.array(
    yup.lazy((prize) => {
      if (!prize.institution && !prize.name && !prize.date && !prize.description) return yup.object();
      if (!prize.institution && !prize.name && !prize.date && prize.description) {
        return yup.object({
          institution: yup.string().required('수여 기관을 입력해 주세요.').max(30, '30자 이내로 입력해 주세요.'),
          name: yup.string().required('수상명을 입력해 주세요.').max(21, '21자 이내로 입력해 주세요.'),
          date: yup.lazy((startDate) => (startDate ? yup.date() : yup.string().nullable().required('일자를 입력해 주세요.'))),
        });
      }

      return yup.object({
        institution: yup.string().required('수여 기관을 입력해 주세요.').max(30, '30자 이내로 입력해 주세요.'),
        name: yup.string().required('수상명을 입력해 주세요.').max(21, '21자 이내로 입력해 주세요.'),
        date: yup.lazy((startDate) => (startDate ? yup.date() : yup.string().nullable().required('일자를 입력해 주세요.'))),
        description: yup.string().max(1000, '최대 글자수를 초과했습니다'),
      });
    }),

  ),
});
const ProfilePrize:FC<IProfilePrizeProps> = ({ onClose, profileModel = new ProfileModel() }) => {
  const useFormed = useForm<IProfilePrizeRqRs>({
    mode: 'onSubmit',
    resolver: yupResolver(prizeSchema),
    defaultValues,
  });
  const { reset, formState, getValues, handleSubmit } = useFormed;
  const { setToastObject } = useToast();
  useEffect(() => {
    const init = async () => {
      try {
        await profileModel.loadPrize();
        if (profileModel.prize) {
          reset(profileModel.prize);
        }
      } catch (e) {
        console.error(e);
        reset(defaultValues);
      }
    };
    init();
  }, [profileModel]);
  useEffect(() => {
    profileModel.isPrizeDirty = formState.isDirty;
  }, [formState.isDirty]);

  const onSubmit = useCallback(async (_data?: any) => {
    try {
      const inputData = getValues();
      const transformed:IProfilePrizeRqRs = {
        ...inputData,
        prizes: inputData.prizes?.map<IPrizeDto>(({ institution, name, date, description }) => ({ institution, name, date, description })),
      };
      transformed.sourcePage = profileModel.sourcePage;
      if (transformed.prizes[0].date === '' && transformed.prizes[0].description === '' && transformed.prizes[0].institution === '' && transformed.prizes[0].name === '')transformed.prizes = [];
      profileModel.isPrizeDirty = false;
      await profileModel.savePrize(transformed);
      setToastObject({ isOpen: true, type: 'SUCCESS', message: '성공적으로 저장되었습니다.', subMessage: undefined });
      if (onClose) onClose();
    } catch (e) {
      console.error(e);
    }
  }, []);
  const onError = () => {
    setToastObject({ isOpen: true, type: 'ERROR', message: '저장에 필요한 정보를 작성해 주세요.', subMessage: undefined });
  };

  return (
    <>
      <ProfileEditTitleComponent
        title='수상 내역을 입력해 주세요.'
        subtitle='수상 규모에 상관 없이 성취 과정을 잘 서술하는 것이 중요해요.'
      />
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <FormProvider {...useFormed}>
          <Frame>
            <FormProfileSectionTitle className='prize-absolute'>수상 내역</FormProfileSectionTitle>
            <ProfilePrizeCard />
          </Frame>
          <JDSaveButton type='submit' onSubmit={() => {}} fixed={false} />
        </FormProvider>
      </form>
    </>
  );
};

export default inject(injectStore.profileModel)(observer(ProfilePrize));
