import { Preferences } from '@capacitor/preferences';
import jsCookie from 'js-cookie';
import MobileStore from 'store/mobileStore';

const NEVER_PREFIX_KEY = 'never_render_check';

const getExpiredDate = (days: number) => {
  const date = new Date();
  date.setDate(date.getDate() + days);
  return date;
};

class NeverRenderCheckUtil {
  static setNeverRenderCheck = async (key: string) => {
    if (MobileStore.isMobile) {
      await Preferences.set({ key: NEVER_PREFIX_KEY + key, value: 'render' });
    } else {
      const expireDate = getExpiredDate(30);
      jsCookie.set(NEVER_PREFIX_KEY + key, 'render', { expires: expireDate });
    }
  };

  static getNeverRenderCheck = async (key: string) => {
    if (MobileStore.isMobile) {
      const { value } = await Preferences.get({ key: NEVER_PREFIX_KEY + key });
      if (value === 'render') return true;
    } else {
      const value = jsCookie.get(NEVER_PREFIX_KEY + key);
      if (value === 'render') return true;
    }
    return false;
  };
}

export default NeverRenderCheckUtil;
