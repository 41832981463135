import JDBaseModal, { IJDBaseModal } from 'components/_v2/_common/modals/JDBaseModal';
import { JDButtonTwoChoiceFrame, JDModalBasicFrame, JDModalButton, JDModalButtonGhost, JDModalDescription, JDModalTitle } from 'components/_v2/_common/modals/JDModalComponents';
import React, { FC } from 'react';
import styled from 'styled-components';

const Frame = styled.div`

  ${JDButtonTwoChoiceFrame} {
    margin-top: 24px;
  }

  ${JDModalDescription} {
    margin-top: 8px;
  }

  ${JDModalButtonGhost} {
    margin-right: 16px;
  }
`;

const MatchHomeDashboardDeleteResumeModal:FC<IJDBaseModal> = ({ isOpen, onClickClose, onSuccess }) => (
  <JDBaseModal dimmed isOpen={isOpen} onClickClose={onClickClose} isDimClickClose>
    <Frame>
      <JDModalBasicFrame>
        <JDModalTitle>지원서를 삭제하시겠어요?</JDModalTitle>
        <JDModalDescription>삭제한 지원서는 다시 되돌릴 수 없습니다.</JDModalDescription>
        <JDButtonTwoChoiceFrame>
          <JDModalButtonGhost onClick={(e) => { e.stopPropagation(); onClickClose(); }}>아니오</JDModalButtonGhost>
          <JDModalButton onClick={(e) => { e.stopPropagation(); onSuccess && onSuccess(); }}>확인</JDModalButton>
        </JDButtonTwoChoiceFrame>
      </JDModalBasicFrame>
    </Frame>
  </JDBaseModal>
);

export default MatchHomeDashboardDeleteResumeModal;
