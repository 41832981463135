import VerificationContent from 'components/_v2/verification/VerificationContent';
import JDBaseModal, { IJDBaseModal } from 'components/_v2/_common/modals/JDBaseModal';
import { JDModalBasicFrame, JDModalButton, JDModalButtonGhost, JDModalTitle } from 'components/_v2/_common/modals/JDModalComponents';
import RoutePaths from 'consts/RoutePaths';
import Fonts from '__designkit__/common/fonts';
import React, { FC } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
  .verification-frame {
    margin: 24px 0;
    .button-frame {
      position: inherit;
      width: 100%;
      
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;

    ${JDModalButton} {
        padding: 14px 0;
        font: ${Fonts.B2_Bold};
        margin-bottom: 8px;
    }

    ${JDModalButtonGhost} {
        margin: 0;
        padding: 14px 0;
        font: ${Fonts.B2_Bold};
    }
    }
}
`;

const IdentificationSelectModal:FC<IJDBaseModal> = ({ isOpen, onClickClose, onSuccess }) => (
  <JDBaseModal dimmed isOpen={isOpen} onClickClose={onClickClose} isDimClickClose>
    <Frame>
      <JDModalBasicFrame>
        <JDModalTitle>잡다 서비스 이용을 위해 <br />본인 인증 방식을 선택해 주세요.</JDModalTitle>
        <VerificationContent
          returnUrl={RoutePaths.profile}
          handleButtonClick={onClickClose}
        />
      </JDModalBasicFrame>
    </Frame>
  </JDBaseModal>
);

export default IdentificationSelectModal;
