import { Button, ButtonMode } from '__designkit__/button/Button';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import JDBaseModal, { IJDBaseModal } from 'components/_v2/_common/modals/JDBaseModal';
import { JDModalBasicFrame, JDModalDescription, JDModalTitle } from 'components/_v2/_common/modals/JDModalComponents';
import React, { FC } from 'react';
import styled from 'styled-components';

const Frame = styled.div`

  ${JDModalTitle} {
    margin-bottom: 8px;
  }
`;

const ButtonFrame = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  
  .cancel-btn {
    margin-right: 8px;
  }
`;

const JDModalContentFrame = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 16px;
    padding: 20px;
    border-radius: 8px;
    background-color: ${Colors.CG_30};

    .content-list {
        display: flex;
        align-items: center;

        span {
            margin-left: 8px;
            font: ${Fonts.B3_Medium};

            b {
                font: ${Fonts.B3_Bold};
            }
        }

        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }
`;

const MatchApplyCancelBenefitsModal:FC<IJDBaseModal> = ({ isOpen, onSuccess, onClickClose }) => (
  <JDBaseModal dimmed isOpen={isOpen} onClickClose={onClickClose} isDimClickClose>
    <Frame>
      <JDModalBasicFrame>
        <JDModalTitle>서비스 이용을 취소하시겠어요?</JDModalTitle>
        <JDModalDescription>잡다 매칭 취소 시 다음 혜택이 사라져요.</JDModalDescription>
        <JDModalContentFrame>
          <div className='content-list'>
            <Icon name='test-acca' size={32} />
            <span><b>역량검사 결과표 확인</b>하기</span>
          </div>
          <div className='content-list'>
            <Icon name='invitation-user' size={32} />
            <span><b>맞춤형 포지션 추천</b> 받기</span>
          </div>
          <div className='content-list'>
            <Icon name='resume-done-green' size={32} />
            <span>인사 담당자의 <b>입사지원 제안</b> 받기</span>
          </div>

        </JDModalContentFrame>
        <ButtonFrame>
          <Button className='cancel-btn' label='취소' outLined size='large' onClick={onClickClose} />
          <Button label='계속' size='large' buttonMode={ButtonMode.PRIMARY} onClick={onSuccess} />
        </ButtonFrame>
      </JDModalBasicFrame>
    </Frame>
  </JDBaseModal>
);

export default MatchApplyCancelBenefitsModal;
