import imgBannerImage from 'assets/img/declaration/img_declaration_bottom_banner.png';
import BottomFloatingFrame from 'components/BottomFloatingFrame';
import ScrollToTopButton from 'components/button/ScrollToTopButton';
import LazyImage from 'components/common/LazyImage';
import JDAWhyBottomBanner from 'components/JDAWhyBottomBanner';
import DeclarationPromise from 'components/jobdaDeclaration/DeclarationPromise';
import DeclarationSlogan from 'components/jobdaDeclaration/DeclarationSlogan';
import DecalrationTitle from 'components/jobdaDeclaration/DeclarationTitle';
import { DeclarationMetaTag } from 'components/_v2/_common/meta/MetaTag';
import RoutePaths from 'consts/RoutePaths';
import { absoluteVerticalCenter } from 'consts/style/mixins';
import JDADeclarations from 'fixtures/JDADeclarations';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { FC } from 'react';
import styled from 'styled-components';
import DeclarationBenefit from './jobdaDeclaration/DeclarationBenefit';

const BottomImage = styled(LazyImage)`
  width:144px;
  height:118px;
  position:absolute;
  ${absoluteVerticalCenter()};
  right:12px;


`;

const JobdaDeclaration: FC<IComponentProps> = () => (
  <>
    <DeclarationMetaTag />
    <DecalrationTitle />
    <section className='frame-promises'>
      {
        JDADeclarations.promises.map((promise, idx) => (
          <DeclarationPromise promise={promise} key={`promise-${idx}`} />
        ))
      }
    </section>
    <section className='frame-slogans'>
      {
        JDADeclarations.slogans.map((slogan, idx) => (
          <DeclarationSlogan slogan={slogan} key={`slogan-${idx}`} />
        ))
      }
    </section>
    <section className='frame-benefits'>
      {
        JDADeclarations.benefits.map((benefit, idx) => (
          <DeclarationBenefit benefit={benefit} key={`benefit-${idx}`} />
        ))
      }
    </section>
    <JDAWhyBottomBanner
      title='잡다 FAQ 바로가기'
      subTitle={`당신이 궁금해할 만한 것들을\n모두 모았어요.`}
      message='확인하러 가기'
      icon={<BottomImage src={imgBannerImage} />}
      url={RoutePaths.jobda_faq}
    />
    <BottomFloatingFrame>
      <ScrollToTopButton />
    </BottomFloatingFrame>
  </>
);

export default JobdaDeclaration;
