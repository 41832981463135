import { Button, ButtonMode } from '__designkit__/button/Button';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import JDDimModal from 'components/_v2/_common/modals/JDDimModal';
import { TERM_TYPE, TermType } from 'consts/TermType';
import { JDCheckBoxInput } from 'consts/_v2/_common/style/mixins';
import IComponentProps from 'interfaces/props/IComponentProps';
import { inject, observer } from 'mobx-react';
import TermsModel from 'models/TermsModel';
import { injectStore } from 'models/store';
import React, { FC, useState } from 'react';
import styled, { keyframes } from 'styled-components';

const bottomModalAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(calc(-100%));
  }
`;

const Frame = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  height: fit-content;
  padding: 8px 16px calc(env(safe-area-inset-bottom) + 16px);
  border-radius: 8px 8px 0 0;
  background-color: ${Colors.WHITE_100};
  animation: ${bottomModalAnimation} 0.3s 0s 1 ease-out forwards;
  z-index: 10005;

  &::before {
    position: absolute;
    top: -8px;
    left: 50%;
    width: 32px;
    height: 4px;
    border-radius: 2px;
    background-color: ${Colors.CG_50};
    transform: translateX(-50%);
    content: '';
  }

  & .open {
    transform: rotate(180deg);
  }
`;

const Header = styled.div`
  width: 100%;
  height: fit-content;
  padding: 17px;
  border-bottom: 1px solid ${Colors.CG_40};
  font: ${Fonts.B1_Bold};
  text-align: center;
`;

const TermList = styled.div`
  width: 100%;
  height: fit-content;
`;

const TermItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0;
  font: ${Fonts.B3_Medium};

  > div {
    transition: .3s;

    & > input {
      ${JDCheckBoxInput}
    }

    & > span {
      margin-left: 8px;
    }
  }
`;

const BtnFrame = styled.footer`
  width: 100%;
  height: fit-content;
  background-color: ${Colors.WHITE_100};
`;

interface INhisTermsModalProps extends IComponentProps {
  onClickComplete: () => void;
  onClickClose: () => void;
  termsModel?: TermsModel;
}

const NhisTermsModal: FC<INhisTermsModalProps> = ({ onClickComplete, onClickClose, termsModel = new TermsModel() }) => {
  const [privacyProvision, setPrivacyProvision] = useState<boolean>(false);

  const onClickComplateBtn = () => {
    onClickComplete();
    onClickClose();
  };

  const onClickDetail = async (termType: TermType) => {
    const res = await termsModel.loadTermsList(termType);
    termsModel.setTermDetail(res.terms[0]);
  };

  return (
    <JDDimModal onClickClose={onClickClose}>
      <Frame>
        <Header>
          서비스 이용 약관 동의
        </Header>
        <TermList>
          <TermItem>
            <div>
              <input type='checkbox' checked={privacyProvision} onChange={() => setPrivacyProvision(!privacyProvision)} />
              <span>개인정보 제3자 제공동의 (필수)</span>
            </div>
            <Icon name='arrow-right' size={32} onClick={() => onClickDetail(TERM_TYPE.NHIS_PRIVACY_PROVISION)} />
          </TermItem>
        </TermList>
        <BtnFrame>
          <Button label='동의하고 시작하기' buttonMode={ButtonMode.PRIMARY} size='large' onClick={onClickComplateBtn} disabled={!privacyProvision} />
        </BtnFrame>
      </Frame>
    </JDDimModal>
  );
};

export default inject(injectStore.termsModel)(observer(NhisTermsModal));
