import classnames from 'classnames/bind';
import React from 'react';
import styles from './tooltipSmall.module.scss';

const cx = classnames.bind(styles);

type ToolTipType = 'black' | 'green' | 'blue';

interface TooltipProps {
  type?: ToolTipType;
  value: string;
}

const TooltipSmall = ({ type = 'black', value }: TooltipProps) => {
  // value에서 숫자 부분만 추출
  const valueParts = value.split(' ');
  const numberPart = parseInt(valueParts[1], 10);

  // 숫자 부분을 콤마가 포함된 형태로 변환
  const formattedNumber = numberPart.toLocaleString();

  // NaN check
  const isNan = Number.isNaN(numberPart);
  // 변환된 숫자 부분을 원래 문자열에 다시 삽입 or '-' if NaN
  const formattedValue = isNan ? '-' : `${valueParts[0]} ${formattedNumber}`;
  return (
    <>
      <div className={cx('tooltipArea', type)}>
        <span className={cx('text')}>{formattedValue}</span>
      </div>
    </>
  );
};

export default TooltipSmall;
