import Colors from '__designkit__/common/colors';
import SingleMenuItem from '__designkit__/components/SingleMenuItem';
import Icon from '__designkit__/icon/Icon';
import React, { useState } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;

  & code {
    display: inline-block;
    width: fit-content;
    padding: 4px;
    border-radius: 4px;
    background-color: ${Colors.CG_30};
    font-style: italic;
    font-weight: 700;
  }
`;

const ControlFrame = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SingleMenuItemPage = () => {
  const [title, setTitle] = useState('Title');
  const [subTitle, setSubTitle] = useState('subTitle');
  const [isBadge, setIsBadge] = useState(false);
  const [isLeftIcon, setIsLeftIcon] = useState(false);
  const [isRightIcon, setIsRightIcon] = useState(false);
  const [isRightIconNull, setIsRightIconNull] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  return (
    <Frame>
      <ControlFrame>
        <span>title: <input type='Title' value={title} onChange={(e) => setTitle(e.currentTarget.value)} /></span>
        <span>subTitle: <input type='Title' value={subTitle} onChange={(e) => setSubTitle(e.currentTarget.value)} /></span>
        <span>badge: <input type='checkbox' checked={isBadge} onChange={(e) => setIsBadge(e.currentTarget.checked)} /></span>
        <span>leftIcon: <input type='checkbox' checked={isLeftIcon} onChange={(e) => setIsLeftIcon(e.currentTarget.checked)} /></span>
        <span>rightIcon: <input type='checkbox' checked={isRightIcon} onChange={(e) => setIsRightIcon(e.currentTarget.checked)} /></span>
        <span>오른쪽 아이콘을 아예 없애고 싶다면 null을 지정하세요.: <input type='checkbox' checked={isRightIconNull} onChange={(e) => setIsRightIconNull(e.currentTarget.checked)} /></span>
        <span>error: <input type='checkbox' checked={isError} onChange={(e) => setIsError(e.currentTarget.checked)} /></span>
        <span>selected: <input type='checkbox' checked={isSelected} onChange={(e) => setIsSelected(e.currentTarget.checked)} /></span>
        <span>disabled: <input type='checkbox' checked={isDisabled} onChange={(e) => setIsDisabled(e.currentTarget.checked)} /></span>
      </ControlFrame>
      <p>props에 대한 자세한 내용은 <code>src/__designkit__/components/SingleMenuItem.tsx</code>를 참고하세요.</p>
      <SingleMenuItem
        title={title || '필수 입력'}
        subTitle={subTitle}
        badge={isBadge ? <Icon name='verified-filled' size={24} color={Colors.G_200} /> : undefined}
        leftIcon={isLeftIcon ? <Icon name='add' size={32} /> : undefined}
        rightIcon={isRightIconNull ? null : isRightIcon ? <Icon name='bell' size={32} /> : undefined}
        error={isError}
        disabled={isDisabled}
        selected={isSelected}
        onClick={() => {}}
      />
    </Frame>
  );
};

export default SingleMenuItemPage;
