import React, { forwardRef, ImgHTMLAttributes } from 'react';

export interface DefaultImgHTMLAttributes extends ImgHTMLAttributes<HTMLImageElement> { }

export interface OnOffImgHTMLAttributes extends ImgHTMLAttributes<HTMLImageElement> {
  active: boolean;
  color?: string;
}

interface IIconOnOff extends OnOffImgHTMLAttributes{
  activeSrc: string;
  unActiveSrc: string;
}

const OnOffIcon = forwardRef<HTMLImageElement, IIconOnOff>(({ active, activeSrc, unActiveSrc, ...props }, ref) => (
  <img ref={ref} alt={props.alt} src={active ? activeSrc : unActiveSrc} {...props} />
));

export default OnOffIcon;
