import { Button, ButtonMode } from '__designkit__/button/Button';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import MatchServiceConditionInductionModal from 'components/_v2/profile/match/MatchServiceConditionInductionModal';
import RoutePaths from 'consts/RoutePaths';
import IComponentProps from 'interfaces/props/IComponentProps';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import MatchApplyModel from 'models/_v2/MatchApplyModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > div.button-frame {
    display: flex;
  }
`;
const TextFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;
const TitleText = styled.div`
  font: ${Fonts.B1_Bold_P};
  color: ${colors.JOBDA_BLACK};
  white-space: pre-wrap;
  text-align: center;
  margin-top: 16px;

`;
const Text = styled.div`
  
    margin-top: 8px;
    font:${Fonts.B2_Medium_P};
    color:${colors.CG_70};
`;

interface IMatchApplyBlurFrameProps extends IComponentProps {
  login?: Login;
  matchApplyModel?: MatchApplyModel;
}

const MatchApplyBlurFrame: FC<IMatchApplyBlurFrameProps> = ({ login = new Login(), matchApplyModel = new MatchApplyModel() }) => {
  const history = useHistory();
  const [isOpenConditionInductionModal, setIsOpenConditionInductionModal] = useState<boolean>(false);
  const [textComponent, setTextComponents] = useState<JSX.Element>();
  const [btnText, setBtnText] = useState<string>();

  const onClickBtn = async () => {
    if (login.matchConfigStatus?.matchingConfigYn) {
      if (matchApplyModel.profile!.completionRate >= 60) {
        history.push(RoutePaths.position);
      } else {
        history.push(RoutePaths.profile);
      }
    } else if (!login.matchConfigStatus?.matchingConfigCondition.profileRequiredCompleteYn) {
      history.push(RoutePaths.profile);
    } else if (login.profileType?.generalYn) {
      if (!login.matchConfigStatus.matchingConfigCondition.accCompleteYn) {
        history.push(RoutePaths.acca_test);
      } else {
        await login.changeMatchConfigStatus(true);
        await login.getMatchConfigStatus();
        setIsOpenConditionInductionModal(true);
      }
    } else if (login.profileType?.developerYn) {
      if (!login.matchConfigStatus.matchingConfigCondition.paccCompleteYn) {
        history.push(RoutePaths.phs_test);
      } else {
        await login.changeMatchConfigStatus(true);
        await login.getMatchConfigStatus();
        setIsOpenConditionInductionModal(true);
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (login.matchConfigStatus === null) await login.getMatchConfigStatus();
      if (login.profileType === null) await login.loadProfileType();
      if (matchApplyModel.profile === null) await matchApplyModel.loadMatchingProfile();
      if (login.matchConfigStatus?.matchingConfigYn) {
        // 인재풀
        const monthTerm = new Date().getMonth() - new Date(login.matchConfigStatus?.lastProfileUpdateDateTime || '').getMonth();
        const dayTerm = new Date().getDate() - new Date(login.matchConfigStatus?.lastProfileUpdateDateTime || '').getDate();
        if (matchApplyModel.profile!.completionRate >= 60 && monthTerm < 3) {
          setTextComponents(<TextFrame><TitleText>제안 받은 포지션이 없어요.</TitleText><Text>제안을 기다리는 동안<br />잡다의 최신 채용 정보를 확인해 보세요.</Text></TextFrame>);
          setBtnText('채용 공고 확인하기');
        } else if (monthTerm > 3 || (monthTerm === 3 && dayTerm > 0)) {
          setTextComponents(<TextFrame><TitleText>제안 받은 포지션이 없어요.</TitleText><Text>마지막 프로필 업데이트가 {monthTerm}개월 전이에요.<br />최신 정보를 작성하면<br />입사 제안 확률이 20% 증가해요</Text></TextFrame>);
          setBtnText('프로필 업데이트하기');
        } else {
          setTextComponents(<TextFrame><TitleText>제안 받은 포지션이 없어요.</TitleText><Text>내 프로필 완성도가 60% 이상이면<br />입사 제안 확률이 20% 증가해요</Text></TextFrame>);
          setBtnText('프로필 작성하기');
        }
      } else if (!login.matchConfigStatus?.matchingConfigCondition.profileRequiredCompleteYn) {
        // 프로필 작성 안 한 사람.
        setTextComponents(<TextFrame><TitleText>제안 받은 포지션이 없어요.</TitleText><Text>{login.userInfo?.name as string}님, 1분 프로필 작성으로<br />750개 기업에게 직접 입사 제안받아보세요.</Text></TextFrame>);
        setBtnText('프로필 작성하기');
      } else if (login.profileType?.generalYn) {
        if (!login.matchConfigStatus.matchingConfigCondition.accCompleteYn) {
          // 역량검사 안 본 사람.
          setTextComponents(<TextFrame><TitleText>제안 받은 포지션이 없어요.</TitleText><Text>{login.userInfo?.name as string}님, 역량검사 한 번으로<br />대기업 포함 750사의 채용 전형을 해결해보세요!</Text></TextFrame>);
          setBtnText('역랑검사 응시하기');
        } else {
          // 프로필 쓰고, 역량검사 본 사람.
          setTextComponents(<TextFrame><TitleText>제안 받은 포지션이 없어요.</TitleText><Text>이제, 입사 제안 받을 준비 끝!<br />아래 포지션 제안받기 버튼을 눌러주세요.</Text></TextFrame>);
          setBtnText('포지션 제안받기');
        }
      } else if (login.profileType?.developerYn) {
        if (!login.matchConfigStatus.matchingConfigCondition.paccCompleteYn) {
          // 개역량검사 안 본 사람.
          setTextComponents(<TextFrame><TitleText>제안 받은 포지션이 없어요.</TitleText><Text>{login.userInfo?.name as string}님, 개발자 검사 응시하고<br />750개 기업에게 직접 입사 제안받아보세요.</Text></TextFrame>);
          setBtnText('검사 응시하기');
        } else {
          // 프로필 쓰고, 개역량검사 본 사람.
          setTextComponents(<TextFrame><TitleText>제안 받은 포지션이 없어요.</TitleText><Text>이제, 입사 제안 받을 준비 끝!<br />아래 포지션 제안받기 버튼을 눌러주세요.</Text></TextFrame>);
          setBtnText('포지션 제안받기');
        }
      }
    })();
  }, [login.matchConfigStatus, login.profileType]);

  return (
    <Frame>
      { textComponent }
      <SpacingBlock size={24} vertical />
      <div role='button' className='button-frame' onClick={onClickBtn}>
        <Button buttonMode={ButtonMode.PRIMARY} label={btnText} size='small' />
      </div>
      <MatchServiceConditionInductionModal isOpen={isOpenConditionInductionModal} onClickClose={() => setIsOpenConditionInductionModal(false)} />
    </Frame>
  );
};

export default inject(injectStore.login, injectStore.matchApplyModel)(observer(MatchApplyBlurFrame));
