import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import JDContentCheckBox from 'components/_v2/_common/input/JDContentCheckBox';
import shadows from 'consts/style/shadows';
import { IJobGroupsWithTitle } from 'interfaces/_v2/ICodeRqRs';
import IMatchApplyProps from 'interfaces/_v2/matchApply/IMatchApplyProps';
import { inject, observer } from 'mobx-react';
import MatchApplyModel from 'models/_v2/MatchApplyModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

const Frame = styled.div`
    &[aria-hidden=true] {
      display: none;
    }
`;

const JobTitlesTab = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:stretch;
    width:100%;
    height:48px;
    overflow-x:auto;
    white-space:pre;
    margin-bottom: 32px;
    ${shadows.headers}; 
    ::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
    }
`;

const TabJob = styled.div<{ count: number}>`
    position:relative;
    font: ${Fonts.B2_Bold};
    line-height: 17px;
    padding-bottom: 14px;
    color: ${colors.CG_60};
    margin-right: 32px;
    display: flex;
    align-items: center;

    &[aria-current=true] {
        color: ${colors.JOBDA_BLACK};
    }

    &[aria-current=true]:before {
      display:block;
      position:absolute;
      content:'';
      bottom:0;
      left:0;
      width:100%;
      height:2px;
      background:${colors.JOBDA_BLACK};
    }

    &::after {
      content: '${(props) => props.count}';
      color: ${colors.WHITE_100};
      width: 22px;
      height: 22px;
      margin-left: 8px;
      background-color: ${colors.JOBDA_BLACK};
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
`;

const JobTitleList = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 68px;

    &[aria-hidden=true] {
        display: none;
    }
`;

const JobTitleFrame = styled.div`
    &:not(:last-child) {
        margin-bottom: 12px;
    }
`;

const JobTitle = styled.div`
    width: 100%;
    padding: 24px 32px;
    text-align: left;
    outline: 1px solid ${colors.CG_50};
    border-radius: 8px;

    span {
        font: ${Fonts.B1_Bold};
        color: ${colors.JOBDA_BLACK};
    }
    &[aria-checked=true] {
      outline: 2px solid ${colors.JOBDA_BLACK};
      background: ${colors.CG_30};
    } 
`;

interface IMatchApplyJobTitlesProps extends IMatchApplyProps{
    selectedJobGroups: IJobGroupsWithTitle[];
    hide: boolean;
}
interface IProfileSelected {
  jobGroupCode: number;
  jobTitleCode: number;
}

const MatchApplyJobTitles:FC<IMatchApplyJobTitlesProps> = ({ selectedJobGroups, hide, matchApplyModel = new MatchApplyModel() }) => {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const { transJobGroups } = matchApplyModel;
  const useFormed = useFormContext();
  const [selectedJobTitleList, setSelectedJobTitleList] = useState<IProfileSelected[]>([]);
  const { watch } = useFormed;

  useEffect(() => {
    setCurrentTab(selectedJobGroups[0].code);
  }, [selectedJobGroups]);

  const onChangeCheckbox = () => {
    const list: [] = watch('jobTitles');
    setSelectedJobTitleList(list.filter((value) => (value && value !== undefined)));
  };

  useEffect(() => {
    matchApplyModel.resetTransJobGroups();

    selectedJobTitleList.forEach((data) => {
      let findIdx = 0;
      const res = matchApplyModel.transJobGroups.find((objData, idx) => {
        findIdx = idx;
        return objData.jobGroupCode === data.jobGroupCode;
      });

      if (res === undefined) {
        matchApplyModel.transJobGroups.push({ jobGroupCode: data.jobGroupCode, jobTitleCodes: [data.jobTitleCode] });
      } else {
        matchApplyModel.transJobGroups[findIdx].jobTitleCodes = [...matchApplyModel.transJobGroups[findIdx].jobTitleCodes, data.jobTitleCode];
      }
    });
  }, [selectedJobTitleList]);

  return (
    <Frame aria-hidden={hide}>
      <JobTitlesTab>
        {selectedJobGroups.map(({ code, name }) => (
          <>
            <TabJob count={transJobGroups.find((objData) => objData.jobGroupCode === code)?.jobTitleCodes.length || 0} aria-current={currentTab === code} onClick={() => setCurrentTab(code)}>{name}</TabJob>
          </>
        ))}
      </JobTitlesTab>
      {
        selectedJobGroups.map(({ code: jobCode, jobTitles }) => (
          <JobTitleList aria-hidden={jobCode !== currentTab}>
            {
                jobTitles.map(({ code, name }) => (
                  <JobTitleFrame>
                    <JDContentCheckBox
                      key={`job-title-${code}`}
                      name={`jobTitles.${code}`}
                      value={{ jobGroupCode: jobCode, jobTitleCode: code }}
                      onChange={onChangeCheckbox}
                      defaultChecked={selectedJobTitleList?.some(({ jobTitleCode }) => jobTitleCode === code)}
                      label={(
                        <JobTitle aria-checked={selectedJobTitleList?.some(({ jobTitleCode }) => jobTitleCode === code)}>
                          <span>{name}</span>
                        </JobTitle>
                      )}
                    />
                  </JobTitleFrame>
                ))
            }
          </JobTitleList>
        ))
      }
    </Frame>
  );
};

export default inject(injectStore.matchApplyModel)(observer(MatchApplyJobTitles));
