import ImgNotResult from 'assets/_v2/positionList/img_character_joy_empty.png';
import ImgError from 'assets/_v2/positionList/img_character_joy_error.png';
import ImgFind from 'assets/_v2/positionList/img_character_joy_find.png';
import ImgInput from 'assets/_v2/positionList/img_character_joy_input.png';
import CustomInfiniteScroll from 'components/CustomInfiniteScroll';
import { IPositionCurationTab } from 'components/_v2/position/curation/PositionCurationTab';
import PositionCard from '__designkit__/card/PositionCard';
import { IPositionList, PositionListErrorFrame, PositionResultFrame } from 'components/_v2/position/PositionList';
import { ErrorCodes } from 'consts/ErrorCodes';
import Fonts from '__designkit__/common/fonts';
import colors from '__designkit__/common/colors';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import PositionCurationModel, { CurationDefaultType } from 'models/_v2/PositionCurationModel';
import PositionListModel from 'models/_v2/PositionListModel';
import { injectStore } from 'models/store';
import React, { FC } from 'react';
import styled from 'styled-components';
import SupportMail from 'consts/_v2/GlobalText';
import PostionFilterTab from '../PostionFilterTab';

const Frame = styled.div`
  > h1{
    padding:0px 16px 0px 16px;
    font: ${Fonts.H5_Bold};
    color:${colors.JOBDA_BLACK};
    margin-bottom: 12px;
  }

  > h2{
    padding:0px 16px 0px 16px;
    font: ${Fonts.B3_Medium};
    color:${colors.JOBDA_BLACK};
    margin-bottom: 32px;
    white-space: pre-line;
  }
`;

const PaddingTop = styled.div`
  padding-top: 16px;
`;

export interface IPositionCurationList extends IPositionCurationTab {
  login?: Login;
}

const PositionCurationList: FC<IPositionList & IPositionCurationList> = ({ login = new Login(), positionCurationModel = new PositionCurationModel(), positionListModel = new PositionListModel() }) => {
  const { searchPositions, curations, curationSelect, recommendPositionError } = positionCurationModel;

  const loadInfiniteScroll = async () => {
    await positionCurationModel.lazeLoadCurationsPositions(positionListModel.searchFilters);
  };

  return (
    <>
      {curationSelect !== CurationDefaultType.RECOMMEND
        ? <PostionFilterTab />
        : <PaddingTop />}

      <Frame>
        {
        curationSelect === CurationDefaultType.RECOMMEND ? (
          <>
            <h1>{login.userInfo?.name}님께 잡다가 추천하는 포지션</h1>
            <h2>{login.userInfo?.name}님이 입력한 프로필, 역량검사결과를 분석하여 꼭 맞는 포지션을 찾았어요.
              추천포지션에 지원하여 합격 확률을 높여보세요!
            </h2>
          </>
        )
          : (
            <>
              <h1>{curations[curationSelect] && curations[curationSelect].definition}</h1>
              <h2>{curations[curationSelect] && curations[curationSelect].description}</h2>
            </>
          )
        }
        {
          curationSelect === CurationDefaultType.RECOMMEND ? (
            <PositionResultFrame>
              {
            searchPositions && searchPositions.positions.length > 0 && searchPositions.positions.map((data, index) => (
              <PositionCard
                key={`match-position-total-card-${data.positionSn}`}
                item={data}
              />
            ))
          }
            </PositionResultFrame>
          )
            : (
              <>
                {searchPositions !== null && searchPositions.pages !== undefined && (
                <CustomInfiniteScroll initonInfinite={false} onInfinite={loadInfiniteScroll} threshold={150} usingDownBtn={searchPositions?.pages?.page < searchPositions?.pages?.totalPages - 1} downBtnName='포지션 더보기'>
                  <PositionResultFrame>
                    {
                  searchPositions && searchPositions.positions.length > 0 && searchPositions.positions.map((data, index) => (
                    <PositionCard
                      key={`match-position-total-card-${data.positionSn}`}
                      item={data}
                    />
                  ))
                  }
                  </PositionResultFrame>
                </CustomInfiniteScroll>
                )}
              </>
            )

        }

        {
        curationSelect === CurationDefaultType.RECOMMEND && recommendPositionError === '' && searchPositions && searchPositions.positions.length === 0 && (
          <PositionListErrorFrame>
            <img src={ImgNotResult} alt='결과 없음 ' />
            <h1>아쉽게도 나와 맞는 포지션이 없네요.</h1>
            <h2>프로필 강화, 선호 조건 조정을 통해<br />
              더 많은 포지션을 추천받아 보세요.
            </h2>
          </PositionListErrorFrame>
        )
      }
        {
        curationSelect !== CurationDefaultType.RECOMMEND && recommendPositionError === '' && searchPositions && searchPositions.positions.length === 0 && (
          <PositionListErrorFrame>
            <img src={ImgInput} alt='포지션 입력중' />
            <h1>해당 조건의 포지션을 찾을 수 없어요.</h1>
            <h2>필터 조건을 수정해보시는건 어때요?</h2>
          </PositionListErrorFrame>
        )
      }
        {
        curationSelect === CurationDefaultType.RECOMMEND && recommendPositionError === ErrorCodes.get_curations_positions.B901 && (
        <PositionListErrorFrame>
          <img src={ImgError} alt='통신 실패' />
          <h1>추천 포지션을 가지고 오는데 <br />
            실패했어요.
          </h1>
          <h2>해결을 원하시는 경우 아래의<br />
            E-mail(잡다 헬프데스크)로 문의해 주세요.<br />
          </h2>
          <a className='email' href={`mailto:${SupportMail}`}>{SupportMail}</a>
        </PositionListErrorFrame>
        )
      }
        {
        curationSelect === CurationDefaultType.RECOMMEND && recommendPositionError === ErrorCodes.get_curations_positions.B905 && (
        <PositionListErrorFrame>
          <img src={ImgFind} alt='포지션 찾는중' />
          <h1>회원님과 적합한 포지션을<br />
            찾는 중입니다.
          </h1>
          <h2>분석 결과를 확인하시려면 <br />
            잠시 후 다시 확인해 주세요.
          </h2>
        </PositionListErrorFrame>
        )
      }
      </Frame>
    </>
  );
};
export default inject(injectStore.login, injectStore.positionCurationModel, injectStore.positionListModel)(observer(PositionCurationList));
