import { Button, ButtonMode } from '__designkit__/button/Button';
import Colors from '__designkit__/common/colors';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import JDSelector, { JDSelectorType } from 'components/_v2/_common/input/JDSelector';
import ProfileEditTitleComponent from 'components/_v2/profile/ProfileEditTitleComponent';
import TabTitle from 'components/_v2/profile/condition/TabTitle';
import { ConditionRegisterType } from 'consts/_v2/profile/ProfileType';
import useQueryParams from 'hooks/useQueryParams';
import useToast from 'hooks/useToast';
import { IPutConditionSalaryRq } from 'interfaces/_v2/profile/IConditionRqRs';
import IComponentProps from 'interfaces/props/IComponentProps';
import useProfileConditions, { useFetchSaveProfileConditionsSalary } from 'query/profiles/useProfileConditionsQuery';
import React, { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

const TabFrame = styled.div<{isResumeModal?:boolean}>`
  width: 100%;
  height: calc(100% - 78px);
  background-color: ${Colors.WHITE_100};
 
`;
const BtnFrame = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 0 16px;
  height: fit-content;
  position: fixed;
  bottom: env(safe-area-inset-bottom);
  height: 78px;
  background-color: ${Colors.WHITE_100};
  z-index: 100000;
`;
const ButtonFrame = styled.div`
  position: fixed;
  bottom: env(safe-area-inset-bottom);
  width: 100%;
  height: 78px;
  padding: 16px;
  border-top: 1px solid ${Colors.CG_40};
  background-color: ${Colors.WHITE_100};
`;
const Frame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
  margin: auto 0;
`;

const RangeFrame = styled.div`
  width: 100%;
  height: fit-content;
  padding: 0px 16px;
`;

interface ISalaryTabProps extends IComponentProps {
  salaryData?:number;
  hidden?: boolean;
  setConditionRegisterType: (type: ConditionRegisterType) => void;
  isResumeModal?: boolean;
  setStartChecking?: (startChecking: boolean) => void;

}

const SalaryTab: FC<ISalaryTabProps> = ({ setStartChecking, isResumeModal, salaryData, hidden, setConditionRegisterType }) => {
  const defaultValues:IPutConditionSalaryRq = { salary: 0, sourcePage: '' };
  const formMethods = useForm({
    mode: 'onSubmit',
    defaultValues,
  });
  const { setValue, getValues, watch, trigger, reset } = formMethods;
  const { setToastObject } = useToast();
  const { sourcePage } = useQueryParams();
  const { refetch } = useProfileConditions(true);
  const saveSuccess = async () => {
    await refetch();
    if (setStartChecking)setStartChecking(true);
    setToastObject({ type: 'SUCCESS', isOpen: true, message: '성공적으로 저장되었습니다.' });
    setConditionRegisterType(ConditionRegisterType.LIST);
  };
  const { mutate: saveProfileSalaryConditions } = useFetchSaveProfileConditionsSalary(saveSuccess);

  // Migration: 기존에 100 단위로 희망 연봉을 가지고 가던 회원들의 연봉을 1000 단위로 변경
  useEffect(() => {
    if (!salaryData) return;
    setValue('salary', Math.floor(salaryData / 1000) * 1000);
    reset({ salary: Math.floor(salaryData / 1000) * 1000 });
    trigger();
  }, [salaryData, setValue, trigger]);

  const onClickSaveBtn = () => {
    const salary = Number(getValues('salary'));
    if (salary) {
      saveProfileSalaryConditions({ salary, sourcePage });
    } else {
      setToastObject({ type: 'ERROR', isOpen: true, message: '저장에 필요한 정보를 작성해 주세요.' });
    }
  };
  if (hidden) return null;
  return (
    <TabFrame isResumeModal={isResumeModal}>
      <FormProvider {...formMethods}>
        <ProfileEditTitleComponent
          title='희망하는 최소 연봉을 알려주세요.'
          subtitle='인사 담당자가 근무 조건 조율 시 가장 중요하게 보는 항목이에요.'
          divider={false}
        />
        <Frame>
          <RangeFrame>
            <JDSelector
              name='salary'
              selectTitle='희망하는 연봉을 선택해 주세요.'
              type={JDSelectorType.NORMAL}
              value={String(watch('salary')) || ''}
              defaultText='희망하는 연봉을 선택해 주세요.'
            >
              <select>
                <option value='' hidden>희망 연봉</option>
                <option value='1000' hidden>1,000 만원 이상</option>
                <option value='1500'>1,500 만원 이상</option>
                <option value='2000'>2,000 만원 이상</option>
                <option value='2500'>2,500 만원 이상</option>
                <option value='3000'>3,000 만원 이상</option>
                <option value='3500'>3,500 만원 이상</option>
                <option value='4000'>4,000 만원 이상</option>
                <option value='4500'>4,500 만원 이상</option>
                <option value='5000'>5,000 만원 이상</option>
                <option value='5500'>5,500 만원 이상</option>
                <option value='6000'>6,000 만원 이상</option>
                <option value='6500'>6,500 만원 이상</option>
                <option value='7000'>7,000 만원 이상</option>
                <option value='7500'>7,500 만원 이상</option>
                <option value='8000'>8,000 만원 이상</option>
                <option value='8500'>8,500 만원 이상</option>
                <option value='9000'>9,000 만원 이상</option>
                <option value='9500'>9,500 만원 이상</option>
                <option value='10000'>10,000 만원 이상</option>
              </select>
            </JDSelector>
          </RangeFrame>
        </Frame>
      </FormProvider>
      {
        isResumeModal
          ? (
            <BtnFrame>
              <Button label='이전' buttonMode={ButtonMode.DEFAULT} size='large' outLined onClick={() => setConditionRegisterType(ConditionRegisterType.LIST)} />
              <Button label='저장하기' buttonMode={ButtonMode.PRIMARY} size='large' onClick={onClickSaveBtn} />
            </BtnFrame>
          )
          : (
            <ButtonFrame>
              <Button label='저장' buttonMode={ButtonMode.PRIMARY} size='large' onClick={onClickSaveBtn} />
            </ButtonFrame>
          )
      }
    </TabFrame>
  );
};

export default SalaryTab;
