import JDALink from 'components/JDALink';
import { IChallengeList } from 'components/_v2/challenge/ChallengeList';
import colors from '__designkit__/common/colors';
import { IChallengeAd } from 'interfaces/_v2/challenge/IChallengeList';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import ChallengeModel from 'models/_v2/ChallengeModel';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import JDSwiper, { JDFractions, JDSwiperSlide } from '../_common/JDSwiper';

const ChallengeAdFrame = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height:calc(100vw/8*9);
  background: ${colors.WHITE_100};
`;

const ChallengeAdCardFrame = styled.div`
  position:relative;
  width: 100%;
  height:calc(100vw/8*9);
  img{
    width  :100%;
    height: 100%;  
    object-fit: cover;
  }
`;

const ChallengeAd: FC<IChallengeList> = ({ challengeModel = new ChallengeModel() }) => {
  const { challengeAds } = challengeModel;
  useEffect(() => {
    const init = async () => {
      await challengeModel.loadChallengeAds();
    };
    init();
  }, []);

  const ChallengeAdCard = (item: IChallengeAd) => (
    <JDALink to={item.bannerLink}>
      <ChallengeAdCardFrame>
        <img
          src={item.bannerImageFiles.find(((value) => value.fileType === 'MO_BANNER'))?.fileUrl}
          alt='test-img'
          onError={(e) => e.currentTarget.src = 'https://picsum.photos/seed/picsum/360/405'}
        />
      </ChallengeAdCardFrame>
    </JDALink>
  );

  if (challengeAds.length === 0) return <></>;
  return (
    <ChallengeAdFrame>
      {challengeAds.length > 0
        && (
          <JDSwiper
            slidesPerView='auto'
            autoplay={{ delay: 3000 }}
            pagination={JDFractions}
          >
            {
              challengeAds.map((item) => (
                <JDSwiperSlide key={item.sn}>
                  {ChallengeAdCard(item)}
                </JDSwiperSlide>
              ))
            }

          </JDSwiper>
        )}
    </ChallengeAdFrame>
  );
};
export default inject(injectStore.challengeModel)(observer(ChallengeAd));
