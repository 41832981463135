import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import ProfileItemTitle from 'components/_v2/profile/ProfileItemTitle';
import ProfileListCard from 'components/_v2/profile/ProfileListCard';
import { Divider1G40 } from 'components/divider/Divider';
import { ReactSelectOption } from 'consts/_v2/_common/ReactSelectOptions';
import { IProfileProjectDto, IProfileProjectListConvert, IProfileProjectListRqRs, IProfileProjectSaveRq } from 'interfaces/_v2/profile/IProfileProjectRqRs';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
`;

const DescriptionCard = styled.div`
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  >h1{
    font: ${Fonts.B2_Bold};
    color:${colors.CG_90};
    margin-bottom: 12px;
  }
  >h2{
    width: 100%;
    display: inline-block;
    white-space: pre-line;
    font: ${Fonts.B2_Medium};
    color:${colors.CG_70};
  }
`;

const ProfilePreviewProject: FC<{ data: IProfileProjectListRqRs}> = ({ data }) => {
  const { projects } = data;
  const [importedProjects, setImportedProjects] = React.useState<IProfileProjectDto[]>([]);
  useEffect(() => {
    transformProject(projects);
  }, [projects]);

  const transformProject = (projectList: IProfileProjectSaveRq[]) => {
    if (projectList?.length) {
      const transformedProjects = projectList.map((item) => {
        if (item) {
          const newContribution: ReactSelectOption = {
            label: item.contribution ? `${item.contribution}%` : '',
            value: item.contribution,
          };

          return {
            title: item.title,
            startDate: item.startDate,
            endDate: item.endDate,
            teamType: item.teamType,
            contribution: item.contribution ? newContribution : undefined,
            performance: item.performance,
            institution: item.institution,
            skills: item.skills,
          };
        }
        return null;
      }).filter((item) => item !== null) as IProfileProjectDto[];

      if (transformedProjects) setImportedProjects(transformedProjects);
    }
  };
  return (
    <Frame>
      <ProfileItemTitle icon='project-light' text='프로젝트' />
      { importedProjects !== null && importedProjects.map((card:IProfileProjectDto, index:number) => (
        <ProfileListCard
          key={`card-list-${card.title}`}
          institution={card.title}
          date={`${card.startDate} ~  ${card.endDate}`}
          description={(
            <DescriptionCard>
              <h1>{card.institution}</h1>
              <h2>{card.performance}</h2>
            </DescriptionCard>
              )}
          index={index}
          length={importedProjects?.length || 0}
          team={card.teamType}
          contribution={card.contribution}
          skills={card.skills}
        />
      ))}
      <Divider1G40 />
    </Frame>
  );
};

export default ProfilePreviewProject;
