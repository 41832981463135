import URIs from 'consts/URIs';
import { ISearchCompany, ISearchRecommendKeyword, ISearchSimilarKeyword } from 'interfaces/_v2/search/IGlobalSearch';
import { action, observable } from 'mobx';
import request from 'utils/request';

export default class GlobalSearchModel {
  @observable recommendKeyword: ISearchRecommendKeyword[] | null = null;
  @observable similarKeyword: ISearchSimilarKeyword | null = null;
  @observable companies: ISearchCompany[]| null = null;
  @observable positionSize:number = 6;
  @observable companySize:number = 4
  @action
  searchRecommendKeyword = async () => {
    try {
      this.recommendKeyword = (await request<{ keywords: ISearchRecommendKeyword[] }>({
        method: 'get',
        url: URIs.get_search_recommend_keyword,
        params: { viewBenchmark: 1, size: 10 },
      })).keywords;
    } catch (e) {
      console.error(e);
    }
  }

  @action
  searchSimilarKeyword = async (keyword:string) => {
    try {
      this.similarKeyword = (await request<ISearchSimilarKeyword>({
        method: 'get',
        url: URIs.get_search_v2_similar_keyword,
        params: { keyword, positionSize: this.positionSize, companySize: this.companySize },
      }));
    } catch (e) {
      console.error(e);
    }
  }

  @action
  searchCompanyList = async (keyword:string) => {
    try {
      this.companies = (await request<{companies:ISearchCompany[]}>({
        method: 'get',
        url: URIs.get_companies_search,
        params: { keyword },
      })).companies;
    } catch (e) {
      console.error(e);
    }
  }
}
