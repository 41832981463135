import React, { FC } from 'react';
import styled from 'styled-components';

const BubbleTemplate = styled.div`
  position: absolute;
  border-radius: 50%;
  bottom: 0;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: bubbleUp;
  @keyframes bubbleUp {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(0, -160px);
    }
  }
`;

const Bubble1 = styled(BubbleTemplate)`
  width: 6px;
  height: 6px;
  left: 38px;
  animation-duration: 3s;
  animation-delay: 1s;
`;


const Bubble2 = styled(BubbleTemplate)`
  width: 5px;
  height: 5px;
  left: 59px;
  animation-duration: 3.5s;
  animation-delay: 0.5s;
`;

const Bubble3 = styled(BubbleTemplate)`
  width: 3px;
  height: 3px;
  left: 72px;
  animation-duration: 4.1s;
  animation-delay: 0.1s;
`;

const Bubble4 = styled(BubbleTemplate)`
  width: 6px;
  height: 6px;
  left: 91px;
  animation-duration: 3.1s;
  animation-delay: 0.3s;
`;

const Bubble5 = styled(BubbleTemplate)`
  width: 4px;
  height: 4px;
  left: 115px;
  animation-duration: 3.7s;
  animation-delay: 0.7s;
`;

const Bubble6 = styled(BubbleTemplate)`
  width: 6px;
  height: 6px;
  left: 133px;
  animation-duration: 2.5s;
  animation-delay: 0.6s;
`;

const Frame = styled.div`
  ${BubbleTemplate} {
    background: ${((props) => (props as any)['data-color'])};
  }
`;

interface IBubbleProps {
  color: string;
}

const Bubble: FC<IBubbleProps> = (({ color }) => (
  <Frame data-color={color}>
    <Bubble1 />
    <Bubble2 />
    <Bubble3 />
    <Bubble4 />
    <Bubble5 />
    <Bubble6 />
  </Frame>
));

export default Bubble;
