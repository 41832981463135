/* eslint-disable default-case */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import classnames from 'classnames/bind';
import Lottie from 'lottie-react';
import bell from 'assets/lottie/icon_file_green.json';
import useToast from 'hooks/useToast';
import { deleteInterviewReservation, getInterviewee, postInterviewReservation, postSignIn, putCompanyConfirm } from '__pc__/page/timeattack_marketer/fetch';
import { usePlanMatching } from '__pc__/page/timeattack_marketer/context';
import TodayRenderCheckUtil from 'utils/TodayRenderCheckUtil';
import JDBaseModal from 'components/_v2/_common/modals/JDBaseModal';
import ListFilter from './listFilter';
import MarketerCard from './marketerCard';
import styles from './index.module.scss';
import { IntervieweePositionType, IntervieweeVO, PostSignInRs } from '../types';

const cx = classnames.bind(styles);

export enum RecruitType {
  Brand = 'Brand',
  Contents = 'Contents',
  Performance = 'Performance',
  Pr = 'Pr',
  Md = 'Md',
}

export type RecruitUnionType = keyof typeof RecruitType;
export type SelectedListType = Record<RecruitUnionType, string[]>;

const defaultIntervieweeList: Record<RecruitUnionType, IntervieweeVO[]> = {
  Brand: [],
  Contents: [],
  Performance: [],
  Pr: [],
  Md: [],
};

const defaultSelectedList: SelectedListType = {
  Brand: [],
  Contents: [],
  Performance: [],
  Pr: [],
  Md: [],
};

const ListItem = () => {
  const [activeTab, setActiveTab] = useState<RecruitType>(RecruitType.Contents);
  const [selectedList, setSelectedList] = useState<SelectedListType>(defaultSelectedList);
  const [list, setList] = useState<Record<RecruitUnionType, IntervieweeVO[]>>(defaultIntervieweeList);
  const defaultList = useRef<Record<RecruitUnionType, IntervieweeVO[]>>(defaultIntervieweeList);
  const { setToastObject } = useToast();
  const [code, setCode] = useState<string>('');
  const [companyInfo, setCompanyInfo] = useState<any>(null);
  const [totalSelectedCount, setTotalSelectCount] = useState<number>(0);
  const [isModalVisible, setModalVisible] = useState(false);

  const handleButtonClick = () => {
    if (!companyInfo) return;
    putCompanyConfirm({
      'company-code': companyInfo.code,
      confirmed: companyInfo.confirmed === 0,
    },
    {
      onSuccess: (data) => {
        const confirmed = data[0].confirmed === 1;
        setCompanyInfo(data[0]);
        if (confirmed) {
          setModalVisible(true);
        } else {
          setToastObject({ isOpen: true, type: 'SUCCESS', message: '계속해서 인재를 선택할 수 있습니다.' });
        }
      },
    });
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    const asyncInit = async () => {
      const tmp = await TodayRenderCheckUtil.getValue<PostSignInRs[number]>('company-info');
      setCompanyInfo(tmp);
      setCode(companyInfo?.companyInfo.code ?? '');
    };
    asyncInit();
  }, []);
  const companyCode = companyInfo?.code ?? '';
  const { getInterviewReservations } = usePlanMatching();

  const selectCard = (key: RecruitUnionType, info: IntervieweeVO) => {
    if (!companyInfo) return;

    const isSelected = selectedList[key].includes(info.code);

    if (isSelected) {
      deleteInterviewReservation(
        {
          'company-code': companyInfo?.code ?? '',
          'interviewee-code': info.code,
        },
        {
          onSuccess: () => {
            const newSelected = { ...selectedList, [key]: selectedList[key].filter((item) => item !== info.code) };
            setSelectedList(newSelected);
            fetchReservationList();
            setToastObject({ isOpen: true, type: 'ERROR', message: '면접 예약이 취소되었습니다.' });
          },
          onError: (e) => {
            if (e.message === 'TIMEOUT_ERROR') {
              setToastObject({ isOpen: true, type: 'ERROR', message: '면접 예약 시간이 지났습니다.' });
              return;
            }
            setToastObject({ isOpen: true, type: 'ERROR', message: '면접 예약 취소에 실패했습니다.' });
            fetchReservationList();
          },
        },
      );
    } else if (totalSelectedCount < 10) {
      postInterviewReservation(
        {
          'company-code': companyInfo?.code ?? '',
          'interviewee-code': info.code,
        },
        {
          onSuccess: () => {
            const newSelected = { ...selectedList, [key]: [...selectedList[key], info.code] };
            setSelectedList(newSelected);
            fetchReservationList();
            setToastObject({
              isOpen: true,
              type: 'SUCCESS',
              message: '면접 예약이 완료되었습니다.',
              subMessage: '인재를 모두 선택했다면, 면접 예약 확정하기 버튼을 눌러주세요.',
            });
          },
          onError: (e) => {
            if (e.message === 'TIMEOUT_ERROR') {
              setToastObject({ isOpen: true, type: 'ERROR', message: '면접 예약 시간이 지났습니다.' });
              return;
            }
            setToastObject({ isOpen: true, type: 'ERROR', message: '면접 예약에 실패했습니다.' });
            fetchReservationList();
          },
        },
      );
    } else {
      setToastObject({ isOpen: true, type: 'INFO', message: '최대 10명의 구직자에게 면접제안을 할 수 있습니다.' });
    }
  };

  useEffect(() => {
    const tmp = Object.values(selectedList).reduce((add, curr) => add + curr.length, 0);
    setTotalSelectCount(tmp);
  }, [selectedList]);
  const fetchIntervieweeList = async () => {
    try {
      const data = await getInterviewee();
      if (!data) return;
      const list = {
        Performance: data.filter((item) => item.position === 'PERFORMANCE'),
        Brand: data.filter((item) => item.position === 'BRAND'),
        Contents: data.filter((item) => item.position === 'CONTENTS'),
        Pr: data.filter((item) => item.position === 'PR'),
        Md: data.filter((item) => item.position === 'MD'),
      };
      setList(list);
      defaultList.current = list;
    } catch (e) {
      console.error(e);
    }
  };

  const fetchReservationList = useCallback(async () => {
    try {
      const data = await getInterviewReservations();
      if (!data) return;
      const newList = structuredClone(defaultList.current);
      const newSelectedList = structuredClone(defaultSelectedList);
      data.forEach((item) => {
        const key = getKeyByPosition(item.intervieweePosition);
        const target = newList[key].find((list:any) => `${list.code}` === item.intervieweeCode);
        target?.reservations.push(item);
        if (item.companyCode === companyCode && !newSelectedList[key].includes(item.intervieweeCode)) {
          newSelectedList[key].push(item.intervieweeCode);
        }
      });
      setList(newList);
      setSelectedList(newSelectedList);
    } catch (e) {
      console.error(e);
    }
  }, [companyCode, defaultList, getInterviewReservations]);

  const fetchPostSignIn = useCallback(async () => {
    if (!companyInfo) return;
    const response = await postSignIn({
      'company-code': companyInfo.code,
      agree: true,
    });
    if (response) {
      setCompanyInfo(response[0]);
      sessionStorage.setItem('company-code', response[0].code);
    }
  }, [companyInfo]);

  useEffect(() => {
    (async () => {
      await fetchIntervieweeList();
      await fetchReservationList();
    })();

    const fetchOnFocus = () => {
      fetchPostSignIn();
      fetchReservationList();
    };

    window.addEventListener('focus', fetchOnFocus);
    return () => {
      window.removeEventListener('focus', fetchOnFocus);
    };
  }, []);

  return (
    <div className={cx('wrap')}>
      {/* 탭 리스트 */}
      <ListFilter setActiveTab={setActiveTab} activeTab={activeTab} userList={defaultList.current} />

      {/* 탭 상세 */}
      <div className={cx('cardList')}>
        {activeTab === RecruitType.Contents && (
          <>
            {list.Contents.map((contents, index) => (
              <MarketerCard
                type='Contents'
                key={`${contents}-${index}`}
                userInfo={contents}
                isSelected={selectedList.Contents.includes(contents.code)}
                onSelect={() => selectCard('Contents', contents)}
                isOff={contents.reservations.length >= 3}
              />
            ))}
          </>
        )}
        {activeTab === RecruitType.Performance && (
          <>
            {list.Performance.map((performance, index) => (
              <MarketerCard
                type='Performance'
                key={`${performance}-${index}`}
                userInfo={performance}
                isSelected={selectedList.Performance.includes(performance.code)}
                onSelect={() => selectCard('Performance', performance)}
                isOff={performance.reservations.length >= 3}
              />
            ))}
          </>
        )}
        {activeTab === RecruitType.Brand && (
        <>
          {list.Brand.map((brand, index) => (
            <MarketerCard
              type={'Brand'}
              key={`${brand}-${index}`}
              userInfo={brand}
              isSelected={selectedList.Brand.includes(brand.code)}
              onSelect={() => selectCard('Brand', brand)}
              isOff={brand.reservations.length >= 3}
            />
          ))}
        </>
        )}
        {activeTab === RecruitType.Pr && (
          <>
            {list.Pr.map((pr, index) => (
              <MarketerCard
                type='Pr'
                key={`${pr}-${index}`}
                userInfo={pr}
                isSelected={selectedList.Pr.includes(pr.code)}
                onSelect={() => selectCard('Pr', pr)}
                isOff={pr.reservations.length >= 3}
              />
            ))}
          </>
        )}
        {activeTab === RecruitType.Md && (
          <>
            {list.Md.map((md, index) => (
              <MarketerCard
                type='Md'
                key={`${md}-${index}`}
                userInfo={md}
                isSelected={selectedList.Md.includes(md.code)}
                onSelect={() => selectCard('Md', md)}
                isOff={md.reservations.length >= 3}
              />
            ))}
          </>
        )}
      </div>

      {/* 플로팅 버튼 */}
      <div className={cx('floatingButton')}>
        <div className={cx('inner')}>
          <div className={cx('text')}>
            <div className={cx('lottieWrap')}>
              <Lottie animationData={bell} loop autoplay />
            </div>
            <div className={cx('message')}>
              <span className={cx('count')}>{totalSelectedCount}명의 인재</span>에게 면접 예약했습니다.
              <span className={cx('subMessage')}>(최대 10명)</span>
            </div>
          </div>
          <div className={cx('action')}>
            {companyInfo?.confirmed === 1 ? (
              <button className={cx('btnComplete', 'btnCancel')} onClick={handleButtonClick}>면접 예약 다시하기</button>
            ) : (
              <button className={cx('btnComplete')} onClick={handleButtonClick}>
                면접 예약 확정하기
              </button>
            )}

            <JDBaseModal
              dimmed
              isOpen={isModalVisible}
              onClickClose={() => handleModalClose()}
            >
              <div className={cx('modalComplete')}>
                <div className={cx('lottie')}>
                  <Lottie animationData={bell} loop autoplay />
                </div>
                <p className={cx('title')}>예약이 확정되었습니다.</p>
                <span className={cx('subTitle')}>
                  면접 일정 협의를 위해 큐레이터가 빠르게 연락드릴게요
                </span>
                <button className={cx('btnClose')} onClick={handleModalClose}>
                  확인
                </button>
              </div>
            </JDBaseModal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListItem;

function getKeyByPosition(position: IntervieweePositionType) {
  switch (position) {
    case 'BRAND':
      return 'Brand';
    case 'CONTENTS':
      return 'Contents';
    case 'PERFORMANCE':
      return 'Performance';
    case 'PR':
      return 'Pr';
    case 'MD':
      return 'Md';
  }
}
