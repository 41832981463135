import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { ReactComponent as IconCheckCircle } from 'assets/icon/icon_check_circle_24_Regular.svg';
import { StyledIconSVG } from 'consts/assets/icons/iconPages';
import IModalFrameProps from 'interfaces/IModalFrameProps';
import React, { FC } from 'react';
import styled from 'styled-components';
import { JDAModalButton, JDAModalContentDescription, JDAModalContentText } from '../modals/JDAModalComponents';
import JDAModalFrame from '../modals/JDAModalFrame';

const StyledModalContent = styled.div`
  text-align: center;
  padding: 24px;
  
  ${JDAModalContentText} {
    margin-top: 16px;
    margin-bottom: 8px;
  }

  ${JDAModalContentDescription} {
    font: ${Fonts.B1_Medium};
      line-height: 23px;
      margin-bottom: 24px;
      color: ${colors.CG_80};

      >p {
          font: ${Fonts.B2_Medium};
          margin-top: 8px;
          color: ${colors.B_90};
      }
  }

  ${JDAModalButton} {
    margin-bottom: 12px;
  }
`;

const MyPagePartershipSuccessModal:FC<IModalFrameProps> = ((props) => {
  const { onClickClose, ...rest } = props;
  return (
    <JDAModalFrame {...rest}>
      <div style={{ width: 312 }}>
        <StyledModalContent>
          <StyledIconSVG fill={colors.G_100}><IconCheckCircle width={64} height={64} /></StyledIconSVG>
          <JDAModalContentText>
            제휴서비스 신청이<br />
            완료되었습니다.
          </JDAModalContentText>
          <JDAModalContentDescription>
            지금부터 잡다 제휴 서비스를<br />
            자유롭게 이용해 보세요.
          </JDAModalContentDescription>

          <JDAModalButton onClick={onClickClose}>확인</JDAModalButton>
        </StyledModalContent>
      </div>
    </JDAModalFrame>
  );
});

export default MyPagePartershipSuccessModal;
