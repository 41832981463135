/* eslint-disable no-restricted-syntax */
import { yupResolver } from '@hookform/resolvers/yup';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import ImgEmptyJoy from 'assets/_v2/challenge/img_character_joy_empty_soon.png';
import Loading from 'components/Loading';
import JDClosableChip from 'components/_v2/_common/chip/JDClosableChip';
import ProfileItemTitle from 'components/_v2/profile/ProfileItemTitle';
import { FormType } from 'components/_v2/profile/loadResume/ProfileResumeLoadModal';
import ResumeSingleMenuItem from 'components/_v2/profile/loadResume/ResumeSingleMenuItem';
import ResumeEditCareer from 'components/_v2/profile/loadResume/resumeEditContent/ResumeEditCareer';
import ResumeEditEducation from 'components/_v2/profile/loadResume/resumeEditContent/ResumeEditEducation';
import ResumeEditExperience from 'components/_v2/profile/loadResume/resumeEditContent/ResumeEditExperience';
import ResumeEditForeign from 'components/_v2/profile/loadResume/resumeEditContent/ResumeEditForeign';
import ResumeEditPrize from 'components/_v2/profile/loadResume/resumeEditContent/ResumeEditPrize';
import ResumeEditProject from 'components/_v2/profile/loadResume/resumeEditContent/ResumeEditProject';
import resumeSchema from 'components/_v2/profile/loadResume/schema/resumeSchema';
import { JDASqureCheckBoxInput } from 'consts/style/mixins';
import useToast from 'hooks/useToast';
import { BaseProfileActivityAndForeignDto, BaseProfileCareerDto, BaseProfileCareerPrizeDto, BaseProfileEducationDto, BaseProfileExaminationDto, BaseProfileLicenseDto, BaseProfileProjectDto, BaseProfileProjectVO, BaseProfileSkillDto, InsightResumeGetVO, InsightResumeLogRq, InsightResumeSaveRq, convertResumeData } from 'interfaces/_v2/insight/IInsightResumeRqRs';
import { ExperienceActivitiesTypeText } from 'interfaces/_v2/profile/IProfileExperienceRqRs';
import { SkillLevelText } from 'interfaces/_v2/profile/IProfileKnowledgeAndSkillsRs';
import { SkillLevel } from 'interfaces/rqrs/IProfileKnowledgeAndSkillsRs';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import useProfileConditions from 'query/profiles/useProfileConditionsQuery';
import { useInsightResumeDetail, useInsightResumeLog, useInsightResumeSave } from 'query/resume/useResumeQuery';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import ScrollUtil from 'utils/ScrollUtil';

const Frame = styled.form`
  width: 100%;
  height: fit-content;
  min-height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  & * {
    user-select: none;
  }
`;

const TitleFrame = styled.div`
  .title-profile {
    width: 100%;
    height: fit-content;
    font: ${Fonts.H4_Bold};
    color: ${Colors.JOBDA_BLACK};
  }
`;
const CheckBoxIcon = styled.div`
   input[type='checkbox'] {
      ${JDASqureCheckBoxInput()};

      &:checked {
        background: ${Colors.G_150};
      }
      :after {
        border-radius: 4px;
        border: 2px solid ${Colors.CG_50};
      }
    }
`;

const ContentFrame = styled.div`
    margin-bottom: 26px;

    .title {
        font: ${Fonts.B2_Bold};
        color: ${Colors.JOBDA_BLACK};
        letter-spacing: -0.01em;
        margin-bottom: 20px;
    }

    .list-frame {
        display: flex;
        flex-wrap: wrap;
        gap:8px;
    }
`;

const FailedFrame = styled.div`
  display: flex;
  justify-content:center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 100px;
  .failed-text{
    font:${Fonts.H5_Bold};
    color:${Colors.JOBDA_BLACK};
  }
  .img-joy{
    width: 100px;
    height: 100px;
    margin-bottom: 32px;
  }
`;

interface ILoadCompleteForm {
    resetDirtyForm:boolean;
    setResetDirtyForm: (value: boolean) => void;
    setFormType: (type: FormType) => void;
    formType: FormType;
    login?:Login
    selectedResume?:number
    setTotalResumeCnt: (value: number) => void;
    setIsSelectedResumeItem: (value: boolean) => void;
    setStep: (value: number) => void;
    isMatchDashboard?:boolean;
}
export interface ChildComponentHandles {
  handleSaveDetail: () => void;
  handleTrigger:() => void;
  handleSubmit:() => void;
}

const LoadCompleteForm = forwardRef<ChildComponentHandles, ILoadCompleteForm>((props, ref) => {
  const { formType, setFormType, resetDirtyForm, isMatchDashboard, setResetDirtyForm, setTotalResumeCnt, selectedResume = 0, setIsSelectedResumeItem, setStep, login = new Login(), ...rest } = props;
  const { data: resumeData } = useInsightResumeDetail(selectedResume, !!login.userInfo);
  const { data: profileConditionsData } = useProfileConditions(!!login.userInfo);
  const [tmpFormValues, setTmpFormValues] = useState<InsightResumeGetVO>();
  const [selectedEduIdx, setSelectedEduIdx] = useState<number>(-1);
  const [selectedCareerIdx, setSelectedCareerIdx] = useState<number>(-1);
  const [selectedProjectIdx, setSelectedProjectIdx] = useState<number>(-1);
  const [selectedExperienceIdx, setSelectedExperienceIdx] = useState<number>(-1);
  const [selectedForeignIdx, setSelectedForeignIdx] = useState<number>(-1);
  const [selectedPrizeIdx, setSelectedPrizeIdx] = useState<number>(-1);
  const [isLoading, setIsLoading] = useState(true);
  const [isInitialCheckDone, setIsInitialCheckDone] = useState(false);
  const [selectedItems, setSelectedItems] = useState<Record<string, boolean>>({});
  const [prefixName, setPrefixName] = useState<string>('');
  const [isSaveBack, setIsSaveBack] = useState<boolean>(false);
  const [isResumeEmpty, setIsResumeEmpty] = useState<boolean>(false);
  const [isDirtyModalOpen, setIsDirtyModalOpen] = useState<boolean>(false);
  const topRef = useRef<HTMLDivElement | null>(null);

  const saveSuccess = async () => {
  };
  const { mutate: saveInsightResume } = useInsightResumeSave(saveSuccess);
  const { setToastObject } = useToast();

  const formMethods = useForm({
    mode: 'all',
    resolver: yupResolver(resumeSchema),
  });
  const { handleSubmit, watch, control, reset, register, trigger, errors, getValues, formState } = formMethods;
  const careersFields:BaseProfileCareerDto[] = watch('career.careers');
  const educationsFields:BaseProfileEducationDto[] = watch('education.educations');
  const projectsFields:BaseProfileProjectVO[] = watch('project.projects');
  const experienceFields:BaseProfileActivityAndForeignDto[] = watch('experience.activities');
  const foreignFields:BaseProfileActivityAndForeignDto[] = watch('experience.foreign');
  const prizeFields:BaseProfileCareerPrizeDto[] = watch('prize.prizes');
  const { pathname } = useLocation();
  const { fields: licenseFields, remove: licenseRemove } = useFieldArray<BaseProfileLicenseDto>({
    control,
    name: 'knowledgeAndSkill.licenses',
  });
  const { fields: skillsFields, remove: skillRemove } = useFieldArray<BaseProfileSkillDto>({
    control,
    name: 'knowledgeAndSkill.skills',
  });
  const { fields: examinationFields, remove: examinationRemove } = useFieldArray<BaseProfileExaminationDto>({
    control,
    name: 'knowledgeAndSkill.examinations',
  });
  const saveLogSuccess = () => {
  };
  const { mutate: saveResumeInduce } = useInsightResumeLog(saveLogSuccess);
  const onClickBtn = async () => {
    const induceRq:InsightResumeLogRq = {
      action: 'VIEW',
      actionSource: pathname,
      actionComponent: 'modal_edit_insight_resume',
      data: { insightResumeSn: selectedResume },
    };
    saveResumeInduce(induceRq);
  };
  useEffect(() => {
    onClickBtn();
  }, []);
  // 초기 데이터 변환하는 로직.
  useEffect(() => {
    if (resumeData) {
      setIsLoading(true);
      const convertedData = convertResumeData(resumeData);
      reset(JSON.parse(JSON.stringify(convertedData)));
      setTmpFormValues(JSON.parse(JSON.stringify(convertedData)));
      checkIfEmpty(convertedData);
      setIsLoading(false);
    }
  }, [resumeData, reset, trigger]);

  const checkIfEmpty = (data:InsightResumeGetVO) => {
    const sections: [keyof InsightResumeGetVO, string][] = [
      ['education', 'educations'],
      ['career', 'careers'],
      ['project', 'projects'],
      ['experience', 'activities'],
      ['experience', 'foreign'],
      ['prize', 'prizes'],
      ['knowledgeAndSkill', 'licenses'],
      ['knowledgeAndSkill', 'examinations'],
      ['knowledgeAndSkill', 'skills'],
    ];
    let isTmpResumeEmpty = true;

    for (const [sectionName, fieldName] of sections) {
      const sectionData = data[sectionName] as any; // Use 'any' for dynamic field access
      if (sectionData[fieldName] && sectionData[fieldName].length > 0) {
        isTmpResumeEmpty = false;
        break;
      }
    }
    setIsResumeEmpty(isTmpResumeEmpty);
  };
  // 변환 후 최초 trigger & 유효성 통과한 아이템 체크
  useEffect(() => {
    if (!isInitialCheckDone && resumeData) {
      trigger().then(() => {
        const convertedData: InsightResumeGetVO = convertResumeData(resumeData);

        const initialSelectedItems: Record<string, boolean> = {};
        const sections = ['education.educations', 'career.careers', 'project.projects', 'experience.activities', 'experience.foreign', 'prize.prizes'];
        sections.forEach((section) => {
          const [sectionName, fieldName] = section.split('.');
          const items = (convertedData as any)[sectionName][fieldName] as any[];
          items.forEach((_, index) => {
            const path = `${section}[${index}]`;
            if (!checkErrorAtPath(path)) {
              initialSelectedItems[path] = true;
            }
          });
        });
        setSelectedItems(initialSelectedItems);
        if (Object.keys(initialSelectedItems).length > 0) {
          setIsSelectedResumeItem(true);
        }
        setIsInitialCheckDone(true);
      });
    }
  }, [isLoading]);

  // 아이템 체크하는 함수
  const toggleSelection = (category: string, index: number) => {
    const key = `${category}[${index}]`;
    setSelectedItems((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  // 아이템 안으로 들어가는 함수
  const editSelection = (index:number, category:FormType, prefix:string) => {
    if (category === FormType.EDUCATION) setSelectedEduIdx(index);
    if (category === FormType.CAREER) setSelectedCareerIdx(index);
    if (category === FormType.PROJECT) setSelectedProjectIdx(index);
    if (category === FormType.EXPERIENCE) setSelectedExperienceIdx(index);
    if (category === FormType.FOREIGN) setSelectedForeignIdx(index);
    if (category === FormType.PRIZE) setSelectedPrizeIdx(index);
    setPrefixName(prefix);
    setFormType(category);
  };

  const makeEduSubTitle = (startDate: string, endDate: string | null, options?:string): string => {
    if (startDate) {
      return `${startDate.replace(/-/g, '.')} ~ ${endDate ? endDate.replace(/-/g, '.') : '재학 중'} ${options ? '|' : ''} ${options || ''}`;
    }
    return '';
  };
  const makeCareerSubTitle = (startDate: string, endDate: string | null, options?:string): string => {
    if (startDate) {
      return `${startDate.replace(/-/g, '.')} ~ ${endDate ? endDate.replace(/-/g, '.') : '재직 중'} ${options ? '|' : ''} ${options || ''}`;
    }
    return '';
  };
  const makeExperienceSubTItle = (startDate: string, endDate: string | null, options?:string): string => {
    let tmpOptions = '';
    if (options) {
      tmpOptions = ExperienceActivitiesTypeText[options as keyof typeof ExperienceActivitiesTypeText];
    }
    if (startDate) {
      return `${startDate.replace(/-/g, '.')} ~ ${endDate ? endDate.replace(/-/g, '.') : '재직 중'} ${options ? '|' : ''} ${tmpOptions || ''}`;
    }
    return '';
  };
  const makePrizeSubTitle = (startDate: string, options?:string): string => {
    if (startDate) {
      return `${startDate.replace(/-/g, '.')} ${options ? '|' : ''} ${options || ''}`;
    }
    return '';
  };

  // forwardRef -> 바깥 버튼과 상호작용
  useImperativeHandle(ref, () => ({

    // 임시 저장
    handleSaveDetail() {
      let tmpIdx = -1;
      if (formType === FormType.EDUCATION) {
        tmpIdx = selectedEduIdx;
      } else if (formType === FormType.CAREER) {
        tmpIdx = selectedCareerIdx;
      } else if (formType === FormType.PROJECT) {
        tmpIdx = selectedProjectIdx;
      } else if (formType === FormType.EXPERIENCE) {
        tmpIdx = selectedExperienceIdx;
      } else if (formType === FormType.FOREIGN) {
        tmpIdx = selectedForeignIdx;
      } else if (formType === FormType.PRIZE) {
        tmpIdx = selectedPrizeIdx;
      }
      const path = `${prefixName}[${tmpIdx}]`;
      if (checkErrorAtPath(path)) {
        setToastObject({ isOpen: true, message: '저장에 필요한 정보를 작성해 주세요.', type: 'ERROR' });
      } else {
        const currentValues = JSON.parse(JSON.stringify(getValues()));
        setTmpFormValues(currentValues);
        setToastObject({ isOpen: true, message: '성공적으로 저장되었습니다.', type: 'SUCCESS' });
        setSelectedItems((prev) => ({
          ...prev,
          [path]: true,
        }));
        setFormType(FormType.ALL);
        topRef.current && topRef.current.scrollIntoView();
      }
    },

    // 뒤로가기 함수
    async handleTrigger() {
      // if (formState.isDirty) {
      //   setIsDirtyModalOpen(true);
      // } else {
      //   handleSaveBack();
      // }
      handleSaveBack();
    },

    // 가져오기 함수
    async handleSubmit() {
      const allFormData = getValues() as InsightResumeGetVO;
      const projects: BaseProfileProjectDto[] = allFormData.project?.projects?.filter((_, index) => selectedItems[`project.projects[${index}]`])
        .map((project) => ({
          ...project,

          contribution: typeof project.contribution?.value === 'number' ? project.contribution.value : 0,
        })) || [];
      const activitiesAndForeignExperiences = [
        ...allFormData.experience?.activities
          ?.filter((_, index) => selectedItems[`experience.activities[${index}]`])
          .map((item) => ({ ...item, tag: item.tag } as BaseProfileActivityAndForeignDto)) || [],
        ...allFormData.experience?.foreign
          ?.filter((_, index) => selectedItems[`experience.foreign[${index}]`])
          .map((item) => ({ ...item, tag: 'OVERSEAS' } as BaseProfileActivityAndForeignDto)) || [],
      ];
      const licenses = licenseFields?.map((field) => ({
        code: field.code ?? 0,
        name: field.name ?? '',
      })) || [];

      const examinations = examinationFields?.map((field) => ({
        code: field.code ?? 0,
        name: field.name ?? '',
        gradeCode: field.gradeCode ?? 0,
        grade: field.grade ?? '',
        score: field.score ?? 0,
        gradeYn: field.gradeYn ?? false,
        scoreYn: field.scoreYn ?? false,
      })) || [];

      const skills = skillsFields?.map((field) => ({
        code: field.code ?? 0,
        keyword: field.keyword ?? '',
        level: field.level ?? SkillLevel.BASIC,
      })) || [];
      const filteredData = {
        education: { educations: allFormData.education?.educations?.filter((_, index) => selectedItems[`education.educations[${index}]`]) || [] },
        career: { careers: allFormData.career?.careers?.filter((_, index) => selectedItems[`career.careers[${index}]`]) || [] },
        project: { projects },
        knowledgeAndSkill: {
          licenses,
          examinations,
          skills,
        },
        experience: { activitiesAndForeignExperiences },
        prize: { prizes: allFormData.prize?.prizes?.filter((_, index) => selectedItems[`prize.prizes[${index}]`]) || [] },

      };
      const insightResumeSaveRq: InsightResumeSaveRq = {
        resumeSn: selectedResume,
        resumeData: filteredData,
      };
      const selectedItemsCount = Object.values(selectedItems).filter((value) => value).length;
      setTotalResumeCnt(selectedItemsCount + licenseFields.length + examinationFields.length + skillsFields.length);
      saveInsightResume(insightResumeSaveRq);
      if (profileConditionsData && profileConditionsData.jobGroups.length > 0 && profileConditionsData.locations.length > 0 && !!profileConditionsData.salary) {
        setStep(4);
      } else {
        setStep(3);
      }
    },
  }));
  const handleSaveBack = () => {
    setIsSaveBack(false);
    const newData = JSON.parse(JSON.stringify(tmpFormValues));
    reset(newData);
    setIsSaveBack(true);
  };

  // reset 후 trigger를 위한 시간차
  useEffect(() => {
    if (isSaveBack) {
      trigger().then(() => {
        setIsSaveBack(false);
        setIsDirtyModalOpen(false);
        setFormType(FormType.ALL);
      });
    }
  }, [isSaveBack, trigger]);

  // 해당 위치에 에러가 있는지 체크하는 함수
  const checkErrorAtPath = (path:any) => {
    let current = errors;
    const pathParts = path.split('.');

    for (const part of pathParts) {
      const arrayMatch = part.match(/^(.+)\[(\d+)\]$/);
      if (arrayMatch) {
        const [, arrayPath, index] = arrayMatch;
        current = current[arrayPath] ? current[arrayPath][index] : undefined;
      } else {
        current = current[part];
      }

      if (!current) return false;
    }

    return true;
  };

  useEffect(() => {
    const allItemsFalse = Object.values(selectedItems).every((value) => !value);
    if (allItemsFalse && licenseFields.length === 0 && examinationFields.length === 0 && skillsFields.length === 0) {
      setIsSelectedResumeItem(false);
    } else {
      setIsSelectedResumeItem(true);
    }
  }, [selectedItems, licenseFields, skillsFields, examinationFields]);
  if (isLoading) return <Loading />;
  return (
    <Frame id='loadCompleteForm'>
      <FormProvider {...formMethods}>
        <TitleFrame hidden={formType !== FormType.ALL} ref={topRef}>
          <div className='title-profile'>내 프로필에 가져올 <br />지원서 정보를 선택해 주세요.</div>
          <SpacingBlock vertical size={32} />
          {
            isResumeEmpty
              ? (
                <FailedFrame>
                  <img src={ImgEmptyJoy} alt='empty-joy' className='img-joy' />
                  <div className='failed-text'>저장된 지원서가 없어요.</div>
                </FailedFrame>
              )
              : (
                <>
                  {resumeData && educationsFields && educationsFields.length > 0 && (
                  <>
                    <ProfileItemTitle resume text='학력' icon='university' />
                    {educationsFields.map((education, index) => (
                      <>
                        <ResumeSingleMenuItem
                          divider
                          title={education.schoolName}
                          subTitle={makeEduSubTitle(education.startDate, education.endDate, (education.profileMajors !== null && education.profileMajors && education.profileMajors.length > 0) ? education.profileMajors[0].majorName : '')}
                          leftIcon={(
                            <Icon name='check-circle-filled' size={24} color={selectedItems[`education.educations[${index}]`] ? Colors.G_150 : Colors.CG_40} />
          )}
                          rightIcon={<Icon name='guide' size={32} color={Colors.JOBDA_BLACK} />}
                          selected={!!selectedItems[`education.educations[${index}]`]}
                          onClickInnerFrame={() => {
                            if (!checkErrorAtPath(`education.educations[${index}]`)) {
                              toggleSelection('education.educations', index);
                            }
                          }}
                          onClickRightIcon={() => editSelection(index, FormType.EDUCATION, 'education.educations')}
                          resumeError={!!errors?.education?.educations?.[index]}
                        />
                        {index !== educationsFields.length - 1 && <SpacingBlock vertical size={12} />}
                      </>
                    ))}
                    <SpacingBlock vertical size={40} />
                  </>
                  )}

                  {resumeData && careersFields && careersFields.length > 0 && (
                  <>
                    <ProfileItemTitle resume text='경력' icon='work' />
                    {careersFields.map((career, index) => (
                      <>
                        <ResumeSingleMenuItem
                          divider
                          title={career.companyName}
                          subTitle={makeCareerSubTitle(career.startDate, career.endDate, career.jobGroupName)}
                          leftIcon={(
                            <Icon name='check-circle-filled' size={24} color={selectedItems[`career.careers[${index}]`] ? Colors.G_150 : Colors.CG_40} />
          )}
                          rightIcon={<Icon name='guide' size={32} color={Colors.JOBDA_BLACK} />}
                          selected={!!selectedItems[`career.careers[${index}]`]}
                          onClickInnerFrame={() => {
                            if (!checkErrorAtPath(`career.careers[${index}]`)) {
                              toggleSelection('career.careers', index);
                            }
                          }}
                          onClickRightIcon={() => editSelection(index, FormType.CAREER, 'career.careers')}
                          resumeError={!!errors?.career?.careers?.[index]}
                        />
                        {index !== careersFields.length - 1 && <SpacingBlock vertical size={12} />}
                      </>
                    ))}
                    <SpacingBlock vertical size={40} />
                  </>
                  )}

                  {resumeData && projectsFields && projectsFields.length > 0 && (
                  <>
                    <ProfileItemTitle resume text='프로젝트' icon='project-light' />
                    {projectsFields.map((project, index) => (
                      <>
                        <ResumeSingleMenuItem
                          divider
                          title={project.title}
                          subTitle={makeCareerSubTitle(project.startDate, project.endDate, project.institution)}
                          leftIcon={(
                            <Icon name='check-circle-filled' size={24} color={selectedItems[`project.projects[${index}]`] ? Colors.G_150 : Colors.CG_40} />
          )}
                          rightIcon={<Icon name='guide' size={32} color={Colors.JOBDA_BLACK} />}
                          selected={!!selectedItems[`project.projects[${index}]`]}
                          onClickInnerFrame={() => {
                            if (!checkErrorAtPath(`project.projects[${index}]`)) {
                              toggleSelection('project.projects', index);
                            }
                          }}
                          onClickRightIcon={() => editSelection(index, FormType.PROJECT, 'project.projects')}
                          resumeError={!!errors?.project?.projects?.[index]}
                        />
                        {index !== projectsFields.length - 1 && <SpacingBlock vertical size={12} />}
                      </>
                    ))}
                    <SpacingBlock vertical size={40} />
                  </>
                  )}
                  {
(licenseFields.length > 0 || examinationFields.length > 0 || skillsFields.length > 0) && (
<ProfileItemTitle resume icon='design' text='지식 · 기술' subText='동일한 자격증, 시험 정보는 불러온 정보로 교체됩니다.' />
)
      }
                  {resumeData && resumeData.knowledgeAndSkill.licenses && resumeData.knowledgeAndSkill.licenses.length > 0 && licenseFields.length > 0 && (
                  <>
                    <ContentFrame>
                      <p className='title'>자격증</p>
                      <div className='list-frame'>
                        {licenseFields.map((field, idx) => (
                          <React.Fragment key={field.code}>
                            <input aria-hidden ref={register()} type='text' name={`licenses[${idx}].name`} defaultValue={field.name || ''} />
                            <input aria-hidden ref={register()} type='text' name={`licenses[${idx}].code`} defaultValue={field.code ?? -1} />
                            <JDClosableChip onClose={() => licenseRemove(idx)}>{field.name}</JDClosableChip>
                          </React.Fragment>
                        ))}
                      </div>
                    </ContentFrame>
                    <SpacingBlock vertical size={8} />
                  </>
                  )}

                  {resumeData && resumeData.knowledgeAndSkill.examinations && resumeData.knowledgeAndSkill.examinations.length > 0 && examinationFields.length > 0 && (
                  <>
                    <ContentFrame>
                      <p className='title'>공인 어학 시험</p>
                      {examinationFields.map((field, idx) => (
                        <React.Fragment key={`${field.code}-idx`}>
                          <input aria-hidden ref={register()} name={`examinations[${idx}].code`} defaultValue={field.code} />
                          <input aria-hidden ref={register()} name={`examinations[${idx}].name`} defaultValue={field.name} />
                          <input aria-hidden ref={register()} name={`examinations[${idx}].gradeYn`} defaultValue={`${field.gradeYn}`} />
                          <input aria-hidden ref={register()} name={`examinations[${idx}].scoreYn`} defaultValue={`${field.scoreYn}`} />
                          <input aria-hidden ref={register()} name={`examinations[${idx}].grade`} defaultValue={field.grade} />
                          <input aria-hidden ref={register()} name={`examinations[${idx}].score`} defaultValue={field.score} />
                          <input aria-hidden ref={register()} name={`examinations[${idx}].gradeCode`} defaultValue={field.gradeCode} />
                          <JDClosableChip onClose={() => examinationRemove(idx)}>{`${field.name}-${`${field.gradeYn}` === 'true' ? `${field.grade}` : field.score}`}</JDClosableChip>
                        </React.Fragment>
                      ))}
                    </ContentFrame>
                    <SpacingBlock vertical size={8} />
                  </>
                  )}

                  {resumeData && resumeData.knowledgeAndSkill.skills && resumeData.knowledgeAndSkill.skills.length > 0 && skillsFields.length > 0 && (
                  <>
                    <ContentFrame>
                      <p className='title'>기술 키워드</p>
                      <div className='list-frame'>
                        {skillsFields.map((field, idx) => (
                          <React.Fragment key={field.id}>
                            <input aria-hidden ref={register()} name={`skills[${idx}].keyword`} defaultValue={field.keyword} />
                            <input aria-hidden ref={register()} name={`skills[${idx}].code`} defaultValue={field.code} />
                            <input aria-hidden ref={register()} name={`skills[${idx}].level`} defaultValue={field.level} />
                            <JDClosableChip onClose={() => skillRemove(idx)}>{`${field.keyword || ''} - ${field.level ? SkillLevelText[field.level] : ''}`}</JDClosableChip>
                          </React.Fragment>
                        ))}
                      </div>
                    </ContentFrame>
                    <SpacingBlock vertical size={8} />
                  </>
                  )}

                  {resumeData && experienceFields.length > 0 && (
                  <>
                    <ProfileItemTitle resume text='경험' icon='read-book' />
                    {experienceFields.map((activity, index) => (
                      <>
                        <ResumeSingleMenuItem
                          divider
                          title={activity.title}
                          subTitle={makeExperienceSubTItle(activity.startDate, activity.endDate, activity.tag)}
                          leftIcon={(
                            <Icon name='check-circle-filled' size={24} color={selectedItems[`experience.activities[${index}]`] ? Colors.G_150 : Colors.CG_40} />
          )}
                          rightIcon={<Icon name='guide' size={32} color={Colors.JOBDA_BLACK} />}
                          selected={!!selectedItems[`experience.activities[${index}]`]}
                          onClickInnerFrame={() => {
                            if (!checkErrorAtPath(`experience.activities[${index}]`)) {
                              toggleSelection('experience.activities', index);
                            }
                          }}
                          onClickRightIcon={() => editSelection(index, FormType.EXPERIENCE, 'experience.activities')}
                          resumeError={!!errors?.experience?.activities?.[index]}
                        />
                        {index !== experienceFields.length - 1 && <SpacingBlock vertical size={12} />}
                      </>
                    ))}
                  </>
                  )}

                  {resumeData && foreignFields.length > 0 && (
                  <>
                    {foreignFields.map((foreign, index) => (
                      <>
                        {index === 0 && <SpacingBlock vertical size={12} />}
                        <ResumeSingleMenuItem
                          divider
                          title={foreign.title}
                          subTitle={makeExperienceSubTItle(foreign.startDate, foreign.endDate, foreign.tag)}
                          leftIcon={(
                            <Icon name='check-circle-filled' size={24} color={selectedItems[`experience.foreign[${index}]`] ? Colors.G_150 : Colors.CG_40} />
          )}
                          rightIcon={<Icon name='guide' size={32} color={Colors.JOBDA_BLACK} />}
                          selected={!!selectedItems[`experience.foreign[${index}]`]}
                          onClickInnerFrame={() => {
                            if (!checkErrorAtPath(`experience.foreign[${index}]`)) {
                              toggleSelection('experience.foreign', index);
                            }
                          }}
                          onClickRightIcon={() => editSelection(index, FormType.FOREIGN, 'experience.foreign')}
                          resumeError={!!errors?.experience?.foreign?.[index]}
                        />
                        {index !== foreignFields.length - 1 && <SpacingBlock vertical size={12} />}
                      </>
                    ))}
                  </>
                  )}
                  {
  (foreignFields.length > 0 || experienceFields.length > 0) && <SpacingBlock vertical size={40} />
}
                  {resumeData && prizeFields.length > 0 && (
                  <>
                    <ProfileItemTitle resume text='수상 내역' icon='diamond' />
                    {prizeFields.map((prize, index) => (
                      <>
                        <ResumeSingleMenuItem
                          divider
                          title={prize.name || ''}
                          subTitle={makePrizeSubTitle(prize.date, prize.institution)}
                          leftIcon={(
                            <Icon name='check-circle-filled' size={24} color={selectedItems[`prize.prizes[${index}]`] ? Colors.G_150 : Colors.CG_40} />
          )}
                          rightIcon={<Icon name='guide' size={32} color={Colors.JOBDA_BLACK} />}
                          selected={!!selectedItems[`prize.prizes[${index}]`]}
                          onClickInnerFrame={() => {
                            if (!checkErrorAtPath(`prize.prizes[${index}]`)) {
                              toggleSelection('prize.prizes', index);
                            }
                          }}
                          onClickRightIcon={() => editSelection(index, FormType.PRIZE, 'prize.prizes')}
                          resumeError={!!errors?.prize?.prizes?.[index]}
                        />
                        {index !== prizeFields.length - 1 && <SpacingBlock vertical size={12} />}
                      </>
                    ))}
                  </>
                  )}
                </>
              )
          }

        </TitleFrame>
        {
            educationsFields && educationsFields.length > 0 && <ResumeEditEducation educationsFields={educationsFields} prefixName='education.educations' eduIndex={selectedEduIdx} isVisible={formType === FormType.EDUCATION} />
        }
        {
          careersFields && careersFields.length > 0 && <ResumeEditCareer careerFields={careersFields} prefixName='career.careers' careerIdx={selectedCareerIdx} isVisible={formType === FormType.CAREER} />
        }
        {
          projectsFields && projectsFields.length > 0 && <ResumeEditProject projectsFields={projectsFields} prefixName='project.projects' projectIdx={selectedProjectIdx} isVisible={formType === FormType.PROJECT} />
        }
        {
            experienceFields && experienceFields.length > 0 && <ResumeEditExperience experienceFields={experienceFields} prefixName='experience.activities' experienceIdx={selectedExperienceIdx} isVisible={formType === FormType.EXPERIENCE} />
        }
        {
            foreignFields && foreignFields.length > 0 && <ResumeEditForeign foreignFields={foreignFields} prefixName='experience.foreign' foreignExperiencesIndex={selectedForeignIdx} isVisible={formType === FormType.FOREIGN} />
        }
        {
            prizeFields && prizeFields.length > 0 && <ResumeEditPrize prizeFields={prizeFields} prefixName='prize.prizes' prizeIdx={selectedPrizeIdx} isVisible={formType === FormType.PRIZE} />
        }
      </FormProvider>
      {/* <ProfileMoveDirtyModal
        isOpen={isDirtyModalOpen}
        onSuccess={async () => {
          handleSaveBack();
          formState.isDirty = false;
        }}
        onClickClose={() => setIsDirtyModalOpen(false)}
      /> */}
    </Frame>
  );
});
export default inject(injectStore.login)(observer(LoadCompleteForm));
