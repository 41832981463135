import JDFullModal from 'components/_v2/_common/modals/JDFullModal';
import ProfilePreview from 'components/_v2/profile/preview/ProfilePreview';
import { IResumePreviewAdditionInfo } from 'interfaces/_v2/positionJd/IResumeAdditionInfoRqRs';
import { IProfileDetails } from 'interfaces/_v2/profile/IProfileDetail';
import React, { FC } from 'react';

const ProfilePreviewModal:FC<{ handleClose: () => void, data: IProfileDetails, additionInfo?: IResumePreviewAdditionInfo }> = ({ handleClose, data, additionInfo }) => (
  <JDFullModal handleClose={handleClose}>
    <ProfilePreview data={data} additionInfo={additionInfo} />
  </JDFullModal>
);

export default ProfilePreviewModal;
