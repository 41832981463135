import React, { useState } from 'react';
// import Step from '__pc__/components/matchIntroduce/step';
import useIO from '__pc__/utils/hooks/useIO';
import { useScrollInteraction } from '__pc__/utils/hooks/useScrollInteraction/useScrollInteraction';
import classnames from 'classnames/bind';
import Footer from 'components/_v2/_common/Footer';
import HookingMsg from './hookingMsg';
import Interview from './interview';
import Join from './join';
import LogoFlow from './logoFlow';
import MainMsg from './mainMsg';
import styles from './matchIntroduce.module.scss';
import Matching from './matching';
import MatchingSecond from './matchingSecond';
import Step from './step';
import FloatingBtn from './floatingBtn';

const cx = classnames.bind(styles);

const MatchIntroduce = () => {
  const { onScrollStyles, onScrollClassName } = useScrollInteraction();
  const [fadeInFirst, setFadeInFirst] = useState(false);
  const [fadeInSecond, setFadeInSecond] = useState(false);
  const [fadeInThird, setFadeInThird] = useState(false);
  const [fadeInFourth, setFadeInFourth] = useState(false);
  const [fadeInFifth, setFadeInFifth] = useState(false);
  const [fadeInSixth, setFadeInSixth] = useState(false);
  const [fadeInTmp, setFadeInTmp] = useState(false);
  // const [fadeInSeventh, setFadeInSeventh] = useState(false);

  // Intersection Observer

  const handleIntersectFirst = (entries: IntersectionObserverEntry[]) => {
    if (entries[0].isIntersecting) {
      setFadeInFirst(true);
    } else {
      setFadeInFirst(false);
    }
  };

  const handleIntersectSecond = (entries: IntersectionObserverEntry[]) => {
    if (entries[0].isIntersecting) {
      setFadeInSecond(true);
    }
  };

  const handleIntersectThird = (entries: IntersectionObserverEntry[]) => {
    if (entries[0].isIntersecting) {
      setFadeInThird(true);
    }
  };

  const handleIntersectFourth = (entries: IntersectionObserverEntry[]) => {
    if (entries[0].isIntersecting) {
      setFadeInFourth(true);
    }
  };

  const handleIntersectFifth = (entries: IntersectionObserverEntry[]) => {
    if (entries[0].isIntersecting) {
      setFadeInFifth(true);
    }
  };

  const handleIntersectSixth = (entries: IntersectionObserverEntry[]) => {
    if (entries[0].isIntersecting) {
      setFadeInSixth(true);
    }
  };
  const handleIntersectTmp = (entries: IntersectionObserverEntry[]) => {
    if (entries[0].isIntersecting) {
      setFadeInTmp(true);
    }
  };
  // const handleIntersectSeventh = (entries: IntersectionObserverEntry[]) => {
  //   if (entries[0].isIntersecting) {
  //     setFadeInSeventh(true);
  //   }
  // };
  const setFirstSectionRef = useIO({ onIntersect: handleIntersectFirst, threshold: 0.2 });
  const setSecondSectionRef = useIO({ onIntersect: handleIntersectSecond, threshold: 0.2 });
  const setThirdSectionRef = useIO({ onIntersect: handleIntersectThird, threshold: 0.2 });
  const setFourthSectionRef = useIO({ onIntersect: handleIntersectFourth, threshold: 0.2 });
  const setFifthSectionRef = useIO({ onIntersect: handleIntersectFifth, threshold: 0.2 });
  const setSixthSectionRef = useIO({ onIntersect: handleIntersectSixth, threshold: 0.2 });
  const setTmpSectionRef = useIO({ onIntersect: handleIntersectTmp, threshold: 0.2 });
  // const setSeventhSectionRef = useIO({ onIntersect: handleIntersectSeventh, threshold: 0.2 });

  return (
    <div className={cx('wrap')}>
      {/* INTRO */}

      <Step onScrollClassName={onScrollClassName} onScrollStyles={onScrollStyles} fadeInFirst={fadeInFirst} />

      <div className={cx('floatingBtnArea')}>
        {/* 로고 */}
        <div ref={setFirstSectionRef}>
          <LogoFlow fadeIn={fadeInFirst} />
        </div>

        {/* 매칭1 */}
        <div ref={setSecondSectionRef}>
          <Matching fadeIn={fadeInSecond} />
        </div>

        {/* 후킹 메세지 */}
        <div ref={setTmpSectionRef}>
          <HookingMsg onScrollClassName={onScrollClassName} onScrollStyles={onScrollStyles} fadeIn={fadeInTmp} />
        </div>
        {/* 매칭2 */}
        <div ref={setThirdSectionRef}>
          <MatchingSecond fadeIn={fadeInThird} />
        </div>

        {/* 김상동님 인터뷰 */}
        <div ref={setFourthSectionRef}>
          <MainMsg fadeIn={fadeInFourth} />
        </div>

        {/* 취업 성공 인터뷰 */}
        <div ref={setFifthSectionRef}>
          <Interview fadeIn={fadeInFifth} />
        </div>

        {/* 참여하기 */}
        <div ref={setSixthSectionRef}>
          <Join fadeIn={fadeInSixth} />
        </div>

        {/* 이벤트 */}
        {/* <div ref={setSeventhSectionRef}>
            <Event fadeIn={fadeInSeventh} />
          </div> */}

        {/* floatingBtn */}
        <FloatingBtn />
      </div>

      <Footer isNavi={false} corpInfoFoled={false} />
    </div>
  );
};

export default MatchIntroduce;
