import React from 'react';
import { Helmet } from 'react-helmet-async';

export const MainMetaTag = () => (
  <Helmet>
    <meta name='title' content='잡다 ㅣ 역량검사(역량검사) 기반의 취업 매칭 플랫폼' data-rh='true' />
    <meta property='og:title' content='잡다 ㅣ 역량검사(역량검사) 기반의 취업 매칭 플랫폼' data-rh='true' />
    <meta name='description' content='채용정보 확인, 역량검사(역량검사) 연습, 역량검사 응시, 지원서 작성 등 취업 준비를 한 곳에서 할 수 있어요.' data-rh='true' />
    <meta property='og:description' content='채용정보 확인, 역량검사(역량검사) 연습, 역량검사 응시, 지원서 작성 등 취업 준비를 한 곳에서 할 수 있어요.' data-rh='true' />
    <meta property='og:image' content='https://www.jobda.im/img/og/imgOg.png' key='og:image' data-rh='true' />
  </Helmet>
);

export const CalendarMetaTag = () => (
  <Helmet>
    <meta name='title' content='잡다 | 채용 캘린더' data-rh='true' />
    <meta property='og:title' content='잡다 | 채용 캘린더' data-rh='true' />
    <meta name='description' content='신입 공채, 대기업 공채 등 다양한 채용공고를 달력 형태로 한 번에 확인해보세요.' data-rh='true' />
    <meta property='og:description' content='신입 공채, 대기업 공채 등 다양한 채용공고를 달력 형태로 한 번에 확인해보세요.' data-rh='true' />
    <meta property='og:image' content='https://m.jobda.im/assets/og/img_meta_position.png' key='og:image' data-rh='true' />
  </Helmet>
);

export const PositionMetaTag = () => (
  <Helmet>
    <meta name='title' content='잡다 | 채용공고' data-rh='true' />
    <meta property='og:title' content='잡다 | 채용공고' data-rh='true' />
    <meta name='description' content='나에게 맞는 추천 기업부터 연봉, 매출, 복지 정보까지 채용공고를 빠르게 확인해보세요.' data-rh='true' />
    <meta property='og:description' content='나에게 맞는 추천 기업부터 연봉, 매출, 복지 정보까지 채용공고를 빠르게 확인해보세요.' data-rh='true' />
    <meta property='og:image' content='https://m.jobda.im/assets/og/img_meta_position.png' key='og:image' data-rh='true' />
  </Helmet>
);

export const PositionJDMetaTag = (companyName?:string, positionName?:string, recruitmentType?:string, jobGroup?:string, closingDateTime?:string) => (
  <Helmet>
    <meta name='title' content={`${companyName} 채용 | ${positionName} | 잡다`} data-rh='true' />
    <meta property='og:title' content={`${companyName} 채용 | ${positionName} | 잡다`} data-rh='true' />
    <meta name='description' content={`${companyName}에서 ${positionName} ${recruitmentType} 지원자를 모집하고 있어요. ${jobGroup} 채용. 마감일:${closingDateTime}`} data-rh='true' />
    <meta property='og:description' content={`${companyName}에서 ${positionName} ${recruitmentType} 지원자를 모집하고 있어요. ${jobGroup} 채용. 마감일:${closingDateTime}`} data-rh='true' />
    <meta property='og:image' content='https://m.jobda.im/assets/og/img_meta_position_jd.png' key='og:image' data-rh='true' />
  </Helmet>
);

export const ChallengeMetaTag = () => (
  <Helmet>
    <meta name='title' content='잡다 | 채용이벤트' data-rh='true' />
    <meta property='og:title' content='잡다 | 채용이벤트' data-rh='true' />
    <meta name='description' content='대규모 공채, 개발자 채용 등 취준생을 위한 채용 이벤트. 지금 당장 도전해보세요.' data-rh='true' />
    <meta property='og:description' content='대규모 공채, 개발자 채용 등 취준생을 위한 채용 이벤트. 지금 당장 도전해보세요.' data-rh='true' />
    <meta property='og:image' content='https://m.jobda.im/assets/og/img_meta_challenge.png' key='og:image' data-rh='true' />
  </Helmet>
);

export const MatchMetaTag = () => (
  <Helmet>
    <meta name='title' content='잡다 | 지원현황' data-rh='true' />
    <meta property='og:title' content='잡다 | 지원현황' data-rh='true' />
    <meta name='description' content='채용공고별 지원현황을 한 번에 확인하고 해당 기업 인사담당자와 개별적으로 소통할 수 있어요.' data-rh='true' />
    <meta property='og:description' content='채용공고별 지원현황을 한 번에 확인하고 해당 기업 인사담당자와 개별적으로 소통할 수 있어요.' data-rh='true' />
    <meta property='og:image' content='https://m.jobda.im/assets/og/img_meta_match.png' key='og:image' data-rh='true' />
  </Helmet>
);

export const ProfileMetaTag = () => (
  <Helmet>
    <meta name='title' content='잡다 | 내 프로필' data-rh='true' />
    <meta property='og:title' content='잡다 | 내 프로필' data-rh='true' />
    <meta name='description' content='자소서는 이제 그만! 역량검사 응시하고 프로필만 입력하면 다양한 채용공고에 바로 지원할 수 있어요.' data-rh='true' />
    <meta property='og:description' content='자소서는 이제 그만! 역량검사 응시하고 프로필만 입력하면 다양한 채용공고에 바로 지원할 수 있어요.' data-rh='true' />
    <meta property='og:image' content='https://m.jobda.im/assets/og/img_meta_profile.png' key='og:image' data-rh='true' />
  </Helmet>
);

export const CompanyMetaTag = (companyName?:string) => (
  <Helmet>
    <meta name='title' content={`${companyName} | 연봉, 직원수, 복지 등 | 잡다`} data-rh='true' />
    <meta property='og:title' content={`${companyName} | 연봉, 직원수, 복지 등 | 잡다`} data-rh='true' />
    <meta name='description' content={`${companyName}이 현재 채용중인 포지션, 연봉, 직원수, 복지 등 더 많은 기업 정보를 잡다에서 확인해보세요.`} data-rh='true' />
    <meta property='og:description' content={`${companyName}이 현재 채용중인 포지션, 연봉, 직원수, 복지 등 더 많은 기업 정보를 잡다에서 확인해보세요.`} data-rh='true' />
    <meta property='og:image' content='https://www.jobda.im/img/og/imgOg.png' key='og:image' data-rh='true' />
  </Helmet>
);

// 신역량검사
export const AccaMetaTag = () => (
  <Helmet>
    <meta name='title' content='잡다 | 역량검사' data-rh='true' />
    <meta property='og:title' content='잡다 | 역량검사' data-rh='true' />
    <meta name='description' content='역량검사 게임, 역량검사 연습, 역량검사 후기까지 역량검사에 대한 모든 것을 확인하고 역량검사에 응시해보세요.' data-rh='true' />
    <meta property='og:description' content='역량검사 게임, 역량검사 연습, 역량검사 후기까지 역량검사에 대한 모든 것을 확인하고 역량검사에 응시해보세요.' data-rh='true' />
    <meta property='og:image' content='https://m.jobda.im/assets/og/img_meta_acca_tutorial.png' key='og:image' data-rh='true' />
  </Helmet>
);

// 구역량검사
export const AccMetaTag = () => (
  <Helmet>
    <meta name='title' content='잡다 | 구 역량검사(역량검사) 연습' data-rh='true' />
    <meta property='og:title' content='잡다 | 구 역량검사(역량검사) 연습' data-rh='true' />
    <meta name='description' content='구역량검사를 연습해볼 수 있어요. 예전 모습의 역량검사가 궁금하시다면 한번 경험해보세요.' data-rh='true' />
    <meta property='og:description' content='구역량검사를 연습해볼 수 있어요. 예전 모습의 역량검사가 궁금하시다면 한번 경험해보세요.' data-rh='true' />
    <meta property='og:image' content='https://m.jobda.im/assets/og/img_meta_acca_tutorial.png' key='og:image' data-rh='true' />
  </Helmet>
);

export const PhsMetaTag = () => (
  <Helmet>
    <meta name='title' content='잡다 | 개발자 검사' data-rh='true' />
    <meta property='og:title' content='잡다 | 개발자 검사' data-rh='true' />
    <meta name='description' content='개발자 취업 준비엔 잡다, 개발자 검사 응시하고 내 개발 역량과 구현 능력을 확인해보세요.' data-rh='true' />
    <meta property='og:description' content='개발자 취업 준비엔 잡다, 개발자 검사 응시하고 내 개발 역량과 구현 능력을 확인해보세요.' data-rh='true' />
    <meta property='og:image' content='https://m.jobda.im/assets/og/img_meta_phs.png' key='og:image' data-rh='true' />
  </Helmet>
);

export const PassInterviewMetaTag = () => (
  <Helmet>
    <meta name='title' content='잡다 | 기출 면접 연습' data-rh='true' />
    <meta property='og:title' content='잡다 | 기출 면접 연습' data-rh='true' />
    <meta name='description' content='기업별 예상 질문 확인하고 영상면접 연습까지! 면접을 완벽하게 대비해보세요.' data-rh='true' />
    <meta property='og:description' content='기업별 예상 질문 확인하고 영상면접 연습까지! 면접을 완벽하게 대비해보세요.' data-rh='true' />
    <meta property='og:image' content='https://m.jobda.im/assets/og/img_meta_pass_interview.png' key='og:image' data-rh='true' />
  </Helmet>
);

// 잡다 선언문
export const DeclarationMetaTag = () => (
  <Helmet>
    <meta name='title' content='잡다 | 잡다 선언문' data-rh='true' />
    <meta property='og:title' content='잡다 | 잡다 선언문' data-rh='true' />
    <meta name='description' content='역량 중심 사회를 지향하는 잡다! 능력과 기회를 매칭하는 잡다의 목표를 확인해 보세요.' data-rh='true' />
    <meta property='og:description' content='역량 중심 사회를 지향하는 잡다! 능력과 기회를 매칭하는 잡다의 목표를 확인해 보세요.' data-rh='true' />
    <meta property='og:image' content='https://www.jobda.im/img/og/imgOg.png' key='og:image' data-rh='true' />
  </Helmet>
);

// 잡다 소개
export const MatchIntroduceMetaTag = () => (
  <Helmet>
    <meta name='title' content='잡다 | 잡다 소개' data-rh='true' />
    <meta property='og:title' content='잡다 | 잡다 소개' data-rh='true' />
    <meta name='description' content='기업과 인재를 연결해주는 잡다의 AI 매칭 서비스를 통해 빠르고 정확한 채용을 경험해보세요.' data-rh='true' />
    <meta property='og:description' content='기업과 인재를 연결해주는 잡다의 AI 매칭 서비스를 통해 빠르고 정확한 채용을 경험해보세요.' data-rh='true' />
    <meta property='og:image' content='https://www.jobda.im/img/og/imgOg.png' key='og:image' data-rh='true' />
  </Helmet>
);

// 역량검사 소개
export const AccaIntroduceMetaTag = () => (
  <Helmet>
    <meta name='title' content='잡다 | 역량검사(역량검사) 소개' data-rh='true' />
    <meta property='og:title' content='잡다 | 역량검사(역량검사) 소개' data-rh='true' />
    <meta name='description' content='역량검사 질문, 역량검사 게임, 역량검사 연습, 역량검사 후기까지 역량검사에 대한 모든 것을 확인해 보세요.' data-rh='true' />
    <meta property='og:description' content='역량검사 질문, 역량검사 게임, 역량검사 연습, 역량검사 후기까지 역량검사에 대한 모든 것을 확인해 보세요.' data-rh='true' />
    <meta property='og:image' content='https://www.jobda.im/img/og/imgOg.png' key='og:image' data-rh='true' />
  </Helmet>
);

// 취업 콘텐츠
export const InfoListMetaTag = () => (
  <Helmet>
    <meta name='title' content='잡다 | 취업 콘텐츠' data-rh='true' />
    <meta property='og:title' content='잡다 | 취업 콘텐츠' data-rh='true' />
    <meta name='description' content='재직자들의 취업 비법, 역량검사 합격 후기, 역량검사 게임 안내 등 생생한 취업 정보를 얻어 가세요.' data-rh='true' />
    <meta property='og:description' content='재직자들의 취업 비법, 역량검사 합격 후기, 역량검사 게임 안내 등 생생한 취업 정보를 얻어 가세요.' data-rh='true' />
    <meta property='og:image' content='https://m.jobda.im/assets/og/img_meta_info_list.png' key='og:image' data-rh='true' />
  </Helmet>
);

// FAQ
export const FAQMetaTag = () => (
  <Helmet>
    <meta name='title' content='잡다 | 고객센터' data-rh='true' />
    <meta property='og:title' content='잡다 | 고객센터' data-rh='true' />
    <meta name='description' content='잡다 고객센터에서 회원가입, 역량검사, 제휴 서비스 등 자주 묻는 질문들을 확인해보세요.' data-rh='true' />
    <meta property='og:description' content='잡다 고객센터에서 회원가입, 역량검사, 제휴 서비스 등 자주 묻는 질문들을 확인해보세요.' data-rh='true' />
    <meta property='og:image' content='https://www.jobda.im/img/og/imgOg.png' key='og:image' data-rh='true' />
  </Helmet>
);

// 역량검사 결과 샘플
export const AccaResultSampleTag = () => (
  <Helmet>
    <meta name='title' content='잡다ㅣJOBDA 역량검사 결과표 예시' data-rh='true' />
    <meta property='og:title' content='잡다ㅣJOBDA 역량검사 결과표 예시' data-rh='true' />
    <meta name='description' content='결과표를 통해 나의 숨겨진 역량을 확인해 보세요.' data-rh='true' />
    <meta property='og:description' content='결과표를 통해 나의 숨겨진 역량을 확인해 보세요.' data-rh='true' />
    <meta property='og:image' content='https://www.jobda.im/img/og/imgOg.png' key='og:image' data-rh='true' />
  </Helmet>
);

// 기획매칭_마케팅 이벤트
export const PlanMatchingMetaTag = () => (
  <Helmet>
    <meta name='title' content='JOBDA | 마케터 채용 타임어택' data-rh='true' />
    <meta property='og:title' content='JOBDA | 마케터 채용 타임어택' data-rh='true' />
    <meta name='description' content='지금이 바로 일잘러 주니어 마케터 채용의 기회! 검증된 인재를 한곳에 모았어요. 인재를 확인하고 빠르게 선점하세요.' data-rh='true' />
    <meta property='og:description' content='지금이 바로 일잘러 주니어 마케터 채용의 기회! 검증된 인재를 한곳에 모았어요. 인재를 확인하고 빠르게 선점하세요.' data-rh='true' />
    <meta property='og:image' content='https://www.jobda.im/img/og/imgOg.png' key='og:image' data-rh='true' />
  </Helmet>
);

export const PassReviewMetaTag = () => (
  <Helmet>
    <meta name='title' content='잡다 | 취업 합격 후기 게시판' data-rh='true' />
    <meta property='og:title' content='잡다 | 취업 합격 후기 게시판' data-rh='true' />
    <meta name='description' content='역량검사 합격, 최종 합격 등 선배들의 취업 성공 후기를 들어보세요. 생생한 합격 수기 속에 취업 꿀팁이 모두 담겨있어요.' data-rh='true' />
    <meta property='og:description' content='역량검사 합격, 최종 합격 등 선배들의 취업 성공 후기를 들어보세요. 생생한 합격 수기 속에 취업 꿀팁이 모두 담겨있어요.' data-rh='true' />
    <meta property='og:image' content='https://www.jobda.im/img/og/imgOg.png' key='og:image' data-rh='true' />
    <meta name='keywords' content='잡다, 취업, 채용공고, 채용정보, 역량검사, 역량검사, 공채, 수시, 취준, 기업, 합격후기, 취업후기, 최종합격후기, 취업성공후기, 후기이벤트, 네이버페이, 잡다매칭' data-rh='true' />

  </Helmet>
);
export default MainMetaTag;
