import React from 'react';

import classnames from 'classnames/bind';
import styles from './schedule.module.scss';

const cx = classnames.bind(styles);

const Schedule = () => (
  <div className={cx('scheduleArea')}>
    <div className={cx('detailInfoInner')}>
      <em className={cx('detailInfoTitle')}>D:CHAMPS<br />일정을 확인하세요</em>
    </div>
  </div>
);

export default Schedule;
