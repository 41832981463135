const Fonts = {
  H1_Regular: '400 34px/44px Pretendard; letter-spacing: -1%',
  H1_Bold: '700 34px/44px Pretendard; letter-spacing: -1%',
  H2_Bold: '700 28px/36px Pretendard; letter-spacing: -1%',
  H3_Bold: '700 24px/32px Pretendard; letter-spacing: -1%',
  H4_Bold: '700 20px/26px Pretendard; letter-spacing: -1%',
  H5_Medium: '500 18px/24px Pretendard; letter-spacing: -1%',
  H5_Bold: '700 18px/24px Pretendard; letter-spacing: -1%',
  B1_Medium: '500 16px/20px Pretendard; letter-spacing: -1%',
  B1_Bold: '700 16px/20px Pretendard; letter-spacing: -1%',
  B1_Medium_P: '500 16px/26px Pretendard; letter-spacing: -1%',
  B1_Bold_P: '700 16px/26px Pretendard; letter-spacing: -1%',
  B2_Medium: '500 14px/18px Pretendard; letter-spacing: -1%',
  B2_Bold: '700 14px/18px Pretendard; letter-spacing: -1%',
  B2_Medium_P: '500 14px/22px Pretendard; letter-spacing: -1%',
  B2_Bold_P: '700 14px/22px Pretendard; letter-spacing: -1%',
  B3_Medium: '500 12px/16px Pretendard; letter-spacing: -1%',
  B3_Bold: '700 12px/16px Pretendard; letter-spacing: -1%',
  B3_Medium_P: '500 12px/20px Pretendard; letter-spacing: -1%',
  B3_Bold_P: '700 12px/20px Pretendard; letter-spacing: -1%',
  B4_Medium: '500 10px/14px Pretendard; letter-spacing: -1%',
  B4_Bold: '700 10px/14px Pretendard; letter-spacing: -1%',
  B4_Medium_P: '500 10px/16px Pretendard; letter-spacing: -1%',
  B4_Bold_P: '700 10px/16px Pretendard; letter-spacing: -1%',
} as const;

export default Fonts;

export const fonts = {
  // 이전 style/fonts 파일
  NOTO_64_900: `font-family: Noto Sans KR; font-weight:900; font-size:64px;`,

  NOTO_36_700: `font-family: Noto Sans KR; font-weight:700; font-size:36px;`,
  WEMAKEP_36_700: `font-family: 'Wemakeprice-Bold'; font-weight:700; font-size:36px;`,
  NOTO_34_700: `font-family: Noto Sans KR; font-weight:700; font-size:34px;`,
  NOTO_32_700: `font-family: Noto Sans KR; font-weight:700; font-size:32px;`,
  NOTO_30_700: `font-family: Noto Sans KR; font-weight:700; font-size:30px;`,
  NOTO_28_700: `font-family: Noto Sans KR; font-weight:700; font-size:28px;`,
  NOTO_24_700: `font-family: Noto Sans KR; font-weight:700; font-size:24px;`,
  WEMAKEP_24_700: `font-family: 'Wemakeprice-Bold'; font-weight:700; font-size:24px;`,
  NOTO_20_700: `font-family: Noto Sans KR; font-weight:700; font-size:20px;`,
  WEMAKEP_20_700: `font-family: 'Wemakeprice-Bold'; font-weight:700; font-size:20px;`,
  NOTO_17_700: `font-family: Noto Sans KR; font-weight:700; font-size:17px;`,
  NOTO_15_700: `font-family: Noto Sans KR; font-weight:700; font-size:15px;`,
  NOTO_14_700: `font-family: Noto Sans KR; font-weight:700; font-size:14px;`,
  NOTO_13_700: `font-family: Noto Sans KR; font-weight:700; font-size:13px;`,
  NOTO_11_700: `font-family: Noto Sans KR; font-weight:700; font-size:11px;`,
  NOTO_10_700: `font-family: Noto Sans KR; font-weight:700; font-size:10px;`,

  NOTO_24_500: `font-family: Noto Sans KR; font-weight:500; font-size:24px;`,
  NOTO_20_500: `font-family: Noto Sans KR; font-weight:500; font-size:20px;`,
  NOTO_17_500: `font-family: Noto Sans KR; font-weight:500; font-size:17px;`,
  NOTO_15_500: `font-family: Noto Sans KR; font-weight:500; font-size:15px;`,
  NOTO_13_500: `font-family: Noto Sans KR; font-weight:500; font-size:13px;`,
  NOTO_11_500: `font-family: Noto Sans KR; font-weight:500; font-size:11px;`,
  NOTO_10_500: `font-family: Noto Sans KR; font-weight:500; font-size:10px;`,
  NOTO_8_500: `font-family: Noto Sans KR; font-weight:500; font-size:8px;`,

  NOTO_24_400: `font-family: Noto Sans KR; font-weight:400; font-size:24px;`,
  NOTO_20_400: `font-family: Noto Sans KR; font-weight:400; font-size:20px;`,
  NOTO_17_400: `font-family: Noto Sans KR; font-weight:400; font-size:17px;`,
  NOTO_15_400: `font-family: Noto Sans KR; font-weight:400; font-size:15px;`,
  NOTO_13_400: `font-family: Noto Sans KR; font-weight:400; font-size:13px;`,
  NOTO_11_400: `font-family: Noto Sans KR; font-weight:400; font-size:11px;`,
  NOTO_10_400: `font-family: Noto Sans KR; font-weight:400; font-size:10px;`,

  NOTO_34_300: `font-family: Noto Sans KR; font-weight:300; font-size:34px;`,
  NOTO_30_300: `font-family: Noto Sans KR; font-weight:300; font-size:30px;`,
  NOTO_24_300: `font-family: Noto Sans KR; font-weight:300; font-size:24px;`,
};

export const fontStyle = {
  BODY1_SEMIBOLD: `
    ${fonts.NOTO_17_700};
    line-height:24px;
    letter-spacing:-0.41px;
  `,
  BODY1_SEMIBOLD_P: `
    ${fonts.NOTO_17_700};
    line-height:26px;
    letter-spacing:-0.41px;
  `,
  BODY1_MEDIUM: `
    ${fonts.NOTO_17_500};
    line-height:24px;
  `,
  BODY1_REGULAR: `
    ${fonts.NOTO_17_400};
    line-height:24px;
    letter-spacing:-0.41px;
  `,
  BODY2_MEDIUM: `
    ${fonts.NOTO_15_500};
    line-height:20px;
  `,
  CAPTION1_MEDIUM: `
    ${fonts.NOTO_13_500};
    line-height:18px;
  `,
  CAPTION1_MEDIUM_P: `
    ${fonts.NOTO_13_500};
    line-height:20px;
  `,
  CAPTION1_SEMIBOLD: `
    ${fonts.NOTO_13_700};
    line-height:18px;
    letter-spacing:-0.0866667px;
  `,
  CAPTION2_MEDIUM: `
    ${fonts.NOTO_11_500};
    line-height:14px;
  `,
  CAPTION2_MEDIUM_P: `
    ${fonts.NOTO_11_500};
    line-height:17px;
  `,
  CAPTION2_SEMIBOLD: `
    ${fonts.NOTO_11_700};
    letter-spacing:-0.07px;
    line-height:14px;
  `,
  CAPTION2_SEMIBOLD_P: `
    ${fonts.NOTO_11_700};
    letter-spacing:-0.07px;
    line-height:17px;
  `,
  CAPTION3_BOLD: `
    ${fonts.NOTO_10_700};
    letter-spacing:-0.07px;
    line-height:13px;
  `,
  CAPTION3_MEDIUM: `
    ${fonts.NOTO_10_500};
    line-height:13px;
  `,
  CAPTION3_MEDIUM_P: `
    ${fonts.NOTO_10_500};
    line-height:15px;
  `,
  HEADLINE2_SEMIBOLD: `
    ${fonts.NOTO_34_700};
    line-height:40px;
    letter-spacing:0.3px;
  `,
  HEADLINE3_SEMIBOLD_P: `
    ${fonts.NOTO_28_700};
    line-height:42px;
    letter-spacing:0.24px;
  `,
  HEADLINE4_SEMIBOLD: `
    ${fonts.NOTO_24_700};
    line-height:32px;
  `,
  HEADLINE5_MEDIUM: `
    ${fonts.NOTO_20_500};
    line-height:24px;
  `,
  HEADLINE5_SEMIBOLD: `
    ${fonts.NOTO_20_700};
    letter-spacing:0.18px;
    line-height:24px;
  `,
  HEADLINE5_BOLD_P: `
    ${fonts.NOTO_20_700};
    line-height:30px;
    letter-spacing: 0.18px;
  `,
};

export const lineHeights = {
  LINEHEIGHT_1_20: 'line-height: 1.2em;', // default
  LINEHEIGHT_1_30: 'line-height: 1.3em;', // default
  LINEHEIGHT_1_40: 'line-height: 1.4em;', // default
  LINEHEIGHT_1_50: 'line-height: 1.5em;', // _P
  LINEHEIGHT_1_85: 'line-height: 1.85em;', // _P
};
