import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import styled from 'styled-components';

const FormProfileSubValueTitle = styled.h3`
    position: relative;
    font: ${Fonts.B1_Medium};
    color: ${colors.JOBDA_BLACK};
    margin-bottom:12px;
  `;

export default FormProfileSubValueTitle;
