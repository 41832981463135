import MatchApplySubmitModal from 'components/_v2/matchApply/MatchApplySubmitModal';
import MatchApplyCardConditionBox from 'components/_v2/matchApply/MatchApplyCardConditionBox';
import { PhsAccType } from 'consts/MatchingMessageType';
import { ProfileType } from 'consts/_v2/profile/ProfileType';
import IMatchApplyProps from 'interfaces/_v2/matchApply/IMatchApplyProps';
import { inject, observer } from 'mobx-react';
import MatchApplyModel from 'models/_v2/MatchApplyModel';
import { injectStore } from 'models/store';
import React, { FC, useState } from 'react';
import { DateFormatYYYYMMDDWithDot } from 'utils/DateUtils';

interface IMatchApplyCardApplyBoxProps extends IMatchApplyProps {
    currentProfileType: ProfileType;
}

const MatchApplyCardApplyBox: FC<IMatchApplyCardApplyBoxProps> = ({ currentProfileType, matchApplyModel = new MatchApplyModel() }) => {
  const accResult = matchApplyModel.applyResults && matchApplyModel.applyResults.find((result) => result.applyType === PhsAccType.ACC);
  const paccResult = matchApplyModel.applyResults && matchApplyModel.applyResults.find((result) => result.applyType === PhsAccType.PACC);
  const pstResult = matchApplyModel.applyResults && matchApplyModel.applyResults.find((result) => result.applyType === PhsAccType.PST);
  const [isOpenSubmitModal, setIsOpenSubmitModal] = useState(false);
  const [currenSubmitType, setCurrentSubmitType] = useState<PhsAccType>(PhsAccType.ACC);
  return (
    <>
      {
        currentProfileType === ProfileType.GENERAL ? (
          <MatchApplyCardConditionBox
            title='역량검사 결과표'
            subTitle={accResult?.applyEndDateTime ? DateFormatYYYYMMDDWithDot(accResult?.applyEndDateTime) : '결과표를 선택해 주세요.'}
            conditionYn={!!accResult}
            handleClick={() => { setIsOpenSubmitModal(true); setCurrentSubmitType(PhsAccType.ACC); }}
          />
        ) : (
          <>
            <MatchApplyCardConditionBox
              title='개발자 역량검사 결과표'
              subTitle={paccResult?.applyEndDateTime ? DateFormatYYYYMMDDWithDot(paccResult?.applyEndDateTime) : '결과표를 선택해 주세요.'}
              conditionYn={!!paccResult}
              handleClick={() => { setIsOpenSubmitModal(true); setCurrentSubmitType(PhsAccType.PACC); }}
            />
            <MatchApplyCardConditionBox
              title='개발구현능력 검사 결과표'
              subTitle={pstResult?.applyEndDateTime ? DateFormatYYYYMMDDWithDot(pstResult?.applyEndDateTime) : '결과표를 선택해 주세요.'}
              conditionYn={!!pstResult}
              handleClick={() => { setIsOpenSubmitModal(true); setCurrentSubmitType(PhsAccType.PST); }}
            />
          </>
        )
      }
      <MatchApplySubmitModal
        submitType={currenSubmitType}
        isOpen={isOpenSubmitModal}
        onClickClose={() => setIsOpenSubmitModal(false)}
      />
    </>
  );
};

export default inject(injectStore.matchApplyModel)(observer(MatchApplyCardApplyBox));
