import { Preferences } from '@capacitor/preferences';
import jsCookie from 'js-cookie';
import MobileStore from 'store/mobileStore';

const isServer = typeof window === 'undefined';

const PREFIX_KEY = 'today_render_check_';

class TodayRenderCheckUtil {
  static setTodayRenderCheck = async (key: string) => {
    if (MobileStore.isMobile) {
      const TodayDate = String(new Date().getDate());
      await Preferences.set({ key: PREFIX_KEY + key, value: TodayDate });
    } else {
      const today = new Date(new Date().setHours(23, 59, 59));
      jsCookie.set(PREFIX_KEY + key, 'render', { expires: today });
    }
  };

  static getTodayRenderCheck = async (key: string) => {
    if (MobileStore.isMobile) {
      const TodayDate = String(new Date().getDate());
      const { value } = await Preferences.get({ key: PREFIX_KEY + key });
      if (value === TodayDate) return true;
    } else {
      const value = jsCookie.get(PREFIX_KEY + key);
      if (value === 'render') return true;
    }
    return false;
  }

  static setPositionSn = async (key: string, positionSn:string) => {
    const today = new Date(new Date().setHours(23, 59, 59));
    jsCookie.set(PREFIX_KEY + key, positionSn, { expires: today });
  };

  static getPositionSn = async (key: string) => {
    const value = jsCookie.get(PREFIX_KEY + key);
    return value;
  }

  static setUtmParams = async (key: string, utmParams:string) => {
    if (MobileStore.isMobile) {
      await Preferences.set({ key: PREFIX_KEY + key, value: utmParams });
    } else {
      const today = new Date(new Date().setHours(23, 59, 59));
      jsCookie.set(PREFIX_KEY + key, utmParams, { expires: today });
    }
  };

  static getUtmParams = async (key: string) => {
    if (MobileStore.isMobile) {
      const { value } = await Preferences.get({ key: PREFIX_KEY + key });
      return value;
    }
    const value = jsCookie.get(PREFIX_KEY + key);
    return value;
  }

  static async setValue<T>(key: string, value: T) {
    if (isServer) return;

    if (MobileStore.isMobile) {
      await Preferences.set({ key: encodeURIComponent(key), value: JSON.stringify(value) });
    } else {
      const today = new Date(new Date().setHours(23, 59, 59));
      jsCookie.set(encodeURIComponent(key), JSON.stringify(value), { expires: today });
    }
  }

  /**
   * getItem과 차이점: 값 parse 처리를 내부적으로 처리
   * @returns setValue에서 저장한 value와 다를 수 있음
   */
  static async getValue<T>(key: string) {
    if (isServer) return;

    if (MobileStore.isMobile) {
      const { value } = await Preferences.get({ key: encodeURIComponent(key) });
      if (value === null) return null;
      return JSON.parse(value) as T;
    }
    const value = jsCookie.get(encodeURIComponent(key));
    if (value === null) return null;
    return value ? JSON.parse(value) as T : null;
  }
}

export default TodayRenderCheckUtil;
