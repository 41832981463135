import PositionCard from '__designkit__/card/PositionCard';
import { Divider12Grey } from 'components/divider/Divider';
import RoutePaths from 'consts/RoutePaths';
import Fonts from '__designkit__/common/fonts';
import colors from '__designkit__/common/colors';
import IPositionJdProps from 'interfaces/_v2/positionJd/IPositionJdProps';
import { inject, observer } from 'mobx-react';
import PositionJdModel from 'models/_v2/PositionJdModel';
import { injectStore } from 'models/store';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import JDSwiper, { JDSwiperSlide } from '../_common/JDSwiper';

const Frame = styled.div`
    padding: 32px 16px;
    .head {
        font: ${Fonts.H5_Bold};
        color: ${colors.CG_60};
        margin-bottom: 16px;

        >b {
            color: ${colors.JOBDA_BLACK};
        }
    }
    
`;

const JobListFrame = styled.div`
    width: 100vw; // 부모의 padding값을 무시하기 위해 사용. => 부모 component에 overflow: hidden 추가해줘야 여백이 안남는다.
    .job-swiper {
    }

    .img-slide {
        width: 160px;
        overflow: visible;
    }

    .link[data-disabled=true] {
        pointer-events: none;
    }
`;


interface IPositionMatchingJobProps extends IPositionJdProps{
  preview?: boolean;
}

const PositionMatchingJob:FC<IPositionMatchingJobProps> = ({ positionJdModel = new PositionJdModel(), preview }) => {
  const jobList = positionJdModel.positionMatchingJobList;
  const history = useHistory();

  return (
    <>
      <Divider12Grey />
      <Frame>
        <p className='head'>보고 계신 포지션과<br /><b>비슷한 포지션이에요.</b></p>
        <JobListFrame>
          <JDSwiper
            className='job-swiper'
            spaceBetween={8}
            slidesPerView='auto'
          >
            { jobList?.map((item) => (
              <JDSwiperSlide key={`slider-${item.positionSn}`} className='img-slide'>
                <div role='presentation' className='link' data-disabled={!!preview} onClick={() => history.push(RoutePaths.position_jd(item.positionSn))}>
                  <PositionCard item={item} />
                </div>
              </JDSwiperSlide>
            ))}

          </JDSwiper>
        </JobListFrame>
      </Frame>
    </>
  );
};

export default inject(injectStore.positionJdModel)(observer(PositionMatchingJob));
