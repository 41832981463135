import React, { useEffect, useState } from 'react';
import bell from 'assets/lottie/icon_bell.json';
import classnames from 'classnames/bind';
import Lottie from 'lottie-react';
import styles from './index.module.scss';
import { usePlanMatching } from '../context';

const cx = classnames.bind(styles);

const Banner = () => {
  const { totalCount } = usePlanMatching();
  const [time, setTime] = useState({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00',
  });

  useEffect(() => {
    const countdownDate = new Date('2024-04-26T18:00:00').getTime();

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;

      if (distance < 0) {
        // 타이머 종료
        clearInterval(interval);
        setTime({
          days: '00',
          hours: '00',
          minutes: '00',
          seconds: '00',
        });
      } else {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setTime({
          days: days.toString().padStart(2, '0'),
          hours: hours.toString().padStart(2, '0'),
          minutes: minutes.toString().padStart(2, '0'),
          seconds: seconds.toString().padStart(2, '0'),
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={cx('bannerWrap')}>
      <div className={cx('banner')}>
        {/* 로고 */}
        <a href='' className={cx('logo')} />

        {/* [D] 총 진행 수 */}
        <div className={cx('total')}>
          <div className={cx('lottieWrap')}>
            <Lottie animationData={bell} loop autoplay />
          </div>
          <span className={cx('state')}>
            <strong>{totalCount}</strong>의 면접이 진행 중이에요!
          </span>
        </div>

        {/* 키비쥬얼 + 타임어택 */}
        <div className={cx('visualWrap')}>
          <div className={cx('visual')} />
          <div className={cx('timeAttack')}>
            {/* 남은 시간 */}
            <div className={cx('timeWrap')}>
              <div className={cx('timeInner')}>
                <div className={cx('time')}>
                  <span className={cx('num')}>{time.days}</span>
                  <span className={cx('unit')}>일</span>
                </div>
                <div className={cx('time')}>
                  <span className={cx('num')}>{time.hours}</span>
                  <span className={cx('unit')}>시간</span>
                </div>
                <div className={cx('time')}>
                  <span className={cx('num')}>{time.minutes}</span>
                  <span className={cx('unit')}>분</span>
                </div>
                <div className={cx('time')}>
                  <span className={cx('num')}>{time.seconds}</span>
                  <span className={cx('unit')}>초</span>
                </div>
              </div>
            </div>
            {/* 안내 사항 */}
            <div className={cx('info')}>
              최대 10명의 구직자에게 면접 예약이 가능합니다.<br />
              4/19(금) 이후 면접 취소 시 프로모션 혜택을 받을 수 없습니다.<br />
              * 4월 내 채용 완료 시 채용 수수료 50% 지원<br />
              면접비는 JOBDA에서 구직자에게 직접 지급합니다. (1회당 50,000원)<br />
              면접 예약을 하시면 일정 조율을 위해 JOBDA 큐레이터가 연락드릴  예정입니다.<br />
              구직자의 개인정보를 임의 처리 및 외부 유출 시 법적 처벌을 받을 수 있습니다.
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Banner;
