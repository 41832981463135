/* eslint-disable class-methods-use-this */
import URIs from 'consts/URIs';
import { IPositionListRs } from 'interfaces/_v2/positionList/IPositionList';
import { request } from 'utils/request';

export default interface PositionRepo {

    fetchPositionProfileMatching(pageSize: number, hide:boolean): Promise<IPositionListRs>;
}

export class RemotePositionRepo implements PositionRepo {
  fetchPositionProfileMatching(pageSize: number, hide:boolean): Promise<IPositionListRs> {
    return request<IPositionListRs>({
      method: 'get',
      url: URIs.get_position_profile_recommend,
      params: { pageSize, hide },
    });
  }
}
