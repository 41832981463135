import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import JDCheckInput, { JD_CHECK_INPUT_TYPE } from 'components/_v2/_common/input/JDCheckInput';
import JDRadioInput, { JD_RADIO_INPUT_TYPE } from 'components/_v2/_common/input/JDRadioInput';
import ProfileItemTitle from 'components/_v2/profile/ProfileItemTitle';
import { ResumeFormType } from 'consts/_v2/profile/ProfileType';
import { IResumePreviewAdditionInfo } from 'interfaces/_v2/positionJd/IResumeAdditionInfoRqRs';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { downloadFile } from 'utils/FileUtil';

const Frame = styled.div``;

const InfoFrame = styled.div`
    margin-bottom: 32px;
    .title {
        font: ${Fonts.B1_Bold_P};
        color: ${colors.JOBDA_BLACK};
        margin-bottom: 16px;
    }

    .answer {
        font: ${Fonts.B2_Medium_P};
        color: ${colors.CG_80};
    }

    .info-frame {
        margin-bottom: 16px;

        &[aria-checked='true'] {
            label input:disabled {
                opacity: 1;
            }
            .label {
                color: ${colors.CG_90};
            }
        }
    }
    .file-frame {
        display: flex;
        align-items: center;

        .file-name {
            margin-left: 2px;
            font: ${Fonts.B3_Medium};
            color: ${colors.JOBDA_BLACK};
        }

    }
`;

const ProfilePreviewAdditionInfo: FC<{ data: IResumePreviewAdditionInfo}> = ({ data }) => {
  const { additionInfo } = data;
  const methods = useForm();
  return (
    <FormProvider {...methods}>
      <Frame>
        <ProfileItemTitle icon='file-add' text='추가 정보' />
        {
              additionInfo && additionInfo.map((info) => {
                // 선택한 답변 없을 시 미노출.
                if (!(!!info?.answer?.length || info.choices.some((item) => item.selectYn) || !!info.files?.length)) return null;

                return (
                  <InfoFrame key={info.question}>
                    <div className='title'>{info.question}</div>
                    <div className='contents'>
                      {info.answer && <div className='answer'>{info.answer}</div>}
                      {info.choices && info.type === ResumeFormType.SINGLE_CHOICE && info.choices.map((choice) => (
                        <div className='info-frame' aria-checked={choice.selectYn}>
                          <JDRadioInput
                            type={JD_RADIO_INPUT_TYPE.ROUND_BOX}
                            key={`radio-option-${choice.name}`}
                            name={info.question}
                            value={choice.name}
                            label={choice.name}
                            disabled
                            defaultValue={choice.selectYn ? choice.name : null}
                          />
                        </div>
                      ))}
                      {info.choices && info.type === ResumeFormType.MULTI_CHOICE && info.choices.map((choice) => (
                        <div className='info-frame' aria-checked={choice.selectYn}>
                          <JDCheckInput
                            type={JD_CHECK_INPUT_TYPE.CHECK_BOX}
                            key={`checkbox-option-${choice.name}`}
                            name={choice.name}
                            value={choice.name}
                            label={choice.name}
                            disabled
                            defaultChecked={choice.selectYn ? choice.name : null}
                          />
                        </div>
                      ))}
                      {(info.choices && info.type === ResumeFormType.DROPDOWN) && (
                        info.choices.map((choice) => {
                          if (choice.selectYn) {
                            return <div className='answer'>{choice.name}</div>;
                          }
                          return <></>;
                        }))}
                      {Array.isArray(info.files) && info.files.map((file) => (
                        <div className='file-frame' key={file.fileUid} role='presentation' onClick={() => downloadFile(file.fileName, file.fileUid)}>
                          <Icon name='link-line' size={24} color={colors.JOBDA_BLACK} />
                          <span role='button' className='file-name'>{file.fileName}</span>
                        </div>
                      ))}
                    </div>
                  </InfoFrame>
                );
              })
            }

      </Frame>
    </FormProvider>
  );
};

export default ProfilePreviewAdditionInfo;
