import { Divider1G40 } from 'components/divider/Divider';
import AccApplyVideoCheckCard from 'components/_v2/acc//AccApplyVideoCheckCard';
import { getAccVideoTitle } from 'components/_v2/acc/AccApplyVideo';
import colors from '__designkit__/common/colors';
import { AccVideoSubTypeText, AccVideoType, AccVideoTypeText } from 'consts/_v2/acc/AccApplyType';
import Fonts from '__designkit__/common/fonts';
import { IAccVideoDetails } from 'interfaces/_v2/acc/IAccResultsRs';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import AccResultsModel from 'models/_v2/AccResultsModel';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

const AccApplyVideoCheckFrame = styled.div`
    display: flex;
    flex-direction: column;
    padding:16px 0 24px 0;

`;
const TitleMenu = styled.div`
    display: flex;
    width:100%;
    justify-content: space-between;
    padding: 0 16px;
    margin-bottom: 8px;
    .title-text{
        font: ${Fonts.B3_Bold};
        color:${colors.CG_90};
    }
    .title-texttwo{
        font: ${Fonts.B3_Bold};
        color:${colors.CG_90};
        margin-right: 16px;
    }
    .right-container{
        display: flex;
    }
`;

const CardFrame = styled.div`
    display: flex;
    flex-direction: column;
    .divider-frame{
        padding: 0 16px;
        .divider-24{
            width:100%;
        }
        &:last-child{
            visibility: hidden;
        }
    }
`;

interface IAccApplyVideoCheckProps {
    hidden:boolean;
    accResultsModel? :AccResultsModel;

}
const AccApplyVideoCheck:FC<IAccApplyVideoCheckProps> = ({ hidden, accResultsModel = new AccResultsModel() }) => {
  const { video } = accResultsModel;

  const [videoList, setVideoList] = useState<IAccVideoDetails[]>([]);

  useEffect(() => {
    const basicExp:IAccVideoDetails[] = [];
    const situationExp:IAccVideoDetails[] = [];
    const basicValue:IAccVideoDetails[] = [];
    const basicFree:IAccVideoDetails[] = [];
    if (video) {
      video.details.map((data) => {
        if (AccVideoType[data.type] === 'VIDEO_BASIC_EXPERIENCE') {
          basicExp.push(data);
        } else if (AccVideoType[data.type] === 'VIDEO_BASIC_SITUATION') {
          situationExp.push(data);
        } else if (AccVideoType[data.type] === 'VIDEO_BASIC_VALUES') {
          basicValue.push(data);
        } else {
          basicFree.push(data);
        }
      });
      const total:IAccVideoDetails[] = [...basicExp, ...situationExp, ...basicValue, ...basicFree];
      setVideoList(total);
    }
  }, [video]);
  return (
    <AccApplyVideoCheckFrame hidden={hidden}>
      <TitleMenu>
        <div className='title-text'>문항</div>
        <div className='right-container'>
          <div className='title-texttwo'>영상확인</div>
          <div className='title-text'>질문확인</div>
        </div>

      </TitleMenu>
      <CardFrame>
        {videoList && videoList.map((data, index) => (
          <>
            <AccApplyVideoCheckCard url={data.url} sn={index} title={`${getAccVideoTitle(AccVideoTypeText[data.type], AccVideoSubTypeText[data.questionType], data.questionNum)}`} unreliableYn={!data.videoErrorYn && !data.voiceErrorYn} question={data.question} />
            <div className='divider-frame'>
              <Divider1G40 className='divider-24' />
            </div>
          </>

        ))}
      </CardFrame>
    </AccApplyVideoCheckFrame>
  );
};
export default inject(injectStore.accResultsModel)(observer(AccApplyVideoCheck));
