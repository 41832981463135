import Icon from '__designkit__/icon/Icon';
import classnames from 'classnames/bind';
import React from 'react';
import { useAccApplyTrialUrl } from 'query/acc/useAccQuery';
import { windowOpenUtils } from 'utils/_v2/windowOpenUtils';
import styles from './gateCard.module.scss';

const cx = classnames.bind(styles);

interface GateListProps {
  thumbnail: string;
  number: number;
  title: string;
  list1: string;
  list2: string;
  list3: string;
  button: string;
}

const GateCard = ({ thumbnail, number, title, list1, list2, list3, button }: GateListProps) => {
  const { data: accTutorialUrl } = useAccApplyTrialUrl();
  const openAccTutorial = () => {
    if (accTutorialUrl) {
      windowOpenUtils(accTutorialUrl.url, '_self');
    }
  };
  return (
    <div className={cx('gateInner')}>
      <div className={cx('thumbnail')} style={{ backgroundImage: `url(${thumbnail})` }} />
      <div className={cx('listInner')}>
        <div className={cx('titleInner')}>
          <span className={cx('number')}>{number}</span>
          <span className={cx('title')}>{title}</span>
        </div>
        <ul>
          <li className={cx('list')}>
            <Icon name='check' size={20} />
            {list1}
          </li>
          <li className={cx('list')}>
            <Icon name='check' size={20} />
            {list2}
          </li>
          <li className={cx('list')}>
            <Icon name='check' size={20} />
            {list3}
          </li>
        </ul>

        <div role='button' onClick={openAccTutorial} className={cx('link', 'acca_intro_mLinks')}>
          {button}
        </div>
      </div>
    </div>
  );
};

export default GateCard;
