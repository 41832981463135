import JDADataPickerInput from 'components/inputs/JDADatePickerInput';
import JDADescriptionInput from 'components/inputs/JDADescriptionInput';
import JDAFileUpload from 'components/inputs/JDAFileUpload';
import JDASquareRadio from 'components/inputs/JDASquareRadio';
import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

// ** form..

const JdaCustomFormPage: FC = () => {
  const useFormed = useForm({ mode: 'all' });
  const { watch, control } = useFormed;
  return (
    <div>
      <FormProvider {...useFormed}>
        <div>데이터 피커</div>
        <JDADataPickerInput
          name='start'
          placeholder='입학 일자'
        />
        <div>
          <JDASquareRadio
            key='test1'
            control={control}
            name='category'
            value='test1'
            text='test1'
          />
          <JDASquareRadio
            key='test2'
            control={control}
            name='category'
            value='test2'
            text='test2'
          />
          <JDASquareRadio
            key='test3'
            control={control}
            name='category'
            value='test3'
            text='test3'
          />
          <h1>{watch('category')}</h1>
        </div>
        <div>
          <JDAFileUpload
            name='file'
          />
        </div>
        <div>
          <JDADescriptionInput
            name='description'
            placeholder='연구 분야를 간략하게 설명해 주세요.'
            maxLength={300}
          />
        </div>
      </FormProvider>
    </div>
  );
};

export default JdaCustomFormPage;

