import { IProfileCareerRs } from 'interfaces/_v2/profile/IProfileCareerRqRs';
import { INameSnRs } from 'interfaces/rqrs/ICommonRqRs';
import { action, observable, reaction } from 'mobx';
import store from 'models/store';

export default class ProfileCareersModel {
  @observable careers: IProfileCareerRs[] = [];
  @observable companySearchResult: INameSnRs[] = [];

  constructor() {
    // careers가 변경되면 자동으로 프로필 SNB의 경력 입력상태를 갱신한다.
    reaction(() => this.careers, (careers) => {
      store.profileModel.snbCheckAdditional.careerInfo.completeYn = careers.length > 0;
    });
  }

  @action
  setCareers = (careers: IProfileCareerRs[]) => {
    this.careers = careers;
  }

  @action
  setCompanySearchResult = (companySearchResult: INameSnRs[]) => {
    this.companySearchResult = companySearchResult;
  }
}
