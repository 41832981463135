import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import IconGoogle from 'assets/icon/icon_login_social_google.svg';
import { OpenIdProvider } from 'interfaces/rqrs/ISocialAuthLoginRs';
import { FC } from 'react';
import GoogleLogin from 'react-google-login';
import MobileStore from 'store/mobileStore';

interface IGoogleLoginButton{
  onSuccess:any;
  onFail: any;
}

const GoogleLoginButton: FC<IGoogleLoginButton> = ({ onSuccess, onFail }) => {
  const onClickMoblieGoogleLogin = async () => {
    try {
      await GoogleAuth.initialize();
      const res = (await GoogleAuth.signIn().then((value) => value).catch((e) => {
        console.log(e);
      }));
      if (!res) return;
      if (res.authentication.accessToken) {
        onSuccess(res.authentication.accessToken, OpenIdProvider.GOOGLE);
      }
    } catch (e) {
      onFail(e);
    }
  };

  return (
    <>{
      !MobileStore.isMobile
        ? (
          <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_JS_KEY as string}
            render={({ onClick }) => (
              <button
                className='social-login-btn'
                onClick={(e) => {
                  e.preventDefault();
                  onClick();
                }}
              >
                <img alt='구글 로그인' src={IconGoogle} />
                <span>구글 로그인</span>
              </button>
            )}
            onSuccess={async (e) => {
              const access_token = (e as any).accessToken;
              onSuccess(access_token, OpenIdProvider.GOOGLE);
            }}
            onFailure={onFail}
            cookiePolicy='single_host_origin'
          />
        )
        : (
          <button
            className='social-login-btn'
            onClick={(e) => {
              e.preventDefault();
              onClickMoblieGoogleLogin();
            }}
          >
            <img alt='구글 로그인' src={IconGoogle} />
            <span>구글 로그인</span>
          </button>
        )
    }
    </>
  );
};

export default GoogleLoginButton;
