import React from 'react';
import Icon from '__designkit__/icon/Icon';
import Colors from '__designkit__/common/colors';
import classnames from 'classnames/bind';
import { copyText } from 'utils/ClipboardUtils';
import useToast from 'hooks/useToast';
import JDALink from 'components/JDALink';
import ImgDownload from '__pc__/page/eventChuseok/@assets/img_page_download.png';
import styles from './eventshareStyle.module.scss';

const cx = classnames.bind(styles);

const EventShare = () => {
  const { setToastObject } = useToast();
  return (
    <div className={cx('contentInner')}>
      <div className={cx('eventTitle')} />
      <div className={cx('basicTitle')}>
        <p className={cx('subTitle')}>지금 SNS, 커뮤니티에 이벤트 공유만해도</p>
        <p className={cx('mainTitle')}>스타벅스 디저트 세트<br />전원 제공!</p>
      </div>
      <div className={cx('eventFinish')}>
        <div className={cx('eventCoffee')} />
        <div className={cx('finishText')}>
          <p className={cx('title')}>공유 이벤트가<br />종료되었습니다.</p>
          <span className={cx('subTitle')}>※ 혜택은 10/27(금)~11/15(수)<br />사이에 순차 제공됩니다.</span>
        </div>
      </div>

      {/* 이벤트 참여 방법 */}
      <div className={cx('eventHow')}>
        {/* 제목 */}
        <div className={cx('eventHowTitle')}>
          아주 간단한 참여 방법
        </div>

        {/* 두가지 설명 */}
        <div className={cx('eventHowContent')}>
          <div className={cx('eventBox')}>
            {/* 1번 이벤트 설명 */}
            <div className={cx('eventBoxTitle')}>
              <span className={cx('num')}>1</span>
              <p className={cx('mainText')}>
                SNS, 커뮤니티에 게시글 작성
              </p>
              <span className={cx('subText')}>소문내기 이미지와 이벤트 페이지 링크(url)를<br />꼭 포함해서 올려주세요.</span>
            </div>
            {/* 버튼 */}
            <div className={cx('eventBtn')}>
              {/* <a href={ImgDownload} download>
                <button className={cx('btnClick', 'btnWhite')}>
                  이미지 다운받기
                  <div className={cx('btnIcon')}>
                    <Icon name='more-vert' size={24} />
                  </div>
                </button>
              </a> */}

              <button className={cx('btnClick', 'btnWhite')} disabled>
                이미지 다운받기
                <div className={cx('btnIcon')}>
                  <Icon name='more-vert' size={24} color={Colors.CG_60} />
                </div>
              </button>

              <button
                onClick={() => {
                  copyText(`https://www.jobda.im/event/holiday2309`);
                  setToastObject({ isOpen: true, type: 'INFO', message: '링크가 복사되었습니다.' });
                }}
                className={cx('btnClick', 'btnWhite')}
                disabled
              >
                페이지 URL 복사하기
                <div className={cx('btnIcon')}>
                  <Icon name='link-line' size={24} color={Colors.CG_60} />
                </div>
              </button>
            </div>
          </div>

          {/* 구분 */}
          <div className={cx('eventLine')} />

          <div className={cx('eventBox')}>
            {/* 2번 이벤트 설명 */}
            <div className={cx('eventBoxTitle')}>
              <span className={cx('num')}>2</span>
              <p className={cx('mainText')}>
                작성한 게시글 url 제출만 하면 끝!
              </p>
              <span className={cx('subText')}>JOBDA 아이디 / 연락처</span>
            </div>
            {/* 버튼 */}
            <div className={cx('eventBtn')}>
              {/* <JDALink to='https://forms.office.com/Pages/ResponsePage.aspx?id=JTLE58C7VEGk4KU2E7crB5qmapEgcD1EjtxRwWmYp9JUNDFCNFhJRE1PRFNWUzNHREY4MFM1NFMxSi4u' className={cx('btnClick', 'btnBlack')}>
                제출하기
                <div className={cx('btnIcon')}>
                  <Icon name='guide' size={24} color={Colors.WHITE_100} />
                </div>
              </JDALink> */}
              <button className={cx('btnClick', 'btnBlack')} disabled>
                제출하기
                <div className={cx('btnIcon')}>
                  <Icon name='guide' size={24} color={Colors.CG_60} />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventShare;
