import React from 'react';

import classnames from 'classnames/bind';
import FlowList from '__pc__/page/accaIntroduce/flowStory/flowList';
import JDALink from 'components/JDALink';
import RoutePaths from 'consts/RoutePaths';
import styles from './interview.module.scss';

const cx = classnames.bind(styles);

interface FlowStoryProps {
  fadeIn: boolean;
}

const Interview = ({ fadeIn }: FlowStoryProps) => (
  <div className={cx('commonBox', { on: fadeIn })}>
    <div className={cx('mainTitle')}>
      이미
      <br />
      1,109,106명의 선배들이
      <br />
      JOBDA 역량검사로
      <br />
      취업을 준비했어요.
    </div>

    <JDALink
      to={RoutePaths.info}
      className={cx('btnBlack', 'btnIcon')}
    >
      취업 합격후기 확인하기
    </JDALink>

    <FlowList />
  </div>
);

export default Interview;
