import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import Loading from 'components/Loading';
import URIs from 'consts/URIs';
import { lineClampOnlyOne } from 'consts/_v2/_common/style/mixins';
import useToast from 'hooks/useToast';
import { IAttachFile } from 'interfaces/_v2/profile/IProfileEducation';
import IComponentProps from 'interfaces/props/IComponentProps';
import { FC, useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import MobileStore from 'store/mobileStore';
import styled from 'styled-components/macro';
import FileUtil from 'utils/FileUtil';
import request from 'utils/request';

// 50MB
const LIMIT_FILE_SIZE: number = 52428800;

const UploadFrame = styled.div<{ hasError?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.CG_40};
    font: ${Fonts.B2_Bold};
    color: ${colors.CG_90};
    padding: 15px 0;
    border-radius: 4px;
    border: ${(props) => (props.hasError ? `1px solid ${colors.ERROR}` : 'none')};
`;
const Frame = styled.div`
  width:100%;

  input[type="file"] {
      position: absolute;
      width: 0;
      height: 0;
      padding: 0;
      overflow: hidden;
      border: 0;
  }

  h3{
    margin-top:4px;
    margin-left:16px;
    font: ${Fonts.B3_Medium};
    line-height: 14px;
    color:${colors.CG_70};
  }

`;

const FileItem = styled.div`
.download-btn{
    font:${Fonts.B2_Medium_P};
    color:${colors.CG_70};
    ${lineClampOnlyOne()};
}
    display: flex;
    padding: 13px 12px 13px 16px;
    border-radius: 4px;
    border:1px solid ${colors.CG_40};
    justify-content: space-between;
    align-items: center;
    
    width: 100%;
   .clear-btn{
      background: transparent;
      width: 24px;
      height: 24px;
    }
`;

interface IJDInnerButtonInput extends IComponentProps {
  name: string;
  placeholder?: string;
  type?: string[];
  limitCount?: number;
  addType?: boolean;
}

const JDImageUpload: FC<IJDInnerButtonInput> = (props: IJDInnerButtonInput) => {
  const { name, placeholder, type, limitCount = 20, addType, ...rest } = props;
  const useFormed = useFormContext();
  const { control, register, watch, errors } = useFormed;
  const fileRef = useRef<HTMLInputElement>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });
  const { setToastObject } = useToast();

  const uploadFile = async (file: File) => {
    try {
      const data = new FormData();
      data.append('authYn', 'false');
      data.append('file', file);
      const res = await request<IAttachFile>({
        method: 'post',
        url: URIs.post_file_upload,
        data,
      });
      return res;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const downloadFile = async (fileName: string, fileUid: string) => {
    try {
      if (MobileStore.isMobile) {
        setIsLoading(true);
      }

      const res = await request<ArrayBuffer>({
        method: 'get',
        url: URIs.get_file_download(fileUid),
        responseType: 'arraybuffer',
      });
      FileUtil.fileDownload(fileName, res, false, setToastObject, setIsLoading);
      return res;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const checkFileValidation = (file: File) => {
    if (file.size > LIMIT_FILE_SIZE) {
      alert(`50MB 미만의 파일만 업로드 할 수 있습니다.`);
      return false;
    }
    if (type) {
      // eslint-disable-next-line array-callback-return, consistent-return
      const checkType = type.some((fileType: string) => {
        if (file.type === fileType) {
          return true;
        }
      });
      if (!checkType) {
        // type에 맞게 커스텀 가능.
        alert(`JPG, PNG 파일을 업로드 해주세요.`);
        return false;
      }
    }
    return true;
  };
  const hasError = errors[name];

  return (
    <Frame>
      {isLoading && <Loading />}

      {
            fields.length > 0

              ? (
                <>

                  {fields.map((field, index) => (
                    <FileItem
                      key={field.id}
                    >
                      <button
                        className='download-btn'
                        onClick={(e) => {
                          e.preventDefault();
                          downloadFile(field.fileName, field.fileUid);
                        }}
                      >
                        {field.fileName}

                      </button>
                      <button
                        className='clear-btn'
                        onClick={() => {
                        // 서버 삭제 기능 미지원
                          remove(index);
                        }}
                      >
                        <Icon name='delete' size={24} />
                      </button>
                      <input
                        aria-hidden
                        name={`${name}[${index}].fileName`}
                        ref={register()}
                        defaultValue={field.fileName}
                      />
                      <input
                        aria-hidden
                        name={`${name}[${index}].fileUid`}
                        ref={register()}
                        defaultValue={field.fileUid}
                      />
                    </FileItem>
                  ))}
                </>
              )
              : (
                <>
                  <UploadFrame role='button' onClick={() => fileRef.current?.click()} hasError={!!hasError}>
                    <Icon name='add' size={24} /> <SpacingBlock horizontal size={6} /> 인증 사진 업로드
                  </UploadFrame>

                  <input
                    id={`file-input-${name}`}
                    name={name}
                    ref={(innerRef) => {
                      if (fileRef !== undefined) (fileRef as any).current = innerRef;
                    }}
                    className={`${watch(name) ? 'value' : ''}`}
                    type='file'
                    onChange={async (e) => {
                      const files = watch(name);

                      if (files && files.length > limitCount - 1) {
                        setToastObject({ isOpen: true, type: 'ERROR', message: `파일은 최대 ${limitCount}개까지 등록할 수 있습니다.` });
                        return false;
                      }
                      if (e.target.files?.item(0)!) {
                        if (checkFileValidation(e.target.files?.item(0)!)) {
                          const res = await uploadFile(e.target.files?.item(0)!);
                          if (res)
                            append({ fileName: res?.fileName, fileUid: res?.fileUid });
                        }
                      }
                    }}
                    {...rest}
                  />
                </>
              )
        }

    </Frame>
  );
};

export default JDImageUpload;
