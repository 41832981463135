import ImgJobda1 from 'assets/img/ai/introduce/img_introduce_jobda_1.png';
import LazyImage from 'components/common/LazyImage';
import colors from '__designkit__/common/colors';
import { fonts, lineHeights } from '__designkit__/common/fonts';
import React from 'react';
import styled from 'styled-components/macro';

const Frame = styled.div`
  height:100%;
`;

const StyledJobdaInfoFrame = styled.div`
  padding-left:24px;
  padding-right:24px;
  padding-top:96px;
  display: flex;
  flex-direction: column;

  h1{
   ${fonts.NOTO_36_700};
   color:${colors.G_100};
   ${lineHeights.LINEHEIGHT_1_40};
  }

  .img-jobda-info-1{
    margin-top:48px;
    width: 100%;
    border-radius: 16px;
  }
  
  .img-jobda-info-2{
    position:relative;
    width:160px;
    height: 116px;
    margin-top:24px;
    align-self: flex-start;
    border-radius: 16px;
    overflow: hidden;
    video {
      width:100%;
      height:100%;
      object-fit:cover;
    }
  }

  h2{
   margin-top:48px;
   margin-bottom:24px;
   ${fonts.NOTO_20_700};
   color:${colors.CG_80}; 
   ${lineHeights.LINEHEIGHT_1_50};
  }

  h3{
   margin-bottom:16px;
   ${fonts.NOTO_13_400};
   color:${colors.CG_70};
   ${lineHeights.LINEHEIGHT_1_50};
   em{
     ${fonts.NOTO_13_700};
   }
  }


`;

const AiIntroduceJobdaInfo: React.FC = () => (
  <Frame>
    <StyledJobdaInfoFrame>
      <h1>잡다도<br />
        알고 있습니다
      </h1>
      <LazyImage src={ImgJobda1} className='img-jobda-info-1' />
      <h2>잡다가 정의하는 역량,<br />
        성과를 낼 수 있는 힘입니다.
      </h2>
      <h3>
        그렇다면 성과는 어떻게 만들어질까요? 신경과학 연구결과에 의하면 우리 뇌의 성과중추라고 할 수 있는 전전두엽에서 성과를 만듭니다.
      </h3>
      <h3><em>신뢰확인-열정발현-전략모색-실행지속이라는 메커니즘을 통해 우리 뇌는 성과를 만듭니다.</em> 그래서 성과를 만들어 내는 역량은 신뢰를 확인할 수 있는 <em>긍정성</em>, 열정을 발현시킬 수 있는 <em>적극성</em>, 전략을 모색하게 하는 <em>전략성</em>, 그리고 실행을 지속시킬 수 있는 <em>성실성</em>입니다.</h3>
      <h3>우리는 이 긍정성, 적극성, 전략성, 성실성을 성과 역량이라고 정의합니다. 또한 대인과 상호작용을 잘 하는 <em>관계성</em>, 자신을 돌아볼 수 있는 자기인식과 같은 <em>정체성</em> 그리고 인지하고 있다는 것을 인지하는 <em>메타인지와 같은 객관성 모두 7가지 역량을 잡다에서는 핵심 역량이라고 정의합니다.</em></h3>
    </StyledJobdaInfoFrame>
  </Frame>
);

export default AiIntroduceJobdaInfo;
