import { ReactComponent as IconMoreLine } from 'assets/_v2/_common/icon_more_vert_24_line.svg';
import MatchHomeDashboardMoreBox from 'components/_v2/matchHome/dashboard/MatchHomeDashboardMoreBox';
import colors from '__designkit__/common/colors';
import { MatchHomeDashboardPositionType } from 'consts/_v2/matchHome/dashboard/MatchHomeDashboardType';
import Fonts from '__designkit__/common/fonts';
import { lineClampBox } from 'consts/_v2/_common/style/mixins';
import { ApplicationPositionType, IMatchDashboardPosition } from 'interfaces/_v2/matchHome/dashboard/IMatchHomeDashboard';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { overDateNow } from 'utils/DateUtils';

const StyledMatchHomeDashboardCard = styled.div<{ type: MatchHomeDashboardPositionType, positionType: ApplicationPositionType, closeYn?: boolean }>`
position: relative;
cursor: pointer;
height: fit-content;
display: flex;
flex-direction: column;
padding: 24px 16px 24px 24px;
border: 1px solid ${colors.CG_40};
border-radius: 8px;
background: ${(props) => (props.type === MatchHomeDashboardPositionType.INTERVIEW_DENIED || props.type === MatchHomeDashboardPositionType.INTERVIEW_FAILED || (props.type === MatchHomeDashboardPositionType.WRITING_RESUME && props.closeYn) ? colors.CG_30 : colors.WHITE_100)};

>span{
  width: fit-content;
  display: inline-flex;
  align-items: flex-end;
  padding: 4px 6px;
  border-radius: 4px;
  margin-bottom: 13px;
  font: ${Fonts.B4_Medium};
  
  &.apply {
    color:${colors.CG_90};
    border: 1px solid ${colors.CG_40};
  }
   
  &.gray{
    background-color: ${colors.CG_70};
    color:${colors.WHITE_100};
    border: 1px solid rgba(0, 0, 0, 0.05);
    
  }
  &.green{
    background-color: ${colors.G_100};
    color:${colors.WHITE_100};
    border: 1px solid rgba(0, 0, 0, 0.05); 
  }
  &.white{
    background-color: ${colors.WHITE_100};
    color:${colors.CG_60};
  }

  &.black{
    background-color: ${colors.JOBDA_BLACK};
    border: 1px solid rgba(0, 0, 0, 0.05);
    color:${colors.WHITE_100}
  }
}

>h1{
  height: 40px;
  font: ${Fonts.B1_Bold};
  color:${colors.JOBDA_BLACK};
  ${lineClampBox(2)};
  margin-bottom: 12px;
}
>h2{
  font: ${Fonts.B3_Medium};
  color:${colors.CG_70};
  margin-bottom: 4px;
}

.position-type-text{
  text-align:right;
  font: ${Fonts.B3_Bold};
  color:${(props) => (props.positionType === ApplicationPositionType.JOBDA ? colors.G_200 : colors.CG_70)};

}

.icon-more-line {
  position: absolute;
  top:24px;
  right:16px;
}
`;

interface IMatchHomeDashboardCard {
  item: IMatchDashboardPosition;
}

const MatchHomeDashboardHiddenCard: FC<IMatchHomeDashboardCard> = ({ item }) => {
  const [viewMoreBox, setViewMoreBox] = useState<boolean>(false);

  const getChip = () => {
    switch (item.positionType) {
      case ApplicationPositionType.JOBDA:
        return <span className='green'>잡다 지원</span>;
      case ApplicationPositionType.HOMEPAGE:
        return <span className='apply'>홈페이지 지원</span>;
      default: return <span />;
    }
  };

  return (
    <>
      <StyledMatchHomeDashboardCard
        type={item.jobApplicationProcessType}
        positionType={item.positionType}
        closeYn={!!item.closingDateTime && overDateNow(item.closingDateTime)}
      >
        {getChip()}
        <h1 role='presentation'>{item.positionName}</h1>
        <h2 role='presentation'>{item.companyName}</h2>
        <IconMoreLine
          onClick={(e) => {
            e.stopPropagation();
            setViewMoreBox(true);
          }}
          className='icon-more-line'
          width={24}
          height={24}
        />
        <MatchHomeDashboardMoreBox
          item={{
            ...item,
            jobApplicationProcessType: MatchHomeDashboardPositionType.HIDDEN,
          }}
          positionSn={item.positionSn}
          isOpen={viewMoreBox}
          onClickClose={() => setViewMoreBox(false)}
        />
      </StyledMatchHomeDashboardCard>

    </>
  );
};

export default MatchHomeDashboardHiddenCard;
