import { useState } from 'react';

type updateCounterMethod = (value: number, interval?: number, callback?: (value: number) => void) => void;

const useCountering = (initCount: number = 0): [number, updateCounterMethod] => {
  const [counter, setCounter] = useState<number>(initCount);

  let sourceValue: number = initCount; // 변화 되기 전 count
  let counteringValue: number = initCount; // 변화 중인 count
  let targetValue: number = 0; // 변화 될 count
  let startTime: number = 0; // 변화가 시작되는 시간

  let interval: number; // 변화되는 시간
  let intervalPrime: number; // 변화되는 시간과 가장 가까운 소수

  let callback: (value: number) => void;

  let requestAniId: number = 0;

  const update = () => {
    const now = Date.now();
    const currentInterval = now - startTime + 1;

    if (currentInterval > interval) {
      setCounter(targetValue);
      callback(targetValue);
      sourceValue = targetValue;
      return;
    }

    const term = Math.floor(((targetValue - sourceValue) / intervalPrime) * currentInterval);
    counteringValue = sourceValue + term;
    setCounter(counteringValue);
    requestAniId = requestAnimationFrame(update);
  };

  const updateCounter = (_value: number, _interval = 1000, _callback: (value: number) => void = () => {}) => {
    sourceValue = counter;
    cancelAnimationFrame(requestAniId);
    startTime = Date.now();
    targetValue = _value;
    interval = _interval;
    intervalPrime = PrimeUtil.getBiggestPrime(_interval);
    callback = _callback;
    update();
  };

  return [counter, updateCounter];
};

class PrimeUtil {
  static getBiggestPrime = (value: number) => {
    let biggestPrime = 1;
    for (let i = value; i >= 2; i--) {
      let isPrime = true;
      for (let j = 2; j < i; j++) {
        if (i % j === 0) {
          isPrime = false;
          break;
        }
      }
      if (isPrime) {
        biggestPrime = i;
        break;
      }
    }
    return biggestPrime;
  }
}

export default useCountering;
