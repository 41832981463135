import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import IconACCA from 'assets/_v2/_common/icon_v2_interests.svg';
import JDRegisterItemButton from 'components/_v2/_common/button/JDRegisterItemButton';
import useToast from 'hooks/useToast';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import MyProfileModel from 'models/_v2/MyProfileModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { Divider8Grey } from 'components/divider/Divider';
import RoutePaths from 'consts/RoutePaths';
import JDALink from 'components/JDALink';
import { color } from 'd3';
import Divider from '../_common/chart/lineChart/Divider';

const Frame = styled.div`

    .title {
      font: ${Fonts.B2_Bold};
      color: ${colors.JOBDA_BLACK};
      margin: 24px 0 16px 0;
    }

    li:not(:last-child) {
      border-bottom: 1px solid ${colors.CG_40};
    }

    li {
      padding: 16px;
    }

    .myLink {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font: ${Fonts.B1_Medium};
      color: ${colors.JOBDA_BLACK};
    }
`;

export interface IMyProfileProps {
  myProfileModel?: MyProfileModel;
}

const MyProfileBoxComponent: FC<IMyProfileProps> = ({ myProfileModel = new MyProfileModel() }) => {
  const history = useHistory();
  const { accProgressList, pstProgressList, paccProgressList } = myProfileModel;
  const { setToastObject } = useToast();

  useEffect(() => {
    const init = async () => {
      await myProfileModel.loadAccProgressList();
      await myProfileModel.loadPaccProgressList();
      await myProfileModel.loadPstProgressList();
    };

    init();
  }, []);

  return (
    <>
      <Frame>
        <ul>
          <li>
            <JDALink to={RoutePaths.profile} className='myLink'>
              내 프로필
              <Icon name='arrow-right' size={32} color={colors.CG_60} />
            </JDALink>
          </li>
          <li>
            <JDALink to={RoutePaths.mypage_applying} className='myLink'>
              작성중인 지원서
              <Icon name='arrow-right' size={32} color={colors.CG_60} />
            </JDALink>
          </li>
          <li>
            <JDALink to={RoutePaths.mypage_apply} className='myLink'>
              지원현황
              <Icon name='arrow-right' size={32} color={colors.CG_60} />
            </JDALink>
          </li>
          <li>
            <JDALink to={RoutePaths.mypage_bookmark} className='myLink'>
              북마크
              <Icon name='arrow-right' size={32} color={colors.CG_60} />
            </JDALink>
          </li>
          <li>
            <JDALink
              to={accProgressList?.length === 0 ? '#' : RoutePaths.acca_results()}
              className={accProgressList?.length === 0 ? 'myLink disabled' : 'myLink'}
              onClick={() => {
                if (accProgressList?.length === 0)
                  setToastObject({ isOpen: true, type: 'ERROR', message: '확인 가능한 결과가 없습니다.' });
              }}
            >
              역량검사 결과
              <Icon name='arrow-right' size={32} color={colors.CG_60} />
            </JDALink>
          </li>
          <li>
            <JDALink
              to={(pstProgressList?.length === 0 && paccProgressList?.length === 0) ? '#' : RoutePaths.phs_pacc_results()}
              className={(pstProgressList?.length === 0 && paccProgressList?.length === 0) ? 'myLink disabled' : 'myLink'}
              onClick={() => {
                if (pstProgressList?.length === 0 && paccProgressList?.length === 0)
                  setToastObject({ isOpen: true, type: 'ERROR', message: '확인 가능한 결과가 없습니다.' });
              }}
            >
              개발자 검사 결과
              <Icon name='arrow-right' size={32} color={colors.CG_60} />
            </JDALink>
          </li>
        </ul>
      </Frame>
      <Divider8Grey />
      <Frame>
        <ul>
          <li>
            <JDALink to={RoutePaths.mypage_account} className='myLink'>
              계정정보 관리
              <Icon name='arrow-right' size={32} color={colors.CG_60} />
            </JDALink>
          </li>
          <li>
            <JDALink to={RoutePaths.mypage_partnership} className='myLink'>
              제휴서비스 신청 관리
              <Icon name='arrow-right' size={32} color={colors.CG_60} />
            </JDALink>
          </li>
          <li>
            <JDALink to={RoutePaths.mypage_notice} className='myLink'>
              알림 설정
              <Icon name='arrow-right' size={32} color={colors.CG_60} />
            </JDALink>
          </li>
        </ul>
      </Frame>
    </>
  );
};

export default inject(injectStore.myProfileModel)(observer(MyProfileBoxComponent));
