import React, { useState, useRef, useEffect } from 'react';
import { OnScrollClassName } from '__pc__/utils/hooks/useScrollInteraction/data/classData';
import { OnScrollStylesProps } from '__pc__/utils/hooks/useScrollInteraction/data/styleData';
import colors from '__designkit__/common/colors';
import Icon from '__designkit__/icon/Icon';
import classnames from 'classnames/bind';
import useIO from '__pc__/utils/hooks/useIO';
import RollingText from './rollingText';
import MainKeyVisual from './mainKeyVisual';
import styles from './step.module.scss';

const cx = classnames.bind(styles);

interface StepProps {
  onScrollClassName: OnScrollClassName;
  onScrollStyles: (props: OnScrollStylesProps) => void;
  fadeInFirst?: boolean;
}

const Step = ({ onScrollClassName, onScrollStyles: _, fadeInFirst }: StepProps) => {
  const [fadeIn, setFadeIn] = useState(false);

  const baseLineRef = useRef<HTMLDivElement>(null);
  const step1Ref = useRef<HTMLDivElement>(null);
  const step2Ref = useRef<HTMLDivElement>(null);
  const step3Ref = useRef<HTMLDivElement>(null);
  const step4Ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setAnimation();
  }, []);

  const setAnimation = () => {
    onScrollClassName({ el: step1Ref.current, classNameData: getClassData(1) });
    onScrollClassName({ el: step2Ref.current, classNameData: getClassData(2) });
    onScrollClassName({ el: step3Ref.current, classNameData: getClassData(3) });
    onScrollClassName({ el: step4Ref.current, classNameData: getClassData(4) });
  };
  const handleIntersect = (entries: IntersectionObserverEntry[]) => {
    if (entries[0].isIntersecting) {
      setFadeIn(true);
    } else {
      setFadeIn(false);
    }
  };
  const getClassData = (step: number) => {
    const { current } = baseLineRef;
    return {
      baseLineEl: current,
      top: () => (current ? (window.innerHeight) * (step - 1) - 200 : 0),
      bottom: () => (current ? (window.innerHeight) * step - 200 : 0),
      className: cx('visible'),
      reverse: true,
    };
  };
  const setSectionRef = useIO({ onIntersect: handleIntersect, threshold: 0.2 });

  return (
    <div className={cx('commonBox', 'cardInner', { on: fadeIn, fadeInFirst })}>
      <div className={cx('stepArea')}>
        <div ref={baseLineRef} className={cx('stepInner')}>
          <div className={cx('screenArea')}>
            <div ref={setSectionRef} />
            <div ref={step1Ref} className={cx('scrollStep')}>
              <MainKeyVisual />
            </div>

            <div ref={step2Ref} className={cx('scrollStep')}>
              <RollingText />
            </div>

            <div ref={step3Ref} className={cx('scrollStep', 'introInner')}>
              <span className={cx('mainText')}>
                역량만으로 취업하는
                <span className={cx('textEffect')}>완벽한 매칭 서비스</span>
              </span>
            </div>

            <div ref={step4Ref} className={cx('scrollStep', 'introInner', 'bgDissolve')}>
              <span className={cx('mainText', 'logo')} />
            </div>

            {/* 스크롤 */}
            <div className={cx('scroll')}>
              Scroll
              <Icon name='arrow-bottom' size={32} color={colors.CG_60} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step;
