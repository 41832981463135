/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import IComponentProps from 'interfaces/props/IComponentProps';
import JoyBody from '../../assets/character/character_joy_body_interview.svg';
import Bubble from './Bubble';
import JoyHands from '../../assets/character/character_joy_hands_interview.svg';
import { absoluteCenterCenter } from 'consts/style/mixins';
import { fonts } from '__designkit__/common/fonts';
import useCountering from 'utils/useCountering';
import { CultureType, CultureType2Color, CultureType2Str } from 'consts/CompanyCultureType';
import WaveGroupService from './waveGroupService';
import { IWaveOption } from './wave';

const CircleFrame = styled.div`
  ${absoluteCenterCenter()};
  width: 186px;
  height: 167px;
  .joy-body {
    position: absolute;
    top: -38px;
    left: 49px;
    transform: scale(0);

    @keyframes joy-body-show {
      0% {
        transform: scale(0);
      }
      66% {
        transform: scale(0);
      }
      78% {
        transform: scale(1.1);
      }
      100% {
        transform: scale(1);
      }
    }
  }
  .joy-hands {
    position: absolute;
    z-index: 1;
    left: 51px;
    top: -6px;
    transform: scaleY(0);
    
    @keyframes joy-hands-show {
      0% {
        transform: scaleY(0);
      }
      78% {
        transform: scaleY(0);
      }
      100% {
        transform: scaleY(1);
      }
    }
  }
  &.active {
    .joy-body {
      animation: joy-body-show 2s;
      animation-fill-mode: forwards;
    }
    .joy-hands {
      animation: joy-hands-show 2s;
      animation-fill-mode: forwards;
    }
    canvas {
      animation: waveUp 4s;
      animation-fill-mode: forwards;
      animation-timing-function: linear;
    }
  }
`;

const HeartStroke = styled.div`
  ${absoluteCenterCenter()};
  width: 186px;
  height: 167px;
  background: ${(props) => (props as any)['data-border']};
  clip-path:url(#clip-heart-stroke);
`;

const Heart = styled.div`
  ${absoluteCenterCenter()};
  width: 176px;
  height: 156px;
  background: ${(props) => (props as any)['data-background']};
  clip-path:url(#clip-heart);
`;

const WaveFrame = styled.div`
  width: 100%;
  height: 200%;
  position: relative;
  canvas {
    width: 100%;
    height: 100%;
    @keyframes waveUp {
      0% {
        transform: translate(0, 0);
      }
      100% {
        transform: translate(0, -${(props) => ((props as any)['data-ratio']) / 2}%);      
      }
    }
  }
`;

const WaveTextBox = styled.button`
  position: absolute;
  background: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  ${fonts.NOTO_15_400};
  b {
    ${fonts.NOTO_28_700};
    margin-right: 2px;
  }
  span {
    ${fonts.NOTO_10_400};
  }
`;

interface IHeartWaveProps extends IComponentProps {
  ratio?: number;
  title?: string;
}

const HeartWave: FC<IHeartWaveProps> = ((props) => {
  const [ratio, setRatio] = useState<number>(0);
  const [countingRatio, updateCount] = useCountering();
  const cultureType = CultureType.POSITIVE;
  const cultureName = CultureType2Str[cultureType];
  const cultureColor = CultureType2Color[cultureType];
  const canvasRef = useRef<HTMLCanvasElement>(null);
  let waveGroupService: WaveGroupService | null;

  const options: IWaveOption[] = [{
    fillStyle: cultureColor.color200,
    waveHeights: [15, 30, 30, 30, 20, 15],
    interval: 6,
    speed: 0.1,
    startPoint: 1,
  }, {
    fillStyle: cultureColor.color100,
    waveHeights: [15, 20, 30, 30, 20, 15],
    interval: 6,
    speed: 0.1,
    startPoint: 2,
  }];

  const resize = () => {
    if (!canvasRef.current) return;
    const { innerWidth, innerHeight } = window;
    waveGroupService?.resize(innerWidth, innerHeight);

    canvasRef.current.width = innerWidth * 2;
    canvasRef.current.height = innerHeight * 2;

    const ctx = canvasRef.current.getContext('2d') || new CanvasRenderingContext2D();
    ctx.scale(2, 2);
  };

  useEffect(() => {
    setRatio(props.ratio ?? 70);
  }, [props.ratio]);

  useEffect(() => {
    if (!canvasRef.current) return;
    const ctx = canvasRef.current.getContext('2d') || new CanvasRenderingContext2D();
    waveGroupService = new WaveGroupService(ctx, 2, options);

    resize();
    if (ratio > 0) {
      updateCount(ratio, 4000);
    }

    waveGroupService.animate();
  }, [canvasRef.current, ratio]);

  return (
    <CircleFrame className={ratio > 0 ? 'active' : ''}>
      <svg width='176' height='156' viewBox='0 0 176 156' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <defs>
          <clipPath id='clip-heart'>
            <path fillRule='evenodd' clipRule='evenodd' d='M46.8833 0.0605469C21.0669 0.0605469 0.03125 21.0734 0.03125 46.8619C0.03125 62.717 7.48934 77.808 22.5967 92.899C29.0582 99.188 70.6864 140.892 84.0596 154.286C86.2473 156.477 89.7513 156.479 91.9418 154.291C105.346 140.901 147.104 99.1887 153.4 92.899C168.699 77.999 175.966 62.908 175.966 46.8619C176.157 21.0734 155.312 0.0605469 129.496 0.0605469C112.285 0.0605469 96.6039 9.42081 88.3809 24.3208L87.9984 24.8939L87.8072 24.3208C79.5842 9.42081 63.9031 0.0605469 46.8833 0.0605469Z' />
          </clipPath>
        </defs>
        <svg width='186' height='166' viewBox='0 0 186 166' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <defs>
            <clipPath id='clip-heart-stroke'>
              <path fillRule='evenodd' clipRule='evenodd' d='M24.0748 101.514L24.0516 101.491C8.35072 85.8073 0 69.5005 0 51.8627C0 23.2461 23.3213 0 51.8842 0C68.2024 0 83.3983 7.76195 93.0951 20.5416C102.799 7.75562 118.022 0 134.497 0C163.072 0 186.2 23.2626 185.999 51.8795C185.994 69.6616 177.872 85.9736 161.924 101.514L100.481 162.89C96.3283 167.039 89.6669 167.037 85.517 162.881L83.3333 160.694C68.1005 145.437 30.209 107.485 24.0983 101.537L24.0748 101.514ZM158.401 97.8999C173.7 82.9999 180.967 67.9089 180.967 51.8627C181.158 26.0742 160.313 5.06142 134.497 5.06142C119.031 5.06142 104.8 12.6201 96.1064 24.965C95.2269 26.2139 94.404 27.5119 93.6423 28.8559C93.5994 28.9316 93.5568 29.0074 93.5143 29.0833C93.4699 29.1626 93.4258 29.2421 93.3818 29.3217L92.9994 29.8948L92.8081 29.3217C92.7221 29.1658 92.6353 29.0106 92.5476 28.8559C92.5177 28.8031 92.4876 28.7503 92.4575 28.6976C91.7211 27.4098 90.9284 26.1647 90.0836 24.9648C81.3919 12.62 67.1785 5.06142 51.8842 5.06142C26.0678 5.06142 5.03216 26.0742 5.03216 51.8627C5.03216 67.7178 12.4902 82.8089 27.5977 97.8999C33.7366 103.875 71.6194 141.818 86.8548 157.078L89.0677 159.295C91.255 161.485 94.7454 161.487 96.9355 159.299L158.401 97.8999Z' />
            </clipPath>
          </defs>
        </svg>
      </svg>
      <img className='joy-body' alt='조이몸' src={JoyBody} />
      <HeartStroke data-border={cultureColor.color80} />
      <Heart data-background={cultureColor.color50}>
        <Bubble color={cultureColor.color100} />
        <WaveFrame data-ratio={ratio}>
          <canvas ref={canvasRef} />
        </WaveFrame>
        <WaveTextBox>
          <b>{countingRatio}</b> ℃<br />
          <span>{ props.title ?? `${cultureName} 지수` }</span>
        </WaveTextBox>
      </Heart>
      <img className='joy-hands' alt='조이손' src={JoyHands} />
    </CircleFrame>
  );
});

export default HeartWave;
