import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import { QuestionHelpContentTypeCode } from 'interfaces/_v2/passInterview/IPassInterviewRs';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

const AnswerFrame = styled.div<{ open: boolean }>`
    display: flex;
    flex-direction: column;
    background-color: ${Colors.CG_30};
    padding:24px 16px;
    border-radius: 8px;
    margin-bottom: 12px;
    .answer-title{
        display: flex;
        justify-content: space-between;
        .answer{
            font:${Fonts.B1_Bold_P};
            color:${Colors.JOBDA_BLACK};
        }
        
    }
    .hidden-space{
        display: flex;
        flex-direction: column;
        gap:16px;
        visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
        height: ${(props) => (props.open ? 'auto' : '0')};
        margin-top: ${(props) => (props.open ? '16px' : '0')};
        .guide-text{
            font:${Fonts.B2_Medium_P};
            color:${Colors.CG_70};
            white-space: pre-wrap;
        }
        .divider{
            height: 1px;
            background-color: ${Colors.CG_40};
        }
        .guide-text-light{
            font:${Fonts.B2_Medium_P};
            color:${Colors.CG_60};
        }
    }
`;

const GoodAnswerFrame = styled.div<{ open: boolean }>`
    display: flex;
    flex-direction: column;
    background-color: ${Colors.G_30};
    padding:24px 16px;
    border-radius: 8px;
    margin-bottom: 12px;
    .answer-title{
        display: flex;
        justify-content: space-between;
        .answer-subtitle{
            font:${Fonts.B2_Bold_P};
            color:${Colors.G_200};
        }
        .answer{
            font:${Fonts.B1_Bold_P};
            color:${Colors.JOBDA_BLACK};
        }
        
    }
    .hidden-space{
        display: flex;
        flex-direction: column;
        gap:16px;
        visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
        height: ${(props) => (props.open ? 'auto' : '0')};
        margin-top: ${(props) => (props.open ? '16px' : '0')};
        .guide-text{
            font:${Fonts.B2_Medium_P};
            color:${Colors.CG_70};
        }
        .divider{
            height: 1px;
            background-color: ${Colors.CG_40};
        }
        .guide-text-light{
            font:${Fonts.B2_Medium_P};
            color:${Colors.CG_60};
        }
    }
`;

const BadAnswerFrame = styled.div<{ open: boolean }>`
    display: flex;
    flex-direction: column;
    background-color: ${Colors.S_30};
    padding:24px 16px;
    border-radius: 8px;
    margin-bottom: 12px;
    .answer-title{
        display: flex;
        justify-content: space-between;
        .answer-subtitle{
            font:${Fonts.B2_Bold_P};
            color:${Colors.S_300};
        }
        .answer{
            font:${Fonts.B1_Bold_P};
            color:${Colors.JOBDA_BLACK};
        }
        
    }
    .hidden-space{
        display: flex;
        flex-direction: column;
        gap:16px;
        visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
        height: ${(props) => (props.open ? 'auto' : '0')};
        margin-top: ${(props) => (props.open ? '16px' : '0')};
        .guide-text{
            font:${Fonts.B2_Medium_P};
            color:${Colors.CG_70};
        }
        .divider{
            height: 1px;
            background-color: ${Colors.CG_40};
        }
        .guide-text-light{
            font:${Fonts.B2_Medium_P};
            color:${Colors.CG_60};
        }
    }
`;
interface IPassInterviewAnswerProps {
  type: QuestionHelpContentTypeCode;
  title?: string;
  value: string;
  strength?: string
}
const PassInterviewAnswer: FC<IPassInterviewAnswerProps> = ({ type, title, value, strength }) => {
  const [answerOpen, setAnswerOpen] = useState<boolean>(false);
  const getTitleType = (titleType: QuestionHelpContentTypeCode) => {
    switch (titleType) {
      case QuestionHelpContentTypeCode.GOOD_ANSWER:
        return '이런 답변을 추천드려요!';
      case QuestionHelpContentTypeCode.BAD_ANSWER:
        return '이런 답변은 지양해요!';
      case QuestionHelpContentTypeCode.QUESTION_PURPOSE:
        return '질문 의도는 아래와 같아요!';
      case QuestionHelpContentTypeCode.ANSWER_GUIDE:
        return '답변을 이렇게 구성해 보세요!';
      case QuestionHelpContentTypeCode.CAUTION:
        return '주의점';
      default:
        return '';
    }
  };
  return (
    <>
      {type === QuestionHelpContentTypeCode.GOOD_ANSWER && (
        <GoodAnswerFrame open={answerOpen} onClick={() => setAnswerOpen(!answerOpen)}>
          <div className='answer-title'>
            <div className='answer'>{getTitleType(type)}</div>
            <Icon name={answerOpen ? 'arrow-top' : 'arrow-bottom'} size={32} />
          </div>
          <div className='hidden-space'>
            <div className='answer-subtitle'>{title}</div>
            <SpacingBlock size={12} vertical />
            <div className='guide-text'>{value}</div>
          </div>
        </GoodAnswerFrame>
      )}
      {type === QuestionHelpContentTypeCode.BAD_ANSWER && (
        <BadAnswerFrame open={answerOpen} onClick={() => setAnswerOpen(!answerOpen)}>
          <div className='answer-title'>
            <div className='answer'>{getTitleType(type)}</div>
            <Icon name={answerOpen ? 'arrow-top' : 'arrow-bottom'} size={32} />
          </div>
          <div className='hidden-space'>
            <div className='answer-subtitle'>{title}</div>
            <SpacingBlock size={12} vertical />
            <div className='guide-text'>{value}</div>
          </div>
        </BadAnswerFrame>
      )}
      {type !== QuestionHelpContentTypeCode.GOOD_ANSWER && type !== QuestionHelpContentTypeCode.BAD_ANSWER && (
        <AnswerFrame open={answerOpen} onClick={() => setAnswerOpen(!answerOpen)}>
          <div className='answer-title'>
            <div className='answer'>{getTitleType(type)}</div>
            <Icon name={answerOpen ? 'arrow-top' : 'arrow-bottom'} size={32} />
          </div>
          <div className='hidden-space'>
            <div className='guide-text'>{value}</div>
            {
              strength === '강점' && (
                <>
                  <div className='divider' />
                  <div className='guide-text-light'>응시자님의 역량 강점과 관련된 대표적인 예상 면접 질문과 가이드입니다. 응시자님이 가진 강점을 어떻게 더 잘 강조하여 어필할 수 있을지 면접 가이드를 활용하여 준비해보세요.</div>
                </>
              )
            }
            {
              strength === '약점 ' && (
                <>
                  <div className='divider' />
                  <div className='guide-text-light'>응시자님의 역량 약점과 관련된 대표적인 예상 면접 질문과 가이드입니다. 응시자님이 가진 약점을 어떻게 주의하여 잘 전달할 수 있을지 면접 가이드를 활용하여 준비해보세요.</div>
                </>
              )
            }
          </div>
        </AnswerFrame>
      )}
    </>

  );
};
export default PassInterviewAnswer;
