import MainHeader from 'components/_v2/_common/mainHeader/MainHeader';
import JDFullScreenBaseModal from 'components/_v2/_common/modals/JDFullScreenBaseModal';
import config from 'config';
import React, { FC } from 'react';
import AuthorizeUtil from 'utils/AuthorizeUtil';

interface IJDProfileViewModal {
  isOpen: boolean;
  onClickClose: () => void;
  resumeSn:number;
}

const JDResumeViewModal: FC<IJDProfileViewModal> = (props) => {
  const { isOpen, onClickClose, resumeSn } = props;

  return (
    <JDFullScreenBaseModal className='overflow-hidden' isOpen={isOpen} onClose={onClickClose} buttonType='none'>
      <MainHeader title='제출 지원서 확인하기' onClose={onClickClose} close search={false} gnb={false} />
      <iframe
        {...props}
        src={`${config.hostResume}/jobda/viewResume?resumeSn=${resumeSn}&jobdaToken=${AuthorizeUtil.bearerAccessToken}`}
        width='100%'
        height='100%'
        frameBorder='0'
        allow='autoplay; fullscreen; picture-in-picture'
        allowFullScreen
        title='resume'
      />
    </JDFullScreenBaseModal>

  );
};

export default JDResumeViewModal;
