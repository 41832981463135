import { PositionOrderType, RecruitmentType } from 'consts/_v2/position/PositionType';
import IPages, { IPageable } from 'interfaces/rqrs/IPages';
import { IImageFile } from 'interfaces/_v2/_common/IImageFile';

export enum PositionFitType{
  JOB_TITLE='JOB_TITLE',
  LOCATION = 'LOCATION',
  SALARY = 'SALARY'
}
export interface IPositionListItem {
  positionSn: number;
  positionName: string;
  recruitmentType: RecruitmentType;
  createdDateTime?: string;
  closingDateTime: string;
  locationCode: number;
  companyName: string;
  companySn?:number;
  logoImageUrl: string;
  teamImageUrl?: string;
  teamTitle?: string;
  jobGroupName?: string;
  jobTitleNames: string[];
  jobPosting:JobPostingDto;
  bookmarkYn?: boolean;
  matchingYn?: boolean;
  minSalary: number;
  maxSalary: number;
  fitTypes?:PositionFitType[]
}
export interface IJobTitleDto{
  code:number;
  codename:string;
  name:string;
}
export interface ILocationDto{
  code:number;
  name:string;
}

export interface IPositionSalaryDto{
  minSalary:number;
  maxSalary:number;
}
export interface JobPostingDto{
  jobPostingSn:number;
  jobPostingName:string;
  positionCount:number;
}
export interface IPositionListRq extends IPages {
  jobTitles: string; // 직무 코드 목록
  recruitments: string; // 채용 형태
  minSalary: number;
  maxSalary: number; // 연봉
  locations: string;// 지역
  matchingYn: boolean;// 매칭 여부
  orderType: PositionOrderType;
  keyword: string;
  bookmarkYn: boolean;
}

export interface IPositionListRs extends IPageable {
  positions: IPositionListItem[];
}

export interface IBookmarkListRs extends IPageable {
  bookmarks: IPositionListItem[];
}

export interface IPositionAd {
  sn: number;
  title: string;
  backgroundColor: string;
  buttonName: string;
  buttonColor: string;
  bannerLink: string;
  positionName: string;
  description: string;
  subDescription: string;
  bannerImageFiles: IImageFile[];
}
