import colors from '__designkit__/common/colors';

interface IKeywordColorType {
  color500: string;
  color300: string;
  color100: string;
  color90: string;
  color80: string;
  color70: string;
  color60: string;
  color30: string;
}

interface ICultureColorType {
  color500: string;
  color300: string;
  color200: string;
  color100: string;
  color90: string;
  color80: string;
  color60: string;
  color50: string;
}

export enum CultureUrlParam {
  welfare = 'welfare', // 복지
  positive = 'positive', // 긍정문화
  performance = 'performance', // 성과문화
  value = 'value', // 가치문화
}

export enum TopicType {
  CASH_REWARD = 'CASH_REWARD', // 물질적 보상
  COWORKER_RELATIONSHIP = 'COWORKER_RELATIONSHIP', // 동료와의 관계
  WORK_ENVIRONMENT = 'WORK_ENVIRONMENT', // 업무 환경

  SOCIAL_REWARD = 'SOCIAL_REWARD', // 사회적 보상
  LEADER_RELATIONSHIP = 'LEADER_RELATIONSHIP', // 리더와의 관계
  GROWTH_ENVIRONMENT = 'GROWTH_ENVIRONMENT', // 성장 환경

  MENTAL_REWARD = 'MENTAL_REWARD', // 정신적 보상
  WORLD_RELATIONSHIP = 'WORLD_RELATIONSHIP', // 세상과의 관계
  TOTAL_ENVIRONMENT = 'TOTAL_ENVIRONMENT', // 통합 환경
}

export enum CultureType {
  WELFARE = 'WELFARE', // 복지
  POSITIVE = 'POSITIVE', // 긍정문화
  PERFORMANCE = 'PERFORMANCE', // 성과문화
  VALUE = 'VALUE', // 가치문화
}

export const CultureUrlParam2CultureType: { [key in keyof typeof CultureUrlParam]: CultureType } = {
  welfare: CultureType.WELFARE,
  positive: CultureType.POSITIVE,
  performance: CultureType.PERFORMANCE,
  value: CultureType.VALUE,
};

export const CultureType2Str: { [key in keyof typeof CultureType]: string } = {
  WELFARE: '복지',
  POSITIVE: '긍정문화',
  PERFORMANCE: '성과문화',
  VALUE: '가치문화',
};

export const TopicType2Str: { [key in keyof typeof TopicType]: string } = {
  CASH_REWARD: '물질적 보상',
  COWORKER_RELATIONSHIP: '동료와의 관계',
  WORK_ENVIRONMENT: '업무 환경',

  SOCIAL_REWARD: '사회적 보상',
  LEADER_RELATIONSHIP: '리더와의 관계',
  GROWTH_ENVIRONMENT: '성장 환경',

  MENTAL_REWARD: '정신적 보상',
  WORLD_RELATIONSHIP: '세상과의 관계',
  TOTAL_ENVIRONMENT: '통합 환경',
};

export const TopicType2Description: { [key in keyof typeof TopicType]: string} = {
  CASH_REWARD: '회사에서 제공하는 금전적 또는 물질적 보상에 대해 구성원들이 긍정적으로 느끼는 정도를 보여줍니다. 해당 항목에는 각종 보상의 공정성 및 복지제도 등이 포함됩니다.',
  COWORKER_RELATIONSHIP: '나와 수평적인 위치에 있는 동료와의 관계에 대해 구성원들이 긍정적으로 느끼는 정도를 보여줍니다. 해당 항목에는 동료의 역량, 호의 및 친밀감 등의 내용이 포함됩니다.',
  WORK_ENVIRONMENT: '회사 생활 및 업무를 구성하는 전반적인 환경에 대해 구성원들이 긍정적으로 느끼는 정도를 보여줍니다. 해당 항목에는 삶의 질, 고용안정성, 보고 및 회의방식 등의 내용이 포함됩니다.',

  SOCIAL_REWARD: '사회적 성취나 인정에 대한 보상에 대해 구성원들이 긍정적으로 느끼는 정도를 보여줍니다. 해당 항목에는 인정, 칭찬 및 감사에 대한 중요성 인식 등의 내용이 포함됩니다.',
  LEADER_RELATIONSHIP: '나와 수직적인 위치에 있는 리더와의 관계에 대해 긍정적으로 느끼는 정도를 보여줍니다. 해당 항목에서는 언행일치, 권한위임 여부 등의 내용이 포함됩니다.',
  GROWTH_ENVIRONMENT: '구성원을 성장시키는 회사의 체계에 대해 긍정적으로 느끼는 정도를 보여줍니다. 해당 항목에서는 육성체계, 소통체계 및 평가체계 등의 내용이 포함됩니다.',

  MENTAL_REWARD: '회사생활을 통해 느끼는 자존감에 대해 긍정적으로 느끼는 정도를 보여줍니다. 해당 항목에서는 브랜드 파워, 회사 인지도 등의 내용이 포함됩니다.',
  WORLD_RELATIONSHIP: '회사의 경영철학, 가치관에 대해 긍정적으로 느끼는 정도를 보여줍니다. 해당 항목에서는 사회적 가치추구 및 이익의 환원 여부 등의 내용이 포함됩니다.',
  TOTAL_ENVIRONMENT: '회사 생활을 통한 자기완성에 대해 긍정적으로 느끼는 정도를 보여줍니다. 해당 항목에서는 기업윤리, 방향성 등의 내용이 포함됩니다.',
};

export const CultureType2TopicList: { [key in keyof typeof CultureType]: TopicType[] } = {
  WELFARE: [],
  POSITIVE: [TopicType.CASH_REWARD, TopicType.COWORKER_RELATIONSHIP, TopicType.WORK_ENVIRONMENT],
  PERFORMANCE: [TopicType.SOCIAL_REWARD, TopicType.LEADER_RELATIONSHIP, TopicType.GROWTH_ENVIRONMENT],
  VALUE: [TopicType.MENTAL_REWARD, TopicType.WORLD_RELATIONSHIP, TopicType.TOTAL_ENVIRONMENT],
};

export const Topic2CultureType: { [key in keyof typeof TopicType]: CultureType } = {
  CASH_REWARD: CultureType.POSITIVE,
  COWORKER_RELATIONSHIP: CultureType.POSITIVE,
  WORK_ENVIRONMENT: CultureType.POSITIVE,

  SOCIAL_REWARD: CultureType.PERFORMANCE,
  LEADER_RELATIONSHIP: CultureType.PERFORMANCE,
  GROWTH_ENVIRONMENT: CultureType.PERFORMANCE,

  MENTAL_REWARD: CultureType.VALUE,
  WORLD_RELATIONSHIP: CultureType.VALUE,
  TOTAL_ENVIRONMENT: CultureType.VALUE,
};

export const CultureType2ColorForKeyword: { [key in keyof typeof CultureType]: IKeywordColorType } = {
  // GREEN/G
  WELFARE: {
    color500: colors.c377538,
    color300: colors.c41AC4D,
    color100: colors.G_100,
    color90: colors.G_90,
    color80: colors.cA2EBB4,
    color70: colors.G_70,
    color60: colors.cC3F2CD,
    color30: colors.G_30,
  },
  // YELLOW/Y
  POSITIVE: {
    color500: colors.c938A29,
    color300: colors.Y_300,
    color100: colors.Y_100,
    color90: colors.cFDF69E,
    color80: colors.cFEF8B1,
    color70: colors.cFEF9BD,
    color60: colors.cFEFCCB,
    color30: colors.Y_30,
  },
  // SKY/S
  PERFORMANCE: {
    color500: colors.c3A7977,
    color300: colors.S_300,
    color100: colors.S_100,
    color90: colors.cAFECEA,
    color80: colors.cBBEFED,
    color70: colors.cC5F1EF,
    color60: colors.cD1F5F3,
    color30: colors.S_30,
  },
  // LIME/L
  VALUE: {
    color500: colors.c668A33,
    color300: colors.c8EC93D,
    color100: colors.cBFEF7B,
    color90: colors.cD0F894,
    color80: colors.cDCFAAD,
    color70: colors.cE2FBB9,
    color60: colors.cE7FCC6,
    color30: colors.cF9FFEB,
  },
};

export const CultureType2Color: { [key in keyof typeof CultureType]: ICultureColorType } = {
  WELFARE: {
    color500: '',
    color300: '',
    color200: '',
    color100: '',
    color90: '',
    color80: '',
    color60: '',
    color50: '',
  },
  POSITIVE: {
    // GREEN/G
    color500: colors.c377538,
    color300: colors.c41AC4D,
    color200: colors.G_200,
    color100: colors.G_100,
    color90: colors.G_90,
    color80: colors.cA2EBB4,
    color60: colors.cC3F2CD,
    color50: colors.cD0F5DA,
  },
  PERFORMANCE: {
    // SKY/S
    color500: colors.c3A7977,
    color300: colors.S_300,
    color200: colors.c5EC7C3,
    color100: colors.S_100,
    color90: colors.cAFECEA,
    color80: colors.cBBEFED,
    color60: colors.cD1F5F3,
    color50: colors.cDCF7F6,
  },
  VALUE: {
    // PURPLE/P
    color500: colors.c553B94,
    color300: colors.c7753CA,
    color200: colors.c9375DB,
    color100: colors.cB49BF0,
    color90: colors.cC7B4F4,
    color80: colors.cD5C7F7,
    color60: colors.cE8E0FB,
    color50: colors.cEEE7FC,
  },
};

interface ICultureDetailInfo {
  explainText: string;
  categoryExplain: string;
}

export const CultureType2DetailInfo: { [key in keyof typeof CultureType]: ICultureDetailInfo } = {
  WELFARE: {
    categoryExplain: '',
    explainText: '',
  },
  POSITIVE: {
    explainText: '회사를 통해 생활에 필요한 욕망을 충족 시키고 더 나아가 육체적, 정신적 안정감을 느끼게 해주는 문화입니다. 기업이 제공하는 환경은 물질적 보상, 동료와의 관계, 업무 환경 등이 있습니다. 긍정문화 지수는 이를 통해 구성원이 느끼는 안정감, 동료에 대한 신뢰, 만족감 수준을 보여줍니다.',
    categoryExplain: '구성원 $[average]%가 이 기업은 긍정이 뿜뿜 올라와 일할 맛이 나는 곳이라고 답했습니다.',
  },
  PERFORMANCE: {
    explainText: '회사 생활을 하면서 구성원과 리더에게 인정 받고 일을 통해 성장할 수 있도록 만들어주는 문화입니다. 기업이 제공하는 환경으로는 사회적 보상, 리더와의 관계, 성장환경 등이 있으며, 성과문화 지수는 이를 통해 구성원이 느끼는 존재감, 리더에 대한 신뢰, 성취감 수준을 보여줍니다.',
    categoryExplain: '구성원 $[average]%가 이 기업은 성과를 낼 수 있는 훌륭한 토양을 가지고 있다고 답했습니다.',
  },
  VALUE: {
    explainText: '회사를 통해 사회에 긍정적인 영향을 미치고 이를 통해 나, 회사에 대해 자부심을 느낄 수 있도록 만들어주는 문화입니다. 기업이 제공하는 환경으로는 정신적 보상, 세상과의 관계, 통합환경 등이 있으며, 가치문화 지수는 구성원이 느끼는 자존감, 경영진에 대한 신뢰, 완성감수준을 보여줍니다.',
    categoryExplain: '구성원 $[average]%가 이 기업은 사회를 위한 훌륭한 가치를 만들고 있다고 답했습니다.',
  },
};

export enum SurveyType {
  AVERAGE,
  CHOICE,
}

interface ITopicTop {
  keyword: string;
  explain: (answerYn: boolean) => string;
}

export const TopicType2Top: { [key in keyof typeof TopicType]: ITopicTop[] } = {
  CASH_REWARD: [
    {
      keyword: '삶의 안정감',
      explain: () => '구성원 $[average]%가 보상으로 삶의 안정감을 느낀다고 답했습니다',
    }, {
      keyword: '공정한 보상',
      explain: () => '구성원 $[average]%가 회사가 제공하는 보상은 공정하다고 답했습니다',
    }, {
      keyword: '생활 복지제도',
      explain: () => '구성원 $[average]%가 생활에 도움이 되는 복지가 있다고 답했습니다',
    },
  ],
  COWORKER_RELATIONSHIP: [
    {
      keyword: '믿음직한 동료',
      explain: () => '구성원 $[average]%가 동료는 믿고 의지할만한 존재라고 답했습니다',
    }, {
      keyword: '뛰어난 동료',
      explain: () => '구성원 $[average]%가 역량이 뛰어난 동료가 많다고 답했습니다',
    }, {
      keyword: '터놓고 말할 대상',
      explain: () => '구성원 $[average]%가 동료에게 터놓고 말할 수 있다고 답했습니다',
    }, {
      keyword: '절친한 친구',
      explain: () => '구성원 $[average]%가 회사에 절친한 친구가 있다고 답했습니다',
    },
  ],
  WORK_ENVIRONMENT: [
    {
      keyword: '업무환경 대만족',
      explain: () => '구성원 $[average]%가 회사 업무환경에 만족한다고 답했습니다',
    }, {
      keyword: '유연근무제',
      explain: () => '구성원 $[average]%가 출근 시간을 자유롭게 조절한다고 답했습니다',
    }, {
      keyword: '자유로운 퇴근',
      explain: () => '구성원 $[average]%가 눈치 안 보고 자유롭게 퇴근한다 답했습니다',
    }, {
      keyword: '퇴근 후 연락금지',
      explain: () => '구성원 $[average]%가 퇴근 후 업무지시가 오지 않는다고 답했습니다',
    }, {
      keyword: '자유로운 휴가',
      explain: () => '구성원 $[average]%가 자유롭게 휴가를 사용한다고 답했습니다',
    }, {
      keyword: '정년 보장',
      explain: () => '구성원 $[average]%가 내가 원하는 한 정년이 보장된다고 답했습니다',
    }, {
      keyword: '웃음과 유머',
      explain: () => '구성원 $[average]%가 회사는 웃음 많고 따뜻한 곳이라 답했습니다',
    }, {
      keyword: '원활한 협업',
      explain: () => '구성원 $[average]%가 부서간 협업이 원활히 이뤄진다 답했습니다',
    }, {
      keyword: '합리적인 결재',
      explain: () => '구성원 $[average]%가 품의 및 결재라인이 합리적이라고 답했습니다',
    }, {
      keyword: '합리적인 보고',
      explain: () => '구성원 $[average]%가 보고는 형식보다 내용이 중시된다 답했습니다',
    }, {
      keyword: '합리적인 회의',
      explain: () => '구성원 $[average]%가 회의는 필요할 때만 진행된다고 답했습니다',
    },
  ],

  SOCIAL_REWARD: [
    {
      keyword: '존재감',
      explain: () => '구성원 $[average]%가 구성원으로부터 존재감을 느꼈다고 답했습니다',
    }, {
      keyword: '중요한 문화',
      explain: () => '구성원 $[average]%가 사회적 보상이 회사의 주요 문화라 답했습니다',
    }, {
      keyword: '존중감',
      explain: () => '구성원 $[average]%가 직급/직책 무관하게 존중 받는다 답했습니다',
    },
  ],
  LEADER_RELATIONSHIP: [
    {
      keyword: '신뢰있는 리더',
      explain: () => '구성원 $[average]%가 리더는 믿고 따를 만한 존재라고 답했습니다',
    }, {
      keyword: '권한 위임',
      explain: () => '구성원 $[average]%가 리더는 구성원에게 권한을 위임한다 답했습니다',
    }, {
      keyword: '도움 되는 피드백',
      explain: () => '구성원 $[average]%가 리더는 도움 되는 피드백을 준다고 답했습니다',
    }, {
      keyword: '전략적 사고',
      explain: () => '구성원 $[average]%가 리더는 팀 목표달성 전략이 있다고 답했습니다',
    }, {
      keyword: '솔직한 리더',
      explain: () => '구성원 $[average]%가 리더는 실수도 솔직하게 인정한다고 답했습니다',
    },
  ],
  GROWTH_ENVIRONMENT: [
    {
      keyword: '성취감',
      explain: () => '구성원 $[average]%가 지난 반 년간 이전보다 성장했다고 답했습니다',
    }, {
      keyword: '육성체계',
      explain: () => '구성원 $[average]%가 회사는 육성체계를 갖추고 있다고 답했습니다',
    }, {
      keyword: '성장 기회',
      explain: () => '구성원 $[average]%가 직급과 상관없이 기회가 주어진다고 답했습니다',
    }, {
      keyword: '전문성 교육',
      explain: () => '구성원 $[average]%가 전문성 향상을 위한 교육이 있다고 답했습니다',
    }, {
      keyword: '도전적 목표수립',
      explain: () => '구성원 $[average]%가 회사는 도전적인 목표를 추구한다고 답했습니다',
    }, {
      keyword: '투명한 소통',
      explain: () => '구성원 $[average]%가 중요한 이슈가 투명하게 공유된다고 답했습니다',
    }, {
      keyword: '공감 목표수립',
      explain: () => '구성원 $[average]%가 구성원과 함께 목표를 수립한다고 답했습니다',
    }, {
      keyword: '평가체계',
      explain: () => '구성원 $[average]%가 회사는 평가체계를 갖추고 있다고 답했습니다',
    }, {
      keyword: '차별 금지',
      explain: () => '구성원 $[average]%가 나이/성별/학교 관련 차별이 없다 답했습니다',
    }, {
      keyword: '절대평가',
      explain: (answerYn: boolean) => (answerYn ? '구성원은 회사는 절대평가를 시행한다고 답했습니다' : '구성원은 회사는 절대평가를 시행하지 않는다고 답했습니다'),
    }, {
      keyword: '다면평가',
      explain: (answerYn: boolean) => (answerYn ? '구성원은 회사는 다면평가를 시행한다고 답했습니다' : '구성원은 회사는 다면평가를 시행하지 않는다고 답했습니다'),
    }, {
      keyword: '인사위원회',
      explain: (answerYn: boolean) => (answerYn ? '구성원은 회사는 인사위원회 등 의사결정기구가 있다고 답했습니다' : '구성원은 회사는 인사위원회 등 의사결정기구가 없다고 답했습니다'),
    },
  ],

  MENTAL_REWARD: [
    {
      keyword: '자존감',
      explain: () => '구성원 $[average]%가 우리 회사가 최고라고 답했습니다',
    }, {
      keyword: '브랜드 파워 1등',
      explain: () => '구성원 $[average]%가 회사는 브랜드 파워를 가지고 있다 답했습니다',
    }, {
      keyword: '꿈의 직장',
      explain: () => '구성원 $[average]%가 회사는 모두 원하는 꿈의 직장이라 답했습니다',
    }, {
      keyword: '자랑스러운 곳',
      explain: () => '구성원 $[average]%가 회사를 자랑스럽게 소개한다고 답했습니다',
    },
  ],
  WORLD_RELATIONSHIP: [
    {
      keyword: '신뢰있는 경영진',
      explain: () => '구성원 $[average]%가 경영진의 비전과 목표를 신뢰한다고 답했습니다',
    }, {
      keyword: '사회 공동 이익',
      explain: () => '구성원 $[average]%가 회사는 사회 공동이익을 생각한다 답했습니다',
    }, {
      keyword: '선한 영향력',
      explain: () => '구성원 $[average]%가 회사는 선한 영향력을 행사한다고 답했습니다',
    }, {
      keyword: '사회 이익 환원',
      explain: (answerYn: boolean) => (answerYn ? '구성원은 회사는 이익을 사회에 환원한다고 답했습니다' : '구성원은 회사는 이익을 사회에 환원하지 않는다고 답했습니다'),
    }, {
      keyword: '자원봉사 프로그램',
      explain: (answerYn: boolean) => (answerYn ? '구성원은 회사는 자원봉사 프로그램을 운영한다 답했습니다' : '구성원은 회사는 자원봉사 프로그램을 운영하지 않는다고 답했습니다'),
    },
  ],
  TOTAL_ENVIRONMENT: [
    {
      keyword: '완성감',
      explain: () => '구성원 $[average]%가 업무를 통해 사명과 꿈을 이룬다 답했습니다',
    }, {
      keyword: '기업 윤리',
      explain: () => '구성원 $[average]%가 회사는 정직하고 윤리적이라고 답했습니다',
    }, {
      keyword: '명확한 방향성',
      explain: () => '구성원 $[average]%가 회사가 지향하는 방향성이 명확하다 답했습니다',
    },
  ],
};

export const TopicType2Incumbent: { [key in keyof typeof TopicType]: string[] } = {
  CASH_REWARD: [
    '안정적인 삶의 원동력',
    '모두에게 공정한 보상',
    '생활에 도움되는 복지제도',
  ],
  COWORKER_RELATIONSHIP: [
    '믿고 의지할 수 있는 존재',
    '능력자 동료들',
    '터놓고 이야기할 수 있는 존재들',
    '절친한 친구',
  ],
  WORK_ENVIRONMENT: [
    '업무 환경 200% 대만족',
    '출근이 자율적',
    '퇴근이 자율적',
    '업무시간 외 업무지시 절대 금지',
    '휴가 사용이 자율적',
    '내가 원할 때까지 정년 보장',
    '웃음이 넘치고 따뜻한 곳',
    '부서간 협업이 활발한 곳',
    '품의 및 결재라인이 합리적',
    '보고 프로세스가 합리적',
    '회의 프로세스가 합리적',
  ],

  SOCIAL_REWARD: [
    '나를 존재감 있는 사람으로 만드는 것',
    '모두에게 매우 중요한 문화',
    '직급, 직책과 무관한 구성원 그 자체로서의 존중',
  ],
  LEADER_RELATIONSHIP: [
    '믿고 따를만한 존재',
    '구성원의 자율을 보장하는 곳',
    '건설적인 피드백을 주는 리더',
    '팀을 성공시킬 수 있는 전략가',
    '실수를 솔직하게 인정하는 쿨한 리더',
  ],
  GROWTH_ENVIRONMENT: [
    '나를 성장할 수 있게 만드는 곳',
    '성장 중심 육성체계를 제공하는 곳',
    '직급 상관 없이 성장 기회를 주는 곳',
    '자기계발 기회를 주는 곳',
    '실패를 두려워하지 않는 도전적인 곳',
    '중요 이슈를 투명하게 공유하는 곳',
    '모두가 공감하는 목표를 수립하는 곳',
    '육성 중심 평가체계를 제공하는 곳',
    '나이, 성별, 학교에 대한 차별이 없는 곳',
    '절대평가를 진행하는 곳',
    '다면평가를 진행하는 곳',
    '공정/객관적인 인사위원회를 운영하는 곳',
  ],

  MENTAL_REWARD: [
    '존재만으로 최고 그 자체',
    '업계 브랜드 파워 1위 회사',
    '모두가 가고 싶은 꿈의 회사',
    '모두에게 자랑스럽게 소개할 수 있는 곳',
  ],
  WORLD_RELATIONSHIP: [
    '신뢰할 수 있는 경영진이 있는 곳',
    '사회 공동 이익을 중요하게 생각하는 곳',
    '선한 영향력을 행사하는 곳',
    '정기적으로 이익을 환원하는 곳',
    '자원봉사 프로그램을 운영하는 곳',
  ],
  TOTAL_ENVIRONMENT: [
    '내 사명과 꿈을 이룰 수 있는 곳',
    '정직하고 윤리적인 곳',
    '명확한 방향성을 지향하는 곳',
  ],
};
