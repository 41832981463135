import JDALink from 'components/JDALink';
import { IPositionList } from 'components/_v2/position/PositionList';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { IPositionAd } from 'interfaces/_v2/positionList/IPositionList';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import PositionListModel from 'models/_v2/PositionListModel';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import JDSwiper, { JDFractions, JDSwiperSlide } from '../_common/JDSwiper';

const PositionAdFrame = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height:calc(100vw/8*9);
  background: ${colors.WHITE_100};
  margin-bottom:32px;
`;

const PositionAdCardFrame = styled.div`
  position:relative;
  width: 100%;
  height:calc(100vw/8*9);
  img{
    width  :100%;
    height: 100%;  
    object-fit: cover;
  }
  >div{
    width  :100%;
    height: 100%;
    top:0;
    left:0;
    background: linear-gradient(180deg, rgba(18, 22, 25, 0) 0%, rgba(18, 22, 25, 0) 40.63%, rgba(18, 22, 25, 0.55) 100%);
    position:absolute;
    padding:32px;
    display: flex;
    flex-direction: column;
    justify-content:flex-end ;
    align-items: flex-start;
    >h2{
      font: ${Fonts.B3_Bold};
      color:${colors.WHITE_100};
      border-bottom: 1px solid ${colors.WHITE_100};
      padding-bottom:3px;
      margin-bottom:16px;
    }
    >h1{
      width: 100%;
      font: ${Fonts.H2_Bold};
      color:${colors.WHITE_100};
      margin-bottom:16px;
      white-space: pre-line;
    }
    >h3{
      width: 100%;
      font: ${Fonts.B3_Medium};
      color:${colors.WHITE_100};
      line-height: 18px;
      letter-spacing: -1%;
      white-space: pre-line;
    }
  }
`;

const PositionAd: FC<IPositionList> = ({ positionListModel = new PositionListModel() }) => {
  const { positionAds } = positionListModel;
  useEffect(() => {
    const init = async () => {
      await positionListModel.loadPositionAd();
    };
    init();
  }, []);

  const PositionAdCard = (item: IPositionAd) => (
    <JDALink to={item.bannerLink}>
      <PositionAdCardFrame onClick={(() => positionListModel.addPositionAdClick(item.sn))}>
        <img
          src={item.bannerImageFiles.find(((value) => value.fileType === 'MO_BANNER_IMAGE'))?.fileUrl}
          alt='test-img'
          onError={(e) => e.currentTarget.src = 'https://picsum.photos/seed/picsum/360/405'}
        />
        <div>
          <h2>{item.positionName}</h2>
          <h1>{item.description}</h1>
          <h3>{item.subDescription}</h3>
        </div>
      </PositionAdCardFrame>
    </JDALink>
  );

  return (
    <>
      {positionAds.length > 0
        && (
          <PositionAdFrame>
            <JDSwiper
              slidesPerView='auto'
              autoplay={{ delay: 3000 }}
              pagination={JDFractions}
              onInit={(slide) => {
                try {
                  positionListModel.addPositionAdShows(positionAds[0].sn);
                } catch (e) {
                  console.error(e);
                }
              }}
              onSlideChange={(slide) => {
                try {
                  positionListModel.addPositionAdShows(positionAds[slide.activeIndex].sn);
                } catch (e) {
                  console.error(e);
                }
              }}
            >
              {
                positionAds.map((item) => (
                  <JDSwiperSlide key={item.sn}>
                    {PositionAdCard(item)}
                  </JDSwiperSlide>
                ))
              }

            </JDSwiper>
          </PositionAdFrame>
        )}
    </>

  );
};
export default inject(injectStore.positionListModel)(observer(PositionAd));
