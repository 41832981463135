import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import JDPopover from 'components/_v2/_common/popover/JDPopover';
import { LocationCode } from 'consts/CompanyLocationType';
import { lineClampBox } from 'consts/style/mixins';
import useProfileConditions from 'query/profiles/useProfileConditionsQuery';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { formatNumberWithCommas } from 'utils/NumberFormatUtil';

const ContentFrame = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    .content-list {
        display: flex;
        justify-content: left;
        align-items: baseline;
        
        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    .content-title {
        font: ${Fonts.B3_Medium_P};
        color: ${Colors.CG_70};
        white-space: nowrap;
        flex-shrink: 0;
        width: 46px;
    }

    .content {
        font: ${Fonts.B2_Medium_P};
        color: ${Colors.JOBDA_BLACK};
        margin-left: 16px;
        word-break: break-all;
        text-align: left;
        flex-grow: 1;
        .count {
            color: ${Colors.G_200};
        }

        .btn-open {
          text-align: left;
        }

        .popover-frame {
          font: ${Fonts.B3_Medium_P};
          color: ${Colors.WHITE_100};
          word-break: break-all;
        }
    }
    .content-working {
        font: ${Fonts.B2_Medium_P};
        color: ${Colors.JOBDA_BLACK};
        margin-left: 16px;
        word-break: break-all;
        text-align: left;
        flex-grow: 1;
        ${lineClampBox(2)}
        .count {
            color: ${Colors.G_200};
        }

        .btn-open {
          text-align: left;
        }

        .popover-frame {
          font: ${Fonts.B3_Medium_P};
          color: ${Colors.WHITE_100};
          word-break: break-all;
        }
    }
   
`;

const ConditionListPopUp:FC = () => {
  const { data: profileConditionsData } = useProfileConditions(true);

  const allJobTitles = profileConditionsData?.jobGroups.reduce((acc: string[], group) => [...acc, ...group.jobTitleNames], []);
  const displayedJobTitles = allJobTitles?.slice(0, 3).join(', ');
  const moreCount = allJobTitles && allJobTitles.length > 3 ? ` + ${allJobTitles.length - 3}개` : '';
  const fullListText = allJobTitles?.join(', ');
  const welfareCnt = profileConditionsData?.welfare ? profileConditionsData?.welfare.length : 0;

  return (
    <ContentFrame>
      <div className='content-list'>
        <div className='content-title'>직군·직무</div>
        <div className='content'>
          <JDPopover
            useOutsideClick
            position='non_pony_under'
            popoverWidth={228}
            anchorIcon={(
              <>
                {displayedJobTitles}<span className='count'>{moreCount}</span>
              </>
            )}
          >
            <div className='popover-frame'>
              {fullListText}
            </div>
          </JDPopover>
        </div>
      </div>
      <div className='content-list'>
        <div className='content-title'>근무지</div>
        <div className='content'>
          <JDPopover
            useOutsideClick
            position='non_pony_under'
            popoverWidth={228}
            anchorIcon={(
              <>
                {profileConditionsData?.locations.slice(0, 3).map((location, index, array) => (
                  <>
                    {LocationCode(Number(location))}
                    {index < array.length - 1 ? ', ' : ''}
                  </>
                ))}
                <span className='count'>{profileConditionsData?.locations && profileConditionsData?.locations.length > 3 && ` + ${profileConditionsData.locations.length - 3}개`}</span>
              </>
    )}
          >
            <div className='popover-frame'>
              {profileConditionsData?.locations.map((location, index, array) => (
                <>
                  {LocationCode(Number(location))}
                  {index < array.length - 1 ? ', ' : ''}
                </>
              ))}
            </div>
          </JDPopover>
        </div>
      </div>
      <div className='content-list'>
        <div className='content-title'>연봉</div>
        <div className='content'>
          {formatNumberWithCommas(profileConditionsData?.salary || 0)}만원 이상
        </div>
      </div>
      <div className='content-list'>
        <div className='content-title'>복지</div>
        <div className='content'>
          {
            welfareCnt > 0
              ? (
                <JDPopover
                  useOutsideClick
                  position='non_pony_under'
                  popoverWidth={228}
                  anchorIcon={(
                    <>
                      {profileConditionsData?.welfare.slice(0, 3).map((welfare, index, array) => (
                        <React.Fragment key={index}>
                          {welfare.name}
                          {index < array.length - 1 ? ', ' : ''}
                        </React.Fragment>
                      ))}
                      <span className='count'>{welfareCnt > 3 && ` + ${welfareCnt - 3}개`}</span>
                    </>
            )}
                >
                  <div className='popover-frame'>
                    {profileConditionsData?.welfare.map((welfare, index, array) => (
                      <React.Fragment key={index}>
                        {welfare.name}
                        {index < array.length - 1 ? ', ' : ''}
                      </React.Fragment>
                    ))}
                  </div>
                </JDPopover>
              )

              : <>-</>
          }

        </div>
      </div>
      <div className='content-list'>
        <div className='content-title'>희망사항</div>
        <div className='content-working'>
          {profileConditionsData?.workingPreference}
        </div>
      </div>
    </ContentFrame>
  );
};

export default ConditionListPopUp;
