import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { FC, useEffect } from 'react';
import MobileStore from 'store/mobileStore';
import { createGlobalStyle } from 'styled-components';

const DisableScrollStyle = createGlobalStyle`

  body {
    height:100%;
    width:100%;
    overflow:hidden;
    touch-action: none;
    -webkit-overflow-scrolling: none;
    overscroll-behavior: none;
    -ms-touch-action: none;
  }
  
`;

export const DisableScroll:FC = () => {
  useEffect(() => {
    const body = document.querySelector('body');
    // Web Safari에서 body가 스크롤 되는 현상 막기 위해 사용.
    if (body && !MobileStore.isMobile) {
      disableBodyScroll(body, { allowTouchMove: () => true });
    }

    return () => {
      if (body && !MobileStore.isMobile) {
        enableBodyScroll(body);
        clearAllBodyScrollLocks();
      }
    };
  }, []);

  return (
    <DisableScrollStyle />
  );
};

export default DisableScroll;
