/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
import { AxiosResponse } from 'axios';
import URIs from 'consts/URIs';
import { PassReviewBannerListRs, PassReviewCommentListGetRs, PassReviewPostCommentsRq, PassReviewPostGetRs, PassReviewPostListGetRq, PassReviewPostListGetRs, PassReviewPostSaveRq, PassReviewPostSaveRs, PassReviewQuestionListRq, PassReviewQuestionListRs } from 'interfaces/_v2/passReview/IPassReviewRqRs';
import request from 'utils/request';

export default interface PassReviewRepo {
  // 합격 후기 게시글 목록 조회
  fetchPassReviewPosts(rq: PassReviewPostListGetRq): Promise<PassReviewPostListGetRs>;
  // 합격 후기 게시글 등록
  createPassReviewPosts(rq: PassReviewPostSaveRq): Promise<PassReviewPostSaveRs>;
  // 합격 후기 게시글 상세 조회
  fetchPassReviewPostDetail(sn: number): Promise<PassReviewPostGetRs>;
  // 합격 후기 게시글 수정
  updatePassReviewPosts(sn: number, rq: PassReviewPostSaveRq): Promise<PassReviewPostSaveRs>;
  // 합격 후기 게시글 삭제
  deletePassReviewPosts(sn: number): Promise<boolean>;
  // 합격 후기 게시글 질문 목록 조회
  fetchPassReviewPostQuestions(rq: PassReviewQuestionListRq): Promise<PassReviewQuestionListRs>;

  // 합격 후기 배너 목록 조회
  fetchPassReviewBanners(): Promise<PassReviewBannerListRs>;
  // 합격 후기 댓글 목록 조회
  fetchPassReviewLoadComments(sn:number): Promise<PassReviewCommentListGetRs>;
   // 합격 후기 댓글 등록
  fetchPassReviewCreateComments(sn:number, rq:PassReviewPostCommentsRq): Promise<boolean>;
   // 합격 후기 댓글 수정
  fetchPassReviewUpdateComments(sn:number, commentSn:number, rq:PassReviewPostCommentsRq): Promise<boolean>;
   // 합격 후기 댓글 삭제
  fetchPassReviewDeleteComments(sn:number, commentSn:number): Promise<boolean>;

}

export class RemotePassReviewRepo implements PassReviewRepo {
  fetchPassReviewPosts(rq: PassReviewPostListGetRq) {
    return request<PassReviewPostListGetRs>({
      method: 'get',
      url: URIs.get_pass_review_posts,
      params: rq,
    });
  }

  createPassReviewPosts(rq: PassReviewPostSaveRq) {
    return request<PassReviewPostSaveRs>({
      method: 'post',
      url: URIs.post_pass_review_posts,
      data: rq,
    });
  }

  fetchPassReviewPostDetail(sn: number) {
    return request<PassReviewPostGetRs>({
      method: 'get',
      url: URIs.get_pass_review_posts_postSn(sn),
    });
  }

  updatePassReviewPosts(sn: number, rq: PassReviewPostSaveRq) {
    return request<PassReviewPostSaveRs>({
      method: 'put',
      url: URIs.put_pass_review_posts_postSn(sn),
      data: rq,
    });
  }

  deletePassReviewPosts(sn: number) {
    return request<boolean>({
      method: 'delete',
      url: URIs.delete_pass_review_posts_postSn(sn),
    });
  }

  fetchPassReviewPostQuestions(rq: PassReviewQuestionListRq) {
    return request<PassReviewQuestionListRs>({
      method: 'get',
      url: URIs.get_pass_review_posts_questions,
      params: rq,
    });
  }

  fetchPassReviewBanners() {
    return request<PassReviewBannerListRs>({
      method: 'get',
      url: URIs.get_pass_review_posts_banners,
    });
  }

  fetchPassReviewLoadComments(sn:number) {
    return request<PassReviewCommentListGetRs>({
      method: 'get',
      url: URIs.get_pass_review_posts_comments(sn),
    });
  }

  fetchPassReviewCreateComments(sn:number, rq:PassReviewPostCommentsRq) {
    return request<boolean>({
      method: 'post',
      url: URIs.post_pass_review_posts_comments(sn),
      data: rq,
    });
  }

  fetchPassReviewUpdateComments(sn:number, commentSn:number, rq:PassReviewPostCommentsRq) {
    return request <boolean>({
      method: 'put',
      url: URIs.put_pass_review_posts_comments(sn, commentSn),
      data: rq,
    });
  }

  fetchPassReviewDeleteComments(sn: number, commentSn:number) {
    return request <boolean>({
      method: 'delete',
      url: URIs.delete_pass_review_posts_comments(sn, commentSn),
    });
  }
}
