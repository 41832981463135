/* eslint-disable react-hooks/exhaustive-deps */
import { MutableRefObject, useCallback, useEffect, useRef } from 'react';

// toggle = true => 다시 보이는 경우 이펙트가 다시 활성화됨
// visible = false => 일정 비율이상 보이는 경우에만 화면에 나타남, 이전까지는 영역만 차지함
interface useScrollAniProps {
  effectClass: string;
  visible?: boolean;
  toggle?: boolean;
  activeCallback?: () => void;
  unActiveCallback?: () => void;
  threshold?: number | number[];
  rootMargin?: string;
}

const useScrollAniRef = <T extends HTMLElement>(props: useScrollAniProps): MutableRefObject<T | null> => {
  const { effectClass, visible, toggle, activeCallback, unActiveCallback, threshold = 0.7, rootMargin } = props;
  const dom = useRef<T>(null);

  const handleScroll = useCallback(([entry]) => {
    const { current } = dom;
    if (current !== null && !entry.isIntersecting) {
      if (!visible) {
        current.style.visibility = 'hidden';
      }
      if (toggle) {
        current.classList.remove(effectClass);
      }
      if (unActiveCallback) {
        unActiveCallback();
      }
    }
    if (current !== null && entry.isIntersecting) {
      if (!visible) {
        current.style.visibility = 'visible';
      }
      current.classList.add(effectClass);
      if (activeCallback) {
        activeCallback();
      }
    }
  }, [dom.current]);

  useEffect(() => {
    const { current } = dom;

    if (current !== null) {
      const observer = new IntersectionObserver(handleScroll, { threshold, rootMargin });
      observer.observe(current);

      return () => observer && observer.disconnect();
    }
    return () => {};
  }, [handleScroll]);

  return dom;
};

export default useScrollAniRef;
