import React, { useState } from 'react';
import JDSwiper, { JDSwiperSlide } from 'components/_v2/_common/JDSwiper';
import contentsImg1 from '__pc__/page/accaIntroduce/@assets/view1.png';
import contentsImg2 from '__pc__/page/accaIntroduce/@assets/view2.png';
import contentsImg3 from '__pc__/page/accaIntroduce/@assets/view3.png';
import contentsImg4 from '__pc__/page/accaIntroduce/@assets/view4.png';
import contentsImg5 from '__pc__/page/accaIntroduce/@assets/view5.png';
import contentsImg6 from '__pc__/page/accaIntroduce/@assets/view6.png';
import Swiper from 'swiper';
import classnames from 'classnames/bind';
import FlowListItem from '../flowListItem';
import VideoModal from '../videoModal';
import styles from './flowList.module.scss';

const cx = classnames.bind(styles);

const FlowList = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [youtubeURL, setYoutubeURL] = useState('');

  const handleItemClick = (url: string) => {
    setIsModalOpen(true);
    setYoutubeURL(url);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const flowListItemInfo = [
    {
      thumbnail: contentsImg1,
      comment: `<strong>역량검사를 통해 제 모습이 어떤지\n미리 확인할 수 있었어요!</strong>\n준비한 답변들을 어떻게 말하는지,\n면접관들에게 제 모습이 어떻게\n보여질지를 알아볼 수 있었습니다.`,
      job: `제약/경영지원 직무`,
      who: `합격자 이건화님`,
      url: `https://www.youtube.com/embed/7kk3NIKIwB0`,
    },
    {
      thumbnail: contentsImg2,
      comment: `스펙이 부족해도 학벌이 좋지 않아도,\n나의 잠재력을 표현할 수 있는 것이\n‘역량검사’이다 보니,\n<strong>서류에서 보여줄 수 없었던\n제 모습을 보여줄 수 있어 좋았습니다.</strong>`,
      job: `플랜트 설계 직무`,
      who: `합격자 장효원님`,
      url: `https://www.youtube.com/embed/_dcfhue1JOw`,
    },
    {
      thumbnail: contentsImg3,
      comment: `<strong>역량검사는 제 자신을 그대로 보여주는\n검사라고 생각합니다.</strong>\n때문에, 지원한 직무에 적합한\n사람인 지도 미리 알아봐주는\n유용한 서비스인 것 같아요.`,
      job: `IT영업/마케팅 직무`,
      who: `합격자 이새론님`,
      url: `https://www.youtube.com/embed/mCAq__UrLLk`,
    },
    {
      thumbnail: contentsImg4,
      comment: `<strong>역량검사는 스펙이 아닌\n제 역량을 정확히 보더라구요!</strong>\n겉모습이 아닌 제 능력에 대한\n객관적 평가를 받을 수 있기 때문에\n자신있게 봤어요!`,
      job: `SW/창업 직무`,
      who: `합격자 박정현님`,
      url: `https://www.youtube.com/embed/UBopuVUV1a0`,
    },
    {
      thumbnail: contentsImg5,
      comment: `대면 면접은 면접관의\n주관이 개입 됐었는데,\n<strong>역량검사는 편견 같은 게 없으니\n저의 장점을\n잘 어필할 수 있었어요.</strong>`,
      job: `금융/영업 직무`,
      who: `합격자 정은지님`,
      url: `https://www.youtube.com/embed/1PW1xAtturE`,
    },
    {
      thumbnail: contentsImg6,
      comment: `<strong>기존의 인적성 검사가 나를 제대로\n평가할 수 있을까? 의문이 있었어요.</strong>\n역량검사는 내가 이런 강점이 있구나,\n이런 점이 괜찮구나 등 '나'에 대해 자세히 알 수 있어서 좋았습니다.`,
      job: `유통/영업지원 직무`,
      who: `합격자 정행호님`,
      url: `https://www.youtube.com/embed/YY0hBy7Fu3A`,
    },
  ];

  const flowListItemEls = flowListItemInfo.map((item, index) => (
    <JDSwiperSlide className={cx('slideCard')}>
      <FlowListItem
        key={index}
        thumbnail={item.thumbnail}
        comment={item.comment}
        job={item.job}
        who={item.who}
        url={item.url}
        handleItemClick={handleItemClick}
      />
    </JDSwiperSlide>
  ));
  const [swiperObj, setSwiperObj] = useState<Swiper | null>(null);

  return (
    <div style={{ width: '100%' }}>
      <JDSwiper
        slidesPerView="auto"
        spaceBetween={16}
        slidesOffsetBefore={16}
        slidesOffsetAfter={16}
        onSwiper={(e) => setSwiperObj(e)}
      >
        {flowListItemEls}
      </JDSwiper>

      <VideoModal
        visible={isModalOpen}
        youtubeURL={youtubeURL}
        handleClose={handleModalClose}
      />
    </div>
  );
};

export default FlowList;
