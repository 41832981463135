// created by khj1115 on 2021-07-28
import URIs from 'consts/URIs';
import { IPostDetailRs } from 'interfaces/rqrs/IPostRs';
import { action, observable } from 'mobx';
import request from 'utils/request';
import type { PostType } from 'interfaces/_v2/contents/IContents';
import { IInfoCardData, PrivacyContentsEnum } from 'interfaces/_v2/contents/IContents';
import ErrorCode from '../consts/ErrorCodes';
import { historyReplace } from '../utils/RouteUtils';
import RoutePaths from '../consts/RoutePaths';

export default class Info {
  @observable
  postDetailSn: number = 0;

  @observable
  type: PostType = 'TEXT';

  @observable
  categorySn: number = 0;

  @observable
  categoryType: string = '';

  @observable
  category: string = '';

  @observable
  title: string = '';

  @observable
  date: string = '';

  @observable
  viewCount: number = 0;

  @observable
  link: string = '';

  @observable
  chatUrl: string = '';

  @observable
  contents: string =
    '<div></div>';

  @observable
  suggestionContents: IInfoCardData[] = [];

  @action
  init = async (sn: number) => {
    this.postDetailSn = sn;
    await this.loadContentDetail();
  };

  loadContentDetail = async () => {
    try {
      if (!this.postDetailSn || Number.isNaN(this.postDetailSn)) throw Error('not page');

      const post = await request<IPostDetailRs>({
        method: 'get',
        url: URIs.get_post(this.postDetailSn),
      });
      this.type = post.type;
      this.categorySn = post.categorySn;
      this.category = post.categoryName;
      this.categoryType = post.categoryIconType;
      this.title = post.title;
      this.date = post.createdDateTime;
      this.viewCount = post.views;
      this.contents = `<div class="contents">${post.contents}</div>`
        .replace(/font-size: 15pt;/gi, 'font-size: 17px; color: #474747; font-weight: bold; ')
        .replace(/font-size: 13pt;/gi, 'font-size: 15px; color: #6a6a6a; ');
      this.link = post.link;
      this.suggestionContents = post.postCards.map((card) => ({
        id: card.sn,
        title: card.title,
        subTitle: card.label,
        category: card.categoryName,
        imageSrc: card.thumbnailUrl,
        categoryType: card.categoryIconType,
        type: card.type,
        privacy: PrivacyContentsEnum.PUBLIC,
      }));
      this.chatUrl = post.chatUrl;
    } catch (e: any) {
      const { errorCode } = e.response?.data;
      if (errorCode === ErrorCode.B902 || errorCode === ErrorCode.B903 || errorCode === ErrorCode.B904) {
        throw e;
      } else {
        historyReplace(RoutePaths.notFound);
      }
    }
  };
}
