export enum EducationLevel {
  HIGHSCHOOL = 'HIGHSCHOOL',
  ASSOCIATE = 'ASSOCIATE',
  BACHELOR = 'BACHELOR',
  MASTER = 'MASTER',
  DOCTOR = 'DOCTOR'
}

export enum EducationLevelText {
  HIGHSCHOOL = '고졸',
  ASSOCIATE = '초대졸',
  BACHELOR = '대졸',
  MASTER = '석사',
  DOCTOR = '박사'
}

export enum EducationSchoolText {
  HIGHSCHOOL = '고등학교',
  ASSOCIATE = '전문대',
  BACHELOR = '대학교',
  MASTER = '대학원(석사)',
  DOCTOR = '대학원(박사)'
}

export enum EducationType {
  GRADUATION = 'GRADUATION', // 졸업
  ATTENDING = 'ATTENDING', // 재학 중
  GRADUATION_CANDIDATE = 'GRADUATION_CANDIDATE', // 졸업 예정
  COMPLETE = 'COMPLETE', // 수료
  LEAVE = 'LEAVE', // 휴학 중
  DROP = 'DROP'// 중퇴
}

export const EducationTypeText: Record<EducationType, string> = {
  GRADUATION: '졸업',
  ATTENDING: '재학 중',
  GRADUATION_CANDIDATE: '졸업 예정',
  COMPLETE: '수료',
  LEAVE: '휴학 중',
  DROP: '중퇴',
};
