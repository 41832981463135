import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon, { IIconProps } from '__designkit__/icon/Icon';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';

const Frame = styled.div<{ isLeftIcon: boolean, isSubTitle: boolean, isError: boolean, isDisabled: boolean, isSelected: boolean, isInverted: boolean }>`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  height: fit-content;
  padding-right: 16px;
  padding-left: ${(props) => (props.isLeftIcon ? 24 : 32)}px;
  padding-top: ${(props) => (props.isSubTitle ? 20 : 16)}px;
  padding-bottom: ${(props) => (props.isSubTitle ? 20 : 16)}px;
  border: ${(props) => (props.isDisabled ? 1 : props.isSelected ? 2 : 1)}px solid ${(props) => (props.isDisabled ? Colors.CG_50 : props.isError ? Colors.ERROR : props.isSelected ? Colors.JOBDA_BLACK : props.isInverted ? Colors.JOBDA_BLACK : Colors.CG_50)};
  border-radius: 8px;
  background-color: ${(props) => (props.isDisabled ? Colors.WHITE_100 : props.isSelected ? Colors.CG_30 : props.isInverted ? Colors.JOBDA_BLACK : Colors.WHITE_100)};
  user-select: none;

  &:active {
    background-color: ${(props) => (props.isDisabled ? Colors.WHITE_100 : props.isInverted ? Colors.CG_70 : Colors.CG_30)};
  }

  & * {
    user-select: none;
  }
`;

const InnerFrame = styled.div<{ isDisabled: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
`;

const ContentsFrame = styled.div<{ isSubTitle: boolean, isInverted: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  max-width: 100%;

  > .title {
    display: flex;
    align-items: center;
    gap: 2px;
    font: ${(props) => (props.isSubTitle ? Fonts.B1_Bold : Fonts.B1_Medium)};
    color: ${(props) => (props.isInverted ? Colors.WHITE_100 : Colors.JOBDA_BLACK)};
    word-break: break-all;
  }

  > .sub-title {
    font: ${Fonts.B2_Medium};
    color: ${Colors.CG_70};
    word-break: break-all;
  }
`;

interface ISingleMenuItemProps extends IComponentProps {
  title: string; // 필수값
  subTitle?: string | ReactElement; // 선택값, 지정하면 title 아래에 나타납니다
  badge?: ReactElement; // 선택값, 지정하면 title 오른쪽에 나타납니다
  leftIcon?: ReactElement<IIconProps>; // 선택값, 지정하면 [title + subTitle] 프레임의 왼쪽에 나타납니다
  rightIcon?: ReactElement<IIconProps> | null; // 선택값, 기본 값은 arrow-right 입니다. 아예 없애고 싶다면 null을 넣어주세요
  error?: boolean; // 선택값, 지정하면 border가 빨간색, 나타납니다
  disabled?: boolean; // 선택값, 지정하면 클릭할 수 없습니다
  selected?: boolean; // 선택값, 지정하면 active 상태가 됩니다
  inverted?: boolean; // 선택값, 지정하면 배경색과 글자색이 반전됩니다. (기존: 배경 - 흰, 글자 - 검)
  onClick: () => void; // 필수값
}

const SingleMenuItem: FC<ISingleMenuItemProps> = ({ title, subTitle = undefined, badge = undefined, leftIcon = undefined, rightIcon = <Icon name='arrow-right' size={32} color={Colors.JOBDA_BLACK} />, error = false, disabled = false, selected = false, inverted = false, onClick, ...props }) => {
  const onClickFrame = () => {
    if (!disabled) onClick();
  };

  return (
    <Frame isLeftIcon={!!leftIcon} isSubTitle={!!subTitle} onClick={onClickFrame} isError={error} isDisabled={disabled} isSelected={selected} isInverted={inverted} {...props} onContextMenu={(e) => e.preventDefault()}>
      <InnerFrame isDisabled={!!disabled}>
        { leftIcon }
        <ContentsFrame isSubTitle={!!subTitle} isInverted={inverted}>
          <div className='title'>
            { title }
            { badge }
          </div>
          { subTitle && <div className='sub-title'>{ subTitle }</div> }
        </ContentsFrame>
      </InnerFrame>
      <InnerFrame isDisabled={disabled}>
        { rightIcon }
      </InnerFrame>
    </Frame>
  );
};

export default SingleMenuItem;
