import Portal from 'components/common/Portal';
import { IJDAFullScreenModalProps } from 'components/JDAFullScreenModal';
import useToast from 'hooks/useToast';
import colors from '__designkit__/common/colors';
import Fonts, { fontStyle } from '__designkit__/common/fonts';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import TermsModel from 'models/TermsModel';
import styled from 'styled-components';
import IconTermsBasic from 'assets/icon/icon_terms_basic.svg';
import IconTermsChecked from 'assets/icon/icon_terms_checked.svg';
import React, { FC, useState } from 'react';
import LazyImage from 'components/common/LazyImage';
import { IconClose } from 'consts/assets/icons/iconPages';
import { ITermsGetDto } from 'interfaces/rqrs/ITermsListGetRs';
import Term from './Term';

const getInitialState = (status = false) => Array.from({ length: 2 }, () => status);

// TermList
interface TermListProps {
  termList: ITermsGetDto[];
  checkStatus: boolean[];
  openStatus: boolean[];
  onCheck: (args: { idx: number }) => void;
  onOpen: (args: { idx: number }) => void;
}

const TermList:FC<TermListProps> = ({ termList, openStatus, checkStatus, onCheck, onOpen }) => (
  <>
    {termList.map((term, idx) => (
      <div key={term.sn} className='box-frame'>
        <Term
          idx={idx}
          isOpen={openStatus[idx]}
          isChecked={checkStatus[idx]}
          onCheck={onCheck}
          onOpen={onOpen}
          {...term}
        />
      </div>
    ))}
  </>
);

const TIMEOUT_MILLISECOND = 300;

const Frame = styled.section`
  display:block;
  position:fixed;
  z-index:10007;
  width:100%;
  height: 100%;
  overflow: scroll;
  background-color: ${colors.WHITE_100};
  top:0;
  left:0;
  overflow-x:hidden;

  animation: open .5s forwards;
  .icon-back {
    position: absolute;
    top: 16px;
    left: 16px;
  }

  &.closing {
    animation: closing ${TIMEOUT_MILLISECOND / 1000}s forwards;
  }

  .confirm-frame {
    position: fixed; 
    z-index: 1; 
    bottom: 0;
    width: 100%;
    height: 100px;
    padding: 0 20px;
  }
  .confirm {
      width: 100%;
      padding: 16px 0;
      margin: 16px 0 32px 0;
      height: 52px;
      text-align: center;
      font: ${Fonts.B1_Bold};
      color: ${colors.WHITE_100};
      background: ${colors.JOBDA_BLACK};
      border-radius: 4px;
      
      :disabled{
        opacity:0.5;
      }
    }
`;

const GNBFrame = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 56px;
    color: ${colors.CG_80};
    ${fontStyle.BODY1_SEMIBOLD};
    text-align: center;
    box-shadow: 0px 1px 1px ${colors.CG_50};

    .close-btn {
      z-index:1;
      position: absolute;
      right: 16px;
      top: 16px;
      width: 24px;
      height: 24px;
      background: none;
    }
`;

const ContentFrame = styled.div`
    width: 100%;
    padding: 32px 20px 0 20px ;

    .content-title {
      ${fontStyle.BODY1_SEMIBOLD};
      color: ${colors.CG_80};
      text-align: center;
      margin-bottom: 24px;
    }

    .box-total-frame {
      display: flex;
      flex-direction: column;
      height: calc(100vh - 260px);
      overflow-y: scroll;
      -ms-overflow-style: none;

      &::-webkit-scrollbar{ 
        display:none; 
      }
    }

    .box-frame {
      display: flex;
      width: 100%;
      padding: 16px 0;
      color: ${colors.CG_80};
      ${fontStyle.BODY2_MEDIUM};
      background: ${colors.CG_30};
      margin-bottom: 16px;
    }

    .label {
      position: relative;
      display: flex;
      align-items: center;

      .title {
        width: 228px;
      }
    }

    .input {
      margin-right: 12px;
    }

    .mark {
      margin: 0 12px;
    }

    .arrow-btn {
      position: relative;
      margin-right: 16px;
    }

 
`;

interface IMatchFileTermModalProps extends IJDAFullScreenModalProps {
    onClickConfirm: () => void;
    termsModel?: TermsModel;
}

const MatchFileTermModal:FC<IMatchFileTermModalProps> = ((props) => {
  const [closing, setClosing] = useState<boolean>(false);
  const { isOpen, className, onClose, termsModel = new TermsModel() } = props;
  const [checkStatus, setCheckStatus] = useState(getInitialState());
  const [openStatus, setOpenStatus] = useState(getInitialState());
  const { setToastObject } = useToast();

  const handleClose = async () => {
    setClosing(true);
    setTimeout(() => {
      if (onClose) onClose();
    }, TIMEOUT_MILLISECOND);
  };

  const getCheckStatus = (status: boolean[]) => status.every((state) => state !== false);

  const onClickAll = () => {
    setCheckStatus(getInitialState(!getCheckStatus(checkStatus)));
  };

  const onCheck = ({ idx }: { idx: number }) => {
    setCheckStatus((prevCheckStatus) => {
      const postCheckStatus = prevCheckStatus;
      postCheckStatus[idx] = !prevCheckStatus[idx];
      return [...postCheckStatus];
    });
  };

  const onOpen = ({ idx }: { idx: number }) => {
    setOpenStatus((prevOpenStatus) => {
      const postOpenStatus = prevOpenStatus;
      postOpenStatus[idx] = !prevOpenStatus[idx];
      return [...postOpenStatus];
    });
  };

  const onSubmit = async () => {
    if (!getCheckStatus(checkStatus)) return;

    if (await termsModel.saveFileTermsAgree({ termsSns: termsModel.termPositionSns })) {
      await termsModel.loadFileTermsAgree();
      handleClose();
    } else {
      setToastObject({ isOpen: true, type: 'ERROR', message: '저장에 실패하였습니다.', position: 'middle' });
    }
  };
  return (
    !isOpen ? <></>
      : (
        <>
          <Portal>
            <Frame {...props} className={`${className ?? ''}  ${closing ? 'closing' : ''}`}>
              <GNBFrame>
                <p className='gnb-title'>약관 동의</p>
                <button className='close-btn' onClick={handleClose}><IconClose /></button>
              </GNBFrame>
              <ContentFrame>
                <p className='content-title'>파일 제출을 위해<br />아래의 항목에 동의해 주세요. </p>
                <div className='box-total-frame'>
                  <div className='box-frame'>
                    <label className='label'>
                      <input
                        type='checkbox'
                        className='input'
                        aria-hidden
                        checked={getCheckStatus(checkStatus)}
                        onChange={onClickAll}
                      />
                      <LazyImage src={getCheckStatus(checkStatus) ? IconTermsChecked : IconTermsBasic} className='mark' />
                      <span>아래의 사항에 대해 전체 동의합니다.</span>
                    </label>
                  </div>
                  <TermList
                    termList={termsModel.privacyLists}
                    openStatus={openStatus}
                    checkStatus={checkStatus}
                    onCheck={onCheck}
                    onOpen={onOpen}
                  />
                </div>

              </ContentFrame>
              <div className='confirm-frame'>
                <button
                  className='confirm'
                  disabled={!getCheckStatus(checkStatus)}
                  onClick={onSubmit}
                >
                  동의하고 제출하기
                </button>
              </div>
            </Frame>
          </Portal>
        </>
      )
  );
});

export default inject(injectStore.termsModel)(observer(MatchFileTermModal));
