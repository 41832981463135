// created by khj1115 on 2021-07-27
/* eslint-disable react-hooks/exhaustive-deps */
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import RoutePaths from 'consts/RoutePaths';
import { ICategoryData } from 'interfaces/IInfoData';
import { inject, observer } from 'mobx-react';
import Contents from 'models/_v2/Contents';
import { injectStore } from 'models/store';
import React, { HTMLAttributes, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import ScrollUtil from 'utils/ScrollUtil';

const Frame = styled.div`
  width: 100%;
  background-color: ${colors.WHITE_100};
`;

const TabFrame = styled.article`
  overflow-x: auto;
  white-space: nowrap;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Tab = styled.section<{ selected: boolean }>`
  color: ${({ selected }) => (selected ? colors.c474747 : colors.CG_50)};
  font: ${Fonts.H4_Bold};
  line-height: 27px;
  display: inline-block;
  margin-right: 24px;
`;

interface ICategorySlideTabProps extends HTMLAttributes<HTMLDivElement> {
  categories: ICategoryData[];
  selectedCategoryId: number;
  contents?: Contents;
}

const CategorySlideTab: React.FC<ICategorySlideTabProps> = ({ contents = new Contents(), ...props }) => {
  const {
    categories,
    selectedCategoryId,
    className,
  } = props;

  const tabRef = useRef<HTMLElement>(null);
  const tabItemRef = useRef<HTMLElement>(null);
  const history = useHistory();

  useEffect(() => {
    if (selectedCategoryId !== undefined) {
      const index: number = categories.findIndex((category) => category.id === selectedCategoryId);
      const ref = [...(tabRef.current?.children as HTMLElement[]|undefined) ?? []][index];
      const left = ref?.offsetLeft - (window.innerWidth / 2) + ref?.offsetWidth / 2 ?? 0;
      if (tabRef.current) {
        tabRef.current.scrollTo({
          left,
          behavior: 'smooth',
        });
      }

      if (selectedCategoryId !== -1)history.replace(`${RoutePaths.info}?category=${selectedCategoryId}`);
      else history.replace(`${RoutePaths.info}`);
    }
  }, [selectedCategoryId]);

  return (
    <Frame className={className}>
      <TabFrame ref={tabRef}>
        {categories.map((category) => (
          <Tab
            key={category.id}
            selected={category.id === selectedCategoryId}
            onClick={() => {
              contents.setSelectedCategory(category.id);
              if (window.scrollY > 400) ScrollUtil.scrollTo(0, 400);
            }}
            ref={tabItemRef}
          >
            {category.name}
          </Tab>
        ))}
      </TabFrame>
    </Frame>
  );
};

export default inject(injectStore.contents)(observer(CategorySlideTab));
