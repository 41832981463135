import React from 'react';

import classnames from 'classnames/bind';
import styles from './gift.module.scss';

const cx = classnames.bind(styles);

const Gift = () => (
  <div className={cx('giftArea')}>
    <div className={cx('detailInfoInner')}>
      <em className={cx('detailInfoTitle')}>D:CHAMPS 순위권 들고<br />푸짐한 경품을 받아가세요</em>
      <p className={cx('detailInfoDesc')}>
        응시 페이지에서 개발자 역량검사와
        <br />
        개발 구현 능력 검사만 완료하면 끝.
        <br />
        개발자 역량검사와 개발 구현 능력 검사를
        <br />
        종료 전까지 모두 완료해야 합니다.
      </p>
    </div>
  </div>
);

export default Gift;
