import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Frame = styled.div`

.wrap {
    display: flex;
  }
  
  .countWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 24px;
    padding: 0 4px;
  }
  
  .count {
    overflow: hidden;
    display: flex;
    height: 100%;
    padding-left: 1px;
    font-family: 'Pretendard', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: #121619;
  }
  
  .number {
    height: 240px;
    transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  
    .digit {
      display: block;
      width: 14px;
      height: 24px;
      text-align: center;
      overflow: hidden;
    }
  }
  `;
interface NumberProps {
  num: number;
  delay: number;
  idx:number;
  visible?:boolean;
}

const Number = (props: NumberProps) => {
  const { num, delay, idx, visible } = props;
  const [style, setStyle] = useState({ transform: 'translateY(24px)' });
  const [styleDown, setStyleDown] = useState({ transform: 'translateY(-240px)' });
  useEffect(() => {
    setTimeout(() => {
      setStyle({ transform: `translateY(${idx % 2 === 1 ? (-num) * 24 : num * 24}px)` });
      if (idx % 2 === 1) {
        setStyle({ transform: `translateY(${(-num) * 24}px)` });
      } else {
        setStyleDown({ transform: `translateY(${-216 + num * 24}px)` });
      }
    }, delay);
  }, [num]);

  let digitEl:any[] = [];
  if (idx % 2 === 1) {
    digitEl = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((value) => <span key={`span-${value}`} className='digit'>{value}</span>);
  } else {
    digitEl = [9, 8, 7, 6, 5, 4, 3, 2, 1, 0].map((value) => <span key={`span-${value}`} className='digit'>{value}</span>);
  }

  return (
    <div className='number' style={visible ? (idx % 2 === 1 ? style : styleDown) : {}}>
      <div className={`${visible ? 'visible' : ''}`}>
        {digitEl}
      </div>
    </div>
  );
};

interface CountProps {
  count?: number;
  visible?:boolean;
}

const CountUpComplex = ({ count, visible }: CountProps) => {
  if (!count) {
    return <></>;
  }
  const countArr = count.toString().split('');
  const arr = Array.from({ length: countArr.length }, () => '0');
  for (let i = 0; i < countArr.length; i++) {
    arr[arr.length - countArr.length + i] = countArr[i];
  }

  const NumberEl = arr.map((num, index) => <Number key={`number-${index}`} visible={visible} num={parseInt(num)} delay={0} idx={(arr.length - 1 - index)} />);
  if (NumberEl.length > 3) {
    NumberEl.splice(-3, 0, <div>,</div>);
  }
  if (NumberEl.length > 7) {
    NumberEl.splice(-7, 0, <div>,</div>);
  }
  return (
    <Frame>
      <span className='countWrap'>
        <div className='count'>
          {NumberEl}
        </div>
      </span>
    </Frame>
  );
};

export default CountUpComplex;
