import { AxiosError } from 'axios';
import { IPutConditionJobGroupsRq, IPutConditionLocationsRq, IPutConditionRq, IPutConditionSalaryRq, IPutConditionWelfareRq, IPutConditionWorkingPreferenceRq } from 'interfaces/_v2/profile/IConditionRqRs';
import { useMutation, useQuery } from 'react-query';
import { RemoteProfilesRepo } from 'repository/profiles/ProfilesRepo';

export const keys = ['match', 'conditions'];

const remoteProfilesRepo = new RemoteProfilesRepo();

const fetchProfileConditions = async () => {
  const data = await remoteProfilesRepo.fetchProfileConditions();
  return data;
};

export default function useProfileConditions(isEnabled: boolean) {
  return useQuery([...keys, 'view'], fetchProfileConditions, { enabled: isEnabled });
}

const fetchSaveProfileConditions = async (rq:IPutConditionRq) => {
  const data = await remoteProfilesRepo.fetchSaveProfileConditions(rq);
  return data;
};

export function useFetchSaveProfileConditions(onSuccess: () => void, onError?: (e: AxiosError) => void) {
  return useMutation((rq:IPutConditionRq) => fetchSaveProfileConditions(rq), {
    onSuccess,
    onError,
  });
}

const fetchSaveProfileConditionsJobGroups = async (rq:IPutConditionJobGroupsRq) => {
  const data = await remoteProfilesRepo.fetchSaveProfileConditionsJobGroups(rq);
  return data;
};

export function useFetchSaveProfileConditionsJobGroups(onSuccess: () => void, onError?: (e: AxiosError) => void) {
  return useMutation((rq:IPutConditionJobGroupsRq) => fetchSaveProfileConditionsJobGroups(rq), {
    onSuccess,
    onError,
  });
}

const fetchSaveProfileConditionsLocations = async (rq:IPutConditionLocationsRq) => {
  const data = await remoteProfilesRepo.fetchSaveProfileConditionsLocations(rq);
  return data;
};

export function useFetchSaveProfileConditionsLocations(onSuccess: () => void, onError?: (e: AxiosError) => void) {
  return useMutation((rq:IPutConditionLocationsRq) => fetchSaveProfileConditionsLocations(rq), {
    onSuccess,
    onError,
  });
}

const fetchSaveProfileConditionsSalary = async (rq:IPutConditionSalaryRq) => {
  const data = await remoteProfilesRepo.fetchSaveProfileConditionsSalary(rq);
  return data;
};

export function useFetchSaveProfileConditionsSalary(onSuccess: () => void, onError?: (e: AxiosError) => void) {
  return useMutation((rq:IPutConditionSalaryRq) => fetchSaveProfileConditionsSalary(rq), {
    onSuccess,
    onError,
  });
}

const fetchSaveProfileConditionsWelfareKeywords = async (rq:IPutConditionWelfareRq) => {
  const data = await remoteProfilesRepo.fetchSaveProfileConditionsWelfareKeywords(rq);
  return data;
};

export function useFetchSaveProfileConditionsWelfareKeywords(onSuccess: () => void, onError?: (e: AxiosError) => void) {
  return useMutation((rq:IPutConditionWelfareRq) => fetchSaveProfileConditionsWelfareKeywords(rq), {
    onSuccess,
    onError,
  });
}

const fetchSaveProfileConditionsWorkingPreference = async (rq:IPutConditionWorkingPreferenceRq) => {
  const data = await remoteProfilesRepo.fetchSaveProfileConditionsWorkingPreference(rq);
  return data;
};

export function useFetchSaveProfileConditionsWorkingPreference(onSuccess: () => void, onError?: (e: AxiosError) => void) {
  return useMutation((rq:IPutConditionWorkingPreferenceRq) => fetchSaveProfileConditionsWorkingPreference(rq), {
    onSuccess,
    onError,
  });
}

const fetchLoadTotalWelfare = async () => {
  const data = await remoteProfilesRepo.fetchLoadTotalWelfare();
  return data;
};

export function useLoadTotalWelfare(isEnabled: boolean) {
  return useQuery([...keys, 'welfare'], fetchLoadTotalWelfare, { enabled: isEnabled });
}

const fetchMatchConditionsLastModified = async () => {
  const data = await remoteProfilesRepo.fetchMatchConditionsLastModified();
  return data;
};

export function useMatchConditionsLastModified(isEnabled: boolean) {
  return useQuery([...keys, 'lastModified'], fetchMatchConditionsLastModified, { enabled: isEnabled });
}

const fetchSaveMatchConditionsLastModified = async () => {
  const data = await remoteProfilesRepo.fetchSaveMatchConditionsLastModified();
  return data;
};

export function useSaveMatchConditionsLastModified(onSuccess: () => void, onError?: (e: AxiosError) => void) {
  return useMutation(fetchSaveMatchConditionsLastModified, {
    onSuccess,
    onError,
  });
}
