import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { matchPath, useLocation } from 'react-router';
import { inject, observer } from 'mobx-react';
import colors from '__designkit__/common/colors';
import shadows from 'consts/style/shadows';
import { fonts, lineHeights } from '__designkit__/common/fonts';
import NudgeModel from 'models/NudgeModel';
import { injectStore } from 'models/store';
import URIs from 'consts/URIs';
import { RoutePathsNeedNudge } from 'consts/RoutePaths';
import useScrollAniRef from './useScrollAniRef';
import IconClose from '../assets/icon/icon_close_16.svg';
import JDALink from './JDALink';

const NUDGE_FRAME_HEIGHT = 80;

const Frame = styled.div`
  height: 0;
  overflow: hidden;
  transition: height 0.25s;
  &.active {
    height: ${NUDGE_FRAME_HEIGHT}px;
  }
  
  .nudge-frame {
    background: ${colors.cEFEFEF};
    padding: 24px 16px 16px 16px;
  }
`;

const StyledNudgeWrapper = styled.div`
  position: relative;
  .nudge {
    color: ${colors.BLACK_100};
    ${fonts.NOTO_13_700}
    ${lineHeights.LINEHEIGHT_1_50}
    text-align: left;
    background: ${colors.WHITE_100};
    ${shadows.large}
    border-radius: 20px;
    height: 40px;
    transition: width 0.25s;
    width: 48px;
    overflow: hidden;
    
    .nudge-img {
      height: 40px;
    }
    
    .icon-close {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: 12px;
      width: 16px;
      height: 16px;
      opacity: 0;
      transition: opacity 0.25s;
    }
    
    &.active {
      width: 100%;
      .icon-close {
        opacity: 1;
      }
    }
  }
`;

interface INudgeProps {
  nudgeModel?: NudgeModel;
}

const Nudge: FC<INudgeProps> = ((props) => {
  const { nudgeModel = new NudgeModel() } = props;
  const location = useLocation();
  const [activeNudgeFrame, setActiveNudgeFrame] = useState<boolean>(false);
  const [activeNudge, setActiveNudge] = useState<boolean>(false);
  const nudgeFrameAni = useScrollAniRef<HTMLDivElement>({
    visible: true,
    effectClass: 'default',
    threshold: 1,
    activeCallback: () => (
      setTimeout(() => {
        setActiveNudgeFrame(true);
        setTimeout(() => {
          setActiveNudge(true);
        }, 250);
      }, 300)
    ),
  });

  const closeHandler = (e: React.MouseEvent<HTMLImageElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveNudge(false);

    if (!nudgeModel.activeNudgeInfo) return;
    setActiveNudgeFrame(false);
    nudgeModel.activeNudgeInfo.closed = true;
  };

  useEffect(() => {
    nudgeModel.loadNudgeInfoList();
  }, []);

  useEffect(() => {
    const needNudge = RoutePathsNeedNudge.find((routePath) => matchPath(location.pathname, routePath));
    if (!needNudge) return;
    nudgeModel.loadActiveNudge(location.pathname);
    setActiveNudgeFrame(false);
    setActiveNudge(false);
    return () => {
      nudgeModel.activeNudgeInfo = undefined;
    };
  }, [location.pathname, nudgeModel.isLoaded]);

  return (
    <div ref={nudgeFrameAni}>
      {
        nudgeModel.activeNudgeInfo && (
          <Frame className={activeNudgeFrame && !nudgeModel.activeNudgeInfo.closed ? 'active' : ''}>
            <div className='nudge-frame'>
              <StyledNudgeWrapper>
                <JDALink to={nudgeModel.activeNudgeInfo.link}>
                  <button className={activeNudge ? 'active nudge' : 'nudge'}>
                    <img className='nudge-img' alt='넛지 이벤트' src={URIs.get_file_view_host(nudgeModel.activeNudgeInfo.thumbnailImageUid)} />
                    <img className='icon-close' alt='닫기' src={IconClose} onClick={closeHandler} />
                  </button>
                </JDALink>
              </StyledNudgeWrapper>
            </div>
          </Frame>
        )
      }
    </div>
  );
});

export default inject(injectStore.nudgeModel)(observer(Nudge));
