import URIs from 'consts/URIs';
import { DChampsStageType } from 'consts/_v2/phs/PaccGateType';
import { IDChampsApplyStatusRs, IDChampsHighestGradeRs, IDChampsPeriodRs, IDChampsRankingRs, IDChampsResultListRs, IDChampsResultTokenRs, IDevChampsInfoDtos } from 'interfaces/_v2/phs/IDChampsRqRs';
import { action, observable } from 'mobx';
import request from 'utils/request';

export default class PaccDchampsModel {
    @observable dChampsHighestGrade:IDChampsHighestGradeRs|null = null;
    @observable dChampsInfo:IDChampsPeriodRs|null = null;
    @observable dChampsResults:IDChampsResultListRs|null = null;
    @observable dChampsResultToken:IDChampsResultTokenRs|null = null;
    @observable dChampsCurrent:boolean = false;
    @observable dChampsCountDate:Date|null = null;
    @observable dChampsFinalsCountDate:Date|null = null;
    @observable dChampsFinalsYn:boolean = false;
    @observable dChampsRanking:IDChampsRankingRs|null = null;
    @observable dChampsApplyStatus:IDChampsApplyStatusRs|null = null;
    @observable dChampsCurrentMonth:string = '';
    @observable dChampsCurrentFinalsMonth:string = '';
    @observable dChampsQualifiersPeriod:IDevChampsInfoDtos[] = [];
    @observable dChampsFinalsPeriod:IDevChampsInfoDtos[] = [];
    @observable dChampsRunning:boolean = false;
    @observable dChampsFinalsRunning:boolean = false;

    @action
    loadDChampsGrade = async (eventType:string) => {
      try {
        this.dChampsHighestGrade = (await request<IDChampsHighestGradeRs>({
          method: 'get',
          url: URIs.get_dchamps_grade,
          params: { eventType },
        }));
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadDChampsInfo = async () => {
      try {
        const res = (await request<IDChampsPeriodRs>({
          method: 'get',
          url: URIs.get_dchamps_info,
        }));
        if (res) {
          res.devChampsInfoDtos.forEach((item:IDevChampsInfoDtos) => {
            if (item.stageType === DChampsStageType.QUALIFIERS) {
              this.dChampsQualifiersPeriod.push(item);
            } else {
              this.dChampsFinalsPeriod.push(item);
            }
          });
        }
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadDChampsResults = async () => {
      try {
        this.dChampsResults = (await request<IDChampsResultListRs>({
          method: 'get',
          url: URIs.get_dchamps_results,
        }));
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadDChampsResultsToken = async (devChampsSn:number) => {
      try {
        this.dChampsResultToken = (await request<IDChampsResultTokenRs>({
          method: 'get',
          url: URIs.get_dchamps_results_token,
          params: { devChampsSn },
        }));
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadDChampsFinalsUserStatus = async (eventType:string) => {
      try {
        this.dChampsFinalsYn = (await request({
          method: 'get',
          url: URIs.get_dchamps_finals_user_status,
          params: { eventType },
        }));
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadDchampsFinalsUserList = async (eventType:string) => {
      try {
        this.dChampsRanking = (await request<IDChampsRankingRs>({
          method: 'get',
          url: URIs.get_dchamps_finals_user_list,
          params: { eventType },
        }));
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadDChampsApplyStatus = async (eventType:string) => {
      try {
        this.dChampsApplyStatus = (await request<IDChampsApplyStatusRs>({
          method: 'get',
          url: URIs.get_dchamps_apply_status,
          params: { eventType },
        }));
      } catch (e) {
        console.error(e);
      }
    }
}
