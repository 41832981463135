import ImgTrashBin from 'assets/_v2/profile/icon_trashbin.svg';
import JDPlusTextButton from 'components/_v2/_common/button/JDPlusTextButton';
import FormProfileValueTitle from 'components/_v2/_common/form/FormProfileValueTitle';
import JDBaseInput from 'components/_v2/_common/input/JDBaseInput';
import JDDatePickerInput from 'components/_v2/_common/input/JDDatePickerInput';
import JDDescriptionInput from 'components/_v2/_common/input/JDDescriptionInput';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import React, { FC, useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { IProfilePrizeProps } from 'components/_v2/profile/prize/ProfilePrizeView';
import ProfileModel from 'models/_v2/profile/ProfileModel';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';

const Frame = styled.div`
    .delete-frame{
        display: flex;
        justify-content: flex-end;
        margin-bottom: 22px;
        .delete-field{
          display:flex;
          .delete-text{
        margin-left: 4px;
        font: ${Fonts.B2_Medium_P};
        line-height: 18px;
        color:${colors.c44474B_80}
        }
        }
        
    }
    .center-institution{
      margin-bottom: 24px;
    }
    .info-date{
      margin-bottom: 24px;
    }
    .margin-date{
      margin-top: 12px;
    }
`;
const Divider = styled.div`
    width: 100%;
    height: 1px;
    background: ${colors.CG_40};
    margin: 28px 0;
`;
const ProfilePrizeCard:FC<IProfilePrizeProps> = ({ profileModel = new ProfileModel() }) => {
  const useFormed = useFormContext();
  const { control, reset } = useFormed;
  const { fields: careersPrizesFields, append: careersPrizeAppend, remove: careersPrizeRemove } = useFieldArray({
    control,
    name: 'prizes',
  });
  useEffect(() => {
    if (careersPrizesFields.length === 0) {
      careersPrizeAppend({
        institution: '',
        name: '',
        date: '',
        description: '',
      }, false);

      reset({
        prizes: [{
          institution: '',
          name: '',
          date: '',
          description: '',
        }],
      });
    }
  }, [careersPrizesFields.length]);

  return (
    <>
      {
        (careersPrizesFields).map((field, prizeIndex) => (
          <Frame>

            <div key={`${field.id}`}>
              <div className='delete-frame'>
                <div className='delete-field' role='button' onClick={() => careersPrizeRemove(prizeIndex)}>
                  <img src={ImgTrashBin} alt='trash' />
                  <div className='delete-text'>삭제</div>
                </div>
              </div>

              <div className='center-institution'>
                <FormProfileValueTitle>수여 기관</FormProfileValueTitle>
                <JDBaseInput
                  key={field.id}
                  name={`prizes[${prizeIndex}].institution`}
                  placeholder='수여 기관을 입력해 주세요.'
                  maxLength={35}
                  defaultValue={field.institution || ''}
                />
              </div>
              <FormProfileValueTitle>수여 정보</FormProfileValueTitle>
              <div className='info-date'>
                <JDBaseInput
                  key={field.id}
                  name={`prizes[${prizeIndex}].name`}
                  placeholder='수상명을 입력해 주세요.'
                  maxLength={21}
                  defaultValue={field.name || ''}
                />
                <div className='margin-date'>
                  <JDDatePickerInput
                    key={field.id}
                    name={`prizes.[${prizeIndex}].date`}
                    placeholder='연도. 월.'
                    month
                    defaultValue={field.date || ''}
                  />
                </div>

              </div>
              <FormProfileValueTitle>내용</FormProfileValueTitle>
              <JDDescriptionInput
                key={field.id}
                name={`prizes[${prizeIndex}].description`}
                placeholder='수여 내용 및 결과를 간단히 입력해 주세요.'
                maxLength={1000}
                height={120}
                autoFocus={false}
                defaultValue={field.description || ''}
              />
              <Divider />

            </div>
          </Frame>

        ))
      }
      <JDPlusTextButton
        text='추가'
        onClick={() => careersPrizeAppend({
          institution: '',
          name: '',
          date: '',
          description: '',
        })}
      />
    </>
  );
};

export default inject(injectStore.profileModel)(observer(ProfilePrizeCard));
