import colors from '__designkit__/common/colors';
import Fonts, { fontStyle } from '__designkit__/common/fonts';
import JDALink from 'components/JDALink';
import JDSwiper, { JDFractions, JDSwiperSlide } from 'components/_v2/_common/JDSwiper';
import JDUnderBaseModal from 'components/_v2/_common/modals/JDUnderBaseModal';
import { IPopup } from 'components/_v2/_common/modals/PopupRouter';
import IModalProps from 'interfaces/props/IModalProps';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { shuffleArray } from 'utils/CommonUtils';
import { DATE_FORMAT_YYYY_MM_DD_HH_MM_SS, overDateNow, str2DateTime } from 'utils/DateUtils';
import TodayRenderCheckUtil from 'utils/TodayRenderCheckUtil';

const Frame = styled.div<{ loaded: boolean }>`
  opacity: ${({ loaded }) => (loaded ? 1 : 0)};
`;

export const NudgeBackground = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  border-radius: 12px 12px 0 0;

  img {
    width: 100%;
    object-fit: cover;
  }

`;
export const BottomClose = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 16px calc(env(safe-area-inset-bottom) * .5 + 16px);

  .check-text{
        display: flex;
        align-items: center;
        margin-left: 6px;
        font: ${Fonts.B3_Medium};
        color:${colors.CG_60};
    }
  .close-text{
        display: flex;
        align-items: center;
        margin-left: 6px;
        font: ${Fonts.B2_Medium};
        color:${colors.CG_80};
    }
  
`;

interface IPopUpNudgeModal extends IModalProps {
  popups: IPopup[];
}

const PopUpNudgeModal: FC<IPopUpNudgeModal> = ({ isOpen, onClose, popups }) => {
  const [loadedCount, setLoadedCount] = useState<number>(0);
  const [filteredItems, setFilteredItems] = useState<any[]>([]);
  const [openState, setOpenState] = useState<boolean>(false);
  const allImagesLoaded = loadedCount === filteredItems.length;

  const handleImageLoad = () => {
    setLoadedCount((prevCount) => prevCount + 1);
  };

  const closeModal = () => {
    if (onClose) {
      setOpenState(false);
      onClose();
    }
  };

  useEffect(() => {
    const init = async () => {
      const validItems = await popups.reduce<Promise<IPopup[]>>(async (accPromise, item: IPopup) => {
        const acc = await accPromise;
        const overStart = str2DateTime(item.postStartDateTime).toFormat(DATE_FORMAT_YYYY_MM_DD_HH_MM_SS) ? overDateNow(str2DateTime(item.postStartDateTime).toFormat(DATE_FORMAT_YYYY_MM_DD_HH_MM_SS)) : true;
        const overEnd = str2DateTime(item.postEndDateTime).toFormat(DATE_FORMAT_YYYY_MM_DD_HH_MM_SS) ? overDateNow(str2DateTime(item.postEndDateTime).toFormat(DATE_FORMAT_YYYY_MM_DD_HH_MM_SS)) : false;
        const notShow = await TodayRenderCheckUtil.getTodayRenderCheck(`popup-${item.sn}`);
        if (overStart && !overEnd && !notShow) {
          acc.push(item);
        }
        return acc;
      }, Promise.resolve([] as IPopup[]));

      if (validItems.length === 0) {
        closeModal();
      } else {
        setOpenState(true);
      }

      const randomizedItems = shuffleArray(validItems);
      setFilteredItems(randomizedItems);
    };

    init();
  }, [popups]);
  return (
    <Frame loaded={allImagesLoaded}>
      <JDUnderBaseModal maxWidth='624px' nonDrag nudge isDimClickClose isOpen={openState && isOpen} onClickClose={() => closeModal()}>
        <>
          <NudgeBackground>
            <JDSwiper
              slidesPerView='auto'
              autoplay={{ delay: 3000 }}
              pagination={JDFractions}
              loop={filteredItems.length > 1}
            >
              {
              filteredItems.map((item: IPopup) => (
                <JDSwiperSlide key={item.moAttachFileUrl} className='main-custom-slide'>
                  {
                    item.link
                      ? (
                        <JDALink className='popup' to={item.link} onClick={closeModal}>
                          <img className='img' src={item.moAttachFileUrl} alt='popup' onLoad={handleImageLoad} />
                        </JDALink>
                      )
                      : <img className='img' src={item.moAttachFileUrl} alt='popup' onLoad={handleImageLoad} />
                  }
                </JDSwiperSlide>
              ))
            }

            </JDSwiper>
          </NudgeBackground>
          <BottomClose>
            <div
              className='check-text'
              role='button'
              onClick={() => {
                filteredItems.forEach((item: IPopup) => {
                  TodayRenderCheckUtil.setTodayRenderCheck(`popup-${item.sn}`);
                });
                if (onClose) {
                  onClose();
                }
              }}
            >
              오늘은 그만보기
            </div>
            <button onClick={closeModal} className='close-text'>닫기</button>
          </BottomClose>
        </>
      </JDUnderBaseModal>
    </Frame>
  );
};

export default inject(injectStore.termsModel)(observer(PopUpNudgeModal));
