import React from 'react';
import classnames from 'classnames/bind';
import styles from './banner2Style.module.scss';

const cx = classnames.bind(styles);

const Banner2 = () => (
  <div className={cx('contentInner', 'banner2Inner')}>
    <div className={cx('star', 'starWhite')} />
    <div className={cx('basicTitle')}>
      <p className={cx('subTitle')}>이번 황금연휴,<br />조금만 시간 투자하고</p>
      <p className={cx('mainTitle')}>
        <p className={cx('import')}>취업 합격 비공개 자료</p>받아가세요.
      </p>
    </div>
    <span className={cx('desc')}>
      <b>이벤트 기간</b> 내 프로필 완성도 30% 이상(필수정보 포함),<br />역량검사 응시 완료 시 혜택 증정
    </span>
  </div>
);

export default Banner2;
