import { JDBaseInputMixin } from 'consts/_v2/_common/style/mixins';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { forwardRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import styled from 'styled-components/macro';
import Icon from '__designkit__/icon/Icon';
import Colors from '__designkit__/common/colors';

const Frame = styled.div`
  ${JDBaseInputMixin()};

  .frame-input-box {
    padding: 6px 8px;
    display: flex;
    position: relative;
  }
`;

interface IJDABaseInput extends IComponentProps {
  name: string;
  onClear?: () => void;
  disabled?: boolean;
  defaultValue?: string;
  className?: string;
  btnClear?: boolean;
  searchIcon?: boolean;
  handleSearch?: () => void;
}

const JDTextField = forwardRef((props: IJDABaseInput, fieldRef) => {
  const { searchIcon, handleSearch, name, btnClear, onClear, disabled, defaultValue, className, ...rest } = props;
  const useFormed = useFormContext();
  const { control, watch, setValue, clearErrors, trigger, register } = useFormed;
  const {
    field: { ref: BaseRef },
    meta,
  } = useController({ name, control, defaultValue });

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(name, e.currentTarget.value);
    if (props.onChange) {
      props.onChange();
    }
  };

  return (
    <Frame className={`jd-input ${className || ''} ${!meta.invalid}`}>
      <div className='frame-input-box'>
        {searchIcon && <Icon name='search' size={24} color={Colors.CG_70} />}
        <input
          name={name}
          ref={function (innerRef) {
            if (fieldRef !== null) (fieldRef as any).current = innerRef;
            if (BaseRef !== undefined) BaseRef.current = innerRef;
            register(innerRef);
          }}
          onChange={onChangeHandler}
          disabled={!!disabled}
          value={watch(name)}
          defaultValue={defaultValue}
          autoComplete='off'
          {...rest}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              BaseRef.current.blur();
              handleSearch && handleSearch();
            }
          }}
          // onBlur={() => {
          //   handleSearch && handleSearch();
          // }}
        />
        {btnClear && (
          <button
            type='button'
            className='btn-clear'
            onClick={() => {
              if (!watch(name)) return;
              if (BaseRef !== undefined) BaseRef.current.value = '';
              if (disabled) return;
              setValue(name, '');
              clearErrors(name);
              handleSearch && handleSearch();
              if (onClear) onClear();
              trigger();
            }}
          >
            <Icon name='close' size={16} />
          </button>
        )}
      </div>
    </Frame>
  );
});

export default JDTextField;
