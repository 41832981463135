import { Button, ButtonMode } from '__designkit__/button/Button';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Shadows from '__designkit__/common/shadows';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import { DateUtil } from 'components/_v2/_common/input/datePicker/DateUtil';
import JDBaseModal from 'components/_v2/_common/modals/JDBaseModal';
import ConditionEditModal from 'components/_v2/profile/condition/ConditionEditModal';
import ConditionListPopUp from 'components/_v2/profile/match/ConditionListPopUp';
import { Divider1G40 } from 'components/divider/Divider';
import RoutePaths from 'consts/RoutePaths';
import { InsightResumeLogRq } from 'interfaces/_v2/insight/IInsightResumeRqRs';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import MatchApplyModel from 'models/_v2/MatchApplyModel';
import { injectStore } from 'models/store';
import { useMatchConditionsLastModified, useSaveMatchConditionsLastModified } from 'query/profiles/useProfileConditionsQuery';
import { useSaveLog } from 'query/useLogQuery';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 312px;
  height: fit-content;
  padding: 32px 20px 24px;
  border-radius: 12px;
  background-color: ${Colors.WHITE_100};
  filter: ${Shadows.Shadow_300};

`;

const Text = styled.div`
  text-align: center;
  font: ${Fonts.H4_Bold};
  white-space: pre-wrap;
    .colored{
        color:${Colors.G_200};
    }
  &.semi {
    font: ${Fonts.B2_Medium_P};

    > b {
        font: ${Fonts.B2_Bold_P};
    }
  }

`;

const ButtonFrame = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;


    .confirm-button {
        margin-left: 8px;
    }
`;

interface IMatchServiceMainInduceModalProps {
    login?: Login;
    isRequiredExposure?: boolean;
    setIsPriorityVisible: (flag: boolean) => void;
    matchApplyModel?: MatchApplyModel

}

const MatchServiceMainInduceModal:FC<IMatchServiceMainInduceModalProps> = ({ login = new Login(), setIsPriorityVisible, isRequiredExposure, matchApplyModel = new MatchApplyModel() }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { pathname } = useLocation();
  const onClickClose = () => { setIsOpen(false); };
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [dayPassed, setDayPassed] = useState<number>(0);

  const { data: lastModifiedData, refetch } = useMatchConditionsLastModified(!!login.userInfo);
  const induceSkipRq:InsightResumeLogRq = {
    action: 'CLICK',
    actionSource: '/',
    actionComponent: 'btn_skip_conditional_modal',
    data: {},
  };
  const induceEditRq:InsightResumeLogRq = {
    action: 'CLICK',
    actionSource: '/',
    actionComponent: 'btn_edit_conditional_modal',
    data: {},
  };
  const saveSuccess = async () => {
    await refetch();
    await saveLog(induceSkipRq);
    setIsEditOpen(false);
  };
  const saveSuccessLog = async () => {
  };
  const { mutate: saveLastModifiedData } = useSaveMatchConditionsLastModified(saveSuccess);
  const { mutate: saveLog } = useSaveLog(saveSuccessLog);
  const checkIfLasted = (lastDay:string, nudgeDay:string) => {
    if (!lastDay) {
      return false;
    }
    const lastDayDate = DateUtil.convert(lastDay);
    const nudgeDayDate = DateUtil.convert(nudgeDay);
    const now = DateUtil.getNow();

    if (lastDayDate && nudgeDayDate && DateUtil.getLeftDays(lastDayDate, now) > 30 && DateUtil.isPastDate(nudgeDayDate)) {
      setDayPassed(DateUtil.getLeftDays(lastDayDate, now));
      return true;
    }
    return false;
  };

  useEffect(() => {
    const init = async () => {
      await login.getMatchConfigStatus();
    };
    init();
  }, []);

  useEffect(() => {
    const init = async () => {
      if (lastModifiedData) {
        if (pathname === RoutePaths.home && checkIfLasted(lastModifiedData.lastModifiedDateTime, lastModifiedData.nextNudgeDateTime) && !isRequiredExposure && login.matchConfigStatus?.matchingConfigYn) {
          setIsPriorityVisible(true);
          setIsOpen(true);
        } else {
          setIsPriorityVisible(false);
          setIsOpen(false);
        }
      } else {
        setIsPriorityVisible(false);
      }
    };

    init();
  }, [lastModifiedData, login.matchConfigStatus]);

  return (
    <>
      <JDBaseModal
        dimmed
        isOpen={isOpen}
        onClickClose={onClickClose}
      >
        <Frame>
          <Text>선호 근무 조건을 <br />업데이트한 지 <span className='colored'>{dayPassed}일</span>이 지났어요</Text>
          <SpacingBlock size={8} vertical />
          <Text className='semi'>이 선호정보로 인사담당자에게 제안 받으시겠어요?</Text>
          <SpacingBlock size={16} vertical />
          <Divider1G40 />
          <SpacingBlock size={16} vertical />
          <ConditionListPopUp />
          <SpacingBlock size={20} vertical />
          <ButtonFrame>
            <Button
              label='이대로 제안받기'
              buttonMode={ButtonMode.DEFAULT}
              size='large'
              outLined
              onClick={() => {
                saveLastModifiedData();
              }}
            />
            <Button
              label='수정하기'
              className='confirm-button'
              buttonMode={ButtonMode.PRIMARY}
              size='large'
              onClick={async () => {
                await saveLog(induceEditRq);
                onClickClose();
                setIsEditOpen(true);
              }}
            />
          </ButtonFrame>

          <SpacingBlock size={16} vertical />
        </Frame>
      </JDBaseModal>
      <ConditionEditModal isOpen={isEditOpen} onClose={() => setIsEditOpen(false)} />
    </>
  );
};

export default inject(injectStore.login, injectStore.matchApplyModel)(observer(MatchServiceMainInduceModal));
