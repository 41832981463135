import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import JDALink from 'components/JDALink';
import Icon from '__designkit__/icon/Icon';
import Colors from '__designkit__/common/colors';
import RoutePaths from 'consts/RoutePaths';
import MatchApplyModel from 'models/_v2/MatchApplyModel';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import Login from 'models/Login';
import { useHistory, useLocation } from 'react-router';
import Context from 'models/Context';
import JDSimpleInfoModal from 'components/_v2/_common/modals/JDSimpleInfoModal';

import EventMatchModel from 'models/_v2/event/EventMatchModel';
import FileUtil from 'utils/FileUtil';
import useToast from 'hooks/useToast';
import { TOAST_TYPE } from 'components/_v2/_common/toast/JDToast';
import MarketingConsentModal from '__pc__/page/eventProfile/howTo/MarketingConsentModal';
import Loading from 'components/Loading';
import styles from './floatingBar.module.scss';

const cx = classnames.bind(styles);

interface IFloatingBar{
  matchApplyModel?:MatchApplyModel;
  login?:Login
  context?:Context
  eventMatchModel?:EventMatchModel;
}
const FloatingBar:FC<IFloatingBar> = ({ matchApplyModel = new MatchApplyModel(), login = new Login(), context = new Context(), eventMatchModel = new EventMatchModel() }) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const [downloadDisabled, setDownloadDisabled] = useState<boolean>(false);
  const [openEventSuccessModal, setOpenEventSuccessModal] = useState(false);
  const [openMarketingConsentModal, setOpenMarketingConsentModal] = useState<boolean>(false);
  const { setToastObject } = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const init = async () => {
      await matchApplyModel.loadMatchingProfile();
      if (!matchApplyModel.profile?.profileRequiredCompleteYn || login.userInfo === null) {
        setDownloadDisabled(true);
      } else {
        setDownloadDisabled(false);
      }
    };
    init();
  }, [login.userInfo]);

  const checkDownload = async () => {
    if (!login.userInfo) {
      context.setRedirectUrl(pathname);
      history.push(RoutePaths.login);
    }
    await login.loadMarketingConsent();
    if (!login.marketingConsentRs?.emailYn || !login.marketingConsentRs?.smsYn) {
      setOpenMarketingConsentModal(true);
    } else {
      try {
        setIsLoading(true);

        const res = await eventMatchModel.loadEventsProfileCompleteProfileDownload();
        if (res) {
          FileUtil.fileDownload('직무별 합격자 이력서 모음', res, true);
          setIsLoading(false);
          setOpenEventSuccessModal(true);
        }
        setIsLoading(false);
      } catch (e) {
        setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '다시 시도해 주세요.', subMessage: undefined });
        console.error(e);
      }
    }
  };

  return (isLoading ? <Loading />
    : ( // 하단 플로팅 바
      <div className={cx('floatingBar')}>
        <div className={cx('floatingInner')}>
          <JDALink to={RoutePaths.profile} className={cx('floatingBtn')}>
            프로필 작성하기
            <div className={cx('btnIcon')}>
              <Icon name='guide' size={32} color={Colors.JOBDA_BLACK} />
            </div>
          </JDALink>
          <button onClick={checkDownload} className={cx('floatingBtn', 'btnDownload')} disabled={downloadDisabled}>
            합격자 프로필 다운로드
            <div className={cx('btnIcon')}>
              <Icon name='download' width={32} height={32} fill={Colors.JOBDA_BLACK} />
            </div>
          </button>
        </div>
        <JDSimpleInfoModal
          title='혜택 다운로드 완료!'
          descriptions={`다운로드 받은 [합격자 프로필 이력서 모음.ZIP]을\n토대로 내 프로필을 보완해보세요. `}
          dimmed
          isOpen={openEventSuccessModal}
          onClickClose={() => {
            setOpenEventSuccessModal(false);
          }}
        />
        <MarketingConsentModal isOpen={openMarketingConsentModal} onClickClose={() => setOpenMarketingConsentModal(false)} />

      </div>
    )
  );
};

export default inject(injectStore.matchApplyModel, injectStore.login, injectStore.context, injectStore.eventMatchModel)(observer(FloatingBar));
