import MobileStore from 'store/mobileStore';

const SCROLL_EVENT_KEY = 'scroll';

class ScrollUtil {
  public static target: HTMLIonContentElement | Window = window;
  public static mobileEventListener: any | null = null;
  static setIonContentElement = (htmlIonContentElement: HTMLIonContentElement | null) => {
    ScrollUtil.target = htmlIonContentElement || window;
  };

  static getScrollElement = async (): Promise<HTMLElement | Window> => {
    if (!(ScrollUtil.target instanceof Window)) {
      return (await ScrollUtil.target.getScrollElement());
    }
    return ScrollUtil.target;
  };

  private static getScrollTarget = async (): Promise<HTMLElement> => {
    if (!(ScrollUtil.target instanceof Window)) {
      return (await ScrollUtil.target.getScrollElement());
    }
    return document.querySelector('html') || new HTMLElement();
  };

  static getScrollHeight = async () => (await ScrollUtil.getScrollTarget()).scrollHeight;

  static getScrollTop = async () => (await ScrollUtil.getScrollTarget()).scrollTop;

  static getClientHeight = async () => (await ScrollUtil.getScrollTarget()).clientHeight;

  static addEventListener = async (listener: EventListenerOrEventListenerObject) => {
    if (!MobileStore.isMobile) {
      (await ScrollUtil.getScrollElement()).addEventListener(SCROLL_EVENT_KEY, listener);
    } else {
      ScrollUtil.mobileEventListener = listener;
    }
  };

  static removeEventListener = async (listener: EventListenerOrEventListenerObject) => {
    if (!MobileStore.isMobile) {
      (await ScrollUtil.getScrollElement()).removeEventListener(SCROLL_EVENT_KEY, listener);
    } else {
      ScrollUtil.mobileEventListener = null;
    }
  };

  static scroll(options?: ScrollToOptions): Promise<any>;

  static scroll(x: number, y: number): Promise<any>;

  static async scroll(options?: ScrollToOptions | number, y?: number): Promise<any> {
    if (typeof options !== 'number') {
      (await ScrollUtil.getScrollElement()).scroll(options);
    } else if (typeof y === 'number') {
      (await ScrollUtil.getScrollElement()).scroll(options, y);
    }
  }

  static scrollBy(options?: ScrollToOptions): Promise<any>;

  static scrollBy(x: number, y: number): Promise<any>;

  static async scrollBy(options?: ScrollToOptions | number, y?: number): Promise<any> {
    if (typeof options !== 'number') {
      (await ScrollUtil.getScrollElement()).scrollBy(options);
    } else if (typeof y === 'number') {
      (await ScrollUtil.getScrollElement()).scrollBy(options, y);
    }
  }

  static scrollTo(options?: ScrollToOptions): Promise<any>;

  static scrollTo(x: number, y: number): Promise<any>;

  static async scrollTo(options?: ScrollToOptions | number, y?: number): Promise<any> {
    if (typeof options !== 'number') {
      (await ScrollUtil.getScrollElement()).scrollTo(options);
    } else if (typeof y === 'number') {
      (await ScrollUtil.getScrollElement()).scrollTo(options, y);
    }
  }
}

export default ScrollUtil;
