import JDALink from 'components/JDALink';
import useScrollAniRef from 'components/useScrollAniRef';
import { IconArrowUpDown, IconRightSimpleRoundSvg } from 'consts/assets/icons/iconPages';
import colors from '__designkit__/common/colors';
import { keyFrameFadeIn, keyFrameFadeInUp } from 'consts/style/mixins';
import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import Fonts from '__designkit__/common/fonts';
import SupportMail from 'consts/_v2/GlobalText';

const Frame = styled.div`
  width: 328px;
  background: ${colors.WHITE_100};
  padding: 56px 0;
  margin: 0 auto;
  display:flex;
  flex-direction: column;
  align-items: center;

  p{
    font: ${Fonts.H2_Bold};
    color:${colors.JOBDA_BLACK};
    text-align:center;
    margin-bottom: 40px;
    opacity: 0;

    &.visible {
      animation: fadeinup10 .4s forwards;
      animation-timing-function : ease-out ;
      ${keyFrameFadeInUp};
    }
  }

  >a{
    align-self: flex-start;

    &.visible {
      animation: fadeinup10 .4s forwards;
      animation-timing-function : ease-out ;
      ${keyFrameFadeInUp};
    }
  }

  .notion-link{
    width: 100%;
    text-align:left;
    margin-top: 4px;
    font: ${Fonts.H4_Bold};
    color:${colors.G_100};
    display:flex;
    align-items:center;
    >svg{
      width:16px;
      margin-left:12px
    }
  }
`;

const StyledCategoryFrame = styled.div<{ isOpen?: boolean }>`
  width: 100%;
  background: ${colors.JOBDA_WHITE};
  border-radius: 12px;
  padding: 24px 32px;
  margin-bottom: 16px;
  opacity: 0;
  > div {
    display: flex;
    justify-content: space-between;

    &.btnDown { 
      position: absolute;
      z-index: 1;
      right: 32px;
    }
  }

  .btnDown { 
    position: absolute;
    z-index: 1;
    right: 32px;
  }

  .faqTitle {
    font: ${Fonts.B1_Bold_P};
    color: ${colors.JOBDA_BLACK};
    margin-bottom: ${(props) => (props.isOpen ? '16px' : '0')};
    white-space: pre-wrap;
    word-break: keep-all;
    width: 200px;
  }

  .content {
    display: none;

    > h2 {
      font: ${Fonts.B3_Medium_P};
      color: ${colors.CG_70};
      word-break: keep-all;
      >em{
        font: ${Fonts.H4_Bold};
      }
    }

    > p {
      font: ${Fonts.H4_Bold};
      margin-bottom: 6px;
      color: ${colors.CG_70};
      opacity: 1;
    }

    ${(props) => props.isOpen
    && css`
      display: block;
      animation: fadein .4s forwards;
      ${keyFrameFadeIn}};
    `}
  }

  &.visible {
    animation: fadeinup10 0.4s forwards;
    animation-timing-function: ease-out;
    ${keyFrameFadeInUp};
  }
`;

const CategoryFrame: FC<{ title: string, content: JSX.Element }> = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const refFaq = useScrollAniRef<HTMLDivElement>({
    effectClass: 'visible',
    activeCallback: () => setVisible(true),
    visible: true,
    threshold: 0.5,
  });

  return (
    <StyledCategoryFrame ref={refFaq} isOpen={isOpen} className={`${visible ? 'visible' : ''}`}>
      <div role='button' onClick={() => setIsOpen(!isOpen)}>
        <h1 className='faqTitle'>{title}</h1>
        <div className='btnDown'><IconArrowUpDown active={isOpen} /></div>
      </div>
      <div className='content'>{content}</div>
    </StyledCategoryFrame>
  );
};

const Faq: FC = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const refTitle = useScrollAniRef<HTMLDivElement>({
    effectClass: 'visible',
    activeCallback: () => setVisible(true),
    visible: true,
    threshold: 0.5,
  });

  return (
    <Frame>
      <p ref={refTitle} className={`${visible ? 'visible' : ''}`}>역량검사, 이런 부분은<br />더 궁금해요!</p>
      <CategoryFrame
        title='역량 기반으로 매칭 받는다는 것, 무슨 의미인가요?​'
        content={(
          <>
            <h2>
              응시자가 가진 역량을 필요로 하는 기업에 채용이 열리면, 가장 빠르게 응시자와 기업을 연결해드려요.
              (역량검사 응시 완료 후 잡다에 프로필을 등록한 회원)
            </h2>
          </>
        )}
      />
      <CategoryFrame
        title='역량검사 결과표로 얻을 수 있는 정보는 무엇이 있나요?'
        content={(
          <h2>
            역량검사 결과를 바탕으로 개인의 역량 유형과 강약점을 알 수 있고, 역량 결과에 따라 취업에 활용할 수 있도록 면접 가이드를 제공해드려요.
            또한 역량검사 응시에 이상이 없었는지 체크하고 싶다면 결과표를 확인해 주세요!
          </h2>
        )}
      />
      <CategoryFrame
        title='역량검사, 다시 응시할 수 있나요?'
        content={(
          <h2>
            역량검사 센터에서의 역량검사 응시 기회는 매월 5회로 초기화 돼요. (단, 미응시한 응시 기회는 이월되지 않습니다.)
            <br />
            응시 중 문제가 생겨 정상적인 응시가 어려운 경우 <a href={`mailto:${SupportMail}`}>{SupportMail}</a>로 관련 내용을 문의해 주세요.
          </h2>
        )}
      />
      <CategoryFrame
        title='역량센터에서 응시한 역량검사, 어디에 활용할 수 있나요?'
        content={(
          <h2>
            기업의 요구에 따라 역량검사에 응시하게 될 때,<br />
            잡다에서 이미 응시 완료한 결과가 있는 경우 역량검사 결과 불러오기를 활용할 수 있어요.
          </h2>
        )}
      />
      <CategoryFrame
        title='역량검사, 평가 기준이 궁금해요.'
        content={(
          <h2>
            역량검사는 뇌신경과학을 기반으로 추출한 역량 기준을 통해, 응시자 고유의 잠재 역량이 기업문화 및 직무에 적합한지 확인해요.
          </h2>
        )}
      />
    </Frame>
  );
};

export default Faq;
