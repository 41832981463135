import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import type { IconName } from 'interfaces/_v2/_common/iconType';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Login from 'models/Login';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import { useHistory } from 'react-router';
import TermsModel from 'models/TermsModel';

const Frame = styled.div<{resume?:boolean}>`
  width: 100%;
  height: fit-content;
  padding: ${(props) => (props.resume ? '0 0 20px 0' : '32px 0')};
`;

const TitleFrame = styled.div<{resume?:boolean}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font:${(props) => (props.resume ? `${Fonts.H5_Bold}` : `${Fonts.H4_Bold}`)} ;

  & > div {
    display: flex;
    justify-content: start;
    align-items: center;
  }
`;

const Tag = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  height: fit-content;
  padding: 3px 5px;
  margin-left: 8px;
  background: ${Colors.CG_60};
  font: ${Fonts.B4_Medium};
  color: ${Colors.WHITE_100};
  border-radius: 4px;
`;

export const RequiredText = styled.span`
  margin-left: 8px;
  font: ${Fonts.B3_Medium};
  color: ${Colors.ERROR};
`;

const EditBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border: 1px solid ${Colors.CG_50};
  border-radius: 12px;
`;

const SubFrame = styled.div`
  margin-top: 6px;
  font: ${Fonts.B3_Medium};
  color: ${Colors.CG_70};
`;

interface IProfileItemTitleProps extends IComponentProps {
  login?: Login;
  termsModel?: TermsModel;
  icon: IconName;
  text: string;
  subText?: string;
  tag?: string;
  required?: boolean;
  onClickEditBtn?: () => void;
  resume?:boolean;
}

const ProfileItemTitle: FC<IProfileItemTitleProps> = ({ resume = false, login = new Login(), termsModel = new TermsModel(), icon, text, subText, tag, required, onClickEditBtn }) => {
  const history = useHistory();

  const onClickEditBtnHandler = async () => {
    if (!login.userInfo) {
      history.replace('/login');
    } else
    if (onClickEditBtn) onClickEditBtn();
  };

  return (
    <Frame resume={resume}>
      <TitleFrame resume={resume}>
        <div>
          <Icon name={icon} size={32} />
          <SpacingBlock size={4} />
          <span>{text}</span>
          {tag && <Tag>{tag}</Tag>}
          {required && <RequiredText>필수</RequiredText>}
        </div>
        { onClickEditBtn && (
        <div role='button'>
          <EditBtn>
            <Icon
              name='add'
              size={16}
              onClick={onClickEditBtnHandler}
            />
          </EditBtn>
        </div>
        )}
      </TitleFrame>
      { subText && (
      <SubFrame>
        <span>{subText}</span>
      </SubFrame>
      )}
    </Frame>
  );
};

export default inject(injectStore.login, injectStore.termsModel)(observer(ProfileItemTitle));
