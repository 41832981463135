import { EducationLevel, EducationType } from 'consts/_v2/profile/EducationLevel';
import { ProfileAuditItemType } from 'consts/_v2/profile/ProfileType';

export enum MajorType{
  MAJOR ='MAJOR',
  DOUBLE ='DOUBLE',
  MINOR ='MINOR'
}

export enum MajorTypeText{
  MAJOR ='주전공',
  DOUBLE ='복수전공',
  MINOR ='부전공'
}

export enum ScoreType{
  PERCENTAGE ='PERCENTAGE',
  SCORE = 'SCORE'
}

export interface IAttachFile {
  fileName: string;
  fileUid: string;
}

export interface IProfileMajor {
  majorType: MajorType;
  majorCode: number;
  majorName:string;
  scoreType: string;
  score: number;
  perfectScore: number;
  auditItems?:ProfileAuditItemType[]
}

interface AttachFile {
  fileUid: string;
  fileName: string;
}

export interface IProfileResearch {
  attachFiles: AttachFile[];
  description?: string;
}

export interface IProfileEducation {
  schoolLevel: EducationLevel;
  educationType: EducationType;
  startDate: string;
  endDate: string;
  schoolCode: number;
  schoolName: string;
  transferYn:boolean;
  profileMajors?: IProfileMajor[];
  profileResearches?: IProfileResearch[];
  auditItems?:ProfileAuditItemType[]
}
export interface IProfileEducationRq {
  educations: IProfileEducation[];
  sourcePage: string | null;
}

export interface IProfileEducationRs {
  educations: IProfileEducation[];
  educationLevel: EducationLevel;
}
