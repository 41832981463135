import URIs from 'consts/URIs';
import { IGetJobGroupsJobTitlesRs } from 'interfaces/_v2/ICodeRqRs';
import request from 'utils/request';

const CodeAPI = {
  // 직군, 직무 전체 조회
  getJobGroupsJobTitles: async () => {
    try {
      const res = await request<IGetJobGroupsJobTitlesRs>({
        method: 'GET',
        url: URIs.get_codes_job_groups_job_titles,
      });
      return res.jobGroupsWithTitles;
    } catch (e) {
      const err = e as { response: any };
      throw err;
    }
  },
};

export default CodeAPI;
