import React from 'react';
import description from '__pc__/graphic/description.json';
import icon_diversity from '__pc__/graphic/icon_diversity.json';
import invitation from '__pc__/graphic/invitation.json';
import classnames from 'classnames/bind';
import LottieFile from 'utils/LottieFile';
import styles from './rollingText.module.scss';

const cx = classnames.bind(styles);

const RollingText = () => (
  <div className={cx('rollingIntro')}>
    <span className={cx('mainText')}>더 이상</span>
    <div className={cx('rollingArea')}>
      <div className={cx('change-word')}>
        <div className={cx('change-inner')}>
          <p className={cx('rollingText')}>
            <div className={cx('visualWrap')}>
              <LottieFile animationData={description} />
            </div>
            자기소개서
          </p>
          <p className={cx('rollingText')}>
            <div className={cx('visualWrap')}>
              <LottieFile animationData={invitation} />
            </div>
            입사지원서
          </p>
          <p className={cx('rollingText')}>
            <div className={cx('visualWrap')}>
              <LottieFile animationData={icon_diversity} />
            </div>
            스펙<span className={cx('spec')}>SPEC</span>
          </p>
          <p className={cx('rollingText')}>
            <div className={cx('visualWrap')}>
              <LottieFile animationData={description} />
            </div>
            자기소개서
          </p>
          <p className={cx('rollingText')}>
            <div className={cx('visualWrap')}>
              <LottieFile animationData={invitation} />
            </div>
            입사지원서
          </p>
          <p className={cx('rollingText')}>
            <div className={cx('visualWrap')}>
              <LottieFile animationData={icon_diversity} />
            </div>
            스펙<span className={cx('spec')}>SPEC</span>
          </p>
          <p className={cx('rollingText')}>
            <div className={cx('visualWrap')}>
              <LottieFile animationData={description} />
            </div>
            자기소개서
          </p>
          <p className={cx('rollingText')}>
            <div className={cx('visualWrap')}>
              <LottieFile animationData={invitation} />
            </div>
            입사지원서
          </p>
          <p className={cx('rollingText')}>
            <div className={cx('visualWrap')}>
              <LottieFile animationData={icon_diversity} />
            </div>
            스펙<span className={cx('spec')}>SPEC</span>
          </p>
        </div>
      </div>
    </div>
    <span className={cx('mainText')}>신경 쓰지 마세요.</span>
  </div>
);

export default RollingText;
