import ImgJoyEmpty from 'assets/_v2/positionList/img_character_joy_empty.png';
import MatchHomeDashboardHiddenCard from 'components/_v2/matchHome/dashboard/MatchHomeDashboardHiddenCard';
import MainHeader from 'components/_v2/_common/mainHeader/MainHeader';
import JDFullScreenBaseModal from 'components/_v2/_common/modals/JDFullScreenBaseModal';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import MatchHomeDashboardModel from 'models/_v2/MatchHomeDashboardModel';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';

interface IMatchHomeDashboardHiddenModal {
  isOpen: boolean;
  onClickClose: () => void;
  matchHomeDashboardModel?:MatchHomeDashboardModel;
}

const Frame = styled.div`
    padding:12px 16px 16px 16px;

  >ul{
    list-style-type: disc;
    margin-bottom:24px;
    >li{
      margin-left:16px;
      font: ${Fonts.B2_Medium};
      color:${colors.CG_70};
      margin-bottom:8px;
    }
  }
  
  .not-found-frame{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin-top:71px;
    img{
      width:80px;
      height:80px;
    }
    >h1{
      margin-top:16px;
      font: ${Fonts.H4_Bold};
      color:${colors.JOBDA_BLACK};
    }
    
  }
`;

const StyledMatchHomeDashboardList = styled.div`
>*{
  margin-bottom: 12px;
}
`;

const MatchHomeDashboardHiddenModal: FC<IMatchHomeDashboardHiddenModal> = ({ isOpen, onClickClose, matchHomeDashboardModel = new MatchHomeDashboardModel() }) => {
  const loadHiddenList = async () => {
    await matchHomeDashboardModel.loadHiddenPositions();
  };

  useEffect(() => {
    if (isOpen) {
      loadHiddenList();
    }
  }, [isOpen]);

  return (
    <JDFullScreenBaseModal isOpen={isOpen} onClose={onClickClose} buttonType='none'>
      <MainHeader title='숨김 포지션 확인' onClose={onClickClose} close search={false} gnb={false} alarm={false} />
      <Frame>
        <ul>
          <li>
            지원내역 복구 시, 대시보드에서 다시 해당 지원내역을 확인하실 수 있습니다.
          </li>
          <li>
            지원내역 영구삭제 시, 해당 지원내역을 영구적으로 확인 하실 수 없으니 신중히 선택해 주세요.
          </li>
        </ul>
        {
           matchHomeDashboardModel.hiddenPositions.length > 0

             ? (
               <StyledMatchHomeDashboardList>
                 { matchHomeDashboardModel.hiddenPositions.map((item) => (
                   <MatchHomeDashboardHiddenCard key={item.positionSn} item={item} />
                 ))}
               </StyledMatchHomeDashboardList>
             )
             : (
               <div className='not-found-frame'>
                 <img src={ImgJoyEmpty} alt='' className='joy-img' />
                 <h1>숨겨둔 포지션을 <br /> 찾을 수 없어요.</h1>
               </div>
             )

          }

      </Frame>
    </JDFullScreenBaseModal>

  );
};

export default inject(injectStore.matchHomeDashboardModel)(observer(MatchHomeDashboardHiddenModal));
