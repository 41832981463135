import { ErrorMessage } from '@hookform/error-message';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import JDCheckInput, { JD_CHECK_INPUT_TYPE } from 'components/_v2/_common/input/JDCheckInput';
import JDDescriptionInput from 'components/_v2/_common/input/JDDescriptionInput';
import JDFileUpload from 'components/_v2/_common/input/JDFileUpload';
import JDRadioInput, { JD_RADIO_INPUT_TYPE } from 'components/_v2/_common/input/JDRadioInput';
import JDSelector, { JDSelectorType } from 'components/_v2/_common/input/JDSelector';
import { lineClampOnlyOne } from 'consts/_v2/_common/style/mixins';
import { ResumeFormType } from 'consts/_v2/profile/ProfileType';
import { IResumeAdditionInfoDto, IResumeAdditionInfoTempDto } from 'interfaces/_v2/positionJd/IResumeAdditionInfoRqRs';
import { inject, observer } from 'mobx-react';
import PositionJdModel from 'models/_v2/PositionJdModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { useFormContext, UseFormMethods } from 'react-hook-form';
import styled from 'styled-components';
import { allFileAllowedExtensions, downloadFile } from 'utils/FileUtil';

const InputFrame = styled.div`
  .message {
    &.false{
      margin-top: 12px;
      color:${colors.ERROR};
    }
  }
  
  .desc-input {
    > div {
      height: 120px;
    }
  }
`;
const Title = styled.div`
    margin: 24px 0 8px;
`;

const Question = styled.div`
    font: ${Fonts.B1_Bold};
    color: ${colors.JOBDA_BLACK};

    .question {
      flex-grow: 1;
      flex-shrink: 1;
      word-break: break-all;
    } 

    .desc {
        font: ${Fonts.B2_Medium_P};
        color: ${colors.CG_80};
        margin-left: 4px;
        flex-grow: 0;
        flex-shrink: 0;
        white-space: nowrap;
    }

`;

const RequiredTag = styled.span`
  width: fit-content;
  font: ${Fonts.B3_Medium};
  color: ${colors.ERROR};
  margin-left: 8px; // 필요에 따라 조정
`;

const ListForm = styled.ul`
    margin: 16px 0;

    li:not(:last-child) {
        margin-bottom: 16px;
    }
`;

const FileFrame = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 4px;
  column-gap: 4px;
  margin-bottom: 12px;
`;
const FileBox = styled.div`
    display: flex;
    align-items: center;
    font: ${Fonts.B3_Medium};
    color: ${colors.CG_80};
    padding: 8px 10px;
    margin-bottom: 4px;
    border-radius: 4px;
    border: 1px solid ${colors.CG_40};

    >div {
      display: flex;
      align-items: center;
    }

    .file-name {
        margin-left: 4px;
        max-width: 130px;
        ${lineClampOnlyOne()}
        word-break: break-all;
    }

    .file-size {
      font: ${Fonts.B4_Medium};
      color: ${colors.CG_60};
    }
`;

interface IProfileAdditionInfoFormProps {
    info: IResumeAdditionInfoDto;
    idx: number;
    formState?: UseFormMethods['formState'];
    errors?: UseFormMethods['errors'];
    clearErrors?: UseFormMethods['clearErrors'];
    positionJdModel?: PositionJdModel;
    tempData?: IResumeAdditionInfoTempDto;
}

const TextInput: FC<IProfileAdditionInfoFormProps> = ({ info, idx }) => (
  <InputFrame>
    <Title>
      <Question>{info.question} {info.required && <RequiredTag>필수</RequiredTag>}</Question>
    </Title>
    <JDDescriptionInput
      name={`additionInfo[${idx}].answer`}
      placeholder='내용을 입력해주세요.'
      maxLength={info.maxAnswerSize || 300}
      autoFocus={false}
      className='desc-input'
      defaultValue={info.answer?.answer}
    />
  </InputFrame>
);
const RadioInput: FC<IProfileAdditionInfoFormProps> = ({ info, idx, errors }) => {
  const { setValue, watch } = useFormContext();
  const [selectedValue, setSelectedValue] = useState<string>('');
  const tmp = info.answer?.choices?.find((choice) => choice.selectYn)?.name || '';
  const handleChange = () => {

  };
  return (
    <InputFrame>
      <Title>
        <Question>
          <span className='question'>{info.question}</span>
          <span className='desc'>(단일 선택)</span>
          {info.required && <RequiredTag>필수</RequiredTag>}
        </Question>
      </Title>
      <ListForm>
        {info.choices?.map((choice, checkIdx) => (
          <li key={`choice-li-${choice}`}>
            <JDRadioInput
              type={JD_RADIO_INPUT_TYPE.ROUND_BOX}
              key={`radio-option-${choice}`}
              name={`additionInfo[${idx}].selectedChoice`}
              value={choice}
              label={choice}
              defaultValue={tmp}
            />
          </li>
        ))}
      </ListForm>
    </InputFrame>
  );
};

const CheckboxInput: FC<IProfileAdditionInfoFormProps> = ({ info, idx, formState, errors, clearErrors }) => {
  const selectedChoices = info.answer?.choices?.filter((choice) => choice.selectYn).map((choice) => choice.name) || [];
  const { setValue, watch } = useFormContext();
  return (

    <InputFrame>
      <Title>
        <Question>
          <span className='question'>{info.question}</span>
          <span className='desc'>(복수 선택)</span>
          {info.required && <RequiredTag>필수</RequiredTag>}
        </Question>
      </Title>
      <ListForm>
        {info.choices?.map((choice, checkIdx) => (
          <li key={`choice-li-${choice}`}>
            <JDCheckInput
              type={JD_CHECK_INPUT_TYPE.CHECK_BOX}
              key={`checkbox-option-${choice}-${checkIdx}`}
              name={`additionInfo[${idx}].choices.${checkIdx}`}
              value={choice}
              label={choice}
              defaultChecked={selectedChoices.includes(choice)}
            />
          </li>
        ))}
      </ListForm>
      <ErrorMessage
        errors={errors}
        name={`additionInfo[${idx}].choices`}
        render={({ message }) => <h4 className='message false'>{message}</h4>}
      />
    </InputFrame>
  );
};
const SelectInput: FC<IProfileAdditionInfoFormProps> = ({ info, idx, tempData }) => {
  const { watch, setValue } = useFormContext();

  // Get the current selected value from form state
  const selectedValue = watch(`additionInfo[${idx}].selectedChoice`) || [];
  const selectedChoices = info.answer?.choices?.filter((choice) => choice.selectYn).map((choice) => choice.name) || [];

  return (
    <InputFrame>
      <Title>
        <Question>{info.question}  {info.required && <RequiredTag>필수</RequiredTag>}</Question>
      </Title>
      <SpacingBlock vertical size={4} />
      <JDSelector
        selectTitle='내용을 선택해 주세요.'
        type={JDSelectorType.NORMAL}
        key={`selector-option-${info.additionInfoSn}`}
        name={`additionInfo[${idx}].selectedChoice`}
        defaultText={selectedChoices.length > 0 ? selectedChoices[0] : '내용을 선택해 주세요'}
        value={selectedValue}
        onChange={(selectedValueNew) => {
          setValue(`additionInfo[${idx}].choices`, selectedValueNew || '');
        }}
      >
        <select>
          <option value='' hidden>내용을 선택해 주세요.</option>
          {
            info.choices?.map((choice) => (
              <option key={`option-${choice}`} value={choice}>{choice}</option>
            ))
          }
        </select>
      </JDSelector>
    </InputFrame>
  );
};

const FileInput: FC<IProfileAdditionInfoFormProps> = ({ info, idx }) => (
  <InputFrame>
    <Title>
      <Question>{info.question} {info.required && <RequiredTag>필수</RequiredTag>}</Question>
    </Title>
    <FileFrame>
      {
      info.files?.map((file) => (
        <FileBox className='contents' key={file.fileUid} onClick={() => downloadFile(file.fileName, file.fileUid)}>
          <div>
            <Icon name='upload' size={16} color={colors.CG_70} />
            <span role='button' className='file-name'>{file.fileName}</span>
          </div>
        </FileBox>
      ))
        }
    </FileFrame>
    <JDFileUpload
      name={`additionInfo[${idx}].files`}
      placeholder='첨부파일을 추가해주세요.'
      addType
      accept={allFileAllowedExtensions}
      defaultValue={[]}
    />
  </InputFrame>
);

const ProfileAdditionInfoForm: FC<IProfileAdditionInfoFormProps > = ({ info, idx, formState, errors, positionJdModel = new PositionJdModel(), clearErrors }) => {
  switch (info.type) {
    case ResumeFormType.TEXT:
      return <TextInput info={info} idx={idx} errors={errors} />;
    case ResumeFormType.SINGLE_CHOICE:
      return <RadioInput info={info} idx={idx} errors={errors} />;
    case ResumeFormType.MULTI_CHOICE:
      return <CheckboxInput info={info} idx={idx} formState={formState} errors={errors} clearErrors={clearErrors} />;
    case ResumeFormType.DROPDOWN:
      return <SelectInput info={info} idx={idx} tempData={positionJdModel.tempAdditionalInfo?.additionInfo[idx]} errors={errors} />;
    case ResumeFormType.ADDITION_ATTACH_FILE:
      return <FileInput info={info} idx={idx} errors={errors} />;
    default:
      return <></>;
  }
};

export default inject(injectStore.positionJdModel)(observer(ProfileAdditionInfoForm));
