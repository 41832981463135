import ImgJoy from 'assets/_v2/search/img_joy_search_modal.png';
import { IconSearch } from 'consts/assets/icons/iconPages';
import RoutePaths from 'consts/RoutePaths';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import { injectStore } from 'models/store';
import GlobalSearchModel from 'models/_v2/GlobalSearchModel';
import PositionListModel from 'models/_v2/PositionListModel';
import React, { FC, KeyboardEvent, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import CommonUtils, { debounce } from 'utils/CommonUtils';
import { lineClampBox, ScrollHiddenMixin } from 'consts/_v2/_common/style/mixins';
import { ReactComponent as IconCorporate } from 'assets/_v2/search/icon_corporate.svg';
import Icon from '__designkit__/icon/Icon';
import GlobalSearchModal from 'components/_v2/search/GlobalSearchModal';

const Frame = styled.div`
  width: 100%;
`;

const StyledSearchInput = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 12px 16px;
  height: 48px;
  border: 1px solid ${colors.JOBDA_BLACK};
  border-radius: 4px;
  padding: 12px 16px 12px 12px;

  input[type=search] {
    border: none;
    outline: none;
    width: 100%;
    height: 40px;
    font: ${Fonts.B2_Medium};
    color:${colors.CG_90};
  }

  input[type=search]::placeholder {
    color:${colors.CG_60};
  }

`;

const StyledSearchResultFrame = styled.div`
    position: absolute;
    top: calc(env(safe-area-inset-top) + 128px);
    left: 0;
    z-index: 1001;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 16px;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    ${ScrollHiddenMixin()};
    background: ${colors.WHITE_100};

    .divider{
      width: 100%;
      height: 1px;
      border: 1px solid ${colors.CG_40};
      margin: 14px 0px;
    }
`;

const StyledSearchResultItem = styled.div`
  cursor: pointer;
  position:relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 0;

  .list-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left:8px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span{
    flex-basis: auto;
    white-space: pre;
    font: ${Fonts.B1_Medium_P};
    overflow: hidden;
    text-overflow: ellipsis;
    &.filter{
      color:${colors.G_150};
    }

  }

`;

interface IGlobalSearchInput {
  isOpen?: boolean;
  onSearch?: any;
  positionListModel?: PositionListModel;
  globalSearchModel?: GlobalSearchModel;
  context?: Context;

}

const GlobalSearchInput: FC<IGlobalSearchInput> = ({ isOpen, onSearch, context = new Context(), positionListModel = new PositionListModel(), globalSearchModel = new GlobalSearchModel() }) => {
  const history = useHistory();
  const [focused, setFocused] = React.useState(false);
  const [currentKeyword, setCurrentKeyword] = React.useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);
  const { search, pathname } = useLocation();
  const [openSearch, setOpenSearch] = React.useState<boolean>(false);
  const wrapperRef = useRef<any>(null);

  const useOutsideClicker = (ref: any) => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setFocused(false);
      }
    }
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  useOutsideClicker(wrapperRef);

  const init = async () => {
    const paramList = CommonUtils.parseQuery(search);
    if (paramList.keyword) {
      if (inputRef.current) inputRef.current!.value = paramList.keyword;
      setCurrentKeyword(paramList.keyword);
    }
  };

  useEffect(() => {
    init();
    return (() => {
      globalSearchModel.similarKeyword = null;
    });
  }, [search]);

  // useEffect(() => {
  //   if (isOpen) {
  //     debounce(() => inputRef.current?.focus(), 50);
  //   }
  // }, [isOpen]);

  const onChangeSearchText = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setFocused(true);
    const inputText = e.target.value;
    if (inputText.trim() === '') {
      globalSearchModel.similarKeyword = null;
      return;
    }
    debounce(async () => {
      await globalSearchModel.searchSimilarKeyword(inputText);
    }, 300);
  };

  return (
    <Frame>
      <StyledSearchInput>
        <IconSearch className='icon-search' fill={colors.JOBDA_BLACK} />
        <input
          ref={inputRef}
          maxLength={100}
          type='search'
          placeholder='기업, 포지션명을 검색해보세요.'
          onChange={onChangeSearchText}
          onFocus={() => setFocused(true)}
          onBlur={() => {
            // debounce(async () => {
            //   setFocused(false);
            // }, 300);
          }}
          onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
              if (inputRef.current?.value === '') return;
              if (inputRef.current?.value === currentKeyword) return;
              positionListModel.resetAll();
              history.push(RoutePaths.search_result(inputRef.current?.value));
              inputRef.current?.blur();
              onSearch && onSearch();
              setFocused(false);
              if (pathname !== RoutePaths.search_result()) context.previousPathname = pathname;
              e.preventDefault();
            }
          }}
        />
      </StyledSearchInput>
      {
        focused && globalSearchModel.similarKeyword && inputRef.current?.value && globalSearchModel.similarKeyword.positionKeywords.length > 0
        && (
          <StyledSearchResultFrame ref={wrapperRef}>
            {
              globalSearchModel.similarKeyword.companyKeywords.map((item) => (
                <StyledSearchResultItem
                  key={item}
                  onClick={() => {
                    if (inputRef.current) inputRef.current!.value = item;
                    history.push(RoutePaths.search_result(item));
                    if (pathname !== RoutePaths.search_result()) context.previousPathname = pathname;
                    onSearch && onSearch();
                    setFocused(false);
                  }}
                >
                  <Icon name='business' size={24} color={colors.CG_60} />
                  <div className='list-item'>
                    <span className='before'>{item.split(`${inputRef.current?.value}`, 2)[0]}</span>
                    <span className='filter'>{inputRef.current?.value}</span>
                    <span className='last'>{item.split(`${inputRef.current?.value}`, 2)[1]}</span>
                  </div>
                </StyledSearchResultItem>
              ))
            }
            {globalSearchModel.similarKeyword.positionKeywords.length !== 0 && globalSearchModel.similarKeyword.companyKeywords.length !== 0 && <div className='divider' />}
            {
              globalSearchModel.similarKeyword.positionKeywords.map((item) => (
                <StyledSearchResultItem
                  key={item.positionName}
                  onClick={() => {
                    if (inputRef.current) inputRef.current!.value = item.positionName;
                    positionListModel.resetAll();
                    history.push(RoutePaths.search_result(item.positionName));
                    if (pathname !== RoutePaths.search_result()) context.previousPathname = pathname;
                    onSearch && onSearch();
                    setFocused(false);
                  }}
                >
                  <Icon name='search' size={24} color={colors.CG_60} />
                  <div className='list-item'>
                    <span className='before'>{item.positionName.split(`${inputRef.current?.value}`, 2)[0]}</span>
                    <span className='filter'>{inputRef.current?.value}</span>
                    <span className='last'>{item.positionName.split(`${inputRef.current?.value}`, 2)[1]}</span>
                  </div>
                </StyledSearchResultItem>
              ))
            }
          </StyledSearchResultFrame>
        )
      }
      <GlobalSearchModal isOpen={openSearch} onClickClose={() => setOpenSearch(false)} />

    </Frame>
  );
};

export default inject(injectStore.positionListModel, injectStore.globalSearchModel, injectStore.context)(observer(GlobalSearchInput));
