import Logo from '__designkit__/Logo';
import { Button, ButtonMode } from '__designkit__/button/Button';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import SmsAPI from 'api/smsAPI';
import JDInput from 'components/_v2/join/JDInput';
import JDInputSelector from 'components/_v2/join/JDInputSelector';
import { Divider1G50 } from 'components/divider/Divider';
import RoutePaths from 'consts/RoutePaths';
import { UserAuthorityType } from 'consts/_v2/UserAuthorityType';
import { countryList } from 'consts/_v2/join/countryList';
import useToast from 'hooks/useToast';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import AuthorizeUtil from 'utils/AuthorizeUtil';
import { convertMsToMMSS } from 'utils/_v2/timeUtils';
import { windowOpenUtils } from 'utils/_v2/windowOpenUtils';
import { JobflexQuery } from './jobflex/JobflexLogin';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top));
  border-top: 8px solid ${colors.CG_90};
  background-color: ${colors.WHITE_100};

`;

const HeaderFrame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
  padding: 24px 16px;
  margin-bottom: 16px;
  
`;

const ViewFrame = styled.div`
    padding: 0 28px;
`;

const ContentFrame = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  padding: 0 4px;

  & h1 {
    font: ${Fonts.H4_Bold};
    color: ${colors.JOBDA_BLACK};
    text-align: center;
  }

    .content {
        font: ${Fonts.B2_Medium_P};
        color: ${colors.CG_70};

        b {
            font: ${Fonts.B2_Bold_P};
        }
    }

    a {
      text-decoration: underline;
    }
`;

const BtnFrame = styled.div`
  width: 100%;
  height: fit-content;
  padding: 0 16px;
  margin-bottom: 8px;
`;
const PhoneBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InnerButtonInput = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;

  > div.postFix {
    position: absolute;
    top: 7px;
    right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const InnerButton = styled.button`
  padding: 8px 16px;
  border-radius: 4px;
  background-color: ${colors.JOBDA_BLACK};
  font: ${Fonts.B3_Bold};
  color: ${colors.WHITE_100};

  &:active {
    background-color: ${colors.CG_70};
  }

  &:disabled {
    background-color: ${colors.CG_40};
    color: ${colors.CG_60};
  }
`;

const Timer = styled.div`
  margin-right: 12px;
  font: ${Fonts.B2_Medium};
  color: ${colors.ERROR};
`;

const Footer = styled.footer`
  position: fixed;
  bottom: env(safe-area-inset-bottom);
  height: fit-content;
  width: 100%;
  background-color: ${colors.WHITE_100};
`;

const UpdateMobilePage = ({ login = new Login() }) => {
  const history = useHistory();
  const [timer, setTimer] = useState<number>(0);
  const [isDiableSendBtn, setIsDiableSendBtn] = useState<boolean>(false);
  const timerRef = useRef<number>(0);

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      countryCode: '82',
      mobile: '',
      token: '',
    },
  });
  const { setToastObject } = useToast();
  const [sendMobile, setSendMobile] = useState<string>('');
  const [isVerificate, setIsVerificate] = useState<boolean>(false);
  const { getValues, watch, formState, reset, setError, trigger, setValue } = formMethods;
  const countryCode = watch('countryCode');
  const mobile = watch('mobile');
  const token = watch('token');
  const onClickSaveBtn = async () => {
    const rq = {
      mobile,
      countryCode,
    };
    try {
      await login.editUsersMobile(rq);
      await AuthorizeUtil.updateAccessToken(true);
      if (login.jobflexType === JobflexQuery.resume) {
        const url = `${login.jobflexResumeDomain}?systemKindCode=MRS2&jobnoticeSn=${login.jobflexJobnoticeSn}&accessToken=${login.jobflexAccessToken}`;
        login.jobflexType = '';
        login.jobflexResumeDomain = '';
        login.jobflexJobnoticeSn = 0;
        login.jobflexAccessToken = '';
        login.jobflexRedirectUrl = '';
        windowOpenUtils(url, '_self');
      } else if (login.jobflexType === JobflexQuery.mypage) {
        login.jobflexType = '';
        login.jobflexResumeDomain = '';
        login.jobflexJobnoticeSn = 0;
        login.jobflexAccessToken = '';
        login.jobflexRedirectUrl = '';
        const url = `${login.jobflexRedirectUrl}&accessToken=${login.jobflexAccessToken}`;
        windowOpenUtils(url, '_self');
      } else {
        await login.loadCurrentUser();
        history.replace(RoutePaths.root);
      }
    } catch (e) {
      const err = e as { response: any };
      if (err.response.data.errorCode === 'B903') {
        setToastObject({ isOpen: true, type: 'ERROR', message: '인증되지 않은 번호입니다.' });
      } else {
        setToastObject({ isOpen: true, type: 'ERROR', message: err.response.data.message });
      }
    }
  };
  useEffect(() => {
    if (!login.userInfo) history.replace(RoutePaths.root);
    if (!login.userInfo?.authorities.includes(UserAuthorityType.MOBILE_UPDATE_REQUIRED_BY_NULL)) {
      history.replace(RoutePaths.root);
    }
  }, [history, login.userInfo]);

  const { errors } = formState;
  const onClickSendBtn = async () => {
    if (countryCode && mobile && !(errors.countryCode || errors.mobile)) {
      try {
        setSendMobile(mobile);
        const res = await SmsAPI.sendVerificationSmsToUser({ countryCode, mobile });
        if (res) {
          window.clearTimeout(timerRef.current);
          setTimer(180000);
          setToastObject({ isOpen: true, type: 'SUCCESS', message: '인증번호가 발송되었습니다.' });
        } else setToastObject({ isOpen: true, type: 'ERROR', message: '인증번호가 발송에 실패했습니다.' });
      } catch (e) {
        const err = e as { response: any };
        setToastObject({ isOpen: true, type: 'ERROR', message: err.response.data.message });
      }
    } else setToastObject({ isOpen: true, type: 'INFO', message: '필수 값들을 모두 입력해 주세요.' });
  };

  const onClickCheckBtn = async () => {
    if (token.length !== 6) setToastObject({ isOpen: true, type: 'ERROR', message: '인증번호를 확인해 주세요.' });
    else if (token && !errors.token) {
      try {
        const res = await SmsAPI.chackVerificationSmsToUser({ countryCode, mobile, token });
        if (res) setIsVerificate(true);
        else {
          setIsVerificate(false);
          setError('token', { message: '인증에 실패했습니다.' });
          setToastObject({ isOpen: true, type: 'ERROR', message: '인증에 실패했습니다.' });
        }
      } catch (e: any) {
        const err = e as { response: any };
        setToastObject({ isOpen: true, type: 'ERROR', message: err.response.data.message });
      }
    }
  };
  useEffect(() => {
    if (mobile !== sendMobile && mobile.length > 9) {
      setTimer(0);
      setIsDiableSendBtn(false);
      setSendMobile('');
    }
    if (timer && !isVerificate && sendMobile) timerRef.current = window.setTimeout(() => setTimer(timer - 1000), 1000);
  }, [timer, isVerificate, mobile, sendMobile]);

  return (
    <FormProvider {...formMethods}>
      <Frame>
        <HeaderFrame>
          <Logo color='black' size={80} />
          <SpacingBlock size={24} vertical />
          <Divider1G50 />
        </HeaderFrame>
        <ViewFrame>
          <ContentFrame>
            <h1>기본정보를 업데이트 해주세요.</h1>
            <SpacingBlock size={16} vertical />
            <div className='content'>
              휴대폰 번호 인증이 완료되어야 지원을 포함한 모든 잡다 서비스를 이용할 수 있으며, <br />
              채용 관련 알림 및 전형 진행 안내를 받을 수 있습니다.
            </div>
            <SpacingBlock size={24} vertical />
            <PhoneBox>
              <JDInputSelector name='countryCode' label='국가' width='calc((100% - 12px) * .3)' options={countryList} />
              <InnerButtonInput style={{ width: 'calc((100% - 12px) * .7)' }}>
                <JDInput type='tel' name='mobile' label='휴대폰 번호' maxLength='11' inputMode='tel' />
                <div className='postFix'>
                  <InnerButton type='button' disabled={isDiableSendBtn} onClick={onClickSendBtn}>
                    { timer === 0 ? '인증' : '재인증' }
                  </InnerButton>
                </div>
              </InnerButtonInput>
            </PhoneBox>
            <SpacingBlock size={16} vertical />
            <InnerButtonInput>
              <JDInput type='string' name='token' label='인증번호' maxLength='6' successText={isVerificate ? '인증이 완료되었어요.' : ''} inputMode='numeric' />
              <div className='postFix'>
                { timer !== 0 && !isVerificate ? <Timer>{ convertMsToMMSS(timer) }</Timer> : null }
                <InnerButton type='button' disabled={token.length !== 6 || timer === 0 || isVerificate} onClick={onClickCheckBtn}>
                  확인
                </InnerButton>
              </div>
            </InnerButtonInput>

          </ContentFrame>

        </ViewFrame>
        <Footer>
          <BtnFrame>
            <Button label='완료' buttonMode={ButtonMode.PRIMARY} disabled={!isVerificate} size='large' onClick={onClickSaveBtn} />
          </BtnFrame>
        </Footer>
      </Frame>

    </FormProvider>
  );
};

export default inject(injectStore.login)(observer(UpdateMobilePage));
