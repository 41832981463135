import IComponentProps from 'interfaces/props/IComponentProps';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import ScrollUtil from 'utils/ScrollUtil';
import iconArrowUp from '../../assets/icon/icon_main_button_arrow_up.svg';

const Button = styled.button`
  transform:scale(0);
  width:fit-content;
  margin-left:auto;
  margin-right:16px;
  margin-bottom: ${((props) => (props as any)['data-offset'] || 0 + 16)}px;
  &[data-visible=true] {
    animation: popup 0.3s forwards;
  }
  &[data-visible=false] {
    animation: popdown 0.3s forwards;
  }
  @keyframes popup {
    0% { transform: scale(0); }
    80% { transform: scale(1.2); }
    100% { transform: scale(1); }
  }
  @keyframes popdown {
    0% { transform: scale(1); }
    20% { transform: scale(1.2); }
    100% { transform: scale(0); }
  }
  &.scroll-up-btn {
    width: 40px;
    height: 40px;
    background: #ffffff;
    border-radius: 50%;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    
    img {
      width: 20px;
      height: 20px;
    }
  }
`;

interface IScrollToTopButtonProps extends IComponentProps {
  bottomOffset?: number;
}

const ScrollToTopButton: FC<IScrollToTopButtonProps> = ((props) => {
  const { bottomOffset } = props;
  const [scrollToTopVisible, setScrollToTopVisible] = useState<boolean>(false);

  useEffect(() => {

    //TODO: app에서는 잘 동작하지 않을 수 있음. 그럴 경우에 PageFrame을 뜯어고쳐야함!
    const scrollHandler = () => {
      if (document.documentElement.scrollTop > 50)
        setScrollToTopVisible(true);
      else
        setScrollToTopVisible(false);
    };
    window.addEventListener('scroll', scrollHandler);
    return () => {
      window.removeEventListener('scroll', scrollHandler);
    }
  }, []);

  const scrollToTopHandler = () => {
    ScrollUtil.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Button className='scroll-up-btn' onClick={scrollToTopHandler} data-visible={scrollToTopVisible} data-offset={bottomOffset}>
      <img alt='위쪽 화살표' src={iconArrowUp} />
    </Button>
  );
});

export default ScrollToTopButton;
