import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import AdminMessageTemplate from 'components/_v2/chatting/AdminMessageTemplate';
import { AdminMetaData, ChattingServerError } from 'interfaces/_v2/chatting/IChattingRqRs';
import IComponentProps from 'interfaces/props/IComponentProps';
import { inject, observer } from 'mobx-react';
import ChattingModel from 'models/_v2/ChattingModel';
import { injectStore } from 'models/store';
import React, { FC, MouseEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Message, MessageTypeEnum } from 'talkplus-sdk';
import { convertMsToDate, convertMsToTime } from 'utils/_v2/timeUtils';
import Emoji from '__designkit__/icon/Emoji';
import { EmojiName } from 'interfaces/_v2/_common/emojiType';
import { ADMIN_USER_NAME } from 'consts/_v2/chatting/chatting';
import ChattingReactionModal from './ChattingReactionModal';
import ChattingReplyItem from './ChattingReplyItem';

const Frame = styled.div<{ isMine: boolean, isContinue: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ isMine }) => (isMine ? 'flex-end' : 'flex-start')};
  width: 100%;
  height: fit-content;
  padding: ${({ isContinue }) => (isContinue ? 0 : 8)}px 12px 8px;
  user-select: none;

  & > div.day {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 8px;
    
    & > div {
      flex-grow: 1;
      height: 1px;
      background-color: ${Colors.CG_50};
    }

    & > span {
      padding: 8px 8px;
      font: ${Fonts.B3_Bold};
      color: ${Colors.CG_60};
      background-color: ${Colors.CG_40};
    }
  }

  & > div.name {
    font: ${Fonts.B3_Bold};
    margin-bottom: 8px;
  }
`;

const Text = styled.div<{ isMine: boolean, isAdmin: boolean }>`
  display: flex;
  flex-direction: ${({ isMine }) => (isMine ? 'row-reverse' : 'row')}; ;
  align-items: flex-end;
  width: 100%;

  & > div.message {
    max-width: 80%;
    padding: 8px 16px;
    border-radius: ${({ isMine }) => (isMine ? '12px 2px 12px 12px' : '2px 12px 12px 12px')};
    background-color: ${({ isMine, isAdmin }) => (isMine ? Colors.G_200 : isAdmin ? Colors.G_30 : Colors.WHITE_100)};
    font: ${Fonts.B3_Medium};
    color: ${({ isMine }) => (isMine ? Colors.WHITE_100 : Colors.BLACK_100)};
    white-space: pre-wrap;
  }

  & > div.time {
    width: fit-content;
    min-width: fit-content;
    margin-bottom: 4px;
    font: ${Fonts.B4_Medium};
    color: ${Colors.CG_60};
  }
`;

const EmojiFrame = styled.div<{ isMine: boolean }>`
  display: flex;
  width: fit-content;
  height: fit-content;
  padding: ${({ isMine }) => (isMine ? '0 8px 0 0' : '0 0 0 8px')}; 
  transform: translateY(-4px);

  & > div:not(:last-child) {
    margin-right: 3px;
  }
`;

const EmojiItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  height: 26px;
  padding: 0 8px;
  border: 1px solid ${Colors.CG_40};
  border-radius: 16px;
  background-color: ${Colors.WHITE_100};
  font: ${Fonts.B3_Medium};

  &.my-reaction {
    border: 1px solid${Colors.CG_70};
    font: ${Fonts.B3_Bold};
  }

  & > span {
    margin-left: 2px;
  }
`;

interface IChattingItemProps extends IComponentProps {
  item: Message;
  isMine: boolean;
  isDayFirst: boolean;
  isContinue: boolean;
  chattingModel?: ChattingModel;
}

const ChattingItem: FC<IChattingItemProps> = ({ item, isMine, isDayFirst, isContinue, chattingModel = new ChattingModel() }) => {
  const [metaData] = useState<AdminMetaData | undefined>(item.data as unknown as AdminMetaData);
  const [myReaction, setMyReaction] = useState<string>('');
  const [isOpenReactionModal, setIsOpenReactionModal] = useState<boolean>(false);
  const messageRef = useRef<HTMLDivElement>(null);
  const touchTimer = useRef<number | null>(null);

  const checkIsAdmin = () => item.type === MessageTypeEnum.Admin || item.type === MessageTypeEnum.AdminHidden;

  const onTouchStartChannelItem = () => {
    touchTimer.current = window.setTimeout(() => {
      touchTimer.current = null;
      setIsOpenReactionModal(true);
    }, 500);
  };

  const onTouchEndChannelItem = () => {
    if (touchTimer.current) {
      window.clearTimeout(touchTimer.current);
      touchTimer.current = null;
    }
  };

  const onClickEmoji = async (event: MouseEvent<HTMLDivElement>) => {
    const emojiType = event.currentTarget.dataset.emoji;
    if (!emojiType) return;
    try {
      if (myReaction === emojiType) {
        // 이미 선택한 이모지를 다시 선택한 경우
        const { message } = await chattingModel.client.removeMessageReaction({ channelId: item.channelId, messageId: item.id, reaction: emojiType });
        chattingModel.updateMessage(message);
      } else if (myReaction) {
        // 다른 이모지를 선택한 경우
        await chattingModel.client.removeMessageReaction({ channelId: item.channelId, messageId: item.id, reaction: myReaction });
        const { message } = await chattingModel.client.addMessageReaction({ channelId: item.channelId, messageId: item.id, reaction: emojiType });
        chattingModel.updateMessage(message);
      } else {
        const { message } = await chattingModel.client.addMessageReaction({ channelId: item.channelId, messageId: item.id, reaction: emojiType });
        chattingModel.updateMessage(message);
      }
    } catch (e) {
      const err = e as ChattingServerError;
      console.error(err);
    }
  };

  useEffect(() => {
    if (item.reactions) {
      setMyReaction('');
      Object.keys(item.reactions).forEach((reaction) => {
        if (item.reactions && item.reactions[reaction].includes(chattingModel.user!.id)) {
          setMyReaction(reaction);
        }
      });
    } else {
      setMyReaction('');
    }
  }, [chattingModel.user, item]);

  return (
    <Frame isMine={isMine} isContinue={isContinue} onContextMenu={(e) => { e.preventDefault(); }}>
      { isDayFirst && (
        <div className='day'>
          <div />
          <span>{ convertMsToDate(item.createdAt) }</span>
          <div />
        </div>
      )}
      { isMine || isContinue ? null : <div className='name'>{ checkIsAdmin() ? ADMIN_USER_NAME : item.username}</div> }
      <Text isMine={isMine} isAdmin={checkIsAdmin()}>
        <div className='message' onTouchStart={onTouchStartChannelItem} onTouchEnd={onTouchEndChannelItem} ref={messageRef}>
          { item.parentMessageId
            ? <ChattingReplyItem item={item} isMine={isMine} />
            : checkIsAdmin() && metaData
              ? <AdminMessageTemplate metaData={metaData} item={item} />
              : item.text }
        </div>
        <SpacingBlock size={4} />
        <div className='time'>{ convertMsToTime(item.createdAt) }</div>
      </Text>
      { item.reactions && (
        <EmojiFrame isMine={isMine}>
          { item.reactions && Object.keys(item.reactions).map((reaction) => (
            <EmojiItem key={reaction} data-emoji={reaction} className={myReaction === reaction ? 'my-reaction' : ''} onClick={onClickEmoji}>
              <Emoji name={reaction as EmojiName} size={18} />
              { item.reactions![reaction].length > 1 && <span>{ item.reactions![reaction].length }</span> }
            </EmojiItem>
          ))}
        </EmojiFrame>
      )}
      { isOpenReactionModal && <ChattingReactionModal messageItem={item} messageRef={messageRef} myReaction={myReaction} onClickClose={() => setIsOpenReactionModal(false)} /> }
    </Frame>
  );
};

export default inject(injectStore.chattingModel)(observer(ChattingItem));
