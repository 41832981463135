import { IonApp } from '@ionic/react';
import IContextPageProps from 'interfaces/props/IContextPageProps';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import { FC } from 'react';
import MobileStore from 'store/mobileStore';
import styled from 'styled-components/macro';

const Frame = styled.div`
  height:100%;
`;

const CustomIonApp = styled(IonApp)<{isNotch:boolean}>`
  position: fixed;
  padding-top: ${(props) => (props.isNotch ? `` : `env(safe-area-inset-top)`)} ;
`;

const AppFrame:FC<IContextPageProps> = ({ context = new Context(), ...props }) => (
  !MobileStore.isMobile ? <Frame {...props} /> : <CustomIonApp isNotch={context.iPhoneNotch} {...props} />
);

export default inject('context')(observer(AppFrame));
