import PositionList from 'components/_v2/position/PositionList';
import GlobalSearchCompanyList from 'components/_v2/search/GlobalSearchCompanyList';
import GlobalSearchInput from 'components/_v2/search/GlobalSearchInput';
import MainHeader from 'components/_v2/_common/mainHeader/MainHeader';
import RoutePaths from 'consts/RoutePaths';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import { injectStore } from 'models/store';
import GlobalSearchModel from 'models/_v2/GlobalSearchModel';
import PositionListModel from 'models/_v2/PositionListModel';
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import CommonUtils from 'utils/CommonUtils';
import GlobalSearchModal from 'components/_v2/search/GlobalSearchModal';

const Frame = styled.div`
  .title{
    font: ${Fonts.H5_Bold};
    color:${colors.JOBDA_BLACK};
    margin-left: 16px;
    margin-top: 28px;
  }
`;

export interface IGlobalSearchResult {
  positionListModel?: PositionListModel;
  globalSearchModel?: GlobalSearchModel;
  context?: Context;
}

const GlobalSearchResult: FC<IGlobalSearchResult> = ({ context = new Context(), positionListModel = new PositionListModel(), globalSearchModel = new GlobalSearchModel() }) => {
  const history = useHistory();
  const { search } = useLocation();
  const init = async () => {
    const paramList = CommonUtils.parseQuery(search);
    if (paramList.keyword) {
      await globalSearchModel.searchCompanyList(paramList.keyword);
    }
  };

  useEffect(() => {
    init();
  }, [search]);

  useEffect(() => () => {
    positionListModel.resetAll();
  }, []);
  return (
    <>
      <MainHeader
        close
        gnb={false}
        search={false}
        alarm={false}
        onClose={() => {
          if (context.previousPathname !== '') history.push(context.previousPathname);
          else history.push(RoutePaths.root);
        }}
      />
      <Frame>
        <GlobalSearchInput />
        {globalSearchModel.companies && globalSearchModel.companies?.length > 0
          && (
            <>
              <div className='title'>{positionListModel.searchFilters.keyword}에 대한 {globalSearchModel.companies?.length}개의 기업 검색 결과입니다.</div>
              <GlobalSearchCompanyList />
            </>
          )}
        <div className='title'>{positionListModel.searchFilters.keyword}에 대한 {positionListModel.searchPositions?.pages.totalElements || 0}개의 포지션 검색 결과입니다.</div>
        <PositionList />

      </Frame>
    </>
  );
};

export default inject(injectStore.positionListModel, injectStore.globalSearchModel, injectStore.context)(observer(GlobalSearchResult));
