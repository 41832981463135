import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import IconInfo from 'assets/_v2/_common/icon_information_circle_15_Regular_grey.svg';
import ImgJoyEmpty from 'assets/_v2/challenge/img_character_joy_empty_soon.png';
import IconResults from 'assets/_v2/phs/icon_phs_results.svg';
import ImgBlock from 'assets/_v2/phs/pst/img_pst_block_dummy.png';
import JDFullModal from 'components/_v2/_common/modals/JDFullModal';
import JDSimpleInfoModal from 'components/_v2/_common/modals/JDSimpleInfoModal';
import AccPstGrade from 'components/_v2/acc/AccPstGrade';
import PstResultContent from 'components/_v2/phs/pst/PstResultContent';
import ErrorCode from 'consts/ErrorCodes';
import { PhsAccType } from 'consts/MatchingMessageType';
import RoutePaths from 'consts/RoutePaths';
import SupportMail from 'consts/_v2/GlobalText';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import PstResultsModel from 'models/_v2/PstResultsModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';

const TitleFrame = styled.div`
  width: 100%;
  height:56px;
  display: flex;
  padding-top:17px;
  justify-content:center;
  box-shadow: inset 0px -0.5px 0.5px rgba(207, 209, 213, 0.5);

  .header-title{
    font: ${Fonts.H5_Bold};
    color:${colors.JOBDA_BLACK};
  }
`;

const ContentFrame = styled.div`
  padding:16px;
`;

const ResultsBox = styled.div`
  width:100%;
  height:84px;
  display: flex;
  padding: 18px 16px;
  justify-content: space-between;
  border-radius: 4px;
  background:${colors.JOBDA_BLACK};
  align-items: center;
`;

const NameContent = styled.div` 
  display:flex;

  .name-text{
    align-self: center;
    margin-left: 18px;
    font: ${Fonts.B1_Bold};
    line-height: 21px;
    color:${colors.WHITE_100};
  }

  .icon-results{
    width:48px;
    height:48px;
  }
`;

const CopyRightFrame = styled.div`
  display: flex;
  padding:0 16px 24px 16px;
  color:${colors.CG_60};
  position: absolute;
  padding-bottom: calc(40px + env(safe-area-inset-bottom));

  .icon-info{
    width:16px;
    height:16px;
    margin-right: 6px;
  }

  .text-info{
    font: ${Fonts.B2_Medium};
  }
`;

const ErrorFrame = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  width:100%;
  height:85vh;

  .joy-img{
    width:100px;
    height:100px;
  }

  .joy-text{
    margin-top: 32px;
    font: ${Fonts.H5_Bold};
    line-height: 23px;
    text-align:center;
  }

  .link-text{
    margin-top: 16px;
    font: ${Fonts.B2_Medium};
    color:${colors.B_90};
    text-decoration:underline;
    text-align:center;
  }
`;

const BlockFrame = styled.div<{isDummy?:boolean}>`
  width: 100%;
  height:100%;
  ${(props) => (props.isDummy ? css`
    background-image: url(${ImgBlock});
  ` : css`
    background-color: ${colors.WHITE_100};
  `)}
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
`;

interface IPstResultModal{
  isOpen?:boolean;
  onClose?:() => void;
  sn:number;
  pstResultsModel?: PstResultsModel;
  login?:Login;
  type:PhsAccType;
}

const PstResultModal :FC<IPstResultModal> = ({ sn, isOpen, onClose, login = new Login(), type, pstResultsModel = new PstResultsModel() }) => {
  const [errorState, setErrorState] = useState<number>(0);
  const [isRecommendServiceBlock, setIsRecommendServiceBlock] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const checkRecommendService = async () => {
    await login.getMatchConfigStatus();
    const { matchingConfigYn } = login.matchConfigStatus || {};
    const { profileRequiredCompleteYn } = login.matchConfigStatus?.matchingConfigCondition || {};

    if (!matchingConfigYn || !profileRequiredCompleteYn) {
      setIsRecommendServiceBlock(true);
    }
  };

  const init = async () => {
    if (isOpen && sn && sn !== 0 && type === PhsAccType.PST) {
      try {
        setLoading(true);
        await checkRecommendService();
        await pstResultsModel.loadPstResults(sn);
        setLoading(false);
      } catch (e: any) {
        setLoading(false);
        switch (e.response?.data?.errorCode) {
          case ErrorCode.B901:
            setErrorState(901);
            break;
          case ErrorCode.B902:
            setErrorState(902);
            break;
          case ErrorCode.B903:
            setErrorState(903);
            break;
          default:
            break;
        }
      }
    }
  };

  useEffect(() => {
    init();
  }, [sn, isOpen]);

  return (
    !isOpen ? <></> : (
      <JDFullModal handleClose={onClose}>
        <TitleFrame><div className='header-title'>결과표 확인</div></TitleFrame>
        {pstResultsModel.pstResults && errorState === 0 ? (
          <div>
            <ContentFrame>
              <ResultsBox>
                <NameContent>
                  <img src={IconResults} className='icon-results' alt='' />
                  <div className='name-text'>{login.userInfo?.name}님의 달성 등급</div>
                </NameContent>
                { !isRecommendServiceBlock && <AccPstGrade pstGrade={pstResultsModel.pstResults.grade} type={PhsAccType.PST} /> }
              </ResultsBox>
            </ContentFrame>
            { isRecommendServiceBlock ? <BlockFrame isDummy /> : (pstResultsModel.pstResults && <PstResultContent />) }
          </div>
        ) : !loading ? (
          <div>
            <ErrorFrame>
              <img src={ImgJoyEmpty} alt='' className='joy-img' />
              { errorState === 901 ? <div className='joy-text'>응시 결과가 삭제되어 <br /> 확인이 불가능해요.</div>
                : errorState === 902 ? <div className='joy-text'> 응시 결과를 분석중이에요! <br /> 결과 분석은 최대 1일이 <br /> 소요될 수 있어요.</div>
                  : <div className='joy-text'>   응시 결과 분석에 실패했어요.<br /> 오류가 지속될 경우 <br /> 헬프데스크로 문의 해 주세요.</div> }
              { errorState === 903 && <a className='link-text' href={`mailto:${SupportMail}`}>{SupportMail}</a> }
            </ErrorFrame>
          </div>
        ) : <></>}

        <CopyRightFrame>
          <img src={IconInfo} className='icon-info' alt='' />
          <div className='text-info'>검사 리포트는 잡다의 소유이며, 무단 재배포 시 법적 불이익을 받을 수 있습니다.</div>
        </CopyRightFrame>
        <JDSimpleInfoModal
          title={`프로필을 입력하면\n응시 분석결과를 볼 수 있어요.`}
          descriptionsTag={(
            <h1 className='descriptionsTag'>
              <button
                onClick={() => {
                  history.push(RoutePaths.profile);
                  setIsRecommendServiceBlock(false);
                  if (onClose) onClose();
                }}
              >
                [내 프로필]에서
              </button> 기본, 선호, 학력만 입력하면 끝!<br />
              <button
                onClick={() => {
                  history.push(RoutePaths.profile);
                  setIsRecommendServiceBlock(false);
                  if (onClose) onClose();
                }}
              >잡다매칭 신청
              </button> 상태가 ON이어야 합니다.
            </h1>
        )}
          btnText='1분만에 프로필 입력하기'
          isOpen={isRecommendServiceBlock}
          isDimClickClose
          handleClick={() => history.push(RoutePaths.profile)}
          onClickClose={() => {
            setIsRecommendServiceBlock(false);
            if (onClose) onClose();
          }}
        />
      </JDFullModal>

    ));
};

export default inject(injectStore.pstResultsModel, injectStore.login)(observer(PstResultModal));
