import classnames from 'classnames/bind';
import ko from 'date-fns/locale/ko';
import React, { useState } from 'react';
import ReactDatePicker, { ReactDatePickerCustomHeaderProps, ReactDatePickerProps } from 'react-datepicker';
import styled from 'styled-components';
import style from './datePicker.module.scss';
import Header from './Header';

const cx = classnames.bind(style);

export type PickerType = 'DAY' | 'MONTH';
export type ModeType = 'DAY' | 'MONTH' | 'YEAR';

type DatePickerProps = ReactDatePickerProps & {};
type setModeType = (mode: ModeType | null) => void;

const isMode = (mode: ModeType | null, modeType: ModeType) => mode === modeType;

const handleClickOutside = (setMode: setModeType) => setMode(null);

const renderDayContents = (dayOfMonth: number, date?: Date) => (
  <span className={`day ${date?.getDay() === 0 && 'sunday'}`}>{dayOfMonth}</span>
);

const renderCustomHeader = (
  props: ReactDatePickerCustomHeaderProps,
  mode: ModeType | null,
  setMode: setModeType,
  pickerType: PickerType,
) => <Header mode={mode} setMode={setMode} pickerType={pickerType} {...props} />;

const CalenderFrame = styled.div`
`;
const DatePicker = (props: DatePickerProps) => {
  const [mode, setMode] = useState<ModeType | null>(null);

  const handleInputClick = () => setMode('DAY');

  const handleSelect = () => {
    switch (mode) {
      case 'YEAR':
      case 'MONTH':
        setMode('DAY');
        break;
      case 'DAY':
        setMode(null);
        break;
    }
  };

  return (
    <div className={cx('wrap')}>
      <ReactDatePicker
        open={mode !== null}
        locale={ko}
        dateFormat='yyyy. MM. dd'
        renderDayContents={renderDayContents}
        renderCustomHeader={(props) => renderCustomHeader(props, mode, setMode, 'DAY')}
        showYearPicker={isMode(mode, 'YEAR')}
        showMonthYearPicker={isMode(mode, 'MONTH')}
        disabledKeyboardNavigation
        showFourColumnMonthYearPicker
        shouldCloseOnSelect={false}
        showPopperArrow={false}
        yearItemNumber={12}
        onInputClick={handleInputClick}
        onSelect={handleSelect}
        onClickOutside={() => handleClickOutside(setMode)}
        disabled={props.disabled}
        readOnly
        {...props}
      />
    </div>

  );
};

const MonthPicker = (props: DatePickerProps) => {
  const [mode, setMode] = useState<ModeType | null>(null);

  const handleInputClick = () => setMode('MONTH');

  const handleSelect = () => {
    switch (mode) {
      case 'YEAR':
        setMode('MONTH');
        break;
      case 'MONTH':
        setMode(null);
        break;
    }
  };

  return (
    <div className={cx('wrap')}>
      <CalenderFrame>
        <ReactDatePicker
          open={mode !== null}
          locale={ko}
          dateFormat='yyyy. MM.'
          renderDayContents={renderDayContents}
          renderCustomHeader={(props) => renderCustomHeader(props, mode, setMode, 'MONTH')}
          showYearPicker={isMode(mode, 'YEAR')}
          showMonthYearPicker={isMode(mode, 'MONTH')}
          disabledKeyboardNavigation
          showFourColumnMonthYearPicker
          shouldCloseOnSelect={false}
          showPopperArrow={false}
          yearItemNumber={12}
          onInputClick={handleInputClick}
          onSelect={handleSelect}
          onClickOutside={() => handleClickOutside(setMode)}
          disabled={props.disabled}
          readOnly
          {...props}
        />
      </CalenderFrame>
    </div>

  );
};

export { DatePicker, MonthPicker };
