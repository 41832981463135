import fireCrackerLottie from '__designkit__/assets/lottie/lottie_firecracker.json';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import IComponentProps from 'interfaces/props/IComponentProps';
import Lottie from 'lottie-react';
import React, { FC } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;

  & .title {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    height: fit-content;

    & .sub {
      font: ${Fonts.B2_Medium};
      color: ${Colors.G_200};

      & > span {
        font: ${Fonts.B2_Bold};
      }
    }

    & .main {
      font: ${Fonts.H4_Bold};
      color: ${Colors.CG_90};
    }
  }

  & .lottie {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    width: 100%;

    & > div {
      width: 80%;
    }
  }
`;

interface ICompleteProps extends IComponentProps {
  count: number;
}

const Complete: FC<ICompleteProps> = ({ count }) => (
  <Frame>
    <div className='title'>
      <div className='sub'>
        축하해요!<br />
        <span>총 {count}개의 경력</span>이 등록되었어요. 🎉
      </div>
      <div className='main'>
        불러온 프로필 정보를 상세히 작성하고,<br />
        인사 담당자에게 더 많은 제안을 받아보세요!
      </div>
    </div>
    <div className='lottie'>
      <Lottie animationData={fireCrackerLottie} />
    </div>
  </Frame>
);

export default Complete;
