import FindIdForm from 'components/findAccount/FindIdForm';
import FindIdResultPage from 'components/findAccount/FindIdResultPage';
import Verification from 'components/_v2/verification/Verification';
import useToast from 'hooks/useToast';
import RoutePaths from 'consts/RoutePaths';
import { keyFrameFadeIn } from 'consts/style/mixins';
import useGoBack from 'hooks/useGoBack';
import useVerification from 'hooks/useVerification';
import { inject, observer } from 'mobx-react';
import FindAccountModel, { FIND_ID_STEP } from 'models/FindAccountModel';
import { injectStore } from 'models/store';
import { FindAccountPage, IFindAccountProps } from 'pages/FindAccount';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import Login from 'models/Login';

export const FindAccountFrame = styled.div`
  height:100%;
  padding: 32px 20px;

  .none{
    display:none;
  }
  
  ${keyFrameFadeIn()};
`;

const FindId: React.FC<IFindAccountProps> = ({ login = new Login(), findAccountModel = new FindAccountModel() }) => {
  const useformed = useForm({
    mode: 'onSubmit',
    defaultValues: {
      name: '',
      birthDate: '',
      genderFlag: '',
      countryCode: '82',
      mobile: '',
      token: '',
    },
  });
  const { currentFindIdStep } = findAccountModel;
  const { setToastObject } = useToast();
  const verify = useVerification();

  useEffect(() => {
    if (login?.userInfo) history.replace(RoutePaths.root);
  }, []);

  useEffect(() => {
    findIdIpin();
  }, [verify]);

  const findIdIpin = async () => {
    if (verify) {
      // IPIN인증 코드로 바로 아이디 결과 페이지 이동
      try {
        await findAccountModel.findIdInfoIPIN(verify.identificationToken);
        findAccountModel.nextFindIdStep(FIND_ID_STEP.RESULT);
      } catch (e) {
        setToastObject({ isOpen: true, type: 'ERROR', message: '아이디 찾기에 실패했습니다.', subMessage: undefined });
      }
    }
  };
  const history = useHistory();
  const handleClose = () => {
    history.push(RoutePaths.login);
  };
  useGoBack(handleClose);

  return (
    <FormProvider {...useformed}>
      <FindAccountFrame>
        <FindIdForm className={currentFindIdStep === FIND_ID_STEP.FORM ? '' : 'none'} />
        <FindIdResultPage className={currentFindIdStep === FIND_ID_STEP.RESULT ? '' : 'none'} />
      </FindAccountFrame>
      <Verification
        handlePassVerification={() => findAccountModel.nextFindIdStep(FIND_ID_STEP.FORM)}
        returnUrl={RoutePaths.findAccount(FindAccountPage.ID)}
        className={currentFindIdStep === 0 ? '' : 'none'}
        content='찾으실 방법을 선택해 주세요.'
        mobileText='휴대폰으로 찾기'
        ipinText='아이핀으로 찾기'
      />
    </FormProvider>
  );
};

export default inject(injectStore.login, injectStore.findAccountModel)(observer(FindId));
