import React from 'react';

import classnames from 'classnames/bind';
import styles from './jobdaMatchBanner.module.scss';

const cx = classnames.bind(styles);

const JobdaMatchBanner = () => (
  <div className={cx('wrap')}>
    <div className={cx('sectionTitleArea')}>
      <strong className={cx('sectionTitle')}>챌린지 플러스<br />잡다매칭 참여하기</strong>
      <p className={cx('sectionDesc')}>
        프로필 작성과 역량검사 응시로
        <br />
        나와 딱맞는 기업에서 면접 제안을 받을 수 있어요
      </p>
    </div>
  </div>
);

export default JobdaMatchBanner;
