import React from 'react';

import classnames from 'classnames/bind';
import JDALink from 'components/JDALink';
import RoutePaths from 'consts/RoutePaths';
import styles from './participate.module.scss';

const cx = classnames.bind(styles);

const Participate = () => (
  <div className={cx('participateArea')}>
    <div className={cx('sectionTitleArea')}>
      <div className={cx('imgParticipate')} />
      <div className={cx('bannerInner')}>
        <p className={cx('bannerTitle')}>"디챔스 참가 방법, 참 쉬워요"</p>
      </div>
    </div>
    <div className={cx('sectionTitleArea')}>
      <strong className={cx('sectionTitle')}>
        D:CHAMPS 참가 방법
        <br />
        <span className={cx('emphasis')}>9월 1일 ~ 9월 27일</span>
        <br />
        기간 내 자유롭게 참여
      </strong>
    </div>

    <JDALink
      to={RoutePaths.phs_test}
      className={cx('detailInfoInner', 'dch_landing_entered_img')}
    />
    <div className={cx('imgText')}>
      개발자 역량검사(역량검사) / 개발 구현 능력 검사는
      <br />
      JOBDA 개발자검사 페이지에서 응시할 수 있어요.
      <br />
      디챔스 참가하기 버튼을 누르면,
      <br />
      개발자검사 페이지로 바로 이동합니다.
    </div>
  </div>
);

export default Participate;
