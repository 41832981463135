import { INameCodeRs } from 'interfaces/rqrs/ICommonRqRs';

export enum SkillLevel {
  BASIC = 'BASIC',
  BEGINNER = 'BEGINNER',
  MIDDLE = 'MIDDLE',
  ADVANCED = 'ADVANCED',
  PROFESSIONAL = 'PROFESSIONAL',
}

export enum SkillLevelText {
  BASIC = '기초',
  BEGINNER = '초급',
  MIDDLE = '중급',
  ADVANCED = '고급',
  PROFESSIONAL = '전문가',
}

export interface IProfileExaminationDto extends INameCodeRs {
  grade: string; // "superior"
  gradeYn: boolean;
  score: number;
  scoreYn: boolean;
  gradeCode: number;
}

export interface IProfileLicenseDto extends INameCodeRs {}

export interface IProfilePortfolioAttachFileDto {
  fileName: string;
  fileUid: string;
}

export interface IProfileSkillDto {
  level: SkillLevel;
  code: number;
  keyword:string;
}

export default interface IProfileKnowledgeAndSkillsRs {
  examinations: IProfileExaminationDto[];
  licenses: IProfileLicenseDto[];
  skills: IProfileSkillDto[];
};
