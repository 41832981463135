export const iframePinchZoomUtil = (content: string) => (`
        <!DOCTYPE html>
        <html lang='ko'>
        <head>
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
          <meta http-equiv="Content-Language" content="ko-KR">
          <meta http-equiv="X-UA-Compatible" content="IE=edge">
          <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests">
          <meta name="viewport"
            content="width=device-width, initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0,user-scalable=no,target-densitydpi=medium-dpi">
          <style type="text/css">
              table {
                white-space:nowrap; 
              }
          </style>
          <script src="https://cdn.jsdelivr.net/npm/pinch-zoom-js@2.3.5/dist/pinch-zoom.umd.min.js"></script>
        </head>
        <body>
            <div class='pinch-zoom'>
              ${content}
            </div>
            <script>
            //모든 a태그 액션을 window open으로 변경
              document.querySelectorAll('a[target="_blank"]').forEach(anchor => {
      anchor.addEventListener('click', function(event) {
        event.preventDefault();
        window.open(this.href);
      });
    });
            //window load 후 실행해야 이미지 높이 파악 가능
            window.addEventListener('load', function () {
            const pinchElement = document.querySelector('.pinch-zoom');
            const contentHeight=window.getComputedStyle(pinchElement).getPropertyValue('height');

            //pinchInstance 한번 만들면 pinchContainer div가 생성되는데 이것을 pinchParent로 활용
            const pinchInstance=new PinchZoom.default(pinchElement, {
              draggableUnzoomed: false,
              minZoom: 1,
              setOffsetsOnce:true,
        
            });

            //강제로 만들어준 pinchContainer(pinchParent의 역할을 하는 div)의 style 초기화 및 높이 설정
            const pinchContainerElement = document.querySelector('.pinch-zoom-container');
            
            // scale 된 height값 지정.
            const numericPartH = parseFloat(contentHeight);
            const padding = 16; // Padding value
            const windowWidth = window.innerWidth;
            const scale = (windowWidth - padding) / pinchElement.offsetWidth;
            const transHeight = scale * numericPartH;
            const transHeightPx = transHeight + 'px';

            pinchContainerElement.style=null;
            pinchContainerElement.style.height= transHeightPx;

            //PinchZoom 재선언으로 기능동작하는 pinchContainer 생성 
            new PinchZoom.default(pinchElement, {
              draggableUnzoomed: false,
              tapZoomFactor: 1,
              zoomOutFactor: 1,
              minZoom: 1,
              setOffsetsOnce:true,
            }
            );
          });
        </script>
        </body>
        </html>`
);

export default ({ iframePinchZoomUtil });
