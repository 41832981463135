import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import JDSwiper, { JDBullets, JDSwiperSlide } from 'components/_v2/_common/JDSwiper';
import JDSelector, { JDSelectorType } from 'components/_v2/_common/input/JDSelector';
import MainNoPosition from 'components/_v2/main/MainNoPosition';
import { MatchingCompanyCard } from 'components/_v2/positionJd/PositionJdMatchingCompany';
import { Divider12Grey } from 'components/divider/Divider';
import RoutePaths from 'consts/RoutePaths';
import { IPositionCompanyCardDto } from 'interfaces/_v2/positionJd/IPositionCompanyCardListRs';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import Main from 'models/_v2/Main';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const RootFrame = styled.div`
    width:100%;
`;

const Frame = styled.div`
  padding:0 16px;
`;

const TitleFrame = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px 0 16px 0;
    
    .title{
        font: ${Fonts.H5_Bold};
        color:${colors.JOBDA_BLACK};
        width: 288px;
        word-break: break-all;
    }
    .more-text{
        display: flex;
        align-items: center;
        font: ${Fonts.B3_Medium};
        color:${colors.JOBDA_BLACK};
    }
`;

const MainPositionFrame = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 12px;

    .pagination-bullets{
      margin: 4px 0 32px 0;
    }
    .main-custom-slide{
      width:calc(100vw - 32px);
      padding:0 16px;
  }
`;
interface IMainPopularPosition {
  main? : Main
  context?: Context;
}
const MainPopularPosition:FC<IMainPopularPosition> = ({ context = new Context(), main = new Main() }) => {
  const [mainPositions, setMainPositions] = useState<any[]>([]);
  const [jobCode, setJobCode] = useState<number>(1);
  const useFormed = useForm();

  const { watch } = useFormed;
  useEffect(() => {
    if (main.positions && main.positions.length > 0) {
      const realDummy:any[] = [];
      let tmpDummy:any[] = [];
      main.positions.forEach((item, idx) => {
        if (idx % 4 === 0 && idx !== 0) {
          realDummy.push(tmpDummy);

          tmpDummy = [];
        }
        tmpDummy.push(item);
      });
      if (tmpDummy.length > 0) {
        realDummy.push(tmpDummy);
      }
      setMainPositions(realDummy);
    }
  }, [main.positions]);

  useEffect(() => {
    const loadPositions = async () => {
      await main.loadPositionJobGroups(jobCode, 8, true);
    };
    loadPositions();
  }, [jobCode, main]);

  return (
    <RootFrame>
      <FormProvider {...useFormed}>
        <Frame>
          <TitleFrame>
            <div className='title'>직군별 추천 공고</div>
            <Link to={RoutePaths.position}>
              <div className='more-text'>전체보기 <Icon name='arrow-right' size={24} color={colors.JOBDA_BLACK} /></div>
            </Link>
          </TitleFrame>
          {context.jobGroupsWithTitles && context.jobGroupsWithTitles.length > 0
          && (
          <JDSelector
            selectTitle='직군 · 직무'
            name='orderType'
            type={JDSelectorType.BIG}
            value={watch('orderType')}
            onChange={async () => {
              const value = watch('orderType');
              setJobCode(value);
            }}
          >
            <select>
              {
              context.jobGroupsWithTitles.map((jobGroup, groupId) => (
                <option key={`${groupId}-${jobGroup.code}`} value={jobGroup.code}>{jobGroup.name}</option>
              ))
            }
            </select>
          </JDSelector>
          )}
        </Frame>

        {mainPositions && mainPositions.length > 0
          ? (
            <MainPositionFrame>

              <JDSwiper
                slidesPerView={1}
                pagination={JDBullets}
                navigation
              >
                {mainPositions && mainPositions.length > 0
                      && mainPositions.map((item, idx) => (
                        <JDSwiperSlide className='main-custom-slide' key={`${idx}-slide`}>
                          {item.map((innerItem:IPositionCompanyCardDto) => (
                            <Link to={RoutePaths.position_jd(innerItem.positionSn)} key={`${item}-${innerItem.positionSn}-innerSlide`} className='link'>
                              <MatchingCompanyCard positionInfo={innerItem} key={`${item}-${innerItem.positionSn}-innerSlide`} />
                            </Link>
                          ))}

                        </JDSwiperSlide>
                      ))}
                <div className='pagination-bullets' />
              </JDSwiper>
            </MainPositionFrame>
          )
          : <MainNoPosition />}

      </FormProvider>
    </RootFrame>
  );
};

export default inject(injectStore.context, injectStore.positionListModel, injectStore.main)(observer(MainPopularPosition));
