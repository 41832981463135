import type { IconName } from 'interfaces/_v2/_common/iconType';
import ImgCompanyHeader0 from 'assets/_v2/company/img_company_header0.png';
import ImgCompanyHeader1 from 'assets/_v2/company/img_company_header1.png';
import ImgCompanyHeader2 from 'assets/_v2/company/img_company_header2.png';
import ImgCompanyHeader3 from 'assets/_v2/company/img_company_header3.png';
import ImgCompanyHeader4 from 'assets/_v2/company/img_company_header4.png';
import ImgCompanyHeader5 from 'assets/_v2/company/img_company_header5.png';
import ImgCompanyHeader6 from 'assets/_v2/company/img_company_header6.png';
import ImgCompanyHeader7 from 'assets/_v2/company/img_company_header7.png';
import ImgCompanyHeader8 from 'assets/_v2/company/img_company_header8.png';
import ImgCompanyHeader9 from 'assets/_v2/company/img_company_header9.png';
import ImgCompanyHeader10 from 'assets/_v2/company/img_company_header10.png';
import ImgCompanyHeader11 from 'assets/_v2/company/img_company_header11.png';
import ImgCompanyHeader12 from 'assets/_v2/company/img_company_header12.png';
import ImgCompanyHeader13 from 'assets/_v2/company/img_company_header13.png';
import ImgCompanyHeader14 from 'assets/_v2/company/img_company_header14.png';
import ImgCompanyHeader15 from 'assets/_v2/company/img_company_header15.png';
import ImgCompanyHeader16 from 'assets/_v2/company/img_company_header16.png';
import ImgCompanyHeader17 from 'assets/_v2/company/img_company_header17.png';
import ImgCompanyHeader18 from 'assets/_v2/company/img_company_header18.png';
import ImgCompanyHeader19 from 'assets/_v2/company/img_company_header19.png';
import ImgCompanyHeader20 from 'assets/_v2/company/img_company_header20.png';
import ImgCompanyHeader21 from 'assets/_v2/company/img_company_header21.png';
import ImgCompanyHeader22 from 'assets/_v2/company/img_company_header22.png';
import ImgCompanyHeader23 from 'assets/_v2/company/img_company_header23.png';
import ImgCompanyHeader24 from 'assets/_v2/company/img_company_header24.png';
import ImgCompanyHeader25 from 'assets/_v2/company/img_company_header25.png';
import ImgCompanyHeader26 from 'assets/_v2/company/img_company_header26.png';
import ImgCompanyHeader27 from 'assets/_v2/company/img_company_header27.png';
import ImgCompanyHeader28 from 'assets/_v2/company/img_company_header28.png';
import ImgCompanyHeader29 from 'assets/_v2/company/img_company_header29.png';
import ImgCompanyHeader30 from 'assets/_v2/company/img_company_header30.png';
import ImgCompanyHeader31 from 'assets/_v2/company/img_company_header31.png';
import ImgCompanyHeader32 from 'assets/_v2/company/img_company_header32.png';
import ImgCompanyHeader33 from 'assets/_v2/company/img_company_header33.png';
import ImgCompanyHeader34 from 'assets/_v2/company/img_company_header34.png';
import ImgCompanyHeader35 from 'assets/_v2/company/img_company_header35.png';
import ImgCompanyHeader36 from 'assets/_v2/company/img_company_header36.png';
import ImgCompanyHeader37 from 'assets/_v2/company/img_company_header37.png';
import ImgCompanyHeader38 from 'assets/_v2/company/img_company_header38.png';
import ImgCompanyHeader39 from 'assets/_v2/company/img_company_header39.png';
import ImgCompanyEmployeeTip0 from 'assets/_v2/company/img_employee_tip0.png';
import ImgCompanyEmployeeTip1 from 'assets/_v2/company/img_employee_tip1.png';
import ImgCompanyEmployeeTip2 from 'assets/_v2/company/img_employee_tip2.png';
import ImgCompanyEmployeeTip3 from 'assets/_v2/company/img_employee_tip3.png';
import ImgCompanyEmployeeTip4 from 'assets/_v2/company/img_employee_tip4.png';
import ImgCompanyEmployeeTip5 from 'assets/_v2/company/img_employee_tip5.png';
import ImgCompanyEmployeeTip6 from 'assets/_v2/company/img_employee_tip6.png';
import ImgCompanyEmployeeTip7 from 'assets/_v2/company/img_employee_tip7.png';
import ImgCompanyEmployeeTip8 from 'assets/_v2/company/img_employee_tip8.png';
import ImgCompanyEmployeeTip9 from 'assets/_v2/company/img_employee_tip9.png';
import ImgCompanyEmployeeTip10 from 'assets/_v2/company/img_employee_tip10.png';
import ImgCompanyEmployeeTip11 from 'assets/_v2/company/img_employee_tip11.png';

interface ICultureListItemBaseData {
  contents: string;
  icon:IconName;
}

export enum TopicType {
    CASH_REWARD = 'CASH_REWARD', // 물질적 보상
    COWORKER_RELATIONSHIP = 'COWORKER_RELATIONSHIP', // 동료와의 관계
    WORK_ENVIRONMENT = 'WORK_ENVIRONMENT', // 업무 환경
  }
export enum TopicTypeText{
    CASH_REWARD = '물질적 보상',
    COWORKER_RELATIONSHIP = '동료와의 관계',
    WORK_ENVIRONMENT = '업무 환경',
}
export type TOPIC_TYPE =
  | 'CASH_REWARD' // 물질적 보상
  | 'COWORKER_RELATIONSHIP' // 동료와의 관계
  | 'WORK_ENVIRONMENT' // 업무환경

export enum CompanyYearType {
    SECOND_THIRD='SECOND_THIRD',
  FOURTH_SIXTH='FOURTH_SIXTH',
  SEVENTH_NINTH='SEVENTH_NINTH',
  TENTH_TWELFTH='TENTH_TWELFTH',
  OVER_THIRTEENTH='OVER_THIRTEENTH',
  }

export enum CompanyYearTypeText{
    SECOND_THIRD = '입사 2~3 년차',
  FOURTH_SIXTH = '입사 4~6 년차',
  SEVENTH_NINTH = '입사 7~9 년차',
  TENTH_TWELFTH = '입사 10~12 년차',
  OVER_THIRTEENTH = '입사 13년차 이상',
  }
export const CultureListItemBaseData: Record<TOPIC_TYPE, Record<number, ICultureListItemBaseData>> = {
  // 긍정문화 - 물질적 보상
  CASH_REWARD: {
    0: { contents: '', icon: 'money' },
    1: { contents: '안정적인 삶의 원동력', icon: 'comfortable-life' },
    2: { contents: '모두에게 공정한 보상', icon: 'fair-balance' },
    3: { contents: '생활에 도움되는 복지제도', icon: 'money' },
  },
  // 긍정문화 - 동료와의 관계
  COWORKER_RELATIONSHIP: {
    0: { contents: '', icon: 'money' },
    1: { contents: '믿고 의지할 수 있는 존재', icon: 'people-blue' },
    2: { contents: '능력자 동료들', icon: 'good-workers' },
    3: { contents: '터놓고 이야기할 수 있는 존재들', icon: 'easy-communication' },
    4: { contents: '절친한 친구', icon: 'person-love' },
  },
  // 긍정문화 - 업무환경
  WORK_ENVIRONMENT: {
    0: { contents: '', icon: 'money' },
    1: { contents: '업무 환경 200% 대만족', icon: 'three-star' },
    2: { contents: '출근이 자율적', icon: 'clock' },
    3: { contents: '퇴근이 자율적', icon: 'bag-clock' },
    4: { contents: '업무시간 외 업무지시 절대 금지', icon: 'do-not-copy' },
    5: { contents: '휴가 사용이 자율적', icon: 'calendar-check' },
    6: { contents: '내가 원할 때까지 정년 보장', icon: 'laurel-wreath-star' },
    7: { contents: '웃음이 넘치고 따뜻한 곳', icon: 'red-heart' },
    8: { contents: '부서간 협업이 활발한 곳', icon: 'polyshape' },
    9: { contents: '품의 및 결재라인이 합리적', icon: 'file-done-green' },
    10: { contents: '보고 프로세스가 합리적', icon: 'test-blue' },
    11: { contents: '회의 프로세스가 합리적', icon: 'chat-blue' },
  },
};

export const CompanyHeaderImageTypeList = [
  { image: ImgCompanyHeader0 },
  { image: ImgCompanyHeader1 },
  { image: ImgCompanyHeader2 },
  { image: ImgCompanyHeader3 },
  { image: ImgCompanyHeader4 },
  { image: ImgCompanyHeader5 },
  { image: ImgCompanyHeader6 },
  { image: ImgCompanyHeader7 },
  { image: ImgCompanyHeader8 },
  { image: ImgCompanyHeader9 },
  { image: ImgCompanyHeader10 },
  { image: ImgCompanyHeader11 },
  { image: ImgCompanyHeader12 },
  { image: ImgCompanyHeader13 },
  { image: ImgCompanyHeader14 },
  { image: ImgCompanyHeader15 },
  { image: ImgCompanyHeader16 },
  { image: ImgCompanyHeader17 },
  { image: ImgCompanyHeader18 },
  { image: ImgCompanyHeader19 },
  { image: ImgCompanyHeader20 },
  { image: ImgCompanyHeader21 },
  { image: ImgCompanyHeader22 },
  { image: ImgCompanyHeader23 },
  { image: ImgCompanyHeader24 },
  { image: ImgCompanyHeader25 },
  { image: ImgCompanyHeader26 },
  { image: ImgCompanyHeader27 },
  { image: ImgCompanyHeader28 },
  { image: ImgCompanyHeader29 },
  { image: ImgCompanyHeader30 },
  { image: ImgCompanyHeader31 },
  { image: ImgCompanyHeader32 },
  { image: ImgCompanyHeader33 },
  { image: ImgCompanyHeader34 },
  { image: ImgCompanyHeader35 },
  { image: ImgCompanyHeader36 },
  { image: ImgCompanyHeader37 },
  { image: ImgCompanyHeader38 },
  { image: ImgCompanyHeader39 },
];

export const CompanyEmployeeTipTypeList = [
  { image: ImgCompanyEmployeeTip0 },
  { image: ImgCompanyEmployeeTip1 },
  { image: ImgCompanyEmployeeTip2 },
  { image: ImgCompanyEmployeeTip3 },
  { image: ImgCompanyEmployeeTip4 },
  { image: ImgCompanyEmployeeTip5 },
  { image: ImgCompanyEmployeeTip6 },
  { image: ImgCompanyEmployeeTip7 },
  { image: ImgCompanyEmployeeTip8 },
  { image: ImgCompanyEmployeeTip9 },
  { image: ImgCompanyEmployeeTip10 },
  { image: ImgCompanyEmployeeTip11 },

];
export default CultureListItemBaseData;
