/* eslint-disable camelcase */
import { SocialType } from 'interfaces/_v2/join/IJoinRqRs';

export enum OpenIdProvider {
  KAKAO = 'KAKAO',
  GOOGLE = 'GOOGLE',
  APPLE='APPLE',
  WEB='WEB' // id,pw 로그인
}

export interface ISocialAuthLoginRs {

  registered: string; // 가입여부
  openIdProvider: OpenIdProvider;// "인증 서비스 종류 ex) KAKAO, GOOGLE
  openId: string;// 인증 서비스 고유 아이디
  name: string;// 사용자명
  email: string;// 이메일
  gender: string;// 성별
  phoneNumber: string;// 휴대폰
  birthday: string;// 생년월일
  state: string;// 옵션 파라미터

  accessToken: string;// 액세스 토큰
  expiresIn: number;// 액세스 토큰 만료시간(초)
  tokenType: string;// 토큰 타입
  refreshTokenExpiresIn: number;// 리프레시 토큰 만료 시간(초)
  refreshToken:string// 리프레시 토큰
}

export interface KaKaoTokenRs {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  refresh_token_expires_in: number;
  scope: string;
  token_type: string;
}

export interface IKaKaoTokenRq {
  grant_type: string;
  client_id: string;
  redirect_uri: string;
  code: string;
}
