import React, { FC, useMemo, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Icon from '__designkit__/icon/Icon';
import Fonts from '__designkit__/common/fonts';
import colors from '__designkit__/common/colors';
import { Button, ButtonMode } from '__designkit__/button/Button';
import JDDimModal from 'components/_v2/_common/modals/JDDimModal';
import IComponentProps from 'interfaces/props/IComponentProps';
import { JDCheckBoxInput } from 'consts/_v2/_common/style/mixins';
import { PARTNERSHIP_AUTH_TYPE } from 'consts/_v2/partnership/partnership';
import type { partnershipAuthType } from 'consts/_v2/partnership/partnership';
import { TermType, TERM_TYPE } from 'consts/TermType';
import JoinModel from 'models/_v2/JoinModel';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';

const bottomModalAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(calc(-100%));
  }
`;

const Frame = styled.div`
  position: absolute;
  top: 100%;
  width: 100%;
  height: fit-content;
  padding-bottom: env(safe-area-inset-bottom);
  border-radius: 12px 12px 0 0;
  background-color: ${colors.WHITE_100};
  animation: ${bottomModalAnimation} 0.3s 0s 1 ease-out forwards;
  z-index: 10005;

  & .open {
    transform: rotate(180deg);
  }
`;

const Header = styled.div`
  width: 100%;
  height: fit-content;
  padding: 17px;
  border-bottom: 1px solid ${colors.CG_40};
  font: ${Fonts.B1_Bold};
  text-align: center;
`;

const TermList = styled.div`
  width: 100%;
  height: fit-content;
  padding: 8px 32px 0;
`;

const TermItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0;
  font: ${Fonts.B3_Medium};

  > div {
    transition: .3s;

    & > input {
      ${JDCheckBoxInput}
    }

    & > span {
      margin-left: 8px;
    }
  }
`;

const BtnFrame = styled.footer`
  width: 100%;
  height: fit-content;
  padding: 16px;
  background-color: ${colors.WHITE_100};
`;

interface IPartnershipTermsModalProps extends IComponentProps {
  authType: partnershipAuthType;
  onClickComplete?: () => void;
  onClickClose?: () => void;
  joinModel?: JoinModel;
}

const PartnershipTermsModal: FC<IPartnershipTermsModalProps> = ({ authType, onClickComplete, onClickClose, joinModel = new JoinModel() }) => {
  const [privacyCollection, setPrivacyCollection] = useState<boolean>(false);
  const [privacyProvision, setPrivacyProvision] = useState<boolean>(false);

  const isDisabledBtn = useMemo(() => {
    if (authType === PARTNERSHIP_AUTH_TYPE.UNIVERSITY) {
      return !privacyCollection || !privacyProvision;
    } if (authType === PARTNERSHIP_AUTH_TYPE.CODE) {
      return !privacyProvision;
    }
    return true;
  }, [authType, privacyCollection, privacyProvision]);

  const onClickDetail = (termType: TermType) => {
    joinModel.activeTermType = termType;
  };

  return (
    <JDDimModal onClickClose={onClickClose}>
      <Frame>
        <Header>
          서비스 이용 약관 동의
        </Header>
        <TermList>
          { authType === PARTNERSHIP_AUTH_TYPE.UNIVERSITY && (
            <TermItem>
              <div>
                <input type='checkbox' checked={privacyCollection} onChange={() => setPrivacyCollection(!privacyCollection)} />
                <span>개인정보 수집 및 이용 동의 (필수)</span>
              </div>
              <Icon name='arrow-right' size={32} onClick={() => onClickDetail(TERM_TYPE.PARTNERSHIP_PRIVACY_COLLECTION)} />
            </TermItem>
          )}
          <TermItem>
            <div>
              <input type='checkbox' checked={privacyProvision} onChange={() => setPrivacyProvision(!privacyProvision)} />
              <span>개인정보 제3자 제공동의 (필수)</span>
            </div>
            <Icon name='arrow-right' size={32} onClick={() => onClickDetail(TERM_TYPE.PARTNERSHIP_PRIVACY_PROVISION)} />
          </TermItem>
        </TermList>
        <BtnFrame>
          <Button label='확인' buttonMode={ButtonMode.PRIMARY} size='large' onClick={onClickComplete} disabled={isDisabledBtn} />
        </BtnFrame>
      </Frame>
    </JDDimModal>
  );
};

export default inject(injectStore.joinModel)(observer(PartnershipTermsModal));
