import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import JDALink from 'components/JDALink';
import MainContents from 'components/_v2/main/MainContents';
import { Divider1G40 } from 'components/divider/Divider';
import RoutePaths from 'consts/RoutePaths';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

const Frame = styled.div``;
const Contents = styled.div`
  padding: 24px 16px;
  .divider {
    margin: 24px 0;
  }
  .main-text {
    margin: 8px 0 32px 0;
    font: ${Fonts.H3_Bold};
    color: ${colors.JOBDA_BLACK};
  }
  .sub-frame {
    .sub-title {
      font: ${Fonts.B2_Bold_P};
      line-height: 24px;
      color: ${colors.JOBDA_BLACK};
      margin-bottom: 12px;
    }
  }
`;
const InnerCardFrame = styled.div<{ isPhs: boolean }>`
  display: flex;
  margin-bottom: 16px;
  .icon-frame {
    display: flex;
    flex: none;
    justify-content: center;
    align-items: center;
    margin-right: 24px;
    width: 80px;
    height: 80px;
    background: ${colors.CG_40};
    border-radius: 8px;
  }
  .text-frame {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 80px;
    .title-text-frame {
      display: flex;
      flex-direction: ${(props) => (props.isPhs ? 'column' : 'row')};
      align-items: ${(props) => (props.isPhs ? '' : 'center')};
      .title-text {
        font: ${Fonts.B1_Bold};
        color: ${colors.JOBDA_BLACK};
      }
      .small-title-text {
        font: ${Fonts.B3_Bold};
        color: ${colors.JOBDA_BLACK};
      }
    }

    .desc-text {
      margin-top: 8px;
      font: ${Fonts.B3_Medium_P};
      color: ${colors.CG_70};
    }
  }
`;
interface ICardProps {
  icon: JSX.Element;
  title: string;
  isPC: boolean;
  isPhs: boolean;
  desc: string;
  linkTo: string;
}
interface ITextReplaceProps {
  text: string;
}
const TextReplace: FC<ITextReplaceProps> = ({ text }) => (
  <div className='desc-text'>
    {text.split('\n').map((txt) => (
      <>
        {txt}
        <br />
      </>
    ))}
  </div>
);
const CardFrame = (card: ICardProps) => {
  if (card.linkTo === '') {
    return (
      <div>
        <InnerCardFrame isPhs={card.isPhs}>
          <div className='icon-frame'>{card.icon}</div>
          <div className='text-frame'>
            <div className='title-text-frame'>
              <div className='title-text'>{card.title}</div>
              {card.isPC && <div className='small-title-text'>(PC에서만 가능)</div>}
            </div>
            <TextReplace text={card.desc} />
          </div>
        </InnerCardFrame>
      </div>
    );
  }

  return (
    <JDALink to={card.linkTo}>
      <InnerCardFrame isPhs={card.isPhs}>
        <div className='icon-frame'>{card.icon}</div>
        <div className='text-frame'>
          <div className='title-text-frame'>
            <div className='title-text'>{card.title}</div>
            {card.isPC && <div className='small-title-text'>(PC에서만 가능)</div>}
          </div>
          <TextReplace text={card.desc} />
        </div>
      </InnerCardFrame>
    </JDALink>
  );
};

const Preparation: FC = () => (
  <Frame>
    <Contents>
      <div className='main-text'>
        취업준비는,
        <br />
        이제 잡다에서 끝내세요.
      </div>
      <div className='sub-frame'>
        <div className='sub-title'>취업준비</div>
        {PreparationText.map((card: ICardProps) => (
          <>{CardFrame(card)}</>
        ))}
      </div>
      <Divider1G40 className='divider' />
      <div className='sub-frame'>
        <div className='sub-title'>면접연습(PC에서만 가능)</div>
        {InterviewPreparationText.map((card: ICardProps) => (
          <>{CardFrame(card)}</>
        ))}
      </div>
      <Divider1G40 className='divider' />
      <div className='sub-frame'>
        <div className='sub-title'>취준 MBTI</div>
        {MBTIText.map((card: ICardProps) => (
          <>{CardFrame(card)}</>
        ))}
      </div>
      <Divider1G40 className='divider' />
      <div className='sub-frame'>
        <div className='sub-title'>역량검사(역량검사)</div>
        {AccaPreparationText.map((card: ICardProps) => (
          <>{CardFrame(card)}</>
        ))}
      </div>
      <Divider1G40 className='divider' />
      <div className='sub-frame'>
        <div className='sub-title'>개발자 검사</div>
        {PaccPreparationText.map((card: ICardProps) => (
          <>{CardFrame(card)}</>
        ))}
      </div>
    </Contents>
    <MainContents preparation />
  </Frame>
);

export default Preparation;

const PreparationText: ICardProps[] = [
  {
    icon: <Icon size={56} name='info-user' />,
    title: '내 프로필',
    desc: `프로필을 미리 작성해두면 
       입사지원할 때 편하게 불러갈 수 있어요.`,
    isPC: false,
    linkTo: RoutePaths.profile,
    isPhs: false,
  },
  {
    icon: <Icon size={56} name='book-green' />,
    title: '취업 콘텐츠',
    desc: `역량검사 준비 방법과 합격 후기부터\n재직자들의 취업 비법 콘텐츠를 확인하세요.`,
    isPC: false,
    linkTo: RoutePaths.info,
    isPhs: false,
  },
  {
    icon: <Icon size={56} name='resume-done-green' />,
    title: '잡다매칭',
    desc: `잡다매칭 참여하면 \n기업에게 먼저 제안 받을 수 있어요.`,
    isPC: false,
    linkTo: RoutePaths.match_apply,
    isPhs: false,
  },
];

const InterviewPreparationText: ICardProps[] = [
  {
    icon: <Icon size={56} name='chat-yellow' />,
    title: '기출 면접 연습',
    desc: `면접준비, 실전처럼 연습해보세요.
          기업별 예상질문부터 면접연습까지 한 번에`,
    isPC: false,
    linkTo: '',
    isPhs: false,
  },
];

const MBTIText: ICardProps[] = [
  {
    icon: <Icon size={56} name='matching-yellow' />,
    title: '취뽀 유형 테스트',
    desc: `간단 테스트로 나의 취업 능력을 알아보세요.
            내 강약점부터 추천 직무까지 받아볼 수 있어요.`,
    linkTo: 'https://acca.jobda.im/jbti/me',
    isPhs: false,
    isPC: false,
  },
];
const AccaPreparationText: ICardProps[] = [
  {
    icon: <Icon size={56} name='acca' />,
    title: '역량검사 실전',
    desc: `역량검사를 응시하고 내 역량에 적합한
          포지션을 추천받아 보세요.`,
    isPC: true,
    linkTo: RoutePaths.acca_test,
    isPhs: false,
  },
  {
    icon: <Icon size={56} name='guide-acca-green' />,
    title: '역량검사 튜토리얼',
    desc: `성향파악부터 영상면접까지,
          실전과 동일한 환경에서 연습할 수 있어요.`,
    isPC: true,
    linkTo: RoutePaths.acca_test,
    isPhs: false,
  },
  {
    icon: <Icon size={56} name='test-acca' />,
    title: '역량검사 결과표',
    desc: `응시한 역량검사결과를 가지고
          취업준비에 참고 및 활용할 수 있어요.`,
    isPC: false,
    linkTo: RoutePaths.acca_results(),
    isPhs: false,
  },
];

const PaccPreparationText: ICardProps[] = [
  {
    icon: <Icon size={56} name='diversity' />,
    title: '개발자 역량검사 실전',
    desc: `개발자 역량검사 응시하고 내 역량에 적합한
          포지션을 추천받아 보세요.`,
    isPC: true,
    linkTo: '',
    isPhs: true,
  },
  {
    icon: <Icon size={56} name='laptop-acca-blue' />,
    title: '개발자 역량검사 튜토리얼',
    desc: `성향파악부터 영상면접까지,
          실전과 동일한 환경에서 연습할 수 있어요.`,
    isPC: true,
    linkTo: '',
    isPhs: true,
  },
  {
    icon: <Icon size={56} name='search-acca-blue' />,
    title: '개발자 역량검사 결과표',
    desc: `응시한 개발자 역량검사 결과를 가지고
          취업준비에 참고 및 활용할 수 있어요.`,
    isPC: false,
    linkTo: RoutePaths.phs_pacc_results(),
    isPhs: true,
  },
  {
    icon: <Icon size={56} name='coding' />,
    title: '개발 구현 능력 검사 실전',
    desc: `개발 구현 능력 검사 응시하고 내 역량에 적합한
          포지션을 추천받아 보세요.`,
    isPC: true,
    linkTo: '',
    isPhs: true,
  },
  {
    icon: <Icon size={56} name='stdio-blue' />,
    title: '개발 구현 능력 검사 튜토리얼',
    desc: `성향파악부터 영상면접까지,
          실전과 동일한 환경에서 연습할 수 있어요.`,
    isPC: true,
    linkTo: '',
    isPhs: true,
  },
  {
    icon: <Icon size={56} name='test-coding-blue' />,
    title: '개발 구현 능력 검사 결과표',
    desc: `응시한 개발 구현 능력 검사 결과를 가지고
          취업준비에 참고 및 활용할 수 있어요.`,
    isPC: false,
    linkTo: RoutePaths.phs_pacc_results(),
    isPhs: true,
  },
];
