import React, { FC } from 'react';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import classNames from 'classnames/bind';

import { ModeType, PickerType } from './JDDatePicker';
import style from './header.module.scss';

const cx = classNames.bind(style);

const getPickerTypeMap: Record<PickerType, string> = {
  MONTH: 'month',
  DAY: 'day',
};

interface HeaderProps {
  mode: ModeType | null;
  setMode: (mode: ModeType) => void;
  pickerType: PickerType;
}

const Header: FC<ReactDatePickerCustomHeaderProps & HeaderProps> = ({
  mode,
  setMode,
  pickerType,
  date,
  decreaseMonth,
  increaseMonth,
  decreaseYear,
  increaseYear,
}) => {
  const handleYearClick = () => setMode('YEAR');
  const handleMonthClick = () => setMode('MONTH');
  const handlePrev = () => decreaseMonth();
  const handleNext = () => increaseMonth();
  const handlePrevYear = () => decreaseYear();
  const handleNextYear = () => increaseYear();
  const isDisabled = mode !== 'DAY';

  return (
    <div className={cx('headerArea', getPickerTypeMap[pickerType])}>
      <div className={cx('dateWrap')}>
        <div role='button' className={cx('date')} onClick={handleYearClick}>
          {date.getFullYear()}년
          <i className={cx('icon')} />
        </div>
        <div role='button' className={cx('date')} onClick={handleMonthClick}>
          {(date.getMonth() + 1).toString().padStart(2, '0')}
          <i className={cx('icon')} />
        </div>
      </div>

      <div className={cx('buttonWrap', getPickerTypeMap[pickerType])}>
        <button
          type='button'
          className={cx('button', 'left', { disabled: isDisabled })}
          onClick={handlePrev}
          disabled={isDisabled}
        />
        <button
          type='button'
          className={cx('button', 'right', { disabled: isDisabled })}
          onClick={handleNext}
          disabled={isDisabled}
        />
      </div>

      {mode === 'YEAR' && (
        <div className={cx('yearButtonWrap')}>
          <button type='button' className={cx('button', 'left', { disabled: isDisabled })} onClick={handlePrevYear} />
          <i className={cx('line')} />
          <button
            type='button'
            className={cx('button', 'right', { disabled: isDisabled })}
            onClick={handleNextYear}
          />
        </div>
      )}
    </div>
  );
};
export default Header;
