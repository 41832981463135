/* eslint-disable react-hooks/exhaustive-deps */
import { ReactComponent as IconReset } from 'assets/_v2/_common/input/icon_reset.svg';
import { ReactComponent as IconArrowDown } from 'assets/_v2/_common/input/icon_small_arrow_down.svg';
import { StyledSelectModalFrame, StyledSmallSelector } from 'components/_v2/position/filter/PositionJobTitleFilter';
import SalaryMultiRange, { ICondition } from 'components/_v2/_common/input/rang/SalaryMultiRange';
import JDUnderBaseModal from 'components/_v2/_common/modals/JDUnderBaseModal';
import { StyledIconSVG } from 'consts/assets/icons/iconPages';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import PositionListModel from 'models/_v2/PositionListModel';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { PositionFiltersProps } from '../PostionFilterTab';

const Frame = styled.div`

`;

const SalaryFrame = styled.div`
  
  &&&{
    margin-top:32px;

  .value-frame{
    display: flex;
    align-items: flex-end;
      
    h2{
      font: ${Fonts.B3_Medium};
      color:${colors.CG_70};
    }
    h1{
      font: ${Fonts.H4_Bold};
      color:${colors.JOBDA_BLACK};
    }
    span{
      font: ${Fonts.H4_Bold};
      color:${colors.JOBDA_BLACK};
      margin:0px 12px 9px 12px;
      align-self: center;
    }
  }
  .rang-frame{
    margin-right: 15px;
  }
  }
`;

const min = 0;
const max = 20000;
const dots = [
  { value: min, label: '0원' },
  { value: 2000, label: '' },
  { value: 4000, label: '' },
  { value: 6000, label: '' },
  { value: 8000, label: '' },
  { value: 10000, label: '' },
  { value: 12000, label: '' },
  { value: 14000, label: '' },
  { value: 16000, label: '' },
  { value: 18000, label: '' },
  { value: max, label: '2억원' },
];

const PositionSalaryFilter: FC<PositionFiltersProps> = ({ reloadPosition, filteredCount, positionListModel = new PositionListModel() }) => {
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const useformed = useForm({ mode: 'all' });
  const [saveSelectList, setSaveSelectList] = useState<ICondition>({
    minValue: min,
    maxValue: max,
    requirementType: 'PREFERENTIAL',
  });

  const [condition, setCondition] = useState<ICondition>({
    minValue: min,
    maxValue: max,
    requirementType: 'PREFERENTIAL',
  });

  useEffect(() => {
    if (positionListModel.isGlobalSearchedSalary) {
      setCondition({
        minValue: positionListModel.searchFilters.minSalary !== undefined ? Number(positionListModel.searchFilters.minSalary) : min,
        maxValue: positionListModel.searchFilters.maxSalary !== undefined ? Number(positionListModel.searchFilters.maxSalary) : max,
        requirementType: 'PREFERENTIAL',
      });
      setSaveSelectList({
        minValue: positionListModel.searchFilters.minSalary !== undefined ? Number(positionListModel.searchFilters.minSalary) : min,
        maxValue: positionListModel.searchFilters.maxSalary !== undefined ? Number(positionListModel.searchFilters.maxSalary) : max,
        requirementType: 'PREFERENTIAL',
      });
      positionListModel.isGlobalSearchedSalary = false;
    }
  }, [positionListModel.isGlobalSearchedSalary]);

  const onClickReset = () => {
    setCondition({
      minValue: min,
      maxValue: max,
      requirementType: 'PREFERENTIAL',
    });
    positionListModel.searchFilters.maxSalary = min;
    positionListModel.searchFilters.minSalary = max;
  };

  const onClickFilter = () => {
    positionListModel.searchFilters.maxSalary = Number(condition.maxValue);
    positionListModel.searchFilters.minSalary = Number(condition.minValue);
    reloadPosition();
    setSaveSelectList(condition);
    setIsSelectOpen(false);
  };
  useEffect(() => {
    if (isSelectOpen) {
      setCondition({
        minValue: positionListModel.searchFilters.minSalary !== undefined ? Number(positionListModel.searchFilters.minSalary) : min,
        maxValue: positionListModel.searchFilters.maxSalary !== undefined ? Number(positionListModel.searchFilters.maxSalary) : max,
        requirementType: 'PREFERENTIAL',
      });
    }
  }, [isSelectOpen]);

  useEffect(() => {
    if (positionListModel.searchFilters.minSalary === undefined && positionListModel.searchFilters.maxSalary === undefined) setSaveSelectList({
      minValue: -1,
      maxValue: -1,
      requirementType: 'PREFERENTIAL',
    });
  }, [positionListModel.searchFilters.minSalary, positionListModel.searchFilters.maxSalary]);

  return (
    <Frame>
      <StyledSmallSelector
        count={saveSelectList.minValue === -1 && saveSelectList.maxValue === -1 ? 0 : 1}
        onClick={() => {
          setIsSelectOpen(true);
        }}
      >
        <span>{saveSelectList.minValue === -1 && saveSelectList.maxValue === -1 ? '연봉' : `${saveSelectList.minValue}만원 ~ ${saveSelectList.maxValue}만원`}</span>
        <StyledIconSVG fill={colors.CG_70}><IconArrowDown /></StyledIconSVG>
      </StyledSmallSelector>
      <JDUnderBaseModal isOpen={isSelectOpen} onClickClose={() => setIsSelectOpen(false)}>
        <FormProvider {...useformed}>
          <StyledSelectModalFrame>
            <div className='title'>필터</div>
            <div className='content-frame'>
              <div className='group-frame'>
                <h1>연봉 범위 선택</h1>
                <SalaryFrame>
                  <div className='value-frame'>
                    <div>
                      <h2>최소</h2>
                      <h1>{condition.minValue}만원</h1>
                    </div>
                    <span>~</span>
                    <div>
                      <h2>최대</h2>
                      <h1>{condition.maxValue}만원</h1>
                    </div>
                  </div>
                  <div className='rang-frame'>
                    <SalaryMultiRange min={min} max={max} dots={dots} step={1000} condition={condition} setCondition={setCondition} />
                  </div>
                </SalaryFrame>
              </div>
            </div>
            <div className='btn-frame'>
              <button className='btn-reset' onClick={onClickReset}><IconReset />필터 초기화</button>
              <button
                className='btn-search'
                onClick={onClickFilter}
              >검색
              </button>
            </div>
          </StyledSelectModalFrame>
        </FormProvider>
      </JDUnderBaseModal>
    </Frame>
  );
};

export default inject(injectStore.positionListModel)(observer(PositionSalaryFilter));
