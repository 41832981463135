import ImgArrowRight from 'assets/icon/icon_arrow_right_small_round_white.svg';
import ImgMatchApplyNudge from 'assets/_v2/matchHome/apply/img_match_apply_nudge.png';
import JDUnderBaseModal from 'components/_v2/_common/modals/JDUnderBaseModal';
import RoutePaths from 'consts/RoutePaths';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { JDCheckBoxInput } from 'consts/_v2/_common/style/mixins';
import IModalProps from 'interfaces/props/IModalProps';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import TermsModel from 'models/TermsModel';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import NeverRenderCheckUtil from 'utils/NeverRenderCheckUtil';

export const NudgeBackground = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width:100%;
  height:214px;
  padding-left:20px;
  background-image: url(${ImgMatchApplyNudge});
  background-size:cover;
  border-radius: 12px 12px 0 0;
  .small-text{
    margin-bottom: 8px;
    font: ${Fonts.B3_Medium};
    color:${colors.CG_90};
  }
  .big-text{
    margin-bottom: 24px;
    font: ${Fonts.B1_Bold};
    line-height: 24px;
    color:${colors.JOBDA_BLACK};
  }
  .nudge-button{
    display: flex;
    justify-content: center;
    align-items: center;
    width:100px;
    height:38px;
    border-radius: 49px;
    background-color: ${colors.JOBDA_BLACK};
    .nudge-text{
      font: ${Fonts.B2_Bold};
      color:${colors.WHITE_100};
      margin-right:6px;
    }
  }
`;
export const BottomClose = styled.div`
  display: flex;
  justify-content: space-between;
  padding:16px;
  padding-bottom: calc(env(safe-area-inset-bottom) + 16px);
  .check-field{
    display: flex;
      .check-text{
        display: flex;
        align-items: center;
        margin-left: 6px;
        font: ${Fonts.B2_Medium};
        color:${colors.CG_80};
    }
    input[type=checkbox] {
        ${JDCheckBoxInput()};
    }
  }
  .close-text{
        display: flex;
        align-items: center;
        margin-left: 6px;
        font: ${Fonts.B2_Medium};
        color:${colors.CG_80};
    }
  
`;

interface IJDMatchApplyNudgeModal extends IModalProps{
  termsModel?:TermsModel
}
const JDMatchApplyNudgeModal:FC<IJDMatchApplyNudgeModal> = ({ isOpen, onClose }) => {
  const [neverChecked, setNeverChecked] = useState<boolean>(false);
  const keyName = 'match-apply-modal';
  const history = useHistory();

  const closeModal = () => {
    if (neverChecked) {
      NeverRenderCheckUtil.setNeverRenderCheck(keyName);
      onClose && onClose();
    } else {
      onClose && onClose();
    }
  };

  return (
    <JDUnderBaseModal nudge isOpen={isOpen} onClickClose={() => closeModal()}>
      <>
        <NudgeBackground>
          <div className='small-text'>
            아직 잡다매칭을 안쓰고 있다구요?
          </div>
          <div className='big-text'>
            지금 신청하고 기업에게 <br /> 먼저 제안받아보세요!
          </div>
          <div
            role='button'
            onClick={() => {
              history.push(RoutePaths.match_apply);
            }}
            className='nudge-button'
          >
            <div className='nudge-text'>
              바로가기
            </div>
            <img alt='arrow' src={ImgArrowRight} />
          </div>
        </NudgeBackground>
        <BottomClose>
          <div className='check-field'>
            <input
              type='checkbox'
              onChange={(e) => {
                if (e.target.checked) setNeverChecked(true);
                else setNeverChecked(false);
              }}
            />
            <div className='check-text'>
              다시 보지 않음
            </div>
          </div>

          <button onClick={closeModal} className='close-text'>닫기</button>
        </BottomClose>
      </>
    </JDUnderBaseModal>
  );
};

export default inject(injectStore.termsModel)(observer(JDMatchApplyNudgeModal));
