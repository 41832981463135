import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import JDFullModal from 'components/_v2/_common/modals/JDFullModal';
import ProfileEditTitleComponent from 'components/_v2/profile/ProfileEditTitleComponent';
import ProfileMoveDirtyModal from 'components/_v2/profile/ProfileMoveDirtyModal';
import ConditionFrame from 'components/_v2/profile/condition/ConditionFrame';
import JobTab from 'components/_v2/profile/condition/JobTab';
import LocationTab from 'components/_v2/profile/condition/LocationTab';
import SalaryTab from 'components/_v2/profile/condition/SalaryTab';
import WelfareTab from 'components/_v2/profile/condition/WelfareTab';
import WorkingPreferenceTab from 'components/_v2/profile/condition/WorkingPreferenceTab';
import { LocationCode, LocationCodeList } from 'consts/CompanyLocationType';
import { ConditionRegisterType } from 'consts/_v2/profile/ProfileType';
import IComponentProps from 'interfaces/props/IComponentProps';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import ProfileModel from 'models/_v2/profile/ProfileModel';
import { injectStore } from 'models/store';
import useProfileConditions from 'query/profiles/useProfileConditionsQuery';
import React, { FC, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import styled from 'styled-components';

const ConditionListFrame = styled.div`
  padding: 0 16px;

`;
const Frame = styled.div`

`;

const BottomFrame = styled.div`
  padding: 24px;
`;
const TitleFrame = styled.div`
  padding: 0 16px;

  .title-profile {
    width: 100%;
    height: fit-content;
    font: ${Fonts.H4_Bold};
    color: ${Colors.JOBDA_BLACK};
  }
`;
interface IConditionEditModalProps extends IComponentProps {
    login?: Login;
    profileModel?: ProfileModel;
    conditionRegisterType: ConditionRegisterType;
    setConditionRegisterType: (type: ConditionRegisterType) => void;
    completeList: string[];
    setCompleteList: (list: string[]) => void;
}
export interface ChildEditComponentHandles{
    saveConditionButton: () => number;
}
const EditConditionView = forwardRef<ChildEditComponentHandles, IConditionEditModalProps>((props, ref) => {
  const { completeList, setCompleteList, conditionRegisterType, setConditionRegisterType, login = new Login(), profileModel = new ProfileModel(), ...rest } = props;
  const [jobGuideText, setJobGuideText] = useState<string>('희망하는 직군 · 직무를 선택해 주세요.');
  const [locationGuideText, setLocationGuideText] = useState<string>('희망하는 근무 지역을 선택해 주세요.');
  const [salaryGuideText, setSalaryGuideText] = useState<string>('선호하는 연봉 수준을 입력해 주세요.');
  const [welfareGuideText, setWelfareGuideText] = useState<string>('선호하는 복지 키워드를 선택해 주세요.');
  const [etcGuideText, setEtcGuideText] = useState<string>('어떤 일하고 싶은지 자유롭게 작성해 주세요.');
  const [jobError, setJobError] = useState<boolean>(false);
  const [locationError, setLocationError] = useState<boolean>(false);
  const [salaryError, setSalaryError] = useState<boolean>(false);
  const [startChecking, setStartChecking] = useState<boolean>(false);
  const { data: profileConditionsData } = useProfileConditions(!!login.userInfo);

  useEffect(() => {
    if (!profileConditionsData) return;

    const { jobGroups, locations, salary, welfare, workingPreference } = profileConditionsData;
    const newCompleteList = [...completeList]; // 현재 completeList의 복사본을 생성

    const updateJobGuideText = () => {
      if (jobGroups.length === 0) return;
      const allJobTitles = jobGroups.flatMap((group) => group.jobTitleNames);
      const uniqueJobTitles:any = [...new Set(allJobTitles)];
      if (uniqueJobTitles.length === 1) {
        setJobGuideText(uniqueJobTitles[0]);
      } else {
        setJobGuideText(`${uniqueJobTitles[0]} 외 ${uniqueJobTitles.length - 1}개`);
      }
    };

    const updateLocationGuideText = () => {
      if (locations.length === 0) return;
      const includesAllLocations = LocationCodeList.every((location) => locations.includes(location));
      if (includesAllLocations) {
        setLocationGuideText('전체 지역');
      } else {
        setLocationGuideText(locations.length === 1 ? LocationCode(locations[0]) : `${LocationCode(locations[0])} 외 ${locations.length - 1}개`);
      }
    };

    const updateSalaryGuideText = () => {
      if (salary > 0) setSalaryGuideText(`${salary.toLocaleString()}만원 이상`);
    };

    const updateWelfareGuideText = () => {
      if (welfare.length === 0) return;
      setWelfareGuideText(welfare.length === 1 ? welfare[0].name : `${welfare[0].name} 외 ${welfare.length - 1}개`);
    };

    const updateEtcGuideText = () => {
      if (workingPreference) setEtcGuideText(workingPreference);
    };

    updateJobGuideText();
    updateLocationGuideText();
    updateSalaryGuideText();
    updateWelfareGuideText();
    updateEtcGuideText();
    if (jobGroups.length > 0 && !newCompleteList.includes('job')) {
      newCompleteList.push('job');
    }
    if (locations.length > 0 && !newCompleteList.includes('location')) {
      newCompleteList.push('location');
    }
    if (salary > 0 && !newCompleteList.includes('salary')) {
      newCompleteList.push('salary');
    }
    if (welfare.length > 0 && !newCompleteList.includes('welfare')) {
      newCompleteList.push('welfare');
    }
    if (workingPreference && !newCompleteList.includes('etc')) {
      newCompleteList.push('etc');
    }
    setCompleteList(newCompleteList);
    if (startChecking) {
      const res = checkConditionsAndUpdateError();
    }
  }, [profileConditionsData, startChecking]);
  const requiredConditions = ['job', 'location', 'salary'];

  const checkConditionsAndUpdateError = () => {
    setStartChecking(true);
    const missingConditions = requiredConditions.filter((condition) => !completeList.includes(condition));
    const isAllConditionsMet = missingConditions.length === 0;
    setJobError(missingConditions.includes('job'));
    setLocationError(missingConditions.includes('location'));
    setSalaryError(missingConditions.includes('salary'));
    if (!isAllConditionsMet) {
      setStartChecking(false);
      return 0;
    }
    setStartChecking(false);
    return (completeList.length);
  };
  useImperativeHandle(ref, () => ({ saveConditionButton: checkConditionsAndUpdateError }));
  return (
    <Frame>
      <TitleFrame hidden={conditionRegisterType !== ConditionRegisterType.LIST}>
        <div className='title-profile'>선호하는 근무 조건도 함께 입력해 주세요. <br />{login.userInfo?.name}님께 딱 맞는 공고를 추천해 드릴게요.</div>
      </TitleFrame>
      <SpacingBlock vertical size={32} />
      <ConditionListFrame hidden={conditionRegisterType !== ConditionRegisterType.LIST}>
        <ConditionFrame error={jobError} onClick={() => setConditionRegisterType(ConditionRegisterType.JOBGROUP)} title='직군 · 직무' required description={jobGuideText} />
        <SpacingBlock vertical size={12} />
        <ConditionFrame error={locationError} onClick={() => setConditionRegisterType(ConditionRegisterType.LOCATION)} title='근무 지역' required description={locationGuideText} />
        <SpacingBlock vertical size={12} />
        <ConditionFrame error={salaryError} onClick={() => setConditionRegisterType(ConditionRegisterType.SALARY)} title='연봉' required description={salaryGuideText} />
        <SpacingBlock vertical size={12} />
        <ConditionFrame onClick={() => setConditionRegisterType(ConditionRegisterType.WELFARE)} title='복지 혜택' required={false} description={welfareGuideText} />
        <SpacingBlock vertical size={12} />
        <ConditionFrame onClick={() => setConditionRegisterType(ConditionRegisterType.WORKING_PREFERENCE)} title='기타 희망사항' required={false} description={etcGuideText} />
      </ConditionListFrame>
      <JobTab setStartChecking={setStartChecking} isResumeModal setConditionRegisterType={setConditionRegisterType} jobGroupData={profileConditionsData?.jobGroups} hidden={conditionRegisterType !== ConditionRegisterType.JOBGROUP} />
      <SalaryTab setStartChecking={setStartChecking} isResumeModal setConditionRegisterType={setConditionRegisterType} salaryData={profileConditionsData?.salary} hidden={conditionRegisterType !== ConditionRegisterType.SALARY} />
      <WelfareTab setStartChecking={setStartChecking} isResumeModal setConditionRegisterType={setConditionRegisterType} welfareData={profileConditionsData?.welfare} hidden={conditionRegisterType !== ConditionRegisterType.WELFARE} />
      <LocationTab isResumeModal setConditionRegisterType={setConditionRegisterType} locationData={profileConditionsData?.locations} hidden={conditionRegisterType !== ConditionRegisterType.LOCATION} />
      <WorkingPreferenceTab isResumeModal setConditionRegisterType={setConditionRegisterType} workingPreferenceData={profileConditionsData?.workingPreference} hidden={conditionRegisterType !== ConditionRegisterType.WORKING_PREFERENCE} />
    </Frame>
  );
});
export default inject(injectStore.login, injectStore.profileModel)(observer(EditConditionView));
