import { StylesConfig } from 'react-select';
import downIcon from 'assets/icon/icon_arrow_down.svg';

export const ReactSelectStyles: StylesConfig<Record<string, any>, true> = {
  container: (base, state) => ({
    // @ts-ignore
    borderColor: state.isFocused ? '#121619 !important' : '#f4f4f5',
    borderRadius: '4px',
    // @ts-ignore
    backgroundColor:'#f4f4f5',
    opacity: state.isDisabled ? '0.5' : '1',
    cursor: state.isDisabled ? 'default' : 'pointer',
  }),
  control: () => ({
    position: 'relative',
  }),
  valueContainer: () => ({
    position: 'relative',
    height: 44,
    padding: '0 46px 0 16px',
    fontSize: 14,
    lineHeight: '16px',

    ':after': {
      position: 'absolute',
      top: '50%',
      right: 16,
      width: 20,
      height: 20,
      backgroundImage: `url(${downIcon})`,
      backgroundSize: 'cover',
      transform: 'translateY(-50%)',
      content: `''`,
    },
  }),
  singleValue: () => ({
    position: 'absolute',
    top: '50%',
    right: 46,
    left: 16,
    color: '#121619',
    transform: 'translateY(-50%)',
  }),
  placeholder: () => ({
    position: 'absolute',
    top: 0,
    right: 46,
    bottom: 0,
    left: 16,
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '16px',
    color: '#9fa4ab',
  }),
  input: () => ({
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    fontSize: 14,
    lineHeight: '16px',
    color: '#121619',
  }),
  indicatorsContainer: () => ({
    display: 'none',
  }),
  menu: () => ({
    position: 'absolute',
    right: 0,
    bottom: -4,
    left: 0,
    border: '1px solid #cfd1d5',
    borderRadius: 4,
    backgroundColor: '#fff',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1)',
    transform: 'translateY(100%)',
    zIndex: 1,
  }),
  menuList: () => ({
    overflowY: 'auto',
    maxHeight: 182,
    padding: '4px 0',
  }),
  option: (base, state) => ({
    padding: '12px 14px',
    fontSize: 14,
    lineHeight: '16px',
    color: state.isSelected ? '#121619' : '#9fa4ab',
    wordBreak: 'keep-all',
    cursor: 'pointer',

    ':hover': {
      backgroundColor: '#f9f9fa',
      color: '#44474b',
    },
  }),
};

export default ReactSelectStyles