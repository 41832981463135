import colors from '__designkit__/common/colors';
import { ReactComponent as IconReset } from 'assets/_v2/_common/input/icon_reset.svg';
import CompanyJobTitleFilter from 'components/_v2/company/CompanyJobTitleFilter';
import CompanyLocationFilter from 'components/_v2/company/CompanyLocationFilter';
import CompanyRecruitmentFilter from 'components/_v2/company/CompanyRecruitmentFilter';
import CompanySalaryFilter from 'components/_v2/company/CompanySalaryFilter';
import { JDSmallSeletor, ScrollHiddenMixin } from 'consts/_v2/_common/style/mixins';
import { PositionOrderType } from 'consts/_v2/position/PositionType';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import CompanyModel from 'models/_v2/CompanyModel';
import { injectStore } from 'models/store';
import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

const PositionFilterTabFrame = styled.div`
  z-index: 1000;
  background: ${colors.WHITE_100};
  width: 100%;
  padding: 16px 0px 16px 0px;
  overflow: hidden;
  margin-top: calc(env(safe-area-inset-top) + 50px);
  >div{
    padding: 0px 16px 0px 16px;
    width: 100%;
    display: inline-flex;
    height: 30px;
    overflow-x: scroll;
    ${ScrollHiddenMixin()}
    
    >*{
      display: inline-block;
      padding-right:8px;
    }
}
`;

const StyledReset = styled.div`
  ${JDSmallSeletor()}
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.JOBDA_BLACK};
  color:${colors.JOBDA_BLACK};
  padding:8px 18px 8px 28px;
  svg {
    left: 10px;
    top:14px;
  }
  span{
    display: flex;
    align-items: center;
  }
  margin-right:8px;

`;
export interface CompanyFiltersProps extends ICompanyDetailFilterTab {
    reloadPosition: () => void;
    filteredCount: () => number;
  }

export interface ICompanyDetailFilterTab {
    companyModel? : CompanyModel
    context?: Context
}
const CompanyDetailFilterTab: FC<ICompanyDetailFilterTab> = ({ companyModel = new CompanyModel(), context = new Context() }) => {
  const useFormed = useForm();

  const reloadPosition = async () => {
    await companyModel.loadCompanyPositions();
  };

  const filteredCount = () => companyModel.companyPositions?.pages.totalElements || 0;

  const renderResetButton = () => (
    <>
      {
      (companyModel.searchFilters.jobTitles !== ''
      || companyModel.searchFilters.recruitments !== ''
      || companyModel.searchFilters.minSalary !== undefined
      || companyModel.searchFilters.maxSalary !== undefined
      || companyModel.searchFilters.locations !== ''
      || companyModel.searchFilters.matchingYn !== false
      || companyModel.searchFilters.orderType !== PositionOrderType.POPULAR)
      && (
        <StyledReset
          onClick={async () => {
            companyModel.resetFilter();
            await companyModel.loadCompanyPositions();
          }}
        >
          <IconReset width={12} height={12} />
          <span>초기화</span>
        </StyledReset>
      )
    }
    </>
  );

  return (
    <PositionFilterTabFrame data-hide={context.hideHeader}>
      <FormProvider {...useFormed}>
        <div>
          {renderResetButton()}
          <CompanyJobTitleFilter reloadPosition={reloadPosition} filteredCount={filteredCount} />
          <CompanyRecruitmentFilter reloadPosition={reloadPosition} filteredCount={filteredCount} />
          <CompanySalaryFilter reloadPosition={reloadPosition} filteredCount={filteredCount} />
          <CompanyLocationFilter reloadPosition={reloadPosition} filteredCount={filteredCount} />
          {/* <CompanyMatchFilter reloadPosition={reloadPosition} filteredCount={filteredCount} /> */}
        </div>
      </FormProvider>
    </PositionFilterTabFrame>
  );
};
export default inject(injectStore.companyModel, injectStore.context)(observer(CompanyDetailFilterTab));
