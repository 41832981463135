import { ErrorMessage } from '@hookform/error-message';
import colors from '__designkit__/common/colors';
import Fonts, { fonts } from '__designkit__/common/fonts';
import { JDBaseInputMixin } from 'consts/_v2/_common/style/mixins';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { forwardRef, useEffect, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import styled from 'styled-components/macro';

const Frame = styled.div<{ height?: number, passboard?:boolean }>`
  ${JDBaseInputMixin()};
  margin-top:8px;
  margin-bottom:8px;
  width:100%;
  height: ${(props) => (props.passboard ? '116px' : 'auto')};
  >div{
    ${(props) => (props.height ? `height:${props.height}px` : `height:200px`)};
    textarea:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px ${colors.JOBDA_WHITE} inset !important;
    }
    textarea:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0 30px ${colors.WHITE_100} inset !important;
    }


    textarea {
      background: inherit;
      font: ${Fonts.B2_Medium};
      height: 100%;
      width:100%;
      border: none;
      outline: none;
      padding: 16px 18px;
      resize:none;
      ::placeholder {
        color: ${colors.CG_60};
        word-break:break-all;
      }
    }
  }

  h3{
    margin-top:8px;
    margin-left:16px;
    font: ${Fonts.B3_Medium};
    color:${colors.CG_70};
  }

`;
interface IJDDescriptionInput extends IComponentProps {
  name: string;
  maxLength: number;
  height?: number;
  customValidator: any;
  disabled?: boolean;
  className?: string;
  limitation?:boolean;
  minLength?:number;
  onlyMax?:boolean;
  onFocus?: () => void;
  passboard?:boolean;
}

const JDDescriptionInput = forwardRef((props: IJDDescriptionInput, fieldRef) => {
  const { name, height, maxLength, disabled, passboard, onlyMax, className, limitation, defaultValue, minLength, onFocus, ...rest } = props;
  const useFormed = useFormContext();
  const { register, errors, watch, setValue, control, trigger } = useFormed;
  const { field: { ref: BaseRef }, meta } = useController({ name, control, defaultValue });

  return (
    <Frame className={`jd-input ${className || ''}  ${!meta.invalid}`} height={height} passboard={passboard}>
      <div>
        <textarea
          name={name}
          ref={function (innerRef) {
            if (fieldRef !== null) (fieldRef as any).current = innerRef;
            if (BaseRef !== undefined) BaseRef.current = innerRef;
            // Warning: IOS SAFARI에서 한글 첫자를 치면 focus 및 키보드 창이 사라지는 문제때매 주석처리 합니다.
            // if (!innerRef?.value) {
            //   innerRef?.blur();
            // }
            register(innerRef);
          }}
          className={`${watch(name) ? 'value' : ''}`}
          disabled={!!disabled}
          onChange={(e) => {
            const { value } = e.currentTarget;
            if (limitation) {
              setValue(name, value);
            } else if (value.length > maxLength) {
              setValue(name, value.slice(0, maxLength));
            } else {
              setValue(name, value);
            }

            if (e.currentTarget.value.length === 0) setValue(name, undefined);
            if (!passboard)trigger(name);
          }}
          value={watch(name)}
          maxLength={maxLength}
          onFocus={onFocus}
          {...rest}
        />
      </div>
      {
    BaseRef.current?.textLength === 0 && errors && (
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <h4 className='message false'>{message}</h4>}
      />
    )
  }
      {
    (BaseRef.current?.textLength !== 0 || !errors) && (
      <>
        {maxLength && !onlyMax && <h3>{`최대 글자수 : ${BaseRef.current?.textLength || 0} / ${maxLength}`}</h3>}
      </>
    )
  }
    </Frame>
  );
});

export default JDDescriptionInput;
