import JDFullModal from 'components/_v2/_common/modals/JDFullModal';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { passwordValidator, rePasswordValidator } from 'consts/ValidationRule';
import ImgLogo from '__designkit__/assets/jobda_app_logo.svg';
import useToast from 'hooks/useToast';
import ErrorCode, { ErrorCodeText } from 'consts/ErrorCodes';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import Icon from '__designkit__/icon/Icon';
import Fonts from '__designkit__/common/fonts';
import Colors from '__designkit__/common/colors';
import { IconLogo } from 'consts/assets/icons/iconPages';
import { FormProvider, useForm } from 'react-hook-form';
import JDPasswordInput from 'components/_v2/_common/input/JDPasswordInput';
import React, { FC, useCallback, useState } from 'react';
import { JDMainButton } from 'consts/_v2/_common/style/mixins';
import styled from 'styled-components';

import { injectStore } from 'models/store';
import AuthorizeUtil from 'utils/AuthorizeUtil';

interface IPasswordChangeModal{
    handleClose?: () => void;
    login?: Login;
    isOpen?:boolean;
}

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  .logo {
    width: 120px;
  }
  
  .lockIcon {
    margin: 20px 0;
  }

  .title {
    font: ${Fonts.H4_Bold};

    .import {
      color: ${Colors.ERROR};
    }
  }

  .subText {
    margin-top: 20px;
    font: ${Fonts.B2_Medium};
    color: ${Colors.CG_70};
    text-align: center;
  }

  .passwordForm {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 20px;
    gap: 20px;
    margin-top: 30px;

    .reInput {
      margin-top: 20px;
    }
  }

  .btn-submit {
      margin-top: 20px;
      ${JDMainButton()};
      height: 46px;
    }

  .btnChange {
    color: ${Colors.CG_60};
    font: ${Fonts.B2_Medium};
    text-decoration: underline;
  }
`;

interface IPasswordChangeFormValues {
  currentPassword:string;
  newPassword:string;
  reNewPassword:string;
}

const validationSchema = yup.object().shape({
  currentPassword: yup.string().test({
    name: 'validation',
    test: (value: any, { createError }) => {
      const customError = passwordValidator(value);

      if (customError === true) return true;
      return createError({ message: customError.toString() });
    },
  }),
  newPassword: yup.string().test({
    name: 'validation',
    test: (value: any, { createError }) => {
      const customError = passwordValidator(value);

      if (customError === true) return true;
      return createError({ message: customError.toString() });
    },
  }),
  reNewPassword: yup.string().test({
    name: 'validation',
    test: (value: any, { createError }) => {
      const customError = passwordValidator(value);

      if (customError === true) return true;
      return createError({ message: customError.toString() });
    },
  })
    .test('equalPassword', '비밀번호가 일치하지 않습니다.', ((value:string, record:any) => (value === (record.parent as IPasswordChangeFormValues).newPassword)) as any),
});

const PasswordChangeModal: FC<IPasswordChangeModal> = ({ handleClose, login = new Login(), isOpen }) => {
  const onClose = () => {
    if (handleClose) {
      handleClose();
    }
  };

  const useFormed = useForm<IPasswordChangeFormValues>({ mode: 'all' });

  const { formState, setError, handleSubmit, reset, watch } = useFormed;

  const onSubmit = useCallback(async (data:IPasswordChangeFormValues) => {
    try {
      const res = await login.updatePassword(data.currentPassword, data.newPassword);
      reset({
        currentPassword: '',
        newPassword: '',
        reNewPassword: '',
      });

      if (res) {
        await AuthorizeUtil.updateAccessToken();
        await login.loadCurrentUser();
        onClose();
      } else {
        throw new Error('failed');
      }
    } catch (e) {
      const err = e as { response: { data: { errorCode: string; message: string } } };
      console.error(err);
      switch (err.response?.data?.errorCode) {
        case ErrorCode.B901:
          setError('currentPassword', { message: ErrorCodeText.put_users_password.B901 });
          break;
        case ErrorCode.B902:
          setError('newPassword', { message: ErrorCodeText.put_users_password.B902 });
          break;
        default:
          setError('reNewPassword', { message: err.response?.data?.message || '알 수 없는 에러입니다.' });
      }
    }
  }, [login, setError, reset]);

  const handleNext = async () => {
    try {
      await login.updatePasswordExpiration();
      await AuthorizeUtil.updateAccessToken();
      await login.loadCurrentUser();
      onClose();
    } catch (e) {
      console.error(e);
    }
  };
  if (!isOpen) return <></>;
  return (
    <JDFullModal handleClose={onClose}>
      <Frame>
        <img className='logo' src={ImgLogo} alt='logo' />
        <Icon className='lockIcon' name='lock-yellow' size={56} />
        <span className='title'>
          비밀번호를 변경한지 <span className='import'>90일</span>이 지났습니다.
        </span>
        <span className='subText'>
          소중한 개인정보 보호를 위해
          <br />
          비밀번호를 변경해 주세요.
        </span>

        <div className='passwordForm'>
          <FormProvider {...useFormed}>
            <div className='row-info full-line'>
              <div className='frame-value'>
                <JDPasswordInput
                  name='currentPassword'
                  placeholder='현재 비밀번호를 입력해 주세요.'
                  customValidator={{ validate: (value: string) => passwordValidator(value) }}
                />
              </div>
            </div>
            <div className='row-info full-line'>
              <div className='frame-value'>
                <JDPasswordInput
                  name='newPassword'
                  placeholder='새 비밀번호를 입력해 주세요.'
                  customValidator={{ validate: (value: string) => passwordValidator(value) }}
                />
              </div>
              <div className='frame-value reInput'>
                <JDPasswordInput
                  name='reNewPassword'
                  placeholder='새 비밀번호를 한번 더 입력해 주세요.'
                  customValidator={{ validate: (value: string) => rePasswordValidator(value, watch('newPassword')) }}
                />
              </div>
            </div>
            <button type='button' className='btn-submit' disabled={!formState.isValid} onClick={handleSubmit(onSubmit)}>
              변경사항 저장
            </button>
            <button onClick={handleNext} type='button' className='btnChange'>
              다음에 변경하기
            </button>
          </FormProvider>
        </div>
      </Frame>
    </JDFullModal>
  );
};
export default inject(injectStore.login)(observer(PasswordChangeModal));
