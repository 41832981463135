import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import ImgOne from 'assets/_v2/company/ImgOne.png';
import ImgThree from 'assets/_v2/company/ImgThree.png';
import ImgTwo from 'assets/_v2/company/ImgTwo.png';
import JDSwiper, { JDBullets, JDSwiperSlide } from 'components/_v2/_common/JDSwiper';
import { ICompanyThemeDto } from 'interfaces/_v2/company/ICompanyInformation';
import { inject, observer } from 'mobx-react';
import CompanyModel from 'models/_v2/CompanyModel';
import { injectStore } from 'models/store';
import React, { FC } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
    width: 100%;
    height: fit-content;
    background-color: ${colors.CG_30};
    .swiper-slide{
      height: initial;
    }
    .main-custom-slide{
    width:300px;
    margin-right: 16px;
    display: flex;
    justify-content: center;
 
  }
  .pagination-bullets{
      margin:32px 0 40px 0;
    }
`;
const ThemeCardFrame = styled.div`
    position:relative;
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 100%;
    min-height: 340px;
    padding: 32px;
    background-color: ${colors.WHITE_100};
    border-radius: 16px;
    box-shadow: 16px 32px 32px 0px rgba(0, 0, 0, 0.06);

    .title-text{
        font:${Fonts.H4_Bold};
        color:${colors.JOBDA_BLACK};
        white-space: pre-line;
    }
    .sub-text{
        font:${Fonts.B2_Medium};
        color:${colors.CG_70};
        word-break: keep-all;
    }
    .img{
        width: 120px;
        height: 120px;
        align-self: flex-end;
    }
`;

interface ICompanyDetailThemeCollection{
    companyModel?:CompanyModel
}
const CompanyDetailThemeCollection:FC<ICompanyDetailThemeCollection> = ({ companyModel = new CompanyModel() }) => {
  const ImageCollection = [
    { img: ImgOne },
    { img: ImgTwo },
    { img: ImgThree },
  ];

  const ThemeCard = (item:ICompanyThemeDto, index:number) => (
    <ThemeCardFrame>
      <div className='title-text'>{`${item.adjective}\n${item.name}`}</div>
      <SpacingBlock vertical size={12} />
      <div className='sub-text'>{item.review}</div>
      <SpacingBlock vertical size={24} />
      <div className='img'>
        <img src={ImageCollection[index].img} alt='img' className='img' />
      </div>
    </ThemeCardFrame>
  );
  return (
    <Frame>
      <SpacingBlock vertical size={40} />
      <JDSwiper
        slidesPerView='auto'
        // autoplay={{ delay: 3000 }}
        pagination={JDBullets}
        navigation
        slidesOffsetBefore={32}
      >
        {companyModel.companyTheme && companyModel.companyTheme.companyThemeDtos.map((card, index) => (
          <JDSwiperSlide key={card.name} className='main-custom-slide'>
            { ThemeCard(card, index) }
          </JDSwiperSlide>
        ))}
        <div className='pagination-bullets' />

      </JDSwiper>
    </Frame>
  );
};

export default inject(injectStore.companyModel)(observer(CompanyDetailThemeCollection));
