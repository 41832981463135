import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import { CompanyInformationTitle } from 'components/_v2/company/CompanyDetailInformation';
import { inject, observer } from 'mobx-react';
import CompanyModel from 'models/_v2/CompanyModel';
import { injectStore } from 'models/store';
import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components';
import { formatNumber } from 'utils/NumberFormatUtil';

const salaryBarAnimation = keyframes`
  0%, 11% {
    transform: scaleY(0.2);
  }
  22%, 33% {
    transform: scaleY(0.6);
  }
  44%, 55% {
    transform: scaleY(0.8);
  }
  66%, 77% {
    transform: scaleY(0.3);
  }
  88%, 100% {
    transform: scaleY(0.7);
  }
`;

const industryBarAnimation = keyframes`
   0%,
  14% {
    transform: scaleY(0.6);
  }
  28%,
  42% {
    transform: scaleY(0.2);
  }
  56%,
  70% {
    transform: scaleY(0.5);
  }
  84%,
  100% {
    transform: scaleY(0.8);
  }
`;

const Frame = styled.div`
  padding: 64px 16px 0 16px;
  .update-text{
      font:${Fonts.B4_Medium};
      color:${colors.CG_60};
    }
`;

const SalaryContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 32px;
    justify-content: center;
    align-items: center;
    height: fit-content;
    width: 100%;
    border: 1px solid ${colors.CG_40};
    border-radius: 8px;
    .container-header{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .title-text{
            font:${Fonts.H4_Bold};
            color:${colors.JOBDA_BLACK};
            margin-bottom: 8px;
        }
        .sub-text{
            font:${Fonts.B2_Medium};
            color:${colors.CG_70}
        }
    }
`;

const Divider = styled.div`
        width: 100%;
        height: 1px;
        background-color:${colors.CG_40};
        margin:32px 0;
`;
const SalaryBottom = styled.div`
    display: flex;
    justify-content: center;
`;
const GraphContainer = styled.div<{money?:number, myCompany?:boolean}>`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: end;
      .graph-number{
          font:${Fonts.B2_Bold};
          color:${colors.CG_70};
          margin-bottom: 16px;
      }
      .graph-sub-text{
          font:${Fonts.B3_Medium};
          color:${colors.CG_70};
      }
      .graph-bar{
          background-color: ${(props) => (props.myCompany ? `${colors.B_90}` : `${colors.CG_40}`)};
          width:40px;
          height:${(props) => (props.money ? `${props.money / 1000000}px` : '110px')};
          max-height:110px;
          margin-bottom:16px;
          border-radius:4px;
          &.noData {
              transform-origin: bottom;
              animation: ${salaryBarAnimation} 8s infinite alternate cubic-bezier(0.5, 0, 0.5, 1);
            }
          &.noDataIndustry {
              transform-origin: bottom;
              animation: ${industryBarAnimation} 8s infinite alternate cubic-bezier(0.5, 0, 0.5, 1);
            }
      }
`;
interface ICompanyDetailAverageSalary{
    companyModel?:CompanyModel
}
const CompanyDetailAverageSalary:FC<ICompanyDetailAverageSalary> = ({ companyModel = new CompanyModel() }) => {
  const a = '';
  return (
    <Frame>
      {
      companyModel.companyInformation
      && (
      <>
        <CompanyInformationTitle>평균 연봉</CompanyInformationTitle>
        <SpacingBlock vertical size={8} />
        {
          companyModel.companyInformation.lastModifiedDate && (
            <div className='update-text'>업데이트 일자 : {companyModel.companyInformation.lastModifiedDate.replace(/-/g, '.')}</div>
          )
        }
        <SpacingBlock vertical size={24} />

        <SalaryContainer>
          <div className='container-header'>
            <div className='title-text'>{companyModel.companyInformation.companyDetailDto.averageSalary ? formatNumber(Math.floor(companyModel.companyInformation.companyDetailDto.averageSalary)) : '데이터 준비 중'}{companyModel.companyInformation.companyDetailDto?.averageSalary ? '원' : ''}</div>
            <div className='sub-text'>전체 종업원 평균</div>
          </div>
          <Divider />
          <SalaryBottom>
            <GraphContainer myCompany money={companyModel.companyInformation.companyDetailDto.averageSalary}>
              <div className='graph-number'>{companyModel.companyInformation.companyDetailDto.averageSalary ? formatNumber(Math.floor(companyModel.companyInformation.companyDetailDto.averageSalary)) : ''}{companyModel.companyInformation.companyDetailDto?.averageSalary ? '원' : ''}</div>
              <div className={`graph-bar ${!companyModel.companyInformation.companyDetailDto.averageSalary && `noData`}`} />
              <div className='graph-sub-text'>{companyModel.companyInformation.companyName}</div>
            </GraphContainer>
            <SpacingBlock horizontal size={40} />
            <GraphContainer money={companyModel.companyInformation.companyDetailDto.industryAverageSalary || 40000000}>
              <div className='graph-number'>{companyModel.companyInformation.companyDetailDto.industryAverageSalary ? formatNumber(Math.floor(companyModel.companyInformation.companyDetailDto.industryAverageSalary)) : ''}{companyModel.companyInformation.companyDetailDto?.industryAverageSalary ? '원' : ''}</div>
              <div className={`graph-bar ${!companyModel.companyInformation.companyDetailDto.industryAverageSalary && `noDataIndustry`}`} />
              <div className='graph-sub-text'>동종 업종</div>
            </GraphContainer>
          </SalaryBottom>
        </SalaryContainer>
      </>
      )
    }
    </Frame>
  );
};

export default inject(injectStore.companyModel)(observer(CompanyDetailAverageSalary));
