import React from 'react';
import main from '__pc__/graphic/main.json';
import classnames from 'classnames/bind';
import LottieFile from 'utils/LottieFile';
import styles from './mainKeyVisual.module.scss';

const cx = classnames.bind(styles);

const MainKeyVisual = () => (
  <div className={cx('mainVisual')}>
    <div className={cx('visualWrap')}>
      <LottieFile animationData={main} loopCondition={false} />
    </div>
  </div>
);

export default MainKeyVisual;
