/* eslint-disable array-callback-return */
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import JDBottomFixedButton from 'components/_v2/_common/button/JDBottomFixedButton';
import JDFullModal from 'components/_v2/_common/modals/JDFullModal';
import { TOAST_TYPE } from 'components/_v2/_common/toast/JDToast';
import ProfileAdditionInfoForm from 'components/_v2/positionJd/ProfileAdditionInfoForm';
import ProfileMoveDirtyModal from 'components/_v2/profile/ProfileMoveDirtyModal';
import { ResumeFormType } from 'consts/_v2/profile/ProfileType';
import useToast from 'hooks/useToast';
import { IChoice, IFilesRqRs, IResumeAdditionInfoApplyDto, IResumeAdditionInfoApplyRq, IResumeAdditionInfoTemp, IResumeAdditionInfoTempDto, IResumePreviewAdditionInfo, IResumePreviewAdditionInfoDto } from 'interfaces/_v2/positionJd/IResumeAdditionInfoRqRs';
import { inject, observer } from 'mobx-react';
import PositionJdModel from 'models/_v2/PositionJdModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { UseFormMethods } from 'react-hook-form';
import styled from 'styled-components';
import AdditionalMoveDirtyModal from '../profile/AdditionalMoveDirtyModal';

const Frame = styled.div`
    padding: 56px 16px 154px;
`;

const TiTle = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    >span {
        font: ${Fonts.H5_Bold};
        color: ${colors.JOBDA_BLACK};
        margin-left: 4px;
    }
`;
interface IProfileAdditionInfoProps {
    handleClose: () => void;
    positionJdModel?: PositionJdModel;
    useFormed: UseFormMethods<IResumeAdditionInfoTemp>;
}
const ProfileAdditionInfoModal: FC<IProfileAdditionInfoProps> = ({ handleClose, positionJdModel = new PositionJdModel(), useFormed }) => {
  const { formState, setValue, getValues, reset, setError, errors, clearErrors, watch } = useFormed;
  const [isLoading, setIsLoading] = useState(true);
  const [isDirtyModalOpen, setIsDirtyModalOpen] = useState<boolean>(false);

  const { setToastObject } = useToast();
  const closeModal = () => {
    if (positionJdModel.isDirty || formState.isDirty) {
      setIsDirtyModalOpen(true);
    } else handleClose();
  };
  const closeDirtyModal = () => {
    setIsDirtyModalOpen(false);
    handleClose();
    reset();
  };
  useEffect(() => {
    setIsLoading(true);
    if (positionJdModel.profileAdditionalInfo) {
      const newFormValues: IResumeAdditionInfoTemp = { additionInfo: [] };

      positionJdModel.profileAdditionalInfo.forEach((info, idx) => {
        const { answer } = info;
        const additionInfoItem: IResumeAdditionInfoTempDto = {
          positionAdditionInfoSn: info.additionInfoSn,
          question: info.question,
          type: info.type,
          answer: answer?.answer || '',
          choices: [],
          files: [],
          selectedChoice: '',
        };

        // SINGLE_CHOICE 및 DROPDOWN 타입 처리
        if ((info.type === ResumeFormType.SINGLE_CHOICE || info.type === ResumeFormType.DROPDOWN) && answer?.choices) {
          const selectedChoice = answer.choices.find((choice) => choice.selectYn)?.name;
          if (selectedChoice) {
            additionInfoItem.selectedChoice = selectedChoice;
          }
        }

        // MULTI_CHOICE 타입 처리
        // if (info.type === ResumeFormType.MULTI_CHOICE && answer?.choices) {
        //   const selectedChoices = answer.choices
        //     .filter((choice) => choice.selectYn)
        //     .map((choice) => choice.name);

        //   additionInfoItem.choices = [...new Set(selectedChoices)]; // 중복 제거
        // }
        // ADDITION_ATTACH_FILE 타입 처리
        if (info.type === ResumeFormType.ADDITION_ATTACH_FILE && answer?.files) {
          const transformedFiles = answer.files.map((file) => ({
            fileUid: file.uid,
            fileName: file.name,
            fileSize: 0,
            fileUrl: '',
          }));
          additionInfoItem.files = transformedFiles;
        }
        newFormValues.additionInfo[idx] = additionInfoItem;
      });
      positionJdModel.tempAdditionalInfo = newFormValues;
      reset(newFormValues);
    }
    setIsLoading(false);
  }, [positionJdModel.profileAdditionalInfo]);

  const checkValidation = async () => {
    const formData = getValues();
    positionJdModel.tempAdditionalInfo = formData;
    const additionInfo:IResumeAdditionInfoApplyDto[] = [];
    const tempInfo = formData.additionInfo;
    const originalInfo = positionJdModel.profileAdditionalInfo;
    const previewAdditionInfoTemp: IResumePreviewAdditionInfoDto[] = [];
    if (originalInfo && tempInfo) {
      tempInfo.forEach((info, idx) => {
        const originalChoices = originalInfo[idx].choices || [];
        let choicesTransformed: IChoice[] = [];

        // SINGLE_CHOICE 및 DROPDOWN 처리
        if (originalInfo[idx].type === ResumeFormType.SINGLE_CHOICE || originalInfo[idx].type === ResumeFormType.DROPDOWN) {
          const selectedChoiceReal = info.selectedChoice; // This is the selected choice from the form
          choicesTransformed = originalChoices.map((originalChoice) => ({
            name: originalChoice,
            selectYn: originalChoice === selectedChoiceReal, // Set selectYn to true only for the selected choice
          }));
        } else {
          // MULTI_CHOICE 및 기타 타입 처리
          choicesTransformed = originalChoices.map((originalChoice) => {
            const isSelected = info.choices ? info.choices.includes(originalChoice) : false;
            return {
              name: originalChoice,
              selectYn: isSelected,
            };
          });
        }
        let filesTransformed: IFilesRqRs[] = [];
        if (info.files) {
          if (Array.isArray(info.files)) {
            filesTransformed = info.files.map((file) => ({
              uid: file.fileUid,
              name: file.fileName,
            }));
          }
        }
        additionInfo.push({
          positionAdditionInfoSn: originalInfo[idx].additionInfoSn,
          question: originalInfo[idx].question,
          type: originalInfo[idx].type,
          answer: info?.answer ? info.answer : '',
          choices: choicesTransformed,
          files: filesTransformed,
        });

        previewAdditionInfoTemp.push({
          question: originalInfo[idx].question,
          type: originalInfo[idx].type,
          answer: info?.answer ? info.answer : '',
          choices: choicesTransformed,
          files: info?.files,
        });
      });
    }
    const additionInfoRq: IResumeAdditionInfoApplyRq = { additionInfo };

    const additionPreview: IResumePreviewAdditionInfo = { additionInfo: previewAdditionInfoTemp };
    await positionJdModel.putProfileAdditionInfo(positionJdModel.positionSn, additionInfoRq.additionInfo);
    setToastObject({ isOpen: true, type: TOAST_TYPE.SUCCESS, message: '성공적으로 저장되었습니다.', duration: 1500 });
    handleClose();
  };
  return (
    <JDFullModal closeCustom>
      <Frame>
        <TiTle>
          <Icon name='file-add' size={32} />
          <span>추가 정보</span>
        </TiTle>
        {
            !isLoading && positionJdModel?.profileAdditionalInfo && positionJdModel?.profileAdditionalInfo.map((item, idx) => (
              <ProfileAdditionInfoForm key={item.additionInfoSn} info={item} idx={idx} formState={formState} errors={errors} clearErrors={clearErrors} />
            ))
        }
      </Frame>
      <JDBottomFixedButton
        primaryLabel='저장'
        handleSuccess={checkValidation}
        defaultClick={closeModal}
        defaultLabel='이전'
      />
      <AdditionalMoveDirtyModal
        isOpen={isDirtyModalOpen}
        onSuccess={() => closeDirtyModal()}
        onClickClose={() => {
          setIsDirtyModalOpen(false);
          positionJdModel.setIsDirty(true);
        }}
      />
    </JDFullModal>
  );
};
// 기획자 이진오님의 강력한 요구로 진행된 작업으로써 불필요한 항목으로 사료됨
export default inject(injectStore.positionJdModel)(observer(ProfileAdditionInfoModal));
