import contentVideo from '__pc__/page/accaIntroduce/@assets/video/videoGate.mp4';
import classnames from 'classnames/bind';
import React from 'react';
import styles from './intro.module.scss';

const cx = classnames.bind(styles);

const Intro = () => {
  const [isVideoPlay, setIsVideoPlay] = React.useState(false);

  return (
    // 인트로
    <div className={cx('introInner')}>
      {/* 메인 비쥬얼 영상 */}
      <video
        className={cx(`main_video`, isVideoPlay && 'play')}
        autoPlay
        playsInline
        muted
        loop
        onPlay={() => {
          setIsVideoPlay(true);
        }}
      >
        <source src={contentVideo} type='video/mp4' />
      </video>
      {/* 메인 텍스트 */}
      <div className={cx('introTitle')}>
        <p className={cx('main_text')}>성공적인 취업의 지름길, JOBDA</p>
        <strong className={cx('main_title')}>스펙과 자소서가 아닌<br />‘진짜’ 역량으로<br /> 취업하세요.</strong>
      </div>
    </div>
  );
};

export default Intro;
