import imgBottomBanner from 'assets/img/ai/introduce/img_introduce_bottom_banner.png';
import AiIntroduceAiInfo from 'components/aiIntroduce/AiIntroduceAiInfo';
import AiIntroduceAiResultInfo from 'components/aiIntroduce/AiIntroduceAiResultInfo';
import AiIntroduceBanner from 'components/aiIntroduce/AiIntroduceBanner';
import AiIntroduceBenefits from 'components/aiIntroduce/AiIntroduceBenefits';
import AiIntroduceCompanyInfo from 'components/aiIntroduce/AiIntroduceCompanyInfo';
import AiIntroduceJobdaInfo from 'components/aiIntroduce/AiIntroduceJobdaInfo';
import LazyImage from 'components/common/LazyImage';
import JDAWhyBottomBanner from 'components/JDAWhyBottomBanner';
import RoutePaths from 'consts/RoutePaths';
import colors from '__designkit__/common/colors';
import { fonts } from '__designkit__/common/fonts';
import React from 'react';
import styled from 'styled-components/macro';

const Frame = styled.div`
  height:100%;
  .bottom-banner {
    .frame-title {
      margin-top:8px;
      * {
        ${fonts.NOTO_24_700};
        line-height:24px;
        letter-spacing:0.18px;
        color:${colors.CG_90};
      }
      strong {
        ${fonts.WEMAKEP_24_700};
        display: inline-block;
        transform: translateY(-0.05em);
      }
    }
    .img-bottom-banner {
      width:160px;
      height:154px;
      position:absolute;
      bottom:0;
      right:18px;
    }
  }
`;

const AiIntroduce: React.FC = () => (
  <Frame>
    <AiIntroduceBanner />
    <AiIntroduceCompanyInfo />
    <AiIntroduceJobdaInfo />
    <AiIntroduceAiInfo />
    <AiIntroduceAiResultInfo />
    <AiIntroduceBenefits />
    <JDAWhyBottomBanner
      className='bottom-banner'
      subTitle='나만의 역량은 과연 무엇일까!'
      title={<span><strong>역량검사</strong> 리포트<br />지금바로 확인하기</span>}
      message='확인하러 가기'
      icon={<LazyImage className='img-bottom-banner' src={imgBottomBanner} />}
      url={RoutePaths.acc_result_competency}
    />
  </Frame>
);

export default AiIntroduce;
