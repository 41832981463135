import LazyImage from 'components/common/LazyImage';
import colors from '__designkit__/common/colors';
import { fonts } from '__designkit__/common/fonts';
import IAccIntroduceBenefit from 'interfaces/IAccIntroduceBenefit';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { FC } from 'react';
import styled from 'styled-components';
import iconRight from 'assets/icon/icon_right_simple_16_Regular_grey.svg';
import aiIntroduceBenefitTitles from 'consts/style/mixins/aiIntroduce';

//* * 역량검사로 알 수 있습니다 카드 */

const Frame = styled.article`
  margin:0 auto;
  width:312px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.12); /* 일단 여기서밖에 안쓸 것 같아서.. */ 
  background:${colors.WHITE_100};
  border-radius:16px;
  padding-bottom:24px;
  overflow:hidden;
  ${aiIntroduceBenefitTitles};
  .frame-img {
    width:100%;
    height:210px;
    background:${colors.c000000_06};
    img:not(.img-fallback) {
      width:100%;
      height:100%;
      object-fit:cover;
    }
  }
  .btn-more {
    margin-left:24px;
    margin-top:24px;
    width:153px;
    height:40px;
    border-radius:20px;
    ${fonts.NOTO_15_400};
    background: ${colors.CG_30};
    color:${colors.CG_70};
    line-height:32px;
    letter-spacing:-0.24px;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    &:after {
      width:16px;
      height:16px;
      content:'';
      background:red;
      margin-left:12px;
      background:url(${iconRight});
    }
  }
`;

interface IAiIntroduceBenefitCardProps extends IComponentProps {
  benefit:IAccIntroduceBenefit;
  onClickMore: () => void;
}

const AiIntroduceBenefitCard:FC<IAiIntroduceBenefitCardProps> = ({
  benefit: {
    title,
    subTitle,
    image,
  },
  onClickMore,
}) => (
  <Frame>
    <LazyImage className='frame-img' src={image} />
    <h2>{subTitle}</h2>
    <h1>{title}</h1>
    <button className='btn-more' onClick={onClickMore}>
      자세히보기
    </button>
  </Frame>
);

export default AiIntroduceBenefitCard;
