import React, { FC, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Colors from '__designkit__/common/colors';
import IComponentProps from 'interfaces/props/IComponentProps';
import Fonts from '__designkit__/common/fonts';
import JDCheckInput, { JD_CHECK_INPUT_TYPE } from 'components/_v2/_common/input/JDCheckInput';
import { useFormContext } from 'react-hook-form';

const SELECTED_FRAME_HEIGHT = 60;

const Frame = styled.div`
  display: flex;
  flex-direction: column;
`;

const GroupFrame = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px 12px;
`;

const Group = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(50% - 6px);
  height: 48px;
  border-radius: 4px;
  border: 1px solid ${Colors.CG_50};
  font: ${Fonts.B1_Medium};
  cursor: pointer;

  ${({ active }) => active
    && css`
      background-color: ${Colors.JOBDA_WHITE};
      border: 1px solid ${Colors.JOBDA_BLACK};
      color: ${Colors.JOBDA_BLACK};
      font: ${Fonts.B1_Bold};
    `}
`;

const ItemFrame = styled.div<{ active: boolean }>`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
  padding-bottom: 78px;
  gap: 8px 12px;
  height: calc(100% - ${SELECTED_FRAME_HEIGHT}px - 80px);
  font: ${Fonts.B1_Medium};

  &[aria-hidden=true] {
    display: none;
  }

  .step-subTitle {
    width: 100%;
    margin: 40px 0 16px 0;
    text-align: center;
    font: ${Fonts.H4_Bold};
  }
`;

const CheckInputWrapper = styled.div<{ isChecked: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(50% - 6px);
  height: 48px;
  border-radius: 4px;
  border: 1px solid ${Colors.CG_50};
  font: ${Fonts.B1_Medium};

  div {
    width: 100%;
    height: 100%;

    label {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }

  ${({ isChecked }) => isChecked
    && css`
      background-color: ${Colors.JOBDA_WHITE};
      border: 1px solid ${Colors.JOBDA_BLACK};
      color: ${Colors.JOBDA_BLACK};
      font: ${Fonts.B1_Bold};
    `}
`;

export interface TwoDepthItem {
  code: number;
  name: string;
  children: Item[];
}

interface Item {
  code: number;
  name: string;
  value: any;
}

interface IJobGroupSelectViewProps extends IComponentProps {
  name: string;
  jobGroups: TwoDepthItem[];
}

const JobGroupSelectView: FC<IJobGroupSelectViewProps> = ({ name, jobGroups }) => {
  const [currentGroup, setCurrentGroup] = useState<number>(-1);
  const formMethods = useFormContext();
  const { watch, setValue, reset } = formMethods;
  const itemFrameRef = useRef<HTMLDivElement | null>(null);

  const handleJobClick = (code: number) => {
    // 현재 선택된 그룹을 업데이트
    setCurrentGroup(code);
    jobGroups.forEach((jobGroup) => {
      if (jobGroup.code !== code) {
        jobGroup.children.forEach((job) => {
          setValue(`${name}.${job.code}`, false); // 선택 상태를 false로 설정
        });
      }
    });
  };

  useEffect(() => {
    if (currentGroup !== -1 && itemFrameRef.current) {
      itemFrameRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [currentGroup]);

  return (
    <Frame>
      <GroupFrame>
        {jobGroups.map((jobGroup) => (
          <Group
            key={jobGroup.code}
            active={currentGroup === jobGroup.code}
            onClick={() => handleJobClick(jobGroup.code)}
          >
            {jobGroup.name}
          </Group>
        ))}
      </GroupFrame>
      {jobGroups.map((jobGroup) => (
        <ItemFrame
          key={jobGroup.code}
          active={currentGroup === jobGroup.code}
          aria-hidden={currentGroup !== jobGroup.code}
          ref={currentGroup === jobGroup.code ? itemFrameRef : null}
        >
          <div className='step-subTitle'>
            원하는 직무를 알려주세요.
          </div>
          {jobGroup.children.map((job) => {
            const isChecked = watch(`${name}.${job.code}`);
            return (
              <CheckInputWrapper key={`${name}-${job.code}`} isChecked={isChecked}>
                <JDCheckInput
                  type={JD_CHECK_INPUT_TYPE.CUSTOM_CHIP}
                  name={`${name}.${job.code}`}
                  value={job.value}
                  label={job.name}
                  defaultChecked={isChecked === false}
                />
              </CheckInputWrapper>
            );
          })}
        </ItemFrame>
      ))}
    </Frame>
  );
};

export default JobGroupSelectView;
