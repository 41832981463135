import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import IModalFrameProps from 'interfaces/IModalFrameProps';
import React, { FC } from 'react';
import styled from 'styled-components';
import { JDAModalButton, JDAModalContentDescription, JDAModalContentText } from './JDAModalComponents';
import JDAModalFrame from './JDAModalFrame';

const StyledModalContent = styled.div`
  text-align: center;
  padding: 24px;
  
  ${JDAModalContentText} {
    margin-top: 16px;
    margin-bottom: 8px;
    color: ${colors.CG_80};
  }

  ${JDAModalContentDescription} {
      font: ${Fonts.B1_Medium_P};
      margin-bottom: 16px;
      color: ${colors.CG_80};
  }

`;

const MatchScheduleFailModal:FC<IModalFrameProps> = ((props) => {
  const { onClickClose, ...rest } = props;
  return (
    <JDAModalFrame {...rest}>
      <div style={{ width: 312 }}>
        <StyledModalContent>
          <JDAModalContentText>
            다른 면접 시간을 선택해 주세요.
          </JDAModalContentText>
          <JDAModalContentDescription>
            이미 마감된 시간이에요.<br />
            만약 원하는 시간이 없다면 <br />
            인사 담당자에게 연락해 주세요.
          </JDAModalContentDescription>

          <JDAModalButton onClick={onClickClose}>확인</JDAModalButton>
        </StyledModalContent>
      </div>
    </JDAModalFrame>
  );
});

export default MatchScheduleFailModal;
