/* eslint-disable no-mixed-operators */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-unused-vars */
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import BarChart from 'components/_v2/_common/chart/barChart';
import { CompanyInformationTitle } from 'components/_v2/company/CompanyDetailInformation';
import { Divider1G40 } from 'components/divider/Divider';
import { BusinessSizeTypeText } from 'consts/_v2/positionJd/BusinessSize';
import { inject, observer } from 'mobx-react';
import CompanyModel from 'models/_v2/CompanyModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { barFormatNumber, formatNumber, krNumberFormat } from 'utils/NumberFormatUtil';
import classnames from 'classnames/bind';
import JDRadioInput, { JD_RADIO_INPUT_TYPE } from 'components/_v2/_common/input/JDRadioInput';
import JDSelector, { JDSelectorType } from 'components/_v2/_common/input/JDSelector';
import LineChart from 'components/_v2/_common/chart/lineChart';
import { FormProvider, useForm } from 'react-hook-form';
import TooltipSmall from 'components/_v2/_common/chart/lineChart/tooltipSmall';
import useScrollAniRef from 'components/useScrollAniRef';
import { ChartData } from 'components/_v2/_common/chart/lineChart/data';
import styles from '../_common/chart/barChart/barChart.module.scss';

const cx = classnames.bind(styles);
const Frame = styled.div`
    padding:0 16px;
    .update-text{
      font:${Fonts.B4_Medium};
      color:${colors.CG_60};
    }
`;

const GridContainer = styled.div`
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  display: grid;
  grid-row-gap: 32px;
  grid-column-gap: 20px;
  background-color: ${colors.CG_30};
  border-radius: 8px;
  padding:20px 20px;
  justify-content: center;
  align-items: center;
`;

const StabilityBox = styled.div`
  display: flex;
  flex-direction: column;
  .title-text{
    font:${Fonts.B3_Medium};
    color:${colors.CG_70};
  }
  .divider-text{
    display:flex;
    flex-direction: row;
    align-items: center;
    .small-divider{
      height: 12px;
      width: 1px;
      background-color:${colors.CG_40};
      margin:0 8px;
    }
    .sub-divider-text{
    font:${Fonts.B1_Bold};
    color:${colors.JOBDA_BLACK};
  }
  }
  .sub-text{
    font:${Fonts.B1_Bold};
    color:${colors.JOBDA_BLACK};
  }
  
`;

const GraphContainer = styled.div`
    display: flex;
    padding: 0 16px;
    .bar-graph{
    margin-bottom: 56px;
    width:100%;
    height:250px;
  }
`;
const BarGraphContainer = styled.div`
  .graph-menu {
    display: flex;
    justify-content: space-between;
    .radio-menu {
      display: flex;
      align-self: flex-start;
      margin-right: 8px;
    }
  }
`;

const LineGraphContainer = styled.div`
  margin-top: 32px;
  .graph-menu {
    display: flex;
    justify-content: space-between;
    .radio-menu {
      display: flex;
      align-self: flex-start;
      margin-right: 8px;
    }
  }
  .graph{
  margin:32px auto 8px;    
  width:340px;
  height:200px;
  }
`;
interface ICompanyFinanceBar {
  value: number;
  name: string;
  row: string;
}

interface ICompanyDetailEmploymentStability{
    companyModel?:CompanyModel
}

const CompanyDetailEmploymentStability:FC<ICompanyDetailEmploymentStability> = ({ companyModel = new CompanyModel() }) => {
  const { companyFinance } = companyModel;

  useEffect(() => {
    if (companyFinance) {
      const revenueTmp:ICompanyFinanceBar[] = [];
      const profitTmp:ICompanyFinanceBar[] = [];
      let maxRevenue = 0;
      let maxProfit = 0;

      companyFinance.revenues.forEach((year) => {
        const tmp = {
          value: year.amount * 10000,
          name: year.amount === 0 ? '0' : barFormatNumber(year.amount),
          row: year.amount === 0 ? '' : year.year.toString(),
        };
        revenueTmp.push(tmp);
        if (year.amount > maxRevenue) {
          maxRevenue = year.amount;
        }
      });

      companyFinance.profits.forEach((year) => {
        const tmp = {
          value: year.amount * 10000,
          name: year.amount === 0 ? '0' : barFormatNumber(year.amount),
          row: year.amount === 0 ? '' : year.year.toString(),
        };
        profitTmp.push(tmp);
        if (year.amount > maxProfit) {
          maxProfit = year.amount;
        }
      });

      setRevenuesData(revenueTmp);
      setProfitsData(profitTmp);
    }
  }, [companyFinance]);

  const barChartData = (value: ICompanyFinanceBar[]) => value.map((item) => ({
    value: item.value,
    name: item.name,
    row: item.row,
  }));

  const FormatNumberLimit: number = 100000000;
  const [revenuesData, setRevenuesData] = useState<ICompanyFinanceBar[]>([]);
  const [profitsData, setProfitsData] = useState<ICompanyFinanceBar[]>([]);

  useEffect(() => {
    if (companyModel.companyFinance) {
      const revenueTmp:ICompanyFinanceBar[] = [];
      const profitTmp:ICompanyFinanceBar[] = [];
      let maxRevenue = 0;
      let maxProfit = 0;

      companyModel.companyFinance.revenues.forEach((year) => {
        const tmp = {
          value: year.amount * 10000,
          name: year.amount === 0 ? '0' : barFormatNumber(year.amount),
          row: year.amount === 0 ? '' : year.year.toString(),
        };
        revenueTmp.push(tmp);
        if (year.amount > maxRevenue) {
          maxRevenue = year.amount;
        }
      });

      companyModel.companyFinance.profits.forEach((year) => {
        const tmp = {
          value: year.amount * 10000,
          name: year.amount === 0 ? '0' : barFormatNumber(year.amount),
          row: year.amount === 0 ? '' : year.year.toString(),
        };
        profitTmp.push(tmp);
        if (year.amount > maxProfit) {
          maxProfit = year.amount;
        }
      });

      setRevenuesData(revenueTmp);
      setProfitsData(profitTmp);
    }
  }, [companyModel.companyFinance]);

  type RevenueProfitType = 'revenue' | 'profit'
  type ToolTipType = 'black' | 'green' | 'blue';

  const [revenueSelector, setRevenueSelector] = useState<boolean>(false);
  const [profitSelector, setProfitSelector] = useState<boolean>(false);
  const [yearSelector, setYearSelector] = useState<number[]>([]);
  const [selectedYear, setSelectedYear] = useState<number>(0);
  const [totalSelector, setTotalSelector] = useState<boolean>(true);
  const [newSelector, setNewSelector] = useState<boolean>(true);
  const [resignSelector, setResignSelector] = useState<boolean>(true);
  const [lineGraphType, setLineGraphType] = useState<RevenueProfitType>('revenue');
  const [graphColor, setGraphColor] = useState<ToolTipType>('black');
  const [totalColumn, setTotalColumn] = useState<string[]>(['0명', '100명', '200명', '300명', '400명', '500명', '600명']);
  const [newColumn, setNewColumn] = useState<string[]>(['0명', '100명', '200명', '300명', '400명', '500명', '600명']);
  const [resignColumn, setResignColumn] = useState<string[]>(['0명', '100명', '200명', '300명', '400명', '500명', '600명']);
  const [graphRows, setGraphRows] = useState<string[]>([]);
  const [totalChartData, setTotalChartData] = useState<number[]>([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [newChartData, setNewChartData] = useState<number[]>([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [resignChartData, setResignChartData] = useState<number[]>([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [totalMax, setTotalMax] = useState<number>(0);
  const [newMax, setNewMax] = useState<number>(0);
  const [resignMax, setResignMax] = useState<number>(0);

  const [visibleEmployee, setVisibleEmployee] = useState<boolean>(false);

  const refEmployee = useScrollAniRef<HTMLDivElement>({
    effectClass: 'visible',
    activeCallback: () => setVisibleEmployee(true),
    visible: true,
    threshold: 0.9,
  });
  const useFormed = useForm({ mode: 'onSubmit' });
  const { watch, setValue } = useFormed;
  const getChartDataSmall = (chartData:number[], maxNum:number, type: ToolTipType): ChartData[] => chartData.map((value, i) => ({
    column: value / (calcUnit(maxNum) * 4 / 5),
    toolTip: <TooltipSmall value={`총 ${value}명`} type={type} />,
  }));

  useEffect(() => {
    const init = async () => {
      const tmpYear:number[] = [];
      if (companyModel.companyEmployment && companyModel.companyEmployment.yearlyEmployments) {
        companyModel.companyEmployment.yearlyEmployments.map((yearEmployment, idx) => {
          if (companyModel.companyEmployment?.yearlyEmployments && idx === companyModel.companyEmployment?.yearlyEmployments.length - 1) {
            setSelectedYear(yearEmployment.year);
          }
          tmpYear.push(yearEmployment.year);
        });
      }
      setYearSelector(tmpYear);
    };
    init();
  }, [companyModel.companyEmployment]);

  useEffect(() => {
    if (companyModel.companyEmployment && companyModel.companyEmployment.yearlyEmployments) {
      companyModel.companyEmployment.yearlyEmployments.map((yearEmployment) => {
        if (yearEmployment.year === selectedYear) {
          setTotalSelector(yearEmployment.totalEmployeeOpenType);
          setNewSelector(yearEmployment.newEmployeeOpenType);
          setResignSelector(yearEmployment.resignEmployeeOpenType);
          const smallYear = selectedYear.toString().slice(2, 4);
          setGraphRows(['', '', `${smallYear}.03`, '',
            '', `${smallYear}.06`, '', '',
            `${smallYear}.09`, '', '', `${smallYear}.12`]);
          let totalMaxTmp = 0;
          let newMaxTmp = 0;
          let resignMaxTmp = 0;
          const totalChart:number[] = [];
          const newChart:number[] = [];
          const resignChart:number[] = [];

          yearEmployment.monthlyEmployments.map((year) => {
            if (year.totalEmployeeNumber > totalMaxTmp) {
              totalMaxTmp = year.totalEmployeeNumber;
            }
            if (year.newEmployeeNumber > newMaxTmp) {
              newMaxTmp = year.newEmployeeNumber;
            }
            if (year.resignEmployeeNumber > resignMaxTmp) {
              resignMaxTmp = year.resignEmployeeNumber;
            }
            totalChart.push(year.totalEmployeeNumber);
            newChart.push(year.newEmployeeNumber);
            resignChart.push(year.resignEmployeeNumber);
          });
          setTotalMax(totalMaxTmp);
          setNewMax(newMaxTmp);
          setResignMax(resignMaxTmp);
          setTotalChartData(totalChart);
          setNewChartData(newChart);
          setResignChartData(resignChart);
          setTotalColumn(['0명', `${calcUnit(totalMaxTmp).toLocaleString()}명`, `${(calcUnit(totalMaxTmp) * 2).toLocaleString()}명`, `${(calcUnit(totalMaxTmp) * 3).toLocaleString()}명`, `${(calcUnit(totalMaxTmp) * 4).toLocaleString()}명`, `${(calcUnit(totalMaxTmp) * 5).toLocaleString()}명`]);
          setNewColumn(['0명', `${calcUnit(newMaxTmp).toLocaleString()}명`, `${(calcUnit(newMaxTmp) * 2).toLocaleString()}명`, `${(calcUnit(newMaxTmp) * 3).toLocaleString()}명`, `${(calcUnit(newMaxTmp) * 4).toLocaleString()}명`, `${(calcUnit(newMaxTmp) * 5).toLocaleString()}명`]);
          setResignColumn(['0명', `${calcUnit(resignMaxTmp).toLocaleString()}명`, `${(calcUnit(resignMaxTmp) * 2).toLocaleString()}명`, `${(calcUnit(resignMaxTmp) * 3).toLocaleString()}명`, `${(calcUnit(resignMaxTmp) * 4).toLocaleString()}명`, `${(calcUnit(resignMaxTmp) * 5).toLocaleString()}명`]);
        }
      });
    }
  }, [yearSelector, selectedYear, companyModel.companyEmployment]);

  useEffect(() => {
    if (companyModel.companyFinance) {
      if (companyModel.companyFinance.revenues.length > 0) {
        setRevenueSelector(true);
      }
      if (companyModel.companyFinance.profits.length > 0) {
        setProfitSelector(true);
        if (companyModel.companyFinance.revenues.length === 0) {
          setLineGraphType('profit');
          setValue('profit_data', 'profit');
        }
      }
    }
  }, [companyModel.companyFinance]);

  const calcUnit = (value:number) => {
    const exponent = 10 ** (value.toString().length - 1);
    return Math.ceil((Math.ceil(value / exponent) * exponent + (4 * exponent)) / 5);
  };

  const onChangeTotalRadio = () => {
    setGraphColor('black');
    setValue('new_employee', undefined);
    setValue('resign_employee', undefined);
  };
  const onChangeNewRadio = () => {
    setGraphColor('green');
    setValue('total_employee', undefined);
    setValue('resign_employee', undefined);
  };
  const onChangeResignRadio = () => {
    setGraphColor('blue');
    setValue('new_employee', undefined);
    setValue('total_employee', undefined);
  };

  const onChangeRevenueRadio = () => {
    setLineGraphType('revenue');
    setValue('profit_data', undefined);
  };

  const onChangeProfitRadio = () => {
    setLineGraphType('profit');
    setValue('revenue_data', undefined);
  };
  return (
    <FormProvider {...useFormed}>

      <Frame>
        {
        companyModel.companyInformation
        && (
        <>
          <SpacingBlock vertical size={64} />

          <CompanyInformationTitle>재무 정보</CompanyInformationTitle>
          <SpacingBlock vertical size={8} />
          <div className='update-text'>업데이트 일자: {companyModel.companyInformation.lastModifiedDate ? companyModel.companyInformation.lastModifiedDate.replace(/-/g, '.') : '-'}</div>
          <SpacingBlock vertical size={15} />
          <GridContainer>
            <StabilityBox>
              <div className='title-text'>기업 규모</div>
              <SpacingBlock vertical size={8} />
              <div className='sub-text'>{companyModel.companyInformation.companyDetailDto.businessSize ? BusinessSizeTypeText[companyModel.companyInformation.companyDetailDto.businessSize] : '-' }</div>
            </StabilityBox>
            <StabilityBox>
              <div className='title-text'>매출액</div>
              <SpacingBlock vertical size={8} />
              <div className='sub-text'> {companyModel.companyInformation.companyDetailDto.revenue
                ? (Math.abs(companyModel.companyInformation.companyDetailDto.revenue) > FormatNumberLimit ? formatNumber(Math.floor(companyModel.companyInformation.companyDetailDto.revenue / FormatNumberLimit) * FormatNumberLimit) : formatNumber(companyModel.companyInformation.companyDetailDto.revenue))
                : '-'}
              </div>
            </StabilityBox>
            <StabilityBox>
              <div className='title-text'>영업이익</div>
              <SpacingBlock vertical size={8} />
              <div className='sub-text'>  {companyModel.companyInformation?.companyDetailDto.profit
                ? (Math.abs(companyModel.companyInformation?.companyDetailDto.profit) > FormatNumberLimit ? formatNumber(Math.floor(companyModel.companyInformation.companyDetailDto.profit / FormatNumberLimit) * FormatNumberLimit) : formatNumber(companyModel.companyInformation.companyDetailDto.profit))
                : '-'}
              </div>
            </StabilityBox>
          </GridContainer>
          <SpacingBlock vertical size={24} />

          {
            companyModel.companyFinance && (companyModel.companyFinance.profits.length > 0 || companyModel.companyFinance.revenues.length > 0)
            && (
              <BarGraphContainer>

                <div className='graph-menu'>
                  <div className='radio-menu'>
                    {revenueSelector
                        && (
                        <JDRadioInput
                          type={JD_RADIO_INPUT_TYPE.ROUND_COMMON_CHIP}
                          name='revenue_data'
                          value='revenue'
                          label={<div>매출액</div>}
                          defaultValue='revenue'
                          onChange={onChangeRevenueRadio}
                        />
                        )}
                    {profitSelector
                        && (
                        <JDRadioInput
                          type={JD_RADIO_INPUT_TYPE.ROUND_COMMON_CHIP}
                          name='profit_data'
                          value='profit'
                          label={<div>영업이익</div>}
                          onChange={onChangeProfitRadio}
                        />
                        )}
                  </div>
                </div>
                <div className={cx('chartWrap')}>
                  <GraphContainer>
                    <div className='bar-graph'>
                      <BarChart data={lineGraphType === 'revenue' ? barChartData(revenuesData) : barChartData(profitsData)} />
                    </div>
                  </GraphContainer>

                </div>
              </BarGraphContainer>
            )
          }
          <CompanyInformationTitle>직원 수</CompanyInformationTitle>
          <SpacingBlock vertical size={8} />
          <div className='update-text'>업데이트 일자: {companyModel.companyInformation.lastModifiedDate ? companyModel.companyInformation.lastModifiedDate.replace(/-/g, '.') : '-'}</div>
          <SpacingBlock vertical size={15} />
          <GridContainer>
            <StabilityBox>
              <div className='title-text'>평균 근속</div>
              <SpacingBlock vertical size={8} />
              <div className='sub-text'>{companyModel.companyInformation.companyDetailDto.averageWorkingYears ? `${companyModel.companyInformation.companyDetailDto.averageWorkingYears}년` : '-'}</div>
            </StabilityBox>
            <StabilityBox>
              <div className='title-text'>2023 입/퇴사자</div>
              <SpacingBlock vertical size={8} />
              <div className='divider-text'>
                <div className='sub-divider-text'>{companyModel.companyInformation.companyDetailDto.numberOfNewEmployees ? `${krNumberFormat(companyModel.companyInformation.companyDetailDto.numberOfNewEmployees)}명` : '-'}</div>
                <div className='small-divider' />
                <div className='sub-divider-text'>{companyModel.companyInformation.companyDetailDto.numberOfTerminateEmployees ? `${krNumberFormat(companyModel.companyInformation.companyDetailDto.numberOfTerminateEmployees)}명` : '-'}</div>
              </div>

            </StabilityBox>
            <StabilityBox>
              <div className='title-text'>임직원</div>
              <SpacingBlock vertical size={8} />
              <div className='sub-text'>{companyModel.companyInformation.companyDetailDto.numberOfEmployees ? `${krNumberFormat(companyModel.companyInformation.companyDetailDto.numberOfEmployees)}명` : '-'}</div>
            </StabilityBox>
          </GridContainer>
          {companyModel.companyEmployment && companyModel.companyEmployment.yearlyEmployments && companyModel.companyEmployment.yearlyEmployments.length > 0
       && (
       <LineGraphContainer>
         <div className='graph-menu'>
           <div className='radio-menu'>
             {totalSelector
          && (
          <JDRadioInput
            type={JD_RADIO_INPUT_TYPE.ROUND_COMMON_CHIP}
            name='total_employee'
            value='total'
            label={<div>전체 직원</div>}
            defaultValue='total'
            onChange={onChangeTotalRadio}
          />
          )}
             {newSelector
        && (
        <JDRadioInput
          type={JD_RADIO_INPUT_TYPE.ROUND_COMMON_CHIP}
          name='new_employee'
          value='new'
          label={<div>입사자</div>}
          onChange={onChangeNewRadio}
        />
        )}
             {resignSelector
        && (
        <JDRadioInput
          type={JD_RADIO_INPUT_TYPE.ROUND_COMMON_CHIP}
          name='resign_employee'
          value='resign'
          label={<div>퇴사자</div>}
          onChange={onChangeResignRadio}
        />
        )}

           </div>
           <div>
             <JDSelector
               className='selector'
               selectTitle='연도 선택'
               name='JDSelectorType_MEDIUMSMALL'
               type={JDSelectorType.MEDIUMSMALL}
               defaultText={`${yearSelector[yearSelector.length - 1]}년`}
               onChange={async () => {
                 setSelectedYear(Number(watch('JDSelectorType_MEDIUMSMALL')));
               }}
             >
               <select>
                 <option value='' hidden>
                   {`${selectedYear}`}
                 </option>
                 {yearSelector.map((key) => (
                   <option key={`selector-${key}`}>{key}년</option>
                 ))}

               </select>
             </JDSelector>
           </div>
         </div>
         <div className='graph' ref={refEmployee}>

           <LineChart
             colors={graphColor}
             type='small'
             rows={graphRows}
             columns={graphColor === 'black' ? totalColumn : (graphColor === 'green' ? newColumn : resignColumn)}
             data={graphColor === 'black' ? getChartDataSmall(totalChartData, totalMax, graphColor) : (graphColor === 'green' ? getChartDataSmall(newChartData, newMax, graphColor) : getChartDataSmall(resignChartData, resignMax, graphColor))}
             onIndex={0}
             viewCount={12}
             visible={visibleEmployee}
           />
         </div>
       </LineGraphContainer>
       )}
        </>
        )
      }
      </Frame>
    </FormProvider>
  );
};

export default inject(injectStore.companyModel)(observer(CompanyDetailEmploymentStability));
