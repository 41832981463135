import React from 'react';

const ArrowUp = () => {
    return (
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.5877 8.99215L7.72939 4.15048C7.5748 3.8909 7.30355 3.7334 7.00022 3.7334C6.69689 3.7334 6.42564 3.88798 6.27105 4.15048L3.41564 8.99215C3.26105 9.25173 3.25814 9.5784 3.4098 9.8409C3.56147 10.1034 3.84147 10.2667 4.1448 10.2667H9.86147C10.1648 10.2667 10.4448 10.1034 10.5965 9.8409C10.7481 9.5784 10.7452 9.25173 10.5906 8.99215H10.5877Z"
          fill="#FB4E4E"
        />
      </svg>
    );
  };
  export default ArrowUp;