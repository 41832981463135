// created by khj1115 on 2021-07-30
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import infoCategoryImageType1 from 'assets/icon/icon_info_category_01.svg';
import infoCategoryImageType2 from 'assets/icon/icon_info_category_02.svg';
import infoCategoryImageType3 from 'assets/icon/icon_info_category_03.svg';
import infoCategoryImageType4 from 'assets/icon/icon_info_category_04.svg';
import infoCategoryImageType5 from 'assets/icon/icon_info_category_05.svg';
import infoCategoryImageType6 from 'assets/icon/icon_info_category_06.svg';
import infoCategoryImageType7 from 'assets/icon/icon_info_category_07.svg';
import infoCategoryImageType8 from 'assets/icon/icon_info_category_08.svg';
import infoCategoryImageType9 from 'assets/icon/icon_info_category_09.svg';
import infoCategoryImageType10 from 'assets/icon/icon_info_category_10.svg';
import { InfoCategoryImageType } from '../../consts/InfoCategoryImageType';

interface ICategoryIconProps {
  type: string;
}

const CategoryIcon: React.FC<ICategoryIconProps> = ({ type }) => {
  const imageSrc = useMemo(() => {
    switch (type) {
      case InfoCategoryImageType.TYPE1:
        return infoCategoryImageType1;
      case InfoCategoryImageType.TYPE2:
        return infoCategoryImageType2;
      case InfoCategoryImageType.TYPE3:
        return infoCategoryImageType3;
      case InfoCategoryImageType.TYPE4:
        return infoCategoryImageType4;
      case InfoCategoryImageType.TYPE5:
        return infoCategoryImageType5;
      case InfoCategoryImageType.TYPE6:
        return infoCategoryImageType6;
      case InfoCategoryImageType.TYPE7:
        return infoCategoryImageType7;
      case InfoCategoryImageType.TYPE8:
        return infoCategoryImageType8;
      case InfoCategoryImageType.TYPE9:
        return infoCategoryImageType9;
      case InfoCategoryImageType.TYPE10:
        return infoCategoryImageType10;
    }
  }, [type]);

  return <img src={imageSrc} alt='category' />;
};

export default observer(CategoryIcon);
