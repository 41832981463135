// created by khj1115 on 2021-07-30
export enum InfoCategoryImageType {
  TYPE1 = 'TYPE1',
  TYPE2 = 'TYPE2',
  TYPE3 = 'TYPE3',
  TYPE4 = 'TYPE4',
  TYPE5 = 'TYPE5',
  TYPE6 = 'TYPE6',
  TYPE7 = 'TYPE7',
  TYPE8 = 'TYPE8',
  TYPE9 = 'TYPE9',
  TYPE10 = 'TYPE10',
}
