import { Button, ButtonMode } from '__designkit__/button/Button';
import Colors from '__designkit__/common/colors';
import ProfileEditTitleComponent from 'components/_v2/profile/ProfileEditTitleComponent';
import { ConditionRegisterType } from 'consts/_v2/profile/ProfileType';
import useQueryParams from 'hooks/useQueryParams';
import useToast from 'hooks/useToast';
import { IPutConditionWelfareRq, IWelfare } from 'interfaces/_v2/profile/IConditionRqRs';
import IComponentProps from 'interfaces/props/IComponentProps';
import useProfileConditions, { useFetchSaveProfileConditionsWelfareKeywords, useLoadTotalWelfare } from 'query/profiles/useProfileConditionsQuery';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import WelfareGroupSelectView, { TwoDepthItem } from './WelefareGroupSelectView';

const TabFrame = styled.div<{isResumeModal?:boolean}>`
  width: 100%;
  height: 100%;
  background-color: ${Colors.WHITE_100};
 
`;
const BtnFrame = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 0 16px;
  height: fit-content;
  position: fixed;
  bottom: env(safe-area-inset-bottom);
  height: 78px;
  background-color: ${Colors.WHITE_100};
  z-index: 100000;
`;
const Frame = styled.div`
  width: 100%;
  height: calc(100% - 88px);
  overflow-y: scroll;
  
  &::-webkit-scrollbar {
    display: none;
  }
`;
const ButtonFrame = styled.div`
  position: fixed;
  bottom: env(safe-area-inset-bottom);
  width: 100%;
  height: 78px;
  padding: 16px;
  border-top: 1px solid ${Colors.CG_40};
  background-color: ${Colors.WHITE_100};
`;
interface IWelfareTabProps extends IComponentProps {
  hidden?: boolean;
  welfareData?: IWelfare[];
  setConditionRegisterType: (type: ConditionRegisterType) => void;
  isResumeModal?: boolean;
  setStartChecking?: (startChecking: boolean) => void;

}

const WelfareTab: FC<IWelfareTabProps> = ({ setStartChecking, isResumeModal,welfareData, hidden, setConditionRegisterType }) => {
  const [init, setInit] = useState<boolean>(false);
  const [welfareGroups, setWelfareGroups] = useState<TwoDepthItem[]>([]);
  const defaultValues:IPutConditionWelfareRq = { welfareKeywords: [], sourcePage: '' };
  const { setToastObject } = useToast();
  const { sourcePage } = useQueryParams();
  const { refetch } = useProfileConditions(true);
  const saveSuccess = async () => {
    await refetch();
    if (setStartChecking)setStartChecking(true);
    setToastObject({ type: 'SUCCESS', isOpen: true, message: '성공적으로 저장되었습니다.' });
    setConditionRegisterType(ConditionRegisterType.LIST);
  };

  const { mutate: saveProfileWelfareConditions } = useFetchSaveProfileConditionsWelfareKeywords(saveSuccess);
  const formMethods = useForm({
    mode: 'onSubmit',
    defaultValues,
  });
  const { register, setValue, watch, getValues, reset } = formMethods;
  const { data: welfareCategoryWithWelfaresData } = useLoadTotalWelfare(true);

  useEffect(() => {
    if (welfareData && welfareData.length > 0) {
      const initialFormValues = welfareData.reduce((acc, welfare) => ({
        ...acc,
        [`welfare[${welfare.code}]`]: welfare.code,
      }), {});

      reset({
        ...getValues(),
        ...initialFormValues,
      });
    }
  }, [welfareData, reset, getValues]);
  useEffect(() => {
    if (!watch('welfare')) return;
    const temp = watch('welfare', []) as number[];
    temp.filter((welfareCode : string | number) => welfareCode)
      .map((welfareCode : string | number) => Number(welfareCode));
    setValue('welfareKeywords', temp);
  }, [watch, setValue, watch('welfare')]);

  useEffect(() => {
    if (!welfareCategoryWithWelfaresData) return;
    const temp: TwoDepthItem[] = [];
    welfareCategoryWithWelfaresData.welfareCategoryWithWelfares.forEach((category) => {
      const temp2 = category.children.map((title) => {
        const temp3 = title.welfares.map((item) => ({
          code: item.code,
          name: item.name,
          value: item.code,
        }));
        return {
          code: title.code,
          name: title.name,
          children: temp3,
        };
      });
      temp.push(...temp2);
    });
    setWelfareGroups(temp);
  }, [welfareCategoryWithWelfaresData]);

  const onClickSaveBtn = async () => {
    const welfareRq = { welfareKeywords: String(getValues('welfareKeywords')).indexOf(',') !== -1 ? String(getValues('welfareKeywords')).split(',').map((welfare) => welfare.trim()).map((code) => Number(code)) : Number(getValues('welfareKeywords')) ? [Number(getValues('welfareKeywords'))] : [], sourcePage: sourcePage || null };
    saveProfileWelfareConditions(welfareRq);
  };
  if (hidden) return null;
  return (
    <TabFrame isResumeModal={isResumeModal}>
      <FormProvider {...formMethods}>
        <input name='welfareKeywords' ref={register} aria-hidden />
        <ProfileEditTitleComponent
          title='희망하는 복지 키워드를 선택해 주세요.'
          subtitle='선택한 키워드로 딱 맞는 복지를 가진 회사를 추천해 드릴게요.'
          divider={false}
        />
        <Frame>
          <WelfareGroupSelectView name='welfare' jobGroups={welfareGroups} />
        </Frame>
      </FormProvider>
      {
        isResumeModal
          ? (
            <BtnFrame>
              <Button label='이전' buttonMode={ButtonMode.DEFAULT} size='large' outLined onClick={() => setConditionRegisterType(ConditionRegisterType.LIST)} />
              <Button label='저장하기' buttonMode={ButtonMode.PRIMARY} size='large' onClick={onClickSaveBtn} />
            </BtnFrame>
          )
          : (
            <ButtonFrame>
              <Button label='저장' buttonMode={ButtonMode.PRIMARY} size='large' onClick={onClickSaveBtn} />
            </ButtonFrame>
          )
      }

    </TabFrame>
  );
};

export default WelfareTab;
