import colors from '__designkit__/common/colors';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components/macro';

const Frame = styled.div`
  width: 24px;
  height: 24px;
  display:flex;
  justify-content:center;
  align-items:center;

  div{
    width: calc(100% - 4px);
    height:100%;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      width: 18.5px;
      border-top: 2px solid ${(props) => (props as any)['data-fill'] || colors.BLACK_100};
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
    }

    span:nth-child(1) {
      top: 4.1px;
    }

    span:nth-child(2){
      top: 11.2px;
    }
    
    span:nth-child(3){
      top: 11.2px;
      opacity: 0;
    }

    span:nth-child(4) {
      top: 18.3px;
    }

    &.open span:nth-child(1) {
      top: 11.2px;
      width: 0%;
      left: 50%;
    }

    &.open span:nth-child(2) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &.open span:nth-child(3) {
       opacity: 1;
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    &.open span:nth-child(4) {
      top: 10px;
      width: 0%;
      left: 50%;
    }
  }
`;

interface IhambugerMenuProps {
  open: boolean;
  fill?: string;
}

const HambugerMenu: FC<IhambugerMenuProps> = ({ open, fill }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <Frame data-fill={fill}>
      <div className={isOpen ? 'open' : ''}>
        <span />
        <span />
        <span />
        <span />
      </div>
    </Frame>
  );
};

export default HambugerMenu;
