import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import { CompanyInformationTitle } from 'components/_v2/company/CompanyDetailInformation';
import { inject, observer } from 'mobx-react';
import CompanyModel from 'models/_v2/CompanyModel';
import { injectStore } from 'models/store';
import React, { FC } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
    padding:64px 16px 0 16px;
`;
const ChipContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
`;
const StyledWelfareChip = styled.div`
    padding:10px 16px;
    background-color: ${colors.WHITE_100};
    border:1px solid ${colors.CG_50};
    border-radius: 48px;
    width: fit-content;
    margin-right: 4px;
    margin-bottom: 8px;
    .chip-text{
        color:${colors.CG_80};
        font:${Fonts.B3_Medium};
    }
`;
interface ICompanyDetailWelfare{
    companyModel?:CompanyModel
}
const CompanyDetailWelfare:FC<ICompanyDetailWelfare> = ({ companyModel = new CompanyModel() }) => (
  <Frame>
    <CompanyInformationTitle>복지</CompanyInformationTitle>
    <SpacingBlock vertical size={24} />
    <ChipContainer>
      {companyModel.companyWelfare && companyModel.companyWelfare.keywords.map((card, index) => (
        <StyledWelfareChip key={`welfare-${card.keyword + index}`}><div className='chip-text'>{card.keyword}</div></StyledWelfareChip>
      ))}
    </ChipContainer>
  </Frame>
);

export default inject(injectStore.companyModel)(observer(CompanyDetailWelfare));
