import { Divider1G40 } from 'components/divider/Divider';
import { MatchingCompanyCard } from 'components/_v2/positionJd/PositionJdMatchingCompany';
import RoutePaths from 'consts/RoutePaths';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import IPositionJdProps from 'interfaces/_v2/positionJd/IPositionJdProps';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import PositionJdModel from 'models/_v2/PositionJdModel';
import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PositionMatchingCompanyModal from './PositionMatchingCompanyModal';

const Frame = styled.div`
padding: 0px 16px 32px 16px;
    .divider {
        margin-bottom: 32px;
    }
    .head {
        font: ${Fonts.H5_Bold};
        color: ${colors.CG_60};

        >b {
            color: ${colors.JOBDA_BLACK};
        }
    }

    >ul {
        margin: 16px 0;
    }

    .link[data-disabled=true] {
        pointer-events: none;
    }
`;

const AllViewButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 12px 0;
    font: ${Fonts.B3_Bold};
    color: ${colors.JOBDA_BLACK};
    border: 1px solid ${colors.JOBDA_BLACK};
    border-radius: 4px;
`;

interface IPositionMatchingChallengeProps extends IPositionJdProps{
  preview?: boolean;
}

const PositionJdMatchingChallenge:FC<IPositionMatchingChallengeProps> = ({ positionJdModel = new PositionJdModel(), preview }) => {
  const { additionalInfo } = positionJdModel;
  const challengeList = positionJdModel.positionMatchingChallengeList;
  const [isAllViewOpen, setIsAllViewOpen] = useState<boolean>(false);

  return (
    <>
      <Frame>
        <Divider1G40 className='divider' />
        <p className='head'><b>{additionalInfo?.challenge.challengeName}에서 채용중</b>인 <br />다른 포지션을 만나보세요.</p>
        <ul>
          {challengeList?.map((data, index) => (
            index < 3
            && (
            <>
              <Link to={RoutePaths.position_jd(data.positionSn)} className='link' data-disabled={!!preview}>
                <li key={`matching-company-card-${data.positionSn}`}><MatchingCompanyCard positionInfo={data} /></li>
              </Link>
            </>
            )
          ))}
        </ul>
        { challengeList && challengeList.length > 3
      && (
      <AllViewButton disabled={preview} onClick={() => setIsAllViewOpen(true)}>
        포지션 {challengeList?.length - 3}개 모두보기
      </AllViewButton>
      )}
      </Frame>
      <PositionMatchingCompanyModal
        isOpen={isAllViewOpen}
        onClose={() => setIsAllViewOpen(false)}
        companyName={additionalInfo?.challenge.challengeName || ''}
        companyList={challengeList || []}
      />
    </>
  );
};

export default inject(injectStore.positionJdModel)(observer(PositionJdMatchingChallenge));
