export enum RecruitmentType {
  OPEN = 'OPEN',
  OCCASIONAL = 'OCCASIONAL',
  INTERN = 'INTERN',
}

export enum RecruitmentTypeText {
  OPEN = '공채',
  OCCASIONAL = '수시',
  INTERN = '인턴',
}

export default RecruitmentType;
