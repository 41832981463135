/* eslint-disable react/no-danger */
import colors from '__designkit__/common/colors';
import IModalFrameProps from 'interfaces/IModalFrameProps';
import React, { FC } from 'react';
import styled from 'styled-components';
import { JDAModalButton, JDAModalContentDescription, JDAModalContentDescriptionTip, JDAModalContentText } from './JDAModalComponents';
import JDAModalFrame from './JDAModalFrame';

const StyledModalContent = styled.div`
  text-align: center;
  padding: 24px;
  
  ${JDAModalContentText} {
    margin-top: 16px;
    margin-bottom: 8px;
  }

  ${JDAModalContentDescription} {
      margin-bottom: 16px;
      color: ${colors.CG_80};
  }

  ${JDAModalContentDescriptionTip} {
      text-align: left;
      margin-bottom: 16px;
  }
`;

interface IJDASimpleModalProps extends IModalFrameProps {
    title: string;
    content: string;
    subDesc?: string;
    em?: string;
}
const JDASimplelModal:FC<IJDASimpleModalProps> = ((props) => {
  const { title, content, subDesc, em, onClickClose, ...rest } = props;
  return (
    <JDAModalFrame {...rest}>
      <div style={{ width: 312 }}>
        <StyledModalContent>
          <JDAModalContentText>
            {title}
          </JDAModalContentText>
          <JDAModalContentDescription dangerouslySetInnerHTML={{ __html: content }} />
          { subDesc && (<JDAModalContentDescriptionTip dangerouslySetInnerHTML={{ __html: subDesc }} />)}
          <JDAModalButton onClick={onClickClose}>확인</JDAModalButton>
        </StyledModalContent>
      </div>
    </JDAModalFrame>
  );
});

export default JDASimplelModal;
