import LoginForm from 'components/_v2/login/LoginForm';
import SignUpFrame from 'components/_v2/login/SignUpFrame';
import SocialLoginForm from 'components/_v2/login/SocialLoginForm';
import RoutePaths from 'consts/RoutePaths';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import ILoginPageProps from 'interfaces/props/ILoginPageProps';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import { injectStore } from 'models/store';
import JoinModel from 'models/_v2/JoinModel';
import React, { FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import CommonUtils, { removeSession } from 'utils/CommonUtils';

const Frame = styled.div`
  height:100%;
  background: ${colors.WHITE_100};
  padding: 16px 20px;
  max-width: 460px;
  min-width: 360px;
  margin: auto;
`;

const TitleFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${colors.JOBDA_BLACK};
  .sub-title {
    font: ${Fonts.B1_Medium};
    margin-bottom: 4px;
  }
  .main-title {
    font: ${Fonts.H3_Bold};
  }
`;

const Login: FC<ILoginPageProps> = (props) => {
  const { context = new Context(), login, joinModel = new JoinModel() } = props;
  const { pathname, search } = useLocation();
  const { publicKey, positionId } = CommonUtils.parseQuery(search);
  const matchLoginPathYn = pathname === RoutePaths.matchLogin;
  const history = useHistory();

  // ats 수동 인재 연동
  const checkMatchLogin = () => {
    if (matchLoginPathYn && publicKey && positionId && login) {
      login.saveMatchLoginInfo(publicKey, Number(positionId));
    }
  };

  useEffect(() => {
    if (publicKey && positionId && login) {
      login.saveMatchLoginInfo(publicKey, Number(positionId));
    }
  }, [publicKey, positionId, login]);
  useEffect(() => {
    if (login?.userInfo) {
      const redirectPath = context.loginRedirectPath;
      context.setRedirectUrl(RoutePaths.root);
      checkMatchLogin();
      history.replace(redirectPath);
    }
  }, [login?.userInfo]);

  useEffect(() => {
    joinModel.socialJoinInfo = null;
    removeSession('socialInfo');
  }, []);

  return (
    <Frame>
      <TitleFrame>
        <div className='sub-title'>채용정보ㆍ역량검사ㆍ매칭까지</div>
        <div className='main-title'>취업기회 지금 바로 잡다!</div>
      </TitleFrame>
      <LoginForm />
      <SocialLoginForm checkMatchLogin={checkMatchLogin} />
      <SignUpFrame checkMatchLogin={checkMatchLogin} />
    </Frame>
  );
};

export default inject(injectStore.context, injectStore.login, injectStore.joinModel)(observer(Login));
