import RoutePaths from 'consts/RoutePaths';

interface IUtmEventQuery {
    incomingUrls: string[];
    startDate?: Date;
    endDate?: Date;
    queryString: {
      utm_source?: string;
      utm_medium?: string;
      utm_campaign?: string;
    };
  }

export const UtmEventQuery: IUtmEventQuery[] = [
  // 2023 하반기 디챔스 DCHAMPS_2023
  {
    incomingUrls: [
      RoutePaths.phs_test,
      RoutePaths.jobda_dchamps_event,
      RoutePaths.phs_dchamps_results(),
    ],
    startDate: new Date('2023-09-01'),
    endDate: new Date('2023-11-31'),
    queryString: {
      utm_source: 'jobda',
      utm_medium: 'cta',
      utm_campaign: '2023_dchamps',
    },
  },
  // 인재풀 전환 마케팅 페이지
  {
    incomingUrls: [RoutePaths.jobda_challenge_event],
    startDate: new Date('2023-09-01'),
    endDate: new Date('2023-12-31'),
    queryString: {
      utm_source: 'jobda',
      utm_medium: 'cta',
      utm_campaign: '2023_get_job_challenge',
    },
  },
  {
    incomingUrls: [RoutePaths.jobda_chuseok_event],
    startDate: new Date('2023-09-25'),
    endDate: new Date('2023-10-12'),
    queryString: {
      utm_source: 'jobda',
      utm_medium: 'cta_join',
      utm_campaign: 'holiday2309',
    },
  },
];

export default UtmEventQuery;
