import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import { DateUtil } from 'components/_v2/_common/input/datePicker/DateUtil';
import ResumeSingleMenuItem from 'components/_v2/profile/loadResume/ResumeSingleMenuItem';
import { InsightResumeLogRq } from 'interfaces/_v2/insight/IInsightResumeRqRs';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import useInsightResumeList, { useInsightResumeLog } from 'query/resume/useResumeQuery';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
    .load-title-text{
        font:${Fonts.H4_Bold};
        color:${Colors.JOBDA_BLACK};
    }
    .info-frame{
        display: flex;
        padding:16px;
        align-items: flex-start;
        background-color: ${Colors.CG_30};
        border-radius: 8px;
        flex-direction: column;
        gap: 4px;
        .inner-frame{
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 8px;
            .inner-text{
                font:${Fonts.B2_Medium};
                color:${Colors.CG_70};
            }
        }
    }
`;
const ResumeFrame = styled.div`
    gap:12px;
    display: flex;
    flex-direction: column;
`;
interface ILoadResumeList{
    login?:Login
    setSelectedResume:(index:number)=>void
    setStep:(index:number)=>void
    setLoadFailed:(index:boolean)=>void
    isMatchDashboard?:boolean
}
const LoadResumeList:FC<ILoadResumeList> = ({ login = new Login(), setSelectedResume, isMatchDashboard, setStep, setLoadFailed }) => {
  const { data: resumeList } = useInsightResumeList(!!login.userInfo);
  const [selected, setSelected] = useState<number>(-1);
  const { pathname } = useLocation();

  const saveLogSuccess = () => {
  };
  const { mutate: saveResumeInduce } = useInsightResumeLog(saveLogSuccess);
  const onView = async () => {
    const induceRq:InsightResumeLogRq = {
      action: 'VIEW',
      actionSource: pathname,
      actionComponent: 'modal_list_insight_resume',
      data: {},
    };
    saveResumeInduce(induceRq);
  };
  useEffect(() => {
    onView();
  }, []);

  const makeSubTitle = (date:string, companyName:string):string => `제출일 : ${DateUtil.formatYYYYMMDD(new Date(date), '.')} | ${companyName}`;
  useEffect(() => {
    if (resumeList) {
      if (resumeList.resumes.length === 0) {
        setLoadFailed(true);
        setStep(3);
      }
    }
  }, [resumeList]);
  return (
    <Frame className='modal_list_insight_resume'>
      <div className='load-title-text'>지원서를 선택해 주세요.</div>
      <SpacingBlock vertical size={24} />
      <div className='info-frame'>
        <div className='inner-frame'>
          <Icon name='check' size={24} color={Colors.JOBDA_BLACK} />
          <div className='inner-text'>지원서마다 불러올 수 있는 정보가 다를 수 있어요.</div>
        </div>
        <div className='inner-frame'>
          <Icon name='check' size={24} color={Colors.JOBDA_BLACK} />
          <div className='inner-text'>불러오기를 동의한 지원서만 불러올 수 있어요.</div>
        </div>
      </div>
      <SpacingBlock vertical size={16} />
      <ResumeFrame>
        {
        resumeList && resumeList.resumes.length > 0 && resumeList.resumes.map((resume, index) => (
          <ResumeSingleMenuItem
            title={resume.positionName}
            subTitle={makeSubTitle(resume.submitDateTime, resume.companyName)}
            rightIcon={<Icon name='check-circle-filled' size={24} color={index === selected ? Colors.G_150 : Colors.CG_40} />}
            onClick={() => {
              setSelected(index);
              setSelectedResume(resume.sn);
            }}
            selected={index === selected}
          />
        ))
      }
        <SpacingBlock size={40} vertical />
      </ResumeFrame>
    </Frame>
  );
};

export default inject(injectStore.login)(observer(LoadResumeList));
