import JDBaseModal from 'components/_v2/_common/modals/JDBaseModal';
import { JDModalBasicFrame, JDModalDescription, JDModalFullButton, JDModalTitle } from 'components/_v2/_common/modals/JDModalComponents';
import JDNoHeaderFullModal from 'components/_v2/_common/modals/JDNoHeaderFullModal';
import React, { FC } from 'react';
import styled from 'styled-components';
import { MatchLoginConnectInfoModalProps } from './MatchLoginConnectInfoModal';

const Frame = styled.div`

    ${JDModalDescription} {
        margin-top: 8px;
    }
`;

const MatchLoginConnectSuccessModal: FC<MatchLoginConnectInfoModalProps> = ({ isOpen, onClickClose }) => (
  <JDNoHeaderFullModal isOpen={isOpen} onClose={onClickClose}>
    <JDBaseModal dimmed isOpen={isOpen} onClickClose={onClickClose} isDimClickClose>
      <Frame>
        <JDModalBasicFrame>
          <JDModalTitle>계정 연동이 완료되었습니다.</JDModalTitle>
          <JDModalDescription>
            해당 포지션에서 진행되는 모든 전형과 메시지를 잡다에서 손쉽게 확인하고 관리해보세요!
          </JDModalDescription>
          <JDModalFullButton onClick={onClickClose}>확인</JDModalFullButton>
        </JDModalBasicFrame>
      </Frame>
    </JDBaseModal>
  </JDNoHeaderFullModal>
);

export default MatchLoginConnectSuccessModal;
