import ImgLocation from 'assets/_v2/positionJd/icon_location.svg';
import IconRightArrow from 'assets/_v2/_common/icon_right_small_arrow.svg';
import LazyImage, { LazyImageLogo } from 'components/common/LazyImage';
import { Divider1G40 } from 'components/divider/Divider';
import JDALink from 'components/JDALink';
import PositionTagList from 'components/_v2/positionJd/PositionTagList';
import JDPopover from 'components/_v2/_common/popover/JDPopover';
import { LocationCode } from 'consts/CompanyLocationType';
import RoutePaths from 'consts/RoutePaths';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { lineClampBox } from 'consts/_v2/_common/style/mixins';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import PositionJdModel from 'models/_v2/PositionJdModel';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { DateFormatYYYYMMDDCCHHMMUnit, getDDayString, str2DateTime, TimeType } from 'utils/DateUtils';
import TextUtil from 'utils/TextUtil';
import { IPositionDetailAdditionalDto, IPositionDetailBasicDto, IPositionDetailCompanyDto } from 'interfaces/_v2/positionJd/IPositionDetailRs';
import PositionJdCompanyNudge from 'components/_v2/positionJd/PositionJdCompanyNudge';
import Icon from '__designkit__/icon/Icon';
import SpacingBlock from '__designkit__/components/SpacingBlock';

const Frame = styled.div`
    display: flex;
    flex-direction: column;
    width:100%;
    .img{
        width:56px;
        height:56px;
        min-width: 56px;
        margin-top: 8px;
        margin-right: 18px;
        border: 1px solid ${colors.c44474B_20};
    }
    .position_name{
        word-break: break-all;
        align-self: center;
        text-align: left;
        font: ${Fonts.H5_Bold};
        ${lineClampBox(2)};
    }

    .company_name{
        display:flex;
        flex-wrap: wrap;
        margin-top: 24px; 
        font: ${Fonts.B1_Bold};
    }
    .align_div{
        display:flex;
        align-items: center;
    }
    .location-frame{
      margin:24px 0 0 24px;
      display: flex;
      align-items: center;
      .location_info{
        margin-left: 4px;
        font:${Fonts.B1_Medium};
        color:${colors.CG_60};
    }
    .location_img{
        width: 13px;
        height: 16px;
    }
    }
   
    .enroll_date_name{
        margin-top: 14px;
        color:${colors.CG_80};
        font: ${Fonts.B3_Medium_P};
    }
    .enroll_date{
        margin-top: 14px;
        margin-left: 48px;
        font: ${Fonts.B1_Bold};
        color:${colors.CG_60};
    }
    .end_date_name{
        font: ${Fonts.B3_Medium_P};
        color:${colors.CG_80};
        margin-top: 14px;
    }
    .end_date{
        font: ${Fonts.B1_Bold};
        margin-top: 14px;
        margin-left: 48px;
    }
    .d_day_frame{
        margin-top: 14px;
        margin-left: 8px;
        padding:4px 6px;
        border-radius: 4px;
        border: 1px solid ${colors.c000000_06};
        background-color:${colors.G_100};
        span{
            display:inline-block;
            font: ${Fonts.B4_Medium};
            color:${colors.WHITE_100}
        }
    }
    .tag_list{
        margin-top: 10px;
    }
    .view_title{
        display: flex;
        align-items:center;
        font: ${Fonts.B3_Medium};
        color:${colors.CG_80};
        .view_count{
          margin-left: 5px;
          color:${colors.CG_60}
        }
    }

`;
const DividerFrame = styled.div`
    display: flex;
    margin-top: 24px;
    .divider-24{
            width:100%;
    }
`;

const ChallengeAndPostingButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  margin-top: 16px;
  font: ${Fonts.B4_Bold};
  color: ${colors.WHITE_100};
  background: ${colors.JOBDA_BLACK};
  border-radius: 28px;

  >span {
    word-break: break-all;
  }

  >img {
    width: 20px;
    height: 20px;
    margin-left: 16px;
  }
`;

const PopOverFrame = styled.div`
  
    color:${colors.WHITE_100};
      >h1{
      font: ${Fonts.B3_Bold};
    }
`;

const NudgeFrame = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  background-color: ${colors.WHITE_100};
  border: 1px solid ${colors.CG_50};
  border-radius: 6px;

  .title {
    font: ${Fonts.B2_Bold};
    color: ${colors.JOBDA_BLACK}; 
    word-break: keep-all;
    margin-bottom: 12px;
  }

  .tip{
    font: ${Fonts.B3_Medium};
    color: ${colors.c41AC4D};
    margin-bottom: 12px;
  }
`;

const LinkButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  padding: 10px;
  width: 100%;
  background-color: ${colors.WHITE_100};
  border: 1px solid ${colors.JOBDA_BLACK};
  border-radius: 4px;
  font: ${Fonts.B3_Bold};
  color: ${colors.JOBDA_BLACK};

  & > div {
    margin-right: 4px;
  }
`;

interface IPositionJdCompnayInfoProps{
  basicInfo : any;
  companyInfo: any
  additionalInfo: any
}

const PositionJdPreviewCompanyInfo:FC<IPositionJdCompnayInfoProps> = ({ basicInfo, companyInfo, additionalInfo }) => {
  return (
    <>
      {basicInfo && companyInfo && additionalInfo && (
      <>
        <Frame>
          <div className='align_div'>

            <LazyImageLogo src={companyInfo.logoImageUrl} className='img' />

            <JDPopover useOutsideClick position='under' popoverMargin={30} anchorIcon={<div className='position_name'>{basicInfo.positionName}</div>}>
              <PopOverFrame>
                <h1>{basicInfo.positionName}</h1>
              </PopOverFrame>
            </JDPopover>
          </div>
          <div className='align_div'>

            <div className='company_name'>{TextUtil.textLengthEllipsis(companyInfo.companyName, 15)}</div>

            <div className='location-frame'>
              <LazyImage src={ImgLocation} className='location_img' />
              <div className='location_info'>{LocationCode(additionalInfo.locationCode)}</div>
            </div>
          </div>
          <div className='tag_list'>
            <PositionTagList
              recruitmentType={basicInfo.recruitmentType}
              jobTitleNames={additionalInfo.jobTitleNames}
              closingDateTime={basicInfo.closingDateTime}
              minSalary={basicInfo.minSalary}
              maxSalary={basicInfo.maxSalary}
              JdCase
            />
          </div>
        </Frame>

        <NudgeFrame>
          <p className='title'>{companyInfo.companyName}에 대한 더 자세한 정보를 알고싶으시다면?</p>
          <LinkButton>
            <Icon name='business' size={24} />
            기업 정보 바로가기
          </LinkButton>
          <SpacingBlock size={8} vertical />
          <LinkButton>
            <Icon name='message' size={24} />
            기업 채팅 바로가기
          </LinkButton>
        </NudgeFrame>
        <DividerFrame>
          <Divider1G40 className='divider-24' />
        </DividerFrame>
        <Frame>
          <div className='align_div'>

            <div className='enroll_date_name'>시작일</div>
            {basicInfo.openingDateTime && (
            <div className='enroll_date'>{DateFormatYYYYMMDDCCHHMMUnit(basicInfo.openingDateTime)}</div>
            )}
          </div>
          <div className='align_div'>
            <div className='end_date_name'>마감일</div>
            <>
              {basicInfo.closingDateTime
                ? (
                  <>
                    <div className='end_date'>{DateFormatYYYYMMDDCCHHMMUnit(basicInfo.closingDateTime)}</div>
                    <div className='d_day_frame'>
                      <span>{getDDayString(str2DateTime(basicInfo.closingDateTime))}</span>
                    </div>
                  </>
                )
                : <div className='end_date'>상시 채용</div>}

            </>
          </div>
        </Frame>
      </>
      )}

    </>
  );
};

export default inject(injectStore.positionJdModel)(observer(PositionJdPreviewCompanyInfo));
