import React, { FC } from 'react';
import styled from 'styled-components';
import IComponentProps from 'interfaces/props/IComponentProps';

interface ISpacingBlockProps extends IComponentProps {
  size: number;
  vertical?: boolean;
}

const Frame = styled.div<ISpacingBlockProps>`
  width: ${({ size, vertical }) => (vertical ? '1px' : `${size}px`)};
  min-width: ${({ size, vertical }) => (vertical ? '1px' : `${size}px`)};
  height: ${({ size, vertical }) => (vertical ? `${size}px` : '1px')};
  min-height: ${({ size, vertical }) => (vertical ? `${size}px` : '1px')};
`;

const SpacingBlock: FC<ISpacingBlockProps> = ({ size = 2, vertical = undefined }) => <Frame size={size} vertical={vertical} />;

export default SpacingBlock;
