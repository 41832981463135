import { ErrorMessage } from '@hookform/error-message';
import { IconBasket20 } from 'consts/assets/icons/iconPages';
import { numberPointValidator, numberValidator } from 'consts/ValidationRule';
import { JDBaseInputMixin } from 'consts/_v2/_common/style/mixins';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { forwardRef, ReactElement, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import styled from 'styled-components/macro';

const Frame = styled.div`
  ${JDBaseInputMixin()};
`;

interface IJDBaseDeleteInput extends IComponentProps {
  name: string;
  defaultMessage?: string
  customValidator: any;
  type?: string;
  onClear?: () => void;
  disabled?: boolean;
  defaultValue?: string;
  className?: string;
  caption?:ReactElement;
  onDelete?: () => void;
}

const JDBaseDeleteInput = forwardRef((props: IJDBaseDeleteInput, fieldRef) => {
  const { name, defaultMessage, customValidator, type, onClear, onDelete, disabled, defaultValue, className, caption, ...rest } = props;
  const useFormed = useFormContext();
  const { control, errors, watch, setValue, clearErrors, trigger, register } = useFormed;
  const { field: { ref: BaseRef }, meta } = useController({ name, control, defaultValue });
  const [inputValue, setInputValue] = useState<string>('');

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (type === 'tel') {
      if (numberValidator(e.currentTarget.value)) {
        setInputValue(e.currentTarget.value);
      } else {
        e.currentTarget.value = inputValue;
      }
    }

    if (type === 'numberPoint') {
      if (numberPointValidator(e.currentTarget.value)) {
        setInputValue(e.currentTarget.value);
      } else {
        e.currentTarget.value = inputValue;
      }
    }

    setValue(name, e.currentTarget.value);
    trigger();
    if (props.onChange) {
      props.onChange();
    }
  };

  return (
    <Frame className={`jd-input ${className || ''} ${!meta.invalid}`}>
      <div>
        <input
          name={name}
          ref={function (innerRef) {
            if (fieldRef !== null) (fieldRef as any).current = innerRef;
            if (BaseRef !== undefined) BaseRef.current = innerRef;
            register(innerRef);
          }}
          className={`${watch(name) ? 'value' : ''}`}
          onChange={onChangeHandler}
          disabled={!!disabled}
          defaultValue={defaultValue}
          autoComplete='off'
          {...rest}
        />
        <button
          type='button'
          className='btn-delete'
          onClick={() => {
            if (BaseRef !== undefined) BaseRef.current.value = '';
            if (disabled) return;
            setValue(name, '');
            setInputValue('');
            clearErrors(name);
            if (onClear) onClear();
            trigger();
            onDelete && onDelete();
          }}
        >
          <IconBasket20 />
        </button>
      </div>
      {(defaultMessage && !meta.invalid) && <h4 className='message'>{defaultMessage}</h4>}
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <h4 className='message false'>{message}</h4>}
      />
      {caption}
    </Frame>
  );
});

export default JDBaseDeleteInput;
