import React, { FC } from 'react';
import styled from 'styled-components';
import Fonts from '__designkit__/common/fonts';
import Colors from '__designkit__/common/colors';
import Icon from '__designkit__/icon/Icon';
import JDAPopover from '../_common/popover/JDPopover';

const Frame = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  font: ${Fonts.B3_Medium};
`;

const PopOverFrame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  color: ${Colors.WHITE_100};
  background-color: ${Colors.CG_70};

  & .title {
    font: ${Fonts.B3_Bold};
  }

  & .content {
    font: ${Fonts.B3_Medium};
  }
`;

const ProfileLegend: FC<{ verified?: boolean }> = ({ verified = undefined }) => (!verified
  ? <></>
  : (
    <Frame>
      <Icon name='verified-filled' size={24} color={Colors.G_150} />
      불러온 경력 정보
      <JDAPopover useOutsideClick position='non_pony_under_right' anchorIcon={<Icon name='information' size={20} color={Colors.CG_70} />}>
        <PopOverFrame>
          <div className='title'>
            불러온 경력 정보
          </div>
          <div className='content'>
            불러온 경력 정보의 직장명, 상태, 근무 기간은 인증된 정보라서 수정할 수 없어요. 업데이트가 필요하다면 다시 경력을 불러와 주세요.
          </div>
        </PopOverFrame>
      </JDAPopover>
    </Frame>
  ));

export default ProfileLegend;
