import JDCheckInput, { JD_CHECK_INPUT_TYPE } from 'components/_v2/_common/input/JDCheckInput';
import { CompanyLocation } from 'consts/CompanyLocationType';
import Fonts from '__designkit__/common/fonts';
import { BusinessSizeTypes, BusinessSizeTypesText, RecruitmentTypes, RecruitmentTypesText } from 'consts/_v2/calendar/CalendarFilterType';
import colors from '__designkit__/common/colors';
import ICalendarProps from 'interfaces/_v2/calendar/ICalendarProps';
import { inject, observer } from 'mobx-react';
import CalendarModel from 'models/_v2/CalendarModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

const Frame = styled.div`
    padding: 32px 16px 66px;

    &[aria-hidden='true'] {
        display: none;
    }
`;

const FilterTile = styled.div`
    font: ${Fonts.B1_Bold};
    color: ${colors.JOBDA_BLACK};
    margin-bottom: 20px;
`;

const FilterCheckFrame = styled.div`
    display: flex;
    margin-bottom: 40px;

    span{
         font: ${Fonts.B2_Medium}; 
         color:${colors.CG_80};
    }

    .jd-input {
      .label {
        padding-left: 2px;
      }
    }

    .group-items{
        display: grid;
        width: 100%;
        align-content: center;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 20px;
      }
`;

export interface IFilterProps extends ICalendarProps{
  dataHide: boolean;
}

const CalendarDetailFilter:FC<IFilterProps> = ({ dataHide, calendarModel = new CalendarModel() }) => {
  const useFormed = useFormContext();
  const { watch, setValue } = useFormed;

  useEffect(() => {
    if (calendarModel.recentlyFilter) {
      if (calendarModel.recentlyFilter.recruitmentTypes.length > 0) {
        calendarModel.recentlyFilter.recruitmentTypes.map((type) => setValue(`recruitmentTypes.${type}`, type));
      }
      if (calendarModel.recentlyFilter.businessSizeTypes.length > 0) {
        calendarModel.recentlyFilter.businessSizeTypes.map((type) => setValue(`businessSizeTypes.${type}`, type));
      }
      if (calendarModel.recentlyFilter.locations.length > 0) {
        calendarModel.recentlyFilter.locations.map((type) => setValue(`locations.${type}`, type));
      }
    }
  }, [calendarModel.recentlyFilter]);

  const getArray = (list: Object) => Object.entries(list).reduce((acc: string[], [key, value]) => {
    if (value) {
      acc.push(value);
    }
    return acc;
  }, []);

  const onChangeRecruitmentCheckbox = () => {
    const filteredList = getArray(watch('recruitmentTypes'));
    calendarModel.calendarFilter.recruitmentTypes = filteredList.join(',');
  };

  const onChangeBusinessSizeCheckbox = () => {
    const filteredList = getArray(watch('businessSizeTypes'));
    calendarModel.calendarFilter.businessSizeTypes = filteredList.join(',');
  };

  const onChangeLocationCheckbox = () => {
    const list: number[] = watch('locations');
    const filteredList = list.filter((value) => value);
    calendarModel.calendarFilter.locations = filteredList.join(',');
  };

  return (
    <Frame aria-hidden={dataHide}>
      <FilterTile>채용 형태</FilterTile>
      <FilterCheckFrame>
        <div className='group-items'>
          {(Object.keys(RecruitmentTypes) as Array<keyof typeof RecruitmentTypes>)
            .filter((type) => RecruitmentTypes[type] !== RecruitmentTypes.NEWORCAREER)
            .map((type) => (
              <JDCheckInput
                key={`check-option-progress-${type}`}
                type={JD_CHECK_INPUT_TYPE.CHECK_BOX}
                name={`recruitmentTypes.${type}`}
                value={type}
                label={<span>{RecruitmentTypesText[type]}</span>}
                onChange={onChangeRecruitmentCheckbox}
              />
            ))}
        </div>
      </FilterCheckFrame>
      <FilterTile>기업 구분</FilterTile>
      <FilterCheckFrame>
        <div className='group-items'>
          {(Object.keys(BusinessSizeTypes) as Array<keyof typeof BusinessSizeTypes>).map((type) => (
            <JDCheckInput
              key={`check-option-business-${type}`}
              type={JD_CHECK_INPUT_TYPE.CHECK_BOX}
              name={`businessSizeTypes.${type}`}
              value={type}
              label={<span>{BusinessSizeTypesText[type]}</span>}
              onChange={onChangeBusinessSizeCheckbox}
            />
          ))}
        </div>
      </FilterCheckFrame>
      <FilterTile>근무 지역</FilterTile>
      <FilterCheckFrame>
        <div className='group-items'>
          {
              Object.values(CompanyLocation).filter((location) => location.code !== '10000').map((location) => (
                <>
                  <JDCheckInput
                    key={`check-option-${location.code}`}
                    type={JD_CHECK_INPUT_TYPE.CHECK_BOX}
                    name={`locations.${location.code}`}
                    value={location.code}
                    className='check-box'
                    label={<span>{location.location}</span>}
                    onChange={onChangeLocationCheckbox}
                  />
                </>
              ))
    }
        </div>
      </FilterCheckFrame>
    </Frame>
  );
};

export default inject(injectStore.calendarModel)(observer(CalendarDetailFilter));
