import ImgBanner from 'assets/_v2/match/img_match_apply_banner.png';
import MatchApplyCard from 'components/_v2/matchApply/MatchApplyCard';
import MatchApplySetting from 'components/_v2/matchApply/MatchApplySetting';
import { Divider12Grey } from 'components/divider/Divider';
import RoutePaths from 'consts/RoutePaths';
import IMatchApplyProps from 'interfaces/_v2/matchApply/IMatchApplyProps';
import { inject, observer } from 'mobx-react';
import MatchApplyModel from 'models/_v2/MatchApplyModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

const Frame = styled.div`
  padding: 24px 0 32px 0;
`;

const BannerFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;

const MatchApplyHome:FC<IMatchApplyProps> = ({ matchApplyModel = new MatchApplyModel() }) => {
  const history = useHistory();

  useEffect(() => {
    const init = async () => {
      await matchApplyModel.loadProfileType();
    };

    init();
  }, []);

  return (
    <Frame>
      <MatchApplyCard />
      <Divider12Grey />
      {
        (matchApplyModel.profileType?.generalYn || matchApplyModel.profileType?.developerYn)
        && <MatchApplySetting />
      }
      <BannerFrame onClick={() => history.push(RoutePaths.match_introduce)}>
        <img className='banner' src={ImgBanner} alt='banner' />
      </BannerFrame>

    </Frame>
  );
};

export default inject(injectStore.matchApplyModel)(observer(MatchApplyHome));
