import styled from 'styled-components/macro';
import colors from '__designkit__/common/colors';
import { fonts, lineHeights } from '__designkit__/common/fonts';
import { JDALargeButton } from 'consts/style/mixins';

const ErrorFrame = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:flex-start;
  padding:20px;
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  .frame-main {
    flex:1;
    display:flex;
    padding-top:136px;
    flex-direction:column;
    align-items:center;
    justify-content:flex-start;
  }
  .img-error {
    height:72px;
  }
  .title,.desc {
    white-space:pre;
    text-align:center;
  }
  .title {
    color:${colors.CG_80};
    ${fonts.NOTO_24_500};
    ${lineHeights.LINEHEIGHT_1_20};
    margin-top:28px;
    margin-bottom:15px;
  }
  .desc {
    color:${colors.CG_60};
    ${fonts.NOTO_13_400};
    ${lineHeights.LINEHEIGHT_1_40};
  }
`;

export const ErrorCTAButton = styled.button`
  ${JDALargeButton(false, true)};
  background-color:${colors.JOBDA_BLACK};
  border-radius:4px;
  width:100%;
`;

export default ErrorFrame;
