import { yupResolver } from '@hookform/resolvers/yup';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import NhisAPI from 'api/nhisAPI';
import kakaoLogo from 'assets/img/img_kakao_logo.png';
import naverLogo from 'assets/img/img_naver_logo.png';
import JDDatePickerInput from 'components/_v2/_common/input/JDDatePickerInput';
import NameField from 'components/react-hook-form/NameField';
import PhoneField from 'components/react-hook-form/PhoneField';
import RadioField from 'components/react-hook-form/RadioField ';
import { NHIS_ERROR_MESSAGE, NHIS_PROVIDER } from 'consts/_v2/profile/nhis';
import useToast from 'hooks/useToast';
import { IPostAuthRequestRq, nhisProvider } from 'interfaces/_v2/profile/INhisRqRs';
import IComponentProps from 'interfaces/props/IComponentProps';
import { inject, observer } from 'mobx-react';
import ProfileBasicInfoModel from 'models/_v2/profile/ProfileBasicInfoModel';
import { injectStore } from 'models/store';
import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { make15YearsOldDate } from 'utils/DateUtils';
import * as yup from 'yup';

const Frame = styled.form`
  width: 100%;
  height: fit-content;
  min-height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  & * {
    user-select: none;
  }
`;

const TitleFrame = styled.div`
  & .title {
    width: 100%;
    height: fit-content;
    font: ${Fonts.H4_Bold};
    color: ${Colors.JOBDA_BLACK};
  }
`;

const FormFrame = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  height: fit-content;
`;

const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  height: fit-content;

  & label {
    font: ${Fonts.B1_Bold};
    color: ${Colors.JOBDA_BLACK};
  }

  & .radio-frame {
    display: flex;
    align-items: center;
    gap: 24px;
    width: 100%;
    height: fit-content;
  }

  // TODO : 여기만을 위한 DatePicker의 스타일? BirthDayField 컴포넌트 구현 필요
  & .date-frame {
    transform: translateX(1px);
    width: calc(100% - 2px);
    z-index: 1;

    & input {
      font: ${Fonts.B2_Medium} !important;
    }
  }
`;

const ProviderItemFrame = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  height: fit-content;
  font: ${Fonts.B2_Medium};
  color: ${Colors.JOBDA_BLACK};

  & img {
    width: 32px;
    height: 32px;
    border-radius: 6.4px;
  }
`;

const PrividerItem: FC<{ providerType: nhisProvider }> = ({ providerType }) => {
  switch (providerType) {
    case NHIS_PROVIDER.KAKAO:
      return (
        <ProviderItemFrame>
          <img src={kakaoLogo} alt='kakao' />카카오톡
        </ProviderItemFrame>
      );
    case NHIS_PROVIDER.NAVER:
      return (
        <ProviderItemFrame>
          <img src={naverLogo} alt='naver' />네이버
        </ProviderItemFrame>
      );
    default:
      return <></>;
  }
};

interface IVerificationFormProps extends IComponentProps {
  stepSetter: Dispatch<SetStateAction<number>>;
  tempSetter: Dispatch<SetStateAction<IPostAuthRequestRq | undefined>>;
  tempVerificationData: IPostAuthRequestRq | undefined;
  profileBasicInfoModel?: ProfileBasicInfoModel;
}

const VerificationForm: FC<IVerificationFormProps> = ({ stepSetter, tempSetter, tempVerificationData, profileBasicInfoModel = new ProfileBasicInfoModel() }) => {
  const { setToastObject } = useToast();

  const formSchema = yup.object({
    provider: yup.string().required(),
    name: yup.string().required('이름을 입력헤주세요.').matches(/^[a-zA-Z가-힣\s]*$/, '잘못된 이름 형식이에요.'),
    birthDay: yup.string().required('생년월일을 입력해 주세요.'),
    phone: yup.string().required('휴대폰 번호를 입력해 주세요.').length(11, '올바른 휴대폰 번호를 입력해 주세요.'),
  });

  const formMethods = useForm<IPostAuthRequestRq>({
    mode: 'onSubmit',
    resolver: yupResolver(formSchema),
    defaultValues: tempVerificationData || {
      provider: NHIS_PROVIDER.KAKAO,
      name: profileBasicInfoModel.baseInfo ? profileBasicInfoModel.baseInfo.name as string : '',
      telcoType: null,
      phone: profileBasicInfoModel.baseInfo ? profileBasicInfoModel.baseInfo.mobile as string : '',
      birthDay: profileBasicInfoModel.baseInfo ? profileBasicInfoModel.baseInfo.birthDate as string : make15YearsOldDate(),
    },
  });
  const { handleSubmit, watch } = formMethods;

  const provider = watch('provider');
  const name = watch('name');
  const telcoType = watch('telcoType');
  const birthDay = watch('birthDay');
  const phone = watch('phone');

  const onSubmit = async () => {
    try {
      await NhisAPI.postAuthRequest({ provider, name, telcoType, birthDay: birthDay.replace(/-/g, ''), phone });
      stepSetter(2);
    } catch (error) {
      console.error(error);
      const e = error as { response: { data: { errorCode: string } } };
      setToastObject({ isOpen: true, type: 'ERROR', message: NHIS_ERROR_MESSAGE[`request_${e.response.data.errorCode}`], duration: 3000 });
    }
  };

  const onError = (error: any) => {
    console.error(error);
    setToastObject({ isOpen: true, type: 'ERROR', message: '입력 정보를 다시 확인해 주세요.', duration: 3000 });
  };

  useEffect(() => {
    tempSetter({ provider, name, telcoType, birthDay, phone });
  }, [provider, name, telcoType, birthDay, phone, tempSetter]);

  return (
    <Frame onSubmit={handleSubmit(onSubmit, onError)} id='verificationForm'>
      <FormProvider {...formMethods}>
        <TitleFrame>
          <div className='title'>간편인증을 진행해 주세요.</div>
        </TitleFrame>
        <SpacingBlock size={32} vertical />
        <FormFrame>
          <FormItem>
            <label htmlFor='provider'>인증방식</label>
            <div className='radio-frame'>
              <RadioField name='provider' value={NHIS_PROVIDER.KAKAO} label={PrividerItem({ providerType: NHIS_PROVIDER.KAKAO })} />
              <RadioField name='provider' value={NHIS_PROVIDER.NAVER} label={PrividerItem({ providerType: NHIS_PROVIDER.NAVER })} />
            </div>
          </FormItem>
          <FormItem>
            <label htmlFor='name'>이름</label>
            <NameField name='name' defaultValue={name} />
          </FormItem>
          <FormItem>
            <label htmlFor='birthDay'>생년월일</label>
            <div className='date-frame'>
              <JDDatePickerInput
                name='birthDay'
                defaultValue={birthDay}
                placeholder='생년월일을 입력해 주세요.'
                maxDate={make15YearsOldDate()}
                fullSize
              />
            </div>
          </FormItem>
          <FormItem>
            <label htmlFor='phone'>휴대폰 번호</label>
            <PhoneField name='phone' defaultValue={phone} />
          </FormItem>
        </FormFrame>
      </FormProvider>
    </Frame>
  );
};

// export default VerificationForm;
export default inject(injectStore.profileBasicInfoModel)(observer(VerificationForm));
