export enum VeteranType {
    NONE = 'NONE',
    VETERAN = 'VETERAN',
}

export const VeteranStr: { [key in keyof typeof VeteranType]: string } = {
  NONE: '비대상',
  VETERAN: '대상',
};

export const VeteranTagStr: { [key in keyof typeof VeteranType]: string } = {
  NONE: '비대상',
  VETERAN: '대상',

};
