import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { IDashBoardButtonProps } from 'components/jobMatch/dashboard/MatchDashBoardCompanyMessage';
import { MatchingCommandType, PhsAccType } from 'consts/MatchingMessageType';
import { IMatchingTalkApplyMessageDto } from 'interfaces/rqrs/IMatchingMessageListRs';
import React, { FC } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;

  >button {
    padding: 8px 16px;
    border-radius: 4px;
    width: 100%;
    height: 46px;

    &:last-child {
      margin-top: 8px;
    }
  }

  .button-accept {
    background: ${colors.G_100};
    color: ${colors.WHITE_100};
    font: ${Fonts.B2_Bold};

    &:disabled {
      opacity: 0.5;
    }

    &[aria-disabled=true] {
      background: ${colors.WHITE_100};
      color: ${colors.CG_80};
      border: 1px solid ${colors.CG_40};
    }
  }
  .button-disabled {
    background: ${colors.CG_40};
    color: ${colors.CG_60};
    font: ${Fonts.B2_Bold};

  }
  .button-pc-only{
    background: ${colors.CG_30};
    color: ${colors.CG_50};
    font: ${Fonts.B2_Bold};
    border: 1px solid ${colors.CG_50}

  }

`;

export interface IDashBoardIPhsSubmitButton extends IDashBoardButtonProps {
  type: MatchingCommandType.ACC_REQUEST | MatchingCommandType.PHS_REQUEST | MatchingCommandType.ACC_LITE_REQUEST;
  submitApplies: IMatchingTalkApplyMessageDto[];
}

const DashBoardIPhsSubmitButton:FC<IDashBoardIPhsSubmitButton> = ({ onClick, disabled, completeYn, type, submitApplies }) => (
  <Frame>
    <button
      id='acc'
      aria-disabled={disabled}
      aria-hidden={type !== MatchingCommandType.ACC_REQUEST}
      className='button-accept'
      disabled={disabled || completeYn || submitApplies.some((data) => data.applyType === PhsAccType.ACC)}
      onClick={(e) => onClick(e)}
    >
      { completeYn || submitApplies.some((data) => data.applyType === PhsAccType.ACC) ? '역량검사 결과 제출 완료' : (!disabled ? '역량검사 결과 제출' : '제출 기한 만료') }
    </button>
    <button
      id='acc-lite'
      aria-disabled={disabled}
      aria-hidden={type !== MatchingCommandType.ACC_LITE_REQUEST}
      className={
        completeYn || submitApplies.some((data) => data.applyType === PhsAccType.ACC)
          ? 'button-disabled' // 결과 제출이 완료되었을 때
          : (!disabled
            ? 'button-pc-only' // PC에서만 응시 가능한 경우
            : 'button-disabled') // 제출 기한 만료일 때
      }
      disabled
      onClick={(e) => onClick(e)}
    >
      { completeYn || submitApplies.some((data) => data.applyType === PhsAccType.ACC) ? '역량검사 결과 제출' : (!disabled ? '응시 및 결과 제출은 PC에서 가능합니다.' : '제출 기한 만료') }
    </button>
    <button
      id='pacc'
      aria-disabled={disabled}
      aria-hidden={type !== MatchingCommandType.PHS_REQUEST}
      className='button-accept'
      disabled={disabled || completeYn || submitApplies.some((data) => data.applyType === PhsAccType.PACC)}
      onClick={(e) => onClick(e)}
    >
      { completeYn || submitApplies.some((data) => data.applyType === PhsAccType.PACC) ? '개발자 역량검사 결과 제출 완료' : (!disabled ? '개발자 역량검사 결과 제출' : '제출 기한 만료') }
    </button>
    <button
      id='pst'
      aria-disabled={disabled}
      aria-hidden={type !== MatchingCommandType.PHS_REQUEST}
      className='button-accept'
      disabled={disabled || completeYn || submitApplies.some((data) => data.applyType === PhsAccType.PST)}
      onClick={(e) => onClick(e)}
    >
      { completeYn || submitApplies.some((data) => data.applyType === PhsAccType.PST) ? '개발 구현 능력 검사 결과 제출 완료' : (!disabled ? '개발 구현 능력 검사 결과 제출' : '제출 기한 만료') }
    </button>

  </Frame>
);

export default DashBoardIPhsSubmitButton;
