import { Button, ButtonMode } from '__designkit__/button/Button';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import JDBaseModal, { IJDBaseModal } from 'components/_v2/_common/modals/JDBaseModal';
import { JDModalBasicFrame, JDModalDescription, JDModalTitle } from 'components/_v2/_common/modals/JDModalComponents';
import MatchServiceCancelConditionModal from 'components/_v2/profile/match/MatchServiceCancelConditionModal';
import { getOptions } from 'consts/_v2/_common/ReactSelectOptions';
import { ReactSelectStyles } from 'consts/_v2/_common/style/ReactSelectStyles';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import styled from 'styled-components';

const Frame = styled.div`

  ${JDModalTitle} {
    font: ${Fonts.H4_Bold};
    margin-bottom: 8px;
  }

  ${JDModalDescription} {
    
    b {
      font: ${Fonts.B2_Bold_P};
      color: ${colors.JOBDA_BLACK};
    }
  }

.select {
    position: relative;
    margin-top: 16px;
    border: 1px solid $C_COOL_GRAY_30;
    border-radius: 4px;
    }
.close-btn{
    z-index:1;
    position: absolute;
    right: 20px;
    top:20px;
    margin-bottom: 8px;
    }
  
  .confirm-button{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 44px;
      margin-top: 20px;
      background-color:${colors.JOBDA_BLACK};
      font: ${Fonts.B2_Bold};
      color:${colors.WHITE_100};
      border-radius: 4px;
      :disabled{
          color:${colors.CG_60};
          background-color: ${colors.CG_40};
      }
  }
`;

const ButtonFrame = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  
  .cancel-btn {
    margin-right: 8px;
  }
`;
interface IMatchApplyCancelModal extends IJDBaseModal {
}

const AccResultDeleteModal:FC<IMatchApplyCancelModal> = ({ isOpen, onClickClose, onSuccess }) => {
  return (
    <>
      <JDBaseModal dimmed isOpen={isOpen} onClickClose={onClickClose} isDimClickClose>
        <Frame>
          <JDModalBasicFrame>
            <JDModalTitle>역량검사 결과표를 삭제하시겠어요?</JDModalTitle>
            <JDModalDescription>삭제한 결과표는 다시 되돌릴 수 없습니다</JDModalDescription>

            <ButtonFrame>
              <Button className='cancel-btn' label='아니오' outLined size='large' onClick={onClickClose} />
              <Button label='네, 삭제할게요' size='large' buttonMode={ButtonMode.PRIMARY} onClick={onSuccess} />
            </ButtonFrame>
          </JDModalBasicFrame>
        </Frame>
      </JDBaseModal>
    </>

  );
};

export default AccResultDeleteModal;
