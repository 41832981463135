import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import JDALink from 'components/JDALink';
import PassboardTab from 'components/_v2/passboard/PassboardTab';
import RoutePaths from 'consts/RoutePaths';
import { lineClampBox } from 'consts/style/mixins';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import ImgMatch from 'assets/_v2/matchHome/matchIcon.svg';
import PassboardList from 'components/_v2/passboard/PassboardList';
import Colors from '__designkit__/common/colors';
import shadows from 'consts/style/shadows';
import Shadows from '__designkit__/common/shadows';
import { useHistory, useLocation } from 'react-router';
import PassboardBanner from 'components/_v2/passboard/PassboardBanner';
import { usePassReviewPosts } from 'query/passReview/usePassReveiwQuery';
import Contents from 'models/_v2/Contents';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import Login from 'models/Login';
import Context from 'models/Context';
import { PassReviewMetaTag } from 'components/_v2/_common/meta/MetaTag';

interface IPassboardMain {
  contents?:Contents
  login?:Login
  context?:Context
}

const Frame = styled.div`

`;

const TitleFrame = styled.div`
  margin-bottom: 24px;
  padding: 0 16px;
  font: ${Fonts.H5_Bold};
  color:${Colors.JOBDA_BLACK};
`;

const MainReviewFrame = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  
`;

const BestReview = styled.div`
  padding: 0 16px;
  width: 100%;
  overflow-x: scroll;

  ::-webkit-scrollbar {
        display: none;
    }
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  .scroll-area {
    display: flex;
    gap: 16px;
    width: fit-content;
  }

  .best-item {
    display: flex;
    flex-direction: column;
    width: 160px;
    
    .item-thumbnail {
      width: 160px;
      height: 112px;
      border-radius: 8px;
      background-color: ${Colors.CG_40};
      object-fit: cover;
    }

    .item-text {
      margin-top: 8px;

      .title {
        font: ${Fonts.B1_Bold};
        color: ${Colors.JOBDA_BLACK};
        ${lineClampBox(2)};
      }

      .tag {
        display: flex;
        gap: 8px;
        margin-top: 16px;
        font: ${Fonts.B3_Medium};
        color: ${Colors.CG_60};
      }
    }
  }

  /* 매칭 카드 */
  .matching-item {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 24px 16px;
    width: 160px;
    height: 192px;
    border-radius: 8px;
    border:1px solid ${Colors.G_70};
    background:${Colors.G_30};

    &::after {
      position: absolute;
      content: '';
      display: inline-block;
      right: 8px;
      bottom: 16px;
      width: 48px;
      height: 48px;
      background: url(${ImgMatch}) no-repeat;
    }

    .title {
      font: ${Fonts.B1_Bold};
      color: ${Colors.JOBDA_BLACK};
    }

    .btnLink {
      display: flex;
      align-items: center;
      margin-top: 12px;
      font: ${Fonts.B3_Medium};
      color: ${Colors.JOBDA_BLACK};
    }
  }
`;
const StyledSNB = styled.div`
  position: fixed;
  bottom: calc(81px + env(safe-area-inset-bottom));
  right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 48px;
  background-color: ${Colors.JOBDA_BLACK};
  filter: ${Shadows.Shadow_300};
`;
const PassboardMain:FC<IPassboardMain> = ({ contents = new Contents(), login = new Login(), context = new Context() }) => {
  const { pathname } = useLocation();
  const history = useHistory();
  useEffect(() => {
    const init = async () => {
      await contents.getPassReviewContents();
    };
    init();
  }, []);

  const handleWrite = () => {
    if (!login.userInfo) {
      context.setRedirectUrl(pathname);
      history.push(RoutePaths.login);
      return;
    }
    history.push(RoutePaths.passboard_new);
  };
  return (
    <Frame>
      <PassReviewMetaTag />
      <PassboardTab />

      <MainReviewFrame>
        {/* 배너 */}
        <div className='section'>
          <PassboardBanner />
        </div>
        {/* 선배들의 합격 꿀팁 대공개 */}
        <div className='section'>
          <TitleFrame>
            선배들의 합격 꿀팁 대공개
          </TitleFrame>
          <BestReview>
            <div className='scroll-area'>
              {
                contents.passReviewContents.map((item, index) => (
                  <div role='button' className='best-item' onClick={() => history.push(RoutePaths.info_detail(item.id))}>
                    <img src={item.imageSrc} alt='' className='item-thumbnail' />
                    <div className='item-text'>
                      <div className='title'>{item.title}</div>
                      <div className='tag'>

                        <div className='tagList'>{item.subTitle}</div>

                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </BestReview>
        </div>

        <PassboardList />
      </MainReviewFrame>
      <StyledSNB onClick={handleWrite}>
        <Icon name='guide' size={24} color={Colors.WHITE_100} />
      </StyledSNB>

    </Frame>
  );
};
export default inject(injectStore.contents, injectStore.login, injectStore.context)(observer(PassboardMain));
