import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import FormValueFrame, { DatePickerFormValueFrame } from 'components/_v2/_common/form/FormProfileValueFrame';
import FormProfileValueTitle from 'components/_v2/_common/form/FormProfileValueTitle';
import JDBaseInput from 'components/_v2/_common/input/JDBaseInput';
import JDDatePickerInput from 'components/_v2/_common/input/JDDatePickerInput';
import JDDescriptionInput from 'components/_v2/_common/input/JDDescriptionInput';
import JDRadioInputTemp, { JD_RADIO_INPUT_TYPE } from 'components/_v2/_common/input/JDRadioInputTemp';
import JDSelector, { JDSelectorType } from 'components/_v2/_common/input/JDSelector';
import { desturctureOptions } from 'components/_v2/profile/careers/ProfileCareers';
import CompanySearchField from 'components/react-hook-form/CompanySearchField';
import { EMPLOYMENT_STATUS, EMPLOYMENT_STATUS_TEXT, EMPLOYMENT_TYPE_TEXT } from 'consts/_v2/profile/career';
import { BaseProfileCareerDto } from 'interfaces/_v2/insight/IInsightResumeRqRs';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

const CareerRegisterFrame = styled.div`
    .content {
      padding: 0 1px;
    }
  .title-text{
      font:${Fonts.H4_Bold};
      color:${Colors.JOBDA_BLACK};
      margin-bottom: 24px;
    }
    height: auto;
    .radio-field {
    display: flex;
    margin-bottom: 24px;

    >:not(:first-child) {
      margin-left: 24px;
    }
  }
`;

interface IResuemEditCareer{
    prefixName:string;
    careerIdx:number;
    isVisible:boolean
    context?:Context
    careerFields?:BaseProfileCareerDto[]
}

const ResumeEditCareer:FC<IResuemEditCareer> = ({ prefixName, careerIdx, isVisible, context = new Context(), careerFields }) => {
  const { control, watch, setValue, trigger, errors } = useFormContext();
  const handleRadioStatus = () => {
    if (watch(`${prefixName}[${careerIdx}].employmentStatus`) === EMPLOYMENT_STATUS.RESIGN) {
      setValue('endDate', undefined);
    }
  };
  const topRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isVisible && topRef.current) {
      topRef.current.scrollIntoView();
    }
  }, [isVisible]);

  return (
    <CareerRegisterFrame hidden={!isVisible} ref={topRef}>
      <div className='title-text'>
        업무 성과를 나타낼 수 있는<br />구체적인 수치, 사실을 포함해 주세요.
      </div>
      {
        careerFields?.map((field, index) => (
          <div className='content' hidden={index !== careerIdx}>
            <FormProfileValueTitle>
              직장명
            </FormProfileValueTitle>
            <FormValueFrame>
              <CompanySearchField name={`${prefixName}[${index}].companyName`} defaultValue={watch(`${prefixName}[${index}].companyName`)} />
            </FormValueFrame>
            <FormProfileValueTitle>
              상태
            </FormProfileValueTitle>
            <div className='radio-field'>
              { Object.values(EMPLOYMENT_STATUS).map((key) => (
                <JDRadioInputTemp
                  type={JD_RADIO_INPUT_TYPE.ROUND_BOX}
                  key={`radio-option-${key}`}
                  name={`${prefixName}[${index}].employmentStatus`}
                  value={key}
                  label={EMPLOYMENT_STATUS_TEXT[key]}
                  defaultValue={watch(`${prefixName}[${index}].employmentStatus`)}
                  onChange={handleRadioStatus}
                />
              ))}
            </div>
            <FormProfileValueTitle>
              근무 기간
            </FormProfileValueTitle>
            <DatePickerFormValueFrame>
              <JDDatePickerInput
                name={`${prefixName}[${index}].startDate`}
                placeholder='연도. 월.'
                defaultValue={watch(`${prefixName}[${index}].startDate`)}
                month
              />
              <span className='date-picker-divider'>~</span>
              <JDDatePickerInput
                name={`${prefixName}[${index}].endDate`}
                placeholder='연도. 월.'
                defaultValue={watch(`${prefixName}[${index}].endDate`)}
                month
                disabled={watch(`${prefixName}[${index}].employmentStatus`) !== EMPLOYMENT_STATUS.RESIGN}
              />
            </DatePickerFormValueFrame>
            <FormProfileValueTitle required>직군</FormProfileValueTitle>
            <FormValueFrame>
              <JDSelector
                type={JDSelectorType.NORMAL}
                name={`${prefixName}[${index}].jobGroupCode`}
                selectTitle='직군을 선택해 주세요.'
                value={watch(`${prefixName}[${index}].jobGroupCode`) ? String(watch(`${prefixName}[${index}].jobGroupCode`)) : undefined}
                onChange={async (value) => {
                  setValue(`jobTitleCode`, value === '0' ? '0' : '');
                }}
              >
                <select>
                  <option value='' hidden>직군을 선택해 주세요.</option>
                  { desturctureOptions(context.jobGroupsWithTitles, watch(`${prefixName}[${index}].jobGroupCode`) ? (code: number) => code === Number(watch(`${prefixName}[${index}].jobGroupCode`)) : undefined) }
                </select>
              </JDSelector>
            </FormValueFrame>
            <FormProfileValueTitle required>직무</FormProfileValueTitle>
            <FormValueFrame>
              <JDSelector
                type={JDSelectorType.NORMAL}
                name={`${prefixName}[${index}].jobTitleCode`}
                selectTitle='직무를 선택해 주세요.'
                value={watch(`${prefixName}[${index}].jobTitleCode`) ? String(watch(`${prefixName}[${index}].jobTitleCode`)) : undefined}
                disabled={!watch(`${prefixName}[${index}].jobGroupCode`)}
              >
                <select>
                  <option value='' hidden>직무를 선택해 주세요.</option>
                  { watch(`${prefixName}[${index}].jobGroupCode`) && desturctureOptions(context.jobTitles[String(watch(`${prefixName}[${index}].jobGroupCode`))]?.titles, (code) => code === Number(watch(`${prefixName}[${index}].jobTitleCode`))) }
                </select>
              </JDSelector>
            </FormValueFrame>
            <FormProfileValueTitle required>계약 형태</FormProfileValueTitle>
            <FormValueFrame>
              <JDSelector
                type={JDSelectorType.NORMAL}
                name={`${prefixName}[${index}].employmentType`}
                selectTitle='계약 형태를 선택해 주세요.'
                value={watch(`${prefixName}[${index}].employmentType`)}
              >
                <select>
                  <option value='' hidden>계약 형태를 선택해 주세요.</option>
                  { Object.entries(EMPLOYMENT_TYPE_TEXT).map(([key, text]) => <option key={`option-career-level-${key}`} value={key} selected={watch(`${prefixName}[${index}].employmentType`) === key}>{text}</option>) }
                </select>
              </JDSelector>
            </FormValueFrame>
            <FormProfileValueTitle>역할</FormProfileValueTitle>
            <FormValueFrame>
              <JDBaseInput
                name={`${prefixName}[${index}].role`}
                placeholder='직장에서 담당했던 역할을 작성해 주세요.'
                defaultValue={watch(`${prefixName}[${index}].role`)}
                maxLength={30}
              />
            </FormValueFrame>
            <FormProfileValueTitle>주요 성과</FormProfileValueTitle>
            <FormValueFrame>
              <JDDescriptionInput
                name={`${prefixName}[${index}].performance`}
                placeholder='주요 성과를 간략하게 설명해 주세요.'
                maxLength={10000}
                autoFocus={false}
                defaultValue={watch(`${prefixName}[${index}].performance`)}
              />
            </FormValueFrame>
          </div>
        ))
      }

    </CareerRegisterFrame>
  );
};
export default inject(injectStore.context)(observer(ResumeEditCareer));
