import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import TabsWithComponent from '__designkit__/tab/TabsWithComponent';
import { AccaResultSampleTag } from 'components/_v2/_common/meta/MetaTag';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import request from 'utils/request';

const StyledIFrame = styled.iframe`
  position: relative;
  width: 100%;
  height: 100%;
  touch-action:pinch-zoom;
`;

const IFrame: FC<{ src: string }> = ({ src }) => (
  <StyledIFrame
    className='pinch-frame'
    src={src}
    allow='autoplay; fullscreen; picture-in-picture'
  />
);

const Frame = styled.div`
  width: 100%;
  height: 100vh;

  & .info{
    padding:16px;

    > h1{
      font: ${Fonts.B3_Bold_P};
      color:${colors.JOBDA_BLACK};
    }
  }
`;

const AccaResultSample = () => {
  const [sampleUrl, setSampleUrl] = useState<string>('');
  const [sampleToken, setSampleToken] = useState<string>('');

  useEffect(() => {
    (async () => {
      try {
        const res = await request<{ url: string, token: string }>({
          method: 'GET',
          url: '/acc/results/token/example',
        });
        setSampleUrl(res.url);
        setSampleToken(res.token);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  return (
    <Frame>
      <AccaResultSampleTag />
      <TabsWithComponent
        tabs={['응시 점검결과', '기본 분석 결과', '심층 분석 결과']}
        components={[
          <>
            <div className='info'>
              <h1>역량검사 결과는 PC에 최적화 되어 있습니다.</h1>
            </div>
            <IFrame src={`${sampleUrl}/acca/examinee-result-for-examinee?Authorization=Bearer${sampleToken}&requestPage=check`} />
          </>,
          <>
            <div className='info'>
              <h1>역량검사 결과는 PC에 최적화 되어 있습니다.</h1>
            </div>
            <IFrame src={`${sampleUrl}/acca/examinee-result-for-examinee?Authorization=Bearer${sampleToken}&requestPage=basic`} />
          </>,
          <>
            <div className='info'>
              <h1>역량검사 결과는 PC에 최적화 되어 있습니다.</h1>
            </div>
            <IFrame src={`${sampleUrl}/acca/examinee-result-for-examinee?Authorization=Bearer${sampleToken}&requestPage=deep`} />
          </>,
        ]}
      />
    </Frame>
  );
};

export default AccaResultSample;
