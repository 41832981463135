import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import AdminMessageTemplate from 'components/_v2/chatting/AdminMessageTemplate';
import { ADMIN_USER_NAME } from 'consts/_v2/chatting/chatting';
import { AdminMetaData } from 'interfaces/_v2/chatting/IChattingRqRs';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Message, MessageTypeEnum } from 'talkplus-sdk';

const ParentText = styled.div<{ isMine: boolean, readMore: boolean }>`
  width: 100%;

  & > div.parent-message {
    & > span {
      display: inline-block;
      font: ${Fonts.B3_Medium};
    }

    & > span.parent-message-user-name {
      font: ${Fonts.B4_Medium};
      color: ${({ isMine }) => (isMine ? Colors.WHITE_100 : Colors.JOBDA_BLACK)};
    }

    & > span.parent-message-text {
      width: 100%;
      display: -webkit-inline-box;
      word-wrap: break-word;
      -webkit-line-clamp: ${({ readMore }) => (readMore ? 'none' : '5')};
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
      color: ${({ isMine }) => (isMine ? '#FFFFFFB3' : Colors.CG_60)}; // TODO : 주연님께 디자인키트 내의 색으로 가능한지 문의
    }

    & > span.read-more-btn {
      font: ${({ isMine }) => (isMine ? Fonts.B3_Bold : Fonts.B3_Medium)};
      margin-top: 2px;
    }
  }

  & > div.divider {
    width: 100%;
    height: 1px;
    margin: 8px 0;
    background-color: ${({ isMine }) => (isMine ? '#ffffff33' : Colors.CG_30)}; // TODO : 주연님께 디자인키트 내의 색으로 가능한지 문의
  }
`;

interface IChattingReplyItemProps extends IComponentProps {
  item: Message
  isMine: boolean;
}

// 헤당 컴포넌트는 부모 메세지가 존재하는 경우에만 렌더링 되므로, parentMessage가 존재한다는 것을 보장한다. => !. 연산자 사용
const ChattingReplyItem: FC<IChattingReplyItemProps> = ({ item, isMine }) => {
  const [readMore, setReadMore] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);
  const parentMessageRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (parentMessageRef.current) {
      setIsOverflow(parentMessageRef.current.scrollHeight > parentMessageRef.current.clientHeight);
    }
  }, []);

  return (
    <ParentText isMine={isMine} readMore={readMore}>
      <div className='parent-message'>
        { item.parentMessage ? (
          <>
            <span className='parent-message-user-name'>{ item.parentMessage!.username === 'Admin' ? ADMIN_USER_NAME : item.parentMessage!.username } :</span>
            <SpacingBlock size={4} vertical />
            <span className='parent-message-text' ref={parentMessageRef}>
              { item.parentMessage!.type === MessageTypeEnum.Admin || item.type === MessageTypeEnum.AdminHidden
                ? <AdminMessageTemplate metaData={item.parentMessage!.data as unknown as AdminMetaData} item={item} />
                : item.parentMessage!.text }
            </span>
            { isOverflow && !readMore && <span className='read-more-btn' role='button' onClick={() => setReadMore(true)}>더보기</span> }
          </>
        ) : (
          <span className='parent-message-text' ref={parentMessageRef}>
            삭제된 메세지입니다.
          </span>
        ) }
      </div>
      <div className='divider' />
      <div>{ item.text }</div>
    </ParentText>
  );
};

export default ChattingReplyItem;
