export enum MatchHomeDashboardType {
  ALL = 'ALL',
  WRITING = 'WRITING',
  JOB = 'JOB',
  PROGRESS = 'PROGRESS',
  COMPLETED = 'COMPLETED',
}

export const MatchHomeDashboardTypeText: Record<
  MatchHomeDashboardType,
  string
> = {
  ALL: '전체',
  WRITING: '작성 중인 지원서',
  JOB: '지원 완료',
  PROGRESS: '전형진행중',
  COMPLETED: '전형종료',
};

export enum MatchHomeDashboardPositionType {
  WRITING_RESUME = 'WRITING_RESUME', // : 작성 중인 지원서 바로가기
  APPLY = 'APPLY', // : 지원 완료
  INTERVIEW_OFFERED = 'INTERVIEW_OFFERED', // : 면접 제안 요청
  INTERVIEW_ACCEPTED = 'INTERVIEW_ACCEPTED', // : 면접 제안 수락
  INTERVIEW_DENIED = 'INTERVIEW_DENIED', // : 면접 제안 거절
  INTERVIEW_ADJUSTED = 'INTERVIEW_ADJUSTED', // : 면접 일정 조정
  ACC_REQUEST = 'ACC_REQUEST', // : ACC 응시 제출 요청
  ACC_SUBMIT = 'ACC_SUBMIT', // : ACC 응시 제출 완료
  PHS_REQUEST = 'PHS_REQUEST', // : PHS 응시 제출 요청
  PHS_SUBMIT = 'PHS_SUBMIT', // : PHS 응시 제출 완료
  INTERVIEW_PASSED = 'INTERVIEW_PASSED', // : 합격
  INTERVIEW_FAILED = 'INTERVIEW_FAILED', // : 불합격
  SUBMIT_RESUME = 'SUBMIT_RESUME', // 제출 완료
  SHOW_SUBMIT_RESUME = 'SHOW_SUBMIT_RESUME', // 제출 한 지원서 바로가기
  IN_PROGRESS = 'IN_PROGRESS', // 전형 진행 중 (INSIGHT)
  END='END', // 전형 종료 (INSIGHT)
  HIDDEN = 'HIDDEN', // 숨겨진 포지션 FRONT용
}
export const MatchHomeDashboardPositionTypeText: { [key in MatchHomeDashboardPositionType]: string } = {
  [MatchHomeDashboardPositionType.WRITING_RESUME]: '작성 중인 지원서',
  [MatchHomeDashboardPositionType.APPLY]: '입사 지원',
  [MatchHomeDashboardPositionType.INTERVIEW_OFFERED]: '면접 요청',
  [MatchHomeDashboardPositionType.INTERVIEW_ACCEPTED]: '면접 수락',
  [MatchHomeDashboardPositionType.INTERVIEW_DENIED]: '면접 거절',
  [MatchHomeDashboardPositionType.INTERVIEW_ADJUSTED]: '면접 조율 중',
  [MatchHomeDashboardPositionType.ACC_REQUEST]: '역량검사 제출 필요',
  [MatchHomeDashboardPositionType.ACC_SUBMIT]: '역량검사 제출 완료',
  [MatchHomeDashboardPositionType.PHS_REQUEST]: '개발자 검사 제출 필요',
  [MatchHomeDashboardPositionType.PHS_SUBMIT]: '개발자 검사 제출 완료',
  [MatchHomeDashboardPositionType.INTERVIEW_PASSED]: '최종 합격',
  [MatchHomeDashboardPositionType.INTERVIEW_FAILED]: '불합격',
  [MatchHomeDashboardPositionType.SUBMIT_RESUME]: '제출 완료',
  [MatchHomeDashboardPositionType.SHOW_SUBMIT_RESUME]: '제출 한 지원서',
  [MatchHomeDashboardPositionType.IN_PROGRESS]: '진행 중',
  [MatchHomeDashboardPositionType.END]: '종료',
  [MatchHomeDashboardPositionType.HIDDEN]: '숨겨진 포지션',
};

export const MatchHomeDashboardPositionTypeTextForPdf: { [key in MatchHomeDashboardPositionType]: string } = {
  [MatchHomeDashboardPositionType.WRITING_RESUME]: '작성 중인 지원서',
  [MatchHomeDashboardPositionType.APPLY]: '지원완료',
  [MatchHomeDashboardPositionType.INTERVIEW_OFFERED]: '전형 진행중',
  [MatchHomeDashboardPositionType.INTERVIEW_ACCEPTED]: '전형 진행중',
  [MatchHomeDashboardPositionType.INTERVIEW_DENIED]: '전형 진행중',
  [MatchHomeDashboardPositionType.INTERVIEW_ADJUSTED]: '전형 진행중',
  [MatchHomeDashboardPositionType.ACC_REQUEST]: '전형 진행중',
  [MatchHomeDashboardPositionType.ACC_SUBMIT]: '전형 진행중',
  [MatchHomeDashboardPositionType.PHS_REQUEST]: '전형 진행중',
  [MatchHomeDashboardPositionType.PHS_SUBMIT]: '전형 진행중',
  [MatchHomeDashboardPositionType.INTERVIEW_PASSED]: '최종 합격',
  [MatchHomeDashboardPositionType.INTERVIEW_FAILED]: '불합격',
  [MatchHomeDashboardPositionType.SUBMIT_RESUME]: '제출 완료',
  [MatchHomeDashboardPositionType.SHOW_SUBMIT_RESUME]: '제출 한 지원서',
  [MatchHomeDashboardPositionType.IN_PROGRESS]: '전형 진행중',
  [MatchHomeDashboardPositionType.END]: '전형 종료',
  [MatchHomeDashboardPositionType.HIDDEN]: '숨겨진 포지션',
};
