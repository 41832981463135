import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import iconCheck from 'assets/_v2/_common/icon_check_white.svg';
import { IconJobConstructionOnOff, IconJobCustomerSupportOnOff, IconJobDesignOnOff, IconJobInsuranceOnOff, IconJobItServiceOnOff, IconJobManageOnOff, IconJobMarketingOnOff, IconJobMedicalOnOff, IconJobProductOnOff, IconJobRndOnOff, IconJobSalesOnOff } from 'assets/_v2/matchHome/ImgOnOffMatchingCard';
import JDContentCheckBox from 'components/_v2/_common/input/JDContentCheckBox';
import JDFullModal from 'components/_v2/_common/modals/JDFullModal';
import MatchApplyJobTitles from 'components/_v2/matchApply/MatchApplyJobTitles';
import MatchHomeTitleHeader from 'components/_v2/matchHome/MatchHomeTitleHeader';
import { JDMainButton } from 'consts/_v2/_common/style/mixins';
import { ProfileType } from 'consts/_v2/profile/ProfileType';
import { IJobGroupsWithTitle } from 'interfaces/_v2/ICodeRqRs';
import IMatchApplyProps from 'interfaces/_v2/matchApply/IMatchApplyProps';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import MatchApplyModel from 'models/_v2/MatchApplyModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

const Frame = styled.div`
  position: relative;
  padding: 32px 16px calc(62px + env(safe-area-inset-bottom));

  .close-btn {
    position: absolute;
    text-align: right;
    width: 100%;
    top: 32px;
    right: 32px;
  }
`;

const JobListFrame = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 12px;
    column-gap: 12px;
    margin-bottom: 32px;
    &[aria-hidden=true] {
      display: none;
    }

    .card-frame {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 96px;
      padding: 16px 0;
      background: ${colors.WHITE_100};
      border: 1px solid ${colors.CG_50};
      border-radius: 8px;
      font: ${Fonts.B2_Bold};
      color: ${colors.JOBDA_BLACK};

      &[aria-checked=true] {
        background: ${colors.CG_70};
        color: ${colors.WHITE_100};
      }

      span {
        margin-top: 10px;
      }
    }
    
`;

const SavaJobFrame = styled.div`
  position: fixed;
  bottom: env(safe-area-inset-bottom);
  background-color: ${colors.WHITE_100};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 72px;
  padding: 10px 16px;
  border-top: 1px solid ${colors.CG_40};
  button {
    width: 160px;
    height: 46px;
  }
  .back-button {
      ${JDMainButton(true, '100%')};
      margin-right: 8px;

      &[aria-hidden=true] {
        visibility: hidden;
      }
     
    }
  .next-button {
      ${JDMainButton(false, '100%')};

      &:disabled {
        background: ${colors.CG_50};
      }

      &[aria-hidden=true] {
        display: none;
      }
  }

  .submit-button {
    ${JDMainButton(false, '100%')};

    &:disabled {
      background: ${colors.CG_50};
    }

    &[aria-hidden=true] {
      display: none;
    }

    &::before {
      content: '';
      width: 24px;
      height: 24px;
      margin-right: 8px;
      background-image: url(${iconCheck});
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
`;

export interface IMatchApplyJobProps extends IMatchApplyProps {
  handleClose: () => void;
  handleSuccess: () => void;
  modalType: ProfileType;
  context?: Context;
}

const DEVELOPER_CODE = 13;

const MatchApplyJob: FC<IMatchApplyJobProps> = ({ handleClose, handleSuccess, context = new Context(), matchApplyModel = new MatchApplyModel(), modalType }) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const useformed = useForm({ mode: 'all' });
  const { watch } = useformed;
  const [jobNextPage, setJobNextPage] = useState<boolean>(false);
  const [selectList, setSelectList] = useState<IJobGroupsWithTitle[]>([]);

  useEffect(() => {
    setJobNextPage(false);
    setSelectList([]);
    if (modalType === ProfileType.DEVELOPER) {
      const dev = context.jobGroupsWithTitles.find((data) => data.code === DEVELOPER_CODE);
      if (dev) {
        setSelectList([dev]);
      }
      setJobNextPage(true);
    }
  }, []);

  const handleClick = (nextPage: boolean) => {
    setJobNextPage(nextPage);
    if (scrollRef.current) scrollRef.current.scrollIntoView({ block: 'start' });
  };

  const handleSubmit = async () => {
    await matchApplyModel.loadSavedJobGroups();

    if (modalType === ProfileType.GENERAL && matchApplyModel.savedJobGroups) {
      matchApplyModel.savedJobGroups.forEach((data) => {
        if (data.jobGroupCode === DEVELOPER_CODE) {
          matchApplyModel.transJobGroups.push({ jobGroupCode: data.jobGroupCode, jobTitleCodes: data.jobTitleCodes });
        }
      });
    }

    if (modalType === ProfileType.DEVELOPER && matchApplyModel.savedJobGroups) {
      matchApplyModel.savedJobGroups.forEach((data) => {
        if (data.jobGroupCode !== DEVELOPER_CODE) {
          matchApplyModel.transJobGroups.push({ jobGroupCode: data.jobGroupCode, jobTitleCodes: data.jobTitleCodes });
        }
      });
    }
    await matchApplyModel.saveJob(matchApplyModel.transJobGroups);
    setSelectList([]);
    setJobNextPage(false);
    handleSuccess();
    handleClose();
  };

  const onChangeCheckbox = () => {
    const list: [] = watch('jobCards');
    setSelectList(list.filter((value) => (value && value !== undefined)));
  };

  const handleJobIcon = (jobCode: number) => {
    const checked = selectList?.some(({ code }) => code === jobCode);
    switch (jobCode) {
      case 1:
        return <IconJobManageOnOff active={checked} />;
      case 2:
        return <IconJobMarketingOnOff active={checked} />;
      case 3:
        return <IconJobSalesOnOff active={checked} />;
      case 4:
        return <IconJobProductOnOff active={checked} />;
      case 6:
        return <IconJobConstructionOnOff active={checked} />;
      case 7:
        return <IconJobRndOnOff active={checked} />;
      case 8:
        return <IconJobItServiceOnOff active={checked} />;
      case 9:
        return <IconJobDesignOnOff active={checked} />;
      case 10:
        return <IconJobInsuranceOnOff active={checked} />;
      case 11:
        return <IconJobCustomerSupportOnOff active={checked} />;
      case 12:
        return <IconJobMedicalOnOff active={checked} />;
      default:
        return <></>;
    }
  };
  return (
    <JDFullModal handleClose={handleClose}>
      <Frame ref={scrollRef}>
        <MatchHomeTitleHeader
          title='어떤일을 하고 싶으세요?'
          desc='희망하는 직군·직무를 모두 선택해 주세요! <br />직군·직무 선택에 따라 추천되는 포지션이 달라져요.'
        />

        <FormProvider {...useformed}>
          <JobListFrame aria-hidden={jobNextPage}>
            {context.jobGroupsWithTitles.map(({ name, code, jobTitles }) => {
              if (code === DEVELOPER_CODE) return <></>;
              return (
                <JDContentCheckBox
                  key={`job-card-${code}`}
                  name={`jobCards.${code}`}
                  value={{ name, code, jobTitles }}
                  onChange={onChangeCheckbox}
                  label={(
                    <div className='card-frame' aria-checked={selectList?.some((data) => data.code === code)}>
                      {handleJobIcon(code)}
                      <span>{name}</span>
                    </div>
                              )}
                  defaultChecked={selectList?.some((data) => data.code === code)}
                />
              );
            })}
          </JobListFrame>
          {
            selectList.length !== 0 && <MatchApplyJobTitles selectedJobGroups={selectList} hide={!jobNextPage} />
          }
        </FormProvider>
      </Frame>
      <SavaJobFrame>
        <button className='back-button' aria-hidden={!jobNextPage || modalType === ProfileType.DEVELOPER} onClick={() => handleClick(false)}>이전</button>
        <button className='next-button' aria-hidden={jobNextPage} disabled={selectList.length === 0} onClick={() => handleClick(true)}>다음
        </button>
        <button className='submit-button' aria-hidden={!jobNextPage} disabled={matchApplyModel.transJobGroups.length === 0} onClick={() => handleSubmit()}>선택완료</button>
      </SavaJobFrame>
    </JDFullModal>
  );
};

export default inject(injectStore.context, injectStore.matchApplyModel)(observer(MatchApplyJob));
