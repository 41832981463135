import { ErrorMessage } from '@hookform/error-message';
import colors from '__designkit__/common/colors';
import { emailValidator, idValidator, numberPointValidator, numberValidator } from 'consts/ValidationRule';
import Fonts, { fontStyle } from '__designkit__/common/fonts';
import { JDBaseInputMixin } from 'consts/_v2/_common/style/mixins';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { forwardRef, ReactElement, useState, useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import styled from 'styled-components/macro';
import { getMobileAutoHyphen } from 'utils/NumberFormatUtil';
import Shadows from '__designkit__/common/shadows';

const Frame = styled.div`
  ${JDBaseInputMixin()};

  .action-button{
    width: 68px;
    height:32px;
    position:absolute;
    margin-left:auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: ${colors.JOBDA_BLACK};
    color:${colors.WHITE_100};
    font: ${Fonts.B2_Bold};
    top:20%;
    right:16px;

    :disabled{
      background: ${colors.CG_50};
    }
  }
`;

const AutoCompleteList = styled.div`
  position: relative;
  top: 6px;
  width: 100%;
  height: fit-content;
  border-radius: 12px;
  background-color: ${colors.WHITE_100};
  filter: ${Shadows.Shadow_400};

  z-index: 100;
`;

const AutoCompleteItem = styled.div`
  width: 100%;
  padding: 12px 32px;
  font: ${Fonts.B1_Medium};

  > span {
    font: ${Fonts.B1_Bold};
  }
`;

interface IJDInnerButtonInput extends IComponentProps {
  name: string;
  defaultMessage?: string
  type?: string;
  disabled?: boolean;
  defaultValue?: string;
  className?: string;
  caption?:ReactElement;
  btnLabel: string;
  btnDisabled?: boolean;
  inputDisabled?: boolean;
  changed?:boolean;
  onClickInnerBtn: () => void;
  autoCompleteList?: {
    fullValue: string;
    userInput: string;
  }[];
}

const JDInnerButtonInput = forwardRef((props: IJDInnerButtonInput, fieldRef) => {
  const { name, changed, defaultMessage, type, onClear, disabled, defaultValue, inputDisabled, className, caption, btnLabel, onClickInnerBtn, btnDisabled, onChange, autoCompleteList, ...rest } = props;
  const useFormed = useFormContext();
  const { control, errors, watch, setValue, setError, trigger } = useFormed;
  const { field: { ref: BaseRef }, meta } = useController({ name, control, defaultValue });
  const [inputValue, setInputValue] = useState<string>('');
  const [isFocus, setIsFocus] = useState<boolean>(false);

  const checkValue = (value: string) => {
    if (type === 'email') {
      setInputValue(value);
      if (emailValidator(value) !== true) {
        setError('email', { message: '올바른 형식이 아닙니다.' });
      } else {
        trigger(name);
      }
    }

    if (type === 'id') {
      setInputValue(value);
      if (idValidator(value) !== true) {
        setError('id', { message: '올바른 형식이 아닙니다.' });
      } else {
        trigger(name);
      }
    }

    setValue(name, value);

    if (onChange) {
      onChange();
    }
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (type === 'tel') {
      setInputValue(getMobileAutoHyphen(e.currentTarget.value));
    }

    if (type === 'tel-nonHyphen') {
      if (numberValidator(e.currentTarget.value)) {
        setInputValue(e.currentTarget.value);
      } else {
        e.currentTarget.value = inputValue;
      }
    }

    if (type === 'numberPoint') {
      if (numberPointValidator(e.currentTarget.value)) {
        setInputValue(e.currentTarget.value);
      } else {
        e.currentTarget.value = inputValue;
      }
    }

    checkValue(e.currentTarget.value);
  };

  useEffect(() => {
    if (inputValue !== watch(name)) {
      setInputValue(watch(name));
    }
  }, [changed]);

  useEffect(() => {
    if (type === 'tel') {
      setInputValue(getMobileAutoHyphen(watch(name)));
    }
  }, [watch(name)]);

  return (
    <Frame className={`jd-input ${className || ''} ${!meta.invalid}`}>
      <div>
        <input
          name={name}
          ref={(innerRef) => {
            if (fieldRef !== null) (fieldRef as any).current = innerRef;
            if (BaseRef !== undefined) BaseRef.current = innerRef;
          }}
          value={inputValue}
          className={`${watch(name) ? 'value' : ''}`}
          onChange={onChangeHandler}
          disabled={!!disabled || inputDisabled}
          defaultValue={defaultValue}
          autoComplete='off'
          autoCapitalize='off'
          {...rest}
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
        />
        { btnLabel
        && (
        <button
          type='button'
          className='action-button'
          disabled={disabled || btnDisabled}
          onClick={(e) => {
            e.stopPropagation();
            if (onClickInnerBtn) onClickInnerBtn();
          }}
        >
          {btnLabel}
        </button>
        )}
        {isFocus && autoCompleteList && (
        <AutoCompleteList>
          {
            autoCompleteList.map((item) => (
              <AutoCompleteItem
                key={item.fullValue}
                onMouseDown={() => {
                  setInputValue(item.fullValue);
                  setValue(name, item.fullValue);
                  checkValue(item.fullValue);
                }}
              >
                <span>{item.userInput}</span>{item.fullValue.slice(item.userInput.length)}
              </AutoCompleteItem>
            ))
          }
        </AutoCompleteList>
        )}
      </div>
      {(defaultMessage && !meta.invalid) && <h4 className='message'>{defaultMessage}</h4>}
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <h4 className='message false'>{message}</h4>}
      />
      {caption}
    </Frame>
  );
});

export default JDInnerButtonInput;
