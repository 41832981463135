const week = ['일', '월', '화', '수', '목', '금', '토'];

// 밀리초를 '10:22' 형식으로 변환
export const convertMsToMMSS = (ms: number): string => {
  const min = Math.floor(ms / 60000);
  const sec = (ms % 60000) / 1000;
  return `${min < 10 ? `0${min}` : min}:${sec < 10 ? `0${sec}` : sec}`;
};

// 밀리초를 '오전 9:32' 형식으로 변환
export const convertMsToTime = (time: number) => {
  const date = new Date(time);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? '오후' : '오전';
  const hour = hours % 12;
  const hourString = hour || 12;
  const minuteString = minutes < 10 ? `0${minutes}` : minutes;
  const strTime = `${ampm} ${hourString}:${minuteString}`;
  return strTime;
};

// 밀리초를 '2023년 9월 27일 (월)' 형식으로 변환
export const convertMsToDate = (time: number) => {
  const date = new Date(time);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const dayOfWeek = date.getDay();
  return `${year}년 ${month}월 ${day}일 (${week[dayOfWeek]})`;
};
