import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import IconFileLink from 'assets/_v2/profile/icon_profile_fileLink.svg';
import Loading from 'components/Loading';
import ProfileItemTitle from 'components/_v2/profile/ProfileItemTitle';
import ProfileMoveDirtyModal from 'components/_v2/profile/ProfileMoveDirtyModal';
import ProfileAttachedFileEditModal from 'components/_v2/profile/attachedFile/ProfileAttachedFileEditModal';
import URIs from 'consts/URIs';
import { lineClampBox } from 'consts/_v2/_common/style/mixins';
import useToast from 'hooks/useToast';
import { IProfileProps } from 'interfaces/_v2/profile/IProfileProps';
import { inject, observer } from 'mobx-react';
import ProfileModel from 'models/_v2/profile/ProfileModel';
import { injectStore } from 'models/store';
import { FC, useState } from 'react';
import MobileStore from 'store/mobileStore';
import styled from 'styled-components';
import FileUtil from 'utils/FileUtil';
import TextUtil from 'utils/TextUtil';
import request from 'utils/request';

const Frame = styled.div``;

const ContentFrame = styled.div`
  &:not(:last-child) {
    margin-bottom: 32px;
  }
  
  .title {
    font: ${Fonts.H5_Bold};
    color: ${colors.JOBDA_BLACK};
    margin-bottom: 12px;
  }

  .contents {
    display: flex;
    align-items: center;

    .file-name {
      font: ${Fonts.B2_Medium_P};
      color: ${colors.JOBDA_BLACK};
      text-decoration-line: underline;
      margin-left: 8px;
      word-break: break-all;
      ${lineClampBox(1)};
    }

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
`;

const ProfileAttachedFileView:FC<IProfileProps> = ({ profileModel = new ProfileModel() }) => {
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDirtyModalOpen, setIsDirtyModalOpen] = useState<boolean>(false);
  const { attachedFile } = profileModel;
  const { setToastObject } = useToast();
  const handleEditClick = () => {
    setIsEditOpen(true);
  };

  const closeDirtyModal = () => {
    profileModel.isDirty = false;
    setIsDirtyModalOpen(false);
  };

  const downloadFile = async (fileName: string, fileUid: string) => {
    if (MobileStore.isMobile) {
      setIsLoading(true);
    }

    try {
      const res = await request<ArrayBuffer>({
        method: 'get',
        url: URIs.get_file_download(fileUid),
        responseType: 'arraybuffer',
      });
      FileUtil.fileDownload(fileName, res, false, setToastObject, setIsLoading);
      return res;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  return (
    <Frame>
      {isLoading && <Loading />}
      <ProfileItemTitle icon='folder-open' text='첨부자료' subText='포트폴리오, 경력기술서, URL 등을 첨부해 주세요.' onClickEditBtn={handleEditClick} />
      { attachedFile && (
        <>
          { attachedFile.portfolioAttachFiles && attachedFile.portfolioAttachFiles.length > 0 && (
          <ContentFrame>
            <p className='title'>포트폴리오</p>
            { attachedFile.portfolioAttachFiles.map((portfolioFile) => (
              <div className='contents' key={portfolioFile.fileUid}>
                <img src={IconFileLink} alt='icon-file-link' />
                <span role='button' className='file-name' onClick={() => downloadFile(portfolioFile.fileName, portfolioFile.fileUid)}>{portfolioFile.fileName}</span>
              </div>
            ))}
          </ContentFrame>
          )}
          { attachedFile.careerAttachFiles && attachedFile.careerAttachFiles.length > 0 && (
          <ContentFrame>
            <p className='title'>경력기술서</p>
            { attachedFile.careerAttachFiles.map((careerFile) => (
              <div className='contents' key={careerFile.fileUid}>
                <img src={IconFileLink} alt='icon-file-link' />
                <span role='button' className='file-name' onClick={() => downloadFile(careerFile.fileName, careerFile.fileUid)}>{careerFile.fileName}</span>
              </div>
            ))}
          </ContentFrame>
          )}
          { attachedFile.referenceUrls && attachedFile.referenceUrls.length > 0 && (
          <ContentFrame>
            <p className='title'>URL</p>
            { attachedFile.referenceUrls.map((url) => (
              <div className='contents' key={`attachedFile-${url}`}>
                <img src={IconFileLink} alt='icon-file-link' />
                <a href={TextUtil.formatWithProtocol(url)}>
                  <span className='file-name'>{url}</span>
                </a>
              </div>
            ))}
          </ContentFrame>
          )}
        </>
      )}
      <ProfileAttachedFileEditModal
        isOpen={isEditOpen}
        onClose={async () => {
          if (profileModel.isDirty) setIsDirtyModalOpen(true);
          else setIsEditOpen(false);
          await profileModel.loadAttachedFile();
        }}
      />
      <ProfileMoveDirtyModal isOpen={isDirtyModalOpen} onSuccess={() => setIsEditOpen(false)} onClickClose={closeDirtyModal} />
    </Frame>
  );
};

export default inject(injectStore.profileModel)(observer(ProfileAttachedFileView));
