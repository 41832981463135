import { AxiosError } from 'axios';
import { PassReviewPostCommentsRq, PassReviewPostListGetRq, PassReviewPostSaveRq, PassReviewQuestionListRq } from 'interfaces/_v2/passReview/IPassReviewRqRs';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import { RemotePassReviewRepo } from 'repository/passReview/PassReviewRepo';

const keys = ['passReview'];
const repo = new RemotePassReviewRepo();

// 합격 후기 게시글 목록 조회
const fetchPassReviewPosts = async (rq: PassReviewPostListGetRq) => {
  const data = await repo.fetchPassReviewPosts(rq);
  return data;
};

export const usePassReviewPosts = (rq: PassReviewPostListGetRq) => {
  return useInfiniteQuery([...keys, 'list', rq], () => fetchPassReviewPosts(rq), {
    getNextPageParam: ({ pages: { page, totalPages } }) => (page < totalPages ? page + 1 : undefined),
    keepPreviousData: true,
  });
};

// 합격 후기 게시글 등록
const createPassReviewPosts = async (rq: PassReviewPostSaveRq) => {
  const data = await repo.createPassReviewPosts(rq);
  return data;
};

export const useCreatePassReviewPosts = (onSuccess: () => void, onError?: (error: AxiosError) => void) => {
  return useMutation(createPassReviewPosts, {
    onSuccess,
    onError,
  });
};

// 합격 후기 게시글 상세 조회
const fetchPassReviewPostDetail = async (sn: number) => {
  const data = await repo.fetchPassReviewPostDetail(sn);
  return data;
};

export const usePassReviewPostDetail = (sn: number) => {
  return useQuery([...keys, 'detail', sn], () => fetchPassReviewPostDetail(sn), { enabled: !!sn });
};

// 합격 후기 게시글 수정
const updatePassReviewPosts = async ({ sn, rq }: { sn: number; rq: PassReviewPostSaveRq }) => {
  const data = await repo.updatePassReviewPosts(sn, rq);
  return data;
};

export const useUpdatePassReviewPosts = (onSuccess: () => void, onError?: (error: AxiosError) => void) => {
  return useMutation(updatePassReviewPosts, {
    onSuccess,
    onError,
  });
};

// 합격 후기 게시글 삭제
const deletePassReviewPosts = async (sn: number) => {
  const data = await repo.deletePassReviewPosts(sn);
  return data;
};

// 게시글 삭제 사용
export const useDeletePassReviewPosts = (onSuccess: () => void, onError?: (error: AxiosError) => void) => {
  return useMutation((sn: number) => deletePassReviewPosts(sn), {
    onSuccess,
    onError,
  });
};
// 합격 후기 게시글 질문 목록 조회
const fetchPassReviewPostQuestions = async (rq: PassReviewQuestionListRq) => {
  const data = await repo.fetchPassReviewPostQuestions(rq);
  return data;
};

export const usePassReviewPostQuestions = (rq: PassReviewQuestionListRq) => {
  return useQuery([...keys, 'questions', rq], () => fetchPassReviewPostQuestions(rq));
};
// 합격 후기 배너 목록 조회
const fetchPassReviewBanners = async () => {
  const data = await repo.fetchPassReviewBanners();
  return data;
};

export const usePassReviewBanners = () => {
  return useQuery([...keys, 'banners'], fetchPassReviewBanners);
};

// 합격 후기 댓글 조회
const fetchPassReviewLoadComments = async (sn: number) => {
  const data = await repo.fetchPassReviewLoadComments(sn);
  return data;
};

export const usePassReviewLoadComments = (sn: number) => {
  return useQuery([...keys, 'comments', sn], () => fetchPassReviewLoadComments(sn));
};

// 합격 후기 댓글 등록

const fetchPassReviewCreateComments = async (sn: number, rq: PassReviewPostCommentsRq) => {
  const data = await repo.fetchPassReviewCreateComments(sn, rq);
  return data;
};

export const usePassReviewCreateComments = (onSuccess: () => void, onError?: (error: AxiosError) => void) => {
  return useMutation(({ sn, rq }: { sn: number; rq: PassReviewPostCommentsRq }) => fetchPassReviewCreateComments(sn, rq), {
    onSuccess,
    onError,
  });
};

// 합격 후기 댓글 삭제
const fetchPassReviewDeleteComments = async (sn: number, commentSn: number) => {
  const data = await repo.fetchPassReviewDeleteComments(sn, commentSn);
  return data;
};

export const usePassReviewDeleteComments = (onSuccess: () => void, onError?: (error: AxiosError) => void) => {
  return useMutation(({ sn, commentSn }: { sn: number; commentSn: number }) => fetchPassReviewDeleteComments(sn, commentSn), {
    onSuccess,
    onError,
  });
};

// 합격 후기 댓글 수정

const fetchPassReviewUpdateComments = async (sn: number, commentSn: number, rq: PassReviewPostCommentsRq) => {
  const data = await repo.fetchPassReviewUpdateComments(sn, commentSn, rq);
  return data;
};

export const usePassReviewUpdateComments = (onSuccess: () => void, onError?: (error: AxiosError) => void) => {
  return useMutation(({ sn, commentSn, rq }: { sn: number; commentSn: number; rq: PassReviewPostCommentsRq }) => fetchPassReviewUpdateComments(sn, commentSn, rq), {
    onSuccess,
    onError,
  });
};
