import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import ChannelEmpty from 'components/_v2/chatting/ChannelEmpty';
import ChannelList from 'components/_v2/chatting/ChannelList';
import { STAR_CHANNEL_COUNT } from 'consts/_v2/chatting/chatting';
import IComponentProps from 'interfaces/props/IComponentProps';
import { inject, observer } from 'mobx-react';
import ChattingModel from 'models/_v2/ChattingModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Channel } from 'talkplus-sdk';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 8px;

  & > .title {
    width: 100%;
    padding: 8px 12px;
    font: ${Fonts.B1_Bold};
  }
`;

const SearshFrame = styled.div`
  width: 100%;
  height: fit-content;
  padding: 0 12px;
`;

const SearchBox = styled.div<{ isFocus: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 12px;
  border: 1px solid ${({ isFocus }) => (isFocus ? Colors.BLACK_100 : 'transparent')};
  border-radius: 4px;
  background-color: ${({ isFocus }) => (isFocus ? Colors.WHITE_100 : Colors.CG_30)};

  & > input {
    width: calc(100% - 56px);
    margin-left: 4px;
    border: none;
    background-color: transparent;
    font: ${Fonts.B2_Medium};
    color: ${Colors.JOBDA_BLACK};
    outline: none;
  }
`;

const Divider = styled.div`
  width: calc(100% + 16px);
  height: 1px;
  margin: 16px 0;
  background-color: ${Colors.CG_40};
`;

interface IChattingSearchProps extends IComponentProps {
  chattingModel?: ChattingModel;
}

const ChattingSearch: FC<IChattingSearchProps> = ({ chattingModel = new ChattingModel() }) => {
  const debounceTimer = useRef<number>(0);
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchResult, setSearchResult] = useState<Channel[]>([]);

  const initValue = () => {
    setInputValue('');
    setSearchValue('');
  };

  useEffect(() => {
    if (debounceTimer.current) window.clearTimeout(debounceTimer.current);
    debounceTimer.current = window.setTimeout(async () => {
      setSearchValue(inputValue);
    }, 300);
  }, [inputValue]);

  useEffect(() => {
    if (searchValue && chattingModel.allChannelList) setSearchResult(chattingModel.allChannelList.filter((channel: Channel) => channel.name.includes(searchValue)));
  }, [chattingModel.allChannelList, searchValue]);

  return (
    <Frame>
      <SpacingBlock size={16} vertical />
      <SearshFrame>
        <SearchBox isFocus={isFocus}>
          <Icon name='search' size={32} color={Colors.CG_70} />
          <input type='text' placeholder='기업명 검색' value={inputValue} onChange={(e) => setInputValue(e.target.value)} onFocus={() => setIsFocus(true)} onBlur={() => setIsFocus(false)} />
          { searchValue.length > 0 && <Icon name='close' size={24} onClick={initValue} />}
        </SearchBox>
      </SearshFrame>
      <SpacingBlock size={16} vertical />
      { searchValue
        ? searchResult.length > 0
          ? <ChannelList items={searchResult} />
          : (
            <>
              <ChannelEmpty />
              <Divider />
              <div className='title'>인기 채팅</div>
              { chattingModel.allChannelList && <ChannelList items={chattingModel.allChannelList.filter((channel) => channel.memberCount > 30).slice(0, STAR_CHANNEL_COUNT)} /> }
            </>
          )
        : (
          <>
            <div className='title'>인기 채팅</div>
            { chattingModel.allChannelList && <ChannelList items={chattingModel.allChannelList.filter((channel) => channel.memberCount > 30).slice(0, STAR_CHANNEL_COUNT)} /> }
          </>
        )}
      <SpacingBlock size={16} vertical />
    </Frame>
  );
};

export default inject(injectStore.chattingModel)(observer(ChattingSearch));
