import IconBadge from 'assets/_v2/positionJd/welfare/icon_badge.svg';
import IconBusinessCenter from 'assets/_v2/positionJd/welfare/icon_business_center.svg';
import IconChildCare from 'assets/_v2/positionJd/welfare/icon_child_care.svg';
import IconCoffee from 'assets/_v2/positionJd/welfare/icon_coffee.svg';
import IconDiamond from 'assets/_v2/positionJd/welfare/icon_diamond.svg';
import IconDirectionsCar from 'assets/_v2/positionJd/welfare/icon_directions_car.svg';
import IconLaptopMac from 'assets/_v2/positionJd/welfare/icon_laptop_mac.svg';
import IconLocalLibrary from 'assets/_v2/positionJd/welfare/icon_local_library.svg';
import IconLuggage from 'assets/_v2/positionJd/welfare/icon_luggage.svg';
import IconLunchDining from 'assets/_v2/positionJd/welfare/icon_lunch_dining.svg';
import IconMedicalServices from 'assets/_v2/positionJd/welfare/icon_medical_services.svg';
import IconPalette from 'assets/_v2/positionJd/welfare/icon_palette.svg';
import IconRealEstate from 'assets/_v2/positionJd/welfare/icon_real_estate.svg';
import IconRedeem from 'assets/_v2/positionJd/welfare/icon_redeem.svg';
import IconSavings from 'assets/_v2/positionJd/welfare/icon_savings.svg';
import IconSmile from 'assets/_v2/positionJd/welfare/icon_smile.svg';
import IconVerified from 'assets/_v2/positionJd/welfare/icon_verified.svg';
import { Divider1G40 } from 'components/divider/Divider';
import { StyledChipWelfareLi, StyledChipWelfareUl } from 'components/_v2/_common/chip/StyledJDChip';
import Fonts from '__designkit__/common/fonts';
import { IWelfareCategoryWithWelfareNameDto } from 'interfaces/_v2/positionJd/IWelfareCategoryWithWelfareNameDto';
import React, { FC } from 'react';
import styled from 'styled-components';

interface IPositionWelfareDetailCardProps{
    card :IWelfareCategoryWithWelfareNameDto;
}
enum WelfareList {
  HEALTH = '건강 지원',
  TRANSPORT = '교통 지원',
  WORK = '근무 제도',
  FINANCE = '금융 지원',
  CULTURE = '문화생활 지원',
  COMPANYCULTURE = '사내문화 제도',
  COMPANYCONVENIENCE = '사내 편의시설',
  FOOD = '식사 지원',
  WORKSUPPORT = '업무 환경지원',
  EMPLOYEE = '임직원 혜택',
  SELFDEVELOPMENT = '자기계발 지원',
  NURTURE = '자녀 양육 지원',
  HOUSE = '주거지원',
  SPECIALPRESENT = '특별선물 제공',
  PRIZE = '포상제도',
  VACATION = '휴가 제도',
  ETC = '기타 지원'
}
const Card = styled.div`
display: flex;
flex-direction: column;
.divider-24{
    width:90%;
}
`;
const DividerFrame = styled.div`
display: flex;
align-items: center;
justify-content: center;
`;

const CardTitle = styled.div`
display: flex;
font: ${Fonts.B1_Bold};
text-align: center;
margin-top: 24px;
margin-left:16px;
.icon{
  width:20px;
  height:20px;
  margin-right: 8px;
}
`;

const WelfareCardFrame = styled.div`
padding: 16px 16px 32px 16px;
`;
const PositionWelfareDetailCard:FC<IPositionWelfareDetailCardProps> = ({ card }) => {
  const getIconType = (cardName:string) => {
    switch (cardName) {
      case (WelfareList.COMPANYCULTURE):
        return IconSmile;
      case (WelfareList.HEALTH):
        return IconMedicalServices;
      case (WelfareList.TRANSPORT):
        return IconDirectionsCar;
      case (WelfareList.WORK):
        return IconBadge;
      case (WelfareList.FINANCE):
        return IconSavings;
      case (WelfareList.CULTURE):
        return IconPalette;
      case (WelfareList.COMPANYCONVENIENCE):
        return IconCoffee;
      case (WelfareList.FOOD):
        return IconLunchDining;
      case (WelfareList.WORKSUPPORT):
        return IconLaptopMac;
      case (WelfareList.EMPLOYEE):
        return IconBusinessCenter;
      case (WelfareList.SELFDEVELOPMENT):
        return IconLocalLibrary;
      case (WelfareList.NURTURE):
        return IconChildCare;
      case (WelfareList.HOUSE):
        return IconRealEstate;
      case (WelfareList.SPECIALPRESENT):
        return IconRedeem;
      case (WelfareList.PRIZE):
        return IconVerified;
      case (WelfareList.VACATION):
        return IconLuggage;
      case (WelfareList.ETC):
        return IconDiamond;
      default:
        break;
    }
  };
  return (
    <>
      {card
     && (
     <Card>
       <CardTitle>
         {card.name}
       </CardTitle>
       <div>
         <WelfareCardFrame>
           <StyledChipWelfareUl>
             {card.welfares.map((welfare) => (
               <StyledChipWelfareLi key={`welfare-chip-${welfare}`}>
                 <span>{welfare}</span>
               </StyledChipWelfareLi>
             ))}
           </StyledChipWelfareUl>
         </WelfareCardFrame>
         <DividerFrame>
           <Divider1G40 className='divider-24' />
         </DividerFrame>
       </div>
     </Card>
     )}

    </>
  );
};

export default PositionWelfareDetailCard;
