export enum PositionOrderType {
  LATEST = 'LATEST', // 최신순
  POPULAR = 'POPULAR', // 인기순
  SALARY='SALARY', // 연봉순
  DEADLINE = 'DEADLINE', // 마감일순
  NONE = 'NONE' // 잡다 추천순
}

export const PositionOrderTypeStr: { [key in keyof typeof PositionOrderType]: string} = {
  LATEST: '최신순',
  POPULAR: '인기순',
  SALARY: '연봉순',
  DEADLINE: '마감일순',
  NONE: '잡다 추천순',
};

export enum RecruitmentType {
  NEW = 'NEW',
  CAREER = 'CAREER',
  INTERNSHIP = 'INTERNSHIP',
  ANY = 'ANY',
  NEWORCAREER = 'NEWORCAREER',
}

export enum RecruitmentTypeText {
  NEW = '신입',
  CAREER = '경력',
  INTERNSHIP = '인턴',
  ANY = '경력무관',
  NEWORCAREER = '신입/경력',
}
