import JDBaseModal, { IJDBaseModal } from 'components/_v2/_common/modals/JDBaseModal';
import { JDModalBasicFrame, JDModalDescription, JDModalFullButton, JDModalTitle } from 'components/_v2/_common/modals/JDModalComponents';
import RoutePaths from 'consts/RoutePaths';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Frame = styled.div`

    ${JDModalDescription} {
        margin-top: 16px;
    }
`;

interface IPositionJdChallengeFailModal extends IJDBaseModal {
    challengeName?: string;
    challengeSn?: number;
}

const PositionJdChallengeFailModal:FC<IPositionJdChallengeFailModal> = (({ challengeSn, isOpen, onClickClose, challengeName }) => (
  <JDBaseModal dimmed isOpen={isOpen} onClickClose={onClickClose} isDimClickClose>
    <Frame>
      <JDModalBasicFrame>
        <JDModalTitle>지원 가능한 횟수를 초과하였습니다.</JDModalTitle>
        <JDModalDescription>
          복수 지원이 제한된 포지션입니다. <br />
          지원 정책은 관련 챌린지를 확인해 주세요.<br />
          <Link to={RoutePaths.challenge_detail(challengeSn)}>{challengeName}</Link>
        </JDModalDescription>
        <JDModalFullButton onClick={onClickClose}>확인</JDModalFullButton>
      </JDModalBasicFrame>
    </Frame>
  </JDBaseModal>
));

export default PositionJdChallengeFailModal;
