import MainHeader from 'components/_v2/_common/mainHeader/MainHeader';
import { StyledTermsFrame } from 'components/modals/TermsModal';
import colors from '__designkit__/common/colors';
import URIs from 'consts/URIs';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import request from 'utils/request';
import Error404 from './Error404';

const FrameStyled = styled(StyledTermsFrame)`
  * {
    line-height: 1.3em !important;
  }
  b { font-weight: bold !important;}
  table td:first-child {
    max-width:80px !important;
  }
  .frame-terms table span,.frame-terms table b {
    /* color:inherit !important; */
  }
  .frame-terms p b span, .frame-terms p b {
    font-size:inherit !important;
  }
  span[style="font-size: 30px;"] u{
    font-size: 1.2em !important;
  }
  .frame-terms table tr:first-child td:first-child b {
    color:${colors.WHITE_100} !important;
  }
  .frame-terms table {
    table-layout:fixed;
    tr td {
      width:40% !important;
    }
  }
  .frame-terms > table {
    table-layout:fixed;
    tr td:not(:first-child) {
      width:40% !important;
    }
  }
  *[style="color: rgb(255, 0, 0);"] *,
  .frame-terms table tr:not(:first-child) td:first-child *[style="color: rgb(255, 0, 0);"] b,
  .frame-terms p *[style="color: rgb(255, 0, 0);"] b {
    color: rgb(255, 0, 0) !important;
  }
  .frame-terms p b,
  .frame-terms p b span,
  .frame-terms p b > span
  {
    font-weight:bold !important;
  }
`;

/**
 *  '/notice/2', //V3 고지문
    '/notice/3', //V4 고지문
    '/notice/4', //개인정보 처리방침 개정 고지 (회원가입 간소화) v1.8.2
    '/notice/5', //1.9.5 매칭 서비스
 */
interface NoticeRs {
  sn: number;
  title: string;
  contents: string;
}

const PrivacyNotice: FC = () => {
  const [content, setContent] = useState<string | null>(null);
  const { noticeSn } = useParams<{ noticeSn: string }>();
  const loadContent = useCallback(async () => {
    try {
      const { notice } = await request<{ notice: NoticeRs }>({
        method: 'get',
        url: URIs.get_notices(Number(noticeSn)),
      });
      if (notice) setContent(notice.contents);
    } catch (e) {
      setContent('');
    }
  }, []);

  useEffect(() => {
    loadContent();
  }, []);

  if (content === null) return <></>;
  return (
    <>
      {content
        ? (
          <FrameStyled className='visible'>
            {/* <DisableScroll /> */}
            <div className='dimmer' />
            <MainHeader
              title='개정 안내'
              search={false}
              gnb={false}
              backable
              data-hide={false as any}
            />
            <div className='frame-content'>
              <div className='frame-terms' dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          </FrameStyled>
        )
        : (
          <>
            <MainHeader
              backable
            />
            <Error404 />
          </>
        )}
    </>
  );
};

export default PrivacyNotice;
