import RoutePaths from 'consts/RoutePaths';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import IModalProps from 'interfaces/props/IModalProps';
import { IPositionCompanyCardDto } from 'interfaces/_v2/positionJd/IPositionCompanyCardListRs';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import JDFullScreenBaseModal from '../_common/modals/JDFullScreenBaseModal';
import { MatchingCompanyCard } from './PositionJdMatchingCompany';

const Frame = styled.div`
  padding: 56px 16px calc(env(safe-area-inset-bottom) + 26px) 16px;
  .head {
    font: ${Fonts.H5_Bold};
    color: ${colors.CG_60};

    >b {
      color: ${colors.JOBDA_BLACK};
    }
  }

  >ul {
    margin: 16px 0;

    li:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`;

interface IPositionMatchingCompanyModalProps extends IModalProps{
    companyName: string;
    companyList: IPositionCompanyCardDto[];
}

const PositionMatchingCompanyModal:FC<IPositionMatchingCompanyModalProps> = ({ companyName, isOpen, onClose, companyList }) => (
  <JDFullScreenBaseModal isOpen={isOpen} onClose={onClose}>
    <Frame>
      <p className='head'><b>{companyName}에서 채용중</b>인 <br />{companyList.length}개의 포지션</p>
      <ul>
        {companyList?.map((data) => (
          <Link key={`position-${data.companyName}`} to={RoutePaths.position_jd(data.positionSn)} className='link'>
            <li key={`matching-company-card-${data.positionSn}`}><MatchingCompanyCard positionInfo={data} /></li>
          </Link>
        ))}
      </ul>
    </Frame>
  </JDFullScreenBaseModal>
);

export default PositionMatchingCompanyModal;
