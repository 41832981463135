import React from 'react';
import classnames from 'classnames/bind';
import styles from './qna.module.scss';
import { noticeData, qnaListData } from './qnaData';

const cx = classnames.bind(styles);

const Qna = () => {
  const qnaListEl = qnaListData.map((item, index) => (
    <li key={index} className={cx('listItem')}>
      <div className={cx('question')}>{item.question}</div>
      <div className={cx('answer')}>{item.answer}</div>
    </li>
  ));

  const noticeListEl = noticeData.map((item, index) => (
    <div key={index} className={cx('noticeInfoInner')}>
      {item.notice}
    </div>
  ));

  return (
    <div className={cx('wrap')}>
      <div className={cx('inner')}>
        <em className={cx('title')}>QNA</em>

        <ul className={cx('qnaList')}>{qnaListEl}</ul>

        <div className={cx('noticeArea')}>
          <div className={cx('noticeTitle')}>유의사항</div>
          <div className={cx('noticeInfoArea')}>{noticeListEl}</div>
        </div>
      </div>
    </div>
  );
};

export default Qna;
