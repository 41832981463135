import React from 'react';
import classnames from 'classnames/bind';
import FaqList from './FaqList';

import styles from './faq.module.scss';

const cx = classnames.bind(styles);

const FAQ = () => {
  return (
    <div className={cx('wrap')}>
      <div className={cx('mainTitle')}>
        JOBDA 면접연습
        <br />더 자세히 알고 싶다면?
      </div>
      <div className={cx('faqInner')}>
        <FaqList />
      </div>
    </div>
  );
};

export default FAQ;
