import { SkillLevel } from 'interfaces/_v2/profile/IProfileKnowledgeAndSkillsRs';
import { ProfileAuditItemType } from 'consts/_v2/profile/ProfileType';
import { ReactSelectOption } from '../../../consts/_v2/_common/ReactSelectOptions';

export enum TeamType {
    TEAM = 'TEAM',
    PERSONAL = 'PERSONAL',
}

export const TeamTypeText: Record<TeamType, string> = {
  TEAM: '팀 프로젝트',
  PERSONAL: '개인 프로젝트',
};
export interface IProfileProjectDto {
    title: string;
    startDate: string;
    endDate: string;
    teamType: TeamType;
    contribution: ReactSelectOption | undefined;
    performance: string;
    institution: string;
    skills: IProfileProjectSkillDto[];
}
export interface IProfileProjectSkillDto {
    code:number;
    keyword:string;
    level:SkillLevel
}

export interface IProfileProjectListRqRs {
    projects: IProfileProjectSaveRq[]
    sourcePage: string | null;
}
export interface IProfileProjectListConvert{
    projects:IProfileProjectDto[]
}
export interface IProfileProjectSaveRq {
    title: string;
    startDate: string;
    endDate: string;
    teamType: TeamType;
    contribution: number;
    performance: string;
    institution: string;
    skills: IProfileProjectSkillDto[];
    auditItems?:ProfileAuditItemType[]

}
