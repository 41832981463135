import Colors from '__designkit__/common/colors';
import spinnerOne from 'assets/lottie/pacc_gate_first.json';
import spinnerTwo from 'assets/lottie/pacc_gate_second.json';
import spinnerThree from 'assets/lottie/pacc_gate_third.json';
import JDSwiper, { JDSwiperSlide, PageNationType } from 'components/_v2/_common/JDSwiper';
import JDSimpleInfoModal from 'components/_v2/_common/modals/JDSimpleInfoModal';
import AccaGateServiceCard from 'components/_v2/acca/gate/AccaGateServiceCard';
import { Divider12B } from 'components/divider/Divider';
import RoutePaths from 'consts/RoutePaths';
import { inject, observer } from 'mobx-react';
import PaccGateModel from 'models/_v2/PaccGateModel';
import { injectStore } from 'models/store';
import { IPaccGateProps } from 'pages/_v2/acca/PaccGate';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

const TotalFrame = styled.div``;
const Frame = styled.div`
  padding: 0 16px;
  .service-swiper{
    .pagination-bullets{
    .swiper-pagination-bullet-active {
      background:${Colors.JOBDA_WHITE};
    }
  }

  }
  .service-card {
    width: fit-content;
    overflow: visible;
    display: flex;
    justify-content: center;
    align-items: start;
    overflow-x: visible;
  }
`;

const PaccaGateService: FC<IPaccGateProps> = ({ paccGateModel = new PaccGateModel() }) => {
  const history = useHistory();
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [openRealInfoModal, setOpenRealInfoModal] = useState(false);

  useEffect(() => {
    const init = async () => {
      await paccGateModel.loadPstMaxChance();
      await paccGateModel.loadPaccMaxChance();
      await paccGateModel.loadPstApplyStatus();
      await paccGateModel.loadPaccApplyStatus();
    };
    init();
  }, []);

  return (
    <TotalFrame>

      {paccGateModel
        && (
        <Frame>
          <JDSwiper className='service-swiper' slidesPerView='auto' spaceBetween={16} paginationtype={PageNationType.bullets}>
            <JDSwiperSlide className='service-card'>
              <AccaGateServiceCard
                darkMode
                subTitle='역량 프로파일 및 취업 가이드'
                title='결과표 확인'
                infoString1='· 응시점검 결과 확인'
                infoString2='· 심층 역량 리포트 확인'
                infoString3='· 결과표 활용 가능'
                lottie={{ className: 'spinner', animationData: spinnerThree }}
                onClick={() => {
                  history.push(RoutePaths.phs_pacc_results());
                }}
              />
            </JDSwiperSlide>
            <JDSwiperSlide className='service-card'>
              <AccaGateServiceCard
                darkMode
                subTitle='디챔스 참가하기'
                onlyPc
                title='개발자 역량검사'
                restCount={paccGateModel.paccChanceCount >= 0 ? paccGateModel.paccChanceCount : 0}
                infoString1={`· 월 ${paccGateModel.paccMaxChance ? paccGateModel.paccMaxChance : 0}회 응시 가능`}
                infoString2='· 무제한 연습 기회 제공'
                infoString3='· 역량검사 결과표 제공'
                lottie={{ className: 'spinner', animationData: spinnerOne }}
                onClick={() => {
                  setOpenRealInfoModal(true);
                }}
              />
            </JDSwiperSlide>
            <JDSwiperSlide className='service-card'>
              <AccaGateServiceCard
                darkMode
                subTitle='디챔스 참가하기'
                onlyPc
                title='개발 구현 능력 검사'
                restCount={paccGateModel.pstChanceCount >= 0 ? paccGateModel.pstChanceCount : 0}
                infoString1={`· 월 ${paccGateModel.pstMaxChance ? paccGateModel.pstMaxChance : 0}회 응시 가능`}
                infoString2='· 무제한 연습 기회 제공'
                infoString3='· 검사 결과표 제공'
                lottie={{ className: 'spinner', animationData: spinnerTwo }}
                onClick={() => {
                  setOpenInfoModal(true);
                }}
              />
            </JDSwiperSlide>
          </JDSwiper>
          <JDSimpleInfoModal dimmed title={`개발자 검사 응시는\n PC에서만 가능해요.`} isOpen={openInfoModal} onClickClose={() => setOpenInfoModal(false)} />
          <JDSimpleInfoModal dimmed title={`개발자 검사 응시는\n PC에서만 가능해요.`} isOpen={openRealInfoModal} onClickClose={() => setOpenRealInfoModal(false)} />
        </Frame>
        )}

      <Divider12B />
    </TotalFrame>
  );
};
export default inject(injectStore.paccGateModel)(observer(PaccaGateService));
