import RecruitJobCard from '__designkit__/card/RecruitJobCard';
import Colors from '__designkit__/common/colors';
import MatchHomeNotFound, { MatchHomeNotFoundViewType } from 'components/_v2/matchHome/MatchHomeNotFound';
import { IAdRecommendJobPostingDto } from 'interfaces/_v2/main/IJobPostings';
import { useBookmarkJobPostings } from 'query/bookmark/useBookmarkQuery';
import { FC } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
    .divider{
        width: 100%;
        height: 1px;
        background-color: ${Colors.CG_40};
    }
`;
interface IMyPageBookmarkJobs{

}
const MyPageBookmarkJobs:FC<IMyPageBookmarkJobs> = () => {
  const { data: bookmarkJobs } = useBookmarkJobPostings();
  return (
    <>
      <Frame>
        {bookmarkJobs && bookmarkJobs.map((item) => (
          <>
            <RecruitJobCard item={item} key={item.sn} />
            <div className='divider' />
          </>
        ))}
      </Frame>
      {
          bookmarkJobs && bookmarkJobs.length === 0 && (
            <MatchHomeNotFound content={MatchHomeNotFoundViewType.JOB} height={282} />
          )
        }
    </>
  );
};

export default MyPageBookmarkJobs;
