import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import ProfileItemTitle from 'components/_v2/profile/ProfileItemTitle';
import ProfileListCard from 'components/_v2/profile/ProfileListCard';
import { getTotalCareerPeriodText } from 'components/_v2/profile/careers/ProfileCareersView';
import { Divider1G40 } from 'components/divider/Divider';
import { EMPLOYMENT_STATUS, EMPLOYMENT_STATUS_TEXT, EMPLOYMENT_TYPE_TEXT } from 'consts/_v2/profile/career';
import { IProfileCareerRs } from 'interfaces/_v2/profile/IProfileCareerRqRs';
import { ICareer } from 'interfaces/_v2/profile/IProfileDetail';
import React, { FC } from 'react';
import styled from 'styled-components';
import { v4 as uuidV4 } from 'uuid';

const Frame = styled.div``;

const DescriptionCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  width: 100%;
  
  > h1 {
    margin-bottom: 12px;
    font: ${Fonts.B1_Bold};
    color: ${Colors.CG_90};
  }

  > h2 {
    display: inline-block;
    width: 100%;
    font: ${Fonts.B2_Medium};
    color: ${Colors.CG_70};
    white-space: pre-line;
  }
`;

const ProfilePreviewCareer: FC<{ data: ICareer}> = ({ data }) => {
  const { careers } = data;

  return (
    <Frame>
      <ProfileItemTitle icon='work' text='경력' tag={getTotalCareerPeriodText(careers)} />
      { careers.map((card: IProfileCareerRs, index: number) => (
        <ProfileListCard
          key={`card-list-${uuidV4()}`}
          institution={card.companyName}
          date={`${card.startDate} ~ ${card.employmentStatus === EMPLOYMENT_STATUS.WORK ? EMPLOYMENT_STATUS_TEXT.WORK : card.endDate}`}
          description={(
            <DescriptionCard>
              { card.role
                ? <h1>{ `${card.role} · ${EMPLOYMENT_TYPE_TEXT[card.employmentType]}` }</h1>
                : <h1>{ EMPLOYMENT_TYPE_TEXT[card.employmentType] }</h1> }
              <h2>{card.performance}</h2>
            </DescriptionCard>
          )}
          index={index}
          length={careers.length || 0}
        />
      ))}
      <Divider1G40 />
    </Frame>
  );
};

export default ProfilePreviewCareer;
