export enum CategoryIconType{
    TYPE1='TYPE1',
    TYPE2='TYPE2',
    TYPE3='TYPE3',
    TYPE4='TYPE4',
    TYPE5='TYPE5',
    TYPE6='TYPE6',
    TYPE7='TYPE7',
    TYPE8='TYPE8',
    TYPE9='TYPE9',
    TYPE10='TYPE10',
    TYPE11='TYPE11',
}

export enum ContentsPrivacyType{
    PUBLIC='PUBLIC',
    PRIVATE='PRIVATE',
    PARTERNERSHIP='PARTERNERSHIP',
    UNLISTED='UNLISTED',
}

export enum PostType{
    TEXT='TEXT',
    VIDEO='VIDEO',
    LIVE='LIVE',
}

export enum PhsProgressType{
    COMPLETED = 'COMPLETED',
    IN_PROGRESS = 'IN_PROGRESS',
    NOT_STARTED = 'NOT_STARTED',
    CONNECT_OVER = 'CONNECT_OVER'
}


export enum DChampsStageType{
    QUALIFIERS = 'QUALIFIERS',
    FINALS = 'FINALS',
}