import URIs from 'consts/URIs';
import { IChallengeCompaniesDto, IChallengeSearch } from 'interfaces/_v2/challenge/IChallengeDetailCompanies';
import { IChallengePositionsDto } from 'interfaces/_v2/challenge/IChallengeDetailPositions';
import { IMatchingChallengeRs } from 'interfaces/_v2/challenge/IMatchingChallengeRs';
import { action, observable } from 'mobx';
import request from 'utils/request';

export default class ChallengeDetailModel {
    @observable challengeDetail: IMatchingChallengeRs | null = null;
    @observable challengeDetailCompanies: IChallengeCompaniesDto[] | null = null;
    @observable challengeDetailPositions: IChallengePositionsDto[] | null = null;
    @observable searchFilters:Partial<IChallengeSearch>={ companySn: '' }

    @action
    loadChallengeDetail = async (sn: number) => {
      this.challengeDetail = (await request({
        method: 'get',
        url: URIs.get_match_challenge_sn(sn),
      }));
    };

    @action
    loadChallengeDetailPositions = async (sn:number, companySn?:string[]) => {
      try {
        if (companySn) {
          this.challengeDetailPositions = (await request<{challengePositions:IChallengePositionsDto[]}>({
            method: 'get',
            url: URIs.get_match_challenge_positions(sn),
            params: { companySns: companySn.join(',') },
          })).challengePositions;
        } else {
          this.challengeDetailPositions = (await request<{challengePositions:IChallengePositionsDto[]}>({
            method: 'get',
            url: URIs.get_match_challenge_positions(sn),
          })).challengePositions;
        }
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadChallengeDetailCompanies = async (sn:number) => {
      try {
        this.challengeDetailCompanies = (await request<{challengeCompanies:IChallengeCompaniesDto[]}>({
          method: 'get',
          url: URIs.get_match_challenge_companies(sn),
        })).challengeCompanies;
      } catch (e) {
        console.error(e);
      }
    }
}
