export const TERM_TYPE = {
  MARKETING_POLICY: 'MARKETING_POLICY',
  PRIVACY_COLLECTION: 'PRIVACY_COLLECTION',
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  PRIVACY_PROVISION: 'PRIVACY_PROVISION',
  TERMS_OF_SERVICE: 'TERMS_OF_SERVICE',
  PROFILE_PRIVACY_COLLECTION: 'PROFILE_PRIVACY_COLLECTION',
  PARTNERSHIP_PRIVACY_COLLECTION: 'PARTNERSHIP_PRIVACY_COLLECTION',
  PARTNERSHIP_PRIVACY_PROVISION: 'PARTNERSHIP_PRIVACY_PROVISION',
  POSITION_PRIVACY_POLICY: 'POSITION_PRIVACY_POLICY',
  POSITION_PRIVACY_PROVISION: 'POSITION_PRIVACY_PROVISION',
  SENSITIVE_PRIVACY_POLICY: 'SENSITIVE_PRIVACY_POLICY',
  SENSITIVE_PRIVACY_PROVISION: 'SENSITIVE_PRIVACY_PROVISION',
  RESUME_PRIVACY_PROVISION: 'RESUME_PRIVACY_PROVISION',
  NHIS_PRIVACY_PROVISION: 'NHIS_PRIVACY_PROVISION',
} as const;

export type TermType = typeof TERM_TYPE[keyof typeof TERM_TYPE];

export const TERM_TYPE_TITLE = {
  PRIVACY_COLLECTION: '개인정보 수집이용안내',
  PRIVACY_PROVISION: '개인정보 제3자 제공동의',
  PRIVACY_POLICY: '개인정보 처리방침',
  TERMS_OF_SERVICE: '서비스 이용약관',
  MARKETING_POLICY: '마케팅 정보 수신 동의 약관',
  PROFILE_PRIVACY_COLLECTION: '개인정보 수집이용안내',
  PARTNERSHIP_PRIVACY_COLLECTION: '개인정보 수집 및 이용동의',
  PARTNERSHIP_PRIVACY_PROVISION: '개인정보 제3자 제공동의',
  POSITION_PRIVACY_POLICY: '포지션 추가서류 제출 개인정보 수집 및 이용 동의 여부',
  POSITION_PRIVACY_PROVISION: '포지션 추가서류 제출 개인정보 제3자 제공 동의 여부',
  SENSITIVE_PRIVACY_POLICY: '민감 정보 수집 및 이용 동의',
  SENSITIVE_PRIVACY_PROVISION: '민감 정보 제 3자 수집 및 이용 동의',
  RESUME_PRIVACY_PROVISION: '개인정보 제3자 제공 동의',
  NHIS_PRIVACY_PROVISION: '개인정보 제3자 제공 동의',
} as const;

export const PARTNERSHIP_TERM_TYPE = {
  PRIVACY_COLLECTION: 'PRIVACY_COLLECTION',
  PRIVACY_PROVISION: 'PRIVACY_PROVISION',
} as const;

export type partnershipTermType = typeof PARTNERSHIP_TERM_TYPE[keyof typeof PARTNERSHIP_TERM_TYPE];
