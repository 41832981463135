interface MessageData {
  companyName: string;
  comment: string;
  who: string;
}

export const messageData: MessageData[] = [
  {
    companyName: '티맥스소프트',
    comment:
      '한국 소프트웨어 기술의 자존심, 티맥스소프트와 함께할 인재를 찾습니다.',
    who: '조OO님',
  },
  {
    companyName: '스푼라디오',
    comment:
      '개발자분들 파이팅! 스푼라디오 파이팅! 스푼라디오에서 함께하실 여러분을 기다릴게요 :).',
    who: '박OO님',
  },
  {
    companyName: '데쌍트코리아',
    comment:
      '디챔스를 통해 꽁꽁 숨겨둔 진짜 실력을 보여주시고, 데쌍트에서 함께, 그리고 즐겁게 일해요!',
    who: '이OO님',
  },
  {
    companyName: '마이다스아이티',
    comment:
      '개발자 분들에게 항상 많은 기회가 생겼으면 좋겠습니다! 우리 디챔스 끝나고 만나요!',
    who: '이OO님',
  },
  {
    companyName: 'S&I코퍼레이션',
    comment:
      '빨리 만나고 싶네요! 개발자 여러분 디챔스 파이팅하시고 S&I코퍼레이션에서 만나요~!',
    who: '강OO님',
  },
];

export default messageData;
