/* eslint-disable array-callback-return */
/* eslint-disable react/no-this-in-sfc */
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import FormValueFrame, { DatePickerFormValueFrame, EducationTypeFormValueFrame } from 'components/_v2/_common/form/FormProfileValueFrame';
import FormProfileValueTitle from 'components/_v2/_common/form/FormProfileValueTitle';
import JDCheckInput, { JD_CHECK_INPUT_TYPE } from 'components/_v2/_common/input/JDCheckInput';
import JDDatePickerInput from 'components/_v2/_common/input/JDDatePickerInput';
import JDSearchInput from 'components/_v2/_common/input/JDSearchInput';
import JDSelector, { JDSelectorType } from 'components/_v2/_common/input/JDSelector';
import ProfileEducationMajor from 'components/_v2/profile/education/ProfileEducationMajor';
import { EducationLevel, EducationSchoolText, EducationType, EducationTypeText } from 'consts/_v2/profile/EducationLevel';
import { BaseProfileEducationDto } from 'interfaces/_v2/insight/IInsightResumeRqRs';
import { INameCodeRs } from 'interfaces/rqrs/ICommonRqRs';
import ProfileEducationModel from 'models/_v2/profile/ProfileEducationModel';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { debounce } from 'utils/CommonUtils';

const EducationRegisterFrame = styled.div`
    height: auto;
    .content {
      padding: 0 1px;
    }
    .title-text{
      font:${Fonts.H4_Bold};
      color:${Colors.JOBDA_BLACK};
      margin-bottom: 24px;
    }
`;

interface IResumeEditEducation{
    prefixName:string;
    eduIndex:number;
    profileEducationModel?:ProfileEducationModel;
    isVisible:boolean
    educationsFields?:BaseProfileEducationDto[]
}

const ResumeEditEducation:FC<IResumeEditEducation> = ({ educationsFields, prefixName, eduIndex, profileEducationModel = new ProfileEducationModel(), isVisible }) => {
  const { control, watch, setValue, trigger, errors } = useFormContext();
  const topRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isVisible && topRef.current) {
      topRef.current.scrollIntoView();
    }
  }, [isVisible]);

  const [searchSchool, setSearchSchools] = useState<INameCodeRs[]>([]);
  return (
    <EducationRegisterFrame hidden={!isVisible} ref={topRef}>
      <div className='title-text'>
        학력 정보는 기업에 따라<br />선택적으로 활용돼요.
      </div>
      {
        educationsFields && educationsFields.map((field, index) => (
          <div className='content' hidden={index !== eduIndex}>
            <FormProfileValueTitle required>학교 구분</FormProfileValueTitle>
            <FormValueFrame>
              <JDSelector
                type={JDSelectorType.NORMAL}
                name={`${prefixName}[${index}].schoolLevel`}
                selectTitle='학교 구분을 선택해 주세요.'
                value={field.schoolLevel}
                onChange={() => {
                  setValue(`${prefixName}[${index}].schoolName`, undefined, { shouldValidate: true });
                  setValue(`${prefixName}[${index}].schoolCode`, undefined, { shouldValidate: true });
                }}
              >
                <select>
                  <option value='' hidden>학교 구분을 선택해 주세요.</option>
                  {
                        Object.entries(EducationSchoolText).map(([key, text]) => <option key={`option-school-level-${index}-${key}`} value={key} selected={watch(`${prefixName}[${index}].schoolLevel`) === key}>{text}</option>)
                      }
                </select>
              </JDSelector>
            </FormValueFrame>
            <FormProfileValueTitle required>학교명</FormProfileValueTitle>
            <FormValueFrame>
              <JDSearchInput
                key={`${prefixName}[${index}]}.schoolName`}
                title='학교명'
                isDirectItem
                defaultTextName={watch(`${prefixName}[${index}].schoolName`)}
                defaultCodeName={watch(`${prefixName}[${index}].schoolCode`)}
                textName={`${prefixName}[${index}].schoolName`}
                codeName={`${prefixName}[${index}].schoolCode`}
                placeholder='학교를 검색해 주세요.'
                disabled={!watch(`${prefixName}[${index}].schoolLevel`)}
                onSearch={(searchText: string) => {
                  if (searchText === '') setSearchSchools([]);
                  else {
                    debounce(async () => {
                      await profileEducationModel.searchSchools(watch(`${prefixName}[${index}].schoolLevel`) as EducationLevel, searchText);
                      await setSearchSchools(profileEducationModel.schools);
                    }, 400);
                  }
                }}
                searchList={searchSchool}
              />
            </FormValueFrame>
            <FormProfileValueTitle required>상태</FormProfileValueTitle>
            <EducationTypeFormValueFrame>
              <JDSelector
                type={JDSelectorType.NORMAL}
                name={`${prefixName}[${index}].educationType`}
                selectTitle='재학 상태'
                value={watch(`${prefixName}[${index}].educationType`)}
                onChange={(value?: string) => {
                  if (value !== EducationType.GRADUATION && value !== EducationType.DROP && value !== EducationType.GRADUATION_CANDIDATE) {
                    setValue(`${prefixName}[${index}].endDate`, undefined, { shouldValidate: true });
                    trigger(`${prefixName}[${index}].endDate`);
                  }
                }}
              >
                <select>
                  <option value='' hidden>재학 상태</option>
                  {
                        Object.entries(EducationTypeText).map(([key, text]) => <option key={`option-school-level-${index}-${key}`} value={key} selected={watch(`${prefixName}[${index}].educationType`) === key}>{text}</option>)
                      }
                </select>
              </JDSelector>
              <JDCheckInput
                type={JD_CHECK_INPUT_TYPE.CHECK_BOX}
                name={`${prefixName}[${index}].transferYn`}
                value
                label='편입'
                defaultChecked={watch(`${prefixName}[${index}].transferYn`)}
              />
            </EducationTypeFormValueFrame>
            <FormProfileValueTitle required>재학기간</FormProfileValueTitle>
            <DatePickerFormValueFrame>
              <JDDatePickerInput
                name={`${prefixName}[${index}].startDate`}
                placeholder='연도. 월.'
                defaultValue={watch(`${prefixName}[${index}].startDate`)}
                month
              />
              <span className='date-picker-divider'>~</span>
              <JDDatePickerInput
                name={`${prefixName}[${index}].endDate`}
                placeholder='연도. 월.'
                defaultValue={watch(`${prefixName}[${index}].endDate`)}
                month
                disabled={watch(`${prefixName}[${index}].educationType`) !== EducationType.GRADUATION && watch(`${prefixName}[${index}].educationType`) !== EducationType.DROP && watch(`${prefixName}[${index}].educationType`) !== EducationType.GRADUATION_CANDIDATE}
              />
            </DatePickerFormValueFrame>

            {
            watch(`${prefixName}[${index}].schoolLevel`) !== EducationLevel.HIGHSCHOOL && (
            <>

              <ProfileEducationMajor
                prefixName={`${prefixName}[${index}]`}
                hidden={watch(`${prefixName}[${index}].schoolLevel`) === EducationLevel.HIGHSCHOOL}
              />
            </>

            )
          }

          </div>
        ))
      }

    </EducationRegisterFrame>
  );
};

export default ResumeEditEducation;
