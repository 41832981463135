import React from 'react';
import classnames from 'classnames/bind';
import Colors from '__designkit__/common/colors';
import JDALink from 'components/JDALink';
import Icon from '__designkit__/icon/Icon';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

const ProfileZip = () => {
  return (
    <div className={cx('commonBox')}>
      {/* 타이틀 */}
      <div className={cx('basicTitleInner')}>
        <span className={cx('basicTitle')}>프로필 작성방법 고민된다면?</span>
        <span className={cx('subTitle')}>
          실제 합격자 프로필을
          <br />
          참고해보세요!
        </span>
      </div>

      <JDALink to='https://jobda.im/event/mailProfile' className={cx('btnBasic')}>
        <div className={cx('title')}><span className={cx('import')}>[합격자 프로필 모음.ZIP]</span> 받으러 가기</div>
        <div className={cx('btnIcon')}>
          <Icon name='arrow-right' size={24} color={Colors.JOBDA_BLACK} />
        </div>
      </JDALink>

    </div>
  );
};

export default ProfileZip;
