/* eslint-disable array-callback-return */
import JDFullScreenBaseModal from 'components/_v2/_common/modals/JDFullScreenBaseModal';
import CalendarDetailFilter from 'components/_v2/calendar/CalendarDetailFilter';
import CalendarJobFilter from 'components/_v2/calendar/CalendarJobFilter';
import FilterCommonBottom from 'components/_v2/filter/FilterCommonBottom';
import { CompanyLocation } from 'consts/CompanyLocationType';
import Fonts from '__designkit__/common/fonts';
import { BusinessSizeTypes, RecruitmentTypes } from 'consts/_v2/calendar/CalendarFilterType';
import colors from '__designkit__/common/colors';
import { ICalendarFilterRq, ICalendarFilterRs } from 'interfaces/_v2/calendar/ICalendarList';
import ICalendarProps from 'interfaces/_v2/calendar/ICalendarProps';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import CalendarModel from 'models/_v2/CalendarModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

const CalendarFilterTab = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  border-bottom: 1px solid ${colors.CG_40};
  width:100%;
  margin-top: 40px;
  padding: 0 16px;
`;

export const Tab = styled.div`
  position:relative;
  font: ${Fonts.B1_Bold};
  padding-bottom: 12px;

  color: ${colors.CG_60};
  margin-right: 24px;
  display: flex;
  align-items: center;

  &[aria-current=true] {
    color: ${colors.JOBDA_BLACK};
  }

  &[aria-current=true]:before {
    display:block;
    position:absolute;
    content:'';
    bottom:0;
    left:0;
    width:100%;
    height:2px;
  background:${colors.CG_90};
  }
`;

interface ICalendarFilterModal extends ICalendarProps {
    isOpen: boolean;
    onClickClose: () => void;
}

enum CalendarFilterType {
    Detail = 'Detail',
    Job = 'Job',
}

const defaultValues: ICalendarFilterRs = { jobTitles: [], recruitmentTypes: [], businessSizeTypes: [], locations: [] };

const CalendarFilterModal:FC<ICalendarFilterModal> = ({ isOpen, onClickClose, calendarModel = new CalendarModel(), login = new Login() }) => {
  const [currentTab, setCurrentTab] = useState<CalendarFilterType>(CalendarFilterType.Detail);
  const useformed = useForm<ICalendarFilterRs>({ mode: 'all', defaultValues });
  const { setValue, reset } = useformed;

  useEffect(() => {
    if (isOpen) {
      loadFilter();
    }
  }, [isOpen]);

  const loadFilter = async () => {
    // 비 로그인 유저 (필터) or 최근 필터가 없는 경우
    if (!calendarModel.recentlyFilter || (!calendarModel.recentlyFilter.jobTitles && !calendarModel.recentlyFilter.recruitmentTypes && !calendarModel.recentlyFilter.businessSizeTypes && !calendarModel.recentlyFilter.locations)) {
      let tempList = calendarModel.calendarLoadFilter.recruitmentTypes!.split(',');
      Object.values(RecruitmentTypes).map((type) => {
        const find = tempList.find((value) => value === type);
        if (find) setValue(`recruitmentTypes.${find}`, find);
      });

      tempList = calendarModel.calendarLoadFilter.businessSizeTypes!.split(',');
      Object.values(BusinessSizeTypes).map((type) => {
        const find = tempList.find((value) => value === type);
        if (find) setValue(`businessSizeTypes.${find}`, find);
      });

      tempList = calendarModel.calendarLoadFilter.locations!.split(',');
      Object.values(CompanyLocation).map((type) => {
        const find = tempList.find((value) => value === type.code);
        if (find) setValue(`locations.${find}`, find);
      });
    }
    calendarModel.calendarFilter = { ...calendarModel.calendarLoadFilter };
  };

  const handleClickReset = async () => {
    reset();
    calendarModel.resetFilter();
  };

  const handleClickFilter = async () => {
    if (calendarModel.calendarFilter.recruitmentTypes) {
      const recruitmentTypes = calendarModel.calendarFilter.recruitmentTypes.split(',');
      const includeNewOrCareer = recruitmentTypes.includes('NEW') || recruitmentTypes.includes('CAREER');
      if (includeNewOrCareer) {
        recruitmentTypes.push(RecruitmentTypes.NEWORCAREER);
      }
      const uniqueRecruitmentTypes = Array.from(new Set(recruitmentTypes));
      calendarModel.calendarFilter.recruitmentTypes = uniqueRecruitmentTypes.join(',');
    }
    calendarModel.calendarLoadFilter = { ...calendarModel.calendarFilter };
    calendarModel.calendarLoadFilter = { ...calendarModel.calendarFilter };
    await calendarModel.loadCalendarList();
    if (login.userInfo) {
      const rq:ICalendarFilterRq = {
        businessSizeTypes: calendarModel.calendarFilter.businessSizeTypes ? calendarModel.calendarFilter.businessSizeTypes.split(',') : [],
        jobTitles: calendarModel.calendarFilter.jobTitles ? calendarModel.calendarFilter.jobTitles.split(',').map(Number) : [],
        locations: calendarModel.calendarFilter.locations ? calendarModel.calendarFilter.locations.split(',').map(Number) : [],
        recruitmentTypes: calendarModel.calendarFilter.recruitmentTypes ? calendarModel.calendarFilter.recruitmentTypes.split(',') : [],
      };
      await calendarModel.saveFilter(rq);
      await calendarModel.loadFilter();
    }
    onClickClose();
  };

  const handleClose = () => {
    reset({ recruitmentTypes: [], businessSizeTypes: [], locations: [], jobTitles: [] });
    calendarModel.resetFilter();
    onClickClose();
  };

  return (
    <JDFullScreenBaseModal isOpen={isOpen} onClose={() => handleClose()}>
      <FormProvider {...useformed}>
        <CalendarFilterTab>
          <Tab aria-current={currentTab === CalendarFilterType.Detail} onClick={() => setCurrentTab(CalendarFilterType.Detail)}>상세 필터</Tab>
          <Tab aria-current={currentTab === CalendarFilterType.Job} onClick={() => setCurrentTab(CalendarFilterType.Job)}>직무 필터</Tab>
        </CalendarFilterTab>
        <CalendarDetailFilter dataHide={currentTab === CalendarFilterType.Job} />
        <CalendarJobFilter dataHide={currentTab === CalendarFilterType.Detail} />
        <FilterCommonBottom
          handleClickFilter={() => handleClickFilter()}
          handleClickReset={() => handleClickReset()}
        />
      </FormProvider>
    </JDFullScreenBaseModal>
  );
};

export default inject(injectStore.calendarModel, injectStore.login)(observer(CalendarFilterModal));
