/* eslint-disable array-callback-return */
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, ButtonMode } from '__designkit__/button/Button';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import JDFullModal from 'components/_v2/_common/modals/JDFullModal';
import ProfileAdditionInfoModal from 'components/_v2/positionJd/ProfileAdditionInfoModal';
import ProfilePreviewModal from 'components/_v2/profile/preview/ProfilePreviewModal';
import { PositionItemTypeText, ProfileItemType, ProfileItemTypeText, ResumeFormType } from 'consts/_v2/profile/ProfileType';
import { AccLiteStatusType } from 'interfaces/_v2/positionJd/IPositionDetailRs';
import { IResumeAdditionInfoApplyDto, IResumeAdditionInfoApplyRq, IResumeAdditionInfoTemp, IResumePreviewAdditionInfo, IResumePreviewAdditionInfoDto } from 'interfaces/_v2/positionJd/IResumeAdditionInfoRqRs';
import { IProfileProps } from 'interfaces/_v2/profile/IProfileProps';
import { inject, observer } from 'mobx-react';
import PositionJdModel from 'models/_v2/PositionJdModel';
import ProfileModel from 'models/_v2/profile/ProfileModel';
import { injectStore } from 'models/store';
import { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';
import RoutePaths from 'consts/RoutePaths';
import { useHistory } from 'react-router';

const Frame = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 56px 16px 82px 16px;
    position: relative;
    height: 100%;
    overflow-y: scroll;
    .title {
        font: ${Fonts.H4_Bold};
        color: ${colors.JOBDA_BLACK};
        margin-bottom: 12px;
    }
    .sub-title{
      font:${Fonts.B2_Medium};
      color:${colors.CG_70};
      margin-bottom: 24px;
    }
    .desc {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font: ${Fonts.B2_Medium_P};
        color: ${colors.CG_70};
        background-color: ${colors.CG_30};
        padding: 20px;
        margin-bottom: 24px;
        border-radius: 8px;
        width: 100%;
        .sub-desc {
            display: flex;
            align-items: center;
            font: ${Fonts.B3_Medium};
            color: ${colors.CG_90};

            span {
                margin-left: 4px;
            }

            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }
    }

`;
const SubBoxComponent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 16px 24px;
  width: 100%;
  min-height: 72px;
  border-radius: 8px;
  background-color: ${colors.CG_30};
  gap: 12px;
  margin-bottom: 8px;
  .title-name{
    width: 260px;
    display: flex;
    flex-direction: column;
    gap:4px;
    .title-text{
      font:${Fonts.B2_Bold};
      color:${colors.CG_80};
    }
    .subtitle-text{
      font:${Fonts.B3_Medium};
      color:${colors.CG_60};
    }
  }
`;
const BoxComponent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    width: 100%;
    min-height: 80px;
    border-radius: 8px;
    border: 1px solid ${colors.CG_50};
    background: ${colors.WHITE_100};
    margin-bottom: 12px;

    .box-name {
        display: flex;
        align-items: center;
        font: ${Fonts.B1_Bold};
        color: ${colors.JOBDA_BLACK};
    }
    .disabled-box-name{
      display: flex;
        align-items: center;
        font: ${Fonts.B1_Bold};
        color: ${colors.CG_50};
        .subtitle{  
          margin-left: 8px;
          font:${Fonts.B3_Medium};
          color:${colors.CG_50};
        }
    }
    .badge-complete{
      display: flex;
      padding:4px 6px;
      border-radius: 4px;
        align-items: center;
        font: ${Fonts.B4_Medium};
        color: ${colors.WHITE_100};
        background: ${colors.G_150};
    }
    .badge-in-progress{
      display: flex;
      padding:4px 6px;
      border-radius: 4px;
        align-items: center;
        font: ${Fonts.B4_Medium};
        color: ${colors.WHITE_100};
        background: ${colors.CG_60};
    }
    .badge-not{
      display: flex;
      padding:4px 6px;
      border-radius: 4px;
        align-items: center;
        font: ${Fonts.B4_Medium};
        color: ${colors.WHITE_100};
        background: ${colors.ERROR};
    }
    button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;
        font: ${Fonts.B3_Bold};
        color: ${colors.WHITE_100};
        border-radius: 4px;
        background-color: ${colors.JOBDA_BLACK};
    }
`;

const SubmitButtonFrame = styled.div`
    width: 100%;
    position: fixed;
    bottom: calc(0px + env(safe-area-inset-bottom));
    display: flex;
    padding: 16px;
    align-items: center;
    justify-content: center;
    border-top: 1px solid ${colors.CG_40};
    margin-top: auto;
    background-color: ${colors.WHITE_100};
    .preview-btn {
      margin-right: 8px;
    }

`;
export interface IProfileApplyModalProps extends IProfileProps {
    handleClose: () => void;
    handleSuccess: (additionInfo: IResumeAdditionInfoApplyRq) => void;
    positionJdModel?: PositionJdModel;
}

const defaultValues: Partial<IResumeAdditionInfoTemp> = { additionInfo: [] };

const ProfileApplyModal:FC<IProfileApplyModalProps> = ({ handleClose, handleSuccess, profileModel = new ProfileModel(), positionJdModel = new PositionJdModel() }) => {
  const [isPreview, setIsPreview] = useState(false);
  const [transAdditionInfo, setTransAdditionInfo] = useState<IResumeAdditionInfoApplyRq>({ additionInfo: [] });
  const [previewAdditionInfo, setPreviewAdditionInfo] = useState<IResumePreviewAdditionInfo>({ additionInfo: [] });
  const [additionAbled, setAdditionAbled] = useState<boolean>(false);
  const additionInfoSchema = yup.object();
  const [profileCheck, setProfileCheck] = useState<boolean>(false);
  const history = useHistory();
  const useFormed = useForm<IResumeAdditionInfoTemp>({
    mode: 'all',
    resolver: yupResolver(additionInfoSchema),
    defaultValues,
  });
  const { getValues } = useFormed;

  const [isOpenAdditionInfoModal, setIsOpenAdditionInfoModal] = useState(false);

  const viewPreview = async () => {
    await profileModel.loadProfileDetail();
    setIsPreview(true);
  };

  const handleApply = async () => {
    handleSuccess(transAdditionInfo);
  };
  const transformAdditionInfo = async () => {
    const additionInfo: IResumeAdditionInfoApplyDto[] = [];
    const previewAdditionInfoTemp: IResumePreviewAdditionInfoDto[] = [];
    const originalInfo = positionJdModel.profileAdditionalInfo;
    if (originalInfo) {
      originalInfo.forEach((info, idx) => {
        let transformedFiles;
        if (info && info.answer && info.answer.files) {
          transformedFiles = info.answer.files.map((file) => ({
            fileUid: file.uid,
            fileName: file.name,
            fileSize: 0,
            fileUrl: '',
          }));
        }

        additionInfo.push({
          positionAdditionInfoSn: originalInfo[idx].additionInfoSn,
          question: originalInfo[idx].question,
          type: originalInfo[idx].type,
          answer: info?.answer?.answer ? info.answer.answer : '',
          choices: info.answer?.choices || [],
          files: info.answer?.files,
        });

        previewAdditionInfoTemp.push({
          question: originalInfo[idx].question,
          type: originalInfo[idx].type,
          answer: info?.answer?.answer ? info.answer.answer : '',
          choices: info.answer?.choices || [],
          files: transformedFiles || [],
        });
      });
    }
    const additionInfoRq: IResumeAdditionInfoApplyRq = { additionInfo };
    const additionPreview: IResumePreviewAdditionInfo = { additionInfo: previewAdditionInfoTemp };

    setTransAdditionInfo(additionInfoRq);
    setPreviewAdditionInfo(additionPreview); // file 형식이 다름
  };

  useEffect(() => {
    const init = async () => {
      await positionJdModel.getRequirementVerification(positionJdModel.positionSn);
      await positionJdModel.getPositionAccStatus(positionJdModel.positionSn);
    };
    init();
  }, [isOpenAdditionInfoModal]);

  useEffect(() => {
    const checkAdditionInfo = async () => {
      if (positionJdModel.profileAdditionalInfo) {
        await positionJdModel.getProfileAdditionInfo(positionJdModel.positionSn);
        await positionJdModel.getRequirementVerification(positionJdModel.positionSn);
        await transformAdditionInfo();
        const check = checkRequiredInfo();
        setAdditionAbled(check);
      }
      const check = checkRequiredInfo();
      setAdditionAbled(check);
    };
    checkAdditionInfo();
  }, [isOpenAdditionInfoModal]);

  const checkRequiredInfo = (): boolean => {
    if (positionJdModel.profileRequiredItems) {
      setProfileCheck(true);
    } else {
      setProfileCheck(false);
    }
    const additionalInfo = positionJdModel.profileAdditionalInfo;

    if (!additionalInfo) return false;

    return additionalInfo.every((info) => {
      if (info.type !== ResumeFormType.ADDITION_ATTACH_FILE && !info.answer) {
        return false;
      }
      if (info.required) {
        const hasAnswer = !!info.answer?.answer;
        const hasFiles = info.answer?.files && info.answer.files.length > 0;
        const hasSelectedChoice = info.answer?.choices && info.answer.choices.some((choice) => choice.selectYn);
        if (!hasAnswer && !hasFiles && !hasSelectedChoice) {
          return false;
        }
      }
      return true;
    });
  };

  const closeModal = () => {
    handleClose();
  };
  return (
    <JDFullModal closeCustom handleClose={closeModal}>
      <FormProvider {...useFormed}>
        <Frame>
          <div className='title'>지원하기</div>
          <div className='sub-title'>포지션마다 요구하는 프로필 항목이 다를 수 있어요.</div>
          <BoxComponent>
            <div className='box-name'>내 프로필</div>
            {
              positionJdModel.profileRequiredItems
                ? <div className='badge-not'>미완료</div>
                : <div className='badge-complete'>완료</div>
            }

          </BoxComponent>
          {
            positionJdModel.profileRequiredItems && Object.keys(positionJdModel.profileRequiredItems).map((key) => (
              <SubBoxComponent key={key} onClick={() => history.replace(RoutePaths.profile_anchor(key))}>
                <div className='title-name'>
                  <div className='title-text'>
                    {ProfileItemTypeText[key]}
                  </div>
                  {
                      key === ProfileItemType.PRIZE || key === ProfileItemType.PROJECT ? (<></>)
                        : (
                          <>
                            {
                          positionJdModel.profileRequiredItems && positionJdModel.profileRequiredItems[key].length > 0 && (
                            <div className='subtitle-text'>
                              {positionJdModel.profileRequiredItems[key].map((item, idx, array) => (
                                <>
                                  {PositionItemTypeText[item]}{idx < array.length - 1 ? ', ' : ''}
                                </>
                              ))}
                            </div>
                          )
                              }
                          </>
                        )
                  }

                </div>
                <Icon name='arrow-right' size={32} color={colors.CG_80} />
              </SubBoxComponent>

            ))
          }
          {
            positionJdModel.profileAdditionalInfo && (
              <BoxComponent onClick={() => setIsOpenAdditionInfoModal(true)}>
                <div className='box-name'>추가 정보<Icon name='arrow-right' size={24} color={colors.JOBDA_BLACK} /></div>
                {
                    additionAbled ? (
                      <div className='badge-complete'>
                        완료
                      </div>
                    ) : (
                      <div className='badge-not'>
                        미완료
                      </div>
                    )
}
              </BoxComponent>
            )
        }
          {
          positionJdModel.positionAccStatus?.accApplyRequiredYn && (
          <BoxComponent>

            <div className='disabled-box-name'>역량검사
              <div className='subtitle'>응시는 PC 환경에서 가능합니다</div>
            </div>
            {
            positionJdModel.positionAccStatus?.accLiteStatus === AccLiteStatusType.COMPLETED ? (
              <div className='badge-complete'>
                완료
              </div>
            ) : positionJdModel.positionAccStatus?.accLiteStatus === AccLiteStatusType.IN_PROGRESS ? (
              <div className='badge-in-progress'>
                응시 중
              </div>
            ) : (
              <div className='badge-not'>
                미완료
              </div>
            )
          }

          </BoxComponent>
          )
        }
        </Frame>
        <SubmitButtonFrame>
          <Button className='preview-btn' buttonMode={ButtonMode.DEFAULT} outLined size='large' onClick={viewPreview} label='제출 전 미리보기' />
          <Button
            disabled={(!!positionJdModel.profileAdditionalInfo && !additionAbled) || profileCheck || (positionJdModel.positionAccStatus?.accApplyRequiredYn && positionJdModel.positionAccStatus?.accLiteStatus !== AccLiteStatusType.COMPLETED)}
            buttonMode={ButtonMode.PRIMARY}
            size='large'
            onClick={handleApply}
            label='제출하기'
          />
        </SubmitButtonFrame>
        {isPreview && profileModel.previewProfile && (
        <ProfilePreviewModal
          data={profileModel.previewProfile}
          additionInfo={previewAdditionInfo}
          handleClose={() => setIsPreview(false)}
        />
        )}
        { isOpenAdditionInfoModal
          && (
          <ProfileAdditionInfoModal
            useFormed={useFormed}
            handleClose={() => setIsOpenAdditionInfoModal(false)}
          />
          )}
      </FormProvider>
    </JDFullModal>
  );
};

export default inject(injectStore.profileModel, injectStore.positionJdModel)(observer(ProfileApplyModal));
