import React, { FC } from 'react';
import styled from 'styled-components';
import IComponentProps from 'interfaces/props/IComponentProps';
import { IJDADeclarationPromiseData } from 'interfaces/IJDADeclarationData';
import Fonts, { fonts, fontStyle } from '__designkit__/common/fonts';
import colors from '__designkit__/common/colors';
import imgPromiseQuote from 'assets/img/declaration/img_declaration_promise_quote.svg';

const Frame = styled.article<{['data-header']:string;}>`
  .frame-title {
    background:${colors.CG_30};
    width:100%;
    height:100%;
    z-index:-1;
    padding-top:32px;
  }
  .frame-title-card {
    padding:31px 32px;
    border-radius:16px 16px 0 0;
    overflow:hidden;
    border-bottom:1px solid ${colors.CG_40};
    height:207px;
    margin:0 16px;
    background-color:${colors.WHITE_100};
    background-image:url(${(props) => props['data-header']});
    background-repeat:no-repeat;
    background-position: right bottom;
    background-size:159px 147px;
    z-index:1;
    h1 {
      ${fontStyle.HEADLINE4_SEMIBOLD};
      color:${colors.JOBDA_BLACK};
      white-space:pre-line;
      &:after {
        margin-top:12px;
        display:block;
        content: 'Promise';
        font: ${Fonts.B1_Bold};
        color:${colors.G_200};
      }
    }
  }
  .frame-content {
    padding-bottom:32px;
    background:${colors.G_90};
    .frame-content-card {
      margin:0 16px;
      padding:32px;
      background:${colors.WHITE_100};
      border-radius:0 0 16px 16px;
      p {
        white-space:pre-line;
        ${fonts.NOTO_13_400};
        line-height:20px;
        letter-spacing:-0.09px;
        color:${colors.CG_80};
      }
    }
    .frame-quote {
      white-space:pre-line;
      font: ${Fonts.B1_Bold};
      color:${colors.G_200};
      margin-top:20px;
      &:before {
        display:block;
        content:'';
        width:20px;
        height:20px;
        background:url(${imgPromiseQuote});
        background-repeat:no-repeat;
        background-size:100%:
        margin-bottom:2px;
      }
    }
  }
`;

interface IDeclarationPromiseProps extends IComponentProps {
  promise: IJDADeclarationPromiseData;
}

const DeclarationPromise:FC<IDeclarationPromiseProps> = ({ promise: { title, paragraphs, quote, headerImage } }) => (
  <Frame data-header={headerImage}>
    <div className='frame-title'>
      <div className='frame-title-card'>
        <h1>{title}</h1>
      </div>
    </div>
    <div className='frame-content'>
      <div className='frame-content-card'>
        {paragraphs}
        <div className='frame-quote'>
          {quote}
        </div>
      </div>
    </div>
  </Frame>
);

export default DeclarationPromise;
