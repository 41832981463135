import { MatchHomeDashboardPositionType } from 'consts/_v2/matchHome/dashboard/MatchHomeDashboardType';

export enum ApplicationPositionType{
  JOBDA='JOBDA', // ATS 포지션
  HOMEPAGE='HOMEPAGE' // JF3 잡플렉스 포지션
}

export enum VisibilityType{
  PUBLIC='PUBLIC', // 공개
  PRIVATE='PRIVATE' // 비공개
}

export enum JF3PageType{
  JOB_DESCRIPTION='JOB_DESCRIPTION', // 공고 상세 페이지
  MY_PAGE='MY_PAGE', // 마이페이지
  RESUME_WRITING='RESUME_WRITING'// 지원서 작성 페이지
}

export interface IMatchHomeDashboardStatus {
  totalResponseRate: number;
  averageResponseDays: number;
}

export interface IMatchDashboardPosition {
  positionSn: number;
  positionName: string;
  companyName: string;
  companySn: number;
  applicationCompleteTime: string;
  closingDateTime: string;
  openingDateTime:string;
  jobApplicationProcessType: MatchHomeDashboardPositionType;
  positionType :ApplicationPositionType;
  openYn:boolean;
  visibilityType: VisibilityType;
  matchingOfferYn: boolean;
  companyLogoUrl?:string;
}

export interface IMatchingHomeApplicationStatusRs{
  totalCount:number;
  applicationCompleteCount:number;
  inProgressCount:number;
  processEndCount:number;
}

export interface IMatchHomeDashboardRs {
  writingResumePositions: IMatchDashboardPosition[];
  jobApplicationPositions: IMatchDashboardPosition[];
  interviewOfferedPositions: IMatchDashboardPosition[];
  progressPositions: IMatchDashboardPosition[];
  completedPositions: IMatchDashboardPosition[];
}

export interface IMatchWritingResumePositions{
  writingResumes:IMatchDashboardPosition[]
}
export interface IMatchApplicationHistories{
  jobApplicationProcesses:IMatchDashboardPosition[]
}

export interface ICertificationDto{
  positionSn:number;
  positionName:string;
  positionLocation:string;
  companySn:number;
  companyName:string;
  applicationCompleteTime:string;
  jobApplicationProcessType:MatchHomeDashboardPositionType;
}
export interface ICertificationRs{
  certificationNumber:string;
  jobApplicationCertificationDtos:ICertificationDto[]
}
