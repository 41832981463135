import { Camera, CameraResultType } from '@capacitor/camera';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import ImgCamera from 'assets/_v2/profile/img_camera.png';
import IconDumy from 'assets/_v2/profile/img_profile_base_info_dumy.png';
import ImageCropModal from 'components/_v2/_common/crop/ImageCropModal';
import JDUnderBaseModal from 'components/_v2/_common/modals/JDUnderBaseModal';
import { StyledSelectModalFrame } from 'components/_v2/position/filter/PositionJobTitleFilter';
import LazyImage from 'components/common/LazyImage';
import URIs from 'consts/URIs';
import useToast from 'hooks/useToast';
import IComponentProps from 'interfaces/props/IComponentProps';
import { inject, observer } from 'mobx-react';
import ProfileBasicInfoModel from 'models/_v2/profile/ProfileBasicInfoModel';
import { injectStore } from 'models/store';
import { FC, useEffect, useRef, useState } from 'react';
import MobileStore from 'store/mobileStore';
import styled from 'styled-components';
import FileUtil from 'utils/FileUtil';
import { request } from 'utils/request';

const Frame = styled.div`
  align-self: center;
  width:96px;
  height:96px;  
    .img-profile-frame{
    position: relative;
    width:96px;
    height:96px;
    align-self: center;
    .img-profile{
      width:96px;
      height:96px;
      border-radius: 50%;
      border: 1px solid rgba(18, 22, 25, 0.1);
      object-fit: cover;
    }
    .img-camera{
      position: absolute;
      right: 0px;
      bottom: 0px;
    }
  }
`;

const MenuFrame = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height:135px;
  padding-top:24px;
  button{
    width: 100%;
    height:44px;
    display: flex;
    justify-content: center;
    align-items: center;
    font: ${Fonts.H5_Medium};
    color:${colors.CG_60};
  }

`;

interface IAttachFile {
  fileName: string;
  fileUid: string;
  fileUrl: string;
  filePath: string;
}

interface IProfilePhotoProps extends IComponentProps {
  autoSavePhoto?: boolean;
  profileBasicInfoModel?: ProfileBasicInfoModel;
}

const ProfilePhoto: FC<IProfilePhotoProps> = ({ profileBasicInfoModel = new ProfileBasicInfoModel(), autoSavePhoto }) => {
  const photoRef = useRef<HTMLInputElement>(null);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [imageCropModalOpen, setImageCropModalOpen] = useState(false);
  const { setToastObject } = useToast();
  const maxAllowedSize = 50 * 1024 * 1024;
  const accept = ['image/jpeg', 'image/png'];
  const [profileImageUrl, setProfileImageUrl] = useState<string | null>(null);
  const [imgName, setImgName] = useState('');

  useEffect(() => {
    if (profileBasicInfoModel.baseInfo) setProfileImageUrl(profileBasicInfoModel.baseInfo.profileImageUrl);
  }, [profileBasicInfoModel.baseInfo]);

  const setFile = async (file: File, cropOn?: boolean) => {
    const data = new FormData();
    data.append('file', file);
    try {
      const res = await request<IAttachFile>({
        method: 'post',
        url: URIs.post_profiles_file_upload,
        data,
      });

      if (res.fileUid) {
        profileBasicInfoModel.uploadImageUid = res.fileUid;
        setProfileImageUrl(res.fileUrl);
        setImgName(res.fileName);

        if (cropOn) setImageCropModalOpen(true);

        if (autoSavePhoto && !cropOn) {
          await profileBasicInfoModel.savePhoto(res.fileUid);
          await profileBasicInfoModel.loadBaseInfo();
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const mobileTakePicture = async () => {
    const image = await Camera.getPhoto({
      quality: 90,
      resultType: CameraResultType.DataUrl,
      promptLabelHeader: '사진',
      promptLabelPhoto: '사진 불러오기',
      promptLabelPicture: '사진 찍기',
      promptLabelCancel: '취소',
    });
    const imageUrl = image.dataUrl;
    const type = image.format;
    const file = FileUtil.dataURLtoFile(imageUrl, `file.${type}`);
    setFile(file, true);
  };

  const photoChange = async () => {
    if (MobileStore.isMobile) {
      mobileTakePicture();
    } else {
      if (photoRef.current) photoRef.current.removeAttribute('capture');
      if (photoRef.current) photoRef.current.click();
    }
  };
  const CheckMenu = () => {
    if (!profileBasicInfoModel.uploadImageUid && !profileBasicInfoModel.baseInfo?.profileImageUrl) {
      photoChange();
    } else {
      setMenuOpen(true);
    }
  };

  const changeImage = (canvas: HTMLCanvasElement) => {
    const file = FileUtil.convertCanvasToFile(canvas.toDataURL(), imgName);
    setFile(file);
  };

  return (
    <Frame>
      <div
        role='button'
        className='img-profile-frame'
        onClick={() => {
          CheckMenu();
        }}
      >
        <LazyImage
          className='img-profile'
          src={profileImageUrl || IconDumy}
          alt='프로필 사진'
          onError={(e) => {
            e.currentTarget.src = IconDumy;
          }}
        />
        <img className='img-camera' src={ImgCamera} alt='카메라' />
        <input
          ref={photoRef}
          hidden
          id='input-thumb'
          type='file'
          accept='image/*'
          onChange={(e: any) => {
            if (e.target.files?.item(0)!) {
              const allowType = accept.some((type) => type === e.target.files?.item(0)?.type);
              if (!allowType) {
                setToastObject({ isOpen: true, message: '잘못된 파일 형식입니다.', type: 'ERROR' });
                return;
              }
              if (e.target.files?.item(0)!.size > maxAllowedSize) {
                setToastObject({ isOpen: true, message: '50MB 미만의 파일만 업로드 할 수 있습니다.', type: 'ERROR' });
                return;
              }
              setFile(e.target.files?.item(0)!, true);
              e.target.value = ''; // 동일한 파일 올릴 수 있도록 초기화
            }
          }}
        />
      </div>
      <JDUnderBaseModal isOpen={menuOpen} onClickClose={() => setMenuOpen(false)}>
        <StyledSelectModalFrame>
          <div className='title'>사진 등록</div>
          <MenuFrame>
            <button onClick={() => {
              // eslint-disable-next-line no-unused-expressions
              photoRef.current?.click();
              setMenuOpen(false);
            }}
            >프로필 수정
            </button>
            <button onClick={async () => {
              profileBasicInfoModel.uploadImageUid = null;
              setProfileImageUrl('');
              setImgName('');
              profileBasicInfoModel.removePhoto();
              setMenuOpen(false);
              if (autoSavePhoto) {
                await profileBasicInfoModel.savePhoto(null);
              }
            }}
            >프로필 삭제
            </button>
          </MenuFrame>
        </StyledSelectModalFrame>
      </JDUnderBaseModal>
      <ImageCropModal
        imgSrc={profileImageUrl || IconDumy}
        imgName={imgName}
        isOpen={imageCropModalOpen}
        onClickClose={() => setImageCropModalOpen(false)}
        width={window.innerWidth ? window.innerWidth : 360}
        height={560}
        onChange={changeImage}
      />
    </Frame>
  );
};

export default inject(injectStore.login, injectStore.profileBasicInfoModel)(observer(ProfilePhoto));
