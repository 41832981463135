import React, { FC, FocusEvent, InputHTMLAttributes, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import Icon from '__designkit__/icon/Icon';
import Fonts from '__designkit__/common/fonts';
import Colors from '__designkit__/common/colors';
import Shadows from '__designkit__/common/shadows';
import IComponentProps from 'interfaces/props/IComponentProps';

const Frame = styled.div<{ width: string; }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
  overflow: visible;
`;

const InputBox = styled.label`
  position: relative;
  display: inline-block;
  width: 100%;
  height: fit-content;
  font: ${Fonts.B2_Medium};

  & > span {
    position: absolute;
    top: 50%;
    left: 16px;
    color: ${Colors.CG_60};
    transform: translateY(-50%);
    transition: .2s;
  }

  & > input {
    box-sizing: border-box;
    width: 100%;
    height: fit-content;
    padding: 13px 14px;
    border: 1px solid ${Colors.CG_30};
    border-radius: 4px;
    background-color: ${Colors.CG_30};
    outline: none;

    &:disabled {
      opacity: 0.5;
    }

    &:focus {
      border: 1px solid ${Colors.JOBDA_BLACK};
      background-color: ${Colors.WHITE_100};
      outline: none;
    }

    &::-webkit-contacts-auto-fill-button, &::-webkit-credentials-auto-fill-button {
      mask-size: 0;
      width: 0;
    }
  }

  & > div {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }

  &.valid {
    & > span {
      transform-origin: top left;
      transform: scale(calc(10 / 12)) translateY(-50%) translateY(-12px);
    }

    & > input {
      padding: 19px 16px 7px 16px;
    }
  }
  
  &.error {
    & > input {
      border: 1px solid ${Colors.ERROR};

      &:focus {
        border: 1px solid ${Colors.ERROR};
      }
    }
  }
`;

const AutoCompleteBox = styled.div`
  position: absolute;
  top: 53px;
  min-width: 100%;
  width: 100%;
  height: fit-content;
  max-height: 200px;
  border: 1px solid ${Colors.CG_30};
  border-radius: 4px;
  background-color: ${Colors.WHITE_100};
  filter: ${Shadows.Shadow_400};
  overflow-y: scroll;
  z-index: 1;

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  & > div {
    width: 100%;
    padding: 12px 16px;
    font: ${Fonts.B1_Medium};

    > span {
      font: ${Fonts.B1_Bold};
    }
  }
`;

const GuideText = styled.div`
  width: 100%;
  padding: 7px 12px 0;
  font: ${Fonts.B3_Medium};
  color: ${Colors.CG_70};
  white-space: nowrap;

  &.error {
    color: ${Colors.ERROR};
  }
  
  &.success {
    color: ${Colors.G_200};
  }
`;

interface IJDInputProps extends IComponentProps {
  type: InputHTMLAttributes<HTMLInputElement>['type'];
  name: string;
  label: string;
  width?: string;
  successText?: string;
  guideText?: string;
  autoCompleteList?: { fullValue: string; userInput: string; }[];
  interaction?: boolean;
  birthFormat?:boolean
}

const JDInput: FC<IJDInputProps> = ({ birthFormat = false, type = 'text', name, label, width = '100%', successText = '', guideText = '', autoCompleteList, interaction = true, ...props }) => {
  const formMethods = useFormContext();
  const { register, watch, setValue, formState } = formMethods;
  const { errors } = formState;

  const [inputType, setInputType] = useState<string>(type);
  const [isFocus, setIsFocus] = useState<boolean>(false);

  const onBlurHandler = (e: FocusEvent<HTMLInputElement>) => {
    setValue(name, e.currentTarget.value);
    setIsFocus(false);
  };
  const formatBirthDate = (value: string) => {
    const clean = value.replace(/\D/g, ''); // 숫자만 추출
    if (clean.length <= 4) return clean; // 첫 4자리까지는 그대로 반환
    if (clean.length <= 6) return `${clean.slice(0, 4)}.${clean.slice(4)}`; // 5-6자리 사이에서 점 추가
    return `${clean.slice(0, 4)}.${clean.slice(4, 6)}.${clean.slice(6)}`; // 7-8자리 사이에서 점 추가
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formatted = formatBirthDate(e.target.value);
    setValue(name, formatted, { shouldValidate: true });
  };

  return (
    <Frame width={width}>
      <InputBox className={`${watch(name) && interaction ? 'valid' : ''} ${errors[name] ? 'error' : ''}`}>
        { interaction && <span>{ label }</span> }
        {
          birthFormat
            ? <input onChange={handleInputChange} type={inputType} aria-label={label} name={name} ref={register} autoComplete='off' onFocus={() => setIsFocus(true)} onBlur={onBlurHandler} {...props} />
            : <input type={inputType} aria-label={label} name={name} ref={register} autoComplete='off' onFocus={() => setIsFocus(true)} onBlur={onBlurHandler} {...props} />
        }

        { type === 'password' && <Icon name={inputType === 'password' ? 'eye-close' : 'eye-open'} size={24} onClick={() => setInputType(inputType === 'password' ? 'text' : 'password')} /> }
      </InputBox>
      { autoCompleteList && isFocus && watch(name) && (
        <AutoCompleteBox>
          { autoCompleteList.map((autoComplete) => <div key={autoComplete.fullValue} role='menu' onMouseDown={() => setValue(name, autoComplete.fullValue)}><span>{autoComplete.userInput}</span>{autoComplete.fullValue.slice(autoComplete.userInput.length)}</div>) }
        </AutoCompleteBox>
      ) }
      { guideText && <GuideText className='guide'>{ guideText }</GuideText> }
      { errors[name] && errors[name].message && <GuideText className='error'>{ errors[name].message }</GuideText> }
      { successText && <GuideText className='success'>{ successText }</GuideText> }
    </Frame>
  );
};

export default JDInput;
