import React, { FC } from 'react';
import img500 from 'assets/img/img_error_500.svg';
import LazyImage from 'components/common/LazyImage';
import { retryRequest } from 'utils/request';
import Portal from 'components/common/Portal';
import styled from 'styled-components/macro';
import colors from '__designkit__/common/colors';
import ImgFixJoy from 'assets/character/joy_fix_service.svg';
import { useHistory } from 'react-router';
import RoutePaths from 'consts/RoutePaths';
import ErrorFrame, { ErrorCTAButton } from './ErrorFrame';

const ErrorBackground = styled.div`
  position:fixed;
  background:${colors.WHITE_100};
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index:10001;
`;

const Error500:FC = () => {
  const history = useHistory();
  return (
    <Portal>
      <ErrorBackground>
        <ErrorFrame>
          <div className='frame-main'>
            <LazyImage className='img-500' src={ImgFixJoy} alt='404' />
            <div className='title'>
              서비스 점검 중입니다
            </div>
            <div className='desc'>
              {`지금 이 페이지와 연결할 수 없습니다.\n문제 해결 중이오니 다시 확인해 주세요.`}
            </div>
          </div>
          <ErrorCTAButton onClick={() => history.push(RoutePaths.home)}>
            홈으로 가기
          </ErrorCTAButton>
        </ErrorFrame>
      </ErrorBackground>
    </Portal>
  );
};

export default Error500;
