import LazyImage from 'components/common/LazyImage';
import colors from '__designkit__/common/colors';
import { fonts, lineHeights } from '__designkit__/common/fonts';
import React from 'react';
import styled from 'styled-components/macro';
import ImgCompany1 from 'assets/img/ai/introduce/img_introduce_company_1.png';
import ImgCompany2 from 'assets/img/ai/introduce/img_introduce_company_2.png';

const Frame = styled.div`
  height:100%;
`;

const StyledCompanyInfoFrame = styled.div`
  padding-left:24px;
  padding-right:24px;
  padding-top:48px;
  display: flex;
  flex-direction: column;

  h1{
   ${fonts.NOTO_36_700};
   color:${colors.CG_90}; 
   ${lineHeights.LINEHEIGHT_1_40};
  }

  .img-company-info-1{
    margin-top:48px;
    align-self: flex-end;
    height: 116px;
    border-radius: 16px;
  }

  .img-company-info-2{
    width: 100%;
    margin-top:24px;
    border-radius: 16px;
  }

  h2{
   margin-top:48px;
   margin-bottom:24px;
   ${fonts.NOTO_20_700};
   color:${colors.CG_80}; 
   ${lineHeights.LINEHEIGHT_1_50};
  }

  h3{
   margin-bottom:16px;
   ${fonts.NOTO_13_400};
   color:${colors.CG_70};
   ${lineHeights.LINEHEIGHT_1_50};
   em{
     ${fonts.NOTO_13_700};
   }
  }


`;

const AiIntroduceCompanyInfo: React.FC = () => (
  <Frame>
    <StyledCompanyInfoFrame>
      <h1>기업은 <br />
        알고 있습니다.
      </h1>
      <LazyImage src={ImgCompany1} className='img-company-info-1' />
      <LazyImage src={ImgCompany2} className='img-company-info-2' />
      <h2>기업이 원하는 인재,<br />
        성과를 잘 내는 사람입니다.
      </h2>
      <h3>취업을 준비하면서 우리는 흔히들 기업이 원하는 인재는 스펙, 학력, 경력, 지식이 좋은 사람이라고 생각합니다. <em>하지만, 기업이 원하는 인재는 자신에게 주어진 역할과 기회를 통해 성과를 잘 내고 잘 성장할 수 있는 ‘일을 잘하는 사람’입니다.</em></h3>
      <h3>세계적인 기업인 <em>구글의 인사책임자</em>는 말합니다. “학벌이나 자격증은 업무 능력과 전혀 상관이 없다. <em>대학을 나오지 않은 사람들이 종종 ‘가장 높은 성과’를 냈다”</em> -뉴욕타임즈 인터뷰-</h3>
      <h3>학교에서 인재는 있는 정답을 잘 맞추는 사람이지만, 직장에서의 인재는 없는 답을 찾고 만들어가는 사람입니다.</h3>
      <h3>
        기업이 원하는 인재는 <em>일을 잘하는 사람, 잘 클 수 있는 사람, 그리고 우리 회사에 잘 맞는 사람입니다.</em> 이 3가지 조건의 공통점이 <em>바로 역량입니다.</em>
      </h3>
    </StyledCompanyInfoFrame>
  </Frame>
);

export default AiIntroduceCompanyInfo;
