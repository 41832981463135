import React from 'react';
import SlidingTextSection from 'components/SlidingTextSection';

const SlidingTextPage = () => {
  return(
    <div>
      <SlidingTextSection text='RIGHT INFORMATION FOR EVERYONE' />
      <SlidingTextSection text='sample text2' />
      <SlidingTextSection text='sample text3' />
      <SlidingTextSection text='sample text4' />
      <SlidingTextSection text='sample text5' />
    </div>
  )
};

export default SlidingTextPage;