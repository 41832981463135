import { ReactComponent as IconReset } from 'assets/_v2/_common/input/icon_reset.svg';
import { ReactComponent as IconArrowDown } from 'assets/_v2/_common/input/icon_small_arrow_down.svg';
import { ChallengePositionCard } from 'components/_v2/challengeDetail/ChallengeDetailPositions';
import { StyledSelectModalFrame } from 'components/_v2/position/filter/PositionJobTitleFilter';
import { StyledMediumSelector } from 'components/_v2/_common/input/JDSelector';
import JDSquareCheckBox from 'components/_v2/_common/input/JDSquareCheckBox';
import JDUnderBaseModal from 'components/_v2/_common/modals/JDUnderBaseModal';
import RoutePaths from 'consts/RoutePaths';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import IModalProps from 'interfaces/props/IModalProps';
import { IChallengePositionsDto } from 'interfaces/_v2/challenge/IChallengeDetailPositions';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import ChallengeDetailModel from 'models/_v2/ChallengeDetailModel';
import React, { FC, useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import JDFullScreenBaseModal from '../_common/modals/JDFullScreenBaseModal';

const Frame = styled.div`
    padding: 56px 16px 26px 16px;
    .head {
        font: ${Fonts.H5_Bold};
        color: ${colors.CG_60};
        margin-bottom: 15px;
        >b {
            color: ${colors.JOBDA_BLACK};
        }
    }

    >ul {
        margin: 16px 0;

        li:not(:last-child) {
            margin-bottom: 8px;
        }
    }
`;

interface IChallengeDetailPositionsModalProps extends IModalProps{
    challengeTitle: string;
    challengePositions: IChallengePositionsDto[];
    challengeDetailModel?: ChallengeDetailModel;
}

const ChallengeDetailPositionsModal:FC<IChallengeDetailPositionsModalProps> = ({ isOpen, onClose, challengePositions, challengeTitle, challengeDetailModel = new ChallengeDetailModel() }) => {
  const { challengeDetailCompanies } = challengeDetailModel;
  const { challengeSn } = useParams<{ challengeSn: string }>();

  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const [currentSelectList, setCurrentSelectList] = useState<string[]>([]);
  const useformed = useForm({ mode: 'all' });
  const { watch, reset } = useformed;

  const [saveSelectList, setSaveSelectList] = useState<string[]>([]);
  const onChangeCheckbox = () => {
    const list: [] = watch('companies');
    setCurrentSelectList(list.filter((value) => value !== false));
  };

  const onClickReset = () => {
    const resetList:any = [];
    challengeDetailCompanies?.map((challengeCompanies) => resetList[challengeCompanies.companySn] = false);
    reset({ companies: resetList });
    setCurrentSelectList([]);
  };

  const onClickFilter = () => {
    challengeDetailModel.searchFilters.companySn = currentSelectList.join(',');
    challengeDetailModel.loadChallengeDetailPositions(Number(challengeSn), currentSelectList);
    setSaveSelectList(currentSelectList);
    setIsSelectOpen(false);
  };

  useEffect(() => {
    if (isSelectOpen) {
      const list = challengeDetailModel.searchFilters.companySn!.split(',');
      if (challengeDetailCompanies) {
        const resetList = Object.values(challengeDetailCompanies).map((type) => {
          const find = list.find((value) => value === String(type.companySn));
          if (find) return find;
          return false;
        });
        reset({ companies: resetList });
      }
      if (list[0] === '')setCurrentSelectList([]);
      else setCurrentSelectList(list);
    }
  }, [isSelectOpen]);

  useEffect(() => {
    if (challengeDetailModel.searchFilters.companySn === '')setSaveSelectList([]);
  }, [challengeDetailModel.searchFilters.companySn]);

  const closeModal = () => {
    challengeDetailModel.loadChallengeDetailPositions(Number(challengeSn));
    onClose && onClose();
  };
  return (
    <>
      <JDFullScreenBaseModal isOpen={isOpen} onClose={() => closeModal()}>
        <Frame>
          <p className='head'><b>{challengeTitle}</b>에 참여중인 {challengePositions.length}개의 포지션</p>
          <StyledMediumSelector
            onClick={() => {
              setIsSelectOpen(true);
            }}
          >
            <span>참여기업</span>
            <IconArrowDown />
          </StyledMediumSelector>
          <ul>
            {challengePositions?.map((data) => (
              <Link to={RoutePaths.position_jd(data.positionSn)} className='link'>
                <li key={`matching-company-card-${data.positionSn}`}><ChallengePositionCard positionInfo={data} /></li>
              </Link>
            ))}
          </ul>
        </Frame>
      </JDFullScreenBaseModal>

      <JDUnderBaseModal isOpen={isSelectOpen} onClickClose={() => setIsSelectOpen(false)}>
        <FormProvider {...useformed}>
          <StyledSelectModalFrame>
            <div className='title'>참여기업</div>
            <div className='content-frame'>
              <div className='group-frame'>
                <div className='group-items'>
                  {
                    challengeDetailCompanies?.map(({ companySn, companyName }, index) => (
                      <>
                        <JDSquareCheckBox
                          key={`check-option-company-${companySn}`}
                          name={`companies.${index}`}
                          value={String(companySn)}
                          label={<span>{companyName}</span>}
                          onChange={onChangeCheckbox}
                        />
                      </>

                    ))
          }
                </div>
              </div>
            </div>
            <div className='btn-frame'>
              <button className='btn-reset' onClick={onClickReset}><IconReset />필터 초기화</button>
              <button
                className='btn-search'
                onClick={onClickFilter}
              >{`검색(${currentSelectList.length})건`}
              </button>
            </div>
          </StyledSelectModalFrame>
        </FormProvider>
      </JDUnderBaseModal>
    </>
  );
};
export default inject(injectStore.challengeDetailModel)(observer(ChallengeDetailPositionsModal));
