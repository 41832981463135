import React from 'react';
import classnames from 'classnames/bind';
import JDALink from 'components/JDALink';
import RoutePaths from 'consts/RoutePaths';
import styles from './mainMsg.module.scss';

const cx = classnames.bind(styles);

interface IntroBoxProps {
  fadeIn: boolean;
}

const MainMsg = ({ fadeIn }: IntroBoxProps) => (
  <div className={cx('commonBox', { on: fadeIn })}>
    <p className={cx('mainTitle')}>
      자소서, 지원서도 안썼는데..
      <br />
      역량만으로
      <br />
      진짜 취업될지 몰랐어요!
    </p>
    <span className={cx('subTitle')}>글로벌 IT기업 서비스기획 합격자 김상동님</span>
    <JDALink
      to={RoutePaths.info_detail(395)}
      className={cx('btnBlack', 'btnIcon')}
    >
      취업 합격후기 확인하기
    </JDALink>
  </div>
);

export default MainMsg;
