import styled from 'styled-components';
import colors from '__designkit__/common/colors';

const DividerG = styled.div`
  width: 100%;
  background: ${colors.CG_30};
`;

const DividerB = styled.div`
  width: 100%;
  background: ${colors.BLACK_100};
`;

const DividerW = styled.div`
  width: 100%;
  background: ${colors.WHITE_100};
`;

const DividerG40 = styled.div`
  width: 100%;
  background: ${colors.CG_40};
`;

const DividerG50 = styled.div`
  width: 100%;
  background: ${colors.CG_50};
`;

const DividerGrey = styled.div`
  width: 100%;
  background: ${colors.CG_30};
`;

const DividerCoolGrey = styled.div`
  width:100%;
  background: ${colors.CG_80};
`;

export const Divider1G = styled(DividerG)`
  height: 1px;
`;

export const Divider12G = styled(DividerG)`
  height: 12px;
`;

export const Divider16G = styled(DividerG)`
  height: 16px;
`;
export const Divider1B = styled(DividerB)`
  height: 1px;
`;

export const Divider12B = styled(DividerB)`
  height: 12px;
`;

export const Divider16B = styled(DividerB)`
  height: 16px;
`;

export const Divider12W = styled(DividerW)`
  height: 12px;
`;

export const Divider16W = styled(DividerW)`
  height: 16px;
`;

export const Divider24W = styled(DividerW)`
  height: 16px;
`;

export const Divider1G40 = styled(DividerG40)`
  height: 1px;
`;

export const Divider1G50 = styled(DividerG50)`
  height: 1px;
`;

export const Divider12Grey = styled(DividerGrey)`
  height: 12px;
`;

export const Divider8Grey = styled(DividerGrey)`
  height: 8px;
`;

export const Divider1CoolGrey = styled(DividerCoolGrey)`
  height: 1px;
`;
