import DisableScroll from 'components/common/DisableScroll';
import Portal from 'components/common/Portal';
import GlobalSearchInput from 'components/_v2/search/GlobalSearchInput';
import RoutePaths from 'consts/RoutePaths';
import { dimmer, lineClampBox } from 'consts/style/mixins';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import GlobalSearchModel from 'models/_v2/GlobalSearchModel';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import Fonts from '__designkit__/common/fonts';
import Login from 'models/Login';
import PositionListModel from 'models/_v2/PositionListModel';
import Colors from '__designkit__/common/colors';
import GlobalSearchKeyword from './GlobalSearchKeyword';
import MainHeader from '../_common/mainHeader/MainHeader';
import GlobalSearchRecommend from './GlobalSearchRecommend';

const HeaderFrame = styled.div`
  .header-frame{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 72px;

    .btn-back {
      margin-left: 12px;
      width: 32px;
      height: 32px;
    }
  }
`;
const StyledSearchFrame = styled.div`
  background: ${Colors.WHITE_100};
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  padding-bottom: 128px;

  .search-inner {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 24px 0;
    

    .chip-frame {
      padding: 0 16px;
    }

    .scroll-frame {
      .search-title {
        padding-left: 16px;
      }
    }
    .search-title {
      margin-bottom: 16px;
      font: ${Fonts.H5_Bold};
      color:${Colors.JOBDA_BLACK};
    }
  }
`;

const PopularList = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px 8px;

  > * {
    flex-basis: calc(50% - 16px);
  }
`;
const PopularListItem = styled.div`
  display: inline-block;
  width: 50%;
  min-width: calc(50% - 16px);
  font: ${Fonts.B1_Medium_P};
  color:${Colors.CG_80};

  .item {
    ${lineClampBox(1)}
    word-break:break-all;
    &.additional-class {
      .order {
        color:${Colors.G_150};
      }
    }

    .order {
      display: inline-block;
      width: 12px;
      margin-right: 8px;
      font: ${Fonts.B2_Bold_P};
      color:${Colors.CG_60};
    }
  }
`;

const BaseModalDimFrame = styled.div`
 position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: env(safe-area-inset-top) 0 env(safe-area-inset-bottom) 0;
  box-sizing: border-box;
  z-index: 10005;
  background-color: ${Colors.WHITE_100};
  margin: 0;

  &.active {
    pointer-events: inherit;
    opacity: 1;
  }


`;
interface IGlobalSearchModal {
  isOpen: boolean;
  onClickClose: () => void;
  globalSearchModel?: GlobalSearchModel;
  positionListModel?: PositionListModel;
  login?:Login
}

const GlobalSearchModal: FC<IGlobalSearchModal> = ((props) => {
  const { isOpen, onClickClose, globalSearchModel = new GlobalSearchModel(), login = new Login(), positionListModel = new PositionListModel() } = props;
  const [isOpenState, setIsOpenState] = useState<boolean>(isOpen);
  const history = useHistory();

  const init = async () => {
    await globalSearchModel.searchRecommendKeyword();
  };

  useEffect(() => {
    setIsOpenState(isOpen);
    if (isOpen)init();
  }, [isOpen]);

  if (isOpen || isOpenState)
    return (
      <Portal>
        <DisableScroll />
        <BaseModalDimFrame className={isOpen ? 'active' : ''}>
          <HeaderFrame>
            <MainHeader
              close
              gnb={false}
              search={false}
              alarm={false}
              onClose={() => onClickClose()}
            />
            <div className='header-frame'>
              <GlobalSearchInput isOpen={isOpen} onSearch={() => onClickClose()} />
            </div>
          </HeaderFrame>
          <StyledSearchFrame onClick={(e) => e.stopPropagation()}>
            <div className='search-inner'>
              {/* 추천 키워드 */}
              <div className='scroll-frame'>
                <div className='search-title'>{login.userInfo ? '나에게 맞는 추천 키워드' : '추천 키워드'} </div>
                <GlobalSearchRecommend onClickClose={onClickClose} />
              </div>
              <div className='chip-frame'>
                <div className='search-title'>인기 기업</div>
                <PopularList>
                  {
                    globalSearchModel.recommendKeyword
                    && globalSearchModel.recommendKeyword
                      .filter((data) => data.companyName !== 'JOBDA' && data.companyName !== '잡다매칭')
                      .slice(0, 6)
                      .map((data, index) => (
                        <PopularListItem
                          key={data.companyName}
                          onClick={() => {
                            history.push(RoutePaths.search_result(data.companyName));
                            positionListModel.resetFilter();
                            onClickClose();
                          }}
                        >
                          <div className={`item ${index <= 2 ? 'additional-class' : ''}`}>
                            <span className='order'>{index + 1}</span>
                            {data.companyName}
                          </div>
                        </PopularListItem>
                      ))
                  }
                </PopularList>
              </div>
              <div className='chip-frame'>
                <div className='search-title'>인기 포지션</div>
                <PopularList>
                  {
                    globalSearchModel.recommendKeyword
                    && globalSearchModel.recommendKeyword.slice(0, 6).map((data, index) => (
                      <PopularListItem
                        key={data.positionName}
                        onClick={() => {
                          history.push(RoutePaths.search_result(data.positionName));
                          positionListModel.resetFilter();
                          onClickClose();
                        }}
                      >
                        <div className={`item ${index <= 2 ? 'additional-class' : ''}`}>
                          <span className='order'>{index + 1}</span>
                          {data.positionName}
                        </div>
                      </PopularListItem>
                    ))
                  }
                </PopularList>
              </div>
              {/* 잡다 추천 서비스 */}
              <div className='scroll-frame'>
                <div className='search-title'>잡다 추천 서비스</div>
                <GlobalSearchKeyword onClickClose={onClickClose} />
              </div>
            </div>
          </StyledSearchFrame>
        </BaseModalDimFrame>
      </Portal>
    );
  return <></>;
});

export default inject(injectStore.globalSearchModel, injectStore.login, injectStore.positionListModel)(observer(GlobalSearchModal));
