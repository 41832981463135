import URIs from 'consts/URIs';
import { IGetCareersRs, IPostCareerRq, IPutCareerRq, IPutNhisCareersRq } from 'interfaces/_v2/profile/IProfileCareerRqRs';
import request from 'utils/request';

const ProfileAPI = {
  // 경력 리스트 조회
  getCareers: async () => {
    try {
      const res = await request<IGetCareersRs>({
        method: 'GET',
        url: URIs.get_profiles_career,
      });
      return res;
    } catch (e) {
      const err = e as { response: any };
      throw err;
    }
  },
  // 경력 추가
  postCareer: async (data: IPostCareerRq) => {
    try {
      await request<void>({
        method: 'POST',
        url: URIs.post_profiles_career,
        data,
      });
    } catch (e) {
      const err = e as { response: any };
      throw err;
    }
  },
  // 경력 수정
  putCareer: async (sn: number, data: IPutCareerRq) => {
    try {
      await request<void>({
        method: 'PUT',
        url: URIs.put_profiles_career(sn),
        data,
      });
    } catch (e) {
      const err = e as { response: any };
      throw err;
    }
  },
  // 경력 삭제
  deleteCareer: async (sn: number) => {
    try {
      await request<void>({
        method: 'DELETE',
        url: URIs.delete_profiles_career(sn),
      });
    } catch (e) {
      const err = e as { response: any };
      throw err;
    }
  },
  // nhis 연동 경력 추가
  putNhisCareer: async (data: IPutNhisCareersRq) => {
    try {
      await request<void>({
        method: 'PUT',
        url: URIs.put_v2_profiles_career_nhis,
        data,
      });
    } catch (e) {
      const err = e as { response: any };
      throw err;
    }
  },

};

export default ProfileAPI;
