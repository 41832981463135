export enum BusinessSizeType {
    SMALL = 'SMALL',
    MEDIUM = 'MEDIUM',
    LARGE = 'LARGE',
    GOVERNMENT = 'GOVERNMENT',
    STARTUP = 'STARTUP',
    ETC = 'ETC',
}

export enum BusinessSizeTypeText {
    SMALL = '중소기업',
    MEDIUM = '중견기업',
    LARGE = '대기업',
    GOVERNMENT = '공기업',
    STARTUP = '스타트업',
    ETC = '기타',
}
