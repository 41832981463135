import { FC } from 'react';
import colors from '__designkit__/common/colors';
import { fontStyle } from '__designkit__/common/fonts';
import IComponentProps from 'interfaces/props/IComponentProps';
import styled from 'styled-components';
import iconInfoB from 'assets/icon/icon_info_b.svg';

//* * 인포메이션 박스 컴포넌트

interface IJDAInformationBoxProps extends IComponentProps {
  type?: 'INFORMATION'
  backgroundColor?: string;
  borderColor?: string;
  fontColor?: string;
}

const JDAInformationBox:FC<IJDAInformationBoxProps> = styled.article<IJDAInformationBoxProps>`
  position: relative;
  ${fontStyle.CAPTION2_MEDIUM};
  padding: 6px 6px 7px 27px;
  border: 1px solid ${({ borderColor }) => borderColor || colors.CG_40};
  color: ${({ fontColor }) => fontColor || colors.CG_70};
  background: ${({ backgroundColor }) => backgroundColor || colors.CG_30};
  border-radius: 4px;
  white-space: pre-line;
  margin-bottom:32px;
  &:before {
    content: '';
    display: block;
    width:16px;
    height:16px;
    position:absolute;
    background: url(${iconInfoB});
    background-repeat:no-repeat;
    top:8px;
    left:8px;
  }
`;

export default JDAInformationBox;
