import config from 'config';
import URIs from 'consts/URIs';
import { EventSourcePolyfill, NativeEventSource } from 'event-source-polyfill';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import { useEffect } from 'react';
import AuthorizeUtil from 'utils/AuthorizeUtil';

const SSERegister = inject(injectStore.notificationModel, injectStore.login, injectStore.matchApplyStatusModel)(observer(({ notificationModel, login, matchApplyStatusModel }) => {

  useEffect(() => {
    const register = async () => {
      try {
        if (AuthorizeUtil.bearerAccessToken) {
          const EventSource = EventSourcePolyfill || NativeEventSource;
          const eventSource = new EventSource(`${config.host}${URIs.get_sse_subscribe}`, {
            headers: { Authorization: AuthorizeUtil.bearerAccessToken },
            heartbeatTimeout: 600000, // 10분
            withCredentials: true,
          });
          await notificationModel.loadNotifications(); // 초기 알림 가져오기 위한 호출
          const NotificationType = 'jobda:v1:notification';
          const MatchingType = 'jobda:v1:matching'; // 매칭 현황 업데이트 조회 이벤트

          eventSource.addEventListener(NotificationType, (event: any) => {
            const parsedData = JSON.parse(event.data);
            notificationModel.setUnRead(true);
            notificationModel.setUnReadCount(notificationModel.unReadCount + 1);
            notificationModel.notifications.unshift(parsedData.notification);
          });

          eventSource.addEventListener(MatchingType, async (event: any) => {
            const parsedData = JSON.parse(event.data);
            await matchApplyStatusModel.loadMatchList(parsedData.statusType);
            await matchApplyStatusModel.loadMatchStatus();
          });

          return () => {
            eventSource.close();
          };
        }
      } catch (error) {
        console.error('SSE Error: ', error);
      }
    };
    register();
  }, [notificationModel, login.userInfo]);

  return null;
}));

export { SSERegister };
