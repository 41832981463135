// 역량검사 분석 (프로필 완성 여부 조회 - 역량검사 분석 상태 조회 )
// NULL - 역량검사 응시 x

// PHS 의 경우
export enum MatchPHSAnalysisType {
    COMPLETED = 'COMPLETED', // 분석 완료 결과 최소 1개 이상
    IN_PROGRESS = 'IN_PROGRESS', // 분석 중 (아직 분석 결과가 없는 경우)
    FAILED = 'FAILED', // 분석 오류
}

// ACC와 PHS와 분석 타입 enum값이 달라서 조심해야 합니다.
export enum MatchAccAnalysisType {
    COMPLETE = 'COMPLETE', // 분석 완료 결과 최소 1개 이상
    PROGRESS = 'PROGRESS', // 분석 중 (아직 분석 결과가 없는 경우)
    FAILED = 'FAILED', // 분석 오류
}
