import URIs from 'consts/URIs';
import { ITalentPoolEventRs } from 'interfaces/_v2/IEventChallenge';
import { action, observable } from 'mobx';
import { request } from 'utils/request';

export default class EventChallengeModel {
    @observable userEventStatus:ITalentPoolEventRs | null = null;

    @action
    loadEventsTalentPoolUserStatus = async () => {
      try {
        this.userEventStatus = (await request<ITalentPoolEventRs>({
          method: 'get',
          url: URIs.get_events_talent_pool_user_status,
        }));
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadEventsTalentPoolProfileInputApply = async () => {
      try {
        const res = await request({
          method: 'post',
          url: URIs.post_events_talent_pool_profile_input_apply,
        });
        return res;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }

    @action
    loadEventsTalentPoolFinalApply = async () => {
      try {
        const res = await request({
          method: 'post',
          url: URIs.post_events_talent_pool_final_apply,
        });
        return res;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }

    @action
    loadEventsTalentPoolAccTestApply = async () => {
      try {
        const res = await request({
          method: 'post',
          url: URIs.post_events_talent_pool_acc_test_apply,
        });
        return res;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }

    @action
    loadEventsAccMbtiApply = async () => {
      try {
        const res = await request({
          method: 'post',
          url: URIs.post_events_acc_mbti_apply,
        });
        return res;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }

    @action
    loadEventsTalentPoolAccTestResumeDownload = async () => {
      try {
        const res = await request<ArrayBuffer>({
          method: 'get',
          url: URIs.get_events_talent_pool_acc_test_resume_download,
          responseType: 'arraybuffer',
        });
        return res;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }
}
