import colors from '__designkit__/common/colors';
import { fonts, lineHeights } from '__designkit__/common/fonts';
import styled from 'styled-components';

//* * 인풋 아래에 붙는 올바른 캡션 부분 참고 - https://www.figma.com/file/fmLAIf2IlhdQF6AlmepT7A/%F0%9F%A4%9D%EC%9E%A1%EB%8B%A4_Design?node-id=4234%3A98910 */

const JDAValidInputCation = styled.div`
  padding-left:16px;
  margin-top:5px;
  ${fonts.NOTO_11_400};
  letter-spacing:-0.07px;
  ${lineHeights.LINEHEIGHT_1_20};
  color:${colors.G_200};
`;

export default JDAValidInputCation;
