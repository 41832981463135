import MyDashboardComponent from 'components/_v2/myProfile/MyDashboardComponent';
import MyProfileBoxComponent from 'components/_v2/myProfile/MyProfileBoxComponent';
import React from 'react';
import styled from 'styled-components';

const Frame = styled.div`

`;

const MyProfile = () => (
  <Frame>
    <MyDashboardComponent />
    <MyProfileBoxComponent />
  </Frame>
);

export default MyProfile;
