import React from 'react';
import Footer from 'components/_v2/_common/Footer';
import classnames from 'classnames/bind';
import Intro from './intro';
import Order from './order';
import HowTo from './howTo';
import LastBanner from './lastBanner';
import styles from './commonStyle.module.scss';
import Interviewee from './Interviewee';
import MatchingMessage from './matchingMessage';
import ProfileZip from './profileZip';

const cx = classnames.bind(styles);

const EventProfile = () => {
  return (
    <div className={cx('wrap')}>
      <Intro />
      <Order />
      <HowTo />
      <ProfileZip />
      <MatchingMessage />
      <Interviewee />
      <LastBanner />
      <Footer isNavi={false} corpInfoFoled={false} />
    </div>
  );
};
export default EventProfile;
