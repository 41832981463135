import DisableScroll from 'components/common/DisableScroll';
import Portal from 'components/common/Portal';
import MainHeader from 'components/_v2/_common/mainHeader/MainHeader';
import { StyledTermsFrame } from 'components/modals/TermsModal';
import colors from '__designkit__/common/colors';
import { importantify } from 'consts/style/mixins';
import { TERM_TYPE } from 'consts/TermType';
import Fonts from '__designkit__/common/fonts';
import IJoinPageProps from 'interfaces/props/IJoinPageProps';
import { ITermsGetDto } from 'interfaces/rqrs/ITermsListGetRs';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import JoinModel from 'models/_v2/JoinModel';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import CommonUtils from 'utils/CommonUtils';

const FrameStyled = styled(StyledTermsFrame)`
  .frame-terms {
    
    p span {
      font: ${importantify(Fonts.B2_Medium_P)};
      color: ${colors.CG_80} !important;
    }

    table tr td {
      b {
        font: ${importantify(Fonts.B3_Bold)};
        color:${colors.CG_90} !important; 
      }
    }

    table tr:not(:first-child) td:first-child {
      b { color:${colors.CG_90} !important; }
    }

    table tr:first-child td, table tr:first-child td * {
      background: none !important;
      color:${colors.CG_90} !important;
      font: ${importantify(Fonts.B3_Medium)};
      b {
        font: ${importantify(Fonts.B3_Bold)};
        color:${colors.CG_90}; 
      }
    }
  }
`;

const ResumeThirdpartyAgreement: FC<IJoinPageProps> = ({ joinModel = new JoinModel() }) => {
  const [activeTerms, setActiveTerms] = useState<ITermsGetDto | null>(null);
  const { search } = useLocation();
  useEffect(() => {
    const init = async () => {
      try {
        const paramList = CommonUtils.parseQuery(search);
        await joinModel.loadTermsList(TERM_TYPE.RESUME_PRIVACY_PROVISION);
        const terms = joinModel.activeTermContents;
        const term = terms[terms.length - 1];
        if (paramList.companyName) {
          term.contents = term.contents.replace(/\$고객사\$/g, paramList.companyName);
          term.contents = term.contents.replace(/\$기업명\$/g, paramList.companyName);
          term.contents = term.contents.replace(/\$입사지원 기업\$/g, paramList.companyName);
        }
        setActiveTerms(term);
      } catch (e) {
        //
      }
    };
    init();
    return () => {
    };
  }, []);

  return (
    <Portal>
      <FrameStyled className='visible'>
        <DisableScroll />
        <div className='dimmer' />
        <MainHeader
          className='header'
          title='개인정보 제3자 제공 동의'
          close
          search={false}
          gnb={false}
          onClose={() => {
            window.close();
            setTimeout(() => {
              joinModel.activeTermType = null;
            }, 500);
          }}
          data-hide={false as any}
        />
        <div className='frame-content'>
          <div className='frame-terms' dangerouslySetInnerHTML={{ __html: activeTerms?.contents || '' }} />
        </div>
      </FrameStyled>
    </Portal>
  );
};

export default inject(injectStore.joinModel)(observer(ResumeThirdpartyAgreement));
