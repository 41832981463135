import useAxios from 'axios-hooks';
import { IRequestOptions } from 'utils/request';

const useRequest = ({ method, url }: IRequestOptions, onFulfilled: (data: any) => void) => {
  const [{ loading, error }, execute] = useAxios(
    {
      url,
      method,
    },
    { manual: true },
  );

  const executeRequest = async (params?: any, data?: any) => {
    try {
      const response = await execute({ params, data });
      if (onFulfilled) {
        onFulfilled(response.data);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return { loading, error, execute: executeRequest };
};

export default useRequest;
