import Loading from 'components/Loading';
import JDScrollToTopButton from 'components/_v2/_common/button/JDScrollToTopButton';
import { PositionJDMetaTag } from 'components/_v2/_common/meta/MetaTag';
import PositionJdApply from 'components/_v2/positionJd/PositionJdApply';
import PositionJdCompanyInfo from 'components/_v2/positionJd/PositionJdCompanyInfo';
import PositionJdCompanyInfoTwo from 'components/_v2/positionJd/PositionJdCompanyInfoTwo';
import PositionJdContent from 'components/_v2/positionJd/PositionJdContent';
import PositionJdEditRequest, { PositionTypeAll } from 'components/_v2/positionJd/PositionJdEditRequest';
import PositionJdKakaoComponent from 'components/_v2/positionJd/PositionJdKakaoComponent';
import PositionJdMatchingChallenge from 'components/_v2/positionJd/PositionJdMatchingChallenge';
import PositionJdMatchingCompany from 'components/_v2/positionJd/PositionJdMatchingCompany';
import PositionJdPosterSwiper from 'components/_v2/positionJd/PositionJdPosterSwiper';
import PositionJdWelfare from 'components/_v2/positionJd/PositionJdWelfare';
import PositionMatchingJob from 'components/_v2/positionJd/PositionMatchingJob';
import { Divider12Grey } from 'components/divider/Divider';
import { PositionApplyType } from 'consts/PositionType';
import RoutePaths from 'consts/RoutePaths';
import { RecruitmentTypeText } from 'consts/_v2/position/PositionType';
import IPositionJdProps from 'interfaces/_v2/positionJd/IPositionJdProps';
import { inject, observer } from 'mobx-react';
import PositionJdModel from 'models/_v2/PositionJdModel';
import { injectStore } from 'models/store';
import AutoHeightIFrame from 'pages/_v2/position/AutoHeightIframe';
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { TimeType, getBetweenTime, getPositionDday } from 'utils/DateUtils';
import { iframePinchZoomUtil } from 'utils/_v2/iframePinchZoomUtils';
import KakaoMapScript from 'utils/_v2/kakaoMap/KakaoMapScript';
import { IsJsonString } from 'utils/_v2/validation';

// 잡플 <> 잡다 공고 => externalYn = true ,externalInfo O
// 잡다 <> ATS, CMS 잡플 공고 => externalYn = true, externalInfo X

const Frame = styled.div<{ nonBottomPadding: boolean }>`
    overflow: hidden; // swiper를 위한 overflow
    padding : 0 0 124px 0;

    .frame-top{
      padding : 10px 16px 32px 16px;
      margin-bottom: 16px;
    }

    ${(props) => props.nonBottomPadding
    && css`
        .frame-top {
          padding-bottom: 0;
        }
    `};

    .jd-content-frame {
      padding: 32px 16px;
      overflow: scroll;
      div{
        margin:auto !important;
      }
      table{
        margin:auto !important;
      }
    }

    .iframe-wrap {
      padding: 0 16px;
    }
`;

const PositionJd: FC<IPositionJdProps> = ({ positionJdModel = new PositionJdModel() }) => {
  const { positionSn } = useParams<{ positionSn: string }>();
  const { positionMatchingJobList } = positionJdModel;
  const { positionMatchingCompanyList } = positionJdModel;
  const { positionMatchingChallengeList } = positionJdModel;
  const { additionalInfo } = positionJdModel;
  const { basicInfo } = positionJdModel;
  const [jobflexJdYn, setJobflexJdYn] = useState(false);
  const [atsJdYn, setAtsJdYn] = useState(false);
  const [cmsYn, setCmsYn] = useState(false);
  const [loading, setLoading] = useState<boolean | null>(null);
  const [fullDocument, setFullDocument] = useState('');
  const [isWinBack, setIsWinBack] = useState<boolean>(false);
  const history = useHistory();

  const checkCompanySn = (sn: number) => {
    const env = process.env.REACT_APP_ENV;
    if ((env === 'staging2' && sn === 91161) || (env === 'production' && sn === 91749) || (env === 'production' && sn === 91931) || (env === 'production' && sn === 91932)) {
      setIsWinBack(true);
    } else {
      setIsWinBack(false);
    }
  };
  useEffect(() => {
    const init = async () => {
      try {
        if (Number.isNaN(Number(positionSn))) {
          throw new Error();
        }
        if (Number(positionSn) === 0) {
          return;
        }
        setLoading(true);
        setCmsYn(false);
        setAtsJdYn(false);
        setJobflexJdYn(false);
        await positionJdModel.init(Number(positionSn));
        await positionJdModel.loadPositionDetail(Number(positionSn));
        checkCompanySn(positionJdModel.companyInfo?.companySn || 0);
        await positionJdModel.loadMatchingCompanyList(Number(positionSn));
        await positionJdModel.loadMatchingJobList(Number(positionSn));
        await positionJdModel.loadMatchingChallengeList(Number(positionSn));
        if (positionJdModel.basicInfo?.jobDescription) {
          setFullDocument(iframePinchZoomUtil(positionJdModel.basicInfo.jobDescription));
        }

        setLoading(false);
        if (positionJdModel.additionalInfo?.challenge) {
          positionJdModel.challengeTimeType = getBetweenTime(positionJdModel.additionalInfo.challenge.postStartDateTime, positionJdModel.additionalInfo.challenge.postEndDateTime, positionJdModel.additionalInfo.challenge.endYn);
        } else {
          positionJdModel.challengeTimeType = TimeType.BEFORE;
        }
        if (positionJdModel.basicInfo?.applyType === PositionApplyType.CMS) setCmsYn(true);
        if (positionJdModel.basicInfo?.applyType === PositionApplyType.INSIGHT) setJobflexJdYn(true);
        if (positionJdModel.basicInfo?.applyType === PositionApplyType.ATS) setAtsJdYn(true);
      } catch (e) {
        history.push(RoutePaths.error);
      }

      if (positionJdModel.basicInfo?.address) {
        await positionJdModel.getLocation();
        KakaoMapScript(positionJdModel.locationX, positionJdModel.locationY, positionJdModel.basicInfo?.address);
      }
    };

    init();
    return () => {
      positionJdModel.terminate();
    };
  }, [positionSn]);

  return (
    <>
      {loading ? <Loading />
        : (
          <>
            {PositionJDMetaTag(
              positionJdModel.companyInfo?.companyName,
              positionJdModel.basicInfo?.positionName,
              RecruitmentTypeText[positionJdModel.basicInfo?.recruitmentType || 'ANY'],
              positionJdModel.additionalInfo?.jobTitleNames[0],
              getPositionDday(positionJdModel.basicInfo?.closingDateTime),
            )}
            <Frame nonBottomPadding={basicInfo?.externalYn === true || !positionJdModel.companyInfo?.openYn}>
              <div className='frame-top'>
                <PositionJdCompanyInfo isWinBack={isWinBack} />
              </div>
              {(!jobflexJdYn && !cmsYn) && <PositionJdPosterSwiper />}
              <div className='frame-top'>
                {
                  !isWinBack && <PositionJdCompanyInfoTwo />

                }
              </div>
              {!atsJdYn ? (basicInfo
                && (
                  <div className='iframe-wrap'>
                    <Divider12Grey />
                    <AutoHeightIFrame srcDoc={fullDocument} />
                  </div>
                )
              ) : (basicInfo
                && IsJsonString(basicInfo.jobDescription)
                && <PositionJdContent positionJd value={JSON.parse(basicInfo.jobDescription)} />)}
              {basicInfo?.applyType === PositionApplyType.CMS && <img src={basicInfo.jobDescriptionImageUrl} alt='cms_image' />}
              {basicInfo?.address && <PositionJdKakaoComponent />}
              {additionalInfo && additionalInfo?.welfareKeywords.length !== 0
                && <PositionJdWelfare />}
              {atsJdYn && <PositionJdEditRequest type={PositionTypeAll.ATS} />}
              {cmsYn && <PositionJdEditRequest type={PositionTypeAll.CMS} />}
              {jobflexJdYn && <PositionJdEditRequest type={PositionTypeAll.INSIGHT} />}
              {positionMatchingJobList && positionMatchingJobList.length !== 0 && positionJdModel.challengeTimeType !== TimeType.ING
                && <PositionMatchingJob />}
              {positionMatchingCompanyList && positionMatchingCompanyList.length !== 0 && positionJdModel.challengeTimeType !== TimeType.ING
                && <PositionJdMatchingCompany />}
              {positionMatchingChallengeList && positionMatchingChallengeList.length !== 0 && positionJdModel.challengeTimeType === TimeType.ING
                && <PositionJdMatchingChallenge />}
            </Frame>
            <PositionJdApply />
            <JDScrollToTopButton />
          </>
        )}
    </>
  );
};

export default inject(injectStore.positionJdModel)(observer(PositionJd));
