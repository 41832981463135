import img404 from 'assets/img/img_error_404.svg';
import LazyImage from 'components/common/LazyImage';
import ErrorFrame, { ErrorCTAButton } from 'components/error/ErrorFrame';
import RoutePaths from 'consts/RoutePaths';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

const Error404:FC = () => {
  const history = useHistory();
  return (
    <ErrorFrame>
      <div className='frame-main'>
        <LazyImage className='img-error' src={img404} alt='404' />
        <div className='title'>
          {`요청하신 페이지를\n찾을 수 없어요`}
        </div>
        <div className='desc'>
          {`존재하지 않거나 사용할 수 없는 페이지입니다.\n입력하신 주소를 다시 확인해 주세요.`}
        </div>
      </div>
      <ErrorCTAButton onClick={() => history.push(RoutePaths.home)}>
        홈으로 가기
      </ErrorCTAButton>
    </ErrorFrame>
  );
};

export default Error404;
