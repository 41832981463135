import { FormState } from 'react-hook-form';
import { useEffect } from 'react';
import store from 'models/store';

// FormState를 체크해서
// observable action에 연결해주는 hook
const useFormDirtyObserver = (formState:FormState<any>, updateObservableAction:(isDirty:boolean) => void, defaultIsDirty:boolean = false) => {
  useEffect(() => {
    updateObservableAction(formState.isDirty);
  }, [formState.isDirty, updateObservableAction]);
  useEffect(() => {
    updateObservableAction(defaultIsDirty);
  }, [defaultIsDirty, updateObservableAction]);
};

export const useProfileFormDirtyObserver = (formState:FormState<any>, defaultIsDirty:boolean = false) => useFormDirtyObserver(formState, store.profileModel.setIsDirty, defaultIsDirty);

export default useFormDirtyObserver;
