import { ReactComponent as ImgLocation } from 'assets/_v2/positionJd/icon_location.svg';
import { LazyImageLogo } from 'components/common/LazyImage';
import { LocationCode } from 'consts/CompanyLocationType';
import RoutePaths from 'consts/RoutePaths';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { lineClampBox } from 'consts/_v2/_common/style/mixins';
import { ISearchCompany } from 'interfaces/_v2/search/IGlobalSearch';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import GlobalSearchModel from 'models/_v2/GlobalSearchModel';
import { IGlobalSearchResult } from 'pages/_v2/GlobalSearchResult';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';

const Frame = styled.div`
  padding: 24px 16px;
  > :not(:last-child) {
    margin-bottom: 8px;
  }
`;

const CompanyCard = styled.div<{ position?: number }>`
  width: 100%;
  height: 88px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 16px;
  background: ${colors.WHITE_100};
  border: 1px solid ${colors.CG_40};
  border-radius: 6px;
  > div {
    display: flex;
    align-items: center;
  }
  .img {
    flex: none;
    width: 48px;
    height: 48px;
  }
  .info {
    margin: 0px 10px;
    display: flex;
    flex-direction: column;
 
    h1 {
      font: ${Fonts.B2_Bold};
      color: ${colors.JOBDA_BLACK};
      width: 180px;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
   
  
    @media ( max-width: 420px ) {
      h1 { 
        width:130px;
   
       }
    }
     @media ( max-width: 400px ) {
      h1 { 
        width:120px;
   
       }
    }
    > div {
      display: flex;
      flex-direction: row;
      > span {
        display: flex;
        align-items: center;
        color: ${colors.CG_60};
        font: ${Fonts.B3_Medium};
        > svg {
          margin: 0px 5px 0px 16px;
        }
      }
      margin-bottom: 4px;
    }
    h2 {
      ${lineClampBox(1)};
      color: ${colors.CG_60};
      font: ${Fonts.B3_Medium};
    }
  }

  .position {
    min-width: 60px;
    min-height: 48px;
    max-width: 60px;
    max-height: 48px;

    border-radius: 4px;
    display: flex;
    text-align: center;
    align-items: center;
    font: ${Fonts.B4_Medium};
    ${(props) => (props.position !== 0
    ? css`
            background: ${colors.CG_90};
            color: ${colors.WHITE_100};
          `
    : css`
            border: 1px solid rgba(68, 71, 75, 0.2);
            color: ${colors.CG_70};
          `)}
  }
`;

const AllViewButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 12px 0;
  font: ${Fonts.B3_Bold};
  color: ${colors.JOBDA_BLACK};
  border: 1px solid ${colors.JOBDA_BLACK};
  border-radius: 4px;
  margin-top: 18px;
`;

const LIMIT_POSITION_COUNT = 3;

const GlobalSearchCompany: FC<IGlobalSearchResult> = ({ globalSearchModel = new GlobalSearchModel() }) => {
  const [isAllViewOpen, setIsAllViewOpen] = useState<boolean>(false);
  const { companies } = globalSearchModel;
  const history = useHistory();

  return (
    <Frame>
      {companies?.map((item: ISearchCompany, index) => {
        if (isAllViewOpen || index < LIMIT_POSITION_COUNT)
          return (
            <CompanyCard
              onClick={() => history.push(RoutePaths.company_details(item.sn))}
              key={item.sn}
              position={item.numberOfPositions}
            >
              <div>
                <LazyImageLogo src={item.logoImageUrl} className='img' />
                <div className='info'>
                  <div>
                    <h1>{item.name}</h1>
                    <span>
                      <ImgLocation width={13} height={13} />
                      {LocationCode(item.locationCode)}
                    </span>
                  </div>
                  <h2>{item.vision}</h2>
                </div>
              </div>
              <div className='position'>
                {item.numberOfPositions}개 포지션 채용중
              </div>
            </CompanyCard>
          );
      })}
      {companies && companies?.length > LIMIT_POSITION_COUNT && (
        <AllViewButton onClick={() => setIsAllViewOpen(!isAllViewOpen)}>
          {isAllViewOpen
            ? '접기'
            : `${companies?.length}개 기업 검색 결과 모두보기`}
        </AllViewButton>
      )}
    </Frame>
  );
};

export default inject(injectStore.globalSearchModel)(
  observer(GlobalSearchCompany),
);
