import URIs from 'consts/URIs';
import { IEventParticipationStatusRs } from 'interfaces/_v2/IEventChallenge';
import { action, observable } from 'mobx';
import { request } from 'utils/request';

export default class EventProfileModel {
  @observable eventParticipationStatus:IEventParticipationStatusRs | null = null;

  @action
  loadEventsParticipationStatus = async (eventType:string) => {
    try {
      this.eventParticipationStatus = (await request<IEventParticipationStatusRs>({
        method: 'get',
        url: URIs.get_events_participation_status,
        params: { eventType },
      }));
    } catch (e) {
      console.error(e);
    }
  }

    @action
    setEventProfileApply = async () => {
      try {
        const res = await request({
          method: 'post',
          url: URIs.post_events_profile_apply,
        });
        return res;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }
}
