/* eslint-disable array-callback-return */
import imgNoJoy from 'assets/_v2/alarm/icon_joy_no_alarm.svg';
import AccPstGrade from 'components/_v2/acc/AccPstGrade';
import AccResultModal from 'components/_v2/acc/AccResultModal';
import MessageAccPhsSubmitModal from 'components/_v2/message/MessageAccPhsSubmitModal';
import PstResultModal from 'components/_v2/phs/pst/PstResultModal';
import { StyledSelectModalFrame } from 'components/_v2/position/filter/PositionJobTitleFilter';
import JDRadioInput, { JD_RADIO_INPUT_TYPE } from 'components/_v2/_common/input/JDRadioInput';
import useToast from 'hooks/useToast';
import JDAtsFailModal from 'components/_v2/_common/modals/JDAtsFailModal';
import JDUnderBaseModal from 'components/_v2/_common/modals/JDUnderBaseModal';
import { MatchAccAnalysisType, MatchPHSAnalysisType } from 'consts/MatchAnalysisType';
import { PhsAccType, PhsAccTypeStr } from 'consts/MatchingMessageType';
import colors from '__designkit__/common/colors';
import { AccGradeResultType } from 'consts/_v2/acc/AccApplyType';
import { PstGradeResultType } from 'consts/_v2/phs/pst/PstApplyType';
import Fonts from '__designkit__/common/fonts';
import { IMatchMessageProps } from 'interfaces/IMatchMessageProps';
import IModalFrameProps from 'interfaces/IModalFrameProps';
import { IAccProgressCompleteDto } from 'interfaces/_v2/acc/IAccProgressCompleteRs';
import { inject, observer } from 'mobx-react';
import MatchMessageStore from 'models/MatchMessageStore';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { debounce } from 'utils/CommonUtils';
import { DateFormatYYYYMMDDHHMMWithDot } from 'utils/DateUtils';

const NoImgFrame = styled.div`
  padding-top:102px;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img{
    width:100px;
    margin-bottom:32px;
  }

  h1{
    text-align: center;
    font: ${Fonts.H5_Bold};
    line-height: 30px;
    color:${colors.JOBDA_BLACK};
  }

  h2 {
    text-align: center;
    font: ${Fonts.B2_Medium};
    color: ${colors.CG_70};
  }
`;

const ListFrame = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  

  .li-frame {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;

    >div {
      display: flex;
      align-items: center;
    }

    .date {
      font: ${Fonts.B3_Medium};
      line-height: 16px;
      color: ${colors.JOBDA_BLACK};
    }

    .slider {
      width: 1px;
      height: 12px;
      background-color: ${colors.CG_40};
      margin: 0 10px;
    }

    .result-btn {
      font: ${Fonts.B3_Medium};
      line-height: 16px;
      color: ${colors.CG_80};
      text-align: center;
      text-decoration: underline;

      &:disabled {
        opacity: 0.5;
      }
    }
  }
`;

const NoImgComponent:FC<{ type: string }> = ({ type }) => (
  <NoImgFrame>
    <img src={imgNoJoy} alt='no-joy' />
    <h1>{type} 결과가 없습니다.</h1>
    <h2>*{type} 응시는 PC에서만 가능합니다.</h2>
  </NoImgFrame>
);

interface IMessageAccPhsUnderModal extends IModalFrameProps {
  submitType: PhsAccType;
  optionSn: number;
  positionSn: number;
  companySn?:number;
}

const MessageAccPhsUnderModal:FC<IMatchMessageProps & IMessageAccPhsUnderModal> = ({ isOpen, onClickClose, positionSn, optionSn, submitType, matchMessageStore = new MatchMessageStore(), companySn }) => {
  const useformed = useForm({ mode: 'onSubmit' });
  const { watch, setValue } = useformed;
  const [isOpenSubmitModal, setIsOpenSubmitModal] = useState<boolean>(false);
  const [selectedAccGrade, setSelectedAccGrade] = useState<AccGradeResultType>(AccGradeResultType.CHEER_UP);
  const [selectedPstGrade, setSelectedPstGrade] = useState<PstGradeResultType | undefined>(undefined);
  const [progressYn, setProgressYn] = useState<boolean>(false);
  const [selectedTime, setSelectedTime] = useState('');
  const [isOpenAccResultModal, setIsOpenAccResultModal] = useState<boolean>(false);
  const [isOpenPstResultModal, setIsOpenPstResultModal] = useState<boolean>(false);
  const [selectedResultSn, setSelectedResultSn] = useState(0);
  const [selectedResultItem, setSelectedResultItem] = useState<IAccProgressCompleteDto | undefined>(undefined);

  const [atsFailModalOpen, setAtsFailModalOpen] = useState<boolean>(false);
  const { setToastObject } = useToast();
  const { accProgressList, paccProgressList, pstProgressList } = matchMessageStore;

  // TODO: 고도화 해야됨. (sn만 받는게 아니라 객체로 받아서)
  const getSelectedGrade = () => {
    switch (submitType) {
      case PhsAccType.ACC: {
        const selectedItem = matchMessageStore.accProgressList?.find((item) => item.sn === Number(watch('applySn')));
        if (selectedItem?.resultType) setSelectedAccGrade(selectedItem?.resultType);
        break;
      }
      case PhsAccType.PACC: {
        const selectedItem = matchMessageStore.paccProgressList?.find((item) => item.sn === Number(watch('applySn')));
        if (selectedItem?.resultType) setSelectedAccGrade(selectedItem?.resultType);
        break;
      }
      case PhsAccType.PST: {
        const selectedItem = matchMessageStore.pstProgressList?.find((item) => item.sn === Number(watch('applySn')));
        if (selectedItem?.grade) setSelectedPstGrade(selectedItem?.grade);
        break;
      }
      default:
    }
  };

  const getSelectedTimeAndProgressYn = () => {
    switch (submitType) {
      case PhsAccType.ACC: {
        const selectedItem = matchMessageStore.accProgressList?.find((item) => item.sn === Number(watch('applySn')));
        setProgressYn(selectedItem?.analysisType === MatchAccAnalysisType.PROGRESS);
        return selectedItem?.applyEndDateTime ? selectedItem?.applyEndDateTime : '';
      }
      case PhsAccType.PACC: {
        const selectedItem = matchMessageStore.paccProgressList?.find((item) => item.sn === Number(watch('applySn')));
        setProgressYn(selectedItem?.analysisType === MatchPHSAnalysisType.IN_PROGRESS);
        return selectedItem?.applyEndDateTime ? selectedItem?.applyEndDateTime : '';
      }
      case PhsAccType.PST: {
        const selectedItem = matchMessageStore.pstProgressList?.find((item) => item.sn === Number(watch('applySn')));
        setProgressYn(selectedItem?.analysisType === MatchPHSAnalysisType.IN_PROGRESS);
        return selectedItem?.applyEndDateTime ? selectedItem?.applyEndDateTime : '';
      }
      default:
        return '';
    }
  };

  const submitResult = async () => {
    setIsOpenSubmitModal(false);
    try {
      debounce(async () => {
        if (optionSn) {
          await matchMessageStore.saveSubmitApply(positionSn, optionSn, matchMessageStore.selectedApplySn);
        }
        await matchMessageStore.loadMessageList(positionSn);
        setToastObject({ isOpen: true, message: '정말 수고하셨어요! 제출된 결과를 인사담당자님에게 바로 전달해 드릴게요.', type: 'INFO' });
      }, 800);
    } catch (e: any) {
      if (e.response.data.errorCode === 'B908') {
        setAtsFailModalOpen(true);
      } else if (e.response.data.errorCode === 'B907') {
        setToastObject({ isOpen: true, message: '이미 제출한 응시 결과입니다.', type: 'ERROR' });
      } else {
        setToastObject({ isOpen: true, message: '제출에 실패했습니다.', type: 'ERROR' });
      }
    }
  };

  const [accExist, setAccExist] = useState<boolean>(true);
  const [paccExist, setPaccExist] = useState<boolean>(true);
  const [pstExist, setPstExist] = useState<boolean>(true);

  useEffect(() => {
    if (matchMessageStore.accProgressList) {
      let cnt = 0;
      if (matchMessageStore.accProgressList.length > 0) {
        matchMessageStore.accProgressList.map((data) => {
          if (data.analysisType === MatchAccAnalysisType.FAILED) {
            cnt += 1;
          }
        });
        if (cnt === matchMessageStore.accProgressList.length) {
          setAccExist(false);
        }
      }
    }
    if (matchMessageStore.paccProgressList) {
      let cnt = 0;
      if (matchMessageStore.paccProgressList.length > 0) {
        matchMessageStore.paccProgressList.map((data) => {
          if (data.analysisType === MatchPHSAnalysisType.FAILED) {
            cnt += 1;
          }
        });
        if (cnt === matchMessageStore.paccProgressList.length) {
          setPaccExist(false);
        }
      }
    }
    if (matchMessageStore.pstProgressList) {
      let cnt = 0;
      if (matchMessageStore.pstProgressList.length > 0) {
        matchMessageStore.pstProgressList.map((data) => {
          if (data.analysisType === MatchPHSAnalysisType.FAILED) {
            cnt += 1;
          }
        });
        if (cnt === matchMessageStore.pstProgressList.length) {
          setPstExist(false);
        }
      }
    }
  }, [matchMessageStore.accProgressList, matchMessageStore.paccProgressList, matchMessageStore.pstProgressList]);

  return (
    <>
      <JDUnderBaseModal isOpen={isOpen} onClickClose={() => onClickClose && onClickClose()}>
        <FormProvider {...useformed}>
          <StyledSelectModalFrame>
            <div className='title'>응시 결과 선택</div>
            <div className='content-frame'>
              {/* ACC */}
              { submitType === PhsAccType.ACC
                ? accProgressList && accProgressList.length > 0 && accExist
                  ? (
                    <ListFrame>
                      { accProgressList.map((item) => (
                        item.analysisType !== MatchAccAnalysisType.FAILED
                        && (
                        <li className='li-frame'>
                          <div>
                            <JDRadioInput
                              key={`radio-${item.code}`}
                              type={JD_RADIO_INPUT_TYPE.ROUND_CIRCLE_BOX}
                              name='applySn'
                              value={item.sn}
                              // defaultValue={accProgressList[0].sn}
                              label={(<div />)}
                            />
                            <span className='date'>{DateFormatYYYYMMDDHHMMWithDot(item.applyEndDateTime)}</span>
                            <div className='slider' />
                            <AccPstGrade
                              accGrade={item.resultType}
                              type={submitType}
                              progressYn={item.analysisType === MatchAccAnalysisType.PROGRESS}
                            />
                          </div>
                          <div>
                            <button
                              className='result-btn'
                              disabled={item.analysisType !== MatchAccAnalysisType.COMPLETE}
                              onClick={() => {
                                setSelectedResultSn(item.sn);
                                setSelectedResultItem(item);
                                setIsOpenAccResultModal(true);
                              }}
                            >결과표 보기
                            </button>
                          </div>
                        </li>
                        )
                      ))}
                    </ListFrame>
                  )
                  : (
                    <NoImgComponent
                      type={PhsAccTypeStr[submitType]}
                    />
                  ) : <></>}
              {/* PACC */}
              { submitType === PhsAccType.PACC
                ? paccProgressList && paccProgressList.length > 0 && paccExist
                  ? (
                    <ListFrame>
                      { paccProgressList.map((item) => (
                        item.analysisType !== MatchPHSAnalysisType.FAILED
                        && (
                        <li className='li-frame'>
                          <div>
                            <JDRadioInput
                              key={`radio-${item.code}`}
                              type={JD_RADIO_INPUT_TYPE.ROUND_CIRCLE_BOX}
                              name='applySn'
                              value={item.sn}
                              // defaultValue={paccProgressList[0].sn}
                              label={(<div />)}
                            />
                            <span className='date'>{DateFormatYYYYMMDDHHMMWithDot(item.applyEndDateTime)}</span>
                            <div className='slider' />
                            <AccPstGrade
                              accGrade={item.resultType}
                              type={submitType}
                              progressYn={item.analysisType === MatchPHSAnalysisType.IN_PROGRESS}
                            />
                          </div>
                          <div>
                            <button
                              className='result-btn'
                              disabled={item.analysisType !== MatchPHSAnalysisType.COMPLETED}
                              onClick={() => {
                                setSelectedResultSn(item.sn);
                                setIsOpenAccResultModal(true);
                              }}
                            >결과표 보기
                            </button>
                          </div>
                        </li>
                        )
                      ))}
                    </ListFrame>
                  )
                  : (
                    <NoImgComponent
                      type={PhsAccTypeStr[submitType]}
                    />
                  ) : <></>}

              {/* PST */}
              { submitType === PhsAccType.PST
                ? pstProgressList && pstProgressList.length > 0 && pstExist
                  ? (
                    <ListFrame>
                      { pstProgressList.map((item) => (
                        item.analysisType !== MatchPHSAnalysisType.FAILED
                        && (
                        <li className='li-frame'>
                          <div>
                            <JDRadioInput
                              key={`radio-${item.code}`}
                              type={JD_RADIO_INPUT_TYPE.ROUND_CIRCLE_BOX}
                              name='applySn'
                              value={item.sn}
                              // defaultValue={pstProgressList[0].sn}
                              label={(<div />)}
                            />
                            <span className='date'>{DateFormatYYYYMMDDHHMMWithDot(item.applyEndDateTime)}</span>
                            <div className='slider' />
                            <AccPstGrade
                              pstGrade={item.grade}
                              type={submitType}
                              progressYn={item.analysisType === MatchPHSAnalysisType.IN_PROGRESS}
                            />
                          </div>
                          <div>
                            <button
                              className='result-btn'
                              disabled={item.analysisType !== MatchPHSAnalysisType.COMPLETED}
                              onClick={() => {
                                setSelectedResultSn(item.sn);
                                setIsOpenPstResultModal(true);
                              }}
                            >결과표 보기
                            </button>
                          </div>
                        </li>
                        )
                      ))}
                    </ListFrame>
                  )
                  : (
                    <NoImgComponent
                      type={PhsAccTypeStr[submitType]}
                    />
                  ) : <></>}

            </div>
            <div className='btn-frame'>
              <button
                className='btn-select'
                disabled={!watch('applySn')}
                onClick={() => {
                  if (!watch('applySn')) {
                    if (submitType === PhsAccType.ACC && matchMessageStore.accProgressList && matchMessageStore.accProgressList.length > 0) {
                      matchMessageStore.selectedApplySn = matchMessageStore.accProgressList[0].sn;
                      setValue('applySn', matchMessageStore.accProgressList[0].sn);
                    }

                    if (submitType === PhsAccType.PACC && matchMessageStore.paccProgressList && matchMessageStore.paccProgressList.length > 0) {
                      matchMessageStore.selectedApplySn = matchMessageStore.paccProgressList[0].sn;
                      setValue('applySn', matchMessageStore.paccProgressList[0].sn);
                    }

                    if (submitType === PhsAccType.PST && matchMessageStore.pstProgressList && matchMessageStore.pstProgressList.length > 0) {
                      matchMessageStore.selectedApplySn = matchMessageStore.pstProgressList[0].sn;
                      setValue('applySn', matchMessageStore.pstProgressList[0].sn);
                    }
                  } else {
                    matchMessageStore.selectedApplySn = watch('applySn');
                  }
                  getSelectedGrade();
                  setSelectedTime(getSelectedTimeAndProgressYn());
                  setIsOpenSubmitModal(true);
                  if (onClickClose) onClickClose();
                }}
              >선택 완료
              </button>
            </div>
          </StyledSelectModalFrame>
        </FormProvider>
      </JDUnderBaseModal>
      <MessageAccPhsSubmitModal
        isOpen={isOpenSubmitModal}
        onClickClose={() => setIsOpenSubmitModal(false)}
        onBack={() => {
          setIsOpenSubmitModal(false);
        }}
        onSuccess={() => submitResult()}
        submitType={submitType}
        accGrade={selectedAccGrade}
        pstGrade={selectedPstGrade}
        time={DateFormatYYYYMMDDHHMMWithDot(selectedTime)}
        progressYn={progressYn}
        companySn={companySn}
      />
      <AccResultModal
        isOpen={isOpenAccResultModal}
        onClose={() => setIsOpenAccResultModal(false)}
        sn={selectedResultSn}
        item={selectedResultItem}
        type={submitType}
      />
      <PstResultModal
        isOpen={isOpenPstResultModal}
        onClose={() => setIsOpenPstResultModal(false)}
        sn={selectedResultSn}
        type={submitType}
      />
      <JDAtsFailModal
        isOpen={atsFailModalOpen}
        onClickClose={() => setAtsFailModalOpen(false)}
      />
    </>
  );
};

export default inject(injectStore.matchMessageStore)(observer(MessageAccPhsUnderModal));
