import LazyImage from 'components/common/LazyImage';
import colors from '__designkit__/common/colors';
import { fonts, lineHeights } from '__designkit__/common/fonts';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { FC } from 'react';
import styled from 'styled-components';

export type AiGateCardType = 'typeA' | 'typeB' | 'typeC' | 'typeD';
enum AiGateCardPosition {
  mainBackground,
  mainBorder,
  subTitle,
  title,
  innerText,
  innerEmText,
  btnBackground,
  btnBorder,
}

const AiGateCardColor: Record<AiGateCardType, string[]> = {
  typeA: [colors.cC3F2CD, colors.cA2EBB4, colors.c3C8E42, colors.c377538, colors.G_200, colors.G_90, colors.G_100, colors.G_200],
  typeB: [colors.cE7FCC6, colors.cDCFAAD, colors.c79A837, colors.c668A33, colors.cA4D95A, colors.cD0F894, colors.cBFEF7B, colors.cA4D95A],
  typeC: [colors.cFEFCCB, colors.cFEF8B1, colors.c938A29, colors.c787120, colors.Y_300, colors.cFDF69E, colors.Y_100, colors.cE7DA4B],
  typeD: [colors.cD1F5F3, colors.cBBEFED, colors.c409390, colors.c3A7977, colors.cD1F5F3, colors.cAFECEA, colors.cC5F1EF, colors.cC5F1EF],
};
const Frame = styled.div`
  width:100%;
  border-radius: 32px;
  background:${(props) => (props as any)['data-bg']};
  border: 2px solid ${(props) => (props as any)['data-border']};
  padding-left:32px;
  padding-right:32px;
  padding-bottom:32px;
  .icon-joy{
    margin-top:32px;
    height:96px;
  }
  h2{
    margin-top:24px;
    ${fonts.NOTO_15_500};
    ${lineHeights.LINEHEIGHT_1_50};
    color:${(props) => (props as any)['data-subTitle']};
  }
  h1{
    ${fonts.NOTO_24_700};
    ${lineHeights.LINEHEIGHT_1_50};
    color:${(props) => (props as any)['data-title']};
    >em{
      ${fonts.WEMAKEP_24_700};
      display: inline-block;
      transform: translateY(-0.05em);
    }
  }
  
  .children-frame{
    margin-top:24px;
    display: flex;
    flex-direction: column;
    color:${(props) => (props as any)['data-title']};

    h3{
      ${fonts.NOTO_11_700};
      margin-bottom: 8px;
    }
    >div{
      display: flex;
      align-items: baseline;
    }
    div{
      ${fonts.NOTO_11_400};
      ${lineHeights.LINEHEIGHT_1_50};
    }
    span{
      margin-left:3px;
      margin-right:6px;
    }
    em{
       background-attachment: local;
       background-image: repeating-linear-gradient(${(props) => (props as any)['data-innerEmText']}, ${(props) => (props as any)['data-innerEmText']});
       background-position-y:9px;
       background-repeat: no-repeat;
    }
  }

  button{
    margin-top:24px;
    width: 100%;
    padding-top:11px;
    padding-bottom:11px;
    border-radius: 32px;
    border:2px solid ${(props) => (props as any)['data-btnBorder']};
    background: ${(props) => (props as any)['data-btnBackground']};
    color:${(props) => (props as any)['data-title']};
    ${fonts.NOTO_15_700};
  }


`;

interface IAiGateCardProps extends IComponentProps {
  type: AiGateCardType;
  img: string;
  subTitle: string;
  title: JSX.Element;
  btnMsg: string;
  onClickBtn: () => void;
}

const AiGateCard: FC<IAiGateCardProps> = ({ id, type, img, subTitle, title, children, btnMsg, onClickBtn }) => (
  <Frame
    id={id}
    data-bg={AiGateCardColor[type][AiGateCardPosition.mainBackground]}
    data-border={AiGateCardColor[type][AiGateCardPosition.mainBorder]}
    data-subTitle={AiGateCardColor[type][AiGateCardPosition.subTitle]}
    data-title={AiGateCardColor[type][AiGateCardPosition.title]}
    data-innerText={AiGateCardColor[type][AiGateCardPosition.innerText]}
    data-innerEmText={AiGateCardColor[type][AiGateCardPosition.innerEmText]}
    data-btnBackground={AiGateCardColor[type][AiGateCardPosition.btnBackground]}
    data-btnBorder={AiGateCardColor[type][AiGateCardPosition.btnBorder]}
  >
    <LazyImage className='icon-joy' src={img} />
    <h2>{subTitle}</h2>
    {title}
    <div className='children-frame'>
      <h3>CHECK POINT</h3>
      {children}
    </div>
    <button onClick={onClickBtn}>
      {btnMsg}
    </button>
  </Frame>
);

export default AiGateCard;
