import { yupResolver } from '@hookform/resolvers/yup';
import useToast from 'hooks/useToast';
import JDClosableChip from 'components/_v2/_common/chip/JDClosableChip';
import FormProfileValueTitle from 'components/_v2/_common/form/FormProfileValueTitle';
import JDSearchInput from 'components/_v2/_common/input/JDSearchInput';
import JDSelector, { JDSelectorType } from 'components/_v2/_common/input/JDSelector';
import JDAPopover from 'components/_v2/_common/popover/JDPopover';
import { IconInfoCircleDarkGrey, IconPlus24 } from 'consts/assets/icons/iconPages';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { ISkillDto } from 'interfaces/rqrs/ISkillListRs';
import { IProfileSkillDto, SkillLevel, SkillLevelText } from 'interfaces/_v2/profile/IProfileKnowledgeAndSkillsRs';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import ProfileProjectModel from 'models/_v2/profile/ProfileProjectModel';

import React, { FC, useState } from 'react';
import { FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { debounce } from 'utils/CommonUtils';
import * as yup from 'yup';
import { IProfileProjectView } from './ProfileProjectView';

const Frame = styled.div`

    .title-frame {
        display: flex;
        justify-content: space-between;

        .a-proposal {
            display:block;
            width:fit-content;
            font: ${Fonts.B2_Medium};
            text-decoration-line:underline;
            color:${colors.B_100};
        }

        .title-left {
            display: flex;
            .anchor-frame {
                display: flex;
                align-items: center;
                margin-left: 4px;
            }
        }
    }

    .row-select-confirm {
        margin-top: 12px;
    }

    .frame-chips {
      display: flex;
      flex-wrap: wrap;
      margin-top: 16px;

      >div {
        margin-right: 8px;
        margin-bottom: 8px;
      }
    }

    .tip {
        margin-top: 40px;
        font: ${Fonts.B1_Medium};
        color: ${colors.CG_60};
    }
`;

const PopOverFrame = styled.abbr`
  display:block;
  width: 304px;
  color:${colors.WHITE_100};

  >div {
    margin-bottom: 16px;
  }

  h1 {
    font: ${Fonts.B3_Bold};
    line-height: 20px;
    margin-bottom: 8px;
  }

  h2 {
    font: ${Fonts.B3_Medium};
    line-height: 16px;
    color:${colors.CG_50};
  }

  ul li {
    margin-bottom: 2px;
  }
`;
const RoundChipFrame = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 16px;
`;

const RoundChip = styled.div`
    font: ${Fonts.B3_Medium};
    line-height: 16px;
    border: 1px solid ${colors.CG_40};
    border-radius: 20px;
    padding: 8px 12px;
    margin-right: 8px;
    margin-bottom: 8px;
`;

const RecommendCategoryFrame = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  h1 {
    font: ${Fonts.B2_Bold};
    color: ${colors.JOBDA_BLACK};
    margin-bottom: 12px;
  }

  .skill-frame {
    display: flex;
    flex-wrap: wrap;
  }
`;

const skillValidationSchema = yup.object().shape({
  code: yup.number().required(),
  keyword: yup.string().required(),
  level: yup.string().required(),
});

interface IProfileProjectKeyword extends IProfileProjectView{
  name:string
}

const ProfileProjectKeyword:FC<IProfileProjectKeyword> = ({ profileProjectModel = new ProfileProjectModel(), name }) => {
  const useFormed = useFormContext();
  const { watch, setValue, control, register } = useFormed;
  const { setToastObject } = useToast();
  const [inputText, setInputText] = useState<string>('');
  const [selectRecommend, setSelectRecommend] = useState<ISkillDto>();
  const [selectRecommendPrev, setSelectRecommendPrev] = useState<ISkillDto>();
  const skillFields = useFieldArray<IProfileSkillDto>({ control, name });
  const skillFormMethod = useForm<IProfileSkillDto>({ mode: 'all', resolver: yupResolver(skillValidationSchema) });

  const [searchSkill, setSearchSkill] = useState<any>([]);
  const [removeInput, setRemoveInput] = useState<boolean>(false);
  // 중복이 있다면 return : true
  const checkDuplication = (value: IProfileSkillDto) => {
    if (!value.code) return true;
    const found = skillFields.fields.some((data) => Number(data.code) === Number(value.code));
    return found;
  };
  const findRecommendList = async () => {
    if (selectRecommend) {
      await profileProjectModel.loadRecommendSkills(selectRecommend.code);
    }
  };

  return (
    <>
      <Frame>
        <div className='title-frame'>
          <div className='title-left'>
            <FormProfileValueTitle>활용 기술</FormProfileValueTitle>
            <JDAPopover popoverWidth={308} useOutsideClick position='non_pony_under_left' popoverMargin={0} anchorIcon={<IconInfoCircleDarkGrey className='icon-anchor' />}>
              <PopOverFrame>
                <div>
                  <h1>활용 기술이란?</h1>
                  <h2>프로젝트에서 활용한 전문 스킬 또는 툴을 의미합니다.</h2>
                </div>
                <div>
                  <h1>활용 기술의 숙련도는?</h1>
                  <h2>• 기초 : 대학교에서 수업을 통해 지식을 학습한 수준</h2>
                  <h2>• 초급 : 아르바이트/인턴을 통해 업무를 하였거나 활용한 수준</h2>
                  <h2>• 중급 : 1년 미만 업무에 적용한 경험이 있거나, 실무에 활용 가능한 수준</h2>
                  <h2>• 고급 : 1~3년 동안 업무에 적용한 경험이 있거나, 큰 도움 없이 활용 가능한 수준</h2>
                  <h2>• 전문가 : 3년 이상 업무에 적용한 경험이 있거나, 자유자재로 다룰 수 있는 수준</h2>
                </div>
              </PopOverFrame>
            </JDAPopover>
          </div>
          <a className='a-proposal' href='https://docs.google.com/forms/d/1TXJVG4fDhWXFr_1D70oFBf2XBuV3Q9ygDJBDfPo7h9s/edit' target='_blank' rel='noopener noreferrer'>기술 키워드 요청하기</a>
        </div>
        <JDSearchInput
          className='input-search'
          textName='keyword'
          codeName='code'
          name='skillIdx'
          title='기술 키워드 '
          removeInputValue={removeInput}
          placeholder='기술 키워드를 검색해 주세요.'
          onSearch={async (searchText: string) => {
            setRemoveInput(false);

            debounce(async () => {
              await profileProjectModel.searchSkills(searchText);
              await setSearchSkill(toJS(profileProjectModel.searchedSkills));
            }, 400);
          }}
          searchList={[...searchSkill]}
          listItemIcon={<IconPlus24 />}
          onSelectItem={async () => {
            const selectKeyWord = watch('keyword');
            const selectCode = watch('code');
            skillFormMethod.setValue('code', selectCode);
            skillFormMethod.setValue('keyword', selectKeyWord);
            setInputText(selectKeyWord);
            setSelectRecommend({ code: selectCode, keyword: selectKeyWord });
            profileProjectModel.searchedSkills = [];
          }}
        />
        <input type='text' aria-hidden name='code' ref={skillFormMethod.register()} />
        <input type='text' aria-hidden name='keyword' ref={skillFormMethod.register()} />
        <FormProvider {...skillFormMethod}>
          <section className='row-select-confirm'>
            <input type='text' aria-hidden name='level' ref={skillFormMethod.register()} />
            <JDSelector
              type={JDSelectorType.NORMAL}
              selectTitle='숙련도를 선택해 주세요.'
              name='level'
              saveData={(text) => skillFormMethod.setValue('level', text)}
              disabled={inputText === ''}
              onChange={async () => {
                const value = skillFormMethod.getValues();
                if (!checkDuplication(value)) {
                  skillFields.append(value);
                  skillFormMethod.setValue('code', undefined);
                  skillFormMethod.setValue('keyword', undefined);
                  skillFormMethod.setValue('level', undefined);
                  await findRecommendList();
                  setSelectRecommendPrev(selectRecommend);
                  setValue('skillIdx', null);
                  setInputText('');
                  setValue('code', null);
                  setValue('keyword', null);
                  setValue('level', null);

                  setRemoveInput(true);
                } else {
                  setToastObject({ isOpen: true, message: '이미 등록된 기술입니다.', type: 'ERROR', position: 'middle' });
                }
              }}
            >
              <select>
                <option value='' hidden>숙련도를 선택해 주세요.</option>
                {
                    (Object.keys(SkillLevel) as Array<keyof typeof SkillLevel>).map((key) => (

                      <option
                        key={key}
                        value={key}
                      >
                        {SkillLevelText[key]}
                      </option>
                    ))
                }
              </select>
            </JDSelector>
          </section>
        </FormProvider>

        <section className='frame-chips'>
          {
              skillFields.fields.map((field, idx) => (
                <React.Fragment key={field.id}>
                  <input aria-hidden ref={register()} name={`${name}.[${idx}].keyword`} defaultValue={field.keyword} />
                  <input aria-hidden ref={register()} name={`${name}.[${idx}].code`} defaultValue={field.code} />
                  <input aria-hidden ref={register()} name={`${name}.[${idx}].level`} defaultValue={field.level} />
                  <JDClosableChip onClose={() => skillFields.remove(idx)}>{`${field.keyword || ''} - ${field.level ? SkillLevelText[field.level] : ''}`}</JDClosableChip>
                </React.Fragment>
              ))
            }
        </section>
        { profileProjectModel.recommendSkills !== undefined && profileProjectModel.recommendSkills.length > 0 && (
          <>
            <p className='tip '>Tips. {selectRecommendPrev?.keyword}와 유사한 기술 키워드를 추가해 보세요.</p>
            <RoundChipFrame>
              {profileProjectModel.recommendSkills.map((skillCategory) => (
                <RecommendCategoryFrame>
                  <h1>{skillCategory.category}</h1>
                  <div className='skill-frame'>
                    {skillCategory.skills.map((skill, index) => {
                      if (index > 20) return <></>;
                      if (skillFields.fields.some((filed) => Number(filed.code) === Number(skill.code))) return <></>;
                      return (
                        <RoundChip
                          key={skill.code}
                          onClick={() => {
                            skillFormMethod.setValue('code', skill.code);
                            skillFormMethod.setValue('keyword', skill.keyword);
                            setSelectRecommend({ code: skill.code, keyword: skill.keyword });
                            setInputText(skill.keyword);
                          }}
                        >{skill.keyword}
                        </RoundChip>
                      );
                    })}
                  </div>
                </RecommendCategoryFrame>
              ))}
            </RoundChipFrame>
          </>
        )}
      </Frame>
    </>
  );
};

export default inject(injectStore.profileProjectModel)(observer(ProfileProjectKeyword));
