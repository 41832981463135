/* eslint-disable array-callback-return */
/* eslint-disable react/no-this-in-sfc */
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import FormProfileValueTitle from 'components/_v2/_common/form/FormProfileValueTitle';
import JDDatePickerInput from 'components/_v2/_common/input/JDDatePickerInput';
import JDDescriptionInput from 'components/_v2/_common/input/JDDescriptionInput';
import JDBaseInput from 'components/_v2/_common/input/JDBaseInput';
import { BaseProfileCareerPrizeDto, BaseProfilePrizeDto } from 'interfaces/_v2/insight/IInsightResumeRqRs';
import React, { FC, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

const Frame = styled.div`
    .content {
      padding: 0 1px;
    }
 .title-text{
      font:${Fonts.H4_Bold};
      color:${Colors.JOBDA_BLACK};
      margin-bottom: 24px;
    }
    .center-institution{
      margin-bottom: 24px;
    }
    .info-date{
      margin-bottom: 24px;
    }
    .margin-date{
      margin-top: 12px;
    }
`;
interface IResumeEditPrize{
    prefixName:string;
    prizeIdx:number;
    isVisible:boolean
    prizeFields?:BaseProfileCareerPrizeDto[]
}

const ResumeEditPrize:FC<IResumeEditPrize> = ({ prefixName, prizeIdx, isVisible, prizeFields }) => {
  const { control, watch, setValue, trigger } = useFormContext();
  const topRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isVisible && topRef.current) {
      topRef.current.scrollIntoView();
    }
  }, [isVisible]);

  return (
    <Frame hidden={!isVisible} ref={topRef}>
      <div className='title-text'>
        나의 성과를 결과 중심으로<br />간결히 작성해 보세요.
      </div>
      {
        prizeFields?.map((field, index) => (
          <div hidden={index !== prizeIdx} className='content'>
            <div className='center-institution'>
              <FormProfileValueTitle required>수여 기관</FormProfileValueTitle>
              <JDBaseInput
                key={index}
                name={`${prefixName}[${index}].institution`}
                placeholder='수여 기관을 입력해 주세요.'
                maxLength={35}
                defaultValue={watch(`${prefixName}[${index}]`).institution || ''}
              />
            </div>
            <FormProfileValueTitle required>수상명</FormProfileValueTitle>
            <div className='info-date'>
              <JDBaseInput
                key={index}
                name={`${prefixName}[${index}].name`}
                placeholder='수상명을 입력해 주세요.'
                maxLength={21}
                defaultValue={watch(`${prefixName}[${index}]`).name || ''}
              />
              <div className='margin-date'>
                <JDDatePickerInput
                  key={index}
                  name={`${prefixName}[${index}].date`}
                  placeholder='연도. 월.'
                  month
                  defaultValue={watch(`${prefixName}[${index}]`).date || ''}
                />
              </div>

            </div>
            <FormProfileValueTitle>내용</FormProfileValueTitle>
            <JDDescriptionInput
              key={index}
              name={`${prefixName}[${index}].description`}
              placeholder='수여 내용 및 결과를 간단히 입력해 주세요.'
              maxLength={1000}
              height={120}
              autoFocus={false}
              defaultValue={watch(`${prefixName}[${index}]`).description || ''}
            />
          </div>
        ))
      }

    </Frame>
  );
};

export default ResumeEditPrize;
