export enum PstLevel {
    BASIC = 'BASIC',
    INTERMEDIATE = 'INTERMEDIATE',
    ADVANCED = 'ADVANCED',
    ALL = 'ALL',
}

export enum PstVersion {
  V1 = 'V1',
  V2 = 'V2',
}

export enum LanguageList{
  CPP='CPP',
  JAVA='JAVA',
  PYTHON='PYTHON',
  JAVASCRIPT='JAVASCRIPT'
}