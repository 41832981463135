import IPositionJdProps from 'interfaces/_v2/positionJd/IPositionJdProps';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import PositionJdModel from 'models/_v2/PositionJdModel';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import JDSwiper, { JDFractions, JDSwiperSlide } from 'components/_v2/_common/JDSwiper';
import { IPositionAttachFileDto } from 'interfaces/_v2/positionJd/IPositionDetailRs';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import LazyImage from 'components/common/LazyImage';
import URIs from 'consts/URIs';
import IconFoldArrow from 'assets/_v2/positionJd/icon_positionJd_fold_arrow.svg';
import { IconInfoCircleDarkGrey } from 'consts/assets/icons/iconPages';
import { TimeType } from 'utils/DateUtils';

const Frame = styled.div`
  width: 100%;
  aspect-ratio: 360/270;
`;

const PosterCardFrame = styled.div`
    position:relative;
    width:100%;
    aspect-ratio: 360/270;
    display: flex;
    flex-direction: column;
    justify-content:flex-end ;
    align-items: flex-start;

    .poster {
      width :100%;
      aspect-ratio: 360/270;
  object-fit: cover;
  object-position: center 50%;
    }

    .dimmed {
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(18, 22, 25, 0) 0%, rgba(18, 22, 25, 0) 42.71%, rgba(18, 22, 25, 0.8) 100%);

      &.folded {
        background: linear-gradient(180deg, rgba(18, 22, 25, 0) 0%, rgba(18, 22, 25, 0) 42.71%, rgba(18, 22, 25, 0.4) 100%);
      }
    } 

    .text-frame {
        position: absolute;
        left: 32px;
        bottom: 40px;
        color:${colors.WHITE_100};
        padding-right: 32px;

        h1{
          font: ${Fonts.B1_Bold};
          word-break: break-all;
        }

        h2{
          font: ${Fonts.B3_Medium};
          line-height: 18px;
          padding-top: 16px;
          word-break: break-all;
        }  
    }

    .caption-frame {
        display: flex;
        align-items: center;
        img{
            margin-left: 8px;
            vertical-align: top;
            width: 20px;
            height: 20px;
            transform: rotate(180deg);
            &.folded {
              transform: rotate(0deg);

            }
        }
    }

    .folder-frame {
      overflow: hidden;
      transition: height 0.25s;
      
      &.folded {
        height: 0px;
      }
  }
`;

const ChallengeInfo = styled.div`
  padding: 16px 32px 16px 36px;
  font: ${Fonts.B3_Medium};
  line-height: 18px;
  color: ${colors.CG_70};
  background: ${colors.JOBDA_WHITE};

  img {
    position: absolute;
    left: 12px;
    width: 16px;
    height: 16px;
  }
`;

interface IPositionJdPosterCard {
  imageInfo: IPositionAttachFileDto;
}

const PositionJdPosterCard: FC<IPositionJdPosterCard> = ({ imageInfo }) => {
  const [folded, setFolded] = useState<boolean>(true);
  const foldHandler = () => setFolded(!folded);

  return (
    <PosterCardFrame>
      <LazyImage className='poster' src={URIs.get_file_view_host(imageInfo.fileUid)} alt='test-img' />
      <div className={folded ? 'folded dimmed' : 'dimmed'}>
        <div className='text-frame'>
          <div className='caption-frame' role='button'>
            <h1>{imageInfo.caption}</h1>
            {imageInfo.description
            && (
            <span role='button' onClick={foldHandler}>
              <img alt='화살표' className={folded ? 'folded' : ''} src={IconFoldArrow} />
            </span>
            )}
          </div>
          <div className={folded ? 'folded folder-frame' : 'folder-frame'}>
            <h2>{imageInfo.description}</h2>
          </div>
        </div>
      </div>
    </PosterCardFrame>
  );
};

const PositionJdPosterSwiper: FC<IPositionJdProps> = ({ positionJdModel = new PositionJdModel() }) => {
  const images = positionJdModel.basicInfo?.images;
  const { additionalInfo } = positionJdModel;

  return (
    <>
      {images !== undefined && images?.length > 0
        && (
          <Frame>
            <JDSwiper
              autoplay={{ delay: 4000 }}
              pagination={JDFractions}
            >
              {
              images?.map((item) => (
                <JDSwiperSlide key={item.fileUrl}>
                  <PositionJdPosterCard imageInfo={item} />
                </JDSwiperSlide>
              ))
            }
            </JDSwiper>
          </Frame>
        )}
      { additionalInfo?.challenge && positionJdModel.challengeTimeType === TimeType.ING && (
      <ChallengeInfo>
        <IconInfoCircleDarkGrey />
        { additionalInfo.challenge.applicableSize
          ? `이 포지션은 ${additionalInfo.challenge.challengeName}에 포함되어 있으며, 
          동일한 챌린지 내에서 ${additionalInfo.challenge.applicableSize}개의 포지션에 지원이 가능합니다.`
          : `
           이 포지션은 ${additionalInfo.challenge.challengeName}에 포함되어 있으며,  
           동일한 챌린지의 모든 포지션에 복수지원이 가능합니다.
           `}
      </ChallengeInfo>
        )}
    </>
  );
};

export default inject(injectStore.positionJdModel)(observer(PositionJdPosterSwiper));
