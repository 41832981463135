import { ReactComponent as EmojiCrySvg } from '__designkit__/assets/Emoji/emoji_cry.svg';
import { ReactComponent as EmojiHeartSvg } from '__designkit__/assets/Emoji/emoji_heart.svg';
import { ReactComponent as EmojiNHISLogoSvg } from '__designkit__/assets/Emoji/emoji_nhis_logo.svg';
import { ReactComponent as EmojiSmileSvg } from '__designkit__/assets/Emoji/emoji_smile.svg';
import { ReactComponent as EmojiThumbSvg } from '__designkit__/assets/Emoji/emoji_thumb.svg';
import { ReactComponent as IconErrorLightSvg } from '__designkit__/assets/icon/icon_error_light.svg';
import { EmojiName } from 'interfaces/_v2/_common/emojiType';
import IComponentProps from 'interfaces/props/IComponentProps';
import React from 'react';
import styled from 'styled-components';

interface IEmojiProps extends IComponentProps {
  name: EmojiName;
  size?: 16 | 18 | 20 | 24 | 28 | 32 | 40 | 48 | 56 | 72 | 96;
}

const Emoji: React.FC<IEmojiProps> = ({ name = 'Error', size = 48, ...props }) => (
  <EmojiContainer width={size} height={size} {...props}>
    <EmojiSvg name={name} />
  </EmojiContainer>
);

const EmojiSvg = ({ name }: { name: string }) => {
  switch (name) {
    case 'cry':
      return <EmojiCrySvg viewBox='0 0 32 32' />;
    case 'heart':
      return <EmojiHeartSvg viewBox='0 0 32 32' />;
    case 'smile':
      return <EmojiSmileSvg viewBox='0 0 32 32' />;
    case 'thumb':
      return <EmojiThumbSvg viewBox='0 0 32 32' />;
    case 'nhis-logo':
      return <EmojiNHISLogoSvg viewBox='0 0 96 96' />;
    default:
      return <IconErrorLightSvg viewBox='0 0 48 48' />;
  }
};

const EmojiContainer = styled.div<{
  width?: number;
  height?: number;
}>`
  width: ${({ width }) => (width ? `${width}px` : '')};
  height: ${({ height }) => (height ? `${height}px` : '')};
  & svg {
    width: ${({ width }) => (width ? `${width}px` : '')};
    height: ${({ height }) => (height ? `${height}px` : '')};
  }
`;

export default Emoji;
