import { Button, ButtonType } from '__designkit__/button/Button';
import Icon from '__designkit__/icon/Icon';
import JDTextField from 'components/_v2/_common/input/JDTextField';
import CalendarFilterModal from 'components/_v2/calendar/CalendarFilterModal';
import Fonts from '__designkit__/common/fonts';
import Colors from '__designkit__/common/colors';
import ICalendarProps from 'interfaces/_v2/calendar/ICalendarProps';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import CalendarModel from 'models/_v2/CalendarModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { DateDataFormatYYYYMMDD, weeksKor } from 'utils/DateUtils';
import RecruitTab from './RecruitTab';

const FoldedCalenderHeaderBar = styled.div`
  padding: 12px 16px 24px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .selected-month {
    font: ${Fonts.B2_Bold};
    color: ${Colors.CG_90};
  }

  .header-right-frame {
    display: flex;
    align-items: center;
  }

  .filter-frame {
    &[aria-checked='true'] {
      .jd-button {
        span {
            position: relative;
            ::after{
            position: absolute;
            content: '';
            width: 4px;
            height: 4px;
            background: ${Colors.G_150};
            border-radius: 50%;
            top: -2px;
            right: -6px;
          }
        }
      }
    }
  }
`;

// 주간 달력 컨테이너 스타일
const CalendarContainer = styled.div`
  position: sticky;
  z-index: 100;
  transition: top 0.25s, background 0.25s;
  top: ${(props) => (!(props as any)['data-hide'] ? '57' : '0')}px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  white-space: pre;
  padding: 0 16px;
  background: ${Colors.WHITE_100};
  border-radius: 0px 0px 16px 16px;

  ::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`;

// 날짜 표시 스타일
const DateLabel = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 0 50px;
  text-align: center;
  padding: 0 8px 32px 8px;
  cursor: pointer;
  scroll-snap-align: center;

  .week-day {
    display: flex;
    justify-content: center;
    align-items: center;
    font: ${Fonts.B3_Medium};
    color: ${Colors.CG_60};
    margin-bottom: 14px;

    &[aria-selected='true'] {
      font: ${Fonts.B3_Bold};
      color: ${Colors.CG_80};
    }
  }

  .day-number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    font: ${Fonts.B3_Medium};
    color: ${Colors.CG_80};

    // 일요일인 경우 빨간색으로 변경
    &.holiday {
      color: ${Colors.ERROR};
    }

    ${(props) => props.selected
      && css`
        color: ${Colors.WHITE_100};
        background-color: ${Colors.G_150};
        border-radius: 50%;

        &.holiday {
          color: ${Colors.WHITE_100};
        }
      `}
  }
`;

const FoldedCalendar: FC<ICalendarProps> = ({ context = new Context(), calendarModel = new CalendarModel() }) => {
  const [isOpenCalendarFilter, setIsOpenCalendarFilter] = useState(false);
  const dateRefs = useRef<{ [date: string]: HTMLDivElement | null }>({});
  const useFormed = useForm({ mode: 'onChange' });
  const { watch } = useFormed;
  const calendarRef = useRef<HTMLDivElement>(null);
  const touchStartX = useRef(0);
  const touchStartY = useRef(0);
  const touchEndX = useRef(0);
  const touchEndY = useRef(0);
  // 터치 시작 시 X 좌표 저장
  const handleTouchStart = (event: any) => {
    touchStartX.current = event.touches[0].clientX;
    touchStartY.current = event.touches[0].clientY;
  };

  // 터치 종료 시 X 좌표 저장하고 스와이프 처리
  const handleTouchEnd = (event: any) => {
    touchEndX.current = event.changedTouches[0].clientX;
    touchEndY.current = event.changedTouches[0].clientY;
    handleSwipe();
  };

  // TODO: 매번 왼쪽,오른쪽에 날짜 추가해주는게 아니라 조건 추가해주기. (끝까지 갔을때만...추가한다던가)
  // 스와이프 처리
  const handleSwipe = () => {
    const differenceX = touchEndX.current - touchStartX.current;
    const differenceY = touchEndY.current - touchStartY.current;
    // 왼쪽 스와이프: 다음 날로 이동
    if (differenceX > 200) {
      handleSwipeLeft();
    }
    // 오른쪽 스와이프: 이전 날로 이동
    else if (differenceX < -200) {
      handleSwipeRight();
    }
    if (differenceY > 50) {
      calendarModel.isCalendarFolded = false;
    } else if (differenceY < -50) {
      calendarModel.isCalendarFolded = true;
    }
  };

  // 이전 날로 이동
  const handleSwipeLeft = () => {
    const newDate = new Date(calendarModel.currentDate);
    newDate.setDate(calendarModel.currentDate.getDate() - 10);
    calendarModel.currentDate = newDate;
  };

  // 다음 날로 이동
  const handleSwipeRight = () => {
    const newDate = new Date(calendarModel.currentDate);
    newDate.setDate(calendarModel.currentDate.getDate() + 10);
    calendarModel.currentDate = newDate;
  };

  // 날짜 클릭 시 선택된 날짜 변경
  const handleDateClick = (date: any) => {
    calendarModel.selectedDate = date;
    calendarModel.showDate = date;
    scrollToSelectedDate(date);
  };

  // 중앙 이동
  const scrollToSelectedDate = (date: Date): void => {
    const component = dateRefs.current[DateDataFormatYYYYMMDD(date)];
    if (!component) return;

    const windowWidth = window.innerWidth;
    const componentWidth = component.offsetWidth;
    const componentLeft = component.offsetLeft;
    const componentCenter = componentLeft + componentWidth / 2;
    const scrollLeft = componentCenter - windowWidth / 2;

    if (calendarRef.current) {
      calendarRef.current.scrollLeft = scrollLeft;
    }
  };

  const handleSearch = async () => {
    calendarModel.calendarLoadFilter.keyword = watch('company-search');
    await calendarModel.loadCalendarList();
  };

  useEffect(() => {
    calendarModel.hasFilter();
  }, [calendarModel.calendarLoadFilter]);

  useEffect(() => {
    scrollToSelectedDate(calendarModel.showDate);
  }, [calendarModel.showDate]);

  useEffect(() => {
    const handleScroll = () => {
      findCenterDate();
    };

    if (calendarRef.current) {
      calendarRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (calendarRef.current) {
        calendarRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const findCenterDate = () => {
    const windowWidth = window.innerWidth;
    const calendarLeft = calendarRef.current?.scrollLeft || 0;
    const centerPosition = calendarLeft + windowWidth / 2;

    const dateKeys = Object.keys(dateRefs.current);

    // eslint-disable-next-line no-restricted-syntax
    for (const key of dateKeys) {
      const dateComponent = dateRefs.current[key];
      if (!dateComponent) continue;

      const componentLeft = dateComponent.offsetLeft;
      const componentRight = componentLeft + dateComponent.offsetWidth;

      if (centerPosition >= componentLeft && centerPosition <= componentRight) {
        calendarModel.centerDate = new Date(key);
        break;
      }
    }
  };

  return (
    <FormProvider {...useFormed}>
      <RecruitTab />
      <FoldedCalenderHeaderBar>
        <div className='selected-month'>
          {calendarModel.centerDate.getFullYear()}년 {calendarModel.centerDate.getMonth() + 1}월
        </div>
        <div className='header-right-frame'>
          <JDTextField name='company-search' placeholder='기업명 검색' searchIcon btnClear handleSearch={handleSearch} />
          <div className='filter-frame' aria-checked={calendarModel.hasFilter()}>
            <Button buttonType={ButtonType.TEXT} label='필터' size='small' icon={<Icon name='filter' size={24} color={Colors.JOBDA_BLACK} />} onClick={() => setIsOpenCalendarFilter(true)} />
          </div>
        </div>
      </FoldedCalenderHeaderBar>
      <CalendarContainer ref={calendarRef} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} data-hide={context.hideHeader}>
        {/* 현재 날짜를 중심으로 전후 90일씩 렌더링 */}
        {Array.from({ length: 180 }).map((_, index) => {
          const date = new Date(calendarModel.currentDate.getFullYear(), calendarModel.currentDate.getMonth(), calendarModel.currentDate.getDate() - 90 + index);
          return (
            <DateLabel
              key={date.getTime()}
              selected={calendarModel.showDate && date.toDateString() === calendarModel.showDate.toDateString()}
              onClick={() => handleDateClick(date)}
              ref={(el) => {
                dateRefs.current[DateDataFormatYYYYMMDD(date)] = el;
              }}
            >
              <div className='week-day' aria-selected={calendarModel.showDate && date.toDateString() === calendarModel.showDate.toDateString()}>
                {weeksKor[date.getDay()]}
              </div>
              <div className={`day-number ${date.getDay() === 0 ? 'holiday' : ''}`}>{date.toLocaleDateString('en-US', { day: 'numeric' })}</div>
            </DateLabel>
          );
        })}
      </CalendarContainer>
      <CalendarFilterModal isOpen={isOpenCalendarFilter} onClickClose={() => setIsOpenCalendarFilter(false)} />
    </FormProvider>
  );
};

export default inject(injectStore.context, injectStore.calendarModel)(observer(FoldedCalendar));
