import MatchApplyStatusBoard from 'components/_v2/matchApply/matchApplyStatus/MatchApplyStatusBoard';
import IMatchApplyStatusProps from 'interfaces/_v2/matchApply/IMatchApplyStatusProps';
import { inject, observer } from 'mobx-react';
import MatchApplyStatusModel from 'models/_v2/MatchApplyStatusModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';

const Frame = styled.div`

`;

const MatchApplyStatus:FC<IMatchApplyStatusProps> = ({ matchApplyStatusModel = new MatchApplyStatusModel() }) => {
  useEffect(() => {
    const init = async () => {
      await matchApplyStatusModel.loadResponseRate();
    };

    init();
  }, []);

  return (
    <Frame>
      <MatchApplyStatusBoard />
    </Frame>
  );
};

export default inject(injectStore.matchApplyStatusModel)(observer(MatchApplyStatus));
