import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import ImgDummy from 'assets/_v2/positionList/img_dumy.png';
import JDALink from 'components/JDALink';
import RoutePaths from 'consts/RoutePaths';
import { CompanyHeaderImageTypeList } from 'consts/_v2/company/CompanyDetailType';
import useToast from 'hooks/useToast';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import Login from 'models/Login';
import CompanyModel from 'models/_v2/CompanyModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';

const Frame = styled.div`
    position:relative;
    width:100%;
    height: 440px;
    .poster{
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: cover;
        object-position: center 50%;
    }
    .dimmed{
        position: absolute;
        width: 100%;
        height: 50%;
        bottom:0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.60) 100%);    
    }
`;
const Header = styled.header<{scrollPosition?:number}>`
    display: flex;    
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(env(safe-area-inset-top) + 50px);
    position: fixed;
    background: ${colors.WHITE_100};
    opacity: calc(${(props) => (props.scrollPosition ? props.scrollPosition : 0)} / 100);
    top:0;
    padding-top: calc(env(safe-area-inset-top));
    z-index: 10;
    & > div {
        left:8px;
        position: absolute;
        z-index: 12;
    }
    .ion-app{
      padding-top: 0px;
    }
`;
const InfoContainer = styled.div`
    padding: 0 32px 40px 32px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-top: 260px;
    position: relative;
  .icon{
    width: 32px;
    height: 32px;
    border-radius: 4px;
  }
  .title{
    font:${Fonts.H4_Bold};
    color:${colors.WHITE_100};
  }
  .sub-title{
    font:${Fonts.B2_Medium};
    color:${colors.WHITE_100};
  }
  .bottom-frame{
    display: flex;
    justify-content: space-between;
    .home-like{
        display: flex;
        .homepage{
            display: flex;
            align-items: center;
            font:${Fonts.B3_Medium};
            color:${colors.WHITE_100};
            .icon-bottom{
                margin-right: 4px;
            }
        }
        .like{
            display: flex;
            align-items: center;
            font:${Fonts.B3_Medium};
            color:${colors.WHITE_100};
            .icon-bottom{
                margin-right: 4px;
            }
        }
    }
    .matching-icon{
        display: flex;
        padding:4px 6px;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.05);
        background: linear-gradient(90deg, #4BD667 0%, #40CCC6 100%);
        color:${colors.WHITE_100}
    }
  }
  
`;
interface ICompanyDetailHeaderCard{
    companyModel?:CompanyModel
    context?:Context
    login?:Login
    headerIconColor?:boolean
    scrollPosition?:number
}
const CompanyDetailHeaderCard:FC<ICompanyDetailHeaderCard> = ({ companyModel = new CompanyModel(), context = new Context(), login = new Login(), headerIconColor, scrollPosition }) => {
  const history = useHistory();
  const [backgroundImage, setBackgroundImage] = useState('');
  const [matchingYn, setMatchingYn] = useState(false);
  const { pathname, search } = useLocation();
  const { setToastObject } = useToast();
  
  const onClickBackBtn = () => {
    history.goBack();
  };

  const controlLike = async () => {
    if (!login.userInfo) {
      context.loginRedirectPath = pathname + search;
      history.push(RoutePaths.login);
    } else if (companyModel.companyHeader) {
      if (companyModel.companyHeader.companyHeaderDto.likeYn) {
        await companyModel.unlikeCompany(companyModel.companySn);
        setToastObject({ isOpen: true, type: 'INFO', message: '관심 기업에서 삭제되었습니다.' });
        await companyModel.loadCompanyHeader();
      } else {
        await companyModel.likeCompany(companyModel.companySn);
        setToastObject({ isOpen: true, type: 'INFO', message: '관심 기업으로 등록되었습니다.' });
        await companyModel.loadCompanyHeader();
      }
    }
  };
  useEffect(() => {
    if (companyModel.companyHeader?.companyHeaderDto.backgroundImageUid === '') {
      const modNum = companyModel.companySn % 40;

      setBackgroundImage(CompanyHeaderImageTypeList[modNum].image);
    }
  }, [companyModel.companyHeader]);
  useEffect(() => {
    if (prMatchingCompany.includes(companyModel.companySn)) {
      setMatchingYn(true);
    } else {
      setMatchingYn(false);
    }
  }, [companyModel.companySn]);
  return (
    <>

      <Header scrollPosition={scrollPosition}>
        {scrollPosition && scrollPosition / 100 > 1
          ? <Icon name='large-arrow-left' size={32} onClick={onClickBackBtn} />
          : <Icon name='large-arrow-left' size={32} onClick={onClickBackBtn} color={colors.WHITE_100} />}

      </Header>
      {
        companyModel.companyHeader
        && (
        <Frame>
          <img src={companyModel.companyHeader.companyHeaderDto.backgroundImageUid ? companyModel.companyHeader.companyHeaderDto.backgroundImageUid : backgroundImage} className='poster' alt='img' />
          <div className='dimmed' />
          <InfoContainer>
            {companyModel.companyHeader.companyHeaderDto.logoImageUid
              ? <img src={companyModel.companyHeader.companyHeaderDto.logoImageUid} alt='img' className='icon' />
              : <img src={ImgDummy} alt='' className='icon' />}

            <SpacingBlock vertical size={16} />
            <div className='title'>{companyModel.companyHeader.companyHeaderDto.name}</div>
            <SpacingBlock vertical size={4} />
            <div className='sub-title'>{companyModel.companyHeader.companyHeaderDto.vision}</div>
            <SpacingBlock vertical size={20} />
            <div className='bottom-frame'>
              <div className='home-like'>
                {
                  companyModel.companyHeader.companyHeaderDto.homepageUrl && (
                    <>
                      <JDALink to={companyModel.companyHeader.companyHeaderDto.homepageUrl} className='homepage'>
                        <Icon name='home' size={24} color={colors.JOBDA_WHITE} className='icon-bottom' />
                        홈페이지
                      </JDALink>
                      <SpacingBlock horizontal size={16} />
                    </>
                  )
                }
                <div className='like' role='button' onClick={controlLike}>
                  {companyModel.companyHeader.companyHeaderDto.likeYn
                    ? <Icon name='heart-filled' size={24} color={colors.ERROR} className='icon-bottom' />
                    : <Icon name='heart' size={24} color={colors.JOBDA_WHITE} className='icon-bottom' />}

                  {companyModel.companyHeader.companyHeaderDto.likeCount}
                </div>
              </div>
              {
                // companyModel.companyHeader.companyHeaderDto.matchingYn
                matchingYn
                && (
                <div className='matching-icon'>
                  MATCHING
                </div>
                )
              }

            </div>
          </InfoContainer>
        </Frame>
        )
    }
    </>

  );
};

export default inject(injectStore.companyModel, injectStore.context, injectStore.login)(observer(CompanyDetailHeaderCard));

const prMatchingCompany = [2, 3, 4, 7, 36, 38, 40, 45, 47, 50, 52, 83, 90, 95, 1000, 10547, 1202, 16697, 268, 271, 274, 28209, 292, 293, 295, 299, 301, 313, 323, 335, 337, 340, 351, 386, 387, 392, 415, 445, 504, 505, 518, 525, 529, 53857, 571, 57695, 583, 599, 640, 65562, 702, 74937, 86945, 91662, 91726, 91728, 91744, 91775];
