import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon, { IIconProps } from '__designkit__/icon/Icon';
import { lineClampOnlyOne } from 'consts/_v2/_common/style/mixins';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';

const Frame = styled.div<{ isLeftIcon: boolean, isSubTitle: boolean, isError: boolean, isDisabled: boolean, isSelected: boolean, isInverted: boolean }>`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  height: fit-content;
  padding-right: 16px;
  padding-left: ${(props) => (props.isLeftIcon ? 24 : 24)}px;
  padding-top: ${(props) => (props.isSubTitle ? 20 : 16)}px;
  padding-bottom: ${(props) => (props.isSubTitle ? 20 : 16)}px;
  border: ${(props) => (props.isDisabled ? 1 : props.isSelected ? 1 : 1)}px solid ${(props) => (props.isDisabled ? Colors.CG_50 : props.isError ? Colors.ERROR : props.isSelected ? Colors.JOBDA_BLACK : props.isInverted ? Colors.JOBDA_BLACK : Colors.CG_50)};
  border-radius: 8px;
  background-color: ${(props) => (props.isDisabled ? Colors.WHITE_100 : props.isSelected ? Colors.CG_30 : props.isInverted ? Colors.JOBDA_BLACK : Colors.WHITE_100)};
  user-select: none;
  align-items: center;
  &:active {
    background-color: ${(props) => (props.isDisabled ? Colors.WHITE_100 : props.isInverted ? Colors.CG_70 : Colors.CG_30)};
  }

  & * {
    user-select: none;
  }
  .divider-small{
    flex:none;
    width: 1px;
    height: 32px;
    background-color: ${Colors.CG_40};
  
  }
`;

const InnerFrame = styled.div<{ isDisabled: boolean }>`
  display: flex;
  align-items: center;
  gap: 16px;
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
`;

const ContentsFrame = styled.div<{ resumeError:boolean, isSubTitle: boolean, isInverted: boolean, isLeftIcon: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  /* max-width: 100%; */

  > .title-text {
    display: flex;
    align-items: center;
    gap: 2px;
    font: ${(props) => (props.isSubTitle ? Fonts.B1_Bold : Fonts.B1_Medium)};
    color: ${(props) => (props.resumeError ? Colors.CG_50 : (props.isInverted ? Colors.WHITE_100 : Colors.JOBDA_BLACK))};
    word-break: break-all;
    width: ${(props) => (props.isLeftIcon ? 198 : 255)}px;
    ${lineClampOnlyOne()}
  } 
  > .sub-title-text {
    font: ${Fonts.B2_Medium};
    color: ${Colors.CG_70};
    word-break: break-all;
    width: ${(props) => (props.isLeftIcon ? 198 : 255)}px;
    ${lineClampOnlyOne()}

  }

`;
const MessageFalseFrame = styled.div`
  display: flex;
  > .message-false{
    display: flex;
    font:${Fonts.B3_Medium};
    color:${Colors.ERROR};
  }
`;
const ErrorMessageIconWrapper = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.icon-error::after {
    content: "";
    position: absolute;
    top: 2px; // 조정 필요
    right: 2px; // 조정 필요
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background-color: ${Colors.ERROR};
  }
`;

interface IResumeSingleMenuItemProps extends IComponentProps {
  title: string; // 필수값
  subTitle?: string | ReactElement; // 선택값, 지정하면 title 아래에 나타납니다
  badge?: ReactElement; // 선택값, 지정하면 title 오른쪽에 나타납니다
  leftIcon?: ReactElement<IIconProps>; // 선택값, 지정하면 [title + subTitle] 프레임의 왼쪽에 나타납니다
  rightIcon?: ReactElement<IIconProps> | null; // 선택값, 기본 값은 arrow-right 입니다. 아예 없애고 싶다면 null을 넣어주세요
  error?: boolean; // 선택값, 지정하면 border가 빨간색, 나타납니다
  resumeError?:boolean;
  disabled?: boolean; // 선택값, 지정하면 클릭할 수 없습니다
  selected?: boolean; // 선택값, 지정하면 active 상태가 됩니다
  inverted?: boolean; // 선택값, 지정하면 배경색과 글자색이 반전됩니다. (기존: 배경 - 흰, 글자 - 검)
  onClick?: () => void; // 필수값
  onClickInnerFrame?:() => void; // 왼쪽 필드 클릭시
  onClickRightIcon?:() => void; // 오른쪽 아이콘 클릭시
  divider?:boolean
}

const ResumeSingleMenuItem: FC<IResumeSingleMenuItemProps> = ({ divider = false, resumeError = false, title, subTitle = undefined, badge = undefined, leftIcon = undefined, rightIcon = <Icon name='arrow-right' size={32} color={Colors.JOBDA_BLACK} />, error = false, disabled = false, selected = false, inverted = false, onClick, onClickInnerFrame, onClickRightIcon, ...props }) => {
  const onClickFrame = () => {
    if (!disabled && onClick) onClick();
  };

  const handleClickInnerFrame = () => {
    if (!disabled && onClickInnerFrame) onClickInnerFrame();
  };

  const handleClickRightIcon = () => {
    if (!disabled && onClickRightIcon) onClickRightIcon();
  };
  return (
    <Frame isLeftIcon={!!leftIcon} isSubTitle={!!subTitle} onClick={onClickFrame} isError={error} isDisabled={disabled} isSelected={selected} isInverted={inverted} {...props} onContextMenu={(e) => e.preventDefault()}>
      <InnerFrame isDisabled={!!disabled} onClick={handleClickInnerFrame}>
        { leftIcon }
        <ContentsFrame resumeError={resumeError} isSubTitle={!!subTitle} isInverted={inverted} isLeftIcon={!!leftIcon}>
          <div className='title-text'>
            { title }
          </div>
          {
            resumeError ? (
              <MessageFalseFrame>
                <Icon name='error' size={16} color={Colors.ERROR} />
                <div className='message-false'>
                  추가 작성 필요
                </div>
              </MessageFalseFrame>
            )
              : (subTitle && <div className='sub-title-text'>{ subTitle }</div>)
          }

        </ContentsFrame>
      </InnerFrame>
      {
        divider && <div className='divider-small' />
      }

      <InnerFrame isDisabled={disabled} onClick={handleClickRightIcon}>
        {
          resumeError ? (
            <ErrorMessageIconWrapper className='icon-error'>
              {rightIcon}
            </ErrorMessageIconWrapper>
          ) : rightIcon
        }
      </InnerFrame>
    </Frame>
  );
};

export default ResumeSingleMenuItem;
