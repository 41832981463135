import JDALink from 'components/JDALink';
import React, { FC } from 'react';

const urlRegex = /https?:\/\/[^\s]+/g;

interface LinkifyStringProps {
    text: string;
}

const LinkifyString :FC<LinkifyStringProps> = ({ text }) => {
  const linkify = (inputText: string) => {
    const foundUrls = inputText.match(urlRegex);

    if (!foundUrls) {
      return inputText;
    }

    let lastIndex = 0;
    const result = [];

    foundUrls.forEach((url) => {
      const linkStart = inputText.indexOf(url, lastIndex);

      // URL 이전의 텍스트를 추가
      result.push(inputText.substring(lastIndex, linkStart));

      result.push(
        <JDALink to={url} key={url} className='hyper-link'>
          {url}
        </JDALink>,
      );

      lastIndex = linkStart + url.length;
    });

    // 남은 문자열 추가
    result.push(inputText.substring(lastIndex));

    return result;
  };

  return <>{linkify(text)}</>;
};

export default LinkifyString;
