import { MatchLoginConnectInfoModalProps } from 'components/_v2/matchLogin/MatchLoginConnectInfoModal';
import JDBaseModal from 'components/_v2/_common/modals/JDBaseModal';
import { JDModalBasicFrame, JDModalDescription, JDModalFullButton, JDModalTitle } from 'components/_v2/_common/modals/JDModalComponents';
import JDNoHeaderFullModal from 'components/_v2/_common/modals/JDNoHeaderFullModal';
import React, { FC } from 'react';
import styled from 'styled-components';

const Frame = styled.div`

    ${JDModalDescription} {
        margin-top: 8px;
    }
`;
const MatchLoginPositionEndModal: FC<MatchLoginConnectInfoModalProps> = ({ isOpen, onClickClose }) => (
  <JDNoHeaderFullModal isOpen={isOpen} onClose={onClickClose}>
    <JDBaseModal dimmed isOpen={isOpen} onClickClose={onClickClose} isDimClickClose>
      <Frame>
        <JDModalBasicFrame>
          <JDModalTitle>계정 연동에<br />실패하였습니다.</JDModalTitle>
          <JDModalDescription>
            포지션을 연동할 수 있는 기간이<br />만료되었습니다.
          </JDModalDescription>
          <JDModalFullButton onClick={onClickClose}>확인</JDModalFullButton>
        </JDModalBasicFrame>
      </Frame>
    </JDBaseModal>
  </JDNoHeaderFullModal>
);

export default MatchLoginPositionEndModal;
