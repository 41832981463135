import JDBaseModal, { IJDBaseModal } from 'components/_v2/_common/modals/JDBaseModal';
import { JDModalBasicFrame, JDModalTitle } from 'components/_v2/_common/modals/JDModalComponents';
import { IconClose } from 'consts/assets/icons/iconPages';
import React, { FC } from 'react';
import styled from 'styled-components';
import ImgCheck from 'assets/_v2/builder/icon_list_check_green.png';
import ImgResume from 'assets/_v2/builder/icon_resume_copy.png';
import ImgMatching from 'assets/_v2/builder/icon_matching_person_green.png';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';

const Frame = styled.div`
.select {
    position: relative;
    margin-top: 16px;
    border: 1px solid $C_COOL_GRAY_30;
    border-radius: 4px;
    }
.close-btn{
    z-index:1;
    position: absolute;
    right: 20px;
    top:20px;
    margin-bottom: 8px;
    }   
.box-frame{
    width: 100%;
    border: 1px solid ${colors.CG_40};
    border-radius: 8px;
    padding:34px 0 6px 0;
    margin-top: 16px;
    .inner-box{
      display: flex;
      flex-direction: row;
      margin-left: 48px;
      margin-bottom: 28px;
      .icon{
      width: 48px;
      height: 48px;
    }
    .text-area{
      margin-left: 24px;
      .main-text{
        font: ${Fonts.B3_Bold};
        color:${colors.CG_90};
      }
      .sub-text{
        font: ${Fonts.B3_Medium};
        color:${colors.CG_60};
      }
    }
    }
    
}

`;

const BuilderLoginInfoModal:FC<IJDBaseModal> = ({ isOpen, onClickClose, onSuccess }) => (
  <JDBaseModal dimmed isOpen={isOpen} onClickClose={onClickClose}>
    <Frame>
      <JDModalBasicFrame>
        <button className='close-btn' onClick={onClickClose}><IconClose /></button>
        <JDModalTitle>더욱 편하고 강력해진, <br /> JOBDA의 지원서 관리 <br /> 서비스를 만나보세요!</JDModalTitle>
        <div className='box-frame'>
          <div className='inner-box'>
            <img src={ImgCheck} alt='icon' className='icon' />
            <div className='text-area'>
              <div className='main-text'>지원서 확인하기</div>
              <div className='sub-text'>제출한 지원서를 <br /> 한눈에 확인해보세요.</div>
            </div>
          </div>
          <div className='inner-box'>
            <img src={ImgResume} alt='icon' className='icon' />
            <div className='text-area'>
              <div className='main-text'>지원서 불러오기</div>
              <div className='sub-text'>다른 지원서 작성에 <br /> 활용해보세요.</div>
            </div>
          </div>
          <div className='inner-box'>
            <img src={ImgMatching} alt='icon' className='icon' />
            <div className='text-area'>
              <div className='main-text'>지원서 통합 관리</div>
              <div className='sub-text'>작성한 지원서를 <br /> 한곳에 모아보세요.</div>
            </div>
          </div>

        </div>
      </JDModalBasicFrame>
    </Frame>
  </JDBaseModal>

);

export default BuilderLoginInfoModal;
