import ProfilePreviewAdditionInfo from 'components/_v2/profile/preview/ProfilePreviewAdditionInfo';
import ProfilePreviewBasic from 'components/_v2/profile/preview/ProfilePreviewBasic';
import ProfilePreviewCareer from 'components/_v2/profile/preview/ProfilePreviewCareer';
import ProfilePreviewEducation from 'components/_v2/profile/preview/ProfilePreviewEducation';
import ProfilePreviewExperience from 'components/_v2/profile/preview/ProfilePreviewExperience';
import ProfilePreviewFile from 'components/_v2/profile/preview/ProfilePreviewFile';
import ProfilePreviewKnowledgeAndSkill from 'components/_v2/profile/preview/ProfilePreviewKnowledgeAndSkill';
import ProfilePreviewPreferential from 'components/_v2/profile/preview/ProfilePreviewPreferential';
import ProfilePreviewPrize from 'components/_v2/profile/preview/ProfilePreviewPrize';
import ProfilePreviewProject from 'components/_v2/profile/preview/ProfilePreviewProject';
import { Divider1G40 } from 'components/divider/Divider';
import { IResumePreviewAdditionInfo } from 'interfaces/_v2/positionJd/IResumeAdditionInfoRqRs';
import { IProfileDetails } from 'interfaces/_v2/profile/IProfileDetail';
import { inject, observer } from 'mobx-react';
import ProfileModel from 'models/_v2/profile/ProfileModel';
import { injectStore } from 'models/store';
import React, { FC } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
    padding: 56px 16px;
`;

interface IProfilePreviewProps {
  profileModel?: ProfileModel;
  data: IProfileDetails;
  additionInfo?: IResumePreviewAdditionInfo;
}

const ProfilePreview: FC<IProfilePreviewProps> = ({ data, additionInfo }) => {
  return (
    <Frame>
      <ProfilePreviewBasic data={data.user} />
      <Divider1G40 />
      {data.education && <ProfilePreviewEducation data={data.education} /> }
      {data.career && <ProfilePreviewCareer data={data.career} />}
      {data.project && <ProfilePreviewProject data={data.project} />}
      {data.knowledgeAndSkill && <ProfilePreviewKnowledgeAndSkill data={data.knowledgeAndSkill} />}
      {data.experience && <ProfilePreviewExperience data={data.experience} />}
      {data.prize && <ProfilePreviewPrize data={data.prize} />}
      {data.preferential && <ProfilePreviewPreferential data={data.preferential} />}
      {data.file && <ProfilePreviewFile data={data.file} />}
      {data.addition && data.addition.additionInfo.length > 0 && <ProfilePreviewAdditionInfo data={data.addition} />}
      {additionInfo && additionInfo.additionInfo.length > 0 && <ProfilePreviewAdditionInfo data={additionInfo} />}
    </Frame>
  );
};

export default inject(injectStore.profileModel)(observer(ProfilePreview));
