import ToggleSwitch from 'components/ToggleSwitch';
import { TOAST_TYPE } from 'components/_v2/_common/toast/JDToast';
import MatchApplyCancelBenefitsModal from 'components/_v2/matchApply/MatchApplyCancelBenefitsModal';
import MatchApplyCancelModal from 'components/_v2/matchApply/MatchApplyCancelModal';
import MatchMissionGuideModal from 'components/_v2/profile/match/MatchMissionGuideModal';
import MatchServiceConditionInductionModal from 'components/_v2/profile/match/MatchServiceConditionInductionModal';
import RoutePaths from 'consts/RoutePaths';
import useToast from 'hooks/useToast';
import IComponentProps from 'interfaces/props/IComponentProps';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import Login from 'models/Login';
import MatchApplyModel from 'models/_v2/MatchApplyModel';
import { injectStore } from 'models/store';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import styled from 'styled-components';

const Frame = styled(FormProvider)``;

interface IMatchServiceToggleProps extends IComponentProps {
  login?: Login;
  context?: Context;
  matchApplyModel?: MatchApplyModel;
  sourcePage?: string;
  setAfterToggle?: (value: boolean) => void;
}

const MatchServiceToggle: FC<IMatchServiceToggleProps> = ({ className, setAfterToggle, sourcePage, login = new Login(), context = new Context() }) => {
  const history = useHistory();
  const useFormed = useForm({ mode: 'onSubmit' });
  const [isMatchService, setIsMatchService] = useState<boolean>(false);
  const [isOpenCancelModal, setIsCancelModalOpen] = useState<boolean>(false);
  const [isOpenCancelBenefitsModal, setIsCancelBenefitsModalOpen] = useState<boolean>(false);
  const [isOpenMissionGuideModal, setIsOpenMissionGuideModal] = useState<boolean>(false);
  const [isOpenConditionInductionModal, setIsOpenConditionInductionModal] = useState<boolean>(false);
  const { setToastObject } = useToast();

  // 토글 클릭 시 동작하는 함수
  const onClickToggle = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!login.userInfo) {
      context.previousPathname = history.location.pathname;
      history.push(RoutePaths.login);
    } else if (e.target.checked) {
      checkMissionComplete();
    } else {
      setIsCancelBenefitsModalOpen(true);
    }
  };

  // 미션 완료 여부 분기, 통과 시 서비스 신청 완료
  const checkMissionComplete = async () => {
    await login.getMatchConfigStatus();
    // 매칭 조건 여부 확인
    // TODO: 기본정보 관리에서 인증하고 뒤로가기 누르면 다시 인증페이지로가서 헤더 뒤로가기 기능 수정
    if (!login.matchConfigStatus?.generalMatchingConfigAvailableYn && !login.matchConfigStatus?.developerMatchingConfigAvailableYn) {
      // 일반/개발자 둘다 조건에 맞지 않으면..
      setIsOpenMissionGuideModal(true);
    } else {
      await login.changeMatchConfigStatus(true, undefined, sourcePage);

      await login.getMatchConfigStatus(); // update
      if (setAfterToggle) {
        setAfterToggle(true);
      } else {
        setIsOpenConditionInductionModal(true);
      }
    }
  };
  // 잡다 매치 서비스 신청 취소 성공 시 동작하는 함수
  const onSuccessCancel = async () => {
    setToastObject({ isOpen: true, type: TOAST_TYPE.SUCCESS, message: '잡다 매칭 신청이 취소되었습니다.', subMessage: undefined });
    setIsCancelModalOpen(false);
  };
  // 잡다 매치 서비스 신청 여부 확인해서 Toggle UI 초기화
  useEffect(() => {
    (async () => {
      if (login.userInfo) {
        if (!login.matchConfigStatus) await login.getMatchConfigStatus();
        if (login.matchConfigStatus) setIsMatchService(login.matchConfigStatus.matchingConfigYn);
      }
    })();
  }, [login, login.matchConfigStatus]);

  return (
    <Frame {...useFormed}>
      <ToggleSwitch className={!isMatchService ? className : ''} checked={isMatchService} onChange={onClickToggle} />
      <MatchMissionGuideModal isOpen={isOpenMissionGuideModal} onClickClose={() => setIsOpenMissionGuideModal(false)} sourcePage={sourcePage} />
      <MatchApplyCancelBenefitsModal isOpen={isOpenCancelBenefitsModal} onClickClose={() => setIsCancelBenefitsModalOpen(false)} onSuccess={() => { setIsCancelBenefitsModalOpen(false); setIsCancelModalOpen(true); }} />
      <MatchApplyCancelModal isOpen={isOpenCancelModal} onSuccess={onSuccessCancel} onClickClose={() => setIsCancelModalOpen(false)} />
      <MatchServiceConditionInductionModal isOpen={isOpenConditionInductionModal} onClickClose={() => setIsOpenConditionInductionModal(false)} />
    </Frame>
  );
};

export default inject(injectStore.login, injectStore.context, injectStore.matchApplyModel)(observer(MatchServiceToggle));
