/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import colors from '__designkit__/common/colors';
import Fonts, { fonts, lineHeights } from '__designkit__/common/fonts';
import { absoluteHorizonCenter, absoluteVerticalCenter, dimmer, JDAInputFrame } from 'consts/style/mixins';
import IComponentProps from 'interfaces/props/IComponentProps';
import IconDownArrow from 'assets/icon/icon_down_arrow_16.svg';
import { ReactComponent as IconSelectUpDownArrow } from 'assets/icon/icon_select_up_down_arrow.svg';
import { StyledIconSVG } from 'consts/assets/icons/iconPages';
import Portal from '../common/Portal';
import UnderlineSplash from '../UnderlineSplash';

export enum JDA_SELECTOR_TYPE {
  type1,
  type2,
}

export const StyledSelectType2 = styled.div`
  ${JDAInputFrame()};
  position: relative;
  img {
    ${absoluteVerticalCenter()}
    right: 16px;
  }

  input {
    width: 100%;
  }

  input:disabled {
    opacity: 1;

  }

  &[data-disabled=true] {
    opacity: 0.5;
  }
 
`;

const Frame = styled.div`
  display: ${(props) => ((props as any)['data-type'] === JDA_SELECTOR_TYPE.type1 ? 'inline-block' : 'block')};
 ${(props) => ((props as any)['data-type'] === JDA_SELECTOR_TYPE.type1 ? '' : 'width:100%;')};

  select {
    display: none;
    &.placeholder ~ ${StyledSelectType2} {
      color: ${colors.CG_60};
    } 
  }
`;

export const StyledSelectType1 = styled.div`
  position: relative;
  display: inline-block;
  padding: 4px 28px 4px 12px;
  height: 28px;
  color: ${colors.CG_80};
  background: ${colors.WHITE_100};
  border: 1px solid ${colors.CG_40};
  border-radius: 20px;
  vertical-align: middle;
  span {
    font: ${Fonts.B2_Medium};
  }
  svg {
    ${absoluteVerticalCenter()}
    right: 8px;
  }
  input:disabled {
    -webkit-text-fill-color: ${colors.CG_90};
    opacity: 1;
  }
`;

const StyledOptions = styled.div`
  background: ${colors.WHITE_100};
  position: absolute;
  width: 100%;
  bottom: 0;
  border-radius: 8px 8px 0px 0px;
  padding: 8px 16px;
  text-align: center;
  transform: translate(0, 100%);
  transition: transform 0.25s;
  transition-delay: 0.25s;
  
  :before {
    content: '';
    ${absoluteHorizonCenter()}
    top: -8px;
    width: 32px;
    height: 4px;
    border-radius: 2px;
    background: ${colors.CG_50};
  }
  .option-title {
    padding: 16px 0;
    color: ${colors.CG_90};
    ${fonts.NOTO_17_700};
    ${lineHeights.LINEHEIGHT_1_50};
    border-bottom: 1px solid ${colors.CG_40};
  }
  .option-wrapper {
    position: relative;
    margin-bottom: 40px;
    max-height: 284px;
    .snap-wrapper {
      overflow: auto;
      max-height: inherit;
      scroll-snap-type: y mandatory;
      -ms-overflow-style: none;
      scrollbar-width: none;
      ::-webkit-scrollbar {
          display: none;
      }
      
      &.active-grad-top {
        .grad-top {
          display: block; 
        }
      }
      &.active-grad-bottom {
        .grad-bottom {
          display: block; 
        }
      }
      .select-option {
        scroll-snap-align: center;
        padding: 16px 0;
        color: ${colors.CG_90};
        ${fonts.NOTO_17_400};
        ${lineHeights.LINEHEIGHT_1_50};
        &[data-disabled=true] {
          pointer-events: none;
          opacity: 0.5;
        }
      }
    }
  }
  
  .grad-bottom {
    z-index: 1;
    display: none;
    position: absolute;
    width: 100%;
    height: 40px;
    bottom: 0px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 51.56%);
  }
  .grad-top {
    z-index: 1;
    display: none;
    position: absolute;
    width: 100%;
    height: 40px;
    top: 0px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 51.56%);
  }
`;

const StyledDimmer = styled.div`
  position: relative;
  ${dimmer()};
  z-index: 10008;
  opacity: 0;
  transition: opacity 0.25s;
  transition-delay: 0.25s;
  pointer-events: none;
  &.active {
    pointer-events: inherit;
    opacity: 1;
    transition-delay: 0s;
    ${StyledOptions} {
      transform: translate(0, 0);
      transition-delay: 0s;
    }
  }
`;

interface IJDASelector extends IComponentProps {
  fieldRef?: React.RefObject<HTMLDivElement>;
  selectTitle: string;
  type?: JDA_SELECTOR_TYPE;
  name?: string;
  onChange?: (value?: string) => void;
  value?: string;
  saveData?: (text: string) => void;
  disabled?: boolean;
}

const JDASelector: FC<IJDASelector> = ((props) => {
  const { name, saveData, onChange, fieldRef, selectTitle, type = JDA_SELECTOR_TYPE.type1, value, disabled, ...rest } = props;
  const useFormed = useFormContext();
  const { register, setValue } = useFormed;
  const frameRef = useRef<HTMLDivElement>(null);
  const dimmerRef = useRef<HTMLDivElement>(null);
  const snapWrapperRef = useRef<HTMLDivElement>(null);
  const [selectedText, setSelectedText] = useState<string>('');
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [selectOptions, setSelectOptions] = useState<React.ReactNode>(<></>);
  const [closedDimmer, setClosedDimmer] = useState<boolean>(true);

  useEffect(() => {
    if (!dimmerRef.current) return;
    if (closedDimmer) {
      dimmerRef.current.classList.remove('active');
    } else {
      dimmerRef.current.classList.add('active');
      scrollHandler();
    }
  }, [closedDimmer]);

  const scrollHandler = () => {
    if (!snapWrapperRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = snapWrapperRef.current;
    if (scrollTop > 0) {
      snapWrapperRef.current.classList.add('active-grad-top');
    } else {
      snapWrapperRef.current.classList.remove('active-grad-top');
    }
    if (scrollTop < scrollHeight - clientHeight) {
      snapWrapperRef.current.classList.add('active-grad-bottom');
    } else {
      snapWrapperRef.current.classList.remove('active-grad-bottom');
    }
  };

  useEffect(() => {
    if (snapWrapperRef.current) {
      snapWrapperRef.current.addEventListener('scroll', scrollHandler);
    }
    return () => {
      if (snapWrapperRef.current) {
        snapWrapperRef.current.removeEventListener('scroll', scrollHandler);
      }
    };
  }, [snapWrapperRef]);

  const generateSelectOptions = (selectEl: HTMLSelectElement, options: HTMLCollectionOf<HTMLOptionElement>) => {
    const optionsLength = selectEl.length;
    setSelectOptions((
      <>
        {
          [...new Array(optionsLength)].map((_, index) => {
            const optionEl = options[index];
            return (
              <div
                className='select-option'
                hidden={optionEl.hidden}
                data-disabled={optionEl.disabled}
                onClick={() => {
                  selectEl.selectedIndex = index;
                  setSelectedIndex(index);
                  setClosedDimmer(true);
                  if (saveData) saveData(optionEl.text);
                  if (name) setValue(name!, optionEl.value, { shouldValidate: true, shouldDirty: true });
                  if (onChange) onChange(optionEl.value);
                }}
              >
                <UnderlineSplash className='underline' height={24} ratio={0.67} color={colors.cFDF69E} enable={selectedIndex === index}>
                  {optionEl.innerText}
                </UnderlineSplash>
              </div>
            );
          })
        }
        {optionsLength > 3 && <div className='select-option' data-disabled />}
      </>
    ));
  };

  useEffect(() => {
    if (frameRef.current) {
      const selectEl = frameRef.current.getElementsByTagName('select')[0];
      const options = selectEl.getElementsByTagName('option');
      const selectedIdx = options[selectEl.selectedIndex].dataset.clearOption ? 0 : selectEl.selectedIndex;
      setSelectedIndex(selectedIdx);
      if (!options[selectedIdx].value) {
        selectEl.classList.add('placeholder');
      } else {
        selectEl.classList.remove('placeholder');
      }
      setSelectedText(options[selectedIdx].innerText);
      generateSelectOptions(selectEl, options);
    }
  }, [props.children, selectedIndex]);

  useEffect(() => {
    if (!value) {
      if (frameRef.current) {
        const selectEl = frameRef.current.getElementsByTagName('select')[0];
        selectEl.selectedIndex = 0;
      }
      setSelectedIndex(0);
    }
    if (name)
      setValue(name, value);
  }, [value]);

  const getSelectorTextComponent = () => {
    switch (type) {
      case JDA_SELECTOR_TYPE.type2:
        return (
          <StyledSelectType2
            ref={fieldRef}
            className={closedDimmer ? '' : 'active'}
            onClick={() => {
              if (!disabled)
                setClosedDimmer(false);
            }}
            data-disabled={disabled}
          >
            <input value={selectedText} disabled />
            <img alt='select 아이콘' src={IconDownArrow} />
          </StyledSelectType2>
        );
      case JDA_SELECTOR_TYPE.type1: default:
        return (
          <StyledSelectType1 ref={fieldRef} onClick={() => setClosedDimmer(false)}>
            <span>{selectedText}</span>
            <StyledIconSVG fill={colors.CG_80}><IconSelectUpDownArrow width={20} height={20} /></StyledIconSVG>
          </StyledSelectType1>
        );
    }
  };

  return (
    <Frame ref={frameRef} data-type={type} {...rest}>
      { props.children}
      <input style={{ display: 'none' }} ref={register()} name={name} />
      { getSelectorTextComponent()}
      <Portal>
        <StyledDimmer ref={dimmerRef} onClick={() => setClosedDimmer(true)}>
          <StyledOptions>
            <div className='option-title'>{selectTitle}</div>
            <div className='option-wrapper'>
              <div ref={snapWrapperRef} className='snap-wrapper'>
                <div className='grad-top' />
                {selectOptions}
                <div className='grad-bottom' />
              </div>
            </div>
          </StyledOptions>
        </StyledDimmer>
      </Portal>
    </Frame>
  );
});

export default JDASelector;
