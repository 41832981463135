import { Button, ButtonMode } from '__designkit__/button/Button';
import colors from '__designkit__/common/colors';
import Icon from '__designkit__/icon/Icon';
import JDFullModal from 'components/_v2/_common/modals/JDFullModal';
import MatchApplyBlindCompany from 'components/_v2/matchApply/MatchApplyBlindCompany';
import IMatchApplyProps from 'interfaces/_v2/matchApply/IMatchApplyProps';
import React, { FC } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
    padding: 16px; 

    .close-btn-icon {
        position: absolute;
        text-align: right;
        width: 100%;
        top: 16px;
        right: 16px;
    }


`;

const ButtonFrame = styled.div`
  position: fixed;
  bottom: env(safe-area-inset-bottom);
  background-color: ${colors.WHITE_100};
  width: 100%;
  height: 78px;
  padding: 16px;
  border-top: 1px solid ${colors.CG_40};
`;
 interface IMatchApplyBlindFullModalProps extends IMatchApplyProps {
    handleClose: () => void;
  }

const MatchApplyBlindFullModal:FC<IMatchApplyBlindFullModalProps> = ({ handleClose }) => (
  <JDFullModal>
    <Frame>
      <Icon name='close' className='close-btn-icon' size={32} onClick={handleClose} />
      <MatchApplyBlindCompany />
    </Frame>
    <ButtonFrame>
      <Button label='확인' buttonMode={ButtonMode.PRIMARY} onClick={handleClose} />
    </ButtonFrame>
  </JDFullModal>
);

export default MatchApplyBlindFullModal;
