import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import MatchReportModel from 'models/_v2/MatchReportModel';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import Login from 'models/Login';
import IconDot from 'assets/_v2/company/cardIcon/icon_dot_green.png';

const FourQuadrantsFrame = styled.div`
  height: 252px;
  width: 100%;
    
/* 우측 4사분면 그래프 */
.scoreInner {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px 24px 44px 48px;
  border-radius: 16px;
  

  .fourQuadrantsGraph {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    width: 100%;
    height: 252px;

    .dotInner {
      position: absolute;
      top: 0;
      left: 0;

      .dot {
        .dot-color{
          position: relative;
          width: 24x;
          height: 24px;
          border-radius: 4px;
        }

        .dotTooltip {
          position: absolute;

          top: -80px;
          left: -50px; /* 넓이의 반 */
          transform: translateX(-50%);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 4px;
          padding: 8px 12px;
          width: fit-content;
          white-space: nowrap;
          background-color: ${Colors.CG_80};
          border-radius: 4px;

          .dotTooltipTitle {
            font:${Fonts.B3_Medium};
            color:${Colors.WHITE_100};
            .score {
              color: ${Colors.G_150};
            }
          }

          .dotTooltipDesc {
            font:${Fonts.B3_Medium};
            color:${Colors.WHITE_100};
            .score {
              margin-top: 4px;
              padding-left: 3px;
              color: ${Colors.G_150};
            }
          }

          &:before {
            position: absolute;
            top: -8px;
            right: 50%;
            transform: translateX(50%);
            width: 32px;
            height: 8px;
            /* background-image: url(/img/v2/components/company/sn/reportTab/graphTooltip.svg); */
            background-size: cover;
            background-position: center;
            content: '';
          }
        }
      }
    }

    .quadrant {
      display: flex;
      padding: 12px 16px;
      border: 1px solid ${Colors.CG_50};
      background-color: ${Colors.WHITE_100};
      font:${Fonts.B1_Bold};
      color:${Colors.CG_50};

      &.on {
        background-color: ${Colors.G_30};
        font:${Fonts.B1_Bold};
        color:${Colors.G_200};
      }

      &:nth-child(2) {
        border-radius: 8px 0 0 0;
        border-right: none;
        border-bottom: none;
      }

      &:nth-child(3) {
        border-radius: 0 8px 0 0;
        align-items: flex-start;
        justify-content: flex-end;
        border-bottom: none;
      }

      &:nth-child(4) {
        border-radius: 0 0 0 8px;
        align-items: flex-end;
        justify-content: flex-start;
        border-right: none;
      }

      &:last-child {
        border-radius: 0 0 8px 0;
        align-items: flex-end;
        justify-content: flex-end;
      }
    }
  }

  /* x,y 축 설명 */
  .axis {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width:calc(100% - 72px);
    height: 252px;

    &.xDesc {
      bottom: 16px;
      align-items: flex-end;
    }

    &.yDesc {
      top: 24px;
      left: 14px;
      writing-mode: vertical-rl;
      text-orientation: sideways;
      transform: rotate(-180deg);
    }

    .axisName {
      
      color: ${Colors.CG_60};
      font:${Fonts.B4_Medium};
      &.bold{
        font:${Fonts.B3_Medium};
        color:${Colors.CG_80};
      }
    }

    :nth-child(2) {
      padding-left: 20px;
      font:${Fonts.B3_Medium};
      color:${Colors.CG_80};
    }
  }
}

`;
interface ICompanyReportFourQuadrants{
  profileScore?:number;
  competencyScore?:number;
  matchReportModel?:MatchReportModel
  login?:Login
}
export enum QuadrantType {
  Excellent = 'Excellent',
  Optimal = 'Optimal',
  Potential = 'Potential',
  CompanyPreference = 'CompanyPreference',
}
const CompanyReportFourQuadrants:FC<ICompanyReportFourQuadrants> = ({ profileScore, competencyScore, matchReportModel = new MatchReportModel(), login = new Login() }) => {
  const [graphPosition, setGraphPosition] = useState<QuadrantType>(QuadrantType.Optimal);
  const [correctedProfileScore, setCorrectedProfileScore] = useState<number>(0);
  const [correctedCompetencyScore, setCorrectedCompetencyScore] = useState<number>(0);
  const [graphPositionText, setGraphPositionText] = useState<string>('');
  const calculateTooltipPosition = (): { top: number; left: number } => {
    switch (graphPosition) {
      case QuadrantType.Excellent:
        return { top: 30, left: 50 };
      case QuadrantType.Optimal:
        return { top: 30, left: -50 };
      case QuadrantType.Potential:
        return { top: -90, left: 50 };
      case QuadrantType.CompanyPreference:
        return { top: -90, left: -50 };
      default:
        return { top: 0, left: 0 };
    }
  };
  const tooltipPosition = calculateTooltipPosition();

  useEffect(() => {
    if (profileScore && competencyScore) {
      const profileScorePercent = profileScore;
      const competencyScorePercent = competencyScore;
      if (profileScorePercent >= 3000 && competencyScorePercent >= 3000) {
        setGraphPosition(QuadrantType.Optimal);
        setGraphPositionText('최적의 인재');
      } else if (profileScorePercent >= 3000 && competencyScorePercent < 3000) {
        setGraphPosition(QuadrantType.CompanyPreference);
        setGraphPositionText('기업 선호 부합');
      } else if (profileScorePercent < 3000 && competencyScorePercent >= 3000) {
        setGraphPosition(QuadrantType.Excellent);
        setGraphPositionText('탁월한 역량');
      } else {
        setGraphPosition(QuadrantType.Potential);
        setGraphPositionText('잠재력');
      }
      setCorrectedProfileScore(scaleValue(profileScorePercent, 'x'));
      setCorrectedCompetencyScore(scaleValue(competencyScorePercent, 'y'));
    }
  }, [profileScore, competencyScore]);
  const scaleValue = (value: number, axis: 'x' | 'y' = 'x') => {
    const minVal = 1000;
    const maxVal = 5000;
    const scaledMin = 0;
    const scaledMax = 100;

    // 값 새로운 범위로 변환 + absolut 위치 기준 값 보정 1000 ~ 5000 -> 0 ~ 100
    const correction = axis === 'y' ? -4 : 4; // absolute 위치 기준값 보정.
    const newValue = ((value - minVal) / (maxVal - minVal)) * (scaledMax - scaledMin) + scaledMin - correction;
    // absolute bottom, left값 보정
    if (axis === 'x') {
      if (newValue > 92) return 92;
      if (newValue < 0) return 0;
    }
    if (axis === 'y') {
      if (newValue < 8) return 8;
    }
    return newValue;
  };

  return (
    <FourQuadrantsFrame>
      <div className='scoreInner'>
        {/* 4사분면 그래프 */}
        <div className='fourQuadrants'>
          <div className='fourQuadrantsInner'>
            {/* X축, Y축 설명 */}
            <div className='axis xDesc'>
              <span className='axisName'>적합</span>
              <span className='axisName bold'>프로필 적합도</span>
              <span className='axisName'>매우 적합</span>
            </div>
            <div className='axis yDesc'>
              <span className='axisName'>적합</span>
              <span className='axisName bold'>역량 적합도</span>
              <span className='axisName'>매우 적합</span>
            </div>

            {/* 그래프 4개의 영역 */}
            {/*
            [D] 설명
            1. 순서대로 2,1,3,4사분면
            2. 활성화된 영역은 class 'on' 추가
            3. 점 dot 은 %로 위치 조정
            4. dot에 따라 툴팁 위치 조정 개발이 필요함
          */}

            <div className='fourQuadrantsGraph'>
              {/* 점 */}
              <div className='dotInner' style={{ top: `${100 - correctedCompetencyScore}%`, left: `${correctedProfileScore}%` }}>
                <div className='dot'>
                  <img src={IconDot} alt='' className='dot-color' />
                  <div className='dotTooltip' style={{ top: `${tooltipPosition.top}px`, left: `${tooltipPosition.left}px` }}>
                    <span className='dotTooltipTitle'>{login.userInfo?.name}님의 매칭 적합도<br /><span className='score'>:{graphPositionText}</span></span>
                    {profileScore && competencyScore && (
                      <div className='dotTooltipDesc'>
                        프로필 적합도<span className='score'>{profileScore >= 3000 && '매우 '}적합</span>
                        <br />
                        역량 적합도<span className='score'>{competencyScore >= 3000 && '매우 '}적합</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className={`quadrant ${graphPosition === QuadrantType.Excellent && 'on'}`}>탁월한 역량</div>
              <div className={`quadrant ${graphPosition === QuadrantType.Optimal && 'on'}`}>최적의 인재</div>
              <div className={`quadrant ${graphPosition === QuadrantType.Potential && 'on'}`}>잠재력</div>
              <div className={`quadrant ${graphPosition === QuadrantType.CompanyPreference && 'on'}`}>기업 선호 부합</div>
            </div>
          </div>
        </div>
      </div>
    </FourQuadrantsFrame>
  );
};

export default inject(injectStore.matchReportModel, injectStore.login)(observer(CompanyReportFourQuadrants));
