import { request } from 'utils/pcRequest';
import { maskCompanyName } from './utils';
import {
  DeleteInterviewReservationRq,
  DeleteInterviewReservationRs,
  GetInterviewReservationRq,
  GetInterviewReservationRs,
  GetInterviewReservationsRs,
  GetIntervieweeRs,
  IntervieweeVO,
  PostInterviewReservationRq,
  PostInterviewReservationRs,
  PostLogRq,
  PostSignInRq,
  PostSignInRs,
} from './types';

const baseUrl = 'https://api-gateway-plan-matching-ceb41fwj.an.gateway.dev';
const endDate = new Date('2024-04-26T18:00:00');

export const postSignIn = (body: PostSignInRq, options?: { onSuccess: (data: PostSignInRs) => void }) => {
  return request<PostSignInRs>(`${baseUrl}/sign-in`, {
    method: 'POST',
    body: JSON.stringify(body),
    ...options,
  });
};

export const getInterviewReservations = async () => {
  const data = await request<GetInterviewReservationsRs>(`${baseUrl}/interview-reservations`, { method: 'GET' });
  return data?.map((item) => ({
    intervieweeCode: item['interviewee-code'],
    intervieweeName: item['interviewee-name'],
    intervieweePosition: item['interviewee-position'],
    companyCode: item['company-code'],
    companyName: item['company-name'],
    maskedCompanyName: maskCompanyName(item['company-name']),
    email: item.email,
    manager: item.manager,
  }));
};

export const postInterviewReservation = (
  body: PostInterviewReservationRq,
  options?: { onSuccess?: () => void; onError?: (e: Error) => void },
) => {
  return request<PostInterviewReservationRs>(`${baseUrl}/interview-reservation`, {
    method: 'POST',
    body: JSON.stringify(body),
    endDate,
    ...options,
  });
};

export const deleteInterviewReservation = (
  body: DeleteInterviewReservationRq,
  options?: { onSuccess?: () => void; onError?: (e: Error) => void },
) => {
  return request<DeleteInterviewReservationRs>(`${baseUrl}/interview-reservation`, {
    method: 'DELETE',
    body: JSON.stringify(body),
    endDate,
    ...options,
  });
};

export const getInterviewReservation = async (params: GetInterviewReservationRq) => {
  const data = await request<GetInterviewReservationRs>(`${baseUrl}/interview-reservation`, {
    method: 'GET',
    params,
  });
  return data?.map((item) => ({
    intervieweeCode: item['interviewee-code'],
    intervieweeName: item['interviewee-name'],
    intervieweePosition: item['interviewee-position'],
    companyCode: item['company-code'],
    companyName: item['company-name'],
    maskedCompanyName: maskCompanyName(item['company-name']),
    email: item.email,
    manager: item.manager,
  }));
};

export const getInterviewee = async () => {
  const data = await request<GetIntervieweeRs>(`${baseUrl}/interviewee`, { method: 'GET' });
  return data?.map<IntervieweeVO>((item) => ({
    sn: item.sn,
    code: item.code,
    name: item.name,
    position: item.position,
    thumbnailUrl: item.thumbnail_url,
    profileUrl: item.profile_url,
    portfolioUrl: item.portfolio_url,
    reservations: [],
  }));
};

export const postLog = async (body: PostLogRq) => {
  return request(`${baseUrl}/log`, {
    method: 'POST',
    body: JSON.stringify(body),
  });
};

export const putCompanyConfirm = async (
  body: { 'company-code': string; confirmed: boolean },
  options?: { onSuccess: (data: PostSignInRs) => void },
) => {
  return request(`${baseUrl}/company-confirm`, {
    method: 'PUT',
    body: JSON.stringify(body),
    ...options,
  });
};
