import React from 'react';
import Modal from '../../modal';
import Icon  from '__designkit__/icon/Icon';
import Colors from '__designkit__/common/colors';
import classnames from 'classnames/bind';
import styles from './videoModal.module.scss';
const cx = classnames.bind(styles);

interface VideoModalProps {
  visible: boolean;
  youtubeURL: string;
  handleClose: () => void;
}

const VideoModal = ({ visible, youtubeURL, handleClose }: VideoModalProps) => {
  return (
    <Modal visible={visible}>
      <div className={cx('layer')}>
        <iframe
          src={youtubeURL}
          className={cx('videoArea')}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
          allowFullScreen
        />
        <a className={cx('btnClose')} role="button" onClick={handleClose}>
          <Icon width={40} height={40} name={'close'}  color={Colors.WHITE_100}/>
          <span className={cx('hidden')}>close</span>
        </a>
      </div>
    </Modal>
  );
};

export default VideoModal;
