import React from 'react';
import Slider from 'react-slick';
import Icon from '__designkit__/icon/Icon';
import contentsImg1 from '__pc__/page/accaIntroduce/@assets/re1.png';
import contentsImg2 from '__pc__/page/accaIntroduce/@assets/re2.png';
import contentsImg3 from '__pc__/page/accaIntroduce/@assets/bg1.png';
import contentsImg4 from '__pc__/page/accaIntroduce/@assets/bg2.png';
import classnames from 'classnames/bind';
import JDALink from 'components/JDALink';
import RoutePaths from 'consts/RoutePaths';
import styles from './introBox.module.scss';

const cx = classnames.bind(styles);

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: false,
  fade: true,
  arrows: false,
};

interface IntroBoxProps {
  fadeIn: boolean;
}

const IntroBox = ({ fadeIn }: IntroBoxProps) => (
  // 역량검사 소개 영역
  <div className={cx('sectionInner', { on: fadeIn })}>
    <p className={cx('mainTitle')}>
      <span className={cx('mainSmall')}>
        딱! 한 번만 응시해도
      </span>
      <br />
      취업이 쉬워져요.
    </p>
    <div className={cx('boxInner')}>
      <div className={cx('box', 'fullWidth')}>
        <p className={cx('title')}>
          <em className={cx('titleLight')}>역량검사 결과표를</em>자기소개서와 면접에<br />바로 활용할 수 있어요.
        </p>
        <span className={cx('subTitle')}>
          결과표를 통해 나의 강약점과 역량 유형을<br />확인하고
          면접 가이드를 활용하여<br />간편한 취업준비를 경험해보세요.

          <JDALink to={RoutePaths.acca_test} className={cx('link','acca_intro_mTest')}>
            <div className={cx('linkText')}>매월 실전 응시기회 제공</div>
            <Icon name='arrow-right' size={24} />
          </JDALink>

        </span>
        <div style={{ width: `100%` }}>
          <Slider className={cx('sliderInner')} {...settings}>
            <div className={cx('imgInner')}>
              <img className={cx('boxImg')} src={contentsImg1} alt='역량검사 소개 이미지' />
              <div className={cx('boxShadow')} />
            </div>
            <div className={cx('imgInner')}>
              <img className={cx('boxImg')} src={contentsImg2} alt='역량검사 소개 이미지' />
              <div className={cx('boxShadow')} />
            </div>
          </Slider>
        </div>

      </div>
      <div className={cx('box', 'halfWidth')}>
        <p className={cx('title')}>
          <em className={cx('titleLight')}>나의 역량을 알아주는 회사가</em>먼저 면접을 제안해요.
        </p>
        <span className={cx('subTitle')}>
          역량검사를 응시하고 프로필을 입력하면
          <br />
          나의 역량을 기반으로
          <br />
          적합한 기업, 직무를 매칭해드려요.
        </span>
        <JDALink to={RoutePaths.match_introduce} className={cx('link','acca_intro_mMatch')}>
          <div className={cx('linkText')}>잡다매칭 자세히보기</div>
          <Icon name='arrow-right' size={24} />
        </JDALink>
        <img className={cx('boxImg')} src={contentsImg3} alt='역량검사 소개 이미지' />
      </div>
      <div className={cx('box', 'halfWidth')}>
        <p className={cx('title')}>
          <em className={cx('titleLight')}>단 한 번의 역량검사 응시로</em>무한대로 지원할 수 있어요.
        </p>
        <span className={cx('subTitle')}>
          한번 응시한 역량검사는 ‘불러오기’가 가능해요.
          <br />
          여러 기업에 지원할 경우, <br />
          잡다에 저장된 역량검사를 제출할 수 있어요.
        </span>
        <JDALink to={RoutePaths.acca_test} className={cx('link','acca_intro_mJob')}>
          <div className={cx('linkText')}>역량검사로 채용중인 공고 바로가기</div>
          <Icon name='arrow-right' size={24} />
        </JDALink>
        <img className={cx('boxImg')} src={contentsImg4} alt='역량검사 소개 이미지' />
      </div>
    </div>
  </div>
);

export default IntroBox;
