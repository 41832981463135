import JDALink from 'components/JDALink';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { lineClampOnlyOne } from 'consts/_v2/_common/style/mixins';
import React, { FC } from 'react';
import styled from 'styled-components';
import ImgDumy from 'assets/_v2/positionList/img_dumy.png';

const JobOpeningLogoCardFrame = styled.div`
    pointer-events: none;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 24px ;
    border: 1px solid ${colors.CG_40};
    border-radius: 8px;
    background-color: ${colors.WHITE_100};
    margin-bottom: 12px;
    .logo-frame{
        display: flex;
        justify-content: flex-start;

        .logo-image{
            width:48px;
            height: 48px;
        }
        .title-frame{
            display: flex;
            flex-direction: column;
            margin-left: 16px;
            .title{
                width: 60vw;
                margin-top: 2px;
                font: ${Fonts.B1_Bold};
                color:${colors.JOBDA_BLACK};
                ${lineClampOnlyOne()};
            }
            .subtitle{
                width: 60vw;
                margin-top: 8px;
                font: ${Fonts.B3_Medium};
                color:${colors.JOBDA_BLACK};
                ${lineClampOnlyOne()};
            }
        }
    }
    .date-frame{
        display: flex;
        margin-top: 12px;
        align-items: center;
        .date{
            font: ${Fonts.B3_Medium};
            color:${colors.CG_70};
        }
        .d-day-frame{
                display: flex;
                background-color: ${colors.JOBDA_WHITE};
                border:1px solid ${colors.CG_50};
                border-radius: 4px;
                justify-content: center;
                align-items: center;
                padding: 4px 6px;
                margin-left: 8px;
           .d-day-text{
                font: ${Fonts.B4_Medium};
                color:${colors.CG_90};
        }
    }
    .all-day-frame{
      display: flex;
      height: 25px;
      background-color: ${colors.WHITE_100};
      border:1px solid ${colors.CG_50};
      border-radius: 4px;
      justify-content: center;
      align-items: center;
      padding:0 7px;
      .d-day-text{
        font: ${Fonts.B4_Medium_P};
        color:${colors.CG_90};
      }
    }
    }
`;

interface IJobOpeningLogoCard {
  className?: string;
  title: string;
  subTitle: string;
  date: string;
  image:string;
  dDate:string;
  bannerLink:string;
}

const JobOpeningLogoCard: FC<{ className?: string, item: IJobOpeningLogoCard }> = ({ className, item }) => (
  <JDALink to={item.bannerLink}>
    <JobOpeningLogoCardFrame className={className}>
      <div className='logo-frame'>
        <img src={item.image || ImgDumy} className='logo-image' alt='logo' onError={(e) => e.currentTarget.src = ImgDumy} />
        <div className='title-frame'>
          <div className='title'>
            {item.title}
          </div>
          <div className='subtitle'>
            {item.subTitle}
          </div>
        </div>
      </div>
      <div className='date-frame'>
        {item.dDate
        && (
        <div className='date'>
          {item.date}
        </div>
        )}
        {item.dDate
          ? (
            <div className='d-day-frame'>
              <div className='d-day-text'>{item.dDate}</div>
            </div>
          )
          : (
            <div className='all-day-frame'>
              <div className='all-day-text'>상시채용</div>
            </div>
          )}
      </div>
    </JobOpeningLogoCardFrame>
  </JDALink>
);

export default JobOpeningLogoCard;
