import styled from 'styled-components';
import colors from '__designkit__/common/colors';
import Fonts, { fonts, fontStyle, lineHeights } from '__designkit__/common/fonts';
import { JDAButton } from 'consts/style/mixins';
import React, { FC } from 'react';
import { IconClose } from 'consts/assets/icons/iconPages';

const JDAModalHeaderFrame = styled.div`
  position: relative;
  width: 100%;
  background: inherit;
  height: 43px;
  border-bottom: 1px solid ${colors.CG_40};
  ${fonts.NOTO_13_500};
  ${lineHeights.LINEHEIGHT_1_40};
  color: ${colors.CG_80};
  display: flex;
  align-items: center;
  justify-content:center;

  .close-btn {
    z-index:1;
    position: absolute;
    right: 11px;
    top: 11px;
    width: 20px;
    height: 20px;
    background: none;
  }
`;

const JDAModalHeader: FC<{ isCloseBtn?: boolean, onClickClose?: () => void }> = ({ isCloseBtn, onClickClose, ...props }) => (
  <JDAModalHeaderFrame>
    <span>{props.children}</span>
    {isCloseBtn && <button className='close-btn' onClick={onClickClose}><IconClose /></button>}
  </JDAModalHeaderFrame>
);

const JDAModalContentText = styled.div`
  text-align: center;
  ${fontStyle.BODY1_SEMIBOLD_P};
  color: ${colors.CG_80};
`;

const JDAModalContentDescription = styled.div`
  text-align: center;
  font: ${Fonts.B1_Medium_P};
  color: ${colors.CG_70};
`;

const JDAModalContentDescriptionTip = styled.div`
  font: ${Fonts.B3_Medium_P};
  color: ${colors.CG_60};
`;

const JDAModalUnderlineText = styled.span`
  text-align: center;
  ${fonts.NOTO_13_400};
  ${lineHeights.LINEHEIGHT_1_40};
  color: ${colors.CG_60};
  border-bottom: 1px solid ${colors.CG_60}; 
`;

const JDAModalMultipleButtonFrame = styled.div`
  width:100%;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  align-items:center;
  
  button {
    margin-left: 12px;
    &:first-child {
      margin: 0;
    }
  }
`;

const JDAModalButton = styled.button`
  ${JDAButton(false, true)};
  height:44px;
  flex:1;
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  font: ${Fonts.B1_Bold};
  border-radius:4px;
  border-width: 0px;
`;

const JDAModalButtonGhost = styled(JDAModalButton)`
  ${JDAButton(true, true)};
  border-width: 2px;
`;

const JDAAlertContent = styled.p`
    font: ${Fonts.B2_Medium};
    color: ${colors.ERROR};
    margin-bottom: 16px;
`;

export { JDAModalHeader, JDAModalContentText, JDAModalContentDescription, JDAModalUnderlineText, JDAModalContentDescriptionTip, JDAModalMultipleButtonFrame, JDAModalButton, JDAModalButtonGhost, JDAAlertContent };
