import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import ImgJobInfo from 'assets/_v2/match/img_match_apply_job_info.png';
import ImgJobDevInfo from 'assets/_v2/match/img_match_apply_job_dev_info.png';
import Loading from 'components/Loading';
import MatchApplyCardApplyBox from 'components/_v2/matchApply/MatchApplyCardApplyBox';
import MatchApplyCardConditionBox from 'components/_v2/matchApply/MatchApplyCardConditionBox';
import MatchApplyCardTag from 'components/_v2/matchApply/MatchApplyCardTag';
import MatchApplyJob from 'components/_v2/matchApply/MatchApplyJob';
import ProfilePhoto from 'components/_v2/profile/baseInfo/ProfilePhoto';
import RoutePaths from 'consts/RoutePaths';
import { ProfileType } from 'consts/_v2/profile/ProfileType';
import IMatchApplyProps from 'interfaces/_v2/matchApply/IMatchApplyProps';
import { inject, observer } from 'mobx-react';
import TermsModel from 'models/TermsModel';
import MatchApplyModel from 'models/_v2/MatchApplyModel';
import ProfileBasicInfoModel from 'models/_v2/profile/ProfileBasicInfoModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { LoggingType } from 'consts/_v2/LoggingType';
import Login from 'models/Login';

const Frame = styled.div`
  padding: 0 16px;
`;

const ProfileTypeTabBox = styled.div`
    display: flex;
    padding: 4px;
    flex: 1;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: 1px solid ${colors.CG_40};
    border-radius: 4px;

`;

const Tab = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    border-radius: 4px;
    font: ${Fonts.B3_Medium};
    color: ${colors.CG_70};
    width: 100%;

    &[aria-selected=true] {
        background: ${colors.JOBDA_BLACK};
        color: ${colors.WHITE_100};
    }
`;

const CardContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 32px 0 20px 0;
  border-radius: 16px;
  background-color: ${colors.WHITE_100};

  .profile-info{
    width: 100%;
    margin-bottom: 24px;
    padding: 0 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .name {
      font: ${Fonts.H3_Bold};
      color: ${colors.JOBDA_BLACK};
      padding-left: 6px;
    }
  }
`;

// Skeleton design
const SkeletonTabBox = styled.div`
    display: flex;
    padding: 4px;
    flex: 1;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: 1px solid ${colors.CG_40};
    border-radius: 4px;
    background-color: ${colors.CG_30};
`;

const SkeletonCardContent = styled.div`
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 24px 0;
  border-radius: 16px;
  background-color: ${colors.WHITE_100};

  .skeleton-info {
    width: 100%;
    height: 96px;
    margin-bottom: 24px;
    padding: 0 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${colors.CG_30};
  }

  .skeleton-box {
    width: 100%;
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    border: 1px solid ${colors.CG_50};
    border-radius: 8px;
    margin-bottom: 12px;
    height: 100px;
    background-color: ${colors.CG_30};
  }
`;

const SkeletonCard = () => (
  <Frame>
    <SkeletonTabBox>
      <Tab />
      <Tab />
    </SkeletonTabBox>
    <SkeletonCardContent>
      <>
        <div className='skeleton-info' />
        <div className='skeleton-box' />
        <div className='skeleton-box' />
      </>
    </SkeletonCardContent>
  </Frame>
);

interface IMatchApplyCardProps extends IMatchApplyProps {
    profileBasicInfoModel?: ProfileBasicInfoModel;
}

const MatchApplyCard: FC<IMatchApplyCardProps> = ({ login = new Login(), matchApplyModel = new MatchApplyModel(), profileBasicInfoModel = new ProfileBasicInfoModel(), termsModel = new TermsModel() }) => {
  const [isOpenJobModal, setIsOpenJobModal] = useState<boolean>(false);
  const [currentProfileType, setCurrentProfileType] = useState<ProfileType>(ProfileType.GENERAL);
  const [jobConditionYn, setJobConditionYn] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    setLoading(true);
    await matchApplyModel.loadMatchingProfile();
    await checkProfileType();
    setLoading(false);
  };

  const checkProfileType = async () => {
    await Promise.all([matchApplyModel.loadProfileType(), login.loadProfileType()]);

    if (matchApplyModel.profileType) {
      if (matchApplyModel.profileType.developerYn && !matchApplyModel.completedDevelopYn) {
        setCurrentProfileType(ProfileType.DEVELOPER);
      } else if (matchApplyModel.profileType.generalYn && !matchApplyModel.completedGeneralYn) {
        setCurrentProfileType(ProfileType.GENERAL);
      } else if (matchApplyModel.profileType.developerYn) {
        setCurrentProfileType(ProfileType.DEVELOPER);
      } else {
        setCurrentProfileType(ProfileType.GENERAL);
      }
    }
    await profileBasicInfoModel.loadBaseInfo();
  };

  useEffect(() => {
    if (currentProfileType === ProfileType.GENERAL) {
      if (!matchApplyModel.profileType?.generalYn) setJobConditionYn(false);
      else setJobConditionYn(true);
    }

    if (currentProfileType === ProfileType.DEVELOPER) {
      if (!matchApplyModel.profileType?.developerYn) setJobConditionYn(false);
      else setJobConditionYn(true);
    }
  }, [matchApplyModel.profileType, currentProfileType]);

  const clickJobModal = async () => {
    // 동의문 체크
    setIsOpenJobModal(true);
  };

  if (loading) return <Loading />;
  return (
    <>
      <Frame>
        <ProfileTypeTabBox>
          <Tab aria-selected={currentProfileType === ProfileType.GENERAL} onClick={() => setCurrentProfileType(ProfileType.GENERAL)}>일반 직군</Tab>
          <Tab aria-selected={currentProfileType === ProfileType.DEVELOPER} onClick={() => setCurrentProfileType(ProfileType.DEVELOPER)}>개발 직군</Tab>
        </ProfileTypeTabBox>
        <CardContent>
          {jobConditionYn
            ? (
              <>
                <div className='profile-info'>
                  <div>
                    <div className='name'>{profileBasicInfoModel.baseInfo?.name}님</div>
                    <MatchApplyCardTag currentProfileType={currentProfileType} jobConditionYn={jobConditionYn} />
                  </div>
                  <ProfilePhoto autoSavePhoto />
                </div>

                <MatchApplyCardConditionBox
                  title='내 프로필'
                  subTitle='기본, 선호, 학력 정보를 입력해 주세요.'
                  conditionYn={matchApplyModel.profile?.profileRequiredCompleteYn || false}
                  profileYn
                  handleClick={() => { history.push(RoutePaths.profile_source(LoggingType.MATCH)); }}
                />
                <MatchApplyCardApplyBox
                  currentProfileType={currentProfileType}
                />
              </>
            )
            : (
              <button onClick={clickJobModal}>
                <img src={currentProfileType === ProfileType.GENERAL ? ImgJobInfo : ImgJobDevInfo} alt='job-info' />
              </button>
            )}

        </CardContent>
      </Frame>
      { isOpenJobModal && <MatchApplyJob modalType={currentProfileType} handleSuccess={checkProfileType} handleClose={() => setIsOpenJobModal(false)} /> }
    </>
  );
};

export default inject(injectStore.login, injectStore.matchApplyModel, injectStore.profileBasicInfoModel, injectStore.termsModel)(observer(MatchApplyCard));
