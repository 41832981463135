import iconArrowDown from 'assets/icon/icon_arrow_down.svg';
import iconArrowDownGreen from 'assets/icon/icon_arrow_down_green.svg';
import iconArrowDownSmall from 'assets/icon/icon_arrow_down_small.svg';
import iconArrowUp from 'assets/icon/icon_arrow_up.svg';
import iconArrowUpGreen from 'assets/icon/icon_arrow_up_green.svg';
import iconArrowUpSmall from 'assets/icon/icon_arrow_up_small.svg';
import iconBasket from 'assets/icon/icon_basket_16_Regular.svg';
import iconBookMark24 from 'assets/icon/icon_bookmark_24_Regular.svg';
import iconBookMarkActive24 from 'assets/icon/icon_bookmark_24_Regular_active.svg';
import iconBuilding from 'assets/icon/icon_building_24.svg';
import iconCheckCircle24Regular from 'assets/icon/icon_check_circle_24_Regular.svg';
import iconClose16 from 'assets/icon/icon_close_16.svg';
import iconClose20 from 'assets/icon/icon_close_20_Regular.svg';
import iconClose20RegularGreen from 'assets/icon/icon_close_20_Regular_green.svg';
import iconCloseW20 from 'assets/icon/icon_close_20_w.svg';
import iconClose from 'assets/icon/icon_close_24.svg';
import iconCloseW from 'assets/icon/icon_close_24_w.svg';
import iconCommentOpen24RegularGreen from 'assets/icon/icon_comment_open_24_Regular_green.svg';
import iconCultureArrow from 'assets/icon/icon_culutre_arrow.svg';
import iconCursorPointer from 'assets/icon/icon_cursor_pointer.svg';
import iconCursorPointerEffect from 'assets/icon/icon_cursor_pointer_effect.svg';
import iconDocument from 'assets/icon/icon_document_regular.svg';
import iconDocumentActive from 'assets/icon/icon_document_regular_active.svg';
import { ReactComponent as IconSVGDown } from 'assets/icon/icon_down_simple_16_Regular.svg';
import iconError24RegularGreen from 'assets/icon/icon_error_24_Regular_green.svg';
import iconEventInfo from 'assets/icon/icon_event_info.svg';
import iconFile from 'assets/icon/icon_file_16_Regular.svg';
import iconHeaderHope24Regular from 'assets/icon/icon_header_hope_24_Regular.svg';
import iconHeaderHope24Filled from 'assets/icon/icon_header_hope_28_Filled.svg';
import iconHeaderShare24Regular from 'assets/icon/icon_header_share_24_Regular.svg';
import iconHope from 'assets/icon/icon_hope.svg';
import iconHopeRegular from 'assets/icon/icon_hope_24_Regular.svg';
import iconHopeRegularGrey from 'assets/icon/icon_hope_24_Regular_grey.svg';
import iconInformationCircle16RgularDarkGrey from 'assets/icon/icon_information_circle_16_Regular.svg';
import iconInfoCircleGrey from 'assets/icon/icon_information_circle_20_grey.svg';
import iconInformationCircle24Regular from 'assets/icon/icon_information_circle_24_Regular.svg';
import iconInfoB from 'assets/icon/icon_info_b.svg';
import iconKey24RegularGreen from 'assets/icon/icon_key_24_Regular_green.svg';
import iconLeftSimpleRound20Regular from 'assets/icon/icon_left_simple_round_20_Regular.svg';
import iconListView from 'assets/icon/icon_listview_24_Regular.svg';
import iconListViewOpen from 'assets/icon/icon_listview_open_24_Regular.svg';
import iconList from 'assets/icon/icon_list_24.svg';
import iconShareEmail from 'assets/icon/icon_pages_button_shareemail_enabled.svg';
import iconShareMore from 'assets/icon/icon_pages_button_sharemore_enabled.svg';
import iconShare from 'assets/icon/icon_pages_button_share_enabled.svg';
import iconPlus24 from 'assets/icon/icon_plus_24.svg';
import iconPlusCircleRegular from 'assets/icon/icon_plus_circle_24_Regular.svg';
import iconRefresh from 'assets/icon/icon_refresh_20_regular.svg';
import iconReviewSmile from 'assets/icon/icon_review_smile.svg';
import iconReviewSmileActive from 'assets/icon/icon_review_smile_active.svg';
import iconRightSimple16Regular from 'assets/icon/icon_right_simple_16_Regular.svg';
import iconRightSimpleRound20Regular from 'assets/icon/icon_right_simple_round_20_Regular.svg';
import iconShareCG from 'assets/icon/icon_share_cg.svg';
import iconShareUrl from 'assets/icon/icon_share_url.svg';
import iconSkeletonJDALogo from 'assets/icon/icon_skeleton_jda_logo.svg';
import iconSlideArrow from 'assets/icon/icon_slide_arrow_20.svg';
import iconStarSmileActive from 'assets/icon/icon_star_smile_active_btn.svg';
import iconStarSmile from 'assets/icon/icon_star_smile_btn.svg';
import iconTalk from 'assets/icon/icon_talk_regular.svg';
import iconTalkActive from 'assets/icon/icon_talk_regular_active.svg';
import { ReactComponent as IconSVGUp } from 'assets/icon/icon_up_simple_16_Regular.svg';
import iconArrowGreen from 'assets/_v2/matchHome/icon_arrow_right_green.svg';
import iconArrowGrey from 'assets/_v2/matchHome/icon_arrow_right_grey.svg';
import iconBag from 'assets/_v2/positionJd/icon_bag.svg';
import iconLocationMark from 'assets/_v2/positionJd/icon_location.svg';
import iconBasket20 from 'assets/_v2/profile/icon_trashbin.svg';
import iconShareFacebook from 'assets/_v2/share/icon_share_facebook.svg';
import iconShareKakao from 'assets/_v2/share/icon_share_kakao.svg';
import iconShareLink from 'assets/_v2/share/icon_share_url.svg';
import iconBookMarkBlack from 'assets/_v2/_common/icon_bookmark_black.svg';
import iconCalendar from 'assets/_v2/_common/icon_calendar.svg';
import iconCheck from 'assets/_v2/_common/icon_check.svg';
import iconLeftArrowGrey from 'assets/_v2/_common/icon_profile_arrow_left.svg';
import iconRightArrowGrey from 'assets/_v2/_common/icon_profile_arrow_right.svg';
import iconTime from 'assets/_v2/_common/icon_time.svg';
import iconInfoCircleV2 from 'assets/_v2/_common/icon_toast_info.svg';
import iconBookMark from 'assets/_v2/_common/icon_v2_bookmark.svg';
import iconBookMarkActive from 'assets/_v2/_common/icon_v2_bookmark_active_green.svg';
import iconDownSimpleArrowB from 'assets/_v2/_common/input/icon_down_simple_black.svg';
import iconRightSimpleArrowB from 'assets/_v2/_common/input/icon_right_simple_black.svg';
import React, { FC, forwardRef, SVGProps } from 'react';
import styled from 'styled-components';
import Colors from '__designkit__/common/colors';
import OnOffIcon, { DefaultImgHTMLAttributes, OnOffImgHTMLAttributes } from './iconModule';

export const IconLogo: FC<SVGProps<SVGSVGElement>> = ((props) => (
  <svg width='63' height='14' viewBox='0 0 54 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_19_12373)'>
      <path d='M37.6877 0.230469C39.2729 0.230469 40.5956 0.778239 41.6559 1.87378C42.7161 2.96931 43.2489 4.32015 43.2541 5.9263C43.2541 7.53245 42.7232 8.88367 41.6615 9.97994C40.5997 11.0762 39.277 11.6243 37.6933 11.6243H33.8526C33.7933 11.6243 33.7364 11.6011 33.6944 11.5597C33.6525 11.5183 33.6289 11.4622 33.6289 11.4036V0.451195C33.6289 0.392655 33.6525 0.336512 33.6944 0.295118C33.7364 0.253724 33.7933 0.230469 33.8526 0.230469H37.6877ZM37.6877 9.47778C38.6891 9.47778 39.5033 9.14927 40.1303 8.49224C40.7574 7.83522 41.0709 6.98064 41.0709 5.92851C41.0709 4.87565 40.7574 4.0207 40.1303 3.36368C39.5033 2.70665 38.6891 2.3785 37.6877 2.37924H35.8982V9.47778H37.6877Z' fill='#121619' />
      <path d='M51.7309 11.4776L51.0497 9.57271H46.3054L45.6634 11.4743C45.6484 11.5181 45.6198 11.5562 45.5817 11.5831C45.5435 11.6101 45.4978 11.6245 45.4509 11.6244H43.469C43.4333 11.6244 43.3981 11.616 43.3663 11.5998C43.3345 11.5837 43.3072 11.5602 43.2865 11.5315C43.2658 11.5027 43.2523 11.4695 43.2473 11.4346C43.2423 11.3997 43.2459 11.3641 43.2576 11.3308L47.1431 0.375054C47.1586 0.33201 47.1872 0.294777 47.2251 0.268486C47.263 0.242196 47.3082 0.228145 47.3545 0.228272H49.8597C49.9058 0.228373 49.9508 0.242528 49.9884 0.268803C50.0261 0.295078 50.0545 0.332188 50.07 0.375054L53.9845 11.3308C53.9963 11.364 53.9998 11.3995 53.9949 11.4343C53.9899 11.4691 53.9766 11.5023 53.956 11.531C53.9354 11.5597 53.9082 11.5832 53.8766 11.5994C53.845 11.6157 53.8099 11.6242 53.7742 11.6244H51.94C51.8937 11.6245 51.8485 11.6104 51.8107 11.5841C51.7728 11.5578 51.7441 11.5206 51.7286 11.4776H51.7309ZM47.0446 7.4725H50.3004L48.6339 2.81628L47.0446 7.4725Z' fill='#121619' />
      <path d='M7.79984 0.214844H5.98127C5.92195 0.214844 5.86506 0.238102 5.82311 0.279496C5.78116 0.32089 5.75759 0.37703 5.75759 0.43557V8.06607C5.75759 8.55461 5.60846 8.93977 5.31022 9.22157C5.01197 9.50336 4.56087 9.64463 3.95692 9.64536C3.1125 9.64536 2.50967 9.30434 2.14282 8.62892C2.07547 8.49497 1.98154 8.37573 1.86661 8.27827C1.75168 8.18081 1.61808 8.10712 1.47374 8.06156C1.32941 8.016 1.17727 7.99951 1.02635 8.01306C0.875437 8.02661 0.728813 8.06993 0.59518 8.14045C0.461547 8.21096 0.343627 8.30724 0.248408 8.42356C0.15319 8.53989 0.0826141 8.67391 0.0408667 8.81766C-0.000880653 8.9614 -0.0129483 9.11196 0.00537654 9.2604C0.0237014 9.40884 0.0720494 9.55213 0.147549 9.68179C0.873782 11.1231 2.14357 11.8438 3.95692 11.8438C5.14469 11.8438 6.1181 11.5127 6.87714 10.8505C7.63618 10.1883 8.01831 9.2602 8.02353 8.06607V0.43557C8.02353 0.37703 7.99996 0.32089 7.95801 0.279496C7.91606 0.238102 7.85916 0.214844 7.79984 0.214844Z' fill='#121619' />
      <path d='M15.3751 0C13.7026 0 12.286 0.572416 11.125 1.71725C9.96411 2.86208 9.38402 4.26516 9.38477 5.92649C9.38477 7.58782 9.96486 8.99054 11.125 10.1346C12.2852 11.2787 13.7019 11.8515 15.3751 11.853C17.0468 11.853 18.4657 11.2802 19.6318 10.1346C20.798 8.98907 21.381 7.58635 21.381 5.92649C21.381 4.26516 20.798 2.86208 19.6318 1.71725C18.4657 0.572416 17.0468 0 15.3751 0ZM18.0313 8.60831C17.3163 9.30801 16.4309 9.65786 15.3751 9.65786C14.3193 9.65786 13.4346 9.30691 12.721 8.605C12.0052 7.90383 11.6477 7.01099 11.6485 5.92649C11.6492 4.84199 12.0067 3.94658 12.721 3.24026C13.4341 2.5612 14.3864 2.18171 15.3773 2.18171C16.3683 2.18171 17.3205 2.5612 18.0336 3.24026C18.7486 3.94584 19.1062 4.84126 19.1062 5.92649C19.1062 7.01173 18.7486 7.90457 18.0336 8.605' fill='#121619' />
      <path d='M30.6161 5.71551C31.3438 5.13868 31.7073 4.37828 31.7065 3.43431C31.7181 3.00042 31.6334 2.56929 31.4581 2.17119C31.2829 1.77309 31.0214 1.41768 30.6921 1.12993C30.0151 0.530288 29.1826 0.230469 28.1947 0.230469H22.938C22.8787 0.230469 22.8218 0.253724 22.7799 0.295118C22.7379 0.336512 22.7144 0.392655 22.7144 0.451195V11.4058C22.7144 11.4644 22.7379 11.5205 22.7799 11.5619C22.8218 11.6033 22.8787 11.6266 22.938 11.6266H28.5302C29.5427 11.6266 30.3957 11.3179 31.0892 10.7006C31.7815 10.0793 32.1282 9.28906 32.1282 8.32118C32.1282 7.16016 31.6219 6.29271 30.6093 5.71882L30.6161 5.71551ZM29.4909 9.14559C29.3668 9.27113 29.2177 9.36981 29.0528 9.43536C28.888 9.50092 28.7112 9.5319 28.5335 9.52634H24.9971V2.32737H28.1947C28.3609 2.32184 28.5264 2.35082 28.6805 2.41241C28.8346 2.47401 28.9739 2.56685 29.0894 2.68494C29.2043 2.80434 29.2941 2.945 29.3536 3.09881C29.4131 3.25261 29.4411 3.41653 29.4361 3.58109C29.441 3.7463 29.4122 3.9108 29.3513 4.06477C29.2904 4.21873 29.1986 4.35902 29.0816 4.47724C28.9676 4.59384 28.8303 4.68583 28.6784 4.74738C28.5265 4.80893 28.3633 4.8387 28.1991 4.83481H27.0024C26.9849 4.83369 26.9673 4.83369 26.9498 4.83481C26.8136 4.83035 26.6778 4.85298 26.5506 4.90136C26.4233 4.94974 26.3073 5.02288 26.2093 5.11643C26.1113 5.20997 26.0333 5.32201 25.9801 5.44588C25.9269 5.56975 25.8995 5.70292 25.8995 5.83746C25.8995 5.972 25.9269 6.10517 25.9801 6.22904C26.0333 6.35291 26.1113 6.46495 26.2093 6.5585C26.3073 6.65204 26.4233 6.72518 26.5506 6.77356C26.6778 6.82194 26.8136 6.84457 26.9498 6.84011H28.5436C28.7213 6.83524 28.8982 6.86676 29.0629 6.93267C29.2277 6.99858 29.3769 7.09745 29.501 7.22307C29.6257 7.34872 29.7234 7.49797 29.7882 7.66184C29.8529 7.8257 29.8835 8.0008 29.8779 8.1766C29.8837 8.35497 29.8529 8.53264 29.7874 8.69897C29.7218 8.86529 29.6229 9.01684 29.4965 9.14449' fill='#121619' />
    </g>
    <defs>
      <clipPath id='clip0_19_12373'>
        <rect width='54' height='11.853' fill='white' />
      </clipPath>
    </defs>
  </svg>
));

export const IconArrowBack: FC<SVGProps<SVGSVGElement>> = ((props) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='#44474B' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path fillRule='evenodd' clipRule='evenodd' d='M16.9951 4.09571C17.3075 3.79896 17.3201 3.30518 17.0234 2.99281C16.7266 2.68045 16.2329 2.66779 15.9205 2.96454L7.00922 11.4303C7.00321 11.4359 6.99727 11.4416 6.9914 11.4475C6.83893 11.5993 6.76206 11.7995 6.76202 12C6.76201 12.1153 6.78739 12.2307 6.83841 12.3371C6.87577 12.4152 6.9268 12.4882 6.9914 12.5525C6.99727 12.5584 7.00321 12.5641 7.00922 12.5697L15.9205 21.0355C16.2329 21.3322 16.7266 21.3195 17.0234 21.0072C17.3201 20.6948 17.3075 20.201 16.9951 19.9043L8.67481 12L16.9951 4.09571Z' />
  </svg>
));

export const StyledIconSVG = styled.div<{ fill?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => props.fill && `
    path{
      fill:${props.fill}
    }
  `}
`;
export const IconSVGArrowUpDown: FC<SVGProps<SVGSVGElement> & { active: boolean }> = ({ active, width = 16, height = 16, fill = Colors.CG_90, ...props }) => (
  active ? <StyledIconSVG fill={fill}><IconSVGUp width={width} height={height} /></StyledIconSVG> : <StyledIconSVG fill={fill}><IconSVGDown width={width} height={height} /></StyledIconSVG>
);

export const IconSearch: FC<SVGProps<SVGSVGElement>> = ((props) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='10.5' cy='10.5' r='5.9' stroke='#121619' strokeWidth='1.2' />
    <line x1='13.9267' y1='14.806' x2='19.4243' y2='20.3036' stroke='#121619' strokeWidth='1.2' />
  </svg>
));

export const IconRefresh = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_refresh' src={iconRefresh} />
));

export const IconBasket = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} alt='icon_basket' src={iconBasket} {...props} />
));

export const IconBasket20 = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} alt='icon_basket' src={iconBasket20} {...props} />
));

export const IconCalendar = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} alt='icon_basket' src={iconCalendar} {...props} />
));

export const IconList = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_list' src={iconList} />
));

export const IconClose = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_close' src={iconClose} />
));

export const IconClose16 = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_close_16' src={iconClose16} />
));

export const IconClose20 = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_close_16' src={iconClose20} />
));

export const IconCloseW = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_close_w' src={iconCloseW} />
));

export const IconCloseW20 = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_close_w' src={iconCloseW20} />
));

export const IconPageShare = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_share' src={iconShare} />
));

export const IconPageShareCG = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_share' src={iconShareCG} />
));

export const IconPageShareKakao = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_share_kakao' src={iconShareKakao} />
));

export const IconPageShareEmail = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_share_email' src={iconShareEmail} />
));

export const IconPageShareFacebook = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_share_facebook' src={iconShareFacebook} />
));

export const IconPageShareLink = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_share_link' src={iconShareLink} />
));

export const IconPageShareURL = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_share_url' src={iconShareUrl} />
));

export const IconPageShareMore = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_share_more' src={iconShareMore} />
));

export const IconBuilding = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_building' src={iconBuilding} />
));

export const IconFile = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_file' src={iconFile} />
));

export const IconStarSmile = forwardRef<HTMLImageElement, OnOffImgHTMLAttributes>((props, ref) => (
  <OnOffIcon ref={ref} {...props} alt='icon_star_smile' active={props.active} activeSrc={iconStarSmileActive} unActiveSrc={iconStarSmile} />
));

export const IconBookmark = forwardRef<HTMLImageElement, OnOffImgHTMLAttributes>((props, ref) => (
  <OnOffIcon ref={ref} {...props} alt='icon_bookmark' active={props.active} activeSrc={iconBookMarkActive} unActiveSrc={iconBookMark} />
));

export const IconBookmarkBlackGreen = forwardRef<HTMLImageElement, OnOffImgHTMLAttributes>((props, ref) => (
  <OnOffIcon ref={ref} {...props} alt='icon_bookmark' active={props.active} activeSrc={iconBookMarkActive} unActiveSrc={iconBookMarkBlack} />
));

export const IconBookmark24 = forwardRef<HTMLImageElement, OnOffImgHTMLAttributes>((props, ref) => (
  <OnOffIcon ref={ref} {...props} alt='icon_bookmark' active={props.active} activeSrc={iconBookMarkActive24} unActiveSrc={iconBookMark24} />
));

export const IconReviewSmile = forwardRef<HTMLImageElement, OnOffImgHTMLAttributes>((props, ref) => (
  <OnOffIcon ref={ref} {...props} alt='icon_review_smile' active={props.active} activeSrc={iconReviewSmileActive} unActiveSrc={iconReviewSmile} />
));

export const IconArrowUpDown = forwardRef<HTMLImageElement, OnOffImgHTMLAttributes>((props, ref) => (
  <OnOffIcon ref={ref} {...props} alt='icon_arrow_up_down' active={props.active} activeSrc={iconArrowUp} unActiveSrc={iconArrowDown} />
));

export const IconGreenArrowUpDown = forwardRef<HTMLImageElement, OnOffImgHTMLAttributes>((props, ref) => (
  <OnOffIcon ref={ref} {...props} alt='icon_arrow_up_down' active={props.active} activeSrc={iconArrowUpGreen} unActiveSrc={iconArrowDownGreen} />
));

export const IconSmallArrowUpDown = forwardRef<HTMLImageElement, OnOffImgHTMLAttributes>((props, ref) => (
  <OnOffIcon ref={ref} {...props} alt='icon_arrow_up_down' active={props.active} activeSrc={iconArrowUpSmall} unActiveSrc={iconArrowDownSmall} />
));

export const IconActiveArrowRightDown = forwardRef<HTMLImageElement, OnOffImgHTMLAttributes>((props, ref) => (
  <OnOffIcon ref={ref} {...props} alt='icon_arrow_right_down' active={props.active} activeSrc={iconDownSimpleArrowB} unActiveSrc={iconRightSimpleArrowB} />
));

export const IconDocument = forwardRef<HTMLImageElement, OnOffImgHTMLAttributes>((props, ref) => (
  <OnOffIcon ref={ref} {...props} alt='icon_document' active={props.active} activeSrc={iconDocumentActive} unActiveSrc={iconDocument} />
));

export const IconTalk = forwardRef<HTMLImageElement, OnOffImgHTMLAttributes>((props, ref) => (
  <OnOffIcon ref={ref} {...props} alt='icon_talk' active={props.active} activeSrc={iconTalkActive} unActiveSrc={iconTalk} />
));

export const IconSlideArrow = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_slide_arrow' src={iconSlideArrow} />
));

export const IconLeftArrowGrey = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_right_arrow' src={iconLeftArrowGrey} />
));

export const IconRightArrowGrey = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_right_arrow' src={iconRightArrowGrey} />
));

export const IconHope = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes & { active?: boolean }>(({ active = false, ...props }, ref) => (
  <img ref={ref} {...props} alt='icon_hope' src={active ? iconHopeRegular : iconHope} />
));

export const IconInfoB = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_info' src={iconInfoB} />
));

export const IconHeaderHope = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes & { filled?: boolean }>(({ filled = false, ...props }, ref) => (
  <img ref={ref} {...props} alt='icon_hope' src={filled ? iconHeaderHope24Filled : iconHeaderHope24Regular} />
));

export const IconHopeRegularGrey = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_hope' src={iconHopeRegularGrey} />
));

export const IconHeaderShare = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_share' src={iconHeaderShare24Regular} />
));

export const IconInfoCircleGrey = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_info_circle_grey' src={iconInfoCircleGrey} />
));

export const IconInfoCircleDarkGrey = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_info_circle_grey' src={iconInformationCircle16RgularDarkGrey} />
));

export const IconLeftSimpleRound = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_left_simple_round' src={iconLeftSimpleRound20Regular} />
));

export const IconLeftSimpleRoundSvg: FC<SVGProps<SVGSVGElement>> = ((props) => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path fillRule='evenodd' clipRule='evenodd' d='M12.8916 2.70387C13.1284 2.96075 13.112 3.36092 12.8552 3.59766L6.55745 9.40165C6.55741 9.40168 6.55749 9.40162 6.55745 9.40165C6.20782 9.72414 6.20797 10.276 6.5573 10.5979L12.8552 16.4024C13.1121 16.6391 13.1284 17.0393 12.8916 17.2961C12.6549 17.553 12.2547 17.5693 11.9978 17.3326L5.70005 11.5282C4.80677 10.705 4.80699 9.29491 5.6999 8.4716L11.9978 2.66741C12.2547 2.43066 12.6549 2.44699 12.8916 2.70387Z' fill='current' />
  </svg>
));

export const IconRightSimpleRound = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_right_simple_round' src={iconRightSimpleRound20Regular} />
));

export const IconRightSimpleRoundSvg: FC<SVGProps<SVGSVGElement>> = ((props) => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path fillRule='evenodd' clipRule='evenodd' d='M8.00213 2.66742C7.74525 2.43067 7.34509 2.44698 7.10834 2.70386C6.87159 2.96073 6.8879 3.3609 7.14477 3.59765L13.4426 9.4021L13.4427 9.40215C13.792 9.72408 13.7922 10.2759 13.4425 10.5983L7.14479 16.4023C6.88791 16.6391 6.87158 17.0392 7.10832 17.2961C7.34507 17.553 7.74523 17.5693 8.00211 17.3326L14.2999 11.5285L14.3001 11.5284C15.193 10.7051 15.1932 9.29496 14.2999 8.47183L8.00213 2.66742Z' fill='current' />
  </svg>
));

export const IconRightSimpleGrey = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_right_simple_round_grey' src={iconRightSimple16Regular} />
));

export const IconPlus: FC<SVGProps<SVGSVGElement>> = ((props) => (
  <svg width='17' height='17' viewBox='0 0 17 17' fill='#44474B' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path fillRule='evenodd' clipRule='evenodd' d='M9.19578 0.945783C9.19578 0.561513 8.88427 0.25 8.5 0.25C8.11573 0.25 7.80422 0.561513 7.80422 0.945783V7.80422H0.945783C0.561513 7.80422 0.25 8.11573 0.25 8.5C0.25 8.88427 0.561513 9.19578 0.945783 9.19578H7.80422V16.0542C7.80422 16.4385 8.11573 16.75 8.5 16.75C8.88427 16.75 9.19578 16.4385 9.19578 16.0542V9.19578H16.0542C16.4385 9.19578 16.75 8.88427 16.75 8.5C16.75 8.11573 16.4385 7.80422 16.0542 7.80422H9.19578V0.945783Z' />
  </svg>
));

export const IconPlus24 = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_plus_24' src={iconPlus24} />
));

export const IconPlusCircle = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_plus_circle' src={iconPlusCircleRegular} />
));

export const IconCultureArrow = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_culture_arrow' src={iconCultureArrow} />
));

export const IconCursorPointer = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_cursor_pointer' src={iconCursorPointer} />
));

export const IconCursorPointerEffect = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_cursor_pointer_effect' src={iconCursorPointerEffect} />
));

export const IconListView = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_listview' src={iconListView} />
));

export const IconListviewOpen = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_listview_open' src={iconListViewOpen} />
));

export const IconSkeletonJDALogo = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_skeleton_jda_logo' src={iconSkeletonJDALogo} />
));

export const IconCultureDetailArrow: FC<SVGProps<SVGSVGElement>> = ((props) => (
  <svg width='16' height='17' viewBox='0 0 16 17' fill={Colors.CG_80} xmlns='http://www.w3.org/2000/svg' {...props}>
    <path fillRule='evenodd' clipRule='evenodd' d='M9.17863 12.9276C8.96455 13.1417 8.96455 13.4888 9.17863 13.7028C9.39272 13.9169 9.73981 13.9169 9.9539 13.7028L14.3394 9.3173C14.5535 9.10322 14.5535 8.75612 14.3394 8.54204L9.9539 4.1565C9.73981 3.94241 9.39272 3.94241 9.17864 4.1565C8.96455 4.37058 8.96455 4.71767 9.17864 4.93176L12.6284 8.38148L2.04819 8.38147C1.74543 8.38147 1.5 8.62691 1.5 8.92967C1.5 9.23243 1.74543 9.47786 2.04819 9.47786L12.6284 9.47786L9.17863 12.9276Z' />
  </svg>
));

export const IconDoubleQuotes: FC<SVGProps<SVGSVGElement>> = ((props) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill={Colors.CG_80} xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M11.5673 6.8254C11.7838 5.67477 12.1404 4.85026 12.427 4.33955C12.5416 4.14265 12.3123 3.91499 12.1086 4.0319C9.70783 5.38558 8.65711 7.35457 8.52975 8.93591C8.41513 10.3019 9.3767 11.7048 10.835 11.9571C12.2932 12.2155 13.6878 11.2802 13.9553 9.86503C14.2355 8.38829 13.1593 6.97308 11.5673 6.8254Z' />
    <path d='M5.04678 6.8254C5.26329 5.67477 5.6199 4.85026 5.90646 4.33955C6.02108 4.14265 5.79184 3.91499 5.58806 4.0319C3.18733 5.38558 2.13661 7.35457 2.00925 8.93591C1.89462 10.3019 2.85619 11.7048 4.31446 11.9571C5.77273 12.2155 7.16732 11.2802 7.43478 9.86503C7.7086 8.38829 6.63241 6.97308 5.04678 6.8254Z' />
  </svg>
));

export const IconEventInfo = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_event_info' src={iconEventInfo} />
));

export const IconCheckCircle = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_check_circle' src={iconCheckCircle24Regular} />
));

export const IconInformationCircle = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_info_circle' src={iconInformationCircle24Regular} />
));

export const IconInfoCircleV2 = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_info_circle' src={iconInfoCircleV2} />
));

export const IconKeyRegularGreen = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_key_regular_green' src={iconKey24RegularGreen} />
));

export const IconCommentOpenRegularGreen = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_comment_open_regular_green' src={iconCommentOpen24RegularGreen} />
));

export const IconErrorRegularGreen = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_error_regular_green' src={iconError24RegularGreen} />
));

export const IconCloseRegularGreen = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_close_regular_green' src={iconClose20RegularGreen} />
));

export const IconLocationMark = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_location_mark' src={iconLocationMark} />
));

export const IconCheck = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_check' src={iconCheck} />
));

export const IconBag = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_check' src={iconBag} />
));

export const IconTime = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_check' src={iconTime} />
));

export const IconArrowGreen = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_check' src={iconArrowGreen} />
));

export const IconArrowGrey = forwardRef<HTMLImageElement, DefaultImgHTMLAttributes>((props, ref) => (
  <img ref={ref} {...props} alt='icon_check' src={iconArrowGrey} />
));

export const IconHeart: FC<SVGProps<SVGSVGElement>> = ((props) => (
  <svg width='83' height='75' viewBox='0 0 83 75' fill={Colors.G_100} xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M10.7431 45.8889L10.7327 45.8786C3.7264 38.8621 0 31.5669 0 23.6764C0 10.8742 10.4068 0.474609 23.1526 0.474609C30.4344 0.474609 37.2154 3.94706 41.5425 9.66429C45.8729 3.94423 52.6657 0.474609 60.0174 0.474609C72.7688 0.474609 83.089 10.8816 82.9994 23.6839C82.9971 31.639 79.373 38.9365 72.2566 45.8887L44.8385 73.3465C42.9852 75.2025 40.0126 75.2019 38.1608 73.3425L37.1864 72.364L37.1819 72.3595C30.3815 65.531 13.4798 48.5593 10.7535 45.8991L10.7431 45.8889Z' />
  </svg>
));
