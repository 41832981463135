import IconEyesClose from 'assets/icon/icon_eyes_close_20.svg';
import IconEyesOpen from 'assets/icon/icon_eyes_open_20.svg';
import { JDBaseInputMixin } from 'consts/_v2/_common/style/mixins';
import { JDAInputFrameMessage } from 'consts/style/mixins';
import { passwordSimpleValidator } from 'consts/ValidationRule';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { forwardRef, useState } from 'react';
import { useFormContext, UseFormMethods } from 'react-hook-form';
import styled from 'styled-components/macro';

const Frame = styled.div`
  ${JDBaseInputMixin()};
  
  .input-frame{
    padding-right:33px;
    input.password {
      font-family: 'pass', 'Roboto', Helvetica, Arial, sans-serif ;
      font-size: 18px;
      &::-webkit-input-placeholder {
        transform: scale(0.77);
        transform-origin: 0 50%;
      }
      &::-moz-placeholder {
        font-size: 14px;
        opacity: 1;
      }
      &:-ms-input-placeholder {
        font-size: 14px;
        font-family: 'Roboto', Helvetica, Arial, sans-serif;
      }
    }
    .btn-eyes{
      display:block;
      position:absolute;
      top:30%;
      margin-right:20px;
      right:0;
      background: none;
    }
  }

  .message{
   ${JDAInputFrameMessage()};
  }
`;
interface IJDPasswordInput extends IComponentProps {
  fieldRef?: React.RefObject<HTMLInputElement | null>;
  name: string;
  defaultMessage?: string
  customValidator: any;
  disabled?: boolean;
  defaultValue?: string;
  useformed?: UseFormMethods<Record<string, any>>;
  className?: string;
  signUpYn?: boolean;
}

const JDPasswordInput = forwardRef((props: IJDPasswordInput) => {
  const { fieldRef, name, defaultMessage, customValidator, signUpYn, disabled, defaultValue, className, onChange, ...rest } = props;
  const useFormed = useFormContext();
  const { register, errors, watch } = useFormed;
  const [type, setType] = useState<string>('password');
  const [inputValue, setInputValue] = useState<string>('');
  const [isReadOnly, setIsReadOnly] = useState(!!signUpYn);
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (type === 'text') {
      if (passwordSimpleValidator(e.currentTarget.value)) {
        setInputValue(e.currentTarget.value);
      } else {
        e.currentTarget.value = inputValue;
      }
    }

    if (onChange) {
      props.onChange();
    }
  };

  return (
    <Frame className={`jda-input ${className || ''}`}>
      <div className={`input-frame ${errors[name] === undefined}`}>
        <input
          name={name}
          ref={function (innerRef) {
            if (fieldRef !== undefined) (fieldRef as any).current = innerRef;
            (register(innerRef, customValidator));
          }}
          className={`${watch(name) ? 'value' : ''}`}
          onChange={onChangeHandler}
          autoCapitalize='off'
          readOnly={isReadOnly} // 구글 비밀번호 자동 완성 block
          disabled={disabled}
          type={type}
          onTouchStart={() => setIsReadOnly(false)} // IOS에서 keyboard가 첫 클릭시 안나오는 부분 해결을 위해 추가
          onClick={() => setIsReadOnly(false)} // PC 접속시 클릭으로 타이핑 가능하게
          defaultValue={defaultValue}
          onFocus={(e) => {
            const val = e.target.value;
            e.target.value = '';
            e.target.value = val;
          }}
          {...rest}
        />
        <button
          type='button'
          className='btn-eyes'
          onClick={() => {
            if (type === 'password') setType('text');
            else setType('password');
            setTimeout(() => {
              fieldRef?.current?.focus();
            }, 100);
          }}
        >
          <img className='icon-eyes' alt='닫기' src={type === 'password' ? IconEyesClose : IconEyesOpen} />
        </button>
      </div>
      {(defaultMessage || errors[name]) && <h4 className={`message ${errors[name] === undefined}`}>{errors[name] ? (errors[name] as any).message : defaultMessage && defaultMessage}</h4>}
    </Frame>
  );
});

export default JDPasswordInput;
