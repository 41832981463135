import { action, observable } from 'mobx';
import request from 'utils/request';
import URIs from 'consts/URIs';
import { IPstResultsRs } from 'interfaces/_v2/phs/pst/IPstResultsRs';

export default class PstResultsModel {
    @observable pstResults:IPstResultsRs | null = null;

    @action
    loadPstResults = async (sn:number) => {
      try {
        this.pstResults = (await request({
          method: 'get',
          url: URIs.get_phs_pst_results,
          params: { sn },
        }));
        return true;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }
}
