import colors from '__designkit__/common/colors';
import { fonts, lineHeights } from '__designkit__/common/fonts';
import { JDAInputFrameBasic, JDAInputFrameMessage } from 'consts/style/mixins';
import { numberValidator } from 'consts/ValidationRule';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { forwardRef, ReactElement, useState } from 'react';
import { useFormContext, UseFormMethods } from 'react-hook-form';
import styled from 'styled-components/macro';

const Frame = styled.div`
  margin-top:8px;
  margin-bottom:8px;
  .input-frame{
    ${JDAInputFrameBasic()};
    input:disabled{
      color:${colors.CG_60};
    }
    .action-btn{
      min-width:68px;
      display:block;
      position:absolute;
      background:${colors.JOBDA_BLACK};
      ${fonts.NOTO_13_700};
      color:${colors.WHITE_100};
      ${lineHeights.LINEHEIGHT_1_50};
      top:15%;
      margin-right:16px;
      right:0;
      border-radius:4px;
      padding: 6px 10px 8px;
      :disabled{
        opacity: 0.5;
      }
      &[aria-done=true]{
        background: ${colors.CG_60};
      }
    }
  }
  .message{
   ${JDAInputFrameMessage()};
  }
`;

interface IJDInnerButtonInput extends IComponentProps {
  fieldRef?: React.RefObject<HTMLInputElement | null>;
  name: string;
  defaultMessage?: string;
  customValidator: any;
  btnLabel: string;
  onClickInnerBtn?: () => void;
  type?: string;
  disabled?: boolean;
  useformed?: UseFormMethods<Record<string, any>>;
  className?: string;
  done?: boolean;
  caption?: ReactElement;
}

const JDInnerButtonInput = forwardRef((props: IJDInnerButtonInput) => {
  const { fieldRef, name, done, defaultMessage, btnLabel, onClickInnerBtn, customValidator, type, disabled, defaultValue, className, inputMode, caption, ...rest } = props;
  const useFormed = useFormContext();
  const { register, errors, watch, clearErrors } = useFormed;
  const [inputValue, setInputValue] = useState<string>('');

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (type === 'tel') {
      if (numberValidator(e.currentTarget.value)) {
        setInputValue(e.currentTarget.value);
      } else {
        e.currentTarget.value = inputValue;
      }
    }
    if (props.onChange) {
      props.onChange();
    }
  };

  const onFocusHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    if (props.onFocus) {
      props.onFocus();
    }
    clearErrors(name);
  };

  return (
    <Frame className={`jda-input ${className || ''}`}>
      <div className={`input-frame ${errors[name] === undefined}`}>
        <input
          name={name}
          ref={(innerRef) => {
            if (fieldRef !== undefined) (fieldRef as any).current = innerRef;
            (register(innerRef, customValidator));
          }}
          className={`${watch(name) ? 'value' : ''}`}
          onChange={onChangeHandler}
          onFocus={onFocusHandler}
          disabled={!!disabled || done}
          type={type}
          {...rest}
        />
        <button
          type='button'
          aria-done={done}
          disabled={errors[name] || watch(name) === '' || disabled}
          className='action-btn'
          onClick={() => onClickInnerBtn && onClickInnerBtn()}
        >
          {btnLabel}
        </button>
      </div>
      {(defaultMessage || errors[name]) && <h4 className={`message ${errors[name] === undefined}`}>{errors[name] ? (errors[name] as any).message : defaultMessage && defaultMessage}</h4>}
      {caption}
    </Frame>
  );
});

export default JDInnerButtonInput;
