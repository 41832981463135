import IconDummy from 'assets/_v2/profile/img_profile_base_info_dumy.png';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import ILoginPageProps from 'interfaces/props/ILoginPageProps';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import React, { FC } from 'react';
import styled from 'styled-components';

const Frame = styled.article`
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:flex-start;
  padding:24px 18px;
  border-bottom:1px solid ${colors.CG_30};
  min-height:96px;

  .user-info {
    margin-left:16px;
  }
  .img-profile{
    width:56px;
    height:56px;
    display:flex;
    align-items:center;
    justify-content:center;
    border-radius: 50%;
    border: 1px solid #E7E8EA;
    object-fit: cover;
  }
  .name {
    font: ${Fonts.H3_Bold};
    color:${colors.JOBDA_BLACK};
  }
  .greetings {
    margin-top:4px;
    font: ${Fonts.B2_Medium};
    color:${colors.JOBDA_BLACK};
  }
`;

const MyPageProfile:FC<ILoginPageProps> = ({ login = new Login() }) => {
  if (!login.userInfo)
    return <></>; // TODO: 404/500로 가야할지? skeleton으로 가야할지? 어떻게할지..
  return (
    <Frame>
      <div className='thumbnail'>
        <img
          className='img-profile'
          src={login.userInfo.profileImageUrl || IconDummy}
          alt='프로필 사진'
          onError={(e) => {
            e.currentTarget.src = IconDummy;
          }}
        />
      </div>
      <div className='user-info'>
        <div className='name'>{login.userInfo.name}</div>
        <div className='greetings'>회원님, 안녕하세요!</div>
      </div>
    </Frame>
  );
};

export default inject(injectStore.login)(observer(MyPageProfile));
