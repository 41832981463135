import React, { useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

const Intro = () => {
  const [dateRange, setDateRange] = useState('');
  const [currentDate, setCurrentDate] = useState('');

  useEffect(() => {
    const now = new Date();
    const year = now.getFullYear().toString().slice(-2);
    const month = now.getMonth() + 1;
    const day = now.getDate();

    const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토'];

    const firstDayDate = new Date(now.getFullYear(), month - 1, 1);
    const firstDay = `${year}.${month.toString().padStart(2, '0')}.01(${daysOfWeek[firstDayDate.getDay()]})`;

    const lastDayDate = new Date(now.getFullYear(), month, 0);
    const lastDay = lastDayDate.getDate();
    const lastDayFormatted = `${year}.${month.toString().padStart(2, '0')}.${lastDay.toString().padStart(2, '0')}(${
      daysOfWeek[lastDayDate.getDay()]
    })`;

    const currentDayFormatted = `${year}.${month.toString().padStart(2, '0')}.${day.toString().padStart(2, '0')}(${
      daysOfWeek[now.getDay()]
    })`;

    setDateRange(`${firstDay} ~ ${lastDayFormatted}`);
    setCurrentDate(currentDayFormatted);
  }, []);

  return (
    <div className={cx('wrap')}>
      <div className={cx('desc')}>
        <span className={cx('period')}>이벤트 기간</span>
        <span className={cx('date', 'part')}>24.06.01(토) ~ 24.06.28(금)</span>
      </div>
      <div className={cx('desc', 'giftDate')}>
        <span className={cx('period')}>혜택 지급</span>
        <span className={cx('date')}>이벤트 종료 후 7일 이내</span>
      </div>
    </div>
  );
};

export default Intro;
