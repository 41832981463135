import React from 'react';
import { resource } from './Resource';

declare type StandardImageProps = JSX.IntrinsicElements['img'];

interface Props extends StandardImageProps {
  src: string;
}

const Img = (props: Props) => {
  const { src, alt, ...rest } = props;

  const loadedSrc = resource.read(src, props.onError);

  return <img {...rest} alt={alt} src={loadedSrc} />;
};

export default Img;
