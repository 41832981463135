import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { lineClampBox } from 'consts/_v2/_common/style/mixins';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import Login from 'models/Login';
import JDALink from 'components/JDALink';
import RoutePaths from 'consts/RoutePaths';
import Icon from '__designkit__/icon/Icon';
import PositionListModel from 'models/_v2/PositionListModel';
import { PositionOrderType } from 'consts/_v2/position/PositionType';
import { IPositionListItem, PositionFitType } from 'interfaces/_v2/positionList/IPositionList';
import { useHistory, useLocation } from 'react-router';
import ImgDumy from 'assets/_v2/positionList/img_dumy.png';
import Context from 'models/Context';
import { IAccCenterPosition } from 'interfaces/_v2/acc/IAccGate';
import useToast from 'hooks/useToast';

const MainPersonalPositionCardFrame = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 160px;

  &:last-child { 
    margin-right: 16px;
  }
  
  .card-img {
    position: relative;
    width: 160px;
    height: 113px;
    border: 1px solid ${colors.CG_40};
    border-radius: 8px;

    .recruit-img {
      width: 100%;
      max-height: 112px;
      object-fit: cover;
      border-radius: 8px;
    }

    .bookmark-frame {
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }

  .text-frame {
    display: flex;
    flex-direction: column;
    .company {
      ${lineClampBox(1)}
      font: ${Fonts.B3_Medium};
      color: ${colors.CG_60};
      margin-bottom: 4px;
    }
    .title {
      line-height: 20px;
      max-height: 40px;
      ${lineClampBox(2)}
      font: ${Fonts.B1_Bold};
      color: ${colors.JOBDA_BLACK};
      margin-bottom: 12px;
    }
    .chip-frame{
      display: flex;
      gap:4px;
      .job-chip{
        display:flex;
        padding: 4px 6px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.05);
        background-color:${colors.G_30};
        color: ${colors.G_200};
        font:${Fonts.B4_Medium};
      }
      .location-chip{
        display:flex;
        padding: 4px 6px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.05);
        background-color:${colors.S_30};
        color: ${colors.S_300};
        font:${Fonts.B4_Medium};
      }
      .salary-chip{
        display:flex;
        padding: 4px 6px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.05);
        background-color:${colors.B_30};
        color: ${colors.B_80};
        font:${Fonts.B4_Medium};
      }
    }

    
  }
`;

const BookMarkFrame = styled.div`
  svg {
    width: 24px;
    height: 24px;
    filter: drop-shadow(0px 1px 6px rgba(34, 34, 34, 0.8));
  }
`;

interface IMainPersonalPositionCard{
  item:IPositionListItem;
  login?:Login;
  context?:Context;
  positionListModel?:PositionListModel;
}
const MainPersonalPositionCard:FC<IMainPersonalPositionCard> = ({ item, login = new Login(), context = new Context(), positionListModel = new PositionListModel() }) => {
  const [bookmark, setBookmark] = useState<boolean>(false);
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { setToastObject } = useToast();

  const instanceOfIAccCenterPosition = (
    object: any,
  ): object is IAccCenterPosition => {
    if (object === undefined) return false;
    return 'sn' in object;
  };

  const setPositionBookMark = async () => {
    try {
      if (!login.userInfo?.id) {
        context.setRedirectUrl(pathname + search);
        history.push(RoutePaths.login);
        return;
      }
      if (instanceOfIAccCenterPosition(item)) {
        await positionListModel.setMatchPositionBookMark(bookmark, item.sn);
      } else {
        await positionListModel.setMatchPositionBookMark(
          bookmark,
          item.positionSn,
        );
      }
      if (!bookmark) {
        setToastObject({ isOpen: true, type: 'INFO', message: '[지원현황] > 북마크에 저장되었어요.', subMessage: undefined });
      } else {
        setToastObject({ isOpen: true, type: 'INFO', message: '북마크가 삭제되었습니다.', subMessage: undefined });
      }
      setBookmark(!bookmark);
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    if (item.bookmarkYn === undefined) {
      setBookmark(true);
    } else {
      setBookmark(item.bookmarkYn);
    }
  }, [item.bookmarkYn]);
  return (
    <MainPersonalPositionCardFrame
      onClick={(e) => {
        history.push(RoutePaths.position_jd(item.positionSn));
        e.stopPropagation();
      }}
    >
      <div className='card-img'>
        <img
          className='recruit-img'
          alt='card'
          src={`${item.teamImageUrl}?w=320&h=224` || ImgDumy}
          onError={(e) => {
            e.currentTarget.src = ImgDumy;
          }}
        />
        <div className='bookmark-frame'>
          <BookMarkFrame
            onClick={(e) => {
              setPositionBookMark();
              e.stopPropagation();
              e.preventDefault();
            }}
          >{bookmark ? <Icon name='bookmark-filled' size={24} color={colors.WHITE_100} /> : <Icon name='bookmark' size={24} color={colors.WHITE_100} />}
          </BookMarkFrame>
        </div>
      </div>
      <div className='text-frame'>
        <div className='company'>
          {item.companyName}
        </div>
        <div className='title'>
          {item.positionName}
        </div>
        {item.fitTypes && item.fitTypes.length > 0 ? (
          <div className='chip-frame'>
            {
            item.fitTypes.includes(PositionFitType.JOB_TITLE) && <div className='job-chip'>직무 Fit</div>
          }
            {
            item.fitTypes.includes(PositionFitType.LOCATION) && <div className='location-chip'>지역 Fit</div>
          }
            {
            item.fitTypes.includes(PositionFitType.SALARY) && <div className='salary-chip'>연봉 Fit</div>
          }
          </div>
        )
          : (
            <div className='chip-frame'>
              <div className='job-chip'>인기</div>
            </div>
          )}

      </div>
    </MainPersonalPositionCardFrame>
  );
};

export default inject(injectStore.login, injectStore.positionListModel, injectStore.context)(observer(MainPersonalPositionCard));
