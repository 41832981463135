import styled from 'styled-components';
import Fonts from '__designkit__/common/fonts';
import Colors from '__designkit__/common/colors';

const JDFullModalHeader = styled.div`
  position: sticky;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid ${Colors.CG_40};
  background: ${Colors.WHITE_100};
  font: ${Fonts.H5_Bold};
  color: ${Colors.JOBDA_BLACK};

  & > div {
    position: absolute;
    right: 16px;
  }
`;

export default JDFullModalHeader;
