import React from 'react';
import classnames from 'classnames/bind';
import JDALink from 'components/JDALink';
import RoutePaths from 'consts/RoutePaths';
import Icon from '__designkit__/icon/Icon';
import Colors from '__designkit__/common/colors';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

const Banner4 = () => (
  <div className={cx('commonBox', 'bottomTag')}>
    <span className={cx('desc')}>
      역량검사까지 완료하고
      <br />
      JOBDA matching을 통해
    </span>
    <span className={cx('import')}>
      기업에서 직접
      <br />
      입사 제안받아보세요!
    </span>

    <JDALink to={RoutePaths.match_introduce} className={cx('btnMove')}>
      <span className={cx('title')}>JOBDA matching 자세히 보기</span>
      <div className={cx('btnIcon')}>
        <Icon name='arrow-upward' size={32} color={Colors.LG_100} />
      </div>
    </JDALink>
  </div>
);

export default Banner4;
