import { Button, ButtonMode } from '__designkit__/button/Button';
import colors from '__designkit__/common/colors';
import MainHeader from 'components/_v2/_common/mainHeader/MainHeader';
import Portal from 'components/common/Portal';
import { StyledTermsFrame } from 'components/modals/TermsModal';
import Error404 from 'pages/Error404';
import React, { FC } from 'react';
import styled from 'styled-components';

const FrameStyled = styled(StyledTermsFrame)`
  * {
    line-height: 1.3em !important;
  }
  b { font-weight: bold !important;}
  table td:first-child {
    max-width:80px !important;
  }
  .frame-content{
    padding:0;
  }
  .frame-terms table span,.frame-terms table b {
    /* color:inherit !important; */
  }
  .frame-terms p b span, .frame-terms p b {
    font-size:inherit !important;
  }
  span[style="font-size: 30px;"] u{
    font-size: 1.2em !important;
  }
  .frame-terms table tr:first-child td:first-child b {
    color:${colors.WHITE_100} !important;
  }
  .frame-terms table {
    table-layout:fixed;
    tr td {
      width:40% !important;
    }
  }
  .frame-terms > table {
    table-layout:fixed;
    tr td:not(:first-child) {
      width:40% !important;
    }
  }
  *[style="color: rgb(255, 0, 0);"] *,
  .frame-terms table tr:not(:first-child) td:first-child *[style="color: rgb(255, 0, 0);"] b,
  .frame-terms p *[style="color: rgb(255, 0, 0);"] b {
    color: rgb(255, 0, 0) !important;
  }
  .frame-terms p b,
  .frame-terms p b span,
  .frame-terms p b > span
  {
    font-weight:bold !important;
  }
  .button-accept{
    padding:20px;
    z-index:10;
  }
`;

interface IThirdPartyPrivacyModal{
    content?:string
    isVisible: boolean;
    onClose: () => void;
    onCheck: () => void;
}

const ThirdPartyPrivacyModal: FC<IThirdPartyPrivacyModal> = ({ content, isVisible, onCheck, onClose }) => {
  if (content === null) return <></>;
  return (
    <Portal>
      {content
        ? (
          <FrameStyled className={`${isVisible ? 'visible' : ''}`}>
            {/* <DisableScroll /> */}
            <MainHeader
              className='header'
              close
              title=''
              alarm={false}
              search={false}
              gnb={false}
              onClose={() => {
                if (onClose) {
                  onClose();
                }
              }}
              data-hide={false as any}
            />
            <div className='frame-content'>
              <div className='frame-terms' dangerouslySetInnerHTML={{ __html: content }} />
            </div>
            <div className='button-accept'>
              <Button
                size='large'
                buttonMode={ButtonMode.PRIMARY}
                onClick={() => {
                  if (onCheck) {
                    onCheck();
                  }
                }}
                label='동의하고 시작하기'
              />
            </div>
          </FrameStyled>
        )
        : (
          <>
            <MainHeader
              backable
            />
            <Error404 />
          </>
        )}

    </Portal>
  );
};

export default ThirdPartyPrivacyModal;
