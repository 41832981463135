import colors from '__designkit__/common/colors';
import { fontStyle } from '__designkit__/common/fonts';
import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import styled from 'styled-components';

const JDARadioLabel = styled.label<{['data-background']:string}>`
input[type=radio] {
  display:none;
  + .label {
    display: inline-block;
    padding:4px 11px 6px 11px;
    ${fontStyle.CAPTION1_MEDIUM};
    color:${colors.CG_80};
    background:${colors.CG_30};
    border:1px solid ${colors.CG_40};
    width:fit-content;
    transition:background .5s, border .5s;
    border-radius:15px;
  }
  &:checked + .label {
    ${fontStyle.CAPTION1_SEMIBOLD};
    color:${colors.WHITE_100};
    background:${(props) => props['data-background']};
    border:1px solid ${colors.G_100};
    width:fit-content;
  }
}
`;

interface IJDAFitlerRadioProps {
  control: Control;
  name: string;
  defaultValue?:any;
  value:any;
  text:string;
  backgroundColor?:string
}

const JDAFilterRadio:FC<IJDAFitlerRadioProps> = ({ backgroundColor = colors.G_100, control, name, defaultValue, value, text }) => (
  <Controller
    control={control}
    name={name}
    defaultValue={defaultValue}
    render={(props) => (
      <JDARadioLabel data-background={backgroundColor}>
        <input type='radio' {...props} value={value} defaultChecked checked={control.watchInternal(name) === value} />
        <span className='label'>{text}</span>
      </JDARadioLabel>
    )}
  />
);

export default JDAFilterRadio;
