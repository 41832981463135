import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import IconInfo from 'assets/_v2/_common/icon_info.svg';
import { Divider12Grey } from 'components/divider/Divider';
import IComponentProps from 'interfaces/props/IComponentProps';

const Frame = styled.div<{essentialYn?: boolean}>`
    padding: 16px 16px 32px 16px;
    .title-frame{
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
      .title {
        display: flex;
        align-items: center;
        font: ${Fonts.H5_Bold};
        line-height: 22px;
        color: ${colors.JOBDA_BLACK};
        

        ${(props) => props.essentialYn && css`
            ::after {
                content: '(필수)';
                font: ${Fonts.B2_Medium};
                color: ${colors.ERROR};
                margin-left: 4px;
            }
        `}
    }
    .tip{
      display: flex;
      align-items: center;
      font: ${Fonts.B2_Medium};
      color:${colors.B_90};
      text-decoration:underline;
    }
    }

    .subtitle-frame {
        display: flex;

        img {
            position: relative;
            top: 2px;
            width: 16px;
            height: 16px;
            margin-right: 6px;
        }

        .subtitle {
            font: ${Fonts.B2_Medium};
            color: ${colors.JOBDA_BLACK};
            letter-spacing: -0.01em;
        }
    }
`;

interface IProfileEditTitleComponent extends IComponentProps{
    title: string;
    subtitle?: string;
    essentialYn?: boolean;
    divider?:boolean;
    tip?:boolean;

}
const ProfileEditTitleComponent: FC<IProfileEditTitleComponent> = ((props) => {
  const { title, subtitle, essentialYn, divider = true, tip, style } = props;
  return (
    <>
      <Frame essentialYn={essentialYn} style={style}>
        <div className='title-frame'>
          <p className='title'>{title}</p>
          {tip && (
            <>
              {props.children}
            </>
          )}
        </div>
        {subtitle
      && (
      <div className='subtitle-frame'>
        <img src={IconInfo} alt='icon-info' />
        <p className='subtitle'>{subtitle}</p>
      </div>
      )}
      </Frame>
      {divider && <Divider12Grey />}
    </>
  );
});

export default ProfileEditTitleComponent;
