import React from 'react';
import JDALink from 'components/JDALink';
import RoutePaths from 'consts/RoutePaths';
import classnames from 'classnames/bind';
import styles from './floatingBtn.module.scss';

const cx = classnames.bind(styles);

const FloatingBtn = () => (
  <div className={cx('floatingBtn')}>
    <JDALink
      to={RoutePaths.match_apply}
      className={cx('btnBlack')}
    >
      매칭 참여하기
    </JDALink>
  </div>
);

export default FloatingBtn;
