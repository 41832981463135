import { Button, ButtonMode } from '__designkit__/button/Button';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import ChattingAPI from 'api/chattingAPI';
import JDFullModal from 'components/_v2/_common/modals/JDFullModal';
import RadioField from 'components/react-hook-form/RadioField ';
import ReasonField from 'components/react-hook-form/ReasonField';
import { CHATTING_REPORT_TYPE, CHATTING_REPORT_TYPE_TEXT } from 'consts/_v2/chatting/chatting';
import useToast from 'hooks/useToast';
import { PostReportRq } from 'interfaces/_v2/chatting/IChattingRqRs';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Message } from 'talkplus-sdk';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${Colors.WHITE_100};
`;

const HeaderFrame = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  height: 56px;
  padding: 0 16px;
`;

const ContentFrame = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-grow: 1;
  width: 100%;
  padding: 0 16px 16px;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  & > div.title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    width: 100%;
    height: fit-content;

    & > div.main {
      font: ${Fonts.H5_Bold};
      color: ${Colors.JOBDA_BLACK};
    }

    & > div.sub {
      font: ${Fonts.B2_Medium_P};
      color: ${Colors.CG_70};
      text-align: center;
    }

    & > div.more {
      font: ${Fonts.B2_Medium};
      color: ${Colors.B_100};

      & a {
        display: flex;
        align-items: center;
      }
    }
  }

  & > div.message-box {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    height: fit-content;
    padding: 16px 24px;
    border-radius: 8px;
    background-color: ${Colors.CG_30};

    & > div.item {
      display: flex;
      align-items: flex-start;
      gap: 8px;
      width: 100%;
      height: fit-content;
      color: ${Colors.JOBDA_BLACK};

      & > div.title {
        display: inline-block;
        width: 48px;
        min-width: 48px;
        font: ${Fonts.B3_Bold_P};
      }

      & > div.content {
        flex-grow: 1;
        font: ${Fonts.B3_Medium_P};
      }
    }
  }

  & > form.report-box {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    height: fit-content;

    & > div.title {
      font: ${Fonts.B1_Bold};
      color: ${Colors.JOBDA_BLACK};
    }
  }
`;

const BtnFrame = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  padding: 16px;
`;

const Label = styled.div`
  font: ${Fonts.B2_Medium};
  color: ${Colors.JOBDA_BLACK};
`;

const ReasonFrame = styled.div`
  & > div.limit {
    padding: 6px 0 0 12px;
    font: ${Fonts.B3_Medium};
    color: ${Colors.CG_70};
  }
`;

interface IChattingReportModalProps extends IComponentProps {
  messageItem: Message;
  onClickClose: () => void;
}

const ChattingReportModal: FC<IChattingReportModalProps> = ({ messageItem, onClickClose }) => {
  const { setToastObject } = useToast();

  const formMethods = useForm<PostReportRq>({ mode: 'onSubmit' });
  const { watch } = formMethods;

  const type = watch('type');
  const reason = watch('reason', '');

  const onClickReport = async () => {
    try {
      onClickClose();
      await ChattingAPI.postReport(messageItem.channelId, messageItem.id, { type, reason });
      setToastObject({ isOpen: true, type: 'INFO', message: '신고가 완료되었어요.', duration: 2500 });
    } catch (error) {
      console.error(error);
      const e = error as { response: { data: { message: string } } };
      setToastObject({ isOpen: true, type: 'ERROR', message: e.response.data.message || '알 수 없는 오류로 실패했습니다. 잠시 후에 다시 시도해 주세요.', duration: 3000 });
    }
  };

  return (
    <JDFullModal>
      <Frame>
        <HeaderFrame>
          <Icon name='close' size={32} color={Colors.JOBDA_BLACK} onClick={onClickClose} />
        </HeaderFrame>
        <ContentFrame>
          <div className='title'>
            <div className='main'>신고하기</div>
            <div className='sub'>
              다음 메시지를 정말 신고하시겠어요?<br />
              무분별한 신고 시 제재를 받을 수 있어요.
            </div>
            <div className='more'>
              <a href='https://support-jobda.notion.site/911692beb82943d19201a5ba3937fa91?pvs=4' rel='noreferrer' target='_blank'>채팅 운영정책<Icon name='arrow-right' size={24} color={Colors.B_100} /></a>
            </div>
          </div>
          <div className='message-box'>
            <div className='item'>
              <div className='title'>작성자</div>
              <div className='content'>{messageItem.username}</div>
            </div>
            <div className='item'>
              <div className='title'>내용</div>
              <div className='content'>{messageItem.text.length > 200 ? `${messageItem.text.slice(0, 200)}...` : messageItem.text}</div>
            </div>
          </div>
          <form className='report-box'>
            <FormProvider {...formMethods}>
              <div className='title'>신고 사유</div>
              { Object.keys(CHATTING_REPORT_TYPE_TEXT).map((reportType) => (reportType !== CHATTING_REPORT_TYPE.OTHER_REASON
                ? <RadioField key={reportType} name='type' value={reportType} label={<Label>{CHATTING_REPORT_TYPE_TEXT[reportType]}</Label>} />
                : (
                  <div>
                    <RadioField key={reportType} name='type' value={reportType} label={<Label>{CHATTING_REPORT_TYPE_TEXT[reportType]}</Label>} />
                    { type === CHATTING_REPORT_TYPE.OTHER_REASON && (
                      <ReasonFrame>
                        <SpacingBlock size={16} vertical />
                        <ReasonField name='reason' maxLength={120} />
                        <div className='limit'>최대 글자수 : {reason.length} / 120</div>
                      </ReasonFrame>
                    )}
                  </div>
                )
              ))}
            </FormProvider>
          </form>
        </ContentFrame>
        <BtnFrame>
          <Button label='신고하기' buttonMode={ButtonMode.PRIMARY} size='large' onClick={onClickReport} disabled={type ? type === CHATTING_REPORT_TYPE.OTHER_REASON ? !(reason.length >= 10) : false : true} />
        </BtnFrame>
      </Frame>
    </JDFullModal>
  );
};

export default ChattingReportModal;
