import { JDModalButton } from 'components/_v2/_common/modals/JDModalComponents';
import colors from '__designkit__/common/colors';
import Fonts, { fontStyle } from '__designkit__/common/fonts';
import IModalFrameProps from 'interfaces/IModalFrameProps';
import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';
import { JDAModalUnderlineText } from './JDAModalComponents';
import JDAModalFrame from './JDAModalFrame';

//* * 일림관련 모달 */

export interface INoticeModalProps extends IModalFrameProps {
  title:string|ReactElement;
  content:string|ReactElement;
  onOk:() => void;
  onCancel:() => void;
}

const ModalFrame = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:center;
  width:304px;
  padding:40px 24px 32px 24px;
  color:${colors.CG_80};
  .frame-title {
    ${fontStyle.BODY1_REGULAR};
    width:100%;
    text-align:center;
    .accent {
      ${fontStyle.BODY1_SEMIBOLD};
    }
  }
  .btn-ok {
    margin-top:32px;
    min-height:44px;
  }
  .btn-cancel {
    margin-top:15px;
  }
  .content {
    font: ${Fonts.B2_Medium};
    color:${colors.CG_70};
    margin-top:16px;
    text-align:center;
  }
`;

const NoticeModal:FC<INoticeModalProps> = ({ title, content, onOk, onCancel, ...props }) => (
  <JDAModalFrame isFrame {...props}>
    <ModalFrame>
      <div className='frame-title'>
        {title}
      </div>
      <div className='content'>
        {content}
      </div>
      <JDModalButton className='btn-ok' onClick={onOk}>
        확인
      </JDModalButton>
      <JDAModalUnderlineText className='btn-cancel' onClick={onCancel}>
        나중에 하기
      </JDAModalUnderlineText>
    </ModalFrame>
  </JDAModalFrame>
);

export default NoticeModal;
