import ChallengeCard from 'components/_v2/challenge/ChallengeCard';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import ChallengeModel from 'models/_v2/ChallengeModel';
import React, { FC } from 'react';
import styled from 'styled-components';

const ChallengeFrame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding:32px 16px;

  >*{
    margin-bottom: 32px;
  }
`;

export interface IChallengeList {
  challengeModel?: ChallengeModel;
}

const ChallengeAd: FC<IChallengeList> = ({ challengeModel = new ChallengeModel() }) => {
  const { challenges } = challengeModel;


  return (
    <ChallengeFrame>
      {
        challenges.map((item) => <ChallengeCard key={item.sn} item={item} />)
      }
    </ChallengeFrame>
  );
};
export default inject(injectStore.challengeModel)(observer(ChallengeAd));
