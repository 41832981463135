// created by khj1115 on 2021-07-30

import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

interface IInfoVideoProps {
  videoUrl: string;
}

const InfoYoutubeVideo: React.FC<IInfoVideoProps> = ({ videoUrl }) => {
  const url = useMemo(() => {
    return 'https://www.youtube.com/embed/' + videoUrl;
  }, [videoUrl]);

  return (
    <div className="frame-video">
      <iframe
        title="youtube"
        width="100%"
        height="198px"
        src={url}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
        allowFullScreen
      />
    </div>
  );
};

export default observer(InfoYoutubeVideo);
