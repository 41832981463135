import React, { useState, useCallback, ReactNode } from 'react';
import classnames from 'classnames/bind';
import styles from './faq.module.scss';

const cx = classnames.bind(styles);

const faqInfoList = [
  {
    question: '제휴 리스트에 우리 학교, 기관이 없으면 어떻게 해야하나요?',
    answer: (
      <>
        <a className={cx('formLink')} href={'https://forms.office.com/r/QfCfGwfgBN'} target='_blank' rel='noreferrer'>
          여기
        </a>
        에 소속되어있는 학교와 기관을 작성해 주세요!
        <br />
        제휴 서비스를 받을 수 있도록 잡다가 대신 전달해 드릴게요.
      </>
    ),
    type: 'type2',
  },
  {
    question: '면접 가이드는 어떤식으로 제공되나요?',
    answer: (
      <>
        잡다 면접 연습에서는 추천 답변과 유사 질문을 확인할 수 있어요.
        <br />
        추천 답변부터 지양해야 할 답변, 유사 질문까지 면접 질문에 대한
        <br />
        다양한 가이드를 제공해 드려요.
      </>
    ),
    type: 'type2',
  },
  {
    question: '잡다 면접 연습을 어떻게 활용하면 좋을까요?',
    answer: (
      <>
        잡다 면접연습에서는 질문의 순서를 마음대로 설정할 수 있어요
        <br />
        다양한 질문들의 순서를 변경해 보세요!
        <br />
        어떤 꼬리 질문에도 대비할 수 있는 나만의 질문 리스트를 만들 수 있어요!
      </>
    ),
    type: 'type2',
  },
];

interface IFaqProps {
  index: number;
  question: string;
  answer: ReactNode;
  type: string;
}

const Faq = ({ index, question, answer, type }: IFaqProps) => {
  const [isShow, setIsShow] = useState(false);

  const onClickFaq = useCallback(() => {
    setIsShow(!isShow);
  }, [isShow]);

  return (
    <li className={cx('listItem', `listItem${index + 1}`)}>
      <a className={cx('listTitle', { on: isShow })} role='button' onClick={onClickFaq}>
        <em className={cx('question')}>{question}</em>
      </a>
      <div className={cx('answer', type, { on: isShow })}>{answer}</div>
    </li>
  );
};

const FaqList = () => {
  const faqList = faqInfoList.map((item, i) => {
    return <Faq key={i} index={i} question={item.question} answer={item.answer} type={item.type} />;
  });

  return <ul className={cx('faqList')}>{faqList}</ul>;
};

export default FaqList;
