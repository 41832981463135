import fireCrackerLottie from '__designkit__/assets/lottie/lottie_firecracker.json';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import ImgEmptyJoy from 'assets/_v2/challenge/img_character_joy_empty_soon.png';
import IComponentProps from 'interfaces/props/IComponentProps';
import Lottie from 'lottie-react';
import React, { FC } from 'react';
import styled from 'styled-components';

const TotalFrame = styled.div`
  width: 100%;
  height: 100%;
`;
const Frame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;


  & .title {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    height: fit-content;

    & .sub {
      font: ${Fonts.B2_Medium};
      color: ${Colors.G_200};

      & > span {
        font: ${Fonts.B2_Bold};
      }
    }

    & .main {
      font: ${Fonts.H4_Bold};
      color: ${Colors.CG_90};
    }
  }

  & .lottie {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    width: 100%;

    & > div {
      width: 80%;
    }
  }
  
`;

const FailedFrame = styled.div`
  display: flex;
  justify-content:center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .failed-text{
    font:${Fonts.H5_Bold};
    color:${Colors.JOBDA_BLACK};
  }
  .img-joy{
    width: 100px;
    height: 100px;
    margin-bottom: 32px;
  }
`;
interface IResumeCompleteProps extends IComponentProps {
  count: number;
  failed?:boolean;
}

const ResumeComplete: FC<IResumeCompleteProps> = ({ count, failed = false }) => (
  <TotalFrame>
    {
      failed
        ? (
          <FailedFrame>
            <img src={ImgEmptyJoy} alt='empty-joy' className='img-joy' />
            <div className='failed-text'>저장된 지원서가 없어요.</div>
          </FailedFrame>
        )
        : (

          <Frame>

            <div className='title'>
              <div className='sub'>
                축하해요! <span>{count}개의 프로필 정보가</span> 추가되었어요!
              </div>
              <div className='main'>
                불러온 프로필 정보를 상세히 작성하고,<br />
                인사 담당자에게 더 많은 제안을 받아보세요!
              </div>
            </div>
            <div className='lottie'>
              <Lottie animationData={fireCrackerLottie} />
            </div>
          </Frame>
        )
    }
  </TotalFrame>
);

export default ResumeComplete;
