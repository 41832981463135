import emailDomainList from 'consts/_v2/join/emailDomainList';

const makeEmailList = (value: string) => {
  if (!value) return [];
  if (value.indexOf('@') === -1) {
    return emailDomainList.map((domain) => ({
      fullValue: `${value}@${domain}`,
      userInput: value,
    }));
  }
  const sub = value.split('@')[1];
  return emailDomainList.filter((domain) => domain.indexOf(sub) === 0).map((domain) => ({
    fullValue: `${value.split('@')[0]}@${domain}`,
    userInput: value,
  }));
};

export default makeEmailList;
