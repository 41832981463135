import React from 'react';
import classnames from 'classnames/bind';
import FlowList from './flowList';
import styles from './flowStory.module.scss';

const cx = classnames.bind(styles);

interface FlowStoryProps {
  fadeIn: boolean;
}

const FlowStory = ({ fadeIn }: FlowStoryProps) => (
  // stroy video 성공사례 영상 리스트 부분
  <div className={cx('sectionInner', 'flowInner', { on: fadeIn })}>
    <p className={cx('mainTitle')}>
      <span className={cx('mainSmall')}>JOBDA 역량검사로 취업을 준비한</span>
      <br />
      100만 명의 선배들
      <br />
      Story를 확인하세요.
    </p>

    <FlowList />
  </div>
);

export default FlowStory;
