import colors from '__designkit__/common/colors';
import React, { FC } from 'react';
import styled from 'styled-components';
import { ReactComponent as IconReset } from 'assets/_v2/_common/input/icon_reset.svg';
import { JDButton } from 'consts/_v2/_common/style/mixins';
import Fonts from '__designkit__/common/fonts';

const BtnFrame = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    height: fit-content;
    padding: 10px 16px calc(env(safe-area-inset-bottom) + 10px);
    display: flex;
    justify-content: space-between;
    align-items:center;
    background: ${colors.WHITE_100};
    box-shadow: inset 0px 0.5px 0.5px rgba(207, 209, 213, 0.5);

    .btn-search{
        ${JDButton(false, '200px')}
        font: ${Fonts.B2_Bold};
        height:46px;
        border-radius: 4px;
      }
      .btn-reset{
        display: flex;
        align-items: center;
        width: 86px;
        svg{
          margin-right:8px;
        }
        font: ${Fonts.B3_Medium};
        ${colors.JOBDA_BLACK};
      }
      .btn-select {
        ${JDButton(false, '86px')}
        font: ${Fonts.B2_Bold};
        height:46px;
        border-radius: 4px;  
      }
      
      button:disabled {
        background: ${colors.CG_40};
        color: ${colors.CG_60};
        border: none;
      }
`;

interface IFilterCommonBottom {
    handleClickFilter: () => void;
    handleClickReset: () => void;
    disabled?: boolean;
}

const FilterCommonBottom: FC<IFilterCommonBottom> = ({ handleClickFilter, handleClickReset, disabled }) => (
  <BtnFrame>
    <button className='btn-reset' onClick={() => handleClickReset()}><IconReset />필터 초기화</button>
    <button
      className='btn-search'
      onClick={() => handleClickFilter()}
      disabled={!!disabled}
    >적용
    </button>
  </BtnFrame>
);

export default FilterCommonBottom;
