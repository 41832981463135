import { Button, ButtonMode } from '__designkit__/button/Button';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import graphLottie from 'assets/lottie/resume_load_lottie.json';
import JDDimModal from 'components/_v2/_common/modals/JDDimModal';
import { InsightResumeLogRq } from 'interfaces/_v2/insight/IInsightResumeRqRs';
import IComponentProps from 'interfaces/props/IComponentProps';
import Lottie from 'lottie-react';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import { useInsightResumeLog } from 'query/resume/useResumeQuery';
import React, { FC } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import RenderCheckUtil from 'utils/RenderCheckUtil';

const Frame = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: fit-content;
  padding: 32px 24px calc(24px + env(safe-area-inset-bottom));
  border-radius: 12px 12px 0 0;
  background-color: ${Colors.WHITE_100};
  z-index: 10005;
`;
const TitleFrame = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
    .header-text{
        font:${Fonts.H4_Bold};
        color:${Colors.JOBDA_BLACK};
        margin-bottom: 8px;
        white-space: pre-line;
        text-align: center;
    }
    .sub-text{
        font:${Fonts.B2_Medium_P};
        color:${Colors.CG_70};
        margin-bottom: 178px;

    }
    .lottie-write{
      width: 240px;
      height: 240px;
      position: absolute;
      bottom:84px;
    }
`;
const BtnFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  height: fit-content;
  padding-top: 24px;

  & > div.ignore-for-week-btn {
    display: flex;
    align-items: center;
    gap: 4px;
    padding-bottom: 1px;
    border-bottom: 1px solid ${Colors.CG_80};
    font: ${Fonts.B3_Medium};
    color: ${Colors.CG_80};
  }
`;

interface IResumeNudgeModalProps extends IComponentProps {
  onClickClose: () => void;
  login?: Login;
  afterLogic: () => void;
  afterFailLogic?: () => void;
  matchDashboard?:boolean
}

const ResumeNudgeModal: FC<IResumeNudgeModalProps> = ({ matchDashboard = false, onClickClose, afterFailLogic, login = new Login(), afterLogic }) => {
  // TODO: 인재풀 인원 수 받아오기
  const { pathname } = useLocation();

  const saveSuccess = () => {
    onClickClose();
    afterLogic();
  };
  const { mutate: saveResumeInduce } = useInsightResumeLog(saveSuccess);
  const onClickBtn = async () => {
    const induceRq:InsightResumeLogRq = {
      action: 'CLICK',
      actionSource: pathname,
      actionComponent: 'btn_nudge_enter_insight_resume',
      data: {},
    };
    saveResumeInduce(induceRq);
  };

  const onClickSubBtn = async () => {
    await RenderCheckUtil.setRenderCheck('resume-induce', 7);
    if (afterFailLogic) afterFailLogic();
    onClickClose();
  };

  return (
    <JDDimModal dimClickable onClickClose={onClickClose}>
      <Frame>
        {
          login.userInfo?.name
            ? (
              <TitleFrame>
                <div className='header-text'>{`${login.userInfo?.name}님! \n저장해 놓은 지원서가 있네요?`}</div>
                <div className='sub-text'>클릭 한 번으로 내 프로필 간편히 채워보세요.</div>
                <Lottie animationData={graphLottie} className='lottie-write' />
              </TitleFrame>
            )
            : (
              <TitleFrame>
                <div className='header-text'>귀찮았던 프로필 작성<br />클릭 한번으로 업데이트 끝!</div>
                <div className='sub-text'>저장한 지원서로 내 프로필 간편히 채워보세요.</div>
                <Lottie animationData={graphLottie} className='lottie-write' />
              </TitleFrame>
            )
        }
        <BtnFrame>
          <Button className='btn_nudge_enter_insight_resume' label='지원서 불러오기' buttonMode={ButtonMode.PRIMARY} size='large' onClick={onClickBtn} />
          <div className='ignore-for-week-btn' role='button' onClick={onClickSubBtn}>
            일주일 간 보지 않기
            <Icon name='close' size={16} color={Colors.JOBDA_BLACK} />
          </div>
        </BtnFrame>
      </Frame>
    </JDDimModal>
  );
};

export default inject(injectStore.login)(observer(ResumeNudgeModal));
