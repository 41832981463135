import React, { FC, Suspense, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
// import colors from '__designkit__/common/colors';
import iconPagesEmptyLogoError from 'assets/icon/icon_pages_empty_logo_error.svg';
import basicFallbackImage from 'assets/icon/icon_pages_empty_noimage_error.svg';
import Img from 'utils/react-suspense-img/Img';
import colors from '__designkit__/common/colors';

//* * 주의! */
//* * div로 되어있고 기본값이 inline-flex임 */
//* * css로 block 처리를 할 때에는 flex로 할 것! */
//* * 박스 사이즈 영역 잡고 시작할것! */
//* * img로 사이즈 잡지 말고 class로 사이즈 잡을 것! */

declare type StandardImageProps = JSX.IntrinsicElements['img'];
interface ILazyImageProps extends StandardImageProps {
  src: string;
  fallbackLoadingImage?: string;
  fallbackFailImage?: string;
}

const FrameFallback = styled.div`
  width:100%;
  height:100%;
  background:${colors.WHITE_100};
  display:inline-flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  img.img-fallback {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const LazyImage:FC<ILazyImageProps> = (props) => {
  const [error, setError] = useState<boolean>(false);
  const { fallbackLoadingImage, fallbackFailImage, src, alt, ...restProps } = props;

  useEffect(() => {
    setError(false);
  }, [props.src]);

  if (error)
    return (
      <FrameFallback>
        <img className='img-fallback' src={fallbackFailImage || basicFallbackImage} alt='error' />
      </FrameFallback>
    );
  return (
    <Suspense fallback={<FrameFallback><img className='img-fallback' src={fallbackLoadingImage || basicFallbackImage} alt='loading' /></FrameFallback>}>
      <Img src={src} alt={alt} onError={() => setError(true)} {...restProps} />
    </Suspense>
  );
};

export const LazyImageLogo:FC<ILazyImageProps> = (props) => <LazyImage fallbackFailImage={iconPagesEmptyLogoError} fallbackLoadingImage={iconPagesEmptyLogoError} {...props as any} />;

export default LazyImage;
