import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import styled, { css } from 'styled-components/macro';

const TAB_HEIGHT = 46;

const TabsFrame = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const StyledTabsFrame = styled.div<{type:string}>`
  position: sticky;
  top: 0;
  z-index: 2;
  display: flex;
  flex-wrap:nowrap;
  height: ${TAB_HEIGHT}px;
  transition: top .25s;
  top: ${(props) => ((props as any)['data-scrollup'] ? '56' : '0')}px;
  overflow-x:auto;
  ${(props) => (props.type === 'LINE' && css`background:${colors.WHITE_100};`)};
  ::-webkit-scrollbar {
    width:0;
    height:0;
  }
`;

export const StyledTab = styled.div<{type:string}>`
  cursor: pointer;
  position: relative;
  flex:1;
  display: flex;
  align-items: center;
  justify-content:center;
  width:fit-content;

  ${(props) => (props.type === 'LINE' && css`
    background: ${colors.WHITE_100};
    color: ${colors.CG_60};
    border-bottom: 2px solid ${colors.CG_40};
    font: ${Fonts.B1_Medium};
    
    &.active {
      font: ${Fonts.B1_Bold};
      color: ${colors.JOBDA_BLACK};
      border-bottom: 2px solid ${colors.JOBDA_BLACK};
    }
  `)};

  ${(props) => (props.type === 'TEXT' && css`
    color: ${colors.CG_60};
    font: ${Fonts.B1_Medium};
    
    &.active {
      font: ${Fonts.B1_Bold};
      color: ${colors.JOBDA_BLACK};
    }

    &:not(:last-child){
      ::after{
        position: absolute;
        content: '';
        width: 1px;
        height:12px;
        background: ${colors.CG_40};
        right:0;
      }
    }

  `)};
`;

const StyledComponent = styled.div`
  width: 100%;
  height: 100%;
  text-align: left;
  
  &.hidden {
    visibility: hidden;
    position: absolute;
  }
`;

interface ITabsWithComponent {
  tabs: string[];
  components: ReactElement[];
  disabledTab?: boolean[];
  initTab?: number;
  onChangeComponent?: (index: number) => void;
  type?:'LINE'|'TEXT'|'BOX'
}

const TabsWithComponent: FC<ITabsWithComponent> = ((props) => {
  const { tabs, initTab, disabledTab, components, onChangeComponent, type = 'LINE' } = props;
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  useEffect(() => {
    if (tabs.length < 1) {
      throw new Error('Not Found Tabs.');
    }
    if (components.length < 1) {
      throw new Error('Not Found Contents');
    }
    if (tabs.length !== components.length) {
      throw new Error('Do not match length of Tabs and Contents.');
    }
    if (disabledTab !== undefined && tabs.length !== disabledTab.length) {
      throw new Error('Do not match length of Tabs and disabledTab.');
    }
  }, [components, tabs, disabledTab]);

  useEffect(() => {
    if (initTab !== undefined) {
      if (tabs.length <= initTab) {
        throw new Error('InitTab value is greater than Tabs length.');
      }
      setActiveTabIndex(initTab);
    }
  }, [initTab, tabs.length]);

  return (
    <TabsFrame>
      <StyledTabsFrame className='tabs-frame' type={type}>
        {
          tabs.map((text, index) => (
            <StyledTab
              className={`${index === activeTabIndex ? 'active tab-item' : 'tab-item'}`}
              type={type}
              onClick={() => {
                if (disabledTab !== undefined && disabledTab[index]) return;
                setActiveTabIndex(index);
                onChangeComponent && onChangeComponent(index);
              }}
            >
              {text}
            </StyledTab>
          ))
        }
      </StyledTabsFrame>
      <StyledComponent>{ components[activeTabIndex] }</StyledComponent>
    </TabsFrame>
  );
});

export default TabsWithComponent;
