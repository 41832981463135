import React from 'react';
import classnames from 'classnames/bind';
import AccaQuestion from './accaQuestion';
import Explanation from './explanation';
import Participate from './participate';
import Schedule from './schedule';
import Gift from './gift';
import DChampsQuestion from './dChampsQuestion';

import DetailInfo from './detailInfo';

import styles from './detail.module.scss';

const cx = classnames.bind(styles);

const Detail = () => (
  <div className={cx('wrap')}>
    <div className={cx('inner')}>
      {/* 참가 방법 영역 */}
      <Participate />

      {/* 일정 영역 */}
      <Schedule />

      {/* 경품 영역 */}
      <Gift />

      {/* 설명 영역  */}
      <Explanation />

      {/* 디챔스 출제 문제 영역 */}
      <DChampsQuestion />

      {/* 역량검사 문제 영역 */}
      <AccaQuestion />

      {/* 상세 안내 영역 */}
      <DetailInfo />
    </div>
  </div>
);

export default Detail;
