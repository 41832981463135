import styled, { css } from 'styled-components';

import React, { FC, useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import bulbLottie from 'assets/lottie/light_bulb_lottie.json';
import Fonts from '__designkit__/common/fonts';
import Colors from '__designkit__/common/colors';
import graphLottie from 'assets/lottie/resume_load_lottie.json';
import Login from 'models/Login';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import Context from 'models/Context';
import { useHistory, useLocation } from 'react-router';
import RoutePaths from 'consts/RoutePaths';
import { useInsightResumeLog } from 'query/resume/useResumeQuery';
import { InsightResumeLogRq } from 'interfaces/_v2/insight/IInsightResumeRqRs';

const BannerArea = styled.div`
  position: relative;
  width: 100%;
  height: 88px;
  margin-bottom: 24px;
`;

const BannerWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 1s ease-in-out;
  pointer-events: none;

  &.fadeIn {
    opacity: 1;
    pointer-events: auto;
  }

  &.fadeOut {
    opacity: 0;
    pointer-events: none;
  }
`;

const BannerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  cursor: pointer;
  background: ${(props) => (props.className?.includes('type1') ? '#54cc6c' : '#339af0')};
  overflow: hidden;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  padding-left: 24px;

  .textArea {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .title {
    font: ${Fonts.B2_Medium};
    color: ${Colors.WHITE_100};
    margin-bottom: 4px;
  }

  .desc {
    font: ${Fonts.B1_Bold};
    color: ${Colors.WHITE_100};
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 16px;
  }

  .iconArea {
    position: absolute;
    top: 50%;
    width: 120px;
    height: 120px;
    transform: translateY(-50%);
  }
`;

const TextArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const IconArea = styled.div`
  position: absolute;
  top: 50%;
  right: 0px;
  width: 120px;
  height: 120px;
  transform: translateY(-50%);
`;

const Title = styled.span`
  font:${Fonts.B2_Medium};
  color:${Colors.WHITE_100};
  margin-bottom: 4px;
`;

const Desc = styled.span`
  font:${Fonts.B1_Bold};
  color:${Colors.WHITE_100};
`;

interface IProfileBanner{
    login?:Login
    context?:Context
    setIsOpenResumeModal:(isOpen:boolean)=>void
}
const ProfileBanner:FC<IProfileBanner> = ({ login = new Login(), context = new Context(), setIsOpenResumeModal }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { pathname } = useLocation();
  const history = useHistory();
  const saveSuccess = () => {
  };
  const { mutate: saveResumeInduce } = useInsightResumeLog(saveSuccess);
  const onClickBtn = async () => {
    const induceRq:InsightResumeLogRq = {
      action: 'CLICK',
      actionSource: '/profile',
      actionComponent: 'btn_banner_enter_insight_resume',
      data: {},
    };
    saveResumeInduce(induceRq);
  };
  const bannerEls = [
    <BannerStyled
      key='1'
      className='banner type2'
      id='btn_banner_enter_insight_resume'
      onClick={() => {
        if (login.userInfo === null) {
          context.setRedirectUrl(pathname);
          history.push(RoutePaths.login);
        } else {
          onClickBtn();
          setIsOpenResumeModal(true);
        }
      }}
    >
      <TextArea className='textArea'>
        <Title>제출했던 지원서로</Title>
        <Desc>
          프로필을 손쉽게 채워볼까?
        </Desc>
      </TextArea>
      <IconArea className='iconArea'>
        <Lottie animationData={graphLottie} />
      </IconArea>
    </BannerStyled>,
    // <BannerStyled
    //   key='2'
    //   className='banner type1'
    //   onClick={() => {

    //   }}
    // >
    //   <TextArea className='textArea'>
    //     <Title>내가 언제 입사했더라?</Title>
    //     <Desc>
    //       잊어버린 경력 쉽게 연동하기
    //     </Desc>
    //   </TextArea>
    //   <IconArea className='iconArea'>
    //     <Lottie animationData={bulbLottie} />
    //   </IconArea>
    // </BannerStyled>,
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % bannerEls.length);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [bannerEls.length]);

  return (
    <BannerArea>
      {bannerEls.map((banner, index) => (
        <BannerWrap
          key={index}
          className={index === activeIndex ? 'fadeIn' : 'fadeOut'}
        >
          {banner}
        </BannerWrap>
      ))}
    </BannerArea>
  );
};

export default inject(injectStore.login, injectStore.context)(observer(ProfileBanner));
