import URIs from 'consts/URIs';
import { IEventParticipationStatusRs } from 'interfaces/_v2/IEventChallenge';
import { action, observable } from 'mobx';
import { request } from 'utils/request';

export default class EventMatchModel {
    @observable eventParticipationStatus:IEventParticipationStatusRs | null = null;

    @action
    loadEventsParticipationStatus = async (eventType:string) => {
      try {
        this.eventParticipationStatus = (await request<IEventParticipationStatusRs>({
          method: 'get',
          url: URIs.get_events_participation_status,
          params: { eventType },
        }));
      } catch (e) {
        console.error(e);
      }
    }

    @action
    setEventMatchingApply = async (url:string) => {
      try {
        const res = await request({
          method: 'post',
          url: URIs.post_events_matching_apply,
          data: { url },
        });
        return res;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }

    @action
    loadEventsProfileCompleteProfileDownload = async () => {
      try {
        const res = await request<ArrayBuffer>({
          method: 'get',
          url: URIs.get_events_profile_complete_2024_profile_download,
          responseType: 'arraybuffer',
        });
        return res;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }
}
