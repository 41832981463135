import iconCalendar from 'assets/icon/icon_calendar_16_Regular.svg';
import iconEye from 'assets/icon/icon_eyes_open_20.svg';
import { WebFrame } from 'components/common/PageFrame';
import InfoYoutubeVideo from 'components/info/InfoVideo';
import ShareButtonGroup from 'components/info/ShareButtonGroup';
import { FooterFrame } from 'components/_v2/_common/Footer';
import { IconDocument } from 'consts/assets/icons/iconPages';
import colors from '__designkit__/common/colors';
import Fonts, { fonts } from '__designkit__/common/fonts';
import shadows from 'consts/style/shadows';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import Info from 'models/Info';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import React, { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import CategoryIcon from './CategoryIcon';
import InfoSuggentionContent from './InfoSuggentionContent';

const NavigationFrame = styled.div`
  bottom:0;
  height:calc(56px + env(safe-area-inset-bottom));
  border:none;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  align-items:stretch;
  padding:0;
  padding-bottom:env(safe-area-inset-bottom);
  text-align:center;
  width:100%;
  border-radius: 16px 16px 0px 0px;
  background:${colors.WHITE_100};
  z-index:1000;
  ${shadows.base}
  > * {
      flex:1;
  }
  li {
    position:relative;
    display:inline-block;
    &[data-disabled=true] {
      pointer-events: none;
    }
    .btn-tab-button{
      position:relative;
      overflow: hidden;
      background:transparent;
      width:100%;
      height:100%;
      display:flex;
      flex-direction:column;
      flex-wrap:nowrap;
      justify-content:center;
      align-items:center;
      display:flex;
      flex-direction:column;
      &:disabled {
        opacity:.5;
      }
    }
    .menu-text {
      ${fonts.NOTO_11_400};
      color:${colors.CG_70};
      margin-top:1px;

      &.active{
        color:${colors.G_100};
      }
    }
  }
`;

const TemporarilyHideFooterAndGNB = createGlobalStyle`
  ${FooterFrame}, ${NavigationFrame} {
    display:none;
  }
  ${WebFrame} {
    padding-bottom:0 !important;
  }
`;

const Frame = styled.div`
  height: 100%;
  position: relative;
`;

const TabFrame = styled.div`
  height:100%;
  display:flex;
  justify-content: space-around;
  flex:1 1 auto;
  button{
    position: relative;
    width:100%;
    background: transparent;
    display:flex;
    align-items: center;
    justify-content: center;
    ${shadows.tnbLine}; 
    padding: 12px 66px;
    color:${colors.CG_60};
    
    img{
      width: 13px;
      line-height: 18px;
    }

    span{
      margin-left:7.5px;
      ${fonts.NOTO_13_700};
      line-height: 18px;
    }
    &.active{
      color:${colors.CG_90};      
    }
  }

  >:not(:last-child){
    :after{
      content: '';
      position: absolute;
      right: 0;
      top: 1em;
      bottom: 1em; 
      width: 1px; 
      background-color: ${colors.CG_40};

    }
  }
`;

const InfoFrame = styled.div`
  padding: 24px;
  background: ${colors.WHITE_100};
`;

const InfoHeader = styled.header`
  flex: 1;
  flex-direction: column;
`;

const InfoCategory = styled.p`
  display: inline-block;
  font: ${Fonts.B2_Medium};
  color: ${colors.cA4A4A4};
  padding: 6px 16px;
  border: 1px solid ${colors.CG_40};
  border-radius: 16px;
  box-sizing: border-box;
`;

const InfoHeaderTitle = styled.p`
  ${fonts.NOTO_24_700};
  color: ${colors.c474747};
  line-height: 36px;
  word-break: break-all;
  margin-top: 16px;
`;

const InfoHeaderOptionalInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
`;

const VerticalDivider = styled.div`
  width: 1px;
  height: 12px;
  margin: 0 12px;
  background-color: ${colors.CG_40};
`;

const HorizontalDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.CG_40};
  margin: 24px 0 32px;
`;

const IconWithTextFrame = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OptionalInfoText = styled.span`
  display: inline-block;
  ${fonts.NOTO_13_400};
  line-height: 16px;
  color: ${colors.c6A6A6A};
  margin-left: 6px;
`;

const InfoBody = styled.div`
  margin-bottom: 48px;
  overflow:auto;
  * {
    font-size: 15px;
    line-height:28px;
    font-weight: inherit;
    font-style: inherit;
    text-decoration: inherit;
  }
  
  
  .contents  b {
    font-weight: bold;
    font-size: inherit;
    
  }
  
  .contents u {
    text-decoration: underline;
    font-weight: inherit;
    font-size: inherit;
  }
  
  .contents i {
    font-style: oblique;
    font-weight: inherit;
    font-size: inherit;
  }
  
  .contents span {
    display: inline-block;
    word-break: break-all;
  }
`;

const InfoCategoryBox = styled.div`
  display:flex;
  align-items:center;
  * ~ * {
    margin-left: 8px;
  }
`;

const ChatFrame = styled.div`
  min-height:650px;
  height:650px;
  position: relative;
  .chatting-handle {
    position:absolute;
    top:60px;
    bottom:60px;
    left:0;
    right:50%;
  }
  .iframe-chatting {
    height:650px;
  }
  @media screen and (max-height: 700px) {
    min-height:500px;
    height:500px;
    .iframe-chatting {
      min-height:500px;
      height:500px;
    }
  }
`;

interface IInfoDetailLivePageProps {
  info?: Info;
  login?: Login;
  context?: Context;
}

const InfoDetailLive: React.FC<IInfoDetailLivePageProps> = ({ info = new Info(), login = new Login() }) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const blockName = (name?: string) => {
    if (!name) return '';
    const firstChar = name.charAt(0);
    const lastChar = name.charAt(name.length - 1);

    if (name.length === 1) return name;
    if (name.length === 2) {
      return `${firstChar}*`;
    }
    const blockChar = '*'.repeat(name.length - 2);
    if (name.length > 2 && name.length < 8) {
      return `${firstChar}${blockChar}${lastChar}`;
    } return `${firstChar}${blockChar}`;
  };

  return (
    <>
      <InfoYoutubeVideo videoUrl={info.link} />
      <Frame>
        <TabFrame>
          <button className={`${activeIndex === 0 ? 'active' : ''}`} onClick={() => setActiveIndex(0)}>
            <IconDocument active={activeIndex === 0} /><span>정보</span>
          </button>
          <button className={`${activeIndex === 1 ? 'active' : ''}`} onClick={() => setActiveIndex(1)}>
            <IconDocument active={activeIndex === 1} /><span>채팅</span>
          </button>
        </TabFrame>
        <InfoFrame aria-hidden={activeIndex !== 0}>
          <InfoHeader>
            <InfoCategoryBox>
              <CategoryIcon type={info?.categoryType} />
              <InfoCategory>{info.category}</InfoCategory>
            </InfoCategoryBox>
            <InfoHeaderTitle>{info.title}</InfoHeaderTitle>
            <InfoHeaderOptionalInfo>
              <IconWithTextFrame>
                <img src={iconCalendar} alt='icon calendar' />
                <OptionalInfoText>{info.date}</OptionalInfoText>
              </IconWithTextFrame>
              <VerticalDivider />
              <IconWithTextFrame>
                <img src={iconEye} alt='icon eye' />
                <OptionalInfoText>{info.viewCount}</OptionalInfoText>
              </IconWithTextFrame>
            </InfoHeaderOptionalInfo>
            <HorizontalDivider />
            <InfoBody dangerouslySetInnerHTML={{ __html: info.contents }} />
            <ShareButtonGroup
              kakao={{
                title: info.title,
                description: info.title,
                mobileWebUrl: document.location.href,
                webUrl: document.location.href,
              }}
              facebook={{
                u: document.location.href,
                quote: info.title,
              }}
              webShare={{
                title: info.title,
                url: document.location.href,
                text: info.title,
              }}
            />
          </InfoHeader>
        </InfoFrame>
        <ChatFrame aria-hidden={activeIndex !== 1}>
          <div className='chatting-handle' />
          <iframe className='iframe-chatting' title='채팅' data-type='chat' src={`${info.chatUrl}/?name=${blockName(login.userInfo?.name)}&roomSn=${info.postDetailSn}&key=${login.userInfo?.name}`} width='100%' height='100%' frameBorder='0' scrolling='no' />
        </ChatFrame>
      </Frame>
      {
        activeIndex === 1 && (
          <TemporarilyHideFooterAndGNB />
        )
      }
      {activeIndex === 0
        && (
          <InfoSuggentionContent />
        )}
    </>
  );
};

export default inject(injectStore.info, injectStore.login)(observer(InfoDetailLive));
