import React, { ReactElement } from 'react';

const numUnits = ['만', '억', '조', '경', '해', '자', '양', '구', '간', '정', '재', '극', '항하사', '아승기', '나유타', '불가사의', '무량대수'];

const krNumberFormat = new Intl.NumberFormat('ko-KR').format;

const splitNumber = (value:number) => `${value}`.match(/.{1,4}(?=(.{4})*$)/g)?.map((valueString) => Number(valueString));

// ~명, ~원 처럼 단위가 들어갈 때에는 콤마를 찍지 않고, 숫자만 표기될 경우에는 콤마가 표시되어야 한다고 함.
const formatNumber = (value:number, unit:string = '', composition:(value:string) => ReactElement|string = (value) => value) => {
  const minusCheck = value < 0;
  if (minusCheck) value = Math.abs(value);
  const nums = splitNumber(value);
  if (!nums || nums?.length <= 1)
    return !unit ? `${(Math.round(value / 10000)).toLocaleString()}만` : <>{Math.round(value / 10000)}{composition(`만${unit}`)}</>;
  const formatted = nums?.slice(0, -1)?.reduce((acc, cur, idx, { length }) => ([
    ...acc,
    <React.Fragment key={`fragment-number-${idx}-${cur}`}>
      {cur !== 0 && (!unit ? cur.toLocaleString() : cur)}
      {cur !== 0 && composition(`${numUnits[length - (idx + 1)]}${idx + 1 === length ? unit : ' '}`)}
    </React.Fragment>,
  ]) as any, []);
  if (minusCheck) return <>{`-`}{formatted.reverse()}</>;
  return formatted;
};

// const barFormatNumber = (amount: number) => {
//   if (Math.abs(amount) >= 10 ** 16)
//     return `${amount < 0 ? '-' : ''}${Math.floor(Math.abs(amount) / 10 ** 16)}경 ${Math.floor((Math.abs(amount) % 10 ** 16) / 10 ** 12)}조`;

//   if (Math.abs(amount) >= 10 ** 12)
//     return `${amount < 0 ? '-' : ''}${Math.floor(Math.abs(amount) / 10 ** 12)}조 ${Math.floor((Math.abs(amount) % 10 ** 12) / 10 ** 8)}억`;
//   if (Math.abs(amount) >= 10 ** 8) return `${amount < 0 ? '-' : ''}${Math.floor(Math.abs(amount) / 10 ** 8)}억`;

//   if (Math.abs(amount) > 0) return `${amount < 0 ? '-' : ''}${Math.floor(Math.abs(amount) / 10 ** 4)}만`;

//   return '0원';
// };

const barFormatNumber = (amount: number) => {
  const isNegative = amount < 0;

  const roundNumber = getRoundNumber(amount);

  if (roundNumber >= 10 ** 16)

    return `${isNegative ? '-' : ''}${Math.floor(roundNumber / 10 ** 16)}경 ${roundNumber % 10 ** 16 / 10 ** 15 ? `${roundNumber % 10 ** 16 / 10 ** 15}천조` : ''}`;

  if (roundNumber >= 10 ** 12)

    return `${isNegative ? '-' : ''}${Math.floor(roundNumber / 10 ** 12)}조 ${roundNumber % 10 ** 12 / 10 ** 11 ? `${roundNumber % 10 ** 12 / 10 ** 11}천억` : ''}`;
  if (roundNumber >= 10 ** 8) return `${isNegative ? '-' : ''}${Math.floor(roundNumber / 10 ** 8)}억 ${roundNumber % 10 ** 8 / 10 ** 7 ? `${roundNumber % 10 ** 8 / 10 ** 7}천만` : ''}`;

  if (roundNumber) return `${isNegative ? '-' : ''}${Math.round(Math.abs(roundNumber) / 10 ** 4)}만`;

  return '0원';
};
const formatNumberToKorean = (
  target: number,
  separator = '',
  customNumberWords?: string[],
  formatNumbers?: (value: number) => string,
): string => {
  if (typeof target !== 'number' || isNaN(target)) throw 'target이 올바른 형식이 아닙니다';

  if (target === 0) return '0';

  const level = 0;
  const stringified = target.toString();
  const reversedList = stringified
    .match(/\d{1,4}(?=(\d{4})*$)/g)
    ?.reverse()
    .map((numText, i) => (i >= level ? numText : null));

  if (!reversedList) return '';
  const formatted = fotmatSplittedNumberText(reversedList, customNumberWords, formatNumbers);
  const joined = formatted.filter((numText) => numText !== null).join(separator);
  if (target < 0) {
    return `-${joined}`;
  }
  return joined;
};

const getRoundNumber = (amount: number) => {
  if (Math.abs(amount) >= 10 ** 16)

    return Math.round(Math.abs(amount) / 10 ** 15) * 10 ** 15;

  if (Math.abs(amount) >= 10 ** 12)

    return Math.round(Math.abs(amount) / 10 ** 11) * 10 ** 11;

  if (Math.abs(amount) >= 10 ** 8) return Math.round(Math.abs(amount) / 10 ** 7) * 10 ** 7;

  if (Math.abs(amount) > 0) return Math.round(Math.abs(amount) / 10 ** 3) * 10 ** 3;

  return 0;
};
const fotmatSplittedNumberText = (
  reversedList: (string | null)[],
  numberWords = ['', '만', '억', '조', '경'],
  formatNumbers?: (value: number) => string,
): (string | null
)[] => {
  const formatterd = reversedList.map((numText, i) => {
    if (numText === null) return null;

    const modifier = numberWords[i] ?? '';
    const num = parseInt(numText);
    if (num === 0) return null;

    const formattedNum = formatNumbers ? formatNumbers(num) : num;
    return `${formattedNum}${modifier}`;
  });
  return formatterd.reverse();
};

// 01093859190 -> 010-9385-9190
const getMobileAutoHyphen = (num: string) => {
  if (!num) return '';
  if (num.includes('-') ? num.length <= 12 : num.length <= 10) return num.replace(/[^0-9]/g, '').replace(/^(\d{0,3})(\d{0,3})(\d{0,4})$/g, '$1-$2-$3').replace(/(\-{1,2})$/g, '');
  return num.replace(/[^0-9]/g, '')
    .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3').replace(/(\-{1,2})$/g, '');
};

const formatDeleteUnderTenThousand = (originalNumber: number) => {
  const isNegative = originalNumber < 0;
  const absOriginalNumber = Math.abs(originalNumber);

  const originalString = formatNumberToKorean(absOriginalNumber);
  let newString;
  let pivot;

  if (absOriginalNumber > 99999999) {
    pivot = originalString.indexOf('억');
    newString = originalString.slice(0, pivot + 1);
  } else if (absOriginalNumber > 9999) {
    pivot = originalString.indexOf('만');
    newString = originalString.slice(0, pivot + 1);
  } else {
    newString = '';
  }

  return isNegative && newString ? `-${newString}` : newString;
};

function formatNumberWithCommas(number: Number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export { numUnits, krNumberFormat, splitNumber, formatNumber, formatNumberToKorean, getMobileAutoHyphen, barFormatNumber, formatDeleteUnderTenThousand, formatNumberWithCommas };
