import TalentpoolMissionModal from 'components/_v2/_common/modals/TalentpoolMissionModal';
import MatchApplyTab from 'components/_v2/matchApply/MatchApplyTab';
import MatchServiceConditionInductionModal from 'components/_v2/profile/match/MatchServiceConditionInductionModal';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import CommonUtils from 'utils/CommonUtils';
import RenderCheckUtil from 'utils/RenderCheckUtil';

const Frame = styled.div``;

const MatchApply = ({ login = new Login() }) => {
  const history = useHistory();
  const query = CommonUtils.parseQuery(history.location.search);
  const [isOpenConditionInductionModal, setIsOpenConditionInductionModal] = useState<boolean>(false);
  const [isOpenTalentpoolMissionModal, setIsOpenTalentpoolMissionModal] = useState<boolean>(false);
  const [isCompleteProfile, setIsCompleteProfile] = useState<boolean>(false);
  const [isCompleteAcc, setIsCompleteAcc] = useState<boolean>(false);
  const [isRender, setIsRender] = useState<boolean>(false);

  useEffect(() => {
    if (query.fromToggle) {
      setIsOpenConditionInductionModal(true);
      history.replace({ search: '' });
    }
  }, [query]);

  useEffect(() => {
    (async () => {
      if (!login.profileType) await login.loadProfileType();
      if (!login.matchConfigStatus) await login.getMatchConfigStatus();
      setIsRender(!(await RenderCheckUtil.getRenderCheck('talentpool-induce')));
      setIsCompleteProfile(login.matchConfigStatus!.matchingConfigCondition.profileRequiredCompleteYn);
      if (login.profileType!.generalYn) {
        setIsCompleteAcc(login.matchConfigStatus!.matchingConfigCondition.accCompleteYn);
      } else if (login.profileType!.developerYn) {
        setIsCompleteAcc(login.matchConfigStatus!.matchingConfigCondition.paccCompleteYn);
      }
    })();
  }, [login]);

  useEffect(() => {
    if (isCompleteProfile && isCompleteAcc && !login.matchConfigStatus?.matchingConfigYn && isRender) setIsOpenTalentpoolMissionModal(true);
    else setIsOpenTalentpoolMissionModal(false);
  }, [isCompleteProfile, isCompleteAcc, isRender, login]);

  return (
    <Frame>
      <MatchApplyTab />
      <MatchServiceConditionInductionModal isOpen={isOpenConditionInductionModal} onClickClose={() => setIsOpenConditionInductionModal(false)} />
      { isOpenTalentpoolMissionModal && <TalentpoolMissionModal onClickClose={() => setIsOpenTalentpoolMissionModal(false)} isCompleteProfile={isCompleteProfile} isCompleteAcc={isCompleteAcc} /> }
    </Frame>
  );
};

export default inject(injectStore.login)(observer(MatchApply));
