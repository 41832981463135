import Error500 from 'components/error/Error500';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import React, { FC, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components/macro';
import browserHistory from 'utils/browserHistory';
import { debounceKey } from 'utils/CommonUtils';
import Footer from 'components/_v2/_common/Footer';
import { injectStore } from 'models/store';
import Nudge from '../Nudge';
import { IPageFrameProps, NavRenderer, NavType } from './PageFrame';

export const WebFrame = styled.div`
  background: ${(props: any) => (props['data-background'] ? props['data-background'] : 'none')};
  height: fit-content;
  min-height: 100%;
  > .frame-content {
    padding-bottom:64px;
  }
  .frame-tab-bar {
    position: fixed;
  }
`;

let lastScrollTop = 0;
const TIMEOUT_DELAY = 1000;

const WebPageFrame: FC<IPageFrameProps> = ({ background, scrollRestoreDelay = TIMEOUT_DELAY, scrollBehavior, children, header, scrollInitialize = true, scrollRestore = false, context = new Context(), footer, navType = NavType.JOBDA, ...props }) => {
  const { pathname } = useLocation();
  const saveScroll = useCallback((e) => {
    if (window.scrollY > 0) {
      const debounceFn = debounceKey(() => {
        const currentScrollTop = document.documentElement.scrollTop;
        context.hideHeader = lastScrollTop - currentScrollTop < 0;
        lastScrollTop = document.documentElement.scrollTop;
        if (pathname === document.location.pathname) {
          context.contextHistory[pathname] = { scroll: window.scrollY, hideHeader: context.hideHeader };
        }
      }, 100);
      debounceFn('MainHeader');
    }
  }, []);

  useEffect(() => {
    if (browserHistory.action === 'POP' && scrollRestore) {
      const top = context.contextHistory[pathname]?.scroll || 0.0001;
      const hideHeaderRestoreValue = context.contextHistory[pathname]?.hideHeader || false;

      setTimeout(async () => {
        window.scroll({ top, behavior: scrollBehavior });
        context.hideHeader = hideHeaderRestoreValue;
      }, scrollRestoreDelay);
    }
  }, [pathname]);

  useEffect(() => {
    try {
      window.addEventListener('scroll', saveScroll);
      if (scrollInitialize) window.scroll({ top: 0 });
    } catch (e) {
      console.log(e);
    }

    return () => {
      window.removeEventListener('scroll', saveScroll);
    };
  }, [pathname]);

  return (
    <WebFrame style={{ paddingBottom: navType === NavType.NONE ? 0 : 62 }} data-background={background}>
      {header}
      {children}
      {!context.hideBottomNav && <NavRenderer navType={navType} />}
      {context.error500 && (<Error500 />)}
      <Nudge />
      {footer && <Footer />}
    </WebFrame>
  );
};

export default inject(injectStore.context)(observer(WebPageFrame));
