import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import { lineClampOnlyOne } from 'consts/_v2/_common/style/mixins';
import React, { FC } from 'react';
import styled from 'styled-components';

const Frame = styled.div<{error?:boolean}>`
  padding: 20px 16px 20px 32px;
  border: ${(props) => (props.error ? `1px solid ${Colors.ERROR}` : `1px solid ${Colors.CG_40}`)} ;
  border-radius: 8px;
  .inner-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .text-container{
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      .title{
        display: flex;
        align-items: center;
        .title-bold{
          font:${Fonts.B1_Bold};
          color:${Colors.JOBDA_BLACK};
        }
        .required-text{
          font:${Fonts.B3_Medium};
          color:${Colors.ERROR};
          margin-left: 12px;
        }
      }
      .description{
        font:${Fonts.B2_Medium};
        color:${Colors.CG_70};
        ${lineClampOnlyOne()};
        width: 240px;
      }
    }
    .icon-container{
      width: 32px;
      height: 32px;
    }
  }
`;

interface IConditionFrame{
    title:string;
    required:boolean;
    description:string;
    onClick?:()=>void;
    error?:boolean;
}
const ConditionFrame:FC<IConditionFrame> = ({ title, required, description, onClick, error }) => {
  return (
    <Frame onClick={onClick} error={error}>
      <div className='inner-container'>
        <div className='text-container'>
          <div className='title'>
            <span className='title-bold'>{title}</span>
            {required && <span className='required-text'>필수</span>}
          </div>
          <SpacingBlock vertical size={8} />
          <div className='description'>{description}</div>
        </div>
        <Icon name='arrow-right' size={32} color={Colors.JOBDA_BLACK} className='icon-container' />
      </div>
    </Frame>
  );
};

export default ConditionFrame;
