import colors from '__designkit__/common/colors';
import { fontStyle } from '__designkit__/common/fonts';
import React, { FC } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
  position: fixed;
  bottom: env(safe-area-inset-bottom);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 12px 16px;
  height: fit-content;
  background: ${colors.WHITE_100};
  box-shadow: inset 0px 0.5px 0.5px rgba(207, 209, 213, 0.5);
  border-top: 1px solid ${colors.CG_40};
  z-index:99000;
`;

const ContentBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 14px 16px;
    background-color: ${colors.G_30};
    border: 1px solid ${colors.G_100};
    border-radius: 8px;

    .header {
        margin-bottom: 2px;

        .title {
            ${fontStyle.CAPTION1_SEMIBOLD};
            color: ${colors.c3C8E42};
            margin-right: 4px;
        }

        .time {
            ${fontStyle.CAPTION3_MEDIUM};
            color: ${colors.c41AC4D};
        }
    }

    .description {
        ${fontStyle.CAPTION2_MEDIUM_P};
        color: ${colors.c3C8E42};
    }

    @media screen and (max-width: 320px) {  
      padding: 14px 8px;

      .header {
        .title {
            ${fontStyle.CAPTION2_SEMIBOLD};
            margin-right: 0;
          }
        }
      .description {
        ${fontStyle.CAPTION3_MEDIUM_P};
      }
    }
    
`;

interface IJDANoticeAlert {
    time?: string;
    type: string;
    content: string;
}

const JDANoticeAlert:FC<IJDANoticeAlert> = ({ time, type, content }) => (
  <Frame>
    <ContentBox>
      <div className='header'>
        <span className='title'>포지션이 {type}되었습니다.</span>
        { time
      && <span className='time'>(종료 시간: {time})</span>}
      </div>
      <p className='description'>{content}</p>
    </ContentBox>
  </Frame>
);

export default JDANoticeAlert;
