import VerificationContent from 'components/_v2/verification/VerificationContent';
import JDBaseInput from 'components/_v2/_common/input/JDBaseInput';
import useToast from 'hooks/useToast';
import { JDModalButton, JDModalButtonGhost } from 'components/_v2/_common/modals/JDModalComponents';
import RoutePaths from 'consts/RoutePaths';
import { idValidator } from 'consts/ValidationRule';
import Fonts from '__designkit__/common/fonts';
import useVerification from 'hooks/useVerification';
import { inject, observer } from 'mobx-react';
import FindAccountModel, { FIND_ACCOUNT_ERROR, FIND_PASSWORD_STEP } from 'models/FindAccountModel';
import { injectStore } from 'models/store';
import { FindAccountPage, IFindAccountProps } from 'pages/FindAccount';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components/macro';
import { saveSession } from 'utils/CommonUtils';

const Frame = styled.div`
  height:100%;
  display:flex;
  flex-direction:column;

  .verification-frame {
    margin: 32px 0;
    .button-frame {
      position: inherit;
      width: 100%;
      
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;

    ${JDModalButton} {
        padding: 14px 0;
        font: ${Fonts.B2_Bold};
        margin-bottom: 8px;
    }

    ${JDModalButtonGhost} {
        padding: 14px 0;
        font: ${Fonts.B2_Bold};
    }
    }
  }
`;

const FindPasswordGate: React.FC<IFindAccountProps> = ({ findAccountModel = new FindAccountModel(), ...props }) => {
  const useFormed = useFormContext();
  const { watch, setError } = useFormed;
  const { setToastObject } = useToast();
  const verify = useVerification();

  const onClickMobileButton = async () => {
    if (watch('id') === '') return;
    try {
      await findAccountModel.checkIdExist(watch('id'));
      findAccountModel.nextFindPasswordStep(FIND_PASSWORD_STEP.FORM);
    } catch (e) {
      if (e === FIND_ACCOUNT_ERROR.B002) setError('id', { type: 'string', message: '아이디가 존재하지 않습니다. 다시 한번 확인해 주세요.' });
    }
  };

  useEffect(() => {
    nextVerify();
  }, [verify]);

  const nextVerify = async () => {
    if (verify) {
      try {
        findAccountModel.saveVerificationInfo(verify);
        findAccountModel.nextFindPasswordStep(FIND_PASSWORD_STEP.CHANGE);
      } catch (e) {
        setToastObject({ isOpen: true, type: 'ERROR', message: '인증에 실패했습니다.', subMessage: undefined });
      }
    }
  };

  // ipin 인증 전 입력한 id값 저장하기
  const saveId = async () => {
    try {
      await findAccountModel.checkIdExist(watch('id'));
      saveSession('find-password-inputId', watch('id'));
      return true;
    } catch (e) {
      if (e === FIND_ACCOUNT_ERROR.B002) setError('id', { type: 'string', message: '아이디가 존재하지 않습니다. 다시 한번 확인해 주세요.' });
      return false;
    }
  };

  return (
    <>
      <Frame className={props.className}>
        <JDBaseInput
          name='id'
          placeholder='아이디를 입력해 주세요.'
          className='input-frame'
          minLength={5}
          maxLength={20}
          customValidator={{ validate: (value: string) => idValidator(value) }}
        />
        <VerificationContent
          handlePassVerification={() => onClickMobileButton()}
          handleIpinClick={() => saveId()}
          returnUrl={RoutePaths.findAccount(FindAccountPage.PW)}
          disabled={watch('id') === undefined || watch('id') === ''}
          mobileText='휴대폰으로 찾기'
          ipinText='아이핀으로 찾기'
        />
      </Frame>
    </>
  );
};
export default inject(injectStore.findAccountModel)(observer(FindPasswordGate));
