import imgAiTutorialBanner from 'assets/img/img_ai_tutorial_banner.png';
import videoAiTutorial from 'assets/video/video_ai_tutorial.mp4';
import LazyImage from 'components/common/LazyImage';
import JDAInlineVideo from 'components/JDAInlineVideo';
import UnderlineSplash from 'components/UnderlineSplash';
import { AccMetaTag } from 'components/_v2/_common/meta/MetaTag';
import colors from '__designkit__/common/colors';
import { fonts, fontStyle } from '__designkit__/common/fonts';
import React from 'react';
import styled from 'styled-components/macro';

const Frame = styled.div`
  height:100%;
  .frame-banner-header {
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    padding-top:48px;
    height:226px;
    background:${colors.cF6F6F6};
    .sub-title {
      color:${colors.G_200};
      ${fontStyle.CAPTION1_MEDIUM};
      text-align:center;
    }
    .title {
      margin-top:16px;
      color:${colors.JOBDA_BLACK};
      ${fontStyle.HEADLINE4_SEMIBOLD};
      text-align:center;
    }
    .tag {
      padding-top:3px;
      ${fonts.NOTO_15_500};
      line-height:20px;
      margin-top:18px;
      min-width:130px;
      height:28px;
      border-radius:14px;
      color:${colors.CG_70};
      border: 1px solid ${colors.CG_60};
      text-align:center;
      display:flex;
      flex-direction:row;
      justify-content:center;
      align-items:flex-start;
    }
  }
  .img-banner {
    position:relative;
    &:before {
      display:block;
      position:absolute;
      top:0;
      content:'';
      width:100%;
      height:84px;
      background: linear-gradient(180deg, ${colors.cF6F6F6} 0%, rgba(246,246,246,0) 100%);
    }
    img, video {
      width:100%;
    }
  }
  .frame-banner-footer {
    width:100%;
    height:222px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    ${fonts.NOTO_20_400};
    line-height:31px;
    span{
      ${fonts.WEMAKEP_20_700};
      display: inline-block;
      transform: translateY(-0.05em);
    }
    .splash {
      ${fontStyle.HEADLINE5_SEMIBOLD};
      color:${colors.CG_90};
    }
  }

  span{
    ${fonts.WEMAKEP_24_700};
    display: inline-block;
    transform: translateY(-0.05em);
    color: ${colors.c5EC7C3};
  }
`;

const AiTutorial: React.FC = () => (
  <Frame>
    <AccMetaTag />
    <div className='frame-banner-header'>
      <div className='sub-title'>역량검사 수련</div>
      <div className='title'><span>역량검사</span> 준비는<br />실전 체험으로 끝!</div>
      <div className='tag'>feat. 공식 개발사</div>
    </div>
    <div className='img-banner'>
      <JDAInlineVideo poster={imgAiTutorialBanner}>
        <source src={videoAiTutorial} type='video/mp4' />
        <LazyImage src={imgAiTutorialBanner} alt='역량검사 준비는 실전 체험으로 끝!' />
      </JDAInlineVideo>
    </div>
    <div className='frame-banner-footer'>
      <div><span>역량검사</span> 수련은</div>
      <UnderlineSplash className='splash' height={22} color={colors.cC3F2CD} enable>PC에서만 가능합니다.</UnderlineSplash>
    </div>
  </Frame>
);

export default AiTutorial;
