import { ErrorMessage } from '@hookform/error-message';
import { IconClose20 } from 'consts/assets/icons/iconPages';
import colors from '__designkit__/common/colors';
import { JDAInputFrameBasic, JDAInputFrameMessage } from 'consts/style/mixins';
import { numberValidator } from 'consts/ValidationRule';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { forwardRef, ReactElement, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import styled from 'styled-components/macro';
import { numberPointValidator } from '../../consts/ValidationRule';

const Frame = styled.div`
  margin-top:12px;
  width:100%;
  .input-frame{
    ${JDAInputFrameBasic()};
   >input{
    padding-right: 50px;
   }
   input.value+.btn-clear {
    display:block;
    position:absolute;
    top:35%;
    margin-right:16px;
    right:0;
    width:20px;
    height:20px;
    background: none;
  }
  &.false{
    border: 1px solid ${colors.ERROR};
    .icon-close{
      filter: invert(44%) sepia(78%) saturate(2124%) hue-rotate(331deg) brightness(102%) contrast(97%);
    }
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }

  }
  .message{
   ${JDAInputFrameMessage()};
  }

`;
interface IJDABaseInput extends IComponentProps {
  fieldRef?: React.RefObject<HTMLInputElement | null>;
  name: string;
  defaultMessage?: string
  customValidator: any;
  type?: string;
  onClear?: () => void;
  disabled?: boolean;
  defaultValue?: string;
  className?: string;
  caption?:ReactElement;
}

const JDBaseInput = forwardRef((props: IJDABaseInput) => {
  const { fieldRef, name, defaultMessage, customValidator, type, onClear, disabled, defaultValue, className, caption, ...rest } = props;
  const useFormed = useFormContext();
  const { control, errors, watch, setValue, clearErrors, trigger } = useFormed;
  const { field: { ref: BaseRef, value: BaseValue }, meta } = useController({ name, control, defaultValue });
  const [inputValue, setInputValue] = useState<string>('');

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (type === 'tel') {
      if (numberValidator(e.currentTarget.value)) {
        setInputValue(e.currentTarget.value);
      } else {
        e.currentTarget.value = inputValue;
      }
    }

    if (type === 'numberPoint') {
      if (numberPointValidator(e.currentTarget.value)) {
        setInputValue(e.currentTarget.value);
      } else {
        e.currentTarget.value = inputValue;
      }
    }

    setValue(name, e.currentTarget.value);
    trigger();
    if (props.onChange) {
      props.onChange();
    }
  };

  return (
    <Frame className={`jda-input ${className || ''}`}>
      <div className={`input-frame ${errors[name] === undefined && !meta.invalid}`}>
        <input
          name={name}
          ref={function (innerRef) {
            if (fieldRef !== undefined) (fieldRef as any).current = innerRef;
            if (BaseRef !== undefined) BaseRef.current = innerRef;
          }}
          className={`${watch(name) ? 'value' : ''}`}
          onChange={onChangeHandler}
          disabled={!!disabled}
          value={BaseValue}
          defaultValue={defaultValue}
          autoComplete='off'
          {...rest}
        />
        <button
          type='button'
          className='btn-clear'
          onClick={() => {
            if (BaseRef !== undefined) BaseRef.current.value = '';
            if (disabled) return;
            if (!watch(name)) return;
            setValue(name, '');
            setInputValue('');
            clearErrors(name);
            if (onClear) onClear();
            trigger();
          }}
        >
          <IconClose20 />
        </button>
      </div>
      {(defaultMessage) && <h4 className='message'>{defaultMessage}</h4>}
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <h4 className='message false'>{message}</h4>}
      />
      {caption}
    </Frame>
  );
});

export default JDBaseInput;
