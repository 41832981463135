import React from 'react';
import Footer from 'components/_v2/_common/Footer';
import Intro from './intro';
import Rabbits from './rabbits';
import Banner from './banner';
import Banner2 from './banner2';
import Resume from './resume';
import Result from './result';
import State from './state';
import Gift from './gift';
import Interview from './interview';
import Banner3 from './banner3';
import EventShare from './eventShare';

const EventChuseok = () => (
  <>
    {/* 메인 탑 인트로 */}
    <Intro />

    {/* 토끼 네마리 */}
    <Rabbits />

    {/* 후킹 베너 */}
    <Banner />

    {/* 후킹 베너2 */}
    <Banner2 />

    {/* 합격자 이력서 모음 ZIP */}
    <Resume />

    {/* 역량검사 심층 분석 결과표 */}
    <Result />

    {/* 응시 모음 다운로드/결과표 확인 영역 */}
    <State />

    {/* 해택 */}
    <Gift />

    {/* 합격자 인터뷰 영역 */}
    <Interview />

    {/* 후킹 배너3 */}
    <Banner3 />

    {/* 공유이벤트 영역 */}
    <EventShare />
    <Footer isNavi={false} corpInfoFoled={false} />

  </>
);

export default EventChuseok;
