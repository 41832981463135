import LazyImage from 'components/common/LazyImage';
import { Divider1G40 } from 'components/divider/Divider';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import IChallengeDetailProps from 'interfaces/_v2/challenge/IChallengeDetailProps';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import ChallengeDetailModel from 'models/_v2/ChallengeDetailModel';
import React, { FC } from 'react';
import styled from 'styled-components';
import { DateFormatYYYYMMDDCCHHMMUnit, getDCount, getDDayString, overDateNow, str2DateTime } from 'utils/DateUtils';

const Frame = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    margin-bottom: 32px;
    width:100%;

    .position_name{
        font: ${Fonts.H5_Bold};
        color:${colors.JOBDA_BLACK};
        word-break: break-all;
    }
    .company_title{
        font: ${Fonts.B1_Bold};
        color:${colors.JOBDA_BLACK};
        margin-top: 24px;
        margin-right: 24px;
    }
    .company_name{
        display:flex;
        flex-wrap: wrap;
        margin-top: 24px; 
        ${Fonts.B1_Medium};
        color:${colors.JOBDA_BLACK};
        word-break: break-all;
    }
    .company_seperate{
        display: flex;
        margin-top: 24px;
        ${Fonts.B1_Medium};
        :nth-last-child(1){
            visibility: hidden;
        }
    }
    .align_div{
        display:flex;
        flex-wrap: wrap;
    }
    .enroll_date_name{
        margin-top: 24px;
        font: ${Fonts.B3_Medium_P};
        color:${colors.JOBDA_BLACK};
    }
    .enroll_date{
        margin-top: 24px;
        margin-left: 48px;
        font: ${Fonts.B1_Bold};
        color:${colors.CG_60};
    }
    .end_date_name{
        font: ${Fonts.B3_Medium_P};
        color:${colors.JOBDA_BLACK};
        margin-top: 14px;
    }
    .end_date{
      font: ${Fonts.B1_Bold};
        color:${colors.JOBDA_BLACK};
        margin-top: 14px;
        margin-left: 48px;
    }
    .d_day_frame{
        margin-top: 14px;
        margin-left: 8px;
        padding:4px 6px;
        border-radius: 4px;
        border: 1px solid ${colors.c000000_06};
        background-color:${colors.G_100};
        span{
            display:inline-block;
            font: ${Fonts.B4_Medium};
            color:${colors.WHITE_100};
        }
    }
    .prepare_frame{
        margin-top: 14px;
        margin-left: 8px;
        padding:4px 6px;
        border-radius: 4px;
        border: 1px solid ${colors.c44474B_20};;
        background-color:${colors.WHITE_100};
        span{
            display:inline-block;
            font: ${Fonts.B4_Medium};
            color:${colors.CG_70};
        }
    }
    .tag_list{
        margin-top: 10px;
        margin-right: 8px;
        font: ${Fonts.B2_Medium};
        line-height: 13px;
        color:${colors.CG_60};
        word-break: break-all;

    }

`;
const DividerFrame = styled.div`
    display: flex;
    margin-top: 24px;
    .divider-24{
            width:100%;
    }
`;
const ImageFrame = styled.div`
  .img{
    width:100%;
  }
`;

const ChallengeHeaderInfo:FC<IChallengeDetailProps> = ({ challengeDetailModel = new ChallengeDetailModel() }) => {
  const { challengeDetail } = challengeDetailModel;

  return (
    <>
      {challengeDetail && (
        <>
          <Frame>
            <div className='align_div'>
              <div className='position_name'>{challengeDetail.title}</div>
            </div>
            <div className='align_div'>
              <div className='company_title'>참여기업</div>
              <div className='company_name'>{challengeDetail.participants}</div>
            </div>
            <div className='align_div'>
              {challengeDetail.description.split(' ').map((tag) => (

                <>
                  {tag !== '\n'
                && <div key={`tag-${tag}`} className='tag_list'>{`#${tag.replace(/,/g, ' #')}`}</div>}
                </>

              ))}
            </div>
            <DividerFrame>
              <Divider1G40 className='divider-24' />
            </DividerFrame>
            <div className='align_div'>
              <div className='enroll_date_name'>등록일</div>
              {challengeDetail.postStartDateTime && (
              <div className='enroll_date'>{DateFormatYYYYMMDDCCHHMMUnit(challengeDetail.postStartDateTime)}</div>
              )}
            </div>
            <div className='align_div'>
              <div className='end_date_name'>마감일</div>
              {challengeDetail.postEndDateTime && (
              <>
                <div className='end_date'>{DateFormatYYYYMMDDCCHHMMUnit(challengeDetail.postEndDateTime)}</div>
                {getDCount(str2DateTime(challengeDetail.postEndDateTime)) <= 0
                && (
                  <>
                    {!overDateNow(challengeDetail.postStartDateTime)
                      ? <div className='prepare_frame'><span>준비중</span></div>
                      : (
                        <div className='d_day_frame'>
                          <span>{getDDayString(str2DateTime(challengeDetail.postEndDateTime))}</span>
                        </div>
                      )}

                  </>

                )}

              </>
              )}
            </div>
          </Frame>
          <ImageFrame>
            <LazyImage src={challengeDetail.bannerImageUrl} className='img' />
          </ImageFrame>
        </>
      )}
    </>
  );
};

export default inject(injectStore.challengeDetailModel)(observer(ChallengeHeaderInfo));
