import React, { FC } from 'react';
import styled from 'styled-components';
import { JDACheckBoxInput, JDAInputFrame, JDALargeButton, JDAMediumButton, JDASmallButton } from 'consts/style/mixins';

const Frame = styled.div`
  padding: 30px;
  > div {
    padding: 30px;
  }
  
  .checkbox-frame {
    label {
      display: inline-block;
      input[type=checkbox] {
        ${JDACheckBoxInput()}
      }
      span {
        padding-left: 8px;
        vertical-align: -webkit-baseline-middle;
      }
    }
  }
  
  .button-frame {
    .round {
      border-radius: 50px;
    }
    .large {
      ${JDALargeButton(false, true)}
      width: 300px;
      display: inline-block;
      margin: 16px;
      &.ghost {
        ${JDALargeButton(true, true)}
        width: 300px;
        display: inline-block;
        margin: 16px;
      }
    }
    .medium {
      ${JDAMediumButton(false)}
      width: 300px;
      display: inline-block;
      margin: 16px;
      &.ghost {
        ${JDAMediumButton(true, true)}
        width: 300px;
        display: inline-block;
        margin: 16px;
      }
    }
    .small {
      ${JDASmallButton(false, true)}
      width: 300px;
      display: inline-block;
      margin: 16px;
      &.ghost {
        ${JDASmallButton(true, true)}
        width: 300px;
        display: inline-block;
        margin: 16px;
      }
    }
  }
  
  .input-frame {
    ${JDAInputFrame()} 
    margin: 16px 0;
    input {
      width: 100%;
    }
    &.label {
      input {
        width: 30%;
      }
    }
  }
`;

const MixinPage: FC = (() => {
  return (
    <Frame>
      <div>
        <div>체크박스</div><br />
        <div className='checkbox-frame'>
          <label>
            <input type='checkbox' />
            <span>체크하면됨</span>
          </label>
        </div>
      </div>
      <div>
        <div>버튼</div><br />
        <div className='button-frame'>
          <button className='large'>큰거</button>
          <button className='large ghost'>큰거 ghost</button>
          <button className='medium'>중간 이펙트 제거</button>
          <button className='medium ghost'>중간 ghost</button>
          <button className='small'>작은거</button>
          <button className='small ghost'>작은거 ghost</button>
          <button className='large round'>둥글면서 큰거</button>
          <button className='medium' disabled>중간</button>
        </div>
      </div>
      <div>
        <div>인풋</div><br />
        <div className='input-frame'>
          <input placeholder='입력하시오' />
        </div>

        <label>
          <div className='input-frame label'>
            <input placeholder='입력하시오' />
            <input placeholder='입력하시오' />
          </div>
        </label>
      </div>
    </Frame>
  );
});

export default MixinPage;
