import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { css } from 'styled-components';
import IconCheckboxChecked from 'assets/icon/icon_checkbox_check.svg';
import IconSmallCheckbox from 'assets/icon/icon_small_checkbox.svg';
import IconSmallCheckboxChecked from 'assets/icon/icon_check_24_Regular_green.svg';

// v2 mixins
export const absoluteHorizonCenter = () => `
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
`;

export const absoluteVerticalCenter = () => `
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
`;

export const absoluteCenterCenter = () => `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ScrollHiddenMixin = () => `
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const lineClampOnlyOne = () => css`
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  `;

export const lineClampBox = (line: number) => css`
  white-space: pre-line;
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: ${line};
`;

export const JDButton = (ghost: boolean = false, width: string = '100%') => `
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  line-height: 22px;
  background: ${ghost ? colors.WHITE_100 : colors.JOBDA_BLACK};
  border-radius: 4px;
  color: ${ghost ? colors.JOBDA_BLACK : colors.WHITE_100};
  width: ${width};
  border: 1px solid ${colors.JOBDA_BLACK};
  ${ghost && `border: 1px solid ${colors.JOBDA_BLACK};`}
  :disabled {
    opacity: 0.5;
  }
`;

export const JDMainButton = (ghost: boolean = false, width: string = '100%') => `
  ${JDButton(ghost, width)};
  padding: 12px 0;
  font: ${Fonts.B2_Bold};
  :disabled {
    opacity: 0.5;
  }
`;

export const JDSmallSeletor = () => css`
  position: relative;
  display: inline-flex;
  height: 28px;
  padding: 8px 19px 8px 10px;
  white-space: nowrap;
  font: ${Fonts.B4_Bold};
  color: ${colors.CG_70};
  background: ${colors.WHITE_100};
  border: 1px solid ${colors.CG_50};
  border-radius: 50px;
  svg {
    ${absoluteVerticalCenter()}
    right: 6px;
  }
  input:disabled {
    -webkit-text-fill-color: ${colors.CG_90};
    opacity: 1;
  }
`;
export const JDMediumSelector = () => `
min-width: 130px;
position: relative;
display: inline-flex;
height: 38px;
padding: 12px 16px;
white-space: nowrap;
font: ${Fonts.B3_Bold};
color: ${colors.JOBDA_BLACK};
background: ${colors.WHITE_100};
border: 1px solid ${colors.JOBDA_BLACK};
border-radius: 4px;
`;

export const JDBaseInputMixin = () => css`
  width:100%;

  >div{
      position:relative;
      display: block;
      width: 100%;
      height:48px;
      color: ${colors.JOBDA_BLACK};
      outline: 1px solid ${colors.CG_30};
      background: ${colors.CG_30};
      border-radius: 4px;
      
      input {
        width:100%;
        height: 100%;
        padding: 0px 50px 0px 16px;
        background: inherit;
        font: ${Fonts.B2_Medium};
        border:none;
        outline: none;
        ::placeholder {
          color: ${colors.CG_60};    
        }
        &:disabled::placeholder {
            opacity: 0.5;
          }
      }

      input:disabled {
        color: ${colors.CG_60};  
        opacity: 0.5;
      }

      .btn-clear {
        display:none;
      }

      input:not([value=""])+.btn-clear {
        display:block;
        position:absolute;
        top:30%;
        margin-right:20px;
        right:0;
        width:20px;
        height:20px;
        background: none;
      }

      .btn-delete {
          display: block;
          position:absolute;
          top:35%;
          margin-right:16px;
          right:0;
          width:20px;
          height:20px;
          background: none;
      }

      // safari focus 문제로 주석.
      // :focus-within {
      //   .btn-delete {
      //     opacity: 1;
      //     position:absolute;
      //     top:35%;
      //     margin-right:16px;
      //     right:0;
      //     width:20px;
      //     height:20px;
      //     background: none;
      //   }
      // }

      input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px ${colors.CG_30} inset !important;
      }
      input:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 30px ${colors.WHITE_100} inset !important;
      }
      
      :focus-within, &.active {
        outline: 1px solid ${colors.CG_90};
        background: transparent;
      } 
  }

  &.false{
    >div{
      outline: 1px solid ${colors.ERROR};
    }
    .icon-close{
      filter: invert(44%) sepia(78%) saturate(2124%) hue-rotate(331deg) brightness(102%) contrast(97%);
    }
  }

  .message{
    ${JDInputMessage()};
  }
`;
export const JDInputMessage = () => `

  height:16px;
  font: ${Fonts.B3_Medium};
  color:${colors.CG_60};
  white-space: break-spaces;
  margin-top:8px;
  margin-left:16px;
  &.false{
    color:${colors.ERROR};
  }
`;

export const JDInputFrame = () => css`
  display: block;
  width: 100%;
  height:48px;
  color: ${colors.CG_90};
  border: 1px solid ${colors.CG_30};
  background: ${colors.CG_30};
  border-radius: 4px;
  
  input {
    padding: 0px 16px;
  }
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px ${colors.CG_30} inset !important;
  }
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px ${colors.WHITE_100} inset !important;
  }
  input {
    background: inherit;
    font: ${Fonts.B1_Medium};
    line-height: 20px;
    height: 100%;
    border: none;
    outline: none;
    ::placeholder {
      color: ${colors.CG_60};
    }
    &:disabled::placeholder {
        opacity: 0.5;
    }
  }
  
  :focus-within, &.active {
    border: 1px solid ${colors.CG_60};
    background: transparent;
  }

  &.false{
    border: 1px solid ${colors.ERROR};
    .icon-close{
      filter: invert(44%) sepia(78%) saturate(2124%) hue-rotate(331deg) brightness(102%) contrast(97%);
    }
  }
`;

export const JDRoundRadioBox = () => css`
    width: 0;
    height: 0;
    margin: 10px;
    position: relative;
    color: ${colors.CG_90};
    :disabled {
      opacity: 0.5;
    }

    :after {
      content: '';
      ${absoluteCenterCenter()};
      height: 20px;
      width: 20px;
      background: inherit;
      border-radius: 50%;
      transition: background 0.1s;
      background-position: center center;
      background: ${colors.WHITE_100};
      border: 2px solid ${colors.CG_50};
    }

    :before {
      content: '';
      ${absoluteCenterCenter()};
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background: inherit;
      opacity: 1;
    }

    &:checked {
      background: ${colors.G_100};
      :after {
        height: 20px;
        width: 20px;
        background: inherit;
        border-radius: 50%;
        transition: boarder 0.1s;
        background-position: center center;
        background: ${colors.WHITE_100};
        border: 5px solid ${colors.G_150};
      }
    } 
`;

export const JDCheckBoxInput = () => css`
  width: 0;
  height: 0;
  margin: 10px;
  position: relative;
  
  :disabled {
    opacity: 0.4;
  }
  
  :after {
    content: '';
    ${absoluteCenterCenter()};
    height: 20px;
    width: 20px;
    background: inherit;
    border-radius:4px;
    transition: background 0.1s;
    background-position: center center;
    background: ${colors.WHITE_100};
    border: 2px solid ${colors.CG_50};
  }
  
  :before {
    content: '';
    ${absoluteCenterCenter()};
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: inherit;
    opacity: 1;
  }
  
  &:checked {
    background: ${colors.G_150};
    :after {
      background: inherit;
      background-image: url(${IconCheckboxChecked});
      background-size: 10px 7.5px;
      background-repeat: no-repeat;
      background-position: center center;
      border:none;
    }
}
`;

export const StyledJDSmallCheckBoxInput = () => css`
  width: 0;
  height: 0;
  margin: 10px;
  position: relative;
  :disabled {
    opacity: 0.4;
  }

  :before {
    content: '';
    ${absoluteCenterCenter()};
    height: 24px;
    width: 24px;
    background: white;
    background-image: url(${IconSmallCheckbox});
    background-repeat: no-repeat;
    background-position: center center;
  }

   &:checked {
    :before {
      background-image: url(${IconSmallCheckboxChecked});
    }
  }
`;
