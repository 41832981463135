import React from 'react';
import classnames from 'classnames/bind';
import LogoList from './logoList';
import styles from './logoFlow.module.scss';

const cx = classnames.bind(styles);

interface IntroBoxProps {
  fadeIn: boolean;
}

const LogoFlow = ({ fadeIn }: IntroBoxProps) => (
  <div className={cx('commonBox', { on: fadeIn })}>
    <div className={cx('mainTitle')}>
      <p className={cx('light')}>스펙으로</p>
      취업하는 시대는 끝
    </div>
    <span className={cx('subTitle')}>
      역량으로 채용하는 750여 개 기업들이
      <br />
      나의 매칭 참여를 기다리고 있어요!
    </span>

    <LogoList />

    {/* 인터뷰 박스 */}
    <div className={cx('interviewBox')}>
      <div className={cx('interviewInner')}>
        <span className={cx('contents')}>
          JOBDA matching으로 역량이
          <br />
          좋은 지원자 분들께 입사 제안을
          <br />
          드리고 있습니다.
        </span>
        <p className={cx('who')}>
          인사팀 오동률 이사
        </p>
      </div>
      <div className={cx('interviewInner')}>
        <span className={cx('contents')}>
          이제는 스펙이 아닌 역량 중심의
          <br />
          채용으로 기업들의
          <br />
          트렌드가 변하고 있습니다.
        </span>
        <p className={cx('who')}>
          인사총무팀 박채희 대리
        </p>
      </div>
      <div className={cx('interviewInner')}>
        <span className={cx('contents')}>
          역량검사로 지원자의 다양한
          <br />
          측면을 검증할 수 있어서
          <br />
          훨씬 효과적입니다.
        </span>
        <p className={cx('who')}>
          인사총무본부 송호현 매니저
        </p>
      </div>
    </div>
  </div>
);

export default LogoFlow;
