import JDBaseModal, { IJDBaseModal } from 'components/_v2/_common/modals/JDBaseModal';
import { JDModalBasicFrame, JDModalDescription, JDModalFullButton, JDModalTipBlue, JDModalTitle } from 'components/_v2/_common/modals/JDModalComponents';
import SupportMail from 'consts/_v2/GlobalText';
import React, { FC } from 'react';
import styled from 'styled-components';

const Frame = styled.div`

    ${JDModalDescription} {
        margin-top: 8px;
        margin-bottom: 12px;
    }
`;
const JDAtsFailModal:FC<IJDBaseModal> = (({ isOpen, onClickClose }) => (
  <JDBaseModal dimmed isOpen={isOpen} onClickClose={onClickClose} isDimClickClose>
    <Frame>
      <JDModalBasicFrame>
        <JDModalTitle>현재 서비스가 원활하지 않습니다.</JDModalTitle>
        <JDModalDescription>오류가 지속될 경우 <br />잡다 헬프데스크로 문의 해 주세요.</JDModalDescription>
        <JDModalTipBlue>잡다 헬프데스크 : {SupportMail}</JDModalTipBlue>
        <JDModalFullButton onClick={onClickClose}>확인</JDModalFullButton>
      </JDModalBasicFrame>
    </Frame>
  </JDBaseModal>
));
export default JDAtsFailModal;
