import { AgreeTermsThirdPartyPrivacyProvisionRq, THIRD_PARTY_TERMS_TYPE, ThirdPartyProvideTermsGetRq } from 'interfaces/terms/IThirdPartyPrivacyRqRs';
import { useMutation, useQuery } from 'react-query';
import { RemoteTermsRepo } from 'repository/terms/TermsRepo';

const remoteTermsRepo = new RemoteTermsRepo();
export const keys = ['terms'];

const fetchTermsThirdPartyPrivacyProvision = async (rq: ThirdPartyProvideTermsGetRq) => {
  const data = await remoteTermsRepo.fetchTermsThirdPartyPrivacyProvision(rq);
  return data;
};

const fetchTermsThirdPartyPrivacyProvisionAgreement = async (
  termsType: THIRD_PARTY_TERMS_TYPE,
  companyName: string,
) => {
  const data = await remoteTermsRepo.fetchTermsThirdPartyPrivacyProvisionAgreement(termsType, companyName);
  return data;
};

const fetchAgreeTermsThirdPartyPrivacyProvision = async (rq: AgreeTermsThirdPartyPrivacyProvisionRq) => {
  const data = await remoteTermsRepo.fetchAgreeTermsThirdPartyPrivacyProvision(rq);
  return data;
};

export function useTermsThirdPartyPrivacyProvision(rq: ThirdPartyProvideTermsGetRq) {
  const { termsType, companyName, provideItems } = rq;
  return useQuery([...keys, 'third-party', rq], () => fetchTermsThirdPartyPrivacyProvision(rq), { enabled: !!(termsType && companyName && provideItems) });
}

export function useTermsThirdPartyPrivacyProvisionAgreement() {
  return useMutation((termsRq: { termsType: THIRD_PARTY_TERMS_TYPE; companyName: string }) => fetchTermsThirdPartyPrivacyProvisionAgreement(termsRq.termsType, termsRq.companyName));
}

export function useAgreeTermsThirdPartyPrivacyProvision() {
  return useMutation((rq: AgreeTermsThirdPartyPrivacyProvisionRq) => fetchAgreeTermsThirdPartyPrivacyProvision(rq));
}
