import React from 'react';
import classnames from 'classnames/bind';
import styles from './logoCard.module.scss';
const cx = classnames.bind(styles);

interface LogoListProps {
  logo: string;
  review: string;
  reviewer: string;
}

const LogoCard = ({ logo, review, reviewer }: LogoListProps) => {
  return (
    <div className={cx('companyItem')}>
      <div className={cx('logoImg')} style={{ backgroundImage: `url(${logo})` }} />
      <span className={cx('review')}>{review}</span>
      <span className={cx('reviewer')}>{reviewer}</span>
    </div>
  );
};

export default LogoCard;
