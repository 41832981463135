import React, { FC, useState } from 'react';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import Footer from 'components/_v2/_common/Footer';
import classnames from 'classnames/bind';
import { useHistory } from 'react-router';
import RoutePaths from 'consts/RoutePaths';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import Login from 'models/Login';
import JDSimpleInfoModal from 'components/_v2/_common/modals/JDSimpleInfoModal';
import colors from '__designkit__/common/colors';
import styled from 'styled-components';
import Fonts from '__designkit__/common/fonts';
import { UserAuthorityType } from 'consts/_v2/UserAuthorityType';
import Faq from './faq';
import LastBanner from './lastBanner';
import InterviewPracticeGateBanner from './banner';
import InterviewPracticeList from './list';
import AllianceList from './allianceList';
import style from './commonStyle.module.scss';

const cx = classnames.bind(style);
const ModalFrame = styled.div`
  
  background: ${colors.WHITE_100};
  width: 100%;
  height: 100%;

  h1{
    text-align: center;
    margin-bottom:8px;
    font:${Fonts.H4_Bold}
  }
  h2{
    text-align: center;
    margin-bottom:16px;
    font:${Fonts.B2_Medium};
    color:${colors.CG_70};
  }
  .btn-frame{
    display: flex;
    gap: 8px;
    justify-content: space-between;
    margin-top: 32px;
    width: 100%;
    .apply-btn{
    background-color: ${colors.JOBDA_BLACK};
    color:${colors.WHITE_100};
    font:${Fonts.B1_Bold};
    width: 100%;
    border-radius: 4px;
    padding:14px 0;
  }
    
  .close{
    background-color: ${colors.JOBDA_WHITE};
    color:${colors.JOBDA_BLACK};
    border: 1px solid ${colors.JOBDA_BLACK};
    font:${Fonts.B1_Bold};
    width: 100%;
    border-radius: 4px;
    padding:14px 0;
  }
  }
  
`;
interface IInterviewPracticeGateProps {
  login?: Login
}
const InterviewPracticeGate:FC<IInterviewPracticeGateProps> = ({ login = new Login() }) => {
  const history = useHistory();
  const [fadeInFirst, setFadeInFirst] = useState<boolean>(false);
  const [fadeInSecond, setFadeInSecond] = useState<boolean>(false);
  const isPartnerAuthority = login.userInfo?.authorities.includes(UserAuthorityType.PARTNERSHIP);
  const [isPartnershipModal, setIsPartnershipModal] = useState<boolean>(false);

  // Intersection Observer
  const handleIntersectFirst = (entries: IntersectionObserverEntry[]) => {
    if (entries[0].isIntersecting) {
      setFadeInFirst(true);
    }
  };
  const handleIntersectSecond = (entries: IntersectionObserverEntry[]) => {
    if (entries[0].isIntersecting) {
      setFadeInSecond(true);
    }
  };

  const setFirstSectionRef = useIntersectionObserver({ onIntersect: handleIntersectFirst, threshold: 0.5 });
  const setSecondSectionRef = useIntersectionObserver({ onIntersect: handleIntersectSecond, threshold: 0.5 });

  const handlePassInterview = () => {
    if (isPartnerAuthority) {
      history.push(RoutePaths.pass_interview_history);
    } else {
      setIsPartnershipModal(true);
    }
  };
  return (
    <>
      <div className={cx('wrapper')}>
        <InterviewPracticeGateBanner />
        <InterviewPracticeList
          setFirstSectionRef={setFirstSectionRef}
          setSecondSectionRef={setSecondSectionRef}
          fadeInFirst={fadeInFirst}
          fadeInSecond={fadeInSecond}
        />
        <AllianceList />
        <Faq />
        <div className={cx('banner')}>
          <LastBanner />
        </div>

        <div className={cx('floatingBar')}>
          <button onClick={handlePassInterview} className={cx('btn', 'btnBlack')}>
            연습기록 확인하기
          </button>
        </div>
      </div>
      <JDSimpleInfoModal
        onClickClose={() => {
          setIsPartnershipModal(false);
        }}
        isOpen={isPartnershipModal}
        isDimClickClose
        dimmed
        isButton={false}
        descriptionsTag={(
          <ModalFrame>
            <div>
              <h1>제휴회원 서비스입니다.
              </h1>
              <h2>기출면접 연습 서비스는<br /> 인증된 회원만 이용이 가능해요.</h2>
              <div className='btn-frame'>
                <button className='apply-btn' onClick={() => history.push(RoutePaths.mypage_partnership)}>제휴서비스 신청</button>
                <button
                  className='close'
                  onClick={() => setIsPartnershipModal(false)}
                >닫기
                </button>
              </div>
            </div>

          </ModalFrame>
  )}
      />
      <Footer isNavi={false} corpInfoFoled={false} />
    </>
  );
};

export default inject(injectStore.login)(observer(InterviewPracticeGate));
