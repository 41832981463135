import colors from '__designkit__/common/colors';
import iconAlert from '__designkit__/assets/icon/icon_bell_light.svg';
import iconNew from 'assets/icon/match/icon_new.svg';
import RoutePaths from 'consts/RoutePaths';
import { inject, observer } from 'mobx-react';
import NotificationModel from 'models/_v2/NotificationModel';
import { injectStore } from 'models/store';
import { INotificationProps } from 'pages/_v2/Notification';
import React, { FC } from 'react';
import { useHistory } from 'react-router';
import styled, { keyframes } from 'styled-components';

const bell = keyframes`
  0%,
  100% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(5deg);
  }
  20% {
    transform: rotate(-5deg);
  }
  30% {
    transform: rotate(7deg);
  }
  40% {
    transform: rotate(-7deg);
  }
  50% {
    transform: rotate(2.5deg);
  }
  60% {
    transform: rotate(-2.5deg);
  }
  70% {
    transform: rotate(0deg);
  }
`;

const StyledHeaderIconAlert = styled.div`
  display:block;
  position:relative;
  width:40px;
  height:40px;
  background-image:url(${iconAlert});
  background-repeat:no-repeat;
  transform-origin: top center;
  background-position: 55% 38%;

  &.ani {
    animation: ${bell} 3s infinite;
    
    &:after {
      content:'';
      width:10px;
      height:10px;
      color:${colors.WHITE_100};
      background-image:url(${iconNew});
      background-size:cover;
      position:absolute;
      top: 6px;
      right: 7px;
    };
  };
`;

const HeaderIconAlert: FC<INotificationProps> = ({ notificationModel = new NotificationModel() }) => {
  const history = useHistory();

  return (
    <StyledHeaderIconAlert
      className={(notificationModel.unreadCount > 0 || notificationModel.unReadYn) ? 'ani' : ''}
      onClick={() => {
        notificationModel.setUnRead(false);
        history.push(RoutePaths.notification);
      }}
    />
  );
};

export default inject(injectStore.notificationModel)(observer(HeaderIconAlert));
