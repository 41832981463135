// Mobile 관련 설정은 최초 한 번만 실행되고 변경되지 않으므로 모듈로 관리하되 setter를 제공하지 않습니다.
// 즉, re-render를 신경쓰지 않아도 되는 상태 값들만 있습니다.

import { App, AppInfo } from '@capacitor/app';
import { Device, DeviceInfo } from '@capacitor/device';
import { Preferences } from '@capacitor/preferences';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import Colors from '__designkit__/common/colors';
import { AppTrackingTransparency } from 'capacitor-ios-app-tracking';
import PushUtils from 'utils/PushUtils';

const PLATFORM = {
  ANDROID: 'android',
  IOS: 'ios',
  WEB: 'web',
} as const;

export type Platform = typeof PLATFORM[keyof typeof PLATFORM];

type MobileStoreType = {
  MINIMUM_VERSION: string;
  URL_SCHME: string;
  ANDROID_APP_STORE_LINK: string;
  IOS_APP_STORE_LINK: string;
  deviceInfo: DeviceInfo | null;
  appInfo: AppInfo | null;
  isMobile: boolean;
  currentPlatform: Platform | null;
  currentVersion: string | null;
  initialized: boolean;

  init(): Promise<void>;
  storeInit(): Promise<void>;
  mobileInit(): Promise<void>;
  openDeviceSettings(): Promise<void>;
  moveToStore(): void;
  // eslint-disable-next-line no-unused-vars
  tryOpenAppOrRedirect(storeLink: string): void;
  getIsLowerVersion(): boolean;
  showLogMobileStoreStatus(): void;
};

const MobileStore: MobileStoreType = {
  MINIMUM_VERSION: '1.9.0',
  URL_SCHME: 'jobda://m.jobda.im',
  ANDROID_APP_STORE_LINK: 'https://play.google.com/store/apps/details?id=io.ionic.jobda',
  IOS_APP_STORE_LINK: 'https://itunes.apple.com/kr/app/apple-store/id1637297178',
  deviceInfo: null,
  appInfo: null,
  isMobile: false,
  currentPlatform: null,
  currentVersion: null,
  initialized: false,

  async init() {
    await this.storeInit();
    await this.mobileInit();
    this.initialized = true;
  },

  async storeInit() {
    if (this.initialized) return;
    this.deviceInfo = await Device.getInfo();
    this.isMobile = this.deviceInfo.platform !== PLATFORM.WEB;
    this.currentPlatform = this.deviceInfo.platform;
  },

  async mobileInit() {
    if (!this.isMobile) return;
    this.appInfo = await App.getInfo();
    this.currentVersion = this.appInfo.version;

    await SplashScreen.hide();

    // 앱 설치 후 최초 실행 시 한번만 실행되는 로직
    const { value } = await Preferences.get({ key: 'first_run_app' });
    if (value !== 'true') {
      await Preferences.set({ key: 'first_run_app', value: 'true' });
    }

    // Android에서만 처리해야 하는 로직
    if (this.currentPlatform === PLATFORM.ANDROID) {
      await StatusBar.setBackgroundColor({ color: Colors.WHITE_100 });
    }

    // iOS에서만 처리해야 하는 로직
    if (this.currentPlatform === PLATFORM.IOS) {
      await AppTrackingTransparency.requestPermission();
    }

    // 플랫폼에 상관없이 처리해야 하는 로직
    await StatusBar.setStyle({ style: Style.Light });
    await PushUtils.register();
  },

  async openDeviceSettings() {
    if (!this.isMobile) return;
    await (new OpenNativeSettings()).open('application_details');
  },

  moveToStore() {
    if (this.isMobile) {
      if (this.currentPlatform === PLATFORM.ANDROID)
        window.location.href = this.ANDROID_APP_STORE_LINK;
      else if (this.currentPlatform === PLATFORM.IOS)
        window.location.href = this.IOS_APP_STORE_LINK;
    } else {
      const { userAgent } = navigator;
      if (/android/i.test(userAgent))
        this.tryOpenAppOrRedirect(this.ANDROID_APP_STORE_LINK);
      else if (/iPad|iPhone|iPod/.test(userAgent))
        this.tryOpenAppOrRedirect(this.IOS_APP_STORE_LINK);
    }
  },

  tryOpenAppOrRedirect(storeLink: string) {
    window.location.href = this.URL_SCHME;
    setTimeout(() => {
      if (!document.hidden) {
        window.location.href = storeLink;
      }
    }, 2500);
  },

  getIsLowerVersion() {
    if (!this.currentVersion) return true;
    const currentVersionArr = this.currentVersion.split('.').map(Number);
    const compareVersionArr = this.MINIMUM_VERSION.split('.').map(Number);
    for (let i = 0; i < currentVersionArr.length; i++) {
      if (currentVersionArr[i] < compareVersionArr[i]) return true;
    }
    return false;
  },

  showLogMobileStoreStatus() {
    if (process.env.REACT_APP_ENV === 'production') return;
    const logingObject = {} as { [key: string]: any };
    Object.entries(this).forEach(([key, value]) => {
      if (typeof value !== 'function') logingObject[key] = value;
    });
    // eslint-disable-next-line no-console
    console.log('[MobileStore Status]', logingObject);
  },
};

export default MobileStore;
