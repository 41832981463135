import PositionCard from '__designkit__/card/PositionCard';
import CustomInfiniteScroll from 'components/CustomInfiniteScroll';
import MatchHomeNotFound, { MatchHomeNotFoundViewType } from 'components/_v2/matchHome/MatchHomeNotFound';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import PositionListModel from 'models/_v2/PositionListModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useBookmarkPositions } from 'query/bookmark/useBookmarkQuery';
import NewPositionCard from '__designkit__/card/NewPositionCard';
import MatchHomeTitleHeader from '../MatchHomeTitleHeader';

const Frame = styled.div`
    padding: 16px;
`;

const ResultFrame = styled.div`
  display: grid;
  grid-template-columns: calc(50% - 4px) calc(50% - 4px);
  align-content: center;
  justify-items: center;
  column-gap:8px;
  row-gap: 32px;
  >:nth-child(n) {
    justify-self: center;
    align-self: flex-start;
  }
`;

export interface IMyPageBookmarkPositions{
  positionListModel?:PositionListModel;
  login?:Login;
}
const MyPageBookmarkPositions:FC<IMyPageBookmarkPositions> = ({ login = new Login(), positionListModel = new PositionListModel() }) => {
  const bookmarks = positionListModel.searchBookmarks?.bookmarks;
  const { data: bookmarkPositions } = useBookmarkPositions();

  return (
    <>
      <Frame>
        <ResultFrame>
          {
          bookmarkPositions && bookmarkPositions.length > 0 && bookmarkPositions.map((data) => (

            <NewPositionCard
              key={`match-position-total-card-${data.positionSn}`}
              item={data}
            />

          ))
        }
        </ResultFrame>
      </Frame>
      {
      bookmarkPositions && bookmarkPositions.length === 0 && (
        <MatchHomeNotFound content={MatchHomeNotFoundViewType.BOOKMARK} height={282} />
      )
    }
    </>

  );
};

export default inject(injectStore.login, injectStore.positionListModel)(observer(MyPageBookmarkPositions));
