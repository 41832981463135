import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import ProfileItemTitle from 'components/_v2/profile/ProfileItemTitle';
import { TagTextBox } from 'components/_v2/profile/preferential/ProfilePreferentialView';
import { Divider1G40 } from 'components/divider/Divider';
import { DisabilityGradeTagStr, DisabilityTypeStr } from 'consts/_v2/profile/DisabilityType';
import { MilitaryClassStr, MilitaryDischargeTypeStr, MilitaryStatusStr, MilitaryTypeStr } from 'consts/_v2/profile/MilitaryType';
import { VeteranType } from 'consts/_v2/profile/VeteranType';
import { IconCalendar } from 'consts/assets/icons/iconPages';
import { IProfilePreferentialRqRs } from 'interfaces/_v2/profile/IProfilePreferentialRqRs';
import React, { FC } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
`;

const ContentFrame = styled.div`
    margin-bottom: 32px;

    .title {
        font: ${Fonts.B1_Bold};
        line-height: 22px;
        color: ${colors.JOBDA_BLACK};
        margin-bottom: 16px;
    }

    .tag-box-frame {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        >span {
            font: ${Fonts.B2_Bold};
            color: ${colors.CG_90};
            margin-left: 8px;
        }
    }

    .military-date-frame {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        >span {
            font: ${Fonts.B3_Medium};
            line-height: 18px;
            color: ${colors.JOBDA_BLACK};
            margin-left: 4px;
        }
    }

    .militaryClass-dischargeType {
        font: ${Fonts.B3_Medium};
        ${colors.CG_70};
    }
`;

const Divider = styled.div`
    width: 100%;
    height: 1px;
    background: ${colors.CG_40};
    margin-bottom: 32px;
`;

const ProfilePreviewPreferential: FC<{ data: IProfilePreferentialRqRs}> = ({ data }) => {
  const { military, disability, veteran } = data;
  return (
    <Frame>
      <ProfileItemTitle icon='military-tech' text='병역 · 취업 우대' />
      { military && (
      <ContentFrame>
        <p className='title'>병역사항</p>
        <div className='tag-box-frame'>
          <TagTextBox text={military?.status ? MilitaryStatusStr[military.status] : ''} />
          <span>{military?.type ? MilitaryTypeStr[military.type] : ''}</span>
        </div>
        { military?.startDate && (
        <div className='military-date-frame'>
          <IconCalendar />
          <span>{military?.startDate} ~ {military?.endDate ? military?.endDate : '복무중'}</span>
        </div>
          )}
        <span className='militaryClass-dischargeType'>
          {military?.militaryClass ? `${MilitaryClassStr[military.militaryClass]} ${military.dischargeType ? '/ ' : ''}` : ''}
          {military?.dischargeType ? MilitaryDischargeTypeStr[military.dischargeType] : ''}
        </span>
      </ContentFrame>
      )}

      { (disability || veteran) && (
      <>
        { military && <Divider /> }
        <ContentFrame>
          <p className='title'>우대사항</p>
          { disability && (
          <div className='tag-box-frame'>
            <TagTextBox text={disability.grade ? DisabilityGradeTagStr[disability.grade] : ''} />
            <span>{disability.type ? DisabilityTypeStr[disability.type] : ''}</span>
          </div>
          )}
          { veteran && (
          <div className='tag-box-frame'>
            <TagTextBox text={veteran.status === VeteranType.VETERAN ? '보훈 대상자' : '보훈 비대상'} />
            <span>{veteran.reason ? veteran.reason : ''}</span>
          </div>
          )}
        </ContentFrame>
      </>
      )}
      <Divider1G40 />
    </Frame>
  );
};

export default ProfilePreviewPreferential;
