import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';

import Context from 'models/Context';
import colors from '__designkit__/common/colors';
import IContextPageProps from 'interfaces/props/IContextPageProps';
import Fonts from '__designkit__/common/fonts';

const TAB_HEIGHT = 43;

const ComponentTabsFrame = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  background: white;
`;

export const StyledTabsFrame = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
  display: flex;
  flex-wrap:nowrap;
  height: ${TAB_HEIGHT}px;
  transition: top .25s;
  top: ${(props) => ((props as any)['data-scrollup'] ? '56' : '0')}px;
  overflow-x:auto;
  padding: 0 20px;
  ::-webkit-scrollbar {
    width:0;
    height:0;
  }
`;

export const StyledTab = styled.div`
  flex:1;
  width:fit-content;
  text-align: center;
  background: ${colors.WHITE_100};
  font: ${Fonts.B2_Medium_P};
  color: ${colors.JOBDA_BLACK};
  border-bottom: 2px solid ${colors.CG_40};

  &.blend-mode {
    background: ${colors.cffffff_0};   
    color: ${colors.c3A7977};
    border-bottom: none;
    &.active {
      > button {
        color: ${colors.c3A7977};
      }
      border-bottom: 2px solid ${colors.CG_40};
    }
  }
  
  > button {
    padding: 0 5px;
    color: ${colors.JOBDA_BLACK};
    font: ${Fonts.B1_Medium};
    height: 100%;
    background: none;
  }
  
  &.active {
    > button {
      font: ${Fonts.B1_Bold};
      color: ${(props) => ((props as any)['data-underline'] ? colors.CG_90 : colors.JOBDA_BLACK)};
    }
    border-bottom: 2px solid ${(props) => (props as any)['data-underline'] || colors.JOBDA_BLACK};
  }
`;

const StyledComponent = styled.div`
  width: 100%;
  min-height: inherit;
  text-align: left;
  
  &.hidden {
    visibility: hidden;
    position: absolute;
  }
`;

interface IComponentWithTabsProps extends IContextPageProps {
  tabs: string[];
  initTab?: number;
  components: any[];
  containHeader?: boolean;
  onChangeComponent?: (index: number) => void;
  underline?: string;
}

const ComponentsWithTabs: FC<IComponentWithTabsProps> = ((props) => {
  const { tabs, initTab, components, containHeader, onChangeComponent, underline, context = new Context() } = props;
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  useEffect(() => {
    if (tabs.length < 1) {
      throw new Error('Not Found Tabs in IonSlidesTabs.');
    }
    if (components.length < 1) {
      throw new Error('Not Found Contents in IonSlidesTabs.');
    }
    if (tabs.length !== components.length) {
      throw new Error('Do not match length of Tabs and Contents.');
    }
  }, [components.length, tabs.length]);

  useEffect(() => {
    if (initTab !== undefined) {
      if (tabs.length <= initTab) {
        throw new Error('InitTab value is greater than Tabs length.');
      }
      setActiveTabIndex(initTab);
    }
  }, [initTab, tabs.length]);

  return (
    <ComponentTabsFrame>
      <StyledTabsFrame className='tabs-frame' data-scrollup={containHeader && !context.hideHeader}>
        {
          tabs.map((text, index) => (
            <StyledTab
              className={`${index === activeTabIndex ? 'active tab-item' : 'tab-item'} ${context.blendMode.active ? 'blend-mode' : ''}`}
              data-underline={underline}
              onClick={() => {
                setActiveTabIndex(index);
                onChangeComponent && onChangeComponent(index);
              }}
            >
              <button>{text}</button>
            </StyledTab>
          ))
        }
        {
          tabs.length === 1 && (
            <StyledTab />
          )
        }
      </StyledTabsFrame>
      <StyledComponent>{ components[activeTabIndex] }</StyledComponent>
    </ComponentTabsFrame>
  );
});

export default inject(injectStore.context)(observer(ComponentsWithTabs));
