import React, { useEffect, useRef, useState } from 'react';

interface IFrameProps {
  srcDoc: string;
}

const AutoHeightIFrame: React.FC<IFrameProps> = ({ srcDoc }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [iframeHeight, setIframeHeight] = useState<number>(0);

  useEffect(() => {
    const iframe = iframeRef.current;

    const handleLoad = () => {
      if (iframe && iframe.contentWindow) {
        const { body } = iframe.contentDocument || iframe.contentWindow!.document;
        setIframeHeight(body.offsetHeight);
      }
    };

    if (iframe) {
      iframe.addEventListener('load', handleLoad);
    }

    return () => {
      if (iframe) {
        iframe.removeEventListener('load', handleLoad);
      }
    };
  }, []);

  return (
    <iframe
      className='pinch-frame'
      allow='autoplay; fullscreen; picture-in-picture'
      sandbox="allow-same-origin allow-scripts allow-popups"
      srcDoc={srcDoc}
      ref={iframeRef}
      title='iframe'
      scrolling='no'
      width='100%'
      height={iframeHeight}
    />
  );
};

export default AutoHeightIFrame;
