import colors from '__designkit__/common/colors';
import Fonts, { fonts } from '__designkit__/common/fonts';
import IModalFrameProps from 'interfaces/IModalFrameProps';
import React, { FC } from 'react';
import styled from 'styled-components';
import SupportMail from 'consts/_v2/GlobalText';
import { JDAModalButton, JDAModalContentDescription, JDAModalContentText } from './JDAModalComponents';
import JDAModalFrame from './JDAModalFrame';

const StyledModalContent = styled.div`
  text-align: center;
  padding: 24px;
  
  ${JDAModalContentText} {
    margin-top: 16px;
    margin-bottom: 8px;
  }

  ${JDAModalContentDescription} {
      font: ${Fonts.B1_Medium};
      line-height: 23px;
      margin-bottom: 24px;
      color: ${colors.CG_80};

      >p {
          ${fonts.NOTO_13_500};
          letter-spacing: -0.09px;
          line-height: 18px;
          margin-top: 8px;
          color: ${colors.B_90};
      }
  }

  ${JDAModalButton} {
    margin-bottom: 12px;
    background-color: ${colors.JOBDA_BLACK};
  }
`;

const MatchAtsFailModal:FC<IModalFrameProps> = ((props) => {
  const { onClickClose, ...rest } = props;
  return (
    <JDAModalFrame {...rest}>
      <div style={{ width: 312 }}>
        <StyledModalContent>
          <JDAModalContentText>
            현재 서비스가 원활하지 않습니다.
          </JDAModalContentText>
          <JDAModalContentDescription>
            일시적인 장애이거나 네트워크<br />
            문제일 수 있습니다. 오류가 지속될 경우<br />
            아래의 E-mail (잡다 헬프데스크)로<br />
            문의 해 주세요.

            <p>{SupportMail}</p>
          </JDAModalContentDescription>

          <JDAModalButton onClick={onClickClose}>확인</JDAModalButton>
        </StyledModalContent>
      </div>
    </JDAModalFrame>
  );
});

export default MatchAtsFailModal;
