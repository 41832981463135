import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import { Divider12B } from 'components/divider/Divider';
import { ScrollHiddenMixin } from 'consts/_v2/_common/style/mixins';
import { IDchampsRankingDto } from 'interfaces/_v2/phs/IDChampsRqRs';
import { inject, observer } from 'mobx-react';
import PaccDchampsModel from 'models/_v2/PaccDChampsModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

const PaccFrame = styled.div<{isRotated?:boolean}>`
  .title-frame{
    padding:32px 16px 0 16px;
    
    .title-text{
      font:${Fonts.H5_Bold};
      color:${colors.JOBDA_WHITE};
    }
    .subtitle-frame{
      display: flex;
      justify-content: space-between;
      align-items: end;
      .subtitle-text{
        font:${Fonts.B3_Medium_P};
        color:${colors.CG_60};
      }
    }

  }

`;
const Frame = styled.div`
    padding:0 16px 32px 16px;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-auto-flow: column;
    width: 100%;
    overflow: hidden;
    display: grid;
    grid-gap: 8px;
    grid-auto-flow: column;
    overflow-x: scroll;
    ${ScrollHiddenMixin()}
    position: relative;

`;
const RankingBox = styled.div<{top?:boolean}>`
    padding: 16px;
    display:flex;
    justify-content: flex-start;
    border: 1px solid rgba(255,255,255,0.20);
    border-radius: 4px;
    background-color:${colors.CG_90};
    width:136px;
    .ranking{
        display:flex;
        width:20px;
        height:20px;
        justify-content:center;
        align-items:center;
        background-color:${colors.CG_90};
        border-radius:2px;
        .ranking-text{
            color:${colors.WHITE_100};
            font:${Fonts.B4_Bold};
        }
    }
    .ranker-name{
      font:${Fonts.B3_Bold};
      color:${colors.JOBDA_WHITE};
    }
`;

export interface IPaccGateDChampsRanking {
  paccDChampsModel?:PaccDchampsModel;
}
const PaccGateDChampsRanking:FC<IPaccGateDChampsRanking> = ({ paccDChampsModel = new PaccDchampsModel() }) => {
  const init = async () => {
    await paccDChampsModel.loadDchampsFinalsUserList(paccDChampsModel.dChampsCurrentMonth || paccDChampsModel.dChampsCurrentFinalsMonth);
  };
  useEffect(() => {
    init();
  }, []);

  return (
    <PaccFrame>
      {
        paccDChampsModel.dChampsRanking
        && (
          <>
            <div className='title-frame'>
              <div className='title-text'>
                9월 디챔스 본선 대상자 선정 완료
              </div>
              <SpacingBlock size={8} vertical />
              <div className='subtitle-frame'>
                <div className='subtitle-text'>
                  10월 1일 오후 3시에 본 페이지에서 진행됩니다.
                </div>
              </div>

            </div>
            <SpacingBlock size={16} vertical />
            <Frame>
              {paccDChampsModel.dChampsRanking && paccDChampsModel.dChampsRanking.users.map((card:IDchampsRankingDto) => (
                <RankingBox>
                  <SpacingBlock size={8} />
                  <div className='ranker-name'>{card.userId}</div>
                </RankingBox>
              ))}

            </Frame>
            <Divider12B />
          </>
        )
      }

    </PaccFrame>
  );
};

export default inject(injectStore.paccDChampsModel)(observer(PaccGateDChampsRanking));
