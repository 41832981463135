/* eslint-disable react-hooks/exhaustive-deps */
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import { injectStore } from 'models/store';
import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

interface IJDAHistoryRouteController{
  context ?: Context;
}
const JDAHistoryRouteController: FC<IJDAHistoryRouteController> = ({ context = new Context() }) => {
  const history = useHistory<{[key: string]: any}>();
  let prevState: {[key: string]: any} = {};

  useEffect(() => {
    prevState = { ...history.location.state };

    const unlisten = history.listen((location, action) => {
      if (location.pathname !== '/login' && !context.previousPathList.includes(location.pathname)) {
        context.updatePreviousPath(location.pathname);
      }
      if (action === 'PUSH' && location.state?.state !== 'listen') {
        history.replace({ ...location, state: { ...prevState, key: 'jobda', state: 'listen' } });
      } else if (action === 'PUSH') {
        prevState = { ...location.state };
      } else if (action === 'REPLACE' && location.state?.state !== 'listen') {
        prevState = { ...prevState, state: 'listen' };
        history.replace({ ...location, state: prevState });
      } else if (action === 'POP' && location.state?.state !== 'listen') {
        prevState = { ...prevState, state: 'listen' };
        history.replace({ ...location, state: prevState });
      } else {
        prevState = { ...location.state };
      }
    });
    return () => {
      unlisten();
    };
  }, []);

  return (<></>);
};

export default inject(injectStore.context)(observer(JDAHistoryRouteController));
