import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { JDASmallButton } from 'consts/style/mixins';
import useCountering from 'utils/useCountering';
import CountUp from 'components/_v2/_common/count/countUp';
import CountUpComplex from 'components/_v2/_common/count/countUpComplex';
import useScrollAniRef from 'components/useScrollAniRef';
import WaveComponent from '../../components/wave/HeartWave';

const Frame = styled.div`
  padding: 32px;
  .container {
    padding: 16px;
    
    .value {
      ${JDASmallButton(true, true)};
      display: inline-block;
      width: 128px;
    }
    .btn {
      ${JDASmallButton(false, true)};
      display: inline-block;
      width: 128px;
    }
  }
`;

const Test1: FC = (() => {
  const [value, setValue] = useState<number>(0);
  const [counter, updateCounter] = useCountering(value);

  const addValue = () => {
    setValue(value + 1000);
  };

  const reduceValue = () => {
    setValue(value - 1000);
  };

  useEffect(() => {
    updateCounter(value);
  }, [value]);

  return (
    <div className='container'>
      <button className='value'>{ counter }</button>
      <button className='btn' onClick={addValue}>돈 올리기</button>
      <button className='btn' onClick={reduceValue}>돈 내리기</button>
      <div>
        <WaveComponent />

      </div>

    </div>
  );
});

const CountingPage: FC = (() => {
  const [visible, setVisible] = useState<boolean>(false);
  const [visibleTwo, setVisibleTwo] = useState<boolean>(false);

  const refInfo = useScrollAniRef<HTMLDivElement>({
    effectClass: 'visible',
    activeCallback: () => setVisible(true),
    visible: true,
    threshold: 0.35,
  });

  const refInfoTwo = useScrollAniRef<HTMLDivElement>({
    effectClass: 'visible',
    activeCallback: () => setVisibleTwo(true),
    visible: true,
    threshold: 0.35,
  });

  return (
    <>
      <Frame>
        <Test1 />
        <WaveComponent />
        <div style={{ height: '1000px' }} >
          스크롤을 내려보세요!
        </div>
        <div ref={refInfo}>

          <CountUp
            count={50}
            fontSize={20}
            visible={visible}
          />
        </div>
        <div ref={refInfoTwo}>

          <CountUpComplex
            visible={visibleTwo}
            count={400}
          />
        </div>

        <WaveComponent />
      </Frame>
    </>
  );
}

);

export default CountingPage;
