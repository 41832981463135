import { Button, ButtonMode } from '__designkit__/button/Button';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import ProfileEditTitleComponent from 'components/_v2/profile/ProfileEditTitleComponent';
import TabTitle from 'components/_v2/profile/condition/TabTitle';
import { ConditionRegisterType } from 'consts/_v2/profile/ProfileType';
import useActiveTabFlag from 'hooks/useActiveTabFlag';
import useQueryParams from 'hooks/useQueryParams';
import useToast from 'hooks/useToast';
import { IPutConditionWorkingPreferenceRq } from 'interfaces/_v2/profile/IConditionRqRs';
import IComponentProps from 'interfaces/props/IComponentProps';
import useProfileConditions, { useFetchSaveProfileConditionsWorkingPreference } from 'query/profiles/useProfileConditionsQuery';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

const TabFrame = styled.div<{isResumeModal?:boolean}>`
  width: 100%;
  height: calc(100% - 78px);
  background-color: ${Colors.WHITE_100};
 `;
const BtnFrame = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 0 16px;
  height: fit-content;
  position: fixed;
  bottom: env(safe-area-inset-bottom);
  height: 78px;
  background-color: ${Colors.WHITE_100};
  z-index: 100000;
`;
const ButtonFrame = styled.div`
  position: fixed;
  bottom: env(safe-area-inset-bottom);
  width: 100%;
  height: 78px;
  padding: 16px;
  border-top: 1px solid ${Colors.CG_40};
  background-color: ${Colors.WHITE_100};
`;
const Frame = styled.div<{isResumeModal?:boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
  margin: auto 0;
  padding: 0 16px;

`;

const WorkingPreferenceTextArea = styled.textarea`
   border-radius: 4px 4px 0 0;
   border-color: ${Colors.CG_40};
   width: 100%;
   height: 252px;
   padding:16px;
   background-color: ${Colors.CG_30};
   font:${Fonts.B2_Medium_P};
`;

const ExampleFrame = styled.div<{rotateX?:number, ANGLE?:number}>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    border: 1px solid ${Colors.CG_40};
    border-top: none;
    border-radius: 0 0 4px 4px;
    width: 100%;
    .rolling-space{
        display: flex;
        align-items: center;
        .example-text{
            font:${Fonts.B4_Medium};
            color:${Colors.G_150};
            margin-right: 6px;
        }
        .rotator-area{
            overflow: hidden;
            .rotator{
                position: relative;
                display: flex;
                align-items: center;
                width: 240px;
                height: 14px;
                transform-style: preserve-3d;
                transition: all 1s ease-in-out;
                transform: ${(props) => `rotateX(${props.rotateX}deg)`};
                .item {
                    position: absolute;
                    backface-visibility: hidden;
                    font:${Fonts.B4_Medium};
                    color:${Colors.CG_70};
                }
            }
        }
    }
    .length-text{
        ${Fonts.B4_Medium};
        color:${Colors.CG_60};
    }

`;

const placeholder = `· 금융, IT 등 선호하는 업종을 작성해 주세요.
· 스타트업, 공기업 등 원하는 기업 형태를 작성해 주세요.
· 커리어 성장, 사내 분위기 등 그 외의 중요하게 생각하는 조건을 적어주세요.`;

const rollingExampleText = [
  '스타트업에서 일하는 것도 괜찮아요.',
  '경기남부와 서울 강남권을 선호해요.',
  '최소 연봉은 4천 이상이면 좋겠어요.',
  'AI 쪽 업종을 선호해요.',
  'UI/UX 프로덕트 디자인 직무로 일하고 싶어요.',
  'JAVA 기술을 위주로 사용하는 곳이면 좋겠어요.',
  'B2C 인하우스 기업에 취업하고 싶어요.',
  '연봉보다는 커리어 성장이 더 중요해요.',
];
const ANGLE = 360 / rollingExampleText.length;

const maxLength = 500;
interface IWorkingPreferenceTabProps extends IComponentProps {
  workingPreferenceData?:string;
  hidden?: boolean;
  setConditionRegisterType: (type: ConditionRegisterType) => void;
  isResumeModal?: boolean;

}
const WorkingPreferenceTab: FC<IWorkingPreferenceTabProps> = ({ isResumeModal, workingPreferenceData, hidden, setConditionRegisterType }) => {
  const [rotateX, setRotateX] = useState(0);
  const defaultValues:IPutConditionWorkingPreferenceRq = { workingPreference: '', sourcePage: '' };
  const formMethods = useForm({
    mode: 'onSubmit',
    defaultValues,
  });
  const { setValue, getValues, register, watch, reset } = formMethods;
  const { setToastObject } = useToast();
  const { sourcePage } = useQueryParams();
  const { refetch } = useProfileConditions(true);
  const initFlag = useActiveTabFlag();
  const workingPreferenceValue = watch('workingPreference');
  const textLength = workingPreferenceValue?.length || 0;

  const saveSuccess = async () => {
    await refetch();
    setToastObject({ type: 'SUCCESS', isOpen: true, message: '성공적으로 저장되었습니다.' });
    setConditionRegisterType(ConditionRegisterType.LIST);
  };
  const { mutate: saveProfileWorkingPreference } = useFetchSaveProfileConditionsWorkingPreference(saveSuccess);

  useEffect(() => {
    if (!workingPreferenceData) return;
    reset({ workingPreference: workingPreferenceData });
  }, [workingPreferenceData]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRotateX((prev) => prev - ANGLE);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  const onClickSaveBtn = () => {
    const workingPreferenceRq:IPutConditionWorkingPreferenceRq = { workingPreference: getValues('workingPreference'), sourcePage };
    saveProfileWorkingPreference(workingPreferenceRq);
  };

  const handleTextAreaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    if (value.length <= maxLength) {
      setValue('workingPreference', value, { shouldValidate: true });
    }
  };
  if (!initFlag) return null;
  if (hidden) return null;
  return (
    <TabFrame isResumeModal={isResumeModal}>
      <FormProvider {...formMethods}>
        <ProfileEditTitleComponent
          title='희망하는 근무 조건을 자유롭게 입력해 주세요.'
          subtitle='상세히 입력할수록 더 정확한 공고와 포지션을 추천해 드릴게요.'
          divider={false}
        />
        <Frame isResumeModal={isResumeModal}>
          <WorkingPreferenceTextArea
            ref={register}
            onChange={handleTextAreaChange}
            name='workingPreference'
            placeholder={placeholder}
          />
          <ExampleFrame rotateX={rotateX} ANGLE={ANGLE}>
            <div className='rolling-space'>
              <div className='example-text'>예시</div>
              <div className='rotator-area'>
                <div className='rotator'>
                  {rollingExampleText.map((text, index) => (
                    <div
                      className='item'
                      key={`example-${text}`}
                      style={{ transform: `rotateX(${index * ANGLE}deg) translateZ(50px)` }}
                    >{text}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className='length-text'>{textLength}/500</div>
          </ExampleFrame>
        </Frame>
      </FormProvider>
      {
        isResumeModal
          ? (
            <BtnFrame>
              <Button label='이전' buttonMode={ButtonMode.DEFAULT} size='large' outLined onClick={() => setConditionRegisterType(ConditionRegisterType.LIST)} />
              <Button label='저장하기' buttonMode={ButtonMode.PRIMARY} size='large' onClick={onClickSaveBtn} />
            </BtnFrame>
          )
          : (
            <ButtonFrame>
              <Button label='저장' buttonMode={ButtonMode.PRIMARY} size='large' onClick={onClickSaveBtn} />
            </ButtonFrame>
          )
      }

    </TabFrame>
  );
};

export default WorkingPreferenceTab;
