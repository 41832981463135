export enum AccSelfReportTaskType {
    LIKERT = 'LIKERT',
    META_LIKERT = 'META_LIKERT',
    MULTIPLE_CHOICE='MULTIPLE_CHOICE',
    BIPOLAR = 'BIPOLAR'
}

export enum AccSelfReportTaskTypeText {
    LIKERT = '나 알아보기',
    META_LIKERT = '타인 관점에서 나 알아보기',
    MULTIPLE_CHOICE='여러 개 선택하기',
    BIPOLAR = '하나만 선택하기'
}

export enum AccVideoType {
    VIDEO_BASIC_EXPERIENCE='VIDEO_BASIC_EXPERIENCE',
    VIDEO_BASIC_SITUATION='VIDEO_BASIC_SITUATION',
    VIDEO_BASIC_VALUES = 'VIDEO_BASIC_VALUES',
    VIDEO_BASIC_FREE_CHOICE = 'VIDEO_BASIC_FREE_CHOICE'
}

export enum AccVideoTypeText {
    VIDEO_BASIC_EXPERIENCE='경험 기반',
    VIDEO_BASIC_SITUATION='상황',
    VIDEO_BASIC_VALUES = '가치관',
    VIDEO_BASIC_FREE_CHOICE = '질문 선택'
}

export enum AccVideoSubType{
    MAIN='MAIN',
    SUB1='SUB1',
    SUB2='SUB2',
    SUB3='SUB3'
}

export enum AccVideoSubTypeText{
    MAIN='0',
    SUB1='1',
    SUB2='2',
    SUB3='3'
}

export enum AccGameType {
    RPS='RPS',
    MRT ='MRT',
    PM = 'PM',
    RMT = 'RMT',
    PCT2 = 'PCT2',
    OTN = 'OTN',
    FNB = 'FNB',
    HAS = 'HAS',
    WNC = 'WNC'
}

export enum AccGameTypeText {
    RPS='가위바위보',
    MRT ='도형 회전하기',
    PM = '약속 정하기',
    RMT = '길 만들기',
    PCT2 = '마법약 만들기',
    OTN = '숫자 누르기',
    FNB = '도형 순서 기억하기',
    HAS = '고양이 술래잡기',
    WNC = '개수 비교하기'
}

export enum AccVideoQuestionType {
    MAIN = 'MAIN',
    SUB1 = 'SUB1',
    SUB2 = 'SUB2',
    SUB3 = 'SUB3'
}
export enum AccGradeResultType {
    CHEER_UP = 'CHEER_UP',
    GOOD = 'GOOD',
    VERY_GOOD = 'VERY_GOOD'
}

export enum AccGradeResultTypeText{
    CHEER_UP = 'Cheer Up',
    GOOD = 'Good',
    VERY_GOOD = 'Very Good'
}

export enum AccApplyResultType{
    SELF_REPORT = 'SELF_REPORT',
    VIDEO = 'VIDEO',
    GAME = 'GAME'
}
