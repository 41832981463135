import RoutePaths from 'consts/RoutePaths';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { lineClampBox } from 'consts/_v2/_common/style/mixins';
import { IChallengeCard } from 'interfaces/_v2/challenge/IChallengeList';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { DateFormatMMDDDot, overDateNow } from 'utils/DateUtils';
import ImgDumy from 'assets/_v2/positionList/img_dumy.png';

const StyledChallengeCard = styled.div<{open:string}>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  >div{
    position: absolute;
    top:17px;
    left:17px;
    width: 48px;
    height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 4px;
    font: ${Fonts.B4_Bold};
    ${(props) => (props.open === '진행중' ? `
      background:${colors.JOBDA_BLACK};
      color:${colors.WHITE_100}
    ` : (props.open === '종료'
    ? `
      background:${colors.CG_50};
      color:${colors.WHITE_100}
    `
    : `
      background:${colors.WHITE_100};
      color:${colors.CG_70}
    `
  ))}
  }

  img{
    width: 100%;
    height: 180px;
    border-radius:6px;
    object-fit: cover;
    margin-bottom: 20px;
  }
  h1{
    font: ${Fonts.B1_Bold};
    color:${colors.JOBDA_BLACK};
    ${lineClampBox(2)};
    margin-bottom: 10px;
  }
  h2{
    >span{
      font: ${Fonts.B3_Bold};
      margin-right:16px;
    }
    word-break: normal;
    font: ${Fonts.B3_Medium};
    color:${colors.JOBDA_BLACK};
    ${lineClampBox(1)};
    margin-bottom: 3px;
  }
  h3{
    font: ${Fonts.B4_Medium};
    color:${colors.CG_60}; 
    ${lineClampBox(2)};
  }
`;

const ChallengeCard: FC<{ item: IChallengeCard }> = ({ item }) => {
  const getState = () => {
    if (item.endYn) return '종료';
    if (!overDateNow(item.postStartDateTime)) return '준비중';
    if (overDateNow(item.postStartDateTime) && !overDateNow(item.postEndDateTime)) return '진행중';
    if (overDateNow(item.postStartDateTime) && overDateNow(item.postEndDateTime)) return '종료';
  };
  const state = String(getState());
  return (
    <StyledChallengeCard open={state}>
      <div>
        <span>{state}</span>
        <span>-</span>
        <span>{DateFormatMMDDDot(item.postEndDateTime)}</span>
      </div>
      <Link to={RoutePaths.challenge_detail(item.sn)}>
        <img
          src={item.bannerImageUrl}
          alt='img-card'
          onError={(e) => e.currentTarget.src = ImgDumy}
        />
      </Link>
      <h1>{item.title}</h1>
      <h2><span>{item.type === 'COMPANY' ? '참여 기업' : '참여 포지션'}</span>{item.participants}</h2>
      <h3>{`#${item.description.replace(/,/g, ' #')}`}</h3>
    </StyledChallengeCard>
  );
};
export default ChallengeCard;
