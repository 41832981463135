import React, { FC } from 'react';
import SwiperCore, { Autoplay, Controller, Navigation, Pagination } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import colors from '__designkit__/common/colors';
import 'consts/style/swiper.css';
import styled from 'styled-components';
import { PaginationOptions } from 'swiper/types/components/pagination';
import Fonts from '__designkit__/common/fonts';

/**
 * Swiper 공통으로 사용할만한 것들을 추가해두려고 함.
 * https://swiperjs.com/react  라이브러리 활용
 * 주의사항
 * 커스텀 컴포넌트를 JDSwiperSlide로 감싸줘야 렌딩될때 swiper-wrapper가 계산됨
 * 커스텀안에 JDSwiperSlide 를 선언해서 구현하면 렌딩안됨 PositionAd 참고
*/

export interface IJDASwiperProps extends Swiper {
  paginationtype?:PageNationType;
}

SwiperCore.use([Pagination, Autoplay, Navigation, Controller]);

const StyledSwiper = styled(Swiper)`
  margin-bottom:0;
  .swiper-wrapper{
    width: fit-content;
  }


  .swiper-button-prev, .swiper-button-next {
    background-image:none;
  }
  .swiper-button-prev:after, .swiper-button-next:after {
    content:'';
    display:block;
  }

  .pagination-bullets{

    .swiper-pagination-bullet {
      bottom:0px;
      width:8px;
      height:8px;
      margin:0 6px;
      background:${colors.CG_60};
      transition: width .4s;
      border-radius: 8px;
    }
    .swiper-pagination-bullet-active {
      background:${colors.JOBDA_BLACK};
    }

  }

  .swiper-pagination-fraction{
    >div{
      position: absolute;
      bottom: 2px;
      right:32px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding:2px 6px;
      background: rgba(18, 22, 25, 0.5);
      border-radius: 2px;

      .swiper-pagination-current{
        font: ${Fonts.B4_Medium};
        color:${colors.WHITE_100};
        opacity: 1 !important;
      }
      .bar{
        height:6px;
        width:1px;
        background: ${colors.CG_60};
        margin: 0px 4px 0px 4px;
      }
      .swiper-pagination-total{
        font: ${Fonts.B4_Medium};
        color:${colors.CG_60};
    }
  }
}
`;

export enum PageNationType{
  fraction='fraction',
  bullets='bullets'
}

export const JDFractions: PaginationOptions = {
  type: 'fraction',
  renderFraction(currentClass, totalClass) {
    return `<div><span class='${currentClass}'></span><div class='bar'></div><span class='${totalClass}'></span></div>`;
  },
};

export const JDBullets: PaginationOptions = {
  type: 'bullets',
  el: '.pagination-bullets',
  renderBullet(index, className) {
    return `<span class="${className}"></span>`;
  },
};

const getPagination = (type?:PageNationType) => {
  switch (type) {
    case 'fraction':
      return JDFractions;
    case 'bullets':
      return JDBullets;
    default:
      return false;
  }
};

const JDSwiper: FC<IJDASwiperProps> = (props) => (
  <StyledSwiper
    observeParents
    observer
    updateOnImagesReady
    pagination={getPagination(props.paginationtype)}
    {...props}
  >
    {props.children}
    {props.paginationtype === PageNationType.bullets && <div className='pagination-bullets' />}
  </StyledSwiper>
);

export const JDSwiperSlide = SwiperSlide;

export default JDSwiper;
