import React from 'react';

import classnames from 'classnames/bind';
import styles from './intro.module.scss';

const cx = classnames.bind(styles);

const Intro = () => (
  <div className={cx('wrap')}>
    <div className={cx('titleArea')}>
      <strong className={cx('title')}>초고속 취업,<br />JOBDA 챌린지</strong>

      <p className={cx('desc')}>
        챌린지 참여 혜택받고
        <br />
        역량만으로 쉽고 빠르게 취업하세요!
      </p>

      <p className={cx('date')}>2023년 8월 31일(목) ~ 9월 25일(월)</p>
    </div>
  </div>
);

export default Intro;
