import BottomNavigation from 'components/_v2/_common/navigation/BottomNavigation';
import IContextPageProps from 'interfaces/props/IContextPageProps';
// import { analytics } from 'utils/FirebaseUtils';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import MobileStore from 'store/mobileStore';
import IonicPageFrame from './IonicPageFrame';
import WebPageFrame from './WebPageFrame';
// import { RoutePathsNeedNavigation } from 'consts/RoutePaths';

export enum NavType {
  NONE = 'NONE', // 네비게이션이 없는 타입
  JOBDA = 'JOBDA', // 잡다 기본 내비게이션 (취업정보)
}

export interface IPageFrameProps extends IContextPageProps {
  background?: string;
  header?: React.ReactElement;
  scrollRestore?: boolean;
  footer?: boolean;
  scrollInitialize?: boolean;
  scrollRestoreDelay?: number;
  scrollBehavior?: 'smooth' | 'auto';
  navType?: NavType;
}

export const WebFrame = styled.div`
  background: ${(props: any) => (props['data-background'] ? props['data-background'] : 'none')};
  min-height: 100%;
  > .frame-content {
    padding-bottom:64px;
  }
  .frame-tab-bar {
    position: fixed;
  }
`;

// 하단 네비게이션
export const NavRenderer: FC<{ navType: NavType }> = ({ navType }) => {
  switch (navType) {
    case NavType.NONE:
      return <></>;
    default:
      return <BottomNavigation />;
  }
};

const PageFrame: FC<IPageFrameProps> = ({ ...props }) => {
  return MobileStore.isMobile ? (
    <IonicPageFrame {...props} />
  ) : (
    <WebPageFrame {...props} />
  );
};

export default PageFrame;
