/* eslint-disable react-hooks/exhaustive-deps */
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { ReactComponent as IconReset } from 'assets/_v2/_common/input/icon_reset.svg';
import { ReactComponent as IconArrowDown } from 'assets/_v2/_common/input/icon_small_arrow_down.svg';
import JDSquareCheckBox from 'components/_v2/_common/input/JDSquareCheckBox';
import JDUnderBaseModal from 'components/_v2/_common/modals/JDUnderBaseModal';
import { PositionFiltersProps } from 'components/_v2/position/PostionFilterTab';
import { JDButton, JDSmallSeletor, ScrollHiddenMixin } from 'consts/_v2/_common/style/mixins';
import { StyledIconSVG } from 'consts/assets/icons/iconPages';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import PositionListModel from 'models/_v2/PositionListModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

export const StyledSmallSelector = styled.div<{ count?: number }>`
  ${JDSmallSeletor()}

  ${(props) => props.count && `
    border: 1px solid ${colors.JOBDA_BLACK};
    color:${colors.JOBDA_BLACK};
  `}
`;

const Frame = styled.div`

`;

export const StyledSelectModalFrame = styled.div`

  .title {
    text-align: center;
    padding: 16px 0;
    color: ${colors.CG_90};
    font: ${Fonts.H5_Bold};
    border-bottom: 1px solid ${colors.CG_40};
  }
  
  .content-frame{
    height: 70vh;
    padding:32px 0px 96px 0px;
    overflow-y: auto;
    ${ScrollHiddenMixin()}
    
    .group-frame{
      margin-bottom:32px;
      h1{
        font: ${Fonts.B1_Bold}; 
        margin-bottom:20px;
        color:${colors.CG_90};
      }
      span{
         font: ${Fonts.B2_Medium}; 
         color:${colors.CG_80};
       }
      .group-items{
        display: grid;
        align-content: center;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 18px;
      }

  }
}
  
  .btn-frame{
      position:fixed;
      width: calc(100% - 32px);
      height:72px;
      display: flex;
      justify-content: space-around;
      align-items:center;
      background: ${colors.WHITE_100};
      padding-bottom: env(safe-area-inset-bottom);
      bottom: env(safe-area-inset-bottom);

      .btn-search{
        ${JDButton(false, '200px')}
        font: ${Fonts.B2_Bold};
        height:46px;
        border-radius: 4px;
      }
      .btn-reset{
        display: flex;
        align-items: center;
        svg{
          margin-right:8px;
        }
        font: ${Fonts.B3_Medium};
        ${colors.JOBDA_BLACK};
      }
      .btn-select {
        ${JDButton(false)}
        font: ${Fonts.B2_Bold};
        height:46px;
        border-radius: 4px;  
      }
      
      button:disabled {
        background: ${colors.CG_40};
        color: ${colors.CG_60};
      }
    }

`;

const PositionJobTitleFilter: FC<PositionFiltersProps> = ({ reloadPosition, filteredCount, context = new Context(), positionListModel = new PositionListModel() }) => {
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const useformed = useForm({ mode: 'all' });
  const { watch, reset } = useformed;
  const [currentSelectList, setCurrentSelectList] = useState<string[]>([]);

  useEffect(() => {
    if (positionListModel.isGlobalSearchedJob) {
      const list = positionListModel.searchFilters.jobTitles!.split(',');
      const resetList: any = [];
      context.jobGroupsWithTitles.forEach((jobGroup) => {
        jobGroup.jobTitles.forEach((job) => {
          const find = list.find((value) => value === String(job.code));
          if (find) resetList[job.code] = find;
          else resetList[job.code] = false;
        });
      });
      reset({ jobTitles: resetList });
      if (list[0] === '')setCurrentSelectList([]);
      else setCurrentSelectList(list);
      const tmpList: [] = watch('jobTitles');
      const filteredList = tmpList.filter((value) => value !== false);
      setCurrentSelectList(filteredList);
      positionListModel.positionJobSelectedList = filteredList;
      positionListModel.searchFilters.jobTitles = filteredList.join(',');
      positionListModel.isGlobalSearchedJob = false;
    }
  }, [positionListModel.isGlobalSearchedJob]);

  const onChangeCheckbox = async () => {
    const list: [] = watch('jobTitles');
    const filteredList = list.filter((value) => value !== false);
    setCurrentSelectList(filteredList);
    positionListModel.positionJobSelectedList = filteredList;
    positionListModel.searchFilters.jobTitles = filteredList.join(',');
    reloadPosition();
  };

  const onClickReset = () => {
    const resetList: any = [];
    context.jobGroupsWithTitles.forEach((jobGroup) => {
      jobGroup.jobTitles.forEach((job) => { resetList[job.code] = false; });
    });
    reset({ jobTitles: resetList });
    setCurrentSelectList([]);
    positionListModel.positionJobSelectedList = [];
    positionListModel.searchFilters.jobTitles = '';
    reloadPosition();
  };

  const onClickFilter = async () => {
    positionListModel.searchFilters.jobTitles = currentSelectList.join(',');
    reloadPosition();
    setIsSelectOpen(false);
  };
  useEffect(() => {
    if (isSelectOpen) {
      const list = positionListModel.searchFilters.jobTitles!.split(',');
      const resetList: any = [];
      context.jobGroupsWithTitles.forEach((jobGroup) => {
        jobGroup.jobTitles.forEach((job) => {
          const find = list.find((value) => value === String(job.code));
          if (find) resetList[job.code] = find;
          else resetList[job.code] = false;
        });
      });
      reset({ jobTitles: resetList });
      if (list[0] === '')setCurrentSelectList([]);
      else setCurrentSelectList(list);
    }
  }, [isSelectOpen]);

  return (
    <Frame>
      <StyledSmallSelector
        count={positionListModel.positionJobSelectedList.length}
        onClick={() => {
          setIsSelectOpen(true);
        }}
      >
        <span>직군·직무{positionListModel.positionJobSelectedList.length > 0 ? ` +${positionListModel.positionJobSelectedList.length}` : ''}</span>
        <StyledIconSVG fill={colors.CG_70}><IconArrowDown /></StyledIconSVG>
      </StyledSmallSelector>
      <JDUnderBaseModal isOpen={isSelectOpen} onClickClose={() => setIsSelectOpen(false)}>
        <FormProvider {...useformed}>
          <StyledSelectModalFrame>
            <div className='title'>필터</div>
            <div className='content-frame'>
              {
                context.jobGroupsWithTitles.map((jobGroup, groupId) => (
                  <div key={`${jobGroup.code}-${groupId}`} className='group-frame'>
                    <h1>{jobGroup.name}</h1>
                    <div className='group-items'>
                      {
                        jobGroup.jobTitles.map(({ code, name }) => (
                          <JDSquareCheckBox
                            key={`check-option-jobtitle-${code}`}
                            name={`jobTitles.${code}`}
                            value={String(code)}
                            label={<span>{name}</span>}
                            onChange={onChangeCheckbox}
                          />
                        ))
                      }
                    </div>
                  </div>
                ))
              }
            </div>
            <div className='btn-frame'>
              <button className='btn-reset' onClick={onClickReset}><IconReset />필터 초기화</button>
              <button
                className='btn-search'
                onClick={onClickFilter}
              >{`검색(${filteredCount()})건`}
              </button>
            </div>
          </StyledSelectModalFrame>
        </FormProvider>
      </JDUnderBaseModal>
    </Frame>
  );
};

export default inject(injectStore.context, injectStore.positionListModel)(observer(PositionJobTitleFilter));
