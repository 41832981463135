import { Button, ButtonMode } from '__designkit__/button/Button';
import Colors from '__designkit__/common/colors';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import JDBaseModal, { IJDBaseModal } from 'components/_v2/_common/modals/JDBaseModal';
import { JDModalDescription, JDModalTitle } from 'components/_v2/_common/modals/JDModalComponents';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import ImgFirst from 'components/_v2/roulette/rouletteGame/img_first_prize.png';
import ImgSecond from 'components/_v2/roulette/rouletteGame/img_second_prize.png';
import ImgThird from 'components/_v2/roulette/rouletteGame/img_third_prize.png';

const Frame = styled.div`
    min-width: 312px;
    max-width: 440px;
    width: 90vw;
    height: auto;
    background-color: ${Colors.WHITE_100};
    box-shadow: 0px 16px 25px 0px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    padding:40px;
    .title{
        font-size: 24px;
        font-weight: 700;
        line-height: 32px; /* 162.5% */

        color: ${Colors.JOBDA_BLACK};
        text-align: center;
        white-space: pre-line;
    }
    .subtitle{
      font-size: 16px;
      font-weight: 500;
      color: ${Colors.CG_70};
      line-height: 26px; /* 162.5% */

      text-align: center;
      white-space: pre-line;
    }
`;

interface ICongratsModal extends IJDBaseModal {
    dimmed?:boolean;
    isOpen:boolean;
    onClickClose:()=>void;
    isBackContentClickable?:boolean;
    isDimClickClose?:boolean;
    title?: string;
    descriptions?: string;
}
const CongratsModal:FC<ICongratsModal> = ({ descriptions, dimmed, isOpen, onClickClose, isBackContentClickable, title, isDimClickClose }) => {
  const [imgSrc, setImgSrc] = useState<string>('');
  useEffect(() => {
    if (title === '1') {
      setImgSrc(ImgFirst);
    } else if (title === '2') {
      setImgSrc(ImgSecond);
    } else {
      setImgSrc(ImgThird);
    }
  }, [title]);

  return (
    <JDBaseModal dimmed={dimmed} isOpen={isOpen} onClickClose={onClickClose} isBackContentClickable={isBackContentClickable} isDimClickClose={isDimClickClose}>
      <Frame>
        { title && <div className='title'>{`당첨을 축하드립니다!\n영업사원에게 공유해주세요.`}</div> }
        <SpacingBlock size={16} vertical />
        { descriptions && <div className='subtitle'>{descriptions}</div> }
        <SpacingBlock size={32} vertical />
        <img src={imgSrc} alt='상품권 이미지' />
        <SpacingBlock size={32} vertical />
        <Button label='확인' buttonMode={ButtonMode.PRIMARY} onClick={onClickClose} size='large' />
      </Frame>
    </JDBaseModal>
  );
};
export default CongratsModal;
