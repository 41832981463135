import { Button, ButtonMode } from '__designkit__/button/Button';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import graphLottie from 'assets/lottie/resume_load_lottie_yellow.json';
import JDDimModal from 'components/_v2/_common/modals/JDDimModal';
import { InsightResumeLogRq } from 'interfaces/_v2/insight/IInsightResumeRqRs';
import IComponentProps from 'interfaces/props/IComponentProps';
import Lottie from 'lottie-react';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import { useInsightResumeAutoSave, useInsightResumeLog } from 'query/resume/useResumeQuery';
import React, { FC } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import RenderCheckUtil from 'utils/RenderCheckUtil';
import IconWrite from 'assets/_v2/_common/icon_description_write_yellow.svg';
import TodayRenderCheckUtil from 'utils/TodayRenderCheckUtil';
import Context from 'models/Context';

const Frame = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: fit-content;
  padding: 32px 24px calc(24px + env(safe-area-inset-bottom));
  border-radius: 12px 12px 0 0;
  background-color: ${Colors.WHITE_100};
  z-index: 10005;
`;
const TitleFrame = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
    .header-text{
        font:${Fonts.H4_Bold};
        color:${Colors.JOBDA_BLACK};
        margin-bottom: 8px;
        white-space: pre-line;
        text-align: center;
    }
    .sub-text{
        font:${Fonts.B2_Medium_P};
        color:${Colors.CG_70};
        margin-bottom: 178px;
    }
    .lottie-write{
      width: 300px;
      height: 300px;
      position: absolute;
      bottom:34px;
    }
`;
const BtnFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  height: fit-content;
  padding-top: 24px;
  z-index:10006;
  position:sticky;
  & > div.ignore-for-week-btn {
    display: flex;
    align-items: center;
    gap: 4px;
    padding-bottom: 1px;
    border-bottom: 1px solid ${Colors.CG_80};
    font: ${Fonts.B3_Medium};
    color: ${Colors.CG_80};
  }
`;

interface IProfileResumeSavedModalProps extends IComponentProps {
  onClickClose: () => void;
  login?: Login;
  context?:Context
}

const ProfileResumeSavedModal: FC<IProfileResumeSavedModalProps> = ({ context = new Context(), onClickClose, login = new Login() }) => {
  // TODO: 인재풀 인원 수 받아오기
  const { data: insightResumeAutoSaveData } = useInsightResumeAutoSave(!!login.userInfo);

  const onClickBtn = async () => {
    await TodayRenderCheckUtil.setTodayRenderCheck('resume-check');
    context.isGlobalModalOpen = false;
    onClickClose();
  };

  return (
    <JDDimModal dimClickable onClickClose={onClickClose}>

      <Frame>
        {
        insightResumeAutoSaveData && (
          <>
            <TitleFrame>
              <div className='header-text'>{insightResumeAutoSaveData.companyName}에 제출했던 지원서가 프로필에 입력되었어요.</div>
              <div className='sub-text'>확인이 필요한 정보가 {insightResumeAutoSaveData.auditCount}개있어요</div>
              <Lottie animationData={graphLottie} className='lottie-write' />
            </TitleFrame>
            <BtnFrame>
              <Button className='btn_nudge_enter_insight_resume' label='내용 확인하기' buttonMode={ButtonMode.PRIMARY} size='large' onClick={onClickBtn} />
            </BtnFrame>
          </>
        )
             }
      </Frame>

    </JDDimModal>
  );
};

export default inject(injectStore.login, injectStore.context)(observer(ProfileResumeSavedModal));
