import ImgJoyPC from 'assets/_v2/character/img_joy_PC.svg';
import LazyImage from 'components/common/LazyImage';
import ErrorFrame, { ErrorCTAButtonACCA } from 'components/_v2/_common/error/ErrorFrame';
import RoutePaths from 'consts/RoutePaths';
import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

const ErrorFrameACCA = styled(ErrorFrame)`
   .frame-main {
    margin-top:100px;
    display: flex;
    align-items: center;
    .img {
      margin-bottom: 12px;
    }
  }
`;

const UnavailableMobileOnly = () => {
  const history = useHistory();
  return (
    <>
      <ErrorFrameACCA>
        <div className='frame-main'>
          <LazyImage className='img' src={ImgJoyPC} alt='joy' />
          <div className='desc'>
            PC에서 가능합니다.
          </div>

        </div>
        <ErrorCTAButtonACCA onClick={() => history.push(RoutePaths.root)}>
          잡다로 이동하기
        </ErrorCTAButtonACCA>
      </ErrorFrameACCA>
    </>
  );
};

export default UnavailableMobileOnly;
