import { Divider12G } from 'components/divider/Divider';
import JDSwiper, { JDSwiperSlide } from 'components/_v2/_common/JDSwiper';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { AccCenterPositionType, AccCenterPositionTypeText, AccResultType, AccResultTypeText } from 'interfaces/_v2/acc/IAccGate';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import AccaGateModel from 'models/_v2/acca/AccaGateModel';
import { IAccaGateProps } from 'pages/_v2/acca/AccaGate';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import Icon from '__designkit__/icon/Icon';
import PositionCard from '__designkit__/card/PositionCard';
import { Banner } from './AccaGateRecruit';

const Frame = styled.div`
  background: ${colors.WHITE_100};

  .title-area {
    padding: 40px 16px 0 16px;

    > h1 {
      font: ${Fonts.H4_Bold};
      color: ${colors.JOBDA_BLACK};
      margin-bottom: 8px;
    }

    .custom-position-info {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      > h2 {
        font: ${Fonts.B2_Medium};
        color: ${colors.CG_60};
      }

      > button {
        display: flex;
        font: ${Fonts.B3_Medium};
        color: ${colors.CG_70};
      }
    }
  }

  .custom-position-swiper {
    padding: 0 16px 40px 16px;
  }

  .custom-position-card {
    width: 158px;

    .card {
      width: 158px;
      height: 118px;
    }
  }
`;

const ResultNoneFrame = styled.div`
  margin-top: 20px;
  width: 100%;
  height: 152px;
  background: ${colors.CG_30};
  border: 1px solid ${colors.CG_40};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > h1 {
    font: ${Fonts.B2_Bold};
    color: ${colors.CG_60};
  }

  > button {
    width: 115px;
    height: 38px;
    padding: 10px 20px;
    background: ${colors.JOBDA_BLACK};
    border-radius: 4px;
    color: ${colors.WHITE_100};
    font: ${Fonts.B2_Bold};
  }
`;

const AccaGateCustomPosition: FC<IAccaGateProps> = ({ accaGateModel = new AccaGateModel() }) => {
  const [currntPositionType, setCurrntPositionType] = React.useState<AccCenterPositionType>(AccCenterPositionType.ACC_RECOMMEND_JOB_GROUP);

  const loadPostion = async (positionType = AccCenterPositionType.ACC_RECOMMEND_JOB_GROUP) => {
    await accaGateModel.loadAccCenterPositionRecommend(positionType);
  };

  useEffect(() => {
    const init = async () => {
      loadPostion();
      await accaGateModel.loadAccResult();
    };
    init();
  }, []);

  return (
    <>
      <Frame>
        { Banner() }
        <div className='title-area'>
          <h1>역량검사 결과 맞춤형 포지션 추천</h1>
          <div className='custom-position-info'>
            {accaGateModel.recommend?.positions && accaGateModel.recommend?.positions.length > 0 ? <h2>{currntPositionType === AccCenterPositionType.ACC_RESULT_TYPE ? `${AccResultTypeText[accaGateModel.accResult!.profileResultType]} 유형이 많이 본 포지션이에요.` : AccCenterPositionTypeText[currntPositionType]}</h2> : <div />}
            <button
              onClick={async () => {
                await loadPostion(currntPositionType === AccCenterPositionType.ACC_RECOMMEND_JOB_GROUP ? AccCenterPositionType.ACC_RESULT_TYPE : AccCenterPositionType.ACC_RECOMMEND_JOB_GROUP);
                setCurrntPositionType(currntPositionType === AccCenterPositionType.ACC_RECOMMEND_JOB_GROUP ? AccCenterPositionType.ACC_RESULT_TYPE : AccCenterPositionType.ACC_RECOMMEND_JOB_GROUP);
              }}
            >
              <Icon name='reset' size={16} /> 새로고침
            </button>
          </div>
        </div>
        {accaGateModel.recommend?.positions && accaGateModel.recommend?.positions.length > 0 ? (
          <JDSwiper className='custom-position-swiper' slidesPerView='auto' spaceBetween={12}>
            {accaGateModel.recommend.positions.map((item, index) => (
              <JDSwiperSlide key={item.sn} className='custom-position-card'>
                <PositionCard
                  item={{
                    positionSn: item.sn,
                    positionName: item.title,
                    recruitmentType: item.recruitmentType,
                    closingDateTime: item.closingDateTime,
                    locationCode: item.locationCode,
                    companyName: item.companyName,
                    logoImageUrl: item.logoImageUrl,
                    teamImageUrl: item.teamImageUrl,
                    jobTitleNames: [item.jobTitleName],
                    bookmarkYn: item.bookmarkYn,
                    matchingYn: item.matchingYn,
                    minSalary: item.minSalary,
                    maxSalary: item.maxSalary,
                    jobPosting: item.jobPosting,
                  }}
                />
              </JDSwiperSlide>
            ))}
          </JDSwiper>
        ) : (
          <ResultNoneFrame>
            <h1>역량검사 결과를 기반으로 포지션을 추천해드려요</h1>
          </ResultNoneFrame>
        )}
      </Frame>
    </>
  );
};

export default inject(injectStore.accaGateModel)(observer(AccaGateCustomPosition));
