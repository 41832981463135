import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import { NHIS_PROVIDER } from 'consts/_v2/profile/nhis';
import { IPostAuthRequestRq } from 'interfaces/_v2/profile/INhisRqRs';
import React, { FC } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  & .title {
    width: 100%;
    height: fit-content;

    & .main {
      font: ${Fonts.H4_Bold};
      color: ${Colors.CG_90};
    }
  }

  & .description-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    height: fit-content;
    
    & .description-item {
      display: flex;
      gap: 12px;
      width: 100%;
      height: fit-content;
      padding: 16px 24px;
      border-radius: 8px;
      background-color: ${Colors.CG_30};
      white-space: pre-wrap;
      
      & > span, div {
        font: ${Fonts.B1_Medium_P};
        color: ${Colors.JOBDA_BLACK};
      }
    }
  }

  & .faq-frame {
    display: flex;
    align-items: center;
    gap: 4px;
    width: 100%;
    height: fit-content;
    font: ${Fonts.B3_Medium};
    color: ${Colors.CG_70};

    & a {
      color: ${Colors.B_100};
      text-decoration: underline;
    }
  }
`;

interface IVerificationWaitProps {
  tempData?: IPostAuthRequestRq;
}

const VerificationWait: FC<IVerificationWaitProps> = ({ tempData }) => (
  <Frame>
    <div className='title'>
      <div className='main'>
        인증 요청을 보냈어요.<br />
        남은 인증 절차를 진행해 주세요!
      </div>
      <SpacingBlock size={32} vertical />
      <div className='description-list'>
        <div className='description-item'>
          <span>1. </span>
          <div className='text'>{ tempData?.provider === NHIS_PROVIDER.KAKAO ? '카카오' : '네이버'} 앱에서 인증 요청 메시지를 확인해 주세요.</div>
        </div>
        <div className='description-item'>
          <span>2. </span>
          <div className='text'>인증 완료 후, 하단의 ‘인증 완료’ 버튼을 눌러주세요.</div>
        </div>
      </div>
      <SpacingBlock size={18} vertical />
      <div className='faq-frame'>
        <Icon name='information' size={20} color={Colors.CG_70} />
        <div>
          인증서 문제는&nbsp;
          { tempData?.provider === NHIS_PROVIDER.KAKAO
            ? <a href='https://cs.kakao.com/helps?articleId=1073198855&service=179&category=660&device=2520&locale=ko' rel='noreferrer' target='_blank'>카카오 고객 센터</a>
            : <a href='https://help.naver.com/service/22027/category/bookmark?lang=ko' rel='noreferrer' target='_blank'>네이버 고객 센터</a>}를 통해 빠르게 문제를 해결할 수 있어요.
        </div>
      </div>
    </div>

  </Frame>
);

export default VerificationWait;
