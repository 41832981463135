import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import styled from 'styled-components';
import { JDMainButton } from 'consts/_v2/_common/style/mixins';

const JDModalBasicFrame = styled.div`
  width:312px;
  display: flex;
  flex-direction: column;
  background: ${colors.WHITE_100};
  border-radius: 12px;
  padding: 40px 24px 24px;
`;

const JDModalTitle = styled.div`
    font: ${Fonts.H5_Bold};
    color: ${colors.JOBDA_BLACK};
    text-align: center;
    white-space: pre-line;
`;

const JDModalDescription = styled.div`
    font: ${Fonts.B2_Medium_P};
    color: ${colors.CG_70};
    text-align: center;
    white-space: pre-line;
`;

const JDModalTipBlue = styled.div`
    font: ${Fonts.B3_Medium};
    color: ${colors.B_100};
    text-align: center;
`;

const JDModalTipRed = styled.div`
    font: ${Fonts.B3_Medium};
    color: ${colors.ERROR};
    text-align: center;
`;

const JDButtonTwoChoiceFrame = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const JDModalButton = styled.button`
  ${JDMainButton(false)};
  height:48px;
  flex:1;
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  border-radius:4px;

  
`;

const JDModalButtonGhost = styled(JDModalButton)`
  ${JDMainButton(true)};
`;

const JDModalFullButton = styled.button`
    width: 100%;
    padding: 12px 16px;
    margin-top: 24px;
    border-radius: 4px;
    font: ${Fonts.B1_Bold};
    color: ${colors.CG_70};
    text-align: center;
    color:${colors.WHITE_100};
    background: ${colors.JOBDA_BLACK};
`;

export { JDModalBasicFrame, JDModalTitle, JDModalDescription, JDModalTipBlue, JDModalTipRed, JDButtonTwoChoiceFrame, JDModalButton, JDModalButtonGhost, JDModalFullButton };
