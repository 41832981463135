import { action, observable } from 'mobx';
import request from 'utils/request';
import URIs from 'consts/URIs';
import { TermType, TERM_TYPE } from 'consts/TermType';
import { PhsAccResultType } from 'consts/MatchingMessageType';
import ITermsDto from 'interfaces/terms/ITermsListRs';
import IAgreeInformationRq from 'interfaces/terms/IAgreeInformationRq';
import { ITermsListGetRs, ITermsGetDto } from 'interfaces/rqrs/ITermsListGetRs';
import IProfileSensitiveInformationRq from 'interfaces/terms/IProfileSensitiveInformationRq';
import { IProfileSensitiveAgreeCheckRs } from 'interfaces/terms/IProfileSensitiveAgreeCheckRs';
import { IAgreeInformationRs, IPositionAgreeCheckRs } from 'interfaces/terms/IAgreeInformationRs';
import Term from 'interfaces/_v2/_common/term';

export default class TermsModel {
    @observable termsList: ITermsDto[] = [];
    @observable privacyLists: ITermsGetDto[] = [];
    @observable privacyPolicy: ITermsGetDto[] = [];
    @observable privacyProvision: ITermsGetDto[] = [];
    @observable resultPrivacyProvision:ITermsGetDto|null = null;
    @observable termsAgreeInformation: IAgreeInformationRs | null = null;
    @observable termsPositionAgreeCheck: IPositionAgreeCheckRs | null = null;
    @observable termsSensitiveYn: IProfileSensitiveAgreeCheckRs | null = null;
    @observable termDetail: Term | null = null;

    get termSns() {
      return this.termsList.map(({ sn }) => sn).sort();
    }

    get termPositionSns() {
      return this.privacyLists.map(({ sn }) => sn).sort();
    }

    @action
    fileTermInit = () => {
      this.termsList = [];
      this.privacyPolicy = [];
      this.privacyProvision = [];
      this.privacyLists = [];
      this.termsPositionAgreeCheck = null;
    }

    @action
    sensitiveTermInit = () => {
      this.termsList = [];
      this.privacyPolicy = [];
      this.privacyProvision = [];
      this.privacyLists = [];
      this.termsSensitiveYn = null;
    }

    @action
    matchTermInit = () => {
      this.termsList = [];
      this.privacyPolicy = [];
      this.privacyProvision = [];
      this.privacyLists = [];
      this.termsAgreeInformation = null;
    }

    @action
    loadTermsResultPrivacyProvision = async (companySn:number, termsType:PhsAccResultType) => {
      try {
        this.resultPrivacyProvision = (await request<ITermsGetDto>({
          method: 'get',
          url: URIs.get_terms_result_privacy_provision,
          params: { companySn, termsType },
        }));
      } catch (e) {
        console.error(e);
      }
    }

    @action
    saveTermsResultPrivacyProvision = async (companySn:number, termsSn:number) => {
      try {
        const res = (await request({
          method: 'post',
          url: URIs.post_terms_result_privacy_provision,
          data: { companySn, termsSn },
        }));
        return res;
      } catch (e) {
        console.error(e);
      }
    }

    // 매칭 제안 받는 동의문 리스트
    @action
    loadMatchTermsList = async () => {
      try {
        this.termsList = (await request<{terms: ITermsDto[]}>({
          method: 'get',
          url: URIs.get_match_terms_information,
        })).terms;
      } catch (e) {
        console.error(e);
      }
    }

    @action
    saveMatchTermsAgreeMatchApply = async (termsSns: IAgreeInformationRq) => {
      try {
        const res = (await request({
          method: 'post',
          url: URIs.post_user_terms_agree_matching_agree,
          data: termsSns,
        }));
        return res;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }

    @action
    loadMatchTermsAgree = async () => {
      try {
        this.termsAgreeInformation = (await request({
          method: 'get',
          url: URIs.get_user_terms_agree_matching_agree,
        }));
      } catch (e) {
        console.error(e);
      }
    }

    @action
    saveFileTermsAgree = async (termsSns: IAgreeInformationRq) => {
      try {
        const res = (await request({
          method: 'post',
          url: URIs.post_user_terms_agree_position_file_agree,
          data: termsSns,
        }));
        return res;
      } catch (e) {
        console.error(e);
      }
    }

    @action
    saveSensitiveTermsAgree = async (termsSns: IProfileSensitiveInformationRq) => {
      try {
        const res = (await request({
          method: 'post',
          url: URIs.post_user_terms_agree_matching_sensitive_agree,
          data: termsSns,
        }));
        return res;
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadFileTermsAgree = async () => {
      try {
        this.termsPositionAgreeCheck = (await request({
          method: 'get',
          url: URIs.get_user_terms_agree_position_file_agree,
        }));
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadTermsList = async (termType: TermType): Promise<ITermsListGetRs> => request<ITermsListGetRs>({
      method: 'get',
      url: URIs.get_terms,
      params: { termType },
    });

    @action
    loadTermsPrivacyPolicy = async () => {
      try {
        this.privacyPolicy = (await this.loadTermsList(TERM_TYPE.POSITION_PRIVACY_POLICY)).terms;
      } catch (e) {
        this.privacyPolicy = [];
      }
    }

    @action
    loadTermsPrivacyProvision = async () => {
      try {
        this.privacyProvision = (await this.loadTermsList(TERM_TYPE.POSITION_PRIVACY_PROVISION)).terms;
      } catch (e) {
        this.privacyProvision = [];
      }
    }

    @action
    loadSensitiveTermsAgree = async () => {
      try {
        this.termsSensitiveYn = (await request({
          method: 'get',
          url: URIs.get_user_terms_agree_matching_sensitive_agree,
        }));
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadTermsSensitivePolicy = async () => {
      try {
        this.privacyPolicy = (await this.loadTermsList(TERM_TYPE.SENSITIVE_PRIVACY_POLICY)).terms;
      } catch (e) {
        this.privacyPolicy = [];
      }
    }

    // 민감정보 제거로 인한 주석처리
    // @action
    // loadTermsSensitiveyProvision = async () => {
    //   try {
    //     this.privacyProvision = (await this.loadTermsList(TERM_TYPE.SENSITIVE_PRIVACY_PROVISION)).terms;
    //   } catch (e) {
    //     this.privacyProvision = [];
    //   }
    // }

    @action setTermsList = (termsList: ITermsDto[]) => {
      this.termsList = termsList;
    }

    @action setTermDetail = (term: Term | null) => {
      this.termDetail = term;
    }

    // 약관에 동의했는지 확인하는 함수
    getIsAgreeTerms = async () => {
      this.matchTermInit();
      await this.loadMatchTermsAgree();
      if (this.termsAgreeInformation!.privacyPolicyYn === false) {
        await this.loadMatchTermsList();
        return false;
      }
      return true;
    }
}
