/* eslint-disable class-methods-use-this */
import URIs from 'consts/URIs';
import { IBookmarkStatusRs, ICompanyBookmarkRs, IJobPostBookmarkRs, IPositionBookmarkRs } from 'interfaces/_v2/IBookmarkRs';
import { request } from 'utils/request';

export default interface BookmarkRepo{
    // 북마크 > 기업 목록 조회
    fetchBookmarkCompanies():Promise<ICompanyBookmarkRs>;

    // 북마크 > 공고 목록 조회
    fetchBookmarkJobPostings():Promise<IJobPostBookmarkRs>

    // 북마크 > 포지션 목록 조회
    fetchBookmarkPositions():Promise<IPositionBookmarkRs>;

    // 북마크 > 상태 조회
    fetchBookmarkStatus():Promise<IBookmarkStatusRs>;
}
export class RemoteBookmarkRepo implements BookmarkRepo {
  fetchBookmarkCompanies(): Promise<ICompanyBookmarkRs> {
    return request<ICompanyBookmarkRs>({
      method: 'get',
      url: URIs.get_bookmark_companies,
    });
  }

  fetchBookmarkJobPostings(): Promise<IJobPostBookmarkRs> {
    return request<IJobPostBookmarkRs>({
      method: 'get',
      url: URIs.get_bookmark_job_postings,
    });
  }

  fetchBookmarkPositions(): Promise<IPositionBookmarkRs> {
    return request<IPositionBookmarkRs>({
      method: 'get',
      url: URIs.get_bookmark_positions,
    });
  }

  fetchBookmarkStatus(): Promise<IBookmarkStatusRs> {
    return request<IBookmarkStatusRs>({
      method: 'get',
      url: URIs.get_bookmark_status,
    });
  }
}
