import React, { useState, useEffect, useRef } from 'react';
import contentsImg1 from '__pc__/page/accaIntroduce/@assets/ac1.png';
import contentsImg2 from '__pc__/page/accaIntroduce/@assets/ac2.png';
import contentsImg3 from '__pc__/page/accaIntroduce/@assets/ac3.png';
import classnames from 'classnames/bind';
import styles from './previewStep.module.scss';
const cx = classnames.bind(styles);

type Tab = {
  label: string;
  video: string;
};

interface PreviewStepProps {
  fadeIn: boolean;
}

const tabsData: Tab[] = [
  { label: '성향파악', video: contentsImg1 },
  { label: '전략게임', video: contentsImg2 },
  { label: '영상면접', video: contentsImg3 },
];

const PreviewStep = ({ fadeIn }: PreviewStepProps) => {
  const [activeTab, setActiveTab] = useState(0);
  const videoRef = useRef<HTMLVideoElement>(null);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, [activeTab]);

  return (
    <div className={cx('sectionInner', 'grayBg', { on: fadeIn })}>
      <p className={cx('mainTitle')}>
        <span className={cx('mainSmall')}>
          JOBDA 역량검사의 과학적인 눈으로
        </span>
        <br />
        나의 ‘진짜’ 역량을
        <br />
        발견해 보세요.
      </p>
      <p className={cx('title')}>
        역량검사<em className={cx('smallTitle')}>(역량검사)</em>
      </p>
      <span className={cx('subTitle')}>
        생물학, 신경과학/ 데이터 사이언스 기반으로
        <br />
        성과를 예측하는 나만의 고유한 잠재역량을
        <br />
        파악하는 검사입니다.
      </span>
      <div className={cx('textInner')}>
        <span className={cx('text')}>지금껏 알기 어려웠던 나의 진짜 역량</span>
        <span className={cx('text')}>JOBDA 역량검사로 확인해 보세요.</span>
      </div>
      <div className={cx('previewInner')}>
        <div className={cx('Tabs')}>
          {tabsData.map((tab, index) => (
            <div
              key={index}
              className={cx('Tab', { active: activeTab === index })}
              onClick={() => handleTabClick(index)}
            >
              {tab.label}
            </div>
          ))}
        </div>
        <div className={cx('tabContent')}>
          <img className={cx('video')} src={tabsData[activeTab].video} alt='' />
        </div>
      </div>
    </div>
  );
};

export default PreviewStep;
