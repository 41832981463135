export interface CompanyListData {
  logoType: string;
  companyName: string;
  job: string;
}

export const companyListData: CompanyListData[] = [
  {
    logoType: 'logo1',
    companyName: '스푼라디오',
    job: '백엔드 개발자',
  },
  {
    logoType: 'logo2',
    companyName: '마이다스아이티',
    job: '프론트엔드 개발자 채용',
  },
  {
    logoType: 'logo2',
    companyName: '마이다스아이티',
    job: '백엔드 개발자 채용',
  },
  {
    logoType: 'logo3',
    companyName: '마이리얼트립',
    job: 'Data Engineer',
  },
  {
    logoType: 'logo3',
    companyName: '마이리얼트립',
    job: 'Frontend 개발자',
  },
  {
    logoType: 'logo4',
    companyName: '트래블월렛',
    job: '개발 담당자 포지션',
  },
  {
    logoType: 'logo5',
    companyName: '멀티캠퍼스',
    job: '프론트엔드 개발',
  },
  {
    logoType: 'logo5',
    companyName: '멀티캠퍼스',
    job: 'Application Achitect',
  },
  {
    logoType: 'logo5',
    companyName: '멀티캠퍼스',
    job: '백엔드 개발',
  },
  {
    logoType: 'logo5',
    companyName: '멀티캠퍼스',
    job: 'DevOps 및 SW Achitecture 운영관리',
  },
  {
    logoType: 'logo5',
    companyName: '멀티캠퍼스',
    job: 'SW achitect',
  },
  {
    logoType: 'logo5',
    companyName: '멀티캠퍼스',
    job: '모바일 웹 개발',
  },
  {
    logoType: 'logo5',
    companyName: '멀티캠퍼스',
    job: 'Technical Achitect',
  },
  {
    logoType: 'logo5',
    companyName: '멀티캠퍼스',
    job: 'IT시스템개발 분석설계자',
  },
  {
    logoType: 'logo5',
    companyName: '멀티캠퍼스',
    job: 'Network Achitect',
  },
  {
    logoType: 'logo6',
    companyName: '핀다',
    job: '백엔드 개발자',
  },
  {
    logoType: 'logo6',
    companyName: '핀다',
    job: '웹프론트엔드 개발자',
  },
  {
    logoType: 'logo6',
    companyName: '핀다',
    job: 'DBA',
  },
  {
    logoType: 'logo6',
    companyName: '핀다',
    job: '오픈업 데이터 엔지니어',
  },
  {
    logoType: 'logo7',
    companyName: '데상트코리아',
    job: '이커머스 시스템 개발 및 운영 담당',
  },
  {
    logoType: 'logo8',
    companyName: '위드네트웍스',
    job: '백엔드 개발자 채용(보안솔루션 개발)',
  },
  {
    logoType: 'logo9',
    companyName: '마이페어',
    job: '개발 담당자 포지션',
  },
  {
    logoType: 'logo10',
    companyName: '콘텐츠퍼스트(태피툰)',
    job: '앱/프론트엔드 개발자 채용',
  },
  {
    logoType: 'logo11',
    companyName: '솔로몬텍',
    job: 'Salesforce Developer 채용',
  },
  {
    logoType: 'logo11',
    companyName: '솔로몬텍',
    job: 'Mendix Developer 채용',
  },
  {
    logoType: 'logo12',
    companyName: '코웨이',
    job: '백엔드 개발자 채용',
  },
  {
    logoType: 'logo12',
    companyName: '코웨이',
    job: '프론트엔드 개발자 채용',
  },
  {
    logoType: 'logo13',
    companyName: 'CJ올리브네트웍스',
    job: 'NLP/NLU 연구 개발',
  },
  {
    logoType: 'logo13',
    companyName: 'CJ올리브네트웍스',
    job: 'WCS 시스템 운영/개발',
  },
  {
    logoType: 'logo13',
    companyName: 'CJ올리브네트웍스',
    job: 'SI 프로젝트 PL',
  },
  {
    logoType: 'logo13',
    companyName: 'CJ올리브네트웍스',
    job: 'NetworkEngineer',
  },
  {
    logoType: 'logo13',
    companyName: 'CJ올리브네트웍스',
    job: 'Public Cloud Architect',
  },
  {
    logoType: 'logo13',
    companyName: 'CJ올리브네트웍스',
    job: '데이터/AI 플랫폼 백엔드 개발자',
  },
  {
    logoType: 'logo13',
    companyName: 'CJ올리브네트웍스',
    job: 'Computer Vision AI Engineer',
  },
  {
    logoType: 'logo13',
    companyName: 'CJ올리브네트웍스',
    job: '프레시웨이 웹시스템 운영/개발',
  },
  {
    logoType: 'logo14',
    companyName: '에스앤아이코퍼레이션',
    job: '프론트엔드 개발 직무',
  },
  {
    logoType: 'logo15',
    companyName: '티맥스소프트',
    job: 'AI연구파트 연구원',
  },
  {
    logoType: 'logo15',
    companyName: '티맥스소프트',
    job: '해외사업부문 SaaS QA',
  },
  {
    logoType: 'logo15',
    companyName: '티맥스소프트',
    job: '해외 OpenFrame 연구/개발',
  },
  {
    logoType: 'logo15',
    companyName: '티맥스소프트',
    job: '해외사업 컴파일러 연구/개발',
  },
  {
    logoType: 'logo15',
    companyName: '티맥스소프트',
    job: '금융부문 프론트엔드 파트장',
  },
  {
    logoType: 'logo15',
    companyName: '티맥스소프트',
    job: '금융부문 F/W 엔진 연구/개발',
  },
  {
    logoType: 'logo15',
    companyName: '티맥스소프트',
    job: '공공부문 APIM 연구/개발',
  },
  {
    logoType: 'logo15',
    companyName: '티맥스소프트',
    job: '공공부문 SaaS QA',
  },
  {
    logoType: 'logo15',
    companyName: '티맥스소프트',
    job: '공공부문 기술지원 엔지니어',
  },
  {
    logoType: 'logo15',
    companyName: '티맥스소프트',
    job: '기업부문 SaaS QA',
  },
  {
    logoType: 'logo15',
    companyName: '티맥스소프트',
    job: '기업부문 SaaS 연구/개발',
  },
];
