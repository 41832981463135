import FindPasswordChange from 'components/findAccount/FindPasswordChange';
import FindPasswordForm from 'components/findAccount/FindPasswordForm';
import RoutePaths from 'consts/RoutePaths';
import useGoBack from 'hooks/useGoBack';
import { inject, observer } from 'mobx-react';
import FindAccountModel, { FIND_PASSWORD_STEP } from 'models/FindAccountModel';
import { injectStore } from 'models/store';
import { IFindAccountProps } from 'pages/FindAccount';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { passwordValidator } from 'consts/ValidationRule';
import { yupResolver } from '@hookform/resolvers/yup';
import FindPasswordGate from 'components/findAccount/FindPasswordGate';
import { FindAccountFrame } from 'components/findAccount/FindId';
import Login from 'models/Login';

interface IPasswordChangeFormValues {
  password:string;
  reNewPassword:string;
}

const FindPassword: React.FC<IFindAccountProps> = ({ login = new Login(), findAccountModel = new FindAccountModel() }) => {
  const findSchema = yup.object(
    {
      password: yup.string().test({
        name: 'validation',
        test: (value: any, { createError }) => {
          const customError = passwordValidator(value);

          if (customError === true) return true;
          return createError({ message: customError.toString() });
        },
      }),
      rePassword: yup.string().test({
        name: 'rePass',
        test: (value: any, { createError }) => {
          const customError = passwordValidator(value);

          if (customError === true) return true;
          return createError({ message: customError.toString() });
        },
      })
        .test('equalPassword', '비밀번호가 일치하지 않습니다.', ((value:string, record:any) => (value === (record.parent as IPasswordChangeFormValues).password)) as any),
    },
  );

  const useFormed = useForm({
    mode: 'onChange',
    resolver: yupResolver(findSchema),
    defaultValues: {
      id: '',
      name: '',
      birthDate: '',
      genderFlag: '',
      countryCode: '82',
      mobile: '',
      token: '',
      password: '',
      rePassword: '',
    },
  });

  const history = useHistory();

  const handleClose = () => {
    history.push(RoutePaths.login);
  };
  useGoBack(handleClose);

  useEffect(() => {
    if (login?.userInfo) history.replace(RoutePaths.root);
  }, []);

  return (
    <FindAccountFrame>
      <FormProvider {...useFormed}>
        <FindPasswordGate className={findAccountModel.currentFindPasswordStep === FIND_PASSWORD_STEP.GATE ? '' : 'none'} />
        <FindPasswordForm className={findAccountModel.currentFindPasswordStep === FIND_PASSWORD_STEP.FORM ? '' : 'none'} />
        <FindPasswordChange className={findAccountModel.currentFindPasswordStep === FIND_PASSWORD_STEP.CHANGE ? '' : 'none'} />
      </FormProvider>
    </FindAccountFrame>
  );
};

export default inject(injectStore.login, injectStore.findAccountModel)(observer(FindPassword));
