import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import JDDimModal from 'components/_v2/_common/modals/JDDimModal';
import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components';

const bottomModalAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(calc(-100%));
  }
`;

const Frame = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  height: fit-content;
  padding: 8px 16px calc(env(safe-area-inset-bottom) + 16px);
  border-radius: 8px 8px 0 0;
  background-color: ${Colors.WHITE_100};
  animation: ${bottomModalAnimation} 0.3s 0s 1 ease-out forwards;
  z-index: 10005;

  &::before {
    position: absolute;
    top: -8px;
    left: 50%;
    width: 32px;
    height: 4px;
    border-radius: 2px;
    background-color: ${Colors.CG_50};
    transform: translateX(-50%);
    content: '';
  }

  & .title {
    width: 100%;
    height: fit-content;
    padding: 16px 0;
    border-bottom: 1px solid ${Colors.CG_40};
    font: ${Fonts.B1_Bold};
    color: ${Colors.JOBDA_BLACK};
    text-align: center;
    user-select: none;
  }
  
  & .btn-frame {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    height: fit-content;
  }

  & .btn-frame button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
    height: fit-content;
    padding: 16px 0;
    border: 1px solid ${Colors.JOBDA_BLACK};
    border-radius: 4px;
    background-color: ${Colors.WHITE_100};
    font: ${Fonts.B2_Bold};
    color: ${Colors.BLACK_100};
    user-select: none;

    &:active {
      background-color: ${Colors.CG_30};
    }

    &.invert {
      background-color: ${Colors.JOBDA_BLACK};
      color: ${Colors.WHITE_100};

      &:active {
        background-color: ${Colors.CG_70};
      }
    }
  }
`;

interface CareerEntryOptionsModalProps {
  onClickClose: () => void;
  option1: () => void;
  option2: () => void;
}

const CareerEntryOptionsModal: FC<CareerEntryOptionsModalProps> = ({ onClickClose, option1, option2 }) => {
  const onClickOption = (option: () => void) => {
    option();
    onClickClose();
  };

  return (
    <JDDimModal onClickClose={onClickClose} dimClickable>
      <Frame onContextMenu={(e) => e.preventDefault()}>
        <div className='title'>경력 추가 방법을 선택해 주세요</div>
        <div className='btn-frame'>
          <button onClick={() => onClickOption(option1)}>
            <Icon name='add' size={24} color={Colors.JOBDA_BLACK} />
            직업 추가하기
          </button>
          <button className='invert' onClick={() => onClickOption(option2)}>
            <Icon name='verified-filled' size={24} color={Colors.G_150} />
            경력 불러오기
          </button>
        </div>
      </Frame>
    </JDDimModal>
  );
};

export default CareerEntryOptionsModal;
