import PositionJobTitleFilter from 'components/_v2/position/filter/PositionJobTitleFilter';
import PositionLocationFilter from 'components/_v2/position/filter/PositionLocationFilter';
import PositionMatchFilter from 'components/_v2/position/filter/PositionMatchFilter';
import PositionRecruitmentsFilter from 'components/_v2/position/filter/PositionRecruitmentsFilter';
import PositionSalaryFilter from 'components/_v2/position/filter/PositionSalaryFilter';
import { IPositionList } from 'components/_v2/position/PositionList';
import JDSelector, { JDSelectorType } from 'components/_v2/_common/input/JDSelector';
import { ScrollHiddenMixin, JDSmallSeletor } from 'consts/_v2/_common/style/mixins';
import { PositionOrderType, PositionOrderTypeStr } from 'consts/_v2/position/PositionType';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import PositionListModel from 'models/_v2/PositionListModel';
import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import colors from '__designkit__/common/colors';
import { ReactComponent as IconReset } from 'assets/_v2/_common/input/icon_reset.svg';
import Context from 'models/Context';
import PositionCurationModel, { CurationDefaultType } from 'models/_v2/PositionCurationModel';

const PositionFilterTabFrame = styled.div`
  position: sticky;
  top: ${(props) => (!(props as any)['data-hide'] ? '55' : '0')}px;
  z-index: 1000;
  background: ${colors.WHITE_100};
  width: 100%;
  padding: 16px 0px 16px 0px;
  overflow: hidden;
  overflow-x: scroll;
  ${ScrollHiddenMixin()}
  >div{
    padding: 0px 16px 0px 16px;
    width: fit-content;
    display: inline-flex;
    position: relative;
    height: 30px;
    
    >*{
      /* display: inline-block; */
      padding-right:8px;
    }
}
`;

const StyledReset = styled.div`
  ${JDSmallSeletor()}
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.JOBDA_BLACK};
  color:${colors.JOBDA_BLACK};
  padding:8px 18px 8px 28px;
  svg {
    left: 10px;
    top:14px;
  }
  margin-right:8px;

`;

export interface PositionFiltersProps extends IPositionList {
  reloadPosition: () => void;
  filteredCount: () => number;
}

const PostionFilterTab: FC<IPositionList> = ({ positionListModel = new PositionListModel(), positionCurationModel = new PositionCurationModel(), context = new Context() }) => {
  const useFormed = useForm();
  const { watch } = useFormed;
  const curationYn = positionCurationModel.curationSelect !== CurationDefaultType.ALL;

  const reloadPosition = async () => {
    if (!curationYn) await positionListModel.loadMatchPositions();
    else await positionCurationModel.loadCurationsPositions(positionCurationModel.currentCurationSn, positionListModel.searchFilters);
  };

  const filteredCount = () => {
    if (!curationYn) return positionListModel.searchPositions?.pages.totalElements || 0;
    return positionCurationModel.searchPositions?.pages?.totalElements || 0;
  };

  const renderResetButton = () => (
    <>
      {
      (positionListModel.searchFilters.jobTitles !== ''
      || positionListModel.searchFilters.recruitments !== ''
      || positionListModel.searchFilters.minSalary !== undefined
      || positionListModel.searchFilters.maxSalary !== undefined
      || positionListModel.searchFilters.locations !== ''
      || positionListModel.searchFilters.matchingYn !== false
      || positionListModel.searchFilters.orderType !== PositionOrderType.POPULAR)
      && (
        <StyledReset
          onClick={async () => {
            positionListModel.resetFilter();
            if (!curationYn) await positionListModel.loadMatchPositions();
            else {
              positionListModel.searchFilters.orderType = PositionOrderType.POPULAR;
              await positionCurationModel.loadCurationsPositions(positionCurationModel.currentCurationSn, positionListModel.searchFilters);
            }
          }}
        >
          <IconReset width={12} height={12} />
          <span>필터 초기화</span>
        </StyledReset>
      )
    }
    </>
  );

  return (
    <PositionFilterTabFrame data-hide={context.hideHeader}>
      <FormProvider {...useFormed}>
        <div>
          <JDSelector
            selectTitle='정렬'
            name='orderType'
            type={JDSelectorType.ORDER}
            onChange={async () => {
              const value = watch('orderType');
              if (!value) return;
              positionListModel.searchFilters.orderType = value as PositionOrderType;
              reloadPosition();
            }}
            value={positionListModel.searchFilters.orderType}
            defaultValue={positionListModel.searchFilters.orderType}
          >
            <select defaultValue={positionListModel.searchFilters.orderType}>
              {
                (Object.keys(PositionOrderType) as Array<keyof typeof PositionOrderType>).map((key) => {
                  if (!curationYn && key === PositionOrderType.NONE) return <></>;
                  return <option key={`${key}-idx`} value={key}>{PositionOrderTypeStr[key]}</option>;
                })
              }
            </select>
          </JDSelector>
          {renderResetButton()}
          <PositionJobTitleFilter reloadPosition={reloadPosition} filteredCount={filteredCount} />
          <PositionRecruitmentsFilter reloadPosition={reloadPosition} filteredCount={filteredCount} />
          <PositionSalaryFilter reloadPosition={reloadPosition} filteredCount={filteredCount} />
          <PositionLocationFilter reloadPosition={reloadPosition} filteredCount={filteredCount} />
          <PositionMatchFilter reloadPosition={reloadPosition} filteredCount={filteredCount} />
        </div>
      </FormProvider>
    </PositionFilterTabFrame>
  );
};
export default inject(injectStore.positionListModel, injectStore.positionCurationModel, injectStore.context)(observer(PostionFilterTab));
