import colors from '__designkit__/common/colors';
import { fonts } from '__designkit__/common/fonts';
import accIntroduceBenefits from 'fixtures/accIntroduce/AccIntroduceBenefits';
import IAccIntroduceBenefit from 'interfaces/IAccIntroduceBenefit';
import React, { useState } from 'react';
import styled from 'styled-components';
import AiIntroduceBenefitCard from './AiIntroduceBenefitCard';
import AiIntroduceBenefitModal from './AiIntroduceBenefitModal';

const Frame = styled.section`
  padding:0 24px;
  padding-top:96px;
  h1.frame-title {
    ${fonts.NOTO_36_700};
    color:${colors.CG_90};
    white-space:pre-line;
    margin-bottom:48px;
    strong {
      ${fonts.WEMAKEP_36_700};
      display: inline-block;
      transform: translateY(-0.05em);
    }
  }
  > article {
    margin-bottom:32px;
  }
`;

const AiIntroduceBenefits = () => {
  const [activeBenefit, setActiveBenefit] = useState<IAccIntroduceBenefit | null>(null);
  return (
    <Frame>
      <h1 className='frame-title'>
        <strong>역량검사</strong>
        {`으로\n알 수 있습니다.`}
      </h1>
      {
        accIntroduceBenefits.map((benefit, idx) => (
          <AiIntroduceBenefitCard
            key={`aiintro-benefit-${idx}`}
            benefit={benefit}
            onClickMore={() => setActiveBenefit(benefit)}
          />
        ))
      }
      {
        activeBenefit && (
          <AiIntroduceBenefitModal
            benefit={activeBenefit}
            open={!!activeBenefit}
            onClose={() => setActiveBenefit(null)}
          />
        )
      }
    </Frame>
  );
};

export default AiIntroduceBenefits;
