import { careerFormSchema } from 'components/_v2/profile/careers/ProfileCareers';
import { prizeSchema } from 'components/_v2/profile/prize/ProfilePrize';
import { EduFormValidationSchema } from 'components/_v2/profile/education/ProfileEducation';
import * as yup from 'yup';
import { TeamType } from 'interfaces/_v2/profile/IProfileProjectRqRs';

const resumeSchema = yup.object({
  career: yup.object({
    careers: yup.array(
      careerFormSchema,
    ),
  }),
  project: yup.object({
    projects: yup.array(
      yup.object({
        title: yup.string().required('프로젝트 명을 입력해 주세요.').max(30, '30자 이내로 입력해 주세요.'),
        endDate: yup.string()
          .when('startDate', (startDate:any, schema:any) => schema.test({
            test: (endDate: Date) => endDate >= startDate,
            message: '종료 일자가 시작 일자보다 빠릅니다.',
          }))
          .required('종료 일자를 입력해 주세요.'),
        contribution: yup.object().nullable().when('teamType', {
          is: (val: TeamType) => val === 'TEAM',
          then: yup.object().nullable().required('기여도를 입력해 주세요.'),
          otherwise: yup.object().nullable().notRequired(),
        }),
        institution: yup.string().max(30, '30자 이내로 입력해 주세요.'),
        startDate: yup
          .string()
          .nullable()
          .required('시작 일자를 입력해 주세요.'),
      }),
    ).nullable(),
  }),
  prize: prizeSchema,
  experience: yup.object({
    activities: yup.array(
      yup.lazy((activity) => {
        if (!activity.tag && !activity.title && !activity.startDate && !activity.endDate && !activity.description) return yup.object();
        if (!activity.tag && !activity.title && !activity.startDate && !activity.endDate && activity.description) {
          return yup.object({
            tag: yup.string().nullable().required('내용을 입력해 주세요.'),
            title: yup.string().nullable().required('기관/장소를 입력해 주세요.').max(25, '25자 이내로 입력해 주세요.'),
            startDate: yup.lazy((startDate) => (startDate ? yup.date() : yup.string().nullable().required('일자를 입력해 주세요.'))),
            endDate: yup.lazy((endDate) => (endDate
              ? yup.date().when('startDate', (startDate: any, schema: any) => (startDate ? schema.min(startDate, '종료일자가 시작일자보다 빠릅니다. ') : schema))
              : yup.string().nullable().required('일자를 입력해 주세요.'))),
            description: yup.string().max(1000, '최대 글자수를 초과했습니다'),
          });
        }

        return yup.object({
          tag: yup.string().nullable().required('내용을 입력해 주세요.'),
          title: yup.string().nullable().required('기관/장소를 입력해 주세요.').max(25, '25자 이내로 입력해 주세요.'),
          startDate: yup.lazy((startDate) => (startDate ? yup.date() : yup.string().nullable().required('일자를 입력해 주세요.'))),
          endDate: yup.lazy((endDate) => (endDate
            ? yup.date().when('startDate', (startDate: any, schema: any) => (startDate ? schema.min(startDate, '종료일자가 시작일자보다 빠릅니다. ') : schema))
            : yup.string().nullable().required('일자를 입력해 주세요.'))),
          description: yup.string().max(1000, '최대 글자수를 초과했습니다'),
        });
      }),
    ),
    foreign: yup.array(
      yup.lazy((foreign) => {
        if (!foreign.title && !foreign.startDate && !foreign.endDate && !foreign.description) return yup.object();
        if (!foreign.title && !foreign.startDate && !foreign.endDate && foreign.description) {
          return yup.object({
            title: yup.string().required('국가를 입력해 주세요.').max(25, '25자 이내로 입력해 주세요.'),
            startDate: yup.lazy((startDate) => (startDate ? yup.date() : yup.string().nullable().required('일자를 입력해 주세요.'))),
            endDate: yup.lazy((endDate) => (endDate
              ? yup.date().when('startDate', (startDate: any, schema: any) => (startDate ? schema.min(startDate, '종료일자가 시작일자보다 빠릅니다. ') : schema))
              : yup.string().nullable().required('일자를 입력해 주세요.'))),
            description: yup.string().max(1000, '최대 글자수를 초과했습니다'),
          });
        }

        return yup.object({
          title: yup.string().required('국가를 입력해 주세요.').max(25, '25자 이내로 입력해 주세요.'),
          startDate: yup.lazy((startDate) => (startDate ? yup.date() : yup.string().nullable().required('일자를 입력해 주세요.'))),
          endDate: yup.lazy((endDate) => (endDate
            ? yup.date().when('startDate', (startDate: any, schema: any) => (startDate ? schema.min(startDate, '종료일자가 시작일자보다 빠릅니다. ') : schema))
            : yup.string().nullable().required('일자를 입력해 주세요.'))),
          description: yup.string().max(1000, '최대 글자수를 초과했습니다'),
        });
      }),
    ),
  }),
  education: EduFormValidationSchema,
});

export default resumeSchema;
