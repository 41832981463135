/* eslint-disable class-methods-use-this */

import URIs from 'consts/URIs';
import { InterviewHistoryListRs, QuestionAnswerResultRs } from 'interfaces/_v2/passInterview/IPassInterviewRs';
import { request } from 'utils/request';

export default interface PassInterviewRepo{

        // 면접 결과 목록
        fetchPassInterviewResults(): Promise<InterviewHistoryListRs>;

        // 면접 결과 상세
        fetchPassInterviewQuestionResults(practiceQuestionSn:number): Promise<QuestionAnswerResultRs>;

        // 면접 영상 재생
        fetchPassInterviewVideo(practiceQuestionSn:number): Promise<Blob>;

}

export class RemotePassInterviewRepo implements PassInterviewRepo {
  fetchPassInterviewResults(): Promise<InterviewHistoryListRs> {
    return request<InterviewHistoryListRs>({
      method: 'get',
      url: URIs.get_interview_practices_results,
    });
  }

  fetchPassInterviewQuestionResults(practiceQuestionSn:number): Promise<QuestionAnswerResultRs> {
    return request<QuestionAnswerResultRs>({
      method: 'get',
      url: URIs.get_interview_practices_questions_practice_question_sn_results(practiceQuestionSn),
    });
  }

  fetchPassInterviewVideo(practiceQuestionSn:number): Promise<Blob> {
    return request<Blob>({
      method: 'get',
      url: URIs.get_interview_practices_practice_questions_sn_answer_videos(practiceQuestionSn),
      responseType: 'blob',
    });
  }
}
