import React from 'react';
import classnames from 'classnames/bind';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

const ProfileDownload = () => (
  <div className={cx('commonBox')}>
    <div className={cx('descTitle')} />

    <div className={cx('descImg')} />
  </div>
);

export default ProfileDownload;
