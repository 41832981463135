import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import JDAtsFailModal from 'components/_v2/_common/modals/JDAtsFailModal';
import MatchHomeDashboardDeleteResumeModal from 'components/_v2/matchHome/dashboard/MatchHomeDashboardDeleteResumeModal';
import PositionApplyCancelModal from 'components/_v2/positionJd/PositionApplyCancelModal';
import { MatchTabType } from 'consts/_v2/match/MatchType';
import { MatchHomeDashboardPositionType } from 'consts/_v2/matchHome/dashboard/MatchHomeDashboardType';
import useToast from 'hooks/useToast';
import { ApplicationPositionType, IMatchDashboardPosition } from 'interfaces/_v2/matchHome/dashboard/IMatchHomeDashboard';
import { inject, observer } from 'mobx-react';
import MatchHomeDashboardModel from 'models/_v2/MatchHomeDashboardModel';
import PositionJdModel from 'models/_v2/PositionJdModel';
import ResumeModel from 'models/_v2/ResumeModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { overDateNow } from 'utils/DateUtils';

const Frame = styled.div`
    display: flex;
    top: 60px;
    right: 16px;
    flex-direction: column;
    z-index:1;
    justify-content: center;
    position: absolute;
    width: 160px;
    padding: 18px 16px;
    background-color: ${colors.WHITE_100};
    border: 1px solid ${colors.CG_40};
    border-radius: 8px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.12);
    li{
      &:not(:last-child){
        margin-bottom:24px;
      }
    }

    .text {
        font: ${Fonts.B2_Medium};
        color: ${colors.CG_70};
        white-space: nowrap;
    }

    &[aria-hidden=true] {
        visibility: hidden;
    }
`;

interface IMatchHomeDashboardMoreBox {
  isOpen: boolean;
  item: IMatchDashboardPosition;
  onClickClose: () => void;
  matchHomeDashboardModel?: MatchHomeDashboardModel;
  resumeModel?: ResumeModel;
  positionSn: number;
  positionJdModel?: PositionJdModel;
  type?:MatchTabType
}

const MatchHomeDashboardMoreBox: FC<IMatchHomeDashboardMoreBox> = ({ type = MatchTabType.ALL, positionSn, positionJdModel = new PositionJdModel(), isOpen, onClickClose, item, matchHomeDashboardModel = new MatchHomeDashboardModel(), resumeModel = new ResumeModel() }) => {
  const wrapperRef = useRef<any>(null);
  const [visibleOpen, setVisibleOpen] = useState<boolean>(false);
  const [isDeleteResumeModalOpen, setIsDeleteResumeModalOpen] = useState<boolean>(false);
  const [isAtsFailModalOpen, setIsAtsFailModalOpen] = useState<boolean>(false);
  const { setToastObject } = useToast();
  const [isApplyCancelModalOpen, setIsApplyCancelModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      setVisibleOpen(true);
    }
  }, [isOpen]);

  const useOutsideClicker = (ref: any) => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setVisibleOpen(false);
        onClickClose();
      }
    }
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  useOutsideClicker(wrapperRef);

  const handleDelete = () => {
    setIsDeleteResumeModalOpen(true);
    setVisibleOpen(false);
    onClickClose();
  };

  const reloadPosition = async () => {
    await matchHomeDashboardModel.loadApplicationHistories(type);
    await matchHomeDashboardModel.loadHiddenPositions();
    await matchHomeDashboardModel.loadApplicationCount();
    await matchHomeDashboardModel.loadWritingResumePositions();
  };

  const handleHide = async () => {
    await matchHomeDashboardModel.hidePosition(positionSn);
    await reloadPosition();
    setVisibleOpen(false);
    onClickClose();
  };

  const handleUnHide = async () => {
    await matchHomeDashboardModel.showPosition([positionSn]);
    await reloadPosition();
    setVisibleOpen(false);
    onClickClose();
  };

  useEffect(() => {
    const init = async () => {
      if (Number.isNaN(Number(positionSn))) {
        throw new Error();
      } else {
        await positionJdModel.init(Number(positionSn));
      }
    };
    init();
  }, [positionSn]);

  const deleteResume = async () => {
    setIsDeleteResumeModalOpen(false);
    try {
      await matchHomeDashboardModel.deleteWritingResume(positionSn);
      await reloadPosition();
      setToastObject({ isOpen: true, type: 'SUCCESS', message: '지원서 삭제에 성공했습니다.' });
    } catch (e: any) {
      if (e.response.data.errorCode === 'B908') {
        setIsAtsFailModalOpen(true);
      } else if (e.response.data.errorCode === 'B901') {
        setToastObject({ isOpen: true, type: 'ERROR', message: '삭제된 포지션 입니다.' });
      } else if (e.response.data.errorCode === 'B902') {
        setToastObject({ isOpen: true, type: 'ERROR', message: '이미 삭제된 지원서 입니다.' });
      } else if (e.response.data.errorCode === 'B903') {
        setToastObject({ isOpen: true, type: 'ERROR', message: '이미 제출된 지원서입니다.' });
      } else if (e.response.data.errorCode === 'B906') {
        setToastObject({ isOpen: true, type: 'ERROR', message: '외부 포지션은 지원 취소 할 수 없습니다.' });
      } else {
        setToastObject({ isOpen: true, type: 'ERROR', message: '지원서 삭제에 실패했습니다.' });
      }
      console.error(e);
    }
  };

  const handleCancelClick = async () => {
    // 1. 포지션 상태 확인 /position/{positionSn}/status 포지션 상태 조회
    await matchHomeDashboardModel.getPositionStatus(positionSn);
    if (!matchHomeDashboardModel.positionStatus) return;

    if (matchHomeDashboardModel.positionStatus.deleteYn) setToastObject({ isOpen: true, type: 'INFO', message: '포지션이 삭제되었습니다.' });
    else if (!matchHomeDashboardModel.positionStatus.openYn) setToastObject({ isOpen: true, type: 'INFO', message: '포지션이 종료되었습니다.' });
    else if (matchHomeDashboardModel.positionStatus.closingDateTime && overDateNow(matchHomeDashboardModel.positionStatus.closingDateTime)) setToastObject({ isOpen: true, type: 'INFO', message: '포지션이 마감되었습니다.' });
    else if (positionJdModel.basicInfo && positionJdModel.basicInfo.openingDateTime && !(overDateNow(positionJdModel.basicInfo.openingDateTime))) setToastObject({ isOpen: true, type: 'INFO', message: '지원하신 포지션의 시작일이 변경됐습니다.', subMessage: '해당 날짜 이후에 확인하실 수 있습니다.' });
    else if (item.positionType === ApplicationPositionType.JOBDA) {
      setIsApplyCancelModalOpen(true);
    } else if (item.positionType === ApplicationPositionType.HOMEPAGE) {
      setIsApplyCancelModalOpen(true);
    }
  };

  const handleCancel = async () => {
    try {
      await matchHomeDashboardModel.deleteApply(positionSn);
      await reloadPosition();
      setToastObject({ isOpen: true, type: 'SUCCESS', message: '지원이 취소되었습니다.' });
    } catch (e: any) {
      if (e.response.data.errorCode === 'B908') {
        setIsAtsFailModalOpen(true);
      } else if (e.response.data.errorCode === 'B904' || e.response.data.errorCode === 'B901') {
        setToastObject({ isOpen: true, type: 'ERROR', message: '마감된 포지션 입니다.' });
      } else if (e.response.data.errorCode === 'B905') {
        setToastObject({ isOpen: true, type: 'ERROR', message: '삭제된 포지션 입니다.' });
      } else if (e.response.data.errorCode === 'B903') {
        setToastObject({ isOpen: true, type: 'ERROR', message: '이미 채용 전형이 진행중인 포지션 입니다.' });
      } else if (e.response.data.errorCode === 'B906') {
        setToastObject({ isOpen: true, type: 'ERROR', message: '외부 포지션은 지원 취소 할 수 없습니다.' });
      } else if (e.response.data.errorCode === 'B907') {
        setToastObject({ isOpen: true, type: 'ERROR', message: '제안을 수락한 포지션은 지원 취소가 불가능합니다.' });
      } else {
        setToastObject({ isOpen: true, type: 'ERROR', message: '지원 취소에 실패했습니다.' });
      }
      console.error(e);
    }
  };

  return (
    <>
      {visibleOpen && (
        <Frame ref={wrapperRef} aria-hidden={!isOpen}>
          <ul>
            {item.jobApplicationProcessType === MatchHomeDashboardPositionType.WRITING_RESUME && (
              <li role='presentation' className='text' onClick={handleDelete}>지원서 삭제</li>
            )}

            {item.jobApplicationProcessType === MatchHomeDashboardPositionType.APPLY && (
              <li role='presentation' className='text' onClick={handleCancelClick}>지원 취소</li>
            )}
            {item.jobApplicationProcessType !== MatchHomeDashboardPositionType.HIDDEN && item.jobApplicationProcessType !== MatchHomeDashboardPositionType.WRITING_RESUME && (
            <li role='presentation' className='text' onClick={handleHide}>포지션 숨기기</li>
            )}
            {item.jobApplicationProcessType === MatchHomeDashboardPositionType.HIDDEN && (
              <li role='presentation' className='text' onClick={handleUnHide}>포지션 숨김 해제</li>
            )}
          </ul>
        </Frame>
      )}
      <MatchHomeDashboardDeleteResumeModal
        isOpen={isDeleteResumeModalOpen}
        onClickClose={() => setIsDeleteResumeModalOpen(false)}
        onSuccess={deleteResume}
      />
      <PositionApplyCancelModal
        isOpen={isApplyCancelModalOpen}
        onSuccess={() => handleCancel()}
        onClickClose={() => setIsApplyCancelModalOpen(false)}
      />
      <JDAtsFailModal isOpen={isAtsFailModalOpen} onClickClose={() => setIsAtsFailModalOpen(false)} />
    </>
  );
};

export default inject(injectStore.matchHomeDashboardModel, injectStore.resumeModel)(observer(MatchHomeDashboardMoreBox));
