import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import IconFileLink from 'assets/_v2/profile/icon_profile_fileLink.svg';
import Loading from 'components/Loading';
import ProfileItemTitle from 'components/_v2/profile/ProfileItemTitle';
import URIs from 'consts/URIs';
import { lineClampBox } from 'consts/_v2/_common/style/mixins';
import useToast from 'hooks/useToast';
import { IProfileAttachFileRs } from 'interfaces/_v2/profile/IProfileAttachFileRqRs';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import FileUtil from 'utils/FileUtil';
import MobileStore from 'store/mobileStore';
import TextUtil from 'utils/TextUtil';
import request from 'utils/request';

const Frame = styled.div``;

const ContentFrame = styled.div`
  margin-bottom: 32px;
  
  .title {
    font: ${Fonts.B1_Bold};
    line-height: 22px;
    color: ${colors.JOBDA_BLACK};
    margin-bottom: 12px;
  }

  .contents {
    display: flex;
    align-items: center;

    .file-name {
      font: ${Fonts.B2_Medium_P};
      color: ${colors.JOBDA_BLACK};
      text-decoration-line: underline;
      margin-left: 8px;
      word-break: break-all;
      ${lineClampBox(1)};
    }

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
`;

const ProfilePreviewFile: FC<{ data: IProfileAttachFileRs}> = ({ data }) => {
  const { portfolioAttachFiles, referenceUrls, careerAttachFiles } = data;
  const { setToastObject } = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const downloadFile = async (fileName: string, fileUid: string) => {
    try {
      if (MobileStore.isMobile) {
        setIsLoading(true);
      }

      const res = await request<ArrayBuffer>({
        method: 'get',
        url: URIs.get_file_download(fileUid),
        responseType: 'arraybuffer',
      });
      FileUtil.fileDownload(fileName, res, false, setToastObject, setIsLoading);
      return res;
    } catch (e) {
      console.error(e);
      return null;
    }
  };
  return (
    <Frame>
      <ProfileItemTitle icon='folder-open' text='첨부자료' />
      {isLoading && <Loading />}

      { portfolioAttachFiles && portfolioAttachFiles.length > 0 && (
        <ContentFrame>
          <p className='title'>포트폴리오</p>
          { portfolioAttachFiles.map((portfolioFile) => (
            <div className='contents' key={portfolioFile.fileUid}>
              <img src={IconFileLink} alt='icon-file-link' />
              <span role='button' className='file-name' onClick={() => downloadFile(portfolioFile.fileName, portfolioFile.fileUid)}>{portfolioFile.fileName}</span>
            </div>
          ))}
        </ContentFrame>
      )}
      { careerAttachFiles && careerAttachFiles.length > 0 && (
        <ContentFrame>
          <p className='title'>경력기술서</p>
          { careerAttachFiles.map((careerFile) => (
            <div className='contents' key={careerFile.fileUid}>
              <img src={IconFileLink} alt='icon-file-link' />
              <span role='button' className='file-name' onClick={() => downloadFile(careerFile.fileName, careerFile.fileUid)}>{careerFile.fileName}</span>
            </div>
          ))}
        </ContentFrame>
      )}
      { referenceUrls && referenceUrls.length > 0 && (
        <ContentFrame>
          <p className='title'>URL</p>
          { referenceUrls.map((url) => (
            <div className='contents' key={`attachedFile-${url}`}>
              <img src={IconFileLink} alt='icon-file-link' />
              <a href={TextUtil.formatWithProtocol(url)}>
                <span className='file-name'>{url}</span>
              </a>
            </div>
          ))}
        </ContentFrame>
      )}

    </Frame>
  );
};

export default ProfilePreviewFile;
