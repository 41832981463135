import React from 'react';
import Intro from './intro';
import CompanyList from './companyList';
import Banner from './banner';
import Description from './description';
import CheeringMessage from './cheeringMessage';
import Detail from './detail';
import Qna from './qna';
import PhsTestButton from './phsTestButton';

const EventDChamps = () => (
  <>
    {/* 인트로 영역 */}
    <Intro />

    {/* 제휴 기업 영역 */}
    <CompanyList />

    {/* 디챔스 배너 영역 */}
    <Banner />

    {/* 디챔스 설명 영역 */}
    <Description />

    {/* 인담자 응원 영역 */}
    <CheeringMessage />

    {/* 디챔스 상세 안내 영역 */}
    {/* 참가 방법 / 일정 / 경품 / 설명 / 디챔스 출제 문제 / 역량검사 문제 / 상세 안내 */}
    <Detail />

    {/* 디챔스 배너 영역 */}
    <Banner />

    {/* 디챔스 QNA 영역 */}
    <Qna />

    {/* 제안 받기 버튼 영역(fixed) */}
    <PhsTestButton />
  </>
);

export default EventDChamps;
