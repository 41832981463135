import { yupResolver } from '@hookform/resolvers/yup';
import { Button, ButtonMode } from '__designkit__/button/Button';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SingleMenuItem from '__designkit__/components/SingleMenuItem';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import ProfileAPI from 'api/profileAPI';
import JDSaveButton from 'components/_v2/_common/button/JDSaveButton';
import FormValueFrame, { DatePickerFormValueFrame } from 'components/_v2/_common/form/FormProfileValueFrame';
import FormProfileValueTitle from 'components/_v2/_common/form/FormProfileValueTitle';
import JDBaseInput from 'components/_v2/_common/input/JDBaseInput';
import JDDatePickerInput from 'components/_v2/_common/input/JDDatePickerInput';
import JDDescriptionInput from 'components/_v2/_common/input/JDDescriptionInput';
import JDRadioInputTemp, { JD_RADIO_INPUT_TYPE } from 'components/_v2/_common/input/JDRadioInputTemp';
import JDSelector, { JDSelectorType } from 'components/_v2/_common/input/JDSelector';
import JDFullModal from 'components/_v2/_common/modals/JDFullModal';
import SimpleConfirmModal from 'components/_v2/_common/modals/SimpleConfirmModal';
import ProfileEditTitleComponent from 'components/_v2/profile/ProfileEditTitleComponent';
import ProfileLegend from 'components/_v2/profile/ProfileLegend';
import CareerEntryOptionsModal from 'components/_v2/profile/careers/CareerEntryOptionsModal';
import NHISCareerAddModal from 'components/_v2/profile/careers/NHISCareerAddModal';
import { IProfileCareersView } from 'components/_v2/profile/careers/ProfileCareersView';
import CompanySearchField from 'components/react-hook-form/CompanySearchField';
import { EMPLOYMENT_STATUS, EMPLOYMENT_STATUS_TEXT, EMPLOYMENT_TYPE_TEXT } from 'consts/_v2/profile/career';
import useQueryParams from 'hooks/useQueryParams';
import useToast from 'hooks/useToast';
import { EmploymentStatus, IProfileCareerRq } from 'interfaces/_v2/profile/IProfileCareerRqRs';
import { INameCodeRs } from 'interfaces/rqrs/ICommonRqRs';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import ProfileCareersModel from 'models/_v2/profile/ProfileCareersModel';
import { injectStore } from 'models/store';
import React, { FC, useCallback, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { debounce } from 'utils/CommonUtils';
import * as yup from 'yup';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Header = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  height: 56px;
  padding: 0 8px;
  background-color: ${Colors.WHITE_100};
  z-index: 1;
`;

const Contents = styled.div`
  flex-grow: 1;
  width: 100%;
  padding: 24px 16px 16px;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ListFrame = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .title {
    display: flex;
    justify-content: flex-end;

    button {
      display: flex;
      align-items: center;
      height: 24px;
      padding: 2px;
    
      & > span {
        display: flex;
        align-items: center;
        font: ${Fonts.B3_Medium};
        color:${Colors.JOBDA_BLACK};
      }

      :disabled {
        opacity: 0.5;
      }
    }
  }

  .register-item-frame {
    > * {
      margin-bottom: 16px;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &.none {
    display: none;
  }
`;

const RegisterFrame = styled.form`
  .radio-field {
    display: flex;
    margin-bottom: 24px;

    >:not(:first-child) {
      margin-left: 24px;
    }
  }
`;

const BtnFrame = styled.div`
  width: 100%;
  height: fit-content;
  padding: 16px;
`;

export const desturctureOptions = (options: INameCodeRs[], selected?: (code: number) => boolean) => {
  if (!options) return <></>;
  return options.map(({ name, code }) => (code === 0 ? <></> : <option key={`option-${code}-${name}`} value={code} selected={selected?.(code)}>{name}</option>));
};
interface IProfileCareersProps extends IProfileCareersView {
  onClose: () => void;
  context?: Context;
}

export const careerFormSchema = yup.object({
  companyName: yup.string().nullable().required('직장명을 입력해 주세요.').max(30, '30자 이내로 입력해 주세요.'),
  employmentStatus: yup.string().required('재직 상태를 입력해 주세요.'),
  startDate: yup.string().required('입사 일자를 입력해 주세요'),
  endDate: yup.string().when('employmentStatus', {
    is: (val: EmploymentStatus) => val === EMPLOYMENT_STATUS.RESIGN,
    then: yup.string().when('startDate', (startDate:any, schema:any) => schema.test({
      test: (endDate: Date) => endDate >= startDate,
      message: '퇴사 일자가 입사일자보다 빠릅니다.',
    })).required('퇴사 일자를 입력해 주세요.'),
    otherwise: yup.string().nullable().notRequired(),
  }),
  jobGroupCode: yup.string().required('직군을 선택해 주세요.').nullable(),
  jobTitleCode: yup.string().required('직무를 선택해 주세요.').nullable(),
  employmentType: yup.string().required('계약 형태를 선택헤주세요.').nullable(),
  performance: yup.string().max(10000, '10000자 이내로 입력해 주세요.'),
  role: yup.string().max(30, '30자 이내로 입력해 주세요.'),
});

const ProfileCareers: FC<IProfileCareersProps> = ({ context = new Context(), profileCareersModel = new ProfileCareersModel(), onClose }) => {
  const { setToastObject } = useToast();
  const { sourcePage } = useQueryParams();
  const [registerItemMode, setRegisterItemMode] = useState<-1 | 0 | 1>(0); // -1 : 삭제, 0 : 목록, 1 : 등록
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false);
  const [deleteIndexSet, setDeleteIndexSet] = useState<Set<number>>(new Set<number>());
  const [currentCareerSn, setCurrentCareerSn] = useState<number | undefined>(undefined);
  const [isOpenOptionsModal, setIsOpenOptionsModal] = useState<boolean>(false);
  const [isOpenNHISCareerAddModal, setIsOpenNHISCareerAddModal] = useState<boolean>(false);
  const registerFrameRef = useRef<HTMLFormElement | null>(null);

  const defaultCareerValue = {
    companyName: undefined,
    employmentStatus: EMPLOYMENT_STATUS.WORK,
    startDate: undefined,
    endDate: undefined,
    jobGroupCode: undefined,
    jobTitleCode: undefined,
    employmentType: undefined,
    performance: undefined,
    role: undefined,
  };

  const formMethods = useForm<IProfileCareerRq>({
    mode: 'onSubmit',
    resolver: yupResolver(careerFormSchema),
    defaultValues: defaultCareerValue,
  });
  const { watch, setValue, getValues, handleSubmit, reset } = formMethods;

  const companyName = watch('companyName');
  const employmentStatus = watch('employmentStatus');
  const startDate = watch('startDate');
  const endDate = watch('endDate');
  const jobGroupCode = watch('jobGroupCode');
  const jobTitleCode = watch('jobTitleCode');
  const employmentType = watch('employmentType');
  const performance = watch('performance');
  const role = watch('role');
  const verified = watch('verified');

  // 경력 목록 모달을 닫거나 경력 등록 모달에서 경력 목록 모달로 전환하는 함수, 닫기 Btn 클릭 시 동작
  const onClickCloseBtn = async () => {
    if (registerItemMode === 1) {
      setRegisterItemMode(0);
      setCurrentCareerSn(undefined);
      reset(defaultCareerValue);
    } else if (registerItemMode === -1) {
      if (deleteIndexSet.size > 0) {
        setIsOpenConfirmModal(true);
      } else {
        setRegisterItemMode(0);
        setDeleteIndexSet(new Set<number>());
      }
    } else {
      onClose();
    }
  };

  // 경력 상태가 재직중일 경우 퇴사일자 필드를 비활성화하고 퇴사일자를 undefined로 설정하는 함수, 재직 상태 수정했을 때 동작
  const handleRadioStatus = () => {
    if (employmentStatus === EMPLOYMENT_STATUS.RESIGN) {
      setValue('endDate', undefined);
    }
  };

  // 경력 목록에서 각 경력의 직군 · 직무를 코드로 받아 텍스트로 표현하는 함수, 경력 목록에서 사용
  const makeJobText = (groupCode: number | null, titleCode: number | null): string => {
    if (groupCode && titleCode) {
      if (context.jobGroupsWithTitles.length > 0 && context.jobTitles[groupCode] && context.jobTitles[groupCode].titles.length > 0) {
        const jobGroupName = context.jobGroupsWithTitles.find((jobGroup) => jobGroup.code === groupCode)?.name;
        const jobTitleName = context.jobTitles[String(groupCode)].titles.find((jobTitle) => jobTitle.code === titleCode)?.name;
        return `${jobGroupName} > ${jobTitleName}`;
      }
    }
    if (groupCode) return '직무를 입력해 주세요';
    return '직군 · 직무를 입력해 주세요';
  };

  // 경력 목록 모드에서 경력 추가 모드로 진입하는 함수, '경력 추가하기' Btn 클릭 시 동작
  const onClickRegisterBtn = () => {
    setRegisterItemMode(1);
    setCurrentCareerSn(undefined);
    reset(defaultCareerValue);
    debounce(() => {
      if (registerFrameRef.current) registerFrameRef.current.scrollTo(0, 0);
    }, 500);
  };

  // 경력 삭제하는 함수, '삭제' Btn 클릭 시 동작
  const onClickDeleteBtn = async () => {
    try {
      const temp: number[] = [];
      deleteIndexSet.forEach((sn) => temp.push(sn));
      await Promise.all(temp.map(async (sn) => { await ProfileAPI.deleteCareer(sn); }));
      profileCareersModel.setCareers((await ProfileAPI.getCareers()).profileCareers);
      setCurrentCareerSn(undefined);
      setDeleteIndexSet(new Set<number>());
      setRegisterItemMode(0);
      setToastObject({ isOpen: true, message: '성공적으로 삭제되었습니다.', type: 'SUCCESS' });
    } catch (e) {
      setToastObject({ isOpen: true, message: '삭제에 실패하였습니다.', type: 'ERROR' });
    }
  };

  // 경력 등록 및 수정하는 함수, '등록' Btn 클릭 시 동작
  const onSubmit = async () => {
    try {
      const profileCareer = getValues() as IProfileCareerRq;
      if (currentCareerSn) await ProfileAPI.putCareer(currentCareerSn, { profileCareer, sourcePage });
      else await ProfileAPI.postCareer({ profileCareer, sourcePage });
      profileCareersModel.setCareers((await ProfileAPI.getCareers()).profileCareers);
      setToastObject({ isOpen: true, message: '성공적으로 저장되었습니다.', type: 'SUCCESS' });
      setRegisterItemMode(0);
      setCurrentCareerSn(undefined);
      reset(defaultCareerValue);
    } catch (e) {
      setToastObject({ isOpen: true, message: '저장에 실패하였습니다.', type: 'ERROR' });
    }
  };

  const onError = useCallback((data) => {
    console.error(data);
    setToastObject({ isOpen: true, message: '저장에 필요한 정보를 작성해 주세요.', type: 'ERROR' });
  }, [setToastObject]);

  return (
    <JDFullModal>
      <Frame>
        <Header>
          <Icon name='large-arrow-left' size={32} color={Colors.JOBDA_BLACK} onClick={onClickCloseBtn} />
        </Header>
        <ProfileEditTitleComponent
          title={currentCareerSn ? '경력 정보를 등록해 주세요.' : '등록된 경력 정보를 확인해 주세요.'}
          subtitle={currentCareerSn ? '업무 성과를 효과적으로 강조하기 위해 구체적인 수치, 사실을 포함해 주세요.' : '최근 활동 순으로 자동 정렬돼요.'}
          divider={!currentCareerSn}
        />
        <Contents>
          { registerItemMode < 1 && (
            profileCareersModel.careers.length > 0
              ? (
                <ListFrame hidden={registerItemMode === 1}>
                  <div className='title'>
                    <button
                      type='button'
                      disabled={profileCareersModel.careers.length === 0}
                      onClick={() => {
                        if (registerItemMode < 0) {
                          setRegisterItemMode(0);
                          setDeleteIndexSet(new Set<number>());
                        } else {
                          setRegisterItemMode(-1);
                        }
                      }}
                    >
                      <span>
                        { registerItemMode < 0 ? <></> : <Icon name='guide' size={24} color={Colors.JOBDA_BLACK} /> }
                        { registerItemMode < 0 ? '취소' : '편집' }
                      </span>
                    </button>
                  </div>
                  <SpacingBlock size={16} vertical />
                  <div className='register-item-frame'>
                    { profileCareersModel.careers.map((career) => (
                      <SingleMenuItem
                        key={career.sn}
                        title={career.companyName}
                        subTitle={makeJobText(career.jobGroupCode, career.jobTitleCode)}
                        onClick={registerItemMode < 0
                          ? () => {
                            if (deleteIndexSet.has(career.sn)) {
                              deleteIndexSet.delete(career.sn);
                              setDeleteIndexSet(new Set(deleteIndexSet));
                            } else {
                              deleteIndexSet.add(career.sn);
                              setDeleteIndexSet(new Set(deleteIndexSet));
                            }
                          }
                          : () => {
                            reset(career);
                            setCurrentCareerSn(career.sn);
                            setRegisterItemMode(1);
                          }}
                        rightIcon={registerItemMode < 0 ? deleteIndexSet.has(career.sn) ? <Icon name='check-circle-filled' size={32} color={Colors.G_150} /> : <Icon name='check-circle-filled' size={32} color={Colors.CG_40} /> : undefined}
                        selected={deleteIndexSet.has(career.sn)}
                        badge={career.verified ? <Icon name='verified-filled' size={24} color={Colors.G_150} /> : undefined}
                      />
                    ))}
                  </div>
                </ListFrame>
              )
              : (
                <>
                  <SingleMenuItem
                    title='직접 추가하기'
                    onClick={onClickRegisterBtn}
                    leftIcon={<Icon name='add' size={32} color={Colors.JOBDA_BLACK} />}
                  />
                  <SpacingBlock size={12} vertical />
                  <SingleMenuItem
                    title='경력 불러오기'
                    onClick={() => setIsOpenNHISCareerAddModal(true)}
                    leftIcon={<Icon name='verified-filled' size={32} color={Colors.G_150} />}
                    rightIcon={<Icon name='arrow-right' size={32} color={Colors.WHITE_100} />}
                    inverted
                  />
                </>
              )
          )}
          { registerItemMode > 0 && (
            <RegisterFrame ref={registerFrameRef} onSubmit={handleSubmit(onSubmit, onError)} id='careerForm'>
              <FormProvider {...formMethods}>
                <ProfileLegend verified={!!verified} />
                <FormProfileValueTitle required={!verified}>
                  직장명
                  { !!verified && <Icon name='verified-filled' size={24} color={Colors.G_150} /> }
                </FormProfileValueTitle>
                <FormValueFrame>
                  <CompanySearchField name='companyName' defaultValue={companyName} disabled={!!verified} />
                </FormValueFrame>
                <FormProfileValueTitle required={!verified}>
                  상태
                  { !!verified && <Icon name='verified-filled' size={24} color={Colors.G_150} /> }
                </FormProfileValueTitle>
                <div className='radio-field'>
                  { Object.values(EMPLOYMENT_STATUS).map((key) => (
                    <JDRadioInputTemp
                      type={JD_RADIO_INPUT_TYPE.ROUND_BOX}
                      key={`radio-option-${key}`}
                      name='employmentStatus'
                      value={key}
                      label={EMPLOYMENT_STATUS_TEXT[key]}
                      defaultValue={employmentStatus}
                      onChange={handleRadioStatus}
                      disabled={!!verified}
                    />
                  ))}
                </div>
                <FormProfileValueTitle required={!verified}>
                  근무 기간
                  { !!verified && <Icon name='verified-filled' size={24} color={Colors.G_150} /> }
                </FormProfileValueTitle>
                <DatePickerFormValueFrame>
                  <JDDatePickerInput
                    name='startDate'
                    placeholder='연도. 월.'
                    defaultValue={startDate}
                    month
                    disabled={!!verified}
                  />
                  <span className='date-picker-divider'>~</span>
                  <JDDatePickerInput
                    name='endDate'
                    placeholder='연도. 월.'
                    defaultValue={endDate}
                    month
                    disabled={verified ? true : employmentStatus !== EMPLOYMENT_STATUS.RESIGN}
                  />
                </DatePickerFormValueFrame>
                <FormProfileValueTitle required>직군</FormProfileValueTitle>
                <FormValueFrame>
                  <JDSelector
                    type={JDSelectorType.NORMAL}
                    name='jobGroupCode'
                    selectTitle='직군을 선택해 주세요.'
                    value={jobGroupCode ? String(jobGroupCode) : undefined}
                    onChange={async (value) => {
                      setValue(`jobTitleCode`, value === '0' ? '0' : '');
                    }}
                  >
                    <select>
                      <option value='' hidden>직군을 선택해 주세요.</option>
                      { desturctureOptions(context.jobGroupsWithTitles, jobGroupCode ? (code: number) => code === Number(jobGroupCode) : undefined) }
                    </select>
                  </JDSelector>
                </FormValueFrame>
                <FormProfileValueTitle required>직무</FormProfileValueTitle>
                <FormValueFrame>
                  <JDSelector
                    type={JDSelectorType.NORMAL}
                    name='jobTitleCode'
                    selectTitle='직무를 선택해 주세요.'
                    value={jobTitleCode ? String(jobTitleCode) : undefined}
                    disabled={!jobGroupCode}
                  >
                    <select>
                      <option value='' hidden>직무를 선택해 주세요.</option>
                      { jobGroupCode && desturctureOptions(context.jobTitles[String(jobGroupCode)]?.titles, (code) => code === Number(jobTitleCode)) }
                    </select>
                  </JDSelector>
                </FormValueFrame>
                <FormProfileValueTitle required>계약 형태</FormProfileValueTitle>
                <FormValueFrame>
                  <JDSelector
                    type={JDSelectorType.NORMAL}
                    name='employmentType'
                    selectTitle='계약 형태를 선택해 주세요.'
                    value={employmentType}
                  >
                    <select>
                      <option value='' hidden>계약 형태를 선택해 주세요.</option>
                      { Object.entries(EMPLOYMENT_TYPE_TEXT).map(([key, text]) => <option key={`option-career-level-${key}`} value={key} selected={employmentType === key}>{text}</option>) }
                    </select>
                  </JDSelector>
                </FormValueFrame>
                <FormProfileValueTitle>역할</FormProfileValueTitle>
                <FormValueFrame>
                  <JDBaseInput
                    name='role'
                    placeholder='직장에서 담당했던 역할을 작성해 주세요.'
                    defaultValue={role}
                    maxLength={30}
                  />
                </FormValueFrame>
                <FormProfileValueTitle>주요 성과</FormProfileValueTitle>
                <FormValueFrame>
                  <JDDescriptionInput
                    name='performance'
                    placeholder='주요 성과를 간략하게 설명해 주세요.'
                    maxLength={10000}
                    autoFocus={false}
                    defaultValue={performance}
                  />
                </FormValueFrame>
              </FormProvider>
            </RegisterFrame>
          )}
        </Contents>
        { registerItemMode === 0
          ? profileCareersModel.careers.length > 0 && <BtnFrame><Button label='경력 추가하기' size='large' buttonMode={ButtonMode.PRIMARY} onClick={() => setIsOpenOptionsModal(true)} /></BtnFrame>
          : registerItemMode === 1
            ? <JDSaveButton type='submit' fixed={false} register={!!currentCareerSn} form='careerForm' />
            : <BtnFrame><Button label='삭제' size='large' buttonMode={ButtonMode.PRIMARY} onClick={onClickDeleteBtn} form='careerForm' /></BtnFrame>}
      </Frame>
      { isOpenOptionsModal && <CareerEntryOptionsModal onClickClose={() => setIsOpenOptionsModal(false)} option1={onClickRegisterBtn} option2={() => setIsOpenNHISCareerAddModal(true)} /> }
      { isOpenNHISCareerAddModal && <NHISCareerAddModal onClickClose={() => setIsOpenNHISCareerAddModal(false)} afterLogic={() => onClickRegisterBtn()} /> }
      <SimpleConfirmModal
        isOpen={isOpenConfirmModal}
        title='페이지에서 나가시겠어요?'
        subTitle='저장되지 않은 정보는 사라집니다.'
        confirmBtnText='나가기'
        cancelBtnText='취소'
        onClickConfirm={() => {
          setRegisterItemMode(0);
          setDeleteIndexSet(new Set<number>());
          setIsOpenConfirmModal(false);
        }}
        onClickCancel={() => setIsOpenConfirmModal(false)}
      />
    </JDFullModal>
  );
};

export default inject(injectStore.context, injectStore.profileCareersModel)(observer(ProfileCareers));
