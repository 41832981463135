import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import ImgBackground from 'assets/_v2/company/img_background_gradation.png';
import JDSwiper, { JDSwiperSlide } from 'components/_v2/_common/JDSwiper';
import { inject, observer } from 'mobx-react';
import CompanyModel from 'models/_v2/CompanyModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import IconMessage from 'assets/_v2/company/icon_special_message.png';
import Login from 'models/Login';
import MatchReportModel from 'models/_v2/MatchReportModel';

const TotalFrame = styled.div``;
const Frame = styled.div`
  width: 100%;
  padding: 40px 24px;
  .title{
    font:${Fonts.H4_Bold};
    color:${Colors.JOBDA_BLACK};
  }
`;

const SpecialMessageWrapper = styled.div`
padding: 8px;
position: relative;
  .svg{
    position: absolute ;
    top: 0;
    left: 0;
  }  
`;

const SpecialMessageArea = styled.div`
  .specialMessage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px;
    border:2px solid #D2C9BA;
    white-space: pre-line;
    .icon{
      width: 60px;
      height: 60px;
    }
    .company-name{
      margin-top: 4px;
      font:${Fonts.B3_Bold};
      color:#D2C9BA;
    }
    .small-divider{
      width: 100%;
      height: 1px;
      background-color: #D2C9BA;
      margin:24px 0;
    }
    .message-frame{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
  
    .intro{
      font:${Fonts.B1_Bold_P};
      color:#857a5c;
    }
    .company-desc{
      font:${Fonts.B3_Medium_P};
      color:${Colors.CG_80};
      word-break: keep-all;
    }
      .background-message {
    font: ${Fonts.B2_Bold_P};
    background: linear-gradient(90deg, #48D378 -0.91%, #40CCC0 50%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;   
      }
    .last-message{
        font:${Fonts.B2_Bold_P};
        color:#0e0e0e;
    }

  }
}
`;
interface TextReplacements {
  [key: string]: string;
}
interface ICompanyReportSpecialMessage {
  companyModel? :CompanyModel
  login?:Login
  matchReportModel?:MatchReportModel
}
const CompanyReportSpecialMessage: FC<ICompanyReportSpecialMessage> = ({ companyModel = new CompanyModel(), login = new Login(), matchReportModel = new MatchReportModel() }) => {
  const messageRef = useRef<HTMLDivElement>(null);
  const [svgHeight, setSvgHeight] = useState<number>(0);
  const [svgWidth, setSvgWidth] = useState<number>(0);
  const name = login.userInfo?.name || '';
  const companyName = companyModel.companyHeader?.companyHeaderDto.name || '';
  const TextWrapperSvg = ({ height, width }: { height: number, width:number }) => (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d={`M1 ${height - 50} V47.834 L 47.4893 1 H ${width - 47} L ${width - 1} 47.8308 V${height - 47} L ${width - 47} ${height - 1} H 47.5023 L 1 ${
          height - 50} Z`}
        stroke='#D2C9BA'
        strokeWidth='2'
      />
    </svg>
  );

  // </svg>
  const replaceTextVariables = (text: string, replacements: TextReplacements): string => {
    let replacedText = text || '';
    // replacements 객체에 있는 각각의 키를 기준으로 텍스트를 대체합니다.
    Object.keys(replacements).forEach((key) => {
      const pattern = new RegExp(`\\$${key}\\$`, 'g');
      replacedText = replacedText.replace(pattern, replacements[key]);
    });

    return replacedText;
  };
  const replacements: TextReplacements = {
    이름: name,
    기업명: companyName,
    강점1: matchReportModel.matchReportPositionsDetail?.competencyAnalysis.strengths[0].keyword || '',
    강점2: matchReportModel.matchReportPositionsDetail?.competencyAnalysis.strengths[1].keyword || '',
    강점3: matchReportModel.matchReportPositionsDetail?.competencyAnalysis.strengths[2].keyword || '',
  };

  useEffect(() => {
    if (!messageRef.current) return;
    setSvgHeight(messageRef.current?.clientHeight ? messageRef.current?.clientHeight + 20 : 0);
    setSvgWidth(messageRef.current?.clientWidth ? messageRef.current?.clientWidth + 20 : 0);
  }, [messageRef, matchReportModel.matchReportCompanyInformation]);
  const isSpecialMessagePresent = matchReportModel.matchReportCompanyInformation
  && (
    matchReportModel.matchReportCompanyInformation.specialMessage.greeting.trim() !== ''
    || matchReportModel.matchReportCompanyInformation.specialMessage.companyIntroduction.trim() !== ''
    || matchReportModel.matchReportCompanyInformation.specialMessage.applicantSpecialMessage.trim() !== ''
    || matchReportModel.matchReportCompanyInformation.specialMessage.closingRemark.trim() !== ''
  );

  if (!isSpecialMessagePresent) {
    return null;
  }

  return (
    <TotalFrame>
      {matchReportModel.matchReportCompanyInformation && (
      <Frame>
        <div className='title'>{login.userInfo?.name}님을 위한<br />특별한 메시지가 도착했어요.</div>
        <SpacingBlock vertical size={32} />
        <SpecialMessageWrapper>
          <div className='svg'>
            <TextWrapperSvg height={svgHeight} width={svgWidth} />
          </div>
          <SpecialMessageArea>
            <div className='specialMessage' ref={messageRef}>
              <img src={IconMessage} alt='icon' className='icon' />
              <div className='company-name'>{companyModel.companyHeader?.companyHeaderDto.name}</div>
              <div className='small-divider' />
              <div className='message-frame'>
                <div className='intro'>{replaceTextVariables(matchReportModel.matchReportCompanyInformation.specialMessage.greeting, replacements)}</div>
                <SpacingBlock vertical size={24} />
                <div className='company-desc'>
                  { replaceTextVariables(matchReportModel.matchReportCompanyInformation.specialMessage.companyIntroduction, replacements)}
                </div>
                <SpacingBlock vertical size={24} />
                <div className='background-message'>{replaceTextVariables(matchReportModel.matchReportCompanyInformation.specialMessage.applicantSpecialMessage, replacements)}</div>
                <SpacingBlock vertical size={24} />
                <div className='last-message'>{replaceTextVariables(matchReportModel.matchReportCompanyInformation.specialMessage.closingRemark, replacements)}</div>
              </div>
            </div>
          </SpecialMessageArea>
        </SpecialMessageWrapper>

      </Frame>
      )}
    </TotalFrame>

  );
};
export default inject(injectStore.matchReportModel, injectStore.login, injectStore.companyModel)(observer(CompanyReportSpecialMessage));
