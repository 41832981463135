import { MatchMetaTag } from 'components/_v2/_common/meta/MetaTag';
import MyPageApplyTab from 'components/_v2/mypage/MyPageApplyTab';
import React from 'react';
import styled from 'styled-components';

const Frame = styled.div`

`;
const MypageHome = () => (
  <Frame>
    <MatchMetaTag />
    <MyPageApplyTab />
  </Frame>
);

export default MypageHome;
