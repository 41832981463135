import React, { useState } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import Fonts from '__designkit__/common/fonts';
import colors from '__designkit__/common/colors';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import joyFixService from 'assets/character/joy_fix_service.svg';
import { DateFormatYYYYMMDDHHMMWithDot } from 'utils/DateUtils';

const Frame = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 56px);
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  height: fit-content;
  text-align: center;
  white-space: pre-wrap;

  h1 {
    font: ${Fonts.H5_Bold};
    color: ${colors.JOBDA_BLACK};
  }

  p {
    font: ${Fonts.B3_Medium_P};
    color: ${colors.CG_70};
  }
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: fit-content;

  & > li:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const ListItem = styled.li`
  & > div.title {
    display: inline-block;
    min-width: 88px;
    padding: 8px 12px;
    margin-right: 16px;
    border-radius: 4px;
    background-color: ${colors.JOBDA_BLACK};
    font: ${Fonts.B3_Bold};
    color: ${colors.WHITE_100};
  }

  & > div.content {
    display: inline-block;
    width: fit-content;
    font: ${Fonts.B3_Medium_P};
    color: ${colors.CG_80};
  }
`;

const ServiceUnavailable = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [defaultStartDateTime, defaultEndDateTime] = ['2023.11.08 01:00', '2023.11.08 08:00'];
  const [startDateTime] = useState<string | null>(params.get('startDateTime'));
  const [endDateTime] = useState<string | null>(params.get('endDateTime'));

  return (
    <Frame>
      <Contents>
        <img src={joyFixService} alt='작업중인 조이 사진' />
        <SpacingBlock size={16} vertical />
        <h1>잡다 홈페이지 서비스 중단 안내</h1>
        <SpacingBlock size={8} vertical />
        <p>{`서비스 업데이트로 인해 잡다 전체 서비스가 일시적으로 중단됩니다.\n이용자 분들의 양해 부탁드립니다.`}</p>
        <SpacingBlock size={24} vertical />
        <List>
          <ListItem>
            <div className='title'>작업 내용</div>
            <div className='content'>서비스 업데이트</div>
          </ListItem>
          <ListItem>
            <div className='title'>중단 서비스</div>
            <div className='content'>JOBDA 서비스 전체</div>
          </ListItem>
          <ListItem>
            <div className='title'>중단 일시</div>
            <div className='content'>{ `${startDateTime && endDateTime ? DateFormatYYYYMMDDHHMMWithDot(startDateTime) : defaultStartDateTime} ~ ${startDateTime && endDateTime ? DateFormatYYYYMMDDHHMMWithDot(endDateTime) : defaultEndDateTime}` }</div>
          </ListItem>
        </List>
        <SpacingBlock size={24} vertical />
        <p>* 위의 일정은 작업 상황에 따라 변경될 수 있습니다.</p>
      </Contents>
    </Frame>
  );
};

export default ServiceUnavailable;
