import { ReactComponent as IconError } from 'assets/icon/icon_error_32_Regular.svg';
import PartershipListModal from 'components/myPage/MyPagePartershipListModal';
import RoutePaths from 'consts/RoutePaths';
import colors from '__designkit__/common/colors';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Fonts from '__designkit__/common/fonts';

const Frame = styled.div`
  background: ${colors.JOBDA_WHITE};
  width: 100%;
  height: 100%;
  >div{
    position: relative;
    padding-top:180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .icon-error{
      margin-bottom: 16px;
    }
  h1{
    text-align: center;
    margin-bottom:32px;
    font:${Fonts.H4_Bold}
  }
  
  .apply{
    background-color: ${colors.JOBDA_BLACK};
    color:${colors.JOBDA_WHITE};
    font:${Fonts.B1_Bold};
    width: 264px;
    margin-bottom:16px;
    border-radius: 4px;
    padding:14px 0;
  }
  
  .list{
    background-color: ${colors.JOBDA_WHITE};
    color:${colors.JOBDA_BLACK};
    border: 1px solid ${colors.JOBDA_BLACK};
    font:${Fonts.B1_Bold};
    width: 264px;
    border-radius: 4px;
    padding:14px 0;
  }
}
`;

const InfoNotPartnership: FC = () => {
  const history = useHistory();
  const [openList, setOpenList] = useState<boolean>(false);
  return (
    <Frame>
      <div>
        <IconError className='icon-error' width={64} height={64} />
        <h1>제휴서비스 인증이 필요해요
        </h1>
        <button className='apply' onClick={() => history.push(RoutePaths.mypage_partnership)}>제휴서비스 신청하기</button>
        <button className='list' onClick={() => setOpenList(true)}>제휴리스트 확인하기</button>
      </div>
      <PartershipListModal
        isOpen={openList}
        onClose={() => setOpenList(false)}
      />
    </Frame>
  );
};

export default InfoNotPartnership;
