import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Frame = styled.div<{fontSize?:number}>`
  .wrap {
  display: flex;
}

.countWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: ${(props) => (props.fontSize ? `${props.fontSize}px` : `20px`)};
}

.count {
  overflow: hidden;
  display: flex;
  height: 100%;
  padding-left: 1px;
  font-family: 'Pretendard', sans-serif;
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : `20px`)};
  font-weight: 700;
  line-height: 20px;
  color: #121619;
}

.number {
  height: ${(props) => (props.fontSize ? `${props.fontSize * 10}px` : `20px`)};
  transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  

  .digit {
    display: block;
    width: ${(props) => (props.fontSize ? `${props.fontSize * 0.6}px` : `10px`)};;
    height: ${(props) => (props.fontSize ? `${props.fontSize}px` : `20px`)};
    text-align: center;
    overflow: hidden;
  }
}

`;
interface NumberProps {
  num: number;
  delay: number;
  idx:number;
  visible?:boolean;
  fontSize?:number;
}

const Number = (props: NumberProps) => {
  const { num, delay, idx, visible, fontSize } = props;
  const [style, setStyle] = useState({ transform: `translateY(${fontSize})` });

  useEffect(() => {
    setTimeout(() => {
      if (delay === 0) {
        setStyle({ transform: fontSize ? `translateY(-${2 * fontSize}px)` : `translateY(-${2 * 20})` });
      } else

      if (delay === 100) {
        setStyle({ transform: fontSize ? `translateY(-${3 * fontSize}px)` : `translateY(-${3 * 20})` });
      } else
      if (delay === 200) {
        setStyle({ transform: fontSize ? `translateY(-${1 * fontSize}px)` : `translateY(-${1 * 20})` });
      } else
      if (delay === 300) {
        setStyle({ transform: fontSize ? `translateY(-${1 * fontSize}px)` : `translateY(-${1 * 20})` });
      }
      if (delay === 400) {
        setStyle({ transform: fontSize ? `translateY(-${1 * fontSize}px)` : `translateY(-${1 * 20})` });
      } else
      if (delay === 500) {
        setStyle({ transform: fontSize ? `translateY(-${1 * fontSize}px)` : `translateY(-${1 * 20})` });
      }
      if (delay === 600) {
        setStyle({ transform: fontSize ? `translateY(-${1 * fontSize}px)` : `translateY(-${1 * 20})` });
      } else
      if (delay === 700) {
        setStyle({ transform: fontSize ? `translateY(-${1 * fontSize}px)` : `translateY(-${1 * 20})` });
      }
      if (delay === 800) {
        setStyle({ transform: fontSize ? `translateY(-${1 * fontSize}px)` : `translateY(-${1 * 20})` });
      }
    }, delay);
  }, [num]);

  let digitEl:any[] = [];
  if (delay === 0) {
    digitEl = [num - 2, num - 1, num].map((value) => <span key={value} className='digit'>{value < 0 ? value + 10 : value}</span>);
  }

  if (delay === 100) {
    digitEl = [num - 3, num - 2, num - 1, num].map((value) => <span key={value} className='digit'>{value < 0 ? value + 10 : value}</span>);
  }

  if (delay === 200) {
    digitEl = [num - 1, num].map((value) => <span key={value} className='digit'>{value < 0 ? value + 10 : value}</span>);
  }

  if (delay === 300) {
    digitEl = [num - 1, num].map((value) => <span key={value} className='digit'>{value < 0 ? value + 10 : value}</span>);
  }
  if (delay === 400) {
    digitEl = [num - 1, num].map((value) => <span key={value} className='digit'>{value < 0 ? value + 10 : value}</span>);
  }

  if (delay === 500) {
    digitEl = [num - 1, num].map((value) => <span key={value} className='digit'>{value < 0 ? value + 10 : value}</span>);
  }

  if (delay === 600) {
    digitEl = [num - 1, num].map((value) => <span key={value} className='digit'>{value < 0 ? value + 10 : value}</span>);
  }

  if (delay === 700) {
    digitEl = [num - 1, num].map((value) => <span key={value} className='digit'>{value < 0 ? value + 10 : value}</span>);
  }

  if (delay === 800) {
    digitEl = [num - 1, num].map((value) => <span key={value} className='digit'>{value < 0 ? value + 10 : value}</span>);
  }
  return (
    <div className='number' style={visible ? style : {}}>
      <div className={`${visible ? 'visible' : ''}`} />
      {digitEl}
    </div>
  );
};

interface CountProps {
  count?: number;
  visible?:boolean;
  fontSize?:number;
}

const CountUp = ({ count = 0, visible, fontSize }: CountProps) => {
  const countArr = count.toString().split('');
  const decimalIndex = countArr.indexOf('.');

  // 만약 소수점이 있다면, count array를 조정
  if (decimalIndex !== -1) {
    // 소수점을 제거
    countArr.splice(decimalIndex, 1);
    // 소수점이 가장 앞에 있었다면 0을 삽입
    if (decimalIndex === 0) {
      countArr.unshift('0');
    }
  }

  const arr = Array.from({ length: countArr.length }, () => '0');
  for (let i = 0; i < countArr.length; i++) {
    arr[arr.length - countArr.length + i] = countArr[i];
  }

  const NumberEl = arr.map((num, index) => <Number fontSize={fontSize} visible={visible} key={index} num={parseInt(num, 10)} delay={(arr.length - 1 - index) * 100} idx={(arr.length - 1 - index)} />);

  // 만약 소수점이 있었다면, 다시 소수점을 삽입
  if (decimalIndex !== -1) {
    NumberEl.splice(decimalIndex, 0, <div>.</div>);
  } else {
    if (NumberEl.length > 3) {
      NumberEl.splice(-3, 0, <div>,</div>);
    }
    if (NumberEl.length > 7) {
      NumberEl.splice(-7, 0, <div>,</div>);
    }
  }

  return (
    <Frame fontSize={fontSize}>
      <span className='countWrap'>
        <div className='count'>
          {NumberEl}
        </div>
      </span>
    </Frame>
  );
};

export default CountUp;
