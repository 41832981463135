import classnames from 'classnames/bind';
import { FC, useEffect, useState } from 'react';
import style from './index.module.scss';

const cx = classnames.bind(style);

interface PreviewStepProps {
  fadeInFirst: boolean;
  fadeInSecond: boolean;
  setFirstSectionRef: (node: Element | null) => void;
  setSecondSectionRef: (node: Element | null) => void;
}

const InterviewPracticeList: FC<PreviewStepProps> = ({
  fadeInFirst,
  fadeInSecond,
  setSecondSectionRef,
  setFirstSectionRef,
}) => {
  const fullText = '원하는 질문을 작성해주세요.';
  const [text, setText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (!isDeleting && text === fullText) {
      timer = setTimeout(() => setIsDeleting(true), 2000);
    } else if (isDeleting && text === '') {
      setIsDeleting(false);
    }

    if (!isDeleting && text !== fullText) {
      timer = setTimeout(() => {
        setText(fullText.slice(0, text.length + 1));
      }, 150);
    } else if (isDeleting) {
      timer = setTimeout(() => {
        setText(fullText.slice(0, text.length - 1));
      }, 50);
    }

    return () => clearTimeout(timer);
  }, [text, isDeleting, fullText]);

  return (
    <div className={cx('wrap')}>
      <div className={cx('mainTitle')}>
        면접 준비 과정에서
        <br />
        도움이 필요하신가요?
      </div>

      <div className={cx('listWrap')}>
        <div className={cx('list')}>
          <div className={cx('contentTitle')}>
            <div className={cx('title')}>기업별 질문</div>
            <div className={cx('subTitle')}>
              원하는 기업을 선택해 해당 기업의
              <br />
              면접 질문 리스트를 확인하고 연습할 수 있어요.
            </div>
          </div>
          <div className={cx('logoList')}>
            <ul className={cx('logoFlow')}>
              <li className={cx('logoImg')} aria-label='logo' />
              <li className={cx('logoImg')} aria-label='logo' />
              <li className={cx('logoImg')} aria-label='logo' />
            </ul>
          </div>
        </div>
        <div className={cx('list')}>
          <div className={cx('contentTitle')}>
            <div className={cx('title')}>빈출 면접 질문</div>
            <div className={cx('subTitle')}>
              약 10,000개 면접 질문에 숨겨진 의도를
              <br />
              잡다가 다 알려 드립니다!
            </div>
          </div>
          <div ref={setFirstSectionRef}>
            <div className={cx('questionList', { on: fadeInFirst })}>
              <div className={cx('question')} />
              <div className={cx('question')} />
              <div className={cx('question')} />
            </div>
          </div>
        </div>
        <div ref={setSecondSectionRef} className={cx('list')}>
          <div className={cx('contentTitle', { on: fadeInSecond })}>
            <div className={cx('title')}>역량검사 결과 기반 질문</div>
            <div className={cx('subTitle')}>
              응시자님의 역량 강점과 약점 관련된 대표적인
              <br />
              예상 면접 질문과 가이드를 제공합니다.
            </div>
          </div>
        </div>
        <div className={cx('list')}>
          <div className={cx('contentTitle')}>
            <div className={cx('title')}>내가 만든 질문</div>
            <div className={cx('subTitle')}>
              지원자가 원하는 질문을 직접
              <br />
              커스터마이징하여 면접 연습을 할 수 있습니다.
            </div>
          </div>
          <div className={cx('questionMade')}>
            {text}
            <span className={cx('blinking-cursor')}>|</span>
          </div>
        </div>
      </div>

      <div className={cx('subInfo')}>제휴 기관에 따라 제공되는 기능이 다를 수 있습니다.</div>
    </div>
  );
};

export default InterviewPracticeList;
