import React from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components/macro';
import MyPageProfile from 'components/myPage/MyPageProfile';
import { Link, useHistory } from 'react-router-dom';
import colors from '__designkit__/common/colors';
import RoutePaths from 'consts/RoutePaths';
import iconRightSimpleRound20Regular from 'assets/icon/icon_right_simple_round_20_Regular.svg';
import { absoluteVerticalCenter } from 'consts/style/mixins';
import ILoginPageProps from 'interfaces/props/ILoginPageProps';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import Fonts from '__designkit__/common/fonts';

const Frame = styled.div`
  .menu {
    margin-top:8px;
  }
  .menu-item {
    position:relative;
    min-height:56px;
    padding:18px;
    font: ${Fonts.B1_Medium};
    color:${colors.CG_90};
    &:after {
      content:'';
      width:20px;
      height:20px;
      background:url(${iconRightSimpleRound20Regular});
      background-repeat:no-repeat;
      position:absolute;
      right:18px;
      ${absoluteVerticalCenter()};
    }
  }
  .menu-logout {
    margin-left:18px;
    margin-top:17px;
    font: ${Fonts.B2_Medium};
    color:${colors.CG_60};
    background:none;
    width:fit-content;
    height:fit-content;
  }
`;

const MyPage: React.FC<ILoginPageProps> = ({ login = new Login() }) => {
  const history = useHistory();
  return (
    <Frame>
      <MyPageProfile />
      {/* 프로필로 통일 예정 23/09.12 */}
      {/* <Link to={RoutePaths.mypage_basic}>
        <article className='menu-item'>
          기본정보 관리
        </article>
      </Link> */}
      <Link to={RoutePaths.mypage_account}>
        <article className='menu-item'>
          계정정보 관리
        </article>
      </Link>
      <Link to={RoutePaths.mypage_partnership}>
        <article className='menu-item'>
          제휴서비스 신청 관리
        </article>
      </Link>
      <Link to={RoutePaths.mypage_notice}>
        <article className='menu-item'>
          알림 관리
        </article>
      </Link>
      <button
        className='menu-logout'
        onClick={async () => {
          await login.logout();
          history.push(RoutePaths.root);
        }}
      >
        로그아웃
      </button>
    </Frame>
  );
};

export default inject(injectStore.login)(observer(MyPage));
