import { Preferences } from '@capacitor/preferences';
import jsCookie from 'js-cookie';
import MobileStore from 'store/mobileStore';

const PREFIX_KEY = 'render_check_';

class RenderCheckUtil {
  // limitDate일만큼 렌더링 하지 않음, 1일 경우 24시간이 아닌 오늘을 의미함.
  static setRenderCheck = async (key: string, limitDate: number) => {
    const today = new Date().setHours(0, 0, 0, 0);
    const deadline = new Date(today + 1000 * 60 * 60 * 24 * limitDate);
    if (MobileStore.isMobile) {
      await Preferences.set({ key: PREFIX_KEY + key, value: String(deadline) });
    } else {
      jsCookie.set(PREFIX_KEY + key, String(deadline), { expires: deadline });
    }
  };

  // true 반환되면 렌더링 하지 않음, false 반환되면 렌더링 해야 함
  static getRenderCheck = async (key: string) => {
    if (MobileStore.isMobile) {
      const { value } = await Preferences.get({ key: PREFIX_KEY + key });
      if (value) {
        const deadline = new Date(value);
        if (new Date() <= deadline) return true;
      }
    } else {
      const value = jsCookie.get(PREFIX_KEY + key);
      if (value) {
        const deadline = new Date(value);
        if (new Date() <= deadline) return true;
      }
    }
    return false;
  }
}

export default RenderCheckUtil;
