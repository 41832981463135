import ContentCard from '__designkit__/card/ContentCard';
import JDSwiper, { JDSwiperSlide } from 'components/_v2/_common/JDSwiper';
import RoutePaths from 'consts/RoutePaths';
import Fonts from '__designkit__/common/fonts';
import colors from '__designkit__/common/colors';
import { IAccCenterPost } from 'interfaces/_v2/acc/IAccGate';
import { inject, observer } from 'mobx-react';
import AccaGateModel from 'models/_v2/acca/AccaGateModel';
import { injectStore } from 'models/store';
import { IAccaGateProps } from 'pages/_v2/acca/AccaGate';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
  padding: 32px 0 40px;

  background: ${colors.WHITE_100};

  >h1{
    font: ${Fonts.H4_Bold};
    color:${colors.JOBDA_BLACK};
    padding-left: 16px;
    margin-bottom: 20px;
  }

  >div{
    display: flex;
    justify-content: space-between;
    margin-bottom:20px;
    >h2{
      font: ${Fonts.B2_Medium};
      color:${colors.CG_70};
    }

    >button{
      display: flex;
      font: ${Fonts.B3_Medium};
      color:${colors.CG_70};
    }
  }

  .content-swiper {
    margin-bottom: 0;
  }

  .content-card{
    width: 240px;
    height:289px;

  }

`;

const AccaGateSuccessContent: FC<IAccaGateProps> = ({ accaGateModel = new AccaGateModel() }) => {
  useEffect(() => {
    const init = async () => {
      await accaGateModel.loadAccCenterScreeningPassReviews();
    };
    init();
  }, []);

  return (
    <>
      <Frame>
        <h1>역량검사 합격 후기 모음</h1>
        {accaGateModel.reviewPost && accaGateModel.reviewPost.length > 0
        && (
        <JDSwiper
          className='content-swiper'
          slidesPerView='auto'
          spaceBetween={12}
          slidesOffsetBefore={16}
          slidesOffsetAfter={16}
        >
          {accaGateModel.reviewPost && accaGateModel.reviewPost.length > 0
          && accaGateModel.reviewPost.map((item:IAccCenterPost) => (
            <JDSwiperSlide key={item.sn} className='content-card'>
              <ContentCard
                item={{
                  title: item.title,
                  category: item.categoryName,
                  label: item.label,
                  image: item.thumbnailUrl,
                  bannerLink: RoutePaths.info_detail(item.sn),
                }}
              />
            </JDSwiperSlide>
          ))}
        </JDSwiper>
        )}
      </Frame>
    </>
  );
};

export default inject(injectStore.accaGateModel)(observer(AccaGateSuccessContent));
