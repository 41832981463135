import JDBaseModal, { IJDBaseModal } from 'components/_v2/_common/modals/JDBaseModal';
import { JDModalBasicFrame, JDModalDescription, JDModalFullButton, JDModalTitle } from 'components/_v2/_common/modals/JDModalComponents';
import RoutePaths from 'consts/RoutePaths';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Frame = styled.div`

    ${JDModalDescription} {
        margin-top: 8px;
    }
`;

const PositionApplyFirstModal:FC<IJDBaseModal> = ({ isOpen, onClickClose }) => (
  <JDBaseModal dimmed isOpen={isOpen} onClickClose={onClickClose} isDimClickClose>
    <Frame>
      <JDModalBasicFrame>
        <JDModalTitle>첫 입사 지원을 축하드립니다.<br />지원자님의 취뽀를 응원합니다.</JDModalTitle>
        <JDModalDescription>{`이후 과정은 [지원현황] > 지원 현황에서`}<br />확인할 수 있어요.</JDModalDescription>
        <Link to={RoutePaths.match_apply}>
          <JDModalFullButton>지원 현황 바로가기</JDModalFullButton>
        </Link>
      </JDModalBasicFrame>
    </Frame>
  </JDBaseModal>
);

export default PositionApplyFirstModal;
