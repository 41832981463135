const get종성 = (str: string) => {
  const cJongs = ['', 'ㄱ', 'ㄲ', 'ㄳ', 'ㄴ', 'ㄵ', 'ㄶ', 'ㄷ', 'ㄹ', 'ㄺ', 'ㄻ', 'ㄼ', 'ㄽ', 'ㄾ', 'ㄿ', 'ㅀ', 'ㅁ', 'ㅂ', 'ㅄ', 'ㅅ', 'ㅆ', 'ㅇ', 'ㅈ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ'];
  const char = str.charAt(str.length - 1);
  const cCode = char.charCodeAt(0) - 0xAC00;
  const jong = cCode % 28; // 종성 index
  return cJongs[jong];
};

export const get은or는 = (str: string) => {
  const jong = get종성(str);
  return jong === '' ? '는' : '은';
};

export const get이or가 = (str: string) => {
  const jong = get종성(str);
  return jong === '' ? '가' : '이';
};

export const get을or를 = (str: string) => {
  const jong = get종성(str);
  return jong === '' ? '를' : '을';
};

export const get란or이란 = (str: string) => {
  const jong = get종성(str);
  return jong === '' ? '란' : '이란';
};

export const get와or과 = (str: string) => {
  const jong = get종성(str);
  return jong === '' ? '와' : '과';
};

export const get로or으로 = (str: string) => {
  const jong = get종성(str);
  return jong === '' ? '로' : '으로';
};
