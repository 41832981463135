import ImgWifi from 'assets/_v2/builder/icon_wifi_off.png';
import JDBaseModal, { IJDBaseModal } from 'components/_v2/_common/modals/JDBaseModal';
import { JDModalBasicFrame, JDModalTitle } from 'components/_v2/_common/modals/JDModalComponents';
import { IconClose } from 'consts/assets/icons/iconPages';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import React, { FC } from 'react';
import styled from 'styled-components';
import SupportMail from 'consts/_v2/GlobalText';

const Frame = styled.div`

.icon{
    align-self: center;
    width: 96px;
    height: 96px;
}
.select {
    position: relative;
    margin-top: 16px;
    border: 1px solid $C_COOL_GRAY_30;
    border-radius: 4px;
    }
.close-btn{
    z-index:1;
    position: absolute;
    right: 20px;
    top:20px;
    margin-bottom: 8px;
    }   
    .confirm-button{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    margin-top: 20px;
    background-color:${colors.JOBDA_BLACK};
    font: ${Fonts.B2_Bold};
    color:${colors.WHITE_100};
    border-radius: 4px;
    :disabled{
        color:${colors.CG_60};
        background-color: ${colors.CG_40};
    }
}
    .sub-text{
        margin-top: 8px;
        align-self: center;
        font: ${Fonts.B2_Medium_P};
        color:${colors.CG_70};
    }
    .sub-link-text{
        align-self: center;
        font: ${Fonts.B2_Medium_P};
        color:${colors.CG_70};
        .link-text{
            color:${colors.B_100};
        }
    }
`;

const BuilderAtsErrorModal:FC<IJDBaseModal> = ({ isOpen, onClickClose, onSuccess }) => (
  <JDBaseModal dimmed isOpen={isOpen} onClickClose={onClickClose}>
    <Frame>
      <JDModalBasicFrame>
        <button className='close-btn' onClick={onClickClose}><IconClose /></button>
        <img src={ImgWifi} className='icon' alt='icon' />
        <JDModalTitle>서버에 문제가 발생하였습니다.</JDModalTitle>
        <div className='sub-text'>
          문제가 지속될 시, <br />
        </div>
        <div className='sub-link-text'><a className='link-text' href={`mailto:${SupportMail}`}>{SupportMail}</a>를 통해 문의해 주세요.
        </div>

        <button onClick={onClickClose} className='confirm-button'>
          확인
        </button>
      </JDModalBasicFrame>
    </Frame>
  </JDBaseModal>

);

export default BuilderAtsErrorModal;
