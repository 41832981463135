export interface ReactSelectOption<T = string | number> {
  value: T;
  label: string;
  __isNew__?: boolean;
  clearableValue?: boolean;
}

export type LoadOptions = <T = ReactSelectOption>(options: readonly T[]) => void;

export const getOptions = <K extends string>(nameMap: Record<K, string>): ReactSelectOption<K>[] => Object.entries(nameMap).map(
  ([value, label]) => ({
    label,
    value,
  } as ReactSelectOption<K>),
);
