import React from 'react';
import RoutePaths from 'consts/RoutePaths';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Fonts from '__designkit__/common/fonts';
import colors from '__designkit__/common/colors';
import shadows from '__designkit__/common/shadows';

const LinkMenuTab = styled.nav`
    display: flex;
    align-items: center;
    gap: 24px;
    padding: 16px 16px 0 16px;
    background-color: ${colors.WHITE_100};
    ${shadows.headers};
  
    .a-link {
        position:relative;
        width:fit-content;
        font: ${Fonts.B2_Medium};
        color: ${colors.CG_60};
        padding-bottom: 16px;
        
        &[aria-current=true] {
            font: ${Fonts.B2_Bold};
            color:${colors.JOBDA_BLACK};
        }

        &[aria-current=true]:before {
          display:block;
          position:absolute;
          content:'';
          bottom: 0;
          left:0;
          width:100%;
          height:2px;
          background:${colors.JOBDA_BLACK};
        }
    }


`;

const PassboardTab = () => {
  const { pathname } = useLocation();

  return (
    <>
      <LinkMenuTab>
        <Link className='a-link' aria-current={pathname === RoutePaths.info} to={RoutePaths.info}>취업 컨텐츠</Link>
        <Link className='a-link' aria-current={pathname === RoutePaths.passboard} to={RoutePaths.passboard}>합격 후기</Link>
      </LinkMenuTab>
    </>
  );
};

export default PassboardTab;
