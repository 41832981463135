/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable dot-notation */
import { ReactComponent as IconArrowDownBig } from 'assets/_v2/_common/icon_arrow_down_12.svg';
import { ReactComponent as IconArrowUpBig } from 'assets/_v2/_common/icon_arrow_up_12.svg';
import { Divider12Grey, Divider1G40 } from 'components/divider/Divider';
import React, { FC, MouseEvent, forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import {
  BaseEditor, createEditor,
  Descendant,
  Element,
} from 'slate';
import { Editable, ReactEditor, RenderElementProps, RenderLeafProps, Slate, withReact } from 'slate-react';
import styled from 'styled-components';
import Fonts from '__designkit__/common/fonts';
import colors from '__designkit__/common/colors';
import JDALink from 'components/JDALink';
import styles from './PositionJdContent.module.css';

type CustomElement = {
  type: BlockFormatType;
    subType?: string;
    children: CustomElement[] | CustomText[];
    label?: string;
    url?: string;
    link?: string;
    size?: 'normal' | 'large';
}

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

type BlockFormatType =
  | 'title'
  | 'sub-title'
  | 'bulleted-list'
  | 'numbered-list'
  | 'list-item'
  | 'paragraph'
  | 'image'
  | 't1'
  | 't2'
  | 't3'
  | 'embed';

type CustomText = {
  text: string;
  bold?: boolean;
  highlight?: boolean;
  underline?: boolean;
  link?: string;
  label?: string;
};

type Props = {
  value: Descendant[];
  placeholder?: string;
  positionJd?:boolean;
  companyDetail?:boolean;
  companyName?: string;
};

const DividerFrame = styled.div`
    display: flex;
    margin-top: 36px;
    .divider-24{
            width:100%;
    }
`;

const Frame = styled.div<{folded?:boolean, folder?:boolean}>`
    padding: 32px 16px;
    height:${(props) => (props.folded && props.folder ? `223px` : 'auto')};
    overflow:hidden;

    .company-name {
      font: ${Fonts.H4_Bold};
      color: ${colors.JOBDA_BLACK};
    }
`;
const ButtonFrame = styled.div<{folded?:boolean}>`
position:relative;
    padding:0 16px;
    .dimmed {
    position: absolute;
    width: 400px;
    height: 100%;
    left: -1px;
    bottom: 64px;
    background: ${(props) => (props.folded
    ? `linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);`
    : ``)};
  }
`;
const AllViewButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 12px 0;
  margin-top: 24px;
  font: ${Fonts.B3_Bold};
  color: ${colors.JOBDA_BLACK};
  border: 1px solid ${colors.JOBDA_BLACK};
  border-radius: 4px;
  .icon-arrow {
    margin-left: 6px;
  }
`;

const PositionJdContent: FC<Props> = ({ value, placeholder, positionJd, companyDetail, companyName }) => {
  const editor = useMemo(() => withReact(createEditor() as ReactEditor), []);
  const [folded, setFolded] = useState<boolean>(!!companyDetail);
  const [folder, setFolder] = useState<boolean>(false);
  useEffect(() => {
    if (companyDetail && document.getElementsByClassName('big-frame')[0].clientHeight !== undefined) {
      const elHeight:number = document.getElementsByClassName('big-frame')[0].clientHeight;
      if (elHeight < 223) {
        setFolder(false);
      } else {
        setFolder(true);
      }
    }
  }, [companyDetail]);
  const foldHandler = () => {
    setFolded(!folded);
  };

  const renderElement = useCallback(
    (props: RenderElementProps) => <Elements {...props} />,
    [],
  );
  const renderLeaf = useCallback(
    (props: RenderLeafProps) => <Leaf {...props} />,
    [],
  );
  return (
    <>
      {positionJd && <Divider12Grey />}
      <Frame className='big-frame' folded={folded} folder={folder}>
        {companyDetail && companyName && (
          <p className='company-name'>{companyName} 소개</p>
        )}
        <Slate
          editor={editor}
          value={value}
          onChange={() => {}}
        >
          <Editable
            renderPlaceholder={({ children, attributes }) => (
              <span {...attributes} className={styles.placeholder}>
                {children}
              </span>
            )}
            placeholder={placeholder}
            className={styles.editor}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            readOnly
          />
        </Slate>

      </Frame>
      {companyDetail
              && folder && (
              <ButtonFrame folded={folded}>
                <div className='dimmed' />

                <AllViewButton onClick={foldHandler}>
                  {folded ? '소개 내용 모두보기' : '접기'}
                  {folded ? (
                    <IconArrowDownBig className='icon-arrow' />
                  ) : (
                    <IconArrowUpBig className='icon-arrow' />
                  )}
                </AllViewButton>
              </ButtonFrame>
      )}
      {positionJd
        && (
        <DividerFrame>
          <Divider1G40 className='divider-24' />
        </DividerFrame>
        )}

    </>
  );
};
// eslint-disable-next-line
const ImageEditorViewElement = forwardRef<
    HTMLDivElement,
    Omit<RenderElementProps, 'attributes'> & {
    isSelected?: boolean;
    handleImageClick?:(e: MouseEvent<HTMLImageElement>) => void;
    refToImage?: (node: HTMLImageElement) => void;
    editorViewMode?: boolean;
      }
      >(({ children, element, isSelected, handleImageClick, editorViewMode, refToImage }, ref) => {
        return (
          <>
            <div
              className={styles['image-block']}
              onClick={(e) => {
                // e.preventDefault();
              }}
              ref={ref}
              contentEditable={false}
              role='button'
            >
              {element.link && !editorViewMode ? (
                <a href={element.link} target={'_blank'} rel='noreferrer'>
                  <img
                    src={element.url}
                    alt={element.label}
                    style={{
                      display: 'block',
                      outline: isSelected ? '1px solid black' : 'none',
                      outlineOffset: -1,
                    }}
                    onClick={(e) => {
                      handleImageClick?.(e);
                    }}
                    ref={refToImage}
                  />
                </a>
              ) : (
                <img
                  src={element.url}
                  alt={element.label}
                  style={{ display: 'block', border: isSelected ? '1px solid #000' : 'none' }}
                  onClick={(e) => {
                    handleImageClick?.(e);
                  }}
                  ref={refToImage}
                />
              )}
            </div>
            <div style={{ display: 'none' }}>{children}</div>
          </>
        );
      });

ImageEditorViewElement.displayName = 'ImageEditorViewElement';
// TYPESCRIPT 버전 문제로 바로 property 바로 접근 안하고 해결했습니다.
const Elements = ({ attributes, children, element }: RenderElementProps) => {
  // switch (element.type) {
  //   case 'title':
  //     return <h1 {...attributes}>{children}</h1>;
  //   case 'sub-title':
  //     return <h2 {...attributes}>{children}</h2>;
  //   case 'bulleted-list':
  //     return <ul {...attributes}>{children}</ul>;
  //   case 'numbered-list':
  //     return <ol {...attributes}>{children}</ol>;
  //   case 'list-item':
  //     return <li {...attributes}>{children}</li>;
  //   default:
  //     return <p {...attributes}>{children}</p>;
  // }
  if (Element.isElementType(element, 'title')) {
    return <h1 {...attributes}>{children}</h1>;
  } if (Element.isElementType(element, 'sub-title')) {
    return <h2 {...attributes}>{children}</h2>;
  } if (Element.isElementType(element, 'bulleted-list')) {
    return <ul {...attributes}>{children}</ul>;
  } if (Element.isElementType(element, 'numbered-list')) {
    return <ol {...attributes}>{children}</ol>;
  } if (Element.isElementType(element, 'list-item')) {
    return <li {...attributes}><div>{children}</div></li>;
  } if (Element.isElementType(element, 'image')) {
    return (
      <div {...attributes} className={styles['image-block']}>
        <ImageEditorViewElement element={element}>{children}</ImageEditorViewElement>
      </div>
    );
  }
  if (Element.isElementType(element, 'color-label')) {
    const customElement = element as CustomElement; // 타입 단언을 사용하여 CustomElement로 캐스팅

    return customElement.size === 'large' ? (
      <h2 className={styles['t2']} {...attributes}>
        {customElement.label}
        {children}
      </h2>
    ) : (
      <span className={styles['color-label']} {...attributes}>
        {customElement.label}
        {children}
      </span>
    );
  }

  if (Element.isElementType(element, 't1')) {
    return (
      <h1 className={styles.t1} {...attributes}>
        {children}
      </h1>
    );
  }
  if (Element.isElementType(element, 't2')) {
    return (
      <h2 className={styles.t2} {...attributes}>
        {children}
      </h2>
    );
  }
  if (Element.isElementType(element, 't3')) {
    return (
      <p className={styles.t3} {...attributes}>
        {children}
      </p>
    );
  }
  if (Element.isElementType(element, 'embed')) {
    const customElement = element as CustomElement; // 타입 단언을 사용하여 CustomElement로 캐스팅

    return (
      <div className={styles['embed']} {...attributes}>
        <iframe
          width='560'
          height='315'
          src={customElement.url}
          frameBorder='0'
          allowFullScreen
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          referrerPolicy='strict-origin-when-cross-origin'
        />
        {children}
      </div>
    );
  }
  return <p {...attributes}>{children}</p>;
};
const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
//   if (leaf.bold) {
//     children = <b>{children}</b>;
//   }

  //   if (leaf.highlight) {
  //     children = <strong>{children}</strong>;
  //   }

  //   if (leaf.underline) {
  //     children = <u>{children}</u>;
  //   }

  //   if (leaf.link) {
  //     children = (
  //       <a onClick={() => window.open(leaf.link, '_blank')}>{children}</a>
  //     );
  //   }

  if (leaf.hasOwnProperty('bold')) {
    children = <b>{children}</b>;
  }

  if (leaf.hasOwnProperty('highlight')) {
    children = <strong>{children}</strong>;
  }
  if (leaf.hasOwnProperty('underline')) {
    children = <u>{children}</u>;
  }
  if (leaf.hasOwnProperty('link')) {
    const linked = leaf.link;
    children = (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <JDALink to={linked || ''}>{children}</JDALink>
    );
  }
  return <span {...attributes}>{children}</span>;
};

export default PositionJdContent;
