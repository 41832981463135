import { MatchLoginConnectInfoModalProps } from 'components/_v2/matchLogin/MatchLoginConnectInfoModal';
import JDBaseModal from 'components/_v2/_common/modals/JDBaseModal';
import { JDModalBasicFrame, JDModalDescription, JDModalFullButton, JDModalTitle } from 'components/_v2/_common/modals/JDModalComponents';
import JDNoHeaderFullModal from 'components/_v2/_common/modals/JDNoHeaderFullModal';
import { IconCheck } from 'consts/assets/icons/iconPages';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import React, { FC } from 'react';
import styled from 'styled-components';
import IconMail from 'assets/_v2/_common/icon_v2_mail.svg';
import SupportMail from 'consts/_v2/GlobalText';

const Frame = styled.div`

    ${JDModalDescription} {
        margin-top: 16px;
    }

    ul {
        margin-bottom: 12px;
    }

    li {
        display: flex;
        justify-content: left;
        align-items: flex-start;

        .icon-check {
            margin-top: 4.5px;
            margin-right: 12px;
        }

        span {
            font: ${Fonts.B2_Medium_P};
            color: ${colors.JOBDA_BLACK};
            text-align: left;
            word-break: break-all;
        }

        .email {
            vertical-align: sub;
            margin-left: 8px;
            font: ${Fonts.B2_Medium_P};
            color: ${colors.JOBDA_BLACK};
            border-bottom: 1px solid #000000;

            div {
                display: inline-flex;
                align-items: center;
                font: ${Fonts.B2_Medium_P};
                img {
                    width: 16px;
                    height: 16px;
                    margin-right: 4px;
                }
            }

           
        }
    }

    li:not(:last-child) {
        margin-bottom: 6px;
    } 

    .tip {
        font: ${Fonts.B3_Medium};
        color: ${colors.B_100};
        text-align: center;
    }
`;

const MatchLoginFailAccountModal: FC<MatchLoginConnectInfoModalProps> = ({ isOpen, onClickClose }) => (
  <JDNoHeaderFullModal isOpen={isOpen} onClose={onClickClose}>
    <JDBaseModal dimmed isOpen={isOpen} onClickClose={onClickClose} isDimClickClose>
      <Frame>
        <JDModalBasicFrame>
          <JDModalTitle>연동할 수 없는 계정입니다.</JDModalTitle>
          <JDModalDescription>
            <ul className='description'>
              <li><IconCheck className='icon-check' /><span>기업에서 작성한 전화번호와 회원님의 계정 전화번호가 다를 경우 연동이 불가능합니다.</span></li>
              <li><IconCheck className='icon-check' />
                <span>오류 해결을 위해
                  <a className='email' href={`mailto:${SupportMail}`}>
                    <div><img src={IconMail} alt='mail' />문의메일</div>
                  </a>로<br /> 연락해 주세요.
                </span>
              </li>
            </ul>
            <span className='tip'>문의 메일 : {SupportMail}</span>
          </JDModalDescription>
          <JDModalFullButton onClick={onClickClose}>확인</JDModalFullButton>
        </JDModalBasicFrame>
      </Frame>
    </JDBaseModal>
  </JDNoHeaderFullModal>
);

export default MatchLoginFailAccountModal;
