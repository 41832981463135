export interface ICategory {
  sn: number;
  name: string;
  priority: number;
  iconType: string;
}

export interface IPage {
  'page': number,
  'size': number,
  'totalPages': number,
  'totalElements': number,
}

export const PostEnum = {
  TEXT: 'TEXT',
  VIDEO: 'VIDEO',
  LIVE: 'LIVE',
  NEW_PAGE: 'NEW_PAGE',
} as const;

export type PostType = typeof PostEnum[keyof typeof PostEnum];

export const PrivacyContentsEnum = {
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE',
  PARTNERSHIP: 'PARTNERSHIP',
  UNLISTED: 'UNLISTED',
  MATCHING_USER: 'MATCHING_USER',
} as const;

export type PrivacyContentsType = typeof PrivacyContentsEnum[keyof typeof PrivacyContentsEnum];

export interface IPost {
  sn: number;
  categoryName: string;
  categoryIconType: string;
  privacy: PrivacyContentsType;
  type: PostType;
  title: string;
  label: string;
  link: string;
  thumbnailUrl: string;
}

export interface ICategoryData {
  id: number;
  name: string;
}
export interface IInfoCardData {
  id: number;
  title: string;
  subTitle: string;
  category: string;
  imageSrc: string;
  categoryType: string;
  type: PostType;
  link?: string;
  privacy: PrivacyContentsType;
}
