import RoutePaths, { spinnerIgnoredURIs } from 'consts/RoutePaths';
import URIs from 'consts/URIs';

export const path2RegExp = (path:string, flags:string = 'g') => new RegExp(path.replace(/\?/g, '\\?').replace(/:[^/]*/g, '.*'), flags);

export const getPathKey = (PathMap:Object, pathname:string) => (
  Object.entries(PathMap).find(([key, routePathname]) => (typeof routePathname === 'function' ? path2RegExp(routePathname()).test(pathname) : routePathname === pathname))
);

export const getURIKey = (pathname:string) => {
  const match = getPathKey(URIs, pathname);
  return match ? match[0] : undefined;
};

export const getRoutePathKey = (pathname:string) => {
  const match = getPathKey(RoutePaths, pathname);
  return match ? match[0] : undefined;
};

export const isSpinnerIgnored = (routeMatch: keyof typeof RoutePaths, uri: string) => (
  spinnerIgnoredURIs[routeMatch as keyof typeof RoutePaths]?.find((requestUri) => (typeof requestUri === 'function' ? path2RegExp(requestUri()).test(uri) : uri === requestUri))
);

export default ({
  path2RegExp,
  getRoutePathKey,
  isSpinnerIgnored,
});
