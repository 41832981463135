import JDBaseModal, { IJDBaseModal } from 'components/_v2/_common/modals/JDBaseModal';
import Fonts from '__designkit__/common/fonts';
import React, { FC } from 'react';
import styled from 'styled-components';
import { windowOpenUtils } from 'utils/_v2/windowOpenUtils';
import SupportMail from 'consts/_v2/GlobalText';
import { JDButtonTwoChoiceFrame, JDModalBasicFrame, JDModalButton, JDModalButtonGhost, JDModalDescription, JDModalTitle } from '../_common/modals/JDModalComponents';

const Frame = styled.div`

    ${JDModalTitle} {
        margin-bottom: 8px;
    }
  ${JDButtonTwoChoiceFrame} {
    flex-direction: column;
    margin-top: 20px;
  }

  ${JDModalButtonGhost} {
    margin-bottom: 8px;
  }

  ${JDModalButton} {
    >a {
      font: ${Fonts.B2_Bold};
    }
  }
`;
const CompanyDetailsEditRequestModal: FC<IJDBaseModal> = ({ isOpen, onClickClose }) => (
  <JDBaseModal dimmed isOpen={isOpen} onClickClose={onClickClose} isDimClickClose isDimCloseBtn>
    <Frame>
      <JDModalBasicFrame>
        <JDModalTitle>기업정보 수정하기</JDModalTitle>
        <JDModalDescription>수정방식을 선택해 주세요.</JDModalDescription>
        <JDButtonTwoChoiceFrame>
          <JDModalButtonGhost onClick={() => {
            onClickClose();
            const url = 'https://jain-membership.hri.link/login?serviceId=ATS';
            windowOpenUtils(url, 'jain-membership');
          }}
          >기업회원 로그인하고 수정하기
          </JDModalButtonGhost>
          <JDModalButton onClick={() => {
            onClickClose();
          }}
          ><a className='email' href={`mailto:${SupportMail}`}>잡다 고객센터에 요청하기</a>
          </JDModalButton>
        </JDButtonTwoChoiceFrame>
      </JDModalBasicFrame>
    </Frame>
  </JDBaseModal>
);

export default CompanyDetailsEditRequestModal;
