/* eslint-disable import/prefer-default-export */
import { useQuery } from 'react-query';
import { RemotePositionRepo } from 'repository/position/PositionRepo';

const keys = ['position'];

const repo = new RemotePositionRepo();

const fetchPositionProfileMatching = async (pageSize: number, hide:boolean) => {
  const { positions } = await repo.fetchPositionProfileMatching(pageSize, hide);
  return positions;
};

export const usePositionProfileMatching = (pageSize: number, hide:boolean) => {
  return useQuery([...keys, 'profile', pageSize], () => fetchPositionProfileMatching(pageSize, hide));
};
