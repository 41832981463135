import { IEventChallengeProps } from '__pc__/page/eventChallenge';
import MarketingConsentModal from '__pc__/page/eventChallenge/MarketingConsentModal';
import classnames from 'classnames/bind';
import JDALink from 'components/JDALink';
import JDSwiper, { JDSwiperSlide, PageNationType } from 'components/_v2/_common/JDSwiper';
import JDSimpleInfoModal from 'components/_v2/_common/modals/JDSimpleInfoModal';
import ErrorCode, { ErrorCodeText } from 'consts/ErrorCodes';
import RoutePaths from 'consts/RoutePaths';
import useToast from 'hooks/useToast';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import EventChallengeModel from 'models/_v2/event/EventChallengeModel';
import { injectStore } from 'models/store';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router';
import styles from './challengeMission1.module.scss';

const cx = classnames.bind(styles);

const ChallengeMission1:FC<IEventChallengeProps> = ({ eventChallengeModel = new EventChallengeModel(), login = new Login() }) => {
  const [openParticipationCompletedModal, setOpenParticipationCompletedModal] = useState(false);
  const [openProfileNotCompletedModal, setOpenProfileNotCompletedModal] = useState(false);
  const [openApplyCompletedModal, setOpenApplyCompletedModal] = useState(false);
  const [openEventSoldOutModal, setOpenEventSoldOutModal] = useState(false);
  const [openMarketingConsentModal, setOpenMarketingConsentModal] = useState(false);
  const { setToastObject } = useToast();
  const history = useHistory();

  const talentPoolProfileApply = async () => {
    try {
      await eventChallengeModel.loadEventsTalentPoolProfileInputApply();
      setOpenApplyCompletedModal(true);
      await eventChallengeModel.loadEventsTalentPoolUserStatus();
    } catch (e: any) {
      switch (e.response?.data?.errorCode) {
        case ErrorCode.B904:
        case ErrorCode.B905:
          setOpenMarketingConsentModal(true);
          break;
        case ErrorCode.B906:
          setToastObject({ isOpen: true, type: 'ERROR', message: ErrorCodeText.post_event_challenge.B906, subMessage: undefined });
          break;
        case ErrorCode.B907:
          setToastObject({ isOpen: true, type: 'ERROR', message: '이벤트 기간 전 프로필을 입력한 회원에게는 제공되지 않습니다. 역량검사 이벤트에 응모해 주세요' });
          break;
        case ErrorCode.B998:
          setOpenEventSoldOutModal(true);
          break;
        default:
          setToastObject({ isOpen: true, type: 'ERROR', message: '다시 시도해 주세요.', subMessage: undefined });

          break;
      }
    }
  };

  const handleTalentPoolProfileApply = async () => {
    if (!login.userInfo) {
      history.push(RoutePaths.login);
      return;
    }
    if (eventChallengeModel.userEventStatus?.talentPoolEventDto.isTalentPoolProfileEventParticipation) {
      setOpenParticipationCompletedModal(true);
      return;
    }
    if (!eventChallengeModel.userEventStatus?.talentPoolEventDto.isTalentPoolProfileInput) {
      setOpenProfileNotCompletedModal(true);
      return;
    }
    talentPoolProfileApply();
  };

  return (
    <div className={cx('inner')}>
      <div className={cx('sectionTitleArea')}>
        <strong className={cx('sectionTitle')}>챌린지 미션 1<br />프로필 작성하기</strong>
      </div>

      {/* 미션 영역 */}
      <div className={cx('missionArea')}>
        <div className={cx('missionInner')}>
          <JDSwiper className='service-swiper' paginationtype={PageNationType.bullets} autoplay>
            <JDSwiperSlide className='service-card'>
              <div className={cx('sliderItem', 'type1')} />
            </JDSwiperSlide>
            <JDSwiperSlide className='service-card'>
              <div className={cx('sliderItem', 'type2')} />
            </JDSwiperSlide>
            <JDSwiperSlide className='service-card'>
              <div className={cx('sliderItem', 'type3')} />
            </JDSwiperSlide>
          </JDSwiper>
        </div>
        <div className={cx('btnArea')}>
          <JDALink
            to={RoutePaths.profile}
            className={cx('btnEvent', 'eventBtn_profile', { outLine: eventChallengeModel.userEventStatus?.talentPoolEventDto.isTalentPoolProfileInput })}
          >
            프로필 {eventChallengeModel.userEventStatus?.talentPoolEventDto.isTalentPoolProfileInput ? '수정하기' : '작성하기'}
          </JDALink>
        </div>
      </div>

      {/* 혜택 영역 */}
      <div className={cx('benefitArea')}>
        <div className={cx('benefitWrap', 'type1')}>
          <div className={cx('textArea')}>
            <em className={cx('benefitTitle')}>
              <i className={cx('lightText')}>혜택 1</i>
              <br />
              대기업 기출 면접 연습 서비스
            </em>
            <p className={cx('benefitDesc')}>
              면접 질문을 확인하고 답변 연습까지 해보세요!
            </p>
          </div>
        </div>
        <div className={cx('benefitWrap', 'type2')}>
          <div className={cx('textArea')}>
            <em className={cx('benefitTitle')}>
              <i className={cx('lightText')}>혜택 2</i>
              <br />
              네이버페이
            </em>
            <p className={cx('benefitDesc')}>
              2,000P 지급
            </p>
          </div>
        </div>
        {/* 응모하기 버튼 영역 */}
        <div className={cx('btnEventArea')}>
          <button
            className={cx('btnEvent', 'btnApply', 'eventBtn_eventApply')}
            onClick={handleTalentPoolProfileApply}
          >
            이벤트 응모하고 혜택 바로 받기
          </button>
        </div>
      </div>

      {/* 알림 텍스트 영역 */}
      <p className={cx('notice')}>
        - 챌린지 미션 완료 후 [응모하기] 버튼으로
        <br />
        &nbsp;&nbsp;이벤트 응모가 가능합니다.
        <br />
        - 네이버페이: 응모 후 즉시 기프티콘 발송
        <br />
      &nbsp;&nbsp;(회원가입 정보의 핸드폰 번호로 발송), 소진 시 마감됩니다.
        <br />
        - 이벤트 기간 내 프로필을 신규 입력한
        <br />
      &nbsp;&nbsp;고객이 응모할 수 있습니다.
        <br />
        - 핵심 기업 기출 연습: 이벤트 응모 시 입력한 이메일로
        <br />
      &nbsp;&nbsp;접속 URL과 인증코드가 발송됩니다.
        <br />- 중복 참여 불가합니다. (핸드폰 번호 당 1번 지급)
      </p>
      <JDSimpleInfoModal title='혜택 지급 완료!' descriptions='다음 미션을 진행해 주세요.' dimmed isOpen={openParticipationCompletedModal} onClickClose={() => setOpenParticipationCompletedModal(false)} />
      <JDSimpleInfoModal title='프로필 입력 후 이벤트 응모가 가능합니다.' dimmed isOpen={openProfileNotCompletedModal} onClickClose={() => setOpenProfileNotCompletedModal(false)} />
      <JDSimpleInfoModal title='<혜택 지급 완료>' descriptions={`1. 핸드폰 번호로 발송된 \n기프티콘을 확인해 주세요 \n2. 기출 면접은 3일 이내 메일로 발송됩니다.`} dimmed isOpen={openApplyCompletedModal} onClickClose={() => setOpenApplyCompletedModal(false)} />
      <JDSimpleInfoModal title='네이버 페이는 선착순 마감되었습니다.' descriptions='기출면접 서비스는 메일로 발송됩니다.' dimmed isOpen={openEventSoldOutModal} onClickClose={() => setOpenEventSoldOutModal(false)} />
      <MarketingConsentModal isOpen={openMarketingConsentModal} onClickClose={() => setOpenMarketingConsentModal(false)} />
    </div>

  );
};

export default inject(injectStore.eventChallengeModel, injectStore.login)(observer(ChallengeMission1));
