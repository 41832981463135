import styled from 'styled-components';
import Fonts from '__designkit__/common/fonts';
import Colors from '__designkit__/common/colors';

const FormProfileValueTitle = styled.h3<{ required?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
  font: ${Fonts.B1_Bold};
  color: ${Colors.JOBDA_BLACK};
  margin-bottom:12px;

  ${(props) => props.required && `
    :after{
      content: '필수';
      position: absolute;
      top: 50%;
      left: 100%;
      width: fit-content;
      height: fit-content;
      margin-left: 8px;
      font: ${Fonts.B3_Medium};
      color: ${Colors.ERROR};
      transform: translateY(-50%);
    }
  `};
`;

export default FormProfileValueTitle;
