export enum MilitaryStatus {
    NONE = 'NONE', // 비대상자
    DISCHARGE = 'DISCHARGE', // 제대 (군필)
    NON_DISCHARGE = 'NON_DISCHARGE', // 미필
    EXEMPTION = 'EXEMPTION', // 면제
    IN_SERVICE = 'IN_SERVICE', // 복무중
}

export const MilitaryStatusStr: { [key in keyof typeof MilitaryStatus]: string} = {
  NONE: '비대상',
  DISCHARGE: '군필',
  NON_DISCHARGE: '미필',
  EXEMPTION: '면제',
  IN_SERVICE: '복무중',
};

export enum MilitaryType {
    ARMY = 'ARMY', // 육군
    NAVY = 'NAVY', // 해군
    AIR_FORCE = 'AIR_FORCE', // 공군
    MARINE = 'MARINE', // 해병
    COMBAT_POLICE = 'COMBAT_POLICE', // 전경
    AUXILIARY_POLICE = 'AUXILIARY_POLICE', // 의경
    PUBLIC_SERVICE_WORKER = 'PUBLIC_SERVICE_WORKER', // 공익
    ETC = 'ETC', // 기타
}

export const MilitaryTypeStr: { [key in keyof typeof MilitaryType]: string} = {
  ARMY: '육군',
  NAVY: '해군',
  AIR_FORCE: '공군',
  MARINE: '해병',
  COMBAT_POLICE: '전경',
  AUXILIARY_POLICE: '의경',
  PUBLIC_SERVICE_WORKER: '공익',
  ETC: '기타',
};

export enum MilitaryClass {
    PRIVATE = 'PRIVATE', // 이등병
    PRIVATE_FIRST_CLASS = 'PRIVATE_FIRST_CLASS', // 일병
    CORPORAL = 'CORPORAL', // 상병
    SERGEANT = 'SERGEANT', // 병장
    STAFF_SERGEANT = 'STAFF_SERGEANT', // 하사
    SERGEANT_FIRST_CLASS = 'SERGEANT_FIRST_CLASS', // 중사
    MASTER_SERGEANT = 'MASTER_SERGEANT', // 상사
    SERGEANT_MAJOR = 'SERGEANT_MAJOR', // 원사
    WARRANT_OFFICER = 'WARRANT_OFFICER', // 준위
    SECOND_LIEUTENANT = 'SECOND_LIEUTENANT', // 소위
    FIRST_LIEUTENANT = 'FIRST_LIEUTENANT', // 중위
    CAPTAIN = 'CAPTAIN', // 대위
    MAJOR = 'MAJOR', // 소령
    LIEUTENANT_COLONEL = 'LIEUTENANT_COLONEL', // 중령
    COLONEL = 'COLONEL', // 대령
    BRIGADIER_GENERAL = 'BRIGADIER_GENERAL', // 준장
    MAJOR_GENERAL = 'MAJOR_GENERAL', // 소장
    LIEUTENANT_GENERAL = 'LIEUTENANT_GENERAL', // 중장
    GENERAL = 'GENERAL', // 대장
    ETC = 'ETC', // 기타
}

export const MilitaryClassStr: { [key in keyof typeof MilitaryClass]: string} = {
  PRIVATE: '이병',
  PRIVATE_FIRST_CLASS: '일병',
  CORPORAL: '상병',
  SERGEANT: '병장',
  STAFF_SERGEANT: '하사',
  SERGEANT_FIRST_CLASS: '중사',
  MASTER_SERGEANT: '상사',
  SERGEANT_MAJOR: '원사',
  WARRANT_OFFICER: '준위 ',
  SECOND_LIEUTENANT: '소위 ',
  FIRST_LIEUTENANT: '중위 ',
  CAPTAIN: '대위 ',
  MAJOR: '소령 ',
  LIEUTENANT_COLONEL: '중령 ',
  COLONEL: '대령 ',
  BRIGADIER_GENERAL: '준장 ',
  MAJOR_GENERAL: '소장 ',
  LIEUTENANT_GENERAL: '중장 ',
  GENERAL: '대장 ',
  ETC: '기타',
};

export enum MilitaryDischargeType {
    COMPLETE = 'COMPLETE', // 만기 제대
    RELEASE_OF_CALL = 'RELEASE_OF_CALL', // 소집 해제
    FAMILY = 'FAMILY', // 의가사 제대(가정)
    DISEASE = 'DISEASE', // 의가사 제대(질병)
    DISHONOR = 'DISHONOR', // 불명예 제대
    VETERAN = 'VETERAN', // 상이 제대 (질병 보훈)
    ETC = 'ETC', // 기타
}

export const MilitaryDischargeTypeStr: { [key in keyof typeof MilitaryDischargeType]: string} = {
    COMPLETE : '만기제대', // 만기 제대
    RELEASE_OF_CALL : '소집해제', // 소집 해제
    FAMILY : '의가사제대', // 의가사 제대(가정)
    DISEASE : '의병제대', // 의가사 제대(질병)
    DISHONOR : '불명예제대', // 불명예 제대
    VETERAN : '상이제대', // 상이 제대 (질병 보훈)
    ETC : '기타', // 기타
  };