import JDBaseModal, { IJDBaseModal } from 'components/_v2/_common/modals/JDBaseModal';
import { JDButtonTwoChoiceFrame, JDModalBasicFrame, JDModalButton, JDModalButtonGhost, JDModalDescription, JDModalTitle } from 'components/_v2/_common/modals/JDModalComponents';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import React, { FC } from 'react';
import styled from 'styled-components';
import IconError from '__designkit__/assets/icon/icon_error_yellow_fill.png';

const Frame = styled.div`
  z-index: 10006;
  ${JDModalTitle} {
    color: ${colors.JOBDA_BLACK};
    margin-bottom: 8px;
  }

  ${JDButtonTwoChoiceFrame} {
    margin-top: 24px;
  }

  ${JDModalButtonGhost} {
    font: ${Fonts.B1_Bold};
    line-height: 22px;
    margin-right: 16px;
  }

  ${JDModalButton} {
    font: ${Fonts.B1_Bold};
    line-height: 22px;
  }
  .icon{
    width: 64px;
    height: 64px;
    align-self: center;
    margin-bottom: 16px;
  }
`;
const JDModalTitleWrap = styled(JDModalTitle)`
  white-space: pre-wrap;
`;
const AdditionalMoveDirtyModal:FC<IJDBaseModal> = ({ isOpen, onClickClose, onSuccess }) => (
  <JDBaseModal dimmed isOpen={isOpen} onClickClose={onClickClose} isDimClickClose>
    <Frame>
      <JDModalBasicFrame>
        <img className='icon' src={IconError} alt='error' />
        <JDModalTitleWrap>페이지를 나가시겠습니까?</JDModalTitleWrap>
        <JDModalDescription>작성 중인 추가 정보는 저장되지 않습니다.</JDModalDescription>
        <JDButtonTwoChoiceFrame>
          <JDModalButtonGhost onClick={onClickClose}>취소</JDModalButtonGhost>
          <JDModalButton onClick={onSuccess}>나가기</JDModalButton>
        </JDButtonTwoChoiceFrame>
      </JDModalBasicFrame>
    </Frame>
  </JDBaseModal>
);

export default AdditionalMoveDirtyModal;
