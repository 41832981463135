import JDASearchInput from 'components/inputs/JDASearchInput';
import { SkillListItem } from 'components/_v2/profile/skill/ProfileSkill';
import { IProfileSkillProps } from 'components/_v2/profile/skill/ProfileSkillView';
import JDClosableChip from 'components/_v2/_common/chip/JDClosableChip';
import FormProfileValueTitle from 'components/_v2/_common/form/FormProfileValueTitle';
import useToast from 'hooks/useToast';
import { IconPlus24 } from 'consts/assets/icons/iconPages';
import { IProfileLicenseDto } from 'interfaces/_v2/profile/IProfileKnowledgeAndSkillsRs';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import ProfileSkillModel from 'models/_v2/profile/ProfileSkillModel';
import React, { FC, useRef } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

const Frame = styled.div` 
    padding: 32px 16px 78px 16px;

    .frame-chips {
      display: flex;
      flex-wrap: wrap;
      margin-top: 16px;

      >div {
        margin-right: 8px;
        margin-bottom: 8px;
      }
    }
`;

const ProfileSkillLicense:FC<IProfileSkillProps> = ({ profileSkillModel = new ProfileSkillModel(), hidden }) => {
  const { setToastObject } = useToast();
  const useFormed = useFormContext();
  const { watch, control, register } = useFormed;
  const reservedTimeout = useRef<number>(-1);
  const licenseFields = useFieldArray<IProfileLicenseDto>({ control, name: 'licenses' });

  return (
    <>
      <Frame aria-hidden={hidden}>
        <FormProfileValueTitle>자격증</FormProfileValueTitle>
        <JDASearchInput
          className='input-search'
          name='idx'
          title='자격증'
          placeholder='자격증을 검색해 주세요.'
          onChange={async (searchText: string) => {
            if (reservedTimeout.current > -1)
              clearTimeout(reservedTimeout.current);
            reservedTimeout.current = setTimeout(() => {
              profileSkillModel.searchLicenses(searchText);
            }, 800) as unknown as number;
          }}
          listItemIcon={<IconPlus24 />}
          removeInputValue
          onSelectItem={async () => {
            const selectIdx: number = watch('idx');
            const selectName = selectIdx && profileSkillModel.searchedLicenses && profileSkillModel.searchedLicenses[selectIdx].name;
            const selectCode = selectIdx && profileSkillModel.searchedLicenses && profileSkillModel.searchedLicenses[selectIdx].code;
            const found = licenseFields.fields.some(({ code }) => Number(code) === Number(selectCode));
            if (found) {
              setToastObject({ isOpen: true, message: '이미 등록된 자격증입니다.', type: 'ERROR', position: 'middle' });
              return;
            }
            if (!found && selectName && selectCode)
              licenseFields.append({ name: selectName, code: selectCode });
          }}
        >
          <SkillListItem>
            {
            profileSkillModel.searchedLicenses.map((license, idx) => {
              if (licenseFields.fields.some((field) => field.code === license.code)) return <></>;
              return (
                <li
                  role='presentation'
                  key={license.code}
                  value={idx}
                >
                  {license.name}
                </li>
              );
            })
            }
          </SkillListItem>
        </JDASearchInput>
        <section className='frame-chips'>
          {
        licenseFields.fields.map((field, idx) => (
          <React.Fragment key={field.code}>
            <input aria-hidden ref={register()} type='text' name={`licenses[${idx}].name`} defaultValue={field.name || ''} />
            <input aria-hidden ref={register()} type='text' name={`licenses[${idx}].code`} defaultValue={field.code ?? -1} />
            <JDClosableChip onClose={() => licenseFields.remove(idx)}>{field.name}</JDClosableChip>
          </React.Fragment>
        ))
       }
        </section>
      </Frame>
    </>
  );
};

export default inject(injectStore.profileSkillModel)(observer(ProfileSkillLicense));
