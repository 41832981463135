import React from 'react';
import classnames from 'classnames/bind';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

const LastBanner = () => (
  <div className={cx('commonBox')}>
    <span className={cx('title')}>스펙없이 취업을, JOBDA</span>
    JOBDA는 스펙이 아닌 역량을 기반으로
    <br />
    능력과 기회를 매치합니다
  </div>
);

export default LastBanner;
