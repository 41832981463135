export interface IUniversityInfo {
  verifiedDate: string;
  schoolCode: number;
  schoolName: string;
  majorCode: number;
  majorName: string;
  studentId: string;
}

export interface IUniversityInfoRq {
  email: string;
  schoolCode: number;
  studentId: string;
  majorCode :number;
  majorName:string;
}

export interface IOrganizationInfo {
  verifiedDate: string;
  organizationName: string;
}

export interface IMyPagePartnership {
  universityInfo: IUniversityInfo;
  organizationInfo: IOrganizationInfo;
  universityVerified: boolean,
  organizationVerified: boolean,
}

export enum PartnershipType {
  UNIVERSITY = 'UNIVERSITY',
  ORGANIZATION = 'ORGANIZATION',
}

export enum CouponType {
  MBTI_A = 'MBTI_A',
  MBTI_B = 'MBTI_B'
}
export interface IRegisteredCouponRs{
  count:number;
  registeredCoupons:RegisteredCouponDto[];
}

export interface RegisteredCouponDto{
  code:string;
  expiresDateTime:string;
  isExpired:boolean;
  registeredDateTime:string;
  couponType:CouponType;
}
