import React from 'react';

import classnames from 'classnames/bind';
import styles from './rabbitsStyle.module.scss';

const cx = classnames.bind(styles);

const Rabbits = () => (
  <div className={cx('contentInner')}>
    <div className={cx('basicTitle')}>
      <p className={cx('subTitle')}>이번 연휴 맘 편히 쉬고 싶은데<br />취업/이직준비로 고민 중이시라면</p>
      <p className={cx('mainTitle')}>JOBDA에서<br />자동 취업을 경험하세요!</p>
    </div>
    <div className={cx('rabbits')} />
  </div>
);

export default Rabbits;
