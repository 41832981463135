import React, { FC, useRef, useState } from 'react';
import Icon from '__designkit__/icon/Icon';

import Colors from '__designkit__/common/colors';
import classnames from 'classnames/bind';
import EventChuseokModel from 'models/_v2/event/EventChuseokModel';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import JDSimpleInfoModal from 'components/_v2/_common/modals/JDSimpleInfoModal';
import ErrorCode from 'consts/ErrorCodes';
import useToast from 'hooks/useToast';
import { TOAST_TYPE } from 'components/_v2/_common/toast/JDToast';
import ScrollUtil from 'utils/ScrollUtil';
import FileUtil from 'utils/FileUtil';
import SampleFullModal from '__pc__/page/eventChuseok/gift/SampleFullModal';
import MarketingConsentModal from '__pc__/page/eventChallenge/MarketingConsentModal';
import Login from 'models/Login';
import { useHistory } from 'react-router';
import RoutePaths from 'consts/RoutePaths';
import { ProfileType } from 'consts/_v2/profile/ProfileType';
import styles from './giftStyle.module.scss';

const cx = classnames.bind(styles);

interface IGiftProps{
  eventChuseokModel?:EventChuseokModel;
  login?:Login
}
const Gift:FC<IGiftProps> = ({ eventChuseokModel = new EventChuseokModel(), login = new Login() }) => {
  const history = useHistory();
  const [openProfileIncompleteModal, setOpenProfileIncompleteModal] = useState(false);
  const [openAccIncompleteModal, setOpenAccIncompleteModal] = useState(false);
  const [openProfileAccIncompleteModal, setOpenProfileAccIncompleteModal] = useState(false);
  const [openEventSoldOutModal, setOpenEventSoldOutModal] = useState(false);
  const [openEventExceptionModal, setOpenEventExceptionModal] = useState(false);
  const [openEventSuccessModal, setOpenEventSuccessModal] = useState(false);
  const [openSampleResumeModal, setOpenSampleResumeModal] = useState(false);
  const [openSampleResultModal, setOpenSampleResultModal] = useState(false);
  const [openMarketingConsentModal, setOpenMarketingConsentModal] = useState<boolean>(false);
  const { setToastObject } = useToast();

  const eventRef = useRef<HTMLDivElement>(null);
  const HolidayEventType = 'TALENT_POOL_HOLIDAY_2023_10';
  const scrollToRef = async () => {
    const refOffSetTop = eventRef.current?.offsetTop;

    if (refOffSetTop) {
      ScrollUtil.scrollTo(0, refOffSetTop ? refOffSetTop - 100 : 0);
    }
  };

  const applyEvent = async () => {
    if (!login.userInfo) {
      history.push(RoutePaths.login);
      return;
    }
    if (eventChuseokModel.profileType === ProfileType.DEVELOPER) {
      if (!eventChuseokModel.isPaccCompleted && !eventChuseokModel.isProfileCompleted) {
        setOpenProfileAccIncompleteModal(true);
        return;
      } if (!eventChuseokModel.isProfileCompleted) {
        setOpenProfileIncompleteModal(true);
        return;
      } if (!eventChuseokModel.isPaccCompleted) {
        setOpenAccIncompleteModal(true);
        return;
      }
    } else {
      if (!eventChuseokModel.isAccCompleted && !eventChuseokModel.isProfileCompleted) {
        setOpenProfileAccIncompleteModal(true);
        return;
      } if (!eventChuseokModel.isProfileCompleted) {
        setOpenProfileIncompleteModal(true);
        return;
      } if (!eventChuseokModel.isAccCompleted) {
        setOpenAccIncompleteModal(true);
        return;
      }
    }

    try {
      await eventChuseokModel.setEventsTalentPoolHoliday2023Apply();
      setOpenEventSuccessModal(true);
      await eventChuseokModel.loadEventsParticipationStatus(HolidayEventType);
    } catch (e: any) {
      switch (e.response?.data?.errorCode) {
        case ErrorCode.B903:
          setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '이미 참여한 이벤트입니다.', subMessage: undefined });
          break;
        case ErrorCode.B002:
        case ErrorCode.B907:
          setOpenEventExceptionModal(true);
          break;
        case ErrorCode.B904:
        case ErrorCode.B905:
          setOpenMarketingConsentModal(true);
          break;
        case ErrorCode.B998:
          setOpenEventSoldOutModal(true);
          break;
        default:
          setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '다시 시도해 주세요.', subMessage: undefined });
          break;
      }
    }
  };

  const handleDownloadResume = async () => {
    if (eventChuseokModel.eventParticipationStatus?.isParticipation) {
      try {
        const res = await eventChuseokModel.loadEventsTalentPoolHoliday2023ResumeDownload();
        if (res) {
          FileUtil.fileDownload('직무별 합격자 이력서 모음.zip', res, true);
        }
      } catch (e) {
        setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '다시 시도해 주세요.', subMessage: undefined });
        console.error(e);
      }
    }
  };

  const handleAccResult = async () => {
    if (eventChuseokModel.eventParticipationStatus?.isParticipation) {
      history.push(RoutePaths.acca_results());
    }
  };
  return (
    <div className={cx('contentInner', 'giftInner')}>
      <div className={cx('star', 'starWhite')} />
      <div className={cx('eventTitle')}>
        <div className={cx('imgTitle')} />
      </div>
      {/* 혜택 버튼 영역 */}
      <button onClick={applyEvent} className={cx('btnClick', 'btnGreen')}>
        지금 한정 혜택 받기
        <div className={cx('btnIcon')}>
          <Icon name='arrow-bottom' size={40} />
        </div>
      </button>

      {/* 설명 */}
      <span className={cx('desc')}>
        * 혜택 제공을 위한 마케팅 정보 수신 동의가 필요합니다.
        <br />
        프로필 30%(필수정보 포함) 이상 입력, 역량검사 응시완료 후<br />[지금 한정 혜택 받기] 버튼을 클릭하면 아래 혜택 버튼이
        활성화됩니다.
      </span>

      {/* 하단 혜택 리스트 */}
      <div className={cx('giftList')} ref={eventRef}>
        {/* 네이버 페이 */}
        <div className={cx('giftBox', 'nPay')}>
          <div className={cx('giftImg', 'giftImg1')} />
        </div>

        {/* 15개 직무별 실제 합격자 이력서 ZIP */}
        <div className={cx('giftBox')}>
          <div className={cx('giftImg', 'giftImg2')} />
          {/* 이력서 다운로드 버튼 - 버튼 비활성화 disabled 추가 */}
          <button onClick={handleDownloadResume} className={cx('btnClick', 'btnWhite')} disabled={!eventChuseokModel.eventParticipationStatus?.isParticipation}>
            합격자 이력서 다운로드
            <div className={cx('btnIcon')}>
              <Icon name='more-vert' size={24} color={Colors.G_200} />
            </div>
          </button>
          {/* 이력서 샘플보기 */}
          <button onClick={() => setOpenSampleResumeModal(true)} className={cx('btnView')}>
            <span className={cx('btnText')}>이력서 샘플보기</span>
            <div className={cx('btnIcon')}>
              <Icon name='arrow-right' size={24} color={Colors.WHITE_100} />
            </div>
          </button>
        </div>

        {/* 내 역량 분석 결과표 */}
        <div className={cx('giftBox')}>
          <div className={cx('giftImg', 'giftImg3')} />
          {/* 이력서 다운로드 버튼 - 버튼 비활성화 disabled 추가 */}
          <button onClick={handleAccResult} className={cx('btnClick', 'btnWhite')} disabled={!eventChuseokModel.eventParticipationStatus?.isParticipation}>
            심층분석 결과표 확인하기
            <div className={cx('btnIcon')}>
              {/* <Icon name='arrow-right' size={24} color={Colors.G_200} /> */}
              {/* 비활성화시에(disabled) 아이콘 색상 바뀜 */}
              <Icon name='arrow-right' size={24} color={Colors.CG_70} />
            </div>
          </button>
          {/* 이력서 샘플보기 */}
          <button onClick={() => setOpenSampleResultModal(true)} className={cx('btnView')}>
            <span className={cx('btnText')}>결과표 샘플보기</span>
            <div className={cx('btnIcon')}>
              <Icon name='arrow-right' size={24} color={Colors.WHITE_100} />
            </div>
          </button>
        </div>
      </div>

      {/* 설명 */}
      <span className={cx('desc')}>
        * 네이버페이 1만원 : 당첨된 경우 회원정보 전화번호로 즉시 기프티콘이 발송됩니다. (선착순 500명 소진 시 미제공)
        <br />
        * 합격자 이력서 : [다운로드]버튼 클릭 시, 열람 가능합니다.
        <br />
        * 내 역량 심층 분석 결과표 : [결과표 확인하기] 버튼 클릭 시, 결과 확인 페이지로 이동됩니다.
        <br />
        : 9/26(화)~10/10(화) 동안 역량검사 심층 결과표가 제공되며, 이벤트 기간 이후에는 심층 결과표를 볼 수 없습니다.
        <br />
        * 이벤트 시작일(9/26) 전에 이미 프로필 30% 이상 입력, 역량검사 응시를 완료한 회원은 이벤트 대상에서
        제외됩니다.
        <br />
        * 이벤트 기간 동안 심층 결과표 확인이 되지 않는 회원님은 역량검사에 재응시 하실 경우 심층 결과표를 확인할 수 있습니다.
      </span>
      <JDSimpleInfoModal title='이벤트 조건을 만족한 후 응모가 가능합니다.' descriptions='프로필 30% 이상 입력(필수정보 포함), 역량검사 응시 완료 후 응모해 주세요' dimmed isOpen={openProfileAccIncompleteModal} onClickClose={() => setOpenProfileAccIncompleteModal(false)} />
      <JDSimpleInfoModal title='이벤트 조건을 만족한 후 응모가 가능합니다.' descriptions='프로필 30% 이상 입력(필수정보 포함) 후 응모해 주세요' dimmed isOpen={openProfileIncompleteModal} onClickClose={() => setOpenProfileIncompleteModal(false)} />
      <JDSimpleInfoModal title='이벤트 조건을 만족한 후 응모가 가능합니다.' descriptions='역량검사 응시 완료 후 응모해 주세요' dimmed isOpen={openAccIncompleteModal} onClickClose={() => setOpenAccIncompleteModal(false)} />
      <JDSimpleInfoModal title='이벤트 시작일(9/26) 전에 이미 프로필 30% 이상 입력, 역량검사 응시를 완료한회원은 이벤트 대상에서 제외됩니다' dimmed isOpen={openEventExceptionModal} onClickClose={() => setOpenEventExceptionModal(false)} />
      <JDSimpleInfoModal
        title={`응모완료! \n네이버페이는 회원정보 상 전화번호로 발송됩니다.`}
        descriptions='합격자 이력서, 심층 분석 결과표는 아래 버튼을 통해 확인할 수 있습니다. '
        dimmed
        isOpen={openEventSuccessModal}
        onClickClose={() => {
          setOpenEventSuccessModal(false);
          scrollToRef();
        }}
      />
      <JDSimpleInfoModal
        title='선착순 이벤트 상품이 모두 소진되었습니다.'
        descriptions={`합격자 이력서, 심층 분석 결과표는 \n아래 버튼을 통해 확인할 수 있습니다.`}
        dimmed
        isOpen={openEventSoldOutModal}
        onClickClose={() => {
          setOpenEventSoldOutModal(false);
          scrollToRef();
        }}
      />
      <SampleFullModal resume isOpen={openSampleResumeModal} onClose={() => setOpenSampleResumeModal(false)} />
      <SampleFullModal isOpen={openSampleResultModal} onClose={() => setOpenSampleResultModal(false)} />
      <MarketingConsentModal isOpen={openMarketingConsentModal} onClickClose={() => setOpenMarketingConsentModal(false)} />

    </div>
  );
};

export default inject(injectStore.eventChuseokModel, injectStore.login)(observer(Gift));
