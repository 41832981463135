import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import ChattingAPI from 'api/chattingAPI';
import JDSwiper, { JDSwiperSlide } from 'components/_v2/_common/JDSwiper';
import JDTab, { Tab } from 'components/_v2/_common/Tab/JDTab';
import ChannelList from 'components/_v2/chatting/ChannelList';
import { ListLoadingMotion } from 'components/_v2/chatting/ListLoadingMotion';
import RoutePaths from 'consts/RoutePaths';
import { loginUserChattingListTab, STAR_CHANNEL_COUNT } from 'consts/_v2/chatting/chatting';
import IComponentProps from 'interfaces/props/IComponentProps';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import ChattingModel from 'models/_v2/ChattingModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import Swiper from 'swiper';
import CommonUtils from 'utils/CommonUtils';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  & *::-webkit-scrollbar {
    display: none;
  }
`;

const NickNameBox = styled.div`
  padding: 8px 12px 16px 12px;
  font: ${Fonts.B2_Medium};
  color: ${Colors.JOBDA_BLACK};

  & > span {
    font: ${Fonts.B2_Bold};
  }
`;

const SuggestChattingFrame = styled.div`
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font: ${Fonts.B2_Medium_P};
  color: ${Colors.CG_70};
  white-space: pre-wrap;
  text-align: center;
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  height: fit-content;
  padding: 7px 14px 7px 20px;
  border: 1px solid ${Colors.JOBDA_BLACK};
  border-radius: 20px;
  font: ${Fonts.B2_Bold};
  color: ${Colors.JOBDA_BLACK};
`;

interface IChattingMainProps extends IComponentProps {
  login?: Login;
  chattingModel?: ChattingModel;
}

const ChattingMain: FC<IChattingMainProps> = ({ login = new Login(), chattingModel = new ChattingModel() }) => {
  const history = useHistory();
  const { tabId } = CommonUtils.parseQuery(history.location.search);

  const [tabItems] = useState<Tab[]>(loginUserChattingListTab);
  const [currentTab, setCurrentTab] = useState<number>(tabId || 0);
  const swiperRef = useRef<Swiper | null>(null);

  useEffect(() => {
    if (swiperRef.current) {
      for (let i = 0; i < tabItems.length; i++) {
        if (tabItems[i].tabId === currentTab) {
          swiperRef.current.slideTo(i);
          break;
        }
      }
    }
  }, [tabItems, currentTab]);

  useEffect(() => {
    (async () => {
      try {
        // 잡다와 talk-plus에 모두 로그인되어 있으면 내 체널 목록을 가져온다.
        if (login.userInfo && !chattingModel?.user?.data?.isAnonymous) {
          chattingModel.setMyChannelList(await chattingModel.makeMyChannelList());
          chattingModel.setMyChannelNotificationList(await ChattingAPI.getAllChannelNotificationWhether());
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [chattingModel, login.userInfo, chattingModel?.user?.data?.isAnonymous]);

  return (
    <Frame>
      <SpacingBlock size={16} vertical />
      <JDTab tabs={tabItems} currentTab={currentTab} tabSetter={setCurrentTab} />
      <SpacingBlock size={16} vertical />
      <JDSwiper
        style={{ width: '100%', padding: '0 8px', margin: 0 }}
        onInit={(swiper) => {
          swiperRef.current = swiper;
          swiper.activeIndex = tabId;
        }}
        onSlideChange={(slide) => {
          setCurrentTab(tabItems[slide.activeIndex].tabId);
          history.replace(`${RoutePaths.chatList}?tabId=${tabItems[slide.activeIndex].tabId}`);
        }}
      >
        <JDSwiperSlide style={{ overflowY: 'auto' }}>
          { login.userInfo === null
            ? (
              <SuggestChattingFrame>
                { `지금 바로 로그인하고\n함께 채용정보를 나눠보세요!` }
                <SpacingBlock size={16} vertical />
                <Button role='button' onClick={() => history.push(RoutePaths.login)}>
                  로그인하기
                  <Icon name='arrow-right' size={24} />
                </Button>
              </SuggestChattingFrame>
            )
            : chattingModel.myChannelList === null
              ? <ListLoadingMotion listCount={5} />
              : chattingModel.myChannelList.length > 0
                ? (
                  <>
                    <NickNameBox><span>{ chattingModel.user?.username }</span>님, 함께 소통해보세요.</NickNameBox>
                    <ChannelList items={chattingModel.myChannelList} lastMessage />
                  </>
                ) : (
                  <SuggestChattingFrame>
                    { `아직 참여한 채팅이 없어요.\n지금 인기 있는 채팅을 확인해볼까요?` }
                    <SpacingBlock size={16} vertical />
                    <Button role='button' onClick={() => setCurrentTab(1)}>
                      인기 채팅 보러가기
                      <Icon name='arrow-right' size={24} />
                    </Button>
                  </SuggestChattingFrame>
                )}
        </JDSwiperSlide>
        <JDSwiperSlide style={{ overflowY: 'auto' }}>
          { chattingModel.allChannelList === null
            ? <ListLoadingMotion listCount={8} />
            : <ChannelList items={chattingModel.allChannelList.filter((channel) => channel.memberCount > 30).slice(0, STAR_CHANNEL_COUNT)} /> }
        </JDSwiperSlide>
      </JDSwiper>
      <SpacingBlock size={16} vertical />
    </Frame>
  );
};

export default inject(injectStore.login, injectStore.chattingModel)(observer(ChattingMain));
