/* eslint-disable array-callback-return */
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import iconPagesEmptyLogoError from 'assets/icon/icon_pages_empty_logo_error.svg';
import JDPopover from 'components/_v2/_common/popover/JDPopover';
import { LazyImageLogo } from 'components/common/LazyImage';
import { Divider1G40 } from 'components/divider/Divider';
import { LocationCode } from 'consts/CompanyLocationType';
import { lineClampBox } from 'consts/_v2/_common/style/mixins';
import { RecruitmentTypeText } from 'consts/_v2/position/PositionType';
import { IJobPreviewRs } from 'interfaces/_v2/jobs/IJobPostingRs';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { DateFormatYYYYMMDDCCHHMMUnit, getDDayString, overDateNow, str2DateTime } from 'utils/DateUtils';

const Frame = styled.div`
  padding: 12px 16px 0;

  .align-div {
    display: flex;
    position: relative;

    .img {
      width: 56px;
      height: 56px;
      min-width: 56px;
      margin-right: 18px;
      border: 1px solid 'rgba(68, 71, 75, 0.2)';
    }
    .img-fallback{

    }

    .position-name {
      width: calc(100vw - 155px);
      text-align: left;
      margin-top: 12px;
      font: ${Fonts.H5_Bold};
      ${lineClampBox(2)};
    }
  }

  .tag-view-frame {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    font: ${Fonts.B3_Medium};
    color: ${Colors.CG_70};
  }

  .d-day-frame {
    padding: 4px 6px;
    border-radius: 4px;
    margin-left: 8px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    background-color: ${Colors.JOBDA_BLACK};
    span {
      display: inline-block;
      font: ${Fonts.B4_Medium};
      color: ${Colors.WHITE_100};
      width: max-content;
    }
  }

  .align-div.center {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    .title {
      font: ${Fonts.B3_Medium};
      color: ${Colors.CG_80};
      margin-right: 12px;
    }

    .content {
      font: ${Fonts.B3_Medium};
      color: ${Colors.JOBDA_BLACK};
    }
  }

  .bookmark {
    position: absolute;
    top: 16px;
    right: 0;
  }
`;

const Divider = styled(Divider1G40)`
  margin: 24px 0;
`;

const PopOverFrame = styled.div`
  color: ${Colors.WHITE_100};
  > h1 {
    font: ${Fonts.B3_Bold};
  }
`;

interface IJobsPreviewPostingInfoProps {
  jobsDetail?:IJobPreviewRs
}

const JobsPreviewPostingInfo: FC<IJobsPreviewPostingInfoProps> = ({ jobsDetail }) => {
  const [tagList, setTagList] = useState<string[]>([]);
  useEffect(() => {
    if (jobsDetail) {
      let tmpTagList:string[] = [];
      jobsDetail.positions.map((position) => {
        tmpTagList.push(RecruitmentTypeText[position.recruitmentType]);
      });
      jobsDetail.positions.map((position) => {
        tmpTagList.push(LocationCode(position.locationCode));
      });
      tmpTagList = tmpTagList.filter((v: string, i: number) => tmpTagList.indexOf(v) === i);
      setTagList(tmpTagList);
    }
  }, [jobsDetail]);
  return (
    <Frame>
      <div className='align-div'>
        {
          jobsDetail?.companyLogoUrl
            ? <LazyImageLogo src={jobsDetail?.companyLogoUrl || ''} className='img' />
            : <img className='img' src={iconPagesEmptyLogoError} alt='error' />
        }

        <JDPopover useOutsideClick position='under' popoverMargin={30} anchorIcon={<div className='position-name'>{jobsDetail?.name}</div>}>
          <PopOverFrame>
            <h1>{jobsDetail?.name || 'name'}</h1>
          </PopOverFrame>
        </JDPopover>
        <div role='presentation' className='bookmark'>
          <Icon name='bookmark' size={32} />
        </div>
      </div>
      <div className='tag-view-frame'>
        <div className='tags'>{tagList.map((tag) => `#${tag} `)}</div>
      </div>
      <Divider />
      <div className='align-div center'>
        <div className='title'>기업</div>
        <span className='content'>{jobsDetail?.companyName}</span>
      </div>
      <div className='align-div center'>
        <div className='title'>진행</div>
        <span className='content'>{jobsDetail?.postStartDateTime && jobsDetail?.postEndDateTime ? `${DateFormatYYYYMMDDCCHHMMUnit(jobsDetail?.postStartDateTime)} ~ ${DateFormatYYYYMMDDCCHHMMUnit(jobsDetail?.postEndDateTime)}` : '상시 채용'}</span>
        {jobsDetail?.postEndDateTime && !overDateNow(jobsDetail?.postEndDateTime) && (
          <div className='d-day-frame'>
            <span>{getDDayString(str2DateTime(jobsDetail?.postEndDateTime || ''))}</span>
          </div>
        )}
      </div>
    </Frame>
  );
};

export default JobsPreviewPostingInfo;
