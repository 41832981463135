import FormFiledFrame from 'components/_v2/_common/form/FormFiledFrame';
import { DatePickerFormValueFrame } from 'components/_v2/_common/form/FormProfileValueFrame';
import FormProfileValueTitle from 'components/_v2/_common/form/FormProfileValueTitle';
import JDDatePickerInput from 'components/_v2/_common/input/JDDatePickerInput';
import JDSelector, { JDSelectorType } from 'components/_v2/_common/input/JDSelector';
import { MilitaryClass, MilitaryClassStr, MilitaryDischargeType, MilitaryDischargeTypeStr, MilitaryStatus, MilitaryStatusStr, MilitaryType, MilitaryTypeStr } from 'consts/_v2/profile/MilitaryType';
import { IProfileProps } from 'interfaces/_v2/profile/IProfileProps';
import { inject, observer } from 'mobx-react';
import ProfileModel from 'models/_v2/profile/ProfileModel';
import { injectStore } from 'models/store';
import React, { FC } from 'react';
import { ArrayField, useFormContext } from 'react-hook-form';

interface IProfilePreferentialMilitaryProps extends IProfileProps {
    militaryFields: Partial<ArrayField<Record<string, any>, 'id'>>[];
}

const ProfilePreferentialMilitary:FC<IProfilePreferentialMilitaryProps> = ({ militaryFields, profileModel = new ProfileModel() }) => {
  const useFormed = useFormContext();
  const { watch, setValue } = useFormed;
  const militaryInvalidCheck = !(watch('military.status') === MilitaryStatus.DISCHARGE || watch('military.status') === MilitaryStatus.IN_SERVICE);

  return (
    <>
      { militaryFields.map((filed) => (
        <div key={filed.id}>
          <FormFiledFrame>
            <FormProfileValueTitle required>병역사항</FormProfileValueTitle>
            <JDSelector
              name='military.status'
              selectTitle='선택'
              type={JDSelectorType.NORMAL}
              className='selector'
              value={profileModel.preferential?.military?.status || ''}
              onChange={() => {
                if (watch('military.status') !== MilitaryStatus.DISCHARGE) {
                  setValue('military.endDate', null);
                  if (watch('military.status') !== MilitaryStatus.IN_SERVICE) {
                    setValue('military.startDate', null);
                    setValue('military.type', null);
                    setValue('military.militaryClass', null);
                    setValue('military.dischargeType', null);
                  }
                }

                if (watch('military.status') === MilitaryStatus.IN_SERVICE)
                  setValue('military.dischargeType', null);
              }}
            >
              <select>
                <option value='' hidden>선택</option>
                {
                     (Object.keys(MilitaryStatus) as Array<keyof typeof MilitaryStatus>).map((key) => (
                       <option key={key} value={key} selected={`${watch('military.status')}` === key}>{MilitaryStatusStr[key]}</option>
                     ))
                     }
              </select>
            </JDSelector>
            <JDSelector
              name='military.type'
              selectTitle='군별'
              type={JDSelectorType.NORMAL}
              className='selector'
              disabled={militaryInvalidCheck}
              value={profileModel.preferential?.military?.type || ''}
            >
              <select>
                <option value='' hidden>군별</option>
                {
                     (Object.keys(MilitaryType) as Array<keyof typeof MilitaryType>).map((key) => (
                       <option key={key} value={key} selected={`${watch('military.type')}` === key}>{MilitaryTypeStr[key]}</option>
                     ))
                     }
              </select>
            </JDSelector>
            <JDSelector
              name='military.militaryClass'
              selectTitle='계급'
              type={JDSelectorType.NORMAL}
              className='selector'
              disabled={militaryInvalidCheck}
              value={profileModel.preferential?.military?.militaryClass || ''}
            >
              <select>
                <option value='' hidden>계급</option>
                {
                     (Object.keys(MilitaryClass) as Array<keyof typeof MilitaryClass>).map((key) => (
                       <option key={key} value={key} selected={`${watch('military.militaryClass')}` === key}>{MilitaryClassStr[key]}</option>
                     ))
                   }
              </select>
            </JDSelector>
            <JDSelector
              name='military.dischargeType'
              selectTitle='제대 구분'
              type={JDSelectorType.NORMAL}
              className='selector'
              disabled={militaryInvalidCheck || watch('military.status') === MilitaryStatus.IN_SERVICE}
              value={profileModel.preferential?.military?.dischargeType || ''}
            >
              <select>
                <option value='' hidden>제대 구분</option>
                {
                     (Object.keys(MilitaryDischargeType) as Array<keyof typeof MilitaryDischargeType>).map((key) => (
                       <option key={key} value={key} selected={`${watch('military.dischargeType')}` === key}>{MilitaryDischargeTypeStr[key]}</option>
                     ))
                     }
              </select>
            </JDSelector>
          </FormFiledFrame>
          <FormFiledFrame>
            <FormProfileValueTitle required>병역 기간</FormProfileValueTitle>
            <DatePickerFormValueFrame>
              <JDDatePickerInput
                name='military.startDate'
                placeholder='연도. 월.'
                month
                disabled={watch('military.status') !== MilitaryStatus.DISCHARGE && watch('military.status') !== MilitaryStatus.IN_SERVICE}
                defaultValue={profileModel.preferential?.military?.startDate || ''}
              />
              <span className='date-picker-divider'>~</span>
              <JDDatePickerInput
                name='military.endDate'
                placeholder='연도. 월.'
                month
                disabled={watch(`military.status`) !== MilitaryStatus.DISCHARGE}
                defaultValue={profileModel.preferential?.military?.endDate || ''}
              />
            </DatePickerFormValueFrame>
          </FormFiledFrame>
        </div>
      ))}
    </>
  );
};

export default inject(injectStore.profileModel)(observer(ProfilePreferentialMilitary));
