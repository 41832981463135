import { useEffect } from 'react';

const useAnchorScroll = (hash: string, delay = 1000) => useEffect(() => {
  const el = hash && document.getElementById(hash.substr(1));
  if (el) {
    setTimeout(() => {
      el.scrollIntoView({ behavior: 'smooth' });
    }, delay);
  }
}, [hash]);

export default useAnchorScroll;
