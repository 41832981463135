import React from 'react';
import classnames from 'classnames/bind';
import Footer from 'components/_v2/_common/Footer';
import Intro from './intro';
import FloatingBar from './floatingBar';
import Banner1 from './banner1';
import Banner2 from './banner2';
import Profile from './profile';
import ProfileDownload from './profileDownload';

import styles from './commonStyle.module.scss';

const cx = classnames.bind(styles);

const EventMailProfile = () => (
  <div className={cx('wrap')}>
    <div className={cx('contentInner')}>
      <Intro />
      <Profile />
      <ProfileDownload />
      <Banner1 />
      <Banner2 />
      <div className={cx('banner3')} />
    </div>

    <FloatingBar />
    <Footer isNavi={false} corpInfoFoled />
  </div>
);

export default EventMailProfile;
