import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import AppleLogin from 'components/common/AppleLogin';
import GoogleLoginButton from 'components/common/GoogleLoginButton';
import KaKaoLoginButton from 'components/common/KaKaoLoginButton';
import RoutePaths from 'consts/RoutePaths';
import { absoluteCenterCenter } from 'consts/_v2/_common/style/mixins';
import useToast from 'hooks/useToast';
import { IJoinProps } from 'interfaces/props/_v2/IJoinProps';
import { ISocialAuthLoginRs, OpenIdProvider } from 'interfaces/rqrs/ISocialAuthLoginRs';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import JoinModel from 'models/_v2/JoinModel';
import { injectStore } from 'models/store';
import { FC } from 'react';
import { useHistory } from 'react-router';
import MobileStore from 'store/mobileStore';
import styled from 'styled-components';
import AuthorizeUtil from 'utils/AuthorizeUtil';
import { saveSession } from 'utils/CommonUtils';

const StyledSocialLoginFrame = styled.div`
  text-align: center;
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .divide-frame {
    position: relative;
    width: 100%;
    border-top: 1px solid ${colors.CG_40};
    padding-top: 23px;

    .divide {
      position: absolute;
      padding: 0 4px;
      color: ${colors.CG_60};
      font: ${Fonts.B3_Medium};
      background: ${colors.WHITE_100};
      text-align: center;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .social-login-btn {
    position: relative;
    width: 100%;
    padding: 0 12px;
    background: none;
    border-radius: 4px;
    color: ${colors.CG_80};
    font: ${Fonts.B2_Medium};
    border: 1px solid ${colors.CG_40};
    height: 40px;
    display: flex;
    justify-content: space-between;
        
    img {
      align-self: center;
      margin-right: 12px;
      width: 24px;
      height: 24px;
    }
    span {
      ${absoluteCenterCenter()};
    }
  }

  >:not(:last-child){
    margin-bottom: 8px;
  }

`;

interface ISocialLoginForm extends IJoinProps {
  checkMatchLogin: () => void;
}

const SocialLoginForm: FC<ISocialLoginForm> = ((props) => {
  const { login = new Login(), joinModel = new JoinModel(), checkMatchLogin } = props;
  const { setToastObject } = useToast();
  const history = useHistory();

  const onFailSocialLogin = (e: any) => {
    console.error(e);
    if (e.error === 'idpiframe_initialization_failed') return; // 구글 로그인 모듈 쿠키 허용 안되는 에러(시크릿 모드에서 무조건 발생)
    setToastObject({ isOpen: true, type: 'ERROR', message: '다시 시도해 주세요.', position: 'middle' });
  };

  const processLogin = async () => {
    checkMatchLogin();
    await login.loadCurrentUser();
  };

  const processSocialLogin = async (accessToken: string, provider: OpenIdProvider) => {
    try {
      let jdaAuth: ISocialAuthLoginRs | null = null;
      if (provider === OpenIdProvider.KAKAO) jdaAuth = await login.postKakaoAuth(accessToken);
      if (provider === OpenIdProvider.GOOGLE) jdaAuth = await login.postGoogleAuth(accessToken);
      if (provider === OpenIdProvider.APPLE) jdaAuth = await login.postAppleAuth(accessToken);
      if (jdaAuth!.registered) {
        await processLogin();
        AuthorizeUtil.setIsAutoLogin(true);
        if (MobileStore.isMobile)AuthorizeUtil.setAutoLoginInfo(provider);
      } else {
        // ipin 인증 전 입력한 social값 저장하기
        const socialInfo: Partial<ISocialAuthLoginRs> = {
          openId: jdaAuth?.openId,
          openIdProvider: jdaAuth?.openIdProvider,
          email: jdaAuth?.email,
          name: jdaAuth?.name,
          phoneNumber: jdaAuth?.phoneNumber,
        };
        saveSession('socialInfo', socialInfo);
        joinModel.socialJoinInfo = jdaAuth;
        checkMatchLogin();
        history.push(RoutePaths.join);
      }
    } catch (e) {
      onFailSocialLogin(e);
    }
  };

  return (
    <StyledSocialLoginFrame>
      <div className='divide-frame'><div className='divide'>SNS 계정으로 로그인</div></div>
      <KaKaoLoginButton
        onSuccess={processSocialLogin}
        onFail={onFailSocialLogin}
      />
      <GoogleLoginButton
        onSuccess={processSocialLogin}
        onFail={onFailSocialLogin}
      />
      <AppleLogin
        onSuccess={processSocialLogin}
        onFail={onFailSocialLogin}
      />
    </StyledSocialLoginFrame>
  );
});

export default inject(injectStore.login, injectStore.joinModel)(observer(SocialLoginForm));
