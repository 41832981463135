import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import React, { InputHTMLAttributes, MouseEvent as ReactMouseEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const Frame = styled.div<{ isFocus: boolean, isError: boolean, isSuccess: boolean, isDisabled: boolean, maxOptionCount: number}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'auto')};

  & * {
    user-select: none;
  }

  & > div:first-child {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 48px;
    padding-left: 14px;
    padding-right: 12px;
    border: 1px solid ${(props) => (props.isError ? Colors.ERROR : props.isSuccess ? Colors.G_150 : Colors.CG_40)};
    border-radius: 4px 4px ${(props) => (props.isFocus ? 0 : 4)}px ${(props) => (props.isFocus ? 0 : 4)}px;
    background-color: ${Colors.CG_30};
    
    & input {
      flex-grow: 1;
      width: 100%;
      height: fit-content;
      border: none;
      background-color: ${Colors.CG_30};
      font: ${Fonts.B2_Medium};
      color: ${Colors.JOBDA_BLACK};
      caret-color: transparent;
      
      &:focus {
        outline: none;
      }
      
      &::placeholder {
        color: ${Colors.CG_60};
      }
      
      &::-webkit-contacts-auto-fill-button, &::-webkit-credentials-auto-fill-button {
        mask-size: 0;
        width: 0;
      }
    }
  }

  & > div.option-list {
    width: 100%;
    height: fit-content;
    max-height: ${(props) => props.maxOptionCount * 48}px;
    border-bottom: 1px solid ${Colors.CG_40};
    border-radius: 0 0 4px 4px;
    overflow-y: auto;

    & > div.option-item {
      width: 100%;
      height: 48px;
      padding: 12px 12px 12px 14px;
      border: 1px solid ${Colors.CG_40};
      border-top: none;
      background-color: ${Colors.WHITE_100};
      font: ${Fonts.B2_Medium};
      color: ${Colors.JOBDA_BLACK};
      
      &:active {
        background-color: ${Colors.CG_30};
      }

      &:last-child {
        border: 1px solid ${Colors.CG_40};
        border-top: none;
        border-bottom: none;
      }
    }
  }
`;

interface IDropDownProps<T> extends InputHTMLAttributes<HTMLInputElement> {
  isError?: boolean;
  isSuccess?: boolean;
  disabled?: boolean;
  optionList: (T & { value: any, label: string })[];
  maxOptionCount?: number;
  // eslint-disable-next-line no-unused-vars
  onClickOption: (value: (T & { value: any, label: string })) => void;
  initialValue?: any;
  defaultOpen?: boolean;
}

const DropDown = <T, >({ isError, isSuccess, disabled, optionList, maxOptionCount = 4, onClickOption, initialValue, defaultOpen, ...props }: IDropDownProps<T>) => {
  const [isFocus, setIsFocus] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const onClickOptionBtn = (e: ReactMouseEvent) => {
    const value = e.currentTarget.getAttribute('data-value');
    const label = e.currentTarget.getAttribute('data-label');
    onClickOption({ value, label } as T & { value: any, label: string });
    setIsFocus(false);
  };

  const getLabel = () => {
    const option = optionList.find((item) => String(item.value) === String(initialValue));
    return option ? option.label : '';
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        setIsFocus(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [ref]);

  useEffect(() => {
    if (defaultOpen) setIsFocus(true);
  }, [defaultOpen]);

  return (
    <Frame isFocus={isFocus} isError={!!isError} isSuccess={!!isSuccess} isDisabled={!!disabled} maxOptionCount={maxOptionCount} ref={ref}>
      <div>
        <input type='text' inputMode='none' readOnly placeholder={props.placeholder} onFocus={() => setIsFocus(true)} value={getLabel()} {...props} />
        <Icon name={isFocus ? 'arrow-top' : 'arrow-bottom'} size={24} color={Colors.JOBDA_BLACK} />
      </div>
      { isFocus && (
        <div className='option-list'>
          { optionList.map((option) => (
            <div role='button' data-value={option.value} data-label={option.label} className='option-item' key={option.value} onClick={onClickOptionBtn} onContextMenu={(e) => e.preventDefault()}>{ option.label }</div>
          ))}
        </div>
      )}
    </Frame>
  );
};

export default DropDown;
