import React, { FC, ReactElement } from 'react';
import IComponentProps from 'interfaces/props/IComponentProps';
import styled from 'styled-components';
import colors from '__designkit__/common/colors';
import { fonts, lineHeights } from '__designkit__/common/fonts';
import IconArrowRight from 'assets/icon/icon_arrow_right_small_round.svg';
import JDALink from './JDALink';

//* * 잡다 소개 하단 영역 */

interface IJDAWhyBottomBannerProps extends IComponentProps {
  icon:ReactElement;
  message: string;
  title: string|ReactElement;
  subTitle?: string|ReactElement;
  url: string;
}

const Frame = styled.article`
  position: relative;
  width:100%;
  height:206px;
  background: ${colors.G_100};
  padding-left:24px;
  padding-top:39px;

  * { white-space:pre-line; }
  
  h2{
    ${fonts.NOTO_13_700};
    color:${colors.CG_80};
    ${lineHeights.LINEHEIGHT_1_50};
  }

  h1{
    margin-top:12px;
    ${fonts.NOTO_20_700};
    color:${colors.CG_90};
  }

  div{
    margin-top:32px;
    display: flex;
    align-items:center;
    ${fonts.NOTO_13_400};
    ${lineHeights.LINEHEIGHT_1_40}
    color:${colors.CG_80};
      img{
        margin-top:2px;
      }
  }
`;

const JDAWhyBottomBanner:FC<IJDAWhyBottomBannerProps> = ({ icon, message, title, subTitle, url, className }) => (
  <JDALink to={url}>
    <Frame className={className}>
      {subTitle && <h2>{subTitle}</h2>}
      <h1 className='frame-title'>{title}</h1>
      <div>{message}<img src={IconArrowRight} alt='icon-arrow' /></div>
      {icon}
    </Frame>
  </JDALink>
);

export default JDAWhyBottomBanner;
