import React, { FC } from 'react';
import styled from 'styled-components';
import Icon from '__designkit__/icon/Icon';
import Fonts from '__designkit__/common/fonts';
import Colors from '__designkit__/common/colors';
import JDLinkButton from 'components/_v2/_common/button/JDLinkButton';
import IComponentProps from 'interfaces/props/IComponentProps';

const Frame = styled.div`
  display: flex;
  flex-direction: row;

  & > div {
    font: ${Fonts.B3_Medium};
    color: ${Colors.CG_70};
  }

  & > div:last-child {
    font: ${Fonts.B3_Bold};
    color: ${Colors.JOBDA_BLACK};
  }
`;

export type BreadcrumbItem = {
  title: string;
  url: string;
}

interface IBreadcrumbNavigationProps extends IComponentProps {
  items: BreadcrumbItem[];
}

const BreadcrumbNavigation: FC<IBreadcrumbNavigationProps> = ({ items = [] }) => (
  <Frame>
    { items.map((item, itemIndex) => (
      <>
        <JDLinkButton key={Math.random()} path={item.url} text={item.title} />
        { itemIndex !== items.length - 1 && <Icon name='large-arrow-right' size={16} color={Colors.CG_60} style={{ margin: '0 4px' }} /> }
      </>
    ))}
  </Frame>
);

export default BreadcrumbNavigation;
