import colors from '__designkit__/common/colors';

const Shadows = {
  Shadow_100: 'drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.12))',
  Shadow_200: 'drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.12))',
  Shadow_300: 'drop-shadow(0px 16px 25px rgba(0, 0, 0, 0.12))',
  Shadow_400: 'drop-shadow(0px 20px 50px rgba(0, 0, 0, 0.25))',
  headers: `box-shadow: 0px 1px 1px ${colors.CG_40};`,
};

export default Shadows;
