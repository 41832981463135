import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import ImgBook from 'assets/_v2/company/img_matching_book.png';
import JDDescriptionInput from 'components/_v2/_common/input/JDDescriptionInput';
import JDRadioInput, { JD_RADIO_INPUT_TYPE } from 'components/_v2/_common/input/JDRadioInput';
import JDSquareCheckBox from 'components/_v2/_common/input/JDSquareCheckBox';
import JDFullModal from 'components/_v2/_common/modals/JDFullModal';
import { JDModalButton } from 'components/_v2/_common/modals/JDModalComponents';
import { TOAST_TYPE } from 'components/_v2/_common/toast/JDToast';
import ConditionCheckModalContent from 'components/_v2/profile/match/ConditionCheckModalContent';
import { RejectReasonType, RejectTextType } from 'components/jobMatch/dashboard/MatchOfferDeniedModal';
import MatchAtsFailModal from 'components/modals/MatchAtsFailModal';
import ErrorCode from 'consts/ErrorCodes';
import RoutePaths from 'consts/RoutePaths';
import useToast from 'hooks/useToast';
import { IMatchMessageProps } from 'interfaces/IMatchMessageProps';
import IModalFrameProps from 'interfaces/IModalFrameProps';
import { IMatchingRejectRq } from 'interfaces/_v2/matchApply/IMatchingRejectRq';
import { inject, observer } from 'mobx-react';
import MatchMessageStore from 'models/MatchMessageStore';
import CompanyModel from 'models/_v2/CompanyModel';
import MatchApplyStatusModel from 'models/_v2/MatchApplyStatusModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import styled from 'styled-components';

const Frame = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 56px 24px 80px;

      .close-btn {
        position: absolute;
        text-align: right;
        width: 100%;
        top: 16px;
        right: 16px;
    }

    .title {
        font: ${Fonts.H5_Bold};
        color: ${colors.JOBDA_BLACK};
        margin-bottom: 8px;
    }

    .sub-title {
        text-align: center;
        font: ${Fonts.B2_Medium_P};
        color: ${colors.CG_70};
    }
`;
const BannerFrame = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 24px;
  padding:20px 0 20px 24px;
  width: 100%;
  background: linear-gradient(90deg, #4BD667 0%, #40CCC6 100%);
  border-radius: 8px;
  .title-text{
    font:${Fonts.B1_Bold};
    color:${colors.WHITE_100};
    margin-bottom: 12px;
  }
  .sub-text{
    font:${Fonts.B3_Medium_P};
    color:${colors.WHITE_100};
  }
  .img{
    position: absolute;
    bottom:-68px;
    right:-21px;
    width: 183px;
  }
`;

const OptionFormFrame = styled.div`
    margin: 32px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .jd-input {
        margin-bottom: 24px;

        .label {
            font: ${Fonts.B2_Medium};
            color: ${colors.CG_90};
        }
    }

    .check-frame {
        display: flex;
        align-items: center;
        padding-left: 24px;

        .jd-input {
          margin-right: 32px;
          font: ${Fonts.B2_Medium};
          color: ${colors.CG_90};
        }
    }
`;

const ButtonFrame = styled.div`
    position: fixed;
    padding: 16px;
    bottom: calc(env(safe-area-inset-bottom));
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: ${colors.WHITE_100};
    border-top: 1px solid ${colors.CG_40};
`;

interface IMatchOfferDeniedModalProps extends IModalFrameProps {
  matchApplyStatusModel?: MatchApplyStatusModel;
  companyModel?:CompanyModel;
  isReport?:boolean;
  matchMessageStore?: MatchMessageStore;
  onClickApply?: (e:any) => void;
  positionSn: number;
  optionSn: number;
}

const MatchMessageDeniedModal:FC<IMatchOfferDeniedModalProps & IMatchMessageProps> = (props) => {
  const { onClickClose, onClickApply, positionSn, optionSn, matchMessageStore = new MatchMessageStore(), matchApplyStatusModel = new MatchApplyStatusModel(), companyModel = new CompanyModel(), isReport } = props;
  const useformed = useForm({ mode: 'onSubmit' });
  const { watch } = useformed;
  const [atsFailModalOpen, setAtsFailModalOpen] = useState<boolean>(false);
  const [conditionPopupOpen, setConditionPopupOpen] = useState<boolean>(false);
  const history = useHistory();
  const { setToastObject } = useToast();

  const onSubmit = async () => {
    try {
      if (optionSn !== -1) {
        const data = useformed.getValues();
        const rejectType:RejectReasonType = data.rejectReason;
        const rq: IMatchingRejectRq = {
          positionSn,
          optionSn,
          rejectReason: RejectTextType[rejectType] || null,
          rejectReasonType: rejectType,
          subReasons: data.subReasons ? data.subReasons.filter((item: string) => item) : null,
        };
        if (data.rejectReason === RejectReasonType.OTHER) {
          rq.rejectReason = watch('textArea');
        }
        await matchMessageStore.RefuseInterview(rq);
        await matchMessageStore.loadMessageList(rq.positionSn);

        setToastObject({ isOpen: true, type: TOAST_TYPE.SUCCESS, message: '제안을 거절하셨습니다.', duration: 1500 });
        if (onClickClose) onClickClose();
      }
    } catch (e: any) {
      switch (e.response?.data?.errorCode) {
        case ErrorCode.B901:
          setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '채용 전형이 종료된 포지션입니다.', duration: 1500 });
          break;
        case ErrorCode.B902:
          setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '삭제된 포지션입니다.', duration: 1500 });
          break;
        case ErrorCode.B903:
          setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '유효하지 않은 요청입니다.', duration: 1500 });
          break;
        case ErrorCode.B905:
          setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '존재하지 않는 선택지입니다.', duration: 1500 });
          break;
        case ErrorCode.B906:
          setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '면접 제안 이력이 없습니다.', duration: 1500 });
          break;
        case ErrorCode.B908:
          setAtsFailModalOpen(true);
          break;
        default:
          setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '지원에 실패했습니다. 다시 시도해 주세요.', duration: 1500 });
      }
      console.error(e);
    }
  };

  return (
    <JDFullModal>
      <FormProvider {...useformed}>
        <Frame>
          <Icon name='close' className='close-btn' size={32} onClick={onClickClose} />
          {!conditionPopupOpen
            ? (
              <>
                <div className='title'>제안을 거절하시겠어요?</div>
                <div className='sub-title'>걱정하지 마세요! 거절에 따른 불이익은 없습니다.<br />
                  더 좋고, 정확한 제안을 드리기 위해 사유를 알려주세요.
                </div>
                <OptionFormFrame>

                  <JDRadioInput
                    key='radio1'
                    type={JD_RADIO_INPUT_TYPE.ROUND_CIRCLE_BOX}
                    name='rejectReason'
                    value={RejectReasonType.INDIVIDUAL_REASON}
                    label={(<div>{RejectTextType.INDIVIDUAL_REASON}</div>)}
                  />
                  <JDRadioInput
                    key='radio1'
                    type={JD_RADIO_INPUT_TYPE.ROUND_CIRCLE_BOX}
                    name='rejectReason'
                    value={RejectReasonType.CONTACT_ANOTHER_COMPANY}
                    label={(<div>{RejectTextType.CONTACT_ANOTHER_COMPANY}</div>)}
                  />
                  <JDRadioInput
                    key='radio4'
                    type={JD_RADIO_INPUT_TYPE.ROUND_CIRCLE_BOX}
                    name='rejectReason'
                    value={RejectReasonType.OTHER}
                    label={(<div>직접 입력</div>)}
                  />
                  {
                      watch('rejectReason') === RejectReasonType.OTHER && (
                      <JDDescriptionInput
                        name='textArea'
                        placeholder='거절 사유를 입력해 주세요.'
                        maxLength={50}
                        height={66}
                      />
                      )
                  }
                </OptionFormFrame>
                <ButtonFrame>
                  <JDModalButton
                    disabled={!watch('rejectReason')
                      || (watch('rejectReason') === RejectReasonType.OTHER && !watch('textArea'))
                      || (watch('rejectReason') === RejectReasonType.WORKING_PREFERENCE && !(watch('subReasons') ? watch('subReasons').filter((item: string) => item).length > 0 : undefined))}
                    onClick={onSubmit}
                  >확인
                  </JDModalButton>
                </ButtonFrame>
              </>
            )
            : (
              <ConditionCheckModalContent
                onClickClose={() => {
                  if (onClickClose) onClickClose();
                }}
              />
            )}
        </Frame>
      </FormProvider>
      <MatchAtsFailModal
        isOpen={atsFailModalOpen}
        onClickClose={() => setAtsFailModalOpen(false)}
      />

    </JDFullModal>
  );
};

export default MatchMessageDeniedModal;
