import { ComponentProps } from '@ionic/core/components';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import ImgShareChallenge from 'assets/_v2/share/img_share_challenge.png';
import ImgShareJobs from 'assets/_v2/share/img_share_jobs.png';
import ImgSharePosition from 'assets/_v2/share/img_share_position.png';
import ImgSharePassboard from 'assets/_v2/share/img_share_passboard.png';

import DisableScroll from 'components/common/DisableScroll';
import Portal from 'components/common/Portal';
import RoutePaths from 'consts/RoutePaths';
import {
  IconClose,
  IconPageShare,
  IconPageShareFacebook,
  IconPageShareKakao,
  IconPageShareLink,
} from 'consts/assets/icons/iconPages';
import { clearIonButtonStyle, dimmer } from 'consts/style/mixins';
import useToast from 'hooks/useToast';
import IComponentProps from 'interfaces/props/IComponentProps';
import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components/macro';
import { copyText } from 'utils/ClipboardUtils';
import { IShareKakaoTalkParams, shareKakaoTalk } from 'utils/KakaoUtils';

const Frame = styled.div`
  display:none;
  position:fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  z-index:10004;
  .dimmer {
    ${dimmer()};
  }
  .frame-share {
    width:100%;
    height: fit-content;
    background:${colors.WHITE_100};
    position: fixed;
    bottom:0;
    z-index:10;
    .title {
      display:flex;
      justify-content:center;
      align-items:center;
      font: ${Fonts.H5_Bold};
      padding-top:12px;
      padding-bottom:12px;
      border-bottom:1px solid ${colors.CG_40};
      width: 100%;
      height: fit-content;
    }

    .icon-close {
      position: absolute;
      right: 16px;
    }

    .btn-close {
      height:52px;
      width:100%;
      ${clearIonButtonStyle()};
      font: ${Fonts.H5_Bold};
      color:${colors.WHITE_100};
      --padding-top:16px;
      --padding-bottom:16px;
      text-align:center;
      background:${colors.G_100};
    }

    .ul-share {
      width:100%;
      height: fit-content;
      padding:24px 0 calc(env(safe-area-inset-bottom) + 24px);
      display:flex;
      flex-direction:row;
      justify-content:center;
      align-items:center;
      background:${colors.JOBDA_WHITE};
      > *:not(:first-child) {
        margin-left:25px;
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
      }
      .btn-share {
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        cursor:pointer;
      }
      .frame-label {
        margin-top:8px;
        font: ${Fonts.B2_Medium};
        text-align:center;
        color:${colors.CG_80};
      }
    }
  }

  &.open {
    display:flex;
    animation: modal-fade-in .5s ease forwards;
    .frame-share {
      animation: modal-bottom-up .5s ease forwards;
    }
  }

  @keyframes modal-fade-in {
    0% {
      opacity:0;
    }
    100% {
      opacity:100%;
    }
  }
  @keyframes modal-bottom-up {
    0% {
      transform: translate(0,100%);
      opacity:0;
    }
    100% {
      transform: translate(0,0);
      opacity:100%;
    }
  }
`;

const ShareButtonFrame = styled.button`
  ${clearIonButtonStyle()};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 40px;
  height: 40px;
`;

export const ShareButton: FC<ComponentProps<HTMLButtonElement>> = (props) => (
  <ShareButtonFrame {...props}>
    <IconPageShare />
  </ShareButtonFrame>
);
// const data = {
//   kakao={{
//     title: '잡다',
//     description: '기업·면접·합격·역량검사까지\n취업의 진짜 정보만 잡다!',
//     mobileWebUrl: document.location.href,
//     webUrl: document.location.href,
//   }}
// facebook = {{
//   u: document.location.href,
//     quote: '잡다',
//   }}
// }
export interface IShareFacebookParams {
  u?: string;
  quote?: string;
}

export interface IShareModalProps extends IComponentProps {
  open?: boolean;
  onClose: () => void;
  shareDescription?: string;
}

const ShareModal: FC<IShareModalProps> = ({ open = false, onClose, shareDescription }) => {
  const { setToastObject } = useToast();
  const [kakaoData, setKakaoData] = useState<IShareKakaoTalkParams>({});
  const [facebookData, setFacebookData] = useState<IShareFacebookParams>({});
  const { pathname } = useLocation();

  useEffect(() => {
    if (open) {
      const webUrl = `https://www.jobda.im${pathname}`;
      const mobileUrl = `https://m.jobda.im${pathname}`;
      switch (`/${pathname.split('/')[1]}`) {
        case RoutePaths.position:
          setKakaoData({
            title: '[잡다] 포지션(공고)을 확인해보세요!',
            description: '잡다에서 포지션 확인하고 지원까지 해보세요!',
            imageUrl: `https://m.jobda.im${ImgSharePosition}`,
            mobileWebUrl: webUrl,
            webUrl: mobileUrl,
          });
          setFacebookData({
            u: webUrl,
            quote: '[잡다] 포지션(공고)을 확인해보세요!',
          });
          break;
        case RoutePaths.challenge:
          setKakaoData({
            title: '[잡다] 챌린지를 확인해보세요!',
            description: '잡다 챌린지에선 다양한 포지션 펑펑 쏟아져요! 어서 확인해보세요.',
            imageUrl: `https://m.jobda.im${ImgShareChallenge}`,
            mobileWebUrl: webUrl,
            webUrl: mobileUrl,
          });
          setFacebookData({
            u: webUrl,
            quote: '[잡다] 챌린지를 확인해보세요!',
          });
          break;
        case RoutePaths.jobs:
          setKakaoData({
            title: '[잡다] 채용 공고를 확인해보세요!',
            description: shareDescription || '다양한 포지션 펑펑 쏟아져요! 어서 확인해보세요.',
            imageUrl: `https://m.jobda.im${ImgShareJobs}`,
            mobileWebUrl: webUrl,
            webUrl: mobileUrl,
          });
          setFacebookData({
            u: webUrl,
            quote: '[잡다] 채용 공고를 확인해보세요!',
          });
          break;
        case RoutePaths.passboard:
          setKakaoData({
            title: '[잡다] 합격한 선배들의 이야기를 들어보세요!',
            description: shareDescription || '지원부터 합격까지 필요한 모든 꿀팁이 한 곳에 모였다.',
            imageUrl: `https://m.jobda.im${ImgSharePassboard}`,
            mobileWebUrl: webUrl,
            webUrl: mobileUrl,
          });
          setFacebookData({
            u: webUrl,
            quote: '[잡다] 합격한 선배들의 이야기를 들어보세요!',
          });
          break;
        default:
          setKakaoData({
            title: '[잡다] 매칭으로 합격 순간을 잡다',
            description: '매칭으로 나에게 적합한 포지션에 지원해보세요!',
            imageUrl: `https://m.jobda.im${ImgSharePosition}`,
            mobileWebUrl: webUrl,
            webUrl: mobileUrl,
          });
          setFacebookData({
            u: webUrl,
            quote: '잡다',
          });
          break;
      }
    }
  }, [open]);

  return !open ? <></> : (
    <Portal>
      <DisableScroll />
      <Frame className={`${open && 'open'}`}>
        <div role='presentation' className='dimmer' onClick={onClose} />
        <div className='frame-share'>
          <div className='title'>
            공유하기
            <IconClose className='icon-close' onClick={() => onClose()} />
          </div>
          <ul className='ul-share'>
            <li
              role='presentation'
              className='btn-share'
              onClick={() => shareKakaoTalk(kakaoData)}
            >
              <IconPageShareKakao />
              <div className='frame-label'>카카오톡</div>
            </li>
            <a href={`https://www.facebook.com/sharer/sharer.php?u=${facebookData.u}&quote=${facebookData.quote}`} target='_blank' rel='noopener noreferrer'>
              <li className='btn-share'>
                <IconPageShareFacebook />
                <div className='frame-label'>페이스북</div>
              </li>
            </a>
            <li
              role='presentation'
              className='btn-share'
              onClick={() => {
                copyText(`https://www.jobda.im${pathname}`);
                setToastObject({ isOpen: true, type: 'INFO', message: '링크가 복사되었습니다.' });
              }}
            >
              <IconPageShareLink />
              <span className='frame-label'>링크복사</span>
            </li>
          </ul>
        </div>
      </Frame>
    </Portal>
  );
};

export default ShareModal;
