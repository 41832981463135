import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import RoutePaths from 'consts/RoutePaths';
import { CHATTING_TODAY_RENDER_CHECK_STRING } from 'consts/_v2/chatting/chatting';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import MobileStore from 'store/mobileStore';
import styled from 'styled-components';
import TodayRenderCheckUtil from 'utils/TodayRenderCheckUtil';

const Frame = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: fit-content;
  padding: 24px 16px 16px;
  border-radius: 16px 16px 0 0;
  background-color: ${Colors.CG_30};
  font: ${Fonts.B3_Medium};
  z-index: 1;

  & > .btn-frame {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: fit-content;
    padding: 9px 0;
    border-radius: 100px;
    background-color: ${Colors.JOBDA_BLACK};
    color: ${Colors.WHITE_100};

    &:active {
      background-color: ${Colors.CG_70};
    }
  }

  & > .close-btn {
    position: absolute;
    top: 8px;
    right: 8px;
  }
`;

const ChattingAppInduction = () => {
  const location = useLocation();
  const [isAppInduction, setIsAppInduction] = useState(false);

  const onClickCloseBtn = () => {
    TodayRenderCheckUtil.setTodayRenderCheck(CHATTING_TODAY_RENDER_CHECK_STRING.chattingAppInduction);
    setIsAppInduction(false);
  };

  const onClickAppInduction = () => {
    MobileStore.moveToStore();
    onClickCloseBtn();
  };

  useEffect(() => {
    if (MobileStore.isMobile) return;
    (async () => {
      if (!(await TodayRenderCheckUtil.getTodayRenderCheck(CHATTING_TODAY_RENDER_CHECK_STRING.chattingAppInduction)) && location.pathname === RoutePaths.chatList) setIsAppInduction(true);
      else setIsAppInduction(false);
    })();
  }, [location.pathname]);

  return !isAppInduction ? <></> : (
    <Frame>
      <div className='text'>앱 설치하고 내 메시지에 대한 반응, 답글을 놓치지 마세요!</div>
      <div className='btn-frame' role='button' onClick={onClickAppInduction}>
        앱으로 빠르게 알림 받기
        <Icon name='arrow-right' size={24} color={Colors.WHITE_100} />
      </div>
      <div className='close-btn' role='button' onClick={onClickCloseBtn}>
        <Icon name='close' size={24} color={Colors.JOBDA_BLACK} />
      </div>
    </Frame>
  );
};

export default ChattingAppInduction;
