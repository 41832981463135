import ImgJoyEmpty from 'assets/_v2/positionList/img_character_joy_empty.png';
import MatchHomeDashboardHiddenCard from 'components/_v2/matchHome/dashboard/MatchHomeDashboardHiddenCard';
import MainHeader from 'components/_v2/_common/mainHeader/MainHeader';
import JDFullScreenBaseModal from 'components/_v2/_common/modals/JDFullScreenBaseModal';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import MatchHomeDashboardModel from 'models/_v2/MatchHomeDashboardModel';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { MatchTabType } from 'consts/_v2/match/MatchType';
import MatchHomeDashboardCard from 'components/_v2/matchHome/dashboard/MatchHomeDashboardCard';
import shadows from 'consts/style/shadows';
import MyPageApplyPdfButton from 'components/_v2/mypage/MyPageApplyPdf';
import Icon from '__designkit__/icon/Icon';

interface IMyPageApplyPdfModal {
  isOpen: boolean;
  onClickClose: () => void;
  matchHomeDashboardModel?:MatchHomeDashboardModel;
}

const Frame = styled.div`
  
`;
const BottomButtonFrame = styled.div`
    display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: fit-content;
  background: ${colors.WHITE_100};
  border-top: 1px solid ${colors.CG_40};
  z-index: 1000;
  padding: 13px 20px calc(env(safe-area-inset-bottom) + 13px);
  ${shadows.base};
`;
const TotalClickFrame = styled.div`
    display: flex;
    justify-content: end;
    padding:12px 16px;
    border-bottom: 1px solid ${colors.CG_40};
    .text{
        font:${Fonts.B2_Bold_P};
        color:${colors.CG_90};
        margin-right: 8px;
    }
`;
const MyPageApplyPdfModal: FC<IMyPageApplyPdfModal> = ({ isOpen, onClickClose, matchHomeDashboardModel = new MatchHomeDashboardModel() }) => {
  const [selectedPositions, setSelectedPositions] = useState<number[]>([]);
  const [isTotalSelected, setIsTotalSelected] = useState(true);
  const loadHistories = async () => {
    await matchHomeDashboardModel.loadApplicationHistories(MatchTabType.ALL);
    if (matchHomeDashboardModel.resumes) {
      setSelectedPositions(matchHomeDashboardModel.resumes.jobApplicationProcesses.map((resume) => resume.positionSn));
    }
  };

  useEffect(() => {
    if (isOpen) {
      loadHistories();
    }
  }, [isOpen]);
  const handleCardClick = (positionSn: number) => {
    setSelectedPositions((prevSelected:any) => (prevSelected.includes(positionSn)
      ? prevSelected.filter((sn:number) => sn !== positionSn)
      : [...prevSelected, positionSn]));
  };

  useEffect(() => {
    if (matchHomeDashboardModel.resumes) {
      if (selectedPositions.length === matchHomeDashboardModel.resumes.jobApplicationProcesses.length) {
        setIsTotalSelected(true);
      } else {
        setIsTotalSelected(false);
      }
    }
  }, [selectedPositions]);
  const handleTotalClick = () => {
    if (matchHomeDashboardModel.resumes) {
      if (isTotalSelected) {
        setSelectedPositions([]);
      } else {
        setSelectedPositions(matchHomeDashboardModel.resumes.jobApplicationProcesses.map((resume) => resume.positionSn));
      }
      setIsTotalSelected(!isTotalSelected);
    }
  };
  return (
    <JDFullScreenBaseModal isOpen={isOpen} onClose={onClickClose} buttonType='none'>
      <MainHeader title='취업활동 증명서' onClose={onClickClose} close search={false} gnb={false} alarm={false} />
      <TotalClickFrame onClick={handleTotalClick}>
        <div className='text'>전체 선택</div>
        {
     isTotalSelected
       ? <Icon name='checkbox' size={24} color={colors.G_150} className='icon-more-line' />
       : <Icon name='checkbox-empty' size={24} color={colors.G_150} className='icon-more-line' />
}
      </TotalClickFrame>
      <Frame>
        {matchHomeDashboardModel.resumes && matchHomeDashboardModel.resumes.jobApplicationProcesses.length > 0 && matchHomeDashboardModel.resumes.jobApplicationProcesses.map((resume, index) => (
          <MatchHomeDashboardCard
            key={index}
            type={MatchTabType.ALL}
            item={resume}
            pdfModalOpen
            isSelected={selectedPositions.includes(resume.positionSn)}
            onCardClick={() => handleCardClick(resume.positionSn)}
          />
        ))}

      </Frame>
      <BottomButtonFrame>
        <MyPageApplyPdfButton selectedPositionSn={selectedPositions} />
      </BottomButtonFrame>
    </JDFullScreenBaseModal>

  );
};

export default inject(injectStore.matchHomeDashboardModel)(observer(MyPageApplyPdfModal));
