import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Shadows from '__designkit__/common/shadows';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import ChattingAPI from 'api/chattingAPI';
import { TOAST_TYPE } from 'components/_v2/_common/toast/JDToast';
import ChattingList from 'components/_v2/chatting/ChattingList';
import RoutePaths from 'consts/RoutePaths';
import { CHATTING_TODAY_RENDER_CHECK_STRING } from 'consts/_v2/chatting/chatting';
import useToast from 'hooks/useToast';
import { ChattingServerError, GetCompanyChannelSnRs } from 'interfaces/_v2/chatting/IChattingRqRs';
import IComponentProps from 'interfaces/props/IComponentProps';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import Login from 'models/Login';
import ChattingModel from 'models/_v2/ChattingModel';
import { injectStore } from 'models/store';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { Event, EventTypeEnum, Member, MessageTypeEnum } from 'talkplus-sdk';
import CommonUtils from 'utils/CommonUtils';
import TodayRenderCheckUtil from 'utils/TodayRenderCheckUtil';

const Frame = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${Colors.CG_40};
  transform: rotate(0deg);
`;

const InputBox = styled.div<{ isKeyboard: boolean }>`
  position: fixed;
  bottom: ${({ isKeyboard }) => (isKeyboard ? 'calc(env(safe-area-inset-bottom) * -1)' : '0px')};
  display: flex;
  align-items: center;
  width: 100%;
  height: fit-content;
  padding: 16px 12px 16px 20px;
  background-color: ${Colors.WHITE_100};
  transition: bottom .4s;

  & > textarea {
    width: 100%;
    height: 32px;
    max-height: 32px;
    border: none;
    font: ${Fonts.B3_Medium};
    color: ${Colors.JOBDA_BLACK};
    white-space: pre-wrap;
    outline: none;

    &::placeholder {
      font: ${Fonts.B3_Medium};
      color: ${Colors.CG_60};
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  & > #hidden-textarea {
    width: 0px;
    height: 0px;
    pointer-events: none;
  }

  & > .vertical-divider {
    width: 1px;
    height: 100%;
    margin: 0 8px;
    background-color: ${Colors.CG_40};
  }

  & > .enter-icon-frame {
    position: absolute;
    top: 16px;
    left: 20px;
    transform: scaleX(-1);
    z-index: 1;
  }
`;

const ReplyMessageFrame = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: fit-content;
  padding: 12px 17px 12px 24px;
  border-bottom: 1px solid ${Colors.CG_50};
  background-color: ${Colors.CG_30};
  z-index: 1;

  & > div.parent-message {
    width: 80%;

    & > span {
      display: inline-block;
    }

    & > span.parent-message-username {
      font: ${Fonts.B4_Medium};
      color: ${Colors.JOBDA_BLACK};
    }

    & > div.parent-message-text {
      width: 100%;
      height: 16px;
      font: ${Fonts.B3_Medium};
      color: ${Colors.CG_60};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

const FloatingBtn = styled.div<{ isReplyMessage: boolean }>`
  position: fixed;
  bottom: ${({ isReplyMessage }) => (isReplyMessage ? 139 : 80)}px;
  right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid ${Colors.CG_40};
  border-radius: 50%;
  background-color: ${Colors.WHITE_100};
  filter: ${Shadows.Shadow_200};
  user-select: none;
`;

const FloatingFrame = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: fit-content;
  height: fit-content;
  padding: 16px 16px 0 0;

  & > .floating-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: ${Colors.CG_90};
    font: ${Fonts.B3_Medium};
    color: ${Colors.WHITE_100};
    filter: ${Shadows.Shadow_200};
    user-select: none;
    transition: width .3s;

    > span {
      width: 0px;
      overflow: hidden;
      white-space: nowrap;
      transition: width .3s;
    }

    &.bottom {
      width: 100px;
      padding: 0 16px 0 12px;

      > span {
        width: fit-content;
      }
    }
  }
`;

interface IChattingRoomProps extends IComponentProps {
  isUserInit: boolean;
  context?: Context;
  login?: Login;
  chattingModel?: ChattingModel;
}

const ChattingRoom: FC<IChattingRoomProps> = ({ isUserInit, context = new Context(), login = new Login(), chattingModel = new ChattingModel() }) => {
  const history = useHistory();
  const { setToastObject } = useToast();
  const { channelId } = CommonUtils.parseQuery(history.location.search);

  const [companyChannelInfo, setCompanyChannelInfo] = useState<GetCompanyChannelSnRs | undefined>(undefined);
  const [isBottom, setIsBottom] = useState(true);
  const [text, setText] = useState('');
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const hiddenRef = useRef<HTMLTextAreaElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = useCallback(() => {
    if (listRef.current) {
      listRef.current.scrollTop = -1;
      listRef.current.scrollTop = 0;
    }
  }, [listRef]);

  const onClickSendBtn = async () => {
    if (!text) return;
    if (hiddenRef.current) hiddenRef.current.focus();
    if (inputRef.current) inputRef.current.focus();
    const res = await chattingModel.client.sendMessage({
      channelId: chattingModel.currentChannel ? chattingModel.currentChannel.id : '',
      type: MessageTypeEnum.Text,
      text,
      parentMessageId: chattingModel.replyMessage ? chattingModel.replyMessage.id : undefined,
    });
    const isExist = !!chattingModel.messageList.find((message) => message.id === res.message?.id);
    if (!isExist) chattingModel.setMessageList([res.message], 'add');
    chattingModel.setReplyMessage(null);
    setText('');
    scrollToBottom();
  };

  const controlWarningMessage = async () => {
    const isShow = await TodayRenderCheckUtil.getTodayRenderCheck(CHATTING_TODAY_RENDER_CHECK_STRING.chattingRoomWarning);
    if (!isShow) {
      setToastObject({
        type: TOAST_TYPE.INFO,
        isOpen: true,
        justSubMessage: true,
        message: '',
        subMessage: '폭언, 음란, 불법 행위, 상업적 홍보 등 채팅방 사용을 저해하는 활동에 대해 메시지 삭제 및 계정 정지 조치를 할 수 있습니다.',
        bottom: 72,
        duration: 3000,
      });
      TodayRenderCheckUtil.setTodayRenderCheck(CHATTING_TODAY_RENDER_CHECK_STRING.chattingRoomWarning);
    }
  };

  const checkCompanyChannel = async () => {
    try {
      const res = await ChattingAPI.getCompanySnByChannelId(chattingModel.currentChannel!.id);
      setCompanyChannelInfo(res);
    } catch (e) {
      const err = e as { response: { data: { message: string } } };
      setToastObject({ isOpen: true, type: 'ERROR', message: err.response.data.message, duration: 2000 });
    }
  };

  const chattingServerEvent = useCallback((data: Event) => {
    if (data.channel.id === chattingModel.currentChannel?.id) {
      if (data.type === EventTypeEnum.Message) {
        const isExist = !!chattingModel.messageList.find((message) => message.id === data.message?.id);
        if (!isExist) chattingModel.setMessageList(data.message ? [data.message] : [], 'add');
        if (isBottom) scrollToBottom();
      } else if (data.type === EventTypeEnum.MemberAdded || data.type === EventTypeEnum.MemberLeft) {
        const temp = chattingModel.currentChannel;
        temp.memberCount = data.channel.memberCount;
        chattingModel.setCurrentChannel(temp);
      }
    }
  }, [chattingModel, scrollToBottom, isBottom]);

  useEffect(() => {
    chattingModel.client.on('event', chattingServerEvent);

    return () => {
      chattingModel.client.off('event', chattingServerEvent);
    };
  }, [chattingModel, chattingServerEvent]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.addEventListener('scroll', () => {
        if (listRef.current) setIsBottom(listRef.current.scrollTop >= -10);
      });
    }
  }, [listRef]);

  useEffect(() => {
    (async () => {
      await ChattingAPI.postChannelEnterLog(channelId);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (!isUserInit) return;
        if (!login.userInfo) {
          context.setRedirectUrl(`${RoutePaths.chatChannel}?channelId=${channelId}`);
          history.replace(RoutePaths.chatList);
        } else if (channelId) {
          const { channel } = await chattingModel.client.getChannel({ channelId });
          if (channel.members && channel.members.findIndex((member: Member) => member.id === chattingModel.user?.id) === -1) {
            const { channel: newChannel } = await chattingModel.client.joinChannel({ channelId });
            const notificationEnabled = await ChattingAPI.getChannelNotificationWhether(channelId);
            chattingModel.setCurrentChannel({ ...newChannel, notificationEnabled });
            chattingModel.updateChannel(channel);
          } else {
            const notificationEnabled = await ChattingAPI.getChannelNotificationWhether(channelId);
            chattingModel.setCurrentChannel({ ...channel, notificationEnabled });
            chattingModel.updateChannel(channel);
          }

          controlWarningMessage();
          checkCompanyChannel();

          if (chattingModel.isFetchAllMesaage) return;
          const res = await chattingModel.getChattingMessageList();
          chattingModel.setMessageList(res.messages, 'fetch');
          chattingModel.setLastMessageId(res.hasNext ? res.messages[res.messages.length - 1].id : undefined);
        } else {
          history.replace(RoutePaths.chatList);
        }
      } catch (e) {
        const err = e as ChattingServerError | { response: { data: { message: string } } };
        if ('response' in err) {
          setToastObject({ isOpen: true, type: 'ERROR', message: err.response.data.message, duration: 2000 });
        } else if (err.code === '2029') {
          setToastObject({ type: 'ERROR', message: '채팅은 최대 99명까지 입장할 수 있어요!', isOpen: true, duration: 2000 });
        }
        history.replace(RoutePaths.chatList);
      }
    })();

    return () => {
      chattingModel.resetChannel();
    };
  }, [channelId, isUserInit]);

  return (
    <Frame>
      <ChattingList messages={chattingModel.messageList} listRef={listRef} />
      <InputBox isKeyboard={context.keyBoardHeight > 0}>
        { chattingModel.replyMessage && (
          <ReplyMessageFrame>
            <div className='parent-message'>
              <span className='parent-message-username'>{ chattingModel.replyMessage.username } :</span>
              <SpacingBlock size={4} vertical />
              <div className='parent-message-text'>{ chattingModel.replyMessage.text }</div>
            </div>
            <Icon name='close' size={24} color={Colors.JOBDA_BLACK} onClick={() => chattingModel.setReplyMessage(null)} />
          </ReplyMessageFrame>
        )}
        { chattingModel.replyMessage && !text && (
          <div className='enter-icon-frame'>
            <Icon name='enter' size={16} color={Colors.CG_60} />
          </div>
        )}
        <textarea ref={inputRef} placeholder={chattingModel.replyMessage ? '      답장' : `${chattingModel.user?.username}님, 다른 지원자들과 함께 채용 정보를 나누어 보세요.`} value={text} onChange={(e) => setText(e.target.value)} maxLength={1000} />
        <textarea ref={hiddenRef} id='hidden-textarea' />
        <div className='vertical-divider' />
        <Icon name={text && text.length > 0 && text[0] !== ' ' && text[0] !== '\n' ? 'send-light-filled' : 'send-light'} size={24} onClick={onClickSendBtn} />
      </InputBox>
      { !isBottom && (
        <FloatingBtn isReplyMessage={!!chattingModel.replyMessage} onTouchStart={scrollToBottom} onTouchEnd={scrollToBottom}>
          <Icon name='arrow-downward' size={24} />
        </FloatingBtn>
      )}
      { companyChannelInfo?.managed && (
        <FloatingFrame>
          <div className={`floating-btn ${isBottom ? 'bottom' : ''}`} role='button' onClick={() => { history.push(RoutePaths.company_details(companyChannelInfo.companySn)); }}>
            <Icon name='business' size={28} color={Colors.WHITE_100} />
            <span>기업정보</span>
          </div>
        </FloatingFrame>
      )}
    </Frame>
  );
};

export default inject(injectStore.context, injectStore.login, injectStore.chattingModel)(observer(ChattingRoom));
