/* eslint-disable import/prefer-default-export */
import { IEmailCheckRq, IEmailCheckRs, IRouletteGameRq, IRouletteGameRs } from 'components/_v2/roulette/interface/IRouletteRqRs';
import { request } from 'utils/pcRequest';

const emailPostUrl = 'https://kr-pr-ag-2024-roulette-74oth8en.an.gateway.dev/sign-in';
const rouletteUrl = 'https://kr-pr-ag-2024-roulette-74oth8en.an.gateway.dev/roulette';

export const postEmailCheck = (body:IEmailCheckRq, options?:{onSuccess:(data:IEmailCheckRs)=>void, onError?: (e: Error) => void}) => {
  return request<IEmailCheckRs>(emailPostUrl, {
    method: 'POST',
    body: JSON.stringify(body),
    ...options,
  });
};

export const postRouletteGame = (body:IRouletteGameRq, options?: { onSuccess: (data: IRouletteGameRs) => void, onError?: (e: Error) => void}) => {
  return request<IRouletteGameRs>(rouletteUrl, {
    method: 'POST',
    body: JSON.stringify(body),
    ...options,
  });
};
