import React from 'react';

import classnames from 'classnames/bind';
import styles from './catchphrase.module.scss';

const cx = classnames.bind(styles);

const Catchphrase = () => (
  <div className={cx('wrap')}>
    <div className={cx('titleArea')}>
      <strong className={cx('title')}>스펙없이 취업을, JOBDA</strong>

      <p className={cx('desc')}>
        JOBDA는 스펙이 아닌 역량을 기반으로
        <br />
        능력과 기회를 매칭합니다
      </p>
    </div>
  </div>
);

export default Catchphrase;
