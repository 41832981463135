/* eslint-disable no-return-assign */
import Icon from '__designkit__/icon/Icon';
import ImgTagEnd from 'assets/_v2/calendar/img_calendar_tag_end.svg';
import ImgTagRolling from 'assets/_v2/calendar/img_calendar_tag_rolling.svg';
import ImgTagStart from 'assets/_v2/calendar/img_calendar_tag_start.svg';
import { LazyImageLogo } from 'components/common/LazyImage';
import { LocationCode } from 'consts/CompanyLocationType';
import RoutePaths from 'consts/RoutePaths';
import Fonts from '__designkit__/common/fonts';
import { lineClampBox } from 'consts/_v2/_common/style/mixins';
import { RecruitmentTypesText, StatusType } from 'consts/_v2/calendar/CalendarFilterType';
import Colors from '__designkit__/common/colors';
import { ICalendarMoItemDto } from 'interfaces/_v2/calendar/ICalendarList';
import ICalendarProps from 'interfaces/_v2/calendar/ICalendarProps';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import Login from 'models/Login';
import CalendarModel from 'models/_v2/CalendarModel';
import { injectStore } from 'models/store';
import React, { FC, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import { DateFormatHHMMUnit } from 'utils/DateUtils';

const Frame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;

  .content-frame {
    width: 100%;
    display: flex;
    align-items: center;
    margin-right: 32px;
    .logo-frame {
      min-width: 48px;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: inherit;
      margin-right: 12px;

      .img-fallback {
        width: 48px;
        height: 48px;
        border: 1.41176px solid rgba(68, 71, 75, 0.1);
        border-radius: 50%;
      }

      .logo {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: inherit;
        border: 1.41176px solid rgba(68, 71, 75, 0.1);
      }
    }

    .text-frame {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .company-box {
        display: flex;
        align-items: center;
        margin-bottom: 4px;

        > p {
          font: ${Fonts.B2_Bold};
          color: ${Colors.CG_90};
          ${lineClampBox(1)};
        }
      }

      .badge {
        margin-right: 5px;
      }

      .position-name {
        font: ${Fonts.B3_Medium};
        color: ${Colors.CG_80};
        margin-bottom: 2px;
        ${lineClampBox(1)};
      }

      .position-tag {
        font: ${Fonts.B3_Medium};
        color: ${Colors.CG_60};

        span {
          font: ${Fonts.B3_Medium};
          color: ${Colors.ERROR};
        }

        span::before {
          content: ' · ';
          color: ${Colors.CG_60};
        }
      }
    }
  }
`;

const BookMarkFrame = styled.div`
  .bookmark {
    width: 24px;
    height: 24px;
  }
`;

interface ICalendarListCard extends ICalendarProps {
  calendarItem: ICalendarMoItemDto;
  date: string;
}

const CalendarListCard: FC<ICalendarListCard> = ({ date, calendarItem, calendarModel = new CalendarModel(), context = new Context(), login = new Login() }) => {
  const [bookmark, setBookmark] = useState<boolean>(calendarItem.bookmarkYn);
  const history = useHistory();
  const { pathname } = useLocation();
  const checkBookmark = async (e: any) => {
    e.stopPropagation();
    if (!login.userInfo) {
      context.setRedirectUrl(pathname);
      history.push(RoutePaths.login);
    } else {
      if (!bookmark) await calendarModel.saveJobPostingsBookmark(calendarItem.jobPostingSn);
      else await calendarModel.deleteJobPostingsBookmark(calendarItem.jobPostingSn);
      setBookmark(!bookmark);
      // list에 실제로 반영시켜주기 위해 값 변경
      if (calendarModel.calendarList) {
        calendarModel.calendarList.forEach((calendarListItem) => {
          calendarListItem.jobPostings.forEach((jobPosting) => {
            if (jobPosting.jobPostingSn === calendarItem.jobPostingSn) {
              jobPosting.bookmarkYn = !bookmark;
            }
          });
        });
      }
    }
  };

  const badgeTag = () => {
    switch (calendarItem.statusType) {
      case StatusType.START:
        return ImgTagStart;
      case StatusType.END:
        return ImgTagEnd;
      case StatusType.ROLLING:
        return ImgTagRolling;
      default:
        return '';
    }
  };

  const positionTag = () => {
    let tagText = '';
    calendarItem.recruitmentTypes.forEach((type) => (tagText += `${RecruitmentTypesText[type]} · `));
    tagText += `${LocationCode(calendarItem.locationCodes[0])}`;
    if (calendarItem.locationCodes.length > 1) tagText += ' 외';
    return tagText;
  };

  const timeTag = () => {
    let tagText = '';
    if (calendarItem.closingDateTime && calendarItem.statusType === StatusType.END) {
      const closingTime = DateFormatHHMMUnit(calendarItem.closingDateTime);
      if (closingTime !== '23:59' && closingTime !== '00:00') {
        tagText += `${closingTime} 마감`;
      }
    }
    return tagText;
  };

  return (
    <Frame
      onClick={() => {
        calendarModel.selectedDate = new Date(date);
        calendarModel.showDate = new Date(date);
        history.push(RoutePaths.jobs_posting(calendarItem.jobPostingSn));
      }}
    >
      <div className='content-frame'>
        <div className='logo-frame'>
          <LazyImageLogo src={`${calendarItem.companyLogoUrl}`} className='logo' />
        </div>
        <div className='text-frame'>
          <div className='company-box'>
            <img className='badge' src={badgeTag()} alt='badge' />
            <p>{calendarItem.companyName}</p>
          </div>
          <div className='position-name'>{calendarItem.jobPostingTitle}</div>
          <div className='position-tag'>
            {positionTag()}
            {timeTag() && <span>{timeTag()}</span>}
          </div>
        </div>
      </div>
      <div className='bookmark-frame'>
        <BookMarkFrame onClick={(e) => checkBookmark(e)}>{bookmark ? <Icon name='bookmark-filled' size={24} color={Colors.G_150} /> : <Icon name='bookmark-filled' size={24} color={Colors.CG_40} />}</BookMarkFrame>
      </div>
    </Frame>
  );
};

export default inject(injectStore.calendarModel, injectStore.context, injectStore.login)(observer(CalendarListCard));
