import { Button, ButtonMode } from '__designkit__/button/Button';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import DropDown from '__designkit__/components/DropDown';
import SingleMenuItem from '__designkit__/components/SingleMenuItem';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import JDDimModal from 'components/_v2/_common/modals/JDDimModal';
import { EMPLOYMENT_TYPE, EMPLOYMENT_TYPE_TEXT } from 'consts/_v2/profile/career';
import { ICompanyReport } from 'interfaces/_v2/profile/INhisRqRs';
import { EmploymentType, INhisCareerRq } from 'interfaces/_v2/profile/IProfileCareerRqRs';
import IComponentProps from 'interfaces/props/IComponentProps';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import { injectStore } from 'models/store';
import React, { Dispatch, FC, ReactElement, SetStateAction, useState } from 'react';
import styled, { keyframes } from 'styled-components';

const bottomModalAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(calc(-100%));
  }
`;

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > div.title {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    height: fit-content;

    & .main {
      font: ${Fonts.H4_Bold};
      color: ${Colors.CG_90};
    }
  }

  & .career-list {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    height: fit-content;
  }
`;

const BadgeText = styled.div`
  padding-left: 8px;
  font: ${Fonts.B3_Medium};
  color: ${Colors.CG_70};
`;

const SubText = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font: ${Fonts.B3_Medium};
  color: ${Colors.CG_70};

  &.error {
    color: ${Colors.ERROR};
  }
`;

const FormFrame = styled.div`
  position: absolute;
  top: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc((${window.innerHeight}px * 4) / 5);
  padding: 8px 16px calc(16px + env(safe-area-inset-bottom));
  border-radius: 12px 12px 0 0;
  background-color: ${Colors.WHITE_100};
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.15);
  animation: ${bottomModalAnimation} 0.3s 0s 1 ease-out forwards;
  z-index: 10005;

  & > div.header-frame {
    flex-shrink: 0;
    width: 100%;
    height: fit-content;
    padding: 16px 0;
    border-bottom: 1px solid ${Colors.CG_40};
    font: ${Fonts.B1_Bold};
    color: ${Colors.JOBDA_BLACK};
    text-align: center;
  }

  & > div.contents-frame {
    flex-grow: 1;
    width: 100%;
    padding: 16px 0 0;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  & > div.btn-frame {
    flex-shrink: 0;
    width: 100%;
    height: fit-content;
    padding: 16px 0 0;
  }
`;

interface ICareerDetailProps extends IComponentProps {
  careerData: (ICompanyReport & INhisCareerRq)[];
  registerCareerSetter: Dispatch<SetStateAction<(ICompanyReport & INhisCareerRq)[]>>;
  context?: Context;
}

const CareerDetail: FC<ICareerDetailProps> = ({ careerData, registerCareerSetter, context = new Context() }) => {
  const [defaultOpenIndex, setDefaultOpenIndex] = useState<number>(-1);
  const [currentToken, setCurrentToken] = useState<string>('');
  const [currentCompanyName, setCurrentCompanyName] = useState<string>('');
  const [currentValue, setCurrentValue] = useState<INhisCareerRq>({ employmentType: undefined, jobGroupCode: undefined, jobTitleCode: undefined });
  const [isOpenDetailModal, setIsOpenDetailModal] = useState<boolean>(false);

  const isDisabled = !(currentValue.employmentType !== undefined && currentValue.jobGroupCode !== undefined && currentValue.jobTitleCode !== undefined);

  const makeBadgeTitle = (startDate: string, endDate: string | null): string => `${startDate.replace(/-/g, '.')} ~ ${endDate ? endDate.replace(/-/g, '.') : '재직 중'}`;

  const makeSubTitle = (token: string): ReactElement => {
    const career = careerData.find((item) => item.token === token);
    if (!career) return <></>;
    if (career.jobGroupCode && career.jobTitleCode && career.employmentType) return <SubText><Icon name='check-circle' size={16} color={Colors.CG_70} />필수 정보 입력 완료</SubText>;
    return <SubText className='error'><Icon name='error' size={16} color={Colors.ERROR} />필수 정보 미입력</SubText>;
  };

  const openForm = (career: ICompanyReport & INhisCareerRq) => {
    setDefaultOpenIndex(currentToken === career.token ? -1 : 0);
    setCurrentToken(currentToken === career.token ? '' : career.token);
    setCurrentCompanyName(career.companyName);
    setCurrentValue({ employmentType: career.employmentType, jobGroupCode: career.jobGroupCode, jobTitleCode: career.jobTitleCode });
    setIsOpenDetailModal(true);
  };

  const closeForm = () => {
    setDefaultOpenIndex(-1);
    setCurrentToken('');
    setCurrentCompanyName('');
    setCurrentValue({ employmentType: undefined, jobGroupCode: undefined, jobTitleCode: undefined });
    setIsOpenDetailModal(false);
  };

  const onClickCompleteBtn = () => {
    registerCareerSetter((prev) => prev.map((career) => {
      if (career.token === currentToken) return { ...career, ...currentValue };
      return career;
    }));
    closeForm();
  };

  return (
    <Frame>
      <div className='title'>
        <div className='main'>
          불러온 경력에 대한<br />
          필수 정보를 입력해 주세요.
        </div>
      </div>
      <SpacingBlock size={32} vertical />
      <div className='career-list'>
        { careerData.map((career) => (
          <SingleMenuItem
            key={career.token}
            title={career.companyName}
            subTitle={makeSubTitle(career.token)}
            badge={<BadgeText>{ makeBadgeTitle(career.startDate, career.endDate) }</BadgeText>}
            rightIcon={<Icon name='guide' size={32} color={Colors.JOBDA_BLACK} />}
            onClick={() => { openForm(career); }}
          />
        ))}
      </div>
      {/* 경력 아이템을 눌렀을때 노출되는 직군, 직무, 계약형태 입력 모달. 값 셋팅이 까다로워서 따로 분리하지 않고 한 파일에 작성함. */}
      { isOpenDetailModal && (
        <JDDimModal dimClickable onClickClose={closeForm}>
          <FormFrame>
            <div className='header-frame'>{ currentCompanyName }</div>
            <div className='contents-frame'>
              <DropDown
                placeholder='직군을 선택해 주세요.'
                onClickOption={({ value }) => { setCurrentValue((prev) => ({ ...prev, jobGroupCode: value })); setDefaultOpenIndex(1); }}
                maxOptionCount={6}
                initialValue={currentValue.jobGroupCode}
                optionList={context.jobGroupsWithTitles.map((jobGroup) => ({ value: jobGroup.code, label: jobGroup.name })) || []}
                defaultOpen={defaultOpenIndex === 0}
              />
              <SpacingBlock size={16} vertical />
              <DropDown
                placeholder='직무를 선택해 주세요.'
                onClickOption={({ value }) => { setCurrentValue((prev) => ({ ...prev, jobTitleCode: value })); setDefaultOpenIndex(2); }}
                maxOptionCount={6}
                initialValue={currentValue.jobTitleCode}
                optionList={context.jobTitles[String(currentValue.jobGroupCode)]?.titles.map((jobTitle) => ({ value: jobTitle.code, label: jobTitle.name })) || []}
                disabled={!currentValue.jobGroupCode}
                defaultOpen={defaultOpenIndex === 1}
              />
              <SpacingBlock size={16} vertical />
              <DropDown
                placeholder='계약 형태를 선택해 주세요.'
                onClickOption={({ value }) => { setCurrentValue((prev) => ({ ...prev, employmentType: value })); }}
                maxOptionCount={6}
                initialValue={currentValue.employmentType}
                optionList={Object.keys(EMPLOYMENT_TYPE).map((type) => ({ value: type, label: EMPLOYMENT_TYPE_TEXT[type as EmploymentType] }))}
                defaultOpen={defaultOpenIndex === 2}
              />
            </div>
            <div className='btn-frame'>
              <Button label='완료' buttonMode={ButtonMode.PRIMARY} size='large' onClick={onClickCompleteBtn} disabled={isDisabled} />
            </div>
          </FormFrame>
        </JDDimModal>
      )}
    </Frame>
  );
};

export default inject(injectStore.context)(observer(CareerDetail));
