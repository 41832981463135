import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import SimpleConfirmModal from 'components/_v2/_common/modals/SimpleConfirmModal';
import RoutePaths from 'consts/RoutePaths';
import IComponentProps from 'interfaces/props/IComponentProps';
import { inject, observer } from 'mobx-react';
import ChattingModel from 'models/_v2/ChattingModel';
import { injectStore } from 'models/store';
import React, { FC, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import ChattingRoomMenu from './ChattingRoomMenu';

const Frame = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 56px;
  font: ${Fonts.H5_Bold};
  color: ${Colors.JOBDA_BLACK};

  & > .room-title {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  & .member-count {
    font: ${Fonts.B3_Medium};
  }
`;

const LeftFrame = styled.div`
  position: absolute;
  left: 12px;
`;

const RightFrame = styled.div`
  display: flex;
  position: absolute;
  right: 12px;
`;

interface IChattingHeaderProps extends IComponentProps {
  chattingModel?: ChattingModel;
}

const ChattingHeader: FC<IChattingHeaderProps> = ({ chattingModel = new ChattingModel() }) => {
  const history = useHistory();
  const location = useLocation();
  const [isOpenLeaveConfirmModal, setIsOpenLeaveConfirmModal] = useState(false);

  const onClickBack = () => {
    if (location.pathname === RoutePaths.chatList) {
      if (history.length > 1) history.goBack();
      else history.replace(RoutePaths.root);
    } else {
      history.replace(RoutePaths.chatList);
    }
  };

  return (
    <Frame>
      <LeftFrame>
        <Icon name='large-arrow-left' size={32} onClick={onClickBack} />
      </LeftFrame>
      { location.pathname === RoutePaths.chatList
        ? '채팅'
        : location.pathname === RoutePaths.chatSearch
          ? '검색'
          : (
            <div className='room-title' role='button' onClick={async () => { await chattingModel.client.enableChannelPushNotification({ channelId: chattingModel.currentChannel?.id as string }); }}>
              { chattingModel.currentChannel?.name }
              <span className='member-count'>({ chattingModel.currentChannel && chattingModel.currentChannel.memberCount - 1 }명)</span>
              { !chattingModel.currentChannel?.notificationEnabled && <Icon name='bell-off' size={20} color={Colors.CG_70} /> }
            </div>
          )}
      <RightFrame>
        { location.pathname === RoutePaths.chatList
          ? <Icon name='search' size={32} onClick={() => history.push(RoutePaths.chatSearch)} />
          : location.pathname === RoutePaths.chatSearch
            ? null
            : <ChattingRoomMenu /> }
      </RightFrame>
      <SimpleConfirmModal
        isOpen={isOpenLeaveConfirmModal}
        title='채팅방을 나가시겠어요?'
        subTitle='해당 채팅방이 내 채팅에서 사라집니다.'
        cancelBtnText='취소'
        confirmBtnText='나가기'
        onClickCancel={() => setIsOpenLeaveConfirmModal(false)}
        onClickConfirm={() => {
          chattingModel.onClickChattingLeaveBtn();
          setIsOpenLeaveConfirmModal(false);
          onClickBack();
        }}
      />
    </Frame>
  );
};

export default inject(injectStore.chattingModel)(observer(ChattingHeader));
