import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import styled from 'styled-components';

const FormValueFrame = styled.div`
  margin-bottom: 24px;
  display: flex;
  width: 100%;
`;

export const FormValueMiddleFrame = styled.div`
  margin-bottom: 16px;
  display: flex;
`;
export const FormValueSmallFrame = styled.div`
  display: flex;
`;

export const RadioButtonFormValueFrame = styled(FormValueFrame)`
  >:not(:first-child){
    margin-left:24px;
  }
`;

export const DeleteButtonFormValueFrame = styled(FormValueFrame)`
  margin-bottom: 4px;
  justify-content: flex-end;
`;

export const EducationTypeFormValueFrame = styled(FormValueFrame)`
  >:last-child{
    margin-left:16px;
  }
`;

export const ScoreTypeFormValueFrame = styled(FormValueMiddleFrame)`
  flex-direction: column;
  .btn-frame{
    display: flex;
    margin-bottom: 4px;

    /* >:not(:first-child){
      margin-left:8px;
    } */
  }
  .input-frame{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    >div{
      width:149px;
    }
    .none{
        display:none;
    }

    span{
      padding-top: 12px;
      margin: 0 16px;
      text-align:center;
      flex-grow:0.5;
      font: ${Fonts.B1_Bold};
      color:${colors.CG_80}
    }

  }
`;

export const DatePickerFormValueFrame = styled(FormValueFrame)`
  justify-content: space-between;
  align-items: baseline;
  >div{
    width:100%;
  }
  >span{
    align-self: center;
    position: relative;
    text-align:center;
    font: ${Fonts.B1_Medium};
    color:${colors.CG_80};
    padding:0 10px;
  }

`;

export default FormValueFrame;
