import { Button, ButtonMode } from '__designkit__/button/Button';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import JDFullModal from 'components/_v2/_common/modals/JDFullModal';
import ResumeComplete from 'components/_v2/profile/loadResume/ResumeComplete';
import EditConditionView, { ChildEditComponentHandles } from 'components/_v2/profile/loadResume/editConditionContent/EditConditionView';
import LoadCompleteForm, { ChildComponentHandles } from 'components/_v2/profile/loadResume/resumeLoadContent/LoadCompleteForm';
import LoadResumeList from 'components/_v2/profile/loadResume/resumeLoadContent/LoadResumeList';
import ResumeIntroduce from 'components/_v2/profile/loadResume/resumeLoadContent/ResumeIntroduce';
import RoutePaths from 'consts/RoutePaths';
import { ConditionRegisterType } from 'consts/_v2/profile/ProfileType';
import useToast from 'hooks/useToast';
import { InsightResumeLogRq } from 'interfaces/_v2/insight/IInsightResumeRqRs';
import IComponentProps from 'interfaces/props/IComponentProps';
import { inject, observer } from 'mobx-react';
import MatchApplyModel from 'models/_v2/MatchApplyModel';
import { injectStore } from 'models/store';
import { useInsightResumeLog } from 'query/resume/useResumeQuery';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';

const Frame = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const HeaderFrame = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 56px;
  background-color: ${Colors.WHITE_100};
  font: ${Fonts.B1_Bold};
  color: ${Colors.JOBDA_BLACK};

  & .right-side {
    position: absolute;
    top: 50%;
    right: 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    width: fit-content;
    height: fit-content;
    transform: translateY(-50%);
  }
`;

const ContentsFrame = styled.div`
  flex-grow: 1;
  width: 100%;
  padding: 16px 16px 24px 16px;
  overflow-y: auto;
  height: inherit;
`;

const ContentsPaddingFrame = styled.div`
  flex-grow: 1;
  width: 100%;
  overflow-y: auto;
  height: inherit;
`;

const ProgressBar = styled.div<{ percent: number }>`
  width: 100%;
  height: 3px;
  background-color: ${Colors.CG_30};

  & > div {
    width: 100%;
    height: 100%;
    background-color: ${Colors.JOBDA_BLACK};
    transform-origin: left; 
    transform: scaleX(${(props) => props.percent / 100}); 
    transition: transform 0.3s ease-in-out;
  }
`;

const BtnFrame = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 78px;
  padding: 16px;
`;

interface IProfileResumeLoadModalProps extends IComponentProps {
  onClickClose: () => void;
  isByNudgeModal?: boolean;
  isMatchDashboard?:boolean;
  matchApplyModel?:MatchApplyModel
}

export enum FormType {
    EDUCATION, CAREER, PROJECT, EXPERIENCE, FOREIGN, PRIZE, ALL
}
export enum ConditionFormType{
  ALL, EDIT
}
const ProfileResumeLoadModal: FC<IProfileResumeLoadModalProps> = ({ matchApplyModel = new MatchApplyModel(), onClickClose, isMatchDashboard, isByNudgeModal = false }) => {
  const [formType, setFormType] = useState<FormType>(FormType.ALL);
  const [step, setStep] = useState(0);
  const [loadFailed, setLoadFailed] = useState<boolean>(false);
  const [percent] = useState([20, 40, 60, 80, 100]);
  const [resetDirtyForm, setResetDirtyForm] = useState(false);
  const loadCompleteFormRef = useRef<ChildComponentHandles>(null);
  const loadEditFormRef = useRef<ChildEditComponentHandles>(null);
  const [selectedResume, setSelectedResume] = useState<number>(-1);
  const [isSelectedResumeItem, setIsSelectedResumeItem] = useState<boolean>(true);
  const [totalResumeCnt, setTotalResumeCnt] = useState<number>(0);
  const [conditionRegisterType, setConditionRegisterType] = useState<ConditionRegisterType>(ConditionRegisterType.LIST);
  const [completeList, setCompleteList] = useState<string[]>([]);
  const [profileCompletePercent, setProfileCompletePercent] = useState<number>(0);

  useEffect(() => {
    const init = async () => {
      await matchApplyModel.loadMatchingProfile();
      setProfileCompletePercent(matchApplyModel.profile?.completionRate || 0);
    };
    init();
  }, []);
  const { setToastObject } = useToast();

  const { pathname } = useLocation();
  const history = useHistory();
  const saveSuccess = () => {
  };
  const { mutate: saveResumeInduce } = useInsightResumeLog(saveSuccess);
  const onClickBtn = async () => {
    const induceRq:InsightResumeLogRq = {
      action: 'CLICK',
      actionSource: pathname,
      actionComponent: 'btn_save_insight_resume',
      data: { insightResumeSn: selectedResume },
    };
    saveResumeInduce(induceRq);
  };

  const onClickCompleteBtn = async () => {
    if (pathname !== RoutePaths.profile) {
      history.push(RoutePaths.profile);
    }
    onClickClose();
  };

  const handleLoadFieldBack = () => {
    if (step === 2 && formType === FormType.ALL) {
      setSelectedResume(-1);
      setStep(1);
    } else {
      loadCompleteFormRef.current?.handleTrigger();
    }
  };
  const getHeaderText = () => {
    if (formType === FormType.EDUCATION) {
      return '학력';
    } if (formType === FormType.CAREER) {
      return '경력';
    } if (formType === FormType.PROJECT) {
      return '프로젝트';
    } if (formType === FormType.EXPERIENCE) {
      return '경험';
    } if (formType === FormType.FOREIGN) {
      return '경험';
    } if (formType === FormType.PRIZE) {
      return '수상';
    }
    return '지원서 불러오기';
  };
  const handleSaveButton = () => {
    if (loadCompleteFormRef.current) {
      loadCompleteFormRef.current.handleSaveDetail();
    }
  };

  const handleSubmitButton = async () => {
    if (loadCompleteFormRef.current) {
      const result = await loadCompleteFormRef.current.handleSubmit();
      onClickBtn();
    }
  };

  useEffect(() => {
    if (isByNudgeModal) {
      setStep(1);
    }
  }, [isByNudgeModal]);

  const checkSaveButton = () => {
    if (loadEditFormRef.current) {
      const res = loadEditFormRef.current.saveConditionButton();
      if (res > 0) {
        setTotalResumeCnt(totalResumeCnt + res);
        const induceRq:InsightResumeLogRq = {
          action: 'CLICK',
          actionSource: pathname,
          actionComponent: 'btn_save_resume_conditional_modal',
          data: { },
        };
        saveResumeInduce(induceRq);
        setStep(4);
      } else {
        setToastObject({ type: 'ERROR', isOpen: true, message: '필수 정보를 작성해 주세요.' });
      }
    }
  };

  const handleSkipButton = () => {
    const induceRq:InsightResumeLogRq = {
      action: 'CLICK',
      actionSource: pathname,
      actionComponent: 'btn_skip_resume_conditional_modal',
      data: { },
    };
    saveResumeInduce(induceRq);
    setStep(4);
  };

  const handleClickClose = () => {
    if (step === 2) {
      const induceRq:InsightResumeLogRq = {
        action: 'CLICK',
        actionSource: pathname,
        actionComponent: 'btn_exit_insight_resume',
        data: { profilePercent: profileCompletePercent },
      };
      saveResumeInduce(induceRq);
      onClickClose();
    } else {
      onClickClose();
    }
  };
  return (
    <JDFullModal>
      <Frame>
        <HeaderFrame>
          {getHeaderText()}
          <div className='right-side'>
            {
              !(step === 2 && formType !== FormType.ALL) && step !== 3 && (
                <Icon name='close' size={32} color={Colors.JOBDA_BLACK} onClick={handleClickClose} />
              )
            }
          </div>
        </HeaderFrame>
        { step === 0
          && (
          <ContentsFrame>
            <ResumeIntroduce />
          </ContentsFrame>
          )}
        { step === 1
          && (
          <ContentsFrame>
            <LoadResumeList setSelectedResume={setSelectedResume} setStep={setStep} setLoadFailed={setLoadFailed} />
          </ContentsFrame>
          )}
        { step === 2
          && (
          <ContentsFrame>
            <LoadCompleteForm setIsSelectedResumeItem={setIsSelectedResumeItem} setStep={setStep} setTotalResumeCnt={setTotalResumeCnt} selectedResume={selectedResume} ref={loadCompleteFormRef} formType={formType} setFormType={setFormType} resetDirtyForm setResetDirtyForm={setResetDirtyForm} />
          </ContentsFrame>
          )}
        { step === 3
        && (
        <ContentsPaddingFrame>
          <EditConditionView ref={loadEditFormRef} completeList={completeList} setCompleteList={setCompleteList} conditionRegisterType={conditionRegisterType} setConditionRegisterType={setConditionRegisterType} />
        </ContentsPaddingFrame>
        )}
        { step === 4
          && (
          <ContentsFrame>
            <ResumeComplete failed={loadFailed} count={totalResumeCnt} />
          </ContentsFrame>
          )}
        { step !== 4 && (
          <ProgressBar percent={percent[step]}>
            <div />
          </ProgressBar>
        )}
        <BtnFrame>
          { step === 0 && <Button label='계속' buttonMode={ButtonMode.PRIMARY} size='large' onClick={() => setStep(1)} /> }
          { step === 1 && (
            <>
              <Button label='선택 완료' disabled={selectedResume < 0} buttonMode={ButtonMode.PRIMARY} size='large' onClick={() => setStep(2)} />

            </>
          )}
          { step === 2 && (
          <>
            <Button label='이전' buttonMode={ButtonMode.DEFAULT} size='large' outLined onClick={handleLoadFieldBack} />
            {
               formType === FormType.ALL
                 ? (
                   <Button type='submit' className='btn_save_insight_resume' disabled={!isSelectedResumeItem} label='가져오기' buttonMode={ButtonMode.PRIMARY} size='large' onClick={handleSubmitButton} />
                 )
                 : (
                   <Button label='저장' buttonMode={ButtonMode.PRIMARY} size='large' onClick={handleSaveButton} />
                 )
             }
          </>
          )}
          { step === 3 && (
          <>
            {
            conditionRegisterType === ConditionRegisterType.LIST
              ? (
                <>
                  <Button label='건너뛰기' buttonMode={ButtonMode.DEFAULT} size='large' outLined onClick={handleSkipButton} />
                  <Button label='저장하기' buttonMode={ButtonMode.PRIMARY} size='large' onClick={checkSaveButton} />
                </>
              ) : (
                <SpacingBlock vertical size={46} />
              )
          }

          </>
          )}
          { step === 4 && (
            <>
              {
              loadFailed
                ? <Button label='확인' buttonMode={ButtonMode.PRIMARY} size='large' onClick={onClickClose} />
                : <Button label='내 프로필에서 확인하기' buttonMode={ButtonMode.PRIMARY} size='large' onClick={onClickCompleteBtn} />
            }
            </>
          )}
        </BtnFrame>
      </Frame>
    </JDFullModal>
  );
};

export default inject(injectStore.matchApplyModel)(observer(ProfileResumeLoadModal));
