import Colors from '__designkit__/common/colors';
import React from 'react';
import styled, { keyframes } from 'styled-components';

const loading = keyframes`
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
`;

const Frame = styled.ul`
  height: 100%;
  padding-bottom: 24px;
  margin-top: 8px;

  .itemArea {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 56px;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;

    @for $i from 1 through 20 {
      &:nth-child(#{$i}) {
        .contentsArea {
          animation-name: ${loading};
          animation-duration: 1.2s;
          animation-timing-function: ease-in-out;
          animation-iteration-count: infinite;
          animation-delay: #{$i * 0.01}s;
        }
      }
    }

    .imgArea {
      overflow: hidden;
      flex: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border: none;
      background: ${Colors.CG_40};
      border-radius: 4px;
    }

    .contentsArea {
      overflow: hidden;
      padding-right: 34px;
      margin-left: 16px;

      width: 100%;
      height: 100%;
      background: ${Colors.CG_30};
      border-radius: 4px;

      color: transparent;
      background: linear-gradient(100deg, #eceff1 30%, #f6f7f8 50%, #eceff1 70%);
      background-size: 400%;

      animation-name: ${loading};
      animation-duration: 1.2s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
    }
  }
`;

interface ListLoadingMotionProps {
  listCount?: number;
}

export const ListLoadingMotion = ({ listCount = 20 }: ListLoadingMotionProps) => {
  const listEls = new Array(listCount).fill(null).map(() => <LoadingListItem key={Math.random()} />);

  return <Frame className='listWrap'>{listEls}</Frame>;
};

export const LoadingListItem = () => (
  <li className='itemArea'>
    <div className='imgArea' />
    <div className='contentsArea'>
      <div className='nameWrap' />
    </div>
  </li>
);
