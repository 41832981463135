import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import styled from 'styled-components';

const FormRoundChip = styled.div<{edit?:boolean}>`
    font: ${Fonts.B3_Medium};
    line-height: 16px;
    padding: 10px 16px;
    border: 1px solid ${colors.CG_50};
    border-radius: 48px;
    color: ${colors.JOBDA_BLACK};
    background-color: ${colors.WHITE_100};
    margin-right: 6px;
    margin-bottom: 6px;
    span{
        font: ${Fonts.B3_Medium};
        color:${colors.JOBDA_BLACK};
        background-color: ${(props) => (props.edit ? `${colors.Y_100}` : `${colors.WHITE_100}`)};
        padding: 0 2px;
    }
`;

export default FormRoundChip;
