import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import IconDumy from 'assets/_v2/profile/img_profile_base_info_dumy.png';
import JDALink from 'components/JDALink';
import { MatchApplyStatusTabType } from 'components/_v2/matchApply/matchApplyStatus/MatchApplyStatusBoard';
import MatchServiceConditionInductionModal from 'components/_v2/profile/match/MatchServiceConditionInductionModal';
import MatchServiceToggle from 'components/_v2/profile/match/MatchServiceToggle';
import RoutePaths from 'consts/RoutePaths';
import { LoggingType } from 'consts/_v2/LoggingType';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import MatchApplyModel from 'models/_v2/MatchApplyModel';
import MatchApplyStatusModel from 'models/_v2/MatchApplyStatusModel';
import MatchHomeDashboardModel from 'models/_v2/MatchHomeDashboardModel';
import ProfileBasicInfoModel from 'models/_v2/profile/ProfileBasicInfoModel';
import IconSpeaker from 'assets/_v2/alarm/icon_speaker_light.svg';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled, { keyframes } from 'styled-components';
import useAccResults from 'query/acc/useAccResultQuery';
import { set } from 'js-cookie';
import PositionListModel from 'models/_v2/PositionListModel';

// 스켈레톤 애니메이션
const shine = keyframes`
  0% { background-position: -40px; }
  100% { background-position: 100%; }
`;

const SkeletonWrapper = styled.div`
  animation: ${shine} 1s linear infinite;
  background-image: linear-gradient(90deg, ${colors.CG_30} 0px, ${colors.JOBDA_WHITE} 40px, ${colors.CG_30} 80px);
  background-size: 200% 100%;
`;

const SkeletonProfile = styled(SkeletonWrapper)`
  width: 64px;
  height: 64px;
  border-radius: 32px;
`;

const SkeletonText = styled(SkeletonWrapper)`
  height: 20px;
  width: 80%;
  border-radius: 4px;
`;

const SkeletonLargeText = styled(SkeletonWrapper)`
  height: 24px;
  width: 100%;
  border-radius: 4px;
`;

const SkeletonButton = styled(SkeletonWrapper)`
  height: 28px;
  width: 200px;
  border-radius: 4px;
`;

const SkeletonCount = styled(SkeletonWrapper)`
  height: 30px;
  width: 30px;
  border-radius: 15px;
`;

const Frame = styled.div`
  padding: 24px 16px 12px 16px;

  .my-state {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 0 8px;

    .img-profile {
      width: 64px;
      height: 64px;
      border-radius: 32px;
    }

    .profile-state {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .title {
        font: ${Fonts.H4_Bold};
        color: ${colors.JOBDA_BLACK};
      }

      .matching-state {
        display: flex;
        align-items: center;
        gap: 16px;
        font: ${Fonts.B1_Medium};
        color: ${colors.CG_70};

        .matching-setting {
          position: relative;
          display: flex;
          align-items: center;
          min-height: 28px;

          &::after {
            content: '';
            position: absolute;
            top: 9px;
            left: -8px;
            width: 1px;
            height: 8px;
            background-color: ${colors.CG_40}; 
          }

          .matching-on {
            position: relative;
            font: ${Fonts.B1_Bold};
            color: ${colors.G_150};

            &::after {
              content: '';
              position: absolute;
              top: 2px;
              right: -6px;
              width: 4px;
              height: 4px;
              border-radius: 2px;
              background-color: ${colors.G_150};
            }
          }

          .matching-on {
            font: ${Fonts.B1_Bold};
            color: ${colors.G_150};
          }

          .matching-off {
            display: flex;
            align-items: center;
            gap: 12px;
            font: ${Fonts.B1_Bold};
            color: ${colors.CG_50};
          }
        }
      }
    }
  }

  .state-count {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    padding: 0 12px;
    height: 74px;
    border-radius: 8px;
    background-color: ${colors.JOBDA_WHITE};

    .state-item {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;
      width: 33%;

      &:not(:last-child)::after {
        content: '';
        position: absolute;
        top: 13px;
        right: 0;
        width: 1px;
        height: 14px;
        background-color: ${colors.CG_40};
      }

      .count {
        font: ${Fonts.H5_Bold};
        color: ${colors.CG_90};

        &.new-count {
          position: relative;
          
          &::after {
            content: '';
            position: absolute;
            display: block;
            top: 0;
            right: -4px;
            width: 4px;
            height: 4px;
            border-radius: 2px;
            background-color: ${colors.ERROR};
          }
        }
      }

      .title {
        font: ${Fonts.B4_Medium};
        color: ${colors.CG_60};
      }
    }
  }

  /* 개인화 메세지 */
  .personal-message {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 12px;
    padding: 12px 16px;
    border: 1px solid ${colors.CG_40};
    border-radius: 8px;

    .message {
      font: ${Fonts.B2_Medium};
      color: ${colors.CG_70};
    
    }
  }
`;

export interface IMyProfileProps {
  login?: Login;
  matchApplyStatusModel?:MatchApplyStatusModel
  matchHomeDashboardModel?:MatchHomeDashboardModel;
  matchApplyModel?: MatchApplyModel
  profileBasicInfoModel?: ProfileBasicInfoModel;
  positionListModel?:PositionListModel;

}

const MyDashboardComponent: FC<IMyProfileProps> = ({ profileBasicInfoModel = new ProfileBasicInfoModel(), positionListModel = new PositionListModel(), login = new Login(), matchApplyStatusModel = new MatchApplyStatusModel(), matchHomeDashboardModel = new MatchHomeDashboardModel(), matchApplyModel = new MatchApplyModel() }) => {
  const [applyCnt, setApplyCnt] = useState<number>(0);
  const history = useHistory();
  const [afterToggle, setAfterToggle] = useState<boolean>(false);
  const [isOpenConditionInductionModal, setIsOpenConditionInductionModal] = useState<boolean>(false);
  const { data: accResults } = useAccResults(!!login.userInfo);
  const { matchingConfigYn } = login.matchConfigStatus || {};
  const [message, setMessage] = useState('');
  const [messageLink, setMessageLink] = useState('');
  const bookmarks = positionListModel.searchBookmarks?.bookmarks;
  const { applicationCount } = matchHomeDashboardModel;

  useEffect(() => {
    const init = async () => {
      await profileBasicInfoModel.loadBaseInfo();
      await matchApplyStatusModel.loadMatchStatus();
      await positionListModel.loadMatchBookmarks();
      await login.getMatchConfigStatus();
      await matchHomeDashboardModel.loadApplicationCount();
    };
    if (login.userInfo) {
      init();
    }
  }, [login.userInfo]);
  useEffect(() => {
    setApplyCnt(applicationCount?.applicationCompleteCount || 0);
  }, [applicationCount]);
  const tabs = [
    // { type: MatchApplyStatusTabType.RECOMMEND, count: matchApplyStatusModel.matchStatus?.recommendCount || 0, new: matchApplyStatusModel.matchStatus?.newRecommendYn, label: '추천됨' },
    { type: MatchApplyStatusTabType.MATCH_OFFER, count: matchApplyStatusModel.matchStatus?.offerCount, new: matchApplyStatusModel.matchStatus?.newOfferYn, label: '입사지원 제안' },
    // { type: MatchApplyStatusTabType.MATCH_INTERVIEW_OFFER, count: matchApplyStatusModel.matchStatus?.interviewOfferCount, new: matchApplyStatusModel.matchStatus?.newInterviewOfferOpenYn, label: '면접 제안' },
  ];
  const handleTabClick = (tab: MatchApplyStatusTabType) => {
    history.push(RoutePaths.match_apply_status_tab(tab));
  };
  useEffect(() => {
    if (afterToggle) {
      setIsOpenConditionInductionModal(true);
      setAfterToggle(false);
    }
  }, [afterToggle]);

  const compare = (dateResult: Date, dateBasic: Date) => {
    const diffTime = Math.abs(dateBasic.getTime() - dateResult.getTime());
    const diffMonths = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 30));
    return diffMonths;
  };

  const applicationDate = new Date(accResults?.resultRecords?.[0]?.applicationDate ?? '');
  const now = new Date();

  useEffect(() => {
    if (accResults && matchingConfigYn !== undefined) {
      if (!accResults?.resultRecords?.length) {
        setMessage('역량검사 응시하고 잡다에서 취업 준비를 시작하세요!');
        setMessageLink(RoutePaths.acca_test);
      } else if (!matchingConfigYn) {
        setMessage('매칭 ON을 하면 입사지원 제안을 받을 수 있어요!');
        setMessageLink(RoutePaths.match_apply);
      } else if (compare(applicationDate, now) >= 6) {
        setMessage('역량검사로 당신의 가능성을 한번 더 확인해 보세요!');
        setMessageLink(RoutePaths.acca_test);
      } else {
        setMessage('프로필을 상세하게 작성하면 매칭 확률이 높아져요!');
        setMessageLink(RoutePaths.profile);
      }
    }
  }, [accResults, matchingConfigYn]);

  if (!accResults || matchingConfigYn === undefined) {
    return (
      <Frame>
        <>
          {/* 스켈레톤 프로필 이미지 */}
          <div className='my-state'>
            <SkeletonProfile />
            {/* 스켈레톤 프로필 상태 */}
            <div className='profile-state'>
              <SkeletonLargeText />
              <div className='matching-state'>
                <SkeletonText />
                <div className='matching-setting'>
                  <SkeletonButton />
                </div>
              </div>
            </div>
          </div>
          {/* 스켈레톤 현황 카운트 */}
          <div className='state-count'>
            <div className='state-item'>
              <SkeletonCount />
              <SkeletonText />
            </div>
            <div className='state-item'>
              <SkeletonCount />
              <SkeletonText />
            </div>
            <div className='state-item'>
              <SkeletonCount />
              <SkeletonText />
            </div>
          </div>
          {/* 스켈레톤 개인화 메세지 */}
          <div className='personal-message'>
            <SkeletonLargeText />
          </div>
        </>
      </Frame>
    );
  }

  return (
    <>
      <Frame>
        { !login.userInfo ? (
          <>
          </>
        ) : (
          <div className='my-state'>
            {/* 프로필 이미지 */}
            <div
              role='button'
              onClick={() => history.push(RoutePaths.profile)}
            >
              <img
                className='img-profile'
                src={profileBasicInfoModel.baseInfo?.profileImageUrl || IconDumy}
                alt='프로필 사진'
                onError={(e) => {
                  e.currentTarget.src = IconDumy;
                }}
              />
            </div>
            {/* 매칭 현황 */}
            <div className='profile-state'>
              <div className='matching-state'>
                잡다매칭
                <div className='matching-setting'>
                  {matchingConfigYn ? (
                    <>
                      <div className='matching-on'>
                        ON
                      </div>
                    </>
                  ) : (
                    <div className='matching-off'>
                      OFF
                      <MatchServiceToggle sourcePage={LoggingType.PROFILE} setAfterToggle={setAfterToggle} />
                    </div>
                  )}
                </div>
              </div>
              <div className='title'>
                {login.userInfo?.name}님의 취업현황
              </div>
            </div>
          </div>
        )}

        {/* 현황 카운트 */}
        {/* [D] 카운트 연동 필요 + 신규 숫자 업데이트 new-count class 추가 */}
        <div className='state-count'>
          <div className='state-item' role='button' onClick={() => history.push(RoutePaths.mypage_bookmark)}>
            <div className='count'>{bookmarks?.length}</div>
            <div className='title'>북마크</div>
          </div>
          {
          tabs.map((tabItem, index) => (
            <div key={`${tabItem.label}-${index}`} className='state-item' role='button' onClick={() => handleTabClick(tabItem.type)}>
              <div className={`count ${tabItem.new ? 'new-count' : ''}`}>{tabItem.count}</div>
              <div className='title'>{tabItem.label}</div>
            </div>
          ))
        }
          <div className='state-item' role='button' onClick={() => history.push(RoutePaths.mypage_apply)}>
            <div className='count'>{applyCnt}</div>
            <div className='title'>지원현황</div>
          </div>
        </div>
        <MatchServiceConditionInductionModal isOpen={isOpenConditionInductionModal} onClickClose={() => setIsOpenConditionInductionModal(false)} />

        {/* 개인화 메세지 */}
        <div className='personal-message'>
          <img src={IconSpeaker} alt='알림' />
          <a href={messageLink} className='message'>
            {message}
          </a>
        </div>
      </Frame>
    </>
  );
};

export default inject(injectStore.matchApplyStatusModel, injectStore.login, injectStore.matchHomeDashboardModel, injectStore.matchApplyModel, injectStore.profileBasicInfoModel, injectStore.positionListModel)(observer(MyDashboardComponent));
