import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import CompanyDetailAdvantage from 'components/_v2/company/CompanyDetailAdvantage';
import CompanyDetailAverageSalary from 'components/_v2/company/CompanyDetailAverageSalary';
import CompanyDetailEmploymentStability from 'components/_v2/company/CompanyDetailEmploymentStability';
import CompanyDetailHoneyTips from 'components/_v2/company/CompanyDetailHoneyTips';
import CompanyDetailSpecialty from 'components/_v2/company/CompanyDetailSpecialty';
import CompanyDetailThemeCollection from 'components/_v2/company/CompanyDetailThemeCollection';
import CompanyDetailWelfare from 'components/_v2/company/CompanyDetailWelfare';
import CompanyDetailsEditRequest from 'components/_v2/company/CompanyDetailsEditRequest';
import CompanyDetailsKakaoComponent from 'components/_v2/company/CompanyDetailsKakaoComponent';
import { inject, observer } from 'mobx-react';
import CompanyModel from 'models/_v2/CompanyModel';
import { injectStore } from 'models/store';
import React, { FC } from 'react';
import styled from 'styled-components';

interface ICompanyDetailInformation {
    companyModel?: CompanyModel
}
export const CompanyInformationTitle = styled.div`
    font: ${Fonts.H4_Bold};
    color: ${colors.JOBDA_BLACK};
`;

const CompanyDetailInformation:FC<ICompanyDetailInformation> = ({ companyModel = new CompanyModel() }) => (
  <>
    {companyModel.companyTheme && companyModel.companyTheme.companyThemeDtos.length > 0 && <CompanyDetailThemeCollection />}
    {/* 특별한 강점이 이상하게 매핑되어있어서 일단 제거 */}
    {/* {companyModel.companySpecialty && companyModel.companySpecialty.specialtyDtos.length > 0 && <CompanyDetailSpecialty />} */}
    {companyModel.companyAdvantage && companyModel.companyAdvantage.companyCulture && (companyModel.companyAdvantage.companyCulture.companyCultureIncumbents.length > 0 || companyModel.companyAdvantage.companyCulture.companyCultureKeywords.length > 0) && <CompanyDetailAdvantage />}
    {companyModel.companyEmployStories && companyModel.companyEmployStories.stories.length > 0 && <CompanyDetailHoneyTips />}
    {companyModel.companyWelfare && companyModel.companyWelfare.keywords.length > 0 && <CompanyDetailWelfare />}
    {companyModel.companyInformation && <CompanyDetailAverageSalary />}
    {companyModel.companyInformation && <CompanyDetailEmploymentStability />}
    {companyModel.companyInformation && companyModel.companyInformation.companyDetailDto.location && companyModel.locationX && <CompanyDetailsKakaoComponent location={companyModel.companyInformation.companyDetailDto.location} />}
    <SpacingBlock vertical size={32} />
    <CompanyDetailsEditRequest />
  </>
);
export default inject(injectStore.companyModel)(observer(CompanyDetailInformation));
