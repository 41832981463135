import React, { FC, useState } from 'react';
import ModalSlideViewer, { ModalSlide } from '../../components/modals/ModalSlideViewer';
import styled from 'styled-components';
import { JDASmallButton } from 'consts/style/mixins';
import LazyImage from '../../components/common/LazyImage';
import JDAModalFrame from '../../components/modals/JDAModalFrame';
import {
  JDAModalButtonGhost,
  JDAModalContentDescription,
  JDAModalContentText,
  JDAModalHeader,
  JDAModalUnderlineText, JDAModalButton, JDAModalMultipleButtonFrame,
} from '../../components/modals/JDAModalComponents';

const Frame = styled.div`
  > div {
    padding: 32px;
    button {
      ${JDASmallButton(false, true)};
      width: 240px;
      margin: auto;
    }
  }
`;

const ModalPage: FC = (() => {
  const [viewerOpen, setViewerOpen] = useState<boolean>(false);
  const [titleViewerOpen, setTitleViewerOpen] = useState<boolean>(false);
  const [JDAModalOpen, setJDAModalOpen] = useState<boolean>(false);
  
  return (
    <Frame>
      <div>
        <button onClick={() => setViewerOpen(true)}>타이틀이 없는 뷰어 모달</button>
        <ModalSlideViewer isOpen={viewerOpen} onClickClose={() => setViewerOpen(false)} index={4}>
          <ModalSlide><LazyImage src='https://picsum.photos/200/300?a'/></ModalSlide>
          <ModalSlide><LazyImage src='https://picsum.photos/300/400?a'/></ModalSlide>
          <ModalSlide><LazyImage src='https://picsum.photos/300/600?a'/></ModalSlide>
          <ModalSlide><LazyImage src='https://picsum.photos/300/800?a'/></ModalSlide>
          <ModalSlide><LazyImage src='https://picsum.photos/300/1000?a'/></ModalSlide>
          <ModalSlide><LazyImage src='https://picsum.photos/300/300?a'/></ModalSlide>
          <ModalSlide><LazyImage src='https://picsum.photos/400/300?a'/></ModalSlide>
          <ModalSlide><LazyImage src='https://picsum.photos/500/300?a'/></ModalSlide>
          <ModalSlide><LazyImage src='https://picsum.photos/600/300?a'/></ModalSlide>
        </ModalSlideViewer>
      </div>
      <div>
        <button onClick={() => setTitleViewerOpen(true)}>타이틀이 있는 뷰어 모달</button>
        <ModalSlideViewer isOpen={titleViewerOpen} onClickClose={() => setTitleViewerOpen(false)} title='이것이 타이틀이다'>
          <ModalSlide><LazyImage src='https://picsum.photos/200/300?a'/></ModalSlide>
          <ModalSlide><LazyImage src='https://picsum.photos/300/400?a'/></ModalSlide>
          <ModalSlide><LazyImage src='https://picsum.photos/300/600?a'/></ModalSlide>
          <ModalSlide><LazyImage src='https://picsum.photos/300/800?a'/></ModalSlide>
          <ModalSlide><LazyImage src='https://picsum.photos/300/1000?a'/></ModalSlide>
          <ModalSlide><LazyImage src='https://picsum.photos/300/300?a'/></ModalSlide>
          <ModalSlide><LazyImage src='https://picsum.photos/400/300?a'/></ModalSlide>
          <ModalSlide><LazyImage src='https://picsum.photos/500/300?a'/></ModalSlide>
          <ModalSlide><LazyImage src='https://picsum.photos/600/300?a'/></ModalSlide>
        </ModalSlideViewer>
      </div>

      <div>
        <button onClick={() => setJDAModalOpen(true)}>JDAModal 컴포넌트</button>
      </div>
      <JDAModalFrame isOpen={JDAModalOpen}>
        <div style={{ width: 304, textAlign: 'center' }}>
          <JDAModalHeader>마케팅 수신동의</JDAModalHeader>
          <div style={{ padding: 16 }}>
            <JDAModalContentText>마케팅 수신동의를 해주셔야<br />SMS로 이모티콘을 받을 수 있어요!</JDAModalContentText><br/>

            <JDAModalContentDescription>마케팅 수신동의 미동의 시 지급 불가 (동의를 안했다면? : 마이페이지  알림관리에서 동의가 가능합니다)</JDAModalContentDescription><br/>

            <JDAModalUnderlineText>지금은 아니에요</JDAModalUnderlineText><br/><br/>

            <JDAModalButtonGhost>가나다라</JDAModalButtonGhost><br />

            <JDAModalButton>가나다라</JDAModalButton><br />

            <JDAModalMultipleButtonFrame>
              <JDAModalButtonGhost>가나다라</JDAModalButtonGhost>
              <JDAModalButton>가나다라</JDAModalButton>
            </JDAModalMultipleButtonFrame><br />

            <JDAModalMultipleButtonFrame>
              <JDAModalButtonGhost>가나다라</JDAModalButtonGhost>
              <JDAModalButton>가나다라</JDAModalButton>
              <JDAModalButton>가나다라</JDAModalButton>
            </JDAModalMultipleButtonFrame><br />

            <JDAModalButton onClick={() => setJDAModalOpen(false)}>닫기</JDAModalButton>
          </div>
        </div>
      </JDAModalFrame>
    </Frame>
  );
});

export default ModalPage;
