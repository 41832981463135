/* eslint-disable react/no-unescaped-entities */
import NoticeModal, { INoticeModalProps } from 'components/modals/NoticeModal';
import React, { FC } from 'react';

//* * on off, type에 따라서 템플릿이 추가된 모달 */

export enum NoticeType {
  SMS = 'SMS',
  EMAIL = '이메일',
}

interface INoticeTemplateModalProps extends Pick<INoticeModalProps, 'isOpen' | 'onOk' | 'onCancel'> {
  type?: NoticeType;
  onOff: 'ON' | 'OFF'
}

const NoticeTemplateModal: FC<INoticeTemplateModalProps> = ({ type, onOff, ...props }) => (
  <NoticeModal
    title={(
      <>
        <span className='accent'>{`${type} 알림 수신을 ${onOff}`}</span>
        하시겠습니까?
      </>
    )}
    content={(
      onOff === 'ON' ? (
        <>
          알림 수신을 ON할 시<br />
          '마케팅 수신 동의'가 진행됩니다.
        </>
      )
        : (
          <>
            잠깐! 알림 수신을 OFF하면<br />
            채용정보와 기업소식이 제공되지 않습니다.
          </>
        )

    )}
    {...props}
  />
);

export default NoticeTemplateModal;
