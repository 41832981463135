import { EmploymentStatus, EmploymentType } from 'interfaces/_v2/profile/IProfileCareerRqRs';

export const EMPLOYMENT_STATUS = {
  WORK: 'WORK',
  RESIGN: 'RESIGN',
} as const;

export const EMPLOYMENT_TYPE = {
  FULL_TIME: 'FULL_TIME',
  TEMPORARY: 'TEMPORARY',
  PART_TIME: 'PART_TIME',
  INTERNSHIP: 'INTERNSHIP',
} as const;

export const EMPLOYMENT_STATUS_TEXT: Record<EmploymentStatus, string> = {
  RESIGN: '퇴사',
  WORK: '재직 중',
} as const;

export const EMPLOYMENT_TYPE_TEXT: Record<EmploymentType, string> = {
  FULL_TIME: '정규직',
  TEMPORARY: '계약직',
  PART_TIME: '아르바이트',
  INTERNSHIP: '인턴',
} as const;
