import styled from 'styled-components';
import React, { FC } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import IComponentProps from 'interfaces/props/IComponentProps';

const CheckBoxFrame = styled.div`

`;

interface IJDContentCheckBox extends IComponentProps{
    name: string;
    value: any;
    onChange?: any;
    defaultChecked?: boolean;
    label?: JSX.Element;
  }
const JDContentCheckBox:FC<IJDContentCheckBox> = ({ name, value, label, onChange, defaultChecked }) => {
  const useFormed = useFormContext();
  const { control, setValue } = useFormed;
  const { field } = useController({ name, control });
  return (
    <CheckBoxFrame className='input-frame'>
      <label>
        <input
          {...field}
          type='checkbox'
          aria-hidden
          name='checkbox'
          id={name}
          onChange={(e) => {
            if (e.target.checked) setValue(name, value);
            else setValue(name, false);
            onChange && onChange(e.target.checked);
          }}
          defaultChecked={defaultChecked}
        />
        {label}
      </label>
    </CheckBoxFrame>

  );
};

export default JDContentCheckBox;
