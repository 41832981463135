import React, { useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import { PlanMatchingMetaTag } from 'components/_v2/_common/meta/MetaTag';
import Banner from './banner';
import ListItem from './listItem';
import LayerModal from './layerModal';
import styles from './commonStyle.module.scss';
import { PlanMatchingProvider } from './context';

const cx = classnames.bind(styles);

const PlanMatching = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(true);

  useEffect(() => {
    const companyCode = sessionStorage.getItem('company-code');
    if (companyCode) {
      setIsModalVisible(false);
    }

    return () => {
      sessionStorage.removeItem('company-code');
    };
  }, []);

  return (
    <PlanMatchingProvider>
      <PlanMatchingMetaTag />
      <div className={cx('commonBox')}>
        <Banner />
        {/* 참여코드 입력모달 노출 전후 */}
        {isModalVisible ? <div className={cx('before')} /> : <ListItem />}

        {/* 참여코드 입력 모달 */}
        <LayerModal isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />

        {/* 푸터 */}
        <div className={cx('footer')}>
          <div className={cx('footerText')}>
            ⓒ 2024 Copyright JOBDA 기획매칭
            <div className={cx('call')}>
              <a href='mailto:matching@jobda.im'>matching@jobda.im</a>ㅣ <a href='tel:010-2775-2599'>010-2775-2599</a>
            </div>
          </div>
        </div>
      </div>
    </PlanMatchingProvider>

  );
};

export default PlanMatching;
