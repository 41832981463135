import MatchHomeDashboardCard from 'components/_v2/matchHome/dashboard/MatchHomeDashboardCard';
import MatchHomeNotFound from 'components/_v2/matchHome/MatchHomeNotFound';
import colors from '__designkit__/common/colors';
import { MatchHomeDashboardType, MatchHomeDashboardTypeText } from 'consts/_v2/matchHome/dashboard/MatchHomeDashboardType';
import Fonts from '__designkit__/common/fonts';
import { IMatchDashboardPosition } from 'interfaces/_v2/matchHome/dashboard/IMatchHomeDashboard';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Divider1G40 } from 'components/divider/Divider';
import { MatchTabType } from 'consts/_v2/match/MatchType';

const StyledMatchHomeDashboardList = styled.div`

>h1{
  display: flex;
  align-items: center;
  font: ${Fonts.B2_Bold};
  color:${colors.JOBDA_BLACK};
  
  span{
    width: 22px;
    height: 22px;
    margin-left:8px;
    display:inline-flex;
    justify-content: center;
    align-items: center;
    font: ${Fonts.B3_Bold};
    background-color:${colors.JOBDA_BLACK} ;
    border-radius: 50%;
    color:${colors.WHITE_100};
  }
  margin-bottom: 12px;
}
margin-bottom: 24px;
`;

const MatchHomeDashboardList: FC<{ list?: IMatchDashboardPosition[], type: MatchTabType }> = ({ list, type }) => (
  <StyledMatchHomeDashboardList>
    {list === undefined || list.length === 0
      ? <MatchHomeNotFound content={type as any} height={400} />
      : list.map((item, index) => (
        <React.Fragment key={item.positionSn}>
          <MatchHomeDashboardCard type={type} item={item} />
          {/* {index === 0 && <Divider1G40 />} */}
        </React.Fragment>
      ))}

  </StyledMatchHomeDashboardList>
);

export default MatchHomeDashboardList;
