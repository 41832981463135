import { LazyImageLogo } from 'components/common/LazyImage';
import colors from '__designkit__/common/colors';
import { fonts } from '__designkit__/common/fonts';
import URIs from 'consts/URIs';
import React, { FC } from 'react';
import styled from 'styled-components';

const Frame = styled.div < { logoSize: number }>`
  position: relative;
  width:${(props) => props.logoSize && `${props.logoSize}px`};
  height:${(props) => props.logoSize && `${props.logoSize}px`};
  min-width:${(props) => props.logoSize && `${props.logoSize}px`};
  .img-logo {
    width:${(props) => props.logoSize && `${props.logoSize}px`};
    height:${(props) => props.logoSize && `${props.logoSize}px`};
    min-width:${(props) => props.logoSize && `${props.logoSize}px`};
    border:1px solid ${colors.CG_40};
    border-radius:50%;
    overflow:hidden;
    object-fit: contain;
  }

  .thumbnail {
    width:${(props) => props.logoSize && `${props.logoSize}px`};
    height:${(props) => props.logoSize && `${props.logoSize}px`};
    min-width:${(props) => props.logoSize && `${props.logoSize}px`};
    border:2px solid ${colors.G_200};
    background:${colors.G_90};
    display:flex;
    align-items:center;
    justify-content:center;
    color:${colors.WHITE_100};
    ${fonts.NOTO_28_700};
    border-radius:50%;
  }
`;

interface ILogoImgOrText {
  imgUrl?: string;
  text?: string;
  size?: number;
}
const LogoImgOrText: FC<ILogoImgOrText> = ({ imgUrl, text, size = 64 }) => (
  <Frame logoSize={size}>
    {imgUrl
      ? <LazyImageLogo className='img-logo' src={imgUrl} />
      : (
        <div className='thumbnail'>
          {text ? text.replace(/(\s*)/g, '').slice(0, 1) === '(' ? text.replace(/(\s*)/g, '').slice(3, 4) : text.replace(/(\s*)/g, '').slice(0, 1) : 'J'}
        </div>
      )}
  </Frame>
);

export default LogoImgOrText;
