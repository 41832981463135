import { Button, ButtonMode } from '__designkit__/button/Button';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import JDBaseModal, { IJDBaseModal } from 'components/_v2/_common/modals/JDBaseModal';
import { JDModalBasicFrame, JDModalDescription, JDModalTitle } from 'components/_v2/_common/modals/JDModalComponents';
import MatchServiceCancelConditionModal from 'components/_v2/profile/match/MatchServiceCancelConditionModal';
import { getOptions } from 'consts/_v2/_common/ReactSelectOptions';
import { ReactSelectStyles } from 'consts/_v2/_common/style/ReactSelectStyles';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import styled from 'styled-components';

const Frame = styled.div`

  ${JDModalTitle} {
    font: ${Fonts.H4_Bold};
    margin-bottom: 8px;
  }

  ${JDModalDescription} {
    
    b {
      font: ${Fonts.B2_Bold_P};
      color: ${colors.JOBDA_BLACK};
    }
  }

.select {
    position: relative;
    margin-top: 16px;
    border: 1px solid $C_COOL_GRAY_30;
    border-radius: 4px;
    }
.close-btn{
    z-index:1;
    position: absolute;
    right: 20px;
    top:20px;
    margin-bottom: 8px;
    }
  
  .confirm-button{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 44px;
      margin-top: 20px;
      background-color:${colors.JOBDA_BLACK};
      font: ${Fonts.B2_Bold};
      color:${colors.WHITE_100};
      border-radius: 4px;
      :disabled{
          color:${colors.CG_60};
          background-color: ${colors.CG_40};
      }
  }
`;

const ButtonFrame = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  
  .cancel-btn {
    margin-right: 8px;
  }
`;

export enum APPLYCANCEL {
    ALREADY = 'ALREADY', // 취업을 해서 잡다매치가 필요없어요
    NOWILL = 'NOWILL', // 취업의사가 없는 상태에요
    DONTLIKE = 'DONTLIKE', // 추천받은 공고가 마음에 들지 않아요
    NOKAKAO = 'NOKAKAO', // 카카오 알림톡을 받고 싶지 않아요
  }

export const applyCancelOptions = getOptions({

  [APPLYCANCEL.ALREADY]: '취업을 해서 잡다매칭이 필요없어요',
  [APPLYCANCEL.NOWILL]: '취업의사가 없는 상태에요',
  [APPLYCANCEL.DONTLIKE]: '추천받은 공고가 마음에 들지 않아요',
  [APPLYCANCEL.NOKAKAO]: '카카오 알림톡을 받고 싶지 않아요',
});

interface IMatchApplyCancelModal extends IJDBaseModal {
    login?:Login
}

const MatchApplyCancelModal:FC<IMatchApplyCancelModal> = ({ isOpen, onClickClose, onSuccess, login = new Login() }) => {
  const [disabled, setDisabled] = useState<boolean>(true);
  const { control, reset } = useFormContext();
  const cancelReason = `cancel-reason`;
  const { field } = useController({
    name: cancelReason,
    control,
  });
  const [isOpenCancelConditionModal, setIsOpenCancelConditionModal] = useState<boolean>(false);

  const confirmSuccess = async () => {
    try {
      if (String(field.value?.label) === '추천받은 공고가 마음에 들지 않아요') {
        modifiedOnClickClose();
        setIsOpenCancelConditionModal(true);
      } else {
        cancelSuccess();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const cancelSuccess = async () => {
    await login.changeMatchConfigStatus(false, String(field.value?.label));
    await login.getMatchConfigStatus();
    setIsOpenCancelConditionModal(false);
    if (onSuccess) onSuccess();
    modifiedOnClickClose();
  };

  useEffect(() => {
    if (field.value) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [field.value]);

  const modifiedOnClickClose = () => {
    reset({ [cancelReason]: undefined });
    if (onClickClose) onClickClose();
  };

  return (
    <>

      <JDBaseModal dimmed isOpen={isOpen} onClickClose={modifiedOnClickClose} isDimClickClose>
        <Frame>
          <JDModalBasicFrame>
            <JDModalTitle>서비스 이용을 취소하시겠어요?</JDModalTitle>
            <JDModalDescription>개선을 위해 <b>취소 사유</b>를 알려주세요.</JDModalDescription>
            <Select
              name={cancelReason}
              value={field.value}
              ref={field.ref}
              onChange={field.onChange}
              className='select'
              placeholder='취소 사유를 선택해 주세요.'
              styles={ReactSelectStyles}
              isSearchable={false}
              options={applyCancelOptions}
            />
            <ButtonFrame>
              <Button className='cancel-btn' label='취소' outLined size='large' onClick={modifiedOnClickClose} />
              <Button label='확인' size='large' buttonMode={ButtonMode.PRIMARY} disabled={disabled} onClick={confirmSuccess} />
            </ButtonFrame>
          </JDModalBasicFrame>
        </Frame>
      </JDBaseModal>
      <MatchServiceCancelConditionModal isOpen={isOpenCancelConditionModal} onClickClose={() => setIsOpenCancelConditionModal(false)} onSuccess={cancelSuccess} />
    </>

  );
};

export default inject(injectStore.login)(observer(MatchApplyCancelModal));
