import React from 'react';
import { InterviewReservationVO } from './types';
import { getInterviewReservations } from './fetch';

type ContextType = {
  getInterviewReservations: () => Promise<InterviewReservationVO[] | void>;
  interviewReservations: InterviewReservationVO[];
  totalCount: number;
};

const PlanMatchingContext = React.createContext<ContextType>({
  getInterviewReservations: async () => {},
  interviewReservations: [],
  totalCount: 0,
});

const PlanMatchingProvider = ({ children }: { children: React.ReactNode }) => {
  const [interviewReservations, setInterviewReservations] = React.useState<InterviewReservationVO[]>([]);
  const [totalCount, setTotalCount] = React.useState(0);

  const fetchData = React.useCallback(async () => {
    try {
      const data = await getInterviewReservations();
      if (!data) return;
      setInterviewReservations(data);
      setTotalCount(data.length);
      return data;
    } catch (e) {
      console.error(e);
    }
  }, []);

  return (
    <PlanMatchingContext.Provider
      value={{
        getInterviewReservations: fetchData,
        interviewReservations,
        totalCount,
      }}
    >
      {children}
    </PlanMatchingContext.Provider>
  );
};

const usePlanMatching = () => {
  const context = React.useContext(PlanMatchingContext);
  if (!context) {
    throw new Error('usePlanMatching must be used within a PlanMatchingProvider');
  }
  return context;
};

export { PlanMatchingProvider, usePlanMatching };
