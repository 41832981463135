import React from 'react';
import styled from 'styled-components';

const SkeletonFrame = styled.div`
  display: flex;
  padding: 8px 0;
  gap: 12px;
  width: 100%;

  .skeleton-img {
    width: 100px;
    height: 80px;
    border-radius: 4px;
    background-color: #ececec;
  }

  .skeleton-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
    flex-grow: 1;
    .skeleton-chip-frame{
        display: flex;
        gap: 4px;
        .skeleton-chip {
      height: 20px;
      width: 30px;
      background-color: #ececec;
      border-radius: 4px;
    }
    }


    .skeleton-text {
      height: 20px;
      width: 170px;
      background-color: #ececec;
      border-radius: 4px;
    }
  }
`;

const PassInterviewSkeleton = () => {
  return (
    <SkeletonFrame>
      <div className='skeleton-img' />
      <div className='skeleton-content'>
        <div className='skeleton-chip-frame'>
          <div className='skeleton-chip' />
          <div className='skeleton-chip' />
          <div className='skeleton-chip' />
        </div>

        <div className='skeleton-text' />
      </div>
    </SkeletonFrame>
  );
};

export default PassInterviewSkeleton;
