import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import ProfileModel from 'models/_v2/profile/ProfileModel';
import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import styled from 'styled-components';
import IComponentProps from 'interfaces/props/IComponentProps';

const TopTab = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:stretch;
    width:100%;
    height:48px;
    overflow-x:auto;
    white-space:pre;
    margin-top: 8px;

`;
const TabTitles = styled.div<{wrong?:boolean}>`
    position:relative;
    font: ${Fonts.B1_Bold};
    padding-bottom: 13px;
    color:${(props) => (props.wrong ? `${colors.ERROR}` : ` ${colors.CG_60}`)};
    display: flex;
    align-items: center;
    width:33%;
    justify-content: center;
    .icon-wrong{
      display: flex;
      margin-left: 4px;
      width:16px;
      height:16px;
    }
    &[aria-current=true] {
        color:${(props) => (props.wrong ? `${colors.ERROR}` : `${colors.JOBDA_BLACK}`)} ;
        font: ${Fonts.B1_Bold};

    }

    &[aria-current=true]:before {
      display:block;
      position:absolute;
      content:'';
      bottom:0;
      left:0;
      width:100%;
      height:2px;
      background:${(props) => (props.wrong ? `${colors.ERROR}` : `${colors.JOBDA_BLACK}`)};
    }
    &[aria-current=false]:before {
      display:block;
      position:absolute;
      content:'';
      bottom:0;
      left:0;
      width:100%;
      height:1px;
      background:${(props) => (props.wrong ? `${colors.ERROR}` : `${colors.CG_40}`)};
    }

`;
interface TopTabListProps{
  code:number,
  name:string
}
interface IProfileTopTabProps extends IComponentProps {
  topTabList:TopTabListProps[],
  profileModel?:ProfileModel,
  currentTab:number;
  setCurrentTab:Dispatch<SetStateAction<number>>;
}
const ProfileTopTab:FC<IProfileTopTabProps> = ({ topTabList, currentTab, setCurrentTab, style, profileModel = new ProfileModel() }) => {
  useEffect(() => {
    if (profileModel.isWrongExperience && profileModel.isWrongExperience.length > 0) {
      setCurrentTab(profileModel.isWrongExperience[0]);
    }
  }, [profileModel.isWrongExperience]);

  return (
    <>
      <TopTab style={style}>
        {topTabList.map(({ code, name }) => (
          profileModel.isWrongExperience
            ? (
              <TabTitles key={code} wrong={profileModel.isWrongExperience.includes(code)} aria-current={currentTab === code} onClick={() => setCurrentTab(code)}>
                {name}
              </TabTitles>
            )
            : <TabTitles key={code} wrong={false} aria-current={currentTab === code} onClick={() => setCurrentTab(code)}>{name}</TabTitles>
        ))}
      </TopTab>

    </>
  );
};
export default inject(injectStore.profileModel)(observer(ProfileTopTab));
