import fileLottie from '__designkit__/assets/lottie/lottie_file_green.json';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import NhisAPI from 'api/nhisAPI';
import useToast from 'hooks/useToast';
import { ICompanyReport } from 'interfaces/_v2/profile/INhisRqRs';
import IComponentProps from 'interfaces/props/IComponentProps';
import Lottie from 'lottie-react';
import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  & .title {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    height: fit-content;

    & .main {
      font: ${Fonts.H4_Bold};
      color: ${Colors.CG_90};
    }
  }

  & .lottie {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    width: 100%;

    & > div {
      width: 80%;
    }
  }
`;

interface ICareerLoadingProps extends IComponentProps {
  name?: string;
  stepSetter: Dispatch<SetStateAction<number>>;
  tempSetter: Dispatch<SetStateAction<ICompanyReport[]>>;
}

const CareerLoading: FC<ICareerLoadingProps> = ({ name = '', stepSetter, tempSetter }) => {
  const { setToastObject } = useToast();

  useEffect(() => {
    (async () => {
      try {
        const res = await Promise.all([await NhisAPI.getCompaniesReport(), await new Promise((resolve) => setTimeout(resolve, 3000))]);
        stepSetter(4);
        tempSetter(res[0]);
      } catch (e) {
        console.error(e);
        setToastObject({ isOpen: true, type: 'ERROR', message: '경력 정보를 불러오는데 실패했어요. 다시 시도해 주세요.' });
      }
    })();
  }, [stepSetter, tempSetter, setToastObject]);

  return (
    <Frame>
      <div className='title'>
        <div className='main'>
          { name }님의<br />
          경력 정보를 불러오고 있어요.
        </div>
      </div>
      <div className='lottie'>
        <Lottie animationData={fileLottie} />
      </div>
    </Frame>
  );
};

export default CareerLoading;
