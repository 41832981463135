import ImgCalendar from 'assets/_v2/profile/icon_calendar.svg';
import ImgProfileList from 'assets/_v2/profile/icon_profile_list.svg';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { ExperienceForeignActivitiesType, ExperienceForeignActivitiesTypeText } from 'interfaces/_v2/profile/IProfileExperienceRqRs';
import { IProfileProjectSkillDto, TeamType, TeamTypeText } from 'interfaces/_v2/profile/IProfileProjectRqRs';
import React, { FC } from 'react';
import styled from 'styled-components';
import ImgPersonal from 'assets/_v2/profile/icon_personal_project.svg';
import ImgTeam from 'assets/_v2/profile/icon_team_project.svg';
import { SkillLevelText } from 'interfaces/_v2/profile/IProfileKnowledgeAndSkillsRs';
import { ReactSelectOption } from 'consts/_v2/_common/ReactSelectOptions';
import FormRoundChip from '../_common/form/FormRoundChip';

const Frame = styled.div<{ index: number, length: number, tag?: string}>`   
    position: relative;
    margin-left: 8px;
    border-left : ${(props) => (props.index !== props.length - 1 ? ` 2px solid ${colors.CG_40}` : `2px solid ${colors.WHITE_100}`)};
    padding-bottom:20px;
    &:not(:last-child){
      padding-bottom: 40px;
    }
    .tag{
        display: flex;
        justify-content:center;
        position:absolute;
        top:-8px;
        margin-left: 20px;
        width: fit-content;
        padding: 6px 8px;
        border:1px solid ${colors.CG_50};
        border-radius: 4px;
        span{
          font: ${Fonts.B3_Medium};
            color:${colors.CG_70};     
        }
    }
    .institution{
        width: 100%;
        display: inline-block;
        white-space: pre-line;
        font: ${Fonts.B1_Bold};
        color:${colors.CG_90};
        margin-left: 20px;
        margin-bottom: 10px;
        padding-top: ${(props) => (props.tag ? `28px` : `0`)};
        .list-img{
            position:absolute;
            top: 0;
            left: -7px;
        }
    }
    div{
        display: flex;
        align-items: center;
    }

    .team-frame{
      margin-left:12px;
      margin-bottom:16px;
      .team-img{
        width:16px;
        height:16px;
      }
      .team-text{
        margin-left:4px;
        font: ${Fonts.B2_Medium};
        color:${colors.JOBDA_BLACK};
        .contribution-text{
          margin-left:3px;
          font: ${Fonts.B2_Medium};
          color:${colors.CG_70}
        }
      }
    }
    .name{
        margin-left: 20px;
        margin-bottom: 4px;
        font: ${Fonts.B2_Bold};
        line-height: 20px;
        color:${colors.CG_80};
    }
    .description{
        width: 100%;
        padding-left: 20px;
        font: ${Fonts.B2_Medium_P};
        color:${colors.CG_70};
        display: inline-block;
        white-space: pre-line;
    }
    .skill-keyword-frame{
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin: 24px 0 26px 20px;
      .title {
          font: ${Fonts.B2_Bold};
          color: ${colors.JOBDA_BLACK};
          letter-spacing: -0.01em;
          margin-bottom: 20px;
      }

      .list-frame {
          display: flex;
          flex-wrap: wrap;
      }
    }
    
  
`;

const DateFrame = styled.div<{edit?:boolean}>`
    background-color: ${(props) => (props.edit ? `${colors.Y_100}` : `${colors.WHITE_100}`)};
    margin-left: 20px;
        margin-bottom: 16px;
        font: ${Fonts.B2_Medium};
        color:${colors.JOBDA_BLACK};
        
        .calendar-img{
            width:16px;
            height:16px;
            margin-right: 6px;
        }
`;
interface IProfileListCardProps {
  institution?: string;
  date: string;
  name?: string;
  description: string | JSX.Element;
  jobGroupName?:string;
  jobTitleName?:string;
  index: number;
  length: number;
  tag?: string;
  team?: TeamType;
  contribution?:ReactSelectOption
  skills?:IProfileProjectSkillDto[]
  editDate?:boolean
}

const ProfileListCard: FC<IProfileListCardProps> = ({ institution, date, name, description, index, length, tag, team, contribution, skills, jobTitleName, jobGroupName, editDate = false }) => (
  <Frame index={index} length={length} tag={tag}>
    {tag && (
      <div className='tag'>
        <span>{ExperienceForeignActivitiesTypeText[tag as ExperienceForeignActivitiesType]}</span>
      </div>
    )}
    {institution && (
      <div className='institution'>
        <img src={ImgProfileList} alt='list-icon' className='list-img' />
        {institution}
      </div>
    )}

    <div>
      <DateFrame edit={editDate}>
        <img src={ImgCalendar} alt='calendar' className='calendar-img' />
        {date}
      </DateFrame>
      {team && (
        <div className='team-frame'>
          {
        team === TeamType.PERSONAL ? (
          <>
            <img className='team-img' src={ImgPersonal} alt='icon' />
            <div className='team-text'>{TeamTypeText.PERSONAL}</div>
          </>
        ) : (
          <>
            <img className='team-img' src={ImgTeam} alt='icon' />
            <div className='team-text'>{TeamTypeText.TEAM}
              {contribution?.value && (
              <div className='contribution-text'>{` · 기여도 ${contribution.value}%`}</div>
              )}
            </div>
          </>
        )
      }

        </div>
      )}
    </div>
    {name && (
    <div className='name'>
      { name}
    </div>
    )}
    {description
        && <div className='description'>{description}</div>}
    <div>
      {skills && skills.length > 0 && (
      <div className='skill-keyword-frame'>
        <p className='title'>활용 기술</p>
        <div className='list-frame'>
          { skills.map((skill) => (
            <FormRoundChip key={`skill-skill-${skill.code}`}>{skill.keyword} - {SkillLevelText[skill.level]}</FormRoundChip>
          ))}
        </div>
      </div>
      )}
    </div>

  </Frame>
);
export default ProfileListCard;
