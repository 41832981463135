import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import ImgBackground from 'assets/_v2/company/img_background_gradation.png';
import JDSwiper, { JDSwiperSlide } from 'components/_v2/_common/JDSwiper';
import JDRegisterItemButton from 'components/_v2/_common/button/JDRegisterItemButton';
import { inject, observer } from 'mobx-react';
import CompanyModel from 'models/_v2/CompanyModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import iconBeta from 'assets/_v2/_common/icon_beta.svg';
import RoutePaths from 'consts/RoutePaths';
import { CHATTING_UTM } from 'consts/_v2/chatting/chatting';
import ChattingAPI from 'api/chattingAPI';
import { useHistory } from 'react-router';
import Login from 'models/Login';

const Frame = styled.div`
    padding:40px 24px;
    
    .btn-chatting{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
}
    .divider{
        width: 100%;
        height: 1px;
        background: ${Colors.CG_40};
        margin:32px 0 24px 0;
    }
`;

const SelectButton = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 64px;
    padding: 16px 24px;
    border: 1px solid ${Colors.CG_50};
    border-radius: 8px;
    justify-content: space-between;
    .select-box{
        display: flex;
        align-items: center;
        .icon{
            width: 40px;
            height: 40px;
        }
        .select-text{
        font:${Fonts.B2_Medium};
        color:${Colors.JOBDA_BLACK};
        margin-left: 16px;
    }
    }
    

`;
const CheckText = styled.div`
    display: flex;
    align-items: flex-start;
    .check-text{
        font:${Fonts.B3_Medium_P};
        color:${Colors.CG_70};
        margin-left: 4px;
    }
`;
const Title = styled.div`
        font:${Fonts.H5_Bold};
        color:${Colors.JOBDA_BLACK};
`;
interface ICompanyReportFooter{
  login?:Login;
}
const CompanyReportFooter: FC<ICompanyReportFooter> = ({ login = new Login() }) => {
  const history = useHistory();

  return (
    <Frame>
      <Title>{login.userInfo?.name}님이 취업하는 그 날까지<br />JOBDA가 응원할게요.</Title>
      <SpacingBlock size={32} vertical />
      <SelectButton onClick={() => { history.push(RoutePaths.preparation); }}>
        <div className='select-box'>
          <Icon name='work' size={32} />
          <div className='select-text'>취업 준비</div>
        </div>
        <Icon name='arrow-right' size={32} />
      </SelectButton>
      <SpacingBlock size={12} vertical />
      <SelectButton onClick={() => { history.push(RoutePaths.mypage_apply); }}>
        <div className='select-box'>
          <Icon name='chart' size={32} />
          <div className='select-text'>지원 현황</div>
        </div>
        <Icon name='arrow-right' size={32} />
      </SelectButton>
      <SpacingBlock size={12} vertical />
      <SelectButton onClick={() => { history.push(RoutePaths.profile); }}>
        <div className='select-box'>
          <Icon name='guide' size={32} />
          <div className='select-text'>내 프로필</div>
        </div>
        <Icon name='arrow-right' size={32} />
      </SelectButton>
      <div className='divider' />
      <CheckText>
        <Icon name='check' size={20} color={Colors.JOBDA_BLACK} />
        <div className='check-text'>매칭 수락 기한이 마감되었거나 제안을 거절한 리포트는 확인할 수 없습니다.</div>
      </CheckText>
      <SpacingBlock size={4} vertical />

      <CheckText>
        <Icon name='check' size={20} color={Colors.JOBDA_BLACK} />
        <div className='check-text'>매칭 리포트는 JOBDA의 자산으로 무단 공유 및 배포할 수 없습니다.</div>
      </CheckText>
    </Frame>
  );
};
export default inject(injectStore.login)(observer(CompanyReportFooter));
