import { Capacitor3KakaoLogin } from 'capacitor3-kakao-login';
import { globalContext } from 'models/Context';
import MobileStore from 'store/mobileStore';

declare global {
  interface Window {
    Kakao: any;
  }
}

export interface IShareKakaoTalkParams {
  title?: string;
  description?: string;
  imageUrl?: string;
  mobileWebUrl?: string;
  webUrl?: string;
}

export const shareKakaoTalk = async ({
  title = 'JOBDA', // 콘텐츠의 타이틀,
  description = '잡다', // 콘텐츠 상세설명
  imageUrl = '', // 썸네일 이미지,
  mobileWebUrl = document.location.href,
  webUrl = document.location.href,
}: IShareKakaoTalkParams) => {
  try {
    globalContext.loading++;
    if (!window.Kakao) {
      console.error('Kakao is not initialized');
      return;
    }
    if (!window.Kakao.Link) {
      await window.Kakao.init(process.env.REACT_APP_KAKAO_JS_KEY);
    }
    if (!MobileStore.isMobile) {
      await window.Kakao.Link.sendDefault({
        objectType: 'feed',
        content: {
          title,
          description,
          imageUrl,
          link: {
            mobileWebUrl,
            webUrl,
          },
        },
        social: {
          likeCount: 0, // LIKE 개수
          commentCount: 0, // 댓글 개수
          sharedCount: 0, // 공유 회수
        },
        buttons: [
          {
            title: '자세히 보기', // 버튼 제목
            link: {
              mobileWebUrl, // 모바일 카카오톡에서 사용하는 웹 링크 URL
              webUrl, // PC버전 카카오톡에서 사용하는 웹 링크 URL
            },
          },
        ],
      });
    } else {
      await Capacitor3KakaoLogin.sendLinkFeed({
        title,
        description,
        image_url: mobileWebUrl || imageUrl,
        image_link_url: mobileWebUrl || webUrl,
        button_title: '자세히 보기',
      });
    }
  } catch (e) {
    console.error(e);
  } finally {
    globalContext.loading--;
  }
};

export default ({ shareKakaoTalk });
