import Colors from '__designkit__/common/colors';
import Icon from '__designkit__/icon/Icon';
import JDDeleteModal from 'components/_v2/_common/modals/JDDeleteModal';
import PassboardMoreList from 'components/_v2/passboard/PassboardMoreList';
import { PassReviewCommentListDto } from 'interfaces/_v2/passReview/IPassReviewRqRs';
import { usePassReviewDeleteComments, usePassReviewLoadComments, usePassReviewUpdateComments } from 'query/passReview/usePassReveiwQuery';
import React, { FC, useState } from 'react';
import { DateFormatYYMMDDWithDot, DateFormatYYYYMMDDWithDot } from 'utils/DateUtils';

interface IPassboardCommentCard{
    comment:PassReviewCommentListDto
    sn:string;
}
const PassboardCommentCard:FC<IPassboardCommentCard> = ({ comment, sn }) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [currentComment, setCurrentComment] = useState(comment.comment);
  const [isNudgeModalVisible, setIsNudgeModalVisible] = useState<boolean>(false);
  const { refetch } = usePassReviewLoadComments(Number(sn));

  const saveSuccess = async () => {
    await refetch();
    setIsEditMode(false);
  };
  const { mutate: updateComment } = usePassReviewUpdateComments(saveSuccess);
  const { mutate: deleteComment } = usePassReviewDeleteComments(saveSuccess);
  const handleComment = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsEditMode(false);
  };

  const handleRegister = async () => {
    await updateComment({ commentSn: comment.commentSn, sn: Number(sn), rq: { comment: currentComment } });
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCurrentComment(event.target.value);
  };

  const handleDelete = async () => {
    await deleteComment({ commentSn: comment.commentSn, sn: Number(sn) });
  };
  return (
    <>
      {
      isEditMode
        ? (
          <div className='comment-item-edit'>
            <div className='comment-header'>
              <div className='comment-info'>
                <div className='comment-user'>{comment.commentUserId}</div>
                <div className='comment-date'>{DateFormatYYYYMMDDWithDot(comment.createdDateTime)}</div>
              </div>
              {
          comment.userComment && (
          <button onClick={handleComment}>
            <Icon name='more-horizon' size={24} color={Colors.CG_60} />
            <PassboardMoreList isOpen={isModalVisible} onClickClose={() => setIsModalVisible(false)} setIsEditMode={setIsEditMode} afterDelete={handleDelete} />
          </button>
          )
        }

            </div>
            <div className='comment-frame'>
              <textarea maxLength={300} className='comment-content' value={currentComment} onChange={handleChange} />
            </div>
            <div className='register-frame'>
              <button className='cancel-button' onClick={handleCancel}>취소</button>
              <button className='register-button' onClick={handleRegister}>등록</button>
            </div>
          </div>
        )
        : (
          <div className='comment-list' key={comment.commentSn}>

            <div className={`comment-item ${comment.userComment && 'mine'}`}>
              <div className='comment-header'>
                <div className='comment-info'>
                  <div className='comment-user'>{comment.commentUserId}</div>
                  <div className='comment-date'>{DateFormatYYYYMMDDWithDot(comment.createdDateTime)}</div>
                </div>
                {
        comment.userComment && (
        <button onClick={handleComment}>
          <Icon name='more-horizon' size={24} color={Colors.CG_60} />
          <PassboardMoreList isOpen={isModalVisible} onClickClose={() => setIsModalVisible(false)} setIsEditMode={setIsEditMode} afterDelete={() => setIsNudgeModalVisible(true)} />
        </button>
        )
      }

              </div>

              <div className='comment-content'>
                {comment.comment}
              </div>
            </div>
          </div>
        )
     }
      <JDDeleteModal
        title='댓글을 삭제하시겠어요?'
        dimmed
        isOpen={isNudgeModalVisible}
        onClickClose={() => setIsNudgeModalVisible(false)}
        handleClickLeft={() => setIsNudgeModalVisible(false)}
        handleClickRight={() => handleDelete()}
        btnTextLeft='취소'
        btnTextRight='삭제'
        isDimClickClose
      />
    </>
  );
};

export default PassboardCommentCard;
