import Colors from '__designkit__/common/colors';
import RadioButton from '__designkit__/components/RadioButton';
import TextField from '__designkit__/components/TextField';
import Icon from '__designkit__/icon/Icon';
import React, { useState } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;

  & code {
    display: inline-block;
    width: fit-content;
    padding: 4px;
    border-radius: 4px;
    background-color: ${Colors.CG_30};
    font-style: italic;
    font-weight: 700;
  }
`;

const ControlFrame = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TextFieldPage = () => {
  const [placeholder, setPlaceholder] = useState<string>('');
  const [isLeftChildren, setIsLeftChildren] = useState<boolean>(false);
  const [isRightChildren, setIsRightChildren] = useState<boolean>(false);
  const [caption, setCaption] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const [radioValue, setRadioValue] = useState<boolean>(false);
  const [isRadioSelected, setIsRadioSelected] = useState<boolean>(false);
  const [isRadioDisabled, setIsRadioDisabled] = useState<boolean>(false);

  return (
    <Frame>
      <ControlFrame>
        <span>placeholder: <input type='Title' value={placeholder} onChange={(e) => setPlaceholder(e.currentTarget.value)} /></span>
        <span>leftChildren: <input type='checkbox' checked={isLeftChildren} onChange={() => setIsLeftChildren(!isLeftChildren)} /></span>
        <span>rightChildren: <input type='checkbox' checked={isRightChildren} onChange={() => setIsRightChildren(!isRightChildren)} /></span>
        <span>caption: <input type='Title' value={caption} onChange={(e) => setCaption(e.currentTarget.value)} /></span>
        <span>errorMessage: <input type='Title' value={errorMessage} onChange={(e) => setErrorMessage(e.currentTarget.value)} /></span>
        <span>successMessage: <input type='Title' value={successMessage} onChange={(e) => setSuccessMessage(e.currentTarget.value)} /></span>
        <span>disabled: <input type='checkbox' checked={isDisabled} onChange={() => setIsDisabled(!isDisabled)} /></span>
      </ControlFrame>
      <p>props에 대한 자세한 내용은 <code>src/__designkit__/components/TextField.tsx</code>를 참고하세요.</p>
      <p>인풋 값은 직접 타이핑해보세요.</p>
      <TextField
        leftChildren={isLeftChildren && <Icon name='search' size={24} color={Colors.JOBDA_BLACK} />}
        rightChildren={isRightChildren && <Icon name='eye-close' size={24} color={Colors.JOBDA_BLACK} />}
        caption={caption}
        errorMessage={errorMessage}
        successMessage={successMessage}
        placeholder={placeholder || '플레이스홀더입니다.'}
        disabled={isDisabled}
      />
      <ControlFrame>
        <span>size(미체크시 medium, 체크시 large): <input type='checkbox' checked={radioValue} onChange={() => setRadioValue(!radioValue)} /></span>
        <span>isSelected: <input type='checkbox' checked={isRadioSelected} onChange={() => setIsRadioSelected(!isRadioSelected)} /></span>
        <span>isDisabled: <input type='checkbox' checked={isRadioDisabled} onChange={() => setIsRadioDisabled(!isRadioDisabled)} /></span>
      </ControlFrame>
      <p>props에 대한 자세한 내용은 <code>src/__designkit__/components/RadioButton.tsx</code>를 참고하세요.</p>
      <RadioButton radioSize={radioValue ? 'large' : 'medium'} selected={isRadioSelected} disabled={isRadioDisabled} />
    </Frame>
  );
};

export default TextFieldPage;
