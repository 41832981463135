import { JDModalButton, JDModalButtonGhost } from 'components/_v2/_common/modals/JDModalComponents';
import URIs from 'consts/URIs';
import Fonts from '__designkit__/common/fonts';
import { IKcbRequestRs } from 'interfaces/_v2/verification/IVerificationRqRs';
import React, { FC } from 'react';
import styled from 'styled-components';
import { saveSession } from 'utils/CommonUtils';
import request from 'utils/request';
import SpacingBlock from '__designkit__/components/SpacingBlock';

const ButtonFrame = styled.div`
    position: fixed;
    bottom: env(safe-area-inset-bottom);
    width: 100%;
    height: fit-content;
    padding: 0 16px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${JDModalButton} {
        padding: 14px 0;
        font: ${Fonts.B2_Bold};
    }

    ${JDModalButtonGhost} {
        padding: 14px 0;
        font: ${Fonts.B2_Bold};
    }
`;

interface VerificationContentProps {
  returnUrl: string;
  handlePassVerification?: () => void;
  handleIpinClick?: () => Promise<boolean>;
  handleButtonClick?: () => void;
  disabled?: boolean;
  mobileText?: string;
  ipinText?: string;
}

export enum VerificationType {
  SMS='SMS_MDL_TOKEN',
  IPIN='IPIN_MDL_TOKEN'
}

const VerificationContent: FC<VerificationContentProps> = ({ returnUrl, mobileText, ipinText, handlePassVerification, handleButtonClick, disabled, handleIpinClick }) => {
  const getKcbSmsRequest = async () => {
    const result = await request<IKcbRequestRs>({
      method: 'get',
      url: URIs.get_kcb_sms_identify_request,
      params: { returnUrl: window.location.origin + returnUrl },
    });
    await saveSession(VerificationType.SMS, result.moduleToken);
    return result;
  };

  const getKcbIpinRequest = async () => {
    const result = await request<IKcbRequestRs>({
      method: 'get',
      url: URIs.get_kcb_ipin_identify_request,
      params: { returnUrl: window.location.origin + returnUrl },
    });
    await saveSession(VerificationType.IPIN, result.moduleToken);
    return result;
  };

  const openKcbPopup = async (kcbToken: IKcbRequestRs, type: VerificationType) => {
    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('target', '_self');
    form.setAttribute('action', kcbToken.popupUrl);
    document.body.appendChild(form);

    const inputTc = document.createElement('input');
    inputTc.setAttribute('type', 'hidden');
    inputTc.setAttribute('name', 'tc');
    inputTc.setAttribute('value', kcbToken.tc);

    const inputCpCd = document.createElement('input');
    inputCpCd.setAttribute('type', 'hidden');
    inputCpCd.setAttribute('name', type === VerificationType.SMS ? 'cp_cd' : 'cpCd');
    inputCpCd.setAttribute('value', kcbToken.cpCd);

    const inputModuleToken = document.createElement('input');
    inputModuleToken.setAttribute('type', 'hidden');
    inputModuleToken.setAttribute('name', type === VerificationType.SMS ? 'mdl_tkn' : 'mdlTkn');
    inputModuleToken.setAttribute('value', kcbToken.moduleToken);
    form.appendChild(inputTc);
    form.appendChild(inputCpCd);
    form.appendChild(inputModuleToken);
    form.submit();
    document.body.removeChild(form);
  };

  return (
    <div className='verification-frame'>
      <ButtonFrame className='button-frame'>
        <JDModalButton
          disabled={disabled}
          onClick={async () => {
            if (handleButtonClick) handleButtonClick();
            if (handlePassVerification) {
              handlePassVerification();
            } else {
              const kcbToken = await getKcbSmsRequest();
              openKcbPopup(kcbToken, VerificationType.SMS);
            }
          }}
        >{ mobileText || '휴대폰 본인인증'}
        </JDModalButton>
        <SpacingBlock size={8} vertical />
        <JDModalButtonGhost
          disabled={disabled}
          onClick={async () => {
            if (handleButtonClick) handleButtonClick();
            if (handleIpinClick) {
              if (await handleIpinClick()) {
                const kcbToken = await getKcbIpinRequest();
                openKcbPopup(kcbToken, VerificationType.IPIN);
              }
            } else {
              const kcbToken = await getKcbIpinRequest();
              openKcbPopup(kcbToken, VerificationType.IPIN);
            }
          }}
        >{ ipinText || '아이핀 본인인증'}
        </JDModalButtonGhost>
      </ButtonFrame>
    </div>
  );
};

export default VerificationContent;
