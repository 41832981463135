import { Button, ButtonMode } from '__designkit__/button/Button';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import styled from 'styled-components';

const Frame = styled.div<{ buttonDirection: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: fit-content;
  padding: 20px 20px 24px;

  & > .button-group {
    display: flex;
    flex-direction: ${({ buttonDirection }) => buttonDirection};
    gap: 8px;
    width: 100%;

    & > button {
      min-width: 132px;
    }
  }

  & > .additional-button {
    padding-bottom: 1px;
    margin-top: 16px;
    border-bottom: 1px solid ${Colors.JOBDA_BLACK};
    font: ${Fonts.B3_Medium};
    color: ${Colors.JOBDA_BLACK};
    cursor: pointer;

    &:active {
      border-bottom: 1px solid ${Colors.CG_70};
      color: ${Colors.CG_70};
    }
  }
`;

const BUTTON_GROUP_TYPE = {
  ALERT: 'alert',
  CONFIRM: 'confirm',
} as const;

const BUTTON_GROUP_DIRECTION = {
  ROW: 'row',
  COLUMN: 'column',
} as const;

export type ButtonGroupType = typeof BUTTON_GROUP_TYPE[keyof typeof BUTTON_GROUP_TYPE];
export type ButtonGroupDirection = typeof BUTTON_GROUP_DIRECTION[keyof typeof BUTTON_GROUP_DIRECTION];

export interface Props {
  type: ButtonGroupType;
  direction?: ButtonGroupDirection;
  reverse?: boolean;
  cancelLabel?: string;
  confirmLabel?: string;
  additionalButtonLabel?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
  onAdditionalButtonClick?: () => void;
}

const ButtonGroup = ({
  type,
  direction = 'row',
  reverse = false,
  cancelLabel = '취소',
  confirmLabel = '확인',
  additionalButtonLabel,
  onCancel = () => {},
  onConfirm = () => {},
  onAdditionalButtonClick = () => {},
}: Props) => {
  const buttonDirection = direction === 'row' ? reverse ? 'row-reverse' : 'row' : reverse ? 'column-reverse' : 'column';

  return (
    <Frame buttonDirection={buttonDirection}>
      <div className='button-group'>
        {type === 'confirm' && <Button label={cancelLabel} buttonMode={ButtonMode.DEFAULT} outLined size='large' onClick={onCancel} />}
        <Button label={confirmLabel} buttonMode={ButtonMode.PRIMARY} size='large' onClick={onConfirm} />
      </div>
      {additionalButtonLabel && (
      <span className='additional-button' role='button' onClick={onAdditionalButtonClick}>{additionalButtonLabel}</span>
      )}
    </Frame>
  );
};

export default ButtonGroup;
