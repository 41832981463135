import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import classNames from 'classnames/bind';
import Loading from 'components/Loading';
import CropBox from 'components/_v2/_common/crop/CropBox';
import DimmedBox from 'components/_v2/_common/crop/DimmedBox';
import { ORIGIN_POINT, ORIGIN_SIZE, Point, Size } from 'components/_v2/_common/crop/data';
import { IJDBaseModal } from 'components/_v2/_common/modals/JDBaseModal';
import JDFullModal from 'components/_v2/_common/modals/JDFullModal';
import { JDMainButton } from 'consts/_v2/_common/style/mixins';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import styles from './imageCrop.module.scss';

const cx = classNames.bind(styles);

const Frame = styled.div`
    background-color: white;
  .icon-close{
    position: absolute;
    top: 20px;
    right: 20px;
  }

`;

const BtnFrame = styled.div`
    position: fixed; 
    z-index: 9999; 
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 20px;
    width: 100%;
    bottom: calc(env(safe-area-inset-bottom));
    background: ${colors.WHITE_100};

    .save-btn {
      ${JDMainButton(false, '100%')};
      padding: 16px 0;
      height: 52px;
      text-align: center;
      border-radius: 4px;
    }
`;

const TitleFrame = styled.div`
    width: 100%;
    height:56px;
    display: flex;
    padding-top:17px;
    justify-content:center;
    box-shadow: inset 0px -0.5px 0.5px rgba(207, 209, 213, 0.5);
    position: relative;
    background-color: inherit;
    z-index: 10005;
    .header-title{
      font: ${Fonts.H5_Bold};
      color:${colors.JOBDA_BLACK};
    }
`;

interface ImageCropProps extends IJDBaseModal{
  imgSrc: string;
  imgName: string;
  width: number;
  height: number;
  isOpen: boolean;
  onClickClose: () => void;
  onChange?: any;
}

const ImageCropModal:FC<ImageCropProps> = ({ imgSrc, imgName, width, height, isOpen, onClickClose, onChange }) => {
  const wrapRef = useRef<HTMLDivElement>(null);
  const cropAreaRef = useRef<HTMLDivElement>(null);
  const [imgSize, setImgSize] = useState<Size>(ORIGIN_SIZE);
  const [cropBoxSize, setCropBoxSize] = useState<Size>(ORIGIN_SIZE);
  const [offset, setOffset] = useState<Point>(ORIGIN_POINT);
  const [widthRatio, setWidthRatio] = useState<number>(1);
  const [heightRatio, setHeightRatio] = useState<number>(1);
  const [isImageLoaded, setImageLoaded] = useState(false);

  const imgOnLoad = useCallback((imgElement: HTMLImageElement) => {
    const imgWidth = getWidth(imgElement.width, imgElement.height);
    const imgHeight = getHeight(imgElement.width, imgElement.height);

    setWidthRatio(imgWidth / imgElement.width);
    setHeightRatio(imgHeight / imgElement.height);
    setImgSize({ w: imgWidth, h: imgHeight });

    setCropBoxSize({ w: imgWidth / 2, h: imgHeight / 2 });
    setOffset({ x: imgWidth / 4, y: imgHeight / 4 });
  }, []);

  useEffect(() => {
    setImageLoaded(false);
    const imgEl = new Image();
    imgEl.src = imgSrc;
    imgEl.crossOrigin = 'anonymous';
    imgEl.onload = () => {
      imgOnLoad(imgEl);
      setImageLoaded(true);
    };
  }, [imgSrc, imgOnLoad]);

  const getWidth = (imgWidth: number, imgHeight: number) => {
    if (imgWidth > width && imgHeight > height) {
      if (height > width) return width;
      return imgWidth * (height / imgHeight);
    }
    if (imgHeight > height) return Math.min(imgWidth, width) * (height / imgHeight);
    return Math.min(imgWidth, width);
  };

  const getHeight = (imgWidth: number, imgHeight: number) => {
    if (imgWidth > width && imgHeight > height) {
      if (height < width) return height;
      return imgHeight * (width / imgWidth);
    }
    if (imgWidth > width) return Math.min(imgHeight, height) * (width / imgWidth);
    return Math.min(imgHeight, height);
  };

  const getOffsetTop = () => (wrapRef.current ? wrapRef.current.offsetTop : 0)
      + (cropAreaRef.current ? cropAreaRef.current?.offsetTop : 0);

  const getOffsetLeft = () => (wrapRef.current ? wrapRef.current?.offsetLeft : 0)
      + (cropAreaRef.current ? cropAreaRef.current.offsetLeft : 0);

  const onSaveCropImage = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const imgEl = new Image();

    canvas.width = cropBoxSize.w;
    canvas.height = cropBoxSize.h;
    imgEl.src = imgSrc;
    imgEl.crossOrigin = 'anonymous';
    imgEl.onload = () => {
      const oc = document.createElement('canvas');
      const octx = oc.getContext('2d');
      oc.width = imgEl.width * widthRatio;
      oc.height = imgEl.height * heightRatio;
      if (octx) octx.drawImage(imgEl, 0, 0, oc.width, oc.height);

      if (ctx) {
        ctx.drawImage(
          oc,
          offset.x,
          offset.y,
          cropBoxSize.w,
          cropBoxSize.h,
          0,
          0,
          cropBoxSize.w,
          cropBoxSize.h,
        );
      }
      if (onChange) onChange(canvas);
      onClickClose();
    };
  };
  if (!isOpen) return <></>;
  return (
    <JDFullModal handleClose={onClickClose}>
      <Frame>
        <TitleFrame><div className='header-title'>사진 수정</div></TitleFrame>
        {
          !isImageLoaded ? <Loading /> : (
            <>
              <div
                ref={wrapRef}
                className={cx('wrap')}
                style={{
                  width: `${width}px`,
                  height: `${height}px`,
                }}
              >
                <div
                  ref={cropAreaRef}
                  className={cx('cropArea')}
                  style={{
                    width: `${imgSize.w}px`,
                    height: `${imgSize.h}px`,
                  }}
                >
                  <div className={cx('imgArea')}>
                    <div className={cx('imgBox')}>

                      <img
                        className={cx('img')}
                        src={imgSrc}
                        style={{
                          width: `${imgSize.w}px`,
                          height: `${imgSize.h}px`,
                        }}
                        alt='img-area'
                      />

                    </div>
                  </div>

                  <DimmedBox
                    imgSize={imgSize}
                    setOffset={setOffset}
                    setCropBoxSize={setCropBoxSize}
                    offsetTop={getOffsetTop()}
                    offsetLeft={getOffsetLeft()}
                  />

                  <CropBox
                    imgSrc={imgSrc}
                    imgSize={imgSize}
                    offset={offset}
                    setOffset={setOffset}
                    cropBoxSize={cropBoxSize}
                    setCropBoxSize={setCropBoxSize}
                    offsetTop={getOffsetTop()}
                    offsetLeft={getOffsetLeft()}
                  />
                </div>
              </div>
            </>
          )
        }

        <BtnFrame>
          <button className='save-btn' onClick={onSaveCropImage}>완료</button>
        </BtnFrame>
      </Frame>
    </JDFullModal>
  );
};

export default ImageCropModal;
