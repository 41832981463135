import ProfileExperienceExperiencesCard from 'components/_v2/profile/experience/ProfileExperienceExperiencesCard';
import FormProfileSectionTitle from 'components/_v2/_common/form/FormProfileSectionTitle';
import JDPopover from 'components/_v2/_common/popover/JDPopover';
import { IconInfoCircleDarkGrey } from 'consts/assets/icons/iconPages';
import { IProfileProps } from 'interfaces/_v2/profile/IProfileProps';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import ProfileModel from 'models/_v2/profile/ProfileModel';
import React, { FC } from 'react';
import styled from 'styled-components';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';

const Frame = styled.div`
    position:relative;
    padding: 32px 16px 128px 16px;
    .activity-absolute{
      position: absolute;
      top:32px;
      .anchor-frame{
        display: flex;
        align-items: center;
        margin-left: 4px;
      }
    }

`;
const PopOverFrame = styled.abbr`
  display:block;
  width: 304px;
  color:${colors.WHITE_100};

  >div {
    margin-bottom: 16px;
  }

  h1 {
    font: ${Fonts.B3_Bold};
    line-height: 20px;
    margin-bottom: 8px;
  }

  h2 {
    font: ${Fonts.B3_Medium};
    line-height: 16px;
    color:${colors.CG_50};
  }
`;
interface IProfileExperienceExperiences extends IProfileProps{
    hidden:boolean
}
const ProfileExperienceExperiences:FC<IProfileExperienceExperiences> = ({ profileModel = new ProfileModel(), hidden }) => (
  <div aria-hidden={hidden}>

    <Frame>
      <FormProfileSectionTitle className='activity-absolute'>기타 경험
        <div className='anchor-frame'>
          <JDPopover popoverWidth={308} useOutsideClick position='non_pony_under_left' popoverMargin={0} anchorIcon={<IconInfoCircleDarkGrey className='icon-anchor' />}>
            <PopOverFrame>
              <div>
                <h1>기타 경험이란?</h1>
                <h2>특정한 서식 없이 내 경험을 자유롭게 이야기하는 곳이에요.</h2>
              </div>
              <div>
                <h1>경험 작성의 꿀팁 3가지</h1>
                <h2>1. 같은 형식으로 통일감있게 작성해 주세요.</h2>
                <h2>
                  2. 쉽게 이해할 수 있는 표현을 사용해 주세요.
                </h2>
                <h2>
                  3. 숫자를 활용해서 구체적으로 설명해 주세요.
                </h2>
              </div>
            </PopOverFrame>
          </JDPopover>
        </div>
      </FormProfileSectionTitle>
      <ProfileExperienceExperiencesCard />
    </Frame>

  </div>
);

export default inject(injectStore.profileModel)(observer(ProfileExperienceExperiences));
