export enum CompanyLocationType {
  SEOUL = 'SEOUL',
  GYEONGGI = 'GYEONGGI',
  INCHEON = 'INCHEON',
  DAEJEON = 'DAEJEON',
  SEJONG = 'SEJONG',
  CHUNGNAM = 'CHUNGNAM',
  CHUNGBUK = 'CHUNGBUK',
  GWANGJU = 'GWANGJU',
  JEONNAM = 'JEONNAM',
  JEONBUK = 'JEONBUK',
  DAEGU = 'DAEGU',
  GYEONGBUK = 'GYEONGBUK',
  BUSAN = 'BUSAN',
  ULSAN = 'ULSAN',
  GYEONGNAM = 'GYEONGNAM',
  GANGWON = 'GANGWON',
  JEJU = 'JEJU',
  TOTAL = 'TOTAL',
}

export enum MatchProfileLocationType {
  TOTAL = 'TOTAL',
  SEOUL = 'SEOUL',
  GYEONGGI = 'GYEONGGI',
  INCHEON = 'INCHEON',
  DAEJEON = 'DAEJEON',
  SEJONG = 'SEJONG',
  CHUNGNAM = 'CHUNGNAM',
  CHUNGBUK = 'CHUNGBUK',
  GWANGJU = 'GWANGJU',
  JEONNAM = 'JEONNAM',
  JEONBUK = 'JEONBUK',
  DAEGU = 'DAEGU',
  GYEONGBUK = 'GYEONGBUK',
  BUSAN = 'BUSAN',
  ULSAN = 'ULSAN',
  GYEONGNAM = 'GYEONGNAM',
  GANGWON = 'GANGWON',
  JEJU = 'JEJU',
}
export const LocationCodeList = [
  10001, 10002, 10003, 10004, 10005, 10006, 10007, 10008, 10009, 10010, 10011, 10012, 10013, 10014, 10015, 10016, 10017, 10000,
];
export const LocationCode = (code: number) => {
  switch (code) {
    case 10001:
      return '서울';
    case 10002:
      return '인천';
    case 10003:
      return '대전';
    case 10004:
      return '세종';
    case 10005:
      return '광주';
    case 10006:
      return '대구';
    case 10007:
      return '울산';
    case 10008:
      return '부산';
    case 10009:
      return '경기';
    case 10010:
      return '강원';
    case 10011:
      return '충북';
    case 10012:
      return '충남';
    case 10013:
      return '전북';
    case 10014:
      return '전남';
    case 10015:
      return '경북';
    case 10016:
      return '경남';
    case 10017:
      return '제주';
    case 10000:
      return '전체';
    default:
      return '국내';
  }
};

export interface ICompanyLocation {
  name: string;
  location: string;
  code?: string;
}

export interface ICompanyLocationFilter {
  name: string;
  location: string;
  code: string;
}

export const CompanyLocation:Record<keyof typeof CompanyLocationType | CompanyLocationType, ICompanyLocationFilter> = {
  SEOUL: { name: '서울특별시', location: '서울', code: '10001' },
  GYEONGGI: { name: '경기도', location: '경기', code: '10009' },
  INCHEON: { name: '인천광역시', location: '인천', code: '10002' },
  BUSAN: { name: '부산광역시', location: '부산', code: '10008' },
  DAEGU: { name: '대구광역시', location: '대구', code: '10006' },
  GWANGJU: { name: '광주광역시', location: '광주', code: '10005' },
  DAEJEON: { name: '대전광역시', location: '대전', code: '10003' },
  ULSAN: { name: '울산광역시', location: '울산', code: '10007' },
  GANGWON: { name: '강원도', location: '강원', code: '10010' },
  SEJONG: { name: '세종특별자치시', location: '세종', code: '10004' },
  CHUNGBUK: { name: '충청북도', location: '충북', code: '10011' },
  CHUNGNAM: { name: '충청남도', location: '충남', code: '10012' },
  JEONBUK: { name: '전라북도', location: '전북', code: '10013' },
  JEONNAM: { name: '전라남도', location: '전남', code: '10014' },
  GYEONGBUK: { name: '경상북도', location: '경북', code: '10015' },
  GYEONGNAM: { name: '경상남도', location: '경남', code: '10016' },
  JEJU: { name: '제주특별자치도', location: '제주', code: '10017' },
  TOTAL: { name: '전체 지역', location: '전체', code: '10000' },
};

export const MatchProfileLocation:Record<keyof typeof MatchProfileLocationType | MatchProfileLocationType, ICompanyLocation> = {
  TOTAL: { name: '전체 지역', location: '전체' },
  SEOUL: { name: '서울특별시', location: '서울' },
  BUSAN: { name: '부산광역시', location: '부산' },
  DAEGU: { name: '대구광역시', location: '대구' },
  INCHEON: { name: '인천광역시', location: '인천' },
  GWANGJU: { name: '광주광역시', location: '광주' },
  DAEJEON: { name: '대전광역시', location: '대전' },
  ULSAN: { name: '울산광역시', location: '울산' },
  SEJONG: { name: '세종특별자치시', location: '세종' },
  GYEONGGI: { name: '경기도', location: '경기' },
  GANGWON: { name: '강원도', location: '강원' },
  CHUNGBUK: { name: '충청북도', location: '충북' },
  CHUNGNAM: { name: '충청남도', location: '충남' },
  JEONBUK: { name: '전라북도', location: '전북' },
  JEONNAM: { name: '전라남도', location: '전남' },
  GYEONGBUK: { name: '경상북도', location: '경북' },
  GYEONGNAM: { name: '경상남도', location: '경남' },
  JEJU: { name: '제주특별자치도', location: '제주' },
};

export default CompanyLocationType;
