import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { ConditionRegisterType } from 'consts/_v2/profile/ProfileType';
import useActiveTabFlag from 'hooks/useActiveTabFlag';
import useQueryParams from 'hooks/useQueryParams';
import { IPutConditionWorkingPreferenceRq } from 'interfaces/_v2/profile/IConditionRqRs';
import IComponentProps from 'interfaces/props/IComponentProps';
import useProfileConditions, { useFetchSaveProfileConditionsWorkingPreference } from 'query/profiles/useProfileConditionsQuery';
import React, { ChangeEvent, FC, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

const TabFrame = styled.div<{isResumeModal?:boolean}>`
  width: 100%;
  height: 142px;
  background-color: ${Colors.WHITE_100};

  .sub-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 40px 0 12px 0;
    text-align: center;
    font: ${Fonts.H4_Bold};
    color: ${Colors.JOBDA_BLACK};
  }
`;

const Frame = styled.div<{isResumeModal?:boolean}>`
  margin: 12px 0;
  height: calc(100vh - 302px);
`;

const WorkingPreferenceTextArea = styled.textarea`
  border-radius: 4px 4px 0 0;
  border-color: ${Colors.CG_40};
  width: 100%;
  height: 142px;
  padding: 16px;
  background-color: ${Colors.CG_30};
  font:${Fonts.B2_Medium_P};

  ::placeholder {
    color: ${Colors.CG_60};
  }
`;

const ExampleFrame = styled.div<{rotateX?:number, ANGLE?:number}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border: 1px solid ${Colors.CG_40};
  border-top: none;
  border-radius: 0 0 4px 4px;
  width: 100%;

  .rolling-space{
    display: flex;
    align-items: center;

    .example-text{
      font:${Fonts.B3_Bold_P};
      color:${Colors.G_150};
      margin-right: 6px;
    }

    .rotator-area{
      overflow: hidden;

      .rotator{
        position: relative;
        display: flex;
        align-items: center;
        width: 240px;
        height: 14px;
        transform-style: preserve-3d;
        transition: all 1s ease-in-out;
        transform: ${(props) => `rotateX(${props.rotateX}deg)`};
        
        .item {
          position: absolute;
          backface-visibility: hidden;
          font:${Fonts.B3_Medium_P};
          color:${Colors.CG_70};
        }
      }
    }
  }

  .length-text{
    font:${Fonts.B3_Medium_P};
    color:${Colors.CG_60};
  }
`;

const placeholder = `· 금융, IT 등 선호하는 업종을 작성해 주세요.
· 스타트업, 공기업 등 원하는 기업 형태를 작성해 주세요.
· 커리어 성장, 사내 분위기 등 그 외의 중요하게 생각하는 조건을 적어주세요.`;

const rollingExampleText = [
  '스타트업에서 일하는 것도 괜찮아요.',
  '경기남부와 서울 강남권을 선호해요.',
  '최소 연봉은 4천 이상이면 좋겠어요.',
  'AI 쪽 업종을 선호해요.',
  'UI/UX 프로덕트 디자인 직무로 일하고 싶어요.',
  'JAVA 기술을 위주로 사용하는 곳이면 좋겠어요.',
  'B2C 인하우스 기업에 취업하고 싶어요.',
  '연봉보다는 커리어 성장이 더 중요해요.',
];
const ANGLE = 360 / rollingExampleText.length;

const maxLength = 500;
interface IWorkingPreferenceTabProps extends IComponentProps {
  workingPreferenceData?:string;
  hidden?: boolean;
  setConditionRegisterType: (type: ConditionRegisterType) => void;
  isResumeModal?: boolean;
}

export interface ChildWorkingPreferenceTabHandles {
  handleSavePreference: () => void;
}

const WorkingPreferenceTab = forwardRef<ChildWorkingPreferenceTabHandles, IWorkingPreferenceTabProps>(({ isResumeModal, workingPreferenceData, hidden, setConditionRegisterType }, ref) => {
  const [rotateX, setRotateX] = useState(0);
  const defaultValues:IPutConditionWorkingPreferenceRq = { workingPreference: '', sourcePage: '' };
  const formMethods = useForm({
    mode: 'onSubmit',
    defaultValues,
  });
  const { setValue, getValues, register, watch, reset } = formMethods;
  const { sourcePage } = useQueryParams();
  const { refetch } = useProfileConditions(true);
  const initFlag = useActiveTabFlag();
  const workingPreferenceValue = watch('workingPreference');
  const textLength = workingPreferenceValue?.length || 0;

  const saveSuccess = async () => {
    await refetch();
    setConditionRegisterType(ConditionRegisterType.LIST);
  };
  const { mutate: saveProfileWorkingPreference } = useFetchSaveProfileConditionsWorkingPreference(saveSuccess);

  useEffect(() => {
    if (!workingPreferenceData) return;
    reset({ workingPreference: workingPreferenceData });
  }, [workingPreferenceData]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRotateX((prev) => prev - ANGLE);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  useImperativeHandle(ref, () => ({
    async handleSavePreference() {
      const workingPreferenceRq:IPutConditionWorkingPreferenceRq = { workingPreference: getValues('workingPreference'), sourcePage };
      saveProfileWorkingPreference(workingPreferenceRq);
    },
  }));

  const handleTextAreaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    if (value.length <= maxLength) {
      setValue('workingPreference', value, { shouldValidate: true });
    } else {
      setValue('workingPreference', value.slice(0, maxLength), { shouldValidate: true });
    }
  };
  if (!initFlag) return null;
  if (hidden) return null;
  return (
    <TabFrame isResumeModal={isResumeModal}>
      <FormProvider {...formMethods}>
        <div className='sub-title'>
          희망하는 근무 조건을 자유롭게 입력해 주세요.
        </div>
        <Frame isResumeModal={isResumeModal}>
          <WorkingPreferenceTextArea
            ref={register}
            onChange={handleTextAreaChange}
            name='workingPreference'
            placeholder={placeholder}
          />
          <ExampleFrame rotateX={rotateX} ANGLE={ANGLE}>
            <div className='rolling-space'>
              <div className='example-text'>예시</div>
              <div className='rotator-area'>
                <div className='rotator'>
                  {rollingExampleText.map((text, index) => (
                    <div
                      className='item'
                      key={`example-${text}`}
                      style={{ transform: `rotateX(${index * ANGLE}deg) translateZ(50px)` }}
                    >{text}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className='length-text'>{textLength}/500</div>
          </ExampleFrame>
        </Frame>
      </FormProvider>
    </TabFrame>
  );
});

export default WorkingPreferenceTab;
