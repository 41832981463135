import React from 'react';
import JDALink from 'components/JDALink';
import RoutePaths from 'consts/RoutePaths';
import classnames from 'classnames/bind';
import styles from './matchingSecond.module.scss';

const cx = classnames.bind(styles);

interface FlowStoryProps {
  fadeIn: boolean;
}

const MatchingSecond = ({ fadeIn }: FlowStoryProps) => (
  <div className={cx('commonBox', { on: fadeIn })}>
    {/* 매칭 내용 두가지 */}
    <div className={cx('matchingInner')}>
      <div className={cx('matchingBox', 'firstBox')}>
        <div className={cx('contentsInner')}>
          <div className={cx('textInner')}>
            <div className={cx('order')}>
              <span className={cx('step')}>STEP 1.</span>
              역량검사 응시
            </div>
            <p className={cx('subText')}>JOBDA 역량검사만의 과학적인 눈으로</p>
            <div className={cx('mainTitle')}>
              <p className={cx('light')}>나의 역량을 검증하고</p>
              역량 유형을 확인하세요
            </div>
          </div>
          <div className={cx('contents')}>
            벌써 1,089,060명이 응시한 취업대세 역량검사
            <br />
            나만의 역량 분석 결과를 받아보세요
          </div>
          <JDALink
            to={RoutePaths.acca_test}
            className={cx('btnBasic')}
          >
            역량검사 응시하기
          </JDALink>
        </div>
      </div>

      <div className={cx('matchingBox', 'secondBox')}>
        <div className={cx('contentsInner')}>
          <div className={cx('textInner')}>
            <div className={cx('order')}>
              <span className={cx('step')}>STEP 2.</span>
              프로필 입력
            </div>
            <p className={cx('subText')}>프로필 선호 정보만 입력하면</p>
            <div className={cx('mainTitle')}>
              <p className={cx('light')}>인사담당자가 직접 입사 제안</p>
              5분 만에 취업 준비 완료!
            </div>
          </div>
          <div className={cx('contents')}>
            자소서도, 입사지원도 필요 없어요
            <br />
            프로필만 입력하면 직접 입사 제안을 드려요!
          </div>
          <JDALink
            to={RoutePaths.profile}
            className={cx('btnBasic')}
          >
            프로필 작성하기
          </JDALink>
        </div>
      </div>
    </div>
  </div>
);

export default MatchingSecond;
