import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from 'App';
import store from 'models/store';
import * as serviceWorker from 'serviceWorker';
import 'assets/fonts/font.css';

Sentry.init({
  dsn: 'https://857cc07465b705e32e703bef2077b87e@o4506698483564544.ingest.sentry.io/4506754351038464',
  beforeSend(event, hint) {
    // hint 파라미터를 사용하여 에러 객체에 접근할 수 있습니다.
    const error = hint.originalException;
    if (error && error instanceof Response && error.status >= 400 && error.status <= 500) {
      // 400번대 HTTP 응답 에러는 로깅하지 않습니다.
      return null;
    }
    return event; // 이외의 경우에는 에러 이벤트를 Sentry로 보냅니다.
  },
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.01, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['https://m.jobda.im'],
  // Session Replay
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 0.1,
  release: process.env.SENTRY_RELEASE,

});
ReactDOM.render(
  <Provider {...store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
