export enum HalfYearType {
  FIRST_HALF = 'FIRST_HALF',
  SECOND_HALF = 'SECOND_HALF',
}

export enum HalfYearTypeText {
  FIRST_HALF = '상반기',
  SECOND_HALF = '하반기',
}

export default HalfYearType;
