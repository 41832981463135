import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import IconFooterArrow from 'assets/icon/icon_footer_arrow.svg';
import { Divider1CoolGrey } from 'components/divider/Divider';
import { TERM_TYPE } from 'consts/TermType';
import NavigationList from 'consts/_v2/FooterNavigationList';
import SupportMail from 'consts/_v2/GlobalText';
import IJoinPageProps from 'interfaces/props/IJoinPageProps';
import { inject, observer } from 'mobx-react';
import JoinModel from 'models/_v2/JoinModel';
import { injectStore } from 'models/store';
import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const JDA_CORP_INFO_HEIGHT = 174;

const StyledJDACorpInfoFrame = styled.div`
  color: ${Colors.CG_60};
  font: ${Fonts.B3_Medium};
  padding: 16px 0;
  height: ${JDA_CORP_INFO_HEIGHT}px;
  white-space: pre-wrap;

  table {
    tr {
      span {
        line-height: 16px;
      }

      span:first-child {
        margin-right: 12px;
      }

      th {
        padding-right: 20px;
        text-align: left;
      }

      td, th {
        letter-spacing: -0.07px;
        line-height: 14px;
        padding-bottom: 10px;
      }

      td {
        word-break: break-all;
      }

      &:last-child {
        td, th {
          padding-bottom: 0;
        }
      }   
    }
  }
`;

const JDAFooterNavigationFrame = styled.div`
  padding-bottom:16px;

  .header {
    margin-top: 24px;
    font: ${Fonts.B3_Bold_P};
    color:${Colors.CG_50}
  }

  .content-box {
    margin-top: 12px;
    margin-bottom: 24px;

    .content {
      margin-bottom:10px;
      font: ${Fonts.B3_Bold};
      color:${Colors.CG_60};
    }
  }

  .link[data-disabled=true] {
    pointer-events: none;
  }
`;

const JDAFooterNavigation:FC = (() => (
  <JDAFooterNavigationFrame>
    { NavigationList.map((menu) => (
      <div key={menu.header}>
        <div className='header'>
          { menu.header }
        </div>
        <div className='content-box'>
          { menu.content.map((content) => (
            <Link key={content.title} className='link' data-disabled={!content.linkTo} to={content.linkTo}>
              <div role='menuitem' key={content.title} className='content'>
                { content.title }
              </div>
            </Link>
          ))}
        </div>
        <Divider1CoolGrey />
      </div>
    ))}
  </JDAFooterNavigationFrame>
));

const JDCorpInfo: FC = (() => (
  <StyledJDACorpInfoFrame>
    <table>
      <tbody>
        <tr>
          <th>대표</th>
          <td>신대석</td>
        </tr>
        <tr>
          <th>주소</th>
          <td><span>경기도 성남시 분당구 판교로228번길 17, 판교세븐벤처밸리2 10층(삼평동)</span>
          </td>
        </tr>
        <tr>
          <th>사업정보</th>
          <td>유료직업소개사업등록번호 <br />: (국내)제2021-3780288-14-5-00039호</td>
        </tr>
        <tr>
          <th />
          <td><span>사업자번호  170-88-01418</span></td>
        </tr>
        <tr>
          <th />
          <td><span>직업정보제공사업 신고번호 J1516020210006</span></td>
        </tr>
      </tbody>
    </table>
  </StyledJDACorpInfoFrame>
));

const StyledJDCorpReadmeFrame = styled.div`
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  margin-top: 20px;

  .read-item {
    margin-bottom: 6px;
    color: ${Colors.CG_60};
    font: ${Fonts.B4_Bold_P};

    span {
      position:relative;
      font: ${Fonts.B4_Medium};
      margin-right :6px;
      margin-left: 6px;

      &:first-child {
        margin-left: 0;
      }

      &:not(:first-child):before {
        position:absolute;
        left:-8px;
        content:'|';
        color: ${Colors.CG_60};
      }

      b {
        font: ${Fonts.B4_Bold};
      }
    }
  }

  .copyright {
    color: ${Colors.CG_50};
    font: ${Fonts.B4_Bold_P};
  }
`;

const JDCorpReadme:FC<IJoinPageProps> = inject(injectStore.joinModel)(observer(({ joinModel = new JoinModel() }) => (
  <StyledJDCorpReadmeFrame>
    <div className='read-item'>
      <div className='copyright'>ⓒ 2024 Copyright JAINWON</div>
    </div>
    <div className='read-item'>
      <span role='presentation'>{SupportMail}</span>
      <span role='presentation' onClick={() => { joinModel.activeTermType = TERM_TYPE.PRIVACY_POLICY; }}><b>개인정보처리방침</b></span>
      <span role='presentation' onClick={() => { joinModel.activeTermType = TERM_TYPE.TERMS_OF_SERVICE; }}>서비스 이용약관</span>
    </div>
  </StyledJDCorpReadmeFrame>
)));

export const FooterFrame = styled.div`
  background: ${Colors.CG_90};
  padding: 24px;

  .basic-info {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    font: ${Fonts.B3_Bold_P};
    align-items: center;

    .title {
      color: ${Colors.CG_50};
      line-height: 1.7em;
      display: flex;
      align-items: flex-end;

      img {
        margin-left: 2px;
        vertical-align: top;
        width: 20px;
        height: 20px;

        &.folded {
          transform: rotate(180deg);
        }
      }
    }
  }
  
  .jda-corp-info-frame {
    height: ${JDA_CORP_INFO_HEIGHT + 1}px;
    overflow: hidden;
    transition: height 0.25s;
    
    &.folded {
      height: 0px;
    }
  }
`;

const Footer: FC<{isNavi?:boolean, corpInfoFoled?:boolean}> = (({ isNavi = true, corpInfoFoled = true }) => {
  const [corpInfoFolded, setCorpInfoFolded] = useState<boolean>(corpInfoFoled);
  const foldHandler = () => setCorpInfoFolded(!corpInfoFolded);

  return (
    <FooterFrame>
      { isNavi && <JDAFooterNavigation /> }
      <div className='basic-info'>
        <div role='button' className='title' onClick={foldHandler}>잡다 사업자 정보<img alt='화살표' className={corpInfoFolded ? 'folded' : ''} src={IconFooterArrow} /></div>
      </div>
      <Divider1CoolGrey />
      <div className={corpInfoFolded ? 'folded jda-corp-info-frame' : 'jda-corp-info-frame'}>
        <JDCorpInfo />
        <Divider1CoolGrey />
      </div>
      <JDCorpReadme />
    </FooterFrame>
  );
});

export default Footer;
