import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import ChattingAPI from 'api/chattingAPI';
import RoutePaths from 'consts/RoutePaths';
import { CHATTING_UTM } from 'consts/_v2/chatting/chatting';
import IPositionJdProps from 'interfaces/_v2/positionJd/IPositionJdProps';
import { inject, observer } from 'mobx-react';
import CompanyModel from 'models/_v2/CompanyModel';
import PositionJdModel from 'models/_v2/PositionJdModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import ScrollUtil from 'utils/ScrollUtil';

const Frame = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  background-color: ${Colors.WHITE_100};
  border: 1px solid ${Colors.CG_50};
  border-radius: 6px;

  .title {
    font: ${Fonts.B2_Bold};
    color: ${Colors.JOBDA_BLACK}; 
    word-break: keep-all;
    margin-bottom: 4px;
  }

  .tip{
    font: ${Fonts.B3_Medium};
    color: ${Colors.c41AC4D};
    margin-bottom: 12px;
  }
`;

const LinkButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  padding: 10px;
  width: 100%;
  background-color: ${Colors.WHITE_100};
  border: 1px solid ${Colors.JOBDA_BLACK};
  border-radius: 4px;
  font: ${Fonts.B3_Bold};
  color: ${Colors.JOBDA_BLACK};

  & > div {
    margin-right: 4px;
  }
`;

interface IPositionJdCompanyNudgeProps extends IPositionJdProps {
    companySn: number;
    companyName: string;
    companyModel?: CompanyModel;
}

const PositionJdCompanyNudge: FC<IPositionJdCompanyNudgeProps> = ({ companySn, companyName, positionJdModel = new PositionJdModel(), companyModel = new CompanyModel() }) => {
  const history = useHistory();
  const [channelId, setChannelId] = useState<string>('');

  const loadCompanyInfo = async () => {
    companyModel.companySn = companySn;
    await companyModel.loadCompanyPositions();
  };

  const handleScrollToPosition = () => {
    if (companyModel.companyPositions?.positions.length)
      ScrollUtil.scrollTo({ top: positionJdModel.positionScrollOffSet + 424 || 0, behavior: 'smooth' });
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await Promise.all([loadCompanyInfo(), ChattingAPI.getChannelIdByCompanySn(companySn)]);
        setChannelId(res[1].channelId);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [companySn]);

  return (
    <>
      { positionJdModel.positionDetailJd?.companyInfo.openYn && (
        <Frame>
          <p className='title'>{companyName}에 대한 더 자세한 정보를 알고싶으시다면?</p>
          <button className='tip' onClick={handleScrollToPosition}>현재 {companyModel.companyPositions?.positions && companyModel.companyPositions?.positions.length > 99 ? '99+' : companyModel.companyPositions?.positions.length || 0}개 포지션 채용중</button>
          <LinkButton onClick={() => { history.push(RoutePaths.company_details(companySn)); }}>
            <Icon name='business' size={24} />
            기업 정보 바로가기
          </LinkButton>
          <SpacingBlock size={8} vertical />
          <LinkButton onClick={() => { history.push(`${RoutePaths.chatChannel}?channelId=${channelId}${CHATTING_UTM.jdPositionToChatting}`); }}>
            <Icon name='message' size={24} />
            기업 채팅 바로가기
          </LinkButton>
        </Frame>
      )}
    </>
  );
};

export default inject(injectStore.companyModel, injectStore.positionJdModel)(observer(PositionJdCompanyNudge));
