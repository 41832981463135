import RoutePaths from 'consts/RoutePaths';
import colors from '__designkit__/common/colors';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Fonts from '__designkit__/common/fonts';
import Lottie from 'lottie-react';
import toggleLottie from 'assets/lottie/toggle.json';

const Frame = styled.div`
  background: ${colors.WHITE_100};
  width: 100%;
  height: 100%;
  padding:0 44px;
  >div{
    position: relative;
    padding-top:180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .icon-error{
      margin-bottom: 16px;
    }
  h1{
    text-align: center;
    margin-bottom:8px;
    font:${Fonts.H4_Bold}
  }
  h2{
    text-align: center;
    margin-bottom:19px;
    font:${Fonts.B2_Medium};
    color:${colors.CG_70};
  }
.toggle-container{
    display: flex;
    width: 100%;
    border:1px solid ${colors.CG_40};
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.05);
    padding: 20px 24px;
    justify-content: space-between;
    align-items: center;    
    border-radius: 8px;
    .title-text{
        font:${Fonts.B3_Medium};
        color:${colors.CG_70};
    }
    .toggle-box{
        display: flex;
        align-items: center;
        .toggle-text{
            font:${Fonts.B2_Bold};
            color:${colors.JOBDA_BLACK};
            margin-right: 12px;
        }
        .toggle-switch{
            
        }
    }

}
  .apply{
    background-color: ${colors.JOBDA_BLACK};
    color:${colors.JOBDA_WHITE};
    font:${Fonts.B1_Bold};
    width: 100%;
    border-radius: 4px;
    padding:14px 0;
    margin-top: 22px;
  }
 
}
`;

const InfoNotMatching: FC = () => {
  const history = useHistory();
  return (
    <Frame>
      <div>
        <h1>잡다매칭에 참여 중인 회원에게만 <br /> 공개된 콘텐츠입니다.
        </h1>
        <h2>{`매칭 설정 > 제안 받기를 켜주세요.`}</h2>
        <div className='toggle-container'>
          <div className='title-text'>매칭 설정</div>
          <div className='toggle-box'>
            <div className='toggle-text'>제안 받기</div>
            <Lottie animationData={toggleLottie} />

          </div>
        </div>
        <button className='apply' onClick={() => history.push(RoutePaths.match_apply)}>잡다매칭 참여하러 가기</button>
      </div>
    </Frame>
  );
};

export default InfoNotMatching;
