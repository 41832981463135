export enum CurationTitleType {
    ACC_SCREENING_JOB_POSTING='ACC_SCREENING_JOB_POSTING',
    CURATION_JOB_POSTING='CURATION_JOB_POSTING',
    MAIN_BANNER='CURATION_JOB_POSTING',
    RECOMMEND_JOB_POSTING='RECOMMEND_JOB_POSTING'
}
export interface IPages {
        page: number;
        size: number;
        totalPages: number;
        totalElements: number;
    }

export interface IAdsTitle {
    title:string;
}
export interface IAdRecommendJobPostingDto {
    jobPostingSn: number;
    companySn: number;
    companyName: string;
    jobPostingName: string;
    postStartDateTime: string;
    postEndDateTime: string;
    companyLogoUrl: string;
    representativeImageUrl: string;
    adSn: number | null;
}

export interface IAdRecommendJobPostingListRs {
        pages: IPages;
        jobPostings: IAdRecommendJobPostingDto[];
    }

export interface IAdCurationCategoryListRs{
    categories:IAdCurationCategoryDto[]
}
export interface IAdCurationCategoryDto{
    sn:number;
    name:string;
    priority:number;
    iconImageUrl:string;
}

export interface IAdCurationJobPostingDto {
        adSn:number;
        jobPostingSn: number;
        companySn: number;
        companyName: string;
        jobPostingName: string;
        postStartDateTime: Date;
        postEndDateTime: Date;
        companyLogoUrl: string;
        representativeImageUrl: string;
    }

export interface IAdCurationJobPostingListRs {
        jobPostings: IAdCurationJobPostingDto[];
    }
