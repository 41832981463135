import Fonts from '__designkit__/common/fonts';
import colors from '__designkit__/common/colors';
import ICalendarProps from 'interfaces/_v2/calendar/ICalendarProps';
import { inject, observer } from 'mobx-react';
import CalendarModel from 'models/_v2/CalendarModel';
import { injectStore } from 'models/store';
import React, { FC } from 'react';
import styled from 'styled-components';
import ScrollUtil from 'utils/ScrollUtil';

const Button = styled.button`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${colors.JOBDA_BLACK};
  font: ${Fonts.B3_Bold};
  color: ${colors.WHITE_100};
`;

const CalendarFloatingButton: FC<ICalendarProps> = ({ calendarModel = new CalendarModel() }) => {
  const scrollToTopHandler = () => {
    // 오늘로 변경
    calendarModel.selectedDate = new Date();
    calendarModel.showDate = new Date();
    ScrollUtil.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Button className='scroll-up-btn' onClick={scrollToTopHandler}>
      오늘
    </Button>
  );
};

export default inject(injectStore.calendarModel)(observer(CalendarFloatingButton));
