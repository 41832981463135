import URIs from 'consts/URIs';
import { IGetCompanyListRq, IGetCompanyListRs } from 'interfaces/_v2/company/ICompanyRqRs';
import request from 'utils/request';

const CompanyAPI = {
  // 기업 리스트 조회
  getCompanies: async (params: IGetCompanyListRq) => {
    try {
      const res = await request<IGetCompanyListRs>({
        method: 'GET',
        url: URIs.get_companies,
        params,
      });
      return res;
    } catch (e) {
      const err = e as { response: any };
      throw err;
    }
  },
};

export default CompanyAPI;
