import { ErrorMessage } from '@hookform/error-message';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import FormValueFrame, { DatePickerFormValueFrame } from 'components/_v2/_common/form/FormProfileValueFrame';
import FormProfileValueTitle from 'components/_v2/_common/form/FormProfileValueTitle';
import JDBaseInput from 'components/_v2/_common/input/JDBaseInput';
import JDDatePickerInput from 'components/_v2/_common/input/JDDatePickerInput';
import JDDescriptionInput from 'components/_v2/_common/input/JDDescriptionInput';
import JDRadioInput from 'components/_v2/_common/input/JDRadioInput';
import { JD_RADIO_INPUT_TYPE } from 'components/_v2/_common/input/JDRadioInputTemp';
import ProfileProjectContributionSelect from 'components/_v2/profile/project/ProfileProjectContributionSelect';
import ProfileProjectKeyword from 'components/_v2/profile/project/ProfileProjectKeyword';
import { BaseProfileProjectVO } from 'interfaces/_v2/insight/IInsightResumeRqRs';
import { TeamType, TeamTypeText } from 'interfaces/_v2/profile/IProfileProjectRqRs';
import Context from 'models/Context';
import React, { FC, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

const ProjectRegisterFrame = styled.div`
  .content{
    padding: 0 1px;
    .title-text{
      font:${Fonts.H4_Bold};
      color:${Colors.JOBDA_BLACK};
      margin-bottom: 24px;
    }
    .team-error{
      width: 100%;
      display: flex;
      flex-direction: column;
      .team-private{
      display: flex;
      justify-content: space-between;
      width: 100%;
      .team-contribution{
        display: flex;
        .team{
          font: ${Fonts.B2_Medium};
        color:${Colors.CG_90};
      }
      .contribution{
          margin: 0 12px 0 8px;
          width: 100px;
          .select {
            position: relative;
            border: 1px solid $C_COOL_GRAY_30;
            border-radius: 4px;
            }
        }
    }
      .private{
        display:flex;
      }
    }
    .message-false{
      font: ${Fonts.B3_Medium};
      color:${Colors.ERROR}
    }
    }
   
    .save-button{
      margin-top: 72px;
    }
  }
`;

interface IResumeEditProject{
    prefixName:string;
    projectIdx:number;
    isVisible:boolean
    context?:Context
    projectsFields:BaseProfileProjectVO[]
}

const ResumeEditProject:FC<IResumeEditProject> = ({ prefixName, projectIdx, isVisible, context = new Context(), projectsFields }) => {
  const { control, watch, setValue, trigger, errors } = useFormContext();
  const topRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isVisible && topRef.current) {
      topRef.current.scrollIntoView();
    }
  }, [isVisible]);

  return (
    <ProjectRegisterFrame hidden={!isVisible} ref={topRef}>
      {
        projectsFields?.map((field, index) => (
          <div className='content' hidden={index !== projectIdx} key={`${field}-project`}>
            <div className='title-text'>
              자신이 했던 역할과 문제 해결 방식을<br />상세히 작성해주세요.
            </div>
            <FormProfileValueTitle required>프로젝트명</FormProfileValueTitle>
            <FormValueFrame>
              <JDBaseInput
                key={index}
                name={`${prefixName}[${index}].title`}
                placeholder='프로젝트명을 입력해 주세요.'
                maxLength={30}
                defaultValue={watch(`${prefixName}[${index}].title`) || ''}
              />
            </FormValueFrame>
            <FormProfileValueTitle required>프로젝트 기간</FormProfileValueTitle>
            <DatePickerFormValueFrame>
              <JDDatePickerInput
                name={`${prefixName}[${index}].startDate`}
                placeholder='연도.월.'
                defaultValue={watch(`${prefixName}[${index}].startDate`)}
                month
              />
              <span className='date-picker-divider'>~</span>
              <JDDatePickerInput
                name={`${prefixName}[${index}].endDate`}
                placeholder='연도.월.'
                defaultValue={watch(`${prefixName}[${index}].endDate`)}
                month
              />
            </DatePickerFormValueFrame>
            <FormProfileValueTitle required>팀/개인</FormProfileValueTitle>
            <FormValueFrame>
              <div className='team-error'>
                <div className='team-private'>
                  {Object.values(TeamType).map((key) => (
                    <>
                      <div className='team-contribution'>
                        <JDRadioInput
                          type={JD_RADIO_INPUT_TYPE.ROUND_BOX}
                          key={`radio-option-${key}`}
                          name={`${prefixName}[${index}].teamType`}
                          value={key}
                          defaultValue={field.teamType}
                          label={(<div className='team'>{TeamTypeText[key]}</div>)}
                        />

                        {key === TeamType.TEAM && (
                        <div className='contribution'>
                          <ProfileProjectContributionSelect prefixName={`${prefixName}[${index}]`} />
                        </div>
                        )}
                      </div>
                    </>
                  ))}
                </div>
                <ErrorMessage
                  errors={errors}
                  name={`${prefixName}[${index}].teamType`}
                  render={({ message }) => <h4 className='message-false'>{message}</h4>}
                />
                <ErrorMessage
                  errors={errors}
                  name={`${prefixName}[${index}].contribution`}
                  render={({ message }) => <h4 className='message-false'>{message}</h4>}
                />
              </div>

            </FormValueFrame>
            <FormProfileValueTitle>주요 업무 및 성과</FormProfileValueTitle>
            <FormValueFrame>
              <JDDescriptionInput
                key=''
                name={`${prefixName}[${index}].performance`}
                placeholder='프로직트 진행 시 담당했던 업무와 성과를 입력해 주세요.'
                maxLength={10000}
                height={300}
                autoFocus={false}
              />
            </FormValueFrame>
            <FormProfileValueTitle>학교/회사/기관</FormProfileValueTitle>
            <FormValueFrame>
              <JDBaseInput
                name={`${prefixName}[${index}].institution`}
                placeholder='학교/회사/기관을 입력해 주세요.'
                maxLength={30}
              />
            </FormValueFrame>
            {
            watch(`${prefixName}[${index}].skills`) && (
            <ProfileProjectKeyword name={`${prefixName}[${index}].skills`} />
            )
          }

          </div>
        ))
      }

    </ProjectRegisterFrame>
  );
};
export default ResumeEditProject;
