/* eslint-disable class-methods-use-this */

import URIs from 'consts/URIs';
import { AccApplyTutorialUrlRs, IAccApplyDeleteRq, IAccResult } from 'interfaces/_v2/acc/IAccGate';
import { request } from 'utils/request';

export default interface AccRepo{

    // 응시 결과 목록
    fetchAccResults(): Promise<IAccResult>;

    // 응시 이력 삭제
    fetchAccDelete(rq:IAccApplyDeleteRq): Promise<boolean>;

    // 역검 튜토리얼 url 조회
    fetchAccApplyTrialUrl(): Promise<AccApplyTutorialUrlRs>;

}

export class RemoteAccRepo implements AccRepo {
  fetchAccResults(): Promise<IAccResult> {
    return request<IAccResult>({
      method: 'get',
      url: URIs.get_v2_acc_results,
    });
  }

  fetchAccDelete(rq:IAccApplyDeleteRq): Promise<boolean> {
    return request({
      method: 'delete',
      url: URIs.delete_v3_acc,
      data: rq,
    });
  }

  fetchAccApplyTrialUrl(): Promise<AccApplyTutorialUrlRs> {
    return request({
      method: 'get',
      url: URIs.get_acc_apply_trial_url,
    });
  }
}
