import { Button, ButtonMode } from '__designkit__/button/Button';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import ConditionEditModal from 'components/_v2/profile/condition/ConditionEditModal';
import ConditionListPopUp from 'components/_v2/profile/match/ConditionListPopUp';
import { Divider1G40 } from 'components/divider/Divider';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Text = styled.div`
  text-align: center;
  font: ${Fonts.H4_Bold};
  white-space: pre-wrap;

  &.semi {
    color: ${Colors.CG_70};
    font: ${Fonts.B2_Medium_P};

    > b {
        font: ${Fonts.B2_Bold_P};
        color: ${Colors.JOBDA_BLACK};
    }
  }

  .green {
    color: ${Colors.G_200};
  }

`;

const ButtonFrame = styled.div`
    position: fixed;
    padding: 16px;
    bottom: calc(env(safe-area-inset-bottom));
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;


    .confirm-button {
        margin-right: 8px;
    }
`;

interface IConditionCheckModal {
    onClickClose: () => void;
}

const ConditionCheckContent:FC<IConditionCheckModal> = ({ onClickClose }) => {
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);

  return (
    <>
      <Frame>
        <Text>혹시 <span className='green'>선호하는 근무 조건</span>이<br />바뀌셨나요?</Text>
        <SpacingBlock size={8} vertical />
        <Text className='semi'>정확히 작성해주시면 <b>딱 맞는 제안</b>을 드릴게요.</Text>
        <SpacingBlock size={32} vertical />
        <Divider1G40 />
        <SpacingBlock size={32} vertical />
        <ConditionListPopUp />
        <SpacingBlock size={20} vertical />
        <ButtonFrame>
          <Button
            label='닫기'
            className='confirm-button'
            buttonMode={ButtonMode.DEFAULT}
            size='large'
            outLined
            onClick={onClickClose}
          />
          <Button
            label='선호 정보 수정'
            buttonMode={ButtonMode.PRIMARY}
            size='large'
            outLined
            onClick={() => {
              setIsEditOpen(true);
            }}
          />
        </ButtonFrame>
        <SpacingBlock size={16} vertical />
      </Frame>
      <ConditionEditModal isOpen={isEditOpen} onClose={() => setIsEditOpen(false)} />
    </>
  );
};

export default ConditionCheckContent;
