import React, {FC, useEffect, useRef} from "react";
import ScrollUtil from "../utils/ScrollUtil";

const convert0Between100 = (value: number) => {
  return Math.min(Math.max(value, 0), 100);
};

const getRate = (height: number, offset: number) => {
  const rate = ((height - offset) / height) * 100;
  return convert0Between100(rate);
};

interface IParallaxFrameProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  onParallax?: (rate: number) => void;
  onParallaxView?: (rate: number) => void;
  onParallaxTop?: (rate: number) => void;
  onParallaxBottom?: (rate: number) => void;
}

const ParallaxFrame: FC<IParallaxFrameProps> = ((props) => {
  const frameRef = useRef<HTMLDivElement>(null);

  const scrollHandler = async () => {
    if (!frameRef.current) return;
    const rect = frameRef.current.getBoundingClientRect();
    const { height, top, bottom } = rect;
    if (props.onParallax) {
      const fullHeight = window.innerHeight + height;
      const parallaxRate = getRate(fullHeight, bottom);
      props.onParallax(parallaxRate);
    }
    if (props.onParallaxView) {
      const viewRate = getRate(height, bottom - window.innerHeight);
      props.onParallaxView(viewRate);
    }
    if (props.onParallaxTop) {
      const topRate = getRate(window.innerHeight, top);
      props.onParallaxTop(topRate);
    }
    if (props.onParallaxBottom) {
      const bottomRate = getRate(window.innerHeight, bottom);
      props.onParallaxBottom(bottomRate);
    }
  };

  useEffect(() => {
    ScrollUtil.addEventListener(scrollHandler);
    return () => {
      ScrollUtil.removeEventListener(scrollHandler);
    }
  }, []);

  return (
    <div ref={frameRef} {...props}>
      { props.children }
    </div>
  );
});

export default ParallaxFrame;
