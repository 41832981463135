import React from 'react';
import classnames from 'classnames/bind';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

const Intro = () => (
  <div className={cx('introBox')}>
    <div className={cx('introTitle')} />
  </div>
);

export default Intro;
