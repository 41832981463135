import React, { FC } from 'react';
import IComponentProps from 'interfaces/props/IComponentProps';
import styled from 'styled-components';
import colors from '__designkit__/common/colors';
import Fonts, { fontStyle } from '__designkit__/common/fonts';
import { keywordTag, absoluteVerticalCenter } from 'consts/style/mixins';
import iconRightSimple16Regular from 'assets/icon/icon_right_simple_16_Regular.svg';
import { ISimpleInfoDto } from 'interfaces/IPassReviewSimpleRs';
import { ProcessTypeTagText } from 'consts/ProcessType';
import { passReviewTitle } from 'utils/pass/PassReviewUtils';

interface IPassReviewListItem extends IComponentProps {
  simpleInfo: ISimpleInfoDto;
}

const Frame = styled.article`
  position:relative;
  cursor:pointer;
  margin:0 16px;
  margin-top:12px;
  border: 1px solid ${colors.CG_40};
  border-radius:4px;
  padding:15px;
  .title {
    ${fontStyle.BODY1_SEMIBOLD};
    color:${colors.CG_90};
  }
  .job-title {
    font: ${Fonts.B3_Medium};
    color:${colors.CG_80};
    margin-top:4px;
  }
  .ul-tags {
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    justify-content:flex-start;
    align-items:flex-start;
    margin-top:24px;
    .tag {
      ${keywordTag()};
      margin-right:8px;
    }
  }
  &[data-new=true]:after {
    content:'NEW';
    ${fontStyle.CAPTION2_SEMIBOLD};
    position:absolute;
    top:16px;
    right:16px;
    color:${colors.ERROR};
  }
  &:before {
    content:'';
    display:block;
    ${absoluteVerticalCenter()};
    width:16px;
    height:16px;
    right:16px;
    background:url(${iconRightSimple16Regular});
    background-repeat:no-repeat;
  }
`;

const PassReviewListItem:FC<IPassReviewListItem> = ({ simpleInfo: { displayNew, halfYearType, jobGroupName, name, processTypeList, recruitmentType, recruitmentYear } }) => (
  <Frame data-new={displayNew}>
    <h1 className='title'>{passReviewTitle(recruitmentYear, halfYearType, recruitmentType)}</h1>
    <div className='job-title'>
      {jobGroupName}
    </div>
    <ul className='ul-tags'>
      {
          processTypeList.map((processType) => (
            <li className='tag' key={`li-pass-review-${name}-${jobGroupName}-${processType}`}>{ProcessTypeTagText[processType]}</li>
          ))
        }
    </ul>
  </Frame>
);

export default PassReviewListItem;
