import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { JDRoundRadioBox } from 'consts/_v2/_common/style/mixins';
import { JDRadioCircleInput } from 'consts/style/mixins';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { forwardRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components/macro';

export enum JD_RADIO_INPUT_TYPE {
  ROUND_BOX = 'ROUND_BOX',
  SQUARE_CHIP = 'SQUURE_CHIP',
  ROUND_BLACK_CHIP = 'ROUND_BLACK_CHIP',
  ROUND_CIRCLE_BOX = 'ROUND_CIRCLE_BOX',
  ROUND_COMMON_CHIP = 'ROUND_COMMON_CHIP',
}

const StyledSquareChip = () => css`
  margin-right: 8px;
  margin-bottom: 8px;
  input {
    display: none;
    + .label {
      height: 34px;
      display: inline-flex;
      padding: 8px 12px;
      align-items: center;
      text-align: center;
      font: ${Fonts.B3_Medium};
      color: ${colors.CG_80};
      border: 1px solid ${colors.CG_40};
      border-radius: 4px;
      transition: background 0.3s, border 0.3s;
    }
    &:checked + .label {
      height: 34px;
      font: ${Fonts.B3_Bold};
      color: ${colors.G_150};
      border: 1px solid ${colors.G_150};
    }
  }
`;

const StyledSquareBlackChip = () => css`
  margin-right: 8px;
  margin-bottom: 8px;
  input {
    display: none;
    + .label {
      height: 34px;
      display: inline-flex;
      padding: 8px 12px;
      align-items: center;
      text-align: center;
      font: ${Fonts.B3_Medium};
      color: ${colors.CG_70};
      background: ${colors.CG_30};
      border-radius: 100px;
      transition: background 0.3s, border 0.3s;
    }
    &:checked + .label {
      height: 34px;
      font: ${Fonts.B3_Bold};
      color: ${colors.WHITE_100};
      background: ${colors.G_150};
      border: 1px solid ${colors.G_150};
    }
  }
`;

const StyledSquareCommonChip = () => css`
  margin-right: 8px;
  margin-bottom: 8px;
  input {
    display: none;
    + .label {
      height: 34px;
      display: inline-flex;
      padding: 8px 12px;
      align-items: center;
      text-align: center;
      font: ${Fonts.B3_Medium};
      color: ${colors.CG_70};
      background: ${colors.WHITE_100};
      border-radius: 100px;
      transition: background 0.3s, border 0.3s;
      border: 1px solid ${colors.CG_40};

    }
    &:checked + .label {
      height: 34px;
      font: ${Fonts.B3_Medium};
      color: ${colors.JOBDA_BLACK};
      background: ${colors.WHITE_100};
      border: 1px solid ${colors.JOBDA_BLACK};
    }
  }
`;
const StyledRoundBox = () => css`
  display: flex;
  align-items: center;
  label {
    display: flex;
    align-items: center;


    input[type='radio'] {
      ${JDRoundRadioBox()};
      &:checked {
        background: ${colors.G_150};
      }
      :after {
        border: 2px solid ${colors.CG_50};
      }
    }
    
    span {
      font: ${Fonts.B2_Medium};
      line-height: 20px;
      color: ${colors.CG_90};
      padding-left: 8px;
      vertical-align: -webkit-baseline-middle;
      em {
        font: ${Fonts.B2_Bold};
      }

      &[aria-disabled=true] {
        color: ${colors.CG_60};
      }
    }  
  }
`;

const StyledRoundCircleBox = () => css`
  display: flex;
  align-items: center;
  label {
    display: flex;
    align-items: center;
    input[type='radio'] {
      ${JDRadioCircleInput()}
      &:checked {
        background: ${colors.G_150};
      }
      :after {
        border: 2px solid ${colors.CG_50};
      }
    }
    span {
      font: ${Fonts.B2_Medium};
      line-height: 20px;
      color: ${colors.CG_80};
      padding-left: 8px;
      vertical-align: -webkit-baseline-middle;
      em {
        font: ${Fonts.B2_Bold};
      }
    }
  }

  &[aria-disabled='true'] {
    span {
      opacity: 0.5;
    }
  }
`;

const Frame = styled.div<{ type: JD_RADIO_INPUT_TYPE }>`
 ${(props) => props.type === JD_RADIO_INPUT_TYPE.SQUARE_CHIP && StyledSquareChip()}
 ${(props) => props.type === JD_RADIO_INPUT_TYPE.ROUND_BLACK_CHIP && StyledSquareBlackChip()}
 ${(props) => props.type === JD_RADIO_INPUT_TYPE.ROUND_BOX && StyledRoundBox()}
 ${(props) => props.type === JD_RADIO_INPUT_TYPE.ROUND_COMMON_CHIP && StyledSquareCommonChip()}
 ${(props) => props.type === JD_RADIO_INPUT_TYPE.ROUND_CIRCLE_BOX
    && StyledRoundCircleBox()}
`;

interface IJDRadio extends IComponentProps {
  name: string;
  value: string;
  type?: JD_RADIO_INPUT_TYPE;
  label?: JSX.Element;
  onChange?: any;
  defaultValue?: string;
}

const JDRadioInputTemp = forwardRef((props: IJDRadio) => {
  const {
    name,
    label,
    onChange,
    value,
    defaultValue,
    className,
    disabled,
    type = JD_RADIO_INPUT_TYPE.SQUARE_CHIP,
  } = props;
  const useFormed = useFormContext();
  const { control, setValue, register } = useFormed;
  useController({ name, control, defaultValue });

  return (
    <Frame
      className={`jd-input ${className || ''}`}
      aria-disabled={disabled}
      type={type}
    >
      <label>
        <input
          type='radio'
          ref={register()}
          onChange={(e) => {
            setValue(name, e.target.value);
            if (onChange) onChange(e.target.checked);
          }}
          checked={defaultValue === value}
          value={value}
          disabled={disabled}
        />
        <span className='label' aria-disabled={disabled}>{label}</span>
      </label>
    </Frame>
  );
});

export default JDRadioInputTemp;
