import { IonItem } from '@ionic/react';
import RoutePaths from 'consts/RoutePaths';
import CustomInputPage from 'pages/hiddenPage/v2/CustomInputPage';
import React, { FC, useCallback } from 'react';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import CommonUtils from 'utils/CommonUtils';
import CountingPage from './CountingPage';
import FilterFormPage from './FilterFormPage';
import FlipPage from './FlipPage';
import FontsPage from './fontsPage';
import FullScreenModalPage from './FullScreenModalPage';
import InterviewComponentsPage from './InterviewComponentsPage';
import JdaCustomFormPage from './JdaCustomFormPage';
import MixinPage from './MixinPage';
import ModalPage from './ModalPage';
import ScrollParallaxPage from './ScrollParallaxPage';
import SelectOptionPage from './SelectOptionPage';
import SharePage from './SharePage';
import SlidingTextPage from './SlidingTextPage';
import SingleMenuItemPage from './SingleMenuItemPage';
import TextFieldPage from './TextFieldPage';

enum HiddenPagePath {
  customInputPage='customInputPage',
  fontsPage = 'fontsPage',
  chartPage = 'chartPage',
  filterForm = 'filterForm',
  jdaHookForm = 'jdaHookForm',
  mixinPage= 'mixinPage',
  sharePage = 'sharePage',
  selectOptionPage = 'selectOptionPage',
  modalPage = 'modalPage',
  fullScreenModalPage = 'fullScreenModalPage',
  countingPage = 'countingPage',
  scrollParallaxPage = 'scrollParallaxPage',
  slidingTextPage = 'slidingText',
  toastPage = 'toastPage',
  flipPage = 'flipPage',
  interviewComponents = 'interviewComponents',
  companyPassReviewPage = 'companyPassReviewPage',
  smartFilterCompanyCardPage = 'smartFilterCompanyCardPage',
  newMainComponentPage = 'newMainComponentPage',
  companyCompare = 'companyCompare',
  jobMatch = 'jobMatch',
  singleMenuItem = 'singleMenuItem',
  textInput = 'textInput',
}

const Main = (
  <div>
    <div style={{ width: '100%', height: '2px', background: 'green', marginBottom: '30px' }} />
    <Link to={RoutePaths.hiddenPage(HiddenPagePath.customInputPage)}>
      <IonItem>V2 커스텀 React Hook form Input</IonItem>
    </Link>
    <div style={{ width: '100%', height: '2px', background: 'green', marginBottom: '30px' }} />
    <Link to={RoutePaths.hiddenPage(HiddenPagePath.fontsPage)}>
      <IonItem>폰트 모음</IonItem>
    </Link>
    <Link to={RoutePaths.hiddenPage(HiddenPagePath.chartPage)}>
      <IonItem>차트 모음</IonItem>
    </Link>
    <Link to={RoutePaths.hiddenPage(HiddenPagePath.mixinPage)}>
      <IonItem>Mixin 모음</IonItem>
    </Link>
    <Link to={RoutePaths.hiddenPage(HiddenPagePath.sharePage)}>
      <IonItem>공유 버튼</IonItem>
    </Link>
    <Link to={RoutePaths.hiddenPage(HiddenPagePath.jdaHookForm)}>
      <IonItem>커스텀 React Hook form Input</IonItem>
    </Link>
    <Link to={RoutePaths.hiddenPage(HiddenPagePath.selectOptionPage)}>
      <IonItem>Select Option</IonItem>
    </Link>
    <Link to={RoutePaths.hiddenPage(HiddenPagePath.modalPage)}>
      <IonItem>모달 페이지</IonItem>
    </Link>
    <Link to={RoutePaths.hiddenPage(HiddenPagePath.fullScreenModalPage)}>
      <IonItem>풀스크린 모달 페이지</IonItem>
    </Link>
    <Link to={RoutePaths.hiddenPage(HiddenPagePath.countingPage)}>
      <IonItem>숫자 올라가는거 + 하트 물결도</IonItem>
    </Link>
    <Link to={RoutePaths.hiddenPage(HiddenPagePath.scrollParallaxPage)}>
      <IonItem>스크롤 Parallax 페이지</IonItem>
    </Link>
    <Link to={RoutePaths.hiddenPage(HiddenPagePath.toastPage)}>
      <IonItem>토스트 페이지</IonItem>
    </Link>
    <Link to={RoutePaths.hiddenPage(HiddenPagePath.flipPage)}>
      <IonItem>카드 뒤집기 페이지</IonItem>
    </Link>
    <Link to={RoutePaths.hiddenPage(HiddenPagePath.interviewComponents)}>
      <IonItem>합격 인터뷰 컴포넌트 만드는 페이지</IonItem>
    </Link>
    <Link to={RoutePaths.hiddenPage(HiddenPagePath.companyPassReviewPage)}>
      <IonItem>합격자 리뷰 검색 페이지</IonItem>
    </Link>
    <Link to={RoutePaths.hiddenPage(HiddenPagePath.filterForm)}>
      <IonItem>필터용 폼 모음</IonItem>
    </Link>
    <Link to={RoutePaths.hiddenPage(HiddenPagePath.smartFilterCompanyCardPage)}>
      <IonItem>스마트필 필터용 기업 카드</IonItem>
    </Link>
    <Link to={RoutePaths.hiddenPage(HiddenPagePath.newMainComponentPage)}>
      <IonItem>신규 메인 페이지 카드 컴포넌트</IonItem>
    </Link>
    <Link to={RoutePaths.hiddenPage(HiddenPagePath.companyCompare)}>
      <IonItem>기업 큐레이션 기업 비교용 페이지</IonItem>
    </Link>
    <Link to={RoutePaths.hiddenPage(HiddenPagePath.singleMenuItem)}>
      <IonItem>싱글 메뉴 아이템</IonItem>
    </Link>
    <Link to={RoutePaths.hiddenPage(HiddenPagePath.textInput)}>
      <IonItem>텍스트, 라디오 필드</IonItem>
    </Link>
  </div>
);

const HiddenPage: FC = (() => {
  const history = useHistory();
  if (!CommonUtils.isDevEnv())
    history.replace('/');

  const { page } = useParams<{ page: string }>();

  const getPageComponent = useCallback(() => {
    switch (page) {
      case HiddenPagePath.customInputPage:
        return <CustomInputPage />;
      case HiddenPagePath.fontsPage:
        return <FontsPage />;
      case HiddenPagePath.filterForm:
        return <FilterFormPage />;
      case HiddenPagePath.jdaHookForm:
        return <JdaCustomFormPage />;
      case HiddenPagePath.mixinPage:
        return <MixinPage />;
      case HiddenPagePath.sharePage:
        return <SharePage />;
      case HiddenPagePath.selectOptionPage:
        return <SelectOptionPage />;
      case HiddenPagePath.modalPage:
        return <ModalPage />;
      case HiddenPagePath.fullScreenModalPage:
        return <FullScreenModalPage />;
      case HiddenPagePath.countingPage:
        return <CountingPage />;
      case HiddenPagePath.scrollParallaxPage:
        return <ScrollParallaxPage />;
      case HiddenPagePath.slidingTextPage:
        return <SlidingTextPage />;
      case HiddenPagePath.flipPage:
        return <FlipPage />;
      case HiddenPagePath.interviewComponents:
        return <InterviewComponentsPage />;
      case HiddenPagePath.singleMenuItem:
        return <SingleMenuItemPage />;
      case HiddenPagePath.textInput:
        return <TextFieldPage />;
      default:
        return Main;
    }
  }, [page]);

  return getPageComponent();
});

export default HiddenPage;
