import Colors from '__designkit__/common/colors';
import ChattingItem from 'components/_v2/chatting/ChattingItem';
import IComponentProps from 'interfaces/props/IComponentProps';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import ChattingModel from 'models/_v2/ChattingModel';
import { injectStore } from 'models/store';
import React, { FC, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { Message } from 'talkplus-sdk';
import { convertMsToDate } from 'utils/_v2/timeUtils';

const Frame = styled.div<{ isKeyboard: boolean, isReplyMode: boolean }>`
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: ${({ isKeyboard, isReplyMode }) => (isKeyboard ? isReplyMode ? `calc(100% - 64px + env(safe-area-inset-bottom) - 59px)` : `calc(100% - 64px + env(safe-area-inset-bottom))` : isReplyMode ? `calc(100% - 64px - 59px)` : `calc(100% - 64px)`)};
  padding: 8px 8px;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${Colors.CG_50};
  }
`;

interface IChattingListProps extends IComponentProps {
  messages: Message[];
  context?: Context;
  chattingModel?: ChattingModel;
}

const ChattingList: FC<IChattingListProps> = ({ messages = [], listRef, context = new Context(), chattingModel = new ChattingModel() }) => {
  const onClickMore = useCallback(async () => {
    const temp = listRef.current.scrollTop;
    if (chattingModel.isFetchAllMesaage) return;
    if (chattingModel.isLoading) return;
    chattingModel.setIsLoading(true);
    const res = await chattingModel.getChattingMessageList();
    chattingModel.setIsLoading(false);
    chattingModel.setMessageList(res.messages, 'fetch');
    chattingModel.setLastMessageId(res.hasNext ? res.messages[res.messages.length - 1].id : undefined);
    listRef.current.scrollTop = temp;
  }, [chattingModel, listRef]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.addEventListener('scroll', () => {
        if (listRef.current) {
          if (listRef.current.scrollTop <= 0 - ((listRef.current.scrollHeight - listRef.current.clientHeight) - 10)) {
            onClickMore();
          }
        }
      });
    }
  }, [listRef, onClickMore]);

  return (
    <Frame ref={listRef} isKeyboard={context.keyBoardHeight > 0} isReplyMode={!!chattingModel.replyMessage}>
      { messages.map((message, index) => (
        <ChattingItem
          key={message.id}
          item={message}
          isMine={message.userId === chattingModel.user?.id}
          isDayFirst={index === messages.length - 1 ? true : convertMsToDate(messages[index + 1].createdAt) !== convertMsToDate(message.createdAt)}
          isContinue={index === messages.length - 1 ? false : messages[index + 1].userId === message.userId}
        />
      )) }
    </Frame>
  );
};

export default inject(injectStore.context, injectStore.chattingModel)(observer(ChattingList));
