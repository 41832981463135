import { ReactComponent as IconInfoCircleGrey } from 'assets/icon/icon_information_circle_20_grey.svg';
import { StyledIconSVG } from 'consts/assets/icons/iconPages';
import colors from '__designkit__/common/colors';
import shadows from 'consts/style/shadows';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { FC, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

export const Anchor = styled.div`
  position:relative;
  display: flex;
  align-items: center;
  width: fit-content;
  height: 20px;
  .btn-open {
    display:inline;
    background:transparent;
    border:none;
    width:fit-content;
    height:fit-content;
  }
`;

export const Popover = styled.article<{ popoverWidth?: number, popoverHeight?: number, popoverMargin?:number, whiteMode?:boolean }>`
  position:absolute;
  z-index: 2;
  padding:14px; 
  width:${(props) => (props.popoverWidth ? `${props.popoverWidth}px` : '222px')};
  ${(props) => (props.popoverHeight ? `height:${props.popoverHeight}px` : '')};
  white-space: break-spaces;
  word-break: break-word;
  background: ${(props) => (props.whiteMode ? `${colors.WHITE_100}` : `${colors.CG_70}`)};
  ${(props) => (props.whiteMode ? `border: 1px solid ${colors.CG_40}` : `border: 1px solid ${colors.CG_70}`)};
  border-radius:8px;
  ${shadows.xlarge};
  // filter: drop-shadow(1px 2px 2px ${colors.c000000_06}) drop-shadow(1px 2px 3px ${colors.c000000_10}) drop-shadow(-1px -1px 0px ${colors.CG_40}) drop-shadow(1px 1px 0px ${colors.CG_40});
  
  opacity:0;
  transform-origin:top left;
  
  &.active {
    /* animation:popover .5s forwards; */
    opacity: 1;
  }
  
  &.inactive {
    /* animation:popover-reverse .5s forwards; */
    display: none;
    opacity: 0;
  }
  
  &:before {
    content:'';
    display:block;
    width:0px;
    height:0px;
    position:absolute;
    border-style:solid;
  }
  &:after {
    content:'';
    display:block;
    width:0px;
    height:0px;
    position:absolute;
    border-style:solid;
  }
  
  &.side {
    left: 32.5px;
    top: -13px;
    &:before {
      top:14px;
      left:-9px;
      border-width:8px 9px 8px 0;
      border-color: transparent ${colors.CG_70} transparent transparent;
    }
    &:after {
      top:16px;
      left:-8px;
      border-width:6px 8px 6px 0;
      border-color: transparent ${colors.CG_70} transparent transparent;
    }
  }

  &.side-left-center {
    left: 32.5px;
    top: -200px;
    &:before {
      top: 200px;
      left:-9px;
      border-width:8px 9px 8px 0;
      border-color: transparent ${colors.CG_70} transparent transparent;
    }
    &:after {
      top: 200px;
      left:-8px;
      border-width: 6px 8px 6px 0;
      border-color: transparent ${colors.CG_70} transparent transparent;
    }
  }

  &.under {
    left: -17px;
    ${(props) => (props.popoverMargin ? `top:${32.5 + props.popoverMargin}px` : `top:32.5px`)};
    &:before {
      top: -9px;
      left: 18px;
      border-width:0 8px 9px 8px;
      border-color: transparent transparent ${colors.CG_70} transparent;
    }
    &:after {
      top: -8px;
      left: 20px;
      border-width: 0 6px 8px 6px;
      border-color: transparent transparent ${colors.CG_70} transparent;
    }
  }

  &.under_no_left {
    left: 0px;
    ${(props) => (props.popoverMargin ? `top:${32.5 + props.popoverMargin}px` : `top:32.5px`)};
    &:before {
      top: -9px;
      left: 18px;
      border-width:0 8px 9px 8px;
      border-color: transparent transparent ${colors.CG_70} transparent;
    }
    &:after {
      top: -8px;
      left: 20px;
      border-width: 0 6px 8px 6px;
      border-color: transparent transparent ${colors.CG_70} transparent;
    }
  }

  &.under_left {
    right: -15px;
    top: 32.5px;
    &:before {
      top: -9px;
      right: 18px;
      border-width:0 8px 9px 8px;
      border-color: transparent transparent ${colors.CG_70} transparent;
    }
    &:after {
      top: -8px;
      right: 20px;
      border-width: 0 6px 8px 6px;
      border-color: transparent transparent ${colors.CG_70} transparent;
    }
  }

  &.up {
    ${(props) => (props.popoverHeight ? `top:-${props.popoverHeight}px` : '')};
    left: 0px;
    &:before {
      top: -9px;
      right: 18px;
      border-width:0 8px 9px 8px;
      border-color: transparent transparent ${colors.CG_70} transparent;
    }
    &:after {
      top: -8px;
      right: 20px;
      border-width: 0 6px 8px 6px;
      border-color: transparent transparent ${colors.CG_70} transparent;
    }
  }

  &.non_pony_under_left {
    ${(props) => (props.popoverHeight ? `top:-${props.popoverHeight}px` : '')};
    left: -60px;
    top: 24px;

    &:before, &:after {
      border: none;
    }

  }

  &.non_pony_under_right {
    ${(props) => (props.popoverHeight ? `top:-${props.popoverHeight}px` : '')};
    right: -8px;
    top: 26px;

    &:before, &:after {
      border: none;
    }

  }
  &.non_pony_under {
    left: -30px;
    ${(props) => (props.popoverMargin ? `top:${32.5 + props.popoverMargin}px` : `top:20px`)};
    &:before, &:after {
      border: none;
    }
  }
  @keyframes popover {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  @keyframes popover-reverse {
    0% {opacity:1;}
    100% {opacity:0;}
  }
`;

interface IJDAPopoverProps extends IComponentProps {
  position?: 'side' | 'side-left-center' | 'under' | 'under_left' | 'under_no_left' |'up' | 'non_pony_under_left' | 'non_pony_under_right' | 'non_pony_under';
  anchorIcon?: JSX.Element;
  popoverWidth?: number;
  popoverHeight?: number;
  popoverMargin?: number;
  useOutsideClick?: boolean;
  whiteMode?: boolean;
}

// TODO: 애니메이션 고도화는 시간 생기면..
const JDAPopover: FC<IJDAPopoverProps> = ((props) => {
  const { position = 'side', children, whiteMode, popoverMargin, anchorIcon, popoverWidth, popoverHeight, useOutsideClick, ...rest } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [visibleOpen, setVisibleOpen] = useState<boolean>(false);
  const wrapperRef = useRef<any>(null);

  useEffect(() => {
    if (open) {
      setVisibleOpen(true);
    }
  }, [open]);

  const useOutsideClicker = (ref:any) => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setVisibleOpen(false);
      }
    }

    useEffect(() => {
      if (useOutsideClick) {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }
      return () => {};
    }, [ref]);
  };

  useOutsideClicker(wrapperRef);

  return (
    <Anchor ref={wrapperRef} className='anchor-frame' {...rest}>
      <button type='button' className='btn-open' onClick={() => setOpen((_open) => !_open)}>
        {anchorIcon ?? <StyledIconSVG fill={colors.CG_60}><IconInfoCircleGrey width={20} height={20} className='icon' /></StyledIconSVG>}
      </button>
      {
        visibleOpen && (
          <Popover
            role='presentation'
            className={open ? `active ${position}` : `inactive ${position}`}
            onAnimationEnd={() => !open && setVisibleOpen(false)}
            onClick={() => setOpen(false)}
            popoverWidth={popoverWidth}
            popoverHeight={popoverHeight}
            popoverMargin={popoverMargin}
            whiteMode={whiteMode}
          >
            { props.children }
          </Popover>
        )
      }
    </Anchor>
  );
});

export default JDAPopover;
