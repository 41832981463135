import JDFullModal from 'components/_v2/_common/modals/JDFullModal';
import JDFullModalHeader from 'components/_v2/_common/modals/JDFullModalComponents';
import ProfilePreferential from 'components/_v2/profile/preferential/ProfilePreferential';
import IModalProps from 'interfaces/props/IModalProps';
import React, { FC } from 'react';

const ProfilePreferentialEditModal:FC<IModalProps> = ({ isOpen, onClose }) => (
  !isOpen ? <></> : (
    <JDFullModal handleClose={onClose}>
      <ProfilePreferential onClose={onClose} />
    </JDFullModal>
  )
);

export default ProfilePreferentialEditModal;
