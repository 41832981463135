import styled from 'styled-components';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';

const SkeletonFrame = styled.div`
  display: flex;
  flex-direction: column;
  width: 302px;
  border-radius: 8px;
  background-color: ${Colors.WHITE_100};
  border: 1px solid ${Colors.CG_40};

  .thumbnail-img-skeleton {
    width: 100%;
    height: 168px;
    background-color: ${Colors.CG_30};
    border-radius: 8px 8px 0 0;
  }

  .list-desc-skeleton {
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .title-skeleton {
      width: 70%;
      height: 20px;
      background-color: ${Colors.CG_30};
    }

    .date-skeleton {
      width: 50%;
      height: 16px;
      background-color: ${Colors.CG_30};
    }

    .progress-skeleton {
      margin-top: 16px;
      width: 100%;
      height: 8px;
      background-color: ${Colors.CG_30};
      border-radius: 48px;
    }
  }
`;

const SkeletonResultCard = () => {
  return (
    <SkeletonFrame>
      <div className='thumbnail-img-skeleton' />
      <div className='list-desc-skeleton'>
        <div className='date-skeleton' />
        <div className='title-skeleton' />
        <div className='progress-skeleton' />
      </div>
    </SkeletonFrame>
  );
};

export default SkeletonResultCard;
