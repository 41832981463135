import React from 'react';

import classnames from 'classnames/bind';
import styles from './description.module.scss';

const cx = classnames.bind(styles);

const Description = () => (
  <div className={cx('wrap')}>
    <div className={cx('inner')}>
      <div className={cx('descArea', 'type1')}>
        <em className={cx('title')}>
          안된다고 말하기도 지겨운 당신
          <br />
          기획자랑 싸우기도 지친 당신
          <br />
          그치만 개발은 사랑하고 즐거워하는 당신
        </em>
      </div>

      <div className={cx('descArea', 'type2')}>
        <em className={cx('title')}>
          인사 담당자를 사로잡는
          <br />
          D:CHAMPS 한 번, 참여해보실래요?
        </em>
      </div>

      <div className={cx('descArea', 'type3')}>
        <em className={cx('title')}>
          디챔스에서 나의 개발 구현 능력 등급은 물론,
          <br />
          면접에서 활용할 수 있는 나의 강점, 약점, 특성 등을
          <br />
          무료로 진단 받을 수 있어요!
        </em>
      </div>
    </div>
  </div>
);

export default Description;
