import { App, URLOpenListenerEvent } from '@capacitor/app';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const DeepLinkAppUrlListener: React.FC<any> = () => {
  const history = useHistory();
  useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      let fixUrl = event.url;
      fixUrl = fixUrl.replace('https://', '');
      fixUrl = fixUrl.replace('jobda://', '');
      fixUrl = fixUrl.replace('m.jobda.im', '');
      fixUrl = fixUrl.replace('m-jobda-im.kr-st-jainwon.com', '');
      fixUrl = fixUrl.replace('m-jobda-im.kr-dv-jainwon.com', '');
      fixUrl = fixUrl.replace('st2-m-jobda-im.kr-st-jainwon.com', '');
      if (fixUrl.charAt(0) !== '/') fixUrl = `/${fixUrl}`;
      console.log(`App opened with URL: ${fixUrl}`);
      if (fixUrl) {
        history.push(fixUrl);
      }
    });
  }, []);

  return null;
};

export default DeepLinkAppUrlListener;
