import { IconClose } from 'consts/assets/icons/iconPages';
import colors from '__designkit__/common/colors';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import DisableScroll from './common/DisableScroll';
import Portal from './common/Portal';

export interface IJDAFullScreenModalProps extends IComponentProps {
  isOpen?: boolean;
  onClose?: () => void;
}

// const TIMEOUT_MILLISECOND = 500;

export const JDAFullScreenModalFrame = styled.section`
  display:block;
  position:fixed;
  z-index:10003;
  width:100%;
  height:100%;
  background:${colors.WHITE_100};
  top:0;
  left:0;
  overflow-y: auto;
  animation-name: open;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;

  .btn-close {
    position: absolute;
    right:16px;
    top:16px;
    background:none;
  }
  &.closing {
    opacity:0;
  }
  @keyframes open {
    0% {
      transform:translateY(100%);
      opacity:1;
    }
    100% {
      transform:translateY(0%);
      opacity:1;
    }
  }

`;

const JDAFullScreenModal:FC<IJDAFullScreenModalProps> = ({ isOpen, onClose, className, children, ...props }) => {
  const [closing, setClosing] = useState<boolean>(false);
  useEffect(() => {
    if (isOpen)
      setClosing(false);
  }, [isOpen]);
  return (
    !isOpen ? <></>
      : (
        <Portal>
          <DisableScroll />
          <JDAFullScreenModalFrame {...props} className={`${className ?? ''} ${closing ? 'closing' : ''}`}>
            {children}
            <button
              className='btn-close'
              title='닫기'
              onClick={async () => {
                setClosing(true);
                onClose && onClose();
              }}
            >
              <IconClose />
            </button>
          </JDAFullScreenModalFrame>
        </Portal>
      )
  );
};

export default JDAFullScreenModal;
