import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import IconDownSmall from 'assets/icon/icon_down_small_16_Regular.svg';
import IconLeftSimple from 'assets/icon/icon_left_simple_24_Regular.svg';
import { JDAFullScreenModalFrame } from 'components/JDAFullScreenModal';
import JDANoticeAlert from 'components/JDANoticeAlert';
import { TOAST_TYPE } from 'components/_v2/_common/toast/JDToast';
import DisableScroll from 'components/common/DisableScroll';
import LazyImage from 'components/common/LazyImage';
import Portal from 'components/common/Portal';
import { Divider1G40 } from 'components/divider/Divider';
import MatchAtsFailModal from 'components/modals/MatchAtsFailModal';
import MatchScheduleFailModal from 'components/modals/MatchScheduleFailModal';
import { MatchingMessageSenderType } from 'consts/MatchingMessageType';
import RoutePaths from 'consts/RoutePaths';
import useToast from 'hooks/useToast';
import { IOnClickProps } from 'interfaces/matchMessage/IOnClickProps';
import { inject, observer } from 'mobx-react';
import MatchMessageStore from 'models/MatchMessageStore';
import MatchHomeDashboardModel from 'models/_v2/MatchHomeDashboardModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { DateFormatYYYYMMDDWEEk, getCreatedDayTime, getFullTimeDot } from 'utils/DateUtils';
import MatchReportModel from 'models/_v2/MatchReportModel';
import JDFullModal from 'components/_v2/_common/modals/JDFullModal';
import JDFullModalHeader from 'components/_v2/_common/modals/JDFullModalComponents';
import { absoluteHorizonCenter } from 'consts/style/mixins';
import Icon from '__designkit__/icon/Icon';
import MatchDashBoardBalloonModal from './MatchDashBoardBalloonModal';
import MatchDashBoardCompanyMessage from './MatchDashBoardCompanyMessage';
import MatchDashBoardUserMessage from './MatchDashBoardUserMessage';
/**
 * 매칭 메시지/채팅 말풍선 UI
 * 인담자/구직자 타입이 존재
 * 메시지 API 경우 시간순 메시지를 응답값을 내려줌
 * [
 *  { sendDateTime: 11:22:33, ... },
 *  { sendDateTime: 11:23:45, ... }
 * ]
 * 같은 날짜에 속한 메시지는 날짜 표시선으로 그루핑 처리 하므로
 * 배열을 순회하며 다른 날짜가 나올 시 날짜 표시선을 그리도록 구현
 */
const JDModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 56px;
  background: ${colors.WHITE_100};
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index:1002;
  padding: calc(env(safe-area-inset-top) + 18px) 12px 18px 8px;
  border-bottom: 1px solid ${colors.CG_40};
  align-items: center;
  .icon-back {
    width: 32px;
    height: 32px;
  }

  .btn-close-position {
    display: flex;
    align-items: center;
    height: 26px;
    padding: 6px 8px;
    font: ${Fonts.B3_Medium};
    color: ${colors.CG_80};
    background-color: ${colors.WHITE_100};
    border: 1px solid ${colors.CG_40};
    border-radius: 4px;

    &[aria-disabled=true] {
      opacity: 0.5;
    }
  }
`;
const Frame = styled(JDAFullScreenModalFrame)`
 

`;

const GNBFrame = styled.div`
      ${absoluteHorizonCenter()};

    padding: 10px 0px;
    .gnb-title-frame{
      display: flex;
      gap:2px;
      .gnb-title {
      color: ${colors.CG_80};
      font: ${Fonts.B1_Bold};
      max-width: 128px;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space:pre-line;
    }

    }
  
    .gnb-company {
      display: flex;
      align-items: center;
      margin-top: 2px;
      background: none;

      > span {
        font: ${Fonts.B3_Medium};
        color: ${colors.CG_80};
        max-width: 104px;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space:pre-line;
      }

      .down_icon {
        margin-left: 1px;
      }
    }
`;

const ContentFrame = styled.div< { NoticeYn?: boolean } >`
    width: 100%;
    padding: 16px 16px 8px 16px;
    padding-bottom: ${(props) => (props.NoticeYn ? '120px' : '120px')};
`;

const DateDividerFrame = styled.div`
    .date-text {
      width: fit-content;
      position: relative;
      padding: 4px 16px;
      top: -12px;
      left: 50%;
      transform: translate(-50%, 0);
      font: ${Fonts.B3_Medium};
      color: ${colors.CG_60};
      background: ${colors.WHITE_100};
    }
`;

export interface IMatchDashBoardMessageModalProps {
  matchMessageStore?: MatchMessageStore;
  matchHomeDashboardModel?:MatchHomeDashboardModel;
  matchReportModel?:MatchReportModel;
}

const MatchDashBoardMessageModal: FC<IMatchDashBoardMessageModalProps> = ({ matchReportModel = new MatchReportModel(), matchMessageStore = new MatchMessageStore() }) => {
  const history = useHistory();
  const [atsFailModalOpen, setAtsFailModalOpen] = useState<boolean>(false);
  const [scheduleFailModalOpen, setScheduleFailModalOpen] = useState<boolean>(false);
  const { setToastObject } = useToast();
  const lastMessageRef = useRef<HTMLDivElement>(null);
  const FrameRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // 마지막 메시지가 있으면 해당 위치로 스크롤
    if (lastMessageRef.current && FrameRef.current) {
      FrameRef.current.scrollTo({ top: lastMessageRef.current.offsetTop - 100 });
    }
  }, [matchMessageStore.messagesDto?.messages]);

  const onClick = async ({ type, optionSn, positionSn }: IOnClickProps) => {
    try {
      switch (type) {
        case 'endPosition':
          setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '종료된 포지션입니다.', duration: 1500 });
          break;
        case 'agree':
          await matchMessageStore.AcceptInterview(positionSn, optionSn);
          break;
        // case 'disagree':
        //   await matchMessageStore.RefuseInterview(positionSn, optionSn);
        //   break;
        default:
          break;
      }
      await matchMessageStore.loadMessageList(matchMessageStore.positionSn);
    } catch (e: any) {
      // error 발생 시 B908 시 - ATS 통신 실패. B907 - 이미 선택된 면접
      if (e.response.data.errorCode === 'B908') {
        setAtsFailModalOpen(true);
      } else if (e.response.data.errorCode === 'B907') {
        // TODO: 해당 메세지 스케쥴만 불러오기
        await matchMessageStore.loadMessageList(matchMessageStore.positionSn);
        setScheduleFailModalOpen(true);
      } else if (e.response.data.errorCode === 'B901' || e.response.data.errorCode === 'B909' || e.response.data.errorCode === 'B910') {
        setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '종료된 포지션입니다.', duration: 1500 });
      } else if (e.response.data.errorCode === 'B903') {
        setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '만료된 요청입니다.', duration: 1500 });
      } else {
        setToastObject({ isOpen: true, type: 'ERROR', message: '입사 지원이 실패하였습니다.', position: 'middle' });
      }
      console.error(e);
    }
  };

  const LoadMessage = async () => {
    await matchMessageStore.loadMessageList(matchMessageStore.positionSn);
  };

  useEffect(() => {
    const init = async () => {
      matchMessageStore.init();
      await matchMessageStore.loadMessageList(matchMessageStore.positionSn);
    };
    if (matchMessageStore.isOpen) init();
  }, [matchMessageStore.isOpen]);

  useEffect(() => (() => {
    matchMessageStore.close();
  }), []);

  useEffect(() => {
    const init = async () => {
      if (matchMessageStore.messagesDto?.companySn) {
        await matchReportModel.loadMatchReportCompaniesPositions(matchMessageStore.messagesDto?.companySn || -1);
      }
    };
    init();
  }, [matchMessageStore.messagesDto?.companySn]);
  if (!matchMessageStore.isOpen) return <></>;
  return (
    <JDFullModal>
      <Frame className='match-dashboard-message-modal' ref={FrameRef}>
        <JDModalHeader>
          <Icon name='large-arrow-left' className='icon-back' onClick={() => matchMessageStore.close()} size={32} />
          <GNBFrame>
            <button onClick={() => matchMessageStore.openBalloonModal()}>
              <div className='gnb-title-frame'>
                <p className='gnb-title'>{matchMessageStore.messagesDto?.positionName}</p>
              </div>
            </button>
          </GNBFrame>
          <button
            className='btn-close-position'
            title='포지션 정보'
            onClick={() => {
              history.push(RoutePaths.position_jd(matchMessageStore.positionSn));
              matchMessageStore.close();
            }}
          >
            포지션 정보
          </button>
        </JDModalHeader>
        <ContentFrame NoticeYn={!matchMessageStore.messagesDto?.openYn}>
          {matchMessageStore.messagesDto
              && matchMessageStore.messagesDto?.messages.length !== 0
              && matchMessageStore.messagesDto?.messages.map(({ sender, sendDateTime, ...rest }, idx) => {
                const formattedCreatedDayTime = getCreatedDayTime(sendDateTime);
                const formattedCreatedDateTime = DateFormatYYYYMMDDWEEk(sendDateTime);
                const isLastMessage = matchMessageStore.messagesDto?.messages ? matchMessageStore.messagesDto.messages.length - 1 : -1;
                let prevDateTime = formattedCreatedDateTime;
                // 날짜가 변경되어 날짜 표시선을 추가할 경우
                if (prevDateTime !== formattedCreatedDateTime || idx === 0) {
                  prevDateTime = formattedCreatedDateTime;
                  switch (sender) {
                    case MatchingMessageSenderType.USER:
                      return (
                        <div key={`${matchMessageStore.messagesDto?.positionName}-${rest.sn}`} ref={isLastMessage === idx ? lastMessageRef : null}>
                          <DateDividerFrame>
                            <Divider1G40 />
                            <div className='date-text'>{formattedCreatedDateTime}</div>
                          </DateDividerFrame>
                          <MatchDashBoardUserMessage
                            date={formattedCreatedDayTime}
                            title={rest.title}
                            submitApplies={rest.submitApplies}
                            openYn={matchMessageStore.messagesDto?.openYn}
                            deleteYn={matchMessageStore.messagesDto?.deleteYn}
                            command={rest.command}
                            positionSn={matchMessageStore.positionSn}
                            sendDateTime={sendDateTime}
                            message={rest.message}
                          />
                        </div>
                      );
                    // sender에 CBO, system이 존재하므로 default로 처리
                    case MatchingMessageSenderType.COMPANY:
                    default:
                      return (
                        <div key={`${matchMessageStore.messagesDto?.positionName}-${rest.sn}`} ref={isLastMessage === idx ? lastMessageRef : null}>
                          <DateDividerFrame>
                            <Divider1G40 />
                            <div className='date-text'>{formattedCreatedDateTime}</div>
                          </DateDividerFrame>
                          <MatchDashBoardCompanyMessage
                            positionSn={matchMessageStore.positionSn}
                            logoImageUrl={matchMessageStore.messagesDto?.logoImageUrl}
                            sendDateTime={formattedCreatedDayTime}
                            sendDateTimeFull={sendDateTime}
                            companyName={matchMessageStore.messagesDto?.companyName}
                            onClick={onClick}
                            loadMessage={LoadMessage}
                            openYn={matchMessageStore.messagesDto?.openYn}
                            companySn={matchMessageStore.messagesDto?.companySn}
                            sender={sender}
                            {...rest}
                          />
                        </div>
                      );
                  }
                  // 날짜가 변경되지 않은 경우
                } else {
                  prevDateTime = formattedCreatedDateTime;
                  switch (sender) {
                    case MatchingMessageSenderType.USER:
                      return (
                        <div key={`${matchMessageStore.messagesDto?.positionName}-${rest.sn}`} ref={isLastMessage === idx ? lastMessageRef : null}>
                          <MatchDashBoardUserMessage
                            date={formattedCreatedDayTime}
                            title={rest.title}
                            submitApplies={rest.submitApplies}
                            openYn={matchMessageStore.messagesDto?.openYn}
                            deleteYn={matchMessageStore.messagesDto?.deleteYn}
                            command={rest.command}
                            decision={rest.decision}
                            positionSn={matchMessageStore.positionSn}
                            sendDateTime={sendDateTime}
                            message={rest.message}
                          />
                        </div>
                      );
                    case MatchingMessageSenderType.COMPANY:
                    default:
                      return (
                        <div key={`${matchMessageStore.messagesDto?.positionName}-${rest.sn}`} ref={isLastMessage === idx ? lastMessageRef : null}>
                          <MatchDashBoardCompanyMessage
                            positionSn={matchMessageStore.positionSn}
                            logoImageUrl={matchMessageStore.messagesDto?.logoImageUrl}
                            sendDateTime={formattedCreatedDayTime}
                            sendDateTimeFull={sendDateTime}
                            companyName={matchMessageStore.messagesDto?.companyName}
                            onClick={onClick}
                            loadMessage={LoadMessage}
                            openYn={matchMessageStore.messagesDto?.openYn}
                            companySn={matchMessageStore.messagesDto?.companySn}
                            sender={sender}
                            deleteYn={matchMessageStore.messagesDto?.deleteYn || false}
                            {...rest}
                          />
                        </div>
                      );
                  }
                }
              })}
        </ContentFrame>
        <MatchDashBoardBalloonModal />
        <MatchAtsFailModal
          isOpen={atsFailModalOpen}
          onClickClose={() => setAtsFailModalOpen(false)}
        />
        <MatchScheduleFailModal
          isOpen={scheduleFailModalOpen}
          onClickClose={() => setScheduleFailModalOpen(false)}
        />
        { (matchMessageStore.messagesDto?.deleteYn)
          && (
            <JDANoticeAlert
              time={matchMessageStore.messagesDto?.deleteDateTime ? getFullTimeDot(matchMessageStore.messagesDto?.deleteDateTime) : ''}
              type='삭제'
              content='포지션이 삭제되면 추가 서류, 역량검사, 개발자 검사 결과 제출이 불가능하며 포지션 상세 페이지가 삭제됩니다.'
            />
          )}
        { (!matchMessageStore.messagesDto?.openYn && !matchMessageStore.messagesDto?.deleteYn)
          && (
            <JDANoticeAlert
              time={matchMessageStore.messagesDto?.endDateTime ? getFullTimeDot(matchMessageStore.messagesDto?.endDateTime) : ''}
              type='종료'
              content='포지션이 종료되면 추가 서류, 역량검사, 개발자 검사 결과 제출이 불가능하며 답변 유효기간이 남아 있는 면접 제안이라도 선택이 불가능합니다.'
            />
          )}
      </Frame>
    </JDFullModal>

  );
};
export default inject(injectStore.matchMessageStore, injectStore.matchReportModel)(observer(MatchDashBoardMessageModal));
