import React, { FC } from 'react';
import styled, { css } from 'styled-components';

const MarqueeFrame = styled.div<{ direction: string, speed: number, overlay?:string }>`
  overflow: hidden;
  width: 100%;
  height: 100%;
  position:relative;

  .marquee {
    display: flex;
    ${(props) => (props.direction === 'top' || props.direction === 'bottom')
      && css`
      flex-direction: column;
    `}
    overflow: hidden;
    user-select: none;
    gap: 2rem;
  }

  .overlay{
        position: absolute;
        width:100%;
        height:100%;
        opacity: 0.5;
        z-index: 1;
        ${(props) => props.overlay && css`
        background: linear-gradient(${props.overlay} 0% ,rgba(108, 221, 131, 0) 10%,rgba(108, 221, 131, 0) 90%, ${props.overlay} 100%);     
       `}
  }

  .marquee__group {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 2rem;
    width: 100%;
    height: 100%;
    flex-direction: column;

    ${(props) => props.direction === 'left'
      && css`
       animation: scrollX ${props.speed}s linear infinite;
    `}
    ${(props) => props.direction === 'right'
      && css`
       animation: scrollX ${props.speed}s linear infinite;
       animation-direction: reverse;
    `}
    ${(props) => props.direction === 'top'
      && css`
       flex-direction: column;
       animation: scrollY ${props.speed}s linear infinite;
    `}
    ${(props) => props.direction === 'bottom'
      && css`
       flex-direction: column;
       animation: scrollY ${props.speed}s linear infinite;
       animation-direction: reverse;
    `}

   
  }


  @keyframes scrollX {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-100% - 2rem));
    }
  }

  @keyframes scrollY {
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(calc(-100% - 2rem));
    }
  }

`;

interface IMarquee {
 direction?: 'left' | 'right'|'top'|'bottom';
 speed?:number;
 overlay?:string; // 백그라운드 컬러
}

const Marquee: FC<IMarquee> = ({ direction = 'right', speed = 60, overlay, children }) => (
  <MarqueeFrame direction={direction} speed={speed} overlay={overlay}>
    {overlay && <div className='overlay' /> }
    <div className='marquee'>
      <div className='marquee__group'>
        {children}
      </div>
      <div aria-hidden='true' className='marquee__group'>
        {children}
      </div>
    </div>
  </MarqueeFrame>
);

export default Marquee;
