import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import JDSelector, { JDSelectorType } from 'components/_v2/_common/input/JDSelector';
import { ConditionRegisterType } from 'consts/_v2/profile/ProfileType';
import useQueryParams from 'hooks/useQueryParams';
import { IPutConditionSalaryRq } from 'interfaces/_v2/profile/IConditionRqRs';
import IComponentProps from 'interfaces/props/IComponentProps';
import useProfileConditions, { useFetchSaveProfileConditionsSalary } from 'query/profiles/useProfileConditionsQuery';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import Context from 'models/Context';
import { FormProvider, useForm } from 'react-hook-form';
import IconSalary from 'assets/_v2/preferred/icon_moneybag_green.svg';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';

const TabFrame = styled.div<{isResumeModal?:boolean}>`
  animation: slide .5s ease-out forwards;

  @keyframes slide {
    0% {
    transform: translateY(10%);
    opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
  }

  .step-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 24px 0;
    text-align: center;
    font: ${Fonts.H4_Bold};
    color: ${Colors.JOBDA_BLACK};

    .title-icon {
      margin-bottom: 12px;
      width: 40px;
      height: 40px;
    }
  }
`;

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
  margin: auto 0;
`;

const RangeFrame = styled.div`
  width: 100%;
  height: fit-content;
`;

interface ISalaryTabProps extends IComponentProps {
  context?: Context;
  salaryData?:number;
  hidden?: boolean;
  setConditionRegisterType: (type: ConditionRegisterType) => void;
  isResumeModal?: boolean;
  setStartChecking?: (startChecking: boolean) => void;

}

export interface ChildSalaryTabHandles {
  handleSaveSalary: () => void;
}

const SalaryTab = forwardRef<ChildSalaryTabHandles, ISalaryTabProps>(({ setStartChecking, isResumeModal, salaryData, context = new Context(), hidden, setConditionRegisterType }, ref) => {
  const defaultValues:IPutConditionSalaryRq = { salary: 0, sourcePage: '' };
  const formMethods = useForm({
    mode: 'onSubmit',
    defaultValues,
  });
  const { setValue, getValues, watch, trigger, reset } = formMethods;
  const { sourcePage } = useQueryParams();
  const { refetch } = useProfileConditions(true);
  const saveSuccess = async () => {
    await refetch();
    if (setStartChecking)setStartChecking(true);
    setConditionRegisterType(ConditionRegisterType.LIST);
  };
  const { mutate: saveProfileSalaryConditions } = useFetchSaveProfileConditionsSalary(saveSuccess);

  useEffect(() => {
    const salary = Number(getValues('salary'));
    if (salary) {
      saveProfileSalaryConditions({ salary, sourcePage });
    }
    if (salary > 1000) {
      context.isPreferredClicked = true;
    } else {
      context.isPreferredClicked = false;
    }
  }, [watch('salary')]);

  useEffect(() => {
    // 초기값 설정
    reset(defaultValues);
  }, [reset]);

  useEffect(() => {
    const salary = Number(getValues('salary'));
    if (salary) {
      saveProfileSalaryConditions({ salary, sourcePage });
    }
    if (salary > 1000) {
      context.isPreferredClicked = true;
    } else {
      context.isPreferredClicked = false;
    }
  }, [watch('salary')]);

  useEffect(() => {
    // 초기값 설정
    reset(defaultValues);
  }, [reset]);

  useImperativeHandle(ref, () => ({
    async handleSaveSalary() {
      const salary = Number(getValues('salary'));
      if (salary) {
        saveProfileSalaryConditions({ salary, sourcePage });
      }
    },
  }));

  if (hidden) return null;
  return (
    <TabFrame isResumeModal={isResumeModal}>
      <FormProvider {...formMethods}>
        <div className='step-title'>
          <img className='title-icon' alt='연봉' src={IconSalary} />
          희망하는 연봉을 알려주세요.
        </div>
        <Frame>
          <RangeFrame>
            <JDSelector
              name='salary'
              selectTitle='희망하는 연봉을 선택해 주세요.'
              type={JDSelectorType.NORMAL}
              value={String(watch('salary')) || ''}
              defaultText='희망하는 연봉을 선택해 주세요.'
            >
              <select>
                <option value='' hidden>희망하는 연봉을 선택해 주세요.</option>
                <option value='1000' hidden>1,000 만원 이상</option>
                <option value='1500'>1,500 만원 이상</option>
                <option value='2000'>2,000 만원 이상</option>
                <option value='2500'>2,500 만원 이상</option>
                <option value='3000'>3,000 만원 이상</option>
                <option value='3500'>3,500 만원 이상</option>
                <option value='4000'>4,000 만원 이상</option>
                <option value='4500'>4,500 만원 이상</option>
                <option value='5000'>5,000 만원 이상</option>
                <option value='5500'>5,500 만원 이상</option>
                <option value='6000'>6,000 만원 이상</option>
                <option value='6500'>6,500 만원 이상</option>
                <option value='7000'>7,000 만원 이상</option>
                <option value='7500'>7,500 만원 이상</option>
                <option value='8000'>8,000 만원 이상</option>
                <option value='8500'>8,500 만원 이상</option>
                <option value='9000'>9,000 만원 이상</option>
                <option value='9500'>9,500 만원 이상</option>
                <option value='10000'>10,000 만원 이상</option>
              </select>
            </JDSelector>
          </RangeFrame>
        </Frame>
      </FormProvider>
    </TabFrame>
  );
});

export default inject(injectStore.context)(observer(SalaryTab));
