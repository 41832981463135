import React from 'react';
import classnames from 'classnames/bind';
import styles from './logoList.module.scss';

const cx = classnames.bind(styles);

const LogoList = () => (
  <div className={cx('logoList')}>
    <ul>
      <li className={cx('logoImg')} aria-label='logo' />
      <li className={cx('logoImg')} aria-label='logo' />
      <li className={cx('logoImg')} aria-label='logo' />
    </ul>
  </div>
);

export default LogoList;
