import JDBaseModal, { IJDBaseModal } from 'components/_v2/_common/modals/JDBaseModal';
import { JDModalBasicFrame, JDModalDescription, JDModalFullButton, JDModalTipBlue, JDModalTitle } from 'components/_v2/_common/modals/JDModalComponents';
import SupportMail from 'consts/_v2/GlobalText';
import React, { FC } from 'react';
import styled from 'styled-components';

const Frame = styled.div`

    ${JDModalDescription} {
        margin-top: 8px;
        margin-bottom: 12px;
    }
`;
const JDApplyRestrictedModal:FC<IJDBaseModal> = (({ isOpen, onClickClose }) => (
  <JDBaseModal dimmed isOpen={isOpen} onClickClose={onClickClose} isDimClickClose>
    <Frame>
      <JDModalBasicFrame>
        <JDModalTitle>지원이 제한된 포지션입니다.</JDModalTitle>
        <JDModalDescription>기업의 인사담당자님께 문의해 주세요.</JDModalDescription>
        <JDModalFullButton onClick={onClickClose}>확인</JDModalFullButton>
      </JDModalBasicFrame>
    </Frame>
  </JDBaseModal>
));
export default JDApplyRestrictedModal;
