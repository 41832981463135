import { INameCodeRs } from './ICommonRqRs';

export enum SkillLevel {
  BASIC = 'BASIC',
  BEGINNER = 'BEGINNER',
  MIDDLE = 'MIDDLE',
  ADVANCED = 'ADVANCED',
  PROFESSIONAL = 'PROFESSIONAL',
}

export enum SkillLevelText {
  BASIC = '기초',
  BEGINNER = '초급',
  MIDDLE = '중급',
  ADVANCED = '고급',
  PROFESSIONAL = '전문가',
}

export const SkillLevelDescription:Record<SkillLevel, string> = {
  BASIC: '대학교에서 수업을 통해 지식을 학습한 수준',
  BEGINNER: '아르바이트/인턴을 통해 업무에 활용한 수준',
  MIDDLE: '1년 미만 업무에 적용한 경험이 있거나, 실무에 활용 가능한 수준',
  ADVANCED: '1~3년 동안 업무에 적용한 경험이 있거나, 큰 도움 없이 활용 가능한 수준',
  PROFESSIONAL: '3년 이상 업무에 적용한 경험이 있거나, 자유자재로 다룰 수 있는 수준',
};

export interface IProfileExaminationDto extends INameCodeRs {
  grade: string; // "superior"
  gradeYn: boolean;
  score: number;
  scoreYn: boolean;
  gradeCode: number;
}

export interface IProfileLicenseDto extends INameCodeRs {}

export interface IProfilePortfolioAttachFileDto {
  fileName: string;
  fileUid: string;
}

export interface IProfileSkillDto {
  level: SkillLevel;
  code: number;
  keyword:string;
}

export default interface IProfileKnowledgeAndSkillsRs {
  examinations: IProfileExaminationDto[];
  licenses: IProfileLicenseDto[];
  portfolioAttachFiles: IProfilePortfolioAttachFileDto[];
  referenceUrl: string;
  skills: IProfileSkillDto[];
};
