import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import PassboardCard from 'components/_v2/passboard/PassboardCard';
import Paging from 'components/common/Paging';
import { Divider1G40 } from 'components/divider/Divider';
import { IconClose20 } from 'consts/assets/icons/iconPages';
import shadows from 'consts/style/shadows';
import { PassReviewListDto, PassReviewPostListGetRq, PassReviewPostListGetRs } from 'interfaces/_v2/passReview/IPassReviewRqRs';
import { usePassReviewPosts } from 'query/passReview/usePassReveiwQuery';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import ScrollUtil from 'utils/ScrollUtil';

const TileFrame = styled.div`
  margin-bottom: 12px;
  padding: 0 16px;
  font: ${Fonts.H5_Bold};
  color:${colors.JOBDA_BLACK};
`;
interface IPassboardList{

}

interface IPassboardListTab{
    tabId:number;
    tabName:string;
    tab:string;
}

const ListTab = styled.div`
    padding: 20px 16px 0px 16px ;
    z-index: 100;
    display: flex;
    align-items: center;
    width: 100%;
    background: ${colors.WHITE_100};
    ${shadows.headers};

    .a-link {
        position:relative;
        width:fit-content;
        font: ${Fonts.B2_Medium};
        color: ${colors.CG_60};
        margin-right: 24px;
        padding-bottom: 12px;
        &[aria-current=true] {
            font: ${Fonts.B2_Bold};
            color:${colors.JOBDA_BLACK}
        }

        &[aria-current=true]:before {
          display:block;
          position:absolute;
          content:'';
          bottom: 0;
          left:0;
          width:100%;
          height:2px;
          background:${colors.JOBDA_BLACK}
        }
    }
`;
const PaddingFrame = styled.div`
  padding:35px 16px 96px 16px;
`;
const SearchFrame = styled.div`
  display: flex;
  border-radius: 4px;
  background-color: ${colors.CG_30};
  align-items: center;
  padding:12px;
  justify-content: space-between;
  .close-btn {
      display: none;
      margin-right: 0;
    }
    .close-btn.active {
      display: block;
    }
    .search-field{
      display: flex;
      .search-icon{
    margin-right:6px;
      object-fit: none;
  }
  .input-field{
    padding:0;
    margin-left: 8px;
    background: ${colors.CG_30};
    border:none;
        outline: none;
        font: ${Fonts.B2_Medium};

  }
    }
 
`;
const DividerFrame = styled.div`
    padding: 0 16px;
`;
const PassboardList: FC<IPassboardList> = () => {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(0); // 페이지 번호는 0부터 시작한다고 가정
  const [pageSize, setPageSize] = useState(10); // 페이지당 항목 수
  const [totalPages, setTotalPages] = useState(0);
  const [keyword, setKeyword] = useState(''); // 검색 키워드
  const [inputValue, setInputValue] = useState<string>('');
  const [rqData, setRqData] = useState<PassReviewPostListGetRq>({
    page: 0,
    size: 10,
    keyword,
  });
  const { data: passboardList, isLoading, isError, error } = usePassReviewPosts(rqData);
  useEffect(() => {
    if (passboardList) {
      setTotalPages(passboardList.pages[0].pages.totalPages);
    }
  }, [passboardList]);
  const passboardListTab:IPassboardListTab[] = [{ tabId: 0, tabName: '전체', tab: 'total' }, { tabId: 1, tabName: '공지', tab: 'notice' }, { tabId: 2, tabName: 'BEST', tab: 'best' }];
  // 페이지 변경 핸들러
  const handleChangePage = (newPage:number) => {
    setCurrentPage(newPage);
    setRqData({
      ...rqData,
      page: newPage,
      size: pageSize,

    });
  };

  // 페이지당 항목 수 변경 핸들러
  const handleChangeSize = (newSize:number) => {
    setPageSize(newSize);
    setCurrentPage(0); // 페이지 사이즈 변경 시 첫 페이지로 이동
  };

  // 검색 핸들러
  const handleSearch = () => {
    setRqData({
      ...rqData,
      keyword: inputValue,
    });
  };
  const handleTabClick = (idx: number) => {
    setCurrentTab(idx);
  };
  const moveToPage = (page: number) => {
    handleChangePage(page);
    // ScrollUtil.scrollTo(0, 400);
  };
  const handleEsc = () => {
    setInputValue('');
    setRqData({
      ...rqData,
      keyword: '',
    });
  };
  return (
    <>
      <div className='section'>
        <TileFrame>
          합격 후기 게시판
        </TileFrame>

        {/* <ListTab>
          {passboardListTab.map((tab, idx) => (
            <div className='a-link' key={tab.tabId} aria-current={currentTab === idx} onClick={() => handleTabClick(idx)} role='button'>{tab.tabName}</div>
          ))}
        </ListTab> */}
        {
        passboardList && passboardList.pages[0].posts.map((passboard:PassReviewListDto, idx) => (
          <>
            <PassboardCard info={passboard} />
            {idx !== passboardList.pages[0].posts.length - 1 && <DividerFrame><Divider1G40 /></DividerFrame>}
          </>
        ))
      }
        <SpacingBlock vertical size={24} />

        <Paging
          onChangePage={moveToPage}
          selectedPage={currentPage}
          totalPageCount={totalPages}
          passboard
        />
        <PaddingFrame>
          <SearchFrame>
            <div className='search-field'>
              <Icon name='search' size={24} color={colors.CG_70} className='search-icon' />
              <input
                type='text'
                className='input-field'
                placeholder='제목을 검색해 주세요.'
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch && handleSearch();
                  }
                }}
              />
            </div>
            <IconClose20
              className={`close-btn ${inputValue.length > 0 ? 'active' : ''}`}
              onClick={handleEsc}
            />
          </SearchFrame>
        </PaddingFrame>
      </div>

    </>
  );
};

export default PassboardList;
