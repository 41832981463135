import ImgDumy from 'assets/_v2/positionList/img_dumy.png';
import PositionTagList from 'components/_v2/positionJd/PositionTagList';
import { IconBookmark24 } from 'consts/assets/icons/iconPages';
import RoutePaths from 'consts/RoutePaths';
import { lineClampBox } from 'consts/style/mixins';
import Fonts from '__designkit__/common/fonts';
import { IPositionListItem } from 'interfaces/_v2/positionList/IPositionList';
import Login from 'models/Login';
import PositionListModel from 'models/_v2/PositionListModel';
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import { lineClampOnlyOne } from 'consts/_v2/_common/style/mixins';
import Colors from '__designkit__/common/colors';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import { IAccCenterPosition } from 'interfaces/_v2/acc/IAccGate';
import Context from 'models/Context';

const PositionCardFrame = styled.div<{ darkMode?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  background: ${(props) => (props.darkMode ? `${Colors.JOBDA_BLACK}` : `${Colors.WHITE_100}`)};
  .card-image-frame {
    width: 100%;
    position: relative;
    margin-bottom: 12px;
    overflow: hidden;
    border-radius: 6px;
    border: 1px solid ${Colors.c121619_10};
    aspect-ratio: 158/118;
    .match {
      position: absolute;
      top: 8px;
      left: 8px;
      padding: 4px 6px;
      background-color: ${Colors.G_100};
      color: ${Colors.WHITE_100};
      border-radius: 4px;
      border: 1px solid ${Colors.c000000_06};
      font: ${Fonts.B4_Medium};
      line-height: 12px;
    }

    .bookmark {
      position: absolute;
      top: 8px;
      right: 8px;
    }
    .rank {
      position: absolute;
      bottom: 8px;
      left: 8px;
      > div {
        position: relative;
        span {
          position: absolute;
          width: 100%;
          top: 10px;
          left: 0px;
          text-align: center;
          font: ${Fonts.B4_Bold};
          color: ${Colors.JOBDA_BLACK};
        }
      }
    }
    .card {
      width: 100%;
      aspect-ratio: 158/118;
      object-fit: cover;
      border-radius: 6px;
    }
  }

  h1 {
    width: 100%;
    height: fit-content;
    font: ${Fonts.B2_Bold};
    color: ${(props) => (props.darkMode ? `${Colors.JOBDA_WHITE}` : `${Colors.JOBDA_BLACK}`)};
    margin-bottom: 8px;
    ${lineClampBox(2)};
  }

  h2 {
    width: 100%;
    font: ${Fonts.B3_Medium};
    color: ${(props) => (props.darkMode ? `${Colors.JOBDA_WHITE}` : `${Colors.JOBDA_BLACK}`)};
    margin-bottom: 8px;
    ${lineClampBox(2)};
  }
`;

interface IPositionCard {
  className?: string;
  item: IPositionListItem;
  positionListModel?: PositionListModel;
  login?: Login;
  darkMode?: boolean;
  context?: Context;
}

const PositionCard: FC<IPositionCard> = ({
  className,
  darkMode,
  item,
  positionListModel = new PositionListModel(),
  login = new Login(),
  context = new Context(),
}) => {
  const [bookmark, setBookmark] = useState<boolean>(false);
  const history = useHistory();
  const { pathname, search } = useLocation();

  const instanceOfIAccCenterPosition = (
    object: any,
  ): object is IAccCenterPosition => {
    if (object === undefined) return false;
    return 'sn' in object;
  };

  const setPositionBookMark = async () => {
    try {
      if (!login.userInfo?.id) {
        context.setRedirectUrl(pathname + search);
        history.push(RoutePaths.login);
        return;
      }
      if (instanceOfIAccCenterPosition(item)) {
        await positionListModel.setMatchPositionBookMark(bookmark, item.sn);
      } else {
        await positionListModel.setMatchPositionBookMark(
          bookmark,
          item.positionSn,
        );
      }
      setBookmark(!bookmark);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (item.bookmarkYn === undefined) {
      setBookmark(true);
    } else {
      setBookmark(item.bookmarkYn);
    }
  }, [item.bookmarkYn]);

  return (
    <PositionCardFrame
      className={className}
      darkMode={darkMode}
      onClick={(e) => {
        history.push(RoutePaths.position_jd(item.positionSn));
        e.stopPropagation();
      }}
    >
      <div className='card-image-frame'>
        {item.matchingYn && <span className='match'>매칭받음</span>}
        <IconBookmark24
          active={bookmark}
          className='bookmark'
          onClick={(e) => {
            setPositionBookMark();
            e.stopPropagation();
            e.preventDefault();
          }}
        />
        {/* Rank 제거 (운영안하기에 제거합니다. 참고 JDA-12020) */}
        <img
          className='card'
          alt='card'
          src={`${item.teamImageUrl}?w=1040&h=1170` || ImgDumy}
          onError={(e) => {
            e.currentTarget.src = ImgDumy;
          }}
        />
      </div>
      <h1 title={item.positionName}>{item.positionName}</h1>
      <h2 title={item.positionName}>{item.companyName}</h2>
      <PositionTagList
        recruitmentType={item.recruitmentType}
        jobTitleNames={item.jobTitleNames}
        closingDateTime={item.closingDateTime}
        minSalary={item.minSalary}
        maxSalary={item.maxSalary}
        locationCode={item.locationCode}
        companyCase
      />
    </PositionCardFrame>
  );
};
export default inject(
  injectStore.positionListModel,
  injectStore.login,
  injectStore.context,
)(observer(PositionCard));
