import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import ChattingAPI from 'api/chattingAPI';
import ImgWayOutGreen from 'assets/_v2/company/icon_move_out_green.png';
import ImgWayOut from 'assets/_v2/company/icon_move_out_white.png';
import ImgQuotation from 'assets/_v2/company/icon_quotation_black.png';
import RoutePaths from 'consts/RoutePaths';
import { ScrollHiddenMixin } from 'consts/_v2/_common/style/mixins';
import { BusinessSizeTypeText } from 'consts/_v2/positionJd/BusinessSize';
import { inject, observer } from 'mobx-react';
import CompanyModel from 'models/_v2/CompanyModel';
import MatchReportModel from 'models/_v2/MatchReportModel';
import { injectStore } from 'models/store';
import { CompanyTabRoute } from 'pages/_v2/company/CompanyDetailTab';
import React, { FC } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { formatNumber, krNumberFormat } from 'utils/NumberFormatUtil';

const Frame = styled.div`
    
`;

const InfoFrame = styled.div`
    /* width:100%; */
    overflow-x: scroll;    
    ${ScrollHiddenMixin()}
    padding: 0 24px;
.inner-frame{
    border:1px solid ${Colors.CG_50};
    border-radius: 8px;
    display: inline-flex;
    padding:32px;
    align-items: center;
    height: 110px;
    position: relative;
    width: max-content;
    .box{
        display: flex;
        flex-direction: column;
        width: auto;
        justify-content: center;
        align-items: center;
        flex: none;
        .box-title{
            font:${Fonts.B3_Medium};
            color:${Colors.CG_70};
            margin-bottom: 8px;
    }
        .box-detail{
            font:${Fonts.B1_Bold};
            color:${Colors.JOBDA_BLACK};
        }
    }
    .small-divider{
        width: 1px;
        height: 46px;
        background: ${Colors.CG_40};
        margin: 0 32px;
        flex: none;
    }
  
}

  
`;
const VisionFrame = styled.div`
    padding:0 24px 16px 24px;
    .vision-title{
        font:${Fonts.H4_Bold};
        color:${Colors.JOBDA_BLACK};
    }
    .vision-detail{
        font:${Fonts.B2_Medium_P};
        color:${Colors.CG_70};
        word-break: keep-all;
    }
    .icon{
        width: 20px;
        height: 12px;
    }
`;
const Title = styled.div`
    padding:40px 24px 0 24px;
    font:${Fonts.H5_Bold};
    color:${Colors.JOBDA_BLACK};
`;
const IdealTalentFrame = styled.div`
    padding: 24px 24px 0 24px;
    .ideal-title{
        font:${Fonts.H5_Bold};
        color:${Colors.JOBDA_BLACK};
    }
    .row-list{
        display: flex;
        .ranking{
            display: flex;
            width: 20px;
            height: 20px;
            background-color: ${Colors.JOBDA_BLACK};
            border-radius: 12px;
            color:${Colors.WHITE_100};
            align-items: center;
            justify-content: center;
            font:${Fonts.B4_Medium};
        }
        .rank-text{
            font:${Fonts.B3_Bold_P};
            color:${Colors.JOBDA_BLACK};
            margin-left: 12px;
        }
    }
`;

const BannerFrame = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    width: 100%;
    border-radius: 8px;
    background: linear-gradient(90deg, #4BD667 0%, #40CCC6 100%);
    margin-bottom: 8px;
    .banner-text{
        font:${Fonts.B2_Bold_P};
        color:${Colors.WHITE_100};
    }
    .icon{
        width:32px;
        height: 32px;
    }
 `;
const ColorBannerFrame = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    width: 100%;
    border-radius: 8px;
    background: ${Colors.WHITE_100};
    margin-bottom: 40px;
    border: 1px solid #41CDC3;
    .banner-text{
        font:${Fonts.B2_Bold_P};
        color:#41CDC3;
    }
    .icon{
        width:32px;
        height: 32px;
 }
`;
interface ICompanyReportInformation{
  companyModel? :CompanyModel
  matchReportModel?:MatchReportModel
  setCurrentTab?: (tab: number) => void
}
const CompanyReportInformation: FC<ICompanyReportInformation> = ({ companyModel = new CompanyModel(), matchReportModel = new MatchReportModel(), setCurrentTab }) => {
  const FormatNumberLimit: number = 100000000;
  const history = useHistory();

  const loadCompanyInfo = async () => {
    await companyModel.loadCompanyPositions();
  };
  const handleChatting = async () => {
    try {
      const res = await Promise.all([loadCompanyInfo(), ChattingAPI.getChannelIdByCompanySn(companyModel.companySn)]);
      history.push(`${RoutePaths.chatChannel}?channelId=${res[1].channelId}`);
    } catch (e) {
      console.error(e);
    }
  };
  const handleCompany = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has('tab')) {
      queryParams.set('tab', CompanyTabRoute.info);
    } else {
      queryParams.append('tab', CompanyTabRoute.info);
    }

    const updatedURL = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.replaceState({}, document.title, updatedURL);
    if (setCurrentTab) {
      setCurrentTab(1);
    }
  };

  return (
    <Frame>
      <Title>{companyModel.companyHeader?.companyHeaderDto.name}의 정보를<br />간략하게 알려드릴게요.</Title>
      <SpacingBlock size={32} vertical />
      <InfoFrame>
        {
          companyModel.companyInformation
          && (
            <div className='inner-frame'>

              <div className='box'>
                <div className='box-title'>기업 규모</div>
                <div className='box-detail'>{companyModel.companyInformation.companyDetailDto.businessSize ? BusinessSizeTypeText[companyModel.companyInformation.companyDetailDto.businessSize] : '-' }</div>
              </div>
              <div className='small-divider' />
              <div className='box'>
                <div className='box-title'>산업</div>
                <div className='box-detail'>{companyModel.companyInformation.companyDetailDto.industryName ? companyModel.companyInformation.companyDetailDto.industryName : '-'}</div>
              </div>
              <div className='small-divider' />
              <div className='box'>
                <div className='box-title'>2022 매출액</div>
                <div className='box-detail'>{companyModel.companyInformation.companyDetailDto.revenue
                  ? (Math.abs(companyModel.companyInformation.companyDetailDto.revenue) > FormatNumberLimit ? formatNumber(Math.floor(companyModel.companyInformation.companyDetailDto.revenue / FormatNumberLimit) * FormatNumberLimit) : formatNumber(companyModel.companyInformation.companyDetailDto.revenue))
                  : '-'}
                </div>
              </div>
              <div className='small-divider' />
              <div className='box'>
                <div className='box-title'>직원 수</div>
                <div className='box-detail'>{companyModel.companyInformation.companyDetailDto.numberOfEmployees ? `${krNumberFormat(companyModel.companyInformation.companyDetailDto.numberOfEmployees)}명` : '-'}</div>
              </div>
              <div className='small-divider' />
              <div className='box'>
                <div className='box-title'>평균 근속</div>
                <div className='box-detail'>{companyModel.companyInformation.companyDetailDto.averageWorkingYears ? `${companyModel.companyInformation.companyDetailDto.averageWorkingYears}년` : '-'}</div>
              </div>
            </div>

          )
        }

      </InfoFrame>
      {matchReportModel.matchReportCompanyInformation && matchReportModel.matchReportCompanyInformation.vision && matchReportModel.matchReportCompanyInformation.vision.visionTitle && matchReportModel.matchReportCompanyInformation.vision.visionTitle.trim() !== '' && (
      <VisionFrame>
        <SpacingBlock size={40} vertical />
        <img src={ImgQuotation} alt='quotation' className='icon' />
        <SpacingBlock size={24} vertical />
        <div className='vision-title'>{matchReportModel.matchReportCompanyInformation?.vision.visionTitle}</div>
        <SpacingBlock size={24} vertical />
        <div className='vision-detail'>{matchReportModel.matchReportCompanyInformation?.vision.visionDetails}</div>
      </VisionFrame>
      )}

      {
  matchReportModel.matchReportCompanyInformation && matchReportModel.matchReportCompanyInformation.idealTalents && matchReportModel.matchReportCompanyInformation.idealTalents[0].text && matchReportModel.matchReportCompanyInformation.idealTalents[0].text.trim() !== '' && (
    <IdealTalentFrame>
      <div className='ideal-title'>인재상</div>
      <SpacingBlock size={24} vertical />
      {
      matchReportModel.matchReportCompanyInformation.idealTalents.map((item, index) => (
        <>
          {
          item.text && (
            <>
              <div className='row-list'>
                <div className='ranking'>{index + 1}</div>
                <div className='rank-text'>{item.text}</div>
              </div>
              <SpacingBlock size={16} vertical />
            </>
          )
        }

        </>
      ))
    }
    </IdealTalentFrame>
  )
}
      {
  matchReportModel.matchReportCompanyInformation && matchReportModel.matchReportCompanyInformation.managementPhilosophies && matchReportModel.matchReportCompanyInformation.managementPhilosophies[0].text && matchReportModel.matchReportCompanyInformation.managementPhilosophies[0].text.trim() !== '' && (
    <IdealTalentFrame>
      <div className='ideal-title'>경영이념</div>
      <SpacingBlock size={24} vertical />
      {
     matchReportModel.matchReportCompanyInformation.managementPhilosophies.map((item, index) => (
       <>
         {
        item.text && (
          <>
            <div className='row-list'>
              <div className='ranking'>{index + 1}</div>
              <div className='rank-text'>{item.text}</div>
            </div>
            <SpacingBlock size={16} vertical />
          </>
        )
       }

       </>
     ))
    }
    </IdealTalentFrame>
  )
}
      <SpacingBlock size={16} vertical />

      <IdealTalentFrame>
        <BannerFrame onClick={handleCompany}>
          <div className='banner-text'>기업정보 바로가기</div>
          <SpacingBlock size={16} horizontal />
          <img src={ImgWayOut} alt='way out' className='icon' />
        </BannerFrame>
        <ColorBannerFrame onClick={handleChatting}>
          <div className='banner-text'>기업 오픈 채팅방 바로가기</div>
          <SpacingBlock size={16} horizontal />
          <img src={ImgWayOutGreen} alt='way out' className='icon' />
        </ColorBannerFrame>
      </IdealTalentFrame>
    </Frame>
  );
};
export default inject(injectStore.matchReportModel, injectStore.login, injectStore.companyModel)(observer(CompanyReportInformation));
