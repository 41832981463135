import Colors from '__designkit__/common/colors';
import TextField from '__designkit__/components/TextField';
import Icon from '__designkit__/icon/Icon';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

const Frame = styled.div`
  position: relative;
  width: 100%;
`;

interface INameFieldProps extends IComponentProps {
  name: string; // 필수값, react-hook-form에서 사용하는 name 값
  defaultValue?: string; // 선택값, 지정시 초기값으로 설정
  disabled?: boolean; // 선택값, 지정시 수정 불가
}

const NameField: FC<INameFieldProps> = ({ name, defaultValue, disabled }) => {
  const [nameValue, setNameValue] = useState<string | undefined>();
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  const { setValue, formState, clearErrors } = useFormContext();
  const { field } = useController({ name });

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        setIsFocus(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [ref]);

  useEffect(() => {
    if (defaultValue) setNameValue(defaultValue);
  }, [defaultValue]);

  return (
    <Frame ref={ref}>
      <TextField
        {...field}
        rightChildren={isFocus && nameValue && <Icon name='close' size={24} color={Colors.JOBDA_BLACK} onClick={() => { setNameValue(''); setValue(name, ''); }} />}
        errorMessage={formState.errors[name]?.message}
        value={nameValue}
        placeholder='이름을 입력해 주세요.'
        onChange={(e) => { setNameValue(e.target.value); setValue(name, e.target.value); }}
        onFocus={() => { setIsFocus(true); clearErrors(name); }}
        disabled={disabled}
        autoComplete='off'
      />
    </Frame>
  );
};

export default NameField;
