// created by khj1115 on 2021-07-26

import ContentBigCard from '__designkit__/card/ContentBigCard';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import shadowCharacter from 'assets/character/character_info_list_banner.png';
import { InfoListMetaTag } from 'components/_v2/_common/meta/MetaTag';
import LazyImage from 'components/common/LazyImage';
import CategorySlideTab from 'components/info/CategorySlideTab';
import IContextPageProps from 'interfaces/props/IContextPageProps';
import { inject, observer } from 'mobx-react';
import Contents from 'models/_v2/Contents';
import { injectStore } from 'models/store';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components/macro';
import CommonUtils from 'utils/CommonUtils';
import ScrollUtil from 'utils/ScrollUtil';
import ImgBanner from 'assets/_v2/info/img_contents_banner.png';
import PassboardTab from 'components/_v2/passboard/PassboardTab';
import Paging from '../components/common/Paging';
import Context from '../models/Context';

const HEADER_HEIGHT = 56;

const Frame = styled.div`
  height: 100%;
  
  .img-banner {
   border-radius: 8px;
  }
`;

const StyledHeader = styled.div`
  padding: 12px 16px;
`;
const InfoCardList = styled.section`
  margin-bottom: 40px;
  padding: 0 16px;

  .info-card-wrap {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const CategorySlideTabFrame = styled(CategorySlideTab)<{
  isVisibleMainHeader: boolean;
}>`
  position: sticky;
  transition: top 0.25s;
  top: ${({ isVisibleMainHeader }) => `${isVisibleMainHeader ? HEADER_HEIGHT : 0}px`};
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
  // transition 작동시 상단탭에 가려지는 현상 수정
  z-index: 2;
`;

interface IInfoListPageProps extends IContextPageProps {
  contents?: Contents;
}

const InfoListPage: React.FC<IInfoListPageProps> = ({ context = new Context(), contents = new Contents() }) => {
  const { search } = useLocation();
  const { category: categorySn } = CommonUtils.parseQuery(search);
  const moveToPage = (page: number) => {
    contents.setPage(page);
    ScrollUtil.scrollTo(0, 400);
  };

  useEffect(() => {
    (async () => {
      await contents.getCategoryList('ALL', categorySn !== '' ? Number(categorySn) : undefined);
      if (categorySn) {
        contents.selectedCategory = Number(categorySn);
      } else contents.selectedCategory = -1;
    })();
  }, []);

  return (
    <Frame>
      <InfoListMetaTag />
      <PassboardTab />
      <StyledHeader>
        <img src={ImgBanner} alt='img' className='img-banner' />
      </StyledHeader>
      <CategorySlideTabFrame
        categories={contents.categories}
        selectedCategoryId={contents.selectedCategory}
        isVisibleMainHeader={!context.hideHeader}
      />
      <InfoCardList>
        {contents.contents.map((card) => (
          <ContentBigCard key={card.id} item={card} />
        ))}
      </InfoCardList>
      <Paging
        onChangePage={moveToPage}
        selectedPage={contents.page}
        totalPageCount={contents.totalPages}
      />
    </Frame>
  );
};

export default inject(
  injectStore.context,
  injectStore.contents,
)(observer(InfoListPage));
