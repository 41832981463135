import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { ADMIN_META_DATA_TYPE } from 'consts/_v2/chatting/chatting';
import { AdminMetaData } from 'interfaces/_v2/chatting/IChattingRqRs';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Message } from 'talkplus-sdk';
import { v4 as uuidv4 } from 'uuid';

const Frame = styled.div`
  white-space: pre-wrap;
  font: ${Fonts.B3_Medium};

  div#title {
    font: ${Fonts.B3_Bold};
  }

  ul#info {
    li {
      margin-bottom: 4px;
    }
    a {
      color: ${Colors.B_80};
      text-decoration: underline;
    }
  }

  & p > span {
    font: ${Fonts.B3_Bold};
  }
`;

interface IAdminMessageTemplateProps extends IComponentProps {
  metaData: AdminMetaData;
  item: Message;
}

const AdminMessageTemplate: FC<IAdminMessageTemplateProps> = ({ metaData, item }) => {
  const convertTime = (closingDateTime: string) => {
    const [date, time] = closingDateTime.split('T');
    const [year, month, day] = date.split('-');
    const [hour, minute] = time.split(':');
    return `${year}년 ${month}월 ${day}일 ${hour}시 ${minute}분`;
  };

  switch (metaData.type) {
    case ADMIN_META_DATA_TYPE.REMIND_JOB_POSTING_DEADLINE_D_DAY:
      return (
        <Frame>
          <div id='title'>[D-Day] 입사지원 마감 안내 ⏰</div>
          <br />
          <p>곧 마감되는 <span>{ metaData.companyName }</span> 채용공고가 있어요. 서둘러 지원을 마무리해 주세요!</p>
          <br />
          <ul id='info'>
            <li>◼︎ { metaData.jobPostingName }</li>
            <li>◼︎ <a href={metaData.recruitmentUrl} target='_blank' rel='noreferrer'>공고 자세히 보기</a></li>
            <li>◼︎ 마감일시 : { convertTime(metaData.closingDateTime) }</li>
          </ul>
        </Frame>
      );
    case ADMIN_META_DATA_TYPE.REMIND_JOB_POSTING_DEADLINE_D_3:
      return (
        <Frame>
          <div id='title'>[D-3] 입사지원 마감 안내 ⏰</div>
          <br />
          <p>곧 마감되는 <span>{ metaData.companyName }</span> 채용공고가 있어요. 서둘러 지원을 마무리해 주세요!</p>
          <br />
          <ul id='info'>
            <li>◼︎ { metaData.jobPostingName }</li>
            <li>◼︎ <a href={metaData.recruitmentUrl} target='_blank' rel='noreferrer'>공고 자세히 보기</a></li>
            <li>◼︎ 마감일시 : { convertTime(metaData.closingDateTime) }</li>
          </ul>
        </Frame>
      );
    case ADMIN_META_DATA_TYPE.REMIND_JOB_POSTING_DEADLINE:
      return (
        <Frame>
          <div id='title'>[D-3] 입사지원 마감 안내 ⏰</div>
          <p>곧 마감되는 <span>{ metaData.customData ? metaData.customData.length > 0 ? metaData.customData[0].companyName : '' : '' }</span> 채용공고가 있어요. 서둘러 지원을 마무리해 주세요!</p>
          <br />
          <ul id='info'>
            { metaData.customData?.map((jobPosting: {jobPostingName: string; recruitmentUrl: string }) => (
              <div key={uuidv4()}>
                <li>◼︎ { jobPosting.jobPostingName }</li>
                { jobPosting.recruitmentUrl && <li>    <a href={jobPosting.recruitmentUrl} target='_blank' rel='noreferrer'>자세히 보기</a></li> }
              </div>
            ))}
          </ul>
        </Frame>
      );
    case ADMIN_META_DATA_TYPE.REMIND_JOB_POSTING_REGISTERED:
      return (
        <Frame>
          <div id='title'>[New] 공고 등록 안내 🔔</div>
          <p>오늘 올라온 <span>{ metaData.customData ? metaData.customData.length > 0 ? metaData.customData[0].companyName : '' : '' }</span> 채용공고가 있어요. 이 기회를 놓치지 마세요!</p>
          <br />
          <ul id='info'>
            { metaData.customData?.map((jobPosting: {jobPostingName: string; recruitmentUrl: string }) => (
              <div key={uuidv4()}>
                <li>◼︎ { jobPosting.jobPostingName }</li>
                { jobPosting.recruitmentUrl && <li>    <a href={jobPosting.recruitmentUrl} target='_blank' rel='noreferrer'>자세히 보기</a></li> }
              </div>
            ))}
          </ul>
        </Frame>
      );
    default:
      return (
        <Frame>
          { item.text }
        </Frame>
      );
  }
};

export default AdminMessageTemplate;
