import React, { FC, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import IComponentProps from 'interfaces/props/IComponentProps';
import TextUtil from 'utils/TextUtil';

interface ICountUpAniProps extends IComponentProps {
  number: number;
  font: string;
  color: string;
}

const Frame = styled.div<{ font: string, color: string }>`
  display: inline-flex;
  align-items: start;
  width: fit-content;
  height: 12px;
  font: ${({ font }) => font};
  color: ${({ color }) => color};
  overflow: hidden;

  & > .window {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    height: fit-content;
  }
`;

const CountUpAni: FC<ICountUpAniProps> = ({ number = 0, font, color }) => {
  const duration = useRef<number>(0);

  return !number ? null : (
    <Frame font={font} color={color}>
      { TextUtil.numberToCommaNumber(number).split('').map((ele) => {
        if (ele !== ',') duration.current += 1;
        // eslint-disable-next-line react/no-array-index-key
        return <CountItem key={Math.random()} item={ele} duration={duration.current} total={String(number).split('').length} />;
      })}
    </Frame>
  );
};

export default CountUpAni;

const ani = keyframes`
  0% {
    transform: translateY(-120px);
  }
  100% {
    transform: translateY(0px);
  }
`;

const NumberItem = styled.div<{ maxDuration: number, duration: number, total: number }>`
  animation: ${ani} ${({ maxDuration, duration, total }) => maxDuration - ((maxDuration / total) * (total - duration))}s ease-in-out;
`;

const CountItem: FC<{ item: string, duration: number, total: number }> = ({ item = 0, duration = 1, total = 1 }) => {
  const ref = useRef<HTMLDivElement>(null);

  return (Number.isNaN(Number(item))
    ? (
      <div>
        <div className='number'>,</div>
      </div>
    )
    : (
      <div>
        <NumberItem ref={ref} maxDuration={Math.ceil(total / 3)} duration={duration} total={total}>
          { Array.from({ length: 10 }, (_, i) => i).map((_, index) => <div key={Math.random()} className='number'>{ (Number(item) + index) % 10}</div>) }
        </NumberItem>
      </div>
    ));
};
