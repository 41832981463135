import ContentBigCard from '__designkit__/card/ContentBigCard';
import eventBanner from 'assets/img/event/MO_profile_event_banner.png';
import eventBanner2 from 'assets/img/event/MO_profile_event_banner2.png';
import JDALink from 'components/JDALink';
import JDSwiper, { JDFractions, JDSwiperSlide } from 'components/_v2/_common/JDSwiper';
import { InfoListMetaTag } from 'components/_v2/_common/meta/MetaTag';
import { MainAdCardFrame, MainAdFrame } from 'components/_v2/main/MainAd';
import Paging from 'components/common/Paging';
import IContextPageProps from 'interfaces/props/IContextPageProps';
import { inject, observer } from 'mobx-react';
import Contents from 'models/_v2/Contents';
import { injectStore } from 'models/store';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import ScrollUtil from 'utils/ScrollUtil';
import { v4 as uuidv4 } from 'uuid';

const Frame = styled.div`
  height: 100%;
  
  .img-banner {
    width: 312px;
    height: 240px;
  }
`;

const InfoCardList = styled.section`
  padding: 16px 20px 0 20px;

  .info-card-wrap {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

interface IEventPageProps extends IContextPageProps {
  contents?: Contents;
}

const EventPage: React.FC<IEventPageProps> = ({ contents = new Contents() }) => {
  const [eventList] = useState([
    {
      id: uuidv4(),
      image: eventBanner2,
      link: '/event/passboard/29',
      startAt: '2024-06-01 00:00:00',
      endAt: '2024-12-31 00:00:00',
    },
  ]);

  const availableEventList = useMemo(() => eventList.filter((event) => new Date(event.startAt) <= new Date() && new Date(event.endAt) > new Date()), [eventList]);

  const moveToPage = (page: number) => {
    contents.setPage(page);
    ScrollUtil.scrollTo(0, 400);
  };

  useEffect(() => {
    (async () => {
      if (contents.eventCategory === null) await contents.getEventCategory();
      if (contents.eventCategory !== null) {
        await contents.getContentList(contents.eventCategory);
        contents.setSelectedCategory(contents.eventCategory);
      }
    })();

    return () => {
      contents.setSelectedCategory(-1);
    };
  }, [contents]);

  return (
    <Frame>
      <InfoListMetaTag />
      {/* { availableEventList.length > 0 && (
        <MainAdFrame>
          <JDSwiper
            slidesPerView={1}
            autoplay={{ delay: 3000 }}
            pagination={JDFractions}
            loop
            main
          >
            { availableEventList.map((event, index) => (
              <JDSwiperSlide className='main-custom-slide' key={event.id}>
                <JDALink to={event.link}>
                  <MainAdCardFrame>
                    <img
                      src={event.image}
                      alt={`${index + 1}번째 배너`}
                      onError={(e) => { e.currentTarget.src = 'https://picsum.photos/seed/picsum/360/405'; }}
                    />
                  </MainAdCardFrame>
                </JDALink>
              </JDSwiperSlide>
            ))}
          </JDSwiper>
        </MainAdFrame>
      )} */}
      <InfoCardList>
        {contents.contents.map((card) => (
          <ContentBigCard key={card.id} item={card} />
        ))}
      </InfoCardList>
      <Paging
        onChangePage={moveToPage}
        selectedPage={contents.page}
        totalPageCount={contents.totalPages}
      />
    </Frame>
  );
};

export default inject(injectStore.contents)(observer(EventPage));
