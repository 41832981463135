import { IconActiveArrowRightDown } from 'consts/assets/icons/iconPages';
import colors from '__designkit__/common/colors';
import { JDASqureCheckBoxInput } from 'consts/style/mixins';
import Fonts, { fontStyle } from '__designkit__/common/fonts';
import { StyledJDSmallCheckBoxInput } from 'consts/_v2/_common/style/mixins';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { forwardRef, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components/macro';
import Icon from '__designkit__/icon/Icon';

export enum JD_CHECK_INPUT_TYPE {
  CHECK_BOX = 'CHECK_BOX',
  CHECK_BOX_SMALL = 'CHECK_BOX_SMALL',
  CUSTOM_CHIP = 'CUSTOM',
  ROUND_CHIP = 'ROUND_CHIP',
  SQUARE_CHIP = 'QUURE_CHIP',
  CHECK_ICON = 'CHECK_ICON',
  CHECK_BIG_ICON = 'CHECK_BIG_ICON',
}

const StyledCheckBox = () => css`
  display: flex;
  align-items: center;
  label {
    display: flex;
    align-items: center;
    input[type='checkbox'] {
      ${JDASqureCheckBoxInput()};

      &:checked {
        background: ${colors.G_150};
      }
      :after {
        border-radius: 4px;
        border: 2px solid ${colors.CG_50};
      }
    }
    span {
      font: ${Fonts.B2_Medium};
      line-height: 20px;
      color: ${colors.CG_90};
      padding-left: 8px;
      em {
        font: ${Fonts.B2_Bold};
      }

      &[aria-disabled=true] {
        color: ${colors.CG_60};
      }
    }
  }

  &[aria-disabled='true'] {
    span {
      opacity: 0.5;
    }
  }
`;

const StyledCheckBoxSmall = () => css`
  display: flex;
  align-items: center;
  label {
    display: flex;
    align-items: center;
    input[type='checkbox'] {
      ${JDASqureCheckBoxInput()};

      &:checked {
        background: ${colors.G_150};
      }
      :after {
        border-radius: 4px;
        border: 2px solid ${colors.CG_50};
      }
    }
    span {
      font: ${Fonts.B3_Medium};
      color: ${colors.JOBDA_BLACK};
      padding-left: 8px;
      margin-right: 3px;
      em {
        font: ${Fonts.B2_Bold};
      }
    }
  }

  &[aria-disabled='true'] {
    span {
      opacity: 0.5;
    }
  }
`;
const StyledCustomChip = () => css`
  input[type='checkbox'] {
    display: none;
  }
`;

const StyledRoundChip = ({ padding }: { padding: string }) => css`
  margin-right: 8px;
  margin-bottom: 8px;
  input[type='checkbox'] {
    display: none;
    + .label {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      padding: ${padding};
      line-height: 16px;
      font: ${Fonts.B3_Medium};
      color: ${colors.CG_50};
      border: 1px solid ${colors.CG_40};
      transition: background 0.3s, border 0.3s;
      border-radius: 20px;
      text-align: center;
    }
    &:checked + .label {
      font: ${Fonts.B3_Bold};
      color: ${colors.JOBDA_BLACK};
      border: 1px solid ${colors.JOBDA_BLACK};
    }
  }
`;

const StyledSquareChip = () => css`
  margin-right: 8px;
  margin-bottom: 8px;
  input[type='checkbox'] {
    display: none;
    + .label {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 34px;
      padding: 10px;
      text-align: center;
      font: ${Fonts.B3_Medium};
      color: ${colors.CG_80};
      border: 1px solid ${colors.CG_40};
      border-radius: 4px;
      transition: background 0.3s, border 0.3s;
    }
    &:checked + .label {
      font: ${Fonts.B3_Bold};
      color: ${colors.JOBDA_BLACK};
      border: 1px solid ${colors.JOBDA_BLACK};
    }
  }
`;

const StyledCheckIcon = () => css`
  label {
    display: flex;
    position: relative;
    align-items: center;

    input[type='checkbox'] {
      ${StyledJDSmallCheckBoxInput()}
    }
    span {
      font: ${Fonts.B3_Medium};
      color: ${colors.CG_80};
      padding-left: 10px;
      vertical-align: -webkit-baseline-middle;
    }
  }
  .sub-text {
    position: absolute;
    width: fit-content;
    top: 15%;
    right: 0px;
    font: ${Fonts.B3_Medium};
    color: ${colors.CG_80};
  }

  .sub-icon {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 15%;
    right: 0px;
    font: ${Fonts.B3_Medium};
    color: ${colors.CG_80};
  }
`;

const StyledBigCheckIcon = () => css`
  label {
    display: flex;
    position: relative;
    align-items: center;

    input[type='checkbox'] {
      ${StyledJDSmallCheckBoxInput()}
    }
    span {
      font: ${Fonts.B2_Medium};
      color: ${colors.CG_90};
      padding: 0 25px 0 10px;
      vertical-align: -webkit-baseline-middle;
    }
  }
  .sub-text {
    position: absolute;
    width: fit-content;
    top: 15%;
    right: 0px;
    font: ${Fonts.B4_Medium_P};
    color: ${colors.CG_70};
  }

  .sub-icon {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 15%;
    right: 0px;
    font: ${Fonts.B3_Medium};
    color: ${colors.CG_80};
  }
`;

const Frame = styled.div<{ type: JD_CHECK_INPUT_TYPE; padding: string }>`
  ${(props) => props.type === JD_CHECK_INPUT_TYPE.CHECK_BOX && StyledCheckBox()}
  ${(props) => props.type === JD_CHECK_INPUT_TYPE.CHECK_BOX_SMALL && StyledCheckBoxSmall()}
 ${(props) => props.type === JD_CHECK_INPUT_TYPE.CUSTOM_CHIP && StyledCustomChip()}
 ${(props) => props.type === JD_CHECK_INPUT_TYPE.ROUND_CHIP && StyledRoundChip({ padding: props.padding || '8px 12px' })}
 ${(props) => props.type === JD_CHECK_INPUT_TYPE.SQUARE_CHIP && StyledSquareChip()}
 ${(props) => props.type === JD_CHECK_INPUT_TYPE.CHECK_ICON && StyledCheckIcon()}
 ${(props) => props.type === JD_CHECK_INPUT_TYPE.CHECK_BIG_ICON && StyledBigCheckIcon()}
`;
interface IJDCheckbox extends IComponentProps {
  name: string;
  value: string;
  type?: JD_CHECK_INPUT_TYPE;
  label?: JSX.Element;
  onChange?: any;
  defaultChecked?: boolean;
  isInfoBtn?: boolean;
  isMoreListBtn?: boolean;
  upDown?: boolean;
  padding?: string;
  onClickInfoBtn?: () => void;
  onClickMoreListBtn?: () => void;
}

const JDCheckInput = forwardRef((props: IJDCheckbox, ref) => {
  const { upDown, name, label, onChange, value, defaultChecked, className, isInfoBtn, isMoreListBtn, onClickInfoBtn, onClickMoreListBtn, disabled, type = JD_CHECK_INPUT_TYPE.CHECK_BOX, ...rest } = props;
  const useFormed = useFormContext();
  const { control, setValue } = useFormed;
  const defaultValue = defaultChecked ? value : false;
  const { field } = useController({ name, control, defaultValue });
  const [isMore, setIsMore] = useState<boolean>(true);
  return (
    <>
      <Frame padding={props.padding ? props.padding : '8px 12px'} className={`jd-input ${className || ''}`} aria-disabled={rest.disabled} type={type}>
        <label>
          <input
            {...field}
            type='checkbox'
            onChange={(e) => {
              if (e.target.checked) setValue(name, value);
              else setValue(name, false);
              onChange && onChange(e.target.checked);
            }}
            defaultChecked={field.value === value}
            checked={field.value !== false}
            disabled={disabled}
          />
          {type === JD_CHECK_INPUT_TYPE.CUSTOM_CHIP ? <>{label}</> : <span className='label' aria-disabled={disabled}>{label}</span>}
          {type === JD_CHECK_INPUT_TYPE.CHECK_ICON && isInfoBtn && (
            <div
              role='presentation'
              className='sub-text'
              onClick={(e) => {
                e.preventDefault();
                if (onClickInfoBtn) onClickInfoBtn();
              }}
            >
              자세히
            </div>
          )}
          {isMoreListBtn && upDown && (
            <div
              className='sub-icon'
              onClick={(e) => {
                e.preventDefault();
                setIsMore(!isMore);
                if (onClickMoreListBtn) onClickMoreListBtn();
              }}
              role='button'
            >
              {isMore ? <Icon name='arrow-bottom' size={24} /> : <Icon name='arrow-top' size={24} />}
            </div>
          )}
          {isMoreListBtn && !upDown && (
            <div className='sub-icon'>
              <IconActiveArrowRightDown
                active={isMore}
                width={16}
                height={16}
                onClick={(e) => {
                  e.preventDefault();
                  setIsMore(!isMore);
                  if (onClickMoreListBtn) onClickMoreListBtn();
                }}
              />
            </div>
          )}
        </label>
      </Frame>
    </>
  );
});

export default JDCheckInput;
