import { Button, ButtonMode } from '__designkit__/button/Button';
import Colors from '__designkit__/common/colors';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import JDBaseInput from 'components/_v2/_common/input/JDBaseInput';
import JDBaseModal, { IJDBaseModal } from 'components/_v2/_common/modals/JDBaseModal';
import { JDModalDescription, JDModalTitle } from 'components/_v2/_common/modals/JDModalComponents';
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

const Frame = styled.div`
    min-width: 312px;
    max-width: 440px;
    width: 90vw;
    height: auto;
    background-color: ${Colors.WHITE_100};
    box-shadow: 0px 16px 25px 0px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    padding:40px 24px 24px 24px;
    .title{
        font-size: 24px;
        font-weight: 700;
        color: ${Colors.JOBDA_BLACK};
        text-align: center;
    }
    .subtitle{
      font-size: 16px;
      font-weight: 500;
      color: ${Colors.CG_70};
      text-align: center;

    }
`;

interface IJDInputEmailModal extends IJDBaseModal {
    dimmed?:boolean;
    isOpen:boolean;
    onClickClose:()=>void;
    isBackContentClickable?:boolean;
    isDimClickClose?:boolean;
    title?: string;
    descriptions?: string;
}
const JDInputEmailModal:FC<IJDInputEmailModal> = ({ descriptions, dimmed, isOpen, onClickClose, isBackContentClickable, title, isDimClickClose }) => {
  const { register, handleSubmit, errors } = useFormContext();

  return (
    <JDBaseModal dimmed={dimmed} isOpen={isOpen} onClickClose={onClickClose} isBackContentClickable={isBackContentClickable} isDimClickClose={isDimClickClose}>
      <Frame>
        { title && <div className='title'>{title}</div> }
        { descriptions && <div className='subtitle'>{descriptions}</div> }
        <SpacingBlock size={24} vertical />
        <form onSubmit={handleSubmit(onClickClose)}>
          <JDBaseInput
            name='email'
            placeholder='이메일을 입력해주세요.'
            ref={register}
            customValidator={{
              required: '이메일을 입력해주세요.',
              pattern: {
                value: /^[0-9a-z]+([-_.]*[0-9a-z-_])*@[a-z]+(\.[a-z]{2,})+$/,
                message: '잘못된 이메일 형식이에요.',
              },
            }}
          />
          <SpacingBlock size={32} vertical />
          <Button type='submit' label='확인' buttonMode={ButtonMode.PRIMARY} size='large' />
        </form>
      </Frame>
    </JDBaseModal>
  );
};
export default JDInputEmailModal;
