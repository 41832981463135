import { ErrorMessage } from '@hookform/error-message';
import IconCalender from 'assets/icon/icon_calendar_20_Regular.svg';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { JDAInputFrameBasic, JDAInputFrameMessage } from 'consts/style/mixins';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { FC, useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

const Frame = styled.div <{ disabled?: boolean, value?: boolean }>`
  width: 149px;
  ${(props) => props.disabled && 'opacity:0.5;'}

  .input-frame{
    ${JDAInputFrameBasic()};
    input[type='date'], input[type='month']{
      -webkit-appearance: none;
      -moz-appearance: none;
      color:transparent;
      font: ${Fonts.B1_Medium};
      &::-webkit-datetime-edit {
        opacity: 0;
      }

    }
    input[type='date']::-webkit-calendar-picker-indicator, input[type='month']::-webkit-calendar-picker-indicator {
      background: transparent;
      bottom: 0;
      color: transparent;
      cursor: pointer;
      height: 100%;
      width: 100%;
      left: -30px;
      position: absolute;
      right: 0;
      top: 0;
    }
    input[type='date']::-webkit-datetime-edit,input[type='month']::-webkit-datetime-edit {
    opacity: 0;
    }
    input[type='date']::-webkit-inner-spin-button, input[type='month']::-webkit-inner-spin-button{
      display: block;
    }

    label{
      display:block;
      position:absolute;
      top: 13px;
      margin-left:16px;
      left:0;
      ${(props) => (props.value ? ` color:${colors.CG_90};` : ` color:${colors.CG_60};`)}
     
      font: ${Fonts.B1_Medium};
    }
    
    img{
      display:block;
      position:absolute;
      top: 13px;
      margin-right:16px;
      right:0;
    }
  }

  .message{
   ${JDAInputFrameMessage()};
  }
`;

interface IJDADatePickerInput extends IComponentProps{
  fieldRef?: React.RefObject<HTMLInputElement | null>;
  name: string;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  month?: boolean;
  customValidator?:any;
  defaultValue?: any;
}

const JDADatePickerInput: FC<IJDADatePickerInput> = (props) => {
  const { fieldRef, name, customValidator, placeholder, defaultValue, disabled, className, month, ...rest } = props;
  const useFormed = useFormContext();
  const { control, errors, watch, setValue } = useFormed;
  const { field, meta } = useController({ name, control, defaultValue });

  useEffect(() => {
    setValue(name, defaultValue);
  }, [defaultValue]);
  return (
    <Frame className={`jda-input ${className || ''}`} disabled={disabled} value={watch(name)}>
      <div className={`input-frame ${errors[name] === undefined && !meta.invalid}`}>
        <label htmlFor={`data-picker-${name}`}>{watch(name) ? watch(name).replace(/-/g, '.') : placeholder}</label>
        <input
          {...field}
          aria-required
          id={`date-picker-${name}`}
          onKeyDown={() => false}
          disabled={!!disabled}
          data-date-inline-picker={false}
          type={month ? 'month' : 'date'}
          min='1960.01.01'
          max='9999-12-31'
          {...rest}
          defaultValue={defaultValue}
        />
        <img className='icon-calender' alt='달력' src={IconCalender} />
      </div>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <h4 className='message false'>{message}</h4>}
      />
    </Frame>
  );
};

export default JDADatePickerInput;
