import React from 'react';
import classnames from 'classnames/bind';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

const MatchingMessage = () => (
  <div className={cx('commonBox', 'bottomTag')}>
    <span className={cx('desc', 'mainDesc')}>
      역량검사까지 완료하고<br />JOBDA matching을 통해
    </span>

    <span className={cx('desc')}>
      기업에서 직접<br />입사 제안받아보세요!
    </span>
  </div>
);

export default MatchingMessage;
