import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import React, { FC } from 'react';
import styled from 'styled-components';
import imgNoJoy from 'assets/_v2/alarm/icon_joy_no_alarm.svg';
import LazyImage from 'components/common/LazyImage';

const StyledNotificationNotLoginView = styled.div`
  padding-top:112px;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img{
    width:96px;
    margin-bottom:20px;
  }

  h1{
    text-align: center;
    font: ${Fonts.H4_Bold};
    color:${colors.CG_80};
  }
`;

const NotificationNotFoundView: FC = () => (
  <StyledNotificationNotLoginView>
    <LazyImage className='img' src={imgNoJoy} />
    <h1>새로운 알람이 없습니다.</h1>
  </StyledNotificationNotLoginView>
);

export default NotificationNotFoundView;
