import { IEventChallengeProps } from '__pc__/page/eventChallenge';
import classnames from 'classnames/bind';
import React, { FC } from 'react';
import styles from './extraBenefit.module.scss';

const cx = classnames.bind(styles);

const ExtraBenefit:FC<IEventChallengeProps> = () => (
  <div className={cx('wrap', 'bg')}>
    <div className={cx('inner')}>
      <div className={cx('sectionTitleArea')}>
        <strong className={cx('sectionTitle')}>취업 응원 특별 이벤트</strong>
        <p className={cx('sectionDesc')}>챌린지 1,2 참여자 중 추첨하여<br />JOBDA가 취업 필수 아이템을 지원합니다.</p>
      </div>

      {/* 혜책 영역 */}
      <div className={cx('benefitArea')}>
        <div className={cx('benefitInner')}>
          <div className={cx('benefitWrap', 'type1')}>
            <div className={cx('textArea')}>
              <em className={cx('benefitTitle')}>9월 응원 선물</em>
              <p className={cx('benefitDesc')}>프로필 사진을 찍어드려요</p>
            </div>
          </div>
        </div>
        <div className={cx('benefitInner')}>
          <div className={cx('benefitWrap', 'type2')}>
            <div className={cx('textArea')}>
              <em className={cx('benefitTitle')}>10월 선물</em>
              <p className={cx('benefitDesc')}>Coming Soon</p>
            </div>
          </div>
          <div className={cx('benefitWrap', 'type3')}>
            <div className={cx('textArea')}>
              <em className={cx('benefitTitle')}>11월 선물</em>
              <p className={cx('benefitDesc')}>Coming Soon</p>
            </div>
          </div>
        </div>
      </div>
      {/* 알림 텍스트 영역 */}
      <p className={cx('notice')}>
        - 10/4 이후 당첨자에 한해 개별 연락을 드립니다.
        <br />
        (연락처, 발송 방법 등 별도 안내 예정)
      </p>
    </div>

  </div>
);

export default ExtraBenefit;
