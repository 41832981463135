import FormFiledFrame from 'components/_v2/_common/form/FormFiledFrame';
import FormProfileValueTitle from 'components/_v2/_common/form/FormProfileValueTitle';
import JDSelector, { JDSelectorType } from 'components/_v2/_common/input/JDSelector';
import { DisabilityGrade, DisabilityGradeStr, DisabilityType, DisabilityTypeStr } from 'consts/_v2/profile/DisabilityType';
import { IProfileProps } from 'interfaces/_v2/profile/IProfileProps';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import ProfileModel from 'models/_v2/profile/ProfileModel';
import React, { FC } from 'react';
import { ArrayField, useFormContext } from 'react-hook-form';

interface IProfilePreferentialDisabilityProps extends IProfileProps {
    disabilityFields: Partial<ArrayField<Record<string, any>, 'id'>>[];
}

const ProfilePreferentialDisability:FC<IProfilePreferentialDisabilityProps> = ({ disabilityFields, profileModel = new ProfileModel() }) => {
  const useFormed = useFormContext();
  const { watch, setValue } = useFormed;

  return (
    <>
      { disabilityFields.map((filed) => (
        <div key={filed.id}>
          <FormFiledFrame>
            <FormProfileValueTitle required>장애 사항</FormProfileValueTitle>
            <JDSelector
              name='disability.grade'
              selectTitle='장애 등급'
              type={JDSelectorType.NORMAL}
              className='selector'
              value={profileModel.preferential?.disability?.grade || ''}
              onChange={() => {
                if (watch('disability.grade') === DisabilityGrade.NONE) {
                  setValue('disability.type', null);
                }
              }}
            >
              <select>
                <option value='' hidden>장애 등급</option>
                {
                  (Object.keys(DisabilityGrade) as Array<keyof typeof DisabilityGrade>).map((key) => (
                    <option key={key} value={key} selected={`${watch('disability.grade')}` === key}>{DisabilityGradeStr[key]}</option>
                  ))
                }
              </select>
            </JDSelector>
            <JDSelector
              name='disability.type'
              selectTitle='장애 내용을 선택해 주세요.'
              type={JDSelectorType.NORMAL}
              disabled={watch('disability.grade') === DisabilityGrade.NONE || !watch('disability.grade')}
              className='selector'
              value={profileModel.preferential?.disability?.type || ''}
            >
              <select>
                <option value='' hidden>장애 내용을 선택해 주세요.</option>
                {
                      (Object.keys(DisabilityType) as Array<keyof typeof DisabilityType>).map((key) => (
                        <option key={key} value={key} selected={`${watch('disability.type')}` === key}>{DisabilityTypeStr[key]}</option>
                      ))
                    }
              </select>
            </JDSelector>
          </FormFiledFrame>
        </div>
      ))}
    </>
  );
};

export default inject(injectStore.profileModel)(observer(ProfilePreferentialDisability));
