// CI 속성은 본인인증 타입인 SMS와 IPIN이 도입되기 이전에 본인인증을 완료했기 떄문에 본인인증은 했지만, 본인인증 타입이 없는 경우를 의미합니다.

export const IDENTIFICATION_TYPE = {
  NONE: 'NONE',
  CI: 'CI',
  SMS: 'SMS',
  IPIN: 'IPIN',
} as const;

export type IdentificationType = typeof IDENTIFICATION_TYPE[keyof typeof IDENTIFICATION_TYPE];

export const IDENTIFICATION_TYPE_STR = {
  NONE: '미완료',
  CI: '완료',
  SMS: '휴대폰 본인인증',
  IPIN: '아이핀 본인인증',
} as const;

export type IdentificationTypeStr = typeof IDENTIFICATION_TYPE_STR[keyof typeof IDENTIFICATION_TYPE_STR];

export interface IGetProfileBasicInfoRs {
  profileImageUrl: string | null;
  profileImageUid: string | null;
  profileImageName: string | null;
  name: string | null;
  birthDate: string | null;
  email: string | null;
  mobile: string | null;
  countryCode: string | null;
  verified: boolean | null;
  userIdentificationType: IdentificationType | null;
}

export interface IPutProfileBasicInfoRq {
  profileImageUid: string | null;
  name: string | null;
  birthDate: string | null;
  email: string | null;
  mobile: string | null;
  countryCode: string | null;
  sourcePage: string | null;
}

export interface IGetCheckMobileDuplicateRq {
  mobile: string;
  countryCode: string;
}

export interface IRequestVerificationCodeRq {
  mobile: string;
  countryCode: string;
}

export interface ICheckVerificationCodeRq {
  mobile: string;
  countryCode: string;
  token: string;
}

export interface IDuplicateOrNotRs {
  isDuplicate: boolean;
}
