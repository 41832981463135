import { useMutation, useQuery } from 'react-query';
import { RemotePassInterviewRepo } from 'repository/passInterview/PassInterviewRepo';

export const keys = ['interview-practices'];

const passInterviewRepo = new RemotePassInterviewRepo();

const fetchPassInterviewResults = async () => {
  const data = await passInterviewRepo.fetchPassInterviewResults();
  return data;
};
export function usePassInterviewResults() {
  return useQuery([...keys, 'results'], fetchPassInterviewResults);
}

const fetchPassInterviewQuestionResults = async (rq:number) => {
  const data = await passInterviewRepo.fetchPassInterviewQuestionResults(rq);
  return data;
};

export function usePassInterviewQuestionResults(rq:number) {
  return useQuery([...keys, 'question', rq], () => fetchPassInterviewQuestionResults(rq));
}

const fetchPassInterviewVideo = async (rq:number) => {
  const data = await passInterviewRepo.fetchPassInterviewVideo(rq);
  return data;
};

export function usePassInterviewVideo(rq:number) {
  return useQuery([...keys, 'question', rq], () => fetchPassInterviewVideo(rq));
}
