import React, { useState, ReactNode } from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import FileUtil from 'utils/FileUtil';
import useToast from 'hooks/useToast';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  Pretendard: {
    normal: 'https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Medium.woff',
    bold: 'https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff',
    italics: 'https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Medium.woff',
    bolditalics: 'https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraBold.woff',
  },
};
interface PdfWrapperProps {
    children?: ReactNode;
    docDefinition?: any;
  }

export const getBase64ImageFromUrl = async (url: string): Promise<string> => {
  const res = await fetch(url);
  const blob = await res.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

const usePdf = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { setToastObject } = useToast();
  const downloadPdf = async (docDefinition: any, fileName:string) => {
    if (isLoading) return; // 중복 호출 방지

    setIsLoading(true);

    try {
      const pdfDocGenerator = pdfMake.createPdf(docDefinition);
      pdfDocGenerator.getBuffer(async (buffer) => {
        const arrayBuffer = buffer.buffer.slice(buffer.byteOffset, buffer.byteOffset + buffer.byteLength);
        await FileUtil.fileDownload(`${fileName}.pdf`, arrayBuffer);
      });
    } catch (error) {
      setToastObject({ isOpen: true, type: 'ERROR', message: '다운로드를 실패했습니다.', duration: 2000 });
      console.error('PDF generation error:', error);
    } finally {
      setToastObject({ isOpen: true, type: 'SUCCESS', message: '다운로드가 완료되었습니다.', duration: 2000 });

      setIsLoading(false);
    }
  };

  const PdfWrapper = ({ children }: PdfWrapperProps) => {
    return (
      <div
        style={{
          // PDF 미리보기 숨김

        }}
      >
        {children}
      </div>
    );
  };
  return { PdfWrapper, downloadPdf, isLoading };
};

export default usePdf;
