import { IconArrowUpDown } from 'consts/assets/icons/iconPages';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import React, { FC, useState, useRef } from 'react';
import styled from 'styled-components';
import IconVideoPlay from 'assets/_v2/_common/icon_video_play.svg';
import IconWarn from 'assets/_v2/acc/icon_acc_warn_red.svg';
import IconGood from 'assets/_v2/acc/icon_acc_good_green.svg';
import AccVideoModal from './AccVideoModal';

const Frame = styled.div`
    display: flex;
    padding:12px 26px 12px 16px;
    width: 100%;
    justify-content: space-between;
`;

const TitleFrame = styled.div`
display: flex;
align-items: center;
    .text-title{
        font: ${Fonts.B2_Medium_P};
        color:${colors.CG_80};
    }
    .icon-warn{
        width:20px;
        height:20px;
        margin-left: 4px;
    }
`;
const CheckFrame = styled.div`
    display: flex;
    .video-check{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 40px;
        height: 40px;
        padding:6px 4px;
        border: 1px solid ${colors.CG_50};
        border-radius: 4px;
        .icon-play{
            align-self: center;
            margin-top: 1px;
            width:10px;
            height:12px;
        }
        .text-time{
          display: flex;
          justify-content: center;
          font: ${Fonts.B4_Medium};
          color:${colors.CG_70};
          margin-top: 2px;
        }
    }
  
  }
    .column-divider{
        width:1px;
        height: 12px;
        margin: 0 10px 0 14px;
        background:${colors.cD9D9D9};
        align-self:center;
    }
`;
const FoldedFrame = styled.div`
      .answer{
        display: flex;
        padding:20px;
        font: ${Fonts.B2_Medium_P};
        color:${colors.CG_70};
        line-height: 20px;
        overflow: hidden;
        background: ${colors.CG_30};
        .q-answer{
            font: ${Fonts.B2_Bold};
            line-height: 20px;
            margin-right: 8px;
        }
    &.folded {
        visibility: hidden;
        padding:0px;
        height:0px;
    }
}
`;
interface IAccApplyVideoCheckCardProps{
    title:string;
    unreliableYn:boolean;
    question:string;
    sn:number;
    url:string
}
const AccApplyVideoCheckCard:FC<IAccApplyVideoCheckCardProps> = ({ url, title, unreliableYn, question, sn }) => {
  const [infoFolded, setInfoFolded] = useState<boolean>(false);
  const [videoLayerVisible, setVideoLayerVisible] = useState<boolean>(false);
  const [videoLength, setVideoLength] = useState<string>('');
  const runVideo = () => {
    setVideoLayerVisible(true);
  };
  const videoEl = useRef(null);

  const handleLoadedMetadata = () => {
    const video:any = videoEl.current;
    if (!video) return;
    const strNum = (video.duration).toString().split('.')[0];
    const secNum = Number(strNum);
    const hours = Math.floor(secNum / 3600);
    const minutes = Math.floor((secNum - (hours * 3600)) / 60);
    const seconds = secNum - (hours * 3600) - (minutes * 60);
    if (minutes < 10) {
      setVideoLength(`0${minutes}:${seconds}`);
    } else {
      setVideoLength(`${minutes}:${seconds}`);
    }
  };
  return (
    <>
      <Frame>
        <TitleFrame>
          <div className='text-title'>{title}</div>
          <img src={unreliableYn ? `${IconGood}` : `${IconWarn}`} alt='icon' className='icon-warn' />

        </TitleFrame>
        <CheckFrame>
          <div className='video-check' onClick={() => runVideo()} role='button'>
            <img src={IconVideoPlay} className='icon-play' alt='' />
            <div className='text-time'>{videoLength}</div>
          </div>
          <div className='column-divider' />
          <IconArrowUpDown onClick={() => setInfoFolded(!infoFolded)} role='button' className={`${sn} question-btn`} active={infoFolded} color={colors.G_100} />

        </CheckFrame>
      </Frame>
      <FoldedFrame>
        <div className={!infoFolded ? 'folded answer' : 'answer'}>
          <div className='q-answer'>Q.</div>
          {question}
        </div>
      </FoldedFrame>
      <AccVideoModal useOutsideClick url={url} isOpen={videoLayerVisible} onClose={() => setVideoLayerVisible(false)} />
      <div aria-hidden>
        <video ref={videoEl} onLoadedMetadata={handleLoadedMetadata}>
          <source src={url} type='video/mp4' />
        </video>
      </div>
    </>
  );
};

export default AccApplyVideoCheckCard;
