import React, { FC } from 'react';

import classNames from 'classnames/bind';
import MultiRange from 'components/_v2/_common/input/rang/MultiRange';
import styles from './SalaryMultiRange.module.scss';

const cx = classNames.bind(styles);

export interface ICondition {
  minValue: number ;
  maxValue: number ;
  requirementType: 'REQUIRED' | 'PREFERENTIAL';
}

interface IDots {
  value: number;
  label: string;
}

interface ISalaryMultiRangeProps {
  min: number;
  max: number;
  dots: IDots[];
  step:number;
  condition: ICondition;
  setCondition: (condition: ICondition) => void;
  disabledRightValue?:boolean;
  disabledLeftValue?:boolean;
}

const SalaryMultiRange: FC<ISalaryMultiRangeProps> = ({ min, max, dots, step, condition, setCondition, disabledRightValue, disabledLeftValue }) => (
  <div className={cx('wrap')}>
    <MultiRange
      step={step}
      min={min}
      max={max}
      leftValue={condition && condition.minValue ? condition.minValue : min}
      rightValue={condition && condition.maxValue ? condition.maxValue : max}
      setLeftValue={(value) => {
        if (disabledLeftValue) return;
        setCondition(
          condition
            ? { ...condition, minValue: value <= min ? min : value }
            : {
              minValue: value <= min ? min : value,
              maxValue: max,
              requirementType: 'PREFERENTIAL',
            },
        );
      }}
      setRightValue={(value) => {
        if (disabledRightValue) return;
        setCondition(
          condition
            ? { ...condition, maxValue: value >= max ? max : value }
            : {
              minValue: min,
              maxValue: value >= max ? max : value,
              requirementType: 'PREFERENTIAL',
            },
        );
      }}
      dots={dots}
      disabledRightValue={disabledRightValue}
    />
  </div>
);

export default SalaryMultiRange;
