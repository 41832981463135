import { Button } from '__designkit__/button/Button';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { IJobPreviewRs } from 'interfaces/_v2/jobs/IJobPostingRs';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import React, { FC } from 'react';
import styled from 'styled-components';
import JobsPreviewPostingPositionCard from './JobsPreviewPostingPositionCard';

const Frame = styled.div`
    padding: 0 16px;
    .title {
        font: ${Fonts.H5_Bold};
        color: ${colors.JOBDA_BLACK};
        margin-bottom: 16px;
    }

    .all-view-button {
        margin-top: 4px;
    }

    ul {
        li {
            margin-bottom: 8px;
        }
    }
`;

interface IJobsPreviewPostingPositions{
    jobsDetail:IJobPreviewRs
}
const JobsPreviewPostingPositions: FC<IJobsPreviewPostingPositions> = ({ jobsDetail }) => {
  return (
    <Frame>
      <p className='title'>모집 포지션</p>
      <ul>
        {jobsDetail?.positions.map((position, index) => (
          (index < 5) && (
            <li key={index}>
              <JobsPreviewPostingPositionCard position={position} />
            </li>
          )
        ))}
      </ul>
      {
              ((jobsDetail && jobsDetail.positions.length > 5)) && (
                <Button
                  outLined
                  label={`포지션 ${jobsDetail && jobsDetail.positions?.length - 5}개 모두보기`}
                  size='small'
                  className='all-view-button'
                />
              )
          }

    </Frame>
  );
};

export default inject(injectStore.jobsModel)(observer(JobsPreviewPostingPositions));
