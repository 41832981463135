import ResultCard from '__designkit__/card/ResultCard';
import SkeletonResultCard from '__designkit__/card/ResultCardSkeleton';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import JDSwiper, { JDSwiperSlide } from 'components/_v2/_common/JDSwiper';
import JDSimpleInfoModal from 'components/_v2/_common/modals/JDSimpleInfoModal';
import RoutePaths from 'consts/RoutePaths';
import { IAccCenterCompanyApplyRecent } from 'interfaces/_v2/acc/IAccGate';
import { inject, observer } from 'mobx-react';
import AccaGateModel from 'models/_v2/acca/AccaGateModel';
import { injectStore } from 'models/store';
import { IAccaGateProps } from 'pages/_v2/acca/AccaGate';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { DateFormatYYYYMMDDCCHHMMUnit } from 'utils/DateUtils';

const Frame = styled.div`

.title-area {
  padding: 40px 16px 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  >h1{
    font: ${Fonts.H4_Bold};
    color:${colors.JOBDA_BLACK};
  }
  .more-text{
    display: flex;
    align-items:center;
      font: ${Fonts.B3_Medium};
      color:${colors.JOBDA_BLACK};
    }
  }

  .latest-exam-swiper {
    padding: 0 16px 40px 16px;
  }

  .latest-exam-card{
    width:fit-content;
    overflow: visible;
    display: flex;
    justify-content: center;
    align-items: start;
  }
`;

const ResultNoneFrame = styled.div`
  width: 100%;
  height: 152px;
  background: ${colors.CG_30};
  border: 1px solid ${colors.CG_40};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  >h1{
    font: ${Fonts.B2_Bold};
    color:${colors.CG_60};
  }

  >button{
    width: 115px;
    height: 38px;
    padding:10px 20px;
    background: ${colors.JOBDA_BLACK};
    border-radius: 4px;
    color:${colors.WHITE_100};
    font: ${Fonts.B2_Bold};
  }
`;

const AccaGateCompanyLatestExam: FC<IAccaGateProps> = ({ accaGateModel = new AccaGateModel() }) => {
  const [openInfoModal, setOpenInfoModal] = React.useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const history = useHistory();
  const [filteredApplies, setFilteredApplies] = useState<IAccCenterCompanyApplyRecent[]>([]);
  const filterList = async () => {
    setIsLoading(true);
    await accaGateModel.loadAccCenterCompanyApplyRecent();
    if (accaGateModel.companyApplies) {
      const threeMonthsAgo = new Date();
      threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
      const filtered = accaGateModel.companyApplies.filter((item: IAccCenterCompanyApplyRecent) => {
        const isRecent = item.lastModifiedDateTime && new Date(item.lastModifiedDateTime) > threeMonthsAgo;
        const isVisible = item.visibleYn === true; // visibleYn이 true인지 확인
        return isRecent && isVisible; // 두 조건 모두 충족하는 경우만 필터링
      });
      setFilteredApplies(filtered);
      setIsLoading(false);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    filterList();
  }, []);

  return (
    <>
      <Frame>
        <div className='title-area'>
          <h1>기업별 최근 응시내역</h1>
        </div>
        {
        isLoading ? (
          // isLoading 중일 때는 SkeletonResultCard를 보여줌
          <JDSwiper
            className='latest-exam-swiper'
            slidesPerView='auto'
            spaceBetween={16}
          >
            {
              Array.from({ length: 5 }).map((_, index) => (
                <JDSwiperSlide className='latest-exam-card' key={index}>
                  <SkeletonResultCard />
                </JDSwiperSlide>
              ))
            }
          </JDSwiper>
        ) : (
          filteredApplies && filteredApplies.length > 0

            ? (
              <JDSwiper
                className='latest-exam-swiper'
                slidesPerView='auto'
                spaceBetween={16}
              >
                {
                filteredApplies.map((item: IAccCenterCompanyApplyRecent) => (
                  <JDSwiperSlide className='latest-exam-card'>
                    <ResultCard item={{
                      date: item.lastModifiedDateTime && DateFormatYYYYMMDDCCHHMMUnit(item.lastModifiedDateTime),
                      title: item.title,
                      doneExamCount: item.completed,
                      maxExamCount: item.total,
                      thumbnailUrl: item.thumbnailUrl,
                      completed: item.completed,
                      dDate: item.submitDeadline,
                      saveYn: item.saveYn,
                      submitYn: item.submitYn,
                      handleClink: () => {
                        if (item.completed === item.total) {
                          history.push(RoutePaths.acca_results(item.sn));
                        } else {
                          setOpenInfoModal(true);
                        }
                      },
                      handleDelete: async () => {
                        await accaGateModel.deleteAccCenterApplyRecent(item.sn);
                        await filterList();
                      },
                    }}
                    />
                  </JDSwiperSlide>
                ))
              }
              </JDSwiper>
            ) : (
              <ResultNoneFrame>
                <h1>최근 응시 내역이 없습니다.</h1>
              </ResultNoneFrame>
            ))
}
      </Frame>
      <JDSimpleInfoModal title='역량검사 응시는 PC에서만 가능해요.' isOpen={openInfoModal} onClickClose={() => setOpenInfoModal(false)} />
    </>
  );
};
export default inject(injectStore.login, injectStore.accaGateModel)(observer(AccaGateCompanyLatestExam));

// const appliesDummy:IAccCenterCompanyApplyRecent[] = [
//   {
//     sn: 545,
//     title: '이어하기와 d-badge',
//     code: '4',
//     lastModifiedDateTime: '2024-08-01T17:36:58',
//     submitDeadline: '2024-10-19T17:36:58',
//     completed: 5,
//     total: 10,
//     thumbnailUrl: 'https://via.placeholder.com/280x160',
//     submitYn: false,
//     saveYn: false,
//   },
//   {
//     sn: 545,
//     title: '제출 완료 + 응시 완료',
//     code: '4',
//     lastModifiedDateTime: '2024-08-01T17:36:58',
//     submitDeadline: '2024-10-19T17:36:58',
//     completed: 10,
//     total: 10,
//     thumbnailUrl: 'https://via.placeholder.com/280x160',
//     submitYn: true,
//     saveYn: false,
//   },
//   {
//     sn: 545,
//     title: '제출 마감 + 진행중',
//     code: '4',
//     lastModifiedDateTime: '2024-08-01T17:36:58',
//     submitDeadline: '2024-08-19T17:36:58',
//     completed: 3,
//     total: 10,
//     thumbnailUrl: 'https://via.placeholder.com/280x160',
//     submitYn: false,
//     saveYn: false,
//   },
//   {
//     sn: 545,
//     title: '제출 마감 + 응시완료',
//     code: '4',
//     lastModifiedDateTime: '2024-10-01T17:36:58',
//     submitDeadline: '2024-10-19T17:36:58',
//     completed: 10,
//     total: 10,
//     thumbnailUrl: 'https://via.placeholder.com/280x160',
//     submitYn: false,
//     saveYn: false,
//   },
//   {
//     sn: 545,
//     title: '추가 응시 중',
//     code: '4',
//     lastModifiedDateTime: '2024-08-01T17:36:58',
//     submitDeadline: null,
//     completed: 2,
//     total: 10,
//     thumbnailUrl: 'https://via.placeholder.com/280x160',
//     submitYn: false,
//     saveYn: true,
//   },
//   {
//     sn: 565,
//     title: '결과표 보기',
//     code: '4',
//     lastModifiedDateTime: '2024-08-01T17:36:58',
//     submitDeadline: null,
//     completed: 10,
//     total: 10,
//     thumbnailUrl: 'https://via.placeholder.com/280x160',
//     submitYn: false,
//     saveYn: true,
//   },
// ];
