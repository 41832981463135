import React, { FC } from 'react';
import IComponentProps from 'interfaces/props/IComponentProps';
import styled from 'styled-components';
import Portal from './common/Portal';

const Frame = styled.div`
  z-index:999;
  position: fixed;
  bottom: calc(72px + env(safe-area-inset-bottom));
  display:flex;
  flex-direction:column;
  align-items:stretch;
  justify-content:flex-end;
  width: fit-content;
  block-size: fit-content;
  right: 12px;
  height:fit-content;
`;

const BottomFloatingFrame:FC<IComponentProps> = (props) => (
  <Portal>
    <Frame>
      {props.children}
    </Frame>
  </Portal>
);

export default BottomFloatingFrame;
