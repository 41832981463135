/* eslint-disable jsx-a11y/media-has-caption */
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { RecruitmentTypeText } from 'consts/RcruitmentType';
import { RecruitmentTypesText } from 'consts/_v2/calendar/CalendarFilterType';
import { InterviewHistoryListItemDto } from 'interfaces/_v2/passInterview/IPassInterviewRs';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import { injectStore } from 'models/store';
import React, { FC } from 'react';
import styled from 'styled-components';
import ImgDummy from 'assets/_v2/_common/img_dummy.png';
import MobileStore from 'store/mobileStore';

const Frame = styled.div`
  padding: 8px 0;
  display: flex;
  gap: 12px;
  width: 100%;
  .img {
    width: 100px;
    height: 75px;
    border-radius: 4px;
    background-color: ${Colors.CG_30};
    background-size: contain;
    flex: none;
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
    .chip-frame {
      display: flex;
      gap: 4px;
      .sky {
        font: ${Fonts.B4_Medium};
        border-radius: 4px;
        color: ${Colors.S_300};
        display: flex;
        border: 1px solid rgba(0, 0, 0, 0.05);
        padding: 4px 6px;
        justify-content: center;
        align-items: center;
        background-color: ${Colors.S_30};
      }
      .violet {
        font: ${Fonts.B4_Medium};
        border-radius: 4px;
        color: ${Colors.B_80};
        display: flex;
        border: 1px solid rgba(0, 0, 0, 0.05);
        padding: 4px 6px;
        justify-content: center;
        align-items: center;
        background-color: ${Colors.B_30};
      }
      .yellow {
        font: ${Fonts.B4_Medium};
        border-radius: 4px;
        color: ${Colors.Y_300};
        display: flex;
        border: 1px solid rgba(0, 0, 0, 0.05);
        padding: 4px 6px;
        justify-content: center;
        align-items: center;
        background-color: ${Colors.Y_30};
      }
      .green {
        font: ${Fonts.B4_Medium};
        border-radius: 4px;
        color: ${Colors.G_200};
        display: flex;
        border: 1px solid rgba(0, 0, 0, 0.05);
        padding: 4px 6px;
        justify-content: center;
        align-items: center;
        background-color: ${Colors.G_30};
      }
      .orange {
        font: ${Fonts.B4_Medium};
        border-radius: 4px;
        color: '#fd7e14';
        display: flex;
        border: 1px solid rgba(0, 0, 0, 0.05);
        padding: 4px 6px;
        justify-content: center;
        align-items: center;
        background-color: '#fff4e6';
      }
      .gray {
        font: ${Fonts.B4_Medium};
        border-radius: 4px;
        color: ${Colors.CG_60};
        display: flex;
        border: 1px solid rgba(0, 0, 0, 0.05);
        padding: 4px 6px;
        justify-content: center;
        align-items: center;
        background-color: ${Colors.WHITE_100};
      }
    }
  }
  .question-title {
    font: ${Fonts.B2_Bold_P};
    color: ${Colors.JOBDA_BLACK};
    align-items: flex-start;
    display: flex;
    text-align: left;
  }
`;

interface IPassInterviewListCard {
  data: InterviewHistoryListItemDto;
  onClick?: () => void;
  context?: Context;
}
const PassInterviewListCard: FC<IPassInterviewListCard> = ({
  data,
  onClick,
  context = new Context(),
}) => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const getMainChip = (text: any, type: any) => {
    switch (type) {
      case 'COMMON':
        if (text === '빈출 면접 질문') {
          return <div className='violet'>{text}</div>;
        }
        return <div className='gray'>{text}</div>;

      case 'COMPANY':
        return <div className='sky'>{text}</div>;

      case 'CUSTOM':
        return <div className='yellow'>{text}</div>;

      case 'ACC':
        return <div className='green'>{text}</div>;
      default:
        return <div className='violet'>{text}</div>;
    }
  };
  const checkVideoFormat = (url:string) => {
    if (url.includes('.webm')) {
      return true;
    } if (url.includes('.mp4')) {
      return false;
    }
    return null;
  };

  return (
    <Frame onClick={onClick}>
      {isSafari && checkVideoFormat(data.answerVideoUrl) ? (
        <div
          className='img'
          style={{ backgroundImage: `url(${ImgDummy})` }}
        />
      ) : (
        <video className='img' playsInline>
          <source src={`${data.answerVideoUrl}#t=0.001`} type='video/mp4' />
        </video>
      )}

      <div className='content'>
        <div className='chip-frame'>
          {getMainChip(data.badgeTitle, data.questionTypeCode)}
          {data.jobTypeCode
            && getMainChip(RecruitmentTypesText[data.jobTypeCode], 'COMMON')}
          {data.jobGroupCode
            && getMainChip(
              data.jobGroupCode === -1
                ? '직군 공통'
                : context.jobGroupsWithTitles.find(
                  (jobGroup) => jobGroup.code === data.jobGroupCode,
                )?.name,
              'COMMON',
            )}
          {data.type && getMainChip(data.type, 'COMMON')}
          {data.description && getMainChip(data.description, 'COMMON')}
        </div>
        <div className='question-title'>{data.questionText}</div>
      </div>
    </Frame>
  );
};
export default inject(injectStore.context)(observer(PassInterviewListCard));
