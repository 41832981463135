import { Haptics, ImpactStyle } from '@capacitor/haptics';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Emoji from '__designkit__/icon/Emoji';
import JDDimModal from 'components/_v2/_common/modals/JDDimModal';
import ChattingReportModal from 'components/_v2/chatting/ChattingReportModal';
import useToast from 'hooks/useToast';
import { ChattingServerError } from 'interfaces/_v2/chatting/IChattingRqRs';
import IComponentProps from 'interfaces/props/IComponentProps';
import { inject, observer } from 'mobx-react';
import ChattingModel from 'models/_v2/ChattingModel';
import { injectStore } from 'models/store';
import { FC, MouseEvent, RefObject, useState } from 'react';
import MobileStore from 'store/mobileStore';
import styled, { keyframes } from 'styled-components';
import { Message, MessageTypeEnum } from 'talkplus-sdk';

const bottomModalAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(calc(-100%));
  }
`;

const Frame = styled.div`
  position: absolute;
  top: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: fit-content;
  padding: 8px 0 calc(16px + env(safe-area-inset-bottom));
  border-radius: 8px 8px 0 0;
  background-color: ${Colors.WHITE_100};
  animation: ${bottomModalAnimation} 0.2s 0s 1 ease-out forwards;
  z-index: 10005;

  > div.divider {
    width: calc(100% - 32px);
    height: 1px;
    background-color: ${Colors.CG_40};
  }

  &::before {
    position: absolute;
    top: -8px;
    left: 50%;
    width: 32px;
    height: 4px;
    border-radius: 2px;
    background-color: ${Colors.CG_50};
    transform: translateX(-50%);
    content: '';
  }
`;

const EmojiFrame = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
  padding: 8px 32px 16px;
`;

const EmojiItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 8px;

  &.my-reaction {
    background-color: ${Colors.CG_30};
  }

  &:active {
    background-color: ${Colors.CG_30};
  }
`;

const MenuFrame = styled.div`
  width: 100%;
  height: fit-content;
  padding: 12px 12px 0;
`;

const Menu = styled.div`
  width: 100%;
  height: fit-content;
  padding: 17px 0;
  border-radius: 8px;
  font: ${Fonts.B1_Medium};
  color: ${Colors.JOBDA_BLACK};
  text-align: center;
  user-select: none;

  &:active {
    background-color: ${Colors.CG_30};
  }
`;

interface IChattingReactionModalProps extends IComponentProps {
  messageItem: Message;
  messageRef: RefObject<HTMLDivElement>;
  myReaction: string;
  onClickClose: () => void;
  chattingModel?: ChattingModel;
}

const ChattingReactionModal: FC<IChattingReactionModalProps> = ({ messageItem, messageRef, myReaction, onClickClose, chattingModel = new ChattingModel() }) => {
  const { setToastObject } = useToast();
  const [isOpenReportModal, setIsOpenReportModal] = useState<boolean>(false);

  const haptic = async () => {
    try {
      await Haptics.impact({ style: ImpactStyle.Medium });
    } catch (e) {
      // 햅틱이 동작하지 않는 디바이스를 위한 방어코드로 따로 처리는 필요하지 않음.
    }
  };

  const onClickEmoji = async (event: MouseEvent<HTMLDivElement>) => {
    const emojiType = event.currentTarget.dataset.emoji;
    if (!emojiType) return;
    try {
      if (myReaction === emojiType) {
        // 이미 선택한 이모지를 다시 선택한 경우
        const { message } = await chattingModel.client.removeMessageReaction({ channelId: messageItem.channelId, messageId: messageItem.id, reaction: emojiType });
        chattingModel.updateMessage(message);
      } else if (myReaction) {
        // 다른 이모지를 선택한 경우
        await chattingModel.client.removeMessageReaction({ channelId: messageItem.channelId, messageId: messageItem.id, reaction: myReaction });
        const { message } = await chattingModel.client.addMessageReaction({ channelId: messageItem.channelId, messageId: messageItem.id, reaction: emojiType });
        chattingModel.updateMessage(message);
      } else {
        const { message } = await chattingModel.client.addMessageReaction({ channelId: messageItem.channelId, messageId: messageItem.id, reaction: emojiType });
        chattingModel.updateMessage(message);
      }
    } catch (e) {
      const err = e as ChattingServerError;
      console.error(err);
    }

    await haptic();
    onClickClose();
  };

  const onClickReplyBtn = async () => {
    chattingModel.setReplyMessage(messageItem);
    await haptic();
    onClickClose();
  };

  const onClickCopyBtn = async () => {
    try {
      if (messageItem.type === MessageTypeEnum.Admin || messageItem.type === MessageTypeEnum.AdminHidden) {
        if (messageRef.current) await navigator.clipboard.writeText(messageRef.current.innerText);
      } else await navigator.clipboard.writeText(messageItem.text);
      await haptic();
      setToastObject({ isOpen: true, type: 'INFO', message: '복사되었어요.' });
      onClickClose();
    } catch (e) {
      console.error(e);
      setToastObject({ isOpen: true, type: 'ERROR', message: '메시지 복사에 실패했습니다. 잠시 뒤에 다시 시도해 주세요.' });
    }
  };

  const onClickReportBtn = async () => {
    setIsOpenReportModal(true);
    await haptic();
  };

  return (
    <JDDimModal onClickClose={onClickClose} dimClickable>
      <Frame>
        <EmojiFrame>
          <EmojiItem data-emoji='thumb' onClick={onClickEmoji} className={myReaction === 'thumb' ? 'my-reaction' : ''}>
            <Emoji name='thumb' size={32} />
          </EmojiItem>
          <EmojiItem data-emoji='heart' onClick={onClickEmoji} className={myReaction === 'heart' ? 'my-reaction' : ''}>
            <Emoji name='heart' size={32} />
          </EmojiItem>
          <EmojiItem data-emoji='smile' onClick={onClickEmoji} className={myReaction === 'smile' ? 'my-reaction' : ''}>
            <Emoji name='smile' size={32} />
          </EmojiItem>
          <EmojiItem data-emoji='cry' onClick={onClickEmoji} className={myReaction === 'cry' ? 'my-reaction' : ''}>
            <Emoji name='cry' size={32} />
          </EmojiItem>
        </EmojiFrame>
        <div className='divider' />
        <MenuFrame>
          <Menu onClick={onClickReplyBtn}>
            답장하기
          </Menu>
          { MobileStore.currentPlatform !== 'android' && (
            <Menu onClick={onClickCopyBtn}>
              복사하기
            </Menu>
          )}
          { (messageItem.userId !== chattingModel.user!.id) && messageItem.type !== MessageTypeEnum.Admin && messageItem.type !== MessageTypeEnum.AdminHidden && (
            <Menu onClick={onClickReportBtn}>
              신고하기
            </Menu>
          )}
        </MenuFrame>
      </Frame>
      { isOpenReportModal && <ChattingReportModal messageItem={messageItem} onClickClose={onClickClose} /> }
    </JDDimModal>
  );
};

export default inject(injectStore.chattingModel)(observer(ChattingReactionModal));
