import Lottie from 'lottie-react'; // Lottie의 AnimationItem을 가져옵니다.
import React, { FC } from 'react';

interface ILottieFile{
    animationData:any;
    className?:string;
    loopCondition?:boolean
}
const LottieFile: FC<ILottieFile> = ({ animationData, className, loopCondition = true }) => {
  const options = {
    animationData,
    loop: loopCondition,
    autoplay: true,
  };

  return (
    <Lottie {...options} className={className} />
  );
};
export default LottieFile;
