import React from 'react';

interface QnaListData {
  question: string;
  answer: React.ReactElement;
}

export const qnaListData: QnaListData[] = [
  {
    question: 'Q. 나의 응시 현황을 알고 싶어요',
    answer: (
      <>
        본 이벤트 상단에 있는 ‘참가하기’ 버튼을 통해 이동되는 개발자 센터 화면에서 본인의 응시 상태를 확인하실 수 있으며,
        개발 구현 능력 검사, 개발자 역량검사(역량검사) 모두 응시하실 수 있습니다.
        나의 개발 등급 및 동료 개발자들의 랭킹도 확인할 수 있도록 준비중이니, 개발자 센터를 애용해 주세요!
        본 이벤트 페이지가 아닌, JOBDA 사이트 메인 상단에서 개발자검사 탭을 눌러 이동하실수도 있습니다.
      </>
    ),
  },
  {
    question: 'Q. 개발자 역량검사(역량검사)은 무엇인가요?',
    answer: (
      <>
        코드는 자신있지만, 면접이나 이력서에서 본인을 잘 설명하지 못해 어려움을 겪는 개발자들이 많습니다.
        <br />
        개발자 역량검사(역량검사)은 개발자의 강점과 약점을 파악하고 분석하여, 자신을 이해할 수 있는 분석 결과를 제공하는
        테스트입니다.
        <br />
        750개 이상의 기업에서 실제 채용에 활용하는 검사로서, 응시자는 실제 채용 과정에서 분석 결과를 바탕으로 면접,
        <br />
        이력서 작성에 도움을 받을 수 있습니다.
      </>
    ),
  },
  {
    question: 'Q. 시상 기준은 무엇인가요?',
    answer: (
      <>
        한 달간 진행되는 디챔스 개발 구현 능력 검사(코딩테스트) 결과에 따라 상위 30명을 선정합니다. 30명에 선정되시면, 마지막 주 주말에 최종 순위 결정전을 진행하게 되며 그 결과를 통해 최종 순위 시상을 진행합니다.
      </>
    ),
  },
  {
    question: 'Q. 기업 제안을 받으려면 무엇을 해야 하나요?',
    answer: (
      <>
        기한 내에 개발 구현 능력 검사(코딩테스트)와 개발자 역량검사를 모두 응시하고 나면, 기업 추천 대상자로 선정됩니다.
        이에 따라, 기업 인사담당자가 확인하실 수 있도록 간단한 프로필 등록 과정만 거치시면 기업 제안이 시작됩니다.
        프로필에 내 이력과 경험을 구체적으로 작성해주시면 인사담당자들이 제안할 확률이 높아진다는 점 꼭 기억하세요!
      </>
    ),
  },
  {
    question: 'Q. 기업 제안은 무제한인가요?',
    answer: (
      <>
        공고에 따라 실시간으로 기업 인사담당자가 제안을 드리고 있으며, 제안 기업도 실시간으로 늘어나고 있습니다.
        <br />
        제안받지 못하더라도, 공고에 지원하실 수 있으며, 디챔스를 통해 역량검사 결과와 피드백 리포트를 함께 제출하므로
        <br />
        인사담당자가 더욱 주목할 수 있습니다.
        <br />
        <br />
        이외에 매칭 AI와 큐레이터가 적합한 기업을 횟수 제한없이 추천드리고 있습니다.
      </>
    ),
  },
];

interface NoticeData {
  notice: React.ReactElement;
}

export const noticeData: NoticeData[] = [
  {
    notice: (
      <>
        본 대회의 참가 자격에는 제한이 없으나 코드 구현 및 개발능력 중심의 진단이 이뤄지므로,
        <br />
        비개발직군의 경우 진행이 어려울 수 있습니다.
      </>
    ),
  },
  {
    notice: (
      <>
        부정참여 방지를 위해, 디챔스 결과가 현저히 낮거나 부적절한 참여로 판단될 경우
        <br />
        이벤트 당첨대상에서 제한될 수 있습니다.
      </>
    ),
  },
  {
    notice: (
      <>
        피드백 리포트는 개발 구현 능력 검사 버전과 개발자 역량검사(역량검사) 버전 두가지로 구성되어 있으며, 하나만 응시한 경우에도 일부 리포트를 확인하실 수 있습니다.
      </>
    ),
  },
  {
    notice: (
      <>
        한번이라도 결선에서 수상한 경우, 이후 결선에서는 수상할 수
        <br />
        없습니다.
      </>
    ),
  },
];
