import Dialog from '__designkit__/components/Dialog';
import MobileStore from 'store/mobileStore';
import styled, { keyframes } from 'styled-components';

const openAnimation = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
`;

const Frame = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100000;

  & > .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    animation: ${openAnimation} 0.5s 0s 1 ease-out forwards;
  }
`;

const AppUpdateModal = () => {
  return (
    <Frame>
      <div className='wrapper'>
        <Dialog
          head='앱 업데이트'
          body={`새로운 버전이 출시되었습니다.\n업데이트 후 이용해주세요.`}
          buttonProps={
          {
            type: 'alert',
            confirmLabel: '스토어로 이동하기',
            onConfirm: () => MobileStore.moveToStore(),
          }
        }
        />
      </div>
    </Frame>
  );
};

export default AppUpdateModal;
