import RoutePaths from 'consts/RoutePaths';

export interface ContentDetail{
    title:string;
    linkTo:string;
}
export const NavigationList:{header:string, content:ContentDetail[]}[] = [
  {
    header: 'About',
    content: [
      {
        title: '잡다선언문',
        linkTo: RoutePaths.jobda_declaration,
      },
      {
        title: '잡다 소개',
        linkTo: RoutePaths.match_introduce,
      },
      {
        title: '역량검사 소개',
        linkTo: RoutePaths.acca_introcue,
      },
    ],
  },
  {
    header: '잡다 취업',
    content: [
      {
        title: '채용공고',
        linkTo: RoutePaths.calendar,
      },
      {
        title: '채용이벤트',
        linkTo: RoutePaths.challenge,
      },
      {
        title: '지원현황',
        linkTo: RoutePaths.mypage_apply,
      },
      {
        title: '내 프로필',
        linkTo: RoutePaths.myprofile,
      },
    ],
  },
  // PC에서만 가능은 클릭되지 않게 구현
  {
    header: '역량검사',
    content: [
      {
        title: '역량검사 응시 (PC에서만 가능)',
        linkTo: '',
      },
      {
        title: '역량검사 튜토리얼(PC에서만 가능)',
        linkTo: '',
      },
      {
        title: '역량검사 소개',
        linkTo: RoutePaths.acca_introcue,
      },
    ],
  },
  {
    header: '개발자 검사',
    content: [
      {
        title: '개발자 역량검사 (PC에서만 가능)',
        linkTo: '',
      },
      {
        title: '개발자 역량검사 튜토리얼 (PC에서만 가능)',
        linkTo: '',
      },
      {
        title: '개발 구현 능력 검사(PC에서만 가능)',
        linkTo: '',
      },
      {
        title: '개발 구현 능력 검사 튜토리얼(PC에서만 가능)',
        linkTo: '',
      },
    ],
  },
  {
    header: '취업 APP',
    content: [
      {
        title: '기출 면접 연습 (PC에서만 가능)',
        linkTo: RoutePaths.pass_interview,
      },
    ],
  },
  {
    header: '취업 콘텐츠',
    content: [
      {
        title: '취업 콘텐츠',
        linkTo: RoutePaths.info, // initiativeclass 생기면 바꿔주기
      },
    ],
  },
];

export default NavigationList;
