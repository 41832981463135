import styled from 'styled-components';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';

export const StyledChipWelfareUl = styled.ul`
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        list-style: none;
`;
export const StyledChipWelfareLi = styled.li`
        display: flex;
        height: 28px;
        padding: 0px 12px;
        align-items: center;
        border: 1px solid ${colors.CG_50};
        border-radius: 18px;
        margin-right: 4px;
        margin-bottom: 6px;
        span {
            display: inline-block;
            font: ${Fonts.B4_Medium};
            line-height: 18px;    
            color:${colors.JOBDA_BLACK};
        }
            
    `;

export const StyledChiKeywordLi = styled.li`
        cursor:pointer;
        display: flex;
        align-items: center;
        height: 28px;
        padding: 5px 12px;
        background: ${colors.CG_30};
        border-radius: 20px;
        margin-right: 4px;
        margin-bottom: 4px;
        span {
            display: inline-block;
            font: ${Fonts.B4_Medium};
            color:${colors.CG_70}
        }
        svg{
            margin:0px -10px 0px 5px;
        }
            
    `;
