import Axios, { AxiosError, AxiosResponse } from 'axios';
import { configure } from 'axios-hooks';
import RoutePaths from 'consts/RoutePaths';
import URIs from 'consts/URIs';
// import storeBase from 'models/storeBase';
import { globalContext } from 'models/Context';
import config from '../config';
import AuthorizeUtil from './AuthorizeUtil';
import { getRoutePathKey, isSpinnerIgnored } from './PathUtils';

// PC 코드
// query param format 맞춰주는 함수
// 기본 axios param format = a[]=1&a[]=2
// ParamsSerializer format = a=1,2
// [ ]가 encoding 안되는 문제 해결
export const defaultParamsSerializer = (paramObj: Record<string, any>) => {
  // corejs3에서 URLSearchParams polyfill 제공
  const params = new URLSearchParams();
  Object.entries(paramObj)
    .filter(([_, value]) => value !== undefined)
    .forEach(([key, value]) => {
      params.append(key, value);
    });

  return params.toString();
};

let lastHeader: any = {};
const lastFailedOptions: any = {};

const instance = Axios.create({
  baseURL: config.host,
  timeout: -1,
  withCredentials: true,
  headers: { 'Content-Type': 'application/json' },
  validateStatus(status) {
    return status >= 200 && status < 300; // default
  },
});

configure({ axios: instance });

instance.interceptors.request.use(async (requestConfig: any) => {
  const routeMatchKey = getRoutePathKey(document.location.pathname);
  if (routeMatchKey && isSpinnerIgnored(routeMatchKey as keyof typeof RoutePaths, requestConfig.url as string)) {
    requestConfig.ignoreLoading = true;
  }
  if (requestConfig.ignoreLoading === false) {
    globalContext.loading++;
    if (globalContext.loading <= 0)
      globalContext.loading = 1;
  }
  const Authorization = AuthorizeUtil.bearerAccessToken;
  if (Authorization && requestConfig.url !== URIs.post_login) {
    requestConfig.headers = {
      ...requestConfig.headers,
      Authorization,
    };
  }

  return requestConfig;
});

instance.defaults.paramsSerializer = defaultParamsSerializer;

instance.interceptors.response.use((response: AxiosResponse<any> | any) => {
  if (!(response.config as any).ignoreLoading) {
    globalContext.loading--;
    if (globalContext.loading < 0)
      globalContext.loading = 0;
  }
  return response;
}, async (err: AxiosError) => {
  if (!(config as any).ignoreLoading) {
    globalContext.loading--;
    if (globalContext.loading < 0)
      globalContext.loading = 0;
  }
  if ((err.response?.status === 401 || err.response?.status === 500) && err.response.data.errorCode === 'J003') {
    try {
      AuthorizeUtil.updateAccessToken();
    } catch (e) {
      return Promise.reject(err);
    }
    const { headers, ...res } = err.config;
    return await request({ ...res });
  }
  if ((err.response?.status === 401 || err.response?.status === 500) && err.response.data.errorCode === 'J001') {
    try {
      AuthorizeUtil.bearerAccessToken = '';
    } catch (e) {
      return Promise.reject(err);
    }
    const { headers, ...res } = err.config;
    return await request({ ...res });
  }

  return Promise.reject(err);
});

export interface IRequestOptions {
  method: 'get' | 'GET' | 'post' | 'POST' | 'put' | 'PUT' | string;
  url: string;
  data?: any;
  params?: any;
  headers?: any;
  ignoreLoading?: boolean;
  responseType?: string;
  timeout?: number;
  [key: string]: any;
}

export interface IRequestError extends AxiosError {
  message: string;
  status: number;
  errors: string[];
  code: string;
  errorCode: string;
}

const request = <T extends any>(options: IRequestOptions | any) => {
  const onSuccess = (response: AxiosResponse<any>): T => {
    lastHeader = response.headers;
    return response.data;
  };

  const onError = (err: IRequestError) => {
    console.error(err);
    throw err;
  };

  return instance(options)
    .then(onSuccess)
    .catch(onError);
};

export const retryRequest = () => request(lastFailedOptions);

export default request;
export { lastHeader, request };
