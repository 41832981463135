import HalfYearType from 'consts/HalfYearType';
import ProcessType from 'consts/ProcessType';
import RecruitmentType from 'consts/RcruitmentType';

export default ({
  simpleInfoList: [
    {
      sn: 11,
      name: '카카오',
      recruitmentYear: 2021,
      halfYearType: 'SECOND_HALF' as HalfYearType,
      recruitmentType: 'OPEN' as RecruitmentType,
      displayNew: true,
      jobGroupName: '홍보 · 마케팅',
      processTypeList: [
        'PRESENTATION',
        'CODING',
      ] as ProcessType[],
    },
    {
      sn: 12,
      name: '카카오',
      recruitmentYear: 2021,
      halfYearType: 'FIRST_HALF' as HalfYearType,
      recruitmentType: 'OPEN' as RecruitmentType,
      displayNew: true,
      jobGroupName: '영업',
      processTypeList: [
        'PRESENTATION',
      ] as ProcessType[],
    },
    {
      sn: 13,
      name: '카카오',
      recruitmentYear: 2020,
      halfYearType: 'SECOND_HALF' as HalfYearType,
      recruitmentType: 'OCCASIONAL' as RecruitmentType,
      displayNew: true,
      jobGroupName: '건설 엔지니어',
      processTypeList: [
        'PRESENTATION',
      ] as ProcessType[],
    },
    {
      sn: 14,
      name: '카카오',
      recruitmentYear: 2021,
      halfYearType: 'SECOND_HALF' as HalfYearType,
      recruitmentType: 'OCCASIONAL' as RecruitmentType,
      displayNew: true,
      jobGroupName: 'IT 서비스',
      processTypeList: [
        'PRESENTATION',
      ] as ProcessType[],
    },
    {
      sn: 18,
      name: '카카오',
      recruitmentYear: 2021,
      halfYearType: 'FIRST_HALF' as HalfYearType,
      recruitmentType: 'OCCASIONAL' as RecruitmentType,
      displayNew: true,
      jobGroupName: 'IT 서비스',
      processTypeList: [
        'CODING',
      ] as ProcessType[],
    },
    {
      sn: 19,
      name: '카카오',
      recruitmentYear: 2021,
      halfYearType: 'FIRST_HALF' as HalfYearType,
      recruitmentType: 'INTERN' as RecruitmentType,
      displayNew: true,
      jobGroupName: 'IT 서비스',
      processTypeList: [
        'CODING',
      ] as ProcessType[],
    },
    {
      sn: 20,
      name: '카카오',
      recruitmentYear: 2021,
      halfYearType: 'SECOND_HALF' as HalfYearType,
      recruitmentType: 'INTERN' as RecruitmentType,
      displayNew: true,
      jobGroupName: '홍보 · 마케팅',
      processTypeList: [
        'CODING',
      ] as ProcessType[],
    },
    {
      sn: 21,
      name: '카카오',
      recruitmentYear: 2021,
      halfYearType: 'SECOND_HALF' as HalfYearType,
      recruitmentType: 'INTERN' as RecruitmentType,
      displayNew: true,
      jobGroupName: '경영기획 · 지원',
      processTypeList: [
        'EXECUTIVE',
        'EXECUTIVE',
      ] as ProcessType[],
    },
    {
      sn: 26,
      name: '카카오',
      recruitmentYear: 2020,
      halfYearType: 'FIRST_HALF' as HalfYearType,
      recruitmentType: 'OCCASIONAL' as RecruitmentType,
      displayNew: true,
      jobGroupName: '경영기획 · 지원',
      processTypeList: [
        'CODING',
      ] as ProcessType[],
    },
    {
      sn: 27,
      name: '카카오',
      recruitmentYear: 2020,
      halfYearType: 'FIRST_HALF' as HalfYearType,
      recruitmentType: 'INTERN' as RecruitmentType,
      displayNew: true,
      jobGroupName: '고민중',
      processTypeList: [
        'CODING',
        'EXECUTIVE',
        'DISCUSSION',
        'CODING',
        'EXECUTIVE',
        'DISCUSSION',
        'CODING',
        'EXECUTIVE',
        'DISCUSSION',
        'CODING',
        'EXECUTIVE',
        'DISCUSSION',
        'CODING',
        'EXECUTIVE',
        'DISCUSSION',
        'CODING',
        'EXECUTIVE',
        'DISCUSSION',
        'CODING',
        'EXECUTIVE',
        'DISCUSSION',
        'CODING',
        'EXECUTIVE',
        'DISCUSSION',
        'CODING',
        'EXECUTIVE',
        'DISCUSSION',
        'CODING',
        'EXECUTIVE',
        'DISCUSSION',
        'CODING',
        'EXECUTIVE',
        'DISCUSSION',
        'CODING',
        'EXECUTIVE',
        'DISCUSSION',
        'CODING',
        'EXECUTIVE',
        'DISCUSSION',
        'CODING',
        'EXECUTIVE',
        'DISCUSSION',
        'CODING',
        'EXECUTIVE',
        'DISCUSSION',
        'CODING',
        'EXECUTIVE',
        'DISCUSSION',
        'CODING',
        'EXECUTIVE',
        'DISCUSSION',
        'CODING',
        'EXECUTIVE',
        'DISCUSSION',
        'CODING',
        'EXECUTIVE',
        'DISCUSSION',
        'CODING',
        'EXECUTIVE',
        'DISCUSSION',
        'CODING',
        'EXECUTIVE',
        'DISCUSSION',
        'CODING',
        'EXECUTIVE',
        'DISCUSSION',
        'CODING',
        'EXECUTIVE',
        'DISCUSSION',
        'CODING',
        'EXECUTIVE',
        'DISCUSSION',
        'CODING',
        'EXECUTIVE',
        'DISCUSSION',
        'CODING',
        'EXECUTIVE',
        'DISCUSSION',
        'CODING',
        'EXECUTIVE',
        'DISCUSSION',
      ] as ProcessType[],
    },
    // {
    //   sn: 28,
    //   name: '카카오',
    //   recruitmentYear: 2020,
    //   halfYearType: 'FIRST_HALF' as HalfYearType,
    //   recruitmentType: 'OCCASIONAL' as RecruitmentType,
    //   displayNew: true,
    //   jobGroupName: '고민중',
    //   processTypeList: [
    //     'CODING',
    //   ] as ProcessType[],
    // },
    // {
    //   sn: 40,
    //   name: '카카오',
    //   recruitmentYear: 2021,
    //   halfYearType: 'SECOND_HALF' as HalfYearType,
    //   recruitmentType: null as RecruitmentType,
    //   displayNew: true,
    //   jobGroupName: '건설 엔지니어',
    //   processTypeList: [
    //     'CODING',
    //   ] as ProcessType[],
    // },
    // {
    //   sn: 41,
    //   name: '카카오',
    //   recruitmentYear: 2019,
    //   halfYearType: 'FIRST_HALF' as HalfYearType,
    //   recruitmentType: null as RecruitmentType,
    //   displayNew: true,
    //   jobGroupName: 'IT 서비스',
    //   processTypeList: [
    //     'CODING',
    //   ] as ProcessType[],
    // },
    // {
    //   sn: 42,
    //   name: '카카오',
    //   recruitmentYear: 2019,
    //   halfYearType: 'FIRST_HALF' as HalfYearType,
    //   recruitmentType: 'INTERN' as RecruitmentType,
    //   displayNew: true,
    //   jobGroupName: '홍보 · 마케팅',
    //   processTypeList: [] as ProcessType[],
    // },
    // {
    //   sn: 43,
    //   name: '카카오',
    //   recruitmentYear: 2019,
    //   halfYearType: 'SECOND_HALF' as HalfYearType,
    //   recruitmentType: null as RecruitmentType,
    //   displayNew: true,
    //   jobGroupName: '고민중',
    //   processTypeList: [] as ProcessType[],
    // },
    // {
    //   sn: 44,
    //   name: '카카오',
    //   recruitmentYear: 2019,
    //   halfYearType: 'FIRST_HALF' as HalfYearType,
    //   recruitmentType: 'OPEN' as RecruitmentType,
    //   displayNew: true,
    //   jobGroupName: '고민중',
    //   processTypeList: [] as ProcessType[],
    // },
  ],
});
