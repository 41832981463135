/* eslint-disable class-methods-use-this */
import URIs from 'consts/URIs';
import { IAdMainRollingBannerListRs } from 'interfaces/_v2/main/IAdMainBanner';
import request from 'utils/request';

export default interface AdsRepo {
    // 메인배너 텍스트 롤링 광고 조회
    fetchMainBannerTextRolling(): Promise<IAdMainRollingBannerListRs>;
}

export class RemoteAdsRepo implements AdsRepo {
  fetchMainBannerTextRolling(): Promise<IAdMainRollingBannerListRs> {
    return request<IAdMainRollingBannerListRs>({
      method: 'get',
      url: URIs.get_ads_main_banners_rolling,
    });
  }
}
