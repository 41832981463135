import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { JDButton } from 'consts/_v2/_common/style/mixins';
import styled from 'styled-components/macro';

const ErrorFrame = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:flex-start;
  padding: 32px 20px;
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  .frame-main {
    flex:1;
    display:flex;
    padding-top:126px;
    flex-direction:column;
    align-items:center;
    justify-content:flex-start;
  }

  .img {
    width: 100px;
    height: 100px;
    margin-bottom: 32px;
  }
  .title,.desc {
    white-space:pre;
    text-align:center;
  }

  .desc {
    color:${colors.JOBDA_BLACK};
    font: ${Fonts.H5_Bold};
    letter-spacing: -0.01em;
  }
`;

export const ErrorCTAButton = styled.button`
  ${JDButton(false, '120px')};
  height: 40px;
  border-radius:4px;
  font: ${Fonts.B1_Bold};
  margin-top: 42px;
`;

export const ErrorCTAButtonACCA = styled.button`
  ${JDButton(false, '100%')};
  height: 46px;
  border-radius:4px;
  font: ${Fonts.B2_Bold};
`;

export default ErrorFrame;
