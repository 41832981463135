import React, { FC, useEffect, useState } from 'react';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import styled from 'styled-components';
import ImgJoyEmpty from 'assets/_v2/challenge/img_character_joy_empty_soon.png';
import ImgJoyContents from 'assets/_v2/main/img_joy_contents_empty.png';

const Frame = styled.div`
    width:100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;
    .img-joy{
        margin-top: 16px;
        width:96px;
        height:96px;
    }
    .title{
        margin-top: 32px;
        font: ${Fonts.H5_Bold};
        color:${colors.JOBDA_BLACK};
    }
    .subtitle{
        margin-top: 8px;
        font: ${Fonts.B2_Medium_P};
        color:${colors.CG_70}
    }

`;
interface IMainNoPosition {
  contents?:boolean;
}
const MainNoPosition:FC<IMainNoPosition> = ({ contents }) => (
  <Frame>
    <img src={contents ? ImgJoyContents : ImgJoyEmpty} className='img-joy' alt='img' />
    {contents
      ? <div className='title'>새로운 콘텐츠를 준비 중입니다.</div>
      : (
        <>
          <div className='title'>공고를 기다리고 있어요.</div>
          <div className='subtitle'>기업에서 채용을 시작하면 채용 공고를 확인할 수 있어요.</div>
        </>
      )}

  </Frame>
);
export default MainNoPosition;
