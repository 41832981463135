import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import JDALink from 'components/JDALink';
import MyPageBookmarkPositions from 'components/_v2/matchHome/bookmark/MyPageBookmarkPositions';
import ImgBanner from 'assets/_v2/matchHome/img_passboard_event_banner.png';

import useQueryParams from 'hooks/useQueryParams';
import Login from 'models/Login';
import MatchMessageStore from 'models/MatchMessageStore';
import MatchHomeDashboardModel from 'models/_v2/MatchHomeDashboardModel';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import PositionListModel from 'models/_v2/PositionListModel';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import MyPageBookmarkJobs from 'components/_v2/matchHome/bookmark/MyPageBookmarkJobs';
import MyPageBookmarkCompanies from 'components/_v2/matchHome/bookmark/MyPageBookmarkCompanies';
import { useBookmarkStatus } from 'query/bookmark/useBookmarkQuery';

const TabFrame = styled.div`
    display: flex;
    justify-content: space-between;
    padding:12px 16px;
    width: 100%;
    border-bottom: 1px solid ${Colors.CG_40};
`;
const BannerFrame = styled.img`
  width:100%;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
  margin-top: 8px;
`;
const TabEach = styled.div<{selected?:boolean}>`
    display: flex;
    background-color: ${(props) => (props.selected ? `${Colors.CG_30}` : `${Colors.WHITE_100}`)};
    border-radius:4px;
    justify-content:center;
    align-items:center;
    width:100%;
    padding: 13px 0;
    text-align:center;
    .text-frame{
        display:flex;
        flex-direction:column;
        .cnt-number{
            font:${Fonts.H5_Bold};
            color:${(props) => (props.selected ? `${Colors.JOBDA_BLACK}` : `${Colors.CG_60}`)};
        }
        .title-text{
            font:${Fonts.B4_Medium};
            color:${(props) => (props.selected ? `${Colors.JOBDA_BLACK}` : `${Colors.CG_60}`)};
        }    
    }
`;
const Frame = styled.div`
    padding:0 16px 40px 16px;
`;

interface IMyPageBookmark {
    login?: Login;
    positionListModel?:PositionListModel;

  }

enum MyPageBookmarkTabType{
  POSITION = 'POSITION',
  JOB = 'JOB',
  COMPANY = 'COMPANY',
}

const MyPageBookmark:FC<IMyPageBookmark> = ({ login = new Login(), positionListModel = new PositionListModel() }) => {
  const [currentTab, setCurrentTab] = useState(MyPageBookmarkTabType.POSITION);
  const { data: bookmarkStatus } = useBookmarkStatus();

  const tabList = [{
    type: MyPageBookmarkTabType.POSITION,
    count: bookmarkStatus?.positionCount,
    label: '포지션',
  },
  {
    type: MyPageBookmarkTabType.JOB,
    count: bookmarkStatus?.jobPostCount,
    label: '공고',
  },
  {
    type: MyPageBookmarkTabType.COMPANY,

    count: bookmarkStatus?.companyCount,
    label: '관심기업',
  }];
  const handleTabClick = (tabType: MyPageBookmarkTabType) => {
    setCurrentTab(tabType);
  };
  return (
    <>
      <TabFrame>
        {tabList.map((tab) => (
          <TabEach onClick={() => handleTabClick(tab.type)} selected={currentTab === tab.type} key={tab.type}>
            <div className='text-frame'>
              <div className='cnt-number'>{tab.count}</div>
              <div className='title-text'>{tab.label}</div>
            </div>
          </TabEach>
        ))}
      </TabFrame>
      <div className='dashboard-frame'>
        {(currentTab === MyPageBookmarkTabType.POSITION) && <MyPageBookmarkPositions />}
        {(currentTab === MyPageBookmarkTabType.JOB) && <MyPageBookmarkJobs />}
        {(currentTab === MyPageBookmarkTabType.COMPANY) && <MyPageBookmarkCompanies />}
      </div>
    </>
  );
};

export default inject(injectStore.positionListModel)(observer(MyPageBookmark));
