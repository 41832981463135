import imgBookmark from 'assets/_v2/matchHome/icon_bookmark_added.svg';
import imgBubble from 'assets/_v2/matchHome/icon_speech_bubble.svg';
import LazyImage from 'components/common/LazyImage';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import React, { FC } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import RoutePaths from 'consts/RoutePaths';

export enum MatchHomeNotFoundViewType {
  BOOKMARK = 'BOOKMARK',
  WRITING = 'WRITING',
  JOB = 'JOB',
  INTERVIEW = 'INTERVIEW',
  PROGRESS = 'PROGRESS',
  COMPLETED = 'COMPLETED',
  COMPANY = 'COMPANY',
  APPLICATION = 'APPLICATION',
  IN_PROGRESS = 'IN_PROGRESS',
  END = 'END',
  ALL = 'ALL'
}

const StyledMatchHomeNotFoundView = styled.div<{height?:number, content?:MatchHomeNotFoundViewType}>`
    display: flex;
    flex-direction: column;
    width:100%;
    ${(props) => props.height && `
        height:${props.height}px;
        `}
    align-items: center;
    justify-content: center;
    background-color: ${colors.WHITE_100};
    .text{
        font: ${Fonts.B1_Bold};
        color:${colors.CG_90};
        align-self: center;
    }
    margin-bottom:12px;
    
`;

const PositionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content:center;
  padding: 14px 22px;
  border-radius: 4px;
  background-color: ${colors.JOBDA_BLACK};
  color:${colors.WHITE_100};
  margin-top: 16px;
  font:${Fonts.B2_Bold};
`;
interface IMatchHomeNotFoundProps{
    content : MatchHomeNotFoundViewType;
    height? : number;
}

const getMsgType = (content : string) => {
  switch (content) {
    case (MatchHomeNotFoundViewType.BOOKMARK):
      return (
        <>
          <div className='text'>북마크 한 포지션이 없어요.</div>
        </>
      );
    case (MatchHomeNotFoundViewType.JOB):
      return (
        <>
          <div className='text'>북마크 한 공고가 없어요.</div>
        </>
      );
    case (MatchHomeNotFoundViewType.COMPANY):
      return (
        <>
          <div className='text'>북마크 한 기업이 없어요.</div>
        </>
      );
    case (MatchHomeNotFoundViewType.WRITING):
    case (MatchHomeNotFoundViewType.INTERVIEW):
    case (MatchHomeNotFoundViewType.COMPLETED):
    case (MatchHomeNotFoundViewType.IN_PROGRESS):
    case (MatchHomeNotFoundViewType.APPLICATION):
    case (MatchHomeNotFoundViewType.END):
    case (MatchHomeNotFoundViewType.ALL):
      return (
        <>
          <div className='text'>진행 중인 전형이 없습니다</div>
        </>
      );
    case (MatchHomeNotFoundViewType.PROGRESS):
      return (
        <>
          <div className='text'>작성중인 지원서가 없습니다</div>
        </>
      );
    default:
      return '';
  }
};
const getTextType = (content:string) => {
  switch (content) {
    case (MatchHomeNotFoundViewType.JOB):
      return '공고';
    default:
      return '포지션';
  }
};
const MatchHomeNotFound:FC<IMatchHomeNotFoundProps> = ((props) => {
  const { content, height } = props;
  const history = useHistory();
  const handlePositionButton = () => {
    if (content === MatchHomeNotFoundViewType.JOB) {
      history.push(RoutePaths.jobs);
    } else {
      history.push(RoutePaths.position);
    }
  };
  return (
    <StyledMatchHomeNotFoundView content={content} height={height}>
      {getMsgType(content)}
      {
      content !== MatchHomeNotFoundViewType.COMPANY && (
      <PositionButton onClick={handlePositionButton}>{getTextType(content)} 보러가기</PositionButton>
      )
    }

    </StyledMatchHomeNotFoundView>
  );
});

export default MatchHomeNotFound;
