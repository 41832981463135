import React, { FC, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import Icon from '__designkit__/icon/Icon';
import Fonts from '__designkit__/common/fonts';
import Colors from '__designkit__/common/colors';
import IComponentProps from 'interfaces/props/IComponentProps';

const FloatingBanner = styled.div`
  position: fixed;
  bottom: calc(80px + env(safe-area-inset-bottom));
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 32px);
  height: fit-content;
  padding: 14px 0;
  border-radius: 60px;
  background-color: ${Colors.JOBDA_BLACK};
  
  & > :first-child {
    margin-left: 30px;
    font: ${Fonts.B3_Bold};
    color: ${Colors.WHITE_100};
  }
  & > :last-child {
    margin-right: 30px;
  }
`;

interface IProflieFloatingProps extends IComponentProps {
  onClickBanner: () => void;
  login?: Login;

}

const ProfileFloatingBanner:FC<IProflieFloatingProps> = ({ className, onClickBanner, login = new Login() }) => {
  const [isOpenFloatingBanner, setIsOpenFloatingBanner] = useState<boolean>(false);

  useEffect(() => {
    if (login.matchConfigStatus) {
      if (!login.matchConfigStatus.matchingConfigCondition.profileRequiredCompleteYn) setIsOpenFloatingBanner(true);
      else setIsOpenFloatingBanner(false);
    }
  }, [login.matchConfigStatus]);

  return !isOpenFloatingBanner ? <></> : (
    <FloatingBanner className={className} onClick={onClickBanner}>
      <span>
        필수정보 3가지만 작성하고 기업에게
        <br />
        제안을 받아보세요. 👍
      </span>
      <Icon
        name='close'
        size={24}
        color={Colors.WHITE_100}
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          setIsOpenFloatingBanner(false);
        }}
      />
    </FloatingBanner>
  );
};

export default inject(injectStore.login)(observer(ProfileFloatingBanner));
