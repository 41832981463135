import Colors from '__designkit__/common/colors';
import Fonts, { fonts } from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import TabsWithComponent from '__designkit__/tab/TabsWithComponent';
import JDAFullScreenModal from 'components/JDAFullScreenModal';
import MainHeader from 'components/_v2/_common/mainHeader/MainHeader';
import { IJDFullScreenModalProps } from 'components/_v2/_common/modals/JDFullScreenBaseModal';
import { JDBaseInputMixin } from 'consts/_v2/_common/style/mixins';
import { IconSearch } from 'consts/assets/icons/iconPages';
import { PartnershipType } from 'interfaces/myPage/partership/IMyPagePartnership';
import { inject, observer } from 'mobx-react';
import MyPagePartnershipModel from 'models/MyPagePartnershipModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';

interface IMyPagePartershipListModalProps extends IJDFullScreenModalProps {
  myPagePartnershipModel?: MyPagePartnershipModel;
}

const Frame = styled.section`
  height:100%;
  overflow-y:auto;
  padding:0 16px;

  .jd-input{
    margin-top:24px;
    ${JDBaseInputMixin()};
    .input-frame{
    // 돋보기 바깥 padding
      padding:0 16px;
      display:flex;
      align-items:center;
      input{
        padding: 0;
        margin-left:8px;
      }
      img{
        margin-right:6px;
        object-fit: none;
      }
    }
  }

  li{
    position:relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width:100%;
    height:56px;
    ${fonts.NOTO_15_400};
    color:${Colors.CG_90};
    padding: 12px 16px;
    border-bottom: 1px solid ${Colors.CG_40};
    .filter{
      color:${Colors.G_200};
    }
  }
`;

const DescriptionBox = styled.div`
  padding-top: 24px;
  color: ${Colors.CG_80};
  font: ${Fonts.B2_Medium_P};

  & > span {
    font: ${Fonts.B2_Bold};
  }
  
`;
const Banner = styled.div`
  padding: 0 16px;
  
  .banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 36px;
    padding: 0 24px;
    height: 88px;
    background-color: ${Colors.CG_30};
    border-radius: 8px;
    margin: 12px 0;

    .sub-title {
      font: ${Fonts.B2_Medium};
      color: ${Colors.JOBDA_BLACK};
    }

    .main-title {
      margin-top: 4px;
      font: ${Fonts.B1_Bold};
      color: ${Colors.JOBDA_BLACK};
    }
  }
`;

const MyPagePartershipListModal: FC<IMyPagePartershipListModalProps> = ({ isOpen, onClose, myPagePartnershipModel = new MyPagePartnershipModel() }) => {
  const [partnershipType, setPartnershipType] = React.useState<PartnershipType>(PartnershipType.UNIVERSITY);
  const [searchText, setSearchText] = React.useState<string>('');
  const inputRef = React.useRef<HTMLInputElement>(null);
  useEffect(() => {
    const init = async () => {
      await myPagePartnershipModel.searchPartnerShip(partnershipType);
    };
    init();
  }, []);

  const renderList = () => (
    <Frame>
      <DescriptionBox>
        {partnershipType === PartnershipType.UNIVERSITY ? (
          <>
            제휴 대학 소속일 경우, <span>[제휴 대학]</span> 탭에서 소속된 학교명을 검색하여 이메일 인증을 통해 제휴인증을 완료해주시기 바랍니다.
          </>
        ) : (
          <>
            제휴 기관 소속일 경우, 제휴 기관에 문의하시어 인증코드를 부여받은 후, <span>[인증코드 입력]</span> 에 해당 인증코드를 입력하시어 제휴인증을 완료해주시기 바랍니다.
          </>
        )}
      </DescriptionBox>
      <div className='jd-input'>
        <div className='input-frame'>
          <IconSearch className='search-icon' />
          <input
            ref={inputRef}
            name='search-input'
            placeholder={partnershipType === PartnershipType.UNIVERSITY ? '제휴 대학을 검색해 주세요.' : '제휴 기관을 검색해 주세요.'}
            autoComplete='off'
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
        </div>
      </div>
      <ul>
        {
          myPagePartnershipModel.partnership.filter((data) => data.includes(searchText)).map((data) => (
            <li key={`list-item-${data}`} role='presentation'>
              {
                searchText === '' ? <span>{data}</span>
                  : (
                    <>
                      <span className='before'>{data.split(`${searchText}`, 2)[0]}</span>
                      <span className='filter'>{searchText}</span>
                      <span className='last'>{data.split(`${searchText}`, 2)[1]}</span>
                    </>
                  )
              }
            </li>
          ))
        }
      </ul>
    </Frame>
  );

  return (
    <JDAFullScreenModal isOpen={isOpen} onClose={onClose}>
      <MainHeader backable={false} gnb={false} search={false} close onClose={onClose} title='제휴 리스트' alarm={false} />
      <Banner>
        <a href='https://forms.office.com/pages/responsepage.aspx?id=JTLE58C7VEGk4KU2E7crB4LpRDnJY8dOlIrkAwV2owhURVE4WDRYSElMVUxKS1M5Q1Y0QldKUzcwWC4u' target='_blank' className='banner' rel='noreferrer'>
          <div className='text'>
            <span className='sub-title'>나의 학교, 기관이 리스트에 없나요?</span>
            <p className='main-title'>제휴 서비스 제안하기</p>
          </div>
          <Icon size={64} name='handshake' />
        </a>
      </Banner>
      <TabsWithComponent
        tabs={['제휴 대학', '제휴 기관']}
        components={[renderList(), renderList()]}
        onChangeComponent={(index) => {
          myPagePartnershipModel.searchPartnerShip(index === 0 ? PartnershipType.UNIVERSITY : PartnershipType.ORGANIZATION);
          setPartnershipType(index === 0 ? PartnershipType.UNIVERSITY : PartnershipType.ORGANIZATION);
          if (inputRef.current) inputRef.current.value = '';
          setSearchText('');
        }}
      />

    </JDAFullScreenModal>
  );
};

export default inject(injectStore.myPagePartnershipModel)(observer(MyPagePartershipListModal));
