import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import IconTierBronze from 'assets/_v2/phs/pst/icon_pst_tier_bronze.svg';
import IconTierGold from 'assets/_v2/phs/pst/icon_pst_tier_gold.svg';
import IconTierPlatinum from 'assets/_v2/phs/pst/icon_pst_tier_platinum.svg';
import IconTierSilver from 'assets/_v2/phs/pst/icon_pst_tier_silver.svg';
import IconTierTryAgain from 'assets/_v2/phs/pst/icon_pst_tier_try.svg';
import JDALink from 'components/JDALink';
import { Divider12B } from 'components/divider/Divider';
import RoutePaths from 'consts/RoutePaths';
import { PstGradeResultType, PstGradeResultTypeText } from 'consts/_v2/phs/pst/PstApplyType';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import PaccDchampsModel from 'models/_v2/PaccDChampsModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';

const PaccFrame = styled.div``;
const Frame = styled.div`
padding:32px 16px;
`;
const TierProfileBox = styled.div<{tier?:boolean}>`
    display: flex;
    padding:${(props) => (props.tier ? '8px 16px 8px 24px' : '24px 16px 24px 24px')};
    justify-content: space-between;
    border: 1px solid rgba(255,255,255,0.20);
    border-radius: 8px;
    background-color: ${colors.CG_90};
    .flex-box{
        display: flex;
        flex-direction: column;
        align-self: center;
        .sub-title{
            font:${Fonts.B3_Medium};
            color:${colors.CG_60};
            margin-bottom: 8px;
        }
        .icon-text{
          display: flex;
          .title{
            font:${Fonts.H5_Bold};
            color:${colors.JOBDA_WHITE};
        }
        }
        
    }
`;
const ParticipateStatus = styled.div`
  display: flex;
  padding: 24px 16px 24px 24px;
  background-color: ${colors.CG_90};
  border: 1px solid rgba(255,255,255,0.20);
  border-radius: 8px;
  flex-direction: column;
  .divider{
      width: 100%;
      height: 1px;
      background-color: ${colors.CG_70};
      margin: 16px 0;
    }
  .header-frame{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .flex-box-checked{
      display: flex;
      align-items: center;
      width: 64px;
      justify-content: flex-start;
    .complete-text{
      font:${Fonts.B3_Medium};
      color:${colors.WHITE_100}; 
      margin-left: 4px;
    }
    .unComplete-text{
      font:${Fonts.B3_Medium};
      color:${colors.CG_70}; 
      margin-left: 4px;
    }
      }
    }
    .flex-box{
      display: flex;
      align-items: center;
      .divider-height{
        height: 12px;
        width: 1px;
        background-color: ${colors.CG_60};
        margin:0 12px;
      }
    }
    .title-text{
      font:${Fonts.B3_Medium_P};
      color:${colors.CG_60};
    }
    .bold-text{
      font:${Fonts.B1_Bold_P};
      color:${colors.WHITE_100}; 
    }
    .participate-yn{
      padding:4px 6px;
      border-radius: 4px;
      border:1px solid rgba(0,0,0,0.05);
      background-color: #331EBA;
      .participate-text{
        font:${Fonts.B4_Medium};
        color:${colors.WHITE_100}; 
      }
    }
  
`;
const GradeSpan = styled.span<{ grade: PstGradeResultType }>`
  margin-right: 4px;
  color: ${(props) => {
    switch (props.grade) {
      case PstGradeResultType.TRY_AGAIN: return '#9FA4AB';
      case PstGradeResultType.BRONZE: return '#CA8656';
      case PstGradeResultType.SILVER: return '#8592BB';
      case PstGradeResultType.GOLD: return '#C3A81A';
      case PstGradeResultType.PLATINUM: return '#09C195';
      default: return '#9FA4AB';
    }
  }};
`;
interface IPaccGateDChampsTierProfile {
    login?:Login
    paccDChampsModel?:PaccDchampsModel
}

const PaccGateDChampsTierProfile:FC<IPaccGateDChampsTierProfile> = ({ paccDChampsModel = new PaccDchampsModel(), login = new Login() }) => {
  useEffect(() => {
    const init = async () => {
      await paccDChampsModel.loadDChampsGrade(paccDChampsModel.dChampsCurrentMonth || paccDChampsModel.dChampsCurrentFinalsMonth);
      await paccDChampsModel.loadDChampsApplyStatus(paccDChampsModel.dChampsCurrentMonth || paccDChampsModel.dChampsCurrentFinalsMonth);
    };
    init();
  }, []);
  const gradeImage = () => {
    switch (paccDChampsModel.dChampsHighestGrade?.grade) {
      case PstGradeResultType.TRY_AGAIN:
        return IconTierTryAgain;
      case PstGradeResultType.BRONZE:
        return IconTierBronze;
      case PstGradeResultType.SILVER:
        return IconTierSilver;
      case PstGradeResultType.GOLD:
        return IconTierGold;
      case PstGradeResultType.PLATINUM:
        return IconTierPlatinum;
      default:
        break;
    }
    return IconTierTryAgain;
  };

  const getText = (inputText:string) => {
    const lastTwoChars = inputText.slice(-2);
    const formattedLastTwoChars = lastTwoChars.startsWith('0') ? lastTwoChars.slice(-1) : lastTwoChars;
    return formattedLastTwoChars;
  };
  
  return (
    <PaccFrame>

      <Frame>
        <ParticipateStatus>
          <div className='header-frame'>
            <div className='title-text'>{paccDChampsModel.dChampsCurrentMonth ? getText(paccDChampsModel.dChampsCurrentMonth) : 9}월 디챔스 참가 조건</div>
            {paccDChampsModel.dChampsApplyStatus?.paccApplied && paccDChampsModel.dChampsApplyStatus?.pstApplied
            && (
            <div className='participate-yn'>
              <div className='participate-text'>참가완료</div>
            </div>
)}

          </div>
          <div className='divider' />
          <div className='header-frame'>
            <div className='flex-box'>
              <div className='bold-text'>개발자 역량검사</div>
              <div className='divider-height' />
              <div className='title-text'> 역량검사</div>
            </div>

            {paccDChampsModel.dChampsApplyStatus?.paccApplied
              ? (
                <div className='flex-box-checked'>
                  <Icon name='check-circle' size={24} color={colors.G_150} />
                  <div className='complete-text'>완료</div>
                </div>
              )
              : (
                <div className='flex-box-checked'>
                  <Icon name='check-circle' size={24} color={colors.CG_70} />
                  <div className='unComplete-text'>미완료</div>
                </div>
              )}

          </div>
          <SpacingBlock size={8} vertical />
          <div className='header-frame'>
            <div className='flex-box'>
              <div className='bold-text'>개발 구현 능력 검사</div>
              <div className='divider-height' />
              <div className='title-text'> 코딩테스트</div>
            </div>

            {paccDChampsModel.dChampsApplyStatus?.pstApplied
              ? (
                <div className='flex-box-checked'>
                  <Icon name='check-circle' size={24} color={colors.G_150} />
                  <div className='complete-text'>완료</div>
                </div>
)
              : (
                <div className='flex-box-checked'>
                  <Icon name='check-circle' size={24} color={colors.CG_70} />
                  <div className='unComplete-text'>미완료</div>
                </div>
)}

          </div>
        </ParticipateStatus>
        <SpacingBlock size={16} vertical />
        {login.userInfo && paccDChampsModel.dChampsHighestGrade && paccDChampsModel.dChampsHighestGrade.grade
                && (
                  <>
                    <TierProfileBox tier>
                      <div className='flex-box'>
                        <div className='sub-title'>{login.userInfo.name}님의 디챔스 등급은</div>
                        <div className='icon-text'>
                          <div className='title'>
                            <GradeSpan grade={paccDChampsModel.dChampsHighestGrade?.grade}>
                              {PstGradeResultTypeText[paccDChampsModel.dChampsHighestGrade?.grade]}
                            </GradeSpan>
                            입니다.
                          </div>
                        </div>
                      </div>
                      <img src={gradeImage()} alt='emblem' />
                    </TierProfileBox>
                    <SpacingBlock size={16} vertical />
                  </>
                )}

        <JDALink to={RoutePaths.profile}>
          <TierProfileBox>
            <div className='flex-box'>
              <div className='sub-title'>칸을 채울 수록 기업 매칭 확률이 높아져요!</div>
              <div className='icon-text'>
                <div className='title'>내 프로필 입력하기</div>
                <SpacingBlock size={8} />
                <Icon name='open_new' size={24} />
              </div>

            </div>
            <Icon name='resume_separation_blue' size={72} />
          </TierProfileBox>
        </JDALink>
      </Frame>
      <Divider12B />
    </PaccFrame>
  );
};
export default inject(injectStore.login, injectStore.paccDChampsModel)(observer(PaccGateDChampsTierProfile));
