import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { fonts, lineHeights } from '__designkit__/common/fonts';
import { TextAlignProperty } from 'csstype';

const StyledEditableFrame = styled.div`
  border: 1px solid black;
`;

const StyledSettingDiv = styled.div`
  padding: 3px 0;
  font-size: 12px;
  > input {
    width: 36px;
  }
`;

const StyledTitle = styled.div`
  position: relative;
  display: inline-block;
  width: 75px;
  font-size: 12px;
  &:after {
    position: absolute;
    content: ':';
    padding: 0 5px;
    right: 0;
  }
`;

const StyledEditableDiv = styled.div`
  width: 100%;
  height: 100%; 
  
  ${(props) => ((props as any)['data-font'])}
  ${(props) => ((props as any)['data-lineheight'])}
  
  * {
    ${(props) => ((props as any)['data-font'])}
    ${(props) => ((props as any)['data-lineheight'])}
  }
`;

const FontsPage: FC = (() => {
  useEffect(() => {
    // console.log(fonts);
  }, []);

  const [font, setFont] = useState<string>(Object.keys(fonts)[0]);
  const getSelectFontComponent = () => (
    <StyledSettingDiv>
      <StyledTitle>폰트</StyledTitle>
      <select value={font} onChange={(e) => setFont(e.currentTarget.value)}>
        {
          Object.keys(fonts).map((title) => <option value={title}>{title}</option>)
        }
      </select>
    </StyledSettingDiv>
  );

  const [lineHeight, setLineHeight] = useState<string>(Object.keys(lineHeights)[0]);
  const getSelectLineHeightComponent = () => (
    <StyledSettingDiv>
      <StyledTitle>줄간격</StyledTitle>
      <select value={lineHeight} onChange={(e) => setLineHeight(e.currentTarget.value)}>
        {
          Object.keys(lineHeights).map((title) => <option value={title}>{title}</option>)
        }
      </select>
    </StyledSettingDiv>
  );

  const [textAlign, setTextAlign] = useState<TextAlignProperty>('left');
  const getSelectTextAlignComponent = () => (
    <StyledSettingDiv>
      <StyledTitle>정렬</StyledTitle>
      <select value={textAlign} onChange={(e) => setTextAlign(e.currentTarget.value as TextAlignProperty)}>
        <option value='left'>왼쪽</option>
        <option value='center'>중앙</option>
        <option value='right'>오른쪽</option>
      </select>
    </StyledSettingDiv>
  );

  const [paddings, setPaddings] = useState<number[]>([0, 0, 0, 0]);
  const getPaddingComponent = () => {
    const onChangePadding = (e: ChangeEvent<HTMLInputElement>) => {
      const tempPaddings = [...paddings];
      switch (e.currentTarget.name) {
        case 'padding-top':
          tempPaddings[0] = Number(e.currentTarget.value);
          break;
        case 'padding-right':
          tempPaddings[1] = Number(e.currentTarget.value);
          break;
        case 'padding-bottom':
          tempPaddings[2] = Number(e.currentTarget.value);
          break;
        case 'padding-left': default:
          tempPaddings[3] = Number(e.currentTarget.value);
          break;
      }
      setPaddings(tempPaddings);
    };

    return (
      <div>
        <br />
        <StyledSettingDiv>
          padding<br /><br />
          <StyledTitle>top(px)</StyledTitle><input type='number' name='padding-top' value={paddings[0]} onChange={onChangePadding} /><br />
          <StyledTitle>right(px)</StyledTitle><input type='number' name='padding-right' value={paddings[1]} onChange={onChangePadding} /><br />
          <StyledTitle>bottom(px)</StyledTitle><input type='number' name='padding-bottom' value={paddings[2]} onChange={onChangePadding} /><br />
          <StyledTitle>left(px)</StyledTitle><input type='number' name='padding-left' value={paddings[3]} onChange={onChangePadding} /><br />
        </StyledSettingDiv>
      </div>
    );
  };

  return (
    <div style={{ padding: 30 }}>
      {
        getSelectFontComponent()
      }
      {
        getSelectLineHeightComponent()
      }
      {
        getSelectTextAlignComponent()
      }
      {
        getPaddingComponent()
      }
      <StyledEditableFrame
        style={{
          padding: paddings.map((value) => `${value}px`).join(' '),
          textAlign,
        }}
      >
        <StyledEditableDiv
          data-font={
            // @ts-ignore
            fonts[font]
          }
          data-lineheight={
            // @ts-ignore
            lineHeights[lineHeight]
          }
          contentEditable
        >
          여기에 내용을 작성해보세요.
        </StyledEditableDiv>
      </StyledEditableFrame>
    </div>
  );
});

export default FontsPage;
