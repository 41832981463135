import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const GraphContainer = styled.div`
position: relative;
    .card {
  
  width: 100%;
}

.graph {
  width: 100%;
  height: auto;
}

.area,
.line {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: draw 2s ease-in-out infinite;
}

.graphAni {
  animation: gradient 2s linear infinite;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes gradient {
  0% {
    stop-color: #23d69e;
    stop-opacity: 0;
  }
  100% {
    stop-color: #23d69e;

    stop-opacity: 0.5;
  }
}
`;
const CompanyGraphInteraction = () => {
  const [linePath, setLinePath] = useState('');
  const [areaPath, setAreaPath] = useState('');
  useEffect(() => {
    const points = [
      { x: 0, y: 100 },
      { x: 110, y: 120 },
      { x: 230, y: 30 },
      { x: 300, y: 0 },
    ];

    let lineD = `M ${points[0].x} ${points[0].y}`;
    let areaD = `M ${points[0].x} 200 L ${points[0].x} ${points[0].y}`;

    for (let i = 1; i < points.length; i++) {
      const cp1x = (points[i].x + points[i - 1].x) / 2;
      const cp1y = points[i - 1].y;
      const cp2x = (points[i].x + points[i - 1].x) / 2;
      const cp2y = points[i].y;

      lineD += ` C ${cp1x} ${cp1y}, ${cp2x} ${cp2y}, ${points[i].x} ${points[i].y}`;
      areaD += ` C ${cp1x} ${cp1y}, ${cp2x} ${cp2y}, ${points[i].x} ${points[i].y}`;
    }

    areaD += ` L ${points[points.length - 1].x} 200 Z`;

    setLinePath(lineD);
    setAreaPath(areaD);
  }, []);

  return (
    // 매칭 적합도 그래프 인터랙션
    <GraphContainer>
      <div className='card'>
        <div className='card-body'>
          <svg className='graph' viewBox='0 0 300 200'>
            <defs>
              <linearGradient id='gradient' x1='0%' y1='0%' x2='0%' y2='100%'>
                <stop className='graphAni' stopColor='#7BDFC0' stopOpacity='0.5' />
                <stop offset='1' stopColor='#7BDFC0' stopOpacity='0' />
              </linearGradient>
            </defs>
            <path className='area' d={areaPath} fill='url(#gradient)' />
            <path className='line' d={linePath} stroke='#7BDFC0' strokeWidth='2' fill='none' />
          </svg>
        </div>
      </div>
    </GraphContainer>
  );
};

export default CompanyGraphInteraction;
