import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import ImgBook from 'assets/_v2/company/img_matching_book.png';
import JDDescriptionInput from 'components/_v2/_common/input/JDDescriptionInput';
import JDRadioInput, { JD_RADIO_INPUT_TYPE } from 'components/_v2/_common/input/JDRadioInput';
import JDSquareCheckBox from 'components/_v2/_common/input/JDSquareCheckBox';
import JDFullModal from 'components/_v2/_common/modals/JDFullModal';
import { JDModalButton } from 'components/_v2/_common/modals/JDModalComponents';
import { TOAST_TYPE } from 'components/_v2/_common/toast/JDToast';
import ConditionCheckModalContent from 'components/_v2/profile/match/ConditionCheckModalContent';
import MatchAtsFailModal from 'components/modals/MatchAtsFailModal';
import ErrorCode from 'consts/ErrorCodes';
import RoutePaths from 'consts/RoutePaths';
import useToast from 'hooks/useToast';
import { IMatchMessageProps } from 'interfaces/IMatchMessageProps';
import IModalFrameProps from 'interfaces/IModalFrameProps';
import { IMatchAdjustRq, IMatchingRejectRq } from 'interfaces/_v2/matchApply/IMatchingRejectRq';
import { inject, observer } from 'mobx-react';
import MatchMessageStore from 'models/MatchMessageStore';
import CompanyModel from 'models/_v2/CompanyModel';
import MatchApplyStatusModel from 'models/_v2/MatchApplyStatusModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import styled from 'styled-components';

const Frame = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 56px 24px 80px;

      .close-btn {
        position: absolute;
        text-align: right;
        width: 100%;
        top: 16px;
        right: 16px;
    }

    .title {
        font: ${Fonts.H5_Bold};
        color: ${colors.JOBDA_BLACK};
        margin-bottom: 8px;
    }

    .sub-title {
        text-align: center;
        font: ${Fonts.B2_Medium_P};
        color: ${colors.CG_70};
    }
`;
const BannerFrame = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 24px;
  padding:20px 0 20px 24px;
  width: 100%;
  background: linear-gradient(90deg, #4BD667 0%, #40CCC6 100%);
  border-radius: 8px;
  .title-text{
    font:${Fonts.B1_Bold};
    color:${colors.WHITE_100};
    margin-bottom: 12px;
  }
  .sub-text{
    font:${Fonts.B3_Medium_P};
    color:${colors.WHITE_100};
  }
  .img{
    position: absolute;
    bottom:-68px;
    right:-21px;
    width: 183px;
  }
`;

const OptionFormFrame = styled.div`
    margin: 32px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .jd-input {
        margin-bottom: 24px;

        .label {
            font: ${Fonts.B2_Medium};
            color: ${colors.CG_90};
        }
    }

    .check-frame {
        display: flex;
        align-items: center;
        padding-left: 24px;

        .jd-input {
          margin-right: 32px;
          font: ${Fonts.B2_Medium};
          color: ${colors.CG_90};
        }
    }
`;

const ButtonFrame = styled.div`
    position: fixed;
    padding: 16px;
    bottom: calc(env(safe-area-inset-bottom));
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: ${colors.WHITE_100};
    border-top: 1px solid ${colors.CG_40};
`;

interface IMatchOfferChangeInterviewModalProps extends IModalFrameProps {
  positionSn: number;
  optionSn:number;
}

export enum RejectReasonType {
  INDUSTRY_OR_JOB_TITLE = 'INDUSTRY_OR_JOB_TITLE',
  WORKING_PREFERENCE = 'WORKING_PREFERENCE',
  JOB_SEARCH = 'JOB_SEARCH',
  INDIVIDUAL_REASON = 'INDIVIDUAL_REASON',
  CONTACT_ANOTHER_COMPANY = 'CONTACT_ANOTHER_COMPANY',
  OTHER = 'OTHER'
}

export enum RejectTextType {
  INDUSTRY_OR_JOB_TITLE = '관심있는 업종, 직무가 아니에요.',
  WORKING_PREFERENCE = '제가 원하는 근로조건이 아니에요.',
  JOB_SEARCH = '당장 취업/이직할 의사가 없어요',
  INDIVIDUAL_REASON = '개인적인 사정으로 면접 참여가 어려워졌습니다.',
  CONTACT_ANOTHER_COMPANY = '다른 기업과 처우 협의 중입니다.',
  OTHER = '직접 입력'

}
export enum RejectSubType{
  SALARY = 'SALARY',
  WELFARE = 'WELFARE',
  LOCATION = 'LOCATION',
}
export enum RejectSubTypeText{
  SALARY = '연봉',
  WELFARE = '복지',
  LOCATION = '근무지',
}
const MatchOfferChangeInterviewModal: FC<IMatchOfferChangeInterviewModalProps & IMatchMessageProps> = (props) => {
  const { onClickClose, matchMessageStore = new MatchMessageStore(), positionSn, optionSn } = props;
  const useformed = useForm({ mode: 'onSubmit' });
  const { watch } = useformed;
  const [atsFailModalOpen, setAtsFailModalOpen] = useState<boolean>(false);

  const onSubmit = async () => {
    const rq:IMatchAdjustRq = {
      optionSn,
      adjustReason: watch('rejectReason'),
    };
    await matchMessageStore.AdjustInterview(rq, positionSn);
    await matchMessageStore.loadMessageList(matchMessageStore.positionSn);
    if (onClickClose) onClickClose();
  };

  return (
    <JDFullModal>
      <FormProvider {...useformed}>
        <Frame>
          <Icon name='close' className='close-btn' size={32} onClick={onClickClose} />
          <div className='title'>다른 일정에 면접을 보고싶나요??</div>
          <div className='sub-title'>면접 일정을 조율할 수 있는지<br />
            인사담당자님께 전달해 드릴게요.
          </div>
          <JDDescriptionInput
            key='rejectReason'
            name='rejectReason'
            placeholder='원하는 면접 일정을 알려주세요.'
            height={210}
            autoFocus={false}
            maxLength={100}
          />
          <ButtonFrame>
            <JDModalButton
              disabled={!watch('rejectReason')}
              onClick={onSubmit}
            >확인
            </JDModalButton>
          </ButtonFrame>

        </Frame>
      </FormProvider>
      <MatchAtsFailModal
        isOpen={atsFailModalOpen}
        onClickClose={() => setAtsFailModalOpen(false)}
      />

    </JDFullModal>
  );
};

export default inject(injectStore.matchMessageStore, injectStore.matchApplyStatusModel, injectStore.companyModel)(observer(MatchOfferChangeInterviewModal));
