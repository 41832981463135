import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import { PhsAccType } from 'consts/MatchingMessageType';
import { ProfileType } from 'consts/_v2/profile/ProfileType';
import IMatchApplyProps from 'interfaces/_v2/matchApply/IMatchApplyProps';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import MatchApplyModel from 'models/_v2/MatchApplyModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
    display: flex;
    align-items: center;
    font: ${Fonts.B2_Medium};
    color: ${colors.CG_70};
    .complete-count{
        color: ${colors.G_200};
    }
`;

interface IMatchStatusProps {
  color: typeof colors[keyof typeof colors];
  children: React.ReactNode;
}

const MatchStatus: React.FC<IMatchStatusProps> = ({ color, children }) => (
  <>
    <Icon name='verified-filled' size={32} color={color} />
    {children}
  </>
);

interface IMatchApplyCardTagProps extends IMatchApplyProps{
  jobConditionYn: boolean;
  currentProfileType: ProfileType;
}

const MatchApplyCardTag: FC<IMatchApplyCardTagProps> = ({ jobConditionYn, currentProfileType, matchApplyModel = new MatchApplyModel(), login = new Login() }) => {
  const [completeCount, setCompleteCount] = useState(0);
  const totalCount = currentProfileType === ProfileType.GENERAL ? 2 : 3;

  useEffect(() => {
    checkCompleteCount();
  }, [matchApplyModel.profile, matchApplyModel.applyResults, currentProfileType]);

  const checkCompleteCount = () => {
    let count = 0;

    if (matchApplyModel.profile && matchApplyModel.profile.profileRequiredCompleteYn) {
      count++;
    }

    if (matchApplyModel.applyResults) {
      const { applyResults } = matchApplyModel;
      const isGeneral = currentProfileType === ProfileType.GENERAL;
      if (isGeneral) {
        if (applyResults.some((result) => result.applyType === PhsAccType.ACC)) count++;
      } else {
        if (applyResults.some((result) => result.applyType === PhsAccType.PACC)) count++;
        if (applyResults.some((result) => result.applyType === PhsAccType.PST)) count++;
      }
    }

    setCompleteCount(count);
  };
  return (
    <Frame>
      {!jobConditionYn ? (
        <MatchStatus color={colors.CG_50}>매칭 대기중</MatchStatus>
      ) : (
        completeCount < totalCount ? (
          <MatchStatus color={colors.CG_50}>
            매칭 준비중 (<span className='complete-count'>{completeCount}</span>/{totalCount})
          </MatchStatus>
        ) : login.matchConfigStatus?.matchingConfigYn ? (
          <MatchStatus color={colors.B_90}>제안 받는 중</MatchStatus>
        ) : (
          <MatchStatus color={colors.G_150}>매칭 준비완료</MatchStatus>
        )
      )}
    </Frame>
  );
};

export default inject(injectStore.matchApplyModel, injectStore.login)(observer(MatchApplyCardTag));
