import URIs from 'consts/URIs';
import { AccJobPostingItemDto, IAccJobPostingListRs } from 'interfaces/_v2/main/IAccPositions';
import { IAdMainBannerDto, IAdMainBannerListRs } from 'interfaces/_v2/main/IAdMainBanner';
import { CurationTitleType, IAdCurationCategoryDto, IAdCurationCategoryListRs, IAdCurationJobPostingDto, IAdCurationJobPostingListRs, IAdRecommendJobPostingDto, IAdRecommendJobPostingListRs, IAdsTitle } from 'interfaces/_v2/main/IJobPostings';
import { IMatchingCompaniesRs, IMatchingCompanyDto } from 'interfaces/_v2/main/IMainProps';
import { action, observable } from 'mobx';
import request from 'utils/request';
import { IPositionJobListCardRs, PositionJobCardDto } from 'interfaces/_v2/main/IPositionsJob';

export default class Main {
    @observable positions: PositionJobCardDto[] = []
    @observable accPositions : AccJobPostingItemDto[] = []
    @observable jobPostingPositions : IAdRecommendJobPostingDto[] = []
    @observable curationCategories: IAdCurationCategoryDto[] = []
    @observable curationPositions: IAdCurationJobPostingDto[]= []
    @observable mainAds: IAdMainBannerDto[] = []
    @observable selectedCategoryId:number = 1;
    @observable accTitle :string = '';
    @observable curationTitle:string = '';
    @observable recommendTitle: string = '';
    @observable matchCompanies: IMatchingCompanyDto[] = [];

    @action
    fetchAdsMainBanners = (data: IAdMainBannerListRs) => {
      this.mainAds = data.adsMainBanners;
    }

    @action
    fetchMatchCompany = (data: IMatchingCompaniesRs) => {
      this.matchCompanies = data.matchingCompanies;
    }

    @action
    loadJobPostingsAdsTitle = async (type:CurationTitleType) => {
      try {
        const res = (await request<IAdsTitle>({
          method: 'get',
          url: URIs.get_job_postings_ads_title,
          params: { type },
        }));
        if (type === CurationTitleType.ACC_SCREENING_JOB_POSTING) {
          this.accTitle = res.title;
        } else if (type === CurationTitleType.CURATION_JOB_POSTING) {
          this.curationTitle = res.title;
        } else if (type === CurationTitleType.RECOMMEND_JOB_POSTING) {
          this.recommendTitle = res.title;
        }
      } catch (e) {
        console.error(e);
      }
    }

    // @action
    // loadAdsMainBanner = async () => {
    //   try {
    //     const res = (await request<IAdMainBannerListRs>({
    //       method: 'get',
    //       url: URIs.get_ads_main_banners,
    //     }));
    //     this.mainAds = res.adsMainBanners;
    //   } catch (e) {
    //     console.error(e);
    //   }
    // }

    @action
    loadPositionJobGroups = async (jobGroupCode:number, pageSize:number, hide?:boolean) => {
      try {
        const res = (await request<IPositionJobListCardRs>({
          method: 'get',
          url: URIs.get_position_job_groups,
          params: { jobGroupCode, pageSize, hide },
        }));
        this.positions = res.positions;
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadPositionJobGroupsReturn = async (jobGroupCode:number, pageSize:number, hide?:boolean) => {
      try {
        const res = (await request<IPositionJobListCardRs>({
          method: 'get',
          url: URIs.get_position_job_groups,
          params: { jobGroupCode, pageSize, hide },
        }));
        return res.positions;
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadAccPostings = async (pageSize:number) => {
      try {
        const res = (await request<IAccJobPostingListRs>({
          method: 'get',
          url: URIs.get_job_postings_acc,
          params: { pageSize },
        }));
        this.accPositions = res.jobPostings;
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadJobPostingsAdsRecommend = async (page:number, size:number, hide?:boolean) => {
      try {
        const res = (await request<IAdRecommendJobPostingListRs>({
          method: 'get',
          url: URIs.get_job_postings_ads_recommend,
          params: { page, size, hide },
        }));
        this.jobPostingPositions = res.jobPostings;
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadJobPostingAdsCurationCategories = async () => {
      try {
        const res = (await request<IAdCurationCategoryListRs>({
          method: 'get',
          url: URIs.get_job_postings_ads_curation_categories,
        }));
        this.curationCategories = res.categories;
        if (res.categories && res.categories.length > 0) {
          this.selectedCategoryId = res.categories[0].sn;
        }
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadJobPostingAdsCuration = async (curationCategorySn:number) => {
      try {
        const res = (await request<IAdCurationJobPostingListRs>({
          method: 'get',
          url: URIs.get_job_postings_ads_curation,
          params: { curationCategorySn },
        }));
        this.curationPositions = res.jobPostings;
      } catch (e) {
        console.error(e);
      }
    }
}
