import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import IMatchApplyProps from 'interfaces/_v2/matchApply/IMatchApplyProps';
import { inject, observer } from 'mobx-react';
import MatchApplyModel from 'models/_v2/MatchApplyModel';
import { injectStore } from 'models/store';
import React, { FC } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
    width: 100%;
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    border: 1px solid ${colors.CG_50};
    border-radius: 8px;
    margin-bottom: 12px;
`;

const TopWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title-frame {
        display: flex;
        align-items: center;

        .title {
            font: ${Fonts.B1_Bold};
            line-height: 32px;
            color: ${colors.JOBDA_BLACK};

        }
    }
`;

const ContentWrap = styled.div`
    display: flex;
    align-items: center;

    span {
        font: ${Fonts.B2_Medium};
        color: ${colors.CG_70};
    }
`;

const ConditionInsuff = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ProfileGraphFrame = styled.div<{ percent: number}>`
  width: 100%;
  height: fit-content;
  padding: 24px;
  border-radius: 8px;
  background-color: ${colors.G_30};

  & > span {
    display: block;
    margin-bottom: 8px;
    font: ${Fonts.B2_Bold};
    color: ${colors.G_200};
  }

  & > div {
    position: relative;
    width: 100%;
    height: 1px;
    background: ${colors.CG_40};
    margin: 16px 0;
    height: 6px;
    border-radius: 3px;
    background-color: ${colors.WHITE_100};
    overflow: hidden;

    & > div {
      position: absolute;
      top: 0;
      left: 0;
      width: ${({ percent }) => percent}%;
      height: 6px;
      border-radius: 4px;
      background-color: ${colors.G_150};
    }
  }
`;

const ResultFrame = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .date {
      font: ${Fonts.B2_Medium};
      color: ${colors.JOBDA_BLACK};
      margin-left: 6px;
  }
`;

interface IMatchApplyCardConditionBoxProps extends IMatchApplyProps {
  conditionYn: boolean; // 조건 충족 여부
  profileYn?: boolean; // 프로필 카드 분기
  title: string;
  subTitle: string;
  handleClick?: () => void;
}

interface TitleFrameProps {
  title: string;
  showIcon?: boolean;
}

const TitleFrame: FC<TitleFrameProps> = ({ title, showIcon = true }) => (
  <TopWrap>
    <div className='title-frame'>
      <span className='title'>{title}</span>
      {showIcon && <Icon name='arrow-right' size={32} />}
    </div>
  </TopWrap>
);

const MatchApplyCardConditionBox: FC<IMatchApplyCardConditionBoxProps> = ({ handleClick, conditionYn, title, subTitle, profileYn, matchApplyModel = new MatchApplyModel() }) => {
  return (
    <Frame onClick={handleClick}>
      {conditionYn
        ? profileYn ? (
          // 조건 충족, 프로필 카드 일 때
          <>
            <TopWrap>
              <TitleFrame title={title} showIcon />
              <Icon name='check-circle-filled' size={32} color={colors.G_150} />
            </TopWrap>
            <SpacingBlock size={6} vertical />
            <ProfileGraphFrame percent={matchApplyModel.profile?.completionRate || 0}>
              <span>{ matchApplyModel.profile?.completionRate || 0 }% 완성</span>
              <div>
                <div />
              </div>
            </ProfileGraphFrame>
          </>
        ) : (
        // 조건 충족, 역량검사 결과표 카드
          <ResultFrame>
            <div>
              <TopWrap>
                <TitleFrame title={title} showIcon={false} />
              </TopWrap>
              <ContentWrap>
                <span>응시완료</span>
                <span className='date'>{subTitle}</span>
                <Icon name='arrow-right' size={32} />
              </ContentWrap>

            </div>

            <Icon name='check-circle-filled' size={32} color={colors.G_150} />
          </ResultFrame>
        )
        : (
          <ConditionInsuff>
            <div>
              <TopWrap>
                <TitleFrame title={title} showIcon={false} />
              </TopWrap>
              <ContentWrap>
                <span>{subTitle}</span>
                <Icon name='arrow-right' size={32} />
              </ContentWrap>
            </div>
            <Icon name='error' color={colors.ERROR} size={32} />
          </ConditionInsuff>
        )}
    </Frame>
  );
};

export default inject(injectStore.matchApplyModel)(observer(MatchApplyCardConditionBox));
