import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import DchampsBanner from 'assets/_v2/phs/img_dchamps_banner.png';
import spinner from 'assets/lottie/pacc_gate_rocket.json';
import spinnerTwo from 'assets/lottie/pacc_gate_rocket_green.json';
import JDALink from 'components/JDALink';
import { DateUtil } from 'components/_v2/_common/input/datePicker/DateUtil';
import RoutePaths from 'consts/RoutePaths';
import { inject, observer } from 'mobx-react';
import PaccDchampsModel from 'models/_v2/PaccDChampsModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import LottieFile from 'utils/LottieFile';

const Frame = styled.div`
    .banner{
        width: 100%;
        height: auto;
    }
`;
const DeadLineFrame = styled.div<{finals?:boolean}>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 56px;
    background-color: ${(props) => (props.finals ? `${colors.B_30}` : `${colors.S_30}`)};
    .lottie-rocket{
        width: 72px;
        height: 72px;
    }
    .sub-title{
        font:${Fonts.B4_Bold_P};
        color:${(props) => (props.finals ? `${colors.B_100}` : `#0C726F`)};
    }
    .d-date-text{
        font:${Fonts.B1_Bold};
        color:${(props) => (props.finals ? `${colors.B_100}` : `#0C726F`)};
        margin-right: 16px;
    }
`;
interface IPaccGateDchampsBanner {
  paccDChampsModel?:PaccDchampsModel
}
const PaccGateDchampsBanner:FC<IPaccGateDchampsBanner> = ({ paccDChampsModel = new PaccDchampsModel() }) => {
  const [timeValue, setTimeValue] = useState<string>('');
  useEffect(() => {
    const init = async () => {
    };
    init();
  }, []);

  useEffect(() => {
    if (!paccDChampsModel.dChampsCountDate) return;
    const interval = setInterval(() => {
      const countDate = paccDChampsModel.dChampsCountDate;
      if (!countDate) {
        clearInterval(interval);
        return;
      }
      setTimeValue(DateUtil.dateDiffFormat(DateUtil.getNow(), countDate, 'dd일 HH시간 mm분 ss초'));
    }, 1000);
  }, [paccDChampsModel.dChampsCountDate]);

  const getText = () => {
    let month = 9;
    let qualifiersOrFinals = '';
    let startOrEnd = '';
    if (paccDChampsModel) {
      month = paccDChampsModel.dChampsFinalsYn ? parseInt(paccDChampsModel.dChampsCurrentFinalsMonth.slice(-2), 10) : parseInt(paccDChampsModel.dChampsCurrentMonth.slice(-2), 10);
      qualifiersOrFinals = paccDChampsModel.dChampsFinalsYn ? '본선' : '예선';
      startOrEnd = paccDChampsModel.dChampsFinalsYn ? (paccDChampsModel.dChampsFinalsRunning ? '마감' : '시작') : (paccDChampsModel.dChampsRunning ? '마감' : '시작');
    }
    return `${month}월 디챔스 ${qualifiersOrFinals} ${startOrEnd}까지`;
  };

  return (
    <Frame>
      {paccDChampsModel.dChampsCountDate && (
      <DeadLineFrame finals={paccDChampsModel.dChampsFinalsYn}>
        <LottieFile key={`${paccDChampsModel.dChampsFinalsYn}-spinner`} animationData={paccDChampsModel.dChampsFinalsYn ? spinner : spinnerTwo} className='lottie-rocket' />
        <div className='sub-title'>{getText()}</div>
        <SpacingBlock size={8} />
        <div className='d-date-text'>{timeValue}</div>
      </DeadLineFrame>
      )}
      <JDALink to={RoutePaths.jobda_dchamps_event}>
        <img alt='' src={DchampsBanner} className='banner' />
      </JDALink>
    </Frame>
  );
};

export default inject(injectStore.paccDChampsModel)(observer(PaccGateDchampsBanner));
