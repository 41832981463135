import Icon from '__designkit__/icon/Icon';
import ImgSample from '__pc__/page/eventMailProfile/@assets/sampleImg.png';
import JDFullModal from 'components/_v2/_common/modals/JDFullModal';
import JDFullModalHeader from 'components/_v2/_common/modals/JDFullModalComponents';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { FC } from 'react';

interface ISampleFullModalProps extends IComponentProps {
    isOpen: boolean;
    onClose: () => void;

  }

const SampleFullModal:FC<ISampleFullModalProps> = ({ isOpen, onClose }) => (
  !isOpen ? <></> : (
    <JDFullModal isOpen={isOpen} onClose={onClose}>
      <JDFullModalHeader>
        <Icon name='close' size={32} onClick={onClose} />
      </JDFullModalHeader>
      <img src={ImgSample} alt='' />

    </JDFullModal>
  ));
export default SampleFullModal;
