export const copyText = (text: string) => {
  const isiOSDevice = navigator.userAgent.match(/ipad|iphone/i);
  if (isiOSDevice) {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    textarea.setSelectionRange(0, 9999);
    document.execCommand('copy');
    document.body.removeChild(textarea);
  } else {
    const listener = (e:ClipboardEvent) => {
      if (!e?.clipboardData)
        throw new Error('not found event');
      e.clipboardData.setData('text/plain', text);
      e.preventDefault();
    };
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
  }
};

export default ({ copyText });
