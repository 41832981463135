import React, { FC } from 'react';
import styled from 'styled-components';
import TermsModel from 'models/TermsModel';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import JDFullModal from 'components/_v2/_common/modals/JDFullModal';
import JDFullModalHeader from 'components/_v2/_common/modals/JDFullModalComponents';
import Fonts, { fontStyle } from '__designkit__/common/fonts';
import Colors from '__designkit__/common/colors';
import { importantify } from 'consts/style/mixins';

const TermMainText = styled.div`
  padding: 16px;

  // TODO : 일단 기존에 동의문에서 쓰던 스타일을 그대로 가져옴, 재정립 필요
  p {
    font: ${importantify(Fonts.B2_Medium)};
    margin-left:0 !important;
    margin-top:0 !important;
    margin-bottom:0 !important;
    word-break:break-all !important;
    line-height: 1.8em !important;
    word-break: break-all;
  }
  p span {
    font: ${importantify(Fonts.B2_Medium)};
  }
  p b span,p b {
    ${importantify(fontStyle.BODY1_REGULAR)};
    margin-bottom:16px !important;
  }
  table {
    border:none !important;
    width: 100% !important;
  }
  table tr td {
    border: 1px solid ${Colors.CG_40} !important;
    color:${Colors.CG_70} !important;
    padding: 11px 10px !important;
    p {
      font: ${importantify(Fonts.B2_Medium)}; 
    }
    /* b { color:${Colors.CG_90}; } */
  }
  table tr {
    border:none !important;
  }
  table tr:not(:first-child) td:first-child {
    border-left:none !important;
    p {
      /* ${importantify(fontStyle.CAPTION1_SEMIBOLD)}; */
    }
    b { color:${Colors.CG_70} !important; }
  }
  table tr td:last-child {
    border-right:none !important;
  }
  table tr:first-child td {
    padding: 11px 0 !important;
    border:none !important;
  }
  table tr:first-child td, table tr:first-child td * {
    background:${Colors.CG_40} !important;
    color:${Colors.JOBDA_BLACK} !important;
    ${importantify(fontStyle.CAPTION1_SEMIBOLD)};
  }
  u span b {
    font: ${importantify(Fonts.B2_Medium)};
    font-weight:bold !important;
  }
`;

interface ITermDetailModalProps {
  termsModel?: TermsModel;
}

const TermDetailModal: FC<ITermDetailModalProps> = ({ termsModel = new TermsModel() }) => (termsModel.termDetail === null ? <></> : (
  <JDFullModal handleClose={() => termsModel.setTermDetail(null)}>
    <JDFullModalHeader>
      { termsModel.termDetail!.title }
    </JDFullModalHeader>
    <TermMainText dangerouslySetInnerHTML={{ __html: termsModel.termDetail!.contents || '' }} />
  </JDFullModal>
));

export default inject(injectStore.termsModel)(observer(TermDetailModal));
