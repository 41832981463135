import JDCheckInput, { JD_CHECK_INPUT_TYPE } from 'components/_v2/_common/input/JDCheckInput';
import IconClose from 'assets/icon/icon_close_24.svg';
import ImgAgreeInfo from 'assets/_v2/resume/Img_resume_agree_info.png';
import JDBaseModal, { IJDBaseModal } from 'components/_v2/_common/modals/JDBaseModal';
import { JDModalBasicFrame, JDModalButton, JDModalDescription, JDModalTitle } from 'components/_v2/_common/modals/JDModalComponents';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import ResumeModel from 'models/_v2/ResumeModel';
import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { windowOpenUtils } from 'utils/_v2/windowOpenUtils';
import TextUtil from 'utils/TextUtil';

const Frame = styled.div`
    .icon-close{
        position: absolute;
        top: 20px;
        right: 20px;
    }

  ${JDModalBasicFrame} {
    display: flex;
    align-items: center;
  }
  ${JDModalTitle} {
    margin-bottom: 20px;
  }
  ${JDModalDescription} {
    margin-top: 8px;
    margin-bottom: 12px;
  }

  .jd-input {
    margin-top: 12px;

    .label {
        >div {
            font: ${Fonts.B3_Medium_P};
            color: ${colors.JOBDA_BLACK};
        }

        .button {
            font: ${Fonts.B3_Medium};
            color: ${colors.CG_70};
            text-decoration: underline;
            margin-left: 12px;
        }
    }
  }

  ${JDModalButton} {
    margin-top: 20px;
  }
`;

interface IResumeAgreeModalProps extends IJDBaseModal{
    positionSn: number;
    resumeModel?: ResumeModel;
}

const DashboardResumeAgreeModal:FC<IResumeAgreeModalProps> = ({ isOpen, onSuccess, onClickClose, positionSn, resumeModel = new ResumeModel() }) => {
  const useFormed = useForm({ mode: 'onSubmit' });
  const { watch } = useFormed;

  const onClickInformation = async () => {
    await resumeModel.getResumeAgreeUrl(positionSn);
    const url = `${resumeModel.resumeAgreeUrl}/thirdparty-agreement`;
    windowOpenUtils(TextUtil.formatWithProtocolHttp(url), 'thirdparty');
  };
  return (
    <JDBaseModal dimmed isOpen={isOpen} onClickClose={onClickClose}>
      <Frame>
        <FormProvider {...useFormed}>
          <JDModalBasicFrame>
            <button className='icon-close' onClick={() => onClickClose()}><img src={IconClose} alt='icon-close' /></button>
            <JDModalTitle>지원서 확인을 위한<br />동의가 필요해요</JDModalTitle>
            <img src={ImgAgreeInfo} alt='img' />
            <JDCheckInput
              type={JD_CHECK_INPUT_TYPE.CHECK_BOX}
              name='agree'
              value
              label={<><div>(필수) 지원서 저장하기 서비스에 대한 개인정보 제3자 제공 동의<button className='button' onClick={onClickInformation}>자세히</button></div></>}
            />
            <JDModalButton disabled={!watch('agree')} onClick={onSuccess}>동의하기</JDModalButton>
          </JDModalBasicFrame>
        </FormProvider>
      </Frame>
    </JDBaseModal>
  );
};

export default DashboardResumeAgreeModal;
