import ProfileItemTitle from 'components/_v2/profile/ProfileItemTitle';
import ProfileListCard from 'components/_v2/profile/ProfileListCard';
import { Divider1G40 } from 'components/divider/Divider';
import { IProfilePrizeRqRs } from 'interfaces/_v2/profile/IProfilePrizeRqRs';
import React, { FC } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
`;

const ProfilePreviewPrize: FC<{ data: IProfilePrizeRqRs}> = ({ data }) => {
  const { prizes } = data;
  return (
    <Frame>
      <ProfileItemTitle icon='diamond' text='수상내역' />
      { prizes.map((card, index) => (
        card.institution && <ProfileListCard key={`card-list${card.institution}`} institution={card.institution} date={card.date} name={card.name} description={card.description} index={index} length={prizes.length} />
      ))}
      <Divider1G40 />
    </Frame>
  );
};

export default ProfilePreviewPrize;
