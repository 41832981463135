import Fonts from '__designkit__/common/fonts';
import colors from '__designkit__/common/colors';
import IJobsProps from 'interfaces/_v2/jobs/IJobsProps';
import { inject, observer } from 'mobx-react';
import JobsModel from 'models/_v2/JobsModel';
import { injectStore } from 'models/store';
import AutoHeightIFrame from 'pages/_v2/position/AutoHeightIframe';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { iframePinchZoomUtil } from 'utils/_v2/iframePinchZoomUtils';

const Frame = styled.div`

    .title {
        font: ${Fonts.H5_Bold};
        color: ${colors.JOBDA_BLACK};
        margin-bottom: 16px;
        padding: 0 16px;
    }


    .iframe-wrap {
      padding: 0 16px;
    }
`;

const JobsJd: FC<IJobsProps> = ({ jobsModel = new JobsModel() }) => {
  const [fullDocument, setFullDocument] = useState('');

  useEffect(() => {
    if (jobsModel.jobPosting?.content) {
      setFullDocument(iframePinchZoomUtil(jobsModel.jobPosting?.content));
    }
  }, [jobsModel.jobPosting?.content]);

  return (
    <Frame>
      <p className='title'>채용 공고</p>
      <div className='iframe-wrap'>
        <AutoHeightIFrame srcDoc={fullDocument} />
      </div>
    </Frame>
  );
};

export default inject(injectStore.jobsModel)(observer(JobsJd));
