import PositionWelfareDetailCard from 'components/_v2/positionJd/PositionWelfareDetailCard';
import Fonts from '__designkit__/common/fonts';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import PositionJdModel from 'models/_v2/PositionJdModel';
import React, { FC } from 'react';
import styled from 'styled-components';
import JDFullScreenBaseModal from '../_common/modals/JDFullScreenBaseModal';

// TODO: 디자인 가이드 나오면 상세 디자인 수정 필요

const Title = styled.div`
    width: 100%;
    height: 35px;
    margin-top: 56px;
    display: flex;
    .text{
      margin-left: 16px;
      font: ${Fonts.H5_Bold};
    }
`;

interface IPositionWelfareProps{
     positionJdModel? : PositionJdModel
     isOpen?:boolean;
     onClose?:() => void
}

const PositionWelfareModal :FC<IPositionWelfareProps> = ({ positionJdModel = new PositionJdModel(), isOpen, onClose }) => {
  const { additionalInfo } = positionJdModel;

  return (
    <>
      <JDFullScreenBaseModal isOpen={isOpen} onClose={onClose}>
        <Title>
          <div className='text'>이런 복지들이 있어요.</div>
        </Title>

        {additionalInfo?.welfareWithCategories.map((card) => (
          <PositionWelfareDetailCard
            card={card}
            key={`welfare-card-${card.name}`}
          />
        ))}
      </JDFullScreenBaseModal>
    </>
  );
};

export default inject(injectStore.positionJdModel)(observer(PositionWelfareModal));
