import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { IMatchingTalkMessageOptionDto } from 'interfaces/rqrs/IMatchingMessageListRs';
import React, { FC, useEffect, useState } from 'react';
import { Control, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import Icon from '__designkit__/icon/Icon';
import MatchOfferChangeInterviewModal from 'components/jobMatch/dashboard/MatchOfferChangeInterviewModal';
import { ReactComponent as IconRadio } from 'assets/_v2/_common/icon_radio.svg';
import { ReactComponent as IconRadioClicked } from 'assets/_v2/_common/icon_radio_clicked.svg';
import { formatDateWithDayOfWeek } from 'utils/DateUtils';

const InterviewScheduleFrame = styled.div`
  >p {
    font: ${Fonts.B2_Bold_P};
    color: ${colors.CG_90};
    word-break: break-all;
  }
  .location-frame{
    display: flex;
    .location-title{
      font:${Fonts.B2_Bold_P};
      color:${colors.CG_90};
    }
    .location-detail{
      margin-left: 12px;
      font:${Fonts.B2_Medium_P};
      color:${colors.CG_70};

    }
  }
  .schedule-title {
    margin-bottom: 16px;
    font: ${Fonts.H5_Bold};
  }

  .schedule-location {
    margin-top: 4px;
    margin-bottom: 8px;
    font: ${Fonts.B3_Medium};
    color: ${colors.CG_80};
  }

  .schedule-desc {
    font: ${Fonts.B3_Bold};
    margin-bottom: 2px;
  } 

  .jda-input {
    background: none;
    padding-right: 0;
    margin-top: 6px;
    margin-bottom: 4px;
  }

  .checkbox-frame {
    label {
      align-items: center;

      span {
        font: ${Fonts.B3_Medium};     
      }
    }
    
  }
  .decision-frame{
    display: flex;
    margin-top: 16px;
    .decision-title{
      font:${Fonts.B2_Bold_P};
      color:${colors.CG_90};
    }
    .decision-date{
      font:${Fonts.B2_Medium_P};
      color:${colors.CG_70};
      margin-left: 12px;
    }
  }
  .decision-description{
    margin-top: 4px;
    font:${Fonts.B3_Medium_P};
    color:${colors.CG_60};
  }
`;

const SpaceFrame = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 8px;
  .control-text{
    display: flex;
    align-items: center;

    font: ${Fonts.B3_Medium};
    color: ${colors.JOBDA_BLACK};
  }
  .title-text{
    font: ${Fonts.B2_Bold_P};
    color: ${colors.JOBDA_BLACK};
    word-break: break-all;
    align-items: center;
    display: flex;
  }
`;

const ClickFrame = styled.div`
  display: flex;
  padding:16px 20px;
  align-items: center;
  gap: 16px;
  width: 100%;
  border:1px solid ${colors.CG_50};
  border-radius: 8px;
  .title-text{
    font:${Fonts.B2_Bold};
    color:${colors.CG_90};
    width: 215px;
    white-space: pre-line;
  }
  .radio{
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: ${colors.WHITE_100};
    border: 1.6px solid ${colors.CG_50};
  }
  &:not(:last-child){
    margin-bottom: 8px;
  
  }
`;

const FinishedFrame = styled.div<{clicked?:boolean}>`
  display: flex;
  padding:16px 20px;
  align-items: center;
  gap: 16px;
  width: 100%;
  border:1px solid ${colors.CG_50};
  border-radius: 8px;
  background-color: ${(props) => (props.clicked ? `${colors.WHITE_100}` : 'none')};
  .title-text{
    font:${Fonts.B2_Bold};
    color:${(props) => (props.clicked ? `${colors.JOBDA_BLACK}` : `${colors.CG_50}`)};
    width: 215px;
    white-space: pre-line;
  }
  .radio{
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: ${colors.WHITE_100};
    border: 1.6px solid ${colors.CG_50};
  }
  &:not(:last-child){
    margin-bottom: 8px;
  
  }
`;
interface IMatchDashBoardOfferScheduleProps {
    control?: Control;
    interviewLocation: string;
    options: IMatchingTalkMessageOptionDto[]
    onChange: (timeContent: string) => void;
    decision: IMatchingTalkMessageOptionDto;
    decisionDueDateTime: string;
    positionSn:number;
    setInterviewOptionSn: (sn:number) => void;
}

const MatchDashBoardOfferSchedule:FC<IMatchDashBoardOfferScheduleProps> = ({ setInterviewOptionSn, positionSn, control, interviewLocation, options, onChange, decision, decisionDueDateTime }) => {
  const [isMatchOfferChangeModalVisible, setIsMatchofferChangeModalVisible] = useState(false);
  const [selectedSn, setSelectedSn] = useState<number | null>(null);
  const [adjustSn, setAdjustSn] = useState<number>(-1);
  const useFormed = useFormContext();
  const { setValue, register, errors, clearErrors, reset, watch } = useFormed;

  useEffect(() => {
    const adjustOption = options.find((option) => option.command === 'ADJUST');
    if (adjustOption) {
      setAdjustSn(adjustOption.sn);
    }
  }, [options]);

  const formatTitle = (title:string) => {
    const daysOfWeek = ['월요일', '화요일', '수요일', '목요일', '금요일', '토요일', '일요일'];

    // 각 요일에 대해 체크하여 해당 요일이 포함되어 있다면 그 뒤에 개행 문자를 추가
    for (const day of daysOfWeek) {
      if (title.includes(day)) {
        return title.replace(day, `${day}\n`);
      }
    }
    return title;
  };

  const handleControlInterview = (check:IMatchingTalkMessageOptionDto) => {
    if (!check) {
      setIsMatchofferChangeModalVisible(true);
    }
  };
  const handleClick = (title:string, sn:number) => {
    onChange(title);
    setSelectedSn(sn); // 선택된 sn 업데이트
    setInterviewOptionSn(sn);
  };
  return (
    <>
      <InterviewScheduleFrame>
        <p className='schedule-title'>면접 일정을 선택해 주세요.</p>
        {interviewLocation && (
        <div className='location-frame'>
          <div className='location-title'>면접 장소</div>
          <div className='location-detail'>{interviewLocation}</div>
        </div>
        )}
        <SpaceFrame>
          <div className='title-text'>면접 시간</div>
          {
            options.find((option) => option.command === 'ADJUST') && (
            <div onClick={() => handleControlInterview(decision)} role='button' className='control-text'>일정 조율 <Icon name='arrow-right' size={24} /> </div>
            )
          }

        </SpaceFrame>

        { !decision && options.map(({ sn, title, disabled, command }) => {
          if (title === '거절' || command === 'ADJUST') return <></>;
          return (
            <ClickFrame key={sn} role='button' onClick={() => handleClick(title, sn)}>
              <div className='title-text'>{formatTitle(title)}</div>
              {selectedSn === sn ? <IconRadioClicked /> : <IconRadio />}
              <input name='schedule' aria-hidden='true' ref={register} value={sn} type='radio' hidden />
            </ClickFrame>

          );
        })}
        {
          decision && options.map(({ sn, title, disabled, command }) => {
            if (title === '거절' || command === 'ADJUST') return <></>;
            return (
              <FinishedFrame key={sn} clicked={decision.sn === sn}>
                <div className='title-text'>{formatTitle(title)}</div>
                {decision.sn === sn ? <IconRadioClicked /> : <IconRadio />}
              </FinishedFrame>

            );
          })
        }
        <div className='decision-frame'>
          <div className='decision-title'>응답 기한</div>
          <div className='decision-date'>{formatDateWithDayOfWeek(decisionDueDateTime) }</div>
        </div>
        <div className='decision-description'>정해진 유효기간까지 제출하지 않을 경우 제출이 불가능합니다.</div>
      </InterviewScheduleFrame>
      {
        isMatchOfferChangeModalVisible && <MatchOfferChangeInterviewModal optionSn={adjustSn} positionSn={positionSn} isOpen={isMatchOfferChangeModalVisible} onClickClose={() => setIsMatchofferChangeModalVisible(false)} />
      }

    </>
  );
};
export default MatchDashBoardOfferSchedule;
