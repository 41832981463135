import styled from 'styled-components';

const FormFiledFrame = styled.div`
  margin-top: 32px;

  .date-frame {
    padding: 0;
    margin-bottom: 0;
  }

  .selector {
    margin-bottom: 12px;
  }

  .baseInput {
    margin-bottom: 16px;
  }
`;

export default FormFiledFrame;
