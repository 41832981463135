import DisableScroll from 'components/common/DisableScroll';
import LogoImgOrText from 'components/common/LogoImgOrText';
import Portal from 'components/common/Portal';
import RoutePaths from 'consts/RoutePaths';
import colors from '__designkit__/common/colors';
import { absoluteHorizonCenter, dimmer } from 'consts/style/mixins';
import shadows from 'consts/style/shadows';
import Fonts from '__designkit__/common/fonts';
import { inject, observer } from 'mobx-react';
import MatchMessageStore from 'models/MatchMessageStore';
import { injectStore } from 'models/store';
import MatchHomeDashboardModel from 'models/_v2/MatchHomeDashboardModel';
import React, { FC, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { IMatchDashBoardMessageModalProps } from './MatchDashBoardMessageModal';

export const ModalFrame = styled.button`
  position: relative;
  ${dimmer()};
  z-index: 10004;
  background-color: rgba(0, 0, 0, 0.7);
  
`;

const StyledContent = styled.div`
  overflow: hidden;
  opacity: 1;
  background: ${colors.WHITE_100};
  ${shadows.xlarge};

  .bubble {
    position: absolute;
    ${absoluteHorizonCenter};
    display: flex;
    padding: 16px;
    top: 56px;
    width: 280px;
    background: ${colors.WHITE_100};
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: ${colors.CG_40} solid 1px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.12);
    z-index: 10004;
    .balloon-text-frame {
      display: flex;
      flex-direction: column;
      margin-left: 8px;
      text-align: left;
    }

    .balloon-title {
      max-width: 208px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      font: ${Fonts.B2_Bold};
      color: ${colors.CG_80};
    }

    .balloon-company {
      max-width: 208px;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      display: block;
      height: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      width: 208px;
      font: ${Fonts.B3_Medium};
      color: ${colors.CG_80};
      margin-top: 4px;
    }

    .thumbnail {
      font: ${Fonts.B1_Bold};
      border: 0.75px solid ${colors.G_200};
    }

  }

  .bubble:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 0 4px 5px;
    border-color: ${colors.WHITE_100} transparent;
    display: block;
    width: 0;
    z-index: 1;
    top: -5px;
    left: 134px;
  }

  .bubble:before {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 0 4px 5px;
    border-color: ${colors.CG_40} transparent;
    display: block;
    width: 0;
    z-index: 0;
    top: -6px;
    left: 134px;
  }
`;

const MatchDashBoardBalloonModal:FC<IMatchDashBoardMessageModalProps> = ({ matchHomeDashboardModel = new MatchHomeDashboardModel(), matchMessageStore = new MatchMessageStore() }) => {
  const wrapperRef = useRef<any>(null);

  const useOutsideClicker = (ref:any) => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        matchMessageStore.closeBalloonModal();
      }
    }
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  useOutsideClicker(wrapperRef);
  return (
    !matchMessageStore.balloonModalView ? <></>
      : (
        <Portal>
          <DisableScroll />
          <ModalFrame>
            <StyledContent>
              <div className='bubble' ref={wrapperRef}>
                <Link to={RoutePaths.company_details(matchMessageStore.messagesDto?.companySn)} className='link'>
                  <LogoImgOrText
                    imgUrl={matchMessageStore.messagesDto?.logoImageUrl}
                    text={matchMessageStore.messagesDto?.companyName}
                    size={32}
                  />
                </Link>
                <div className='balloon-text-frame'>
                  <p className='balloon-title'>{matchMessageStore.messagesDto?.positionName}</p>
                  <Link to={RoutePaths.company_details(matchMessageStore.messagesDto?.companySn)} className='link'>
                    <p className='balloon-company'>{matchMessageStore.messagesDto?.companyName}</p>
                  </Link>
                </div>
              </div>
            </StyledContent>
          </ModalFrame>
        </Portal>
      )
  );
};
export default inject(injectStore.matchMessageStore, injectStore.matchHomeDashboardModel, injectStore.positionJdModel)(observer(MatchDashBoardBalloonModal));
