import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Shadows from '__designkit__/common/shadows';
import ButtonGroup, { Props as ButtonProps } from '__designkit__/components/Dialog/ButtonGroup';
import { ReactNode } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
  width: calc(100% - 48px);
  max-width: 312px;
  height: fit-content;
  background-color: ${Colors.WHITE_100};
  border-radius: 12px;
  filter: ${Shadows.Shadow_300};

  & > .content {
    padding: 40px 20px 0;

    & .media {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 16px;
    }

    & .text {
      text-align: center;
      white-space: pre-wrap;
    }

    & .title {
      padding-bottom: 8px;
      font: ${Fonts.H4_Bold};
      color: ${Colors.JOBDA_BLACK};
    }

    & .body {
      font: ${Fonts.B2_Medium_P};
      color: ${Colors.CG_70};
    }

    & .sub-body {
      padding-top: 12px;
      font: ${Fonts.B3_Medium};
      color: ${Colors.B_100};
    }
  }
`;

interface Props {
  media?: ReactNode;
  head: string;
  body?: string;
  subBody?: string | ReactNode;
  buttonProps: ButtonProps;
}

const Dialog = ({ media, head, body, subBody, buttonProps }: Props) => {
  return (
    <Frame>
      <div className='content'>
        {media && <div className='media'>{media}</div>}
        <div className='text title'>{head}</div>
        {body && <div className='text body'>{body}</div>}
        {subBody && <div className='text sub-body'>{subBody}</div>}
      </div>
      <ButtonGroup {...buttonProps} />
    </Frame>
  );
};

export default Dialog;
