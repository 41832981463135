import DisableScroll from 'components/common/DisableScroll';
import Portal from 'components/common/Portal';
import JDASelector, { JDA_SELECTOR_TYPE } from 'components/inputs/JDASelector';
import MainHeader from 'components/_v2/_common/mainHeader/MainHeader';
import colors from '__designkit__/common/colors';
import Fonts, { fontStyle } from '__designkit__/common/fonts';
import { dimmer, importantify } from 'consts/style/mixins';
import { PARTNERSHIP_TERM_TYPE, TERM_TYPE, TERM_TYPE_TITLE } from 'consts/TermType';
import { IJoinProps } from 'interfaces/props/_v2/IJoinProps';
import { ITermsGetDto } from 'interfaces/rqrs/ITermsListGetRs';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import JoinModel from 'models/_v2/JoinModel';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

export const StyledTermsFrame = styled.div`
  position:fixed;
  top:100vh;
  width:100%;
  height:100vh;
  background:white;
  z-index:10007;
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:stretch;
  transition:top .5s;
  .dimmer {
    ${dimmer()};
    transition: opacity .5s;
    opacity:0;
  }
  &.visible {
    top:0;
    opacity:1;
  }
  .frame-content {
    flex:1;
    width:100%;
    background:${colors.WHITE_100};
    z-index:6;
    padding:26px 20px;
    overflow-y:auto;
    overflow-x:hidden;
    .frame-title {
      display:flex;
      flex-direction:row;
      justify-content:flex-start;
      align-items:center;
      margin-bottom:26px;
      padding-bottom: 10px;
      border-bottom: 1px solid ${colors.CG_40};
      .title {
        flex:1;
        ${fontStyle.BODY1_SEMIBOLD};
        color:${colors.CG_80};
      }
    }
  }
  .select-options {
    z-index:10008;
  }
  .frame-terms {
    padding-bottom: calc(112px + env(safe-area-inset-bottom));
    p {
      font: ${importantify(Fonts.B2_Medium)};
      margin-left:0 !important;
      margin-top:0 !important;
      margin-bottom:0 !important;
      word-break:break-all !important;
      line-height: 1.8em !important;
      word-break: break-all;
    }
    p span {
      font: ${importantify(Fonts.B2_Medium)};
    }
    p b span,p b {
      ${importantify(fontStyle.BODY1_REGULAR)};
      margin-bottom:16px !important;
    }
    table {
      border:none !important;
      width: 100% !important;
    }
    table tr td {
      border: 1px solid ${colors.CG_40} !important;
      color:${colors.CG_70} !important;
      padding: 11px 10px !important;
      p {
        font: ${importantify(Fonts.B2_Medium)}; 
      }
      /* b { color:${colors.CG_90}; } */
    }
    table tr {
      border:none !important;
    }
    table tr:not(:first-child) td:first-child {
      border-left:none !important;
      p {
        /* ${importantify(fontStyle.CAPTION1_SEMIBOLD)}; */
      }
      b { color:${colors.CG_70} !important; }
    }
    table tr td:last-child {
      border-right:none !important;
    }
    table tr:first-child td {
      padding: 11px 0 !important;
      border:none !important;
    }
    table tr:first-child td, table tr:first-child td * {
      background:${colors.CG_40} !important;
      color:${colors.JOBDA_BLACK} !important;
      ${importantify(fontStyle.CAPTION1_SEMIBOLD)};
    }
    u span b {
      font: ${importantify(Fonts.B2_Medium)};
      font-weight:bold !important;
    }
  }
`;

interface termsFormValues { activeIndex: number }

const TermsModal: FC<IJoinProps> = ({ joinModel = new JoinModel() }) => {
  const [activeTerms, setActiveTerms] = useState<ITermsGetDto | null>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const formMethod = useForm<termsFormValues>({ defaultValues: { activeIndex: 0 } });
  const { setValue, watch, reset } = formMethod;

  useEffect(() => {
    if (joinModel.activeTermType == null) return;
    setActiveTerms(joinModel.activeTermContents[Number(watch('activeIndex'))]);
  }, [watch('activeIndex')]);

  useEffect(() => {
    const init = async () => {
      if (joinModel.activeTermType == null) return;
      if (joinModel.activeTermType === TERM_TYPE.PARTNERSHIP_PRIVACY_COLLECTION || joinModel.activeTermType === TERM_TYPE.PARTNERSHIP_PRIVACY_PROVISION) {
        await joinModel.loadPartnershipTermsDetail(joinModel.activeTermType === TERM_TYPE.PARTNERSHIP_PRIVACY_COLLECTION ? PARTNERSHIP_TERM_TYPE.PRIVACY_COLLECTION : PARTNERSHIP_TERM_TYPE.PRIVACY_PROVISION);
      } else {
        await joinModel.loadTermsList(joinModel.activeTermType);
      }
      setValue('activeIndex', 0);
      setVisible(!!joinModel.activeTermType);
    };
    init();
    return () => {
      reset();
    };
  }, [joinModel, joinModel.activeTermType, setValue, reset]);

  if (!joinModel.activeTermType)
    return <></>;
  return (
    <Portal>
      <StyledTermsFrame className={visible ? 'visible' : ''}>
        <DisableScroll />
        <div className='dimmer' />
        <MainHeader
          className='header'
          title={TERM_TYPE_TITLE[joinModel.activeTermType]}
          close
          alarm={false}
          search={false}
          gnb={false}
          onClose={() => {
            setVisible(false);
            setTimeout(() => {
              joinModel.resetTerms();
            }, 500);
          }}
          data-hide={false as any}
        />
        <FormProvider {...formMethod}>
          <div className='frame-content'>
            <div className='frame-title'>
              <div className='title'>{activeTerms?.title}</div>
              {
                joinModel.activeTermContents && joinModel.activeTermContents.length > 0 && (
                  <JDASelector
                    className='select-options'
                    selectTitle='개정이력'
                    type={JDA_SELECTOR_TYPE.type1}
                    name='activeIndex'
                  >
                    <select>
                      {
                        joinModel.activeTermContents.map(({ sn, version }, idx) => (
                          <option key={`option-term-${sn}`} value={idx} selected={idx === Number(watch('activeIndex'))}>개정이력 {version}</option>
                        ))
                      }
                    </select>
                  </JDASelector>
                )
              }
            </div>
            <div className='frame-terms' dangerouslySetInnerHTML={{ __html: activeTerms?.contents || '' }} />
          </div>
        </FormProvider>
      </StyledTermsFrame>
    </Portal>
  );
};

export default inject(injectStore.joinModel)(observer(TermsModal));
