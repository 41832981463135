export interface IMatchingReportCompanyRs {
    specialMessage: SpecialMessageDto
    vision: VisionDto
    idealTalents: IdealTalent[]
    managementPhilosophies: ManagementPhilosophyDto[]
  }

export interface SpecialMessageDto {
    greeting: string
    companyIntroduction: string
    applicantSpecialMessage: string
    closingRemark: string
  }

export interface VisionDto {
    visionTitle: string
    visionDetails: string
  }

export interface IdealTalent {
    text: string
  }

export interface ManagementPhilosophyDto {
    text: string
  }

export interface IMatchingReportPositionsRs {
    positions: MatchingReportPositionDto[]
  }

export interface MatchingReportPositionDto {
    sn: number
    name: string
    responseType: string
    decisionDueDateTime: string
    options:MessageOption[]
  }
export interface MessageOption{
  optionSn:number
  optionCommand:MessageOptionType
}
export enum MessageOptionType{
  ACCEPT='ACCEPT',
  REFUSE='REFUSE',
}
export interface IMatchingReportRs {
    score: MatchingReportScoreDto
    profileFit: IMatchingReportProfileFitDto
    competencyAnalysis: MatchingReportCompetencyAnalysisDto
    offerMessage: string
    addressInfo: MatchingReportPositionAddressDto
  }

export interface MatchingReportScoreDto {
    matchingTotalScore: number
    profileScore: number
    competencyScore: number
  }

export interface IMatchingReportProfileFitDto {
  jobTitleFit: JobTitleFit
  salaryFit: SalaryFit
  careerFit: CareerFit
  }
export interface JobTitleFit {
    jobTitleFits: JobTitleFits[]
  }

export interface JobTitleFits {
    code: string
    name: string
    fitYn: boolean
  }

export interface SalaryFit {
    minSalary: number
    maxSalary: number
    fitYn: boolean
  }

export interface CareerFit {
    minYear: number
    maxYear: number
    fitYn: boolean
  }
export interface MatchingReportCompetencyAnalysisDto {
    strengths: Strength[]
  }

export interface Strength {
    keyword: string
    comment: string
    priority: number
    questions: Question[]
  }

export interface Question {
    question: string
    comment: string
    priority: number
  }

export interface MatchingReportPositionAddressDto {
    address: string
    detailAddress: string
  }
