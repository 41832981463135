import { Divider12Grey } from 'components/divider/Divider';
import ChallengeDetailPositions from 'components/_v2/challengeDetail/ChallengeDetailPositions';
import colors from '__designkit__/common/colors';
import shadows from 'consts/style/shadows';
import Fonts from '__designkit__/common/fonts';
import IChallengeDetailProps from 'interfaces/_v2/challenge/IChallengeDetailProps';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import { injectStore } from 'models/store';
import ChallengeDetailModel from 'models/_v2/ChallengeDetailModel';
import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ScrollUtil from 'utils/ScrollUtil';
import { IsJsonString } from 'utils/_v2/validation';
import PositionJdContent from '../positionJd/PositionJdContent';

const ContentFrame = styled.div`

  .divider-24 {
    margin: 32px 0;
  }
`;

export const ContentTab = styled.div`

  position: sticky;
  z-index: 100;
  transition: top .25s, background .25s;
  top: ${(props) => (!(props as any)['data-hide'] ? '57' : '0')}px;
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:stretch;
  width:100%;
  height:40px;
  overflow-x:auto;
  white-space:pre;
  margin-top: 8px;
  margin-bottom: 32px;
  padding: 0 16px;
  background: ${colors.WHITE_100};
  ${shadows.headers}; 
  ::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
  }
`;

export const Tab = styled.div`
  position:relative;
  font: ${Fonts.B2_Bold};
  line-height: 17px;
  padding-top: 8px;
  padding-bottom: 14px;
  color: ${colors.CG_60};
  margin-right: 32px;
  display: flex;
  align-items: center;

  &[aria-current=true] {
    color: ${colors.JOBDA_BLACK};
  }

  &[aria-current=true]:before {
    display:block;
    position:absolute;
    content:'';
    bottom:0;
    left:0;
    width:100%;
    height:2px;
    background:${colors.JOBDA_BLACK};
  }
`;

const Contents = styled.div`
  .title {
    padding: 0 16px;
    font: ${Fonts.H5_Bold};
    color: ${colors.JOBDA_BLACK};
    margin-bottom: 8px;
  }
`;

const ChallengeDetailContent:FC<IChallengeDetailProps> = ({ context = new Context(), challengeDetailModel = new ChallengeDetailModel() }) => {
  const { challengeDetail } = challengeDetailModel;
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [clickTab, setClickTab] = useState<boolean>(false);
  const itemsRef = useRef<Array<HTMLDivElement | null>>([]); // multiple ref
  const getPageMaxScroll = () => Math.max(
    document.body.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.clientHeight,
    document.documentElement.scrollHeight,
    document.documentElement.offsetHeight,
  ) - window.innerHeight;
  const maxScroll = getPageMaxScroll();

  const scrollToRef = (idx:number) => {
    const ref = itemsRef.current[idx];
    const refOffSetTop = ref?.offsetTop;
    if (refOffSetTop) {
      if (refOffSetTop <= maxScroll || maxScroll === 0) {
        ScrollUtil.scrollTo({ left: 0, top: refOffSetTop ? refOffSetTop - 300 : 0, behavior: 'smooth' });
      } else {
        ScrollUtil.scrollTo({ left: 0, top: refOffSetTop ? refOffSetTop - 300 : 0, behavior: 'smooth' });
      }
      setClickTab(true);
      setCurrentTab(idx);
    }
  };

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, challengeDetail?.challengeTabs.length);
  }, [challengeDetail?.challengeTabs]);

  const [scrollPosition, setScrollPosition] = useState(0);

  const blendHandler = async () => {
    const scrollTop = await ScrollUtil.getScrollTop();
    setScrollPosition(scrollTop);
  };

  useEffect(() => {
    ScrollUtil.addEventListener(blendHandler);
    return () => {
      ScrollUtil.removeEventListener(blendHandler);
    };
  }, []);

  useEffect(() => {
    if (clickTab) {
      setClickTab(false);
    } else {
      itemsRef.current.map((tab, idx) => {
        const boundary = tab?.offsetTop;
        if (boundary && scrollPosition + 200 >= boundary) {
          setCurrentTab(idx);
        }
      });
    }
  }, [scrollPosition]);
  const participatePosition = {
    name: '참여 포지션',
    contents: '참여 포지션',
  };
  useEffect(() => {
    if (challengeDetail) {
      challengeDetail.challengeTabs.splice(1, 0, participatePosition);
    }
  }, [challengeDetail]);
  return (
    <ContentFrame>
      <ContentTab data-hide={context.hideHeader}>
        {challengeDetail?.challengeTabs.map(({ name }, idx) => <Tab onClick={() => scrollToRef(idx)} aria-current={currentTab === idx}>{name}</Tab>)}
      </ContentTab>
      <div>
        { challengeDetail?.challengeTabs.map(({ name, contents }, idx) => (
          <>
            <Contents key={`contents-${name}`} ref={(el) => { itemsRef.current[idx] = el; }}>
              <p className='title'>{name}</p>
              {idx === 1
                ? <ChallengeDetailPositions />
                : (
                  contents !== undefined && IsJsonString(contents)
                  && <PositionJdContent positionJd={false} value={JSON.parse(contents)} />
                )}

            </Contents>
            <Divider12Grey className='divider-24' />
          </>
        ))}
      </div>
    </ContentFrame>
  );
};
export default inject(injectStore.challengeDetailModel, injectStore.context)(observer(ChallengeDetailContent));
