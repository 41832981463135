export enum DisabilityGrade {
    NONE = 'NONE', // 비대상
    SEVERE = 'SEVERE', // 중증(기존 1~3등급)
    MILD = 'MILD', // 경증(기존 4~6등급)
}

export const DisabilityGradeStr: { [key in keyof typeof DisabilityGrade]: string } = {
  NONE: '비대상',
  SEVERE: '중증(기존 1~3등급)',
  MILD: '경증(기존 4~6등급)',
};

export const DisabilityGradeTagStr: { [key in keyof typeof DisabilityGrade]: string } = {
  NONE: '장애 비대상',
  SEVERE: '장애 중증',
  MILD: '장애 경증',
};

export enum DisabilityType {
    LIVER = 'LIVER', // 간
    BRAIN_LESION = 'BRAIN_LESION', // 뇌병번
    EPILEPSY = 'EPILEPSY', // 뇌전증
    VISUAL = 'VISUAL', // 시각
    KIDNEY = 'KIDNEY', // 신장
    CARDIAC = 'CARDIAC', // 심장
    FACIAL = 'FACIAL', // 안면
    SPEECH = 'SPEECH', // 언어
    AUTISM = 'AUTISM', // 자폐
    INTESTINAL_URINARY = 'INTESTINAL_URINARY', // 장루 요루
    MENTAL = 'MENTAL', // 정신
    INTELLECTUAL = 'INTELLECTUAL', // 지적
    PHYSICAL = 'PHYSICAL', // 지체
    HEARING = 'HEARING', // 청각
    RESPIRATORY = 'RESPIRATORY', // 호흡기
}

export const DisabilityTypeStr: { [key in keyof typeof DisabilityType]: string} = {
  LIVER: '간장애',
  BRAIN_LESION: '뇌병변장애',
  EPILEPSY: '뇌전증장애',
  VISUAL: '시각장애',
  KIDNEY: '신장장애',
  CARDIAC: '심장장애',
  FACIAL: '안면장애',
  SPEECH: '언어장애',
  AUTISM: '자폐장애',
  INTESTINAL_URINARY: '장루요루장애',
  MENTAL: '정신장애',
  INTELLECTUAL: '지적장애',
  PHYSICAL: '지체장애',
  HEARING: '청각장애',
  RESPIRATORY: '호흡기장애',
};
