import URIs from 'consts/URIs';
import { request } from 'utils/request';
import { action, observable } from 'mobx';
import { IResumeSiteRs } from 'interfaces/_v2/resume/IResumeSiteRs';

export default class ResumeModel {
  @observable url: string = '';
  @observable positionId: number = 0;
  @observable publicKey: string = '';
  @observable resumeAgreeUrl = '';
  @observable resumeSn = 0;
  @observable positionSn: number = 0;

  @action
  getPublicKey = async (positionSn:number) => {
    try {
      const result = (await request<IResumeSiteRs>({
        method: 'get',
        url: URIs.resume_site,
        params: { positionSn },
      }));
      this.url = result.url;
      this.positionId = result.positionId;
      this.publicKey = result.publicKey;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  @action
  getPositionSn = async (publicKey:string, positionId:number) => {
    try {
      const result = (await request<number>({
        method: 'get',
        url: URIs.position_sn,
        params: { publicKey, positionId },
      }));
      this.positionSn = result;
    } catch (e) {
      console.error(e);
    }
  }

  @action
  getResumeAgree = async (positionSn: number) => {
    try {
      const res = (await request<boolean>({
        method: 'get',
        url: URIs.get_resume_agree(positionSn),
      }));
      return res;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  @action
  getResumeAgreeUrl = async (positionSn:number) => {
    try {
      this.resumeAgreeUrl = (await request<{url: string}>({
        method: 'get',
        url: URIs.get_resume_agree_url(positionSn),
      })).url;
    } catch (e) {
      console.error(e);
    }
  }

  @action
  getResumeSn = async (positionSn: number) => {
    try {
      this.resumeSn = (await request<number>({
        method: 'get',
        url: URIs.get_resume_sn(positionSn),
      }));
    } catch (error) {
      console.error(error);
    }
  }

  @action
  postResumeAgree = async (positionSn: number) => {
    try {
      await request({
        method: 'post',
        url: URIs.post_resume_agree(positionSn),
      });
    } catch (e) {
      console.error(e);
    }
  }
}
