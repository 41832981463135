import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import MainHeader from 'components/_v2/_common/mainHeader/MainHeader';
import NotificationCard from 'components/_v2/notification/NotificationCard';
import NotificationNotFoundView from 'components/_v2/notification/NotificationNotFoundView';
import MatchDashBoardMessageModal from 'components/jobMatch/dashboard/MatchDashBoardMessageModal';
import shadows from 'consts/style/shadows';
import { inject, observer } from 'mobx-react';
import NotificationModel from 'models/_v2/NotificationModel';
import { injectStore } from 'models/store';
import React, { FC } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

const Frame = styled.div`
  >:not(:last-child){
    ${shadows.tnbLine};
  }
`;

const HeaderBtnFrame = styled.div`
    padding:8px 20px 11px 20px;
    height:33px;
    border-bottom: 1px solid ${colors.CG_40};
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 12px;
    
    >button{
        background: transparent;
    }
    .clear{
        color:${colors.CG_60};
        font: ${Fonts.B3_Medium};
        line-height: 14px;
      }
    .read{
        color:${colors.CG_70};
        font: ${Fonts.B3_Medium};
      }
`;

export interface INotificationProps {
  notificationModel?: NotificationModel;
}
const Notification: FC<INotificationProps> = ({ notificationModel = new NotificationModel() }) => {
  const history = useHistory();

  return (
    <>
      <MainHeader
        title='알람'
        gnb={false}
        search={false}
        close
        alarm={false}
        underShadow={false}
        onClose={() => history.goBack()}
      />
      <HeaderBtnFrame>
        <button
          className='clear'
          onClick={async () => {
            const notificationSns = notificationModel.notifications.map((item) => item.notificationSn);
            await notificationModel.deleteNotifications(notificationSns);
            await notificationModel.loadNotifications();
          }}
        >모두 지우기
        </button>
        <button
          className='read'
          onClick={async () => {
            const notificationSns = notificationModel.notifications.map((item) => item.notificationSn);
            await notificationModel.readNotifications(notificationSns);
            await notificationModel.loadNotifications();
          }}
        >모두 읽음
        </button>
      </HeaderBtnFrame>
      <Frame>
        <>
          {notificationModel.notifications.length !== 0 ? notificationModel.notifications.map((item) => (
            <NotificationCard
              key={`notification-card-${item.notificationSn}`}
              item={item}
            />
          ))
            : <NotificationNotFoundView />}
        </>
        <MatchDashBoardMessageModal />
      </Frame>
    </>
  );
};

export default inject(injectStore.notificationModel)(observer(Notification));
