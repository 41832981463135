import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import RoutePaths from 'consts/RoutePaths';
import { lineClampBox, lineClampOnlyOne } from 'consts/_v2/_common/style/mixins';
import { CurationTitleType, IAdRecommendJobPostingDto } from 'interfaces/_v2/main/IJobPostings';
import { inject, observer } from 'mobx-react';
import JobsModel from 'models/_v2/JobsModel';
import Main from 'models/_v2/Main';
import { injectStore } from 'models/store';
import useAdsImpressionQuery from 'query/ads/useAdsImpressionQuery';
import useAdsViewsQuery from 'query/ads/useAdsViewsQuery';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { DateFormatYYYYMMDDCCHHMMUnit, getDDayString, str2DateTime } from 'utils/DateUtils';
import ImgDumy from 'assets/_v2/positionList/img_dumy.png';
import JDSwiper, { JDBullets, JDSwiperSlide } from '../_common/JDSwiper';
import MainNoPosition from './MainNoPosition';

const Frame = styled.div`
  width:100%;
  height: fit-content;
  margin-top: 40px;
`;

const TitleFrame = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 16px 16px 16px;

    .title{
        font: ${Fonts.H5_Bold};
        color:${colors.JOBDA_BLACK};
        width: 288px;
        word-break: break-all;
    }
    .ad-frame{
        display: flex;
        background-color:${colors.JOBDA_WHITE};
        border:1px solid rgb(0, 0,0,0.05);
        border-radius: 4px;
        justify-content: center;
        align-items: center;
        padding:4px 6px;
        height: 24px;
        .ad-frame-text{
            font: ${Fonts.B4_Medium};
            color:${colors.CG_50}
        }
    }
`;

const InnerFrame = styled.div`
`;

const MainRecruitFrame = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .pagination-bullets{
        margin:22px 0 32px 0;
    }
`;

const MainRecruitCardFrame = styled.div`
    display: flex;
    justify-content: flex-start;
    padding: 10px 16px ;
    .recruit-img{
        width:120px;
        height:120px;
        border-radius: 4px;
    }
    .text-frame{
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        margin-left: 14px;
        height: 120px;
        .title{
            font: ${Fonts.B1_Bold};
            color:${colors.JOBDA_BLACK};
            margin-bottom: 16px;
            ${lineClampOnlyOne()};
            width:52vw;
        }
        .subtitle{
            font: ${Fonts.B3_Medium};
            color:${colors.JOBDA_BLACK};
            margin-bottom: 7px;
            ${lineClampBox(2)};
            width:52vw;
        }
        .date-frame{
            display: flex;
            align-items: center;
            .date-text{
                font: ${Fonts.B3_Medium};
                color:${colors.CG_70};
                margin-right:8px;
            }
            .d-day-frame{
                display: flex;
                background-color: ${colors.JOBDA_WHITE};
                border:1px solid ${colors.CG_50};
                border-radius: 4px;
                justify-content: center;
                align-items: center;
                padding: 4px 6px;
           .d-day-text{
                font: ${Fonts.B4_Medium};
                color:${colors.CG_90};
        }
    }
        }
    }
`;

interface IMainRecruit{
  main?:Main
  jobsModel?: JobsModel;
}

const MainRecruit:FC<IMainRecruit> = ({ main = new Main(), jobsModel = new JobsModel() }) => {
  const history = useHistory();
  const [jobPostingPositionList, setJobPostingPositionList] = useState<IAdRecommendJobPostingDto[][]>([]);
  const [loading, setLoading] = useState(false);
  const { mutate: increaseAdsViewMutate } = useAdsViewsQuery();
  const { mutate: impressionAdsMutate } = useAdsImpressionQuery();

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      await main.loadJobPostingsAdsRecommend(0, 15, true);
      await main.loadJobPostingsAdsTitle(CurationTitleType.RECOMMEND_JOB_POSTING);
      setLoading(false);
    };
    init();
  }, [main]);

  const shuffle = (array: any[]): any[] => {
    const result = array.slice();
    for (let i = result.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [result[i], result[j]] = [result[j], result[i]];
    }
    return result;
  };

  useEffect(() => {
    if (main.jobPostingPositions && main.jobPostingPositions.length > 0) {
      // 광고 공고와 일반 공고 분리
      let adList: IAdRecommendJobPostingDto[] = [];
      let normalList: IAdRecommendJobPostingDto[] = [];
      main.jobPostingPositions.forEach((item) => {
        if (item.adSn) {
          adList.push(item);
        } else {
          normalList.push(item);
        }
      });

      // 광고 공고와 일반 공고 각각 랜덤으로 순서 섞기
      adList = adList && shuffle(adList);
      normalList = normalList && shuffle(normalList);

      // 광고 공고와 일반 공고 3개씩 묶기 (광고 우선)
      const realDummy: IAdRecommendJobPostingDto[][] = [];
      let tmpDummy: IAdRecommendJobPostingDto[] = [];
      [...adList, ...normalList].forEach((item, index) => {
        tmpDummy.push(item);
        if ((index + 1) % 3 === 0 && tmpDummy.length > 0) {
          realDummy.push(tmpDummy);
          tmpDummy = [];
        }
      });

      // 실제 노출될 공고 리스트 설정
      setJobPostingPositionList(realDummy);
    }
  }, [main.jobPostingPositions]);

  const MainRecruitCard = (item:IAdRecommendJobPostingDto) => (
    <MainRecruitCardFrame onClick={(e) => {
      const sns = jobPostingPositionList.flat().map((positionItem) => positionItem.jobPostingSn);
      jobsModel.jobPostingListSn = sns;
      history.push(RoutePaths.jobs_posting(item.jobPostingSn));
      if (item.adSn) {
        increaseAdsViewMutate(item.adSn);
      }

      e.stopPropagation();
    }}
    >
      <img
        src={`${item.representativeImageUrl}?w=240&h=240`}
        className='recruit-img'
        alt='img'
        onError={(e) => e.currentTarget.src = ImgDumy}

      />
      <div className='text-frame'>
        <div className='title'>
          {item.companyName}
        </div>
        <div className='subtitle'>
          {item.jobPostingName}
        </div>
        <div className='date-frame'>
          {item.postEndDateTime
        && (
        <div className='date-text'>
          {DateFormatYYYYMMDDCCHHMMUnit(item.postEndDateTime)}
        </div>
        )}

          {item.postEndDateTime
            ? (
              <div className='d-day-frame'>
                <div className='d-day-text'>{getDDayString(str2DateTime(item.postEndDateTime))}</div>
              </div>
            )
            : (
              <div className='d-day-frame'>
                <div className='d-day-text'>상시 채용</div>
              </div>
            )}
        </div>
      </div>
    </MainRecruitCardFrame>
  );

  return (
    <Frame>
      <TitleFrame>
        <div className='title'>지금 뜨는 공고</div>
      </TitleFrame>
      { jobPostingPositionList && jobPostingPositionList.length > 0 ? (
        <MainRecruitFrame>
          <JDSwiper
            slidesPerView={1}
            pagination={JDBullets}
            navigation
            onInit={() => {
              try {
                jobPostingPositionList[0].forEach((item:IAdRecommendJobPostingDto) => {
                  if (item.adSn) {
                    impressionAdsMutate(item.adSn);
                  }
                });
              } catch (e) {
                console.error(e);
              }
            }}
            onSlideChange={(slide) => {
              try {
                jobPostingPositionList[slide.realIndex].forEach((item:IAdRecommendJobPostingDto) => {
                  if (item.adSn) {
                    impressionAdsMutate(item.adSn);
                  }
                });
              } catch (e) {
                console.error(e);
              }
            }}
          >
            { jobPostingPositionList && jobPostingPositionList.length > 0 && jobPostingPositionList.map((item) => (
              <JDSwiperSlide key={item[0].jobPostingSn}>
                { item.map((innerItem) => (
                  <InnerFrame key={innerItem.jobPostingSn}>
                    { MainRecruitCard(innerItem) }
                  </InnerFrame>
                ))}
              </JDSwiperSlide>
            ))}
            <div className='pagination-bullets' />
          </JDSwiper>
        </MainRecruitFrame>
      ) : !loading ? <MainNoPosition /> : <></>}
    </Frame>
  );
};

export default inject(injectStore.main, injectStore.jobsModel)(observer(MainRecruit));
