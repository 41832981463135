import React, {FC, useRef} from "react";
import ParallaxFrame from "../../components/parallaxFrame";
import styled from "styled-components";
import { absoluteCenterCenter, absoluteHorizonCenter } from 'consts/style/mixins';

const StyledDiv = styled.button`
  text-align: center;
  font-size: 48px;
  background: none;
  height: 800px;
  width: 100%;
  color: black;
`;

const StyledViewSizeFrame = styled.div`
  width: 100vw;
  height: 100vh;
  position: sticky;
  top: 0;
  overflow: hidden;
`;

const StyledBackTextFrame = styled.div`
  position: absolute;
  top: 96px;
  ${absoluteHorizonCenter()};
  z-index: 0;
`;

const StyledFrontTextFrame = styled.div`
  position: absolute;
  ${absoluteCenterCenter()};
  z-index: 2;
`;

const StyledRounded = styled.div`
  width: 157%;
  padding-bottom: 157%;
  position: absolute;
  background: #6CDD83;
  transform: translate(-50%, 0) scale(5);
  left: 50%;
  top: 48%;
  border-radius: 50%;
  z-index: 1;
`;

const StyleParallaxFrame = styled(ParallaxFrame)`
    height: 2000px;
    margin: auto;
    .bg {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
      transition: background 1s;
    }
    b {
      margin-left: 30px;
      position: sticky;
      top: calc(50% - 42.5px);
      font-size: 64px;
    }
`;

const ScrollParallaxPage: FC = (() => {
  const bgRef = useRef<HTMLSpanElement>(null);
  const textRef = useRef<HTMLElement>(null);
  const topRef = useRef<number>(0);
  const bottomRef = useRef<number>(0);

  const greenBoxRef = useRef<HTMLDivElement>(null);
  const backTextBoxRef = useRef<HTMLDivElement>(null);
  const frontTextBoxRef = useRef<HTMLDivElement>(null);

  const moveText = (rate: number) => {
    if (rate < 20 || rate > 70) return;
    if (!textRef.current) return;
    textRef.current.style.marginLeft = `${30 + (rate - 20) * 3}px`;
  };

  const bgHandlerTop = (rate: number) => {
    topRef.current = rate;
    bgHandler();
  };

  const bgHandlerBottom = (rate: number) => {
    bottomRef.current = rate;
    bgHandler();
  };

  const bgHandler = () => {
    if (!bgRef.current) return;
    if (topRef.current < 50 || bottomRef.current > 50) {
      bgRef.current.style.background = 'none';
    } else {
      bgRef.current.style.background = 'yellow';
    }
  };

  const effectHandler = (rate: number) => {
    if(!greenBoxRef.current) return;
    if(!backTextBoxRef.current) return;
    if(!frontTextBoxRef.current) return;
    const scale = Math.max(5 - ((rate * 5) / 100), 1);
    greenBoxRef.current.style.transform = `translate(-50%, 0) scale(${scale})`;
    const top = Math.min((rate * 25) / 100, 20);
    frontTextBoxRef.current.style.top = `calc(50% + ${top}vh)`;
    backTextBoxRef.current.style.opacity = `${(rate - 60) / 20}`;
  };
  return (
    <div>
      <StyledDiv>
        아래로
      </StyledDiv>
      <StyleParallaxFrame
        onParallaxView={effectHandler}
      >
        <StyledViewSizeFrame>
          <StyledBackTextFrame ref={backTextBoxRef}>
            <div>취업의 시작과 의사결정은</div>
            <div>올바른 정보를 통해</div>
            <div>이뤄질 수 있습니다.</div><br />
            <div>단 하나의 올바른 정보가 당신의 취업의</div>
            <div>판도를 완전히 바꿔버릴 수도 있습니다.</div>
          </StyledBackTextFrame>
          <StyledRounded ref={greenBoxRef} />
          <StyledFrontTextFrame ref={frontTextBoxRef}>
            <div>JOBDA MISSION</div><br />
            <div>잡다는 취업 의사결정에 필요한</div>
            <div>올바른 정보를 모두에게</div>
            <div>제공하고자 노력합니다.</div>
          </StyledFrontTextFrame>
        </StyledViewSizeFrame>
      </StyleParallaxFrame>
      <StyledDiv>
        더 아래로
      </StyledDiv>
      <StyleParallaxFrame
        onParallax={moveText}
        onParallaxTop={bgHandlerTop}
        onParallaxBottom={bgHandlerBottom}
      >
        <span className='bg' ref={bgRef} />
        <b ref={textRef}>ABCDEFG</b>
      </StyleParallaxFrame>
      <StyledDiv>
        위로
      </StyledDiv>
    </div>
  );
});

export default ScrollParallaxPage;
