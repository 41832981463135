/* eslint-disable react-hooks/exhaustive-deps */
import { ReactComponent as IconReset } from 'assets/_v2/_common/input/icon_reset.svg';
import { ReactComponent as IconArrowDown } from 'assets/_v2/_common/input/icon_small_arrow_down.svg';
import { StyledSelectModalFrame, StyledSmallSelector } from 'components/_v2/position/filter/PositionJobTitleFilter';
import JDSquareCheckBox from 'components/_v2/_common/input/JDSquareCheckBox';
import JDUnderBaseModal from 'components/_v2/_common/modals/JDUnderBaseModal';
import { StyledIconSVG } from 'consts/assets/icons/iconPages';
import { CompanyLocation } from 'consts/CompanyLocationType';
import colors from '__designkit__/common/colors';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import PositionListModel from 'models/_v2/PositionListModel';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { PositionFiltersProps } from '../PostionFilterTab';

const Frame = styled.div`

`;

const PositionLocationFilter: FC<PositionFiltersProps> = ({ reloadPosition, filteredCount, positionListModel = new PositionListModel() }) => {
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const useformed = useForm({ mode: 'all' });
  const { watch, reset } = useformed;
  const [currentSelectList, setCurrentSelectList] = useState<string[]>([]);
  const [saveSelectList, setSaveSelectList] = useState<string[]>([]);

  useEffect(() => {
    if (positionListModel.isGlobalSearchedLocation) {
      const list = positionListModel.searchFilters.locations!.split(',');
      const resetList = Object.values(CompanyLocation).map((type) => {
        const find = list.find((value) => value === type.code);
        if (find) return find;
        return false;
      });
      reset({ locations: resetList });
      const tmpLst: [] = watch('locations');
      const filteredList = tmpLst.filter((value) => value !== false);
      setCurrentSelectList(filteredList);
      positionListModel.positionLocationSelectedList = filteredList;
      positionListModel.searchFilters.locations = filteredList.join(',');
      positionListModel.isGlobalSearchedLocation = false;
    }
  }, [positionListModel.isGlobalSearchedLocation]);

  const onChangeCheckbox = () => {
    const list: [] = watch('locations');
    const filteredList = list.filter((value) => value !== false);
    setCurrentSelectList(filteredList);
    positionListModel.positionLocationSelectedList = filteredList;
    positionListModel.searchFilters.locations = filteredList.join(',');
    reloadPosition();
  };

  const onClickReset = () => {
    reset({ locations: Object.values(CompanyLocation).map(() => false) });
    setCurrentSelectList([]);
    positionListModel.positionLocationSelectedList = [];
    positionListModel.searchFilters.locations = '';
    reloadPosition();
  };

  const onClickFilter = () => {
    // if (currentSelectList.length === 0) return;
    positionListModel.searchFilters.locations = currentSelectList.join(',');
    reloadPosition();
    setSaveSelectList(currentSelectList);
    setIsSelectOpen(false);
  };

  useEffect(() => {
    if (positionListModel.searchFilters.locations === '') setSaveSelectList([]);
  }, [positionListModel.searchFilters.locations]);

  useEffect(() => {
    if (isSelectOpen) {
      const list = positionListModel.searchFilters.locations!.split(',');
      const resetList = Object.values(CompanyLocation).map((type) => {
        const find = list.find((value) => value === type.code);
        if (find) return find;
        return false;
      });
      reset({ locations: resetList });
      if (list[0] === '')setCurrentSelectList([]);
      else setCurrentSelectList(list);
    }
  }, [isSelectOpen]);

  return (
    <Frame>
      <StyledSmallSelector
        count={positionListModel.positionLocationSelectedList.length}
        onClick={() => {
          setIsSelectOpen(true);
        }}
      >
        <span>지역{positionListModel.positionLocationSelectedList.length > 0 ? ` +${positionListModel.positionLocationSelectedList.length}` : ''}</span>
        <StyledIconSVG fill={colors.CG_70}><IconArrowDown /></StyledIconSVG>
      </StyledSmallSelector>
      <JDUnderBaseModal isOpen={isSelectOpen} onClickClose={() => setIsSelectOpen(false)}>
        <FormProvider {...useformed}>
          <StyledSelectModalFrame>
            <div className='title'>필터</div>
            <div className='content-frame'>
              <div className='group-frame'>
                <h1>지역선택 <span>(복수선택 가능)</span></h1>
                <div className='group-items'>
                  {
                Object.values(CompanyLocation).filter((location) => location.code !== '10000').map((location, index) => (
                  <JDSquareCheckBox
                    key={`check-option-locations-${location.code}`}
                    name={`locations.${index}`}
                    value={location.code}
                    label={<span>{location.name}</span>}
                    onChange={onChangeCheckbox}
                  />
                ))
              }
                </div>
              </div>
            </div>
            <div className='btn-frame'>
              <button className='btn-reset' onClick={onClickReset}><IconReset />필터 초기화</button>
              <button
                className='btn-search'
                onClick={onClickFilter}
              >{`검색(${filteredCount()})건`}
              </button>
            </div>
          </StyledSelectModalFrame>
        </FormProvider>
      </JDUnderBaseModal>
    </Frame>
  );
};

export default inject(injectStore.positionListModel)(observer(PositionLocationFilter));
