import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { ConditionRegisterType } from 'consts/_v2/profile/ProfileType';
import IComponentProps from 'interfaces/props/IComponentProps';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import useProfileConditions from 'query/profiles/useProfileConditionsQuery';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import RoutePaths from 'consts/RoutePaths';
import { useHistory } from 'react-router';
import Context from 'models/Context';
import JobTab, { ChildJobTabHandles } from './preferredJobTab';
import LocationTab, { ChildLocationHandles } from './preferredLocationTab';
import SalaryTab, { ChildSalaryTabHandles } from './preferredSalaryTab';
import CareerEntryOptionsModal from './preferredCareer';
import PreferredResult from './PreferredResult';
import { ChildWorkingPreferenceTabHandles } from './preferredWorkingPreferenceTab';

const MainFrame = styled.div`
  height: calc(100% - 134px);
  
  .progressBar {
    display: flex;
    position: sticky;
    top: 56px;
    height: 4px;
    background-color: #F1F3F5;
    z-index: 1;

    .progress {
      height: 4px;
      background-color: ${Colors.CG_90};
      border-radius: 4px;
      transition: width 0.3s ease;
    }
  }

  .step-inner {
    padding: 0 16px 78px 16px;
    height: 100%;
    .action-btn {
      position: fixed;
      bottom: 0;
      left: 0;
      display: flex;
      gap: 8px;
      padding: 16px 16px calc(env(safe-area-inset-bottom) + 16px) 16px;
      width: 100%;
      height: 78px;
      background-color: ${Colors.WHITE_100};

      .btnBasic {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 46px;
        border-radius: 4px;
        font: ${Fonts.B2_Bold};

        &.btnBack {
          border: 1px solid ${Colors.JOBDA_BLACK};
          color: ${Colors.JOBDA_BLACK};
          
          &:disabled {
            border: 1px solid ${Colors.CG_50};
            background-color: ${Colors.WHITE_100};
            color: ${Colors.CG_50};
          }
        }

        &.btnNext {
          color: ${Colors.WHITE_100};
          background-color: ${Colors.JOBDA_BLACK};

          &:disabled {
            background-color: ${Colors.CG_40};
            color: ${Colors.CG_60};
          }
        }
      }

      .btnDone {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 46px;
        border-radius: 4px;
        background-color: ${Colors.JOBDA_BLACK};
        font: ${Fonts.B2_Bold};
        color: ${Colors.WHITE_100};
      }
    }
  }
`;

interface IPreferredStepProps extends IComponentProps {
  login?: Login;
  context?:Context
}

const PreferredStep: FC<IPreferredStepProps> = ({ login = new Login(), context = new Context() }) => {
  const [conditionRegisterType, setConditionRegisterType] = useState<ConditionRegisterType>(ConditionRegisterType.LIST);
  const { data: profileConditionsData } = useProfileConditions(!!login.userInfo);
  const history = useHistory();
  const loadJobTabRef = useRef<ChildJobTabHandles>(null);
  const loadLocationTabRef = useRef<ChildLocationHandles>(null);
  const loadSalaryTabRef = useRef<ChildSalaryTabHandles>(null);
  const loadWorkingPreferenceTabRef = useRef<ChildWorkingPreferenceTabHandles>(null);
  const totalSteps = 5;
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [isNextDisabled, setIsNextDisabled] = useState<boolean>(true);
  const [isPreviousDisabled, setIsPreviousDisabled] = useState<boolean>(true);

  const getProgressWidth = () => {
    return `${((currentStep + 1) / totalSteps) * 100}%`;
  };

  const handleNextStep = async () => {
    if (currentStep < totalSteps - 1 && !isNextDisabled) {
      if (currentStep === 0) {
        if (loadJobTabRef.current) {
          await loadJobTabRef.current?.handleSaveJobButton();
          setCurrentStep((prevStep) => prevStep + 1);
        }
      } else if (currentStep === 1) {
        if (loadLocationTabRef.current) {
          await loadLocationTabRef.current?.handleSaveLocation();
          setCurrentStep((prevStep) => prevStep + 1);
        }
      } else if (currentStep === 2) {
        if (loadSalaryTabRef.current) {
          await loadSalaryTabRef.current?.handleSaveSalary();
          setCurrentStep((prevStep) => prevStep + 1);
        }
      } else if (currentStep === 3) {
        if (loadWorkingPreferenceTabRef.current) {
          await loadWorkingPreferenceTabRef.current?.handleSavePreference();
          setCurrentStep((prevStep) => prevStep + 1);
        }
      } else {
        setCurrentStep((prevStep) => prevStep + 1);
      }
    }
  };

  const handlePreviousStep = () => {
    if (currentStep > 0 && !isPreviousDisabled) {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  const handleDone = () => {
    history.push(RoutePaths.profile);
  };

  useEffect(() => {
    setIsPreviousDisabled(currentStep === 0);
    setIsNextDisabled(currentStep === totalSteps - 1);
  }, [currentStep]);

  return (
    <MainFrame>
      <div className='progressBar'>
        <div className='progress' style={{ width: getProgressWidth() }} />
      </div>

      <div className='step-inner'>
        {currentStep === 0 && (
          <JobTab
            ref={loadJobTabRef}
            setConditionRegisterType={setConditionRegisterType}
            jobGroupData={profileConditionsData?.jobGroups}
          />
        )}
        {currentStep === 1 && (
          <LocationTab
            ref={loadLocationTabRef}
            setConditionRegisterType={setConditionRegisterType}
            locationData={profileConditionsData?.locations}
          />
        )}
        {currentStep === 2 && (
        <SalaryTab
          ref={loadSalaryTabRef}
          setConditionRegisterType={setConditionRegisterType}
          salaryData={profileConditionsData?.salary}
        />
        )}
        {currentStep === 3 && (
          <CareerEntryOptionsModal
            preferenceRef={loadWorkingPreferenceTabRef}
          />
        )}
        {currentStep === 4 && (
          <PreferredResult />
        )}

        <div className='action-btn'>
          {currentStep === totalSteps - 1 ? (
            <button className='btnDone' onClick={handleDone}>
              프로필 확인하기
            </button>
          ) : (
            <>
              <button className='btnBasic btnBack' onClick={handlePreviousStep} disabled={isPreviousDisabled}>
                이전
              </button>
              <button
                className='btnBasic btnNext'
                onClick={handleNextStep}
                disabled={!context.isPreferredClicked}
              >
                {currentStep === 3 ? '프로필 작성 완료' : '다음'}
              </button>
            </>
          )}
        </div>
      </div>
    </MainFrame>
  );
};

export default inject(injectStore.login, injectStore.profileModel, injectStore.context)(observer(PreferredStep));
