import { ErrorMessage } from '@hookform/error-message';
import IconCalender from 'assets/icon/icon_calendar_20_Regular.svg';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { JDBaseInputMixin } from 'consts/_v2/_common/style/mixins';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { forwardRef, useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { DateUtil } from './datePicker/DateUtil';
import { DatePicker, MonthPicker } from './datePicker/JDDatePicker';

const Frame = styled.div <{ disabled?: boolean, value?: boolean, month?:boolean, fullSize?:boolean}>`
  ${JDBaseInputMixin()};
  ${(props) => props.disabled && 'opacity:0.5;'}
  ${(props) => (props.month ? (props.fullSize ? css`width:100%;` : css`width:149px;`) : css`width:100%;`)}
  
  >div{
    display: flex;
    align-items: center;
    .input-calendar[type='date'], .input-calendar[type='month']{
      padding:0px 0px 0px 16px;
      -webkit-appearance: none;
      -moz-appearance: none;
      color:transparent;
      font: ${Fonts.B1_Medium};
      &::-webkit-datetime-edit {
        opacity: 0;
      }

    }

    .input-calendar[type='date']::-webkit-calendar-picker-indicator, .input-calendar[type='month']::-webkit-calendar-picker-indicator {
      background: transparent;
      bottom: 0;
      color: transparent;
      cursor: pointer;
      height: 100%;
      width: 100%;
      left: -30px;
      position: absolute;
      right: 0;
      top: 0;
    }
    .input-calendar[type='date']::-webkit-datetime-edit,.input-calendar[type='month']::-webkit-datetime-edit {
    opacity: 0;
    }
    .input-calendar[type='date']::-webkit-inner-spin-button, .input-calendar[type='month']::-webkit-inner-spin-button{
      display: block;
    }

    label{
      display:block;
      position:absolute;
      top: 18px;
      margin-left:16px;
      left:0;
      ${(props) => (props.value ? ` color:${colors.CG_90};` : ` color:${colors.CG_60};`)}

      font: ${Fonts.B1_Medium};
    }

    img{
      display:block;
      position:absolute;
      top: 14px;
      margin-right:16px;
      right:0;
      color:rgba(101, 106, 113, 1);
    }
  }


`;

interface IJDDatePickerInput extends IComponentProps {
  name: string;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  month?: boolean;
  customValidator?: any;
  defaultValue?: any;
  fullSize?:boolean;
}

const JDDatePickerInput = forwardRef((props: IJDDatePickerInput, fieldRef) => {
  const { name, fullSize, customValidator, placeholder, defaultValue, disabled, className, month = false, minDate = undefined, maxDate = undefined, ...rest } = props;
  const useFormed = useFormContext();
  const { control, errors, watch, setValue } = useFormed;
  const { field, meta } = useController({ name, control, defaultValue });

  useEffect(() => {
    setValue(name, defaultValue);
  }, [defaultValue]);
  const selected = field.value ? new Date(field.value) : null;

  const handleChange = (date: Date | null) => {
    if (!date) return;
    if (month) {
      field.onChange(DateUtil.format(date, 'yyyy-MM'));
    } else {
      field.onChange(DateUtil.format(date, 'yyyy-MM-dd'));
    }
  };
  return (
    <Frame fullSize={fullSize} className={`jda-input ${className || ''} ${!meta.invalid}`} disabled={disabled} value={watch(name)} month={month}>
      <div>
        <img className='input-calender' alt='달력' src={IconCalender} />

        {month
          ? (
            <MonthPicker
              selected={selected}
              onChange={handleChange}
              placeholderText={placeholder || '연도. 월'}
              minDate={minDate ? new Date(minDate) : new Date('1900-01-01')}
              maxDate={maxDate ? new Date(maxDate) : new Date('9999-12-30')}
              className='input-calendar-month'
              disabled={disabled}
            />
          )
          : (
            <DatePicker
              selected={selected}
              onChange={handleChange}
              placeholderText={placeholder || '연도. 월. 일'}
              minDate={minDate ? new Date(minDate) : new Date('1900-01-01')}
              maxDate={maxDate ? new Date(maxDate) : new Date('9999-12-30')}
              className='input-calendar-date'
              disabled={disabled}
            />
          )}

      </div>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <h4 className='message false'>{message}</h4>}
      />
    </Frame>
  );
});

export default JDDatePickerInput;
