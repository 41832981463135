import { ErrorMessage } from '@hookform/error-message';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import TextField from '__designkit__/components/TextField';
import Icon from '__designkit__/icon/Icon';
import CompanyAPI from 'api/companyAPI';
import IComponentProps from 'interfaces/props/IComponentProps';
import { INameSnRs } from 'interfaces/rqrs/ICommonRqRs';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { debounce } from 'utils/CommonUtils';

const Frame = styled.div`
  position: relative;
  width: 100%;

  & .company-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 200px;
    padding: 6px 0;
    overflow-y: auto;
    background-color: ${Colors.WHITE_100};
    border: 1px solid ${Colors.CG_30};
    border-radius: 8px;
    box-shadow: 0px 8px 16px rgba(0,0,0,0.12);
    z-index: 10;

    & div {
      width: 100%;
      height: fit-content;
      padding: 12px 16px;
      font: ${Fonts.B2_Medium};
      user-select: none;

      &:active {
        background-color: ${Colors.CG_30};
      }
    }
  }
`;

interface CompanySearchFieldProps extends IComponentProps {
  name: string; // 필수값, react-hook-form에서 사용하는 name 값
  defaultValue?: string; // 선택값, 지정시 초기값으로 설정
  disabled?: boolean; // 선택값, 지정시 수정 불가
}

const CompanySearchField: FC<CompanySearchFieldProps> = ({ name, defaultValue, disabled }) => {
  const [searchValue, setSearchValue] = useState<string | undefined>();
  const [isSelected, setIsSelected] = useState<string | undefined>();
  const [companyList, setCompanyList] = useState<INameSnRs[]>([]);
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [realErrorMessage, setRealErrorMessage] = useState<string>('');
  const ref = useRef<HTMLDivElement>(null);

  const { setValue, formState, clearErrors, trigger, watch, errors } = useFormContext();
  const { field } = useController({ name });

  const controlValue = (value: string) => { setSearchValue(value); setIsSelected(value); setValue(name, value, { shouldValidate: true }); setIsFocus(false); };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        setIsFocus(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [ref]);

  useEffect(() => {
    debounce(async () => {
      if (!searchValue) setCompanyList([]);
      else {
        const res = await CompanyAPI.getCompanies({ keyword: searchValue });
        setCompanyList(res.companies);
      }
    }, 400);
  }, [searchValue]);

  useEffect(() => {
    if (defaultValue) setSearchValue(defaultValue);
    if (name) trigger(name);
    trigger(name);
  }, [defaultValue, name]);
  return (
    <Frame ref={ref}>
      <TextField
        {...field}
        leftChildren={<Icon name='search' size={24} color={Colors.JOBDA_BLACK} />}
        rightChildren={isFocus && searchValue && <Icon name='close' size={24} color={Colors.JOBDA_BLACK} onClick={() => controlValue('')} />}
        errorMessage={formState.errors[name]?.message || realErrorMessage}
        value={searchValue}
        placeholder='직장을 검색해 주세요.'
        onChange={(e) => { setSearchValue(e.target.value); setValue(name, e.target.value); }}
        onFocus={() => { setIsFocus(true); clearErrors(name); }}
        disabled={disabled}
        autoComplete='off'
      />
      { isFocus && searchValue && searchValue !== isSelected && (
        <div className='company-list' role='button' onContextMenu={(e) => e.preventDefault()}>
          { companyList.map((company) => (
            <div key={company.sn} role='button' onClick={() => controlValue(company.name)}>{ company.name }</div>
          ))}
          <div role='button' onClick={() => controlValue(searchValue)}>직접 입력</div>
        </div>
      )}
      {
        !formState.errors[name]?.message && (
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => { setRealErrorMessage(message); return null; }}
          />
        )
      }
    </Frame>
  );
};

export default CompanySearchField;
