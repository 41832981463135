import confettiLottie from '__designkit__/assets/lottie/lottie_confetti.json';
import { Button, ButtonMode } from '__designkit__/button/Button';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import JDDimModal from 'components/_v2/_common/modals/JDDimModal';
import { Divider1G40 } from 'components/divider/Divider';
import RoutePaths from 'consts/RoutePaths';
import IComponentProps from 'interfaces/props/IComponentProps';
import Lottie from 'lottie-react';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import RenderCheckUtil from 'utils/RenderCheckUtil';

const Frame = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: fit-content;
  padding: 32px 24px calc(24px + env(safe-area-inset-bottom));
  border-radius: 12px 12px 0 0;
  background-color: ${Colors.WHITE_100};
  z-index: 10005;
`;

const TitleFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 24px;
  text-align: center;

  & > div.title-box {
    padding: 2px 8px;
    background: linear-gradient(90deg, #4CD768 0.31%, #41CDC6 100.31%);
    font: ${Fonts.B1_Bold};
    color: ${Colors.WHITE_100};
  }

  & > div.title {
    font: ${Fonts.H4_Bold};
    color: ${Colors.JOBDA_BLACK};
  }

  & > div.description {
    padding-top: 12px;
    font: ${Fonts.B2_Medium_P};
    color: ${Colors.CG_70};

    & > span {
      font: ${Fonts.B2_Bold_P};
      color: ${Colors.JOBDA_BLACK};
    }
  }
`;

const MissionProgressFrame = styled.div<{ percent: number }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px 0;

  & > div.mission-complete-percent {
    display: flex;
    align-items: center;
    font: ${Fonts.B3_Medium};
    color: ${Colors.CG_70};

    & > span {
      color: ${Colors.JOBDA_BLACK};
    }
  }

  & > div.mission-progress-bar-frame {
    display: flex;
    width: 100%;
    height: 16px;
    border-radius: 8px;
    background-color: ${Colors.CG_30};

    & > div.mission-progress-bar {
      position: relative;
      width: 100%; 
      height: 100%;
      border-radius: 8px;
      background: linear-gradient(90deg, #4CD768 0.31%, #41CDC6 100.31%);
      transform-origin: left; /* scaleX 변환의 기준점을 왼쪽으로 설정 */
      transform: scaleX(${(props) => props.percent / 100}); /* 백분율을 scaleX 값으로 변환 */
      transition: transform 0.5s ease-in-out; /* transform 속성에 대한 전환 효과 적용 */

      & > div.lottie {
        position: absolute;
        top: 50%;
        right: 0;
        transform: scaleX(${(props) => (props.percent === 0 ? 0 : 1 / (props.percent / 100))}) translateX(50%) translateY(-50%);
        width: 80px;
        height: 80px;
      }
    }
  }
`;

const MissionListFrame = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > div.mission-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    & > div.mission-title {
      display: flex;
      align-items: flex-end;
      gap: 4px;
      font: ${Fonts.B1_Bold};
      color: ${Colors.JOBDA_BLACK};

      & > span {
        font: ${Fonts.B4_Medium};
        color: ${Colors.CG_70};
      }
    }

    & > div.mission-check {
      display: flex;
      align-items: center;
      gap: 4px;
      min-width: 64px;
      font: ${Fonts.B3_Medium};
      color: ${Colors.JOBDA_BLACK};
    }
  }
`;

const BtnFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  height: fit-content;
  padding-top: 24px;

  & > div.ignore-for-week-btn {
    display: flex;
    align-items: center;
    gap: 4px;
    padding-bottom: 1px;
    border-bottom: 1px solid ${Colors.CG_80};
    font: ${Fonts.B3_Medium};
    color: ${Colors.CG_80};
  }
`;

interface ITalentpoolMissionModalProps extends IComponentProps {
  isCompleteProfile: boolean;
  isCompleteAcc: boolean;
  onClickClose: () => void;
  login?: Login;
}

const TalentpoolMissionModal: FC<ITalentpoolMissionModalProps> = ({ isCompleteProfile, isCompleteAcc, onClickClose, login = new Login() }) => {
  const history = useHistory();
  const [percent, setPercent] = useState<number>(0);
  const [progressBarWidth, setProgressBarWidth] = useState<number>(0);

  // TODO: 인재풀 인원 수 받아오기

  const onClickBtn = async () => {
    try {
      if (isCompleteProfile && isCompleteAcc) {
        await login.changeMatchConfigStatus(true);
        await login.getMatchConfigStatus();
        history.push(`${RoutePaths.match_apply}?fromToggle=true`);
      } else if (isCompleteProfile) {
        if (login.profileType?.generalYn) {
          history.push(RoutePaths.acca_test);
        } else if (login.profileType?.developerYn) {
          history.push(RoutePaths.phs_test);
        }
      } else {
        history.push(RoutePaths.profile);
      }
      onClickClose();
    } catch (error) {
      console.error(error);
    }
  };

  const onClickSubBtn = async () => {
    await RenderCheckUtil.setRenderCheck('talentpool-induce', 7);
    onClickClose();
  };

  useEffect(() => {
    setPercent(isCompleteProfile && isCompleteAcc ? 100 : 50);
  }, [isCompleteProfile, isCompleteAcc]);

  useEffect(() => {
    setTimeout(() => {
      setProgressBarWidth(percent);
    }, 100);
  }, [percent]);

  return (
    <JDDimModal dimClickable onClickClose={onClickClose}>
      <Frame>
        <TitleFrame>
          <div className='title-box'>MISSON 보상</div>
          <SpacingBlock size={8} vertical />
          <div className='title'>750개 기업의 입사 제안</div>
          { isCompleteProfile && isCompleteAcc && (
            <div className='description'>
              {login.userDefault?.name || '회원'}님, 미션 달성을 축하해요!<br /><span>아래 버튼을 누르면 바로 제안이 시작돼요.</span>
            </div>
          )}
        </TitleFrame>
        <Divider1G40 />
        <MissionProgressFrame percent={progressBarWidth}>
          <div className='mission-complete-percent'>
            미션 달성률 : &nbsp;<span>{percent}%</span>
          </div>
          <div className='mission-progress-bar-frame'>
            <div className='mission-progress-bar'>
              <div className='lottie'>
                <Lottie animationData={confettiLottie} loop={false} initialSegment={[15, 120]} />
              </div>
            </div>
          </div>
        </MissionProgressFrame>
        <MissionListFrame>
          <div className='mission-item'>
            <div className='mission-title'>
              프로필 필수 항목 작성
            </div>
            <div className='mission-check'>
              <Icon name='check-circle-filled' size={24} color={isCompleteProfile ? Colors.G_150 : Colors.CG_50} />
              {isCompleteProfile ? '완료' : '미완료'}
            </div>
          </div>
          <div className='mission-item'>
            <div className='mission-title'>
              역량검사 응시
              <span>(PC만 가능)</span>
            </div>
            <div className='mission-check'>
              <Icon name='check-circle-filled' size={24} color={isCompleteAcc ? Colors.G_150 : Colors.CG_50} />
              {isCompleteAcc ? '완료' : '미완료'}
            </div>
          </div>
        </MissionListFrame>
        <BtnFrame>
          <Button label={isCompleteProfile && isCompleteAcc ? '입사 제안 받기' : isCompleteProfile ? '역량검사 알아보기' : '1분만에 프로필 작성하기'} buttonMode={ButtonMode.PRIMARY} size='large' onClick={onClickBtn} />
          <div className='ignore-for-week-btn' role='button' onClick={onClickSubBtn}>
            일주일 간 보지 않기
            <Icon name='close' size={16} color={Colors.JOBDA_BLACK} />
          </div>
        </BtnFrame>
      </Frame>
    </JDDimModal>
  );
};

export default inject(injectStore.login)(observer(TalentpoolMissionModal));
