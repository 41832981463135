import imgBuilding from 'assets/_v2/positionJd/icon_building.svg';
import imgMoney from 'assets/_v2/positionJd/icon_circle_money.svg';
import imgFlag from 'assets/_v2/positionJd/icon_flag_yellow.svg';
import imgLocationRed from 'assets/_v2/positionJd/icon_location_red.svg';
import imgPeople from 'assets/_v2/positionJd/icon_people_blue.svg';
import imgPresentation from 'assets/_v2/positionJd/icon_presentation_2.svg';
import imgStar from 'assets/_v2/positionJd/icon_star_person.svg';
import LazyImage from 'components/common/LazyImage';
import JDPopover from 'components/_v2/_common/popover/JDPopover';

import colors from '__designkit__/common/colors';
import { BusinessSizeTypeText } from 'consts/_v2/positionJd/BusinessSize';
import Fonts from '__designkit__/common/fonts';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import PositionJdModel from 'models/_v2/PositionJdModel';
import React, { FC } from 'react';
import styled from 'styled-components';
import { DateFormatYYMMDDUnit } from 'utils/DateUtils';
import { get을or를 } from 'utils/Hangul';
import { formatNumber, formatNumberToKorean, krNumberFormat } from 'utils/NumberFormatUtil';
import TextUtil from 'utils/TextUtil';

const Title = styled.div`
  font: ${Fonts.H5_Bold};
  margin-top: 32px;
`;

const TextUpdate = styled.div`
  font: ${Fonts.B3_Medium};
  color: ${colors.CG_60};
  margin-top: 8px;

`;
const PopOverFrame = styled.div`
      color:${colors.WHITE_100};
      >h1{
      font: ${Fonts.B3_Bold};
    }
`;
const Frame = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    .company_detail{
        justify-content: flex-start;
        flex-direction: column;
        margin-top: 24px;
     }
    .img{
        width:40px;
        height: 40px;
    }
    .company_detail_title{
        margin-top: 12px;
        font: ${Fonts.B3_Medium};
        color:${colors.CG_70}
    }
    .company_detail_content{
        margin-top: 4px;
        font: ${Fonts.B2_Bold};
    }
`;

const AnchorFrame = styled.div`
  .anchor-frame {
    height: auto;
  }
`;
interface IPositionJdCompanyInfoTwoProps{
    positionJdModel? : PositionJdModel
}

const PositionJdCompanyInfoTwo:FC<IPositionJdCompanyInfoTwoProps> = ({ positionJdModel = new PositionJdModel() }) => {
  const { companyInfo } = positionJdModel;
  const EllipsisLength:number = 15;
  const FormatNumberLimit:number = 100000000;
  return (
    <>
      {companyInfo
        ? (
          <>
            <AnchorFrame>
              {companyInfo.companyName.length > EllipsisLength
                ? (
                  <JDPopover useOutsideClick position='under_no_left' popoverMargin={32} anchorIcon={<Title>{`${TextUtil.textLengthEllipsis(companyInfo.companyName, EllipsisLength) + get을or를(TextUtil.textLengthEllipsis(companyInfo.companyName, 15))} 소개합니다!`}</Title>}>
                    <PopOverFrame>
                      <h1>{companyInfo.companyName}</h1>
                    </PopOverFrame>
                  </JDPopover>
                )

                : <Title>{`${companyInfo.companyName + get을or를(companyInfo.companyName)} 소개합니다!`}</Title>}
              {companyInfo?.lastModifiedDate && <TextUpdate>업데이트 일자: {companyInfo?.lastModifiedDate.replace(/-/g, '.')}</TextUpdate>}
            </AnchorFrame>
            <Frame>
              <div className='company_detail'>
                <LazyImage src={imgStar} className='img' />
                <div className='company_detail_title'>대표자명</div>
                <div className='company_detail_content'>{companyInfo.ceoName ? TextUtil.textLengthEllipsis(companyInfo.ceoName, EllipsisLength) : '-'}</div>
              </div>

              <div className='company_detail'>
                <LazyImage src={imgMoney} className='img' />
                <div className='company_detail_title'>전체 종업원 평균 연봉</div>
                <div className='company_detail_content'>{companyInfo.averageSalary ? formatNumber(Math.floor(companyInfo.averageSalary)) : '-'}{companyInfo.averageSalary ? '원' : ''}</div>
              </div>

              <div className='company_detail'>
                <LazyImage src={imgPeople} className='img' />
                <div className='company_detail_title'>직원수</div>
                <div className='company_detail_content'>{companyInfo.numberOfEmployees ? `${krNumberFormat(companyInfo.numberOfEmployees)}명` : '-'}</div>
              </div>

              <div className='company_detail'>
                <LazyImage src={imgBuilding} className='img' />
                <div className='company_detail_title'>기업구분</div>
                <div className='company_detail_content'>{companyInfo.businessSize ? BusinessSizeTypeText[companyInfo.businessSize] : '-'}</div>
              </div>

              <div className='company_detail'>
                <LazyImage src={imgPresentation} className='img' />
                <div className='company_detail_title'>매출액</div>
                <div className='company_detail_content'>{companyInfo.revenue ? (companyInfo.revenue > FormatNumberLimit ? `${formatNumberToKorean(Math.floor(companyInfo.revenue / FormatNumberLimit) * FormatNumberLimit)}원` : `${formatNumberToKorean(companyInfo.revenue)}원`) : '-'}</div>
              </div>

              <div className='company_detail'>
                <LazyImage src={imgLocationRed} className='img' />
                <div className='company_detail_title'>위치</div>
                {!companyInfo.location
                  ? <div className='company_detail_content'>-</div>
                  : (companyInfo.location.length > EllipsisLength
                    ? (
                      <JDPopover useOutsideClick position='under' anchorIcon={<div className='company_detail_content'>{TextUtil.textLengthEllipsis(companyInfo.location, EllipsisLength)}</div>}>
                        <PopOverFrame>
                          <h1>{companyInfo.location}</h1>
                        </PopOverFrame>
                      </JDPopover>
                    )

                    : <div className='company_detail_content'>{TextUtil.textLengthEllipsis(companyInfo.location, EllipsisLength)}</div>)}

              </div>

              <div className='company_detail'>
                <LazyImage src={imgFlag} className='img' />
                <div className='company_detail_title'>설립일</div>
                <div className='company_detail_content'>{companyInfo.establishDate ? DateFormatYYMMDDUnit(companyInfo.establishDate) : '-'}</div>
              </div>

            </Frame>
          </>
        )
        : <></>}
    </>
  );
};
export default inject(injectStore.positionJdModel)(observer(PositionJdCompanyInfoTwo));
