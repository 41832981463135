import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import RoutePaths from 'consts/RoutePaths';
import Icon from '__designkit__/icon/Icon';
import Fonts from '__designkit__/common/fonts';
import colors from '__designkit__/common/colors';

const MatchingIconFrame = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    margin: 16px 16px 12px 16px;
    height: 88px;

    .icon-frame {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 80px;

        .name {
            font: ${Fonts.B3_Medium};
            color: ${colors.JOBDA_BLACK};
            margin-top: 8px;
        }
    }
`;

const MainMatching = () => {
  const history = useHistory();
  return (
    <MatchingIconFrame>
      <button className='icon-frame' onClick={() => history.push(RoutePaths.profile)}>
        <Icon name='info-user' size={48} />
        <span className='name'>내 프로필</span>
      </button>
      <button className='icon-frame' onClick={() => history.push(RoutePaths.acca_test)}>
        <Icon name='acca-home' size={48} />
        <span className='name'>역량검사</span>
      </button>
      <button className='icon-frame' onClick={() => history.push(RoutePaths.mypage_apply)}>
        <Icon name='resume' size={48} />
        <span className='name'>지원현황</span>
      </button>
      <button className='icon-frame' onClick={() => history.push(RoutePaths.match_apply)}>
        <Icon name='matching' size={48} />
        <span className='name'>매칭현황</span>
      </button>
    </MatchingIconFrame>
  );
};

export default MainMatching;
