/* eslint-disable import/prefer-default-export */
interface RequestOptions<T> extends RequestInit {
    type?: 'json' | 'text' | 'blob' | 'formData' | 'arrayBuffer';
    onError?: (error: Error) => void;
    onSuccess?: (data: T) => void;
    params?: Record<string, string>;
    endDate?: Date;
  }

const defaultOptions = {
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
};

export const request = async <T>(url: string, options: RequestOptions<T> = {}) => {
  const { type, onError, onSuccess, params, endDate, ...restOptions } = options;
  if (endDate && endDate.getTime() < Date.now()) {
    throw new Error('TIMEOUT_ERROR');
  }

  const newOptions = { ...defaultOptions, ...restOptions };
  const queryString = new URLSearchParams(params ?? {}).toString();
  const fullUrl = queryString ? `${url}?${queryString}` : url;

  return fetch(fullUrl, newOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }

      switch (type) {
        case 'text':
          return response.text();
        case 'blob':
          return response.blob();
        case 'formData':
          return response.formData();
        case 'arrayBuffer':
          return response.arrayBuffer();
        case 'json':
        default:
          return response.json();
      }
    })
    .then((data: T) => {
      onSuccess?.(data);
      return data;
    })
    .catch((error) => {
      console.error(error);
      onError?.(error);
    });
};
