import React from 'react';
import FlowList from '__pc__/page/accaIntroduce/flowStory/flowList';

import classnames from 'classnames/bind';
import styles from './interview.module.scss';

const cx = classnames.bind(styles);

const Interview = () => (
  <div className={cx('contentInner')}>
    <div className={cx('basicTitle')}>
      <p className={cx('subTitle')}>JOBDA로 취업을 준비한</p>
      <p className={cx('mainTitle')}>100만 명 선배들의<br />Story를 확인해보세요.</p>
    </div>

    <FlowList />
  </div>
);

export default Interview;
