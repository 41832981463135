import PositionRecruitmentType from 'consts/PositionType';
import colors from '__designkit__/common/colors';
import { RecruitmentType, RecruitmentTypeText } from 'consts/_v2/position/PositionType';
import Fonts from '__designkit__/common/fonts';
import React, { FC } from 'react';
import styled from 'styled-components';
import { getDCount } from 'utils/DateUtils';
import { krNumberFormat } from 'utils/NumberFormatUtil';
import JDPopover from 'components/_v2/_common/popover/JDPopover';
import { LocationCode } from 'consts/CompanyLocationType';
import { lineClampBox } from 'consts/_v2/_common/style/mixins';

const TagFrame = styled.ul<{JdCase?:boolean, companyCase?:boolean}>`
    ${lineClampBox(2)}
    font: ${(props) => (props.JdCase ? Fonts.B3_Medium : Fonts.B3_Medium)}; 
    overflow: ${(props) => (props.JdCase ? 'visible' : 'hidden')}; 
    
    color: ${colors.CG_60};
    .popover-frame{
    color:${colors.WHITE_100};
      .popover-text{
        font: ${Fonts.B3_Medium};
        line-height:16px;
        color:${colors.CG_50};
        &:not(:last-child){
          margin-bottom: 6px;
        }
      }
  }
  .divider{
    width: 1px;
    height: 8px;
    margin: 0 8px;
    background-color: ${colors.CG_40};
    display: inline-block;
  }
  
    li {
      display: inline;
      margin-right: 4px;
      margin-bottom:${(props) => (props.companyCase ? '0px' : '8px')};
      font: ${Fonts.B3_Medium};
      line-height: 18px;
      word-break: break-all;
      .plus{
        display: inline-block;
        margin-left: 3px;
        color: ${(props) => (props.JdCase ? `${colors.JOBDA_BLACK}` : `${colors.CG_60}`)};
      }
    }

    .anchor-frame {
      display: inline;
    }
    
`;

interface IPositionTagList {
    recruitmentType: PositionRecruitmentType | RecruitmentType;
    jobTitleNames?: string[];
    closingDateTime: string;
    minSalary?: number;
    maxSalary?: number;
    JdCase?: boolean;
    locationCode?:number;
    companyCase?:boolean
}

const PositionTagList:FC<IPositionTagList> = ({ companyCase, recruitmentType, jobTitleNames, closingDateTime, minSalary, maxSalary, locationCode, JdCase }) => {
  const getDdate = (time:string) => {
    if (!time) return '상시채용';
    const dCount = getDCount(time);
    if (dCount === 0) return `오늘마감`;
    if (dCount > 0) return `마감${dCount}일지남`;
    if (dCount < 0) return `마감${dCount * -1}일남음`;
  };
  return (
    <TagFrame JdCase={JdCase} companyCase={companyCase}>
      {locationCode && <><li>{`${LocationCode(locationCode)}`}</li>{(recruitmentType || closingDateTime) && <li className='divider' />}</>}
      {recruitmentType && <><li>{`${RecruitmentTypeText[recruitmentType]}`}</li>{closingDateTime && <li className='divider' />}</>}
      {closingDateTime && <li>{`${getDdate(closingDateTime)}`}</li>}
      {/* 직군 임시 제거 */}
      {/* {jobTitleNames && jobTitleNames.length > 0
        && (
        <li>
          <>{jobTitleNames[0] && `#${jobTitleNames[0]}`}
            {JdCase
              ? (
                <JDPopover useOutsideClick position='non_pony_under' popoverWidth={100} anchorIcon={<div className='plus'>{jobTitleNames.length > 1 && ` +${jobTitleNames.length - 1}`}</div>}>
                  <div className='popover-frame'>
                    {jobTitleNames.map((job, index) => (
                      index >= 1 && (<div className='popover-text'>{job}</div>)
                    ))}
                  </div>
                </JDPopover>
              )
              : <div className='plus'>{jobTitleNames.length > 1 && ` +${jobTitleNames.length - 1}`}</div>}
          </>
        </li>
        )} */}
      {/* {(maxSalary && maxSalary > 0 && minSalary !== null) ? <li>{`#연봉${krNumberFormat(minSalary)}~${krNumberFormat(maxSalary)}만원`}</li> : ''} */}
    </TagFrame>
  );
};

export default PositionTagList;
