type ConvertParamType = Date | number | string | null;

export class DateUtil {
  static dayInMilliSecond = 24 * 60 * 60 * 1000; // 하루를 ms로 변환한 값.
  static minuteInMilliSecond = 60 * 1000;

  static getNow() {
    // 테스트 용이성을 위해서 new Date() 대신 사용
    return new Date(Date.now());
  }

  static convert = (date: ConvertParamType): Date | null => {
    if (date instanceof Date) return date;
    if (typeof date === 'number' || typeof date === 'string') {
      const d = new Date(date);
      if (isNaN(d.getTime())) throw '올바르지 않은 입력입니다.';
      return d;
    }
    return null;
  };
  // 지정된 startDate 와 endDate에 포함되었는지 여부 체크
  static getWhetherIncludedInThePeriod(
    startDate: Date = new Date(),
    endDate: Date = new Date(),
    currentTime = new Date(),
  ) {
    return this.compare(startDate, currentTime) < 1 && this.compare(currentTime, endDate) < 1;
  }

  
  static dateDiffFormat = (startDate = DateUtil.getNow(), endDate: Date, format: string) => {
    const ms = endDate.getTime() - startDate.getTime();
    const days: number = Math.floor(ms / (24 * 60 * 60 * 1000));
    const daysms: number = ms % (24 * 60 * 60 * 1000);
    const hours: number = Math.floor(daysms / (60 * 60 * 1000));
    const hoursms: number = ms % (60 * 60 * 1000);
    const minutes: number = Math.floor(hoursms / (60 * 1000));
    const minutesms: number = ms % (60 * 1000);
    const sec: number = Math.floor(minutesms / 1000);

    let yearDiff = endDate.getFullYear() - startDate.getFullYear();
    let monthDiff = endDate.getMonth() - startDate.getMonth();

    if (monthDiff < 0) {
      yearDiff -= 1;
      monthDiff += 12;
    }

    return format.replace(/yyyy?|yy?|MM?|dd?|HH?|mm?|ss?|/g, (format: string) => {
      switch (format) {
        case 'yyyy': // year difference
          return yearDiff.toString();
        case 'yy': // year difference two digits (ex: 2012 => 12)
          return yearDiff.toString().slice(-2);
        case 'MM': // month difference
          return monthDiff.toString().padStart(2, '0');
        case 'M': // month difference
          return monthDiff.toString();
        case 'dd': // date difference
          return days.toString();
        case 'HH': // hours (0~24)
          return hours.toString().padStart(2, '0');
        case 'mm': // minutes
          return minutes.toString().padStart(2, '0');
        case 'ss': // second
          return sec.toString().padStart(2, '0');
        default:
          return '';
      }
    });
  };

  static compare = (dateA: Date, dateB: Date): number => {
    // a < b : -1
    // a = b : 0
    // a > b : 1
    const a = dateA.valueOf();
    const b = dateB.valueOf();
    return Number(a > b) - Number(a < b);
  };

  static rangeYear = (dateA: Date, dateB: Date): Array<number> => {
    const dateArray = [];

    let startYear = dateA.getFullYear();
    const endYear = dateB.getFullYear();
    while (startYear <= endYear) {
      dateArray.push(startYear);
      startYear += 1;
    }

    dateArray.sort((a, b) => b - a);

    return dateArray;
  };

  // endDate 에서 date 를 뺀 day 를 return
  // endDate 가 없으면 현재 시간에서 date 를 뺀 day 를 return
  static getLeftDays = (date: Date, endDate = DateUtil.getNow()): number => {
    const offset = -1 * date.getTimezoneOffset() * DateUtil.minuteInMilliSecond; // timezone offset으로 값 보정
    const clearedStartDate = Math.floor((date.valueOf() + offset) / DateUtil.dayInMilliSecond);
    const clearedEndDate = Math.floor((endDate.valueOf() + offset) / DateUtil.dayInMilliSecond);

    return clearedEndDate - clearedStartDate;
  };

  static isLeftDays = (startDate: Date, days: number) => DateUtil.getLeftDays(startDate, DateUtil.getNow()) === days;

  static isToday = (date: Date): boolean => DateUtil.getLeftDays(date, DateUtil.getNow()) === 0;

  // date 가 compareDate 보다 전날이거나, 같은날이면 return true
  // date 가 compareDate 보다 다음날이면 return false
  // compareDate 가 없으면 현재 시간과 비교
  static isPastDate = (date: Date, compareDate = DateUtil.getNow()): boolean => DateUtil.compare(compareDate, date) >= 0;

  static formatYYYYMMDD = (date: Date, separator = ''): string => {
    const format = ['yyyy', 'MM', 'dd'].join(separator);
    return DateUtil.format(date, format);
  };

  static getPad = (num: number): string => num.toString().padStart(2, '0');

  static addDays = (date: Date, days: number) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  // ex) 2020년 08.20 21:07 => format(date, 'yyyy년 MM.dd HH:mm');
  static format = (date: Date, format: string): string => {
    const week = ['일', '월', '화', '수', '목', '금', '토'];

    return format.replace(/yyyy?|yy?|MM?|dd?|HH?|mm?|week?/g, (format: string): string => {
      switch (format) {
        case 'yyyy': // year
          return date.getFullYear().toString();
        case 'yy': // year two digits (ex 2012 => 12)
          return date.getFullYear().toString().slice(2);
        case 'MM': // month
          return DateUtil.getPad(date.getMonth() + 1);
        case 'M': // month
          return (date.getMonth() + 1).toString();
        case 'dd': // date
          return DateUtil.getPad(date.getDate());
        case 'HH': // hours (0~24)
          return DateUtil.getPad(date.getHours());
        case 'mm': // minutes
          return DateUtil.getPad(date.getMinutes());
        case 'week':
          return week[date.getDay()];
        default:
          return '';
      }
    });
  };

  static getDDay(endDate: string | Date | null): string {
    if (!endDate) return '상시';

    const tempDate = new Date(endDate);
    if (DateUtil.isPastDate(tempDate)) return '모집종료';

    const result = DateUtil.getLeftDays(new DateVO().nativeDate, tempDate);

    if (isNaN(result)) return '';
    if (result === 0) return 'D-Day';

    return `D-${result}`;
  }

  static getOnlyDDay(endDate: string | Date | null): string {
    if (!endDate) return '';

    const tempDate = new Date(endDate);
    const result = DateUtil.getLeftDays(new DateVO().nativeDate, tempDate);

    if (isNaN(result)) return '';

    return `D-${result}`;
  }

  // 방금전, 00분전, 00시간 전, 00일 전
  static getDiffTimeOrDay(date: Date) {
    // @ts-ignore
    const milliSeconds = DateUtil.getNow() - date;

    const seconds = milliSeconds / 1000;
    if (seconds < 60) return '방금전';

    const minutes = seconds / 60;
    if (minutes < 60) return `${Math.floor(minutes)}분 전`;

    const hours = minutes / 60;
    if (hours < 24) return `${Math.floor(hours)}시간 전`;

    const days = hours / 24;
    return `${Math.floor(days)}일 전`;
  }

  // 오전, 오후 12시 보정 처리 추가
  static getAmPmFormat(date: Date) {
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');

    switch (hours) {
      case 0:
        return `오전 12:${minutes}`;

      case 12:
        return `오후 12:${minutes}`;

      default:
        return this.getAmPmString(hours, minutes);
    }
  }

  // 오전, 오후 시간 포맷
  static getAmPmString(hours: number, minutes: string) {
    const isAm = hours <= 12;
    const hour = isAm ? hours : hours - 12;

    return `${isAm ? '오전' : '오후'} ${hour}:${minutes}`;
  }

  // 현재 시간 기준 남은 초 return
  static getRemainingSeconds = (endTime: string) => {
    const endDate = new Date(endTime);
    // 시간 문자열인지 체크
    if (isNaN(endDate.getTime())) return 0;
    // 남은 시간 계산
    const millisecond = endDate.getTime() - new Date().getTime();
    return Math.floor(millisecond / 1000);
  };

  // 시간 초를 분:초 포맷으로 변환
  static getTimeFormatHHMMdd = (second: number) => {
    const hour = Math.floor(second / 3600);
    let min = String(Math.floor((second - hour * 3600) / 60));
    let sec = String(second % 60);

    min = min.length <= 1 ? `0${min}` : min;
    sec = sec.length <= 1 ? `0${sec}` : sec;

    return hour ? `${hour}:${min}:${sec}` : `${min}:${sec}`;
  };

  // 월 차이 구하기(yyy7.mm)
  static getMonthPeriod(startDate: Date = new Date(), endDate: Date = new Date()) {
    return (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());
  }

  // 숫자 >  y년 m개월 로 리턴
  static getTotalPeriodTextYM(period: number) {
    if (period < 1) return '';
    if (period < 12) return `${period}개월`;
    if (period > 12) {
      const yearText = `${Math.floor(period / 12)}년`;
      const monthText = period % 12 ? `${period % 12}개월` : '';

      return monthText ? `${yearText} ${monthText}` : yearText;
    }

    return '';
  }
}

export class DateVO {
  private _date: Date = DateUtil.getNow();

  constructor(date?: ConvertParamType) {
    if (date instanceof Date) this._date = date;
    if (typeof date === 'number' || typeof date === 'string') {
      this._date = new Date(date);

      if (isNaN(this._date.getTime()) && typeof date === 'string') {
        // IE일 경우 (일단 백엔드에서 주는 rs의 date string 형태만 고려함)
        date = date.split(' ').join('T'); // '2020-02-02 12:12:12' => '2020-02-02T12:12:12' // exc) '04 Dec 1995 00:12:00 GMT'
        const signIndex = Math.max(date.lastIndexOf('-'), date.lastIndexOf('+'));
        let offsetSec = 0;
        if (signIndex > 10) {
          // 타임존오프셋이 있는 경우 ex) '2020-02-02T12:12:12.111+0900' (10 초과는 '-'가 날짜 구분자가 아님을 의미) // exc) +09:00 or +09
          offsetSec = parseInt(date.slice(signIndex));
          offsetSec = (9 - offsetSec / 100) * 60 * 60 + (offsetSec % 100) * 60; // 9는 대한민국 offset (offset이 0이면 UTC시간 => 이땐 9가 더해져야됨)
          date = date.slice(0, signIndex);
        }
        this._date = new Date(new Date(date).getTime() + offsetSec * 1000);
        console.error('Date string is invalid ', date); // 일단 이 if문 내에서는 logErr 출력
      }

      if (isNaN(this._date.getTime())) throw '타입 오류';
    }
    if (date === undefined || date === null) this._date = DateUtil.getNow();
  }

  get nativeDate(): Date {
    return this._date;
  }

  get year(): number {
    return this._date.getFullYear();
  }

  get month(): number {
    return this._date.getMonth() + 1;
  }

  get date(): number {
    return this._date.getDate();
  }

  get day() {
    return ['일', '월', '화', '수', '목', '금', '토'][this._date.getDay()];
  }

  get hours() {
    return this._date.getHours();
  }

  get minutes() {
    return this._date.getMinutes();
  }

  get time() {
    return this._date.getTime();
  }
}
