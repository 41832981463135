import React from 'react';
import classnames from 'classnames/bind';
import JDALink from 'components/JDALink';
import Colors from '__designkit__/common/colors';
import Icon from '__designkit__/icon/Icon';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

const Banner3 = () => (
  <div className={cx('commonBox')}>
    <div className={cx('reviewInner')}>
      <div className={cx('reviewBox')}>
        <span className={cx('desc')}>
          자소서, 지원서 없이
          <br />
          글로벌 IT 기업에 합격한
          <br />
          김상동님의 합격 비법은?
        </span>

        <div className={cx('review')} />

        <JDALink to='https://www.jobda.im/info/361' className={cx('btnBasic')}>
          <span className={cx('title')}>취업 합격후기 풀버전 확인</span>
          <div className={cx('btnIcon')}>
            <Icon name='arrow-right' size={32} color={Colors.JOBDA_BLACK} />
          </div>
        </JDALink>

        <div className={cx('nameTag')} />

      </div>
    </div>
  </div>
);

export default Banner3;
