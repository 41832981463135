import RoutePaths from 'consts/RoutePaths';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import JDBaseModal, { IJDBaseModal } from '../_common/modals/JDBaseModal';
import { JDModalBasicFrame, JDModalDescription, JDModalFullButton, JDModalTitle } from '../_common/modals/JDModalComponents';

const Frame = styled.div`

  ${JDModalTitle} {
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;
const VerificationTimeEndModal:FC<IJDBaseModal> = ({ isOpen, onClickClose }) => (
  <JDBaseModal dimmed isOpen={isOpen} onClickClose={onClickClose} isDimClickClose>
    <Frame>
      <JDModalBasicFrame>
        <JDModalTitle>세션이 만료되었습니다.</JDModalTitle>
        <JDModalDescription>다시 한 번 인증해 주세요.</JDModalDescription>
        <Link to={RoutePaths.root}>
          <JDModalFullButton>확인</JDModalFullButton>
        </Link>
      </JDModalBasicFrame>
    </Frame>
  </JDBaseModal>
);
export default VerificationTimeEndModal;
