import { Button, ButtonMode } from '__designkit__/button/Button';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import JoyImage from 'assets/character/joy_say_can_connect.svg';
import RoutePaths from 'consts/RoutePaths';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import AccResultSampleModal from '../acc/AccResultSampleModal';

const Frame = styled.div`
  width: 100%;
  height: fit-content;
`;

const ContentsFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100;
  padding: 24px 0 0;

  & > div.sub {
    display: flex;
    align-items: center;
    font: ${Fonts.B2_Medium_P};
    color: ${colors.B_100};

    & > div {
      display: flex;
      align-items: center;
    }
  }
`;

const BtnFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px 0 16px;

  & > div.button-wapper {
    width: 100%;
    padding: 0 21px;
  }

  & > div.underline-button {
    display: inline-flex;
    align-items: center;
    border-bottom: 1px solid ${colors.JOBDA_BLACK};
  }
`;

const Title = styled.div`
  font: ${Fonts.H4_Bold} !important;
  color: ${colors.JOBDA_BLACK} !important;

  &.sub {
    font: ${Fonts.B2_Medium_P};
    color: ${colors.CG_70};
  }
`;

interface IMatchApplyNoResultModalProps extends IComponentProps {
  onClickClose?: () => void;
}

const MatchApplyNoResultModal: FC<IMatchApplyNoResultModalProps> = ({ onClickClose = () => {} }) => {
  const history = useHistory();
  const [isOpenAccResultSampleModal, setIsOpenAccResultSampleModal] = useState(false);

  return (
    <Frame>
      <ContentsFrame>
        <Title>아직 역량검사 응시 전이에요.</Title>
        <SpacingBlock size={8} vertical />
        <Title className='sub'>정확도 높은 역량 측정을 위해  PC에서</Title>
        <div className='sub'>
          <Title className='sub'>응시를 권장하고 있어요</Title>
          <SpacingBlock size={8} />
          <div role='button' onClick={() => history.push(RoutePaths.acca_introcue)}>
            더보기
            <Icon name='arrow-right' size={24} color={colors.B_100} />
          </div>
        </div>
        <SpacingBlock size={32} vertical />
        <img src={JoyImage} alt='이어하기가 가능하다고 말하는 조이 이미지' />
      </ContentsFrame>
      <BtnFrame>
        <div className='button-wapper'>
          <Button buttonMode={ButtonMode.PRIMARY} label='결과표 예시 보기' size='large' onClick={() => setIsOpenAccResultSampleModal(true)} />
        </div>
        <SpacingBlock size={16} vertical />
        <div role='button' className='underline-button' onClick={onClickClose}>
          다음에 하기
          <Icon name='close' size={16} />
        </div>
      </BtnFrame>
      <AccResultSampleModal isOpen={isOpenAccResultSampleModal} onClickClose={() => setIsOpenAccResultSampleModal(false)} />
    </Frame>
  );
};

export default MatchApplyNoResultModal;
