import Portal from 'components/common/Portal';
import { IconCheckCircle } from 'consts/assets/icons/iconPages';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { absoluteCenterCenter, absoluteHorizonCenter, keyFrameFadeIn, keyFrameFadeOut } from 'consts/style/mixins';
import shadows from 'consts/style/shadows';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

export enum MY_PAGE_PARTERSHIP_TOAST_TYPE {
  SEND_MAIL = 'SEND_MAIL',
  SUCCESS = 'SUCCESS',
}

interface IJDAToastProps extends IComponentProps {
  type?: MY_PAGE_PARTERSHIP_TOAST_TYPE;
  isOpen?: boolean;
  onDidDismiss?: () => void;
  message?: string;
  duration?: number;
  position?: 'middle' | 'bottom';
}

const ToastFrame = styled.div`
  position:fixed;
  color:${colors.WHITE_100};
  display:flex;
  flex-direction:row;
  align-items:center;
  ${shadows.large};
  z-index:99000;
  border-radius:4px;
  padding:16px;
  opacity:0;
  width: 312px;
  min-width:254px;
  animation:fadein 500ms forwards;
  background: ${colors.c3ED160};
  pointer-event:none;
  &.closing {
    animation:fadeout 500ms forwards;
  }
  
  ${keyFrameFadeIn()};
  ${keyFrameFadeOut()};
  > *:not(:last-child) {
    margin-right:8px;
  }
  .btn-close {
    background:none;
    width:20px;
    height:20px;
  }
  
  .frame-message {
    flex:1;
    font: ${Fonts.B2_Bold_P};
    white-space: pre-line;
  }
  &.middle {
    ${absoluteCenterCenter()};
    position:fixed;
  }
  &.bottom {
    ${absoluteHorizonCenter()};
    position:fixed;
    bottom:calc(77px + env(safe-area-inset-bottom));
  }
`;

const MyPagePartershipToast: FC<IJDAToastProps> = ({ position = 'middle', type = MY_PAGE_PARTERSHIP_TOAST_TYPE.SEND_MAIL, isOpen = true, onDidDismiss = () => { }, message = '', duration = 1000 }) => {
  const [open, setOpen] = useState<boolean>(isOpen);
  const [closing, setClosing] = useState<boolean>(false);
  const refTimer = useRef<number>(-1);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const onClose = useCallback(() => {
    setOpen(false);
    onDidDismiss();
  }, [onDidDismiss]);

  useEffect(() => {
    if (closing) {
      setTimeout(() => {
        onClose();
      }, 500);
    }
  }, [closing, onClose]);

  useEffect(() => {
    if (open) {
      setClosing(false);
      refTimer.current = setTimeout(() => {
        setClosing(true);
      }, duration) as unknown as number;
    }
    return () => {
      if (refTimer.current > 0) {
        clearTimeout(refTimer.current);
        refTimer.current = -1;
      }
      setClosing(false);
    };
  }, [open, duration, onClose]);

  const textByType = useCallback(
    (toastType: MY_PAGE_PARTERSHIP_TOAST_TYPE) => {
      switch (toastType) {
        case MY_PAGE_PARTERSHIP_TOAST_TYPE.SEND_MAIL:
          return `인증 메일이 발송되었습니다.​
          발송된 메일을 통해 인증코드를 입력하시면 인증이 완료됩니다.`;
        case MY_PAGE_PARTERSHIP_TOAST_TYPE.SUCCESS:
          return `인증이 완료되었습니다. 
          제휴서비스 신청을 이어서 진행해 보세요.`;
        default:
          return '';
      }
    },
    [],
  );

  if (!open)
    return <></>;

  return (
    <Portal>
      <ToastFrame className={`${type} ${closing ? 'closing' : ''} ${position}`}>
        <IconCheckCircle />
        <div className='frame-message'>
          {textByType(type)}
        </div>
      </ToastFrame>
    </Portal>
  );
};

export default MyPagePartershipToast;
