/* eslint-disable class-methods-use-this */
import URIs from 'consts/URIs';
import { AgreeTermsThirdPartyPrivacyProvisionRq, THIRD_PARTY_TERMS_TYPE, ThirdPartyProvideTermsGetRq, ThirdPartyProvideTermsGetRs, ThirdPartyTermsAgreementRs } from 'interfaces/terms/IThirdPartyPrivacyRqRs';
import { request } from 'utils/request';

export default interface TermsRepo {

  // 개인정보 제3자 제공 약관 내용 조회(공용)
  fetchTermsThirdPartyPrivacyProvision(
    rq: ThirdPartyProvideTermsGetRq,
  ): Promise<ThirdPartyProvideTermsGetRs>;

  // 개인정보 제3자 제공 약관 내용 조회(공용) 생성
  fetchAgreeTermsThirdPartyPrivacyProvision(termsSn: AgreeTermsThirdPartyPrivacyProvisionRq): Promise<void>;

  // 개인정보 제3자 제공 약관 동의 여부 조회(공용)
  fetchTermsThirdPartyPrivacyProvisionAgreement(
    termsType: THIRD_PARTY_TERMS_TYPE,
    companyName: string,
  ): Promise<ThirdPartyTermsAgreementRs>;
}

export class RemoteTermsRepo implements TermsRepo {
  fetchTermsThirdPartyPrivacyProvision(
    rq: ThirdPartyProvideTermsGetRq,
  ): Promise<ThirdPartyProvideTermsGetRs> {
    return request<ThirdPartyProvideTermsGetRs>({
      method: 'get',
      url: URIs.get_terms_third_party_privacy_provision,
      params: rq,
    });
  }

  fetchAgreeTermsThirdPartyPrivacyProvision(rq: AgreeTermsThirdPartyPrivacyProvisionRq): Promise<void> {
    return request<void>({
      method: 'post',
      url: URIs.post_terms_third_party_privacy_provision,
      data: rq,
    });
  }

  fetchTermsThirdPartyPrivacyProvisionAgreement(
    termsType: THIRD_PARTY_TERMS_TYPE,
    companyName: string,
  ): Promise<ThirdPartyTermsAgreementRs> {
    return request<ThirdPartyTermsAgreementRs>({
      method: 'get',
      url: URIs.get_terms_third_party_privacy_provision_agreement,
      params: { termsType, companyName },
    });
  }
}
