// import IconArrowRight from 'assets/_v2/_common/input/icon_right_simple_black.svg';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import IconArrowRight from 'assets/_v2/direction/icon_right_small_Regular_44474B.svg';
import { JDModalButton } from 'components/_v2/_common/modals/JDModalComponents';
import RoutePaths from 'consts/RoutePaths';
import { inject, observer } from 'mobx-react';
import FindAccountModel from 'models/FindAccountModel';
import { injectStore } from 'models/store';
import { FindAccountPage, IFindAccountProps } from 'pages/FindAccount';
import { FC } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import MobileStore from 'store/mobileStore';
import styled from 'styled-components';
import TodayRenderCheckUtil from 'utils/TodayRenderCheckUtil';
import { windowOpenUtils } from 'utils/_v2/windowOpenUtils';

const Frame = styled.div`
  padding-top: 24px;
  height:100%;
  display:flex;
  flex-direction:column;
  align-items:center;

   .info{
    font: ${Fonts.H4_Bold};
    color: ${colors.JOBDA_BLACK};
    margin-bottom:24px;
  }

  .id-box{ 
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;

    width:100%;
    padding: 30px 0;
    background-color: ${colors.CG_30};
    margin-bottom:40px;

    >h1{
        font: ${Fonts.H3_Bold};
        color: ${colors.JOBDA_BLACK};
       margin-bottom:2px;
    }
    >h2{
        font: ${Fonts.B3_Medium};
        color: ${colors.CG_70};
    }
  }

  .login-btn{
    padding: 14px 0;
    border-radius:4px;
    margin-bottom:24px;
  }
  
  .password-reset-btn{
    display:flex;
    align-items: center;
    font: ${Fonts.B2_Medium};
    color: ${colors.CG_70};
    background:transparent;

    img {
        margin-left: 2px;
        width: 16px;
        height: 16px;
    }
  }
`;

interface IFindIdProps extends IFindAccountProps {
    className?: string;
}

const FindIdResultPage: FC<IFindIdProps> = ({ findAccountModel = new FindAccountModel(), className }) => {
  const history = useHistory();
  const handleLogin = async () => {
    const redirectUrl = await TodayRenderCheckUtil.getUtmParams('redirect_url');
    if (redirectUrl) {
      if (MobileStore.isMobile) {
        windowOpenUtils(redirectUrl, '_blank');
        history.push(RoutePaths.login);
      } else windowOpenUtils(redirectUrl, '_self');
    } else history.push(RoutePaths.login);
  };
  return (
    <>
      <Frame className={className || ''}>
        <div className='info'>찾은 아이디는 아래와 같습니다.</div>
        <div className='id-box'>
          <h1>{findAccountModel.idInfo?.id}</h1>
          <h2>{`${findAccountModel.idInfo?.createdDateTime || ''} 가입`}</h2>
        </div>
        <JDModalButton className='login-btn' onClick={handleLogin}>로그인</JDModalButton>
        <Link to={RoutePaths.findAccount(FindAccountPage.PW)}>
          <button className='password-reset-btn'>비밀번호 재설정
            <img src={IconArrowRight} alt='icon-arrow' />
          </button>
        </Link>
      </Frame>
    </>
  );
};

export default inject(injectStore.findAccountModel)(observer(FindIdResultPage));
