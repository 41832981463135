interface IConfigBase {
  useMock: boolean;
  fullHost: string;
  firebaseConfig: Object;
}

interface IConfig extends IConfigBase {
    host : string;
    hostRes : string;
    contextPath : string;
    hostAttachRes: string;
    hostAttachDown: string;
    hostResume?:string;
    hostAts?:string;
}
const config : IConfig = (() : IConfig => {
  const commonSetting : IConfigBase = {
    useMock: false,
    fullHost: document.location.origin,
    firebaseConfig: {},
  };

  // TODO: 나중에 나눌 것
  switch (process.env.REACT_APP_ENV) {
    case 'local':
    case 'development':
      return {
        ...commonSetting,
        host: 'https://api-jobda-im.kr-dv-jainwon.com',
        hostRes: '',
        hostAttachRes: 'https://api-jobda-im.kr-dv-jainwon.com/attach/view',
        hostAttachDown: 'https://api-jobda-im.kr-dv-jainwon.com/attach/down',
        contextPath: '',
        hostResume: 'https://resume.match.ats.kr-dv-jainwon.com',
        hostAts: 'https://api.ats.kr-dv-jainwon.com',
      };
    case 'staging':
      return {
        ...commonSetting,
        host: 'https://api-jobda-im.kr-st-jainwon.com',
        hostRes: '',
        hostAttachRes: 'https://api-jobda-im.kr-st-jainwon.com/attach/view',
        hostAttachDown: 'https://api-jobda-im.kr-st-jainwon.com/attach/down',
        contextPath: '',
        hostResume: 'https://resume.match.ats.kr-st-jainwon.com',
        hostAts: 'https://api.ats.kr-st-jainwon.com',
      };
    case 'staging2':
      return {
        ...commonSetting,
        host: 'https://st2-api-jobda-im.kr-st-jainwon.com',
        hostRes: '',
        hostAttachRes: 'https://st2-api-jobda-im.kr-st-jainwon.com/attach/view',
        hostAttachDown: 'https://st2-api-jobda-im.kr-dv-jainwon.com/attach/down',
        contextPath: '',
        hostResume: 'https://st2-resume.match.ats.kr-st-jainwon.com',
        hostAts: 'https://st2-api.ats.kr-st-jainwon.com',
      };
    case 'production':
    default:
      return {
        ...commonSetting,
        host: 'https://api.jobda.im',
        hostRes: '',
        hostAttachRes: 'https://api.jobda.im/attach/view',
        hostAttachDown: 'https://api.jobda.im/attach/down',
        contextPath: '',
        hostResume: 'https://resume.match.jobda.im',
        hostAts: 'https://api.match.jobda.im',
      };
  }
})();

export default config;
