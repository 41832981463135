import Colors from '__designkit__/common/colors';
import Loading from 'components/Loading';
import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components/macro';

const GlobalStyle = createGlobalStyle`
  body > #root {
    height: 100%;
  };
`;

const Frame = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${Colors.WHITE_100};
`;

const CustomSplash = () => {
  return (
    <Frame>
      <GlobalStyle />
      <Loading />
    </Frame>
  );
};

export default CustomSplash;
