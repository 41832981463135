import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import Colors from '__designkit__/common/colors';
import JDALink from 'components/JDALink';
import RoutePaths from 'consts/RoutePaths';
import Icon from '__designkit__/icon/Icon';
import ErrorCode from 'consts/ErrorCodes';
import { TOAST_TYPE } from 'components/_v2/_common/toast/JDToast';
import useToast from 'hooks/useToast';
import { useHistory, useLocation } from 'react-router';
import Login from 'models/Login';
import Context from 'models/Context';
import EventProfileModel from 'models/_v2/event/EventProfileModel';
import JDSimpleInfoModal from 'components/_v2/_common/modals/JDSimpleInfoModal';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import MarketingConsentModal from './MarketingConsentModal';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

interface IEventRule {
  login?:Login;
  eventProfileModel?:EventProfileModel;
  context?:Context;
}

const HowTo:FC<IEventRule> = ({ login = new Login(), eventProfileModel = new EventProfileModel(), context = new Context() }) => {
  const { setToastObject } = useToast();
  const history = useHistory();
  const { pathname } = useLocation();
  const [openMarketingConsentModal, setOpenMarketingConsentModal] = useState<boolean>(false);
  const [OpenSuccessCompletedModal, setOpenSuccessCompletedModal] = useState<boolean>(false);
  const [openEmptyProfileModal, setOpenEmptyProfileModal] = useState<boolean>(false);
  const [openDoneApplyCompletedModal, setOpenDoneApplyCompletedModal] = useState<boolean>(false);
  const ProfileApplyType = 'PROFILE_EVENT_2023_12';

  useEffect(() => {
    const init = async () => {
      await eventProfileModel.loadEventsParticipationStatus(ProfileApplyType);
    };
    init();
  }, []);

  const applyEvent = async () => {
    if (!login.userInfo) {
      context.setRedirectUrl(pathname);
      history.push(RoutePaths.login);
      return;
    }

    try {
      await eventProfileModel.setEventProfileApply();
      setOpenSuccessCompletedModal(true);
      await eventProfileModel.loadEventsParticipationStatus(ProfileApplyType);
    } catch (e: any) {
      switch (e.response?.data?.errorCode) {
        case ErrorCode.B901:
          setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '휴대폰 번호가 존재하지 않습니다.', subMessage: undefined });
          break;
        case ErrorCode.B903:
          setOpenDoneApplyCompletedModal(true);
          break;
        case ErrorCode.B904:
        case ErrorCode.B905:
          setOpenMarketingConsentModal(true);
          break;
        case ErrorCode.B907:
          setOpenEmptyProfileModal(true);
          break;
        default:
          setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '다시 시도해 주세요.', subMessage: undefined });
          break;
      }
    }
  };

  return (
    <div className={cx('commonBox', 'whiteTag')}>
      {/* 타이틀 */}
      <div className={cx('basicTitleInner')}>
        <span className={cx('basicTitle')}>프로필 신규 작성/업데이트만 해도</span>
        <span className={cx('subTitle')}>
          JOBDA가 취업지원금
          <br />
          총 120만원을 지원합니다!
        </span>
      </div>

      <span className={cx('subText')}>[이벤트 응모하기] 버튼을 클릭해서 응모해주세요.</span>

      <div className={cx('contentInner')}>
        <div className={cx('firstPrice')} />
        <span className={cx('giftInner')}>
          신세계백화점 상품권
          <br />
          <span className={cx('import')}>10만원(2명)</span>
        </span>
        <div className={cx('naverPay')}>
          <span className={cx('giftBox')}>
            네이버페이
            <strong> 5천원(200명)</strong>
          </span>
        </div>
      </div>

      {/* [D] 이벤트 참여 버튼 */}
      <div className={cx('joinArea')}>
        <JDALink to={RoutePaths.profile} className={cx('btnLink')}>
          <span className={cx('title')}>프로필 신규 작성 / 업데이트</span>
          <div className={cx('btnIcon')}>
            <Icon name='guide' size={32} color={Colors.CG_80} />
          </div>
        </JDALink>
        <button
          className={cx('btnLink', 'btnJoin')}
          onClick={() => applyEvent()}
          disabled
        >
          <span className={cx('title')}>이벤트 응모하기</span>
        </button>
      </div>

      <span className={cx('eventInfo')}>
        * 프로필 입력 완료 후 아래 [이벤트 응모하기] 버튼을 누르면 이벤트에 응모가 완료됩니다.
        <br />
        * 이벤트 참여를 위한 마케팅 정보 수신 동의가 필요합니다.
        <br />* 프로필 완성도가 높을 수록, 프로필 내용을 상세하게 입력하실 수록 당첨 확률이 높아집니다.
      </span>

      <JDSimpleInfoModal title='응모완료!' descriptions={`당첨될 경우 네이버페이는\n회원정보 상 전화번호로 발송됩니다.\n프로필 완성도가 높고\n내용이 상세할수록 당첨 확률이 올라갑니다.`} dimmed isOpen={OpenSuccessCompletedModal} onClickClose={() => setOpenSuccessCompletedModal(false)} />
      <JDSimpleInfoModal descriptions={`이벤트 조건을 만족한 후 응모가 가능합니다.\n프로필 필수정보 작성 후 응모해 주세요.`} dimmed isOpen={openEmptyProfileModal} onClickClose={() => setOpenEmptyProfileModal(false)} />
      <JDSimpleInfoModal title='이미 이벤트 응모가 완료되었습니다.' descriptions={`당첨 확률을 높이기 위해\n프로필을 업데이트 해 주세요.`} dimmed isOpen={openDoneApplyCompletedModal} onClickClose={() => setOpenDoneApplyCompletedModal(false)} />
      <MarketingConsentModal isOpen={openMarketingConsentModal} onClickClose={() => setOpenMarketingConsentModal(false)} />
    </div>
  );
};

export default inject(injectStore.login, injectStore.eventProfileModel, injectStore.context)(observer(HowTo));
