export const Space = {
  MAIN: 'MAIN',
  ACC_ATOZ: 'ACC_ATOZ',
  ACC_SCREENING_PASS_REVIEW: 'ACC_SCREENING_PASS_REVIEW',
  PHS_DCHAMPS: 'PHS_DCHAMPS',
  ALL: 'ALL',
  ACC_GAME: 'ACC_GAME',
} as const;

export type ContentsSpace = keyof typeof Space;

export interface IContentsParams {
  categorySns: number[],
  page?: number;
  size?: number;
}
