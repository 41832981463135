export const NHIS_PROVIDER = {
  KAKAO: 'KAKAO',
  NAVER: 'NAVER',
  PASS: 'PASS',
} as const;

export const NHIS_ERROR_MESSAGE: { [key: string]: string } = {
  request_B901: '인증 중에 오류가 발생했어요. 절차를 다시 확인해 주세요.',
  request_B902: '요청에 실패했어요. 인증 정보 및 인증서를 확인해 주세요.',
  check_B901: '인증 중에 오류가 발생했어요. 절차를 다시 확인해 주세요.',
  check_B902: '인증이 완료되지 않았어요. 절차를 다시 확인해 주세요.',
  check_B903: '인증 시간이 만료되었어요. 다시 시도해 주세요.',
};
