import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import IconFrontier from 'assets/_v2/_common/icon_frontier.png';
import IconHdot from 'assets/_v2/_common/icon_hdot_logo.png';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import confetti from 'canvas-confetti';
import CongratsModal from 'components/_v2/_common/modals/CongratsModal';
import JDInputEmailModal from 'components/_v2/_common/modals/JDInputEmailModal';
import JDSimpleInfoModal from 'components/_v2/_common/modals/JDSimpleInfoModal';
import { postEmailCheck, postRouletteGame } from 'components/_v2/roulette/rouletteFetch';
import RouletteGame from 'components/_v2/roulette/rouletteGame';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const CustomHeader = styled.div`
    position:absolute;
    top:0;
    display: flex;
    width: 100%;
    height: 48px;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(68, 71, 75, 0.20);
    .icon{
        width:80px;
        height: 16px;
    }
`;

const Frame = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: ${colors.JOBDA_BLACK};
    display: flex;
    justify-content: center;
    position:relative;
    overflow: hidden;
    .big-frame{
        position:absolute;
        width: 720px;
        height: 464px;
        padding: 0px 38px 10px 38px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        top: -240px;
        .small-frame{
            display: flex;
            position: absolute;
            width: 644px;
            height: 464px;
            flex-shrink: 0;
            .right-background{
                position:absolute;
                left:350px;
                top:50px;
                width: 300px;
                height: 245px;
                border-radius: 300px;
                background: var(--palette-cyan-cyan400, #3BC9DB);
                filter: blur(120px);
                flex-shrink: 0;
            }
            .center-background{
                position:absolute;
                top: 20px;
                width: 300px;
                height: 245px;
                border-radius: 300px;
                opacity: 0.5;
                background: var(--color-accent-teal-400, #20C997);
                filter: blur(80px);
                flex-shrink: 0;
            }
            .left-background{
                border-radius: 300px;
                position:absolute;
                right:300px;
                top:50px;
                width: 300px;
                height: 245px;
                opacity: 0.5;
                background: var(--palette-teal-teal400, #38D9A9);
                filter: blur(120px);
                flex-shrink: 0;
            }
    }
    }
  
   
`;
const InnerFrame = styled.div`
    margin:  0 auto;
    max-width: 480px;
    min-width: 375px;
    height: 100vh;
    background-color: ${colors.JOBDA_BLACK};
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    .icon-frontier{
        width: 320px;
        height:44px;
        margin-top: 12px;
        z-index: 1;
    }
`;
const LogoFrame = styled.div`
    margin-top: 96px;
    display: flex;
    border: 0.5px solid rgba(255, 255, 255, 0.40);
    border-radius: 100px;
    padding:6px 16px;
    z-index: 1;
    .title{
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: ${colors.WHITE_100};
    }
`;
const Roulette: FC = () => {
  const [initModal, setInitModal] = useState<boolean>(false);
  const [failModal, setFailModal] = useState<boolean>(false);
  const [congratsModal, setCongratsModal] = useState<boolean>(false);
  const [rank, setRank] = useState<number>(1);
  const [managerName, setManagerName] = useState<string>('');
  const [managerPhone, setManagerPhone] = useState<string>('');
  const [startAble, setStartAble] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>('');
  const [userSn, setUserSn] = useState<number>(0);
  const [startBtn, setStartBtn] = useState<boolean>(false);

  const joinSchema = yup.object({ email: yup.string().required('').matches(/^[0-9a-z]+([-_.]*[0-9a-z-_])*@[a-z]+(\.[a-z]{2,})+$/, '잘못된 이메일 형식이에요.') });
  const formMethods = useForm({
    mode: 'onChange',
    resolver: yupResolver(joinSchema),
  });
  const { handleSubmit, errors, watch, getValues, formState, reset, setError } = formMethods;
  useEffect(() => {
    const init = async () => {
      setInitModal(true);
    };
    init();
  }, []);
  const handleCheckEmail = async () => {
    if (formState.errors.email) return;
    const body = { email: watch('email') };
    setUserEmail(watch('email'));
    await postEmailCheck(body, {
      onSuccess: (data) => {
        setRank(data.rank);
        setUserSn(data.sn);
        if (data.participation_count !== 0) {
          setManagerName(data.manager_name);
          setManagerPhone(data.manager_phone);
          setStartAble(false);
          setCongratsModal(true);
        } else {
          setStartBtn(true);
        }
        setInitModal(false);
      },
      onError: (error) => {
        setStartAble(false);
        setError('email', { message: '유효하지 않은 이메일입니다.' });
      },
    });
  };

  useEffect(() => {
    if (congratsModal) {
      confetti({
        particleCount: 120,
        angle: 90,
        spread: 60,
        decay: 0.95,
        origin: { x: 0.5, y: 1.3 },
        zIndex: 1000000,
        scalar: 2,
      });
    }
  }, [congratsModal]);

  const beforeRoulette = async () => {
    const body = { sn: userSn, email: userEmail };
    await postRouletteGame(body, {
      onSuccess: (data) => {
        setRank(data.rank);
        setUserSn(data.sn);
        setManagerName(data.manager_name);
        setManagerPhone(data.manager_phone);
        setStartAble(true);
      },
      onError: (error) => {
        setStartAble(false);
        setFailModal(true);
      },
    });
  };
  const afterRoulette = async () => {
    setCongratsModal(true);
    setStartAble(false);
    setStartBtn(false);
  };
  return (
    <Frame>
      <FormProvider {...formMethods}>
        <div className='big-frame'>
          <div className='small-frame'>
            <div className='right-background' />
            <div className='center-background' />
            <div className='left-background' />
          </div>
        </div>
        <CustomHeader>
          <img src={IconHdot} alt='hdot' className='icon' />
        </CustomHeader>
        <InnerFrame>
          <LogoFrame>
            <div className='title'>행운의 룰렛 EVENT</div>
          </LogoFrame>
          <img src={IconFrontier} alt='frontier' className='icon-frontier' />
          <div>
            <RouletteGame targetPrize={rank} start={startAble} afterLogic={afterRoulette} startLogic={beforeRoulette} startBtn={startBtn} />
          </div>
        </InnerFrame>
        <JDInputEmailModal title='이메일을 입력해주세요.' isOpen={initModal} onClickClose={handleCheckEmail} />
        <JDSimpleInfoModal title='이미 참여한 이벤트입니다' isOpen={failModal} onClickClose={() => setFailModal(false)} btnText='확인' />
        <CongratsModal title={String(rank)} descriptions={`채용/성과 담당자 및 팀장님이 함께\n세미나를 방문하셔야만 지급됩니다.`} isOpen={congratsModal} onClickClose={() => setCongratsModal(false)} />
      </FormProvider>
    </Frame>
  );
};

export default Roulette;
