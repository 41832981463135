import RoutePaths from 'consts/RoutePaths';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import React, { FC } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import IconJoyEmpty from 'assets/_v2/company/icon_character_joy_empty.svg';
import IconJoyReady from 'assets/_v2/company/icon_character_joy_ready.svg';
import ErrorCode from 'consts/ErrorCodes';
import Icon from '__designkit__/icon/Icon';

const Header = styled.header`
    display: flex;    
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(50px);
    position: fixed;
    background-color: ${colors.WHITE_100};
    top: 0;
    z-index: 10;
    & > div {
        left:8px;
        position: absolute;
        z-index: 12;
    }
`;
const ErrorFrame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 180px;
  .img-joy{
    width:80px;
    height: 80px;
  }
  .bold-text{
    margin-top: 32px;
    font: ${Fonts.H3_Bold};
    color:${colors.CG_90};
    text-align: center;
  }
  .normal-text{
    margin-top: 16px;
    font: ${Fonts.B2_Medium_P};
    color:${colors.CG_60};
    text-align: center;
  }
`;

const BackMainButton = styled.div`
  position:fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
  width:91%;
  height:48px;
  border-radius: 4px;
  background:${colors.JOBDA_BLACK};
  bottom:calc(80px + env(safe-area-inset-bottom));
  margin: 0 auto;
  span{
    font: ${Fonts.B1_Bold};
    color:${colors.WHITE_100};
    text-align: center;
  }
`;

interface ICompanyDetailErrorPage {
  type: ErrorCode;
}

const CompanyDetailErrorPage: FC<ICompanyDetailErrorPage> = ({ type }) => {
  const history = useHistory();
  const handleClick = () => {
    history.push(RoutePaths.root);
  };
  const onClickBackBtn = () => {
    history.goBack();
  };
  return (
    <>
      <Header>
        <Icon name='large-arrow-left' size={32} onClick={onClickBackBtn} />
      </Header>
      {
        // B001 (ATS 기업 생성X), B901 (미게시)
        type === ErrorCode.B001 || type === ErrorCode.B901 ? (
          <ErrorFrame>
            <img src={IconJoyReady} className='img-joy' alt='error' />
            <div className='bold-text'>
              기업 전용 페이지를 <br />
              준비하고 있어요.
            </div>
            <div className='normal-text'>
              기업 소개부터 인원, 재무 등 <br />
              다양한 정보를 준비하고 있으니 <br />
              조금만 기다려주세요.<br />
            </div>
            <BackMainButton onClick={() => handleClick()}>
              <span>메인으로 돌아가기</span>
            </BackMainButton>
          </ErrorFrame>
        ) : (
          <ErrorFrame>
            <img src={IconJoyEmpty} className='img-joy' alt='error' />
            <div className='bold-text'>
              요청하신 페이지를 <br />
              찾을 수 없어요.
            </div>
            <div className='normal-text'>
              기업 서비스를 준비하고 있거나 <br />
              사용할 수 없는 페이지입니다. <br />
              입력하신 주소를 다시 확인해 주세요.<br />
            </div>
            <BackMainButton onClick={() => handleClick()}>
              <span>메인으로 돌아가기</span>
            </BackMainButton>
          </ErrorFrame>
        )
      }
    </>
  );
};
export default CompanyDetailErrorPage;
