import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { injectStore } from 'models/store';
import MyProfileModel from 'models/_v2/MyProfileModel';
import { ListFrame } from 'pages/_v2/myprofile/MyProfileAccaResults';
import AccResultModal from 'components/_v2/acc/AccResultModal';
import PstResultModal from 'components/_v2/phs/pst/PstResultModal';
import JDSimpleInfoModal from 'components/_v2/_common/modals/JDSimpleInfoModal';
import useToast from 'hooks/useToast';
import JDRegisterItemButton from 'components/_v2/_common/button/JDRegisterItemButton';
import ProfileEditTitleComponent from 'components/_v2/profile/ProfileEditTitleComponent';
import JDMatchApplyNudgeModal from 'components/_v2/_common/modals/JDMatchApplyNudgeModal';
import JDFullScreenBackableModal from 'components/_v2/_common/modals/JDFullScreenBackableModal';
import CommonUtils from 'utils/CommonUtils';
import { DateFormatYYYYMMDDHHMMWithDot } from 'utils/DateUtils';
import { PhsAccType } from 'consts/MatchingMessageType';
import { MatchPHSAnalysisType } from 'consts/MatchAnalysisType';
import colors from '__designkit__/common/colors';
import Fonts, { fontStyle } from '__designkit__/common/fonts';
import IModalProps from 'interfaces/props/IModalProps';
import { IPstProgressCompleteDto } from 'interfaces/_v2/phs/pst/IPstProgressCompleteRs';
import { IPaccProgressCompleteDto } from 'interfaces/_v2/phs/pacc/IPaccProgressCompleteRs';
import PaccDchampsModel from 'models/_v2/PaccDChampsModel';
import IconDChamps from 'assets/_v2/phs/img_dchamps_emblem 2.svg';
import { IDChampsResultDto } from 'interfaces/_v2/phs/IDChampsRqRs';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import { TOAST_TYPE } from 'components/_v2/_common/toast/JDToast';

const Frame = styled.div`
  .progress {
    display: flex;
    padding:6px 8px;
    width: fit-content;
    height: 28px;
    align-items: center;
    background: ${colors.CG_60};
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    color: ${colors.WHITE_100};
    font: ${Fonts.B3_Medium};
  }

  .complete {
    display: flex;
    padding:6px 8px;
    height: 28px;
    width: fit-content;
    align-items: center;
    background: ${colors.JOBDA_BLACK};
    border-radius: 4px;
    color: ${colors.WHITE_100};
    font: ${Fonts.B3_Medium};
  }

`;

const CustomContentFrame = styled.div`
display: flex;
justify-content: space-between;
padding:8px 0;
  .dchamps-icon{
    width: 48px;
    height: 48px;
  }
  .dchamps-title{
    font: ${Fonts.B1_Medium_P};
    color:${colors.JOBDA_BLACK};
    align-self: center;
  }
  `;
interface IMyProfilePhsResultModalProps extends IModalProps{
  type: PhsAccType;
  paccDChampsModel?:PaccDchampsModel

}

const MyProfilePhsResultModal:FC<IMyProfilePhsResultModalProps & IMyProfilePhsResultModalProps> = ({ type, isOpen, onClose, myProfileModel = new MyProfileModel(), paccDChampsModel = new PaccDchampsModel() }) => {
  const { search } = useLocation();
  const [searchOpen, setSearchOpen] = useState(false);
  const { pstProgressList, paccProgressList } = myProfileModel;
  const { dChampsResults } = paccDChampsModel;
  const { setToastObject } = useToast();
  const [selectedResultSn, setSelectedResultSn] = useState(0);
  const [isOpenPaccResultModal, setIsOpenPaccResultModal] = useState<boolean>(false);
  const [isOpenPstResultModal, setIsOpenPstResultModal] = useState<boolean>(false);
  const [isNotSupportModal, setIsNotSupportModal] = useState<boolean>(false);
  const [isDChampsNotSupportModal, setIsDChampsNotSupportModal] = useState<boolean>(false);

  const init = async () => {
    const paramList = CommonUtils.parseQuery(search);
    if (paramList.sn) {
      const currentURL = window.location.href;
      const [baseUrl, queryString] = currentURL.split('?');
      if (queryString) {
        const updatedURL = baseUrl;
        window.history.replaceState({}, document.title, updatedURL);
        setSearchOpen(true);
        setSelectedResultSn(Number(paramList.sn));
        if (type === PhsAccType.PST)
          setIsOpenPstResultModal(true);
        else if (type === PhsAccType.PACC) {
          setIsOpenPaccResultModal(true);
        } else if (type === PhsAccType.DCHAMPS) {
          setIsDChampsNotSupportModal(true);
        }
      }
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <JDFullScreenBackableModal isOpen={isOpen || searchOpen} onClose={onClose}>
      <Frame>
        <ProfileEditTitleComponent
          title={type === PhsAccType.PACC ? '개발자 역량검사 결과를 선택해 주세요.' : type === PhsAccType.PST ? '개발 구현 능력 검사 결과를 선택해 주세요.' : '디챔스 결과를 선택해 주세요.'}
          subtitle='분석중인 경우 분석이 완료되면 결과표 확인 가능합니다.'
          divider={false}
        />
        {type === PhsAccType.PACC && paccProgressList && paccProgressList.length > 0 && (
        <ListFrame>
          { paccProgressList.map((item: IPaccProgressCompleteDto) => (
            item.analysisType !== MatchPHSAnalysisType.FAILED && (
            <li className='li-frame'>
              <JDRegisterItemButton
                title={DateFormatYYYYMMDDHHMMWithDot(item.applyEndDateTime)}
                mode='SELECT'
                disabled={item.analysisType === MatchPHSAnalysisType.IN_PROGRESS}
                onClick={() => {
                  if (item.analysisType === MatchPHSAnalysisType.IN_PROGRESS)
                    setToastObject({ isOpen: true, type: TOAST_TYPE.INFO, message: '결과 분석중입니다.', subMessage: '결과 분석에는 최대 24시간이 소요될 수 있습니다.', duration: 2000 });
                  else {
                    setSelectedResultSn(item.sn);
                    setIsOpenPaccResultModal(true);
                  }
                }}
              >
                { item.analysisType === MatchPHSAnalysisType.IN_PROGRESS ? <div className='progress'>분석중</div> : <div className='complete'>분석 완료</div> }
              </JDRegisterItemButton>
            </li>
            )))}
        </ListFrame>
        )}
        {type === PhsAccType.PST && pstProgressList && pstProgressList.length > 0 && (
        <ListFrame>
          { pstProgressList.map((item: IPstProgressCompleteDto) => (
            item.analysisType !== MatchPHSAnalysisType.FAILED && (
            <li className='li-frame'>
              <JDRegisterItemButton
                title={DateFormatYYYYMMDDHHMMWithDot(item.applyEndDateTime)}
                mode='SELECT'
                disabled={item.analysisType === MatchPHSAnalysisType.IN_PROGRESS}
                onClick={() => {
                  if (item.analysisType === MatchPHSAnalysisType.IN_PROGRESS)
                    setToastObject({ isOpen: true, type: TOAST_TYPE.INFO, message: '결과 분석중입니다.', subMessage: '결과 분석에는 최대 24시간이 소요될 수 있습니다.' });
                  else {
                    setSelectedResultSn(item.sn);
                    setIsOpenPstResultModal(true);
                  }
                }}
              >
                { item.analysisType === MatchPHSAnalysisType.IN_PROGRESS ? <div className='progress'>분석중</div> : <div className='complete'>분석 완료</div> }
              </JDRegisterItemButton>
            </li>
            )))}
        </ListFrame>
        )}
        {type === PhsAccType.DCHAMPS && dChampsResults && dChampsResults.results.length > 0 && (
        <ListFrame>
          { dChampsResults.results.map((item:IDChampsResultDto) => (
            <li className='li-frame'>
              <JDRegisterItemButton
                title={item.title}
                customUIYn
                mode='SELECT'
                onClick={() => {
                  setIsDChampsNotSupportModal(true);
                }}
              >
                <CustomContentFrame>
                  <img src={IconDChamps} className='dchamps-icon' alt='dchamps' />
                  <SpacingBlock size={20} />
                  <div className='dchamps-title'>{item.title}</div>
                </CustomContentFrame>
              </JDRegisterItemButton>
            </li>
          ))}
        </ListFrame>
        )}
      </Frame>

      {isOpenPaccResultModal ? (
        <AccResultModal
          isOpen={isOpenPaccResultModal}
          onClose={() => {
            setIsOpenPaccResultModal(false);
            setSearchOpen(false);
          }}
          sn={selectedResultSn}
          type={PhsAccType.PACC}
        />
      ) : null}

      {isOpenPstResultModal ? (
        <PstResultModal
          isOpen={isOpenPstResultModal}
          onClose={() => setIsOpenPstResultModal(false)}
          sn={selectedResultSn}
          type={PhsAccType.PST}
        />
      ) : null}

      <JDSimpleInfoModal
        isOpen={isNotSupportModal}
        title={`최신 역량검사 결과표는\nPC에서 확인 가능합니다.`}
        onClickClose={() => setIsNotSupportModal(false)}
      />
      <JDSimpleInfoModal
        isOpen={isDChampsNotSupportModal}
        title={`디챔스 결과표는\nPC에서 확인 가능합니다.`}
        onClickClose={() => setIsDChampsNotSupportModal(false)}
      />

      <JDMatchApplyNudgeModal />
    </JDFullScreenBackableModal>
  );
};

export default inject(injectStore.myProfileModel, injectStore.paccDChampsModel)(observer(MyProfilePhsResultModal));
