export enum CenterHomeType{
    ACCA = 'ACCA',
    PACC = 'PACC',
}

export enum CenterHomeTypeText{
    ACCA = '역량검사',
    PACC = '개발자 검사',
}

export enum EmploymentHomeType{
    CALENDAR = 'CALENDAR',
    JOBPOSTING = 'JOBPOSTING',
    CHALLENGE = 'CHALLENGE'
}

export enum EmploymentHomeTypeText{
    CALENDAR = '채용캘린더',
    JOBPOSTING = '채용공고',
    CHALLENGE = '채용이벤트'
}
