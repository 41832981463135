import React, { FC } from "react";
import styled from "styled-components";

const StyledFrame = styled.div`
  position: relative;
`;

const StyledFlipFrame = styled.div`
  transform-style: preserve-3d;
  transition: transform ${(props) => (props as any)['data-duration']}ms;
  width: 100%;
  height: 100%;
  transform: perspective(1000px) rotateY(0eg);
  &.flip {
    transform: perspective(1000px) rotateY(-180deg);
  }
`;

const StyledFlipItem = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
`;

const StyledFlipBackItem = styled(StyledFlipItem)`
  transform: rotateY(180deg);
`;

const StyledChildren = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

interface IFlipComponentProps extends React.HTMLAttributes<HTMLDivElement> {
  front: React.ReactElement;
  back: React.ReactElement;
  flip?: boolean;
  duration?: number;
}
const FlipComponent: FC<IFlipComponentProps> = ((props) => {
  const { front, back, flip = false, duration = 500, ...rest } = props;
  return (
    <StyledFrame {...rest}>
      <StyledFlipFrame className={flip ? 'flip' : ''} data-duration={duration}>
        <StyledFlipItem>{ front }</StyledFlipItem>
        <StyledFlipBackItem>{ back }</StyledFlipBackItem>
      </StyledFlipFrame>
      {
        props.children && (
          <StyledChildren>{ props.children }</StyledChildren>
        )
      }
    </StyledFrame>
  );
});

export default FlipComponent;
