import { ReactComponent as IconClose } from 'assets/icon/icon_close_16.svg';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import React, { FC } from 'react';
import styled from 'styled-components';

interface IJDClosableChipProps {
  onClose: () => void;
}

const Frame = styled.div`
  display:inline-flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
  padding: 8px 12px;
  border:1px solid ${colors.JOBDA_BLACK};
  border-radius:20px;
  font: ${Fonts.B2_Bold};
  color:${colors.JOBDA_BLACK};
  background-color: ${colors.JOBDA_WHITE};
  white-space : nowrap;
  
  svg{
    margin-left: 8px;
    >path{
      fill:${colors.JOBDA_BLACK};
    };
  }
`;

const JDClosableChip:FC<IJDClosableChipProps> = ({ onClose, ...props }) => (
  <Frame>
    <span className='text'>{props.children}</span>
    <IconClose width={16} height={16} type='button' onClick={onClose} />
  </Frame>
);

export default JDClosableChip;
