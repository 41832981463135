import DisableScroll from 'components/common/DisableScroll';
import Portal from 'components/common/Portal';
import colors from '__designkit__/common/colors';
import { fonts } from '__designkit__/common/fonts';
import { dimmer, JDAButton } from 'consts/style/mixins';
import aiIntroduceBenefitTitles from 'consts/style/mixins/aiIntroduce';
import IAccIntroduceBenefit from 'interfaces/IAccIntroduceBenefit';
import React, { FC } from 'react';
import styled from 'styled-components';
import iconClose from 'assets/icon/icon_close_24_Light_grey.svg';
import IModalProps from 'interfaces/props/IModalProps';

interface IAiIntroduceBenefitModalProps extends IModalProps {
  benefit:IAccIntroduceBenefit;
}

const Frame = styled.section`
  width:100vw;
  height:calc(100% - env(safe-area-inset-bottom));
  position:fixed;
  top:0;
  left:0;
  z-index:10004;
  .dimmer {
    ${dimmer()};
    z-index:-1;
  }
  .frame-modal {
    position:relative;
    margin:24px;
    background:${colors.WHITE_100};
    width:calc(100% - 48px);
    height:calc(100% - 48px);
    border-radius:16px;
    padding:48px 0px 24px 0;
    display:flex;
    flex-direction:column;
    align-items:stretch;
    justify-content:flex-start;
    ${aiIntroduceBenefitTitles};
    .btn-close {
      ${JDAButton(false, true)};
      width:48px;
      height:48px;
      display:flex;
      flex-direction:row;
      justify-content:center;
      align-items:center;
      border-radius:50%;
      position:absolute;
      top:24px;
      right:24px;
      background:${colors.CG_30};
    }
    .frame-description {
      flex:1;
      margin-left:24px;
      margin-top:24px;
      overflow-y:scroll;
      padding-right:24px;
      * {
        ${fonts.NOTO_15_400};
        line-height:30px;
        letter-spacing:-0.24px;
        color:${colors.CG_70};
      }
      p {
        margin-top:30px;
        em{
          ${fonts.NOTO_15_700};
        }
      }
      ::-webkit-scrollbar {
        background:${colors.WHITE_100};
        width:24px
      }
      ::-webkit-scrollbar-thumb {
        background:${colors.CG_40};
        background-clip: content-box;
        border: 8px solid transparent;
      }
      ::-webkit-scrollbar-track {
        background:${colors.WHITE_100};
      }
    }
  }
`;

const AiIntroduceBenefitModal:FC<IAiIntroduceBenefitModalProps> = ({ benefit: { subTitle, title, description }, onClose }) => (
  <Portal>
    <DisableScroll />
    <Frame>
      <div className='dimmer' />
      <div className='frame-modal'>
        <button className='btn-close' onClick={onClose}>
          <img src={iconClose} alt='닫기' />
        </button>
        <h2>{subTitle}</h2>
        <h1>{title}</h1>
        <section className='frame-description'>
          {description}
        </section>
      </div>
    </Frame>
  </Portal>
);

export default AiIntroduceBenefitModal;
