import { JDModalButton } from 'components/_v2/_common/modals/JDModalComponents';
import { NoticeType } from 'components/_v2/_common/modals/NoticeTemplateModal';
import colors from '__designkit__/common/colors';
import Fonts, { fonts, fontStyle } from '__designkit__/common/fonts';
import React, { FC } from 'react';
import styled from 'styled-components';
import { DateFormatYYMMDDHHMMUnit } from 'utils/DateUtils';
import JDAModalFrame from './modals/JDAModalFrame';
import { INoticeModalProps } from './modals/NoticeModal';

export type onOffType = 'ON'|'OFF'|'BOTH';

interface INoticeUpdateResultModalProps extends Pick<INoticeModalProps, 'isOpen'|'onOk'> {
  type: NoticeType|'BOTH';
  onOff: onOffType;
  dateTime:string;
}

const Frame = styled.div`
  padding:40px 24px;
  width:304px;
  .ul-notice-update-result {
    font: ${Fonts.B1_Medium};
    color:${colors.CG_90};
    padding-bottom:16px;
    border-bottom:1px solid ${colors.CG_40};
    li {
      display:flex;
      flex-direction:row;
      justify-content:flex-start;
      align-items:flex-start;
      .list-title {
        ${fontStyle.BODY2_MEDIUM};
        color:${colors.CG_60};
        display:flex;
        flex-direction:row;
        justify-content:flex-start;
        align-items:center;
        &:after {
          content:'';
          display:inline-block;
          height:13px;
          width:1px;
          background:${colors.CG_40};
          margin:0 6px;
        }
      }
      .list-content { flex: 1; }
    }
  }
  .footer-message {
    margin-top:16px;
    text-align:center;
    ${fonts.NOTO_13_400};
    line-height:21px;
    letter-spacing:-0.24px;
    color:${colors.CG_70};
    white-space:pre-line;
    margin-bottom:32px;
  }
`;

export const updateResultMessage = (type:NoticeType, onOff: onOffType) => `${type} 알림 ${onOff === 'ON' ? '수신동의' : '수신거부'} 처리 완료`;

const NoticeUpdateResultModal:FC<INoticeUpdateResultModalProps> = ({ type, onOff, onOk, isOpen, dateTime }) => (
  <JDAModalFrame
    isOpen={isOpen}
    isFrame
  >
    <Frame>
      <ul className='ul-notice-update-result'>
        <li>
          <span className='list-title'>전송자</span>
          잡다
        </li>
        <li>
          <span className='list-title'>일시</span>
          <span className='list-content'>
            {DateFormatYYMMDDHHMMUnit(dateTime)}
          </span>
        </li>
        <li>
          <span className='list-title'>처리 결과</span>
          <span className='list-content'>
            { type === 'BOTH' ? '마케팅 수신 처리 완료' : updateResultMessage(type, onOff)}
          </span>
        </li>
      </ul>
      <div className='footer-message'>
        {`추후 마이페이지 > 알림관리에서\n변경할 수 있습니다.`}
      </div>
      <JDModalButton className='btn-ok' onClick={onOk}>
        확인
      </JDModalButton>
    </Frame>
  </JDAModalFrame>
);

export default NoticeUpdateResultModal;
