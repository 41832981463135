import ImgBanner1 from 'assets/img/ai/introduce/img_introduce_banner_1.png';
import ImgBanner2 from 'assets/img/ai/introduce/img_introduce_banner_2.png';
import colors from '__designkit__/common/colors';
import { fonts, lineHeights } from '__designkit__/common/fonts';
import React from 'react';
import styled from 'styled-components/macro';

const Frame = styled.div`
  height:498px;
  background:${colors.cFEF8B1};
`;

const StyledInfo = styled.div`
  padding-top:48px;
  width:100%;
  display:flex;
  flex-direction: column;
  align-items: center;
  h2{
    ${fonts.NOTO_14_700};
    color:${colors.CG_80};
  }
  h1{
    text-align: center;
    margin-top:12px;
    ${fonts.NOTO_30_300};
    ${lineHeights.LINEHEIGHT_1_40};
    color:${colors.CG_80};
    letter-spacing: -1px;
    em{
      ${fonts.NOTO_30_700};
      color:${colors.CG_90};
    }
  }

  div{
    position:relative;
    margin-top:32px;
    width:312px;
    img{
      position: absolute;
      top: 0;
      left:0;
    }
    .img-banner-1{
     animation-name: fadeout; 
     animation-duration: 7s; 
     animation-iteration-count: infinite; 
    }
    
    .img-banner-2{
      opacity: 0;
      animation-name: fadein;
      animation-duration: 7s;
      animation-iteration-count: infinite;
    }

  }

   @keyframes fadeout {
    0%{
      opacity:1;
    }
    35%{
      opacity:1;
    }
    50%{
      opacity:0;
    }
    85%{
      opacity:0;
    }
    100%{
      opacity:1;
    }
  }

  @keyframes fadein {
    0%{
      opacity:0;
    }
    35%{
      opacity:0;
    }
    50%{
      opacity:1;
    }
    85%{
      opacity:1;
    }
    100%{
      opacity:0;
    }
  }

`;

const AiIntroduceBanner: React.FC = () => (
  <Frame>
    <StyledInfo>
      <h2>공정 기회, 공정 평가, 평등 세상을 위해</h2>
      <h1>잡다는 스펙이 아닌 <br />
        <em>역량 중심 취업을 <br />
          지향 합니다.
        </em>
      </h1>
      <div>
        <img className='img-banner-1' src={ImgBanner1} alt='역량을 잡다란?' />
        <img className='img-banner-2' src={ImgBanner2} alt='역량을 잡다란?' />
      </div>
    </StyledInfo>
  </Frame>
);

export default AiIntroduceBanner;
