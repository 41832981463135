/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import colors from '__designkit__/common/colors';
import { absoluteCenterCenter, absoluteVerticalCenter } from 'consts/style/mixins';
import { fonts, lineHeights } from '__designkit__/common/fonts';

const Frame = styled.div`
  display: inline-block;
`;

const RatingFrame = styled.div`
  position: relative;
  left: 0;
  width: 231px;
  height: 26px;
  border-left: 1px solid ${colors.CG_60};
  border-right: 1px solid ${colors.CG_60};
`;

const RatingDefaultFrame = styled.div`
  display: flex;
  width: 229px;
  height: 12px;
  ${absoluteCenterCenter()};
`;

const RatingTextFrame = styled.div`
  display: flex;
  width: 229px;
  height: 14px;
  margin: 0 1px;
`;

const RatingActiveFrame = styled(RatingDefaultFrame)`
  overflow: hidden;
`;

const RatingRadiusFrame = styled.div`
  position: absolute;
  border-radius: 0 6px 6px 0;
  overflow: hidden;
  left: 0;
  top: 0;

  &.inner {
    margin: 1px 0;
  }
`;

const RatingColorStrokeBoxFrame = styled.div`
  display: flex;
`;

const RatingColorBoxFrame = styled.div`
  display: flex;
`;

const RatingMoveFrame = styled.div`
  width: 229px;
  transition: transform 1s;
  transform: translate(${(props) => (props as any)['data-rate'] - 229}px,0px);
  
  ${RatingColorStrokeBoxFrame}, ${RatingColorBoxFrame} {
    transition: transform 1s;
    transform: translate(${(props) => 229 - (props as any)['data-rate']}px,0px);
  }
  overflow: hidden;
`;

const RatingTextBox = styled.button`
  position: relative;
  background: none;
  width: 45px;
  height: 14px;
  margin-right: 1px;
  color: ${colors.CG_70};
  ${fonts.NOTO_10_500};
  ${lineHeights.LINEHEIGHT_1_20};
  white-space: nowrap;
  :last-child {
    margin-right: 0px;  
  }
`;

const RatingBox = styled.div`
  position: relative;
  background: ${colors.CG_40};
  border: 1px solid ${colors.CG_50};
  width: 46px;
  height: 12px;
  border-left: none;
  :last-child {
    width: 45px;
    border-right: none;  
  }
  &:not(:last-child) {
    &:after {
      content: '';
      left: 45px;
      ${absoluteVerticalCenter()};
      height: 20px;
      width: 1px;
      background: ${colors.CG_50};
    }
  }
`;

const RatingColorStrokeBox = styled.div`
  width: 46px;
  height: 12px;
  
  &:last-child {
    width: 45px;
  }
  &.yellow {
    background: ${colors.cE7DA4B};
  }
  &.lime {
    background: ${colors.cA4D95A};
  }
  &.green {
    background: ${colors.G_200};
  }
  &.sky {
    background: ${colors.c5EC7C3};
  }
  &.blue {
    background: ${colors.B_80};
  }
`;

const RatingColorBox = styled.div`
  width: 45px;
  height: 10px;
  margin-right: 1px;

  &:last-child {
    width: 44px;
    margin-right: 0px;
  }
  &.yellow {
    background: ${colors.Y_100};
  }
  &.lime {
    background: ${colors.cBFEF7B};
  }
  &.green {
    background: ${colors.G_100};
  }
  &.sky {
    background: ${colors.S_100};
  }
  &.blue {
    background: ${colors.c718DD7};
  }
`;

interface IDifficultyRatingProps {
  rating?: 'NONE' | 'VERY_EASY' | 'EASY' | 'NORMAL' | 'HARD' | 'VERY_HARD';
}

const DifficultyRating: FC<IDifficultyRatingProps> = ((props) => {
  const { rating } = props;

  const getRating2Score = () => {
    switch (rating) {
      case 'VERY_EASY':
        return 46;
      case 'EASY':
        return 46 * 2;
      case 'NORMAL':
        return 46 * 3;
      case 'HARD':
        return 46 * 4;
      case 'VERY_HARD':
        return 46 * 5 - 1;
      default:
        return 0;
    }
  };
  const [rate, setRate] = useState<number>(0);

  useEffect(() => {
    setRate(getRating2Score());
  }, [rating]);

  return (
    <Frame>
      <RatingFrame>
        <RatingDefaultFrame>
          <RatingBox /><RatingBox /><RatingBox /><RatingBox /><RatingBox />
        </RatingDefaultFrame>

        <RatingActiveFrame>
          <RatingMoveFrame data-rate={rate}>
            <RatingRadiusFrame>
              <RatingColorStrokeBoxFrame>
                <RatingColorStrokeBox className='yellow' /><RatingColorStrokeBox className='lime' /><RatingColorStrokeBox className='green' /><RatingColorStrokeBox className='sky' /><RatingColorStrokeBox className='blue' />
              </RatingColorStrokeBoxFrame>
            </RatingRadiusFrame>

            <RatingRadiusFrame className='inner'>
              <RatingColorBoxFrame>
                <RatingColorBox className='yellow' /><RatingColorBox className='lime' /><RatingColorBox className='green' /><RatingColorBox className='sky' /><RatingColorBox className='blue' />
              </RatingColorBoxFrame>
            </RatingRadiusFrame>
          </RatingMoveFrame>
        </RatingActiveFrame>
      </RatingFrame>
      <RatingTextFrame>
        <RatingTextBox>쉬움</RatingTextBox>
        <RatingTextBox>다소 쉬움</RatingTextBox>
        <RatingTextBox>보통</RatingTextBox>
        <RatingTextBox>다소 어려움</RatingTextBox>
        <RatingTextBox>어려움</RatingTextBox>
      </RatingTextFrame>
    </Frame>
  );
});

export default DifficultyRating;
