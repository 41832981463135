/* eslint-disable array-callback-return */
/* eslint-disable react/no-this-in-sfc */
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { DatePickerFormValueFrame } from 'components/_v2/_common/form/FormProfileValueFrame';
import FormProfileValueTitle from 'components/_v2/_common/form/FormProfileValueTitle';
import JDDatePickerInput from 'components/_v2/_common/input/JDDatePickerInput';
import JDDescriptionInput from 'components/_v2/_common/input/JDDescriptionInput';
import JDSelector, { JDSelectorType } from 'components/_v2/_common/input/JDSelector';
import { CareersActivitiesTypePlaceholdText } from 'components/_v2/profile/experience/ProfileExperienceActivitiesCard';
import JDBaseInput from 'components/inputs/JDBaseInput';
import { BaseProfileActivityAndForeignDto } from 'interfaces/_v2/insight/IInsightResumeRqRs';
import { ExperienceActivitiesType, ExperienceActivitiesTypeText } from 'interfaces/_v2/profile/IProfileExperienceRqRs';
import React, { FC, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

const ActivitiesFrame = styled.div`
    .content {
      padding: 0 1px;
    }
 .title-text{
      font:${Fonts.H4_Bold};
      color:${Colors.JOBDA_BLACK};
      margin-bottom: 24px;
    }
    .activity-selector{
        margin:20px 0 24px 0;
        .selector{
            margin-top: 12px;
        }
        .center-place{
            margin-top: 12px;
    }

    }
    
    .info-date{
      margin-bottom: 24px;
    }
    .margin-date{
      margin-top: 12px;
    }
`;

interface IResumeEditExperience{
    prefixName:string;
    experienceIdx:number;
    isVisible:boolean
    experienceFields?:BaseProfileActivityAndForeignDto[]
}

const ResumeEditExperience:FC<IResumeEditExperience> = ({ prefixName, experienceIdx, isVisible, experienceFields }) => {
  const { control, watch, setValue, trigger } = useFormContext();
  const topRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isVisible && topRef.current) {
      topRef.current.scrollIntoView();
    }
  }, [isVisible]);

  return (
    <ActivitiesFrame hidden={!isVisible} ref={topRef}>
      <div className='title-text'>
        경험을 통해 얻은 결과와 배운 점을<br />구체적으로 작성해주세요.
      </div>
      {
        experienceFields?.map((field, index) => (
          <div hidden={index !== experienceIdx} className='content'>
            <div className='activity-selector'>
              <FormProfileValueTitle required>활동 구분</FormProfileValueTitle>
              <div className='selector'>
                <JDSelector
                  name={`${prefixName}[${index}].tag`}
                  selectTitle='선택'
                  type={JDSelectorType.NORMAL}
                  value={watch(`${prefixName}[${index}]`).tag || ''}
                >
                  <select>
                    <option value='' hidden>선택</option>
                    {
                Object.entries(ExperienceActivitiesTypeText).map(([key, text]) => <option key={`option-school-level-${index}-${key}`} value={key} selected={watch(`${prefixName}[${index}].tag`) === key}>{text}</option>)
              }
                  </select>
                </JDSelector>
              </div>
              <div className='center-place'>
                <JDBaseInput
                  name={`${prefixName}[${index}].title`}
                  placeholder='기관/장소를 입력해 주세요.'
                  maxLength={35}
                  defaultValue={watch(`${prefixName}[${index}]`).title}
                />
              </div>
            </div>
            <FormProfileValueTitle required>기간</FormProfileValueTitle>
            <DatePickerFormValueFrame>
              <JDDatePickerInput
                name={`${prefixName}[${index}].startDate`}
                placeholder='연도. 월.'
                month
                fullSize
                defaultValue={watch(`${prefixName}[${index}]`).startDate}
              />
              <span className='date-picker-divider'>~</span>
              <JDDatePickerInput
                name={`${prefixName}[${index}].endDate`}
                placeholder='연도. 월.'
                month
                fullSize
                defaultValue={watch(`${prefixName}[${index}]`).endDate}
              />
            </DatePickerFormValueFrame>

            <FormProfileValueTitle>내용</FormProfileValueTitle>
            <JDDescriptionInput
              name={`${prefixName}[${index}].description`}
              placeholder={CareersActivitiesTypePlaceholdText[watch(`${prefixName}[${index}].tag`) as ExperienceActivitiesType] || '내용을 간단히 입력해 주세요.'}
              maxLength={1000}
              height={120}
              autoFocus={false}
              defaultValue={watch(`${prefixName}[${index}]`).description}
            />
          </div>
        ))
      }

    </ActivitiesFrame>
  );
};

export default ResumeEditExperience;
