import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import CultureListItemBaseData, { TOPIC_TYPE } from 'consts/_v2/company/CompanyDetailType';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

const AdvantageGraphFrame = styled.div<{ percent: number, tier?:number}>`
    width: 100%;
    height: 48px;
    display: flex;
    padding: 12px 16px;
    border-radius: 4px;
    background-color: ${colors.JOBDA_WHITE};
    position: relative;
    .inside-span{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        z-index: 1;
        align-items: center;
        .text-image{
            display: flex;
            align-items: center;
            .icon{
                display: flex;
                align-items: center;
            }
            .text{
                font:${Fonts.B2_Bold};
                color:${(props) => (props.tier === 1 ? `${colors.WHITE_100}` : `${colors.JOBDA_BLACK}`)} ;
                margin-left: 12px;
            }
        }
        .percent-text{
            font:${Fonts.B3_Medium};
            color:${colors.CG_60};
        }
    }
    .percent-gauge {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%; 
      height: 48px;
      background-color: ${(props) => (props.tier === 1 ? `${colors.JOBDA_BLACK}` : props.tier === 2 ? `${colors.CG_50}` : `${colors.CG_40}`)};
      border-radius: 4px;
      transform-origin: left;
      transform: scaleX(${({ percent }) => percent / 100});
      transition: transform 1s ease-in-out;
    }
`;
interface ICompanyAdvantageGraph {
    index: number;
  topicType: TOPIC_TYPE;
  questionNumber: number;
  averageScore: number | null;
  answerYn: boolean | null;
  tier:number;
}

const CompanyAdvantageGraph:FC<ICompanyAdvantageGraph> = ({ index, tier, topicType, questionNumber, averageScore, answerYn }) => {
  const contents = CultureListItemBaseData[topicType][questionNumber]?.contents;
  const icon = CultureListItemBaseData[topicType][questionNumber]?.icon;
  const [newTier, setNewTier] = useState<number>(0);
  useEffect(() => {
    if (averageScore) {
      if (tier === 1 && averageScore < 65) {
        setNewTier(2);
      } else {
        setNewTier(tier);
      }
    }
  }, [averageScore]);

  return (
    <AdvantageGraphFrame key={index} percent={averageScore || 0} tier={newTier}>
      <div className='inside-span'>
        <div className='text-image'>
          <div className='icon'>
            <Icon name={icon} size={24} />
          </div>
          <div className='text'>{contents}</div>
        </div>
        <div className='percent-text'>{averageScore}%</div>
      </div>
      <div className='percent-gauge' />
    </AdvantageGraphFrame>
  );
};

export default CompanyAdvantageGraph;
