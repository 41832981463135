import React, { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Fonts from '__designkit__/common/fonts';
import Colors from '__designkit__/common/colors';
import IComponentProps from 'interfaces/props/IComponentProps';

const Frame = styled.div<{ width: string }>`
  position: relative;
  display: flex;
  width: ${({ width }) => (width || '100%')};
  height: fit-content;
  min-height: 30px;
  padding: 0 16px;
  border-bottom: 1px solid ${Colors.CG_40};
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  & > div:not(:last-child) {
    margin-right: 24px;
  }
`;

const TabItem = styled.div<{ current: boolean }>`
  padding-bottom: 12px;
  font: ${Fonts.B2_Bold};
  color: ${({ current }) => (current ? Colors.JOBDA_BLACK : Colors.CG_60)};
`;

const Flag = styled.div`
  position: absolute;
  bottom: 0;
  left: 16px;
  width: 0px;
  height: 2px;
  background-color: ${Colors.JOBDA_BLACK};
  transition: .3s;
`;

export interface Tab {
  tabId: number;
  tabName: string;
}

interface IJDTabProps extends IComponentProps {
  tabs: Tab[];
  currentTab: number;
  tabSetter: React.Dispatch<React.SetStateAction<number>>;
  width?: string;
}

// JDTab 사용법
// JDTab을 사용하는 컴포넌트에서 currentTab과 setCurrentTab을 useState로 선언하고 넘겨준다.
// 각 Tab에 따라 보여줄 컨텐츠는 외부에서 컨트롤한다.

// JDTab 인자설명
// tabs: 그리고자 하는 탭의 리스트로 각 탭은 tabId와 tabName을 가지고 있다. (required)
// currentTab: 현재 탭을 나타내며, default 값으로 사용된다. (required)
// tabSetter: currentTab을 컨트롤할 수 있는 setter이다. (required)
// width: 간혹, 외부의 Frame이 padding을 가지고 있어서 JDTab이 꽉 차지 않을 때가 있다. 이 때, width를 조정해주면 된다. (optional)

const JDTab: FC<IJDTabProps> = ({ tabs, currentTab, tabSetter, width = '100%' }) => {
  const tabRef = useRef<HTMLDivElement | null>(null);
  const flagRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const tab = tabRef.current;
    const flag = flagRef.current;

    if (tab && flag) {
      const currentTabItem = tab.querySelector(`#tab-${currentTab}`) as HTMLDivElement;

      if (currentTabItem) {
        const { left, width: tabWidth } = currentTabItem.getBoundingClientRect();
        flag.style.left = `${left + tab.scrollLeft - 1}px`;
        flag.style.width = `${tabWidth + 2}px`;
      }
    }
  }, [tabs, currentTab]);

  return (
    <Frame ref={tabRef} width={width}>
      <Flag ref={flagRef} />
      { tabs.map((tab) => <TabItem key={tab.tabId} id={`tab-${tab.tabId}`} current={currentTab === tab.tabId} onClick={() => tabSetter(tab.tabId)}>{tab.tabName}</TabItem>) }
    </Frame>
  );
};

export default JDTab;
