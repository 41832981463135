/* eslint-disable no-restricted-properties */
import ChattingAPI from 'api/chattingAPI';
import { ADMIN_META_DATA_TYPE } from 'consts/_v2/chatting/chatting';
import { ChannelNotificationWhether, ChattingServerError, MyChannel } from 'interfaces/_v2/chatting/IChattingRqRs';
import { action, observable, reaction } from 'mobx';
import { Channel, Client, GetMessagesResponse, Message, MessageTypeEnum, User } from 'talkplus-sdk';

// 전체채팅방 목록 정렬 함수
// 멤버수 > 마지막 메세지 여부 > 마지막 메세지 시간 순으로 정렬
const sortChannelList = (a: Channel, b: Channel) => {
  const now = new Date().getTime();
  const diffA = now - new Date(a.lastSentAt).getTime();
  const diffB = now - new Date(b.lastSentAt).getTime();
  // 시간 차이를 hours로 변환
  const hoursA = diffA / (1000 * 60 * 60);
  const hoursB = diffB / (1000 * 60 * 60);
  // t = 마지막 메세지 경과 시간 / 24
  const tA = Math.pow(hoursA + 1, 2);
  const tB = Math.pow(hoursB + 1, 2);

  const scoreA = a.memberCount / tA;
  const scoreB = b.memberCount / tB;

  return scoreB - scoreA;
};

// 내채팅방 목록 정렬 함수
// 마지막 메세지 여부 > 마지막 메세지 시간 순으로 정렬
const sortChannelListForMy = (a: Channel, b: Channel) => {
  if (a.lastMessage && !b.lastMessage) return -1;
  if (!a.lastMessage && b.lastMessage) return 1;
  if (a.lastMessage && b.lastMessage) {
    if (a.lastMessage.createdAt > b.lastMessage.createdAt) return -1;
    if (a.lastMessage.createdAt < b.lastMessage.createdAt) return 1;
    return 0;
  } return 0;
};

const getTalkPlusAppId = (): string => {
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return process.env.REACT_APP_TALK_PLUS_APP_ID as string;
    case 'staging2':
      return process.env.REACT_APP_TALK_PLUS_QA_APP_ID as string;
    case 'staging':
      return process.env.REACT_APP_TALK_PLUS_ST_APP_ID as string;
    case 'development':
      return process.env.REACT_APP_TALK_PLUS_DV_APP_ID as string;
    default:
      return process.env.REACT_APP_TALK_PLUS_DV_APP_ID as string;
  }
};
export default class ChattingModel {
  @observable client: Client = new Client({ appId: getTalkPlusAppId() });
  @observable user: User | null = null;
  @observable myChannelList: MyChannel[] | null = null;
  @observable myChannelNotificationList: {[channelId: string]: boolean} | null = null;
  @observable allChannelList: Channel[] | null = null;
  @observable currentChannel: MyChannel | null = null;
  @observable messageList: Message[] = [];
  @observable lastMessageId: string | undefined = undefined;
  @observable isFetchAllMesaage: boolean = false;
  @observable replyMessage: Message | null = null;
  @observable isLoading: boolean = false;
  @observable isOpenChannelLongTouchMenu: boolean = false;

  constructor() {
    // 채팅방 알림 설정 목록이 갱신될 때마다, 내 채팅방 목록의 알림 여부를 업데이트하는 리액션
    reaction(() => this.myChannelNotificationList, (myChannelNotificationList) => {
      if (this.myChannelList) {
        const temp: MyChannel[] = this.myChannelList.map((channel: MyChannel) => {
          const tempChannel: MyChannel = channel;
          tempChannel.notificationEnabled = false;
          if (myChannelNotificationList && myChannelNotificationList[channel.id]) tempChannel.notificationEnabled = true;
          return tempChannel;
        });
        this.setMyChannelList(temp);
      }
    });
  }

  // 채팅 모달을 닫을 때, 채팅과 관련된 모든 설정 값들을 초기화하는 함수
  @action
  reset = () => {
    this.resetChannel();
    this.isOpenChannelLongTouchMenu = false;
  }

  // 채팅방에서 나올 때, 채팅방과 관련된 모든 설정 값들을 초기화하는 함수
  @action
  resetChannel = () => {
    this.currentChannel = null;
    this.messageList = [];
    this.lastMessageId = undefined;
    this.isFetchAllMesaage = false;
    this.replyMessage = null;
    this.isLoading = false;
  }

  // 채팅 서버에 로그인한 유저 정보를 설정하는 함수
  @action
  setUser = (user: User | null) => {
    this.user = user;
  }

  // 내 채널 리스트를 설정하는 함수
  @action
  setMyChannelList = (myChannelList: MyChannel[] | null) => {
    this.myChannelList = myChannelList ? myChannelList.sort(sortChannelListForMy) : null;
  }

  // 모든 채널 리스트를 설정하는 함수
  @action
  setAllChannelList = (allChannelList: Channel[] | null) => {
    this.allChannelList = allChannelList ? allChannelList.sort(sortChannelList) : null;
  }

  // 현재 채널을 설정하는 함수
  @action
  setCurrentChannel = (currentChannel: MyChannel) => {
    this.currentChannel = currentChannel;
  }

  // 채팅방의 메시지 리스트를 추가하는 함수
  // 'add'는 사용자가 입력하거나, 실시간으로 전달받는 메세지로 맨 앞에 추가, 'fetch'는 채팅 더보기를 통해 불러온 메세지로 맨 뒤에 추가
  @action
  setMessageList = (messageList: Message[], mode: 'add' | 'fetch') => {
    const newMessageList = this.messagePretreatment(messageList);
    if (mode === 'fetch') {
      if (this.messageList.length === 0) this.messageList = newMessageList;
      else this.messageList = [...this.messageList, ...newMessageList];
    } else if (mode === 'add') {
      this.messageList = [...newMessageList, ...this.messageList];
    }
  }

  isAdminMessageType = (message: Message) => [MessageTypeEnum.Admin, MessageTypeEnum.AdminHidden].includes(message.type);

  getCustomData = (text: string) => {
    let customData;
    try {
      customData = JSON.parse(text);
    } catch (e) {
      customData = [];
    }
    return customData;
  }

  messagePretreatment = (messageList: Message[]) => {
    const temp = messageList.map((message) => {
      if (this.isAdminMessageType(message)) {
        if (message.data?.type) {
          if (!(message.data.type === ADMIN_META_DATA_TYPE.REMIND_JOB_POSTING_DEADLINE_D_DAY || message.data.type === ADMIN_META_DATA_TYPE.REMIND_JOB_POSTING_DEADLINE_D_3)) {
            const customData = this.getCustomData(message.text);
            return {
              ...message,
              text: '',
              data: {
                ...message.data,
                customData,
              },
            };
          }
        }
      }

      if (message.parentMessage) {
        if (this.isAdminMessageType(message.parentMessage)) {
          if (message.parentMessage.data?.type) {
            if (!(message.parentMessage.data.type === ADMIN_META_DATA_TYPE.REMIND_JOB_POSTING_DEADLINE_D_DAY || message.parentMessage.data.type === ADMIN_META_DATA_TYPE.REMIND_JOB_POSTING_DEADLINE_D_3)) {
              const customData = this.getCustomData(message.parentMessage.text);
              return {
                ...message,
                parentMessage: {
                  ...message.parentMessage,
                  text: '',
                  data: {
                    ...message.parentMessage.data,
                    customData,
                  },
                },
              };
            }
          }
        }
      }

      return message;
    });

    return temp;
  }

  // 마지막으로 받은 메세지의 Id를 설정하는 함수
  @action
  setLastMessageId = (lastMessageId: string | undefined) => {
    if (lastMessageId === undefined) this.setIsFetchAllMessage(true);
    this.lastMessageId = lastMessageId;
  }

  // 모든 메세지를 가져왔는지 여부를 설정하는 함수
  @action
  setIsFetchAllMessage = (isFetchAllMesaage: boolean) => {
    this.isFetchAllMesaage = isFetchAllMesaage;
  }

  // 채팅방 나가기 모달을 열고 닫는 함수
  @action
  setIsOpenChannelLongTouchMenu = (isOpenChannelLongTouchMenu: boolean) => {
    this.isOpenChannelLongTouchMenu = isOpenChannelLongTouchMenu;
  }

  // 로딩 상태를 설정하는 함수
  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  }

  // 답장 모드를 설정하는 함수
  @action
  setReplyMessage = (message: Message | null) => {
    this.replyMessage = message;
  }

  // 내 채널들의 알림 여부 목록를 설정하는 함수
  @action
  setMyChannelNotificationList = (myChannelNotificationList: ChannelNotificationWhether[]) => {
    const notificationObj: {[channelId: string]: boolean} = {};
    myChannelNotificationList.forEach((notification) => { notificationObj[notification.channelId] = true; });
    this.myChannelNotificationList = notificationObj;
  }

  // 전체 채널의 정보를 업데이트하는 함수
  @action
  updateChannel = (channel: Channel) => {
    const temp = this.allChannelList;
    if (!temp) return;
    const index = temp.findIndex((item) => item.id === channel.id);
    temp[index] = channel;
    this.allChannelList = temp;
  }

  // 메세지의 정보를 업데이트하는 함수
  @action
  updateMessage = (message: Message) => {
    const temp = this.messageList;
    if (!temp) return;
    const index = temp.findIndex((item) => item.id === message.id);
    temp[index] = message;
    this.messageList = temp;
  }

  // 채팅방의 메시지 리스트를 가져오는 함수
  getChattingMessageList = async (): Promise<GetMessagesResponse> => {
    const res = await this.client.getMessages({
      channelId: this.currentChannel ? this.currentChannel.id : '',
      lastMessageId: this.lastMessageId,
      limit: 50,
      order: 'latest',
    });
    return res;
  }

  // 채팅방에서 나가는 Btn을 클릭했을 때 실행되는 함수
  onClickChattingLeaveBtn = async () => {
    if (!this.currentChannel) return;
    try {
      await this.client.leaveChannel({ channelId: this.currentChannel.id });
      this.setMyChannelList(await this.makeMyChannelList());
      this.setIsOpenChannelLongTouchMenu(false);
    } catch (e) {
      const err = e as ChattingServerError;
      console.error(err);
    }
  };

  // 내가 속한 채팅방과 내가 알림 설정한 채팅방 목록을 호출해서 결합해, myChannelList를 만드는 함수
  makeMyChannelList = async (): Promise<MyChannel[]> => {
    const myChannelListByTalkPlus = await ChattingAPI.getMyChannel(this.client);
    const myChannelNotificationInfpByBE = await ChattingAPI.getAllChannelNotificationWhether();

    const notificationObj: {[channelId: string]: boolean} = {};
    myChannelNotificationInfpByBE.forEach((notification) => { notificationObj[notification.channelId] = true; });

    const temp: MyChannel[] = myChannelListByTalkPlus.map((channel: Channel) => {
      const tempChannel: MyChannel = { ...channel, notificationEnabled: false };
      if (notificationObj[channel.id]) tempChannel.notificationEnabled = true;
      return tempChannel;
    });

    return temp;
  }
}
