import SpacingBlock from '__designkit__/components/SpacingBlock';
import React from 'react';
import styled from 'styled-components';
import joyEmpty from 'assets/_v2/positionList/img_character_joy_empty.png';
import Fonts from '__designkit__/common/fonts';
import Colors from '__designkit__/common/colors';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  height: fit-content;
  padding: 16px 0 24px;

  & > div.title {
    font: ${Fonts.B1_Bold};
    color: ${Colors.JOBDA_BLACK};
  }

  & > div.sub-title {
    font: ${Fonts.B3_Medium_P};
    color: ${Colors.CG_70}
  }
`;

const ChannelEmpty = () => (
  <Frame>
    <img src={joyEmpty} alt='채팅이 없을 때의 이미지' width={80} />
    <SpacingBlock size={24} vertical />
    <div className='title'>아직 해당하는 기업 채팅이 없어요...</div>
    <div className='sub-title'>다른 채팅도 한번 살펴보는 건 어떨까요?</div>
  </Frame>
);

export default ChannelEmpty;
