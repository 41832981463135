import { Button, ButtonMode } from '__designkit__/button/Button';
import Colors from '__designkit__/common/colors';
import JDCheckInput, { JD_CHECK_INPUT_TYPE } from 'components/_v2/_common/input/JDCheckInput';
import ProfileEditTitleComponent from 'components/_v2/profile/ProfileEditTitleComponent';
import { CompanyLocation } from 'consts/CompanyLocationType';
import { ConditionRegisterType } from 'consts/_v2/profile/ProfileType';
import useQueryParams from 'hooks/useQueryParams';
import useToast from 'hooks/useToast';
import { IPutConditionLocationsRq } from 'interfaces/_v2/profile/IConditionRqRs';
import IComponentProps from 'interfaces/props/IComponentProps';
import useProfileConditions, { useFetchSaveProfileConditionsLocations } from 'query/profiles/useProfileConditionsQuery';
import React, { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

const TabFrame = styled.div<{isResumeModal?:boolean}>`
  width: 100%;
  background-color: ${Colors.WHITE_100};
  height: calc(100% - 78px);

`;
const BtnFrame = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 0 16px;
  height: fit-content;
  position: fixed;
  bottom: env(safe-area-inset-bottom);
  height: 78px;
  background-color: ${Colors.WHITE_100};
  z-index: 100000;
`;
const Frame = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  row-gap: 8px;
  column-gap: 8px;
  padding: 0 16px;

  .jd-input{
    width: 100%;
  }

  .check-box {
    .label {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
`;
const ButtonFrame = styled.div`
  position: fixed;
  bottom: env(safe-area-inset-bottom);
  width: 100%;
  height: 78px;
  padding: 16px;
  border-top: 1px solid ${Colors.CG_40};
  background-color: ${Colors.WHITE_100};
`;

interface ILocationTabProps extends IComponentProps {
  hidden?: boolean;
  locationData?: number[];
  setConditionRegisterType: (type: ConditionRegisterType) => void;
  isResumeModal?: boolean;

}

const LocationTab: FC<ILocationTabProps> = ({ isResumeModal, hidden, locationData, setConditionRegisterType }) => {
  const { setToastObject } = useToast();
  const { sourcePage } = useQueryParams();
  const { refetch } = useProfileConditions(true);
  const saveSuccess = async () => {
    await refetch();

    setToastObject({ type: 'SUCCESS', isOpen: true, message: '성공적으로 저장되었습니다.' });
    setConditionRegisterType(ConditionRegisterType.LIST);
  };
  const { mutate: saveProfileLocationConditions } = useFetchSaveProfileConditionsLocations(saveSuccess);
  const defaultValues:IPutConditionLocationsRq = { locations: [], sourcePage: '' };
  const formMethods = useForm({
    mode: 'onSubmit',
    defaultValues,
  });
  const { register, setValue, watch, getValues, formState, reset } = formMethods;
  const handleLocationAllClick = (checked: boolean) => {
    if (!checked) {
      Object.values(CompanyLocation).forEach((location, index) => {
        setValue(`location[${index}]`, false);
      });
    } else {
      Object.values(CompanyLocation).forEach((location, index) => {
        setValue(`location[${index}]`, location.code);
      });
    }
  };
  useEffect(() => {
    if (!locationData) return;

    const formValues: Record<string, number | boolean> = {};
    Object.values(CompanyLocation).forEach((location, index) => {
      const isLocationSelected = locationData.includes(Number(location.code));

      formValues[`location[${index}]`] = isLocationSelected ? Number(location.code) : false;
    });
    reset({ ...formValues });
  }, [locationData, reset]);

  useEffect(() => {
    if (hidden) return; // hidden이 true이면 아무 작업도 하지 않음

    const temp = watch('location', []) as string[];
    const filteredLocations = temp
      .filter((locationCode: string | number) => locationCode)
      .map((locationCode: string | number) => Number(locationCode));
    const locationsArray = watch('location', []) as boolean[];

    const allSelected = !locationsArray.some((location) => location === false);
    if (getValues('location-TOTAL') !== allSelected) {
      setValue('location-TOTAL', allSelected);
    }
    if (JSON.stringify(getValues('locations')) !== JSON.stringify(filteredLocations)) {
      setValue('locations', filteredLocations);
    }
  }, [watch, setValue, getValues]);

  const onClickSaveBtn = () => {
    const locations = String(getValues('locations'))
      .split(',')
      .map((location) => Number(location.trim()))
      .filter((code) => !Number.isNaN(code) && Number.isFinite(code));

    const locationRq = {
      locations: locations.length > 0 ? locations : [],
      sourcePage: sourcePage || null,
    };

    if (locationRq.locations.length > 0) {
      saveProfileLocationConditions(locationRq);
    } else {
      setToastObject({ type: 'ERROR', isOpen: true, message: '저장에 필요한 정보를 작성해 주세요.' });
    }
  };
  if (hidden) return null;

  return (
    <TabFrame isResumeModal={isResumeModal}>
      <FormProvider {...formMethods}>
        <input name='locations' ref={register} aria-hidden />
        <ProfileEditTitleComponent
          title='희망하는 근무지역을 모두 선택해 주세요.'
          subtitle='다양한 지역을 선택할 수록 더 많은 추천을 받아볼 수 있어요.'
          divider={false}
        />
        <Frame>
          <JDCheckInput
            key='check-option-TOTAL'
            type={JD_CHECK_INPUT_TYPE.SQUARE_CHIP}
            name='location-TOTAL'
            value
            className='check-box'
            label={<div className='check-box-label'>전체 지역</div>}
            onChange={(checked: boolean) => handleLocationAllClick(checked)}
          />
          {Object.values(CompanyLocation).filter((location) => location.code !== '10000')
            .map((location, index) => (
              <JDCheckInput
                key={`check-option-${location.code}`}
                type={JD_CHECK_INPUT_TYPE.SQUARE_CHIP}
                name={`location[${index}]`}
                value={location.code}
                className='check-box'
                label={<div className='check-box-label'>{location.name}</div>}
              />
            ))}
        </Frame>
      </FormProvider>
      {
        isResumeModal
          ? (
            <BtnFrame>
              <Button label='이전' buttonMode={ButtonMode.DEFAULT} size='large' outLined onClick={() => setConditionRegisterType(ConditionRegisterType.LIST)} />
              <Button label='저장하기' buttonMode={ButtonMode.PRIMARY} size='large' onClick={onClickSaveBtn} />
            </BtnFrame>
          )
          : (
            <ButtonFrame>
              <Button label='저장' buttonMode={ButtonMode.PRIMARY} size='large' onClick={onClickSaveBtn} />
            </ButtonFrame>
          )
      }
    </TabFrame>
  );
};

export default LocationTab;
