import { Divider1G40 } from 'components/divider/Divider';
import AccApplyGame from 'components/_v2/acc/AccApplyGame';
import AccApplySelfReport from 'components/_v2/acc/AccApplySelfReport';
import AccApplyVideo from 'components/_v2/acc/AccApplyVideo';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import AccResultsModel from 'models/_v2/AccResultsModel';
import React, { FC } from 'react';
import styled from 'styled-components';

const AccApplyTotalReportContainer = styled.div`
  display: flex;
  flex-direction: column;

`;
const DividerFrame = styled.div`
    display: flex;
    padding: 0 16px;
    .divider-24{
            width:100%;
    }
`;

interface IAccApplyTotalReportProps{
    hidden:boolean;
    accResultsModel? : AccResultsModel
}
const AccApplyTotalReport:FC<IAccApplyTotalReportProps> = ({ hidden, accResultsModel = new AccResultsModel() }) => (
  <>
    {accResultsModel.applyResult
      && (
      <AccApplyTotalReportContainer hidden={hidden}>
        <AccApplySelfReport unreliableYn={accResultsModel.applyResult?.moduleResults[0].unreliableYn} />
        <DividerFrame>
          <Divider1G40 className='divider-24' />
        </DividerFrame>
        <AccApplyGame unreliableYn={accResultsModel.applyResult?.moduleResults[2].unreliableYn} />
        <DividerFrame>
          <Divider1G40 className='divider-24' />
        </DividerFrame>
        <AccApplyVideo unreliableYn={accResultsModel.applyResult?.moduleResults[1].unreliableYn} />

      </AccApplyTotalReportContainer>
      )}
  </>

);
export default inject(injectStore.accResultsModel)(observer(AccApplyTotalReport));
