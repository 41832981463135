/* eslint-disable jsx-a11y/media-has-caption */
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import InterviewVideoPlayer from 'components/_v2/passInterview/InterviewVideoPlayer';
import PassInterviewDetailModal from 'components/_v2/passInterview/PassInterviewDetailModal';
import PassInterviewListCard from 'components/_v2/passInterview/PassInterviewListCard';
import { usePassInterviewQuestionResults, usePassInterviewResults, usePassInterviewVideo } from 'query/passInterview/usePassInterviewQuery';
import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ImgJoyEmpty from 'assets/_v2/positionList/img_character_joy_empty.png';
import PassInterviewSkeleton from 'components/_v2/passInterview/PassInterviewSkeleton';

interface IPassInterviewHistory{

}
const Frame = styled.div`
  padding:12px 16px;
`;
export const PassInterviewBanner = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
background-color: ${Colors.CG_30};
border-radius: 8px;
  padding: 12px 28px 12px 24px;
  .text-frame{
    display: flex;
    flex-direction: column;
    gap: 4px;
    .title{
      font: ${Fonts.B1_Bold};
      color: ${Colors.JOBDA_BLACK};
    }
    .detail{
      font: ${Fonts.B2_Medium};
      color: ${Colors.JOBDA_BLACK};
    }
  }
  
`;

const DateLine = styled.div`
  display: flex;
  margin: 12px 0;
  align-items: center;
  .divider{
    height: 1px;
    flex:1 0 0;
    background-color: ${Colors.CG_40};
  }
  .text{
    font:${Fonts.B3_Medium};
    color:${Colors.CG_60};
    margin: 0 12px;
  }
`;
const EmptyFrame = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  margin-top: 180px;
  .img{
    width: 80px;
    height:80px;  
  }
  .bold-text{
    margin-top: 16px;
    font:${Fonts.H4_Bold};
    color:${Colors.JOBDA_BLACK};
  }
  .light-text{
    margin-top: 8px;
    font:${Fonts.B2_Medium};
    color:${Colors.JOBDA_BLACK};
  }

`;
const PassInterviewHistory:FC<IPassInterviewHistory> = () => {
  const [selectedSn, setSelectedSn] = useState<number>(-1);
  const { data: passInterviewResults, isLoading } = usePassInterviewResults();
  // const { data: passInterviewQuestionResults } = usePassInterviewVideo(1);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState<boolean>(false);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const videoEl = useRef(null);
  const sortedDates = passInterviewResults
    ? Object.entries(passInterviewResults.practiceRecordQuestionsByDate)
      .sort((a, b) => b[0].localeCompare(a[0])) // 날짜 기준 오름차순 정렬
    : [];

  return (
    <Frame>
      <PassInterviewBanner>
        <div className='text-frame'>
          <div className='title'>면접 연습기록 리스트</div>
          <div className='detail'>{isSafari ? '연습 영상은 PC에서만 확인 가능합니다.' : '면접 연습은 PC에서만 가능합니다.'}</div>
        </div>
        <Icon name='idea' size={64} />
      </PassInterviewBanner>
      {isLoading ? (
        Array.from({ length: 6 }, (_, i) => <PassInterviewSkeleton key={i} />)
      ) : sortedDates.length > 0 ? (
        sortedDates.map(([date, items]) => (
          <React.Fragment key={date}>
            <DateLine>
              <div className='divider' />
              <div className='text'>{date.replace(/-/g, '.')}</div>
              <div className='divider' />
            </DateLine>
            {items.map((item) => (
              <PassInterviewListCard
                key={item.practiceQuestionSn}
                data={item}
                onClick={() => {
                  setSelectedSn(item.practiceQuestionSn);
                  setIsDetailModalOpen(true);
                }}
              />
            ))}
          </React.Fragment>
        ))
      ) : (
        <EmptyFrame>
          <img src={ImgJoyEmpty} alt='' className='img' />
          <div className='bold-text'>아직 면접 연습을 진행하지 않았어요.</div>
          <div className='light-text'>면접 연습을 완료하시면 여기서 기록을 확인할 수 있어요.</div>
        </EmptyFrame>
      )}
      {selectedSn && (
        <PassInterviewDetailModal
          selectedSn={selectedSn}
          isOpen={isDetailModalOpen}
          onClose={() => setIsDetailModalOpen(false)}
        />
      )}
      {/* <InterviewVideoPlayer source={passInterviewQuestionResults || null} /> */}
      <PassInterviewDetailModal selectedSn={selectedSn} isOpen={isDetailModalOpen} onClose={() => setIsDetailModalOpen(false)} />
    </Frame>
  );
};
export default PassInterviewHistory;
