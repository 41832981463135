import React, { FC, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import styled from 'styled-components';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import IconCareer from 'assets/_v2/preferred/icon_chart_search_blue.svg';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import Context from 'models/Context';
import WorkingPreferenceTab from './preferredWorkingPreferenceTab';
import NHISCareerAddModal from '../profile/careers/NHISCareerAddModal';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: slide .5s ease-out forwards;
  height: calc(100% - 140px);
  /* overflow: auto; */

  ::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
  }

  ::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
  }

  ::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
  }

  @keyframes slide {
    0% {
      transform: translateY(10%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .step-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 24px 0;
    text-align: center;
    font: ${Fonts.H4_Bold};
    color: ${Colors.JOBDA_BLACK};

    .title-icon {
      margin-bottom: 12px;
      width: 40px;
      height: 40px;
    }
  }
  
  & .btn-frame {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;

    button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
    height: 100px;
    padding: 24px 16px 24px;
    border: 1px solid ${Colors.CG_50};
    border-radius: 8px;
    background-color: ${Colors.WHITE_100};
    user-select: none;

    &.active {
      border: 1px solid ${Colors.JOBDA_BLACK};
      background: ${Colors.JOBDA_WHITE};
      text-align: left;
    }

    .btn-text {
      text-align: left;
      font: ${Fonts.B1_Bold_P};
      color: ${Colors.BLACK_100};
    }
  }

    .sub-text {
      text-align: center;
      font: ${Fonts.B2_Medium};
      color: ${Colors.CG_60};
    }
  }
`;

interface CareerEntryOptionsProps {
  context?:Context;
  preferenceRef: React.RefObject<any>;
}

const CareerEntryOptions:FC<CareerEntryOptionsProps> = ({ context = new Context(), preferenceRef }) => {
  const [showWorkingPreferenceTab, setShowWorkingPreferenceTab] = useState(false);
  const [showNHISCareerAddModal, setShowNHISCareerAddModal] = useState(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [activeButton, setActiveButton] = useState<string | null>(null);

  const handleButtonClick = (buttonType: string) => {
    if (buttonType === 'WorkingPreference' && isLoaded) {
      return;
    }
    setActiveButton(buttonType);
    if (buttonType === 'NHIS') {
      handleShowNHISCareerAddModal();
    } else if (buttonType === 'WorkingPreference') {
      handleShowWorkingPreferenceTab();
    }
  };

  const handleShowWorkingPreferenceTab = () => {
    setShowWorkingPreferenceTab(true);
  };

  const handleShowNHISCareerAddModal = () => {
    setShowNHISCareerAddModal(true);
  };

  function onClickRegisterBtn(): void {
    throw new Error('Function not implemented.');
  }

  useEffect(() => {
    if (activeButton === 'NHIS' && isLoaded) {
      context.isPreferredClicked = true;
    } else if (activeButton === 'WorkingPreference') {
      context.isPreferredClicked = true;
    } else {
      context.isPreferredClicked = false;
    }
  }, [activeButton, isLoaded]);

  return (
    <Frame onContextMenu={(e) => e.preventDefault()}>
      <div className='step-title'>
        <img className='title-icon' alt='경력' src={IconCareer} />
        경력을 알려주세요.
      </div>
      <div className='btn-frame'>
        <button
          className={isLoaded ? 'active' : ''}
          onClick={() => handleButtonClick('NHIS')}
        >
          <span className='btn-text'>1분만에<br />내 모든 경력을 불러올래요.</span>
          {isLoaded && <Icon name='verified-filled' size={40} color={Colors.G_150} />}
        </button>
        <button
          className={activeButton === 'WorkingPreference' ? 'active' : ''}
          onClick={() => handleButtonClick('WorkingPreference')}
          disabled={isLoaded}
        >
          <span className='btn-text'>아직 신입이에요!<br />잡다를 통해 취업준비 하고 싶어요.</span>
        </button>
        <span className='sub-text'>내 프로필에서 경력을 수정할 수 있습니다.</span>
      </div>

      {/* 로드완료되면 workingPreferenceTab노출 */}
      {isLoaded && (
      <WorkingPreferenceTab
        setConditionRegisterType={() => {}}
        ref={preferenceRef}
      />
      )}

      {showNHISCareerAddModal && (
        <NHISCareerAddModal
          onClickClose={() => setShowNHISCareerAddModal(false)}
          afterLogic={() => onClickRegisterBtn()}
          setIsLoaded={setIsLoaded}
        />
      )}

      {showWorkingPreferenceTab && !isLoaded && (
      <WorkingPreferenceTab
        setConditionRegisterType={() => {}}
        ref={preferenceRef}
      />
      )}
    </Frame>
  );
};

export default inject(injectStore.context)(observer(CareerEntryOptions));
