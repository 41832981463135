import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import ImgDumy from 'assets/_v2/positionList/img_dumy.png';
import RoutePaths from 'consts/RoutePaths';
import { lineClampBox, lineClampOnlyOne } from 'consts/_v2/_common/style/mixins';
import { IAdRecommendJobPostingDto } from 'interfaces/_v2/main/IJobPostings';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import { DateFormatYYYYMMDDCCHHMMUnit, getDDayString, str2DateTime } from 'utils/DateUtils';
import React, { FC, useEffect, useState } from 'react';
import { IconBookmark24 } from 'consts/assets/icons/iconPages';
import { IJobPostBookmarkDto, IJobPostBookmarkRs } from 'interfaces/_v2/IBookmarkRs';
import { useBookmarkPositions, useBookmarkStatus } from 'query/bookmark/useBookmarkQuery';
import Login from 'models/Login';
import CalendarModel from 'models/_v2/CalendarModel';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import Context from 'models/Context';
import Icon from '__designkit__/icon/Icon';

const RecruitJobCardFrame = styled.div`
    display: flex;
    justify-content: flex-start;
    padding: 16px;
    position:relative;
    .recruit-img{
        width:118px;
        height:118px;
        border-radius: 4px;
    }

    .bookmark {
      position: absolute;
      top: 31px;
      right: 16px;
    }
    .text-frame{
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        margin-left: 14px;
        height: 120px;
        .title{
            font: ${Fonts.B1_Bold};
            color:${colors.JOBDA_BLACK};
            margin-bottom: 8px;
            ${lineClampOnlyOne()};
            width:52vw;
        }
        .subtitle{
            font: ${Fonts.B2_Medium};
            color:${colors.CG_70};
            margin-bottom: 16px;
            ${lineClampBox(2)};
            width:52vw;
        }
        .date-frame{
            display: flex;
            align-items: center;
            .date-text{
                font: ${Fonts.B3_Medium};
                color:${colors.CG_70};
                margin-right:8px;
            }
            .d-day-frame{
                display: flex;
                background-color: ${colors.JOBDA_WHITE};
                border:1px solid ${colors.CG_50};
                border-radius: 4px;
                justify-content: center;
                align-items: center;
                padding: 4px 6px;
           .d-day-text{
                font: ${Fonts.B4_Medium};
                color:${colors.CG_90};
        }
    }
        }
    }
`;

interface IRecruitJobCard {
    item:IJobPostBookmarkDto;
  login?:Login
  calendarModel?:CalendarModel
  context?:Context
}

const RecruitJobCard:FC<IRecruitJobCard> = ({ item, login = new Login(), calendarModel = new CalendarModel(), context = new Context() }) => {
  const history = useHistory();
  const [bookmark, setBookmark] = useState<boolean>(false);
  const { data: bookmarkStatus, refetch } = useBookmarkStatus();
  const { pathname } = useLocation();
  useEffect(() => {
    if (item.bookmarkYn === undefined) {
      setBookmark(false);
    } else {
      setBookmark(item.bookmarkYn);
    }
  }, [item.bookmarkYn]);
  const checkBookmark = async (e: any) => {
    e.stopPropagation();
    if (!login.userInfo) {
      context.setRedirectUrl(pathname);
      history.push(RoutePaths.login);
    } else {
      if (!bookmark) await calendarModel.saveJobPostingsBookmark(item.sn);
      else await calendarModel.deleteJobPostingsBookmark(item.sn);
      refetch();
      setBookmark(!bookmark);
    }
  };

  return (
    <RecruitJobCardFrame onClick={(e) => {
      history.push(RoutePaths.jobs_posting(item.sn));
      e.stopPropagation();
    }}
    >
      <img
        src={`${item.representativeImageUrl}?w=240&h=240`}
        className='recruit-img'
        alt='img'
        onError={(e) => e.currentTarget.src = ImgDumy}
      />
      <div
        role='presentation'
        className='bookmark'
        onClick={(e) => {
          checkBookmark(e);
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        {bookmark ? <Icon name='bookmark-filled' size={24} color={colors.G_150} /> : <Icon name='bookmark' color={colors.CG_40} size={24} />}
      </div>

      <div className='text-frame'>
        <div className='title'>
          {item.companyName}
        </div>
        <div className='subtitle'>
          {item.name}
        </div>
        <div className='date-frame'>
          {item.postEndDateTime
        && (
        <div className='date-text'>
          {DateFormatYYYYMMDDCCHHMMUnit(item.postEndDateTime)}
        </div>
        )}

          {item.postEndDateTime
            ? (
              <div className='d-day-frame'>
                <div className='d-day-text'>{getDDayString(str2DateTime(item.postEndDateTime))}</div>
              </div>
            )
            : (
              <div className='d-day-frame'>
                <div className='d-day-text'>상시 채용</div>
              </div>
            )}
        </div>
      </div>
    </RecruitJobCardFrame>
  );
};

export default inject(injectStore.calendarModel, injectStore.login, injectStore.context)(observer(RecruitJobCard));
