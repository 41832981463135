import ImgTrashBin from 'assets/_v2/profile/icon_trashbin.svg';
import JDPlusTextButton from 'components/_v2/_common/button/JDPlusTextButton';
import FormProfileValueTitle from 'components/_v2/_common/form/FormProfileValueTitle';
import JDDescriptionInput from 'components/_v2/_common/input/JDDescriptionInput';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import React, { FC, useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

const DatePickerFormValueFrame = styled.div`
display:flex;
margin-bottom: 24px;
  justify-content: space-between;
  >span{
    position: relative;
    top: 28px;
    text-align:center;
    font: ${Fonts.B1_Medium};
  }

  .date-picker-divider {
    color: ${colors.CG_80};
    position: relative;
    top: 22px;
    margin: 0 6px;
  }

  .date-text {
    margin-top: 4px;
    padding-left: 16px;
    color: ${colors.CG_70};
  }
`;

const ActivitiesFrame = styled.div`
    .delete-frame{
        display: flex;
        justify-content: flex-end;
        margin-bottom: 22px;
        .delete-field{
            display:flex;
            .delete-text{
                margin-left: 4px;
                font: ${Fonts.B2_Medium_P};
                line-height: 18px;
                color:${colors.c44474B_80}
        }
        }
        
    }
    .activity-selector{
        margin:20px 0 24px 0;
        .selector{
            margin-top: 12px;
        }
        .center-country{
            margin-top: 12px;
    }

    }
    
    .info-date{
      margin-bottom: 24px;
    }
    .margin-date{
      margin-top: 12px;
    }
`;
const Divider = styled.div`
    width: 100%;
    height: 1px;
    background: ${colors.CG_40};
    margin: 28px 0;
`;
const ProfileExperienceExperiencesCard:FC = () => {
  const useFormed = useFormContext();
  const { control } = useFormed;

  const name = `experiences`;
  const { fields: experiencesFields, append: experiencesAppend, remove: experiencesRemove } = useFieldArray({
    control,
    name,
  });
  useEffect(() => {
    if (experiencesFields.length === 0) {
      experiencesAppend({ description: '' }, false);
    }
  }, [experiencesFields.length]);

  return (
    <>
      {
        (experiencesFields).map((field, experiencesIndex) => (
          <ActivitiesFrame>

            <div key={`${field.id}`}>
              <div className='delete-frame'>
                <div className='delete-field' role='button' onClick={() => experiencesRemove(experiencesIndex)}>
                  <img src={ImgTrashBin} alt='trash' />
                  <div className='delete-text'>삭제</div>
                </div>
              </div>

              <FormProfileValueTitle>내용</FormProfileValueTitle>
              <JDDescriptionInput
                key={field.id}
                name={`experiences[${experiencesIndex}].description`}
                placeholder={`본인의 경험에 대해 자유롭게 설명해 주세요. \n \n- '희망의 연탄 나르기'봉사활동 매주 참여 (2011년 ~) \n- '전국 대학생 디자인 공모전' 참여 및 대상 수상 (2019.03) \n \n[프로젝트] \n- 2022.01 ~ 2022.04 \n- 담당 역할 : PO 및 프론트 개발\n- 신규 커머스 플랫폼 개발 프로젝트 참여\n- 플레이스토어에 판매 (누적 10,000건 다운로드, 만족도 4.2/5점)`}
                maxLength={1000}
                height={300}
                autoFocus={false}
                defaultValue={field.description}
              />
              <Divider />

            </div>
          </ActivitiesFrame>

        ))
      }
      <JDPlusTextButton
        text='추가'
        onClick={() => experiencesAppend({ description: '' })}
      />
    </>
  );
};

export default ProfileExperienceExperiencesCard;
