import ImgJoyEmpty from 'assets/_v2/challenge/img_character_joy_empty_soon.svg';
import ImgInformation from 'assets/_v2/_common/icon_information_circle_15_Regular_grey.svg';
import ChallengeDetailPositionsModal from 'components/_v2/challengeDetail/ChallengeDetailPositionsModal';
import { LocationCode } from 'consts/CompanyLocationType';
import { JobApplicationProcessType } from 'consts/PositionType';
import RoutePaths from 'consts/RoutePaths';
import colors from '__designkit__/common/colors';
import { RecruitmentTypeText } from 'consts/_v2/position/PositionType';
import Fonts from '__designkit__/common/fonts';
import { IChallengePositionsDto } from 'interfaces/_v2/challenge/IChallengeDetailPositions';
import IChallengeDetailProps from 'interfaces/_v2/challenge/IChallengeDetailProps';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import ChallengeDetailModel from 'models/_v2/ChallengeDetailModel';
import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { overDateNow } from 'utils/DateUtils';
import { krNumberFormat } from 'utils/NumberFormatUtil';

const Frame = styled.div`
  padding: 0 16px;

    .divider {
        margin-bottom: 32px;
    }
    .head {
        font: ${Fonts.H5_Bold};
        color: ${colors.CG_60};

        >b {
            color: ${colors.JOBDA_BLACK};
        }
    }

    >ul {
        margin: 8px 0;
    }

    .link[data-disabled=true] {
        pointer-events: none;
    }
`;

const AllViewButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 12px 0;
    font: ${Fonts.B3_Bold};
    color: ${colors.JOBDA_BLACK};
    border: 1px solid ${colors.JOBDA_BLACK};
    border-radius: 4px;
`;

const CardFrame = styled.div`
    display: flex;
    flex-direction: column;
    padding: 22px 24px 18px 24px;
    border: 1px solid ${colors.CG_40};
    border-radius: 6px;
    margin-bottom: 8px;
    .apply-yn{
      display: flex;
      width: fit-content;
      height:20px;
      padding:4px 5px;
      background: ${colors.JOBDA_BLACK};
      border-radius: 4px;
      margin-bottom: 8px;
      span{
        display: flex;
        font: ${Fonts.B4_Medium};
        line-height: 12px;
        color:${colors.WHITE_100};
      }
    }

    .applying {
      display: flex;
      width: fit-content;
      height:20px;
      padding:4px 5px;
      background: ${colors.CG_70};
      border-radius: 4px;
      margin-bottom: 8px;
      span{
        display: flex;
        font: ${Fonts.B4_Medium};
        line-height: 12px;
        color:${colors.WHITE_100};
      }
    }
    .position-title{
      font: ${Fonts.B2_Bold};
      color:${colors.JOBDA_BLACK};
      line-height: 17px;
    }
    .job-group-name {
        font: ${Fonts.B3_Bold};
        color: ${colors.JOBDA_BLACK};
    }

   
`;

const PositionDescFrame = styled.div`
    display: flex;
    margin-top: 8px;
    white-space: nowrap;
    .normal-box {
        font: ${Fonts.B4_Bold_P};
        color: ${colors.JOBDA_BLACK};
        max-width: 100px;
        white-space:nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .salary-box{
      overflow: hidden;
      text-overflow: ellipsis;
      font: ${Fonts.B4_Bold_P};
        color: ${colors.JOBDA_BLACK};
    }
    .column-divider{
      display: flex;
      justify-content: center;
      align-items: center;
        width:1px;
        height:10px;
        margin: 0 6px;
        background: ${colors.CG_40};
        :last-child{
          visibility: hidden;
        }
      }

`;

const InfoCard = styled.div`
  display: flex;
  width:100%;
  padding:16px 12px;
  background: ${colors.JOBDA_WHITE};
  border-radius: 8px;
  justify-content: center;
  .info-text{
    color:${colors.CG_70};
    font: ${Fonts.B3_Medium};
    line-height: 18px;
    margin-left: 10px;
  }
`;

const JoyFrame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 24px;
  .joy-img{
    margin-top: 64px;
  }
  .joy-text{
    font: ${Fonts.H5_Bold};
    line-height: 28px;
    margin-top: 32px;
    text-align: center;
  }

`;
interface IChallengePositionCardProps {
    positionInfo: IChallengePositionsDto;
}

export const ChallengePositionCard:FC<IChallengePositionCardProps> = ({ positionInfo }) => (
  <CardFrame>
    {
      positionInfo.jobApplicationProcessType !== null && positionInfo.jobApplicationProcessType === JobApplicationProcessType.WRITING_RESUME && <div className='applying'><span>지원서 작성 중</span></div>
    }
    {
      positionInfo.jobApplicationProcessType !== null && positionInfo.jobApplicationProcessType !== JobApplicationProcessType.WRITING_RESUME && <div className='apply-yn'><span>지원 완료</span></div>
    }

    <div className='position-title'>{positionInfo.positionName}</div>
    <PositionDescFrame>
      {positionInfo.companyName
      && (
      <>
        <div className='normal-box'>{positionInfo.companyName}</div>
        <div className='column-divider' />
      </>
      )}
      {positionInfo.locationCode
      && (
      <>
        <div className='normal-box'>{LocationCode(positionInfo.locationCode)}</div>
        <div className='column-divider' />
      </>
      )}

      {positionInfo.jobTitleNames
      && (
      <>
        <div className='normal-box'>{`${positionInfo.jobTitleNames[0]} `}</div>&nbsp;
        <div className='normal-box'>{positionInfo.jobTitleNames.length > 1 && `+ ${positionInfo.jobTitleNames.length - 1}`}</div>
        <div className='column-divider' />
      </>
      )}

      {positionInfo.recruitmentType
      && (
      <>   <div className='normal-box'>{RecruitmentTypeText[positionInfo.recruitmentType]}</div>
        <div className='column-divider' />
      </>
      )}

      { positionInfo.minSalary && <div className='salary-box'>연봉{`${krNumberFormat(positionInfo.minSalary)}만원 이상`}</div> }
    </PositionDescFrame>
  </CardFrame>
);

const ChallengeDetailPositions:FC<IChallengeDetailProps> = ({ challengeDetailModel = new ChallengeDetailModel() }) => {
  const challengePositions = challengeDetailModel.challengeDetailPositions;
  const challengeTitle = challengeDetailModel.challengeDetail?.title;
  const { challengeDetail } = challengeDetailModel;
  const [isAllViewOpen, setIsAllViewOpen] = useState<boolean>(false);

  return (
    <>
      <Frame>

        {challengeDetail && overDateNow(challengeDetail.postStartDateTime)
          ? (
            <>
              <InfoCard>
                <img src={ImgInformation} alt='info' />
                <div className='info-text'>{`해당 챌린지에서 ${challengeDetailModel.challengeDetail?.applicableSize === null ? `무제한으로` : `${challengeDetailModel.challengeDetail?.applicableSize}개의`} 포지션에 지원이 가능합니다.`}</div>
              </InfoCard>
              <ul>
                {challengePositions?.map((data, index) => (
                  index < 3
            && (
            <>
              <Link to={RoutePaths.position_jd(data.positionSn)} className='link' data-disabled={false}>
                <li key={`matching-company-card-${data.positionSn}`}><ChallengePositionCard positionInfo={data} /></li>
              </Link>
            </>
            )
                ))}
              </ul>
              { challengePositions && challengePositions.length > 3
      && (
      <AllViewButton disabled={false} onClick={() => setIsAllViewOpen(true)}>
        포지션 {challengePositions?.length}개 모두보기
      </AllViewButton>
      )}

            </>
          )
          : (
            <>
              <JoyFrame>
                <img src={ImgJoyEmpty} alt='joy' className='joy-img' />
                <div className='joy-text'>어마어마한 포지션들이 <br />곧 공개됩니다.</div>
              </JoyFrame>
            </>
          )}
      </Frame>
      <ChallengeDetailPositionsModal
        isOpen={isAllViewOpen}
        onClose={() => setIsAllViewOpen(false)}
        challengeTitle={challengeTitle || ''}
        challengePositions={challengePositions || []}
      />
    </>
  );
};

export default inject(injectStore.challengeDetailModel)(observer(ChallengeDetailPositions));
