/* eslint-disable react-hooks/exhaustive-deps */
import Portal from 'components/common/Portal';
import { IconBuilding, IconClose, IconClose20, IconSearch } from 'consts/assets/icons/iconPages';
import colors from '__designkit__/common/colors';
import { fonts, lineHeights } from '__designkit__/common/fonts';
import { dimmer, JDAInputFrameBasic, JDAInputFrameMessage } from 'consts/style/mixins';
import IComponentProps from 'interfaces/props/IComponentProps';
import { INameCodeRs } from 'interfaces/rqrs/ICommonRqRs';
import { IMajorAliasListGetDto } from 'interfaces/rqrs/IMajorAliasListGetRs';
import React, { forwardRef, MouseEvent, ReactNode, useEffect, useRef, useState } from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import styled from 'styled-components/macro';

const Frame = styled.div`
  width:100%;

  .input-frame{
    ${JDAInputFrameBasic()};
    // 돋보기 바깥 padding
    padding:0 16px;
    display:flex;
    align-items:center;

    // 돋보기 내부 input
    input{
      padding: 0;
      margin-left:10px;
    }

    img{
      margin-right:6px;
      object-fit: none;
    }

    .close-btn {
      display: none;
      margin-right: 0;
    }

    .close-btn.active {
      display: block;
    }

  }
  .message{
   ${JDAInputFrameMessage()};
  }
`;

const StyledSearchHeaderFrame = styled.div`
  background-color:${colors.WHITE_100};
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index:1;
  
  .header{
   display:flex;
   flex-direction:row;
   align-items:center;
   justify-content:space-between;
   height:calc(56px + env(safe-area-inset-top));
   padding-top:calc(17px + env(safe-area-inset-top));
   padding-left:18px;
   padding-bottom:18px;
   padding-right:18px;
  
   >span{
    ${fonts.NOTO_15_700};
    color:${colors.CG_90};
   }
  }

  // 모달로 띄어지는 입력창 (외부)
  .inner.input-frame{
    display: block;
    width: 100%;
    height:56px;
    padding: 0px 16px;
    color: ${colors.CG_90};
    display:flex;
    align-items:center;
    box-shadow: 0px 1px 1px ${colors.CG_50};
    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px ${colors.JOBDA_WHITE} inset !important;
    }
    input:-webkit-autofill:focus {
     -webkit-box-shadow: 0 0 0 30px ${colors.WHITE_100} inset !important;
    }
    input {
      background: inherit;
      ${fonts.NOTO_15_400};
      ${lineHeights.LINEHEIGHT_1_50};
      height: 100%;
      border: none;
      outline: none;
      ::placeholder {
        color: ${colors.CG_60};
      }
    }
    img{
      margin-right:6px;
      object-fit: none;
    }

    .search-icon {
      margin-right: 6px;
    }
  }
`;

const StyledSearchResultFrame = styled.div`
  background-color:${colors.WHITE_100};
  li{
    position:relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width:100%;
    height:56px;
    ${fonts.NOTO_15_400};
    color:${colors.CG_90};
    padding: 12px 16px;
    img{
      margin-right:16px;
      object-fit: none;
    }
  }
`;

const StyledSearchFrame = styled.div`
 ${dimmer()};
  z-index:100002;
  display:flex;
  flex-direction:column;
  opacity: 1;
  transition: opacity 0.25s;
  transition-delay: 0.25s;
  overflow-y:auto;
  background-color: ${colors.WHITE_100};
`;

interface IJDSearchInput extends IComponentProps {
  fieldRef?: React.RefObject<HTMLInputElement | null>;
  textName: string;
  codeName: string;
  title?: string;
  customValidator?: RegisterOptions;
  onSelectItem?: () => void;
  onSearch: (searchText: string) => void;
  searchList: INameCodeRs[] | IMajorAliasListGetDto[];
  defaultTextName?: string; // default 텍스트값;
  defaultCodeName?: string; // default code;
  isDirectItem?: boolean; // 직접입력 아이템 사용
  searchInputCustomPattern?: RegExp;
  listItemIcon?: ReactNode;
}

const JDSearchInput = forwardRef((props: IJDSearchInput) => {
  const { fieldRef, textName, codeName, customValidator, className, placeholder, onSearch, searchList, searchInputCustomPattern, onSelectItem, defaultTextName, defaultCodeName, isDirectItem, disabled, title, listItemIcon } = props;
  const useFormed = useFormContext();
  const { register, errors, watch, setValue } = useFormed;

  const frameRef = useRef<HTMLDivElement>(null);
  const innerInputRef = useRef<HTMLInputElement>(null);

  const [openSearchView, setOpenSearchView] = useState<boolean>(false);
  const [searchLis, setSearchLis] = useState<JSX.Element>(<></>);
  const [searchText, setSearchText] = useState<string>('');

  const instanceOfINameCodeRs = (object: any): object is INameCodeRs => {
    if (object === undefined) return false;
    return ('code' in object);
  };

  const instanceOfIMajorAliasListGetDto = (object: any): object is IMajorAliasListGetDto => {
    if (object === undefined) return false;
    return ('majorCode' in object);
  };

  const onClose = () => {
    setSearchText('');
    setSearchLis(<></>);
  };

  const DirectItme = () => (
    isDirectItem && (
      <li
        role='presentation'
        onClick={() => {
          setValue(textName, `$${searchText}`);
          setOpenSearchView(false);
          if (onSelectItem) onSelectItem();
          onClose();
        }}
      >
        {listItemIcon ?? <IconBuilding />}<span>직접 입력</span>
      </li>
    )
  );

  useEffect(() => {
    if (frameRef.current && searchText !== '' && searchList !== undefined) {
      if (instanceOfINameCodeRs(searchList[0])) {
        setSearchLis(
          (<ul>
            {
              Array.from(searchList as INameCodeRs[]).map((data, index) => (
                <li
                  key={`search-item-${data}-${index}`}
                  role='presentation'
                  onClick={(e: MouseEvent<HTMLLIElement>) => {
                    setValue(textName, data.name, { shouldDirty: true, shouldValidate: true });
                    setValue(codeName, String(data.code), { shouldDirty: true, shouldValidate: true });
                    setOpenSearchView(false);
                    if (onSelectItem) onSelectItem();
                    onClose();
                  }}
                >
                  {listItemIcon ?? <IconBuilding />}<span>{data.name}</span>
                </li>
              ))
            }
            {DirectItme()}
           </ul>
          ),
        );
      }
      if (instanceOfIMajorAliasListGetDto(searchList[0])) {
        setSearchLis(
          (<ul>
            {
              Array.from(searchList as IMajorAliasListGetDto[]).map((data, index) => (
                <li
                  key={`search-item-${data}-${index}`}
                  role='presentation'
                  onClick={(e: MouseEvent<HTMLLIElement>) => {
                    setValue(textName, data.name, { shouldDirty: true, shouldValidate: true });
                    setValue(codeName, String(data.majorCode), { shouldDirty: true, shouldValidate: true });
                    setOpenSearchView(false);
                    if (onSelectItem) onSelectItem();
                    onClose();
                  }}
                >
                  {listItemIcon ?? <IconBuilding />}<span>{data.name}</span>
                </li>
              ))
            }
            {DirectItme()}
           </ul>
          ),
        );
      }
    }
  }, [searchList]);

  useEffect(() => {
    if (!openSearchView) {
      onClose();
    }
  }, [openSearchView]);

  return (
    <Frame className={`jda-input ${className || ''}`}>
      <div className={`input-frame ${errors[textName] === undefined}`}>
        <IconSearch className='search-icon' />
        <input
          ref={(ref) => {
            if (fieldRef !== undefined) (fieldRef as any).current = ref;
            (register(ref, customValidator));
          }}
          name={textName}
          placeholder={placeholder}
          disabled={disabled}
          autoComplete='off'
          onClick={() => {
            setOpenSearchView(true);
            setTimeout(() => innerInputRef.current?.focus(), 200);
          }}
          defaultValue={defaultTextName}
        />
        <input
          style={{ display: 'none' }}
          ref={register()}
          name={codeName}
          defaultValue={defaultCodeName}
        />
        {watch(textName)
          && (
            <IconClose20
              className={`close-btn ${watch(textName) ? 'active' : ''}`}
              onClick={() => {
                setValue(textName, '');
                setValue(codeName, '');
              }}
            />
          )}
      </div>
      <Portal>
        <StyledSearchFrame ref={frameRef} style={{ display: openSearchView ? 'block' : 'none' }}>
          <StyledSearchHeaderFrame>
            <div className='header'><span>{title ?? placeholder}</span><IconClose onClick={() => setOpenSearchView(false)} /></div>
            <div className={`inner input-frame ${errors[textName] === undefined}`}>
              <IconSearch className='search-icon' />
              <input
                ref={innerInputRef}
                type='text'
                placeholder={placeholder}
                onChange={(e) => {
                  if (searchInputCustomPattern && !searchInputCustomPattern.test(e.currentTarget.value)) {
                    e.currentTarget.value = e.currentTarget.value.slice(0, -1);
                  }
                  setSearchText(e.currentTarget.value);
                  if (onSearch) onSearch(e.currentTarget.value);
                }}
              />
            </div>
          </StyledSearchHeaderFrame>
          {searchText && (
            <StyledSearchResultFrame>
              {searchLis}
            </StyledSearchResultFrame>
          )}
        </StyledSearchFrame>
      </Portal>
    </Frame>
  );
});

export default JDSearchInput;
