// created by khj1115 on 2021-07-28

import InfoDetailForm from 'components/info/InfoDetailForm';
import InfoDetailLive from 'components/info/InfoDetailLive';
import InfoNotMatching from 'components/info/InfoNotMatching';
import InfoNotPartnership from 'components/info/InfoNotPartnership';
// import InfoNotPartnership from 'components/info/InfoNotPartnership';
import InfoSuggentionContent from 'components/info/InfoSuggentionContent';
import InfoYoutubeVideo from 'components/info/InfoVideo';
import ErrorCode from 'consts/ErrorCodes';
import RoutePaths from 'consts/RoutePaths';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import Info from 'models/Info';
import { injectStore } from 'models/store';
import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import styled from 'styled-components';

const Frame = styled.div`
  height: 100%;
`;

export interface IInfoDetailPageProps {
  info?: Info;
  context?: Context;
  passboard?:boolean;
}

const InfoDetailPage: React.FC<IInfoDetailPageProps> = ({ info = new Info(), context = new Context() }) => {
  const { infoSn } = useParams<{ infoSn: string }>();
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [errorCode, setErrorCode] = React.useState<ErrorCode | null>(null);
  useEffect(() => {
    (async () => {
      await info.init(Number(infoSn)).then(() => {
        setLoading(false);
      })
        .catch((e: any) => {
          context.setRedirectUrl(location.pathname);
          if (e.response.data.errorCode === ErrorCode.B902) {
            history.replace(RoutePaths.login);
          } else if (e.response.data.errorCode === ErrorCode.B903) {
            setErrorCode(ErrorCode.B903);
            setLoading(false);
          } else if (e.response.data.errorCode === ErrorCode.B904) {
            setErrorCode(ErrorCode.B904);
            setLoading(false);
          } else {
            history.replace(RoutePaths.notFound);
          }
        });
    })();

    return (() => {
      setLoading(true);
      setErrorCode(null);
    });
  }, [infoSn]);

  if (loading) return <></>;
  if (errorCode === ErrorCode.B903) {
    return <InfoNotPartnership />;
  }
  if (errorCode === ErrorCode.B904) {
    return <InfoNotMatching />;
  }
  return (
    <Frame>
      {info?.type === 'LIVE'
        ? <InfoDetailLive />
        : (
          <>
            {info?.type === 'VIDEO' && <InfoYoutubeVideo videoUrl={info.link} />}
            <InfoDetailForm />
            <InfoSuggentionContent />
          </>
        )}
    </Frame>
  );
};

export default inject(injectStore.info, injectStore.context)(observer(InfoDetailPage));
