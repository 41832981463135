import Colors from '__designkit__/common/colors';
import ImgJoyEmpty from 'assets/_v2/challenge/img_character_joy_empty_soon.png';
import Fonts from '__designkit__/common/fonts';
import colors from '__designkit__/common/colors';
import React, { FC } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
    width:100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;
    .img-joy{
        margin-top: 16px;
        width:96px;
        height:96px;
    }
    .title{
        margin-top: 32px;
        font: ${Fonts.H5_Bold};
        color:${Colors.JOBDA_WHITE};
    }
    .subtitle{
        margin-top: 8px;
        font: ${Fonts.B2_Medium_P};
        color:${Colors.CG_60}
    }

`;
interface IPaccNoPosition {
  skill?:boolean;
}
const PaccNoPosition:FC<IPaccNoPosition> = ({ skill }) => (
  <Frame>
    <img src={ImgJoyEmpty} className='img-joy' alt='img' />

    <div className='title'>포지션을 기다리고 있어요.</div>
    {skill
      ? (<div className='subtitle'>입력한 기술 키워드를 활용할 수 있는 채용이 시작되면 포지션을 확인할 수 있어요.</div>)
      : <div className='subtitle'>기업에서 채용을 시작하면 채용 포지션을 확인할 수 있어요.</div>}

  </Frame>
);
export default PaccNoPosition;
