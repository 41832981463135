import ProfileExperienceActivitiesCard from 'components/_v2/profile/experience/ProfileExperienceActivitiesCard';
import FormProfileSectionTitle from 'components/_v2/_common/form/FormProfileSectionTitle';
import { IProfileProps } from 'interfaces/_v2/profile/IProfileProps';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import ProfileModel from 'models/_v2/profile/ProfileModel';
import React, { FC } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
    position:relative;
    padding: 32px 16px 128px 16px;
    .activity-absolute{
      position: absolute;
      top:32px;
    }

`;
interface IProfileExperienceActivitiesProps extends IProfileProps{
    hidden:boolean
}
const ProfileExperienceActivities:FC<IProfileExperienceActivitiesProps> = ({ profileModel = new ProfileModel(), hidden }) => (
  <div aria-hidden={hidden}>

    <Frame>
      <FormProfileSectionTitle className='activity-absolute'>대내외 활동</FormProfileSectionTitle>
      <ProfileExperienceActivitiesCard />
    </Frame>

  </div>
);

export default inject(injectStore.profileModel)(observer(ProfileExperienceActivities));
