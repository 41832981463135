import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import FormRoundChip from 'components/_v2/_common/form/FormRoundChip';
import ProfileItemTitle from 'components/_v2/profile/ProfileItemTitle';
import { Divider1G40 } from 'components/divider/Divider';
import IProfileKnowledgeAndSkillsRs, { SkillLevelText } from 'interfaces/_v2/profile/IProfileKnowledgeAndSkillsRs';
import React, { FC } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
`;

const ContentFrame = styled.div`
    margin-bottom: 26px;

    .title {
        font: ${Fonts.B2_Bold};
        color: ${colors.JOBDA_BLACK};
        letter-spacing: -0.01em;
        margin-bottom: 20px;
    }

    .list-frame {
        display: flex;
        flex-wrap: wrap;
    }
`;

const ProfilePreviewKnowledgeAndSkill: FC<{ data: IProfileKnowledgeAndSkillsRs}> = ({ data }) => {
  const { examinations, licenses, skills } = data;

  return (
    <Frame>
      <ProfileItemTitle icon='design' text='지식 · 기술' />
      { licenses && licenses.length > 0 && (
      <ContentFrame>
        <p className='title'>자격증</p>
        <div className='list-frame'>
          { licenses.map((license) => (
            <FormRoundChip key={`skill-license-${license.code}`}>{license.name}</FormRoundChip>
          ))}
        </div>
      </ContentFrame>
      )}
      { examinations && examinations.length > 0 && (
      <ContentFrame>
        <p className='title'>공인 어학 시험</p>
        <div className='list-frame'>
          { examinations.map((examination) => (
            <FormRoundChip key={`skill-examination-${examination.code}`}>{examination.name} - {examination.score ? examination.score : examination.grade}</FormRoundChip>
          ))}
        </div>
      </ContentFrame>
      )}
      { skills && skills.length > 0 && (
      <ContentFrame>
        <p className='title'>기술 키워드</p>
        <div className='list-frame'>
          { skills.map((item) => (
            <FormRoundChip key={`skill-skill-${item.code}`}>{item.keyword} - {SkillLevelText[item.level]}</FormRoundChip>
          ))}
        </div>
      </ContentFrame>
      )}
      <Divider1G40 />
    </Frame>
  );
};

export default ProfilePreviewKnowledgeAndSkill;
