import MatchLoginConnectInfoModal from 'components/_v2/matchLogin/MatchLoginConnectInfoModal';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import MatchLoginCommonFailModal from 'components/_v2/matchLogin/MatchLoginCommonFailModal';
import MatchLoginConnectCancelModal from 'components/_v2/matchLogin/MatchLoginConnectCancelModal';
import MatchLoginConnectSuccessModal from 'components/_v2/matchLogin/MatchLoginConnectSuccessModal';
import MatchLoginFailAccountModal from 'components/_v2/matchLogin/MatchLoginFailAccountModal';
import MatchLoginPositionEndModal from 'components/_v2/matchLogin/MatchLoginPositionEndModal';
import MatchLoginRedundantEmailModal from 'components/_v2/matchLogin/MatchLoginRedundantEmailModal';
import MatchLoginRedundantSupportModal from 'components/_v2/matchLogin/MatchLoginRedundantSupportModal';
import JDAtsFailModal from 'components/_v2/_common/modals/JDAtsFailModal';
import MatchLoginResumeExistModal from 'components/_v2/matchLogin/MatchLoginResumeExistModal';
import ResumeModel from 'models/_v2/ResumeModel';
import { useHistory } from 'react-router';
import RoutePaths from 'consts/RoutePaths';

const MatchLogin: FC<{ login?: Login, resumeModel?:ResumeModel }> = ({ login = new Login(), resumeModel = new ResumeModel() }) => {
  const [isOpenMatchLoginModal, setIsOpenMatchLoginModal] = useState<boolean>(false);
  const [isOpenResumeExistModal, setIsOpenResumeExistModal] = useState<boolean>(false);

  const [isOpenSuccessModal, setIsOpenSuccessModal] = useState<boolean>(false);
  const [isOpenCancelModal, setIsOpenCancelModal] = useState<boolean>(false);
  const [isOpenFailRedundantSupportModal, setIsOpenFailRedundantSupportModal] = useState<boolean>(false);
  const [isOpenFailRedundantEmailModal, setIsOpenFailRedundantEmailModal] = useState<boolean>(false);
  const [isOpenFailAccountModal, setIsOpenFailAccountModal] = useState<boolean>(false);
  const [isOpenPositionEndModal, setIsOpenPositionEndModal] = useState<boolean>(false);
  const [isOpenCommonFailModal, setIsOpenCommonFailModal] = useState<boolean>(false);
  const [atsFailModalOpen, setAtsFailModalOpen] = useState<boolean>(false);

  const history = useHistory();

  useEffect(() => {
    checkMatchLoginModal();
  }, [login.matchLoginYn]);

  const checkMatchLoginModal = async () => {
    if (login.matchLoginYn) {
      if (await login.getResumeExists(login.publicKey, login.positionId)) {
        setIsOpenResumeExistModal(true);
      } else setIsOpenMatchLoginModal(true);
    }
  };

  const onProcess = async () => {
    try {
      await login.postMatchApplicantsAccount(login.publicKey, login.positionId);
      setIsOpenSuccessModal(true);
    } catch (e: any) {
      // B002 : 데이터를 찾을 수 없습니다.
      // B901 : ATS 서버와 통신에 실패했습니다.
      // B902 : 종료 또는 삭제된 포지션 입니다.
      // B903 : 이미 연동된 계정입니다.(재연동)
      // B904 : 이미 지원한 포지션 입니다.
      // B905 : 연동할 수 없는 계정입니다.
      if (e.response.data.errorCode === 'B901') {
        setAtsFailModalOpen(true);
      } else if (e.response.data.errorCode === 'B902') {
        setIsOpenPositionEndModal(true);
      } else if (e.response.data.errorCode === 'B903') {
        setIsOpenFailRedundantEmailModal(true);
      } else if (e.response.data.errorCode === 'B904') {
        setIsOpenFailRedundantSupportModal(true);
      } else if (e.response.data.errorCode === 'B905') {
        setIsOpenFailAccountModal(true);
      } else {
        setIsOpenCommonFailModal(true);
      }
      console.error(e);
    }
  };

  const onCancel = async () => {
    setIsOpenCancelModal(true);
  };

  const successCase = async () => {
    await resumeModel.getPositionSn(login.publicKey, login.positionId);
    history.push(RoutePaths.mypage_apply_Message(resumeModel.positionSn));
    setIsOpenSuccessModal(false);
  };
  return (
    <>
      <MatchLoginConnectInfoModal
        isOpen={isOpenMatchLoginModal}
        onClickClose={() => {
          setIsOpenMatchLoginModal(false);
          login.matchLoginYn = false;
        }}
        onProcess={onProcess}
        onCancel={onCancel}
      />
      <MatchLoginResumeExistModal
        isOpen={isOpenResumeExistModal}
        onClickClose={() => {
          setIsOpenResumeExistModal(false);
          login.matchLoginYn = false;
        }}
        onProcess={onProcess}
        onCancel={onCancel}
      />
      <MatchLoginConnectSuccessModal isOpen={isOpenSuccessModal} onClickClose={successCase} />
      <MatchLoginConnectCancelModal isOpen={isOpenCancelModal} onClickClose={() => { setIsOpenCancelModal(false); }} />
      <MatchLoginFailAccountModal isOpen={isOpenFailAccountModal} onClickClose={() => { setIsOpenFailAccountModal(false); }} />
      <MatchLoginRedundantSupportModal isOpen={isOpenFailRedundantSupportModal} onClickClose={() => { setIsOpenFailRedundantSupportModal(false); }} />
      <MatchLoginPositionEndModal isOpen={isOpenPositionEndModal} onClickClose={() => { setIsOpenPositionEndModal(false); }} />
      <MatchLoginCommonFailModal isOpen={isOpenCommonFailModal} onClickClose={() => { setIsOpenCommonFailModal(false); }} />
      <MatchLoginRedundantEmailModal isOpen={isOpenFailRedundantEmailModal} onClickClose={() => { setIsOpenFailRedundantEmailModal(false); }} />
      <JDAtsFailModal isOpen={atsFailModalOpen} onClickClose={() => setAtsFailModalOpen(false)} />
    </>
  );
};

export default inject(injectStore.login, injectStore.resumeModel)(observer(MatchLogin));
