enum ErrorCode {
  B001 = 'B001',
  B002 = 'B002',
  B901 = 'B901',
  B902 = 'B902',
  B903 = 'B903',
  B904 = 'B904',
  B905 = 'B905',
  B906 = 'B906',
  B907 = 'B907',
  B908 = 'B908',
  B909 = 'B909',
  B996 = 'B996',
  B997 = 'B997',
  B998 = 'B998',
  B999 = 'B999',
  D002 = 'D002',
  I001 = 'I001',
  U001 = 'U001',
  U002 = 'U002',
  U003 = 'U003',
  U004 = 'U004',
  U005 = 'U005',
}

export const ErrorCodes = {
  post_event_challenge: {
    [ErrorCode.B002]: 'B002',
    [ErrorCode.B903]: 'B903',
    [ErrorCode.B904]: 'B904',
    [ErrorCode.B905]: 'B905',
    [ErrorCode.B906]: 'B906',
    [ErrorCode.B907]: 'B907',
    [ErrorCode.B996]: 'B996',
    [ErrorCode.B997]: 'B997',
    [ErrorCode.B998]: 'B998',
    [ErrorCode.B999]: 'B999',
  },
  post_register: {
    [ErrorCode.B901]: 'B901',
    [ErrorCode.B902]: 'B902',
    [ErrorCode.B903]: 'B903',
    [ErrorCode.B904]: 'B904',
    [ErrorCode.B905]: 'B905',
  },
  post_users_password_verification: { [ErrorCode.B902]: 'B902' },
  post_users_password_reset: { [ErrorCode.B902]: 'B902' },
  put_users_password: {
    [ErrorCode.B901]: 'B901',
    [ErrorCode.B902]: 'B902',
  },
  post_users_id: { [ErrorCode.B902]: 'B902' },
  get_event_my_rank: {
    [ErrorCode.B901]: 'B901',
    [ErrorCode.B902]: 'B902',
  },
  get_event_code_check: { [ErrorCode.B901]: 'B901' },
  post_event: {
    [ErrorCode.B901]: 'B901',
    [ErrorCode.B902]: 'B902',
    [ErrorCode.B903]: 'B903',
    [ErrorCode.B904]: 'B904',
    [ErrorCode.B905]: 'B905',
    [ErrorCode.B906]: 'B906',
  },
  post_login: {
    [ErrorCode.U001]: 'U001',
    [ErrorCode.U002]: 'U002',
    [ErrorCode.U003]: 'U003',
    [ErrorCode.U004]: 'U004',
    [ErrorCode.U005]: 'U005',
  },
  put_company_like: { [ErrorCode.B901]: 'B901' },
  get_user_liked_companies_new_jobs: {
    [ErrorCode.B901]: 'B901',
    [ErrorCode.B902]: 'B902',
  },
  put_users_default: {
    [ErrorCode.B901]: 'B901',
    [ErrorCode.B902]: 'B902',
    [ErrorCode.B903]: 'B903',
  },
  put_match_profiles_education: {
    [ErrorCode.B901]: 'B901',
    [ErrorCode.B902]: 'B902',
    [ErrorCode.B903]: 'B903',
    [ErrorCode.B904]: 'B904',
    [ErrorCode.B905]: 'B905',
    [ErrorCode.B906]: 'B906',
    [ErrorCode.B907]: 'B907',
    [ErrorCode.B908]: 'B908',
  },
  put_match_profiles_career: {
    [ErrorCode.B901]: 'B901',
    [ErrorCode.B902]: 'B902',
    [ErrorCode.B903]: 'B903',
    [ErrorCode.B904]: 'B904',
    [ErrorCode.B905]: 'B905',
    [ErrorCode.B907]: 'B907',
  },
  get_match_positions_recommend: {
    [ErrorCode.B901]: 'B901',
    [ErrorCode.B902]: 'B902',
    [ErrorCode.B903]: 'B903',
    [ErrorCode.B904]: 'B904',
    [ErrorCode.B905]: 'B905',
  },
  get_curations_positions: {
    [ErrorCode.B901]: 'B901',
    [ErrorCode.B902]: 'B902',
    [ErrorCode.B903]: 'B903',
    [ErrorCode.B904]: 'B904',
    [ErrorCode.B905]: 'B905',
  },
};

export const ErrorCodeText = {
  post_event_challenge: {
    [ErrorCode.B002]: '사용자가 존재하지 않습니다.',
    [ErrorCode.B903]: '이미 참여한 이벤트입니다.',
    [ErrorCode.B904]: 'SMS 알림 동의가 되어있지 않습니다.',
    [ErrorCode.B905]: '이메일 알림 동의가 되어있지 않습니다.',
    [ErrorCode.B906]: '이벤트 응모 기간이 아닙니다.',
    [ErrorCode.B907]: '이벤트 참여 조건을 만족하지 못했습니다.',
    [ErrorCode.B996]: '기프티콘 발송을 위한 휴대폰 번호가 존재하지 않습니다.',
    [ErrorCode.B997]: '기프티콘 발송을 위한 마케팅 동의가 되어있지 않습니다.',
    [ErrorCode.B998]: '이벤트의 기프티콘 재고가 남아있지 않습니다.',
    [ErrorCode.B999]: '이미 기프티콘을 발송 받았습니다.',
  },
  post_register: {
    [ErrorCode.B901]: '이미 등록된 이메일과 아이디입니다.',
    [ErrorCode.B902]: '이미 등록된 이메일입니다.',
    [ErrorCode.B903]: '이미 등록된 아이디입니다.',
    [ErrorCode.B904]: '이미 등록된 휴대폰 번호입니다.',
    [ErrorCode.B905]: '이미 가입된 정보가 있습니다.\n다시 확인해 주세요.',
    [ErrorCode.B906]: '20일 이내에 탈퇴한 내역이 존재합니다.',
    [ErrorCode.B907]: '유효하지 않은 휴대전화 인증번호 입니다. ',
    [ErrorCode.B908]: 'SMS 인증은 휴대폰 번호가 필수값입니다.',
    [ErrorCode.B909]: '본인인증 세션이 만료되었습니다.',
  },
  post_users_password_verification: { [ErrorCode.B902]: '일치하는 회원정보가 없습니다.' },
  post_users_password_reset: { [ErrorCode.B902]: '일치하는 회원정보가 없습니다.' },
  put_users_password: {
    [ErrorCode.B901]: '비밀번호가 틀립니다.',
    [ErrorCode.B902]: '6~16자, 영문 대,소문자, 숫자, 특수문자 중 2개 이상 사용하세요.',
  },
  post_users_id: { [ErrorCode.B902]: '일치하는 회원정보가 없습니다.' },
  get_event_my_rank: {
    [ErrorCode.B901]: '이벤트에 참여한 이력이 없어요!',
    [ErrorCode.B902]: '이벤트 대상자인 취준생이 아닙니다.',
  },
  get_event_code_check: { [ErrorCode.B901]: '잘못된 추천인 링크 입니다.주소를 다시 확인해 주세요!' },
  post_event: {
    [ErrorCode.B901]: '이미 등록된 이메일과 아이디입니다.',
    [ErrorCode.B902]: '이미 등록된 이메일입니다.',
    [ErrorCode.B903]: '이미 등록된 아이디입니다.',
    [ErrorCode.B904]: '이미 등록된 휴대폰 번호입니다.',
    [ErrorCode.B905]: '이미 가입된 정보가 있습니다.\n다시 확인해 주세요.',
    [ErrorCode.B906]: '20일 이내에 탈퇴한 내역이 존재합니다.',
    [ErrorCode.B907]: 'IPIN 인증을 제외하고 휴대폰 번호는 필수값입니다.',
    [ErrorCode.B908]: 'SMS 인증은 휴대폰 번호가 필수값입니다.',
    [ErrorCode.B909]: '본인인증 세션이 만료되었습니다.',
  },
  post_login: {
    [ErrorCode.U001]: '로그인이 필요합니다.',
    [ErrorCode.U002]: '비밀번호가 틀렸습니다.',
    [ErrorCode.U003]: '비밀번호가 5회 이상 틀렸습니다.',
    [ErrorCode.U004]: '계정이 비활성화 되었습니다.',
    [ErrorCode.U005]: '계정이 만료되었습니다.',
  },
  put_company_like: { [ErrorCode.B901]: '이미 가고싶어요 한 기업입니다.' },
  get_user_liked_companies_new_jobs: {
    [ErrorCode.B901]: '가고 싶어요한 기업이 없습니다.',
    [ErrorCode.B902]: '최신 공고가 없습니다.',
  },
  put_users_default: {
    [ErrorCode.B901]: '이미 등록된 이메일입니다. 다른 이메일을 사용해 주세요.',
    [ErrorCode.B902]: '이미 등록된 휴대폰 번호입니다. 다른 휴대폰 번호를 사용해 주세요.',
    [ErrorCode.B903]: '인증되지 않은 번호입니다.',
    [ErrorCode.B906]: 'SMS 인증입니다. 휴대폰 번호를 입력해 주세요.',
  },
  put_match_profiles_seek: {
    [ErrorCode.B901]: '보훈, 장애 사항은 민감 정보 약관 동의, 민감 정보 제3자 약관 동의가 필요합니다.',
    [ErrorCode.B902]: '필수 입력사항을 완료하셔야 다음 단계로 이동 가능합니다.',
    [ErrorCode.B903]: '병역 기간의 시작일이 종료일보다 빠릅니다.',
  },
  put_match_profiles_education: {
    [ErrorCode.B901]: '졸업일자가 입학일자보다 빠릅니다.',
    [ErrorCode.B902]: '재학중인데 졸업 일자가 존재합니다.',
    [ErrorCode.B903]: '연구분야 논문의 첨부파일과 설명이 비어있습니다.',
    [ErrorCode.B904]: '학점 점수가 만점 점수보다 큽니다.',
    [ErrorCode.B905]: '학교가 중복 됩니다.',
    [ErrorCode.B906]: '전공 타입이 중복 됩니다.',
    [ErrorCode.B907]: '이전 탭 까지 작성이 완료되지 않았습니다.',
    [ErrorCode.B908]: '졸업 이거나 졸업예정일 경우 졸업날짜가 비어있을 수 없습니다.',
  },
  put_match_profiles_career: {
    [ErrorCode.B901]: '중복되는 기업이 있습니다.',
    [ErrorCode.B902]: '중복되는 경력기술서가 있습니다.',
    [ErrorCode.B903]: '퇴사일이 입력되지 않았습니다.',
    [ErrorCode.B904]: '퇴사일자가 입사일자보다 빠릅니다.',
    [ErrorCode.B905]: '직군/직무는 고민중이 될 수 없습니다.',
    [ErrorCode.B907]: '이전 탭 까지 작성이 완료되지 않았습니다.',
  },
  get_match_positions_recommend: {
    [ErrorCode.B901]: 'Matching 서버와 통신에 실패했습니다.',
    [ErrorCode.B902]: '프로필 입력이 완료되지 않았습니다.',
    [ErrorCode.B903]: '역량검사 응시 결과를 분석중입니다.',
    [ErrorCode.B904]: '선호조건 등록이 완료되지 않았습니다.',
    [ErrorCode.B905]: '회원님과 적합한 포지션을 찾는중입니다.',
  },
  get_curations_positions: {
    [ErrorCode.B901]: 'Matching 서버와 통신에 실패했습니다.',
    [ErrorCode.B902]: '프로필 입력이 완료되지 않았습니다.',
    [ErrorCode.B903]: '역량검사 응시 결과를 분석중입니다.',
    [ErrorCode.B904]: '선호조건 등록이 완료되지 않았습니다.',
    [ErrorCode.B905]: '회원님과 적합한 포지션을 찾는중입니다.',
  },
  get_profile_skill: {
    [ErrorCode.B901]: '중복되는 자격증이 있습니다.',
    [ErrorCode.B902]: '중복되는 어학시험이 있습니다.',
    [ErrorCode.B903]: '중복되는 기술키워드가 있습니다.',
  },
};
export default ErrorCode;
