import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

const useQueryParams = (): { [name: string]: string } => {
  const [params, setParams] = useState({});
  const location = useLocation();
  useEffect(() => {
    const temp: any = {};
    new URLSearchParams(location.search).forEach((value, key) => {
      temp[key] = value;
    });
    setParams(temp);
  }, []);

  return params;
};

export default useQueryParams;
