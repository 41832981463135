import JDBaseModal, { IJDBaseModal } from 'components/_v2/_common/modals/JDBaseModal';
import React, { FC } from 'react';
import styled from 'styled-components';
import { JDButtonTwoChoiceFrame, JDModalBasicFrame, JDModalButton, JDModalButtonGhost, JDModalTitle } from '../_common/modals/JDModalComponents';

const Frame = styled.div`

  ${JDButtonTwoChoiceFrame} {
    margin-top: 24px;
  }

  ${JDModalButtonGhost} {
    margin-right: 16px;
  }
`;

const PositionApplyCancelModal:FC<IJDBaseModal> = ({ isOpen, onClickClose, onSuccess }) => (
  <JDBaseModal dimmed isOpen={isOpen} onClickClose={onClickClose} isDimClickClose>
    <Frame>
      <JDModalBasicFrame>
        <JDModalTitle>지원을 취소하시겠어요?</JDModalTitle>
        <JDButtonTwoChoiceFrame>
          <JDModalButtonGhost onClick={onClickClose}>아니오</JDModalButtonGhost>
          <JDModalButton onClick={onSuccess}>확인</JDModalButton>
        </JDButtonTwoChoiceFrame>
      </JDModalBasicFrame>
    </Frame>
  </JDBaseModal>
);

export default PositionApplyCancelModal;
