import { Button, ButtonMode } from '__designkit__/button/Button';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import JoyImage from 'assets/character/joy_say_can_connect.svg';
import Loading from 'components/Loading';
import JDBaseInput from 'components/_v2/_common/input/JDBaseInput';
import JDMatchApplyNudgeModal from 'components/_v2/_common/modals/JDMatchApplyNudgeModal';
import JDSimpleInfoModal from 'components/_v2/_common/modals/JDSimpleInfoModal';
import TalentpoolMissionModal from 'components/_v2/_common/modals/TalentpoolMissionModal';
import AccResultModal from 'components/_v2/acc/AccResultModal';
import AccResultSampleModal from 'components/_v2/acc/AccResultSampleModal';
import { IMyProfileProps } from 'components/_v2/myProfile/MyProfileBoxComponent';
import { MatchAccAnalysisType } from 'consts/MatchAnalysisType';
import { PhsAccType } from 'consts/MatchingMessageType';
import RoutePaths from 'consts/RoutePaths';
import { lineClampBox } from 'consts/style/mixins';
import useToast from 'hooks/useToast';
import { IAccProgressCompleteDto } from 'interfaces/_v2/acc/IAccProgressCompleteRs';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import MyProfileModel from 'models/_v2/MyProfileModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import CommonUtils from 'utils/CommonUtils';
import { DateFormatYYYYMMDDHHMMWithDot } from 'utils/DateUtils';
import LottieFile from 'utils/LottieFile';
import RenderCheckUtil from 'utils/RenderCheckUtil';
import Diversity from '__pc__/graphic/icon_diversity.json';
import JDAPopover from 'components/_v2/_common/popover/JDPopover';
import JDNoBorderItemButton from 'components/_v2/_common/button/JDNoBorderItemButton';
import AccResultDeleteModal from 'components/_v2/acc/AccResultDeleteModal';
import useAccResults from 'query/acc/useAccResultQuery';
import { useAccDelete } from 'query/acc/useAccQuery';
import { AxiosError } from 'axios';
import MatchApplyStatusModel from 'models/_v2/MatchApplyStatusModel';
import { IAccApplyDeleteRq } from 'interfaces/_v2/acc/IAccGate';
import useActiveTabFlag from 'hooks/useActiveTabFlag';
import ImgEmpty from 'assets/_v2/positionList/img_character_joy_find.png';

const Frame = styled.div`
  width: 100%;
  height: 100%;

  .match-induction-box {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 16px 24px 16px;
    padding: 0 24px;
    height: 88px;
    background-color: ${Colors.CG_30};
    border-radius: 8px;
    font: ${Fonts.B2_Medium};
    color: ${Colors.B_100};

    .link-lottie {
      position: absolute;
      top: -9px;
      right: 10px;
      width: 100px;
      height: 100px;
    }

    .link-area {
      span {
        font: ${Fonts.B2_Medium};
        color: ${Colors.JOBDA_BLACK};
      }

      h1 {
        margin-top: 4px;
        font: ${Fonts.B1_Bold};
        color: ${Colors.JOBDA_BLACK};
      }
    }
  }
`;

export const ListFrame = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 16px;

  .li-frame {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${Colors.CG_40};

    &:last-child {
      border-bottom: none;
    }

    .more-menu {
      position: absolute;
      top: 16px;
      right: 0;
      display: flex;
      justify-content: flex-end;
      width: 24px;
      height: 76px;
    }
  }

`;

const CustomContentFrame = styled.div`
  display: flex;
  width: 100%;

  .result-list {
    position: relative;
    display: flex;
    gap: 12px;
    width: calc(100% - 30px);
    
    .result-img {
      flex: none; 
      width: 120px;
        height: 80px;
      .thumbnailImg {
        width: 100%;
        max-height: 80px;
        object-fit: cover;
        border-radius: 4px;
      }
      .default-img {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: ${Colors.CG_30};
        border-radius: 8px 8px 0 0;
        overflow: hidden;

        &::after {
          content: '';
          display: block;
          width: 96px;
          height: 96px;
          position: absolute;
          /* top: 36px; */
          left: 50%;
          transform: translateX(-50%);
          background: url(${ImgEmpty}) no-repeat;
          background-size: contain;
        }
      }
    }

    .result-desc {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .tag-frame {
        display: flex;
        margin-bottom: 8px;

        .custom-name-input {
          width: 180px;
          height: 28px;
          margin-left: 8px;

          > div {
            width: 180px;
            height: 100%;
            padding: 6px 8px;
            background-color: ${Colors.JOBDA_WHITE};
            border-radius: 4px;
            border: 1px solid ${Colors.CG_50};

            input {
              font: ${Fonts.B3_Medium};
              color: ${Colors.JOBDA_BLACK};
              padding: 0 20px 0 0;
            }

            :focus-within, &.active {
              outline: 1px solid ${Colors.JOBDA_BLACK};
              background: ${Colors.WHITE_100};
            }
          }
        }

        .progress {
          display: flex;
          padding:6px 8px;
          width: fit-content;
          height: 28px;
          align-items: center;
          background: ${Colors.CG_60};
          border: 1px solid rgba(0, 0, 0, 0.05);
          border-radius: 4px;
          color: ${Colors.WHITE_100};
          font: ${Fonts.B3_Medium};
        }

        .complete {
          display: flex;
          align-items: center;
          padding: 0 6px;
          height: 22px;
          width: fit-content;
          background: ${Colors.G_150};
          border-radius: 4px;
          border: 1px solid rgba(0, 0, 0, 0.05);
          color: ${Colors.WHITE_100};
          font: ${Fonts.B4_Medium};
        }

        .external-name {
          margin-left: 8px;
          font: ${Fonts.B3_Medium};
          color: ${Colors.JOBDA_BLACK};
          padding: 6px 8px;
          background-color: ${Colors.JOBDA_WHITE};
          border: 1px solid ${Colors.CG_50};
          border-radius: 4px;
        }

        .inner-name {
          height: 28px;
          padding: 6px 8px;
          margin-left: 8px;
          font: ${Fonts.B3_Medium};
          color: ${Colors.WHITE_100};
          background: ${Colors.G_100};
          border: 1px solid rgba(0, 0, 0, 0.05);
          border-radius: 4px;
        }
      }
    }
  }

  .result-info {
    .name {
      margin-bottom: 4px;
      ${lineClampBox(1)};
      font: ${Fonts.B1_Bold};
      color:${Colors.JOBDA_BLACK}; 
    }

    .date {
      font: ${Fonts.B2_Medium};
      color:${Colors.CG_60};
    }
  }
`;

const EmptyFrame = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 121px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  
  & > div.joy-frame {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% - 86px);

    & > div.sub {
      display: flex;
      align-items: center;
      font: ${Fonts.B2_Medium_P};
      color: ${Colors.B_100};

      & > div {
        display: flex;
        align-items: center;
      }
    }

    & > div.notice-text {
      margin-top: 16px;
      font: ${Fonts.B3_Medium};
      color: ${Colors.CG_70};
    }
  }
  
  & > div.btn-frame {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 20px;
  }
`;

const Title = styled.div`
  font: ${Fonts.H4_Bold} !important;
  color: ${Colors.JOBDA_BLACK};

  &.sub {
    font: ${Fonts.B2_Medium_P};
    color: ${Colors.CG_70} !important;
  }
`;

const ExampleFrame = styled.div<{rotateX?:number, ANGLE?:number}>`
    padding: 0 16px;

    .rolling-space{
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 12px 16px;
      border: 1px solid ${Colors.CG_40};
      border-radius: 8px;

      .example-text{
        font:${Fonts.B3_Bold_P};
        color:${Colors.G_150};
      }

      .rotator-area{
        overflow: hidden;

        .rotator{
          position: relative;
          display: flex;
          align-items: center;
          width: 288px;
          height: 20px;
          transform-style: preserve-3d;
          transition: all 1s ease-in-out;
          transform: ${(props) => `rotateX(${props.rotateX}deg)`};
          
          .item {
            position: absolute;
            backface-visibility: hidden;
            font:${Fonts.B3_Bold_P};
            color:${Colors.CG_70};
          }
        }
      }
    }
`;

const PopoverFrame = styled.div`
  display: flex;
  justify-content: center;

  > button {
      font: ${Fonts.B2_Medium};
      color: ${Colors.CG_70};
  }
`;

const rollingExampleText = [
  '단정하게 응시하면 제안확률이 높아져요.',
  '분석이 끝나면 카카오톡 메시지를 보내드려요. (최대 1일 소요)',
  '여러 기업에 지원 시 잡다에 저장된 역량검사를 제출할 수 있어요.',
  '취업에 활용할 수 있도록 결과별 면접 가이드를 제공해드려요.',
];
const ANGLE = 360 / rollingExampleText.length;

interface IMyProfileAccaResults extends IMyProfileProps {
  login?:Login
  matchApplyStatusModel?:MatchApplyStatusModel

}

const MyProfileAccaResults: FC<IMyProfileAccaResults> = ({ login = new Login(), myProfileModel = new MyProfileModel(), matchApplyStatusModel = new MatchApplyStatusModel() }) => {
  const history = useHistory();
  const { search } = useLocation();
  const useFormed = useForm({ mode: 'onSubmit' });
  const { watch } = useFormed;
  const { data: accResults, refetch, isLoading } = useAccResults(!!login.userInfo);
  const { setToastObject } = useToast();
  const [selectedResultSn, setSelectedResultSn] = useState(0);
  const [selectedResultItem, setSelectedResultItem] = useState<IAccProgressCompleteDto | undefined>(undefined);
  const [isOpenAccResultModal, setIsOpenAccResultModal] = useState<boolean>(false);
  const [isOpenAccResultSampleModal, setIsOpenAccResultSampleModal] = useState<boolean>(false);
  const [isNotSupportModal, setIsNotSupportModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean | null>(null);
  const [rotateX, setRotateX] = useState(0);
  const [isAccDeleteModal, setIsAccDeleteModal] = useState<boolean>(false);
  const [isUsedInMatching, setIsUsedInMatching] = useState<boolean>(false);
  const [deleteSn, setDeleteSn] = useState(-1);
  const initFlag = useActiveTabFlag();
  const deleteSuccess = async () => {
    setToastObject({ isOpen: true, type: 'SUCCESS', message: '역량검사 결과표 삭제에 성공했습니다.', duration: 2000 });
    await refetch();
    setIsAccDeleteModal(false);
  };
  const deleteFail = (e:AxiosError) => {
    setToastObject({ isOpen: true, type: 'ERROR', message: e.response?.data.message, duration: 2000 });
  };
  const { mutate: deleteAccCenterApplyRecent } = useAccDelete(deleteSuccess, deleteFail);

  const [isOpenTalentpoolMissionModal, setIsOpenTalentpoolMissionModal] = useState<boolean>(false);
  const [isCompleteProfile, setIsCompleteProfile] = useState<boolean>(false);
  const [isCompleteAcc, setIsCompleteAcc] = useState<boolean>(false);
  const [isRender, setIsRender] = useState<boolean>(false);
  const { matchingConfigYn } = login.matchConfigStatus || {};

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      await matchApplyStatusModel.loadMatchStatus();
      const paramList = CommonUtils.parseQuery(search);
      if (Number(paramList.sn)) {
        const accResultItem = myProfileModel.accProgressList?.find((accResult) => accResult.sn === Number(paramList.sn));
        if (accResultItem) {
          setSelectedResultSn(accResultItem.sn);
          setSelectedResultItem(accResultItem);
          setIsOpenAccResultModal(true);
        } else {
          setSelectedResultSn(paramList.sn);
          setIsOpenAccResultModal(true);
        }
      }
      setLoading(false);
    };

    init();
  }, []);

  useEffect(() => {
    (async () => {
      if (!login.profileType) await login.loadProfileType();
      if (!login.matchConfigStatus) await login.getMatchConfigStatus();
      setIsRender(!(await RenderCheckUtil.getRenderCheck('talentpool-induce')));
      setIsCompleteProfile(login.matchConfigStatus!.matchingConfigCondition.profileRequiredCompleteYn);
      if (login.profileType!.generalYn) {
        setIsCompleteAcc(login.matchConfigStatus!.matchingConfigCondition.accCompleteYn);
      } else if (login.profileType!.developerYn) {
        setIsCompleteAcc(login.matchConfigStatus!.matchingConfigCondition.paccCompleteYn);
      }
    })();
  }, [login]);
  useEffect(() => {
    const condition1 = !isCompleteProfile && isCompleteAcc;
    const condition2 = isCompleteProfile && isCompleteAcc && !login.matchConfigStatus?.matchingConfigYn;
    if ((condition1 || condition2) && isRender) setIsOpenTalentpoolMissionModal(true);
    else setIsOpenTalentpoolMissionModal(false);
  }, [isCompleteProfile, isCompleteAcc, isRender, login]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRotateX((prev) => prev - ANGLE);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);
  const handleDeleteResult = async () => {
    if (accResults?.resultRecords.length === 1 && !matchingConfigYn) {
      setIsUsedInMatching(true);
    } else if (deleteSn) {
      const rq:IAccApplyDeleteRq = { companySn: false, applySns: [deleteSn] };
      deleteAccCenterApplyRecent(rq);
    }
  };
  if (!initFlag) return null;
  return (
    <Frame>
      { (loading || isLoading)
        ? <Loading />
        : accResults?.resultRecords && accResults?.resultRecords.length > 0
          ? (
            <FormProvider {...useFormed}>
              <div className='match-induction-box' role='button' onClick={() => { history.push(RoutePaths.info_detail(334)); }}>
                <div className='link-area'>
                  <span>역량검사 결과 활용법</span>
                  <h1>역량검사 결과표 100% 활용하기</h1>
                </div>
                <LottieFile className='link-lottie' animationData={Diversity} />
              </div>
              <ExampleFrame rotateX={rotateX} ANGLE={ANGLE}>
                <div className='rolling-space'>
                  <div className='example-text'>TIP</div>
                  <div className='rotator-area'>
                    <div className='rotator'>
                      {rollingExampleText.map((text, index) => (
                        <div
                          className='item'
                          key={`example-${text}`}
                          style={{ transform: `rotateX(${index * ANGLE}deg) translateZ(50px)` }}
                        >{text}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </ExampleFrame>
              <ListFrame>
                { accResults?.resultRecords.map((item) => (
                  item.analysisType !== MatchAccAnalysisType.FAILED && (
                    <li className='li-frame'>
                      <JDNoBorderItemButton
                        title={DateFormatYYYYMMDDHHMMWithDot(item.applicationDate)}
                        mode='SELECT'
                        disabled={item.analysisType === MatchAccAnalysisType.PROGRESS}
                        customUIYn
                        onClick={(e) => {
                          if (e.target.name === 'custom-name' || e.target.title === 'custom-edit') return;
                          if (item.analysisType === MatchAccAnalysisType.PROGRESS) {
                            setToastObject({ isOpen: true, type: 'ERROR', message: '분석이 끝나면 확인할 수 있어요.', duration: 2000 });
                          } else {
                            setSelectedResultSn(item.sn);
                            const tmpItem:IAccProgressCompleteDto = {
                              sn: item.sn,
                              name: item.name,
                              code: item.code,
                              analysisType: item.analysisType,
                              resultType: item.resultType,
                              applyEndDateTime: item.applicationDate,
                              externalYn: item.externalYn, // 외부 응시 여부
                              version: item.version, // 버전
                              matchingUseYn: false, // 매칭 사용 여부
                            };
                            setSelectedResultItem(tmpItem);
                            setIsOpenAccResultModal(true);
                          }
                        }}
                      >
                        <CustomContentFrame>
                          <div className='result-list'>
                            <div className='result-img'>
                              {
                                item.thumbnailUrl
                                  ? (
                                    <img
                                      src={item.thumbnailUrl}
                                      className='thumbnailImg'
                                      alt=''
                                      onError={(e) => e.currentTarget.src = ImgEmpty}
                                    />
                                  )
                                  : <div className='default-img' />
                              }

                            </div>
                            <div className='result-desc'>
                              <div className='tag-frame'>
                                { item.analysisType === MatchAccAnalysisType.PROGRESS ? <div className='progress'>분석중</div> : <div className='complete'>분석 완료</div> }
                              </div>
                              <div className='result-info'>
                                <span className='name'>{item.name ? item.name : '역량검사 결과표'}</span>
                                <div className='date'>{DateFormatYYYYMMDDHHMMWithDot(item.applicationDate)}</div>
                              </div>
                            </div>
                          </div>
                        </CustomContentFrame>
                      </JDNoBorderItemButton>
                      {/* 더보기 버튼 클릭시 삭제 버튼 등장 */}
                      <div className='more-menu'>
                        <JDAPopover popoverWidth={100} useOutsideClick position='non_pony_under_left' popoverMargin={0} whiteMode anchorIcon={<Icon name='more-horizon' size={24} />}>
                          <PopoverFrame>
                            <button
                              onClick={() => {
                                setDeleteSn(item.sn);
                                setIsAccDeleteModal(true);
                              }}
                              className='btn-delete'
                            >
                              결과표 삭제
                            </button>
                          </PopoverFrame>
                        </JDAPopover>
                      </div>
                    </li>
                  )))}
              </ListFrame>
            </FormProvider>
          )
          : (
            <EmptyFrame>
              <div className='joy-frame'>
                <Title>아직 역량검사 응시 전이에요.</Title>
                <SpacingBlock size={8} vertical />
                <Title className='sub'>역량검사 응시 한 번으로 입사 제안 받는 방법</Title>
                <div className='sub'>
                  <Title className='sub'>자세히 알아볼까요?</Title>
                  <SpacingBlock size={8} />
                  <div role='button' onClick={() => { history.push(RoutePaths.info_detail(395)); }}>
                    알아보기
                    <Icon name='arrow-right' size={24} color={Colors.B_100} />
                  </div>
                </div>
                <SpacingBlock size={32} vertical />
                <img src={JoyImage} alt='이어하기가 가능하다고 말하는 조이 이미지' />
                <div className='notice-text'>* 정확도 높은 분석을 위해 PC에서만 응시 가능해요!</div>
              </div>
              <div className='btn-frame'>
                <Button buttonMode={ButtonMode.PRIMARY} size='large' label='결과표 예시 보기' onClick={() => setIsOpenAccResultSampleModal(true)} />
              </div>
            </EmptyFrame>
          )}
      <AccResultModal
        isOpen={isOpenAccResultModal}
        onClose={() => setIsOpenAccResultModal(false)}
        sn={selectedResultSn}
        item={selectedResultItem}
        type={PhsAccType.ACC}
      />
      <AccResultSampleModal isOpen={isOpenAccResultSampleModal} onClickClose={() => setIsOpenAccResultSampleModal(false)} />
      <JDSimpleInfoModal
        isOpen={isNotSupportModal}
        title={`최신 역량검사 결과표는\nPC에서 확인 가능합니다.`}
        onClickClose={() => setIsNotSupportModal(false)}
      />
      <JDSimpleInfoModal
        isOpen={isUsedInMatching}
        title={`매칭에 사용중인 결과표입니다.`}
        descriptions={`잡다매칭에서 제안받기를 OFF해야\n결과표 삭제가 가능합니다.`}
        onClickClose={() => setIsUsedInMatching(false)}
      />
      <AccResultDeleteModal
        isOpen={isAccDeleteModal}
        onClickClose={() => setIsAccDeleteModal(false)}
        onSuccess={() => {
          handleDeleteResult();
        }}
      />
      <JDMatchApplyNudgeModal />
      { isOpenTalentpoolMissionModal && <TalentpoolMissionModal onClickClose={() => setIsOpenTalentpoolMissionModal(false)} isCompleteProfile={isCompleteProfile} isCompleteAcc={isCompleteAcc} /> }
    </Frame>
  );
};

export default inject(injectStore.login, injectStore.myProfileModel, injectStore.matchApplyStatusModel)(observer(MyProfileAccaResults));
