import { createGlobalStyle } from 'styled-components/macro';

const GlobalStyle = createGlobalStyle`

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video, button {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    outline:none;
    line-height: 1;
    color:inherit;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
  }
  a {
    text-decoration:none;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  html,body {
    font-size:12px;
  }

  * {
    box-sizing:border-box;
    letter-spacing: -0.03em;
    word-break:keep-all;
    touch-action: pan-x pan-y;
    &:after {
      box-sizing:border-box;
    }
    &:before {
      box-sizing:border-box;
    }
  }
  input {
    border-radius:0;
    background-color:#ffffff;
  }

  button, label {
    cursor: pointer;
  }

  button {
    background: none;
  }
  
  .msg-main {
    text-align: center;
  }

  .msg-caption {
    text-align: center;
  }

  .logo-img {
    display: block;
    margin: 0 auto;
    width: 75%;
  }
  
  ::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }
  ::-webkit-scrollbar-button {
    width: 0.5em;
    height: 0.5em;
  }
  ::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #666;
  }
  ::-webkit-scrollbar-track {
    background: #ccc;
    border: 0px none #ffffff;
    border-radius: 0.5em;
  }
  ::-webkit-scrollbar-track:hover {
    background: #aaa;
  }
  ::-webkit-scrollbar-track:active {
    background: #999;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
  
  //** basic fade transition */
  .fade-enter {
    opacity: 0.01;
  }
  .fade-enter-active {
    /* opacity: 0.01; */
  }
  .fade-enter-done {
    opacity:1; 
    transition: opacity 500ms linear;
  }
  .fade-exit {
    opacity:1;
  }
  .fade-exit-active {
    opacity: 0.01;
    transition: opacity 500ms linear;
  }

  *[aria-hidden=true] {
    display:none;
  }

  html,body {
    // scroll-behavior: smooth;
  }

  ${
  (props:any) => (props.platform === 'web' ? `
      body {
        display: initial !important;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        margin-left: none;
        margin-right: none;
        margin-top: none;
        margin-bottom: none;
        padding-left: none;
        padding-right: none;
        padding-top: none;
        padding-bottom: none;
        position: relative;
        width: auto
        max-width: auto;
        height: auto;
        max-height: auto;
        text-rendering: optimizeLegibility;
        overflow:visible;
        -webkit-user-drag: none;
        -ms-content-zooming: none;
        word-wrap: break-word;
        overscroll-behavior-y: contain;
        -webkit-overflow-scrolling:touch;
        text-size-adjust: none;
        height:100%;
        #root {
          height:100%;
        }
        ${props.preventScroll ? 'overflow: hidden; #root { height: auto; }' : ''}
      }
    ` : `
      body {
        overscroll-behavior-y: none;
      }
    `)
}

body{
  >iframe{
    display:none;
  }
}
.root-portal{
  > *{
   margin-top: env(safe-area-inset-top);
   margin-bottom: env(safe-area-inset-bottom);
  }
}

 
  @media print{
    @page {
      size: A4 landscape;
      margin : 0;
    }
    .no-print{
      display:none;
    }
    .print-center {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @font-face {
    font-family: 'Wemakeprice-Bold';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-10-21@1.0/Wemakeprice-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    line-height: 0em;
  }
`;
export default GlobalStyle;
