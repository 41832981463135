import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import JDDescriptionInput from 'components/_v2/_common/input/JDDescriptionInput';
import { PassReviewQuestionDto } from 'interfaces/_v2/passReview/IPassReviewRqRs';
import React, { FC, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

const QuestionSubtitle = styled.span<{ isError: boolean }>`
    font:${Fonts.B1_Bold};
    color:${Colors.JOBDA_BLACK};
    margin-bottom: 12px;
    .question-subtitle{
      font:${Fonts.B3_Medium};
      color: ${({ isError }) => (isError ? 'red' : Colors.CG_60)};
      margin-left: 8px;
    }
`;
interface IPassboardQuestion{
    question:PassReviewQuestionDto;
    fieldName:string;
    idx:number;
}
const PassboardQuestion:FC<IPassboardQuestion> = ({ question, fieldName, idx }) => {
  const [questionCount, setQuestionCount] = useState(0);

  const methods = useFormContext();
  const { control, watch, setValue, reset, trigger, errors } = methods;
  const questionEach = watch(`${fieldName}[${idx}].answer`) || '';
  // useEffect(() => {
  //   // 입력된 텍스트의 길이가 minLength보다 크거나 같으면 true를, 그렇지 않으면 false를 설정
  //   const isValidLength = questionEach.length >= question.minLength;
  //   setValue(`${fieldName}[${idx}].isValidMinLength`, isValidLength, { shouldValidate: true });
  //   setQuestionCount(questionEach.length);
  // }, [questionEach, question.minLength, fieldName, idx]);
  useEffect(() => {
    // questionSn 값 설정
    if (question.questionSn !== undefined) {
      setValue(`${fieldName}[${idx}].questionSn`, question.questionSn);
    }
  }, [question.questionSn, fieldName, setValue, idx]);
  const hasError = !!errors[fieldName]?.[idx]?.answer;
  useEffect(() => {
    // 초기 로드 시 minLength 값을 설정
    setValue(`${fieldName}[${idx}].minLength`, question.minLength, { shouldDirty: false, shouldValidate: false });
  }, [setValue, question.minLength, fieldName, idx]);
  return (

    <>
      <QuestionSubtitle isError={hasError}>
        {question.question}{ question.minLength && <span className={`question-subtitle`}>{`최소 ${question.minLength}자`}</span>}
      </QuestionSubtitle>

      <JDDescriptionInput
        key={question.questionSn}
        name={`answers[${idx}].answer`}
        placeholder='내용을 입력해 주세요.'
        height={96}
        autoFocus={false}
        maxLength={question.maxLength}
        defaultValue={questionEach}
        passboard
      />
      <Controller
        name={`${fieldName}[${idx}].minLength`}
        control={control}
        render={() => <input type='hidden' />}
      />
      <Controller
        name={`${fieldName}[${idx}].questionSn`}
        control={control}
        render={() => <input type='hidden' />}
      />
    </>

  );
};
export default PassboardQuestion;
