import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Footer from 'components/_v2/_common/Footer';
import { MainMetaTag } from 'components/_v2/_common/meta/MetaTag';
import MainAccaRecruit from 'components/_v2/main/MainAccaRecruit';
import MainAd from 'components/_v2/main/MainAd';
import MainContents from 'components/_v2/main/MainContents';
import MainMatching from 'components/_v2/main/MainMatching';
import MainPersonalPosition from 'components/_v2/main/MainPersonalPosition';
import MainPopularPosition from 'components/_v2/main/MainPopularPosition';
import MainRecruit from 'components/_v2/main/MainRecruit';
import MainRolling from 'components/_v2/main/MainRolling';
import { FC } from 'react';
import MobileStore from 'store/mobileStore';
import styled from 'styled-components';

const Frame = styled.div`
  width:100%;
`;

export const MainTitleFrame = styled.div`
    display: flex;
    flex-direction: column;
    margin:32px 0 24px 0px;
    .title{
        font: ${Fonts.H5_Bold};
        color:${colors.JOBDA_BLACK};
        width: 288px;
        word-break: break-all;
    }
    .subtitle-frame{
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
        .subtitle-text{
            font: ${Fonts.B3_Medium};
            color:${colors.CG_70};
        }
        .more-text{
        display: flex;
        align-items: center;
        font: ${Fonts.B3_Medium};
        color:${colors.CG_70};
        text-decoration: underline;
    }
    }
`;

const Main:FC = () => (
  <Frame>
    <MainMetaTag />
    <MainAd />
    <MainRolling />
    <MainMatching />
    <MainPersonalPosition />
    {/* 5.3.0에서 MainCuration 제거  */}
    {/* <MainCuration /> */}
    <MainRecruit />
    <MainPopularPosition />
    <MainAccaRecruit />
    <MainContents />
    {!MobileStore.isMobile && <Footer isNavi={false} corpInfoFoled={false} />}
  </Frame>
);

export default Main;
