import IconDiamond from 'assets/_v2/positionJd/icon_diamond.svg';
import LazyImage from 'components/common/LazyImage';
import PositionWelfareModal from 'components/_v2/positionJd/PositionWelfareModal';
import { StyledChipWelfareLi, StyledChipWelfareUl } from 'components/_v2/_common/chip/StyledJDChip';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import PositionJdModel from 'models/_v2/PositionJdModel';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    padding : 0 16px;
    .title{
        width: 100%;
        margin-bottom:16px;
        display: flex;
            .icon{
            margin-right: 10px;
            width: 24px;
            height:24px;
            }
    }
    .text{
      font: ${Fonts.H5_Bold};
    }
    
    .card_title{
      display: flex;
      font: ${Fonts.B1_Bold};
      text-align: center;
      margin-top: 20px;
    }

`;

const AllViewButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 12px 0;
    font: ${Fonts.B3_Bold};
    color: ${colors.JOBDA_BLACK};
    border: 1px solid ${colors.JOBDA_BLACK};
    border-radius: 4px;
    margin-top: 18px;
`;

interface IPositionJdPreviewWelfareProps{
    additionalInfo:any
}

const PositionJdPreviewWelfare:FC<IPositionJdPreviewWelfareProps> = ({ additionalInfo }) => {
  const [isAllViewOpen, setIsAllViewOpen] = useState<boolean>(false);

  return (
    <>
      <Frame>
        <div className='title'>
          <LazyImage className='icon' src={IconDiamond} />
          <div className='text'>이런 복지들이 있어요.</div>
        </div>

        <StyledChipWelfareUl>
          {additionalInfo?.welfareWithCategories.map((welfareCategory:any) => (
            welfareCategory.welfares.map((welfare:any, index:number) => (
              index < 1
              && (
              <StyledChipWelfareLi key={`welfare-chip-${welfare}`}>
                <span>{welfare}</span>
              </StyledChipWelfareLi>
              )
            ))))}
        </StyledChipWelfareUl>
        <AllViewButton onClick={() => setIsAllViewOpen(true)}>
          {additionalInfo?.welfareKeywords.length}개 보유 복지 모두보기
        </AllViewButton>
      </Frame>
      <PositionWelfareModal isOpen={isAllViewOpen} onClose={() => setIsAllViewOpen(false)} />

    </>
  );
};
export default inject(injectStore.positionJdModel)(observer(PositionJdPreviewWelfare));
