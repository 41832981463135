import iconRightSmall20RegularGreen from 'assets/icon/icon_right_small_20_Regular_green.svg';
import LazyImage from 'components/common/LazyImage';
import JDALink from 'components/JDALink';
import colors from '__designkit__/common/colors';
import Fonts, { fontStyle } from '__designkit__/common/fonts';
import IJDADeclarationSloganData from 'interfaces/IJDADeclarationData';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { FC } from 'react';
import styled from 'styled-components';

interface IDeclarationsloganProps extends IComponentProps {
  slogan: IJDADeclarationSloganData;
}

const Frame = styled.article`
  padding-top:32px;
  background:${colors.CG_30};
  &:last-child {
    padding-bottom:32px;
  }
  .frame-content {
    margin:0 16px;
    background:${colors.WHITE_100};
    border-radius:16px;
    padding-bottom:32px;
    box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.05);
    * {
      white-space:pre-line;
    }
    h1 {
      padding:0 32px;
      margin-top:32px;
      ${fontStyle.HEADLINE4_SEMIBOLD};
      color:${colors.JOBDA_BLACK};
      line-height:36px;
    }
    h2 {
      margin-top:12px;
      padding:0 32px;
      ${fontStyle.CAPTION1_SEMIBOLD};
      color:${colors.G_200};
    }
    .frame-paragraphs {
      padding:0 32px;
      margin-top:32px;
      * {
        color:${colors.CG_80};
        font: ${Fonts.B1_Medium};
        line-height:26px;
      }
      p {
        margin-top:24px;
      }
      b {
        font-weight:bold;
      }
      ol {
        margin-top:10px;
        &.dark li, span {
          color:${colors.CG_90};
        }
        li {
          display:flex;
          flex-direction:row;
          justify-content:flex-start;
          align-items:flex-start;
          &, * { font-weight:bold; }
          &:before {
            display:block;
            margin-right:4px;
          }
          &:nth-child(1):before {
            content:'첫째,';
          }
          &:nth-child(2):before {
            content:'둘째,';
          }
          &:nth-child(3):before {
            content:'셋째,';
          }
        }
      }
      strong{
        color: ${colors.c5EC7C3};
        font-weight:bold !important;
      }
    }
    .action {
      display:block;
      margin:0 32px;
      margin-top:32px;
      font: ${Fonts.B1_Bold};
      color:${colors.G_200};
      &:after {
        display:inline-block;
        content:'';
        width:20px;
        height:20px;
        background:url(${iconRightSmall20RegularGreen});
        background-repeat:no-repeat;
        vertical-align:sub;
      }
    }
  }

`;

const DeclarationSlogan:FC<IDeclarationsloganProps> = ({ slogan: { title, subtitle, headerImage, paragraphs, action } }) => (
  <Frame>
    <div className='frame-content'>
      <LazyImage className='img-header' src={headerImage} />
      <h1 className='frame-title'>
        {title}
      </h1>
      <h2>
        {subtitle}
      </h2>
      <div className='frame-paragraphs'>
        { paragraphs }
      </div>
      {
        action && (
          <JDALink className='action' to={action.to}>
            {action.text}
          </JDALink>
        )
      }
    </div>
  </Frame>
);

export default DeclarationSlogan;
