import { Button } from '__designkit__/button/Button';
import colors from '__designkit__/common/colors';
import Fonts, { fontStyle } from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import IconCpp from 'assets/_v2/phs/pst/icon_pst_Cpp.svg';
import IconJava from 'assets/_v2/phs/pst/icon_pst_Java.svg';
import IconJavaScript from 'assets/_v2/phs/pst/icon_pst_JavaScript.svg';
import IconPython from 'assets/_v2/phs/pst/icon_pst_Python.svg';
import TierBronze from 'assets/_v2/phs/pst/img_pst_tier_bronze.svg';
import TierGold from 'assets/_v2/phs/pst/img_pst_tier_gold.svg';
import TierPlatinum from 'assets/_v2/phs/pst/img_pst_tier_platinum.svg';
import TierSilver from 'assets/_v2/phs/pst/img_pst_tier_silver.svg';
import TierTryAgain from 'assets/_v2/phs/pst/img_pst_tier_try.svg';
import { Divider1G50 } from 'components/divider/Divider';

import { PstGradeDescription, PstGradeResultType, PstGradeResultTypeText, PstGradeTip } from 'consts/_v2/phs/pst/PstApplyType';
import { LanguageList, PstVersion } from 'consts/_v2/phs/pst/PstResultType';
import { inject, observer } from 'mobx-react';
import PstResultsModel from 'models/_v2/PstResultsModel';
import { injectStore } from 'models/store';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
  padding: 0px 16px 24px 16px;

`;

const TierFrame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  .tier-title {
    margin-top: 24px;
    font: ${Fonts.H4_Bold};
    color: ${colors.JOBDA_BLACK};
    
    >span {

    }
  }

  .divider {
    width: 32px;
    height: 1px;
    background-color: ${colors.CG_70};
    margin: 24px 0;
  }

  .text {
    font: ${Fonts.B2_Medium_P};
    color: ${colors.CG_70};

    >p {
      color: ${colors.JOBDA_BLACK};
    }
  }

  .fold-button {
    margin-top: 24px;
  }
`;

const TipFrame = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid ${colors.CG_50};

  .title-frame {
    display: flex;

    .title {
      margin-left: 4px;
      font: ${Fonts.B3_Bold};
    }
  }

  .tip-content {
    font: ${Fonts.B3_Medium_P};
    color: ${colors.CG_80};
  }
`;

const Divider = styled(Divider1G50)`
  margin: 16px 0;
`;

const FoldedFrame = styled.div<{ folded: boolean }>`
  position: relative;
  height: ${(props) => (props.folded ? '80px' : '100%')};
  overflow: hidden; 

  .dimmed {
    position: absolute;
    width: 100%;
    height: 80px; 
    background: ${(props) => (props.folded ? 'linear-gradient(0deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);' : 'inherit')};
  
  }
`;

const PaddingContainer = styled.div`
  padding-top: 16px;
`;

const TitleContainer = styled.div`
  font: ${Fonts.B1_Bold};
  line-height: 22px;
  margin-top: 8px;
`;

const ScoreContainer = styled.div`
  padding:20px 16px;
  margin-top: 16px;
  width:100%;
  background:${colors.JOBDA_WHITE};
  border: 1px solid ${colors.CG_40};
  border-radius: 8px;
  .column-container{
    display: flex;
    flex-direction: column;
    >div{
      display: flex;
      margin-bottom: 12px;
      &:last-child{
        margin-bottom:0px;
      }
    }
    .text-title{
      font: ${Fonts.B2_Medium_P};
      color:${colors.CG_80};
      margin-right: 32px;
    }
    .text-score{
      font: ${Fonts.B2_Bold};
      line-height: 20px;
      color:${colors.CG_80};
    }
    .icon-language{
      width:17px;
      height:17px;
      align-self: center;

    }
  }
`;

const GradeSpan = styled.span<{ grade: PstGradeResultType }>`
  margin-right: 4px;
  color: ${(props) => {
    switch (props.grade) {
      case PstGradeResultType.TRY_AGAIN: return '#9FA4AB';
      case PstGradeResultType.BRONZE: return '#CA8656';
      case PstGradeResultType.SILVER: return '#8592BB';
      case PstGradeResultType.GOLD: return '#C3A81A';
      case PstGradeResultType.PLATINUM: return '#09C195';
      default: return '#9FA4AB';
    }
  }};
`;

const PstResultContent :FC<{ pstResultsModel?: PstResultsModel }> = ({ pstResultsModel = new PstResultsModel() }) => {
  const [folded, setFolded] = useState<boolean>(true);

  const getIconType = (languageName: string) => {
    switch (languageName) {
      case (LanguageList.CPP):
        return IconCpp;
      case (LanguageList.JAVA):
        return IconJava;
      case (LanguageList.PYTHON):
        return IconPython;
      case (LanguageList.JAVASCRIPT):
        return IconJavaScript;
      default:
        break;
    }
  };

  const gradeImage = () => {
    switch (pstResultsModel.pstResults?.grade) {
      case PstGradeResultType.TRY_AGAIN:
        return TierTryAgain;
      case PstGradeResultType.BRONZE:
        return TierBronze;
      case PstGradeResultType.SILVER:
        return TierSilver;
      case PstGradeResultType.GOLD:
        return TierGold;
      case PstGradeResultType.PLATINUM:
        return TierPlatinum;
      default:
        break;
    }
    return TierTryAgain;
  };

  const gradeDescription = () => {
    const version = pstResultsModel.pstResults?.version || PstVersion.V1;
    const grade = pstResultsModel.pstResults?.grade || PstGradeResultType.TRY_AGAIN;

    switch (grade) {
      case PstGradeResultType.TRY_AGAIN:
        return PstGradeDescription[version].TRY_AGAIN;
      case PstGradeResultType.BRONZE:
        return PstGradeDescription[version].BRONZE;
      case PstGradeResultType.SILVER:
        return PstGradeDescription[version].SILVER;
      case PstGradeResultType.GOLD:
        return PstGradeDescription[version].GOLD;
      case PstGradeResultType.PLATINUM:
        return PstGradeDescription[version].PLATINUM;
      default:
        break;
    }
    return PstGradeDescription[version].TRY_AGAIN;
  };

  const foldHandler = () => {
    setFolded(!folded);
  };

  if (!pstResultsModel.pstResults) return <></>;
  return (
    <Frame>
      <TierFrame>
        <img className='tier-image' src={gradeImage()} alt='tier' />
        <div className='tier-title'>
          <GradeSpan grade={pstResultsModel.pstResults.grade}>
            {PstGradeResultTypeText[pstResultsModel.pstResults.grade]}
          </GradeSpan>
          등급을 달성하였습니다.
        </div>
        <div className='divider' />
        <FoldedFrame folded={folded}>
          <div className='dimmed' />
          <div
            className='text'
            dangerouslySetInnerHTML={{ __html: gradeDescription() }}
          />
          <TipFrame>
            <div className='title-frame'>
              <Icon name='alert-filed' size={20} />
              <div className='title'>이전 응시보다 높은 점수를 받았는데 <br />왜 등급이 낮아졌나요?</div>
            </div>
            <Divider />
            <div
              className='tip-content'
              dangerouslySetInnerHTML={{ __html: PstGradeTip[pstResultsModel.pstResults.version] }}
            />
          </TipFrame>
        </FoldedFrame>
        <Button
          outLined
          className='fold-button'
          label={folded ? '달성 등급 설명 모두 보기' : '접기'}
          icon={folded ? <Icon name='arrow-bottom' size={24} color={colors.JOBDA_BLACK} /> : <Icon name='arrow-top' size={24} color={colors.JOBDA_BLACK} />}
          iconRight
          onClick={foldHandler}
        />
      </TierFrame>
      <PaddingContainer>
        <TitleContainer>검사 결과</TitleContainer>
        <ScoreContainer>
          <div className='column-container'>
            {
              pstResultsModel.pstResults.version === PstVersion.V2
              && (
                <div>
                  <div className='text-title'>통과 단계</div>
                  <div className='text-score'>{pstResultsModel.pstResults.finalPassedStep ? `${pstResultsModel.pstResults.finalPassedStep}단계` : '-' }</div>
                </div>
              )
            }
            <div>
              <div className='text-title'>획득 점수</div>
              <div className='text-score'>{pstResultsModel.pstResults.lastSubmitResult ? `${Math.round(pstResultsModel.pstResults.lastSubmitResult?.score)}점 / 100점` : '-' }</div>
            </div>
            <div>
              <div className='text-title'>소요 시간</div>
              <div className='text-score'>{pstResultsModel.pstResults.lastSubmitResult ? `${pstResultsModel.pstResults.lastSubmitResult?.elapsedTime}분` : '-'}</div>
            </div>
            <div>
              <div className='text-title'>제출 언어</div>
              {pstResultsModel.pstResults.lastSubmitResult
                ? <img src={getIconType(pstResultsModel.pstResults.lastSubmitResult?.language)} alt='icon' className='icon-language' />
                : <div className='text-score'>-</div>}

            </div>
          </div>
        </ScoreContainer>
      </PaddingContainer>
    </Frame>
  );
};

export default inject(injectStore.pstResultsModel)(observer(PstResultContent));
