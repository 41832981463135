import styled from 'styled-components';
import React, { FC, useRef, useEffect } from 'react';
import IComponentProps from 'interfaces/props/IComponentProps';
import { fonts } from '__designkit__/common/fonts';
import colors from '__designkit__/common/colors';

const MarqueeFrame = styled.div`
  position:relative;
  width:100%;
  height:200vh;
  overflow-x:hidden;
  background:${colors.G_100};
  .text-marquee {
    position:absolute;
    height:20px;
    top:50%;
    left:50%;
    margin-top:-10px;
    font-size:50px;
    white-space:nowrap;
    ${fonts.NOTO_64_900};
    color:${colors.WHITE_100};
  }
`;

interface ISlidingTextSectionProps extends IComponentProps {
  text: string;
}

const SlidingTextSection:FC<ISlidingTextSectionProps> = ({ text }) => {
  const refFrame = useRef<HTMLDivElement>(null);
  const refText = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const onScroll = () => {
      const y = document.documentElement.scrollTop;
      if (refFrame.current && refText.current) {
        const { offsetTop, clientHeight } = refFrame.current;
        if (y >= offsetTop && y <= offsetTop + clientHeight) {
          const { clientWidth } = refText.current;
          const newPos = (y - offsetTop) / (clientHeight - window.innerHeight);
          refText.current.style.position = 'fixed';
          refText.current.style.left = `${clientWidth / 2 - (clientWidth) * newPos}px`;
        } else {
          refText.current.style.position = 'absolute';
        }
      }
    };
    document.addEventListener('scroll', onScroll);
    return (() => {
      document.removeEventListener('scroll', onScroll);
    });
  }, []);
  return (
    <MarqueeFrame className='frame-marquee' ref={refFrame}>
      <div ref={refText} className='text-marquee'>{text}</div>
    </MarqueeFrame>
  );
};

export default SlidingTextSection;
