import { Divider1G40 } from 'components/divider/Divider';
import { IProfileEduFieldArrayProps } from 'components/_v2/profile/education/ProfileEducationMajor';
import JDDeleteButton from 'components/_v2/_common/button/JDDeleteButton';
import JDPlusTextButton from 'components/_v2/_common/button/JDPlusTextButton';
import FormValueFrame, { DeleteButtonFormValueFrame, FormValueMiddleFrame } from 'components/_v2/_common/form/FormProfileValueFrame';
import FormProfileValueTitle from 'components/_v2/_common/form/FormProfileValueTitle';
import JDDescriptionInput from 'components/_v2/_common/input/JDDescriptionInput';
import JDFileUpload from 'components/_v2/_common/input/JDFileUpload';
import { IProfileResearch } from 'interfaces/_v2/profile/IProfileEducation';
import React, { FC, useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

const ProfileEducationResearches: FC<IProfileEduFieldArrayProps> = (props: IProfileEduFieldArrayProps) => {
  const { prefixName } = props;
  const useFormed = useFormContext();
  const { control, watch } = useFormed;
  const name = `${prefixName}.profileResearches`;
  const { fields: profileResearchesFields, append, remove: profileResearchesRemove } = useFieldArray({
    control,
    name,
  });
  const profileResearchesAppend = () => {
    append({}, false);
  };
  useEffect(() => {
    const profileResearch: IProfileResearch[] = watch(name);
    if (profileResearch === null || profileResearch.length === 0) profileResearchesAppend();
  }, []);

  return (
    <div aria-hidden={props.hidden}>
      {
        (profileResearchesFields && profileResearchesFields).map((field, majorIndex) => (
          <div key={field.id}>
            {majorIndex !== 0
              && (
                <>
                  <FormValueFrame>
                    <Divider1G40 />
                  </FormValueFrame>
                  <DeleteButtonFormValueFrame>
                    <JDDeleteButton
                      onClick={() => profileResearchesRemove(majorIndex)}
                    />
                  </DeleteButtonFormValueFrame>
                </>
              )}
            <FormProfileValueTitle>연구분야 ・ 논문</FormProfileValueTitle>
            <FormValueMiddleFrame>
              <JDFileUpload
                name={`${name}[${majorIndex}].attachFiles`}
                placeholder='첨부파일을 추가해 주세요.'
                accept='application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation'
                type={['application/pdf', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.slideshow', 'application/vnd.openxmlformats-officedocument.presentationml.presentation']}
              />
            </FormValueMiddleFrame>
            <FormValueFrame>
              <JDDescriptionInput
                key={`${field.id}-description`}
                name={`${name}[${majorIndex}].description`}
                placeholder='연구 분야를 간략하게 설명해 주세요.'
                maxLength={1000}
                autoFocus={false}
                defaultValue={field.description}
              />
            </FormValueFrame>
          </div>
        ))
      }
      <FormValueFrame>
        <JDPlusTextButton
          text='연구 분야 ・ 논문 추가'
          onClick={() => profileResearchesAppend()}
        />
      </FormValueFrame>
    </div>
  );
};

export default ProfileEducationResearches;
