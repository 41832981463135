import URIs from 'consts/URIs';
import { IAccProgressCompleteDto } from 'interfaces/_v2/acc/IAccProgressCompleteRs';
import { IPaccProgressCompleteDto } from 'interfaces/_v2/phs/pacc/IPaccProgressCompleteRs';
import { IPstProgressCompleteDto } from 'interfaces/_v2/phs/pst/IPstProgressCompleteRs';
import { action, observable } from 'mobx';
import request from 'utils/request';

export default class MyProfileModel {
    @observable accProgressList: IAccProgressCompleteDto[] | null = null;
    @observable pstProgressList: IPstProgressCompleteDto[] | null = null;
    @observable paccProgressList: IPaccProgressCompleteDto[] | null = null;

    @action
    loadAccProgressList = async () => {
      try {
        this.accProgressList = (await request<{resultRecords : IAccProgressCompleteDto[]}>({
          method: 'get',
          url: URIs.get_acc_results_list,
        })).resultRecords;
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadPaccProgressList = async () => {
      try {
        this.paccProgressList = (await request<{resultRecords : IPaccProgressCompleteDto[]}>({
          method: 'get',
          url: URIs.get_pacc_results_list,
        })).resultRecords;
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadPstProgressList = async () => {
      try {
        this.pstProgressList = (await request<{resultRecords : IPstProgressCompleteDto[]}>({
          method: 'get',
          url: URIs.get_pst_results_list,
        })).resultRecords;
      } catch (e) {
        console.error(e);
      }
    }

  @action
  changeAccResultName = async (sn: number, name: string) => {
    try {
      await request({
        method: 'put',
        url: URIs.put_acc_result_name,
        data: { sn, name },
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
