import React from 'react';

import classnames from 'classnames/bind';
import FlowList from '__pc__/page/accaIntroduce/flowStory/flowList';
import styles from './interview.module.scss';

const cx = classnames.bind(styles);

const Interview = () => (
  <div className={cx('wrap')}>
    <div className={cx('sectionTitleArea')}>
      <strong className={cx('sectionTitle')}>역량검사 합격자 후기</strong>
      <p className={cx('sectionDesc', 'flowListTitle')}>
        JOBDA 역량검사로 취업을 준비한
        <br />
        100만 명의 선배들 Story를 확인하세요
      </p>
    </div>

    <FlowList />
  </div>
);

export default Interview;
