import React from 'react';
import classnames from 'classnames/bind';
import { detailInfoListData } from './detailInfoData';

import styles from './detailInfo.module.scss';

const cx = classnames.bind(styles);

const DetailInfo = () => {
  const detailInfoListEl = detailInfoListData.map((item, index) => (
    <li key={index} className={cx('listItem')}>
      <div className={cx('infoTitle')}>{item.title}</div>
      <div className={cx('infoDataArea')}>
        {item.subData ? (
          item.subData.map((subItem, subIndex) => (
            <div key={subIndex} className={cx('infoDataInner')}>
              <div className={cx('infoData')}>{subItem.data}</div>
              {subItem.dataDesc && <div className={cx('infoDataDesc')}>{subItem.dataDesc}</div>}
            </div>
          ))
        ) : (
          <div className={cx('infoDataInner')}>
            <div className={cx('infoData')}>{item.data}</div>
            {item.dataDesc && <div className={cx('infoDataDesc')}>{item.dataDesc}</div>}
          </div>
        )}
      </div>
    </li>
  ));

  return (
    <div className={cx('detailInfoArea')}>
      <div className={cx('detailInfoInner')}>
        <em className={cx('detailInfoTitle')}>상세 안내</em>

        <ul className={cx('detailInfoList')}>{detailInfoListEl}</ul>
      </div>
    </div>
  );
};

export default DetailInfo;
