import Colors from '__designkit__/common/colors';
import DisableScroll from 'components/common/DisableScroll';
import Portal from 'components/common/Portal';
import IComponentProps from 'interfaces/props/IComponentProps';
import { FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import MobileStore from 'store/mobileStore';
import styled, { keyframes } from 'styled-components';

const dimOpacityAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.6;
  }
`;

const Frame = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  z-index: 10005;

  // iOS 환경에서 .root-portal 아래 모든 요소에 margin-top, margin-bottom 생기는 코드 방지
  margin: 0;
`;

const Dim = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${Colors.BLACK_100};
  opacity: 0.6;
  animation: ${dimOpacityAnimation} 0.3s ease-out;
  z-index: 10005;
`;

interface IJDDimModalProps extends IComponentProps {
  dimClickable?: boolean;
  onClickClose?: () => void;
}

const JDDimModal: FC<IJDDimModalProps> = ({ children, dimClickable = true, onClickClose = () => {} }: IJDDimModalProps) => {
  const history = useHistory<{[key: string]: any}>();
  const [originalUrl] = useState<string>(history.location.pathname + history.location.hash);

  const pushModalState = useCallback(() => {
    window.history.pushState({ ...history.location.state }, '', history.location.pathname);
  }, [history]);

  useEffect(() => {
    if (!MobileStore.isMobile) {
      pushModalState();
      window.addEventListener('popstate', pushModalState);
      return () => {
        window.removeEventListener('popstate', pushModalState);
        if (originalUrl === history.location.pathname + history.location.hash) window.history.go(-1);
      };
    }
    return () => {};
  }, [history, originalUrl, pushModalState]);

  return (
    <Portal>
      <DisableScroll />
      <Frame>
        <Dim onClick={() => { if (dimClickable) onClickClose(); }} />
        {children}
      </Frame>
    </Portal>
  );
};

export default JDDimModal;
