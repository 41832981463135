import Colors from '__designkit__/common/colors';
import React, { FC, InputHTMLAttributes } from 'react';
import styled from 'styled-components';

const Frame = styled.div<{ isSelected: boolean, isDisabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.isSelected ? props.isDisabled ? Colors.CG_60 : Colors.G_150 : Colors.WHITE_100)};
  border: 2px solid ${(props) => (props.isSelected ? props.isDisabled ? Colors.CG_60 : Colors.G_150 : props.isDisabled ? Colors.CG_50_40 : Colors.CG_50)};

  &.size-medium {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  &.size-large {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }

  & input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    width: 50%; 
    height: 50%;
    border-radius: 50%;
    background-color: ${Colors.WHITE_100};
  }
`;

interface IRadioButtonProps extends InputHTMLAttributes<HTMLInputElement> {
  radioSize?: 'medium' | 'large'; // 선택값, 기본값은 medium
  selected?: boolean; // 선택값, 기본값은 false
  disabled?: boolean; // 선택값, 기본값은 false
}

const RadioButton: FC<IRadioButtonProps> = ({ radioSize = 'medium', selected = false, disabled = false, ...props }) => (
  <Frame className={radioSize === 'medium' ? 'size-medium' : 'size-large'} isSelected={selected} isDisabled={disabled}>
    <input type='radio' {...props} />
  </Frame>
);

export default RadioButton;
