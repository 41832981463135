import { ComponentProps } from '@ionic/core/components';
import { IonContent } from '@ionic/react';
import IContextPageProps from 'interfaces/props/IContextPageProps';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import { FC, forwardRef } from 'react';

//* * 스크롤 시 헤더를 가려줄 수 있게 해준다. Header를 안에다가 둘것*/

interface IHeaderHidableIonContentProps
  extends IContextPageProps,
    ComponentProps<HTMLIonContentElement> {}

const HeaderHidableIonContent: FC<IHeaderHidableIonContentProps> = forwardRef<
  HTMLIonContentElement,
  IHeaderHidableIonContentProps
>(({ context = new Context(), onIonScroll, ...props }, ref) => (
  <IonContent ref={ref} scrollEvents onIonScroll={onIonScroll} {...props} />
));

export default inject('context')(observer(HeaderHidableIonContent));
