import { useEffect, useRef } from 'react';

// 소개페이지 전용 Intersection Observer Hook (모바일 호환용)

interface useIntersectionObserverProps {
  root?: Element | null;
  rootMargin?: string;
  threshold?: number | number[];
  onIntersect: (entries: IntersectionObserverEntry[]) => void;
}

const useIO = ({
  root = null,
  rootMargin = '0px',
  threshold = 0,
  onIntersect,
}: useIntersectionObserverProps): ((node: Element | null) => void) => {
  const targetRef = useRef<Element | null>(null);

  useEffect(() => {
    if (!targetRef.current) return;

    const observer: IntersectionObserver = new IntersectionObserver(onIntersect, { root, rootMargin, threshold });
    observer.observe(targetRef.current);

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [onIntersect, root, rootMargin, threshold]);

  return (node: Element | null) => {
    targetRef.current = node;
  };
};

export default useIO;
