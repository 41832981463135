import JDClosableChip from 'components/_v2/_common/chip/JDClosableChip';
import JDCheckInput, { JD_CHECK_INPUT_TYPE } from 'components/_v2/_common/input/JDCheckInput';
import { IFilterProps } from 'components/_v2/calendar/CalendarDetailFilter';
import { Divider1G40 } from 'components/divider/Divider';
import Fonts from '__designkit__/common/fonts';
import colors from '__designkit__/common/colors';
import ICalendarProps from 'interfaces/_v2/calendar/ICalendarProps';
import { INameCodeRs } from 'interfaces/rqrs/ICommonRqRs';
import { inject, observer } from 'mobx-react';
import CalendarModel from 'models/_v2/CalendarModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components';
import Context from 'models/Context';

const Frame = styled.div`
    display: flex;
    height: fit-content;
    min-height: calc(100% - 66px);
    padding-bottom: 126px;
    &[aria-hidden='true'] {
        display: none;
    }

`;

const JobGroupFrame = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px 0;
    background: ${colors.JOBDA_WHITE};
`;

const GroupTitle = styled.div < { currentJob: boolean }>`
    width: 144px;
    height: 44px;
    padding: 12px 16px;
    font: ${Fonts.B2_Medium};
    color: ${colors.CG_70};

    ${(props) => props.currentJob && css`
        background-color: ${colors.WHITE_100};
        image.png  `}
`;

const JobTitleFrame = styled.div`
    padding: 20px;

    .jd-input {
      margin-bottom: 20px;
    }

    .group-items {
        display: flex;
        flex-direction: column;
        justify-content: center;
       
        .label{
            padding-left: 0;
          

            >span {
              padding-left: 10px;
            }
        }
        
    }
`;

const DividerHorizontal = styled(Divider1G40)`
    width: 1px;
    height: auto;
`;

const JobGroupInnerFrame = styled.div`

`;

const JobFilterChipFrame = styled.div`
  display: flex;
  width: 100%;
  height: 60px;
  align-items: center;
  padding: 12px 16px;
  position: fixed;
  bottom: calc(66px + env(safe-area-inset-bottom));
  overflow-x:auto;
  white-space:pre;
  background-color: ${colors.WHITE_100};
  filter: drop-shadow(0px -4px 16px rgba(0, 0, 0, 0.08));

  ::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
  }
  
  .chip-frame {
    margin-right: 6px;
  }

  &[aria-hidden='true'] {
    display: none;
  }

  .text {
    font: ${Fonts.B3_Bold};
  }
`;

const CalendarJobFilter: FC<IFilterProps & ICalendarProps> = ({ dataHide, context = new Context(), calendarModel = new CalendarModel() }) => {
  const useformed = useFormContext();
  const [currentJobCode, setCurrentJobCode] = useState(1); // 경영기획 Code 1 (default)
  const { watch, control, register, setValue } = useformed;
  const JobsFields = useFieldArray<INameCodeRs>({ control, name: 'jobs' });

  useEffect(() => {
    if (context.jobGroupsWithTitles[0])
      setCurrentJobCode(context.jobGroupsWithTitles[0].code);
    if (calendarModel.recentlyFilter) {
      if (calendarModel.recentlyFilter.jobTitles.length > 0) {
        calendarModel.recentlyFilter.jobTitles.forEach((jobTitle) => {
          setValue(`jobTitles.${jobTitle.code}`, String(jobTitle.code));
          addJobField(jobTitle.code, jobTitle.name);
        });
      }
    } else {
      const tempList = calendarModel.calendarLoadFilter.jobTitles!.split(',');
      context.jobGroupsWithTitles.forEach((jobGroup) => {
        jobGroup.jobTitles.forEach((job) => {
          const find = tempList.find((value) => value === String(job.code));
          if (find) {
            setValue(`jobTitles.${find}`, String(find));
            addJobField(job.code, job.name);
          }
        });
      });
    }
  }, []);

  const handleClickJobGroup = (jobGroupCode: number) => {
    setCurrentJobCode(jobGroupCode);
  };

  const handleChangeCheckbox = (code: number, name: string, checked: boolean) => {
    const list: INameCodeRs[] = watch('jobTitles');
    const filteredList = list.filter((value) => value);
    calendarModel.calendarFilter.jobTitles = filteredList.join(',');
    if (checked) {
      addJobField(code, name);
    } else {
      const index = JobsFields.fields.findIndex((field) => Number(field.code) === code);
      if (index !== -1) {
        removeJobField(code, index);
      }
    }
  };

  const addJobField = (code: number, name: string) => {
    JobsFields.append({ code, name });
  };

  const removeJobField = (code: number, index: number) => {
    JobsFields.remove(index);
    setValue(`jobTitles.${code}`, false);
    const list: INameCodeRs[] = watch('jobTitles');
    const filteredList = list.filter((value) => value);
    calendarModel.calendarFilter.jobTitles = filteredList.join(',');
  };

  return (
    <>
      <Frame aria-hidden={dataHide}>
        <JobGroupFrame>
          {context.jobGroupsWithTitles.map((jobGroup) => (
            <GroupTitle key={`jobGroup-${jobGroup.code}`} onClick={() => handleClickJobGroup(jobGroup.code)} currentJob={currentJobCode === jobGroup.code}>{jobGroup.name}</GroupTitle>
          ))}
        </JobGroupFrame>
        <DividerHorizontal />
        <JobTitleFrame>
          <div className='group-items'>
            {context.jobGroupsWithTitles.map((jobGroup) => (
              <JobGroupInnerFrame aria-hidden={jobGroup.code !== currentJobCode}>
                {jobGroup.jobTitles.map(({ code, name }) => (
                  <JDCheckInput
                    key={`check-option-jobTitle-${code}`}
                    type={JD_CHECK_INPUT_TYPE.CHECK_BOX}
                    name={`jobTitles.${code}`}
                    value={String(code)}
                    label={<span>{name}</span>}
                    onChange={(checked: boolean) => handleChangeCheckbox(code, name, checked)}
                  />
                ))}
              </JobGroupInnerFrame>
            ))}
          </div>
        </JobTitleFrame>
      </Frame>
      <JobFilterChipFrame aria-hidden={dataHide}>
        {JobsFields.fields.map((job, idx) => (
          <div className='chip-frame' key={`chip-${job.code}`}>
            <input aria-hidden ref={register()} type='text' name={`jobs[${idx}].name`} defaultValue={job.name || ''} />
            <input aria-hidden ref={register()} type='text' name={`jobs[${idx}].code`} defaultValue={job.code ?? -1} />
            <JDClosableChip onClose={() => removeJobField(Number(job.code), idx)}>{job.name}</JDClosableChip>
          </div>
        ))}
      </JobFilterChipFrame>
    </>
  );
};

export default inject(injectStore.context, injectStore.calendarModel)(observer(CalendarJobFilter));
