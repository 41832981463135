import ImgTrashBin from 'assets/_v2/profile/icon_trashbin.svg';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import React, { FC } from 'react';
import styled from 'styled-components';

const Frame = styled.button`
  justify-self: right;
  display: flex;
  background: transparent;
  align-items:center;
  >img{
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
  >span{
    color:${colors.CG_80};
    font: ${Fonts.B2_Medium_P};
    line-height: 18px;
  }
`;

const JDDeleteButton: FC<{ text?: string, onClick: React.MouseEventHandler<HTMLButtonElement> }> = ({ text, onClick }) => (
  <Frame onClick={onClick}>
    <img src={ImgTrashBin} alt='삭제' />
    <span>{text || '삭제'}</span>
  </Frame>
);

export default JDDeleteButton;
