export enum QuestionTypeCode {
    COMMON = 'COMMON',
    COMPANY = 'COMPANY',
    CUSTOM = 'CUSTOM',
    ACC = 'ACC'
}
export interface InterviewHistoryListRs {
  practiceRecordQuestionsByDate: { [key: string]: InterviewHistoryListItemDto[] };
}

export interface InterviewHistoryListItemDto {
  questionTypeCode: QuestionTypeCode;
  badgeTitle: string;
  practiceQuestionSn: number;
  pooledQuestionSn: number;
  passReviewCompanySn: number | null;
  questionText: string;
  playTime: number;
  date: string;
  isFavoriteQuestion: boolean;
  jobTypeCode: JobTypeCode;
  jobGroupCode: JobGroupCode;
  answerVideoUrl:string;
  type: string;
  description:string
}

export enum JobTypeCode {
    NEW = 'NEW',
    CAREER = 'CAREER',
    INTERNSHIP = 'INTERNSHIP',
  }

export enum JobGroupCode {
    COMMON = -1,
    MANAGEMENT = 1,
    MARKETING = 2,
    SALES = 3,
    PRODUCTION = 4,
    ENGINEER = 6,
    RESEARCH = 7,
    IT = 8,
    DESIGN = 9,
    FINANCE = 10,
    CS = 11,
    MEDICAL = 12,
  }

export interface QuestionAnswerResultRs {
    answerVideoUrl:string;
    pooledQuestionSn: number;
    questionText: string;
    dateTime: string;
    badgeTitle:string;
    description:string;
    jobTypeCode: JobTypeCode;
    jobGroupCode: JobGroupCode;
    questionTypeCode: QuestionTypeCode;
    type: string;
    helpContents: QuestionHelpContentMap | null;
  }

export interface QuestionHelpContentMap {
    [QuestionHelpContentTypeCode.QUESTION_PURPOSE]: QuestionHelpSimpleContent[];
    [QuestionHelpContentTypeCode.QUESTION_PURPOSE_LINK]: QuestionHelpSimpleContent[];
    [QuestionHelpContentTypeCode.ANSWER_GUIDE]: QuestionHelpSimpleContent[];
    [QuestionHelpContentTypeCode.CAUTION]: QuestionHelpSimpleContent[];
    [QuestionHelpContentTypeCode.GOOD_ANSWER]: QuestionHelpDetailContent[];
    [QuestionHelpContentTypeCode.BAD_ANSWER]: QuestionHelpDetailContent[];
    [QuestionHelpContentTypeCode.SIMILAR_QUESTION_ANSWER]: QuestionHelpDetailContent[];
  }
export enum QuestionHelpContentTypeCode {
    QUESTION_PURPOSE = 'QUESTION_PURPOSE',
    QUESTION_PURPOSE_LINK = 'QUESTION_PURPOSE_LINK',
    ANSWER_GUIDE = 'ANSWER_GUIDE',
    CAUTION = 'CAUTION',
    GOOD_ANSWER = 'GOOD_ANSWER',
    BAD_ANSWER = 'BAD_ANSWER',
    SIMILAR_QUESTION_ANSWER = 'SIMILAR_QUESTION_ANSWER',
  }

export interface QuestionHelpSimpleContent {
    value: string;
  }

export interface QuestionHelpDetailContent extends QuestionHelpSimpleContent {
    title: string;
  }
