import React from 'react';
import Icon from '__designkit__/icon/Icon';
import Colors from '__designkit__/common/colors';
import classnames from 'classnames/bind';
import JDALink from 'components/JDALink';
import RoutePaths from 'consts/RoutePaths';
import styles from './bannerStyle.module.scss';

const cx = classnames.bind(styles);

const Banner = () => (
  <div className={cx('contentInner', 'bottomBox', 'bannerInner')}>
    <span className={cx('text')}>
      프로필과 역량검사만 완료하면
      <br />
      매칭 대상자로 선정되어
      <br />
    </span>
    <p className={cx('textImport')}>
      인사담당자가
      <br />
      직접 면접을 제안합니다.
    </p>
    <JDALink to={RoutePaths.match_introduce} className={cx('btnBasic')}>
      잡다매칭 자세히보기
      <div className={cx('btnIcon')}>
        {/* <IconArrowRightLight width={16} height={16} fill={Colors.C_WHITE} /> */}
        <Icon name='arrow-right' size={16} color={Colors.WHITE_100} />
      </div>
    </JDALink>
    <div className={cx('bannerText')} />
  </div>
);

export default Banner;
