import React from 'react';
import classnames from 'classnames/bind';
import Icon from '__designkit__/icon/Icon';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

const Order = () => (
  <div className={cx('commonBox', 'greenTag')}>

    {/* 타이틀 */}
    <div className={cx('basicTitleInner')}>
      <span className={cx('basicTitle')}>750여 개 기업에게 노출되는 내 프로필</span>
      <span className={cx('subTitle')}>
        상세한 프로필로 면접 제안
        <br />
        가능성을 높여보세요!
      </span>
    </div>

    {/* 면접 제안 가능성 내용 */}
    <div className={cx('contentInner')}>
      <span className={cx('basicText')}>
        <span className={cx('num')}>1</span>
        상세한 프로필 작성
      </span>
      <Icon name='large-arrow-bottom' width={48} height={48} />
      <span className={cx('basicText')}>
        <span className={cx('num')}>2</span>
        기업 인사담당자에게 우선 노출
      </span>
      <Icon name='large-arrow-bottom' width={48} height={48} />
      <span className={cx('basicText')}>
        <span className={cx('num')}>3</span>
        입사 제안 확률 UP
      </span>
    </div>
  </div>
);

export default Order;
