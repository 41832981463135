import React from 'react';
import classnames from 'classnames/bind';
import { IntervieweeVO, PostSignInRs } from '__pc__/page/timeattack_marketer/types';
import JDALink from 'components/JDALink';
import TodayRenderCheckUtil from 'utils/TodayRenderCheckUtil';
import styles from './index.module.scss';
import { postLog } from '../../fetch';

const cx = classnames.bind(styles);

interface IMarketerCardProps {
  type: string;
  userInfo: IntervieweeVO;
  onSelect?: () => void;
  isSelected?: boolean;
  isOff?: boolean;
}

const MarketerCard = ({ userInfo, onSelect, isSelected, type, isOff }: IMarketerCardProps) => {
  const handleSelect = () => {
    onSelect?.();
  };

  const onClickCard = () => {
    const asyncInit = async () => {
      const companyInfo = await TodayRenderCheckUtil.getValue<PostSignInRs[number]>('company-info');
      if (!companyInfo) return;

      await postLog({
        'company-code': companyInfo.code,
        'interviewee-code': userInfo.code,
        action: 'VIEW_PROFILE',
      });
    };
    asyncInit();
  };

  return (
    <div className={cx('wrap')}>
      {/* 인재번호, 체크박스 숨김처리 */}
      <div className={cx('infoHidden')}>
        {userInfo.code}
        <input type='checkbox' checked={isSelected} onChange={handleSelect} />
      </div>

      {/* 인재카드 */}
      <div className={cx('thumbnail', { checked: isSelected })}>
        <img src={userInfo.thumbnailUrl} className={cx('cardImg')} alt='img' />
        <JDALink
          to={`/event/timeattack_marketer/${userInfo.code}?type=${type}`}
          target='_blank'
          className={cx('detailBtn')}
          onClick={onClickCard}
        >
          인재정보 더보기
        </JDALink>
        {/* 면접 예약하기 버튼 */}
        <a
          className={cx('interviewBtn', { checked: isSelected, isMyReservation: isSelected && isOff })}
          role='button'
          onClick={handleSelect}
        >
          {isSelected ? '예약 취소하기' : '면접 예약하기'}
        </a>
        {isOff ? <div className={cx('statusInner', 'off')} /> : <div className={cx('statusInner', 'on')} />}
      </div>

      <div className={cx('companyInfo')}>
        {userInfo.reservations.map((reservation, i) => (
          <div className={cx('stateOrder', 'done')} key={i}>
            <span className={cx('state')}>{i + 1}회차</span>
            {reservation.maskedCompanyName} 면접 예약 완료
          </div>
        ))}
        {Array.from({ length: 3 - userInfo.reservations.length }).map((_, i) => (
          <div className={cx('stateOrder')} key={i}>
            <span className={cx('state')}>{userInfo.reservations.length + i + 1}회차</span>
            예약 가능
          </div>
        ))}
      </div>
    </div>
  );
};

export default MarketerCard;
