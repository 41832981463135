import useToast from 'hooks/useToast';
import JDSaveButton from 'components/_v2/_common/button/JDSaveButton';
import JDFullScreenBackableModal from 'components/_v2/_common/modals/JDFullScreenBackableModal';
import ProfileEditTitleComponent from 'components/_v2/profile/ProfileEditTitleComponent';
import ProfileSkillKeyword from 'components/_v2/profile/skill/ProfileSkillKeyword';
import ErrorCode, { ErrorCodeText } from 'consts/ErrorCodes';
import Fonts from '__designkit__/common/fonts';
import colors from '__designkit__/common/colors';
import IProfileKnowledgeAndSkillsRs from 'interfaces/_v2/profile/IProfileKnowledgeAndSkillsRs';
import { IProfileSkillSaveDto } from 'interfaces/_v2/profile/IProfileKnowledgeAndSkillsSaveRq';
import { IPhsSkillSaveRq } from 'interfaces/rqrs/IPhsRqRs';
import { inject, observer } from 'mobx-react';
import PaccGateModel from 'models/_v2/PaccGateModel';
import { injectStore } from 'models/store';
import { IPaccGateProps } from 'pages/_v2/acca/PaccGate';
import React, { FC, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

const Frame = styled.div`
`;

const TitleFrame = styled(ProfileEditTitleComponent)`
    padding-top: 40px;
`;
export const SkillListItem = styled.ul`
  li {
    position:relative;
    padding-left:16px 16px 20px 56px;
    font: ${Fonts.B1_Medium};
    color: ${colors.CG_90};

  }
`;

const defaultValues: Partial<IProfileKnowledgeAndSkillsRs> = { licenses: [], examinations: [], skills: [] };

interface IPaccGateSkillModal extends IPaccGateProps {
    isOpen?:boolean;
    onClickClose?: () => void;
}
const PaccGateSkillModal:FC<IPaccGateSkillModal> = ({ isOpen, onClickClose, paccGateModel = new PaccGateModel() }) => {
  const { setToastObject } = useToast();

  const useFormed = useForm<IProfileKnowledgeAndSkillsRs>({
    mode: 'onSubmit',
    defaultValues,
  });

  const { formState, reset, getValues, setValue, handleSubmit } = useFormed;
  useEffect(() => {
    const init = async () => {
      try {
        await paccGateModel.loadPhsCenterSkill();
        if (paccGateModel.centerSkill)
          reset(paccGateModel.centerSkill);
      } catch (e) {
        console.error(e);
        reset(defaultValues);
      }
    };
    init();
  }, [paccGateModel, reset, isOpen]);

  useEffect(() => {
    paccGateModel.isDirty = formState.isDirty;
  }, [formState.isDirty]);

  const onSubmit = useCallback(async () => {
    try {
      const inputData = getValues();

      const transformed: IPhsSkillSaveRq = {
        ...inputData,
        skills: inputData.skills?.map<IProfileSkillSaveDto>(({ code, level }) => ({ code, level })),
      };

      if (transformed.skills === undefined) transformed.skills = [];

      await paccGateModel.savePhsCenterSill(transformed);
      setValue('skillIdx', null);
      paccGateModel.isDirty = false;
      setToastObject({ isOpen: true, type: 'SUCCESS', message: '성공적으로 저장되었습니다.', subMessage: undefined });
    } catch (e: any) {
      switch (e.response?.data?.errorCode) {
        case ErrorCode.B901:
          setToastObject({ isOpen: true, type: 'ERROR', message: ErrorCodeText.get_profile_skill.B901 });
          break;
        case ErrorCode.B902:
          setToastObject({ isOpen: true, type: 'ERROR', message: ErrorCodeText.get_profile_skill.B902 });
          break;
        case ErrorCode.B903:
          setToastObject({ isOpen: true, type: 'ERROR', message: ErrorCodeText.get_profile_skill.B903 });
          break;
        default:
          setToastObject({ isOpen: true, type: 'ERROR', message: '저장에 실패하였습니다.' });
      }
      console.error(e);
    }
  }, []);

  const onError = () => {

  };

  return (
    <JDFullScreenBackableModal isOpen={isOpen} onClose={onClickClose}>
      <TitleFrame
        title='보유하고 있는 기술 키워드를 입력해 주세요.'
        subtitle='내 프로필에 입력한 기술 키워드와 연동됩니다.'
      />
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <FormProvider {...useFormed}>
          <Frame>
            <ProfileSkillKeyword />
          </Frame>
          <JDSaveButton type='submit' fixed={false} onSubmit={() => {}} />
        </FormProvider>
      </form>
    </JDFullScreenBackableModal>
  );
};

export default inject(injectStore.paccGateModel)(observer(PaccGateSkillModal));
