import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import CompanyDetailFilterTab from 'components/_v2/company/CompanyDetailFilterTab';
import MainNoPosition from 'components/_v2/main/MainNoPosition';
import RoutePaths from 'consts/RoutePaths';
import { lineClampBox } from 'consts/style/mixins';
import { inject, observer } from 'mobx-react';
import CompanyModel from 'models/_v2/CompanyModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { getDCount, getDDayString, str2DateTime } from 'utils/DateUtils';

const Header = styled.header`
    display: flex;    
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(env(safe-area-inset-top) + 50px);
    position: fixed;
    background-color: ${colors.WHITE_100};
    top: 0;
    z-index: 10;
    padding-top: calc(env(safe-area-inset-top));
    & > div {
        left:8px;
        position: absolute;
        z-index: 12;
    }
`;
const PositionContainer = styled.div`
  padding:24px 16px;
  display: flex;
  justify-content: space-between;
  .position-title{
    font:${Fonts.B2_Bold_P};
    color:${colors.JOBDA_BLACK};
    max-width: 170px;
    ${lineClampBox(2)}
  }
  .info-frame{
    display: flex;
    align-items: center;
    .subtitle-text{
      font:${Fonts.B3_Medium};
      color:${colors.CG_70};
    }
    .d-day-frame{
      padding:4px 6px;
      border-radius: 4px;
      border: 1px solid ${colors.CG_50};
      background-color: ${colors.JOBDA_WHITE};
      display: flex;
      justify-content: center;
      align-items: center;
      height: fit-content;
      width: fit-content;
    }
  }
`;
const Divider = styled.div`
  width: 100%;
  height:1px;
  background-color: ${colors.CG_40};
`;
interface ICompanyDetailPosition {
    companyModel?: CompanyModel
}
const CompanyDetailPosition:FC<ICompanyDetailPosition> = ({ companyModel = new CompanyModel() }) => {
  const positions = companyModel.companyPositions?.positions;
  const history = useHistory();

  useEffect(() => {
    const init = async () => {
      if (companyModel.companySn) {
        await companyModel.loadCompanyPositions();
      }
    };
    init();
  }, [companyModel, companyModel.companySn]);
  const onClickBackBtn = () => {
    history.goBack();
  };

  return (
    <>
      <Header>
        <Icon name='large-arrow-left' size={32} onClick={onClickBackBtn} />
      </Header>
      {
        (companyModel.searchFilters.jobTitles === ''
          && companyModel.searchFilters.recruitments === ''
          && companyModel.searchFilters.minSalary === undefined
          && companyModel.searchFilters.maxSalary === undefined
          && companyModel.searchFilters.locations === ''
          && companyModel.searchFilters.matchingYn === false
        ) && positions && positions?.length === 0
          ? <></> : <CompanyDetailFilterTab />
      }

      {companyModel.companyPositions !== null && companyModel.companyPositions?.pages !== undefined
        && (
          <>
            {
                  positions && positions.length > 0 && positions.map((data) => {
                    // 마감 지나면 포지션 리스트에서 제거
                    if (data.closingDateTime && getDCount(data.closingDateTime) > 0) return <></>;
                    return (
                      <>
                        <PositionContainer onClick={() => history.push(RoutePaths.position_jd(data.positionSn))}>
                          <div className='position-title'>{data.positionName}</div>
                          <div className='info-frame'>
                            <div className='subtitle-text'>{data.closingDateTime && data.closingDateTime.split('T')[0].replace(/-/g, '.')}</div>
                            <SpacingBlock horizontal size={8} />
                            <div className='d-day-frame'>
                              <div className='subtitle-text'>{data.closingDateTime ? getDDayString(str2DateTime(data.closingDateTime)) : '상시'}</div>
                            </div>
                          </div>
                        </PositionContainer>
                        <Divider />
                      </>
                    );
                  })
                }
          </>

        )}
      {
         companyModel.companyPositions?.positions?.length === 0 && (
         <>
           <SpacingBlock vertical size={130} />
           <MainNoPosition />
         </>
         )
        }
    </>
  );
};
export default inject(injectStore.companyModel)(observer(CompanyDetailPosition));
