import { JobSearchStatusType } from 'consts/MatchType';
import { PhsAccType } from 'consts/MatchingMessageType';
import URIs from 'consts/URIs';
import { IAccProgressCompleteDto } from 'interfaces/_v2/acc/IAccProgressCompleteRs';
import { IMatchingApplyResultDto, IMatchingProfileDto, IMatchingProfileRs } from 'interfaces/_v2/matchApply/IMatchingProfileRs';
import { IPaccProgressCompleteDto } from 'interfaces/_v2/phs/pacc/IPaccProgressCompleteRs';
import { IPstProgressCompleteDto } from 'interfaces/_v2/phs/pst/IPstProgressCompleteRs';
import { IProfileJobGroupDto, IProfileJobGroupNameDto } from 'interfaces/_v2/profile/IMatchingConditionRqRs';
import { IProfileTypeRs } from 'interfaces/_v2/profile/IProfileTypeRs';
import { INameSnRs } from 'interfaces/rqrs/ICommonRqRs';
import { IProfileBlindCompanyRq } from 'interfaces/rqrs/IProfileBlindCompanyRq';
import { IProfileBlindCompanyRs } from 'interfaces/rqrs/IProfileBlindCompanyRs';
import { action, observable } from 'mobx';
import request from 'utils/request';

export default class MatchApplyModel {
  @observable profile: IMatchingProfileDto | null = null;
  @observable applyResults: IMatchingApplyResultDto[] = [];
  @observable completedGeneralYn: boolean = false;
  @observable completedDevelopYn: boolean = false;
  @observable transJobGroups: IProfileJobGroupDto[] = [];
  @observable savedJobGroups: IProfileJobGroupNameDto[] | null = null;
  @observable profileType: IProfileTypeRs | null = null;
  @observable accProgressList: IAccProgressCompleteDto[] | null = null;
  @observable pstProgressList: IPstProgressCompleteDto[] | null = null;
  @observable paccProgressList: IPaccProgressCompleteDto[] | null = null;
  @observable companySearchList: INameSnRs[] = [];
  @observable myBlinds: Partial<IProfileBlindCompanyRs> | null = null;
  @observable blindCompany: IProfileBlindCompanyRq = { blindCompanySnSet: [] };

  @action
  loadMatchingProfile = async () => {
    try {
      const res = (await request<IMatchingProfileRs>({
        method: 'get',
        url: URIs.get_match_profile,
      }));
      if (res) {
        this.profile = res.profile;
        this.applyResults = res.applyResults;

        if (res.profile.profileRequiredCompleteYn) {
          if (res.applyResults.some((result) => result.applyType === PhsAccType.ACC)) this.completedGeneralYn = true;
          if (res.applyResults.some((result) => result.applyType === PhsAccType.PACC) && res.applyResults.some((result) => result.applyType === PhsAccType.PST)) this.completedDevelopYn = true;
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  @action
  saveJob = async (jobGroups: IProfileJobGroupDto[]) => {
    try {
      await request({
        method: 'put',
        url: URIs.put_match_profiles_job_group,
        data: { jobGroups },
      });
    } catch (e) {
      console.error(e);
    }
  }

  @action
  loadSavedJobGroups = async () => {
    try {
      this.savedJobGroups = (await request<{ jobGroups: IProfileJobGroupNameDto[]; }>({
        method: 'get',
        url: URIs.get_match_conditions,
      })).jobGroups;
    } catch (e) {
      console.error(e);
    }
  }

  resetTransJobGroups = () => {
    this.transJobGroups = [];
  }

  @action
  loadProfileType = async () => {
    try {
      this.profileType = (await request({
        method: 'get',
        url: URIs.get_match_profiles_type,
      }));
    } catch (error) {
      console.error(error);
    }
  }

  @action
  loadAccProgressList = async () => {
    try {
      this.accProgressList = (await request<{resultRecords : IAccProgressCompleteDto[]}>({
        method: 'get',
        url: URIs.get_acc_results_list,
      })).resultRecords;
    } catch (e) {
      console.error(e);
    }
  }

  @action
  loadPaccProgressList = async () => {
    try {
      this.paccProgressList = (await request<{resultRecords : IPaccProgressCompleteDto[]}>({
        method: 'get',
        url: URIs.get_pacc_results_list,
      })).resultRecords;
    } catch (e) {
      console.error(e);
    }
  }

  @action
  loadPstProgressList = async () => {
    try {
      this.pstProgressList = (await request<{resultRecords : IPstProgressCompleteDto[]}>({
        method: 'get',
        url: URIs.get_pst_results_list,
      })).resultRecords;
    } catch (e) {
      console.error(e);
    }
  }

  @action
  putAccResultMatching = async (applySn: number) => {
    try {
      await request({
        method: 'put',
        url: URIs.put_acc_results_select_match,
        data: { applySn },
      });
    } catch (e) {
      console.error(e);
    }
  }

  @action
  putPaccResultMatching = async (applySn: number) => {
    try {
      await request({
        method: 'put',
        url: URIs.put_pacc_results_select_match,
        data: { applySn },
      });
    } catch (e) {
      console.error(e);
    }
  }

  @action
  putPstResultMatching = async (applySn: number) => {
    try {
      await request({
        method: 'put',
        url: URIs.put_pst_results_select_match,
        data: { applySn },
      });
    } catch (e) {
      console.error(e);
    }
  }

  @action
  searchCompanies = async (keyword: string) => {
    this.companySearchList = (
      await request<{ companies: INameSnRs[] }>({
        method: 'get',
        url: URIs.get_companies,
        params: { keyword },
      })
    ).companies;
  };

  @action
  saveMyBlinds = async (data:IProfileBlindCompanyRq) => {
    try {
      const res = await request({
        method: 'put',
        url: URIs.put_match_profiles_my_blind,
        data,
      });
      return res;
    } catch (e) {
      console.error(e);
      throw (e);
    }
  }

  @action
  loadMyBlinds = async () => {
    try {
      this.myBlinds = await request<IProfileBlindCompanyRs>({
        method: 'get',
        url: URIs.get_match_profiles_my_blind,
      });
    } catch (e) {
      console.error(e);
    }
  }

  @action
  saveJobSearchStatus = async (jobSearchStatus: JobSearchStatusType) => {
    try {
      await request({
        method: 'put',
        url: URIs.put_match_config_job_search_status,
        data: { jobSearchStatus },
      });
    } catch (e) {
      console.error(e);
    }
  }

  @action
  terminate = () => {
    this.myBlinds = null;
  }
}
