import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Fonts from '__designkit__/common/fonts';
import Colors from '__designkit__/common/colors';
import Icon from '__designkit__/icon/Icon';
import { lineClampBox } from 'consts/style/mixins';
import useAdsMainBanner from 'query/ads/useAdsMainBannerQuery';
import useActiveTabFlag from 'hooks/useActiveTabFlag';
import JDALink from 'components/JDALink';
import RoutePaths from 'consts/RoutePaths';

const Frame = styled.div`
  padding: 0 16px;
`;

const ExampleFrame = styled.div<{rotateX?:number, ANGLE?:number}>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    padding: 12px 16px;
    border: 1px solid ${Colors.CG_40};
    border-radius: 8px;

    .rolling-space{
      display: flex;
      align-items: center;
      gap: 8px;

      .rotator-area{
        overflow: hidden;
        
        .rotator{
          position: relative;
          display: flex;
          align-items: center;
          width: 68vw;
          height: 18px;
          transform-style: preserve-3d;
          transition: all 1s ease-in-out;
          transform: ${(props) => `rotateX(${props.rotateX}deg)`};

          .item {
              position: absolute;
              backface-visibility: hidden;
              font:${Fonts.B2_Medium};
              color:${Colors.CG_70};

          }
          .rolling-text{
            display:flex;
            ${lineClampBox(1)};
          }
        }
      }
    }
`;

const MainRolling = () => {
  const [rotateX, setRotateX] = useState(0);
  const [angle, setAngle] = useState(0);
  const initFlag = useActiveTabFlag();
  const { data: adsMainRolling } = useAdsMainBanner(true);
  useEffect(() => {
    if (adsMainRolling && adsMainRolling.adsMainBanners && adsMainRolling.adsMainBanners.length > 0) {
      setAngle(360 / adsMainRolling.adsMainBanners.length);
    }
  }, [adsMainRolling]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setRotateX((prev) => prev - angle);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [angle]);
  if (!initFlag) return null;
  return (
    <Frame>
      <ExampleFrame rotateX={rotateX} ANGLE={angle}>
        <div className='rolling-space'>
          <Icon name='information' size={24} color={Colors.G_150} />
          <div className='rotator-area'>
            <div className='rotator'>

              {adsMainRolling && adsMainRolling.adsMainBanners && adsMainRolling.adsMainBanners.length > 0 ? (
                adsMainRolling.adsMainBanners.map((item, index) => (
                  <div
                    className='item'
                    key={`example-${item.sn}`}
                    style={{ transform: `rotateX(${index * angle}deg) translateZ(50px)` }}
                  >
                    <JDALink to={item.link} className='rolling-text'>
                      {item.name}
                    </JDALink>
                  </div>
                ))
              ) : (
              // adsMainRolling.adsMainBanners의 길이가 0인 경우 렌더링 될 내용
                <div
                  className='item'
                  style={{ transform: `rotateX(0deg) translateZ(50px)` }}
                >
                  <JDALink to={RoutePaths.match_introduce} className='rolling-text'>
                    잡다매칭, 역량만으로 취업하는 매칭 서비스
                  </JDALink>
                </div>
              )}

              {/* {adsMainRolling && adsMainRolling.adsMainBanners && adsMainRolling.adsMainBanners.map((item, index) => (
                <div
                  className='item'
                  key={`example-${item.sn}`}
                  style={{ transform: `rotateX(${index * angle}deg) translateZ(50px)` }}
                >
                  <JDALink to={item.link} className='rolling-text'>
                    {item.name}
                  </JDALink>
                </div>
              ))} */}

            </div>
          </div>
        </div>
      </ExampleFrame>
    </Frame>
  );
};

export default MainRolling;
