import React, { FC } from 'react';
import styled from 'styled-components';
import imgDeclarationTitle from 'assets/img/declaration/img_declaration_title.svg';
import colors from '__designkit__/common/colors';
import { fonts, fontStyle } from '__designkit__/common/fonts';

const Frame = styled.section`
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:center;
  padding-top:48px;
  padding-bottom:16px;
  background:${colors.CG_30};
  .frame-title {
    ${fontStyle.HEADLINE5_MEDIUM};
    color:${colors.CG_70};
    &:before {
      content:'';
      width:36px;
      height:36px;
      display:block;
      background:url(${imgDeclarationTitle});
      background-repeat:no-repeat;
      background-size:100%;
      margin:0 auto;
    }
  }
  h2 {
    margin-top:32px;
    ${fonts.NOTO_34_300};
    line-height:40px;
    letter-spacing:0.3px;
    color:${colors.JOBDA_BLACK};
    white-space:pre-line;
    text-align:center;
    b {
      display:block;
      ${fontStyle.HEADLINE2_SEMIBOLD};
    }
  }
`;

const DecalrationTitle:FC = () => (
  <Frame>
    <h1 className='frame-title'>
      잡다선언문
    </h1>
    <h2>
      <b>잡다는 대한민국의</b>
      역량중심 사회를
      지향합니다.
    </h2>
  </Frame>
);

export default DecalrationTitle;
