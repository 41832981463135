import Fonts from '__designkit__/common/fonts';
import DisableScroll from 'components/common/DisableScroll';
import Portal from 'components/common/Portal';
import RoutePaths from 'consts/RoutePaths';
import { ScrollHiddenMixin } from 'consts/style/mixins';
import IComponentProps from 'interfaces/props/IComponentProps';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import Login from 'models/Login';
import Contents from 'models/_v2/Contents';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { IconLogo } from 'consts/assets/icons/iconPages';
import HambugerMenu from 'components/common/HambugerMenu';
import Colors from '__designkit__/common/colors';
import Banner from 'assets/_v2/drawNavigation/banner.png';
import Acca from 'assets/_v2/drawNavigation/icon_acca.svg';
import PreparationContents from 'assets/_v2/drawNavigation/icon_book_green.svg';
import EmploymentAd from 'assets/_v2/drawNavigation/icon_briefcase_purple.svg';
import EmploymentCalendar from 'assets/_v2/drawNavigation/icon_calendar.svg';
import Pacc from 'assets/_v2/drawNavigation/icon_coding.svg';
import MyMenuLogo4 from 'assets/_v2/drawNavigation/icon_file_done_green.svg';
import Event from 'assets/_v2/drawNavigation/icon_gift_red.svg';
import MyMenuLogo1 from 'assets/_v2/drawNavigation/icon_info_user.svg';
import PreparationMbti from 'assets/_v2/drawNavigation/icon_matching_yellow.svg';
import MyMenuLogo2 from 'assets/_v2/drawNavigation/icon_page_checklist_blue.svg';
import MatchingSetting from 'assets/_v2/drawNavigation/icon_resume_done_green.svg';
import ImgSetting from 'assets/_v2/drawNavigation/icon_setting_light.svg';
import AccaResult from 'assets/_v2/drawNavigation/icon_test_acca.svg';
import PaccResult from 'assets/_v2/drawNavigation/icon_test_coding_blue.svg';
import MailYellow from 'assets/_v2/drawNavigation/icon_mail_yellow.svg';
import PassBoard from 'assets/_v2/drawNavigation/icon_chat_blue.svg';
import PassInterview from 'assets/_v2/drawNavigation/icon_flag_red.svg';

import JDALink from 'components/JDALink';

import Icon from '__designkit__/icon/Icon';

// v2 3.0.0 New DrawerNavigation 작업
const Frame = styled.div`
    height:100%;
  width: 100%;
  overflow-y: auto;
  ${ScrollHiddenMixin()};
  &.open{
    position:fixed;
    top: 0px;
    left: 0px;
    width:100%;
    height:100%;
    background:${Colors.WHITE_100};
    z-index: 10003;
  }

  .drawer-header {
    position:relative;
    height:56px;
    padding:0px 12px 0px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  
  .hamburger-icon{
    display:flex;
    align-items:center;
    justify-content: center;
    width: 40px;
    height: 40px;
  }
`;
const Container = styled.div`

  display: flex;
  flex-direction: column;
  padding: 16px 24px 24px;
  padding-bottom: calc(32px + env(safe-area-inset-bottom));

  .drawer-intro {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font: ${Fonts.H5_Bold};

    &.login {
      justify-content: flex-start;
      gap: 8px;
    }
  }

  /* 메뉴 4가지 */
  .myMenu {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    margin-top: 24px;

    .menu-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      
      .icon-frame {
        padding: 12px;
        border-radius: 10px;
        background-color: ${Colors.CG_30};
      }
      
      .title-text {
        font: ${Fonts.B3_Medium};
        color: ${Colors.JOBDA_BLACK};
      }
    }
  }

  /* 전체 메뉴 */
  .drawer-menu {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 24px;
    padding-top: 32px;
    border-top: 1px solid ${Colors.CG_40};

    .menu-list {
      display: flex;
      align-items: center;
      padding: 8px 0;

      .icon-frame {
        margin-right: 16px;
        img {
          width: 32px;
          height: 32px;
        }
      }

      .title-text {
        font: ${Fonts.B1_Bold};
        color: ${Colors.JOBDA_BLACK};
        margin-right: 8px;
      };
      }
      .new-icon{
        display: flex;
        width: 16px;
        height: 16px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background-color: ${Colors.ERROR};
        font:${Fonts.B3_Bold};
        color:${Colors.WHITE_100};
        padding:2px 4px;
      }
  }

  .menu-map {
    .main-title {
      display: flex;
      margin-bottom: 8px;
      font: ${Fonts.B3_Medium};
      color: ${Colors.CG_70};
    }
  }
  
`;

const BannerFrame = styled.div`
  margin-top: 32px;
  width: 100%;
  height: 100%;
  .banner{
    width: 100%;
  }
`;

interface IItemProps {
  icon: JSX.Element;
  title: string;
  linkTo: string;
}

interface IMatchingProps {
  icon: JSX.Element;
  title: string;
  linkTo: string;
  new?:boolean;
}

const myMenuFrame = (item: IItemProps, onClose: () => void) => (
  <JDALink className='menu-item' to={item.linkTo} onClick={onClose}>
    <div className='icon-frame'>{item.icon}</div>
    <div className='title-text'>{item.title}</div>
  </JDALink>
);

const MenuFrame = (item: IMatchingProps, onClose: () => void) => (
  <JDALink className='menu-list' to={item.linkTo} onClick={onClose}>
    <div className='icon-frame'>{item.icon}</div>
    <div className='title-text'>{item.title}</div>
    {item.new && <div className='new-icon'>N</div>}
  </JDALink>
);

interface INavigationProps extends IComponentProps {
  open: boolean;
  onClose: () => void;
  login?: Login;
  context?: Context;
  contents?: Contents;
}

const DrawerNavigation: FC<INavigationProps> = ({ open, onClose, login = new Login(), context = new Context(), contents = new Contents() }) => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [openDrawerDelay, setOpenDrawerDelay] = useState<boolean>(false);
  const { active } = context.blendMode;

  useEffect(() => {
    setOpenDrawer(open);
    setTimeout(() => {
      setOpenDrawerDelay(open);
    }, 100);
  }, [open]);

  useEffect(() => {
    (async () => {
      await contents.getEventCategory();
    })();
  }, []);

  if (openDrawer)
    return (
      <Portal>
        <Frame className={`${openDrawer && 'open'}`}>
          <DisableScroll />
          <div className='drawer-header'>
            <Link
              onClick={() => {
                if (path === RoutePaths.home) {
                  document.location.reload();
                }
                onClose();
              }}
              to={RoutePaths.home}
            >
              <IconLogo fill={active ? context?.blendMode.iconFill : undefined} />
            </Link>
            <button className='hamburger-icon' onClick={onClose}>
              <HambugerMenu open={openDrawerDelay} />
            </button>
          </div>
          <Container>

            { !login.userInfo
              ? (
                <div className='drawer-intro login'>
                  <button
                    onClick={async () => {
                      context.setRedirectUrl(pathname);
                      history.push(RoutePaths.login);
                      onClose();
                    }}
                  >로그인
                  </button>
                  <Icon name='arrow-right' size={32} />
                </div>
              )
              : (
                <>
                  {/* 로그인 후 */}
                  <div className='drawer-intro'>
                    <span className='user-name'>{login.userInfo?.name}</span>
                    <JDALink to={RoutePaths.mypage} onClick={onClose}>
                      <img src={ImgSetting} alt='계정설정' />
                    </JDALink>
                  </div>

                  {/* MY Menu */}
                  <div className='myMenu'>
                    {myMenuItem.map((item: IItemProps) => (
                      <>{myMenuFrame(item, onClose)}</>
                    ))}
                  </div>
                </>
              )}

            {/* 전체 메뉴 리스트 */}
            <div className='drawer-menu'>
              <div className='menu-map'>
                <span className='main-title'>잡다매칭</span>
                {MatchingMenuList.map((item: IMatchingProps) => (
                  <>{MenuFrame(item, onClose)}</>
                ))}
              </div>
              <div className='menu-map'>
                <span className='main-title'>채용정보</span>
                {EmploymentMenuList.map((item: IMatchingProps) => (
                  <>{MenuFrame(item, onClose)}</>
                ))}
              </div>
              <div className='menu-map'>
                <span className='main-title'>역량검사</span>
                {AccaMenuList.map((item: IMatchingProps) => (
                  <>{MenuFrame(item, onClose)}</>
                ))}
              </div>
              <div className='menu-map'>
                <span className='main-title'>개발자 검사</span>
                {PaccMenuList.map((item: IMatchingProps) => (
                  <>{MenuFrame(item, onClose)}</>
                ))}
              </div>
              <div className='menu-map'>
                <span className='main-title'>취업 준비</span>
                {PreparationMenuList.map((item: IMatchingProps) => (
                  <>{MenuFrame(item, onClose)}</>
                ))}
              </div>
              <div className='menu-map'>
                <span className='main-title'>기타 서비스</span>
                {EtcMenuList.map((item: IMatchingProps) => (
                  <>{MenuFrame(item, onClose)}</>
                ))}
              </div>
            </div>

            {/* 배너 */}
            <BannerFrame onClick={() => {
              history.push(RoutePaths.match_apply);
              onClose();
            }}
            >
              <img className='banner' src={Banner} alt='banner' />
            </BannerFrame>

          </Container>
        </Frame>
      </Portal>
    );
  return (<></>);
};

export default inject(injectStore.login, injectStore.context, injectStore.contents)(observer(DrawerNavigation));

// My Menu 리스트
const myMenuItem: IItemProps[] = [
  {
    icon: <img src={MyMenuLogo1} alt='내 프로필' />,
    title: '내 프로필',
    linkTo: RoutePaths.profile,
  },
  {
    icon: <Icon name='matching' size={40} />,
    title: '매칭 현황',
    linkTo: RoutePaths.match_apply,
  },
  {
    icon: <Icon name='resume' size={40} />,
    title: '지원 현황',
    linkTo: RoutePaths.mypage_apply,
  },
  {
    icon: <img src={MyMenuLogo4} alt='북마크한 공고' />,
    title: '북마크한 공고',
    linkTo: RoutePaths.mypage_bookmark,
  },
];

// 잡다 매칭 메뉴 리스트
const MatchingMenuList: IMatchingProps[] = [
  {
    icon: <img src={MatchingSetting} alt='매칭설정' />,
    title: '매칭설정',
    linkTo: RoutePaths.match_apply,
  },
  {
    icon: <img src={MyMenuLogo2} alt='매칭현황' />,
    title: '매칭현황',
    linkTo: RoutePaths.match_apply_status,
  },
];

// 채용정보 메뉴 리스트
const EmploymentMenuList: IMatchingProps[] = [
  {
    icon: <img src={EmploymentCalendar} alt='채용 캘린더' />,
    title: '채용 캘린더',
    linkTo: RoutePaths.jobs,
  },
  {
    icon: <img src={EmploymentAd} alt='채용 공고' />,
    title: '채용 공고',
    linkTo: RoutePaths.position,
  },
];

// 역량검사 메뉴 리스트
const AccaMenuList: IMatchingProps[] = [
  {
    icon: <img src={Acca} alt='역량검사' />,
    title: '역량검사',
    linkTo: RoutePaths.acca_test,
  },
  {
    icon: <img src={AccaResult} alt='역량검사 결과표' />,
    title: '역량검사 결과표',
    linkTo: RoutePaths.acca_results(),
  },
];

// 개발자 검사 메뉴 리스트
const PaccMenuList: IMatchingProps[] = [
  {
    icon: <img src={Pacc} alt='개발자 검사' />,
    title: '개발자 검사',
    linkTo: RoutePaths.phs_test,
  },
  {
    icon: <img src={PaccResult} alt='개발자 검사 결과표' />,
    title: '개발자 검사 결과표',
    linkTo: RoutePaths.phs_pacc_results(),
  },
];

// 취업준비 메뉴 리스트
const PreparationMenuList: IMatchingProps[] = [
  {
    icon: <img src={PreparationContents} alt='취업 콘텐츠' />,
    title: '취업 콘텐츠',
    linkTo: RoutePaths.info,
  },
  {
    icon: <img src={PassBoard} alt='합격 후기 게시판' />,
    title: '합격 후기 게시판',
    linkTo: RoutePaths.passboard,
  },
  {
    icon: <img src={PassInterview} alt='기출 면접 연습' />,
    title: '기출 면접 연습',
    linkTo: RoutePaths.pass_interview,
    new: true,
  },
];

// 이벤트 메뉴 리스트
const EtcMenuList: IMatchingProps[] = [
  // {
  //   icon: <img src={Event} alt='이벤트' />,
  //   title: '이벤트',
  //   linkTo: RoutePaths.event,
  // },
  {
    icon: <img src={MailYellow} alt='고객센터' />,
    title: '고객센터',
    linkTo: RoutePaths.jobda_faq,
  },
];
