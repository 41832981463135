/* eslint-disable react-hooks/exhaustive-deps */
import Portal from 'components/common/Portal';
import Fonts, { fonts } from '__designkit__/common/fonts';
import { IconBuilding, IconClose, IconClose20, IconSearch } from 'consts/assets/icons/iconPages';
import colors from '__designkit__/common/colors';

import { dimmer, JDAInputFrameBasic, JDAInputFrameMessage } from 'consts/style/mixins';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { forwardRef, MouseEvent, ReactNode, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components/macro';

const Frame = styled.div`
  width:100%;

  .input-frame{
    ${JDAInputFrameBasic()};
    // 돋보기 바깥 padding
    padding:0 16px;
    display:flex;
    align-items:center;

    // 돋보기 내부 input
    input{
      padding: 0;
      margin-left:10px;
      font: ${Fonts.B2_Medium};
      ::placeholder{
        font: ${Fonts.B2_Medium};
      }
    }

    img{
      margin-right:6px;
      object-fit: none;
    }

    .close-btn {
      display: none;
      margin-right: 0;
    }

    .close-btn.active {
      display: block;
    }

  }
  .message{
   ${JDAInputFrameMessage()};
  }
`;

const StyledSearchHeaderFrame = styled.div`
  background-color:${colors.WHITE_100};
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index:1;
  
  .header{
   display:flex;
   flex-direction:row;
   align-items:center;
   justify-content:space-between;
   height:calc(48px + env(safe-area-inset-top));
   padding-top:calc(17px + env(safe-area-inset-top));
   padding-left:18px;
   padding-bottom:18px;
   padding-right:18px;
  
   >span{
    font: ${Fonts.B1_Bold};
    color:${colors.CG_90};
   }
  }

  // 모달로 띄어지는 입력창 (외부)
  .inner.input-frame{
    display: block;
    width: 100%;
    height:48px;
    padding: 0px 16px;
    color: ${colors.CG_90};
    display:flex;
    align-items:center;
    box-shadow: 0px 1px 1px ${colors.CG_50};
    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px ${colors.JOBDA_WHITE} inset !important;
    }
    input:-webkit-autofill:focus {
     -webkit-box-shadow: 0 0 0 30px ${colors.WHITE_100} inset !important;
    }
    input {
      background: inherit;
      font: ${Fonts.B2_Medium};
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      ::placeholder {
        font: ${Fonts.B2_Medium};
        color: ${colors.CG_60};
      }
      padding-left: 16px;
    }
    img{
      margin-right:6px;
      object-fit: none;
    }

    .search-icon {
      margin-right: 6px;
    }
  }
`;

const StyledSearchResultFrame = styled.div`
  background-color:${colors.WHITE_100};
  li{
    position:relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width:100%;
    height:48px;
    ${fonts.NOTO_15_400};
    color:${colors.CG_90};
    padding: 12px 16px;
    img{
      margin-right:16px;
      object-fit: none;
    }
  }
`;

const StyledSearchFrame = styled.div`
 ${dimmer()};
  z-index:100002;
  display:flex;
  flex-direction:column;
  opacity: 1;
  transition: opacity 0.25s;
  transition-delay: 0.25s;
  overflow-y:auto;
  background-color: ${colors.WHITE_100};
`;

interface IJDASearchInput extends IComponentProps {
  fieldRef?: React.RefObject<HTMLInputElement | null>;
  name: string;
  title?:string;
  onSelectItem?: () => void;
  inputTextValue?: string; // 처음 표시되는 텍스트값;
  isCustomValue?: boolean; // 직접입력 아이템 사용
  searchInputCustomPattern?: RegExp;
  listItemIcon?: ReactNode;
  onDelete?: () => void; // x 닫기로 인풋값 제거시
  removeInputValue?: boolean; // 선택한 뒤에 인풋 텍스트 제거 여부
}

const JDASearchInput = forwardRef((props: IJDASearchInput) => {
  const { fieldRef, name, className, placeholder, onChange, removeInputValue, searchInputCustomPattern, onSelectItem, children, inputTextValue, isCustomValue, disabled, title, listItemIcon, onDelete } = props;
  const useFormed = useFormContext();
  const { register, errors, watch, setValue } = useFormed;

  const frameRef = useRef<HTMLDivElement>(null);
  const innerInputRef = useRef<HTMLInputElement>(null);

  const [openSearchView, setOpenSearchView] = useState<boolean>(false);
  const [searchLis, setSearchLis] = useState<React.ReactNode>(<></>);
  const [searchText, setSearchText] = useState<string>('');
  const [inputText, setInputText] = useState<string>('');

  useEffect(() => {
    if (!watch(name)) setInputText('');
  }, [watch(name)]);
  useEffect(() => {
    if (frameRef.current && searchText !== '') {
      const ulEle: HTMLUListElement = frameRef.current.getElementsByTagName('ul')[0];
      const lis = ulEle.getElementsByTagName('li');
      setSearchLis(
        (<ul>
          {
            Array.from(lis).map((data, index) => {
              const currentLi = lis[index];
              return (
                <li
                  role='presentation'
                  onClick={(e:MouseEvent<HTMLLIElement>) => {
                    setInputText(currentLi.innerText);
                    setValue(name, String(currentLi?.value), { shouldDirty: true, shouldValidate: true });
                    setOpenSearchView(false);
                    setSearchText('');
                    if (onSelectItem) onSelectItem();
                    if (removeInputValue) setInputText('');
                  }}
                >
                  {listItemIcon ?? <IconBuilding /> }<span>{currentLi.innerText}</span>
                </li>
              );
            })
          }
          {
            isCustomValue && (
              <li
                role='presentation'
                onClick={() => {
                  setInputText(searchText);
                  setValue(name, `$${searchText}`);
                  setOpenSearchView(false);
                  if (onSelectItem) onSelectItem();
                  if (removeInputValue) setInputText('');
                }}
              >
                  {listItemIcon ?? <IconBuilding /> }<span>직접 입력</span>
              </li>
            )
          }
        </ul>),
      );
    }
  }, [children]);

  useEffect(() => {
    setInputText(inputTextValue || '');
    return (() => {
      setSearchLis(<></>);
    });
  }, [inputTextValue]);

  useEffect(() => {
    if (!openSearchView) setSearchLis(<></>);
  }, [openSearchView]);

  useEffect(() => {
    if (watch(name) === '' && inputTextValue === '') setInputText('');
  }, [watch(name)]);

  return (
    <Frame className={`jda-input ${className || ''}`}>
      <div className={`input-frame ${errors[name] === undefined}`}>
        <IconSearch className='search-icon' />
        <input
          ref={(ref) => {
            if (fieldRef !== undefined) (fieldRef as any).current = ref;
          }}
          placeholder={placeholder}
          disabled={disabled}
          onClick={() => {
            setOpenSearchView(true);
            setTimeout(() => innerInputRef.current?.focus(), 200);
          }}
          value={inputText}
        />
        <input
          style={{ display: 'none' }}
          name={name}
          value={watch(name)}
          ref={register()}
        />
        <IconClose20
          className={`close-btn ${inputText ? 'active' : ''}`}
          onClick={() => {
            onDelete && onDelete();
            setValue(name, '');
            setInputText('');
          }}
        />
      </div>
      <Portal>
        <StyledSearchFrame ref={frameRef} style={{ display: openSearchView ? 'block' : 'none' }}>
          <div style={{ display: 'none' }}>
            {children}
          </div>
          <StyledSearchHeaderFrame>
            <div className='header'><span>{title ?? placeholder}</span><IconClose onClick={() => { setOpenSearchView(false); setSearchText(''); }} /></div>
            <div className={`inner input-frame ${errors[name] === undefined}`}>
              <IconSearch className='search-icon' />
              <input
                ref={(ref) => {
                  if (innerInputRef !== undefined) (innerInputRef as any).current = ref;
                }}
                type='text'
                value={searchText}
                placeholder={placeholder}
                onChange={(e) => {
                  if (searchInputCustomPattern && !searchInputCustomPattern.test(e.currentTarget.value)) {
                    e.currentTarget.value = e.currentTarget.value.slice(0, -1);
                  }
                  setSearchText(e.currentTarget.value);
                  if (onChange)onChange(e.currentTarget.value);
                }}
              />
            </div>
          </StyledSearchHeaderFrame>
          {searchText && (
            <StyledSearchResultFrame>
              {searchLis}
            </StyledSearchResultFrame>
          )}
        </StyledSearchFrame>
      </Portal>
    </Frame>
  );
});

export default JDASearchInput;
