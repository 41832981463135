import React from 'react';
import classnames from 'classnames/bind';
import { useHistory } from 'react-router';
import RoutePaths from 'consts/RoutePaths';
import style from './index.module.scss';

const cx = classnames.bind(style);

const AllianceList = () => {
  const history = useHistory();
  const handleBtnClick = () => {
    history.push(RoutePaths.mypage_partnership_list(true));
  };
  return (
    <div className={cx('wrap')}>
      <div className={cx('mainTitle')}>
        많은 학교와 기관이 잡다에서
        <br />
        면접 연습을 진행하고 있습니다.
      </div>
      <div className={cx('btnWrap')}>
        <button onClick={handleBtnClick} className={cx('btn', 'btnBlack')}>제휴 리스트 확인하기</button>
      </div>
      <div className={cx('logoList')}>
        <ul className={cx('logoFlow')}>
          <li className={cx('logoImg')} aria-label='logo' />
          <li className={cx('logoImg')} aria-label='logo' />
          <li className={cx('logoImg')} aria-label='logo' />
        </ul>
      </div>
      <div className={cx('subInfo')}>2024년 7월 기준 제휴 학교/기관입니다.</div>
    </div>
  );
};

export default AllianceList;
