import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import ProfileItemTitle from 'components/_v2/profile/ProfileItemTitle';
import ProfileListCard from 'components/_v2/profile/ProfileListCard';
import { FileTag } from 'components/_v2/profile/education/ProfileEducationView';
import { Divider1G, Divider1G40 } from 'components/divider/Divider';
import { EducationLevelText, EducationSchoolText, EducationType, EducationTypeText } from 'consts/_v2/profile/EducationLevel';
import { IProfileEducation, IProfileEducationRs, MajorTypeText, ScoreType } from 'interfaces/_v2/profile/IProfileEducation';
import React, { FC } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
`;

const DescriptionCard = styled.div`
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  >h1{
    font: ${Fonts.B2_Bold};
    line-height: 20px;
    color:${colors.CG_90};
    margin-bottom: 8px;
  }
  >h2{
    font: ${Fonts.B2_Medium};
    color:${colors.CG_70};
  }
  .research-frame{
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    margin-top:27px;
    >div{
      width:100%;
      display: inline-block;
      white-space: pre-line;
      font: ${Fonts.B3_Medium};
      color:${colors.CG_70};
      margin-top: 19px;
    }
  }
`;

const ProfilePreviewEducation: FC<{ data: IProfileEducationRs}> = ({ data }) => {
  const { educations, educationLevel } = data;

  const getCardEndDate = (card:IProfileEducation) => {
    if (card.educationType === EducationType.GRADUATION) return card.endDate;
    if (card.educationType === EducationType.GRADUATION_CANDIDATE || card.educationType === EducationType.DROP) return `${card.endDate}(${EducationTypeText[card.educationType]})`;
    return EducationTypeText[card.educationType];
  };

  return (
    <Frame>
      <ProfileItemTitle icon='university' text='학력' tag={educationLevel !== null ? EducationLevelText[educationLevel] : undefined} />
      {educations.map((card: IProfileEducation, index: number) => (
        <ProfileListCard
          key={`card-list${card.schoolName}`}
          institution={card.schoolName}
          date={`${card.startDate} ~ ${getCardEndDate(card)}`}
          description={(
            <DescriptionCard>
              <h1>{EducationSchoolText[card.schoolLevel]}</h1>
              {card.profileMajors?.map((major, idx) => (
                <h2 key={`major-tag-${idx}`}>{`${MajorTypeText[major.majorType]} : ${major.majorName} ${major.scoreType === ScoreType.SCORE
                  ? (major.score === null ? `` : `(학점 ${major.score}/${major.perfectScore})`)
                  : (major.score === null ? `` : `(백분위 ${major.score})`)
                }`}
                </h2>
              ))}
              {card.profileResearches?.map((research, idx) => (
                <div className='research-frame' key={`research-frame-${idx}`}>
                  {research.attachFiles.map((file) => (
                    <FileTag key={`file-item-${file.fileUid}`} fileName={file.fileName} fileUid={file.fileUid} />
                  ))}
                  <div>
                    {research.description}
                  </div>
                </div>
              ))}
            </DescriptionCard>
              )}
          index={index}
          length={educations?.length || 0}
        />
      ))}
      <Divider1G40 />
    </Frame>

  );
};

export default ProfilePreviewEducation;
