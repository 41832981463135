import DisableScroll from 'components/common/DisableScroll';
import Portal from 'components/common/Portal';
import colors from '__designkit__/common/colors';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { FC } from 'react';
import styled from 'styled-components';

const JDAFullScreenModalFrame = styled.section<{ topMargin: number, blackYn: boolean }>`
  display:block;
  position:fixed;
  z-index:10002;
  width:100%;
  height:100%;
  background: ${(props) => (props.blackYn ? `${colors.CG_80}` : `${colors.WHITE_100}`)};
  top: ${(props) => (props.topMargin ? `${props.topMargin}px` : 0)};
  padding-bottom: calc(${(props) => (props.topMargin ? `${props.topMargin}px` : 0)} + env(safe-area-inset-bottom));
  left:0;
  overflow-y: auto;
`;

interface IJDNoHeaderFullModalProps extends IComponentProps {
    isOpen?: boolean;
    onClose?: () => void;
  topMargin?: number;
  black?: boolean;
  }

const JDNoHeaderFullModal :FC<IJDNoHeaderFullModalProps> = ({ isOpen, onClose, black = false, className, children, topMargin, ...props }) => (
  !isOpen ? <></>
    : (
      <Portal>
        <DisableScroll />
        <JDAFullScreenModalFrame blackYn={black} topMargin={topMargin || 0} {...props} className={`${className ?? ''}`}>
          {children}
        </JDAFullScreenModalFrame>
      </Portal>
    )
);

export default JDNoHeaderFullModal;
