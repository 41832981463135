import { useState } from 'react';

const { kakao } = window;

export default function KakaoMapScript(locationX: string, locationY: string, address: string) {
  const container = document.getElementById('kakaoMap');
  const options = {
    center: new kakao.maps.LatLng(33.450701, 126.570667),
    level: 3,
  };
  const map = new kakao.maps.Map(container, options);
  const coords = new kakao.maps.LatLng(locationY, locationX);
  // 결과값으로 받은 위치를 마커로 표시합니다
  const marker = new kakao.maps.Marker({
    map,
    position: coords,
  });

  const infowindow = new kakao.maps.InfoWindow({ zIndex: 1 });
  kakao.maps.event.addListener(marker, 'click', () => {
    // 마커를 클릭하면 장소명이 인포윈도우에 표출됩니다
    infowindow.setContent(`<div style="padding:5px;font-size:12px;">${address}</div>`);
    infowindow.open(map, marker);
  });
  // 인포윈도우로 장소에 대한 설명을 표시합니다
  //   const infowindow = new kakao.maps.InfoWindow({ content: '<div style="width:150px;text-align:center;padding:6px 0;">우리회사</div>' });
  //   infowindow.open(marker);

  // 지도의 중심을 결과값으로 받은 위치로 이동시킵니다
  map.setCenter(coords);
}

declare global {
    interface Window {
      kakao: any;
    }
}
