import React from 'react';
import classnames from 'classnames/bind';
import style from './index.module.scss';

const cx = classnames.bind(style);

const InterviewPracticeGateBanner = () => {
  return (
    <div className={cx('banner')}>
      <div className={cx('contents')}>
        <div className={cx('titleWrap')}>
          <div className={cx('desc')}>
            면접 연습, <span className={cx('import')}>JOBDA</span>에서 끝내자
          </div>
          <div className={cx('title')}>
            기업별 예상 면접 질문부터
            <br />
            면접 연습까지 한 번에!
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterviewPracticeGateBanner;
