import ChallengeAd from 'components/_v2/challenge/ChallengeAd';
import ChallengeList, { IChallengeList } from 'components/_v2/challenge/ChallengeList';
import Fonts from '__designkit__/common/fonts';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import ChallengeModel from 'models/_v2/ChallengeModel';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import ImgJoyEmpty from 'assets/_v2/challenge/img_character_joy_empty_soon.svg';
import colors from '__designkit__/common/colors';
import { ChallengeMetaTag } from 'components/_v2/_common/meta/MetaTag';

const NotChallenge = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  .joy-img{
    margin-top: 64px;
  }

  h1{
    font: ${Fonts.H5_Bold};
    margin-top: 32px;
    text-align: center;
    color:${colors.JOBDA_BLACK};
  }

  h2{
    font: ${Fonts.B2_Medium_P};
    margin-top: 8px;
    text-align: center;
    color:${colors.CG_70}
  }

`;

const Challenge: FC<IChallengeList> = ({ challengeModel = new ChallengeModel() }) => {
  useEffect(() => {
    const init = async () => {
      await challengeModel.loadChallenge();
    };
    init();
  }, []);
  return (
    <>
      <ChallengeMetaTag />
      {
      challengeModel.challenges.length > 0
        ? (
          <>
            <ChallengeAd />
            <ChallengeList />
          </>
        )
        : (
          <NotChallenge>
            <img src={ImgJoyEmpty} alt='joy' className='joy-img' />
            <h1>현재 등록된 채용이벤트가 없어요.</h1>
            <h2>채용 관련 이벤트가 생길 경우,<br />
              해당 페이지에서 확인하실 수 있어요.
            </h2>
          </NotChallenge>
        )
    }
    </>
  );
};

export default inject(injectStore.challengeModel)(observer(Challenge));
