import { ErrorMessage } from '@hookform/error-message';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import IconFile from 'assets/_v2/_common/input/icon_input_file.svg';
import IconBasket from 'assets/_v2/profile/icon_trashbin.svg';
import Loading from 'components/Loading';
import URIs from 'consts/URIs';
import useToast from 'hooks/useToast';
import { IAttachFile } from 'interfaces/_v2/profile/IProfileEducation';
import IComponentProps from 'interfaces/props/IComponentProps';
import { FC, useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import MobileStore from 'store/mobileStore';
import styled from 'styled-components/macro';
import FileUtil from 'utils/FileUtil';
import request from 'utils/request';

// 50MB
const LIMIT_FILE_SIZE: number = 52428800;

const Frame = styled.div`
  width:100%;
  .action-btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    background:${colors.CG_30};
    border-radius: 4px;

    >span{
      margin-left:16px;
      font: ${Fonts.B1_Medium};
      color:${colors.CG_60};
    }
    >div{
      display:flex;
      justify-content: center;
      align-items:center;
      border-radius: 4px 0px 0px 4px;
      transform: matrix(-1, 0, 0, 1, 0, 0);
      background:${colors.CG_80};
      width:48px;
      height:100%;
    }
    .img-file{
      transform: matrix(-1, 0, 0, 1, 0, 0);
    }
  }

  input[type="file"] {
      position: absolute;
      width: 0;
      height: 0;
      padding: 0;
      overflow: hidden;
      border: 0;
  }

  h3{
    margin-top:4px;
    margin-left:16px;
    font: ${Fonts.B3_Medium};
    line-height: 14px;
    color:${colors.CG_70};
  }
  
`;

const FileItem = styled.div < { size?: number } >`
    cursor: pointer;
    background: transparent;
    margin-top:4px;
    margin-left:16px;
    display:flex;
    align-items: center;

    .download-btn {
      margin-right: 4px;
      background: transparent;
      border: none;
      text-align: left;
      font: ${Fonts.B3_Medium};
      line-height: 14px;
      color:${colors.B_100};
      text-decoration-line: underline;
      max-width: 214px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      word-wrap: normal;
  }
   .clear-btn{
      background: transparent;
      width: 14px;
      height: 14px;
    }
`;

interface IJDInnerButtonInput extends IComponentProps {
  name: string;
  placeholder?: string;
  type?: string[];
  limitCount?: number;
  addType?: boolean;
}

const JDFileUpload: FC<IJDInnerButtonInput> = (props: IJDInnerButtonInput) => {
  const { name, placeholder, type, limitCount = 20, addType, ...rest } = props;
  const useFormed = useFormContext();
  const { control, register, watch, errors } = useFormed;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fileRef = useRef<HTMLInputElement>();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });
  const { setToastObject } = useToast();

  const uploadFile = async (file: File) => {
    try {
      const data = new FormData();
      data.append('authYn', 'false');
      data.append('file', file);
      const res = await request<IAttachFile>({
        method: 'post',
        url: URIs.post_file_upload,
        data,
      });
      return res;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const downloadFile = async (fileName: string, fileUid: string) => {
    try {
      if (MobileStore.isMobile) {
        setIsLoading(true);
      }

      const res = await request<ArrayBuffer>({
        method: 'get',
        url: URIs.get_file_download(fileUid),
        responseType: 'arraybuffer',
      });
      FileUtil.fileDownload(fileName, res, false, setToastObject, setIsLoading);
      return res;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const checkFileValidation = (file: File) => {
    if (file.size > LIMIT_FILE_SIZE) {
      alert(`파일 사이즈가 초과했습니다.`);
      return false;
    }
    if (type) {
      // eslint-disable-next-line array-callback-return, consistent-return
      const checkType = type.some((fileType: string) => {
        if (file.type === fileType) {
          return true;
        }
      });
      if (!checkType) {
        // type에 맞게 커스텀 가능.
        alert(`파일 확장자는 PDF, PPT만 가능합니다.`);
        return false;
      }
    }
    return true;
  };

  return (
    <Frame>
      {isLoading && <Loading />}

      <div className='action-btn' role='button' onClick={() => fileRef.current?.click()}>
        <span>{placeholder || '첨부파일을 추가해 주세요.'}</span>
        <div>
          <img className='img-file' src={IconFile} alt='파일' />
        </div>
      </div>
      <input
        id={`file-input-${name}`}
        name={name}
        ref={(innerRef) => {
          if (fileRef !== undefined) (fileRef as any).current = innerRef;
        }}
        //* capture 추가시 카메라창 화면으로 바로 전환됨
        // capture='camera'
        className={`${watch(name) ? 'value' : ''}`}
        type='file'
        onChange={async (e) => {
          const files = watch(name);

          if (files && files.length > limitCount - 1) {
            setToastObject({ isOpen: true, type: 'ERROR', message: `파일은 최대 ${limitCount}개까지 등록할 수 있습니다.` });
            return false;
          }
          if (e.target.files?.item(0)!) {
            if (checkFileValidation(e.target.files?.item(0)!)) {
              const res = await uploadFile(e.target.files?.item(0)!);
              if (res)
                append({ fileName: res?.fileName, fileUid: res?.fileUid });
            }
          }
        }}
        {...rest}
      />
      {
        addType ? (
          <h3>pdf,ppt,doc, hwp, xls, zip, jpg 파일 (50MB) 이하</h3>
        ) : (
          <h3>pdf, ppt 파일(50MB 이하)</h3>
        )
      }
      {fields.map((field, index) => (
        <FileItem
          size={String(watch(`${name}[${index}].fileName`)).length * 1.5 || 0}
          key={field.id}
        >
          <button
            className='download-btn'
            onClick={(e) => {
              e.preventDefault();
              downloadFile(field.fileName, field.fileUid);
            }}
          >
            {field.fileName}
          </button>
          <button
            className='clear-btn'
            onClick={() => {
              // 서버 삭제 기능 미지원
              remove(index);
            }}
          >
            <img
              src={IconBasket}
              alt='삭제'
            />
          </button>
          <input
            aria-hidden
            name={`${name}[${index}].fileName`}
            ref={register()}
            defaultValue={field.fileName}
          />
          <input
            aria-hidden
            name={`${name}[${index}].fileUid`}
            ref={register()}
            defaultValue={field.fileUid}
          />
        </FileItem>
      ))}
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <h4 className='message false'>{message}</h4>}
      />
    </Frame>
  );
};

export default JDFileUpload;
