import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import IComponentProps from 'interfaces/props/IComponentProps';
import { useFormContext } from 'react-hook-form';

const Frame = styled.div<{ width: string, disabled: boolean; }>`
  position: relative;
  width: ${({ width }) => width};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  color: ${({ disabled }) => (disabled ? Colors.CG_60 : Colors.JOBDA_BLACK)} !important;
`;

const InputBox = styled.label<{ disabled: boolean }>`
  position: relative;
  display: inline-block;
  width: 100%;
  height: fit-content;
  font: ${Fonts.B2_Medium};

  & > span.label {
    position: absolute;
    top: 50%;
    left: 16px;
    color: ${Colors.CG_60};
    transform: translateY(-50%);
    transition: .2s;
  }

  & > span.value {
    position: absolute;
    top: 50%;
    left: 16px;
    color: ${Colors.JOBDA_BLACK}; 
  }

  & > span.no-interaction {
    position: absolute;
    top: 50%;
    left: 16px;
    color: ${Colors.JOBDA_BLACK}; 
    transform: translateY(-50%);
  }

  & > div.selectBox {
    box-sizing: border-box;
    width: 100%;
    height: 46px;
    border: 1px solid ${Colors.CG_30};
    border-radius: 4px;
    background-color: ${Colors.CG_30};
    appearance: none;
    outline: none;

    &:focus {
      border: 1px solid ${Colors.JOBDA_BLACK};
      background-color: ${Colors.WHITE_100};
      outline: none;
    } 

    &::-webkit-contacts-auto-fill-button, &::-webkit-credentials-auto-fill-button {
      mask-size: 0;
      width: 0;
    }
  }

  & > div.icon {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    transition: .3s;

    &.focus {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  &.valid {
    & > span.label {
      transform-origin: top left;
      transform: scale(calc(10 / 12)) translateY(-50%) translateY(-12px);
    }
  }

  &.error {
    & > div.selectBox {
      border: 1px solid ${Colors.ERROR};
    }
  }
`;

const OptionBox = styled.div`
  position: absolute;
  top: 53px;
  min-width: 100%;
  width: fit-content;
  height: fit-content;
  max-height: 150px;
  border: 1px solid ${Colors.CG_40};
  border-radius: 4px;
  background-color: ${Colors.WHITE_100};
  overflow-y: scroll;
  z-index: 1;

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  & > div {
    display: flex;
    align-items: center;
    width: 100%;
    height: fit-content;
    padding: 8px 16px;
    font: ${Fonts.B2_Medium};
    color: ${Colors.JOBDA_BLACK};
    white-space: nowrap;

    &:active {
      background-color: ${Colors.CG_30};
    }
  }
`;

const GuideText = styled.div`
  padding: 7px 12px 0;
  font: ${Fonts.B3_Medium};
  color: ${Colors.CG_70};
`;

interface IJDInputSelectorProps extends IComponentProps {
  name: string;
  label: string;
  options: {
    label: string;
    value: any;
  }[];
  width?: string;
  guideText?: string;
  interaction?: boolean;
  disabled?: boolean;
}

const JDInputSelector: FC<IJDInputSelectorProps> = ({ name, label, options = [], width = '100%', guideText = '', interaction = true, disabled = false }) => {
  const formMethods = useFormContext();
  const { register, watch, setValue, formState } = formMethods;
  const { errors } = formState;

  const [isFocus, setIsFocus] = useState<boolean>(false);

  const onClickOption = (value: any) => {
    setValue(name, value);
    setIsFocus(false);
  };

  useEffect(() => {
    const event = () => {
      setIsFocus(false);
      document.removeEventListener('click', event);
    };
    if (isFocus) {
      document.addEventListener('click', event);
    }
  }, [isFocus]);

  return (
    <Frame width={width} disabled={disabled}>
      <input aria-label={label} name={name} ref={register} type='hidden' />
      <InputBox className={`${watch(name) && interaction ? 'valid' : ''} ${errors[name] ? 'error' : ''}`} disabled={disabled} onClick={() => setIsFocus(true)}>
        { interaction && <span className='label'>{ label }</span>}
        <div className='selectBox' role='menu' />
        { watch(name) && <span className={interaction ? 'value' : 'no-interaction'}>{ name === 'countryCode' ? `+ ${watch(name)}` : watch(name) }</span> }
        <Icon name='arrow-bottom' size={24} className={`icon ${isFocus ? 'focus' : ''}`} />
      </InputBox>
      { isFocus && (
        <OptionBox>
          { options.map((option) => <div role='menu' key={option.label} onMouseDown={(e) => e.preventDefault()} onClick={() => onClickOption(option.value)}>{ option.label }</div>) }
        </OptionBox>
      )}
      { guideText && <GuideText className='guide'>{ guideText }</GuideText> }
    </Frame>
  );
};

export default JDInputSelector;
