import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import IconSelfReport from 'assets/_v2/acc/icon_acc_self_report.svg';
import IconWarn from 'assets/_v2/acc/icon_acc_warn_red.svg';
import IconGood from 'assets/_v2/acc/icon_acc_good_green.svg';
import { AccSelfReportTaskTypeText } from 'consts/_v2/acc/AccApplyType';
import AccResultsModel from 'models/_v2/AccResultsModel';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';

const Frame = styled.div`
    padding: 32px 16px 28px 16px;
`;

const NameContent = styled.div<{unreliableYn:boolean}>` 
    display:flex;
    .name-text{
        margin-left: 18px;
        font: ${Fonts.B1_Bold};
        line-height: 22px;
        color:${colors.JOBDA_BLACK};
    }
    .result-text{
        margin-left: 18px;
        margin-top: 2px;
        font: ${Fonts.B2_Medium_P};
        color:${(props) => (props.unreliableYn ? `${colors.ERROR}` : `${colors.JOBDA_BLACK}`)}
    }
    .icon-results{
        width:48px;
        height:48px
    }
`;
const TitleText = styled.div`
    display: flex;
    flex-direction:column;
`;

const ResultFrame = styled.div`
  border:1px solid ${colors.CG_40};
  background : ${colors.JOBDA_WHITE};
  padding:20px 20px 20px 24px;
  margin-top: 20px;
  border-radius: 8px;
  .warn-text{
    font: ${Fonts.B3_Medium};
    line-height: 18px;
    color:${colors.ERROR};
  }
  .icon-warn{
    width:20px;
    height:20px;
    margin-right: 4px;
  }
  .icon-small-warn{
    width:16px;
    height:16px;
    margin-right: 4px;
  }
`;
const FlexBox = styled.div`
  display: flex;
`;
const ReportList = styled.div`
  display: flex;
  justify-content:space-between;
  margin-bottom: 12px;
  .report-title{
    font: ${Fonts.B2_Medium_P};
    color:${colors.CG_80};
  }
  .report-icon{
    width:20px;
    height:20px;
    margin-right: 4px;
  }
`;

interface IAccApplySelfReportProps {
  unreliableYn:boolean;
  accResultsModel? :AccResultsModel;
}
const AccApplySelfReport:FC<IAccApplySelfReportProps> = ({ unreliableYn, accResultsModel = new AccResultsModel() }) => {
  const { selfReport } = accResultsModel;
  const [unreliable, setUnreliable] = useState(false);

  useEffect(() => {
    setUnreliable(false);
    if (selfReport) {
      selfReport.results.map((data) => {
        if (data.unreliableYn) {
          setUnreliable(true);
        }
      });
    }
  }, [selfReport]);

  return (
    <Frame>
      <NameContent unreliableYn={unreliableYn}>
        <img src={IconSelfReport} className='icon-results' alt='icon' />
        <TitleText>
          <div className='name-text'>성향파악</div>
          {unreliableYn
            ? <div className='result-text'>보완점을 확인해보세요!</div>
            : <div className='result-text'>안정적으로 응시했어요!</div>}

        </TitleText>
      </NameContent>
      <ResultFrame>
        {selfReport && selfReport?.results.map((data) => (

          <ReportList key={`data-${data}`}>
            <div className='report-title'>{AccSelfReportTaskTypeText[data.type]}</div>
            <img src={data.unreliableYn ? `${IconWarn}` : `${IconGood}`} alt='icon' />
          </ReportList>
        ))}
        {unreliable
    && (
    <FlexBox>
      <img className='icon-small-warn' src={IconWarn} alt='icon' />
      <div className='warn-text'>성향 파악의 설문 항목 중 미응답하거나 응답속도에 이상이 있는 경우일 수 있어요. 자세한 내용이 궁금하시면 PC에서 결과표를 확인해보세요.</div>
    </FlexBox>
    )}

      </ResultFrame>
    </Frame>
  );
};

export default inject(injectStore.accResultsModel)(observer(AccApplySelfReport));
