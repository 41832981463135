import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';
import JDBaseModal, { IJDBaseModal } from 'components/_v2/_common/modals/JDBaseModal';
import { JDModalBasicFrame, JDModalDescription, JDModalFullButton, JDModalTitle } from 'components/_v2/_common/modals/JDModalComponents';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';

const Frame = styled.div`
  ${JDModalDescription} {
    margin-top: 8px;
    margin-bottom: 12px;
  }

  .descriptionsTag{
    margin-top: 8px;
    margin-bottom: 12px;
    font: ${Fonts.B1_Medium};
    color: ${Colors.CG_70};
    text-align: center;
    white-space: pre-line;

    a{
      text-decoration: underline;
    }

    button{
      text-decoration: underline;
    }
  }
`;

const JDSimpleInfoModal: FC<IJDBaseModal & {isButton?:boolean, dimmed?:boolean, title?: string, descriptions?: string, descriptionsTag?: ReactElement, btnText?: string, handleClick?: () => void, }> = (({ isButton = true, dimmed = false, isOpen, handleClick, btnText = '확인', onClickClose, title, descriptions, descriptionsTag, isBackContentClickable, isDimClickClose }) => (
  <JDBaseModal dimmed={dimmed} isOpen={isOpen} onClickClose={onClickClose} isBackContentClickable={isBackContentClickable} isDimClickClose={isDimClickClose}>
    <Frame>
      <JDModalBasicFrame>
        { title && <JDModalTitle>{title}</JDModalTitle> }
        { descriptions && <JDModalDescription>{descriptions}</JDModalDescription> }
        { descriptionsTag && descriptionsTag }
        {
          isButton
          && (
          <JDModalFullButton onClick={() => {
            if (handleClick) handleClick();
            if (onClickClose) onClickClose();
          }}
          >{btnText}
          </JDModalFullButton>
          )
        }

      </JDModalBasicFrame>
    </Frame>
  </JDBaseModal>
));
export default JDSimpleInfoModal;
