import JDBaseModal from 'components/_v2/_common/modals/JDBaseModal';
import { JDModalBasicFrame, JDModalDescription, JDModalFullButton, JDModalTitle } from 'components/_v2/_common/modals/JDModalComponents';
import JDNoHeaderFullModal from 'components/_v2/_common/modals/JDNoHeaderFullModal';
import React, { FC } from 'react';
import styled from 'styled-components';
import { MatchLoginConnectInfoModalProps } from './MatchLoginConnectInfoModal';

const Frame = styled.div`

    ${JDModalDescription} {
        margin-top: 8px;
    }
`;

const MatchLoginConnectCancelModal: FC<MatchLoginConnectInfoModalProps> = ({ isOpen, onClickClose }) => (
  <JDNoHeaderFullModal isOpen={isOpen} onClose={onClickClose}>
    <JDBaseModal dimmed isOpen={isOpen} onClickClose={onClickClose} isDimClickClose>
      <Frame>
        <JDModalBasicFrame>
          <JDModalTitle>계정 연동을 취소하였습니다.</JDModalTitle>
          <JDModalDescription>
            연동을 취소하더라도 수신 받은 알림톡이나 메일을 통해 다시 연동이 가능합니다.
          </JDModalDescription>
          <JDModalFullButton onClick={onClickClose}>확인</JDModalFullButton>
        </JDModalBasicFrame>
      </Frame>
    </JDBaseModal>
  </JDNoHeaderFullModal>
);
export default MatchLoginConnectCancelModal;
