import React, { FC } from 'react';
import styled from 'styled-components';
import IComponentProps from 'interfaces/props/IComponentProps';
import { ReactComponent as LogoSvg } from '__designkit__/assets/jobda_logo.svg';

interface ILogoProps extends IComponentProps {
    color?: 'white' | 'black';
    size?: number;
}

const Logo: FC<ILogoProps> = ({ color = 'white', size = 400 }: ILogoProps) => (
  <LogoContainer color={color} size={size}>
    <LogoSvg viewBox='0 0 400 90' />
  </LogoContainer>
);

export default Logo;

const LogoContainer = styled.div<ILogoProps>`
    width: fit-content;
    height: fit-content;

    & svg {
        width:${({ size }) => (size ? `${size}px` : '')};
        height:${({ size }) => (size ? `${(size * 9) / 40}px` : '')};
    }

    & path {
        fill: ${({ color }) => color || ''}
    }
`;
