import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import { lineClampBox } from 'consts/_v2/_common/style/mixins';
import { RecruitmentTypeText } from 'consts/_v2/position/PositionType';
import { JobPreviewPositionDetailDto } from 'interfaces/_v2/jobs/IJobPostingRs';
import React, { FC } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 16px;
  border: 1px solid ${colors.CG_40};
  border-radius: 6px;

  .career-type {
    font: ${Fonts.B4_Medium};
    color: ${colors.JOBDA_BLACK};
    width: 40px;
    flex-grow: 0;
  }

  .position-name {
    font: ${Fonts.B2_Bold};
    color: ${colors.JOBDA_BLACK};
    margin-left: 16px;
    flex-grow: 1;
    ${lineClampBox(1)}
  }
`;

const JobsPreviewPostingPositionCard: FC<{ position: JobPreviewPositionDetailDto }> = ({ position }) => (
  <Frame>
    <div className='career-type'>{RecruitmentTypeText[position.recruitmentType]}</div>
    <div className='position-name'>{position.positionName}</div>
    <Icon name='arrow-right' size={24} />
  </Frame>
);

export default JobsPreviewPostingPositionCard;
