import React, { useState } from 'react';
import classnames from 'classnames/bind';
import Colors from '__designkit__/common/colors';
import Icon from '__designkit__/icon/Icon';
import styles from './index.module.scss';
import SampleFullModal from './SampleFullModal';

const cx = classnames.bind(styles);

const Profile = () => {
  const [openSampleResultModal, setOpenSampleResultModal] = useState(false);

  return (
    <div className={cx('commonBox')}>
      {/* 이미지 슬라이드 제목 */}
      <div className={cx('mainDesc')}>
        <div className={cx('icon', 'folder')} />
        <span className={cx('text')}>어디 잘 쓴 이력서 하나 없냐고요?</span>
        <span className={cx('desc')}>여기 15개나 있습니다!</span>
        <div className={cx('star')} />
      </div>
      <div className={cx('basicTitle')}>
        <p className={cx('subTitle')}>실제 합격자의 프로필과<br />합격자&인담자 꿀팁 수록</p>
        <p className={cx('mainTitle')}>합격자 프로필 모음.ZIP</p>

        {/* 이력서 샘플보기 */}
        <button onClick={() => setOpenSampleResultModal(true)} className={cx('btnBasic')}>
          <span className={cx('title')}>합격자 프로필 샘플보기</span>
          <div className={cx('btnIcon')}>
            <Icon name='arrow-right' width={24} height={24} color={Colors.JOBDA_BLACK} />
          </div>
        </button>
      </div>

      <SampleFullModal isOpen={openSampleResultModal} onClose={() => setOpenSampleResultModal(false)} />
    </div>
  );
};

export default Profile;
