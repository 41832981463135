import React from 'react';
import IAccIntroduceBenefit from 'interfaces/IAccIntroduceBenefit';
import ImgBenefit1 from 'assets/img/ai/introduce/img_introduce_benefit_1.png';
import ImgBenefit2 from 'assets/img/ai/introduce/img_introduce_benefit_2.png';
import ImgBenefit3 from 'assets/img/ai/introduce/img_introduce_benefit_3.png';
import ImgBenefit4 from 'assets/img/ai/introduce/img_introduce_benefit_4.png';

const accIntroduceBenefits: IAccIntroduceBenefit[] = [
  {
    subTitle: '역량검사만의 차별점',
    title: `역량검사는 성과, 가치, 관계를\n통합적으로 분석합니다.`,
    description: <>
      <p>
        일반적인 인적성검사는 자기보고식 형태로 진행되는 성격과 지능검사라고 할 수 있습니다. 인적성검사는 응답자 스스로가 점수를 매기는 방식이므로 의도적이든 그렇지 않던 응답을 속일 수 있으며 사람마다 점수에 부여하는 의미가 일관적이지 않습니다.
      </p>
      <p>
        또한 성격유형검사로 많이 알려져 있는 MBTI, DISK, 애니어그램 등은 성격심리학적 관점에서 요인분석이라는 통계적 기법을 사용하여 인지영역에서의 경향성을 파악하는 일종의 성격검사입니다.
      </p>
      <p>
        하지만, 이러한 <em>지능과 성격은 성과를 내는 것과 연관이 거의 없습니다.</em> 지능의 경우는 일부 상관도가 존재하지만 성격의 경우는 신뢰할 수 있는 타당도가 부족하다는 것이  연구결과입니다.
      </p>
      <p>
        <em>역량검사는 이러한 기존 검사와 달리 생물학과 신경학의 연구결과를 바탕으로 알고리즘을 설계하고 전략게임을 통해 자극과 반응의 패턴을 분석함으로써 성과, 가치, 관계적 측면에서의 인지영역의 경향성과 비인지 영역의 속성을 통합적으로 파악하는 역량검사입니다.</em>
      </p>
    </>,
    image: ImgBenefit1, // TODO: TBD
  },
  {
    subTitle: '역량을 아는 것은 왜 중요할까요?',
    title: `우리의 성과를 만드는 힘은\n역량이기 때문입니다.`,
    description: <>
      <p>
        우리는 흔히 자신의 전공과 적성이 역량과 관련이 있을 것으로 오해하고 있습니다.
      </p>
      <p>
        역량검사는 이러한 기존 검사와 달리 생물학과 신경학의 연구결과를 바탕으로 알고리즘을 설계하고 전략게임을 통해 자극과 반응의 패턴을 분석함으로써 성과, 가치, 관계적 측면에서의 인지영역의 경향성과 비인지 영역의 속성을 통합적으로 파악하는 역량검사입니다.
      </p>
      <p>
        <em>신경과학에서는 ‘능력=역량X기술X지식’으로 표현하고 능력을 역량, 기술, 지식의 상호작용으로 성과를 만드는 힘이라고 정의합니다.</em>
      </p>
      <p>
        여기서 역량은 성과의 성능, 지식은 성과의 재료 그리고 기술은 역량의 강화와 지식의 숙련을 의미합니다. <em>능력을 발휘한다는 것은 자신의 핵심역량을 알고, 그 역량들이 환경과 긍정적 상호작용으로 잘 발현된다는 것을 의미합니다.</em>
      </p>
    </>,
    image: ImgBenefit2, // TODO: TBD
  },
  {
    subTitle: '성공의 시작은 무엇일까요?',
    title: `성공경험의 첫 시작, 나만의 역량을\n객관적으로 아는 것입니다.`,
    description: <>
      <p>
        일반적으로 적성은 어떤 일에 적합한 성질이나 성격으로 이해하고 있고, 적성으로 나에게 적합한  전공이나 직무를 선택하고 있습니다.
      </p>
      <p>
        그러나 <em>적성의 본질적 의미는 적합성이 아니라 적응성입니다.</em> 우리 인간은 모두 환경에 적응하며 생존하고 진화해온 존재입니다. 자신의 역량을 알고, 새로운 환경에 적응하며, 역량과 환경과의 상호작용으로 통해 성과를 만드는 성공경험을 쌓는 것이 무엇보다도 중요합니다.
      </p>
      <p>
        <em>성공경험이 우리의 역량을 강화 시키는 핵심이고 자신감, 존재감, 자존감을 고양시키는 원동력이며 자기실현을 통해 우리 삶을 행복하게 만드는 시작이기 때문입니다.</em>
      </p>
      <p>
        역량검사 리포트를 통해 자신의 역량을 객관적으로 알고 자신의 강점을 잘 개발하기 위해 학습하고 노력하는 것이 사회생활의 성공을 위한 출발입니다.
      </p>
    </>,
    image: ImgBenefit3, // TODO: TBD
  },
  {
    subTitle: '역량검사 리포트 어떻게 진화할까요?',
    title: `나만의 역량을 강화할 수 있는\n역량 함양 프로그램을 제공합니다.`,
    description: <>
      <p>
        앞서 이야기 드린 것 처럼, 역량검사 리포트는 나의 역량을 분석한 결과를 토대로 나의 강점과 약점에 대한 정보를 제공합니다.
      </p>
      <p>
        역량검사 리포트를 통해 <em>나의 역량을 객관적으로 이해하였다면 그 역량을 계속해서 ‘강화‘하고 ‘함양’하는 것이 중요합니다.</em> 핵심역량 중에서 우리가 학습과 습관을 통해 강화 시킬 수 있는 역량은 긍정, 전략, 성찰입니다.
      </p>
      <p>
        잡다에서는 추후 역량검사 리포트의 분석결과와 연계하여 어떤 역량을 어떻게 강화할 수 있는지에 대한 역량함양 프로그램을 기획하여 구직자 여러분들께 제공드릴 계획입니다.
      </p>
      <p>
        <em>비단 취업준비 뿐만 아니라 회사생활에서 좀 더 성과를 잘 내고 성장하고 싶은 분들과 이직을 고민하시는 분들, 진학과 전공 선택을 포함한  진로를 고민하시는 분들을 위해 잡다와 역량검사 결과표는 계속해서 진화 할 예정입니다.</em>
      </p>
    </>,
    image: ImgBenefit4, // TODO: TBD
  },
];

export default accIntroduceBenefits;
