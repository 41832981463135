import React, { FC } from 'react';
// import React, { FC, useEffect } from 'react';
import RoutePaths from 'consts/RoutePaths';
import IContextPageProps from 'interfaces/props/IContextPageProps';
import ILoginPageProps from 'interfaces/props/ILoginPageProps';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import Login from 'models/Login';
import { Redirect, Route, RouteProps, useLocation } from 'react-router';
// import { RouteAllowedForNotAgreedUser } from 'utils/RouteUtils';
import Loading from 'components/Loading';

interface IAuthRoute extends ILoginPageProps, IContextPageProps, RouteProps {}

const AuthRoute:FC<IAuthRoute> = ({ login = new Login(), context = new Context(), ...params }) => {
  const { initialized } = context;
  const { pathname, search } = useLocation();

  // TODO: 개인정보처리방침 재동의시 필요함ㄴ
  // useEffect(() => {
  //   if (login.userInfo && (!RouteAllowedForNotAgreedUser.includes(pathname) && !login.priavcyCollectionAgreed))
  //     login.forcedToGetAgree = true;
  // }, [login.priavcyCollectionAgreed, login, pathname]);

  if (!initialized)
    return <Loading />;
  if (!login.userInfo) {
    context.setRedirectUrl(`${pathname}${search}`);
    return <Redirect to={RoutePaths.login} />;
  }
  return <Route {...params} />;
};

export default inject('login', 'context')(observer(AuthRoute));
