import React, { FC, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Icon from '__designkit__/icon/Icon';
import Fonts from '__designkit__/common/fonts';
import colors from '__designkit__/common/colors';
import { Button, ButtonMode } from '__designkit__/button/Button';
import JDDimModal from 'components/_v2/_common/modals/JDDimModal';
import IComponentProps from 'interfaces/props/IComponentProps';
import { JDCheckBoxInput } from 'consts/_v2/_common/style/mixins';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import TermsModel from 'models/TermsModel';
import useToast from 'hooks/useToast';
import { SENSITIVE_TERMS_TYPE } from 'consts/SensitiveTermsType';

const bottomModalAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(calc(-100%));
  }
`;

const Frame = styled.div`
  position: absolute;
  top: 100%;
  width: 100%;
  height: fit-content;
  padding-bottom: env(safe-area-inset-bottom);
  border-radius: 12px 12px 0 0;
  background-color: ${colors.WHITE_100};
  animation: ${bottomModalAnimation} 0.3s 0s 1 ease-out forwards;
  z-index: 10005;

  & .open {
    transform: rotate(180deg);
  }
`;

const Header = styled.div`
  width: 100%;
  height: fit-content;
  padding: 17px;
  border-bottom: 1px solid ${colors.CG_40};
  font: ${Fonts.B1_Bold};
  text-align: center;
`;

const TermList = styled.div`
  width: 100%;
  height: fit-content;
  padding: 8px 32px 0;
`;

const TermItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0;
  font: ${Fonts.B3_Medium};

  > div {
    transition: .3s;

    & > input {
      ${JDCheckBoxInput}
    }

    & > span {
      margin-left: 8px;
    }
  }
`;

const BtnFrame = styled.footer`
  width: 100%;
  height: fit-content;
  padding: 16px;
  background-color: ${colors.WHITE_100};
`;

interface ISensitiveTermsModalProps extends IComponentProps {
  onClickComplete?: () => void;
  onClickClose?: () => void;
  termsModel?: TermsModel;
}

const SensitiveTermsModal: FC<ISensitiveTermsModalProps> = ({ onClickComplete, onClickClose, termsModel = new TermsModel() }) => {
  const [checkTerms, setCheckTerms] = useState<number[]>([]);
  const { setToastObject } = useToast();

  const check = (sn: number) => {
    if (checkTerms.includes(sn)) setCheckTerms(checkTerms.filter((termSn) => termSn !== sn));
    else setCheckTerms([...checkTerms, sn]);
  };

  const onClickConfirm = async () => {
    try {
      await termsModel.saveSensitiveTermsAgree({ termsSns: checkTerms });
      await termsModel.loadSensitiveTermsAgree();
      if (onClickComplete) onClickComplete();
    } catch (e) {
      const err = e as { response: { data: { message: string } } };
      setToastObject({ isOpen: true, type: 'ERROR', message: err.response.data.message, duration: 2000 });
    } finally {
      if (onClickClose) onClickClose();
    }
  };

  useEffect(() => {
    (async () => {
      const { terms } = await termsModel.loadTermsList(SENSITIVE_TERMS_TYPE.SENSITIVE_PRIVACY_POLICY);
      termsModel.setTermsList(terms);
    })();
  }, []);

  return (
    <JDDimModal onClickClose={onClickClose}>
      <Frame>
        <Header>
          서비스 이용 약관 동의
        </Header>
        <TermList>
          { Object.values(SENSITIVE_TERMS_TYPE).map((termType) => termsModel.termsList.map((term) => {
            if (termType === term.type) {
              return (
                <TermItem>
                  <div>
                    <input type='checkbox' checked={checkTerms.includes(term.sn)} onChange={() => check(term.sn)} />
                    <span>{ term.title }</span>
                  </div>
                  <Icon name='arrow-right' size={32} onClick={() => { termsModel.setTermDetail(term); }} />
                </TermItem>
              );
            }
            return <></>;
          }))}
          { termsModel.termsList.map((term) => (
            <TermItem>
              <div>
                <input type='checkbox' checked={checkTerms.includes(term.sn)} onChange={() => check(term.sn)} />
                <span>{ term.title }</span>
              </div>
              <Icon name='arrow-right' size={32} onClick={() => { termsModel.setTermDetail(term); }} />
            </TermItem>
          ))}
        </TermList>
        <BtnFrame>
          <Button label='확인' buttonMode={ButtonMode.PRIMARY} size='large' onClick={onClickConfirm} disabled={!(checkTerms.length === termsModel.termsList.length)} />
        </BtnFrame>
      </Frame>
    </JDDimModal>
  );
};

export default inject(injectStore.termsModel)(observer(SensitiveTermsModal));
