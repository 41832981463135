import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { IDashBoardButtonProps } from 'components/jobMatch/dashboard/MatchDashBoardCompanyMessage';
import React, { FC } from 'react';
import styled from 'styled-components';

const ButtonOptionFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  
  >button {
    padding: 8px 16px;
    border-radius: 4px;
    width: 140px;
    height: 36px;
  }

  .button-accept {
    background: ${colors.JOBDA_BLACK};
    color: ${colors.WHITE_100};
    font: ${Fonts.B3_Bold};
    width: 100%;
    margin-bottom: 8px;
    &:disabled {
      opacity: 0.5;
    }
  }

  .button-refuse {
    background: ${colors.CG_30};
    color: ${colors.CG_70};
    font: ${Fonts.B3_Bold};
    border: 1px solid ${colors.CG_60};
    width: 100%;
    &:disabled {
      opacity: 0.5;
    }
  }
`;

const MatchDashBoardMatchOfferButton:FC<IDashBoardButtonProps> = ({ onClick, disabled }) => (
  <ButtonOptionFrame>
    <button id='matchAccept' className='button-accept' disabled={disabled} onClick={(e) => onClick(e)}>수락하기</button>
    <button id='matchRefuse' className='button-refuse' disabled={disabled} onClick={(e) => onClick(e)}>거절하기</button>
  </ButtonOptionFrame>
);

export default MatchDashBoardMatchOfferButton;
