export const initialValueOfEssential = {
  basicInfo: {
    title: '기본정보',
    completeYn: false,
    essential: true,
    isPC: false,
  },
  preferInfo: {
    title: '선호정보',
    completeYn: false,
    essential: true,
    isPC: false,
  },
  eduInfo: {
    title: '학력',
    completeYn: false,
    essential: true,
    isPC: false,
  },
};

export const initialValueOfAdditional = {
  careerInfo: {
    title: '경력',
    completeYn: false,
    essential: false,
    isPC: false,
  },
  projectInfo: {
    title: '프로젝트',
    completeYn: false,
    essential: false,
    isPC: false,
  },
  skillInfo: {
    title: '지식 · 기술',
    completeYn: false,
    essential: false,
    isPC: false,
  },
  experienceInfo: {
    title: '경험',
    completeYn: false,
    essential: false,
    isPC: false,
  },
  prizeInfo: {
    title: '수상내역',
    completeYn: false,
    essential: false,
    isPC: false,
  },
  militaryInfo: {
    title: '병역 · 취업 우대',
    completeYn: false,
    essential: false,
    isPC: false,
  },
  fileInfo: {
    title: '첨부자료',
    completeYn: false,
    essential: false,
    isPC: false,
  },
};
