import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface IJDLinkButtonProps {
  path: string;
  text: string;
  new?: boolean;
  onClose?: (e?: React.MouseEvent | null) => void;
}
const JDButtonFrame = styled.div`
  .link[data-disabled='true'] {
    pointer-events: none;
  }
  display: flex;
  align-items: center;
`;

export const NewButton = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 4px;
  background-color: ${colors.ERROR};
  width: 36px;
  border-radius: 8px;
  .text {
    font: ${Fonts.B4_Bold};
    color: ${colors.WHITE_100};
  }
`;
const JDLinkButton: FC<IJDLinkButtonProps> = (props) => {
  const { path, onClose, text } = props;

  return (
    <JDButtonFrame>
      <Link className='link' data-disabled={!path} to={path} onClick={onClose}>
        <button>
          {text}
        </button>
      </Link>
      {props.new && <NewButton><div className='text'>NEW</div></NewButton>}
    </JDButtonFrame>
  );
};

export default JDLinkButton;
