import JDALink from 'components/JDALink';
import JobsPostingPositionCard from 'components/_v2/jobs/JobsPostingPositionCard';
import RoutePaths from 'consts/RoutePaths';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import IJobsProps from 'interfaces/_v2/jobs/IJobsProps';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import JobsModel from 'models/_v2/JobsModel';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Button } from '__designkit__/button/Button';
import ImgEmptyJoy from 'assets/_v2/positionList/img_character_joy_empty.png';

const Frame = styled.div`
    padding: 0 16px;
    .title {
        font: ${Fonts.H5_Bold};
        color: ${colors.JOBDA_BLACK};
        margin-bottom: 16px;
    }

    .all-view-button {
        margin-top: 4px;
    }

    ul {
        li {
            margin-bottom: 8px;
        }
    }
`;

const EmptyFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
  gap:16px;
  .img-joy{
    width: 64px;
    height: 64px;
  }
  .text-empty{
    font:${Fonts.B1_Bold};
    color:${colors.CG_60};
  }
`;
const JobsPostingPositions: FC<IJobsProps> = ({ jobsModel = new JobsModel() }) => {
  const { jobPosting } = jobsModel;
  const [isAllViewOpen, setIsAllViewOpen] = useState<boolean>(false);

  return (
    <Frame>
      <p className='title'>모집 포지션</p>
      <ul>
        {
          jobPosting && jobPosting.positions.length > 0
            ? (
              <>
                {jobPosting?.positions.map((position, index) => (
                  (isAllViewOpen || index < 5) && (
                  <li key={position.positionSn}>
                    <JDALink to={RoutePaths.position_jd(position.positionSn)}>
                      <JobsPostingPositionCard position={position} />
                    </JDALink>
                  </li>
                  )
                ))}
              </>
            )
            : (
              <EmptyFrame>
                <img src={ImgEmptyJoy} alt='empty' className='img-joy' />
                <div className='text-empty'>모집 중인 포지션이 없습니다.</div>
              </EmptyFrame>
            )
        }

      </ul>
      {
              (!isAllViewOpen && (jobPosting && jobPosting.positions.length > 5)) && (
                <Button
                  outLined
                  onClick={() => setIsAllViewOpen(true)}
                  label={`포지션 ${jobPosting && jobPosting.positions?.length - 5}개 모두보기`}
                  size='small'
                  className='all-view-button'
                />
              )
          }

    </Frame>
  );
};

export default inject(injectStore.jobsModel)(observer(JobsPostingPositions));
