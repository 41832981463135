import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import IconDumy from 'assets/_v2/profile/img_profile_base_info_dumy.png';
import { RequiredText } from 'components/_v2/profile/ProfileItemTitle';
import ProfileBasicInfoModal from 'components/_v2/profile/baseInfo/ProfileBasicInfoModal';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import TermsModel from 'models/TermsModel';
import ProfileBasicInfoModel from 'models/_v2/profile/ProfileBasicInfoModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

const Frame = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
  padding: 10px 0 32px;
`;

const LeftFrame = styled.div`
  & > div.user-name {
    display: flex;
    align-items: center;
    font: ${Fonts.H2_Bold};

    & > span.career-type {
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
      height: fit-content;
      padding: 5px 8px;
      margin-left: 8px;
      font: ${Fonts.B3_Medium};
      color: ${Colors.WHITE_100};
      background-color: ${Colors.CG_60};
      border-radius: 4px;
    }
  }

  & > p {
    font: ${Fonts.B2_Medium};
    color:${Colors.JOBDA_BLACK};  
  }

  & > p.birth {
    font: ${Fonts.B1_Medium};
  }

  & > div.divid-line {
    width: 28px;
    height: 1px;
    
    background-color: ${Colors.JOBDA_BLACK};
  }
`;

const RightFrame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  & > img {
    width: 96px;
    height: 96px;
    border-radius: 96px;
    object-fit: cover;
  }
`;

const EditBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border: 1px solid ${Colors.CG_50};
  border-radius: 12px;
`;

interface IProfileBasicInfo {
  login?: Login;
  termsModel?: TermsModel;
  profileBasicInfoModel?: ProfileBasicInfoModel;
  suggestionModalCode: number;
}

const ProfileBasicInfoView: FC<IProfileBasicInfo> = ({ suggestionModalCode = 0, login = new Login(), termsModel = new TermsModel(), profileBasicInfoModel = new ProfileBasicInfoModel() }) => {
  const history = useHistory();
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);

  const onClickEditBtnHandler = async () => {
    if (!login.userInfo) {
      history.replace('/login');
    } else {
      setIsOpenEditModal(true);
    }
  };

  useEffect(() => {
    if (suggestionModalCode === 1) setIsOpenEditModal(true);
  }, [suggestionModalCode]);

  return (
    <Frame>
      <LeftFrame>
        <div className='user-name'>
          <span>{profileBasicInfoModel.baseInfo?.name || login.userDefault?.name }</span>
          <RequiredText>필수</RequiredText>
        </div>
        <SpacingBlock size={4} vertical />
        <p className='birth'>{profileBasicInfoModel.baseInfo?.birthDate || login.userDefault?.birthDate}</p>
        <SpacingBlock size={25} vertical />
        <div className='divid-line' />
        <SpacingBlock size={16} vertical />
        <p>
          { profileBasicInfoModel.baseInfo?.mobile
            ? profileBasicInfoModel.baseInfo?.mobile.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
            : login.userDefault?.mobile
              ? login.userDefault?.mobile.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
              : '' }
        </p>
        <SpacingBlock size={6} vertical />
        <p>{profileBasicInfoModel.baseInfo?.email || login.userDefault?.email}</p>
      </LeftFrame>
      <RightFrame>
        <EditBtn>
          <Icon
            name='add'
            size={16}
            onClick={onClickEditBtnHandler}
          />
        </EditBtn>
        <img
          className='img-profile'
          src={profileBasicInfoModel.baseInfo?.profileImageUrl || IconDumy}
          alt='프로필 사진'
          onError={(e) => {
            e.currentTarget.src = IconDumy;
          }}
        />
      </RightFrame>

      { isOpenEditModal && (
        <ProfileBasicInfoModal
          onClose={async () => {
            await Promise.all([profileBasicInfoModel.loadBaseInfo(), login.getMatchConfigStatus()]);
            setIsOpenEditModal(false);
          }}
        />
      )}
    </Frame>
  );
};

export default inject(injectStore.login, injectStore.termsModel, injectStore.profileBasicInfoModel)(observer(ProfileBasicInfoView));
