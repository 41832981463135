import React, { FC, useState } from 'react';
import Icon from '__designkit__/icon/Icon';
import ShareModal from 'components/_v2/_common/modals/ShareModal';

const SharePage: FC = () => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div>
      <br />
      공유버튼
      <div>
        <Icon name='share' size={40} onClick={() => setOpen(true)} />
      </div>
      <ShareModal open={open} onClose={() => setOpen(false)} />
    </div>
  );
};

export default SharePage;
