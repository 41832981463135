import { IconFile } from 'consts/assets/icons/iconPages';
import colors from '__designkit__/common/colors';
import Fonts, { fontStyle } from '__designkit__/common/fonts';
import IModalFrameProps from 'interfaces/IModalFrameProps';
import React, { FC } from 'react';
import styled from 'styled-components';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import { lineClampOnlyOne } from 'consts/_v2/_common/style/mixins';
import { JDAModalButton, JDAModalContentText, JDAModalContentDescription, JDAAlertContent, JDAModalButtonGhost } from './JDAModalComponents';
import JDAModalFrame from './JDAModalFrame';

const StyledModalContent = styled.div`
  text-align: center;
  padding: 24px;
  
  .title-text{
    font:${Fonts.H5_Bold};
    color:${colors.JOBDA_BLACK};
  }
  .desc-text{
    font:${Fonts.B2_Medium_P};
    color:${colors.CG_70};
  }
  .alert-text{
    font:${Fonts.B3_Medium};
    color:${colors.ERROR};
  }
  ${JDAModalContentText} {
    margin-top: 16px;
    margin-bottom: 8px;
  }

  ${JDAModalContentDescription} {
      margin-bottom: 8px;
      color: ${colors.CG_80};
  }

  ${JDAModalButton} {
    margin-bottom: 12px;
  }
`;

const FileListFrame = styled.div`
  width: 100%;
  max-height: 128px;
  overflow-y: scroll;
  padding: 12px;
  background-color: ${colors.CG_30};
  margin-bottom: 8px;

  ::-webkit-scrollbar {
        display: none;
    }
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-items: cen;
    li {
      display: flex;

      >span {
        font:${Fonts.B2_Medium_P};
        color: ${colors.CG_70};
        text-align: left;
        width: 210px;
        ${lineClampOnlyOne()}
        
      }

      img {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
    }

    li:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }
`;
const ButtonFrame = styled.div`
  display: flex;
  justify-content: space-between;
  width:100%;
  gap:8px;
  .submit-button{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:14px 22px;
    background-color: ${colors.JOBDA_BLACK};
    color:${colors.WHITE_100};
    font:${Fonts.B2_Bold};
    border-radius: 4px;
  }
  .cancel-button{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:14px 22px;
    background-color: ${colors.WHITE_100};
    color:${colors.JOBDA_BLACK};
    font:${Fonts.B2_Bold};
    border:1px solid ${colors.JOBDA_BLACK};
    border-radius: 4px;
  }
`;

interface IMatchMessageFileSubmitModalProps extends IModalFrameProps {
    onClickSubmit: () => void;
    files: string[];
}

const MatchMessageFileSubmitModal:FC<IMatchMessageFileSubmitModalProps> = (props) => {
  const { onClickSubmit, onClickClose, files, ...rest } = props;

  return (
    <JDAModalFrame {...rest}>
      <div style={{ width: 312 }}>
        <StyledModalContent>
          <SpacingBlock size={8} vertical />
          <div className='title-text'>파일 제출하시겠어요?</div>
          <SpacingBlock size={8} vertical />
          <div className='desc-text'>제출하는 내용을 잘 확인하셨나요?</div>
          <SpacingBlock size={4} vertical />
          <div className='alert-text'>* 최종 제출 이후에는 수정이 불가능합니다.</div>
          <SpacingBlock size={16} vertical />

          <FileListFrame>
            <ul>
              { files.map((fileName) => (
                <li key={fileName}><Icon name='file-add' className='icon' size={24} /><SpacingBlock size={8} horizontal /> <span>{fileName}</span></li>
              ))}
            </ul>
          </FileListFrame>
          <SpacingBlock size={28} vertical />

          <ButtonFrame>
            <div role='button' className='cancel-button' onClick={onClickClose}>취소</div>
            <div role='button' className='submit-button' id='acceptFileSubmit' onClick={() => onClickSubmit()}>제출</div>

          </ButtonFrame>

        </StyledModalContent>
      </div>
    </JDAModalFrame>
  );
};

export default MatchMessageFileSubmitModal;
