import { default as Colors, default as colors } from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import JDALink from 'components/JDALink';
import { DateUtil } from 'components/_v2/_common/input/datePicker/DateUtil';
import AccaTab from 'components/_v2/acca/gate/AccaTab';
import PaccGateContents from 'components/_v2/phs/gate/PaccGateContents';
import PaccGateDChampsRanking from 'components/_v2/phs/gate/PaccGateDChampsRanking';
import PaccGateDChampsTierProfile from 'components/_v2/phs/gate/PaccGateDChampsTierProfile';
import PaccGateDchampsBanner from 'components/_v2/phs/gate/PaccGateDchampsBanner';
import PaccGateDevPosition from 'components/_v2/phs/gate/PaccGateDevPosition';
import PaccGateService from 'components/_v2/phs/gate/PaccGateService';
import PaccGateSkillPosition from 'components/_v2/phs/gate/PaccGateSkillPosition';
import { CenterHomeType } from 'consts/_v2/CenterType';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import Login from 'models/Login';
import TermsModel from 'models/TermsModel';
import PaccDchampsModel from 'models/_v2/PaccDChampsModel';
import PaccGateModel from 'models/_v2/PaccGateModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import TodayRenderCheckUtil from 'utils/TodayRenderCheckUtil';

const Frame = styled.div`
  height: 100%;
  padding-bottom: 32px;
  background-color: ${Colors.JOBDA_BLACK};
`;
const CountFrame = styled.div`
  height: 100%;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .info-frame {
    display: flex;
    align-items: center;
  }
  .info-link {
    color: ${Colors.JOBDA_WHITE};
    font: ${Fonts.B3_Medium};
    border-bottom: 1px solid ${Colors.JOBDA_WHITE};
  }
  h3 {
    font: ${Fonts.B4_Medium};
    color: ${colors.CG_70};
    text-align: right;
    margin-bottom: 2px;
  }
`;
export interface IPaccGateProps {
    login?: Login;
    context?:Context;
    termsModel?:TermsModel;
    paccGateModel?: PaccGateModel;
    paccDChampsModel?:PaccDchampsModel;
}

const PaccGate: FC<IPaccGateProps> = ({ context = new Context(), paccDChampsModel = new PaccDchampsModel() }) => {
  const [isNudgeOpen, setIsNudgeOpen] = useState<boolean>(false);
  const keyName = 'dchamps_event_banner';

  const checkDChampsPeriod = async () => {
    if (paccDChampsModel.dChampsQualifiersPeriod && paccDChampsModel.dChampsQualifiersPeriod.length > 0) {
      const qualifiersPeriod = paccDChampsModel.dChampsQualifiersPeriod[0];
      const qualifiersPeriodStart = new Date(qualifiersPeriod.startDateTime);
      const qualifiersPeriodEnd = new Date(qualifiersPeriod.endDateTime);
      if (DateUtil.getWhetherIncludedInThePeriod(qualifiersPeriodStart, qualifiersPeriodEnd)) {
        paccDChampsModel.dChampsCountDate = qualifiersPeriodEnd;
        paccDChampsModel.dChampsCurrentMonth = qualifiersPeriod.eventType;
        paccDChampsModel.dChampsCurrent = true;
        paccDChampsModel.dChampsRunning = true;
      } else {
        paccDChampsModel.dChampsCurrent = false;
      }
    }
    if (paccDChampsModel.dChampsFinalsPeriod && paccDChampsModel.dChampsFinalsPeriod.length > 0) {
      if (paccDChampsModel.dChampsCurrentMonth !== paccDChampsModel.dChampsFinalsPeriod[0].eventType) {
        await paccDChampsModel.loadDChampsFinalsUserStatus(paccDChampsModel.dChampsFinalsPeriod[0].eventType);
        if (paccDChampsModel.dChampsFinalsYn) {
          const finalsPeriod = paccDChampsModel.dChampsFinalsPeriod[0];
          const finalsPeriodStart = new Date(finalsPeriod.startDateTime);
          const finalsPeriodEnd = new Date(finalsPeriod.endDateTime);
          paccDChampsModel.dChampsCurrentFinalsMonth = finalsPeriod.eventType;
          paccDChampsModel.dChampsCurrent = true;
          if (DateUtil.getWhetherIncludedInThePeriod(finalsPeriodStart, finalsPeriodEnd)) {
            paccDChampsModel.dChampsCountDate = finalsPeriodEnd;
            paccDChampsModel.dChampsFinalsRunning = true;
          } else {
            paccDChampsModel.dChampsCountDate = finalsPeriodStart;
          }
        }
      }
    }
  };

  useEffect(() => {
    const init = async () => {
      const notShow = await TodayRenderCheckUtil.getTodayRenderCheck(keyName);
      if (notShow) {
        setIsNudgeOpen(false);
      } else {
        setIsNudgeOpen(true);
      }
      context.centerHomeType = CenterHomeType.PACC;
      await paccDChampsModel.loadDChampsInfo();
      await checkDChampsPeriod();
    };
    init();
  }, []);
  return (
    <Frame>
      <AccaTab />
      {paccDChampsModel.dChampsCurrent && (
      <PaccGateDchampsBanner />
      )}

      <CountFrame>
        <div className='info-frame'>
          <JDALink className='info-link' to='https://www.jobda.im/info/361'>
            개발자검사 응시 전, 꼭 확인하세요
          </JDALink>
          <Icon name='arrow-right' color={Colors.JOBDA_WHITE} size={24} />
        </div>
      </CountFrame>
      <PaccGateService />
      {paccDChampsModel.dChampsCurrent && (
        <>
          <PaccGateDChampsTierProfile />
          <PaccGateDChampsRanking />
        </>
      )}
      <PaccGateSkillPosition />
      <PaccGateDevPosition />
      <PaccGateContents />
      {/* 다음 주차에 부활할 예정 */}
      {/* <PaccGateNudgeModal isOpen={isNudgeOpen} onClose={() => setIsNudgeOpen(false)} /> */}
    </Frame>
  );
};

export default inject(injectStore.context, injectStore.paccGateModel, injectStore.paccDChampsModel)(observer(PaccGate));
