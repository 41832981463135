import React from 'react';

const ArrowDown = () => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3.41228 5.00785L6.27061 9.84952C6.4252 10.1091 6.69645 10.2666 6.99978 10.2666C7.30311 10.2666 7.57436 10.112 7.72895 9.84952L10.5844 5.00785C10.7389 4.74827 10.7419 4.4216 10.5902 4.1591C10.4385 3.8966 10.1585 3.73327 9.8552 3.73327L4.13853 3.73327C3.8352 3.73327 3.5552 3.8966 3.40353 4.1591C3.25186 4.4216 3.25478 4.74827 3.40936 5.00785L3.41228 5.00785Z'
      fill='#315FC7'
    />
  </svg>
);
export default ArrowDown;
