import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import JDBaseModal, { IJDBaseModal } from 'components/_v2/_common/modals/JDBaseModal';
import { JDModalBasicFrame, JDModalDescription, JDModalFullButton, JDModalTipBlue, JDModalTitle } from 'components/_v2/_common/modals/JDModalComponents';
import RoutePaths from 'consts/RoutePaths';
import SupportMail from 'consts/_v2/GlobalText';
import React, { FC } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

const Frame = styled.div`

    ${JDModalTitle} {
        margin-top: 12px;
    }
    ${JDModalDescription} {
        padding: 16px;
        margin-top: 16px;
        margin-bottom: 12px;
        font: ${Fonts.B2_Medium_P};
        color: ${colors.JOBDA_BLACK};
        background-color: ${colors.CG_30};
        border-radius: 8px;

        >span {
          font: ${Fonts.B2_Bold_P};
          color: ${colors.B_90};
          margin: 0 4px;
        }
    }

    ${JDModalTipBlue} {
      color: ${colors.CG_60};
      font: ${Fonts.B3_Medium_P};
      
      span {
        color: ${colors.B_90};
        text-decoration: underline;
      }
    }

    ${JDModalFullButton} {
      margin-top: 20px;
    }
`;

interface IMatchResumeChangeModalProps extends IJDBaseModal {
    positionSn: number;
}
const MatchResumeChangeModal: FC<IMatchResumeChangeModalProps> = (({ isOpen, onClickClose, positionSn }) => {
  const history = useHistory();
  return (
    <JDBaseModal dimmed isOpen={isOpen} onClickClose={onClickClose} isDimClickClose isDimCloseBtn>
      <Frame>
        <JDModalBasicFrame>
          <JDModalTitle>지원 방식이<br /> 변경되었습니다.</JDModalTitle>
          <JDModalDescription>지원서 작성 <span>→</span> 내 프로필 제출</JDModalDescription>
          <JDModalTipBlue>작성 중이었던 지원서 내용이 필요하시면<br />
            <a href={`mailto:${SupportMail}`}><span>{SupportMail}</span></a>으로 문의주세요.
          </JDModalTipBlue>
          <JDModalFullButton onClick={() => {
            onClickClose();
            history.push(RoutePaths.position_jd(positionSn));
          }}
          >포지션에 지원하러 가기
          </JDModalFullButton>
        </JDModalBasicFrame>
      </Frame>
    </JDBaseModal>
  );
});

export default MatchResumeChangeModal;
