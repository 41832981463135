import { OnScrollClassName } from '__pc__/utils/hooks/useScrollInteraction/data/classData';
import { OnScrollStylesProps } from '__pc__/utils/hooks/useScrollInteraction/data/styleData';
import classnames from 'classnames/bind';
import React, { useEffect, useRef } from 'react';
import styles from './hookingMsg.module.scss';

const cx = classnames.bind(styles);

interface HookingProps {
  onScrollClassName: OnScrollClassName;
  onScrollStyles: (props: OnScrollStylesProps) => void;
  fadeIn?:boolean
}

const HookingMsg = ({ onScrollClassName, onScrollStyles: _ , fadeIn}: HookingProps) => {
  const baseLineRef = useRef<HTMLDivElement>(null);
  const step1Ref = useRef<HTMLDivElement>(null);
  const step2Ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setAnimation();
  }, []);

  const setAnimation = () => {
    onScrollClassName({ el: step1Ref.current, classNameData: getClassData(1) });
    onScrollClassName({ el: step2Ref.current, classNameData: getClassData(2) });
  };

  const getClassData = (step: number) => {
    const { current } = baseLineRef;

    return {
      baseLineEl: current,
      top: () => (current ? (current.getBoundingClientRect().height / 2) * (step - 1) - 500 : 0),
      bottom: () => (current ? (current.getBoundingClientRect().height / 2) * step - 500 : 0),
      className: cx('visible'),
      reverse: true,
    };
  };

  return (
    <div className={cx('commonBox', 'cardInner', { on: fadeIn })}>
      <div className={cx('stepArea')}>
        <div ref={baseLineRef} className={cx('stepInner')}>
          <div className={cx('screenArea')}>

            <div ref={step1Ref} className={cx('scrollStep')}>
              <div className={cx('mainTitle')} />
            </div>

            <div ref={step2Ref} className={cx('scrollStep', 'bgDissolve')}>
              <div className={cx('mainTitle')} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HookingMsg;
