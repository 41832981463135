export enum PstGradeResultType {
  TRY_AGAIN = 'TRY_AGAIN',
  BRONZE = 'BRONZE',
  SILVER = 'SILVER',
  GOLD = 'GOLD',
  PLATINUM = 'PLATINUM',
}

export enum PstGradeResultTypeText {
  TRY_AGAIN = 'Try again',
  BRONZE = 'Bronze',
  SILVER = 'Silver',
  GOLD = 'Gold',
  PLATINUM = 'Platinum',
}

export const PstGradeDescription = {
  V1: {
    TRY_AGAIN: `
    <p>Try again 등급은 문제 해결에 성공하지 못한 경우 주어지는 등급입니다.</p><br />
    이는 간단한 알고리즘 로직을 구현하는데 어려움이 있는 수준을 의미하며 개발 실무를 진행하기 위해선 개발 기초 지식에 대한 학습이 추가로 필요할 수 있습니다. <br /><br />
    기본적인 알고리즘 로직에 대한 학습을 한다면 개발 능력 향상에 도움이 될 것으로 보입니다. <br /><br />
    튜토리얼을 통해 연습 후 재응시해보세요.
  `,
    BRONZE: `
    <p>브론즈 등급은 하위권에 속하는 등급입니다.</p><br />
    이는 간단한 문제 해결을 위해 기본적인 알고리즘 로직을 구현할 수 있는 수준을 의미하며 개발 실무를 진행하기 위해선 개발 기초 지식 및 기술에 대한 학습이 추가로 필요할 수 있습니다. <br /><br />
    문제 해결 방안을 도출하고 이를 구현하는 능력을 더 키운다면 개발 능력 향상에 도움이 될 것으로 보입니다. <br /><br />
    더 높은 등급을 달성하기 위해 재도전 하시거나 튜토리얼을 통해 연습해 주세요.
  `,
    SILVER: `
    <p>실버 등급은 중하위권에 속하는 등급입니다.</p><br />
    이는 복합적인 상황보다는 단순한 상황에서의 문제를 해결할 수 있음을 의미하며 개발 실무를 진행할 때는 영향 범위가 적은 단위 기능 위주로 문제 해결을 시도할 수 있을 것으로 기대됩니다. <br /><br />
    여러 가지 조건들을 종합적으로 고려하는 능력을 더 키운다면 실무 개발 능력을 향상시키는 데 도움이 될 것으로 보입니다. <br /><br />
    더 높은 등급을 달성하기 위해 재도전 하시거나 튜토리얼을 통해 연습해 주세요.
  `,
    GOLD: `
    <p>골드 등급은 상위 20~50% 정도 수준에 속하는 등급입니다.</p><br />
    이는 복합적인 문제에 대한 해결 방안을 제시할 수 있음을 의미하며 비즈니스 영역에 대한 지식과 기술이 있다면, 안정적으로 개발 실무를 진행할 수 있을 것으로 기대됩니다.  <br /><br />
    문제 해결에 더욱 적합한 방안을 생각하고 구현하는 능력을 조금 더 키운다면 취업 과정에서 좋은 결과를 얻을 수 있을 것으로 기대됩니다. <br /><br />
    더 높은 등급을 달성하기 위해 재도전 하시거나 튜토리얼을 통해 연습해 주세요.
  `,
    PLATINUM: `
    <p>플래티넘 등급은 상위 20% 정도 수준에 속하는 등급입니다.</p><br />
    이는 복합적인 문제상황에서 핵심을 빠르게 파악하는 능력이 뛰어남을 의미하며 비즈니스 영역에 대한 지식과 기술이 있다면, 개발 실무 진행시 높은 완성도의 결과물을 기대할 수 있을 것으로 예상됩니다.  <br /><br />
    취업 과정에서 이러한 장점을 부각시킨다면 좋은 결과를 얻을 수 있을 것으로 기대됩니다. <br /><br />
    재도전하여 새로운 문제에도 도전해보세요.
  `,
  },
  V2: {
    TRY_AGAIN: `
    <p>Try again 등급은 문제 해결에 성공하지 못한 경우 주어지는 등급입니다</p><br />
    응시자님은 답안을 제출하지 않았거나 문제 해결에 성공하지 못하셨습니다.<br /><br />
    이는 간단한 알고리즘 로직을 구현하는데 어려움이 있는 수준을 의미하며 개발 실무를 진행하기 위해선 개발 기초 지식에 대한 학습이 추가로 필요할 수 있습니다. <br /><br /><br />
    기본적인 알고리즘 로직에 대한 학습을 한다면 개발 능력 향상에 도움이 될 것으로 보입니다. <br /><br /><br />
    튜토리얼을 통해 연습 후 재응시해보세요.
  `,
    BRONZE: `
    <p>브론즈 등급은 하위권에 속하는 등급입니다.</p><br />
    응시자님은 문제 해결에 성공하지 못하셨거나 간단한 조건이 주어진 낮은 단계의 문제만 해결하셨습니다.<br /><br />
    이는 간단한 문제 해결을 위해 기본적인 알고리즘 로직을 구현할 수 있는 수준을 의미하며 개발 실무를 진행하기 위해선 개발 기초 지식 및 기술에 대한 학습이 추가로 필요할 수 있습니다. <br /><br />
    문제 해결 방안을 도출하고 이를 구현하는 능력을 더 키운다면 개발 능력 향상에 도움이 될 것으로 보입니다. <br /><br />
    더 높은 등급을 달성하기 위해 재도전 하시거나 튜토리얼을 통해 연습해 주세요.
  `,
    SILVER: `
    <p>실버 등급은 중하위권에 속하는 등급입니다.</p><br />
    응시자님은 간단한 조건들이 주어진 낮은 단계의 문제는 해결했지만 조건들이 추가된 높은 단계의 문제는 해결하지 못했습니다.<br /><br />
    이는 복합적인 상황보다는 단순한 상황에서의 문제를 해결할 수 있음을 의미하며 개발 실무를 진행할 때는 영향 범위가 적은 단위 기능 위주로 문제 해결을 시도할 수 있을 것으로 기대됩니다. <br /><br />
    여러 가지 조건들을 종합적으로 고려하는 능력을 더 키운다면 실무 개발 능력을 향상시키는 데 도움이 될 것으로 보입니다. <br /><br />
    더 높은 등급을 달성하기 위해 재도전 하시거나 튜토리얼을 통해 연습해 주세요.
  `,
    GOLD: `
    <p>골드 등급은 상위 20~50% 정도 수준에 속하는 등급입니다.</p><br />
    응시자님은 단계별로 추가되는 조건들 중 핵심적인 요소들을 고려하여 어느 정도 문제를 해결했습니다.<br /><br />
    이는 복합적인 문제에 대한 해결 방안을 제시할 수 있음을 의미하며 비즈니스 영역에 대한 지식과 기술이 있다면, 안정적으로 개발 실무를 진행할 수 있을 것으로 기대됩니다.  <br /><br />
    문제 해결에 더욱 적합한 방안을 생각하고 구현하는 능력을 조금 더 키운다면 취업 과정에서 좋은 결과를 얻을 수 있을 것으로 기대됩니다. <br /><br />
    더 높은 등급을 달성하기 위해 재도전 하시거나 튜토리얼을 통해 연습해 주세요.
  `,
    PLATINUM: `
    <p>플래티넘 등급은 상위 20% 정도 수준에 속하는 등급입니다.</p><br />
    응시자님은 단계별로 추가되는 조건들을 대부분 고려하여 높은 수준으로 문제를 해결했습니다.<br /><br />
    이는 복합적인 문제상황에서 핵심을 빠르게 파악하는 능력이 뛰어남을 의미하며 비즈니스 영역에 대한 지식과 기술이 있다면, 개발 실무 진행시 높은 완성도의 결과물을 기대할 수 있을 것으로 예상됩니다.  <br /><br />
    취업 과정에서 이러한 장점을 부각시킨다면 좋은 결과를 얻을 수 있을 것으로 기대됩니다. <br /><br />
    재도전하여 새로운 문제에도 도전해보세요.
  `,
  },
};

export const PstGradeTip = {
  V1: `
    달성 등급은 점수 뿐만 아니라 응시자님과 같은 문제를 풀었던 사람들과 비교하여 계산됩니다. 따라서 점수가 같더라도 등급의 차이가 있을 수 있습니다.
  `,
  V2: `
    달성 등급은 점수 뿐만 아니라 응시자님과 같은 문제를 풀었던 사람들과 비교하여 계산됩니다. 따라서 점수가 같더라도 등급의 차이가 있을 수 있습니다.<br />
    (단, 만점자는 항상 최고 등급인 플래티넘을 받을 수 있도록 설계되어 있습니다.)
  `,
};
