/* eslint-disable react-hooks/exhaustive-deps */
import colors from '__designkit__/common/colors';
import { ReactComponent as IconReset } from 'assets/_v2/_common/input/icon_reset.svg';
import { ReactComponent as IconArrowDown } from 'assets/_v2/_common/input/icon_small_arrow_down.svg';
import JDSquareCheckBox from 'components/_v2/_common/input/JDSquareCheckBox';
import JDUnderBaseModal from 'components/_v2/_common/modals/JDUnderBaseModal';
import { CompanyFiltersProps } from 'components/_v2/company/CompanyDetailFilterTab';
import { StyledSelectModalFrame, StyledSmallSelector } from 'components/_v2/position/filter/PositionJobTitleFilter';
import { RecruitmentType, RecruitmentTypeText } from 'consts/_v2/position/PositionType';
import { StyledIconSVG } from 'consts/assets/icons/iconPages';
import { inject, observer } from 'mobx-react';
import CompanyModel from 'models/_v2/CompanyModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

const Frame = styled.div`

`;

const CompanyRecruitmentFilter: FC<CompanyFiltersProps> = ({ reloadPosition, filteredCount, companyModel = new CompanyModel() }) => {
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const useformed = useForm({ mode: 'all' });
  const { watch, reset } = useformed;
  const [currentSelectList, setCurrentSelectList] = useState<string[]>([]);
  const [saveSelectList, setSaveSelectList] = useState<string[]>([]);

  const onChangeCheckbox = () => {
    const list: [] = watch('recruitments');
    const filterdList = list.filter((value) => value !== false);
    setCurrentSelectList(filterdList);
    companyModel.searchFilters.recruitments = filterdList.join(',');
    reloadPosition();
  };

  const onClickReset = () => {
    reset({ recruitments: Object.values(RecruitmentType).map(() => false) });
    setCurrentSelectList([]);
  };

  const onClickFilter = () => {
    companyModel.searchFilters.recruitments = currentSelectList.join(',');
    reloadPosition();
    setSaveSelectList(currentSelectList);
    setIsSelectOpen(false);
  };

  useEffect(() => {
    if (companyModel.searchFilters.recruitments === '') setSaveSelectList([]);
  }, [companyModel.searchFilters.recruitments]);

  useEffect(() => {
    if (isSelectOpen) {
      const list = companyModel.searchFilters.recruitments!.split(',');
      const resetList = Object.values(RecruitmentType).map((type) => {
        const find = list.find((value) => value === type);
        if (find) return find;
        return false;
      });
      reset({ recruitments: resetList });
      if (list[0] === '')setCurrentSelectList([]);
      else setCurrentSelectList(list);
    }
  }, [isSelectOpen]);

  return (
    <Frame>
      <StyledSmallSelector
        count={saveSelectList.length}
        onClick={() => {
          setIsSelectOpen(true);
        }}
      >
        <span>경력{saveSelectList.length > 0 ? ` +${saveSelectList.length}` : ''}</span>
        <StyledIconSVG fill={colors.CG_70}><IconArrowDown /></StyledIconSVG>
      </StyledSmallSelector>
      <JDUnderBaseModal isOpen={isSelectOpen} onClickClose={() => setIsSelectOpen(false)}>
        <FormProvider {...useformed}>
          <StyledSelectModalFrame>
            <div className='title'>필터</div>
            <div className='content-frame'>
              <div className='group-frame'>
                <h1>경력선택 <span>(복수선택 가능)</span></h1>
                <div className='group-items'>
                  {
                    Object.values(RecruitmentType).map((type, index) => (
                      <JDSquareCheckBox
                        key={`check-option-recruitments-${type}`}
                        name={`recruitments.${index}`}
                        value={type}
                        label={<span>{RecruitmentTypeText[type]}</span>}
                        onChange={onChangeCheckbox}
                      />
                    ))
                  }
                </div>
              </div>
            </div>
            <div className='btn-frame'>
              <button className='btn-reset' onClick={onClickReset}><IconReset />필터 초기화</button>
              <button
                className='btn-search'
                onClick={onClickFilter}
              >{`검색(${filteredCount()})건`}
              </button>
            </div>
          </StyledSelectModalFrame>
        </FormProvider>
      </JDUnderBaseModal>
    </Frame>
  );
};

export default inject(injectStore.companyModel)(observer(CompanyRecruitmentFilter));
