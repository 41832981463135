import LazyImage from 'components/common/LazyImage';
import colors from '__designkit__/common/colors';
import Fonts, { fontStyle } from '__designkit__/common/fonts';
import { IJDADeclarationBenefitData } from 'interfaces/IJDADeclarationData';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { FC } from 'react';
import styled from 'styled-components';

interface IDeclarationBenefitProps extends IComponentProps {
  benefit:IJDADeclarationBenefitData;
}

const Frame = styled.article`
  padding-bottom:32px;
  &:nth-of-type(2n) {
    background:${colors.JOBDA_WHITE};
  }
  * {
    white-space:pre-line;
  }
  .frame-title {
    margin:0 24px;
    padding-top:48px;
    padding-bottom:32px;
    border-bottom:1px solid ${colors.CG_40};
    h1 {
      margin-top:8px;
      ${fontStyle.HEADLINE4_SEMIBOLD};
      color:${colors.JOBDA_BLACK};
      line-height:36px;
    }
    h2 {
      margin-top:8px;
      font: ${Fonts.B1_Medium};
      color:${colors.CG_90};
      line-height:26px;
    }
  }
  .frame-content {
    padding-top:32px;
    margin:0 24px;
    border-bottom:1px solid ${colors.CG_40};
    * {
      font: ${Fonts.B1_Medium};
      color:${colors.CG_80};
      line-height:26px;
    }
    p {
      margin-top:20px;
      margin-bottom:32px;
      >strong{
        color: ${colors.c5EC7C3};
        font-weight:bold !important;
      }
    }
    h2 {
      ${fontStyle.BODY1_SEMIBOLD};
      color:${colors.G_200};
      line-height:28px;
      strong{
        color: ${colors.c5EC7C3};
        font-weight:bold !important;
        font-size:inherit;
      }
    }
  
  }
`;

const DeclarationBenefit:FC<IDeclarationBenefitProps> = ({ benefit: { headerImage, title, subtitle, paragraphs } }) => (
  <Frame>
    <div className='frame-title'>
      <LazyImage src={headerImage} />
      <h1>{title}</h1>
      <h2>{subtitle}</h2>
    </div>
    <div className='frame-content'>
      {paragraphs}
    </div>
  </Frame>
);

export default DeclarationBenefit;
