import IconGood from 'assets/_v2/acc/icon_acc_good_green.svg';
import IconVideo from 'assets/_v2/acc/icon_acc_video.svg';
import IconWarn from 'assets/_v2/acc/icon_acc_warn_red.svg';
import colors from '__designkit__/common/colors';
import { AccVideoSubTypeText, AccVideoType, AccVideoTypeText } from 'consts/_v2/acc/AccApplyType';
import Fonts from '__designkit__/common/fonts';
import { IAccVideoDetails } from 'interfaces/_v2/acc/IAccResultsRs';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import AccResultsModel from 'models/_v2/AccResultsModel';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
    padding: 32px 16px 28px 16px;
`;

const NameContent = styled.div<{unreliableYn:boolean}>` 
    display:flex;
    .name-text{
        margin-left: 18px;
        font: ${Fonts.B1_Bold};
        line-height: 22px;
        color:${colors.JOBDA_BLACK};
    }
    .result-text{
        margin-left: 18px;
        margin-top: 2px;
        font: ${Fonts.B2_Medium_P};
        color:${(props) => (props.unreliableYn ? `${colors.ERROR}` : `${colors.JOBDA_BLACK}`)}
    }
    .icon-results{
        width:48px;
        height:48px
    }
`;
const TitleText = styled.div`
    display: flex;
    flex-direction:column;
`;

const ResultFrame = styled.div`
  border:1px solid ${colors.CG_40};
  background : ${colors.JOBDA_WHITE};
  padding:20px 20px 20px 24px;
  margin-top: 20px;
  border-radius: 8px;
  .warn-text{
    font: ${Fonts.B3_Medium};
    line-height: 18px;
    color:${colors.ERROR};
  }
  .icon-warn{
    width:20px;
    height:20px;
    margin-right: 4px;
  }
  .icon-small-warn{
    width:16px;
    height:16px;
    margin-right: 4px;
  }
`;
const FlexBox = styled.div`
  display: flex;
`;
const ReportList = styled.div`
  display: flex;
  justify-content:space-between;
  margin-bottom: 12px;
  .report-title{
    font: ${Fonts.B2_Medium_P};
    color:${colors.CG_80};
  }
  .report-icon{
    width:20px;
    height:20px;
    margin-right: 4px;
  }
`;

interface IAccApplyVideoProps {
  unreliableYn:boolean
  accResultsModel? :AccResultsModel;

}

export const getAccVideoTitle = (title:string, sub:string, number:number) => {
  const realTitle = `${title} 면접`;
  if (title === '상황') {
    return realTitle;
  }
  if (title === '질문 선택') {
    return realTitle;
  }
  if (sub === '0') {
    return `${realTitle} - ${title} 질문 ${number}`;
  }
  return `${realTitle} - 관련 질문 ${number}-${sub}`;
};

const AccApplyVideo:FC<IAccApplyVideoProps> = ({ unreliableYn, accResultsModel = new AccResultsModel() }) => {
  const { video } = accResultsModel;
  const [unreliable, setUnreliable] = useState<boolean>(false);
  const [videoList, setVideoList] = useState<IAccVideoDetails[]>([]);

  useEffect(() => {
    setUnreliable(false);
    const basicExp:IAccVideoDetails[] = [];
    const situationExp:IAccVideoDetails[] = [];
    const basicValue:IAccVideoDetails[] = [];
    const basicFree:IAccVideoDetails[] = [];
    if (video) {
      video.details.map((data) => {
        if (data.videoErrorYn || data.voiceErrorYn) {
          setUnreliable(true);
        }
        if (AccVideoType[data.type] === 'VIDEO_BASIC_EXPERIENCE') {
          basicExp.push(data);
        } else if (AccVideoType[data.type] === 'VIDEO_BASIC_SITUATION') {
          situationExp.push(data);
        } else if (AccVideoType[data.type] === 'VIDEO_BASIC_VALUES') {
          basicValue.push(data);
        } else {
          basicFree.push(data);
        }
      });
      const total:IAccVideoDetails[] = [...basicExp, ...situationExp, ...basicValue, ...basicFree];
      setVideoList(total);
    }
  }, [video]);
  return (
    <Frame>
      <NameContent unreliableYn={unreliableYn}>
        <img src={IconVideo} className='icon-results' alt='icon' />
        <TitleText>
          <div className='name-text'>영상 분석</div>
          {unreliableYn
            ? <div className='result-text'>보완점을 확인해보세요!</div>
            : <div className='result-text'>안정적으로 응시했어요!</div>}

        </TitleText>
      </NameContent>
      <ResultFrame>
        {videoList && videoList.map((data) => (
          <ReportList>
            <div className='report-title'>{`${getAccVideoTitle(AccVideoTypeText[data.type], AccVideoSubTypeText[data.questionType], data.questionNum)}`}</div>
            <img src={!data.videoErrorYn && !data.voiceErrorYn ? `${IconGood}` : `${IconWarn}`} alt='icon' />
          </ReportList>
        ))}

        {unreliable
        && (
        <FlexBox>
          <img className='icon-small-warn' src={IconWarn} alt='icon' />
          <div className='warn-text'>이 표시가 있는 항목은 밝기나 소리 등의 이유로 분석하기 어려운 항목이에요. 자세한 내용이 궁금하시면 영상을 확인해보세요.</div>
        </FlexBox>
        )}

      </ResultFrame>
    </Frame>
  );
};

export default inject(injectStore.accResultsModel)(observer(AccApplyVideo));
