/* eslint-disable react/react-in-jsx-scope */
import { useCallback, useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import bell from 'assets/lottie/icon_bell.json';
import Icon from '__designkit__/icon/Icon';
import classnames from 'classnames/bind';
import useToast from 'hooks/useToast';
import { useHistory, useParams } from 'react-router';
import { deleteInterviewReservation, getInterviewReservation, getInterviewee, postInterviewReservation } from '__pc__/page/timeattack_marketer/fetch';
import { PostSignInRs } from '__pc__/page/timeattack_marketer/types';
import TodayRenderCheckUtil from 'utils/TodayRenderCheckUtil';
import JDALink from 'components/JDALink';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

const CardImg = () => {
  const [reservationCount, setReservationCount] = useState(0);
  const [isMyReservation, setIsMyReservation] = useState(false);
  const [src, setSrc] = useState('');
  const [portfolioSrc, setPortfolioSrc] = useState('');
  const { userSn: code } = useParams<{ userSn: string }>();
  const history = useHistory();
  const { setToastObject } = useToast();

  const fetchUserInfo = useCallback(async () => {
    if (!code) return;

    try {
      const res = await getInterviewee();
      if (!res) return;

      const user = res.find((item) => item.code === code);
      if (!user) return;

      setSrc(user.profileUrl);
      user.portfolioUrl && setPortfolioSrc(user.portfolioUrl);
    } catch (e) {
      console.error(e);
    }
  }, [code]);

  const fetchData = useCallback(async (intervieweeCode: string, companyCode: string) => {
    try {
      const res = await getInterviewReservation({ 'interviewee-code': intervieweeCode ?? '' });

      if (!res) return;
      setReservationCount(Math.max(3 - res.length, 0));
      setIsMyReservation(res?.some((item) => item.companyCode === companyCode));
    } catch (e) {
      setReservationCount(0);
      console.error(e);
    }
  }, []);

  const handleReservation = async () => {
    if (!code || (!isMyReservation && reservationCount === 0)) return;
    const companyInfo = await TodayRenderCheckUtil.getValue<PostSignInRs[number]>('company-info');
    if (!companyInfo) return;

    if (isMyReservation) {
      deleteInterviewReservation(
        {
          'company-code': companyInfo?.code ?? '',
          'interviewee-code': code,
        },
        {
          onSuccess: () => {
            fetchData(code, companyInfo.code);
            setToastObject({ type: 'ERROR', isOpen: true, message: '면접 예약이 취소되었습니다.' });
          },
          onError: (e) => {
            if (e.message === 'TIMEOUT_ERROR') {
              setToastObject({ isOpen: true, type: 'ERROR', message: '면접 예약 시간이 지났습니다.' });
              return;
            }
            setToastObject({ type: 'ERROR', isOpen: true, message: '면접 예약 취소에 실패했습니다.' });
            fetchData(code, companyInfo.code);
          },
        },
      );
    } else {
      postInterviewReservation(
        {
          'company-code': companyInfo.code,
          'interviewee-code': code,
        },
        {
          onSuccess: () => {
            fetchData(code, companyInfo.code);
            setToastObject({
              type: 'SUCCESS',
              isOpen: true,
              message: '면접 예약이 완료되었습니다.',
              subMessage: '인재를 모두 선택했다면, 면접 예약 확정하기 버튼을 눌러주세요.',
            });
          },
          onError: (e) => {
            if (e.message === 'TIMEOUT_ERROR') {
              setToastObject({ isOpen: true, type: 'ERROR', message: '면접 예약 시간이 지났습니다.' });
              return;
            }
            setToastObject({ type: 'ERROR', isOpen: true, message: '면접 예약에 실패했습니다.' });
            fetchData(code, companyInfo.code);
          },
        },
      );
    }
  };

  useEffect(() => {
    const asyncInit = async () => {
      const companyInfo = await TodayRenderCheckUtil.getValue<PostSignInRs[number]>('company-info');
      if (!companyInfo) {
        history.push('/event/timeattack_marketer');
        return;
      }
      if (!code) return;

      fetchUserInfo();
      fetchData(code, companyInfo.code);
    };
    asyncInit();
  }, [code, history, fetchData, fetchUserInfo]);

  useEffect(() => {
    const asyncInit = async () => {
      const companyInfo = await TodayRenderCheckUtil.getValue<PostSignInRs[number]>('company-info');
      if (!companyInfo || !code) {
        return;
      }
      fetchData(code, companyInfo.code);
    };

    window.addEventListener('focus', asyncInit);
    return () => {
      window.removeEventListener('focus', asyncInit);
    };
  }, [code, fetchData]);

  return (
    <div className={cx('resultInner')}>
      <div className={cx('result')}>
        {src && <img className={cx('resultImg')} src={src} alt='' />}

        {/* 개인 포트폴리오 영역 */}
        {!!portfolioSrc && <JDALink to={portfolioSrc} target='_blank' className={cx('portfolio')} />}

        {/* 로그인 바로가기 */}
        <a className={cx('moreInfo')} target='_blank' href='https://jain-membership.hri.link/login?serviceId=ATS' rel='noreferrer' />

        {/* 플로팅 버튼 */}
        <div className={cx('floatingButton')}>
          <div className={cx('inner')}>
            <div className={cx('text')}>
              <div className={cx('lottieWrap')}>
                <Lottie animationData={bell} loop autoplay />
              </div>
              <div className={cx('message')}>
                이제 <strong>{reservationCount}개</strong> 기업만이 면접 제안할 수 있습니다 (총 3회 중)
              </div>
            </div>
            <button
              className={cx('action')}
              disabled={!isMyReservation && reservationCount === 0}
              onClick={handleReservation}
            >
              {!isMyReservation && reservationCount === 0 ? (
                '면접 예약이 완료되었습니다.'
              ) : (
                <>
                  {isMyReservation ? '면접 예약 취소하기' : '지금 바로 면접 예약하기'}
                  <Icon name='arrow-right' size={24} />
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardImg;
