import URIs from 'consts/URIs';
import { ProfileType } from 'consts/_v2/profile/ProfileType';
import { IEventParticipationStatusRs } from 'interfaces/_v2/IEventChallenge';
import { action, observable } from 'mobx';
import { request } from 'utils/request';

export default class EventChuseokModel {
    @observable eventParticipationStatus:IEventParticipationStatusRs | null = null;
    @observable isProfileCompleted:boolean = false;
    @observable isAccCompleted:boolean = false;
    @observable isPaccCompleted:boolean = false;
    @observable profileType:ProfileType | null = null;
    @action
    loadEventsParticipationStatus = async (eventType:string) => {
      try {
        this.eventParticipationStatus = (await request<IEventParticipationStatusRs>({
          method: 'get',
          url: URIs.get_events_participation_status,
          params: { eventType },
        }));
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadEventsTalentPoolHoliday2023ResumeDownload = async () => {
      try {
        const res = await request<ArrayBuffer>({
          method: 'get',
          url: URIs.get_events_talent_pool_holiday_2023_resume_download,
          responseType: 'arraybuffer',
        });
        return res;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }

    @action
    setEventsTalentPoolHoliday2023Apply = async () => {
      try {
        const res = await request({
          method: 'post',
          url: URIs.post_events_talent_pool_holiday_2023_apply,
        });
        return res;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }

    @action
    loadFileSignedUrl = async (fileUid:string) => {
      try {
        const res = await request({
          method: 'get',
          url: URIs.get_file_signed_url(fileUid),
        });
        return res;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }
}
