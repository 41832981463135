import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { inject, observer } from 'mobx-react';
import MatchHomeDashboardModel from 'models/_v2/MatchHomeDashboardModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
    display: flex;
    flex-direction: column;
    z-index:1;
    justify-content: center;
    bottom: calc(140px + env(safe-area-inset-bottom));
    position: fixed;
    right: 16px;
    width: 160px;
    padding: 18px 16px;
    background-color: ${colors.WHITE_100};
    border: 1px solid ${colors.CG_40};
    border-radius: 8px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.12);
    li{
      &:not(:last-child){
        margin-bottom:24px;
      }
    }

    .text {
        font: ${Fonts.B2_Medium};
        color: ${colors.CG_70};
        white-space: nowrap;
    }

    &[aria-hidden=true] {
        visibility: hidden;
    }
`;

interface IMyPageSnb {
  isOpen: boolean;
  onClickClose: () => void;
  setHiddenModalOpen: (isOpen: boolean) => void;
  setApplyPdfModalOpen: (isOpen: boolean) => void;
  matchHomeDashboardModel?: MatchHomeDashboardModel;
}

const MyPageSnb: FC<IMyPageSnb> = ({ isOpen, onClickClose, setHiddenModalOpen, setApplyPdfModalOpen, matchHomeDashboardModel = new MatchHomeDashboardModel() }) => {
  const wrapperRef = useRef<any>(null);
  const [visibleOpen, setVisibleOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      setVisibleOpen(true);
    }
  }, [isOpen]);

  const useOutsideClicker = (ref: any) => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setVisibleOpen(false);
        onClickClose();
      }
    }
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  useOutsideClicker(wrapperRef);

  const handleClickHidden = () => {
    setHiddenModalOpen(true);
  };
  return (
    <>
      {visibleOpen && (
        <Frame ref={wrapperRef} aria-hidden={!isOpen}>
          <ul>
            <li onClick={() => setApplyPdfModalOpen(true)} role='presentation' className='text'>취업활동 증명서</li>
            <li onClick={handleClickHidden} role='presentation' className='text'>숨긴 내역 확인</li>
          </ul>
        </Frame>
      )}

    </>
  );
};

export default inject(injectStore.matchHomeDashboardModel)(observer(MyPageSnb));
