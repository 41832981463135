export const ADMIN_USER_NAME = '📢 JOBDA';

export const loginUserChattingListTab = [{ tabId: 0, tabName: '내 채팅' }, { tabId: 1, tabName: '인기 채팅' }];

export const ADMIN_META_DATA_TYPE = {
  REMIND_JOB_POSTING_DEADLINE_D_DAY: 'REMIND_JOB_POSTING_DEADLINE_D_DAY',
  REMIND_JOB_POSTING_DEADLINE_D_3: 'REMIND_JOB_POSTING_DEADLINE_D_3',
  REMIND_JOB_POSTING_DEADLINE: 'REMIND_JOB_POSTING_DEADLINE',
  REMIND_JOB_POSTING_REGISTERED: 'REMIND_JOB_POSTING_REGISTERED',
} as const;

export type adminMetaDataType = typeof ADMIN_META_DATA_TYPE[keyof typeof ADMIN_META_DATA_TYPE];

export const CHATTING_UTM = { jdPositionToChatting: '&utm_source=kakaotalk&utm_medium=button&utm_campaign=jobdescription&utm_content=join_chat' };

export const CHATTING_TODAY_RENDER_CHECK_STRING = {
  chattingRoomWarning: 'chattingRoomWarning',
  chattingInduction: 'chattingInduction',
  chattingAppInduction: 'chattingAppInduction',
};

export const STAR_CHANNEL_COUNT = 20;

export const CHATTING_REPORT_TYPE = {
  FALSE_INFORMATION: 'FALSE_INFORMATION',
  ABUSE_HATE_SPEECH: 'ABUSE_HATE_SPEECH',
  ADVERTISEMENT_SPAM: 'ADVERTISEMENT_SPAM',
  ILLEGAL_FRAUD: 'ILLEGAL_FRAUD',
  OTHER_REASON: 'OTHER_REASON',
} as const;

export type chattingReportType = typeof CHATTING_REPORT_TYPE[keyof typeof CHATTING_REPORT_TYPE];

export const CHATTING_REPORT_TYPE_TEXT: { [key: string]: string } = {
  FALSE_INFORMATION: '허위 사실 유포',
  ABUSE_HATE_SPEECH: '욕설, 혐오, 비방 및 갈등 조장',
  ADVERTISEMENT_SPAM: '광고, 스팸, 도배성 메세지',
  ILLEGAL_FRAUD: '불법, 사기 행위',
  OTHER_REASON: '기타 사유 (최소 10자)',
} as const;
