import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import React, { FC, TextareaHTMLAttributes } from 'react';
import styled from 'styled-components';

const Frame = styled.div<{ isLeftChildren: boolean, isRightChildren: boolean, isError: boolean, isSuccess: boolean, isDisabled: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'auto')};

  & > div:first-child {
    display: flex;
    align-items: center;
    gap: 4px;
    width: 100%;
    height: fit-content;
    padding-left: ${(props) => (props.isLeftChildren ? 12 : 14)}px;
    padding-right: ${(props) => (props.isRightChildren ? 12 : 14)}px;
    padding-top: 8px;
    padding-bottom: 12px;
    border: 1px solid ${(props) => (props.isError ? Colors.ERROR : props.isSuccess ? Colors.G_150 : Colors.CG_30)};
    border-radius: 4px;
    background-color: ${Colors.CG_30};

    & textArea {
      flex-grow: 1;
      width: 100%;
      height: fit-content;
      min-height: 4em;
      border: none;
      background-color: ${Colors.CG_30};
      font: ${Fonts.B2_Medium_P};
      color: ${Colors.JOBDA_BLACK};

      &:focus {
        outline: none;
        background-color: ${Colors.WHITE_100};
      }

      &::placeholder {
        color: ${Colors.CG_60};
      }

      &::-webkit-contacts-auto-fill-button, &::-webkit-credentials-auto-fill-button {
        mask-size: 0;
        width: 0;
      }
    }

    &:focus-within {
      border: 1px solid ${Colors.JOBDA_BLACK};
      background-color: ${Colors.WHITE_100};
    }
  }

  & > div:last-child {
    width: 100%;
    height: fit-content;
    padding: 0 16px;
    font: ${Fonts.B3_Medium};
    color: ${(props) => (props.isError ? Colors.ERROR : props.isSuccess ? Colors.G_150 : Colors.CG_70)};
  }
`;

interface TextAreaFieldProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  leftChildren?: React.ReactNode; // 선택값, 지정하면 인풋 필드의 왼쪽에 표시됨
  rightChildren?: React.ReactNode; // 선택값, 지정하면 인풋 필드의 오른쪽에 표시됨
  caption?: string; // 선택값, 지정하면 인풋 필드 아래에 캡션을 표시함
  errorMessage?: string; // 선택값, 지정하면 인풋 필드의 테두리가 빨간색으로 표시되고 에러 메시지가 표시됨
  successMessage?: string; // 선택값, 지정하면 인풋 필드의 테두리가 초록색으로 표시되고 성공 메시지가 표시됨
  // 그 외의 input 필드가 받는 모든 props를 받을 수 있음
  // placeholder, value, onChange 등
}

const TextAreaField: FC<TextAreaFieldProps> = ({ leftChildren = undefined, rightChildren = undefined, caption = '', errorMessage = '', successMessage = '', ...props }) => (
  <Frame isLeftChildren={!!leftChildren} isRightChildren={!!rightChildren} isError={!!errorMessage} isSuccess={!!successMessage} isDisabled={!!props.disabled}>
    <div>
      { leftChildren }
      <textarea {...props} />
      { rightChildren }
    </div>
    <div>
      { errorMessage || successMessage || caption || '' }
    </div>
  </Frame>
);

export default TextAreaField;
