import React from 'react';
import styled from 'styled-components';
import spinner from 'assets/lottie/spinner_x64.json';
import Lottie from 'lottie-react';
import Portal from './common/Portal';

const Frame = styled.div`
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  display:flex;
  z-index: 99999;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  .spinner {
    width:64px;
    height:64px;
    filter:drop-shadow(0px 10px 15px rgba(0, 0, 0, 0.05));
    svg {
      filter:drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.05));
    }
  }
`;

const Loading = () => {
  return (
    <Portal>
      <Frame>
        <div className='spinner'>
          <Lottie animationData={spinner} loop autoplay />
        </div>
      </Frame>
    </Portal>
  );
};

export default Loading;
