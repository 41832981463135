import Colors from '__designkit__/common/colors';
import { fonts } from '__designkit__/common/fonts';

const aiIntroduceBenefitTitles = `
  h2 {
    ${fonts.NOTO_13_700};
    color: ${Colors.G_100};
    line-height:24px;
    letter-spacing:-0.41px;
    padding:0 24px;
    margin-top:24px;
  }
  h1 {
    ${fonts.NOTO_20_700};
    line-height:32px;
    color:${Colors.CG_80};
    /* white-space:pre-line; */
    padding:0 24px;
    margin-top:8px
  }
`;

export default aiIntroduceBenefitTitles;
