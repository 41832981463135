import { Button, ButtonMode } from '__designkit__/button/Button';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import { Divider12G } from 'components/divider/Divider';
import RoutePaths from 'consts/RoutePaths';
import { JDACheckBoxInput } from 'consts/style/mixins';
import useToast from 'hooks/useToast';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import React, { MouseEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

const Frame = styled.div`
  width: 100%;
  height: calc(100vh - 56px - env(safe-area-inset-bottom) - env(safe-area-inset-top));
`;

const StepFrame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const ContentFrame = styled.div`
  width: 100%;
  height: fit-content;

  &.cancel {
    padding: 16px 16px 0;
  }
`;

const FAQTitleFrame = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: fit-content;
  padding: 24px 16px;
  font: ${Fonts.B1_Bold};
  color: ${colors.JOBDA_BLACK};
`;

const CancelTitleFrame = styled.div`
  width: 100%;
  height: fit-content;
  font: ${Fonts.B1_Bold_P};
  color: ${colors.JOBDA_BLACK};

  b {
    color: ${colors.G_200};
  }
`;

const FAQList = styled.div`
  & div.item {
    & div.title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: fit-content;
      padding: 7px 12px 7px 20px;
      border-bottom: 1px solid ${colors.CG_40};
      font: ${Fonts.B2_Medium};
      color: ${colors.JOBDA_BLACK};

      & div:last-child {
        transition: .4s;
      }

      & div:last-child.active {
        transform: rotateX(180deg)
      }
    }

    & div.content {
      width: 100%;
      height: fit-content;
      padding: 20px;
      background-color: ${colors.JOBDA_WHITE};
      font: ${Fonts.B3_Medium_P};
      color: ${colors.CG_80};

      &.none {
        display: none;
      }

      & span {
        color: ${colors.B_100};
        text-decoration: underline;
      }
    }
  }
`;

const HistoryList = styled.div`
  & div.item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 4px;
    }

    & div.content {
      font: ${Fonts.B2_Bold};
      color: ${colors.JOBDA_BLACK};

      & div.sub {
        margin-top: 4px;
        font: ${Fonts.B4_Medium};
        color: ${colors.CG_70};
      }
    }
  }
`;

const CheckList = styled.div`
  width: 100%;
  height: fit-content;
  padding: 20px;
  border-radius: 8px;
  background-color: ${colors.CG_30};
  font: ${Fonts.B3_Medium};
  color: ${colors.JOBDA_BLACK};

  b {
    font: ${Fonts.B3_Bold};
  }

  & div.item {
    display: flex;
    align-items: flex-start;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    .content {
      font: ${Fonts.B3_Medium};
      color: ${colors.JOBDA_BLACK};

      b {
        font: ${Fonts.B3_Bold};
      }
    }
  }
`;

const BtnFrame = styled.div`
  width: 100%;
  height: fit-content;
  padding: 24px 20px;

  .check-message {
    display: flex;
    align-items: center;
    width: 100%;
    height: fit-content;

    & input {
      ${JDACheckBoxInput()}
    }

    .message {
      font: ${Fonts.B3_Medium};
      color: ${colors.CG_70};
    }
  }
`;

const DeleteAccountPage = ({ login = new Login() }) => {
  const history = useHistory();
  const { setToastObject } = useToast();

  const [step, setStep] = useState<number>(1); // 1: 첫번째 스텝 - FAQ 페이지, 2: 두번째 스텝 - 탈퇴 페이지
  const [activeFAQ, setActiveFAQ] = useState<number | null>(null); // 활성화 FAQ 아이템
  const [isConfirm, setIsConfirm] = useState<boolean>(false); // 탈퇴 버튼 활성화 여부

  const onClickFAQItem = (e: MouseEvent) => {
    const sn = Number(e.currentTarget.getAttribute('data-FAQ-sn'));
    setActiveFAQ(activeFAQ === sn ? null : sn);
  };

  const onClickCancelBtn = async () => {
    try {
      await login.deleteUser();
      await login.logout();
      history.push(RoutePaths.root);
      setToastObject({ isOpen: true, type: 'SUCCESS', message: '계정이 정상적으로 삭제되었습니다.', duration: 2000 });
    } catch (e) {
      console.error(e);
      setToastObject({ isOpen: true, type: 'ERROR', message: '계정 삭제에 실패했습니다. 잠시 후 다시 시도해 주세요.', duration: 2000 });
    }
  };

  useEffect(() => {
    (async () => {
      if (!login.userInfo) history.replace(RoutePaths.root);
      await login.getUserWorkLog();
    })();
  }, []);

  return (
    <Frame>
      { step === 1
        ? (
          <StepFrame>
            <ContentFrame>
              <FAQTitleFrame>
                <Icon name='error-filled' size={24} color={colors.ERROR} />
                <SpacingBlock size={8} />
                <div>잠깐! 혹시 떠나시는 이유가 아래에 있나요?</div>
              </FAQTitleFrame>
              <Divider12G />
              <FAQList>
                <div role='button' className='item' data-FAQ-sn={1} onClick={onClickFAQItem}>
                  <div className='title'>
                    개인정보(이메일, 휴대폰 번호 등)를 변경하고 싶어요.
                    <Icon name='arrow-bottom' size={40} className={activeFAQ === 1 ? 'active' : ''} />
                  </div>
                  <div className={`content ${activeFAQ !== 1 ? 'none' : ''}`}>이메일, 휴대폰 번호 등의 개인정보를 바꾸고 싶다면, <span role='link' onClick={() => { history.push(RoutePaths.profile); }}>내 프로필</span>에서 변경할 수 있어요.</div>
                </div>
                <div role='button' className='item' data-FAQ-sn={2} onClick={onClickFAQItem}>
                  <div className='title'>
                    역량검사 결과를 삭제하고 싶어요.
                    <Icon name='arrow-bottom' size={40} className={activeFAQ === 2 ? 'active' : ''} />
                  </div>
                  <div className={`content ${activeFAQ !== 2 ? 'none' : ''}`}>역량검사 결과를 삭제하고 싶다면 고객 문의 메일(<span>support@jobda.im</span>)을 통해 도와드릴게요.</div>
                </div>
                <div role='button' className='item' data-FAQ-sn={3} onClick={onClickFAQItem}>
                  <div className='title'>
                    역량검사 응시 횟수를 초기화하고 싶어요.
                    <Icon name='arrow-bottom' size={40} className={activeFAQ === 3 ? 'active' : ''} />
                  </div>
                  <div className={`content ${activeFAQ !== 3 ? 'none' : ''}`}>역량검사 응시 횟수는 한 달에 5회 제공되며, 매월 1일에 초기화돼요. 계정을 삭제하시더라도, 서비스 부정 참여 방지를 위해 본인인증 정보 및 역량검사 응시 횟수는 90일간 보관하고 있어요. 따라서 90일 안에 재가입하실 경우, 계정 삭제 이전의 역량검사 응시 횟수가 유지돼요.</div>
                </div>
                <div role='button' className='item' data-FAQ-sn={4} onClick={onClickFAQItem}>
                  <div className='title'>
                    알림이 너무 자주 와요.
                    <Icon name='arrow-bottom' size={40} className={activeFAQ === 4 ? 'active' : ''} />
                  </div>
                  <div className={`content ${activeFAQ !== 4 ? 'none' : ''}`}>채용 제안 알림을 받고 싶지 않다면, <span role='link' onClick={() => { history.push(RoutePaths.match_apply); }}>잡다 매칭</span>의 제안 받기 알림 설정을 변경해 주세요. SMS, 이메일 알림을 받고 싶지 않다면, <span role='link' onClick={() => { history.push(RoutePaths.mypage_notice); }}>알림 관리</span>에서 변경하실 수 있어요.</div>
                </div>
              </FAQList>
            </ContentFrame>
            <BtnFrame>
              <Button label='계속' buttonMode={ButtonMode.PRIMARY} size='large' onClick={() => setStep(2)} />
            </BtnFrame>
          </StepFrame>
        ) : (
          <StepFrame>
            <ContentFrame className='cancel'>
              <CancelTitleFrame>
                { login.userInfo?.name }님의<br /><b>소중한 기록</b>이 완전히 사라져요.
              </CancelTitleFrame>
              <SpacingBlock size={24} vertical />
              { login.userWorkLog && (
                <HistoryList>
                  { login.userWorkLog.accApplyCount > 0 && (
                    <div className='item'>
                      <Icon name='acca' size={40} />
                      <SpacingBlock size={12} />
                      <div className='content'>
                        <div className='title'>역량검사 응시 이력 {login.userWorkLog.accApplyCount}개 삭제</div>
                        <div className='sub'>(단, 본인인증 정보 및 역량검사 응시 횟수는 20일 보관 후 삭제)</div>
                      </div>
                    </div>
                  )}
                  { login.userWorkLog.jobApplicationCount > 0 && (
                    <div className='item'>
                      <Icon name='matching_person_green' size={40} />
                      <SpacingBlock size={12} />
                      <div className='content'>
                        <div className='title'>나의 지원 이력 {login.userWorkLog.jobApplicationCount}개 삭제</div>
                      </div>
                    </div>
                  )}
                  { login.userWorkLog.matchStatusCount > 0 && (
                    <div className='item'>
                      <Icon name='invitation-user' size={40} />
                      <SpacingBlock size={12} />
                      <div className='content'>
                        <div className='title'>나에게 온 열람, 관심, 채용 제안 알림 {login.userWorkLog.matchStatusCount}개 삭제</div>
                      </div>
                    </div>
                  )}
                  { login.userWorkLog.profileCompletionRate > 0 && (
                    <div className='item'>
                      <Icon name='resume-done-green' size={40} />
                      <SpacingBlock size={12} />
                      <div className='content'>
                        <div className='title'>내 프로필 작성 항목 {login.userWorkLog.profileCompletionRate}개 삭제</div>
                      </div>
                    </div>
                  )}
                </HistoryList>
              )}
              <SpacingBlock size={16} vertical />
              <CheckList>
                <div className='item'>
                  <Icon name='check' size={16} color={colors.JOBDA_BLACK} />
                  <SpacingBlock size={8} />
                  <div className='content'>
                    서비스 부정 참여 방지를 위해 본인인증 정보 및 역량검사 응시 횟수는 90일간 보관돼요. <b>따라서 90일 안에 재가입하실 경우, 계정 삭제 이전의 역량검사 응시 횟수가 유지돼요.</b>
                  </div>
                </div>
                <div className='item'>
                  <Icon name='check' size={16} color={colors.JOBDA_BLACK} />
                  <SpacingBlock size={8} />
                  <div className='content'>
                    지원하기 전에 프로필이 잘 작성되었는지 확인해 보세요.
                  </div>
                </div>
              </CheckList>
            </ContentFrame>
            <BtnFrame>
              <div className='check-message'>
                <input type='checkbox' id='check' onChange={() => setIsConfirm(!isConfirm)} />
                <SpacingBlock size={8} />
                <div className='message'>안내 사항을 모두 확인했으며, 계정 및 데이터를 완전히 삭제할게요.</div>
              </div>
              <SpacingBlock size={16} vertical />
              <Button label='계정 삭제하기' buttonMode={ButtonMode.PRIMARY} size='large' disabled={!isConfirm} onClick={onClickCancelBtn} />
            </BtnFrame>
          </StepFrame>
        )}
    </Frame>
  );
};

export default inject(injectStore.login)(observer(DeleteAccountPage));
