/* eslint-disable react-hooks/exhaustive-deps */
import { ReactComponent as IconReset } from 'assets/_v2/_common/input/icon_reset.svg';
import { ReactComponent as IconArrowDown } from 'assets/_v2/_common/input/icon_small_arrow_down.svg';
import { StyledSelectModalFrame, StyledSmallSelector } from 'components/_v2/position/filter/PositionJobTitleFilter';
import JDSquareCheckBox from 'components/_v2/_common/input/JDSquareCheckBox';
import JDUnderBaseModal from 'components/_v2/_common/modals/JDUnderBaseModal';
import { StyledIconSVG } from 'consts/assets/icons/iconPages';
import colors from '__designkit__/common/colors';
import { RecruitmentType, RecruitmentTypeText } from 'consts/_v2/position/PositionType';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import PositionListModel from 'models/_v2/PositionListModel';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { PositionFiltersProps } from '../PostionFilterTab';

const Frame = styled.div`

`;

const PositionRecruitmentsFilter: FC<PositionFiltersProps> = ({ reloadPosition, filteredCount, positionListModel = new PositionListModel() }) => {
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const useformed = useForm({ mode: 'all' });
  const { watch, reset } = useformed;
  const [currentSelectList, setCurrentSelectList] = useState<string[]>([]);
  const [saveSelectList, setSaveSelectList] = useState<string[]>([]);

  const onChangeCheckbox = () => {
    const list: [] = watch('recruitments');
    const filteredList:string[] = list.filter((value) => value !== false);
    const includeNew = filteredList.includes(RecruitmentType.NEW);
    const includeCareer = filteredList.includes(RecruitmentType.CAREER);

    if (includeNew || includeCareer) {
      filteredList.push(RecruitmentType.NEWORCAREER);
    } else {
      const index = filteredList.indexOf(RecruitmentType.NEWORCAREER);
      if (index > -1) filteredList.splice(index, 1);
    }

    // 중복 제거
    const uniqueFilteredList = Array.from(new Set(filteredList));

    setCurrentSelectList(uniqueFilteredList);
    positionListModel.positionRecruitSelectedList = filteredList;
    positionListModel.searchFilters.recruitments = filteredList.join(',');
    reloadPosition();
  };

  const onClickReset = () => {
    reset({ recruitments: Object.values(RecruitmentType).map(() => false) });
    setCurrentSelectList([]);
    positionListModel.positionRecruitSelectedList = [];
    positionListModel.searchFilters.recruitments = '';
    reloadPosition();
  };

  const onClickFilter = () => {
    const selectedRecruitmentTypes = currentSelectList;
    const includeNewOrCareer = selectedRecruitmentTypes.includes(RecruitmentType.NEW) || selectedRecruitmentTypes.includes(RecruitmentType.CAREER);
    if (includeNewOrCareer) {
      selectedRecruitmentTypes.push(RecruitmentType.NEWORCAREER);
    }
    const uniqueRecruitmentTypes = Array.from(new Set(selectedRecruitmentTypes));
    positionListModel.searchFilters.recruitments = uniqueRecruitmentTypes.join(',');

    reloadPosition();
    setSaveSelectList(currentSelectList);
    setIsSelectOpen(false);
  };

  useEffect(() => {
    if (positionListModel.searchFilters.recruitments === '') setSaveSelectList([]);
  }, [positionListModel.searchFilters.recruitments]);

  useEffect(() => {
    if (isSelectOpen) {
      const list = positionListModel.searchFilters.recruitments!.split(',');
      const resetList = Object.values(RecruitmentType).map((type) => {
        const find = list.find((value) => value === type);
        if (find) return find;
        return false;
      });
      reset({ recruitments: resetList });
      if (list[0] === '')setCurrentSelectList([]);
      else setCurrentSelectList(list);
    }
  }, [isSelectOpen]);

  return (
    <Frame>
      <StyledSmallSelector
        count={positionListModel.positionRecruitSelectedList.length}
        onClick={() => {
          setIsSelectOpen(true);
        }}
      >
        <span>경력{positionListModel.positionRecruitSelectedList.length > 0 ? ` +${positionListModel.positionRecruitSelectedList.length}` : ''}</span>
        <StyledIconSVG fill={colors.CG_70}><IconArrowDown /></StyledIconSVG>
      </StyledSmallSelector>
      <JDUnderBaseModal isOpen={isSelectOpen} onClickClose={() => setIsSelectOpen(false)}>
        <FormProvider {...useformed}>
          <StyledSelectModalFrame>
            <div className='title'>필터</div>
            <div className='content-frame'>
              <div className='group-frame'>
                <h1>경력선택 <span>(복수선택 가능)</span></h1>
                <div className='group-items'>
                  {
                    Object.values(RecruitmentType)
                      .filter((type) => type !== RecruitmentType.NEWORCAREER)
                      .map((type, index) => (
                        <JDSquareCheckBox
                          key={`check-option-recruitments-${type}`}
                          name={`recruitments.${index}`}
                          value={type}
                          label={<span>{RecruitmentTypeText[type]}</span>}
                          onChange={onChangeCheckbox}
                        />
                      ))
                  }
                </div>
              </div>
            </div>
            <div className='btn-frame'>
              <button className='btn-reset' onClick={onClickReset}><IconReset />필터 초기화</button>
              <button
                className='btn-search'
                onClick={onClickFilter}
              >{`검색(${filteredCount()})건`}
              </button>
            </div>
          </StyledSelectModalFrame>
        </FormProvider>
      </JDUnderBaseModal>
    </Frame>
  );
};

export default inject(injectStore.positionListModel)(observer(PositionRecruitmentsFilter));
