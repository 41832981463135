import React, { useState } from 'react';
import JDAFullScreenModal from 'components/JDAFullScreenModal';

const FullScreenModalPage = () => {
  const [fullScreenModalVisible, setFullScreenModalVisible] = useState<boolean>(false);

  return (
    <>
      <button onClick={() => setFullScreenModalVisible(true)}>풀스크린 모달</button>
      <JDAFullScreenModal isOpen={fullScreenModalVisible} onClose={() => setFullScreenModalVisible(false)}>
        풀스크린 모달이에요.
      </JDAFullScreenModal>
    </>
  );
};

export default FullScreenModalPage;
