import MatchHomeDashboardInfoModal from 'components/_v2/matchHome/dashboard/MatchHomeDashboardInfoModal';
import MatchHomeDashboardList from 'components/_v2/matchHome/dashboard/MatchHomeDashboardList';
import MatchDashBoardMessageModal from 'components/jobMatch/dashboard/MatchDashBoardMessageModal';
import { MatchTabType } from 'consts/_v2/match/MatchType';
import { MatchHomeDashboardType } from 'consts/_v2/matchHome/dashboard/MatchHomeDashboardType';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import MatchMessageStore from 'models/MatchMessageStore';
import MatchHomeDashboardModel from 'models/_v2/MatchHomeDashboardModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
    
`;

interface IMypageApplying {
    login?: Login;
    matchHomeDashboardModel?: MatchHomeDashboardModel;
    matchMessageStore?: MatchMessageStore;
  }

const MyPageApplying:FC<IMypageApplying> = ({ login = new Login(), matchMessageStore = new MatchMessageStore(), matchHomeDashboardModel = new MatchHomeDashboardModel() }) => {
  const { writingResumes } = matchHomeDashboardModel;
  useEffect(() => {
    const init = async () => {
      await matchHomeDashboardModel.loadWritingResumePositions();
    };
    init();
  }, []);

  return (
    <Frame>
      <MatchHomeDashboardList list={writingResumes?.writingResumes} type={MatchTabType.WRITING} />
      <MatchDashBoardMessageModal />

      <MatchHomeDashboardInfoModal
        isOpen={matchHomeDashboardModel.infoModal.isOpen}
        onClickClose={() => {
          matchHomeDashboardModel.infoModal.isOpen = false;
        }}
      />
    </Frame>
  );
};

export default inject(injectStore.login, injectStore.matchHomeDashboardModel, injectStore.matchMessageStore)(observer(MyPageApplying));
