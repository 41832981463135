import React from 'react';
import LogoCard from './logoCard';
import contentsImg1 from '__pc__/page/accaIntroduce/@assets/MO_logo.png';
import contentsImg2 from '__pc__/page/accaIntroduce/@assets/gs.png';
import contentsImg3 from '__pc__/page/accaIntroduce/@assets/axa.png';
import contentsImg4 from '__pc__/page/accaIntroduce/@assets/hospital.png';
import classnames from 'classnames/bind';
import styles from './logoList.module.scss';
const cx = classnames.bind(styles);

interface LogoListProps {
  fadeIn: boolean;
}

const LogoList = ({ fadeIn }: LogoListProps) => {
  const IMG_PATH = '/img/v2/components/accaIntroduce';

  return (
    // 기업로고 리스트, 성공사례
    <div className={cx('sectionInner', 'grayBg', { on: fadeIn })}>
      <p className={cx('mainTitle')}>
        <span className={cx('mainSmall')}>
          이미 750여개의 기업들이
        </span>
        <br />
        JOBDA 역량검사로
        <br />
        인재를 채용하고 있어요.
      </p>
      <div className={cx('logoList')}>
        <img className={cx('logo')} src={contentsImg1} alt="기업로고" />
      </div>

      <div className={cx('companyList')}>
        <LogoCard
          logo={contentsImg2}
          review={'JOBDA 역량검사는 지원자의\n다양한 측면을 검증할 수 있어서\n훨씬 효과적이에요!'}
          reviewer={'GS리테일 인사총무부 선임'}
        />
        <LogoCard
          logo={contentsImg3}
          review={'학벌이나 스펙이 아닌\n‘역량 중심’으로\n인재를 선별할 수 있습니다'}
          reviewer={'AXA 손해보험 인사 책임매니저'}
        />
        <LogoCard
          logo={contentsImg4}
          review={'지원자의 숨겨진 능력까지\n알아볼 수 있어서\n역량검사를 적극 활용하고 있습니다.'}
          reviewer={'인천성모병원 HR팀장'}
        />
      </div>
    </div>
  );
};

export default LogoList;
