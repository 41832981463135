import React, { FC } from 'react';
import styled from 'styled-components';
import { NewButton } from 'components/_v2/_common/button/JDLinkButton';
import Icon from '__designkit__/icon/Icon';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { StyledIconSVG } from 'consts/assets/icons/iconPages';
import IComponentProps from 'interfaces/props/IComponentProps';
import { ReactComponent as IconMinus } from 'assets/_v2/_common/input/icon_minus.svg';

const Frame = styled.div<{ strong?: boolean, customUIYn?: boolean, isError: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${(props) => (props.customUIYn ? '100%' : '67px')};
  padding: ${(props) => (props.customUIYn ? '0 24px' : '22px 24px')};
  background: ${colors.WHITE_100};
  border: 1px solid ${({ isError }) => (isError ? colors.ERROR : colors.CG_50)};
  border-radius: 8px;

  .icon-left{
    margin-right:10px;
  }
  
  .strong-text{
    font: ${Fonts.B1_Bold};
    color:${colors.JOBDA_BLACK};
  }

  .weak-text{
    font: ${Fonts.B1_Medium};
    color:${colors.JOBDA_BLACK};  
  }
  
  .icon-right{
    margin-left:auto;
  }

  .children {
    margin-left: 16px;
  }

  &[aria-disabled=true] {
    opacity : 0.5;
  }
`;

interface IJDRegisterItemButton extends IComponentProps {
  title: string;
  strong?: boolean;
  mode?: 'SELECT' | 'DELETE' | 'COMPLETE';
  onClick?: (e: any) => void;
  onDelete?: () => void;
  icon?: any;
  disabled?: boolean;
  customUIYn?: boolean;
  new?: boolean;
  isError?: boolean;
}

const JDRegisterItemButton: FC<IJDRegisterItemButton> = ({ title, disabled, customUIYn, strong, mode = 'SELECT', onClick, onDelete, icon, isError = false, ...props }) => (
  <Frame
    aria-disabled={disabled}
    strong={strong}
    onClick={(e) => { if (mode !== 'DELETE' && onClick) onClick(e); }}
    customUIYn={customUIYn}
    isError={isError}
  >
    { customUIYn ? (
      <>
        {props.children}
      </>
    ) : (
      <>
        { icon && <div className='icon-left'>{icon}</div> }
        { strong
          ? <div className='strong-text'>{title}</div>
          : <div className='weak-text'>{title}</div> }
        { props.new && <NewButton><div className='text'>NEW</div></NewButton> }
        <div className='children'>{ props.children }</div>
      </>
    )}
    { !disabled
      ? mode === 'SELECT'
        ? <Icon name='arrow-right' className='icon-right' color={colors.JOBDA_BLACK} size={32} />
        : (
          <StyledIconSVG
            onClick={() => { if (onDelete) onDelete(); }}
            className='icon-right'
          ><IconMinus width={20} height={20} />
          </StyledIconSVG>
        )
      : <></>}
  </Frame>
);

export default JDRegisterItemButton;
