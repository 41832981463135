import RoutePaths from 'consts/RoutePaths';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { lineClampBox } from 'consts/_v2/_common/style/mixins';
import basicChannelLogo from 'assets/icon/icon_pages_empty_logo_error.svg';
import styled from 'styled-components';
import React, { FC } from 'react';
import JDALink from 'components/JDALink';
import { IInfoCardData } from 'interfaces/_v2/contents/IContents';

export const InfoContentsCardFrame = styled.div`
  display: flex;
  gap: 12px;
  padding: 16px 0;
  border-bottom: 1px solid ${colors.CG_30};


  .thumbnail{
    flex: none;
    width: 120px;
    height: 90px;
    border-radius: 10px;
    object-fit: cover;
    border: 1px solid ${colors.CG_30};
  }

  .desc {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;

    .intro {
      .title{
        font: ${Fonts.B4_Medium};
        color:${colors.CG_90};
      }

      .subtitle{
        margin-top: 4px;
        font: ${Fonts.B1_Bold};
        color:${colors.JOBDA_BLACK};
        ${lineClampBox(2)}
      }
    }
  }

  .hash-tag-frame{
    display: flex;
    justify-content: flex-start;
    
    .hash-tag{
        font: ${Fonts.B2_Medium};
        color:${colors.CG_60};
        ${lineClampBox(1)}
    }
  }
`;

const ContentBigCard: FC<{className?:string, item:IInfoCardData}> = ({ item }) => (
  <JDALink to={item.type === 'NEW_PAGE' ? `${item.link}` : RoutePaths.info_detail(item.id)}>
    <InfoContentsCardFrame>
      <img
        src={item.imageSrc ? item.imageSrc : basicChannelLogo}
        className='thumbnail'
        alt='img'
        onError={(e) => { e.currentTarget.src = basicChannelLogo; }}
      />
      <div className='desc'>
        <div className='intro'>
          <div className='title'>{item.category}</div>
          <div className='subtitle'>{item.title}</div>
        </div>
        <div className='hash-tag-frame'>
          <div className='hash-tag'>{item.subTitle}</div>
        </div>
      </div>
    </InfoContentsCardFrame>
  </JDALink>
);

export default ContentBigCard;
