import classnames from 'classnames/bind';
import Footer from 'components/_v2/_common/Footer';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import Login from 'models/Login';
import EventChallengeModel from 'models/_v2/event/EventChallengeModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import CommonUtils from 'utils/CommonUtils';
import ScrollUtil from 'utils/ScrollUtil';
import Catchphrase from './catchphrase';
import Challenge from './challenge';
import ChallengeMission1 from './challengeMission1';
import ChallengeMission2 from './challengeMission2';
import styles from './commonStyle.module.scss';
import ExtraBenefit from './extraBenefit';
import Interview from './interview';
import Intro from './intro';
import JobdaMatchBanner from './jobdaMatchBanner';

const cx = classnames.bind(styles);
export interface IEventChallengeProps {
  eventChallengeModel?:EventChallengeModel;
  login?:Login;
  context?:Context;
}
const EventChallenge:FC<IEventChallengeProps> = ({ eventChallengeModel = new EventChallengeModel() }) => {
  // 스크롤 이동을 위한 ref
  const missionOneRef = useRef<HTMLDivElement>(null);
  const missionTwoRef = useRef<HTMLDivElement>(null);
  const { search } = useLocation();
  const { move } = CommonUtils.parseQuery(search);

  useEffect(() => {
    const init = async () => {
      await eventChallengeModel.loadEventsTalentPoolUserStatus();
    };
    init();
  }, []);
  const scrollToRef = async () => {
    const refOffSetTop = missionTwoRef.current?.offsetTop;

    if (refOffSetTop) {
      ScrollUtil.scrollTo(0, refOffSetTop ? refOffSetTop - 100 : 0);
    }
  };
  useEffect(() => {
    if (move === 'accTest') {
      scrollToRef();
    }
  }, [missionTwoRef.current]);
  return (
    <>
      {/* 인트로 영역 */}
      <Intro />

      {/* 잡다 챌린지 설명 영역 */}
      <Challenge missionOneRef={missionOneRef} missionTwoRef={missionTwoRef} />

      {/* 잡다 챌린지 미션 1 영역 */}
      <div className={cx('wrap')} ref={missionOneRef}>
        <ChallengeMission1 />
      </div>
      {/* 잡다 챌린지 미션 2 영역 */}
      <div className={cx('wrap')} ref={missionTwoRef}>
        <ChallengeMission2 />
      </div>
      {/* 챌린지 플러스 - 잡다매칭 배너 영역 */}
      <JobdaMatchBanner />

      {/* 추가 증정 이벤트 */}
      <ExtraBenefit />

      {/* 합격자 인터뷰 영역 */}
      <Interview />

      {/* 캐치프레이즈 영역 */}
      <Catchphrase />
      <Footer isNavi={false} corpInfoFoled={false} />

    </>
  );
};

export default inject(injectStore.eventChallengeModel)(observer(EventChallenge));
