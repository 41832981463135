import colors from '__designkit__/common/colors';
import Fonts, { fonts } from '__designkit__/common/fonts';
import { JDAInputFrameBasic } from 'consts/style/mixins';

import IComponentProps from 'interfaces/props/IComponentProps';
import React, { forwardRef, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components/macro';

const Frame = styled.div<{ height?: number }>`
  margin-top:8px;
  margin-bottom:8px;
  width:100%;
  .input-frame{
    ${JDAInputFrameBasic()};
    ${(props) => (props.height ? `height:${props.height}px` : `height:200px`)};
    textarea:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px ${colors.JOBDA_WHITE} inset !important;
    }
    textarea:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0 30px ${colors.WHITE_100} inset !important;
    }

    textarea {
      background: inherit;
      font: ${Fonts.B1_Medium};
      height: 100%;
      width:100%;
      border: none;
      outline: none;
      padding: 16px 18px;
      resize:none;
      ::placeholder {
        color: ${colors.CG_60};
      }
    }
  }

  h3{
    margin-top:4px;
    margin-left:16px;
    ${fonts.NOTO_11_400};
    color:${colors.CG_70};
  }

`;
interface IJDADescriptionInput extends IComponentProps {
  name: string;
  maxLength: number;
  height?: number;
  customValidator: any;
  disabled?: boolean;
  className?: string;
}

const JDADescriptionInput = forwardRef((props: IJDADescriptionInput) => {
  const { name, height, maxLength, disabled, className, defaultValue, ...rest } = props;
  const useFormed = useFormContext();
  const textAreaRef = useRef<HTMLTextAreaElement>();
  const { register, errors, watch, setValue } = useFormed;

  return (
    <Frame className={`jda-input ${className || ''}`} height={height}>
      <div className={`input-frame ${errors[name] === undefined}`}>
        <textarea
          name={name}
          ref={function (innerRef) {
            if (textAreaRef !== undefined) (textAreaRef as any).current = innerRef;
            if (!innerRef?.value) {
              innerRef?.blur();
            }
            register(innerRef);
          }}
          className={`${watch(name) ? 'value' : ''}`}
          disabled={!!disabled}
          onChange={(e) => {
            if (e.currentTarget.value.length === 0) setValue(name, undefined);
          }}
          value={watch(name)}
          maxLength={maxLength}
          {...rest}
        />
      </div>
      <h3>{`최대 글자수 : ${textAreaRef.current?.textLength}/${maxLength}`}</h3>
    </Frame>
  );
});

export default JDADescriptionInput;
