import PositionRecruitmentType, { JobApplicationProcessType, MatchingOfferStatusType, PositionApplyType } from 'consts/PositionType';
import { BusinessSizeType } from 'consts/_v2/positionJd/BusinessSize';
import { IWelfareCategoryWithWelfareNameDto } from 'interfaces/_v2/positionJd/IWelfareCategoryWithWelfareNameDto';

export interface IPositionAttachFileDto {
    caption: string;
    fileUrl: string;
    fileUid: string;
    description: string;
    priority: number;
}

export interface IPositionDetailChallengeDto {
    challengeSn: number;
    challengeName: string;
    applicableSize: number;
    postStartDateTime: string;
    postEndDateTime: string;
    endYn: boolean;
}

export interface IPositionDetailAdditionalDto {
    locationCode: number;
    jobGroupName: string;
    jobGroupCode: number;
    jobTitleNames: string[];
    welfareKeywords: string[];
    welfareWithCategories: IWelfareCategoryWithWelfareNameDto[];
    challenge: IPositionDetailChallengeDto;
}

export interface IPositionDetailBasicDto {
    positionSn: number;
    positionName: string;
    recruitmentType: PositionRecruitmentType;
    address: string;
    detailAddress: string;
    minSalary: number;
    maxSalary: number;
    views: number;
    openingDateTime: string;
    createdDateTime: string;
    closingDateTime: string;
    jobDescription: string;
    averageReplyDays: number;
    images: IPositionAttachFileDto[];
    openYn: boolean;
    externalYn: boolean;
    applyType: PositionApplyType;
    recruitmentUrl: string;
    jobDescriptionImageUrl: string;
    isApplicable:boolean;
}

export interface IPositionDetailCompanyDto {
    companySn: number;
    companyName: string;
    homepageUrl: string;
    ceoName: string;
    startingSalary: number;
    averageSalary: number;
    numberOfEmployees: number;
    businessSize: BusinessSizeType;
    establishDate: string;
    location: string;
    revenue: number;
    openYn: boolean;
    logoImageUid: string;
    logoImageUrl: string;
    lastModifiedDate: string;
}

export interface IPositionApplyProfileComplete {
    profileRequiredCompleteYn: boolean, // 프로필 완성여부 (기본, 학력, 선호 )
    verifyYn: boolean; // 본인 인증
    matchingConditionYn: boolean; // 선호 정보
}

export interface IPositionDetailStateDto {
    bookmarkYn: boolean;
    jobApplicationProcessType: JobApplicationProcessType;
    matchingOfferStatus: MatchingOfferStatusType;
    decisionDueDateTime: string; // 응답 마감 기한
}

export interface IPositionExternalDetailDto {
    startDateTime: string; // 공고 시작 일자
    url: string; // 인사이트 공고 URL
}

export interface IPositionDetailJobPostingDto{
    jobPostingName:string;
    jobPostingSn:number;
    positionCount:number;
}
export default interface IPositionDetailRs {
    additionalInfo: IPositionDetailAdditionalDto;
    basicInfo: IPositionDetailBasicDto;
    companyInfo: IPositionDetailCompanyDto;
    stateInfo: IPositionDetailStateDto;
    externalInfo: IPositionExternalDetailDto;
    jobPosting:IPositionDetailJobPostingDto
}

export interface IPositionPreviewRs {
    additionalInfo: IPositionDetailAdditionalDto;
    basicInfo: IPositionDetailBasicDto;
    companyInfo: IPositionDetailCompanyDto;
}

export enum AccLiteStatusType {
    BEFORE_APPLY = 'BEFORE_APPLY',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED'
}

export interface IPositionAccStatusRs {
    accApplyRequiredYn:boolean;
    accLiteStatus:AccLiteStatusType
}
