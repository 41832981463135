import IconClose from 'assets/icon/icon_close_24.svg';
import JDBaseModal, { IJDBaseModal } from 'components/_v2/_common/modals/JDBaseModal';
import { JDModalBasicFrame, JDModalDescription, JDModalFullButton, JDModalTitle } from 'components/_v2/_common/modals/JDModalComponents';
import RoutePaths from 'consts/RoutePaths';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Frame = styled.div`
  .icon-close{
    position: absolute;
    top: 20px;
    right: 20px;
  }

  ${JDModalTitle} {
    margin: 8px 0;
  }
`;
const PositionJdFirstBookmarkModal:FC<IJDBaseModal> = ({ isOpen, onClickClose }) => (
  <JDBaseModal dimmed isOpen={isOpen} onClickClose={onClickClose} isDimClickClose>
    <Frame>
      <JDModalBasicFrame>
        <button className='icon-close' onClick={onClickClose}><img src={IconClose} alt='icon-close' /></button>
        <JDModalTitle>처음으로 관심있는 포지션을 저장하셨네요!.</JDModalTitle>
        <JDModalDescription>{`북마크한 포지션은 [지원현황] > 북마크에서`} <br />확인할 수 있어요.</JDModalDescription>
        <Link to={RoutePaths.mypage_bookmark} onClick={onClickClose}>
          <JDModalFullButton>북마크한 포지션 보기</JDModalFullButton>
        </Link>
      </JDModalBasicFrame>
    </Frame>
  </JDBaseModal>
);

export default PositionJdFirstBookmarkModal;
