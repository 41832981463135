import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { JDCheckBoxInput } from 'consts/_v2/_common/style/mixins';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { forwardRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import styled from 'styled-components/macro';

const Frame = styled.div`
    display: flex;
    align-items:center;
    label {
      display: flex;
      align-items: center;
      input[type=checkbox] {
        ${JDCheckBoxInput()};
      }
      span {
        font: ${Fonts.B2_Medium};
        line-height: 20px;
        color:${colors.CG_80};
        padding-left: 8px;
        vertical-align: -webkit-baseline-middle;
        em{
          font: ${Fonts.B2_Bold};
          color:${colors.JOBDA_BLACK}
        }
      }
    }

  &[aria-disabled=true]{
    span{
      opacity: 0.5;
    }
  }

`;

interface IJDCheckbox extends IComponentProps {
  name: string;
  value: string;
  label?: JSX.Element;
  onChange?: any;
  defaultChecked?: boolean;
}

const JDSquareCheckBox = forwardRef((props: IJDCheckbox) => {
  const { name, label, onChange, value, defaultChecked, className, disabled, ...rest } = props;
  const useFormed = useFormContext();
  const { control, setValue } = useFormed;
  const defaultValue = defaultChecked ? value : false;
  const { field } = useController({ name, control, defaultValue });
  return (
    <Frame className={`jd-input ${className || ''}`} aria-disabled={rest.disabled}>
      <label>
        <input
          {...field}
          type='checkbox'
          onChange={(e) => {
            if (e.target.checked) setValue(name, value);
            else setValue(name, false);
            onChange && onChange(e.target.checked);
          }}
          defaultChecked={defaultChecked}
          checked={field.value !== false}
        />
        {label}
      </label>
    </Frame>
  );
});

export default JDSquareCheckBox;
