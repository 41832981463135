import React from 'react';
import classnames from 'classnames/bind';
import style from './index.module.scss';

const cx = classnames.bind(style);

const LastBanner = () => {
  return (
    <div className={cx('banner')}>
      <div className={cx('contents')}>
        <div className={cx('titleWrap')}>
          잡다 면접연습
          <br />
          지금 바로 시작하세요.
        </div>
      </div>
    </div>
  );
};

export default LastBanner;
