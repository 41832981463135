import ImgNotResult from 'assets/_v2/positionList/img_character_joy_empty.png';
import CustomInfiniteScroll from 'components/CustomInfiniteScroll';
import Fonts from '__designkit__/common/fonts';
import colors from '__designkit__/common/colors';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import PositionCurationModel from 'models/_v2/PositionCurationModel';
import PositionListModel from 'models/_v2/PositionListModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import CommonUtils from 'utils/CommonUtils';
import { getDCount } from 'utils/DateUtils';
import PositionCard from '__designkit__/card/PositionCard';
import PostionFilterTab from './PostionFilterTab';
import PostionFisrtBookmarkModal from './PostionFisrtBookmarkModal';

const Frame = styled.div`
  padding-bottom: 60px;
`;

export const PositionResultFrame = styled.div`
  display: grid;
  grid-template-columns: calc(50% - 6px) calc(50% - 6px);
  align-content: center;
  justify-items: center;
  column-gap:8px;
  row-gap: 24px;
  padding:0px 16px 0px 16px;
  >:nth-child(n) {
    justify-self: center;
    align-self: flex-start;
  }
  margin-bottom: 32px;
`;

export const PositionListErrorFrame = styled.div`
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  padding-top: 48px;
  padding-bottom: 40px;
  >img{
    width:100px;
    margin-bottom:32px;
  }
  h1{
    font: ${Fonts.H5_Bold};
    color:${colors.JOBDA_BLACK};
    text-align: center;
    margin-bottom:8px;
  }
  h2{
    font: ${Fonts.B3_Medium};
    color:${colors.CG_70};
    text-align: center;
  }
  .email{
    margin-top:5px;
    font: ${Fonts.B3_Medium};
    color:${colors.CG_70};
    text-align: center;
    color:${colors.G_200};
    text-decoration: underline;
  }
`;

export interface IPositionList {
  positionListModel?: PositionListModel;
  positionCurationModel?: PositionCurationModel;
  context?: Context;
}

const PositionList: FC<IPositionList> = ({ positionListModel = new PositionListModel(), context = new Context() }) => {
  const positions = positionListModel.searchPositions?.positions;
  const { search } = useLocation();
  const [searchKeyword, setSearchKeyword] = useState<string>('');

  const init = async () => {
    const paramList = CommonUtils.parseQuery(search);
    if (paramList.keyword) {
      setSearchKeyword(paramList.keyword);
      positionListModel.searchFilters.keyword = paramList.keyword;
    }
    await positionListModel.loadMatchPositions();
  };

  useEffect(() => {
    init();
  }, [search]);

  const loadInfiniteScroll = async () => {
    await positionListModel.lazeLoadMatchPositions();
  };
  return (
    <>

      {
        (positionListModel.searchFilters.jobTitles === ''
          && positionListModel.searchFilters.recruitments === ''
          && positionListModel.searchFilters.minSalary === undefined
          && positionListModel.searchFilters.maxSalary === undefined
          && positionListModel.searchFilters.locations === ''
          && positionListModel.searchFilters.matchingYn === false
        ) && positions && positions?.length === 0 && searchKeyword !== ''
          ? <></> : <PostionFilterTab />
      }
      <Frame>
        {positionListModel.searchPositions !== null && positionListModel.searchPositions?.pages !== undefined
          && (
            <CustomInfiniteScroll onInfinite={loadInfiniteScroll} initonInfinite={false} threshold={150} usingDownBtn={positionListModel.searchPositions?.pages.page < positionListModel.searchPositions?.pages.totalPages - 1} downBtnName='포지션 더보기'>
              <PositionResultFrame>
                {
                  positions && positions.length > 0 && positions.map((data) => {
                    // 마감 지나면 포지션 리스트에서 제거
                    if (data.closingDateTime && getDCount(data.closingDateTime) > 0) return <></>;
                    return (
                      <PositionCard
                        key={`match-position-total-card-${data.positionSn}`}
                        item={data}
                      />
                    );
                  })
                }
              </PositionResultFrame>
            </CustomInfiniteScroll>
          )}
        {
          (positionListModel.searchFilters.jobTitles !== ''
            || positionListModel.searchFilters.recruitments !== ''
            || positionListModel.searchFilters.minSalary !== undefined
            || positionListModel.searchFilters.maxSalary !== undefined
            || positionListModel.searchFilters.locations !== ''
            || positionListModel.searchFilters.matchingYn !== false
          )
            && positions && positions.length === 0

            ? (
              <PositionListErrorFrame>
                <img src={ImgNotResult} alt='매칭 전체 포지션' />
                <h1>해당 조건의 포지션을 찾을 수 없어요.</h1>
                <h2>필터 조건을 수정해보시는건 어때요?</h2>
              </PositionListErrorFrame>
            )
            : searchKeyword && positions && positions.length === 0 ? (
              <PositionListErrorFrame>
                <img src={ImgNotResult} alt='매칭 전체 포지션' />
                <h1>포지션 정보가 없네요...</h1>
                <h2>다른 검색어를 입력해 보시겠어요?</h2>
              </PositionListErrorFrame>

            )
              : <></>
        }
        <PostionFisrtBookmarkModal />
      </Frame>
    </>

  );
};

export default inject(injectStore.positionListModel)(observer(PositionList));
