import iconArrowUp from 'assets/_v2/_common/icon_arrow_up_white_16.svg';
import colors from '__designkit__/common/colors';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import ScrollUtil from 'utils/ScrollUtil';

export const StyledFloatingButton = styled.div`
  content: '';
  position: fixed;
  right: 16px; 
  bottom: calc(env(safe-area-inset-bottom) + 88px);
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.06)) drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
  z-index: 1;
`;

const Button = styled.button`
  transform: scale(0);
  width: fit-content;

  &[data-visible='true'] {
    animation: popup 0.3s forwards;
  }
  &[data-visible='false'] {
    animation: popdown 0.3s forwards;
  }
  @keyframes popup {
    0% {
      transform: scale(0);
    }
    80% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes popdown {
    100% {
      transform: scale(0);
    }
  }
  &.scroll-up-btn {
    width: 40px;
    height: 40px;
    background: ${colors.JOBDA_BLACK};
    border-radius: 50%;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1),
      0px 4px 6px rgba(0, 0, 0, 0.05);
    overflow: hidden;

    img {
      width: 16px;
      height: 16px;
    }
  }
`;

const JDScrollToTopButton: FC = () => {
  const [scrollToTopVisible, setScrollToTopVisible] = useState<boolean>(false);
  const [scrollPosition, setScrollPosition] = useState<number>(0);

  const blendHandler = async () => {
    const scrollTop = await ScrollUtil.getScrollTop();
    setScrollPosition(scrollTop);
  };

  useEffect(() => {
    if (scrollPosition > 50) setScrollToTopVisible(true);
    else setScrollToTopVisible(false);
  }, [scrollPosition]);

  useEffect(() => {
    ScrollUtil.addEventListener(blendHandler);
    return () => {
      ScrollUtil.removeEventListener(blendHandler);
    };
  }, []);

  const scrollToTopHandler = () => {
    ScrollUtil.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <StyledFloatingButton>
      <Button
        className='scroll-up-btn'
        onClick={scrollToTopHandler}
        data-visible={scrollToTopVisible}
      >
        <img alt='위쪽 화살표' src={iconArrowUp} />
      </Button>
    </StyledFloatingButton>
  );
};

export default JDScrollToTopButton;
