import React, {FC, useState} from "react";
import styled from "styled-components";
import FlipComponent from '../../components/FlipComponent';

const StyledFlipComponent = styled(FlipComponent)`
  width: 312px;
  height: 444px;
  margin: 50px auto;
`;
const StyledFront = styled.div`
  background: red;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  box-shadow: 0px 0px 69.6429px rgba(0, 0, 0, 0.5);
`;
const StyledBack = styled.div`
  background: blue;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  box-shadow: 0px 0px 69.6429px rgba(0, 0, 0, 0.5);
`;
const FlipCardPage: FC = (() => {
  const [flip1, setFlip1] = useState<boolean>(false);
  const [flip2, setFlip2] = useState<boolean>(false);

  return (
    <div>
      <StyledFlipComponent
        front={(<StyledFront><button onClick={() => setFlip1(true)}>앞인데 뒤집으세요</button></StyledFront>)}
        back={(<StyledBack><button onClick={() => setFlip1(false)}>뒤인데 뒤집으세요</button></StyledBack>)}
        flip={flip1}
        duration={500}
      />

      <StyledFlipComponent
        front={(<StyledFront>aaaa</StyledFront>)}
        back={(<StyledBack>bbbb</StyledBack>)}
        flip={flip2}
        duration={1000}
      >
        <button onClick={() => setFlip2(!flip2)}>돌아라</button>
      </StyledFlipComponent>
    </div>
  );
});

export default FlipCardPage;