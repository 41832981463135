import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import colors from '__designkit__/common/colors';
import { fonts, lineHeights } from '__designkit__/common/fonts';
import { absoluteVerticalCenter } from 'consts/style/mixins';

interface IProgressHeartRatingProps {
  max: number;
  value: number;
}

const Frame = styled.div`
  display: inline-block;
  width: 124px;
  height: 20px;
  
  span {
    color: ${colors.CG_80};
    ${fonts.NOTO_13_700};
    ${lineHeights.LINEHEIGHT_1_40};
    vertical-align: top;
    margin-left: 8px;
  }
`;

const HeartRatingFrame = styled.div`
  position: relative;
  display: inline-block;
  width: 96px;
  height: 20px;
`;

const HEART_SIZE = 16;

const greenHeartLeftValue = (index: number, rate: number) => {
  const initLeft = -((index * HEART_SIZE) + (HEART_SIZE * 5));
  const targetLeft = (HEART_SIZE * 5) * rate;
  const left = initLeft + targetLeft;
  return left;
};
const HeartBox = styled.div`
  position:relative;
  ${absoluteVerticalCenter()};
  width: ${HEART_SIZE}px;
  height: ${HEART_SIZE}px;
  background: ${colors.CG_40};
  clip-path:url(#clip-path-heart-progress);
  -webkit-clip-path: path('M8 14.236L14.2819 7.14585C14.8697 6.41112 15.1636 5.41924 14.9064 4.39062C14.6125 3.10485 13.5472 2.07623 12.2247 1.81908C9.98376 1.37824 8 2.70075 8 4.79472C8 2.70075 6.01624 1.37824 3.77532 1.81908C2.45281 2.07623 1.38746 3.10485 1.09357 4.39062C0.836417 5.45598 1.13031 6.41112 1.71809 7.14585L8 14.236Z');
  left: ${(props) => (props as any)['data-index'] * (16 + 4)}px;
  &:before {
    content: '';
    width: 80px;
    height: 16px;
    position: absolute;
    background: ${colors.G_100};
    transition: transform 1s;
    transform: translate(${(props) => greenHeartLeftValue((props as any)['data-index'], (props as any)['data-rate'])}px, 0);
  }
`;

const ProgressHeartRating: FC<IProgressHeartRatingProps> = ((props) => {
  const { max, value } = props;
  const [rate, setRate] = useState<number>(0);

  useEffect(() => {
    const rate = Math.min(value, max) / max;
    setRate(rate);
  }, [max, value]);

  return (
    <>
      <Frame>
        <svg width='0' height='0' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <defs>
            <clipPath id='clip-path-heart-progress'>
              <path d='M8 14.236L14.2819 7.14585C14.8697 6.41112 15.1636 5.41924 14.9064 4.39062C14.6125 3.10485 13.5472 2.07623 12.2247 1.81908C9.98376 1.37824 8 2.70075 8 4.79472C8 2.70075 6.01624 1.37824 3.77532 1.81908C2.45281 2.07623 1.38746 3.10485 1.09357 4.39062C0.836417 5.45598 1.13031 6.41112 1.71809 7.14585L8 14.236Z' fill='#6CDD83' stroke='#6CDD83' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
            </clipPath>
          </defs>
        </svg>
        <HeartRatingFrame>
          <HeartBox data-index={0} data-rate={rate} />
          <HeartBox data-index={1} data-rate={rate} />
          <HeartBox data-index={2} data-rate={rate} />
          <HeartBox data-index={3} data-rate={rate} />
          <HeartBox data-index={4} data-rate={rate} />
        </HeartRatingFrame>
        <span>{value && value.toFixed(1)}</span>
      </Frame>
    </>
  );
});

export default ProgressHeartRating;
