import ImgLogo from 'assets/_v2/_common/img_logo_acca.svg';
import VerificationContent from 'components/_v2/verification/VerificationContent';
import React, { FC } from 'react';
import styled from 'styled-components';
import IconShield from 'assets/_v2/verification/icon_shield_user.svg';
import Fonts from '__designkit__/common/fonts';
import colors from '__designkit__/common/colors';

const Frame = styled.div`
  width: 100%;
  height: fit-content;

  &.none {
    display: none;
  }
`;

const Header = styled.div`
  display: flex;
  padding-top: 32px;
  flex-direction: column;
  align-items: center;

  img {
    display: block;
    width: 82px;
  }

  .divider-frame {
    width: 100%;
    margin-top: 24px;

    .divider {
      width: 100%;
      height: 0;
      border-top: 1px solid #DEE2E6;
    }
  }
`;

const ContentFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 20px;

  > div {
    margin-top: 12px;
    text-align: center;
    font: ${Fonts.H4_Bold};
    color:${colors.JOBDA_BLACK};
    white-space: pre-line;
  }
`;

interface IVerificationProps {
  content: string;
  returnUrl:string;
  className?: string;
  headerYn?: boolean;
  handlePassVerification?: () => void;
  mobileText?: string;
  ipinText?: string;
}

const Verification: FC<IVerificationProps> = ({ content, returnUrl, className, headerYn, handlePassVerification, mobileText, ipinText }) => (
  <Frame className={className || ''}>
    {headerYn
      && (
      <Header>
        <img src={ImgLogo} alt='logo' />
        <div className='divider-frame'>
          <div className='divider' />
        </div>
      </Header>
      )}
    <ContentFrame>
      <img src={IconShield} alt='icon' />
      <div>{content}</div>
    </ContentFrame>
    <VerificationContent
      handlePassVerification={handlePassVerification}
      returnUrl={returnUrl}
      mobileText={mobileText}
      ipinText={ipinText}
    />
  </Frame>
);

export default Verification;
