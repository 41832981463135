import JDPlusTextButton from 'components/_v2/_common/button/JDPlusTextButton';
import FormProfileValueTitle from 'components/_v2/_common/form/FormProfileValueTitle';
import JDBaseDeleteInput from 'components/_v2/_common/input/JDBaseDeleteInput';
import { IProfileProps } from 'interfaces/_v2/profile/IProfileProps';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import ProfileModel from 'models/_v2/profile/ProfileModel';
import React, { FC, useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

const Frame = styled.div`

  .referenceUrl {
    margin-bottom: 28px;
  }
  .urls {
    margin-bottom: 20px;
  }
  .url-frame {
    margin-top: 8px;
  }
`;

const ProfileAttachedFileUrls:FC<IProfileProps> = ({ profileModel = new ProfileModel() }) => {
  const useFormed = useFormContext();
  const { control, watch } = useFormed;
  const name = `referenceUrls`;
  const { fields: referenceUrlsFields, append: referenceUrlsAppend, remove: referenceUrlsRemove } = useFieldArray<String>({
    control,
    name,
  });

  useEffect(() => {
    if (referenceUrlsFields.length === 0) {
      referenceUrlsAppend('', false);
    }
  }, [referenceUrlsFields]);

  return (
    <Frame>
      <FormProfileValueTitle>참조 URL</FormProfileValueTitle>
      <div className='referenceUrl'>
        { referenceUrlsFields.map((field, urlsIndex) => (
          <div key={field.id} className='url-frame'>
            <JDBaseDeleteInput
              name={`${name}[${urlsIndex}].`}
              placeholder='참조 URL을 입력해 주세요.'
              defaultValue={Object.values(field).filter((value, idx) => idx !== Object.keys(field).length - 1).join('') || ''}
              onDelete={() => {
                // if (urlsIndex === 0) setValue(`${name}[${urlsIndex}].`, '');
                // else
                referenceUrlsRemove(urlsIndex);
              }}
            />
          </div>
        ))}
      </div>
      <JDPlusTextButton
        text='추가'
        onClick={() => referenceUrlsAppend({ })}
        disabled={!watch(`${name}[0].`) && !watch(`${name}[${referenceUrlsFields.length - 1}].`)}
      />
    </Frame>
  );
};

export default inject(injectStore.profileModel)(observer(ProfileAttachedFileUrls));
