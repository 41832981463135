import RadioButton from '__designkit__/components/RadioButton';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { FC } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

const Frame = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: fit-content;
  height: fit-content;
`;

interface IRadioFieldProps extends IComponentProps {
  name: string; // 필수값, react-hook-form에서 사용하는 name 값
  value: string; // 필수값, 해당 라디오 버튼이 가지는 값
  label: React.ReactNode; // 필수값, 해당 라디오 버튼의 라벨로 React 컴포넌트를 받음
  disabled?: boolean; // 선택값, 지정시 수정 불가
}

const RadioField: FC<IRadioFieldProps> = ({ name, value, label, disabled }) => {
  const { setValue, getValues, clearErrors } = useFormContext();
  const { field } = useController({ name });

  return (
    <Frame onClick={() => { setValue(name, value); clearErrors(name); }}>
      <RadioButton
        {...field}
        name={name}
        value={value}
        selected={value === getValues(name)}
        disabled={disabled}
      />
      { label }
    </Frame>
  );
};

export default RadioField;
