/* eslint-disable react-hooks/exhaustive-deps */
import ComponentsWithTabs from 'components/ComponentsWithTabs';
import FindId from 'components/findAccount/FindId';
import FindPassword from 'components/findAccount/FindPassword';
import RoutePaths from 'consts/RoutePaths';
import IContextPageProps from 'interfaces/props/IContextPageProps';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import FindAccountModel from 'models/FindAccountModel';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import TodayRenderCheckUtil from 'utils/TodayRenderCheckUtil';

export const FindAccountPage = {
  ID: 'id',
  PW: 'pw',
} as const;

export type FindAccountPageType = typeof FindAccountPage[keyof typeof FindAccountPage];

export interface IFindAccountProps extends IContextPageProps {
  login?: Login;
  findAccountModel?: FindAccountModel;
  context?: Context;
}

const FindAccount: React.FC<IFindAccountProps> = ({ findAccountModel = new FindAccountModel(), context = new Context() }) => {
  const history = useHistory();
  const { page } = useParams<{ page: FindAccountPageType }>();
  const [activeTabType, setActiveTabType] = useState<number>(0);
  const urlString = new URLSearchParams(history.location.search);
  const redirectUrl = urlString.get('redirect_url');

  useEffect(() => {
    if (redirectUrl) {
      context.otherPageRedirectUrl = redirectUrl;
      TodayRenderCheckUtil.setUtmParams('redirect_url', redirectUrl);
    }
  }, [redirectUrl]);

  useEffect(() => (() => {
    findAccountModel.resetData();
  }), []);

  useEffect(() => {
    switch (page) {
      case FindAccountPage.ID:
        setActiveTabType(0);
        findAccountModel.resetData();
        break;
      case FindAccountPage.PW:
        setActiveTabType(1);
        findAccountModel.resetData();
        break;
      default:
        history.replace(RoutePaths.findAccount(FindAccountPage.ID));
        break;
    }
  }, [page]);

  const setTabLocation = (index: number) => {
    switch (index) {
      case 1:
        history.replace(RoutePaths.findAccount(FindAccountPage.PW));
        break;
      case 0: default:
        history.replace(RoutePaths.findAccount(FindAccountPage.ID));
        break;
    }
  };

  return (
    <ComponentsWithTabs
      tabs={['아이디 찾기', '비밀번호 찾기']}
      initTab={activeTabType}
      containHeader
      onChangeComponent={setTabLocation}
      components={[
        page === FindAccountPage.ID && <FindId />,
        page === FindAccountPage.PW && <FindPassword />, // IPIN 인증 중복 막기 위해
      ]}
    />
  );
};

export default inject(injectStore.findAccountModel, injectStore.context)(observer(FindAccount));
