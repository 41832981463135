/* eslint-disable class-methods-use-this */
import URIs from 'consts/URIs';
import { InsightResumeLogRq } from 'interfaces/_v2/insight/IInsightResumeRqRs';
import { request } from 'utils/request';

export default interface LogRepo {
    // 지원서 로그 저장
    saveLog(rq:InsightResumeLogRq): Promise<boolean>;
}

export class RemoteLogRepo implements LogRepo {
  saveLog(rq:InsightResumeLogRq): Promise<boolean> {
    return request({
      method: 'post',
      url: URIs.post_v1_logs,
      data: rq,
    });
  }
}
