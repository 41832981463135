import { MatchLoginConnectInfoModalProps } from 'components/_v2/matchLogin/MatchLoginConnectInfoModal';
import JDBaseModal from 'components/_v2/_common/modals/JDBaseModal';
import { JDModalBasicFrame, JDModalDescription, JDModalTitle } from 'components/_v2/_common/modals/JDModalComponents';
import JDNoHeaderFullModal from 'components/_v2/_common/modals/JDNoHeaderFullModal';
import { IconCheck } from 'consts/assets/icons/iconPages';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { JDMainButton } from 'consts/_v2/_common/style/mixins';
import React, { FC } from 'react';
import styled from 'styled-components';

const Frame = styled.div`

    ${JDModalDescription} {
        margin-top: 16px;

        ul {
            margin-bottom: 12px;
        }

        li {
            display: flex;
            justify-content: left;
            align-items: flex-start;

            .icon-check {
                margin-top: 4.5px;
                margin-right: 12px;
            }

            span {
                font: ${Fonts.B2_Medium_P};
                color: ${colors.JOBDA_BLACK};
                text-align: left;
                word-break: break-all;
            }
        }

        li:not(:last-child) {
            margin-bottom: 6px;
        }

        .tip {
            font: ${Fonts.B3_Medium};
            color: ${colors.CG_60};
            text-align: center;
        }
    }
`;

const ButtonFrame = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    
    button {
      border-radius: 4px;
    }
    .amend-button {
        ${JDMainButton(true, '128px')};
        margin-right: 8px;
    }
    .submit-button {
        ${JDMainButton(false, '128px')};
    }
`;

const MatchLoginResumeExistModal:FC<MatchLoginConnectInfoModalProps> = ({ onProcess, onCancel, isOpen, onClickClose }) => (
  <>
    <JDNoHeaderFullModal isOpen={isOpen} onClose={onClickClose}>
      <JDBaseModal dimmed isOpen={isOpen} onClickClose={onClickClose} isDimClickClose>
        <Frame>
          <JDModalBasicFrame>
            <JDModalTitle>작성중인 지원서가 있습니다.<br />그래도 전형을 진행하시겠습니까?</JDModalTitle>
            <JDModalDescription>
              <ul className='description'>
                <li><IconCheck className='icon-check' /><span>해당 포지션에 자동으로 지원 처리가 됩니다.</span></li>
                <li><IconCheck className='icon-check' /><span>인사담당자가 발송했던 메세지를 모두 확인할 수 있습니다.</span></li>
                <li><IconCheck className='icon-check' /><span>작성 중인 지원서는 자동으로 삭제됩니다.</span></li>
              </ul>
              <span className='tip'>동의하시는 경우 연동하기 버튼을 눌러주세요.</span>
            </JDModalDescription>

            <ButtonFrame>
              <button className='amend-button' onClick={() => { if (onCancel) onCancel(); onClickClose(); }}>다음에 하기</button>
              <button className='submit-button' onClick={() => { if (onProcess) onProcess(); onClickClose(); }}>연동 하기</button>
            </ButtonFrame>
          </JDModalBasicFrame>
        </Frame>
      </JDBaseModal>
    </JDNoHeaderFullModal>
  </>
);

export default MatchLoginResumeExistModal;
