import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import CompanyAdvantageGraph from 'components/_v2/company/CompanyAdvantageGraph';
import { CompanyInformationTitle } from 'components/_v2/company/CompanyDetailInformation';
import { StyledMainCurationChip, StyledMainCurationTab } from 'components/_v2/main/MainContents';
import { TopicType, TopicTypeText } from 'consts/_v2/company/CompanyDetailType';
import { inject, observer } from 'mobx-react';
import CompanyModel from 'models/_v2/CompanyModel';
import { injectStore } from 'models/store';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
    padding:0 16px;
`;
const InnerFrame = styled.div`
    padding: 64px 0px 0px 0px;
`;
const StyledCompanyCurationTab = styled(StyledMainCurationTab)`
    .curation-frame{
    padding: 0px;
}
`;

const StyledCompanyCurationChip = styled(StyledMainCurationChip)`
    background-color: ${colors.WHITE_100};
    border: ${(props) => (props.selected ? `1px solid ${colors.JOBDA_BLACK}` : `1px solid ${colors.CG_40}`)} ;
    margin-right:4px;
    .curation-text{
            color:${(props) => (props.selected ? `${colors.JOBDA_BLACK}` : `${colors.CG_70}`)} ;
            font:${Fonts.B3_Medium};
        }
`;

const ScrollFrame = styled.div`
height: 182px;
width: 100%;
overflow: hidden;
overflow-y: scroll;
position: relative;
padding-right: 12px;
::-webkit-scrollbar{
  width:4px;
}
::-webkit-scrollbar-thumb{
  border-radius: 0px;
  background-color: ${colors.CG_50};
}
::-webkit-scrollbar-track{
  background-color: ${colors.WHITE_100};
}
`;

interface ICompanyDetailAdvantage {
    companyModel?: CompanyModel
}
const CompanyDetailAdvantage:FC<ICompanyDetailAdvantage> = ({ companyModel = new CompanyModel() }) => {
  const chipTitle = [
    { type: TopicType.WORK_ENVIRONMENT },
    { type: TopicType.COWORKER_RELATIONSHIP },
    { type: TopicType.CASH_REWARD },
  ];
  const [selectedId, setSelectedId] = useState<number>(0);
  const [selectedType, setSelectedType] = useState<TopicType>(TopicType.WORK_ENVIRONMENT);
  const handleTabSelect = (tabId:number, type:TopicType) => {
    setSelectedId(tabId);
    setSelectedType(type);
  };
  const filteredData = companyModel.companyAdvantage?.companyCulture.companyCultureIncumbents
    .find((incumbent) => incumbent.type === selectedType);
  return (
    <Frame>
      <InnerFrame>
        <CompanyInformationTitle>입사 선배가 <br /> 알려주는 장점</CompanyInformationTitle>
        <SpacingBlock vertical size={24} />
        <StyledCompanyCurationTab>
          <div className='curation-frame'>
            {chipTitle.map((item, idx) => (
              <StyledCompanyCurationChip key={`curation-${item.type + idx}`} selected={selectedId === idx} onClick={() => handleTabSelect(idx, item.type)}>
                <div className='curation-text'>
                  {TopicTypeText[item.type]}
                </div>
              </StyledCompanyCurationChip>
            ))}

          </div>
        </StyledCompanyCurationTab>
        <ScrollFrame>
          {companyModel.companyAdvantage?.companyCulture && filteredData?.companyCultureAnswers.map((item, idx) => (
            <>
              <CompanyAdvantageGraph
                key={`advantage-${item}-${idx}`}
                index={idx}
                topicType={selectedType}
                averageScore={item.averageScore}
                questionNumber={item.questionNumber}
                answerYn
                tier={idx + 1}
              />
              <SpacingBlock size={8} vertical />
            </>
          ))}
        </ScrollFrame>
      </InnerFrame>

    </Frame>
  );
};
export default inject(injectStore.companyModel)(observer(CompanyDetailAdvantage));
