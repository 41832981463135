import React, { FC } from 'react';
import styled from 'styled-components';
import { RequiredText } from 'components/_v2/profile/ProfileItemTitle';
import Icon from '__designkit__/icon/Icon';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import IComponentProps from 'interfaces/props/IComponentProps';

const StyledSNBTab = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: fit-content;
  padding: 16px 4px;

  .tab {
    display: flex;
    align-items: center;

    .tab-text {
      font: ${Fonts.B1_Medium};
      color: ${Colors.CG_80};
    }
    
    .icon-star{
      height: 13px;
      width: 11px;
    }
  }

  .check-icon{
    width: 20px;
  }
`;

const CheckCircle = styled.div<{ isChecked: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;  
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: ${({ isChecked }) => (isChecked ? Colors.G_150 : Colors.CG_50)};
`;

interface ITabProps{
  title: string;
  isPC?: boolean;
  essential: boolean;
  completeYn: boolean;
}
interface IProfileSNBTabProps extends IComponentProps{
  tabInfo: ITabProps
}

const ProfileSNBTab: FC<IProfileSNBTabProps> = ({ tabInfo, onClick }) => (
  <StyledSNBTab onClick={onClick}>
    <div className='tab'>
      <div className='tab-text'>{ tabInfo.title }</div>
      { tabInfo.essential && <RequiredText>필수</RequiredText> }
      { tabInfo.isPC && <Icon name='open-in-new' size={20} color={Colors.CG_80} /> }
    </div>
    <CheckCircle isChecked={tabInfo.completeYn}>
      <Icon name='check' size={20} color={Colors.WHITE_100} />
    </CheckCircle>
  </StyledSNBTab>
);

export default ProfileSNBTab;
