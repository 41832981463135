import { ProfileAuditItemType } from 'consts/_v2/profile/ProfileType';

export enum ExperienceActivitiesType {
    INTERNAL = 'INTERNAL',
    EXTERNAL = 'EXTERNAL',
    EDUCATION = 'EDUCATION',
    VOLUNTEER = 'VOLUNTEER',
    CLUB = 'CLUB',
  }

export enum ExperienceActivitiesTypeText {
    INTERNAL = '교내 활동',
    EXTERNAL = '대외 활동',
    EDUCATION = '교육 이수',
    VOLUNTEER = '봉사 활동',
    CLUB = '동아리',
    OVERSEAS = '해외 경험'
  }

export enum ExperienceForeignActivitiesType{
    INTERNAL = 'INTERNAL',
    EXTERNAL = 'EXTERNAL',
    EDUCATION = 'EDUCATION',
    VOLUNTEER = 'VOLUNTEER',
    CLUB = 'CLUB',
    OVERSEAS = 'OVERSEAS',
}
export enum ExperienceForeignActivitiesTypeText{
    INTERNAL = '교내 활동',
    EXTERNAL = '대외 활동',
    EDUCATION = '교육 이수',
    VOLUNTEER = '봉사 활동',
    CLUB = '동아리',
    OVERSEAS = '해외 경험'
}
export interface IActivityDto{
    type: string;
    place: string;
    startDate:string;
    endDate:string;
    description:string;
}

export interface IForeignExperienceDto{
    countryCode:number;
    startDate:string;
    endDate:string;
    description:string;
}

export interface IForeignExperienceEditDto{
    countryCode:number;
    countryName:string;
    startDate:string;
    endDate:string;
    description:string;
}

export interface IExperienceDto{
    description:string;
}

export interface IProfileExperienceRq{
    activities:IActivityDto[];
    foreignExperiences:IForeignExperienceDto[];
    experiences: IExperienceDto[];
    sourcePage: string | null;
}
export interface IProfileExperienceEditRq{
    activities:IActivityDto[];
    foreignExperiences:IForeignExperienceEditDto[];
    experiences:IExperienceDto[];
}
export interface IActivityAndForeignExperience{
    tag : string;
    code:number;
    title:string;
    startDate:string;
    endDate:string;
    description:string;
    auditItems?:ProfileAuditItemType[]
}
export interface IProfileExperienceRs{
    activitiesAndForeignExperiences:IActivityAndForeignExperience[];
    etcExperiences:IExperienceDto[];
}
