import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { IDashBoardButtonProps } from 'components/jobMatch/dashboard/MatchDashBoardCompanyMessage';
import React, { FC } from 'react';
import styled from 'styled-components';

const ButtonOptionFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  >button {
    padding: 14px 16px;
    border-radius: 4px;
    width: 100%;
  }

  .button-accept {
    background: ${colors.JOBDA_BLACK};
    color: ${colors.WHITE_100};
    font: ${Fonts.B2_Bold};
    margin-bottom: 8px;

    &:disabled {
      opacity: 0.5;
    }
  }

  .button-refuse {
    color: ${colors.CG_70};
    font: ${Fonts.B2_Bold};
    border:1px solid ${colors.CG_60};
    &:disabled {
      opacity: 0.5;
    }
  }
`;

const MatchDashboardNoScheduleButton:FC<IDashBoardButtonProps> = ({ onClick, disabled }) => {
  return (
    <ButtonOptionFrame>
      <button id='accept' className='button-accept' onClick={(e) => onClick(e)}>수락하기</button>
      <button id='refuse' className='button-refuse' onClick={(e) => onClick(e)}>거절하기</button>
    </ButtonOptionFrame>
  );
};

export default MatchDashboardNoScheduleButton;
