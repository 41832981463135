/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable camelcase */
import RoutePaths from 'consts/RoutePaths';
import { IKaKaoTokenRq, ISocialAuthLoginRs, OpenIdProvider } from 'interfaces/rqrs/ISocialAuthLoginRs';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import JoinModel from 'models/_v2/JoinModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import MobileStore from 'store/mobileStore';
import AuthorizeUtil from 'utils/AuthorizeUtil';
import CommonUtils, { saveSession } from 'utils/CommonUtils';

interface IKakao{
    login?:Login
    joinModel?:JoinModel
}
interface IParams {
    grant_type: string;
    client_id: string;
    redirect_uri: string;
    code: string;
    [key: string]: string; // 인덱스 시그니처 추가
}

const Kakao:FC<IKakao> = ({ login = new Login(), joinModel = new JoinModel() }) => {
  const { search, pathname } = useLocation();
  const { code } = CommonUtils.parseQuery(search);
  const { publicKey, positionId } = CommonUtils.parseQuery(search);
  const matchLoginPathYn = pathname === RoutePaths.matchLogin;
  const history = useHistory();

  const getTokenParamKakao = (kakaoCode: string) => {
    return {
      grant_type: 'authorization_code',
      client_id: process.env.REACT_APP_KAKAO_JS_KEY,
      redirect_uri: `${window.location.origin}/oauth2/callback/kakao`,
      code: kakaoCode, // 'kakaoCode' 대신 'code' 사용
    };
  };
  const getKakaoToken = async (kakaoCode: string) => {
    const params:IParams = getTokenParamKakao(kakaoCode);
    const queryString = Object.keys(params)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key] as string)}`) // 타입 단언 사용
      .join('&');

    try {
      const data = await login.getKakaoToken(queryString);
      return data;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  };
  const processLogin = async () => {
    await login.loadCurrentUser();
    history.push(RoutePaths.login);
  };

  const kakaoLogin = async (code: string) => {
    try {
      // 인가 코드로 카카오 토큰 받음
      const data:any = await getKakaoToken(code);
      let jdaAuth: ISocialAuthLoginRs | null = null;
      jdaAuth = await login.postKakaoAuth(data.data.access_token);

      if (jdaAuth!.registered) {
        await processLogin();
        AuthorizeUtil.setIsAutoLogin(true);
      } else {
      // ipin 인증 전 입력한 social값 저장하기
        const socialInfo: Partial<ISocialAuthLoginRs> = {
          openId: jdaAuth?.openId,
          openIdProvider: jdaAuth?.openIdProvider,
          email: jdaAuth?.email,
          name: jdaAuth?.name,
          phoneNumber: jdaAuth?.phoneNumber,
        };
        saveSession('socialInfo', socialInfo);
        joinModel.socialJoinInfo = jdaAuth;
        history.push(RoutePaths.join);
      }
    } catch (e: any) {
      return e;
    }
  };

  useEffect(() => {
    if (!code) return;
    const authenticate = async () => {
      await kakaoLogin(code as string);
    };

    authenticate();
  }, [code]);

  return <></>;
};

export default inject(injectStore.login, injectStore.joinModel)(observer(Kakao));
